import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

export const UserSalesTargetsWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Sales Target',
  fields: [
    {
      name: 'id',
      title: 'ID',
      format: COLUMN_FORMAT.ID_LINK_SHORT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      linkRoute: '/internal-projects/tasks',
      visible: false,
    },
    {
      name: 'user_id',
      title: 'User Id',
      visible: false,
      isParentId: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'start_date',
      title: 'Start Date',
      visible: true,
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      formSaveField: 'name',
    },
    {
      name: 'end_date',
      title: 'End Date',
      visible: true,
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
    },
    {
      name: 'annual_profit_target',
      title: 'Profit Target',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
    },
    {
      name: 'salary_threshold',
      title: 'Salary Threshold',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
    },
    {
      name: 'first_profit_target',
      title: 'First Profit Target',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
    },
    {
      name: 'second_profit_target',
      title: 'Second Profit Target',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
    },
  ],
};
