
/**
 * @description
 * Convert the first letter of every word in string to upper case
 *
 * @param {string} words
 */
export const titleCase: (words: string) => string = (words: string) => String(words || '')
  .toLocaleLowerCase()
  .split(' ')
  .map((word) => word.charAt(0).toUpperCase()
        + (word.length > 1
          ? word.slice(1)
          : ''))
  .join(' ');
