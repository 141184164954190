import React from 'react';
import classNames from 'classnames';
import { Label as ReactStrapLabel, LabelProps as ReactStrapLabelProps } from 'reactstrap';

export type LabelProps = ReactStrapLabelProps & {
  hasError?: boolean;
};

export const Label:React.FC<LabelProps> = (props) => {
  const { children, className } = props;

  // Ensure only valid props go to the ReactStrap Label
  const { hasError, ...reactStrapLabelProps } = props;

  return (
    <ReactStrapLabel
      {...reactStrapLabelProps}
      className={classNames(className, {
        danger: (hasError === true),
      })}
    >
      {children}
    </ReactStrapLabel>
  );
};
