import React, { useEffect, useCallback, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader } from 'reactstrap';

import { APIContext } from '../providers/api-provider';

import { LoadingSpinner } from '../layout-helpers/loading-spinner';
import { apiAborter } from '../../helpers/api-aborter.helper';

/**
 * DToolsUserList component for Data Table
 */
export const DToolsUserList = ({ loggedInOnly }) => {
  const [dToolsUsers, setDToolsUsers] = useState({
    data: [],
    loading: true,
    hasError: false,
  });

  const abortController = useRef(null);
  const { apiFetch } = useContext(APIContext);

  const loadData = useCallback(async () => {
    setDToolsUsers({
      ...dToolsUsers,
      loading: true,
      hasError: false,
    });

    if (abortController.current) {
      abortController.current.abort();
    }

    abortController.current = apiAborter();

    const response = await apiFetch(
      '/dtools-connector/users',
      {
        name: 'DToolsUserList::loadData',
        signal: abortController.current.signal,
      },
    );

    if (response.success) {
      abortController.current = null;

      // Get the returned data, if it exists, otherwise use an empty array
      if (response.body.data) {
        let data = response.body.data || [];
        if (loggedInOnly) {
          data = data.filter((item) => item.SessionId !== null);
        }
        setDToolsUsers({
          data,
          loading: false,
          hasError: false,
        });
      }
      else {
        setDToolsUsers({
          data: [],
          loading: false,
          hasError: true,
        });
      }
    } else if (!response.aborted) {
      abortController.current = null;
      console.error(response.error);

      setDToolsUsers({
        data: [],
        loading: false,
        hasError: true,
      });
    }
  }, [loggedInOnly, dToolsUsers, apiFetch]);


  /**
   * Load the data when teh component is mounted
   */
  useEffect(() => {
    loadData();

    // Stop loading data if the component is unmounted
    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Card>
      <CardHeader className="bg-dark text-white" tag="h4">
        {loggedInOnly && 'Logged In '}
        D-Tools Users
      </CardHeader>
      <div
        className="table-container"
        style={{ maxHeight: loggedInOnly ? '300px' : '490px', overflowY: 'auto' }}
      >
        <table style={{ width: '100%' }} className="table table-striped">
          <thead>
            {/*
              "Id": "e139f6c6-000f-413e-90f0-028ec2a5a9a0",
              "Name": "Tony Stark",
              "Email": "mark.latham@ciasia.com.au",
              "SessionId": null,
              "UserName": "Tony Stark",
              "Roles": "Designer, Project Manager, Sales Rep",
              "Mobile": "",
              "SIUser": true,
              "MIId": null,
              "Inactive": false,
              "SSId": null,
              "GroupId": "2f2ffa77-6ac8-4222-8eee-5de4bdacd08f",
              "Cost": 0
            */}
            <tr>
              {/* <th>Id</th> */}
              <th>Name</th>
              {/* <th>Email</th> */}
              <th className="text-center">Logged In</th>
              {/* <th>UserName</th> */}
              {!loggedInOnly && <th>Roles</th>}
              {/* <th>Mobile</th> */}
              {!loggedInOnly && <th>Enabled</th>}
              {/* <th>MIId</th> */}
              {/* <th>Active</th> */}
              {/* <th>SSId</th> */}
              {/* <th>GroupId</th> */}
              {/* <th>Cost</th> */}
            </tr>
          </thead>
          <tbody>
            {dToolsUsers.loading && (
              <tr>
                <td colSpan={loggedInOnly ? 2 : 4}>
                  <LoadingSpinner />
                </td>
              </tr>
            )}
            {!dToolsUsers.loading && dToolsUsers.data.length < 1 && !dToolsUsers.hasError && (
              <tr>
                <td colSpan={loggedInOnly ? 2 : 4}>No users listed.</td>
              </tr>
            )}
            {!dToolsUsers.loading && dToolsUsers.data.length < 1 && dToolsUsers.hasError && (
              <tr>
                <td colSpan={loggedInOnly ? 2 : 4}>
                  Couldn&apos;t fetch D-Tools users at this time.
                </td>
              </tr>
            )}
            {dToolsUsers.data
              .sort((a, b) => {
                // name sort the whole lot
                if (a.Name < b.Name) return -1;
                if (a.Name > b.Name) return 1;
                return 0;
              })
              .sort((a, b) => {
                // now sort on user status and logged in status
                if (a.SessionId === null && b.SessionId === null) {
                  if (a.SIUser) return -1;
                  if (b.SIUser) return +1;
                }
                if (a.SessionId === null) return +1;
                if (b.SessionId === null) return -1;
                return 0;
              })
              .map((item) => (
                <tr key={`location-${item.Id}`}>
                  {/* <td>{item.Id}</td> */}
                  <td>
                    <a href={`mailto:${item.Email}`}>{item.Name}</a>
                  </td>
                  {/* <td>{item.Email}</td> */}
                  <td className="text-center">{item.SessionId !== null ? 'Y' : ''}</td>
                  {/* <td>{item.UserName}</td> */}
                  {!loggedInOnly && <td style={{ maxWidth: '200px' }}>{item.Roles}</td>}
                  {/* <td>{item.Mobile}</td> */}
                  {!loggedInOnly && (
                    <td>
                      {item.SIUser ? (
                        <span className="text-green">yes</span>
                      ) : (
                        <span className="text-danger">no</span>
                      )}
                    </td>
                  )}
                  {/* <td>{item.MIId}</td> */}
                  {/* <td>{item.Inactive}</td> */}
                  {/* <td>{item.SSId}</td> */}
                  {/* <td>{item.GroupId}</td> */}
                  {/* <td>{item.Cost}</td> */}
                </tr>
              ))}
          </tbody>
        </table>
        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      </div>
    </Card>
  );
};

DToolsUserList.defaultProps = {
  loggedInOnly: false,
};

DToolsUserList.propTypes = {
  loggedInOnly: PropTypes.bool,
};
