/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { Button, Table } from 'reactstrap';
import { ConfirmActionModalResult } from '../../../../types/modal/modal-result';
import { IRenewContractModalWizardPage2Data, IRenewContractModalWizardState } from '../renew-contract-data.interface';

import { ModalWizardPage, ModalWizardPageProps } from '../../modal-wizard-page';
import { CheckBox } from '../../../form-input/checkbox';
import { formatValueString } from '../../../render-functions';
import { COLUMN_FORMAT } from '../../../../constants/column-format.const';

export const RenewContractModalPage2 = (props: ModalWizardPageProps<
  IRenewContractModalWizardPage2Data,
  IRenewContractModalWizardState,
  ConfirmActionModalResult
>): React.ReactElement => {
  const { pageData, onPageDataFieldChanged, wizardState, onModalComplete, closeModal, modalId } = props;
  const {
    processing,
    success,
    contractSpaces,
  } = wizardState;

  return (
    <ModalWizardPage<IRenewContractModalWizardPage2Data, IRenewContractModalWizardState, ConfirmActionModalResult> {...props}>
      <div className="wizard-page-section">
        <div className="wizard-page-section-heading">
          <span>Step 2: Review Contract Spaces</span>
        </div>
        {/* When the processing has completed, show the close button */}
        {!processing && !!success && (
          <div className="wizard-page-section-content">
            <Button
              color="primary"
              onClick={() => {
                closeModal(modalId, false, () => onModalComplete({ processAction: false }));
              }}
            >
              Close
            </Button>
          </div>
        )}
        <Table>
          <thead>
            <tr>
              <th>Renew?</th>
              <th>Space</th>
              <th>Type</th>
              <th className="text-right">Value</th>
            </tr>
          </thead>
          <tbody>
            {contractSpaces && contractSpaces.map((space) => (
              <tr key={space.id} className="text-right">
                <td>
                  <CheckBox
                    id={`include_space_${space.id}`}
                    name={`include_space_${space.id}`}
                    inline
                    value={pageData.cover_spaces[space.id]}
                    onChange={() => onPageDataFieldChanged && onPageDataFieldChanged({
                      fieldName: 'cover_spaces',
                      newValue: {
                        ...pageData.cover_spaces,
                        [space.id]: pageData.cover_spaces[space.id] ? 0 : 1,
                      },
                    })}
                  />

                </td>
                <td>
                  {space.company_space.path}
                </td>
                <td>
                  {space.subtype?.name}
                </td>
                <td className="text-right">
                  {formatValueString(space.value, COLUMN_FORMAT.CURRENCY)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="wizard-page-section-content">
          <div className="wizard-form" />
        </div>

      </div>
    </ModalWizardPage>
  );
};
