/* eslint-disable react/button-has-type */
/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import classnames from 'classnames';
import Icon from './icon';
import { noop } from '../../utils/helpers';
import { DEBUG } from '../../utils/constants';
import { colorHue, consoleColor } from '../../utils/colours';

/**
 *
 * @param {string} name name to show for output
 * @param {*} data anything to output
 * @param {number} [depth=3] depth of output
 */
const darkPr = (name, data, depth = 3) => (
  <Pr name={name} data={data} depth={depth} dark closed={false} fly={false} />
);

/**
 * Print recursive attribute
 * @param {Component} that JSX class containing a constructor
 * @param {string} att attribute to use from `that` eg `that.att`
 * @param {number} [depth=3] depth of output
 * @param {string} [from='Pr'] name of where this is from
 */
export const prat = (that, att, depth, from = 'Pr') => {
  if (!DEBUG) return null;
  const d = att ? that[att] : that;
  const strucName = that.constructor.name;
  const attr = att ? `.${att}` : '';
  const n = `${strucName === 'Object' ? from : strucName}${attr}`;
  console.log(n, d);
  return darkPr(n, d, depth);
};

/**
 * Print Recursive state
 * @param {Component} that JSX class containing a constructor
 * @param {string} att attribute to use from `that` eg `that.att`
 * @param {number} [depth=3] depth of output
 */
export const prState = (that, att = null, depth = 3) => prat(that.state, att, depth, 'state');

/**
 * Print recursive props
 * @param {Component} that JSX class containing a constructor
 * @param {string} att attribute to use from `that` eg `that.att`
 * @param {number} [depth=3] depth of output
 */
export const prProp = (that, att = null, depth = 3) => prat(that.props, att, depth, 'props');

/**
 * Print recursive props AND state in a fragment
 * @param {Component} that JSX class containing a constructor
 * @param {number} [depth=3] depth of output
 */
export const prBoth = (that, depth = 3) => (
  <>
    {prat(that, 'state', depth, 'state')}
    {prat(that, 'props', depth, 'props')}
  </>
);

/**
 *
 * @param {Component} that JSX class containing a constructor
 * @param {string} att attribute to use from `that` eg `that.att`
 * @param {number} [depth=3] depth of output
 */
export const prFrom = (that, att = null, depth = 3) => prat(that, att, depth);

/**
 * Print recursive any data
 * @param {string} name name to show for output
 * @param {*} data anything to output
 * @param {number} [depth=3] depth of output
 * @example
 * // Inside JSX
 * return (<Fragment>{pr('searchTerm', this.props.searchTerm)}</Fragment>);
 *
 * // in a render:
 * return pr('searchTerm', this.props.searchTerm);
 */
export const pr = (name, data, depth = 3) => {
  if (!DEBUG) return null;
  // console.log(name, data);
  return darkPr(name, data, depth);
};

/**
 * Just a debug log with colour and spam
 * @param {string} name
 * @param {*} data
 */
export const clog = (name, ...data) => {
  if (!DEBUG) return null;
  console.log(`%c ${name} `, colorHue(name[0].toUpperCase()), ...data);
  return darkPr(name, data, 10);
};

/**
 * Just a debug log with colour and spam
 * @param {string} name
 * @param {*} data
 */
export const cloggo = (name, ...data) => {
  if (!DEBUG) return null;
  const rep = name[0].toUpperCase();
  const dew = 20 - name.length / 2;
  const hog = dew >= 0 ? rep.repeat(dew) : '';
  console.log(`%c${hog} - ${name} - ${hog}`, consoleColor('info'), ...data);
  return darkPr(name, data, 10);
};

/**
 * PURE DATA component. Renders a box containing data dump.
 * Also shows undefined and function, unlike json.stringify.
 *
 * @param {*} [data=undefined] Anything you want to output.
 * @param {string} [name='Data'] title bar name.
 * @param {boolean} [dark=false] if true, it uses black on white colour style.
 * @param {string} [depth='0'] Depth of object output, eg. '1' will show first.
 *    level and '...' after that.
 * @param {boolean} [closed=false] Start closed?
 * @param {boolean} [fly=false] draggable window?
 * @param {boolean} [keyQuotes=false] Keep double quotes around keys in objects?
 * @param {boolean} [doubleQuotes=false] Use double quotes around strings, otherwise single.
 *
 * @example
 * // Flying dark box starting closed
 * <Pr data={foo} closed dark fly />
 *
 * // Inline white starting open
 * <Pr data={foo} />
 *
 * // Inline dark starting open titled Foobar
 * <Pr data={foo} name="Foobar" dark />
 *
 * // Component state closed, flying, dark and limit to 3 depth
 * <Pr data={this} name={`${this.constructor.name}.state`} depth="3" dark closed fly />
 */
class Pr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: !props.closed,
      fly: props.fly,
      dead: false,
      depth: props.depth,
    };
  }

  /**
   * Returns a string like stringify except truncates at a certain depth
   * @param {object} object Javascript object
   * @param {number} [depthLimit = 0] depth limit
   * @param {number} [spaces = 2] number of spaces for stringify
   * @returns {string} truncated json
   */
  truncatedStringify = (object, depthLimit = 0, spaces = 2) => {
    /**
     * @internal
     */
    const recursiveTrim = (value, currDepth) => {
      if (depthLimit > 0) {
        let result = {};
        let isArray = false;
        if (value !== null && typeof value.length === 'number') {
          result = [];
          isArray = true;
        }
        if (currDepth < depthLimit) {
          if (typeof value === 'object' && value !== null) {
            let recursiveResult;
            Object.keys(value).forEach((k) => {
              const child = value[k];
              if (typeof child === 'object') {
                recursiveResult = recursiveTrim(child, currDepth + 1);
                if (isArray) {
                  // if (result[0] !== 'array ${}') {
                  result.push(recursiveResult);
                  // }
                } else {
                  result[k] = recursiveResult;
                }
              } else if (isArray) {
                result.push(child);
              } else {
                result[k] = child;
              }
            });
            return result;
          }
          return value;
        } if (currDepth === depthLimit && value === null) {
          return null;
        }
        if (isArray) {
          return '{[]}';
        }
        if (typeof value === 'object' && value !== null) {
          return '{{}}';
        }
        return '{}';
      }
      return value;
    };

    // Stringify highlighting functions and undefined and replace "${}" with ...
    // Doesn't work properly with "arrays" (doesn't draw key)
    return JSON.stringify(
      object,
      (key, value) => {
        if (typeof value === 'undefined') {
          return '{undefined}';
        }
        if (typeof value === 'function') {
          return '{func}';
        }
        if (typeof value === 'object') {
          return recursiveTrim(value, 0);
        }
        return value;
      },
      spaces,
    )
      .replace(/"\{\}"/g, '…')
      .replace(/"\{\{\}\}"/g, '{…}')
      .replace(/"\{\[\]\}"/g, '[…]');
  };

  /**
   * Pretty-print json to HTML or terminal
   * @param {*} json string of json or actual json
   * @param {object} options Object of key value options:
   * - `options.keyQuotes` : if false, json key strings will not have double quotes. default `false`.
   * - `options.doubleQuotes` : if false, string values will use double quotes. default `false`.
   */
  styleJson = (json, { keyQuotes = false, doubleQuotes = false }) => {
    const patternRegex = /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g;
    let jsonString = json;
    if (typeof json === 'undefined') {
      return 'undefined';
    }
    if (typeof json !== 'string') {
      jsonString = this.truncatedStringify(json, parseInt(this.state.depth, 10));
    }
    jsonString = jsonString
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
    return jsonString.replace(patternRegex, (match) => {
      let className = 'number';
      let output = match;
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          className = 'key';
          if (!keyQuotes) {
            output = match.replace(/"/g, '');
          }
        } else if (match === '"{func}"') {
          className = 'function';
          output = 'function';
        } else if (match === '"{undefined}"') {
          className = 'undefined';
          output = 'undefined';
        } else {
          className = 'string';
          if (!doubleQuotes) {
            output = match.replace(/"/g, "'");
          }
        }
      } else if (/true/.test(match)) {
        className = 'boolean true';
      } else if (/false/.test(match)) {
        className = 'boolean false';
      } else if (/null/.test(match)) {
        className = 'null';
      }

      // return <span className={className}>match</span>;
      return `<span class="${className}">${output}</span>`;
    });
  };

  /**
   * Click minimize/maximize
   */
  clickBlock = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  /**
   * Click pin/fly
   */
  clickFly = () => {
    this.setState((prevState) => ({ fly: !prevState.fly }));
  };

  /**
   * Click close
   */
  clickClose = () => {
    this.setState({ dead: true });
  };

  /**
   * Click depth increase
   */
  increaseDepth = () => {
    this.setState((prevState) => ({ depth: prevState.depth >= 100 ? 100 : prevState.depth + 1 }));
  };

  /**
   * Click depth decrease
   */
  decreaseDepth = () => {
    this.setState((prevState) => ({ depth: prevState.depth <= 1 ? 1 : prevState.depth - 1 }));
  };

  /**
   * Returns a print_r array converting HTML tags to entities
   * for nicer display.
   * @param {*} data
   * @returns {string}
   */
  htmlOutput = (data) => {
    if (typeof data === 'string') {
      return `'${data}'`;
    } if (typeof data === 'object') {
      return this.styleJson(data, {
        keyQuotes: this.props.keyQuotes,
        doubleQuotes: this.props.doubleQuotes,
      });
    } if (typeof data === 'boolean') {
      return data === false ? 'false' : 'true';
    }
    // probs a number at this stage
    return data;
  };

  // Render <Pr />
  render() {
    // return nothing if debug isn't on, or if she's dead
    const { dead } = this.state;
    if (!DEBUG || dead) return null;

    //
    const { data, name, dark } = this.props;
    const { open, fly } = this.state;
    const displayType = data instanceof Array ? `object (array[${data.length}])` : typeof data;
    const output = (
      <div className={classnames('pr', { fly, open })}>
        <div className="item">
          <div className="heading">
            {fly && <Icon i="ellipsis-v" fw spaceAfter />}
            &nbsp;
            <span
              tabIndex={-1}
              onKeyDown={noop}
              role="link"
              className="name"
              onClick={fly ? noop : this.clickBlock}
            >
              {name}
            </span>
            <span className="type">
              &nbsp;
              {displayType}
            </span>
            <div className="controls btn-group pull-right">
              <button
                tabIndex={-1}
                onKeyDown={noop}
                className="btn btn-sm btn-secondary"
                onClick={this.decreaseDepth}
              >
                <Icon i="outdent" />
              </button>
              <button
                tabIndex={-1}
                onKeyDown={noop}
                className="btn btn-sm btn-secondary"
                onClick={this.increaseDepth}
              >
                <Icon i="indent" />
              </button>

              <button
                tabIndex={-1}
                onKeyDown={noop}
                className="btn btn-sm btn-secondary"
                onClick={this.clickFly}
              >
                <Icon i={fly ? 'thumb-tack' : 'window-restore'} />
              </button>
              {fly && (
                <button
                  tabIndex={-1}
                  onKeyDown={noop}
                  className="btn btn-sm btn-secondary"
                  onClick={this.clickBlock}
                >
                  <Icon i={open ? 'chevron-up' : 'chevron-down'} />
                </button>
              )}
              <button
                tabIndex={-1}
                onKeyDown={noop}
                className="btn btn-sm btn-secondary"
                onClick={this.clickClose}
              >
                <Icon i="times" />
              </button>
            </div>
          </div>
          <div className={classnames('accordion', { open })}>
            <pre
              className={classnames('html', { dark })}
              key={name}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: this.htmlOutput(data) }}
            />
          </div>
        </div>
      </div>
    );

    // Returns <Pr />, if fly mode, return around Draggable wrapper
    return fly ? <Draggable handle=".heading">{output}</Draggable> : output;
  }
}

Pr.defaultProps = {
  data: undefined,
  name: 'Data',
  dark: false,
  depth: 0,
  closed: false,
  fly: false,
  keyQuotes: false,
  doubleQuotes: false,
};

Pr.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
    PropTypes.array,
    PropTypes.shape({}),
    PropTypes.number,
  ]),
  name: PropTypes.string,
  dark: PropTypes.bool,
  depth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  closed: PropTypes.bool,
  fly: PropTypes.bool,
  keyQuotes: PropTypes.bool,
  doubleQuotes: PropTypes.bool,
};

export default Pr;
