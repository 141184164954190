import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { DataTablePageProps } from '../../types/data-table-page.props';
import { ModalContext } from '../modals/modal-context';
import { ICommissionPaymentBatchRecord } from '../../types/finance/commission-payment-batch.record.interface';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { NewRecordModalResult } from '../../types/modal/modal-result';

export type CommissionPaymentBatchesDataTablePageProps = DataTablePageProps;

/**
 * Display the "CommissionPaymentBatches" data table as a whole page
 */
export const CommissionPaymentBatchesDataTablePage: React.FC<CommissionPaymentBatchesDataTablePageProps> = ({
  baseFilters,
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const history = useHistory();
  const { showModal } = useContext(ModalContext);

  const actionHandlers = {
    create: () => {
      showModal<NewRecordModalResult<ICommissionPaymentBatchRecord>>(MODAL_TYPE.NEW_COMMISSION_PAYMENT_BATCH, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/finance/sales-commission/payment-batches/${modalResult.newRecord.id}`);
        },
      });
    },
  };


  return (
    <DataTablePage
      title="Commission Payment Batches"
      tableIdentifier={TABLE_IDENTIFIER.COMMISSION_PAYMENT_BATCHES_TABLE}
      actionHandlers={actionHandlers}
      baseFilters={baseFilters}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      {children}
    </DataTablePage>
  );
};
