export const INVOICE_REQUEST_TYPE: Record<string, number> = {
  PROJECT: 1,
  CONTRACT: 2,
  JOB: 3,
} as const;
export type INVOICE_REQUEST_TYPE = typeof INVOICE_REQUEST_TYPE;
export type AN_INVOICE_REQUEST_TYPE = INVOICE_REQUEST_TYPE[keyof INVOICE_REQUEST_TYPE];

export const INVOICE_REQUEST_TYPE_NAMES: {[K in AN_INVOICE_REQUEST_TYPE]: string } = {
  [INVOICE_REQUEST_TYPE.PROJECT]: 'Project',
  [INVOICE_REQUEST_TYPE.CONTRACT]: 'Contract',
  [INVOICE_REQUEST_TYPE.JOB]: 'Service Job',
} as const;
