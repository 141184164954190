import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { ButtonGroup, Button } from 'reactstrap';

import { IColumnFilter, IColumnSort } from '../../../types/portal-data-table/in-memory-table-types';

import Icon from '../../layout-helpers/icon';
import { DebouncedInput } from '../../debounced-input/debounced-input';

import { TIMEOUT_TYPING_DEBOUNCE } from '../../../utils/constants';
import { HTML_INPUT_TYPE } from '../../../constants/html-input-type.const';

interface ITableSearchControlsProps {
  // Formatting
  color?: string,
  activeColor?: string,

  // View State
  isColumnFilterDrawerVisible?: boolean,

  // Search, Sort and Filter State
  searchTerm?: null | string,
  sortedColumns?: IColumnSort[],
  filteredColumns?: IColumnFilter[],

  // Actions and Events
  onClearSortedColumns?: (pushToHistory: boolean) => void,
  onToggleColumnFilterDrawer?: () => void,
  onResetTableSettings?: (pushToHistory: boolean, replaceInHistory?: boolean) => void,
  onSearchTermChange?: (newSearchTerm: null | string, pushToHistory?: boolean) => void,
}

/**
 * Table Controls component
 */
export const TableSearchControls: React.FC<ITableSearchControlsProps> = (props) => {
  const {
    color = 'primary',
    activeColor = 'success',
    isColumnFilterDrawerVisible = false,
    searchTerm = '',
    sortedColumns = [],
    filteredColumns = [],
    onToggleColumnFilterDrawer,
    onResetTableSettings,
    onClearSortedColumns,
    onSearchTermChange,
  } = props;

  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchInputValue = useMemo<string>(() => searchTerm ?? '', [searchTerm]);

  const searchDebounceTimeout = useRef<null | ReturnType<typeof setTimeout>>(null);

  const isSearched = useMemo(() => !!((searchTerm && searchTerm.trim() !== '')), [searchTerm]);
  const isSorted = useMemo(() => (sortedColumns && sortedColumns.length > 0), [sortedColumns]);
  const isFiltered = useMemo(() => (filteredColumns && filteredColumns.length > 0), [filteredColumns]);


  /**
   * Fired when the search term in the input changes as a result of the user typing
   */
  const handleSearchInputChange = useCallback((newValue: string) => {
    if (onSearchTermChange) {
      onSearchTermChange(newValue, false);
    }
  }, [onSearchTermChange]);


  /**
   * Fired when the user focuses the search box
   */
  const handleSearchInputFocus = useCallback(() => {
    // Select the text inside the search input
    if (searchInputRef.current) {
      searchInputRef.current.select();
    }
  }, []);


  /**
   * Fired when the user toggles the sort button to clear any active sorts on the table
   */
  const handleClearSortedColumns = useCallback(() => {
    if (onClearSortedColumns && sortedColumns && sortedColumns.length > 0) {
      onClearSortedColumns(true);
    }
  }, [onClearSortedColumns, sortedColumns]);


  /**
   * Fired on Unmount to clear the debounce timeout
   */
  useEffect(() => () => {
    if (searchDebounceTimeout.current !== null) {
      clearTimeout(searchDebounceTimeout.current);
      searchDebounceTimeout.current = null;
    }
  }, []);


  // Render
  return (
    <ButtonGroup className="table-search-controls">
      {onSearchTermChange && (
        <div className="data-tables-filter">
          {/* Search Input */}
          <DebouncedInput
            innerRef={searchInputRef}
            value={searchInputValue}
            type={HTML_INPUT_TYPE.SEARCH}
            className={`form-control search-${color}`}
            placeholder="Search..."
            debounceDuration={TIMEOUT_TYPING_DEBOUNCE}

            onChange={handleSearchInputChange}
            onFocus={handleSearchInputFocus}
          />
        </div>
      )}

      {/* Search Button */}
      {onSearchTermChange && (
        <Button color={isSearched ? activeColor : color} title="Search" active={isSearched}>
          <Icon i="search" />
        </Button>
      )}

      {/* Clear Filters Button */}
      {onToggleColumnFilterDrawer && (
        <Button
          color={isFiltered ? activeColor : color}
          className="text-white"
          active={isColumnFilterDrawerVisible}
          onClick={onToggleColumnFilterDrawer}
          onKeyPress={onToggleColumnFilterDrawer}
          title={`${
            isFiltered ? 'Active Filters - click to CLEAR ALL' : ''
          } Show/hide Filter Bar`}
          tabIndex={-1}
        >
          <Icon i="filter" />
        </Button>
      )}

      {/* Clear Sorted Columns Button */}
      {onClearSortedColumns && (
        <Button
          color={isSorted ? activeColor : color}
          active={isSorted}
          onClick={handleClearSortedColumns}
          onKeyPress={handleClearSortedColumns}
          title="Clear all column sorting"
          tabIndex={-1}
        >
          <Icon i="sort-amount-desc" />
        </Button>
      )}

      {/* Reset Table Button */}
      {onResetTableSettings && (
        <Button
          color={color}
          onClick={() => onResetTableSettings(true)}
          onKeyPress={() => onResetTableSettings(true)}
          title="Reset table to defaults"
          tabIndex={-1}
        >
          <Icon i="undo" />
        </Button>
      )}
    </ButtonGroup>
  );
};
