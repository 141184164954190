import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import { Card, Table } from 'reactstrap';
import { IAPIAction } from '../../../types/api-action.interface';
import { IExpenseClaimItemRecord } from '../../../types/expense-claim/expense-claim-item.record.interface';
import Icon from '../../layout-helpers/icon';
import { ImageViewer } from '../../layout-helpers/image-viewer';
import { PDFViewer } from '../../layout-helpers/pdf-viewer';
import { IExpenseClaimFileRecord } from '../../../types/expense-claim/expense-claim-file.record.interface';
import { isImageMimeType } from '../../../constants/mime-type.const';
import { usePreviousValue } from '../../../react-hooks/use-previous-value.hook';
import { SERObject } from '../../../helpers/ser-object.helper';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import { APIContext } from '../../providers/api-provider';
import { getAvailableActions } from '../../../helpers/get-available-actions.helper';
import { API_ACTION } from '../../../constants/api-action.const';
import { ClaimItemSummary } from './claim-item-summary-row';

interface IClaimItemsWidgetProps {
  widgetData: IExpenseClaimItemRecord[],
  widgetDataChecksum: number,
}


export const ClaimItemsWidget: React.FC<IClaimItemsWidgetProps> = (props) => {
  const {
    widgetData = [],
    widgetDataChecksum,
  } = props;


  const { apiFetch } = useContext(APIContext);

  const downloadAbortController = useRef<null | AbortController>(null);

  // Keep track of the old checksum value
  const oldWidgetDataChecksum = usePreviousValue(widgetDataChecksum);

  // Keep a secondary list of image only items that is used to populate the lightbox viewer
  const [imageItems, setImageItems] = useState<IExpenseClaimFileRecord[]>([]);

  // This is the item that the PDF viewer is currently viewing
  const [viewingPDFItem, setViewingPDFItem] = useState<null | IExpenseClaimFileRecord>(null);

  // Whether the image viewer is visible
  const [lightboxVisible, setLightboxVisible] = useState<boolean>(false);
  const [lightboxImageId, setLightboxImageId] = useState<null | IExpenseClaimFileRecord['id']>(null);


  /**
   * When the widgetDataChecksum changes, we need to update the image items in our image array
   */
  useEffect(() => {
    if (oldWidgetDataChecksum !== widgetDataChecksum) {
      const allFiles: IExpenseClaimFileRecord[] = [];
      // Update the image items
      widgetData.map((item: IExpenseClaimItemRecord) => {
        if (item.receipt_file) allFiles.push(item.receipt_file);
        return true;
      });
      setImageItems(allFiles.filter((widgetDataItem) => isImageMimeType(widgetDataItem.content_type)));
    }
  }, [widgetDataChecksum, oldWidgetDataChecksum, widgetData]);


  /**
   * Call this to get the transport Url for downloading the file
   */
  const getDownloadTransportUrl = useCallback(async (claimId: number, fileRecord: IExpenseClaimFileRecord): Promise<SERObject<string>> => {
    // The result will be a Success/Error/Response (SER) object
    const result = new SERObject<string>(false);

    // Cancel any existing requests and create a new Abort signal
    if (downloadAbortController.current) {
      downloadAbortController.current.abort();
    }
    downloadAbortController.current = apiAborter();

    try {
      // Use the incoming form props to create the final api route
      const finalAPIRoute = `/expenseclaim/${claimId}/file/${fileRecord.id}`;

      // First get the available actions for the file so we can get the Download action
      const getAvailableActionsResult = await getAvailableActions(finalAPIRoute, apiFetch);

      if (getAvailableActionsResult.success && getAvailableActionsResult.result) {
        // Look for the Download action
        const downloadAction: undefined | IAPIAction = getAvailableActionsResult.result[API_ACTION.DOWNLOAD];
        if (downloadAction) {
          // Request the download using the action
          const response = await apiFetch(
            downloadAction.link,
            {
              method: downloadAction.method,
              signal: downloadAbortController.current.signal,
            },
          );

          if (response.success && !!response.body?.transport?.url) {
            downloadAbortController.current = null;

            result.success = true;
            result.result = response.body.transport.url;
          } else if (!response.aborted) {
            downloadAbortController.current = null;
            result.error = `Failed to get the download transport: ${response.body?.message ?? response.body ?? 'unknown error'}`;
          }
        } else {
          result.error = 'There is no download capability provided on the target file!';
        }
      } else {
        result.error = getAvailableActionsResult.error || 'An unexpected error occurred.';
      }
    } catch (err) {
      result.error = `Thumbnail download failed due to an unexpected error: ${err}`;
    }

    if (result.error) {
      console.error(result.error);
    }

    return result;
  }, [apiFetch]);


  /**
   * Fired when the user clicks a collection item
   */
  const handleCollectionItemClick = useCallback((claimId: number, itemData: IExpenseClaimFileRecord) => {
    getDownloadTransportUrl(claimId, itemData)
      .then((response) => {
        if (isImageMimeType(itemData.content_type ?? null)) {
          setImageItems([{
            ...itemData,
            downloadUrl: response.result,
          }]);
          setLightboxImageId(itemData.id);
          setLightboxVisible(true);
        } else {
          setViewingPDFItem({
            ...itemData,
            downloadUrl: response.result,
          });
        }
      });
  }, [getDownloadTransportUrl]);


  // render the widget
  return (
    <div className="widget project-items-widget" style={{ overflowX: 'auto' }}>
      <Card>
        <ImageViewer
          images={imageItems}
          visible={lightboxVisible}
          onClose={() => setLightboxVisible(false)}
          viewingId={lightboxImageId}
        />
        <PDFViewer
          closePdfViewer={() => setViewingPDFItem(null)}
          isOpen={!!viewingPDFItem}
          filePath={viewingPDFItem?.downloadUrl ?? ''}
          fileSize={viewingPDFItem?.file_size ?? 0}
          filename={viewingPDFItem?.filename ?? ''}
          comment={viewingPDFItem?.comment ?? undefined}
        />
        <Table className="table table-striped color-table secondary-table" style={{ whiteSpace: 'break-spaces' }}>
          <thead>
            <tr>
              <th>Claim ID</th>
              {/* <th>Claim Name</th> */}
              <th>Name</th>
              <th>Description</th>
              <th>Amount</th>
              <th>Proof</th>
              <th>Type / Acc</th>
              <th>P/S/C | MYOB</th>
              {/* <th>Claim Amount</th> */}
            </tr>
          </thead>
          <tbody>
            {!widgetData && (
              <Icon i="rolling" />
            )}
            {widgetData && widgetData.map((item: IExpenseClaimItemRecord) => (
              <ClaimItemSummary key={item.id} item={item} handleCollectionItemClick={handleCollectionItemClick} />
            ))}
          </tbody>
        </Table>
        {/* <pre>{JSON.stringify(widgetData, null, 2)}</pre> */}
      </Card>
    </div>
  );
};
