import {
  projectDesignTypeOptions,
  projectPhaseOptions,
  leadSourceOptions,
  projectFreightTypeOptions,
} from '../utils/constants';
import { PERMISSION } from '../constants/permissions.const';
import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { startCase } from '../utils/helpers';
import { fullName } from '../helpers/full-name.helper';
import { MODAL_TYPE } from '../constants/modal-type.const';
import { PROJECT_STATUS, PROJECT_STATUS_NAME_MAP } from '../constants/project-status.const';
import { mapSelectInputOptions } from '../helpers/map-select.helper';
import { ICON } from '../constants/icon.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { DATA_TABLE_FLAG } from '../constants/data-table-flag.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';
import { THEME_COLOR } from '../constants/theme-color.const';


const maintenanceToolTip = [
  'Maintenance is important so consider your selection carefully.<br/><br/>',
  '<ul>',
  '<li><strong>Maintenance Included</strong>: This project includes a maintenance agreement, contract or subscription</li>',
  '<li><strong>Maintenance Only</strong>: This project was created to solely represent a maintenance agreement, contract or subscription</li>',
  '<li><strong>Has No Maintenance</strong>: This project does not include maintenance or subscriptions of any kind</li>',
  '<li><strong>On Existing Agreement</strong>: This project has maintenance covered under another project (use the notes to specify details)</li>',
  // eslint-disable-next-line max-len
  '<li><strong>Still Negotiating Maintenance</strong>: Select this if maintenance or subscriptions are pending. You will need to select a firm maintenance option prior to project completion</li>',
  '</ul>',
].join('');

/**
 * Table Definition for Project Table
 *
 * @todo all of the 'input-options' settings are getting stored in the redux store which
 *       is quite a bad choice.
 */
const projectTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Project',
  initialFormFocusFieldName: 'name',
  baseRoute: '/project',
  availableFlags: [DATA_TABLE_FLAG.ONLY_MINE],
  baseQueryString: [
    'with[]=phase',
    'with[]=status',
    'with[]=state:id,acronym',
    'with[]=client:id,name,quickbooks_id',
    'with[]=contact:id,first,middle,last',
    'with[]=partnerProgram:id,name',
    'with[]=projectMaintenanceType:id,name',
    'with[]=owner:id,name,initials',
    'with[]=projectManager:project_id,project_manager_id,name',
    'with[]=activatedBy:id,name',
    'with[]=projectFreightType:id,name',
    'with[]=maintenanceContract:id,type_id,status_id,number',
    'with_count[]=forecasts',
    'with_count[]=orders',
    'with_count[]=invoices',
    'with_count[]=projectResources',
    'with_count[]=notes',
    'with_count[]=projectContacts',
    'with_count[]=projectLocations',
    'with_count[]=projectCompanies',
    'with_count[]=invoiceRequests',
  ].join('&'),

  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      // IMPORTANT: make sure these fields are configured with their "visible" and "order" property to reflect this default preset
      fields: [
        'project_number',
        'name',
        'value',
        'likelihood',
        'state',
        'owner',
        'project_manager',
        'client',
        'contact',
        'status',
        'phase',
        'source_id',
        'source_comment',
        'supply_only_job',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: ['project_number', 'value', 'status', 'client'],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'project_number',
        'owner',
        'project_manager',
        'state',
        'client',
        'contact',
        'name',
        'value',
        'status',
        'phase',
        'partner_program',
        'external_design',
        'likelihood',
        'tender',
        'id',
        'quickbooks_subcustomer_id',
        'user_audit_created_at',
        'user_audit_updated_at',
        'project_maintenance_type',
        'maintenance_contract',
        'proposal_due',
        'project_start',
        'supply_only_job',
        'count_of_forecasts',
        'count_of_orders',
        'count_of_invoices',
        'count_of_projectResources',
        'count_of_notes',
        'count_of_projectContacts',
        'count_of_projectLocations',
        'count_of_projectCompanies',
      ],
    },
    {
      name: 'basic',
      title: 'Basic Fields',
      fields: [
        'project_number',
        'name',
        'value',
        'status',
        'phase',
        'client',
        'owner',
      ],
    },
    {
      name: 'sales-pipeline',
      title: 'Sales Pipeline',
      fields: [
        'project_number',
        'proposal_due',
        'project_start',
        'status',
        'state',
        'owner',
        'client',
        'likelihood',
        'value',
        'contact',
        'name',
      ],
    },
  ],

  /**
   * Definition of Columns for this table
   * Includes settings for APIPolyForm fields
   */
  columns: [
    {
      name: 'id',
      title: 'ID',
      description: 'Project ID',
      format: COLUMN_FORMAT.ID_LINK,
      linkId: 'id',
      linkRoute: '/crm/projects',
      visible: false,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'project_number',
      title: 'P-Number',
      description: 'Project Unique Identifier',
      format: COLUMN_FORMAT.P_NUMBER,
      linkId: 'id',
      linkRoute: '/crm/projects',
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      sortColumn: 'id',
      order: 1,
      formOrder: 10,
    },
    {
      name: 'name',
      title: 'Name',
      description: 'Project Working Title',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      required: true,
      order: 2,
      formOrder: 20,
    },
    {
      name: 'state',
      title: 'State',
      format: COLUMN_FORMAT.OBJECT,
      description: 'Where is the project taking place?',
      object: {
        sourceField: 'state',
        key: 'acronym',
        format: COLUMN_FORMAT.SHORT_TEXT,
        listSource: 'state',
      },
      formFieldType: FORM_FIELD_TYPE.STATE_PICKER,
      formSaveField: 'state_id',
      visible: true,
      showInForm: true,
      required: true,
      order: 5,
      formOrder: 30,
    },
    {
      name: 'owner',
      title: 'Salesperson',
      description: 'The Ci team member responsible for this project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'owner',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
      formSaveField: 'owner_id',
      sortColumn: 'owner.name',
      visible: true,
      showInForm: true,
      required: true,
      order: 6,
      formOrder: 40,
    },
    {
      name: 'client',
      title: 'Client',
      description: 'The end user client for the project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'client',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/crm/companies',
        listSource: 'company',
      },
      formFieldType: FORM_FIELD_TYPE.COMPANY_PICKER,
      formSaveField: 'client_id',
      visible: true,
      showInForm: true,
      isCreatable: true,
      order: 8,
      formOrder: 50,
    },
    {
      name: 'contact',
      title: 'Contact',
      description: 'The client contact person',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'contact',
        key: 'id',
        getValue: (value, row) => fullName(row),
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/crm/contacts',
        listSource: 'person',
      },
      sortColumn: 'contact.first',
      formFieldType: FORM_FIELD_TYPE.CONTACT_PICKER,
      formSaveField: 'contact_id',
      isCreatable: true,
      getNewItemData: (inputValue, formData) => ({
        company_id: formData.client_id,
        company: formData.client,
      }),
      showInForm: true,
      order: 9,
      formOrder: 60,
    },
    {
      title: 'Created',
      name: 'user_audit_created_at',
      sortColumn: 'created_at',
      formSaveField: 'created_at',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'created_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: false,
      showInForm: false,
      order: 16,
      formOrder: 70,
    },
    {
      title: 'Updated',
      name: 'user_audit_updated_at',
      sortColumn: 'updated_at',
      formSaveField: 'updated_at',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'updated_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      visible: false,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      showInForm: false,
      order: 15,
      formOrder: 80,
    },
    {
      name: 'value',
      title: 'Value',
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
      allowNegative: true,
      format: COLUMN_FORMAT.CURRENCY,
      description: 'Project Total Value',
      visible: true,
      order: 3,
      formOrder: 90,
    },
    {
      name: 'status',
      title: 'Status',
      description: 'Project Status',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'status',
        key: 'name',
        format: COLUMN_FORMAT.STATUS_BADGE,
        listSource: 'projectStatus',
      },
      formFieldType: FORM_FIELD_TYPE.PROJECT_STATUS_PICKER,
      formSaveField: 'status_id',
      visible: true,
      showInForm: true,
      required: true,
      isClearable: false,
      order: 11,
      formOrder: 100,
    },
    {
      name: 'phase',
      title: 'Phase',
      format: COLUMN_FORMAT.OBJECT,
      description: 'Current Phase of Project',
      object: {
        sourceField: 'phase',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
        listSource: 'projectPhase',
      },
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: projectPhaseOptions,
      formSaveField: 'phase_id',
      visible: true,
      order: 12,
      formOrder: 110,
    },
    {
      name: 'likelihood',
      title: 'Likelihood',
      description: 'Probability out of 10 of project going ahead',
      format: COLUMN_FORMAT.LIKELIHOOD,
      formFieldType: FORM_FIELD_TYPE.LIKELIHOOD,
      visible: true,
      order: 4,
      formOrder: 120,
    },
    {
      name: 'partner_program',
      title: 'Partner Program',
      description: '',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'partner_program',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
        listSource: 'partnerProgram',
      },
      formFieldType: FORM_FIELD_TYPE.PARTNER_PROGRAM_PICKER,
      formSaveField: 'partner_program_id',
      visible: false,
      showInForm: true,
      order: 8,
      formOrder: 130,
    },
    {
      name: 'project_maintenance_type',
      title: 'Maintenance',
      format: COLUMN_FORMAT.OBJECT,
      description: 'The type of maintenance for this project',
      formFieldToolTip: maintenanceToolTip,
      object: {
        sourceField: 'project_maintenance_type',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
        listSource: 'projectMaintenanceType',
        getValue: (value, row) => startCase(row.name),
      },
      formFieldType: FORM_FIELD_TYPE.PROJECT_MAINTENANCE_TYPE_PICKER,
      formSaveField: 'project_maintenance_type_id',
      visible: false,
      showInForm: true,
      required: true,
      order: 18,
      formOrder: 140,
    },
    {
      name: 'maintenance_contract',
      title: 'Maintenance Contract',
      description: '',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'maintenance_contract',
        key: 'number',
        format: COLUMN_FORMAT.NAME_LINK_SHORT,
        linkRoute: '/service-and-support/contracts',
      },
      formFieldType: FORM_FIELD_TYPE.CONTRACT_PICKER,
      formSaveField: 'maintenance_contract_id',
      visible: false,
      showInForm: true,
      order: 19,
      formOrder: 142,
    },
    {
      name: 'external_design',
      title: 'Project Design',
      format: COLUMN_FORMAT.ID_NAME_OPTIONS,
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: projectDesignTypeOptions,
      visible: false,
      showInForm: true,
      required: true,
      columnWidth: '18em',
      order: 14,
      formOrder: 150,
    },
    {
      name: 'tender',
      title: 'Tender',
      description: 'Project is part of a tender',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: false,
      showInForm: true,
      required: true,
      order: 13,
      formOrder: 170,
    },
    {
      name: 'quickbooks_subcustomer_id',
      title: 'QuickBooks Status',
      description: 'Status of the Project in Quickbooks',
      format: COLUMN_FORMAT.BUTTON_LINK,
      linkId: 'quickbooks_subcustomer_id',
      externalLink: (row) => `${process.env.REACT_APP_QUICKBOOKS_APP_ENDPOINT}app/customerdetail?nameId=${row.quickbooks_subcustomer_id}`,
      buttonLabel: 'View in QuickBooks',
      visible: false,
      showInForm: false,
      isReadOnly: true,
      order: 14,
      formOrder: 180,
    },
    {
      name: 'project_manager',
      title: 'Project Manager',
      description: 'The assigned project manager resource',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'project_manager',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'project_manager_id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      formSaveField: 'projectManager.project_manager_id',
      formFieldToolTip: 'The project manager can be assigned by adding a "Project Manager" resource to this project.',
      sortColumn: 'projectManager.name',
      visible: true,
      showInForm: true,
      order: 7,
      formOrder: 45,
    },
    {
      title: 'Proposal Due',
      name: 'proposal_due',
      description: 'When the proposal or revision is due to the client.',
      format: COLUMN_FORMAT.DATE,
      visible: false,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      showInForm: true,
      order: 20,
      formOrder: 200,
    },
    {
      title: 'Expected Start Date',
      name: 'project_start',
      description: 'Commencement date, matching the Project Total forecast.',
      formFieldToolTip: "The start date can also be edited by changing the date on to the 'Project Total' forecast line.",
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: false,
      showInForm: true,
      order: 21,
      formOrder: 210,
    },
    {
      name: 'source_id',
      title: 'Lead Source',
      description: 'What was the lead source for the project?',
      format: COLUMN_FORMAT.ID_NAME_OPTIONS,
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: leadSourceOptions,
      visible: true,
      showInForm: true,
      required: true,
      order: 22,
      formOrder: 220,
    },
    {
      name: 'source_comment',
      title: 'Source Comment',
      description: 'Additional Source information.',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      formRequired: false,
      order: 23,
      formOrder: 225,
    },
    {
      name: 'supply_only_job',
      title: 'Supply Only Job',
      description: 'Is the job only for supply of items or services? (ie. box drops or licences).',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: true,
      formRequired: true,
      showInForm: true,
      order: 24,
      formOrder: 230,
      required: false,
    },
    {
      name: 'project_freight_type_id',
      title: 'Freight Type',
      format: COLUMN_FORMAT.ID_NAME_OPTIONS,
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: projectFreightTypeOptions,
      visible: false,
      required: true,
      columnWidth: '18em',
      order: 25,
      formOrder: 235,
    },
    {
      name: 'count_of_forecasts',
      title: 'Count of Forecasts',
      description: 'The count of forecasts assigned to this project',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 240,
      formOrder: 240,
      showInForm: false,
      isReadOnly: true,
    }, {
      name: 'count_of_orders',
      title: 'Count of Client Orders',
      description: 'The count of client orders assigned to this project',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 250,
      formOrder: 250,
      showInForm: false,
      isReadOnly: true,
    }, {
      name: 'count_of_invoices',
      title: 'Count of Invoices',
      description: 'The count of invoices assigned to this project',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 260,
      formOrder: 260,
      showInForm: false,
      isReadOnly: true,
    }, {
      name: 'count_of_projectResources',
      title: 'Count of Resources',
      description: 'The count of resources assigned to this project',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 270,
      formOrder: 270,
      showInForm: false,
      isReadOnly: true,
    }, {
      name: 'count_of_notes',
      title: 'Count of Notes',
      description: 'The count of notes assigned to this project',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 280,
      formOrder: 280,
      showInForm: false,
      isReadOnly: true,
    }, {
      name: 'count_of_projectContacts',
      title: 'Count of Contacts',
      description: 'The count of contacts assigned to this project',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 290,
      formOrder: 290,
      showInForm: false,
      isReadOnly: true,
    }, {
      name: 'count_of_projectLocations',
      title: 'Count of Locations',
      description: 'The count of locations assigned to this project',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 300,
      formOrder: 300,
      showInForm: false,
      isReadOnly: true,
    }, {
      name: 'count_of_projectCompanies',
      title: 'Count of Companies',
      description: 'The count of companies assigned to this project',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 310,
      formOrder: 310,
      showInForm: false,
      isReadOnly: true,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Project Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
      apiQuery: [
        'with[]=phase',
        'with[]=status',
        'with[]=partnerProgram:id,name',
        'with[]=state:id,acronym',
        'with[]=client:id,name',
        'with[]=contact:id,first,middle,last',
        'with[]=owner:id,name,initials',
      ].join('&'),
    },
    {
      name: 'drive',
      title: 'Drive',
      description: 'Project Documents and Files',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_DRIVE,
    },
    {
      name: 'forecasting',
      title: 'Forecasting',
      description: 'Project Forecasting',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_FORECASTS,
      apiRoute: '/forecast',
      apiQuery: [
        'with[]=type',
        'pagelength=0',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'count_of_forecasts',
          badgeColor: THEME_COLOR.DARK,
          // Take out the project forecast total row
          onCalculateValue: (value) => (value ? value - 1 : 0),
        },
      ],
    },
    {
      name: 'clientorders',
      title: 'Client Orders',
      description: 'Client Orders',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_CLIENT_ORDERS,
      isVisible: (rowData) => [PROJECT_STATUS.LEAD].indexOf(rowData.status_id) === -1,
      apiRoute: '/order',
      apiQuery: [
        'with[]=activatingPurchaseOrderProjectFile',
        'with[]=proposalProjectFile',
        'pagelength=0',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'count_of_orders',
          badgeColor: THEME_COLOR.DARK,
        },
      ],
    },
    {
      name: 'invoices',
      title: 'Invoices',
      description: 'Client Invoices',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_INVOICES,
      isVisible: (rowData) => [PROJECT_STATUS.LEAD, PROJECT_STATUS.PROPOSAL].indexOf(rowData.status_id) === -1,
      apiRoute: '/invoice',
      apiQuery: [
        'with[]=type:id,name',
        'pagelength=0',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'count_of_invoices',
          badgeColor: THEME_COLOR.DARK,
        },
      ],
    },
    {
      name: 'resources',
      title: 'Resources',
      description: 'Project Resources',
      apiRoute: '/resource',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_RESOURCES,
      apiQuery: [
        'with[]=user:id,name',
        'with[]=role:id,name',
        'pagelength=0',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'count_of_projectResources',
          badgeColor: THEME_COLOR.DARK,
        },
      ],
    },
    {
      name: 'dtools',
      title: 'Dtools Projects',
      description: 'Matching Dtools Projects',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_DTOOLS_PROJECT,
      apiRoute: '/dtoolslink',
    },
    {
      name: 'myoborders',
      title: 'MYOB Orders',
      description: 'MYOB Purchase Orders',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_MYOB_ORDERS,
      isVisible: (rowData) => [PROJECT_STATUS.LEAD].indexOf(rowData.status_id) === -1,
      apiRoute: '/dtoolslink',
    },
    {
      name: 'accounting',
      title: 'Accounting',
      description: 'Accounting Info',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_ACCOUNTING,
      isVisible: (rowData) => [PROJECT_STATUS.LEAD].indexOf(rowData.status_id) === -1,
      apiRoute: '/quickbookslink',
      apiQuery: 'pagelength=20',
      permissions: PERMISSION.REPORT_PROJECT_PROFITABILITY,
    },
    {
      name: 'notes',
      title: 'Notes',
      description: 'Project Notes',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_NOTES,
      apiRoute: '/note',
      apiQuery: [
        'with[]=createdBy:id,name',
        'with[]=type:id,name',
        'pagelength=10&sort[0][field]=id',
        'sort[0][direction]=desc',
      ].join('&'),
      autoLoadAllData: false,
      badgeCountFields: [
        {
          fieldName: 'count_of_notes',
          badgeColor: THEME_COLOR.PRIMARY,
        },
      ],
    },
    {
      name: 'contacts',
      title: 'Contacts',
      description: 'Project Contacts',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_CONTACTS,
      apiRoute: '/contact',
      // gets `project_contacts`, joins on `people` as `item.person`
      // also gets `person.company`
      apiQuery: [
        'with[]=person',
        'with[]=type',
        'with[]=person.company:id,name',
        'pagelength=20',
      ].join('&'),
      autoLoadAllData: false,
      badgeCountFields: [
        {
          fieldName: 'count_of_projectContacts',
          badgeColor: THEME_COLOR.DARK,
        },
      ],
    },
    {
      name: 'projectLocations',
      title: 'Locations',
      description: 'Project Locations',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_LOCATIONS,
      apiRoute: '/location',
      apiQuery: ['with[]=companyLocation:id,name,path,type_id,company_id', 'with[]=projectManager:id,name', 'pagelength=100'].join('&'),
      autoLoadAllData: false,
      badgeCountFields: [
        {
          fieldName: 'count_of_projectLocations',
          badgeColor: THEME_COLOR.DARK,
        },
      ],
    },
    {
      name: 'companies',
      description: 'Companies Associated with this Project',
      title: 'Companies',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_COMPANIES,
      apiRoute: '/company',
      apiQuery: ['with[]=company:id,name', 'with[]=type:id,name', 'pagelength=20'].join('&'),
      autoLoadAllData: false,
      badgeCountFields: [
        {
          fieldName: 'count_of_projectCompanies',
          badgeColor: THEME_COLOR.DARK,
          // Don't display "1" - it's useless. Only draw attention to the tab if there are two or more companies
          onCalculateValue: (value) => (value < 2 ? 0 : value),
        },
      ],
    },
    {
      name: 'contracts',
      title: 'Contracts',
      description: 'Project Contracts',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_CONTRACTS,
      apiRoute: (rowData) => {
        const apiQuery = [
          'with[]=type',
          'with[]=subtype',
          'with[]=status:id,name',
          'with[]=billingType:id,name',
          'with[]=client:id,name',
          'with[]=provider:id,name',
          'with[]=project:id,project_number,partner_program_id,dtools_id',
          'with[]=renewedFrom:id,type_id,status_id',
          'with[]=renewedFrom.type:id,prefix',
          'with[]=renewedTo:id,type_id,status_id',
          'with[]=renewedTo.type:id,prefix',
          'with[]=region:id,name',
          'filter[0][field]=project_id',
          'filter[0][operation]=equals',
          `filter[0][value]=${rowData.id}`,
          'pagelength=1000',
        ].join('&');
        return `/contract?${apiQuery}`;
      },
      autoLoadAllData: false,
    },
    {
      ...eventsTabDefault,
      description: 'Project Event Audit Trail',
      apiQuery: [
        'with[]=createdBy:id,name',
        'with[]=projectForecastTracking:id,data,story',
        'with[]=projectInvoiceTracking:id,data,story',
        'with[]=projectCompanyTracking:id,data,story',
        'with[]=projectResourceTracking:id,data,story',
        'with[]=projectOrderTracking:id,data,story',
        'with[]=projectNoteTracking:id,data,story',
        'pagelength=-1',
      ].join('&'),
      autoLoadAllData: true,
      statusMap: PROJECT_STATUS_NAME_MAP,
      /* @todo use the below to retrieve the extra  detail only when a user clicks through for info */
      // trackableRelations: {
      //   projectForecastTracking: 'project_forecast_tracking',
      //   projectInvoiceTracking: 'project_invoice_tracking',
      //   projectCompanyTracking: 'project_company_tracking',
      //   projectResourceTracking: 'project_resource_tracking',
      //   projectOrderTracking: 'project_order_tracking',
      // },
    },
    {
      name: 'etasummary',
      title: 'ETA Summary',
      description: 'ETA Summary',
      apiRoute: '/item-eta-report',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_ITEM_ETA_SUMMARY,
      isVisible: (rowData) => [PROJECT_STATUS.LEAD].indexOf(rowData.status_id) === -1,
    },
    {
      name: 'profitability',
      title: 'Profitability Report',
      description: 'Project Profitability Report',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_PROFITABILITY,
      isVisible: (rowData) => [PROJECT_STATUS.LEAD].indexOf(rowData.status_id) === -1,
      apiRoute: '/profitandloss',
      apiQuery: 'with[]=quickBooksProfitAndLossAccountLines',
      permissions: PERMISSION.REPORT_PROJECT_PROFITABILITY,
    },
    {
      name: 'changeorders',
      title: 'Dtools Change Orders',
      description: 'Matching Dtools Change Orders',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_DTOOLS_CHANGE_ORDERS,
      apiRoute: '/dtools-change-order',
    },
    {
      name: 'invoice-requests',
      title: 'Invoice Requests',
      description: 'Client Invoice Requests',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_INVOICE_REQUESTS,
      isVisible: (rowData) => [PROJECT_STATUS.LEAD, PROJECT_STATUS.PROPOSAL].indexOf(rowData.status_id) === -1,
      apiRoute: '/invoice-request',
      apiQuery: [
        'with[]=type:id,name',
        'with[]=status:id,name',
        'with[]=billToContact:id,first,last',
        'with[]=billToCustomer:id,name',
        'with[]=projectForecast',
        'pagelength=0',
        'with_count[]=details',
        'with_sum[]=details.total_price',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'count_of_invoiceRequests',
          badgeColor: THEME_COLOR.DARK,
        },
      ],
      permissions: [
        PERMISSION.INVOICE_REQUEST_VIEWER,
      ],
    },
    {
      // no longer in use
      name: 'supplierorders',
      title: 'Supplier Orders',
      description: 'Supplier Purchase Orders',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_QUICKBOOKS_ORDERS,
      isVisible: (rowData) => [PROJECT_STATUS.LEAD].indexOf(rowData.status_id) === -1,
      apiRoute: '/dtoolslink',
    },
    {
      name: 'commission-info',
      title: 'Commission Info',
      description: 'Project Commission Information',
      apiRoute: (rowData) => `/project/${rowData.id}`,
      apiQuery: [
        'with[]=commissionIteration:id,name',
        'with[]=commissionBracket:id,iteration_id,net_profit_threshold,commission_percentage',
      ].join('&'),
      widgetDefinition: WIDGET_DEFINITION.PROJECT_COMMISSION_INFORMATION,
      permissions: [
        PERMISSION.COMMISSION_PAYMENT_VIEWER,
        PERMISSION.COMMISSION_PAYMENT_MANAGER,
        PERMISSION.API_ADMIN,
      ],
    },
    {
      name: 'commission-payments',
      title: 'Commission Payments',
      description: 'Project Commission Payments',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_COMMISSION_PAYMENTS,
      apiRoute: '/commission-payment',
      apiQuery: [
        'with[]=user:id,name',
        'with[]=paymentBatch:id,name',
        'pagelength=0',
      ].join('&'),
      permissions: [
        PERMISSION.COMMISSION_PAYMENT_VIEWER,
        PERMISSION.COMMISSION_PAYMENT_MANAGER,
        PERMISSION.API_ADMIN,
      ],
    },
    {
      name: 'admin',
      title: 'Admin Functions',
      description: 'Control administrative settings for this project',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_ADMIN,
      permissions: [
        PERMISSION.PROJECT_REGENERATE_NUMBER,
        PERMISSION.PROJECT_ROLLBACK,
        PERMISSION.API_ADMIN,
      ],
    },
  ],

  /**
   * Action settings for action buttons and related dialogs
   * @see https://wiki.ciportal.net/books/ci-portal-developer-documentation/page/expense-claim-workflow-actions
   */
  possibleActions: [
    // {
    //   name: 'create',
    //   title: 'New Project',
    //   color: BUTTON_COLOR.PRIMARY,
    //   icon: ICON.ADD,
    //   description: 'Create a new',
    //   successMessage: 'Created',
    //   failMessage: 'Failed to create',
    //   hideRecordActionButton: true,
    // },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update',
      successMessage: 'Updated',
      failMessage: 'Failed to update',
      hideRecordActionButton: true,
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the project',
      successMessage: 'Project deleted',
      failMessage: 'Failed to delete project',
      hideRecordActionButton: true,
    },
    {
      name: 'activate',
      title: 'Activate',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.ACTIVATE,
      description: 'Activate the project',
      successMessage: 'Project Activated',
      failMessage: 'Failed to activate the project',
      modalType: MODAL_TYPE.ACTIVATE_PROJECT,
      hideRecordActionButton: false,
    },
    {
      name: 'rollback',
      title: 'Rollback',
      color: BUTTON_COLOR.WARNING,
      icon: ICON.ROLLBACK,
      description: 'Roll back the project activation',
      successMessage: 'Project Rolled Back',
      failMessage: 'Failed to roll back the project activation',
      requireConfirmReason: true,
      hideRecordActionButton: true,
    },
    {
      name: 'create-project-in-dtools',
      title: 'Create Project in Dtools',
      color: BUTTON_COLOR.WARNING,
      icon: ICON.CLOUD,
      description: 'Create the project in D-Tools',
      successMessage: 'Project Created',
      failMessage: 'Failed to create the project in D-Tools',
      requireConfirmReason: false,
      hideRecordActionButton: false,
    },
  ],
  /**
   * View definitions
   */
  viewKey: 'active',
  views: [
    {
      key: 'pipeline',
      title: 'Sales Pipeline',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Sales Pipeline',
        },
      ],
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.IN,
          values: ['lead', 'proposal'],
        },
      ],
      flags: [],
    },
    {
      key: 'active',
      title: 'Active Projects',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Active Projects',
        },
      ],
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['active'],
        },
      ],
      flags: [],
    },
    {
      key: 'all',
      title: 'All Leads, Proposals & Projects',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'All My Leads, Proposals & Projects',
        },
      ],
      onlyMineTitle: 'My Leads, Proposals & Projects',
      filters: [],
      flags: [],
    },
    {
      key: 'leads',
      title: 'Project Leads',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Project Leads',
        },
      ],
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['lead'],
        },
      ],
      flags: [],
    },
    {
      key: 'proposals',
      title: 'Project Proposals',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Project Proposals',
        },
      ],
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['proposal'],
        },
      ],
      flags: [],
    },
    {
      key: 'completed',
      title: 'Completed Projects',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Completed Proposals',
        },
      ],
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['completed'],
        },
      ],
      flags: [],
    },
    {
      key: 'lost',
      title: 'Lost Project Leads',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Lost Project Leads',
        },
      ],
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['lost'],
        },
      ],
      flags: [],
    },
  ],
};

export default projectTableInitialSettings;
