import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';

export type ProgressPercentProps = Pick<FormFieldComponentProps,
  'id' |
  'name' |
  'value' |
  'disabled' |
  'onChange'
>

/**
 * Progress Percent Input
 */
export const ProgressPercentInput: React.FC<ProgressPercentProps> = (props) => {
  const {
    id,
    name,
    value,
    disabled,
    onChange,
  } = props;

  const [futureValue, setFutureValue] = useState<number>(value ? parseInt(value, 10) : 0);

  const progressContainerRef: React.Ref<HTMLDivElement> = useRef<HTMLDivElement>(null);

  /**
   * Handle the mouse move when in edit mode
   */
  const handleMouseMove = useCallback((e: { nativeEvent: { clientX: number }}) => {
    if (disabled) return true;
    const rect = progressContainerRef?.current?.getBoundingClientRect();
    const offsetLeft = rect?.left || 0;
    const offsetWidth = rect?.width || 0;
    setFutureValue(
      Math.round(
        (
          (e.nativeEvent.clientX - offsetLeft + 1)
          / offsetWidth
        )
        * 20,
      ) * 5,
    );
    return true;
  }, [disabled]);

  /**
   * Handle a mouse-out / leave event
   */
  const handleMouseLeave = useCallback(() => {
    if (disabled) return true;
    setFutureValue(value ? parseInt(value, 10) : 0);
    return true;
  }, [disabled, value]);

  /**
   * Fired when the user clicks one of the progress indicators
   */
  const handleClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    if (disabled) return true;
    if (onChange) {
      onChange({
        fieldName: name,
        newValue: futureValue,
      });
    }
    return true;
  }, [disabled, futureValue, name, onChange]);

  /**
   * update if new props are passed down.
   */
  useEffect(() => {
    setFutureValue(value ? parseInt(value, 10) : 0);
  }, [value]);

  // Render
  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      id={id}
      className={classnames({
        'progress-percent-editor': !disabled,
        'progress-percent-viewer': disabled,
      })}
      title={`${futureValue}%`}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      ref={progressContainerRef}
    >
      <div
        className="progress-percent-bar"
        style={{ width: `${futureValue}%` }}
      >
        {futureValue > 50 && `${futureValue}%`}
      </div>
      <div
        className="progress-percent-blank"
        style={{ width: `${100 - futureValue}%` }}
      >
        {futureValue <= 50 && `${futureValue}%`}
      </div>
    </div>
  );
};
