import { combineReducers } from 'redux';
import settingsReducer from './settings-reducer';
import appStatusReducer from './app-status-reducer';
import tableSettingsReducer from './table-settings-reducer';
import apiQueryCacheReducer from './api-query-cache-reducer';

/**
 * Define the application global store structure
 * @todo Correct Reducer Naming
 */
const allReducers = {
  settings: settingsReducer,
  tableSettings: tableSettingsReducer,
  appStatus: appStatusReducer,
  apiQueryCache: apiQueryCacheReducer,
};

const rootReducer = combineReducers(allReducers);

export default rootReducer;
