import React from 'react';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';

import AsyncSelect from './async-select';

export type ProjectContactTypePickerProps = AsyncSelectComponentProps;

export const ProjectContactTypePicker:React.FC<ProjectContactTypePickerProps> = (props) => (
  <AsyncSelect
    {...props}
    searchRoute="/project/contact/type"
    loadAndKeepAll
  />
);
