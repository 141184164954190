import React, { useState } from 'react';
import classnames from 'classnames';
import shortId from 'shortid';
import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { formatAlignClass } from '../render-functions';

export type TextInputProps = Pick<FormFieldComponentProps,
  'className' |
  'id' |
  'name' |
  'value' |

  'placeholder' |
  'maxLength' |
  'autoComplete' |
  'autoCompleteIdentifier' |

  'tabIndex' |
  'disabled' |
  'hasError' |
  'format' |
  'inline' |

  'onKeyPress' |
  'onKeyDown' |
  'onKeyUp' |
  'onChange' |
  'onClick'
>

export const TextInput:React.FC<TextInputProps> = ({
  className,
  id,
  name,
  value,

  placeholder,
  maxLength,
  autoComplete,
  autoCompleteIdentifier,

  tabIndex,
  disabled,
  hasError,
  format,
  inline,

  onKeyDown,
  onKeyUp,
  onKeyPress,
  onChange,
  onClick,
}) => {
  // This is to prevent chrome's auto complete from getting in the way
  const [autoCompleteBlockingName] = useState(() => ((autoComplete === true) ? (autoCompleteIdentifier ?? name) : `${shortId.generate()}`));

  return (
    <input
      id={id}
      required
      onChange={(e) => {
        if (onChange) { onChange({
          fieldName: name,
          newValue: e.target.value,
        }); }
      }}
      disabled={disabled === true}
      name={autoCompleteBlockingName}
      value={value ?? ''}
      type="text"
      placeholder={placeholder}
      className={classnames(className, 'form-control', formatAlignClass(format, inline), {
        inline: (inline === true),
        'form-control-danger': (hasError === true),
      })}
      maxLength={maxLength}
      // Using 'autocomplete="off" is not enough for chrome. Have to give it a random string so that it tries to auto-complete for a random category instead
      autoComplete={(autoComplete === true) ? (autoCompleteIdentifier ?? 'on') : autoCompleteBlockingName}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onKeyPress={onKeyPress}
      onClick={onClick}
    />
  );
};
