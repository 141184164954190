import React from 'react';
import { useHistory } from 'react-router';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { DataTablePageProps } from '../../types/data-table-page.props';

export type CommissionStructureBracketsDataTablePageProps = DataTablePageProps;

/**
 * Display the "CommissionStructureBrackets" data table as a whole page
 */
export const CommissionStructureBracketsDataTablePage: React.FC<CommissionStructureBracketsDataTablePageProps> = ({
  baseFilters,
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const history = useHistory();

  return (
    <DataTablePage
      title="Commission Structure Brackets"
      tableIdentifier={TABLE_IDENTIFIER.COMMISSION_STRUCTURE_BRACKETS_TABLE}
      baseFilters={baseFilters}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      {children}
    </DataTablePage>
  );
};
