import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { APIContext } from '../../providers/api-provider';
import { ModalContext } from '../../modals/modal-context';

import { FormRendererProps } from '../../../types/poly-form/form-renderer.props';
import { IAPIAction } from '../../../types/api-action.interface';
import { IAPIFileTransport } from '../../../types/api-file-transport.interface';
import { IFileRecord } from '../../../types/file.record.interface';

import { usePreviousValue } from '../../../react-hooks/use-previous-value.hook';

import { FormButtons } from '../form-buttons';
import Icon from '../../layout-helpers/icon';
import { LoadingSpinner } from '../../layout-helpers/loading-spinner';
import { InfoTooltip } from '../../info-tooltip';

import { apiAborter } from '../../../helpers/api-aborter.helper';
import { buildAPIRoute } from '../../../helpers/build-api-route.helper';
import { cancelFileUpload, confirmFileUpload, requestFileUpload, uploadFile } from '../../../helpers/file-upload.helper';
import { debugLog } from '../../../utils/helpers';
import { downloadFile } from '../../../helpers/download-file.helper';
import { getAvailableActions } from '../../../helpers/get-available-actions.helper';
import { prettyFileSize } from '../../../helpers/pretty-file-size.helper';
import { prettyFileName } from '../../../helpers/pretty-file-name.helper';
import { SERObject } from '../../../helpers/ser-object.helper';

import { AN_UPLOAD_STATE, UPLOAD_STATE } from '../../../constants/upload-state.const';
import { API_ACTION } from '../../../constants/api-action.const';
import { BUTTON_COLOR } from '../../../constants/button-color.const';
import { getMimeTypeIcon, getMimeTypeIconColor, isImageMimeType, isPDFMimeType } from '../../../constants/mime-type.const';
import { ICON } from '../../../constants/icon.const';


export type FileFormRendererProps<T extends IFileRecord> = FormRendererProps & {
  formData: T,
}

// Used for the purposes of debugging the portal data table component update lifecycle
let ffrLifeCycleIndex = 0;

/**
 * FileFormRendererProps is designed to be used as part of the File Collection Widget
 *
 * @note during the entire file-upload process, the ID of the component is the same as when it was
 * first rendered. Once the upload process is complete, the new FileID is passed back up to the calling
 * component. This is IMPORTANT to know because once that ID changes... this component's key changes
 * and react disposes of it and re-renders it. Don't Change the ID until the very end!!
 */
export const FileFormRenderer = <T extends IFileRecord, >(props: FileFormRendererProps<T>): React.ReactElement<FileFormRendererProps<T>> => {
  const {
    primaryKeyValue,
    formData,
    itemCaption = 'file',
    parentId,

    isReadOnly,
    isNewRecord = false,
    isEditing,
    isLocked,
    isBusy = false,

    hasErrors = false,

    baseRoute = '',
    apiRoute,
    apiQuery,

    permittedActions = [],

    formDeleteConfirmationType,

    updateRecord,
    endEditRecord,
    deleteRecord,
    onClick,
  } = props;

  const { apiFetch } = useContext(APIContext);
  const { showUpdateFileModal } = useContext(ModalContext);

  // This flag is set to false when the component is unmounted so that we can avoid invalid react state updates
  const componentMounted = useRef<boolean>(true);

  const [thumbnailUrl, setThumbnailUrl] = useState<undefined | string>(undefined);
  const [thumbnailLoaded, setThumbnailLoaded] = useState<boolean>(false);

  // Mainly used for PDF files that need to determine their transport before reporting that they've been clicked on
  const [isFetchingDownloadUrl, setIsFetchingDownloadUrl] = useState<boolean>(false);
  const [downloadUrl, setDownloadUrl] = useState<undefined | string>(undefined);
  const previousDownloadUrl = usePreviousValue(downloadUrl);
  const [delayClickUntilDownloadUrlExists, setDelayClickUntilDownloadUrlExists] = useState<boolean>(false);

  // These are only used if a the record has been provided with a "file" attribute from a file browser / file selection / file drop etc...
  // When the "file" property has been provided, the initial upload state will evaluate as "pending" to start the upload cycle
  const [fileUpload, setFileUpload] = useState<{
    uploadState: AN_UPLOAD_STATE,
    fileToUpload: null | File,
  }>({
    uploadState: UPLOAD_STATE.NOT_UPLOADING,
    fileToUpload: null,
  });
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  // Simple evaluator to convert a valid upload state into a boolean for logic evaluation
  const isUploading = ([
    UPLOAD_STATE.PENDING,
    UPLOAD_STATE.REQUESTING,
    UPLOAD_STATE.REQUEST_SUCCEEDED,
    UPLOAD_STATE.UPLOADING,
    UPLOAD_STATE.UPLOAD_SUCCEEDED,
    UPLOAD_STATE.CONFIRMING,
    UPLOAD_STATE.CONFIRM_SUCCEEDED,
  ] as AN_UPLOAD_STATE[]).includes(fileUpload.uploadState);

  // Simple evaluator to convert a valid upload state into a boolean for logic evaluation
  const hasUploadError = ([
    UPLOAD_STATE.REQUEST_FAILED,
    UPLOAD_STATE.UPLOAD_FAILED,
    UPLOAD_STATE.CONFIRM_FAILED,
  ] as AN_UPLOAD_STATE[]).includes(fileUpload.uploadState);

  // The upload, download and API operations all require an abort controller
  const uploadAbortController = useRef<null | AbortController>(null);
  const downloadAbortController = useRef<null | AbortController>(null);

  // These are used by a file upload to confirm a complete or cancel an incomplete upload
  const cancelFileUploadAction = useRef<null | IAPIAction>(null);
  const confirmFileUploadAction = useRef<null | IAPIAction>(null);

  // This is the file transport retrieved by an upload request. Only used for uploading the file
  const fileUploadTransport = useRef<null | IAPIFileTransport>(null);

  const isClickable = (
    (isImageMimeType(formData.content_type) || isPDFMimeType(formData.content_type)) &&
    !isFetchingDownloadUrl &&
    !isBusy
  );
  const isDownloadable = !!formData?.user_audit?.confirmed_at;

  /**
   * Returns true if the upload was aborted for whatever reason (component un-mounting most likely)
   */
  const uploadAborted = (): boolean => (!componentMounted.current || (!!uploadAbortController.current && uploadAbortController.current.signal.aborted));


  /**
   * Step 1/3
   * Fired when the upload state is set to UPLOAD_STATE.PENDING to initiate the upload request
   */
  const performFileUploadRequest = useCallback(async (): Promise<SERObject<T>> => {
    // The result will be a Success/Error/Response (SER) object
    const result = new SERObject<T>(false);

    // Prevent this method firing twice if my useEffect hooks and exhaustive deps have been written poorly
    if (fileUpload.uploadState !== UPLOAD_STATE.PENDING) return result;

    // helps with debugging
    // eslint-disable-next-line no-plusplus
    debugLog(`FFR Lifecycle ${ffrLifeCycleIndex++} - (1/3) performFileUploadRequest`, 'info', { fileUpload }, '⏫');

    // This action requires an apiRoute
    if (!apiRoute) throw new Error('FileFormRenderer::performFileUploadRequest(): apiRoute is a mandatory property for a file upload.');

    // This action requires the updateRecord method
    if (!updateRecord) throw new Error('FileFormRenderer::performFileUploadRequest(): updateRecord is a mandatory property for a file upload.');

    // This action requires that the fileUpload.fileToUpload value has been successfully set
    if (!fileUpload.fileToUpload) throw new Error('FileFormRenderer::performFileUploadRequest(): fileUpload.fileToUpload is a mandatory state value for a file upload.');

    // Let the component know that the upload cycle has progressed
    setFileUpload({
      ...fileUpload,
      uploadState: UPLOAD_STATE.REQUESTING,
    });
    setUploadProgress(0);

    // Reset the upload refs
    cancelFileUploadAction.current = null;
    confirmFileUploadAction.current = null;
    fileUploadTransport.current = null;

    // These are references for use by THIS function and are passed back up to the state once a successful upload request has been made
    let newRecordData: null | T = null;

    try {
      // Use the incoming form props to create the final api route
      const finalAPIRoute = buildAPIRoute<T>({
        isNewRecord: true,
        parentId,
        formData,
        baseRoute,
        apiRoute,
      });

      // Perform the POST on the API to create the file record
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id: unusedId, ...body } = formData;
      const requestFileUploadResult = await requestFileUpload<T>(
        finalAPIRoute,
        apiFetch,
        uploadAbortController.current,
        (newAbortController: null | AbortController) => { uploadAbortController.current = newAbortController; },
        fileUpload.fileToUpload,
        body,
      );

      // If the request was successful
      if (!uploadAborted() && requestFileUploadResult.success && requestFileUploadResult.result) {
        cancelFileUploadAction.current = requestFileUploadResult.result.actions[API_ACTION.CANCEL];
        confirmFileUploadAction.current = requestFileUploadResult.result.actions[API_ACTION.CONFIRM];
        fileUploadTransport.current = requestFileUploadResult.result.transport;

        // The API will have updated some of the file data we can pass back to the parent using updateRecord
        newRecordData = requestFileUploadResult.result.data as T;

        // The request is now successful
        result.success = true;
      } else if (!uploadAborted()) {
        result.error = `File Upload Failed when requesting file upload: ${requestFileUploadResult.error}`;
      }
    } catch (err) {
      result.error = `File Upload Failed due to an unexpected error: ${err}`;
    } finally {
      if (result.success && newRecordData) {
        // Prepare for the next phase in the upload cycle
        setFileUpload((currentFileUpload) => ({
          ...currentFileUpload,
          uploadState: UPLOAD_STATE.REQUEST_SUCCEEDED,
        }));

        // Ensure the form data is up to date. This should be done last as it will trigger a re-render of this component
        updateRecord(primaryKeyValue, {
          ...formData,

          // @note don't pass the primary key back here. Wait until the upload is completed
          // [primaryKeyFieldName]: newRecordData[primaryKeyFieldName],

          content_type: newRecordData.content_type,
          user_audit: newRecordData.user_audit,

          // It's important that we remove the "file" record from the data otherwise we may trigger
          // the cycle again when the component is rendered
          file: null,
        });
      } else {
        console.error('FileFormRenderer::performFileUploadRequest error: ', result.error ?? 'unexpected error');

        setFileUpload((currentFileUpload) => ({
          ...currentFileUpload,
          uploadState: UPLOAD_STATE.REQUEST_FAILED,
        }));
        setUploadProgress(100);

        // Cancel the Upload if something went wrong of the upload was aborted
        if (cancelFileUploadAction.current) {
          cancelFileUpload(apiFetch, cancelFileUploadAction.current);
        }

        // Remove the cancel and confirm actions
        cancelFileUploadAction.current = null;
        confirmFileUploadAction.current = null;
      }
    }

    return result;
  }, [apiFetch, apiRoute, baseRoute, fileUpload, formData, parentId, primaryKeyValue, updateRecord]);


  /**
   * Step 2/3
   * Fired after a successful upload request and performs the physical upload
   */
  const performFileUpload = useCallback(async (): Promise<SERObject<T>> => {
    // The result will be a Success/Error/Response (SER) object
    const result = new SERObject<T>(false);

    // Prevent this method firing twice if my useEffect hooks and exhaustive deps have been written poorly
    if (fileUpload.uploadState !== UPLOAD_STATE.REQUEST_SUCCEEDED) return result;

    // eslint-disable-next-line no-plusplus
    debugLog(`FFR Lifecycle ${ffrLifeCycleIndex++} - (2/3) performFileUpload`, 'info', { fileUpload }, '⏫');

    // This action requires that the fileToUpload state value has been successfully set
    if (!fileUpload.fileToUpload) throw new Error('FileFormRenderer::performFileUpload(): fileToUpload is a mandatory value for a file upload.');

    // This action requires that the fileUploadTransport has been successfully set
    if (!fileUploadTransport.current) throw new Error('FileFormRenderer::performFileUpload(): fileUploadTransport is a mandatory value for a file upload.');

    // Let the component know that the upload cycle has progressed
    setFileUpload({
      ...fileUpload,
      uploadState: UPLOAD_STATE.UPLOADING,
    });

    try {
      const uploadFileResult = await uploadFile(
        fileUpload.fileToUpload,
        fileUploadTransport.current,
        (progress) => {
          if (componentMounted.current) {
            setUploadProgress(progress);
          }
        },
      );

      // If the upload was successful
      if (!uploadAborted() && uploadFileResult.success) {
        result.success = true;
      } else if (!uploadAborted()) {
        result.error = `File Upload Failed during the file transfer: ${uploadFileResult.error}`;
      }
    } catch (err) {
      result.error = `File Upload Failed due to an unexpected error: ${err}`;
      console.error('FileInput::performFileUpload error', err);
    } finally {
      if (result.success) {
        // Prepare for the next phase in the upload cycle
        setFileUpload((currentFileUpload) => ({
          ...currentFileUpload,
          uploadState: UPLOAD_STATE.UPLOAD_SUCCEEDED,
        }));
        setUploadProgress(100);
      } else if (componentMounted.current) {
        setFileUpload((currentFileUpload) => ({
          ...currentFileUpload,
          uploadState: UPLOAD_STATE.UPLOAD_FAILED,
        }));
        setUploadProgress(100);

        // Cancel the Upload if something went wrong of the upload was aborted (this also happens on componentWillUnmount)
        if (cancelFileUploadAction.current) {
          cancelFileUpload(apiFetch, cancelFileUploadAction.current);
        }

        // Remove the cancel and confirm actions
        cancelFileUploadAction.current = null;
        confirmFileUploadAction.current = null;
      }
    }

    return result;
  }, [apiFetch, fileUpload]);


  /**
   * Step 3/3
   * Fired when the upload state is set to UPLOAD_STATE.UPLOAD_SUCCEEDED to confirm the completed upload
   */
  const performFileUploadConfirmation = useCallback(async (): Promise<SERObject<T>> => {
    // The result will be a Success/Error/Response (SER) object
    const result = new SERObject<T>(false);

    // Prevent this method firing twice if my useEffect hooks and exhaustive deps have been written poorly
    if (fileUpload.uploadState !== UPLOAD_STATE.UPLOAD_SUCCEEDED) return result;

    // helps with debugging
    // eslint-disable-next-line no-plusplus
    debugLog(`FFR Lifecycle ${ffrLifeCycleIndex++} - (3/3) performFileUploadConfirmation`, 'info', { fileUpload }, '⏫');

    // This action requires that the confirmFileUploadAction has been successfully set
    if (!confirmFileUploadAction.current) throw new Error('FileFormRenderer::performFileUploadConfirmation(): confirmFileUploadAction is a mandatory value for a file upload.');

    // This action requires the updateRecord method
    if (!updateRecord) throw new Error('FileFormRenderer::performFileUploadConfirmation(): updateRecord is a mandatory property for a file upload.');

    // Let the component know that the upload cycle has progressed
    setFileUpload({
      ...fileUpload,
      uploadState: UPLOAD_STATE.CONFIRMING,
    });

    // These are references for use by THIS function and are passed back up to the state once a successful upload request has been made
    let newRecordData: null | T = null;

    try {
      // Confirm the Upload
      const confirmFileUploadResult = await confirmFileUpload<T>(
        apiFetch,
        confirmFileUploadAction.current,
      );

      if (!uploadAborted() && confirmFileUploadResult.success && confirmFileUploadResult.result) {
        // The API will have updated some of the file data we can pass back to the parent using updateRecord
        newRecordData = confirmFileUploadResult.result.data as T;

        // The request is now successful
        result.success = true;
      } else if (!uploadAborted()) {
        result.error = `File Upload Failed when confirming file upload: ${confirmFileUploadResult.error}`;
      }
    } catch (err) {
      result.error = `File Upload Failed due to an unexpected error: ${err}`;
    } finally {
      if (result.success && newRecordData) {
        // Finish off the file upload
        setFileUpload((currentFileUpload) => ({
          ...currentFileUpload,
          uploadState: UPLOAD_STATE.CONFIRM_SUCCEEDED,
        }));

        // Remove the cancel and confirm actions
        cancelFileUploadAction.current = null;
        confirmFileUploadAction.current = null;

        // Ensure the form data is up to date. This should be done last as it will trigger a re-render of this component
        setTimeout(() => {
          updateRecord(primaryKeyValue, {
            ...formData,
            // Now we DO want to pass the primary key back.
            // This will effectively re-render this component as the component's `key` will change
            ...newRecordData,
          });
        });
      } else if (componentMounted.current) {
        console.error('FileFormRenderer::performFileUploadConfirmation error: ', result.error ?? 'unexpected error');

        setFileUpload((currentFileUpload) => ({
          ...currentFileUpload,
          uploadState: UPLOAD_STATE.CONFIRM_FAILED,
        }));
        setUploadProgress(100);

        // Cancel the Upload if something went wrong of the upload was aborted (this also happens on componentWillUnmount)
        if (cancelFileUploadAction.current) {
          cancelFileUpload(apiFetch, cancelFileUploadAction.current);
        }

        // Remove the cancel and confirm actions
        cancelFileUploadAction.current = null;
        confirmFileUploadAction.current = null;
      }
    }

    return result;
  }, [apiFetch, fileUpload, formData, primaryKeyValue, updateRecord]);


  /**
   * Call this to get the transport Url for downloading the file
   */
  const getDownloadTransportUrl = useCallback(async (): Promise<SERObject<string>> => {
    // The result will be a Success/Error/Response (SER) object
    const result = new SERObject<string>(false);

    // This action requires an apiRoute
    if (!apiRoute) throw new Error('FileFormRenderer::getDownloadTransportUrl(): apiRoute is a mandatory property to get the preview image Url.');

    // Cancel any existing requests and create a new Abort signal
    if (downloadAbortController.current) {
      downloadAbortController.current.abort();
    }
    downloadAbortController.current = apiAborter();

    try {
      // Use the incoming form props to create the final api route
      const finalAPIRoute = buildAPIRoute({
        parentId,
        primaryKeyValue,
        formData,
        baseRoute,
        apiRoute,
      });

      // First get the available actions for the file so we can get the Download action
      const getAvailableActionsResult = await getAvailableActions(finalAPIRoute, apiFetch);

      if (getAvailableActionsResult.success && getAvailableActionsResult.result) {
        // Look for the Download action
        const downloadAction: undefined | IAPIAction = getAvailableActionsResult.result[API_ACTION.DOWNLOAD];
        if (downloadAction) {
          // Request the download using the action
          const response = await apiFetch(
            downloadAction.link,
            {
              method: downloadAction.method,
              signal: downloadAbortController.current.signal,
            },
          );

          if (response.success && !!response.body?.transport?.url) {
            downloadAbortController.current = null;

            result.success = true;
            result.result = response.body.transport.url;
          } else if (!response.aborted) {
            downloadAbortController.current = null;
            result.error = `Failed to get the download transport: ${response.body?.message ?? response.body ?? 'unknown error'}`;
          }
        } else {
          result.error = 'There is no download capability provided on the target file!';
        }
      } else {
        result.error = getAvailableActionsResult.error || 'An unexpected error occurred.';
      }
    } catch (err) {
      result.error = `Thumbnail download failed due to an unexpected error: ${err}`;
    }

    if (result.error) {
      console.error(result.error);
    }

    return result;
  }, [apiFetch, apiRoute, baseRoute, formData, parentId, primaryKeyValue]);


  /**
   * Fired when the file is a fully uploaded image file which supports preview loading
   * TODO: for now this is just the download transport Url
   */
  const getThumbnailImageUrl = useCallback(async (): Promise<SERObject<string>> => getDownloadTransportUrl(), [getDownloadTransportUrl]);


  /**
   * Fired when the user clicks the download button
   */
  const handleDownloadFile = useCallback(async (): Promise<SERObject<T>> => {
    // The result will be a Success/Error/Response (SER) object
    const result = new SERObject<T>(false);

    // This action requires an apiRoute
    if (!apiRoute) throw new Error('FileFormRenderer::handleDownloadFile(): apiRoute is a mandatory property to download the file.');

    // Cancel any existing requests and create a new Abort signal
    if (downloadAbortController.current) {
      downloadAbortController.current.abort();
    }
    downloadAbortController.current = apiAborter();

    try {
      // Use the incoming form props to create the final api route
      const finalAPIRoute = buildAPIRoute({
        parentId,
        primaryKeyValue,
        formData,
        baseRoute,
        apiRoute,
      });

      // First get the available actions for the file so we can get the Download action
      const getAvailableActionsResult = await getAvailableActions(finalAPIRoute, apiFetch);

      if (getAvailableActionsResult.success && getAvailableActionsResult.result) {
        // Look for the Download action
        const downloadAction: undefined | IAPIAction = getAvailableActionsResult.result[API_ACTION.DOWNLOAD];
        if (downloadAction) {
          // Request the download using the action
          const response = await apiFetch(
            downloadAction.link,
            {
              method: downloadAction.method,
              signal: downloadAbortController.current.signal,
            },
          );

          if (response.success) {
            downloadAbortController.current = null;

            result.success = true;
            result.result = response.body;

            // Trigger the download
            downloadFile(response.body.transport.filename, response.body.transport.url);
          } else if (!response.aborted) {
            downloadAbortController.current = null;
          }
        } else {
          result.error = 'There is no download capability provided on the target file!';
        }
      } else {
        result.error = getAvailableActionsResult.error || 'An unexpected error occurred.';
      }
    } catch (err) {
      result.error = `File download failed due to an unexpected error: ${err}`;
    }

    if (result.error) {
      console.error(result.error);
    }

    return result;
  }, [apiFetch, apiRoute, baseRoute, formData, parentId, primaryKeyValue]);


  /**
   * Wrapper around the startEditRecord method
   */
  const handleStartEditRecord = useCallback(() => {
    // Don't fire off the default edit record. Instead, launch a modal to edit the record.
    // if (startEditRecord) {
    //   startEditRecord(primaryKeyValue, formData);
    // }

    showUpdateFileModal<T>({
      parentId,
      baseRoute,
      apiRoute,
      apiQuery,
      formData,
      onModalComplete: (modalResult) => {
        if (modalResult.success) {
          if (updateRecord) {
            updateRecord(primaryKeyValue, modalResult.updatedRecord);
          }
        }
      },
    });
  }, [apiQuery, apiRoute, baseRoute, formData, parentId, primaryKeyValue, showUpdateFileModal, updateRecord]);


  /**
   * Wrapper around the endEditRecord method
   */
  const handleEndEditRecord = useCallback((saveChanges: boolean) => {
    if (endEditRecord) {
      endEditRecord(saveChanges, primaryKeyValue, formData);
    }
  }, [endEditRecord, formData, primaryKeyValue]);


  /**
   * Wrapper around the deleteRecord method
   */
  const handleDeleteRecord = useCallback(() => {
    if (deleteRecord) {
      deleteRecord(primaryKeyValue, formData);
    }
  }, [deleteRecord, formData, primaryKeyValue]);


  /**
   * Fired when the user clicks on the form
   */
  const handleClick = useCallback(async () => {
    if (onClick) {
      // Some files will need their download transport urls calculated before they can be "clicked"
      const transportUrlRequired = (
        isPDFMimeType(formData.content_type ?? null) ||
        isImageMimeType(formData.content_type ?? null)
      ) && !formData.downloadUrl;

      // If this file requires its transport Url to be calculated
      if (transportUrlRequired && updateRecord) {
        // Don't perform the click now... wait until the formData has the download Url
        setDelayClickUntilDownloadUrlExists(true);

        // Begin retrieving the Download Url
        setIsFetchingDownloadUrl(true);
        const getDownloadTransportUrlResult = await getDownloadTransportUrl();
        setIsFetchingDownloadUrl(false);
        if (getDownloadTransportUrlResult.success) {
          setDownloadUrl(getDownloadTransportUrlResult.result ?? undefined);
        }
      }

      // Otherwise Perform the click immediately
      else {
        onClick(primaryKeyValue, formData);
      }
    }
  }, [formData, getDownloadTransportUrl, onClick, primaryKeyValue, updateRecord]);


  /**
   * If the formData.file attribute is set then the file needs to be uploaded
   * Each phase of the upload is latched so that state updates to the component
   * don't interrupt / re-start various stages of the upload process
   */
  useEffect(() => {
    // Only kick off a file upload if we have a file record and the component is not already uploading a file
    if (formData.file && (fileUpload.uploadState === UPLOAD_STATE.NOT_UPLOADING)) {
      // Set the upload state to pending. This will fire off the upload cycle
      setFileUpload({
        fileToUpload: formData.file,
        uploadState: UPLOAD_STATE.PENDING,
      });
    }
  }, [formData, fileUpload]);


  /**
   * This effect moves the upload cycle through each of its latches
   */
  useEffect(() => {
    switch (fileUpload.uploadState) {
      // The file has been identified as pending upload. Perform an upload request.
      case UPLOAD_STATE.PENDING:
        performFileUploadRequest();
        break;

      // The upload request has succeeded. Perform the upload
      case UPLOAD_STATE.REQUEST_SUCCEEDED:
        performFileUpload();
        break;

      // The upload has succeeded. Confirm the upload
      case UPLOAD_STATE.UPLOAD_SUCCEEDED:
        performFileUploadConfirmation();
        break;

      // TODO: cleanup?
    }
  }, [fileUpload.uploadState, performFileUploadRequest, performFileUpload, performFileUploadConfirmation]);


  /**
   * On first load, evaluate whether the file is a complete upload and if so, go and retrieve the thumbnail image
   * for image mime-types
   */
  useEffect(() => {
    if (formData?.user_audit?.confirmed_at && formData?.content_type && isImageMimeType(formData.content_type)) {
      const updateThumbnail = async () => {
        const getThumbnailResult = await getThumbnailImageUrl();
        if (getThumbnailResult.success) {
          setThumbnailUrl(getThumbnailResult.result ?? undefined);

          // This is the same as the download Url so we may as well populate that now
          setDownloadUrl(getThumbnailResult.result ?? undefined);
        }
      };
      updateThumbnail();
    }
  // @note we only want this to fire on component mount. Don't provide any deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  /**
   * When the downloadUrl changes, pass it back up the chain to be added to the form data
   * (for use in the lightbox etc...)
   */
  useEffect(() => {
    if (updateRecord && (previousDownloadUrl !== downloadUrl) && (formData.downloadUrl !== downloadUrl)) {
      // This update record prevents the parent record from refreshing as this is not technically "new" data
      updateRecord(primaryKeyValue, {
        ...formData,
        downloadUrl,
      }, false);
    }
  }, [updateRecord, primaryKeyValue, formData, previousDownloadUrl, downloadUrl]);


  /**
   * When the downloadUrl has been passed up the chain and arrives back down inside this component,
   * evaluate whether there was a delayed click that needed to be triggered
   */
  useEffect(() => {
    if (delayClickUntilDownloadUrlExists && !!formData.downloadUrl && onClick) {
      setDelayClickUntilDownloadUrlExists(false);
      onClick(primaryKeyValue, formData);
    }
  }, [delayClickUntilDownloadUrlExists, formData, onClick, primaryKeyValue]);

  /**
   * Fired when the component is unloaded
   */
  useEffect(() => () => {
    // Flag that the component has been unmounted.
    // This prevents unnecessary updates on asynchronous functions
    componentMounted.current = false;

    // Cancel the Upload if something went wrong of the upload was aborted
    if (cancelFileUploadAction.current) {
      cancelFileUpload(apiFetch, cancelFileUploadAction.current);
    }

    // fire the abort controller if required
    if (uploadAbortController.current) {
      uploadAbortController.current.abort();
    }
  // @note: ignore exhaustive deps, we only want this to fire once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // Render
  return (
    <div
      role="none"
      className={classNames('portal-form', 'file', {
        'has-errors': hasErrors,
        'is-clickable': isClickable,
      })}
      onClick={isClickable ? handleClick : undefined}
    >
      {/* thumbnail Image / File-Type Icon */}
      <div className="icon-wrapper">
        {/* By default, display the file icon */}
        {!hasUploadError && (
          <div className="fallback-icon" style={{ color: getMimeTypeIconColor(formData.content_type) }}>
            <Icon i={getMimeTypeIcon(formData.content_type)} size="lg" />
          </div>
        )}

        {/* If there was an error display an error icon */}
        {hasUploadError && (
          <div className="fallback-icon error">
            <Icon i={ICON.ERROR} size="lg" />
          </div>
        )}

        {/* Use an image to load the thumbnail */}
        {thumbnailUrl && !thumbnailLoaded && (
          <img
            className="thumbnail-image loader"
            src={thumbnailUrl}
            alt={formData.filename}
            title={formData.comment ?? undefined}
            onLoad={() => setThumbnailLoaded(true)}
          />
        )}

        {/* Use a div with a background to display the thumbnail */}
        {thumbnailUrl && thumbnailLoaded && (
          <div
            className="thumbnail-image"
            style={{
              backgroundImage: `url("${thumbnailUrl}")`,
            }}
          />
        )}

        {/* When uploading, display the upload progress bar */}
        {isUploading && (
          <div className="upload-progress" style={{ width: `${uploadProgress}%` }} />
        )}

        {/* Buttons */}
        {!isUploading && (
          <div className="buttons-wrapper">
            <FormButtons
              buttonsClassName="border-0"
              itemCaption={itemCaption}
              inline
              permittedActions={permittedActions}
              additionalButtonsBefore={[
                {
                  name: API_ACTION.DOWNLOAD,
                  visible: isDownloadable,
                  title: 'Download',
                  color: BUTTON_COLOR.PRIMARY,
                  icon: ICON.DOWNLOAD_FILE,
                  onClick: handleDownloadFile,
                },
              ]}
              formDeleteConfirmationType={formDeleteConfirmationType}

              formIsEditing={isEditing}
              formIsReadOnly={isReadOnly}
              formIsCreating={isNewRecord}
              formIsBusy={isBusy}
              formIsLocked={isLocked}

              startEditRecord={handleStartEditRecord}
              endEditRecord={handleEndEditRecord}
              deleteRecord={handleDeleteRecord}
            />
          </div>
        )}

        {/* File comment as a tooltip */}
        {formData.comment && (
          <InfoTooltip
            title={formData.filename}
            buttonIcon={ICON.COMMENT}
          >
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: formData.comment ?? '' }} />
          </InfoTooltip>
        )}
      </div>

      {/* Filename */}
      <div className="filename-wrapper">
        {/* eslint-disable-next-line react/no-danger */}
        <span className="filename" dangerouslySetInnerHTML={{ __html: prettyFileName(formData.filename) }} />
        <span className="file-size">
          {prettyFileSize(formData.file_size ?? 0)}
        </span>
      </div>

      {/* Busy Indicator */}
      {(isUploading || isFetchingDownloadUrl) && (
        <div className="busy-indicator">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};
