/* eslint-disable import/no-cycle */

import classNames from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';
import { ICON } from '../../../constants/icon.const';
import { currencyAUD } from '../../../utils/helpers';
import Icon from '../../layout-helpers/icon';
import { IDetailedProfitAndLossSection, DetailedProfitAndLossSections } from './detailed-profit-and-loss-sections';
import {
  DetailedProfitandLossColumnCurrencyFields,
  DetailedProfitandLossColumnNumberFields,
  DetailedProfitAndLossDateFields,
} from './detailed-profit-and-loss-table';
import { LongStringToolTipMore } from './long-string-tooltip-more';

type DetailedProfitAndLossSectionProps = {
  section: IDetailedProfitAndLossSection,
  columns: {
    name: string,
    title: string,
  }[],
  depth: number,
};


export const DetailedProfitAndLossSection: React.FC<DetailedProfitAndLossSectionProps> = ({ section, columns, depth }) => {
  const [showDetail, setShowDetail] = useState(depth < 2);
  const currencyFields = DetailedProfitandLossColumnCurrencyFields;
  const numberFields = DetailedProfitandLossColumnNumberFields;
  const dateFields = DetailedProfitAndLossDateFields;
  const grossProfitLine = section.summaryLines.filter((line) => line.tx_date === 'Gross Profit')[0] || undefined;
  return (
    <>
      {showDetail && section.name && (
        <tr onClick={() => setShowDetail(!showDetail)} className="section-title-line">
          <th colSpan={columns.length}>
            <Icon i={showDetail ? ICON.CARET_DOWN : ICON.CARET_RIGHT} size="sm" className="pr-1" />
            <span style={{ paddingLeft: `${depth * 0.5}rem` }}>{section.name}</span>
          </th>
        </tr>
      )}
      {showDetail && section.sections.length > 0 && (
        <DetailedProfitAndLossSections sections={section.sections} columns={columns} depth={depth + 1} grossProfitLine={grossProfitLine} />
      )}
      {showDetail && section.accountLines && section.accountLines.map((accountLine) => (
        <tr key={section.id} className="account-line">
          {columns.map((column, index) => {
            const columnName = column.name;
            const isCurrency = (currencyFields.indexOf(columnName) > -1);
            const isDate = (dateFields.indexOf(columnName) > -1);
            const isNumeric = (numberFields.indexOf(columnName) > -1);
            const columnValue = accountLine[columnName];
            const isLongString = (String(columnValue).length > 40);
            return (
              <td
                key={column.name}
                className={classNames({
                  'mono-font': isDate || isCurrency || isNumeric,
                  'text-right': isCurrency || isNumeric,
                  'text-danger': isCurrency && columnValue && Number(columnValue) < 0,
                })}
              >
                <div style={index === 0 ? { paddingLeft: `${depth}rem`, minWidth: '8rem' } : {}}>
                  {isCurrency && columnValue !== null && currencyAUD(Number(columnValue), 0)}
                  {isDate && columnValue !== null && moment(columnValue).format('DD/MM/YYYY')}
                  {!isCurrency && !isDate && !isLongString && columnValue}
                  {isLongString && (
                    <LongStringToolTipMore string={String(columnValue)} length={40} />
                  )}
                </div>
              </td>
            );
          })}
        </tr>
      ))}
      {section.summaryLines && section.summaryLines.map((summaryLine) => {
        const currencyValue = summaryLine.subt_nat_amount || summaryLine.subt_nat_home_amount || null;
        if (summaryLine[columns[0].name] === 'Gross Profit') return undefined;
        return (
          <tr key={section.id} className="summary-line" onClick={() => setShowDetail(true)}>
            <th colSpan={columns.length - 1}>
              {!showDetail && (<Icon i={ICON.CARET_RIGHT} size="sm" className="pr-1" />)}
              <span style={{ paddingLeft: `${depth * 0.5}rem` }}>{summaryLine[columns[0].name]}</span>
            </th>
            <th className={classNames('text-right', {
              'text-danger': currencyValue && Number(currencyValue) < 0,
            })}
            >
              {currencyValue && currencyAUD(Number(currencyValue), 0)}
            </th>
          </tr>
        );
      })}
    </>
  );
};
