import React from 'react';
import classnames from 'classnames';
import { NavLink } from 'reactstrap';

import { APIRecord } from '../../types/api-record.interface';
import { ITabDefinition } from '../../types/tabs-with-more/tab-definition.interface';

import { TabBadgeCounts } from './tab-badge-counts';

export interface ITabWithRef extends ITabDefinition {
  ref?: React.Ref<HTMLLIElement>,
}

export type TabWithRefProps = {
  tab: ITabWithRef,
  activeTabKey: string,
  visible?: boolean,
  rowData?: APIRecord,

  changeTab?: (tabName: string) => void,
};

/**
 * Tabs With More navlink tab
 * a navlink tab with a ref to the Dom Element for the tab.
 */
export const TabWithRef: React.FC<TabWithRefProps> = (props) => {
  const {
    tab,
    activeTabKey,
    visible = true,
    rowData,

    changeTab,
  } = props;
  return (
    <li
      className={classnames('nav-item', {
        hide: !visible,
        active: activeTabKey === tab.name,
      })}
      ref={tab.ref}
    >
      <NavLink
        className={classnames({ active: activeTabKey === tab.name })}
        onClick={changeTab ? () => changeTab(tab.name) : undefined}
      >
        {tab.title}
        {rowData && tab.badgeCountFields && (
          <TabBadgeCounts
            rowData={rowData}
            badgeCountFields={tab.badgeCountFields}
          />
        )}
      </NavLink>
    </li>
  );
};
