import React from 'react';

import { SelectOption } from '../../types/poly-form/select-option';
import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';

import { monthsSelect, birthYearOptions, monthDayOptions } from '../../utils/constants';
import { ConstSelect } from './const-select';


const calculateFieldValue = (selectedYear: null | number, selectedMonth: null | number, selectedDay: null | number): null | string => {
  if ((!selectedYear) && (!selectedMonth) && (!selectedDay)) return null;
  return `${
    (selectedYear && selectedYear.toString().padStart(4, '0')) ?? ''
  }-${
    (selectedMonth && selectedMonth.toString().padStart(2, '0')) ?? ''
  }-${
    (selectedDay && selectedDay.toString().padStart(2, '0')) ?? ''
  }`;
};

export type BirthdaySelectProps = Pick<FormFieldComponentProps,
  'id' |
  'name' |
  'formSaveField' |
  'formData' |
  'disabled' |
  'hasError' |
  'onChange'
>;

export const BirthdaySelect: React.FC<BirthdaySelectProps> = (props) => {
  const {
    id,
    name,
    formSaveField = null,
    formData = {},
    disabled = false,
    hasError = false,
    onChange,
  } = props;

  const saveField = formSaveField || name;
  const yearOptions: SelectOption[] = [{ id: 0, name: 'Prefer not to say' }, ...birthYearOptions];
  const monthOptions: SelectOption[] = [...monthsSelect.map((month) => ({ id: month.value, name: month.label } as SelectOption))];
  const dayOptions: SelectOption[] = [...monthDayOptions];

  // Break up the date
  const currentValue: string = formData[saveField] as string ?? '--';
  const dateBits: string[] = currentValue.split('-');
  const selectedYear: null | number = dateBits[0] ? parseInt(dateBits[0], 10) : null;
  const selectedMonth: null | number = dateBits[1] ? parseInt(dateBits[1], 10) : null;
  const selectedDay: null | number = dateBits[2] ? parseInt(dateBits[2], 10) : null;

  return (
    <div className="birthday-select">
      <ConstSelect
        name={`${name}_year`}
        id={`${id}_year`}
        className="year"
        labelKey="name"
        valueKey="id"
        placeholder="Year"
        value={selectedYear}
        inputOptions={yearOptions}
        disabled={disabled}
        isClearable
        hasError={hasError}
        onChange={({ newValue }) => { if (onChange) { onChange({
          fieldName: saveField,
          newValue: calculateFieldValue(newValue, selectedMonth, selectedDay),
        }); } }}
      />

      <ConstSelect
        name={`${name}_month`}
        id={`${id}_month`}
        className="month"
        labelKey="name"
        valueKey="id"
        placeholder="month"
        value={selectedMonth}
        inputOptions={monthOptions}
        disabled={disabled}
        isClearable
        hasError={hasError}
        onChange={({ newValue }) => { if (onChange) { onChange({
          fieldName: saveField,
          newValue: calculateFieldValue(selectedYear, newValue, selectedDay),
        }); } }}
      />

      <ConstSelect
        name={`${name}_day`}
        id={`${id}_day`}
        className="day"
        labelKey="name"
        valueKey="id"
        placeholder="day"
        value={selectedDay}
        inputOptions={dayOptions}
        disabled={disabled}
        isClearable
        hasError={hasError}
        onChange={({ newValue }) => { if (onChange) { onChange({
          fieldName: saveField,
          newValue: calculateFieldValue(selectedYear, selectedMonth, newValue),
        }); } }}
      />
    </div>
  );
};
