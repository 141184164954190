import { SORT_DIRECTION } from '@corporate-initiatives/ci-portal-js-sdk';
import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';

/**
 * Table Definition for Lead Table
 */
const companySpaceTypesTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Company Space Type',
  initialFormFocusFieldName: 'name',
  baseRoute: '/companyspacetype',
  baseQueryString: [
    'with[]=createdBy:id,name,initials',
  ].join('&'),

  /**
   * Start with the right default sort!
   */
  sortedColumns: [
    {
      name: 'sort_order',
      direction: SORT_DIRECTION.ASC,
      sortIndex: 0,
    },
  ],

  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'sort_order',
        'name',
        'icon',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'name',
        'icon',
        'sort_order',
        'description',
      ],
    },
  ],

  /**
   * Definition of Columns for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Identity',
      description: 'Company Space Type Id',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      showInForm: false,
      visible: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'sort_order',
      title: 'Sort Order',
      description: 'The order to sort the space types in the user picker',
      format: COLUMN_FORMAT.NUMBER,
      visible: true,
      order: 10,
      formOrder: 3,
    },
    {
      name: 'name',
      title: 'Name',
      description: 'Space Type Name',
      format: COLUMN_FORMAT.NAME_LINK,
      linkId: 'id',
      linkRoute: '/settings/company-space-types',
      visible: true,
      required: true,
      order: 20,
      formOrder: 1,
    },
    {
      name: 'icon',
      title: 'Icon',
      description: 'An icon to display to the users for easy identification.',
      format: COLUMN_FORMAT.ICON_NAME,
      visible: true,
      required: false,
      order: 30,
      formOrder: 2,
      formFieldToolTip: [
        'You can use an icon code from the any of the following three icon libraries:<br/>',
        '<ul>',
        '<li><a href="https://fontawesome.com/v4.7/icons/" target="_blank">FontAwesome 4.7</a></li>',
        '<li><a href="https://themify.me/themify-icons" target="_blank">Themify</a></li>',
        '<li><a href="https://simplelineicons.github.io/" target="_blank">Simple Line Icons</a></li>',
        '</ul>',
      ].join(''),
    },
    {
      name: 'description',
      title: 'Description',
      description: 'Company Space Type Description',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: false,
      required: false,
      order: 40,
      formOrder: 2,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Company Space Type Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      ...eventsTabDefault,
      description: 'Company Space Type Audit Trail',
    },
  ],

  /**
   * Action settings for action buttons and related dialogs
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add a Company Space Type',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new company space type',
      successMessage: 'Created',
      failMessage: 'Failed to Create the Company Space Type',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the company space type details',
      successMessage: 'Company Space Type details updated',
      failMessage: 'Failed to update the Company Space Type details',
      hideRecordActionButton: true,
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the Company Space Type',
      successMessage: 'Company Space Type deleted',
      failMessage: 'Failed to delete the Company Space Type',
      hideRecordActionButton: true,
    },
  ],
};

export default companySpaceTypesTableInitialSettings;
