import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

export const ContractContractSubtypesWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Subtype',
  // isReadOnly: true,
  // showAddBtn: false,
  fields: [
    // parent fields
    {
      name: 'contract_id',
      visible: false,
      isParentId: true,
    },
    {
      name: 'type_id',
      description: 'Parent Type Id',
      visible: false,
      useParentDataFieldValue: 'type_id',
    },
    // link fields
    {
      name: 'contract_subtype',
      title: 'Name',
      format: COLUMN_FORMAT.OBJECT,
      formFieldType: FORM_FIELD_TYPE.CONTRACT_SUBTYPE_PICKER,
      object: {
        sourceField: 'contract_subtype',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formSaveField: 'contract_subtype_id',
    },
    {
      name: 'description',
      title: 'Description',
      format: COLUMN_FORMAT.OBJECT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      object: {
        sourceField: 'contract_subtype',
        key: 'description',
        format: COLUMN_FORMAT.LONG_TEXT,
      },
    },
  ],
};
