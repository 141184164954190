import React, { Component, forwardRef } from 'react';
import moment from 'moment';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Card, CardHeader, CardBody, Table, Button,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import { connectToAPIProvider } from '../providers/api-provider';

import Icon from '../layout-helpers/icon';
import { InfoTooltip } from '../info-tooltip';
import FriendlyFormMessage from '../layout-helpers/friendly-form-message';

import API_PROVIDER_PROP_TYPES from '../../prop-types/api-provider-prop-types';
import { THEME_COLOR } from '../../constants/theme-color.const';
import { DATE_FORMAT } from '../../constants/date-format.const';
import { ICON } from '../../constants/icon.const';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { LoadingSpinner } from '../layout-helpers/loading-spinner';

/**
 * <AvailableLeave />
 */
class AvailableLeave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableLeave: [],
      errors: {},
      alertColor: null,
      formMessage: null,
      loaded: false,
      selectedDate: props.atDate || new Date(),
    };

    this.abortController = null;
  }

  componentDidMount() {
    this.loadData();
  }

  // <AvailableLeave /> un-mounted
  componentWillUnmount() {
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  loadData = () => {
    const { userId } = this.props;

    if (!userId) {
      this.setState({
        formMessage: 'Invalid user',
        loaded: true,
      });
      return;
    }

    // Kill any existing fetches
    if (this.abortController) {
      this.abortController.abort();
    }

    this.setState({
      formMessage: null,
      errors: {},
      alertColor: null,
      loaded: false,
    }, async () => {
      const { selectedDate } = this.state;
      const { apiProvider: { apiFetch } } = this.props;

      let dateQuery = '';
      if (selectedDate instanceof Date) {
        dateQuery = `?at_date=${moment(selectedDate).format(DATE_FORMAT.YEAR_MONTH_DAY_DASHES)}`;
      }

      this.abortController = apiAborter();

      const response = await apiFetch(`/user/${userId}/leave/summary${dateQuery}`, { signal: this.abortController.signal });

      if (response.success) {
        this.abortController = null;
        // If Payroll System could not be contacted by the API back-end, it's probably because the
        // User's email and/or name are different in Payroll than Portal.
        let alertColor = null;
        let formMessage = null;
        if (!response.body.data.accounting_data_received) {
          alertColor = 'danger';
          formMessage = `We were unable to retrieve accurate balances from the Payroll Software.
            Please contact IT or Finance for assistance.`;
        }
        // Set the state to success (and add a warning if keypay shat)
        this.setState({
          availableLeave: response.body.data.leave_block_type_summary,
          loaded: true,
          alertColor,
          formMessage,
        });
      } else if (!response.aborted) {
        this.abortController = null;
        this.setState({
          formMessage: response.error,
          errors: {},
          alertColor: 'danger',
          loaded: true,
        });
      }
    });
  };


  /**
   * Callback for clicking day in date picker
   * @param {object Date} day
   * Also can have:
   * @param {object} modifiers
   * @param {React.SyntheticEvent} e event from clicking day in calendar
   */
  pickDate = (day) => {
    this.setState(
      {
        selectedDate: day,
      },
      this.loadData,
    );
  };


  // Render for <AvailableLeave />
  render() {
    const {
      formMessage,
      errors,
      alertColor,
      loaded,
      availableLeave,
      selectedDate,
    } = this.state;

    const { atDate: propsDate } = this.props;

    const now = moment();
    const isChanged = moment(selectedDate).format('Y-MM-DD') === moment(propsDate).format('Y-MM-DD');

    const { colorTheme } = this.props;

    // eslint-disable-next-line react/display-name, react/prop-types, @typescript-eslint/no-unused-vars
    const DatePickerButton = forwardRef(({ onClick }, ref) => (
      <Button onClick={onClick} className="ml-2" id="button_date_select">
        <Icon i="calendar" />
      </Button>
    ));

    return (
      <Card className="shadow">
        <CardHeader className="align-items-center d-flex no-block justify-content-between">
          <h4 className="mb-0">
            {`Leave Summary ${selectedDate && ` at ${moment(selectedDate).format(DATE_FORMAT.AUSTRALIAN_READABLE)}`}`}
            {!isChanged && propsDate && (
              <Button
                title={`Today: ${now.format(DATE_FORMAT.AUSTRALIAN_READABLE)}`}
                onClick={() => this.pickDate(propsDate)}
                className="ml-2"
              >
                Reset
              </Button>
            )}
          </h4>
        </CardHeader>
        <CardBody>
          {!loaded && <LoadingSpinner caption="Loading available leave..." />}
          {loaded && availableLeave && (
            <Table
              striped
              bordered
              size="sm"
              className={classNames('color-table', `${colorTheme}-table`, 'mb-0')}
            >
              <thead>
                <tr>
                  <th>Type</th>
                  <th>
                    Taken&nbsp;
                    <InfoTooltip title="Leave Taken" buttonClassName="text-white">
                      Number of leave days taken in the current period for this type of leave.
                    </InfoTooltip>
                  </th>
                  <th>
                    Approved&nbsp;
                    <InfoTooltip title="Approved Leave" buttonClassName="text-white">
                      Number of leave days currently approved, but not yet taken.
                    </InfoTooltip>
                  </th>
                  <th>
                    Available&nbsp;
                    <InfoTooltip title="Available Leave" buttonClassName="text-white">
                      Number of leave days available to apply for.
                    </InfoTooltip>
                  </th>
                  <th>
                    Payroll Hours&nbsp;
                    <InfoTooltip title="Available Leave" buttonClassName="text-white">
                      Number of leave hours listed in Payroll @
                      {' '}
                      {moment(selectedDate).format(DATE_FORMAT.DAY_MONTH_YEAR_SLASHES)}

                    </InfoTooltip>
                  </th>
                </tr>
              </thead>
              <tbody>
                {availableLeave.map((item) => {
                  /* eslint-disable camelcase */
                  const {
                    always_display,
                    hours_available,
                    days_approved,
                    days_available,
                    days_available_estimated,
                    hours_available_estimated,
                    days_confirmed,
                    since_date,
                    type_id,
                    type_name,
                  } = item;

                  // Hide row if it's sensitive
                  const hide = !always_display && (days_approved + days_confirmed === 0);
                  if (hide) {
                    return null;
                  }

                  /**
                   * Local Node for quickly drawing a cell with an info tooltip
                   * @param {*} props
                   */
                  const Cell = ({
                    title,
                    tooltip,
                    value,
                    suffix = 'day',
                    showButton = true,
                    buttonIcon = ICON.INFO,
                    buttonClassName = 'text-muted',
                  }) => (
                    <td title={title}>
                      {value || 0}
                      &nbsp;
                      {pluralize(suffix, value || 0, false)}
                      &nbsp;
                      {showButton && (
                        <InfoTooltip
                          buttonIcon={buttonIcon}
                          title={title}
                          buttonClassName={buttonClassName}
                        >
                          <span>{tooltip}</span>
                          <span>{moment(since_date).format(DATE_FORMAT.AUSTRALIAN_READABLE)}</span>
                        </InfoTooltip>
                      )}
                    </td>
                  );

                  // Return table row
                  return (
                    <tr key={type_id}>
                      <td title={`Type ${type_id}`}>{type_name}</td>
                      <Cell
                        title="Leave Taken"
                        tooltip="Number of leave days taken for this type of leave since "
                        value={days_confirmed}
                      />
                      <Cell
                        title="Leave Approved"
                        tooltip="Number of leave days approved since "
                        value={days_approved}
                      />
                      <Cell
                        title="Leave Available"
                        tooltip={`We were unable to retrieve any payroll balances for '${type_name}', leave availability has been estimated for the period starting `}
                        value={days_available}
                        showButton={days_available_estimated}
                        buttonIcon="warning"
                        buttonClassName="p-0 text-warning"
                      />
                      <Cell
                        title="Leave Hours"
                        suffix="hour"
                        tooltip={`We were unable to retrieve any payroll balances for '${type_name}', leave availability has been estimated for the period starting `}
                        value={hours_available ? Math.round(hours_available * 100) / 100 : null}
                        showButton={hours_available_estimated}
                        buttonIcon="warning"
                        buttonClassName="p-0 text-warning"
                      />
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}

          {formMessage && <div className="mt-4" />}
          <FriendlyFormMessage
            formMessage={formMessage}
            errors={errors}
            alertColor={alertColor}
            showList
            isOpen={!!errors}
            useSimpleDefault
          />

          <h4 className="mt-4"><Link to="/hrm/national-leave-calendar" target="_blank">Check the National Leave Calendar</Link></h4>
        </CardBody>
      </Card>
    );
  }
}

AvailableLeave.defaultProps = {
  userId: null,
  atDate: null,
  colorTheme: 'primary',
};

AvailableLeave.propTypes = {
  userId: PropTypes.number,
  atDate: PropTypes.instanceOf(Date),
  colorTheme: PropTypes.oneOf(Object.values(THEME_COLOR)),
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
};

export default connectToAPIProvider(AvailableLeave);
