export const DATATABLE_UPDATE_SETTINGS = 'DATATABLE_UPDATE_SETTINGS';

/**
 * @description
 * Mass update settings for a Portal Data Table
 * - Reducer: `DATATABLE_UPDATE_SETTINGS`
 *
 * @param {string} tableIdentifier table identifier in redux, eg. 'projectsTable'
 *  See `initialState` in `table-settings-reducer`
 * @param {{
 *  activePage?: number,
 *  pageSize?: number,
 *  flags?: string[],
 *  searchTerm?: string,
 *  viewKey?: string,
 *  filteredColumns?: {
 *    field: string,
 *    operation: string,
 *    values: string[]
 *  }[],
 *  sortedColumns?: {
 *    name: string,
 *    direction: 'asc' | 'desc',
 *    sortIndex: number
 *  }[],
 * isTightModeEnabled?: boolean,
 * isTextWrappingEnabled?: boolean,
 * reloadTable?: boolean,
 * }} settings table (query) settings to use to load data
 * @param {array} [visibleColumns=[]]
 *
 * @returns {{
 *  type: 'DATATABLE_UPDATE_SETTINGS',
 *  tableIdentifier: import('../../constants/table-identifier.const').A_TABLE_IDENTIFIER,
 *  settings: import('../../types/portal-data-table/portal-data-table-settings.interface').IPortalDataTableSettings
 * }}
 */
export const updateTableSettings = (tableIdentifier, settings) => ({
  type: DATATABLE_UPDATE_SETTINGS,
  tableIdentifier,
  settings,
});
