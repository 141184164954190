import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

/**
 * Table Definition for Commission Structure Iterations Table
 */
const commissionStructureIterationsTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Commission Structure Iteration',
  initialFormFocusFieldName: 'first',
  baseRoute: '/commission-structure/iteration',
  // baseQueryString: [
  //   'with[]=manager:id,name',
  //   'with[]=supportPerson:id,name',
  // ].join('&'),

  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'name',
        'start',
        'end',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'name',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'name',
        'start',
        'end',
      ],
    },
  ],

  /**
   * Definition of columns and APIPolyForm fields for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Identity',
      description: 'Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      linkRoute: '/finance/sales-commission/commission-iterations',
      visible: false,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'name',
      title: 'Name',
      description: 'Commission Structure Iteration Name',
      format: COLUMN_FORMAT.NAME_LINK,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      linkRoute: '/finance/sales-commission/commission-iterations',
      visible: true,
      showInForm: true,
      required: true,
      order: 10,
      formOrder: 10,
    },
    {
      name: 'start',
      title: 'Start',
      description: 'Commission Structure Iteration Start',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: true,
      showInForm: true,
      required: true,
      order: 20,
      formOrder: 20,
    },
    {
      name: 'end',
      title: 'End',
      description: 'Commission Structure Iteration End',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: true,
      showInForm: true,
      required: true,
      order: 30,
      formOrder: 30,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the Commission Structure Iteration',
      successMessage: 'Updated Commission Structure Iteration',
      failMessage: 'Failed to update Commission Structure Iteration',
      hideRecordActionButton: true,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Commission Structure Iteration Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'brackets',
      title: 'Brackets',
      apiRoute: '/bracket',
      description: 'Commission Brackets',
      widgetDefinition: WIDGET_DEFINITION.COMMISSION_STRUCTURE_BRACKETS,
    },
    {
      ...eventsTabDefault,
      description: 'Commission Structure Event Audit Trail',
      apiQuery: [
        'with[]=createdBy:id,name',
        'with[]=commissionStructureBracketTracking:id,data,story',
        'pagelength=20',
      ].join('&'),
    },
  ],
};

export default commissionStructureIterationsTableInitialSettings;
