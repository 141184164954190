import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import PageHeader from '../../app-layout/page-header';
import { YearMonthPicker } from '../../form-input/yearmonth-picker';
import { monthsSelect, projectSummaryYearOptions } from '../../../utils/constants';

const SalesForecastPageHeader = (props) => {
  const {
    startMonth,
    startYear,
    endMonth,
    endYear,
    isFullScreen,
    onSettingsButtonClick,
    onChangeDateRangeElement,
    onSetFullScreen,
    onResetDateRange,
    onToggleStatusTable,
    statusTableVisible,
    hasCacheError,
  } = props;


  const monthOptions = monthsSelect.map((month) => ({ id: month.value, name: month.label }));
  const yearOptions = projectSummaryYearOptions;

  const statusButtonActiveColour = hasCacheError ? 'danger' : 'primary';
  const statusButtonTextColour = hasCacheError ? 'text-danger' : 'text-info';

  /**
   * @description
   * Render out the settings that are displayed at the top right of the page
   */
  const renderSettings = () => (
    <div className="settings">
      <YearMonthPicker
        label="From:"
        month={Number(startMonth)}
        year={Number(startYear)}
        monthOptions={monthOptions}
        yearOptions={yearOptions}
        onChange={({ fieldName, newValue }) => onChangeDateRangeElement('start', fieldName, newValue)}
      />
      <YearMonthPicker
        label="To:"
        month={Number(endMonth)}
        year={Number(endYear)}
        monthOptions={monthOptions}
        yearOptions={yearOptions}
        onChange={({ fieldName, newValue }) => onChangeDateRangeElement('end', fieldName, newValue)}
      />
      <Button onClick={onResetDateRange} title="Reset the Date Range">
        <i className="fa fa-undo" />
      </Button>
      <Button onClick={onToggleStatusTable} title="Status Info" color={statusTableVisible ? statusButtonActiveColour : undefined}>
        <i className={`fa fa-info-circle ${statusTableVisible ? undefined : statusButtonTextColour}`} />
      </Button>
      <Button onClick={onSettingsButtonClick} title="Dashboard Settings">
        <i className="fa fa-cog" />
      </Button>
      <Button onClick={() => onSetFullScreen(!isFullScreen)} title="Full Screen Mode">
        <i className={isFullScreen ? 'ti-close' : 'ti-fullscreen'} />
      </Button>
    </div>
  );

  return (
    <PageHeader {...props} onRenderActions={renderSettings} />
  );
};

SalesForecastPageHeader.propTypes = {
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
  startMonth: PropTypes.number.isRequired,
  endMonth: PropTypes.number.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
  statusTableVisible: PropTypes.bool.isRequired,
  hasCacheError: PropTypes.bool.isRequired,

  onSettingsButtonClick: PropTypes.func.isRequired,
  onChangeDateRangeElement: PropTypes.func.isRequired,
  onSetFullScreen: PropTypes.func.isRequired,
  onResetDateRange: PropTypes.func.isRequired,
  onToggleStatusTable: PropTypes.func.isRequired,
};

SalesForecastPageHeader.defaultProps = {};

export default SalesForecastPageHeader;
