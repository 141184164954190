import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Table } from 'reactstrap';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import { IMyobProjectTransactionQueryResponse } from '../../../types/myob-advanced/myob-project-transaction-query-response.interface';
import Icon from '../../layout-helpers/icon';
import { APIContext } from '../../providers/api-provider';
import { formatValueString } from '../../render-functions';


export const MyobTransactionTable: React.FC<{ projectId: number }> = (props) => {
  const { projectId } = props;
  const { apiFetch } = useContext(APIContext);
  const transactionsAbortController = useRef<AbortController | null>(null);
  const [transactionResponse, setTaskResponse] = useState<IMyobProjectTransactionQueryResponse | null>(null);

  const loadProjectTransactions = useCallback(async () => {
    if (transactionsAbortController.current) {
      transactionsAbortController.current.abort();
    }
    transactionsAbortController.current = apiAborter();

    const response = await apiFetch(
      `/project/${projectId}/myob-transactions`,
      {
        name: 'BillAndPurchaseOrderUpdatePanelOrderList:load',
        signal: transactionsAbortController.current.signal,
      },
    );

    if (response.success) {
      transactionsAbortController.current = null;
      setTaskResponse(response.body);
    } else if (!response.aborted) {
      //
    }
  }, [apiFetch, projectId]);


  useEffect(() => {
    loadProjectTransactions();
  }, [loadProjectTransactions, projectId]);

  return (
    <div className="mt-3">
      <h4>Project Transactions</h4>
      {transactionResponse === null ? <div className="text-center"><Icon i="rolling" /></div> : (
        <Table className="color-table purple-table">
          <thead>
            <tr>
              <th>Account</th>
              {/* <th>State</th> */}
              <th>Task</th>
              <th>Document</th>
              <th>Description</th>
              <th className="text-right currency">Amount</th>
            </tr>
          </thead>
          <tbody>
            {transactionResponse && transactionResponse.Results.map((transaction) => (
              <tr key={transaction.id}>
                <td>
                  {transaction.DebitAccount.value}
                </td>
                {/* State */}
                {/* <td>
              {transaction.DebitSubaccount.value}
            </td> */}
                <td>
                  {transaction.ProjectTask.value}
                </td>
                <td>
                  {transaction.RefNumber.value}
                </td>
                <td>
                  {transaction.Description.value}
                </td>
                <td className="text-right currency">
                  {formatValueString(transaction.Amount.value, COLUMN_FORMAT.CURRENCY)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};
