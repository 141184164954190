import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { NewRecordModalResult } from '../../types/modal/modal-result';
import { ITeamProcessRecord } from '../../types/internal-project/team-process.record.interface';

import { ModalContext } from '../modals/modal-context';
import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { DataTablePageProps } from '../../types/data-table-page.props';

export type TeamProcessesDataTablePageProps = DataTablePageProps;

/**
 * Display the "Team Processes" data table as a whole page
 */
export const TeamProcessesDataTablePage: React.FC<TeamProcessesDataTablePageProps> = ({
  baseFilters,
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const history = useHistory();
  const { showModal } = useContext(ModalContext);

  const actionHandlers = {
    create: () => {
      showModal<NewRecordModalResult<ITeamProcessRecord>>(MODAL_TYPE.NEW_TEAM_PROCESS, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/internal-projects/processes/${modalResult.newRecord.id}`);
        },
      });
    },
  };

  return (
    <DataTablePage
      title="Team Processes"
      tableIdentifier={TABLE_IDENTIFIER.PROCESSES_TABLE}
      actionHandlers={actionHandlers}
      baseFilters={baseFilters}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      {children}
    </DataTablePage>
  );
};
