import axios, { AxiosResponse, Method } from 'axios';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { APIFetchFunction } from '../components/providers/api-provider';
import { IFileRequestTransportResponse } from '../types/file-request-transport-response.interface';
import { IFileRecord } from '../types/file.record.interface';
import { SERObject } from './ser-object.helper';
import { IAPIFileTransport } from '../types/api-file-transport.interface';
import { IAPIAction } from '../types/api-action.interface';
import { IFileConfirmUploadResponse } from '../types/file-confirm-upload-response.interface';
import { apiAborter } from './api-aborter.helper';
import { localStorageLoad } from '../utils/localStorage';
import { LOCAL_STORAGE_KEYS } from '../utils/local-storage-keys';

const apiPath = process.env.REACT_APP_API;
const apiVersion = process.env.REACT_APP_API_VERSION;

/**
 * Request the upload of a project file
 *
 * This is the first step in the upload progress so store the response of this method for
 * use in the remaining upload, confirm &/or cancel methods.
 */
export const requestFileUpload = async <T extends IFileRecord>(
  apiEndpoint: string,
  apiFetch: APIFetchFunction<IFileRequestTransportResponse<T>>,
  abortController: null | AbortController,
  onUpdateAbortController: (updatedAbortController: AbortController | null) => void,
  file: File,
  otherData?: Record<string, unknown>,
): Promise<SERObject<IFileRequestTransportResponse<T>>> => {
  const result = new SERObject<IFileRequestTransportResponse<T>>(false);

  // Abort any previous file transfer calls
  if (abortController) {
    abortController.abort();
  }

  // Create a new Abort Controller and pass it back to the owner (for termination signalling)
  abortController = apiAborter();
  if (onUpdateAbortController) {
    onUpdateAbortController(abortController);
  }

  // Perform the fetch
  const response = await apiFetch(
    apiEndpoint,
    {
      method: HTTP_METHOD.POST,
      name: 'requestFileUpload',
      body: {
        filename: file.name,
        ...otherData,
      },
      signal: abortController.signal,
    },
  );

  if (response.success) {
    if (onUpdateAbortController) {
      onUpdateAbortController(null);
    }

    result.success = true;
    result.result = response.body;
  } else if (!response.aborted) {
    if (onUpdateAbortController) {
      onUpdateAbortController(null);
    }

    console.error('requestFileUpload: failed to request upload of file. ', response.error);
    result.error = response.error;
  }

  return result;
};


/**
 * Actually perform the upload of a file
 *
 * You can get the transport parameter from the response of the `requestFileUpload` method
 */
export const uploadFile = async (
  file: File,
  transport: IAPIFileTransport,
  onProgress?: (progress: number) => void,
): Promise<SERObject> => {
  const uploadResult = new SERObject(false);
  const axiosCancelToken = axios.CancelToken.source();
  let progress = 0;

  try {
    // Begin the Upload
    const response = await axios.request({
      url: transport.url,
      data: file,
      method: (transport.method ? transport.method.toLowerCase() : 'post') as Method,
      cancelToken: axiosCancelToken.token,
      headers: {
        accept: 'application/json',
      },
      onUploadProgress: ({ lengthComputable, total, loaded, type }: {
        lengthComputable?: boolean,
        total?: number,
        loaded?: number,
        type?: string,
      }) => {
        let newProgress = 0;
        if (type === 'progress' && lengthComputable && (total !== undefined) && (loaded !== undefined)) {
          newProgress = (loaded / total) * 100;
        }

        if ((newProgress !== progress) && onProgress) {
          onProgress(progress);
        }

        progress = newProgress;
      },
    });

    // Success
    if (response.status >= 200 && response.status <= 300) {
      uploadResult.success = true;

      // Indicate the upload completion
      if (onProgress) {
        onProgress(100);
      }
    }

    // Fail
    else {
      uploadResult.error = `${response.status}: ${response.data}`;
    }

  // Transport Error
  } catch (error) {
    console.error('uploadFile error: ', { error });
    if (axios.isAxiosError(error)) {
      uploadResult.error = error.response ? `${error.response.status}: ${error.response.data}` : error.message;
    } else {
      uploadResult.error = 'Unknown Error';
    }
  }

  return uploadResult;
};

export const uploadFileDirect = async <T>(
  formData: FormData,
  transport: IAPIFileTransport,
  onProgress?: (progress: number) => void,
): Promise<SERObject<T>> => {
  const uploadResult = new SERObject<T>(false);
  const axiosCancelToken = axios.CancelToken.source();
  let progress = 0;

  const token = localStorageLoad(LOCAL_STORAGE_KEYS.API_AUTH_TOKEN);

  try {
    // Begin the Upload
    // @todo Perhaps this should be in src/helpers/file-upload.helper.ts
    const response: AxiosResponse<{ data: T }> = await axios.request({
      url: `${apiPath}${apiVersion}${transport.url}`,
      data: formData,
      cancelToken: axiosCancelToken.token,
      method: (transport.method ? transport.method.toLowerCase() : 'post') as Method,
      headers: {
        Authorization: `Bearer ${token.access_token}`,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: ({ lengthComputable, total, loaded, type }: {
        lengthComputable?: boolean,
        total?: number,
        loaded?: number,
        type?: string,
      }) => {
        let newProgress = 0;
        if (type === 'progress' && lengthComputable && (total !== undefined) && (loaded !== undefined)) {
          newProgress = (loaded / total) * 100;
        }

        if ((newProgress !== progress) && onProgress) {
          onProgress(progress);
        }
        progress = newProgress;
      },
    });


    // Success
    if (response.status >= 200 && response.status <= 300) {
      uploadResult.success = true;

      uploadResult.result = response.data.data;

      // Indicate the upload completion
      if (onProgress) {
        onProgress(100);
      }
    }

    // Fail
    else {
      uploadResult.error = `${response.status}: ${response.data}`;
    }

  // Transport Error
  } catch (error) {
    console.error('uploadFile error: ', { error });
    if (axios.isAxiosError(error)) {
      uploadResult.error = error.response ? `${error.response.status}: ${error.response.data}` : error.message;
    } else {
      uploadResult.error = 'Unknown Error';
    }
  }
  return uploadResult;
};

/**
 * Confirm a successfully uploaded file.
 *
 * You need to pass the apiFetch function from the API Provider
 * You can get the confirmAction from the response of the `requestFileUpload` function
 * The abortController is optional here
 */
export const confirmFileUpload = async <T extends IFileRecord>(
  apiFetch: APIFetchFunction<IFileConfirmUploadResponse<T>>,
  confirmAction: IAPIAction,
  abortController?: null | AbortController,
  onUpdateAbortController?: (updatedAbortController: AbortController | null) => void,
): Promise<SERObject<IFileConfirmUploadResponse<T>>> => {
  const result = new SERObject<IFileConfirmUploadResponse<T>>(false);

  // Abort any previous file transfer calls
  if (abortController) {
    abortController.abort();
  }

  // Create a new Abort Controller and pass it back to the owner (for termination signalling)
  abortController = apiAborter();
  if (onUpdateAbortController) {
    onUpdateAbortController(abortController);
  }

  // perform the fetch
  const response = await apiFetch(
    confirmAction.link,
    {
      method: confirmAction.method,
      name: 'confirmFileUpload',
      signal: abortController.signal,
    },
  );

  if (response.success) {
    if (onUpdateAbortController) {
      onUpdateAbortController(null);
    }

    result.success = true;
    result.result = response.body;
  } else if (!response.aborted) {
    if (onUpdateAbortController) {
      onUpdateAbortController(null);
    }

    console.error('confirmFileUpload: failed to confirm upload of a file. ', response.error);
    result.error = response.error;
  }

  return result;
};


/**
 * Cancel a partially uploaded file
 *
 * You can get the confirmAction from the response of the `requestFileUpload` function
 * There is no abortController for a cancel upload method.
 * Don't `await` this method, just call it.
 */
export const cancelFileUpload = async <T extends IFileRecord>(
  apiFetch: APIFetchFunction<IFileConfirmUploadResponse<T>>,
  cancelAction: IAPIAction,
): Promise<SERObject<T>> => {
  const result = new SERObject<T>(false);

  const response = await apiFetch(
    cancelAction.link,
    {
      method: cancelAction.method,
      name: 'cancelFileUpload',
      // signal: probably not required here.
    },
  );

  if (response.success) {
    result.success = true;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    result.result = response.body!.data;
  } else if (!response.aborted) {
    console.error('cancelFileUpload: failed to cancel upload of a file. ', response.error);
    result.error = response.error;
  }

  return result;
};
