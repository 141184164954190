/**
 * Transition direction is used in conjunction with TRANSITION_STATE to determine
 * which direction a transition is happening
 */
export const TRANSITION_DIRECTION = {
  LEFT: 'left',
  RIGHT: 'right',
} as const;

export type TRANSITION_DIRECTION = typeof TRANSITION_DIRECTION;
export type A_TRANSITION_DIRECTION = TRANSITION_DIRECTION[keyof TRANSITION_DIRECTION];
