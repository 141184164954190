import React from 'react';
import { Card, CardBody } from 'reactstrap';
import internalProjectTasksTableInitialSettings from '../../table-definitions/internal-project-tasks-table';
import { IInternalProjectTaskRecord } from '../../types/internal-project/internal-project-task.record.interface';
import { ISprintRecord } from '../../types/internal-project/sprint.record.interface';
import { WidgetProps } from '../../types/widget.props';
import { CardSwimlanes } from '../project-card-view/card-swimlanes';


export type SprintSwimlanesWidgetProps = Omit<WidgetProps, 'widgetData' | 'rowData'> & {
  // eslint-disable-next-line react/no-unused-prop-types
  rowData: ISprintRecord,
  widgetData: IInternalProjectTaskRecord[],
}

export const SprintSwimlanesWidget: React.FC<SprintSwimlanesWidgetProps> = (props) => {
  const { widgetData } = props;
  return (
    <Card className="card-view compact">
      <CardBody>
        {widgetData && (
          <CardSwimlanes
            cardItems={widgetData}
            cardLinkPath="/internal-projects/tasks"
            tableSettings={internalProjectTasksTableInitialSettings}
          />
        )}
      </CardBody>
    </Card>
  );
};
