import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';

import { IProjectRecord } from '../../../types/project/project.record.interface';

import { APIContext } from '../../providers/api-provider';
import { MSTeamsContext } from '../../layout-helpers/msteams-provider';

import Icon from '../../layout-helpers/icon';

import { apiAborter } from '../../../helpers/api-aborter.helper';
import FriendlyFormMessage from '../../layout-helpers/friendly-form-message';


export type MicrosoftServicesProjectAdminWidgetSectionProps = {
  rowData: IProjectRecord,
  refreshRecord: () => void,
}


type MicrosoftGraphIntegrationState = {
  isCreatingGroup: boolean,
  groupId: null | string,

  isLinkingGroupDriveItem: boolean,
  groupDriveItemId: null | string,
  groupDriveItemUrl: null | string,

  isCreatingTeam: boolean,
  teamId: null | string,
  teamUrl: null | string,
}

type ProcessActionState = {
  isProcessing: boolean,
  lastSuccess: null | boolean,
  lastMessage: null | string,
}


/**
 * @description
 * Returns true if any of the attributes on the project record that are microsoft integration IDs are "TBA" (i.e. linking / generating)
 */
function isMicrosoftIntegrationAttributePending(isResourceIdPending: (resourceId: null | string) => boolean, resourceIds: {
  ms_group_id: null | string,
  ms_group_drive_item_id: null | string,
  ms_team_id: null | string,
}) {
  return (
    isResourceIdPending(resourceIds.ms_group_id) ||
    isResourceIdPending(resourceIds.ms_group_drive_item_id) ||
    isResourceIdPending(resourceIds.ms_team_id)
  );
}


/**
 * This widget displays the Project Resources by wrapping a table widget in a custom widget
 * for displaying other components
 *
 * TODO: Action to Sync Resources
 * TODO: Action to Sync Drive Items
 * TODO: Action to Sync Group and Team Name
 */
export const MicrosoftServicesProjectAdminWidgetSection:React.FC<MicrosoftServicesProjectAdminWidgetSectionProps> = (props) => {
  const {
    rowData,
    refreshRecord,
  } = props;

  const { apiFetch } = useContext(APIContext);
  const {
    getMsTeamsUrl,
    getMsTeamsUrlTarget,
    isResourceIdPending,
    isResourceIdValid,
  } = useContext(MSTeamsContext);

  const {
    id: projectId,
    ms_group_id: msGroupId,
    ms_group_drive_item_id: msGroupDriveItemId,
    ms_group_drive_item_url: msGroupDriveItemURL,
    ms_team_id: msTeamId,
    ms_team_url: msTeamURL,
  } = rowData;

  const [shouldPollIntegrationState, setShouldPollIntegrationState] = useState<boolean>(isMicrosoftIntegrationAttributePending(isResourceIdPending, rowData));

  // Create a state for the re-sync microsoft services
  const [microsoftIntegrationState, setMicrosoftIntegrationState] = useState<MicrosoftGraphIntegrationState>({
    isCreatingGroup: isResourceIdPending(msGroupId),
    groupId: msGroupId,

    isLinkingGroupDriveItem: isResourceIdPending(msGroupDriveItemId),
    groupDriveItemId: msGroupDriveItemId,
    groupDriveItemUrl: msGroupDriveItemURL,

    isCreatingTeam: isResourceIdPending(msTeamId),
    teamId: msTeamId,
    teamUrl: msTeamURL,
  });

  const [manuallyCreateProjectGroupState, setManuallyCreateProjectGroupState] = useState<ProcessActionState>({ isProcessing: false, lastSuccess: null, lastMessage: null });
  const [manuallyPrepareMSDriveResourcesState, setManuallyPrepareMSDriveResourcesState] = useState<ProcessActionState>({ isProcessing: false, lastSuccess: null, lastMessage: null });
  const [manuallyCreateProjectTeamState, setManuallyCreateProjectTeamState] = useState<ProcessActionState>({ isProcessing: false, lastSuccess: null, lastMessage: null });
  const [manuallyResyncProjectResourcesWithGroupState, setManuallyResyncProjectResourcesWithGroupState] = useState<ProcessActionState>({
    isProcessing: false,
    lastSuccess: null,
    lastMessage: null,
  });

  const pollIntegrationStateInterval = useRef<null | ReturnType<typeof setInterval>>(null);
  const abortPollIntegrationState = useRef<null | AbortController>(null);
  const abortCreateProjectGroup = useRef<null | AbortController>(null);
  const abortPrepareMSDriveResources = useRef<null | AbortController>(null);
  const abortCreateProjectTeam = useRef<null | AbortController>(null);
  const abortResyncProjectResourcesWithGroupState = useRef<null | AbortController>(null);


  /**
   * Manually attempt to create or wakeup the project group creation
   */
  const manuallyCreateProjectGroup = useCallback(async () => {
    // Don't allow this method to run twice concurrently
    if (manuallyCreateProjectGroupState.isProcessing) return;

    setManuallyCreateProjectGroupState({
      isProcessing: true,
      lastSuccess: null,
      lastMessage: null,
    });

    setMicrosoftIntegrationState((oldState: MicrosoftGraphIntegrationState) => ({
      ...oldState,
      isCreatingGroup: true,
      groupId: 'TBA',
    }));

    setShouldPollIntegrationState(true);

    if (abortCreateProjectGroup.current) {
      abortCreateProjectGroup.current.abort();
    }
    abortCreateProjectGroup.current = apiAborter();

    // Call the API
    const response = await apiFetch(
      `/project/${projectId}/action/create-ms-group`,
      {
        method: HTTP_METHOD.POST,
        name: 'ProjectAdminWidget::manuallyCreateProjectGroup',
        signal: abortCreateProjectGroup.current.signal,
      },
    );

    if (response.success) {
      abortCreateProjectGroup.current = null;
      const { success, error, ms_group_id } = response.body;

      // Update the integration state
      setMicrosoftIntegrationState((oldState: MicrosoftGraphIntegrationState) => ({
        ...oldState,
        isCreatingGroup: isResourceIdPending(ms_group_id),
        groupId: ms_group_id,
      }));

      // Update the action state
      setManuallyCreateProjectGroupState({
        isProcessing: false,
        lastSuccess: success,
        lastMessage: error,
      });

      // If the resource is no longer pending - refresh the parent
      if (!isResourceIdPending(ms_group_id)) {
        refreshRecord();
      }
    } else if (!response.aborted) {
      abortCreateProjectGroup.current = null;
      console.error('ProjectAdminWidget::manuallyCreateProjectGroup', response.error);

      // Update the action state
      setManuallyCreateProjectGroupState({
        isProcessing: false,
        lastSuccess: false,
        lastMessage: response.error,
      });
    }
  }, [manuallyCreateProjectGroupState.isProcessing, apiFetch, projectId, isResourceIdPending, refreshRecord]);


  /**
   * Manually attempt to link or wakeup the project drive link operation
   */
  const manuallyPrepareMSDriveResources = useCallback(async () => {
    // Don't allow this method to run twice concurrently
    if (manuallyPrepareMSDriveResourcesState.isProcessing) return;

    setManuallyPrepareMSDriveResourcesState({
      isProcessing: true,
      lastSuccess: null,
      lastMessage: null,
    });

    setMicrosoftIntegrationState((oldState: MicrosoftGraphIntegrationState) => ({
      ...oldState,
      isLinkingGroupDriveItem: true,
      groupDriveItemId: 'TBA',
      groupDriveItemUrl: 'TBA',
    }));

    setShouldPollIntegrationState(true);

    if (abortPrepareMSDriveResources.current) {
      abortPrepareMSDriveResources.current.abort();
    }
    abortPrepareMSDriveResources.current = apiAborter();

    // Call the API
    const response = await apiFetch(
      `/project/${projectId}/action/prepare-ms-drive-resources`,
      {
        method: HTTP_METHOD.POST,
        name: 'ProjectAdminWidget::manuallyPrepareMSDriveResources',
        signal: abortPrepareMSDriveResources.current.signal,
      },
    );

    if (response.success) {
      abortPrepareMSDriveResources.current = null;
      const { success, error, ms_group_drive_item_id, ms_group_drive_item_url } = response.body;

      // Update the integration state
      setMicrosoftIntegrationState((oldState: MicrosoftGraphIntegrationState) => ({
        ...oldState,
        isLinkingGroupDriveItem: isResourceIdPending(ms_group_drive_item_id),
        groupDriveItemId: ms_group_drive_item_id,
        groupDriveItemUrl: ms_group_drive_item_url,
      }));

      // Update the action state
      setManuallyPrepareMSDriveResourcesState({
        isProcessing: false,
        lastSuccess: success,
        lastMessage: error,
      });

      // If the resource is no longer pending - refresh the parent
      if (!isResourceIdPending(ms_group_drive_item_id)) {
        refreshRecord();
      }
    } else if (!response.aborted) {
      abortPrepareMSDriveResources.current = null;
      console.error('ProjectAdminWidget::manuallyPrepareMSDriveResources', response.error);

      // Update the action state
      setManuallyPrepareMSDriveResourcesState({
        isProcessing: false,
        lastSuccess: false,
        lastMessage: response.error,
      });
    }
  }, [manuallyPrepareMSDriveResourcesState.isProcessing, apiFetch, projectId, isResourceIdPending, refreshRecord]);


  /**
   * Manually attempt to create a project team
   */
  const manuallyCreateProjectTeam = useCallback(async () => {
    // Don't allow this method to run twice concurrently
    if (manuallyCreateProjectTeamState.isProcessing) return;

    setManuallyCreateProjectTeamState({
      isProcessing: true,
      lastSuccess: null,
      lastMessage: null,
    });

    setMicrosoftIntegrationState((oldState: MicrosoftGraphIntegrationState) => ({
      ...oldState,
      isCreatingTeam: true,
      teamId: 'TBA',
      teamUrl: 'TBA',
    }));

    setShouldPollIntegrationState(true);

    if (abortCreateProjectTeam.current) {
      abortCreateProjectTeam.current.abort();
    }
    abortCreateProjectTeam.current = apiAborter();

    // Call the API
    const response = await apiFetch(
      `/project/${projectId}/action/create-ms-team`,
      {
        method: HTTP_METHOD.POST,
        name: 'ProjectAdminWidget::manuallyCreateProjectTeam',
        signal: abortCreateProjectTeam.current.signal,
      },
    );

    if (response.success) {
      abortCreateProjectTeam.current = null;
      const { success, error, ms_team_id, ms_team_url } = response.body;

      // Update the integration state
      setMicrosoftIntegrationState((oldState: MicrosoftGraphIntegrationState) => ({
        ...oldState,
        isCreatingTeam: isResourceIdPending(ms_team_id),
        teamId: ms_team_id,
        teamUrl: ms_team_url,
      }));

      // Update the action state
      setManuallyCreateProjectTeamState({
        isProcessing: false,
        lastSuccess: success,
        lastMessage: error,
      });

      // If the resource is no longer pending - refresh the parent
      if (!isResourceIdPending(ms_team_id)) {
        refreshRecord();
      }
    } else if (!response.aborted) {
      abortCreateProjectTeam.current = null;
      console.error('ProjectAdminWidget::manuallyCreateProjectTeam', response.error);

      // Update the action state
      setManuallyCreateProjectTeamState({
        isProcessing: false,
        lastSuccess: false,
        lastMessage: response.error,
      });
    }
  }, [manuallyCreateProjectTeamState.isProcessing, apiFetch, projectId, isResourceIdPending, refreshRecord]);


  /**
   * Manually resync the resources attached to a group
   */
  const manuallyResyncProjectResourcesWithGroup = useCallback(async () => {
    // Don't allow this method to run twice concurrently
    if (manuallyResyncProjectResourcesWithGroupState.isProcessing) return;

    setManuallyResyncProjectResourcesWithGroupState({
      isProcessing: true,
      lastSuccess: null,
      lastMessage: null,
    });

    if (abortResyncProjectResourcesWithGroupState.current) {
      abortResyncProjectResourcesWithGroupState.current.abort();
    }
    abortResyncProjectResourcesWithGroupState.current = apiAborter();

    // Call the API
    const response = await apiFetch(
      `/project/${projectId}/action/sync-project-resources-with-ms-group`,
      {
        method: HTTP_METHOD.POST,
        name: 'ProjectAdminWidget::manuallyResyncProjectResourcesWithGroup',
        signal: abortResyncProjectResourcesWithGroupState.current.signal,
      },
    );

    if (response.success) {
      abortResyncProjectResourcesWithGroupState.current = null;
      const { success, error, addedOwners, removedOwners, addedMembers, removedMembers } = response.body;

      // Update the action state
      setManuallyResyncProjectResourcesWithGroupState({
        isProcessing: false,
        lastSuccess: success,
        lastMessage: error ?? `
          ${addedOwners.length ? `${addedOwners.length} owners added. ` : ''}
          ${removedOwners.length ? `${removedOwners.length} owners removed. ` : ''}
          ${addedMembers.length ? `${addedMembers.length} members added. ` : ''}
          ${removedMembers.length ? `${removedMembers.length} members removed. ` : ''}
          ${(addedOwners.length + removedOwners.length + addedMembers.length + removedMembers.length) ? '' : 'No Changes made to group owners or members.'}
        `,
      });
    } else if (!response.aborted) {
      abortResyncProjectResourcesWithGroupState.current = null;
      console.error('ProjectAdminWidget::manuallyResyncProjectResourcesWithGroup', response.error);

      // Update the action state
      setManuallyResyncProjectResourcesWithGroupState({
        isProcessing: false,
        lastSuccess: false,
        lastMessage: response.error,
      });
    }
  }, [manuallyResyncProjectResourcesWithGroupState.isProcessing, apiFetch, projectId]);


  /**
   * Fired by the timer that is set up to continuously check on the microsoft integration services if we're waiting on anything to complete
   */
  const pollIntegrationState = useCallback(async () => {
    // Kill the interval if we're no longer waiting on anything to complete when this method fires
    if (!shouldPollIntegrationState) {
      if (pollIntegrationStateInterval.current) {
        clearInterval(pollIntegrationStateInterval.current);
        pollIntegrationStateInterval.current = null;
      }
      return;
    }

    if (abortPollIntegrationState.current) {
      abortPollIntegrationState.current.abort();
    }
    abortPollIntegrationState.current = apiAborter();

    // Call the API
    const response = await apiFetch(
      `/project/${projectId}`,
      {
        name: 'ProjectAdminWidget::pollIntegrationState',
        signal: abortPollIntegrationState.current.signal,
      },
    );

    if (response.success) {
      abortPollIntegrationState.current = null;
      const { data: project } = response.body;

      // Update the integration state
      setMicrosoftIntegrationState((oldState: MicrosoftGraphIntegrationState) => ({
        ...oldState,
        isCreatingGroup: isResourceIdPending(project.ms_group_id),
        groupId: project.ms_group_id,

        isLinkingGroupDriveItem: isResourceIdPending(project.ms_group_drive_item_id),
        groupDriveItemId: project.ms_group_drive_item_id,
        groupDriveItemUrl: project.ms_group_drive_item_url,

        isCreatingTeam: isResourceIdPending(project.ms_team_id),
        teamId: project.ms_team_id,
        teamUrl: project.ms_team_url,
      }));

      // If none of the important ids are pending any more, refresh the parent to reload the data
      if (!isMicrosoftIntegrationAttributePending(isResourceIdPending, project)) {
        refreshRecord();
      }
    } else if (!response.aborted) {
      abortPollIntegrationState.current = null;
      console.error('ProjectAdminWidget::pollIntegrationState', response.error);
    }
  }, [apiFetch, shouldPollIntegrationState, projectId, refreshRecord, isResourceIdPending]);


  /**
   * Whenever we arrive at this page and the row data indicates we're waiting for something to be created,
   * periodically poll the API for changes.
   */
  useEffect(() => {
    if (pollIntegrationStateInterval.current) {
      clearInterval(pollIntegrationStateInterval.current);
      pollIntegrationStateInterval.current = null;
    }

    // Check back periodically to see if the operations have completed
    if (shouldPollIntegrationState) {
      pollIntegrationStateInterval.current = setInterval(pollIntegrationState, 5000);
    }

    return () => {
      if (pollIntegrationStateInterval.current) {
        clearInterval(pollIntegrationStateInterval.current);
        pollIntegrationStateInterval.current = null;
      }
    };
  }, [shouldPollIntegrationState, pollIntegrationState]);


  /**
   * Whenever the row data changes - evaluate the integrations to see if we need to poll their state
   */
  useEffect(() => {
    setShouldPollIntegrationState(isMicrosoftIntegrationAttributePending(isResourceIdPending, rowData));
  }, [isResourceIdPending, rowData]);


  /**
   * (ComponentWillMount)
   */
  useEffect(() => () => {
    // (ComponentwillUnMount)
    if (abortPollIntegrationState.current) abortPollIntegrationState.current.abort();
  }, []);


  /**
   * Render
   */
  return (
    <div className="function-section">
      <h4>Microsoft Services Integration</h4>
      <p>
        Use the following information to ensure this project is correctly connected to the Microsoft ecosystem
        (Active Directory, SharePoint, OneDrive and Teams).
      </p>

      <ul>
        {/* ms_group_id */}
        <li>
          <span>Microsoft Active Directory Group: </span>

          {/* Has group */}
          {isResourceIdValid(microsoftIntegrationState.groupId) && (
            <span>
              <span>{microsoftIntegrationState.groupId}</span>
              {!manuallyResyncProjectResourcesWithGroupState.isProcessing && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href="#" onClick={manuallyResyncProjectResourcesWithGroup}> [RESYNC MEMBERS]</a>
              )}
              {manuallyResyncProjectResourcesWithGroupState.isProcessing && (
                <span>
                  <span> </span>
                  <Icon isBusy i="spin" />
                  <span> Resyncing...</span>
                </span>
              )}
            </span>
          )}

          {/* No group - creating at the moment */}
          {!isResourceIdValid(microsoftIntegrationState.groupId) && microsoftIntegrationState.isCreatingGroup && (
            <span>
              <Icon isBusy i="spin" />
              <span> Creating...</span>
              {!manuallyPrepareMSDriveResourcesState.isProcessing && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href="#" onClick={manuallyCreateProjectGroup}> [POKE]</a>
              )}
            </span>
          )}

          {/* No group - Not Creating at the moment */}
          {!isResourceIdValid(microsoftIntegrationState.groupId) && !microsoftIntegrationState.isCreatingGroup && (
            <span>
              <span>Not Created.</span>
              {!manuallyPrepareMSDriveResourcesState.isProcessing && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href="#" onClick={manuallyCreateProjectGroup}> [CREATE NOW]</a>
              )}
            </span>
          )}
        </li>

        {/* ms_group_drive_item_id */}
        <li>
          <span>
            <span>Project Drive: </span>
            {!manuallyPrepareMSDriveResourcesState.isProcessing && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a href="#" onClick={manuallyPrepareMSDriveResources}> [SYNC ALL]</a>
            )}
          </span>
          {/* Has Project Drive */}
          {isResourceIdValid(microsoftIntegrationState.groupDriveItemId) && (
            <ul>
              <li>
                <span>{`Id: ${microsoftIntegrationState.groupDriveItemId}`}</span>
              </li>
              <li>
                <span>URL: </span>
                <a href={microsoftIntegrationState.groupDriveItemUrl ?? '#'} target="_blank" rel="noopener noreferrer">{microsoftIntegrationState.groupDriveItemUrl}</a>
              </li>
            </ul>
          )}

          {/* No Project Drive - linking at the moment */}
          {!isResourceIdValid(microsoftIntegrationState.groupDriveItemId) && microsoftIntegrationState.isLinkingGroupDriveItem && (
            <span>
              <Icon isBusy i="spin" />
              <span> Linking...</span>
              {!manuallyPrepareMSDriveResourcesState.isProcessing && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href="#" onClick={manuallyPrepareMSDriveResources}> [POKE]</a>
              )}
            </span>
          )}

          {/* No Project Drive - linking at the moment */}
          {!isResourceIdValid(microsoftIntegrationState.groupDriveItemId) && !microsoftIntegrationState.isLinkingGroupDriveItem && (
            <span>
              <span>Not Linked.</span>
              {!manuallyPrepareMSDriveResourcesState.isProcessing && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href="#" onClick={manuallyPrepareMSDriveResources}> [LINK NOW]</a>
              )}
            </span>
          )}
        </li>

        {/* ms_team_id */}
        <li>
          <span>Microsoft Team: </span>
          {/* Has Team */}
          {isResourceIdValid(microsoftIntegrationState.teamId) && (
            <ul>
              <li>
                <span>{`Id: ${microsoftIntegrationState.teamId}`}</span>
              </li>
              <li>
                <span>URL: </span>
                <a href={getMsTeamsUrl(microsoftIntegrationState.teamUrl)} target={getMsTeamsUrlTarget()} rel="noopener noreferrer">{microsoftIntegrationState.teamUrl}</a>
              </li>
            </ul>
          )}

          {/* No Team - creating at the moment */}
          {!isResourceIdValid(microsoftIntegrationState.teamId) && microsoftIntegrationState.isCreatingTeam && (
            <span>
              <Icon isBusy i="spin" />
              <span> Creating...</span>
              {!manuallyCreateProjectTeamState.isProcessing && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href="#" onClick={manuallyCreateProjectTeam}> [POKE]</a>
              )}
            </span>
          )}

          {/* No Team - not creating one */}
          {!isResourceIdValid(microsoftIntegrationState.teamId) && !microsoftIntegrationState.isCreatingTeam && (
            <span>
              <span>Not Created.</span>
              {!manuallyPrepareMSDriveResourcesState.isProcessing && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href="#" onClick={manuallyCreateProjectTeam}> [CREATE NOW]</a>
              )}
            </span>
          )}
          <span />
        </li>
      </ul>

      {/* Manually Create Project Group Action Result */}
      {!manuallyCreateProjectGroupState.isProcessing && manuallyCreateProjectGroupState.lastMessage && (
        <FriendlyFormMessage
          formMessage={manuallyCreateProjectGroupState.lastMessage}
          alertColor={manuallyCreateProjectGroupState.lastSuccess ? 'success' : 'danger'}
          useSimpleDefault
        />
      )}

      {/* Manually Link Drive Item Action Result */}
      {!manuallyPrepareMSDriveResourcesState.isProcessing && manuallyPrepareMSDriveResourcesState.lastMessage && (
        <FriendlyFormMessage
          formMessage={manuallyPrepareMSDriveResourcesState.lastMessage}
          alertColor={manuallyPrepareMSDriveResourcesState.lastSuccess ? 'success' : 'danger'}
          useSimpleDefault
        />
      )}

      {/* Manually Create Project Team Action Result */}
      {!manuallyCreateProjectTeamState.isProcessing && manuallyCreateProjectTeamState.lastMessage && (
        <FriendlyFormMessage
          formMessage={manuallyCreateProjectTeamState.lastMessage}
          alertColor={manuallyCreateProjectTeamState.lastSuccess ? 'success' : 'danger'}
          useSimpleDefault
        />
      )}

      {/* Manually Resync Project Group Members Action Result */}
      {!manuallyResyncProjectResourcesWithGroupState.isProcessing && manuallyResyncProjectResourcesWithGroupState.lastMessage && (
        <FriendlyFormMessage
          formMessage={manuallyResyncProjectResourcesWithGroupState.lastMessage}
          alertColor={manuallyResyncProjectResourcesWithGroupState.lastSuccess ? 'success' : 'danger'}
          useSimpleDefault
        />
      )}

    </div>
  );
};
