import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Table } from 'reactstrap';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import { IMyobProjectTaskRecord } from '../../../types/myob-advanced/myob-project-task.record.interface';
import Icon from '../../layout-helpers/icon';
import { APIContext } from '../../providers/api-provider';


export const MyobTaskTable: React.FC<{ myobProjectId: string }> = (props) => {
  const { myobProjectId } = props;
  const { apiFetch } = useContext(APIContext);
  const tasksAbortController = useRef<AbortController | null>(null);
  const [taskResponse, setTaskResponse] = useState<IMyobProjectTaskRecord[] | null>(null);

  const loadProjectTasks = useCallback(async () => {
    if (tasksAbortController.current) {
      tasksAbortController.current.abort();
    }
    tasksAbortController.current = apiAborter();

    const response = await apiFetch(
      `/myob-advanced-connector/project/${myobProjectId}/tasks`,
      {
        name: 'BillAndPurchaseOrderUpdatePanelOrderList:load',
        signal: tasksAbortController.current.signal,
      },
    );

    if (response.success) {
      tasksAbortController.current = null;
      setTaskResponse(response.body);
    } else if (!response.aborted) {
      //
    }
  }, [apiFetch, myobProjectId]);


  useEffect(() => {
    loadProjectTasks();
  }, [loadProjectTasks, myobProjectId]);

  return (
    <div className="mt-3">
      <h4>Project Tasks</h4>
      {taskResponse === null ? <div className="text-center"><Icon i="rolling" /></div> : (
        <Table className="color-table purple-table">
          <thead>
            <tr>
              <th>Task</th>
              <th>Description</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {taskResponse && taskResponse.map((task) => (
              <tr key={task.ProjectTaskID.value}>
                <td>
                  {task.ProjectTaskID.value}
                </td>
                <td>
                  {task.Description.value}
                </td>
                <td>
                  {task.Status.value}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};
