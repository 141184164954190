import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';

export const InternalProjectTasksWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Task',
  fields: [
    {
      name: 'internal_project_id',
      isParentId: true,
      visible: false,
    },
    {
      name: 'id',
      title: 'ID',
      visible: false,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      linkRoute: '/internal-projects/tasks',
    },
    {
      name: 'name',
      title: 'Task Name',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: '/internal-projects/tasks',
    },
    {
      name: 'description',
      title: 'Task Description',
      format: COLUMN_FORMAT.STRIP_HTML,
    },
    {
      name: 'priority',
      title: 'Priority',
      format: COLUMN_FORMAT.NUMBER_SHORT,
    },
    {
      name: 'progress',
      title: 'Progress',
      format: COLUMN_FORMAT.PROGRESS_PERCENT,
      formFieldType: FORM_FIELD_TYPE.PROGRESS_PERCENT_INPUT,
    },
    {
      name: 'sprint',
      title: 'Sprint',
      description: 'Current Sprint of Task',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'sprint',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/internal-projects/sprints',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.SPRINT_PICKER,
      formSaveField: 'sprint_id',
    },
    {
      name: 'assigned_to',
      title: 'Assigned To',
      formSaveField: 'assigned_to_id',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'assigned_to',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
    },
    {
      name: 'estimated_hours',
      title: 'Estimated Time',
      description: "Project's estimated labour hours",
      format: COLUMN_FORMAT.DURATION_BUSINESS_TIME,
    },
    {
      name: 'external_url',
      title: 'External Link',
      format: COLUMN_FORMAT.ABBREVIATED_EXT_LINK,
    },
  ],
};
