import React, { SyntheticEvent, useContext, useState, useCallback } from 'react';
import { Card, CardHeader, CardBody, Form, InputGroup, Input, InputGroupAddon, Button } from 'reactstrap';
import { useHistory } from 'react-router';


import { CurrentUserContext } from '../providers/current-user-provider';
import { ModalContext, ShowModalMethod } from '../modals/modal-context';

import { ActionButton } from '../record-action-buttons/action-button';

import { ICON } from '../../constants/icon.const';
import { PERMISSION } from '../../constants/permissions.const';
import { IActionButton } from '../../types/action-button.interface';
import { ILeaveApplicationRecord } from '../../types/leave/leave-application.record.interface';
import { IExpenseClaimRecord } from '../../types/expense-claim/expense-claim.record.interface';
import { IAllowanceRecord } from '../../types/allowance/allowance.record.interface';
import { ICompanyRecord } from '../../types/company/company.record.interface';
import { IContactRecord } from '../../types/contact/contact.record.interface';
import { IUserRecord } from '../../types/user/user.record.interface';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { NewRecordModalResult } from '../../types/modal/modal-result';
import Icon from '../layout-helpers/icon';

/**
 * These are the buttons to render on the landing page
 */
const buttons: IActionButton[] = [
  {
    name: 'write-article',
    title: 'Write an Article',
    icon: ICON.NEWS,
    description: 'Write news Article',
    color: 'primary',
    visible: false,
    permissions: [PERMISSION.NEWS_WRITER],
    onClick: ({ history }) => {
      history.push('/comms/news/create');
    },
  },
  {
    name: 'new_leave_application',
    title: 'Apply for Leave',
    icon: ICON.MY_LEAVE,
    description: 'Create a new Leave Application',
    color: 'primary',
    visible: false,
    permissions: [PERMISSION.LEAVE_USER],
    onClick: ({ history, showModal }: {history: ReturnType<typeof useHistory>, showModal: ShowModalMethod}) => {
      showModal<NewRecordModalResult<ILeaveApplicationRecord>>(MODAL_TYPE.NEW_LEAVE_APPLICATION, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/hrm/my-leave/${modalResult.newRecord.id}/blocks`);
        },
      });
    },
  },
  {
    name: 'new-expense-claim',
    title: 'Claim Expense',
    icon: ICON.EXPENSE_CLAIMS,
    description: 'Create a new Expense Claim',
    color: 'primary',
    visible: false,
    permissions: [PERMISSION.EXPENSE_USER],
    onClick: ({ history, showModal }: {history: ReturnType<typeof useHistory>, showModal: ShowModalMethod}) => {
      showModal<NewRecordModalResult<IExpenseClaimRecord>>(MODAL_TYPE.NEW_EXPENSE, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/hrm/my-expenses/${modalResult.newRecord.id}/files`);
        },
      });
    },
  },
  {
    name: 'new-allowance-claim',
    title: 'Claim Allowance',
    icon: ICON.MY_ALLOWANCES,
    description: 'Create a new Allowance Claim',
    color: 'primary',
    visible: false,
    permissions: [PERMISSION.ALLOWANCE_USER],
    onClick: ({ history, showModal }: {history: ReturnType<typeof useHistory>, showModal: ShowModalMethod}) => {
      showModal<NewRecordModalResult<IAllowanceRecord>>(MODAL_TYPE.NEW_ALLOWANCE, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/hrm/my-allowances/${modalResult.newRecord.id}`);
        },
      });
    },
  },
  {
    name: 'add-a-company',
    title: 'Add a Company',
    icon: ICON.COMPANIES,
    description: 'Create a new Company',
    color: 'primary',
    visible: false,
    permissions: [PERMISSION.COMPANY_CREATE],
    onClick: ({ history, showModal }: {history: ReturnType<typeof useHistory>, showModal: ShowModalMethod}) => {
      showModal<NewRecordModalResult<ICompanyRecord>>(MODAL_TYPE.NEW_COMPANY, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/crm/companies/${modalResult.newRecord.id}`);
        },
      });
    },
  },
  {
    name: 'Add a Contact',
    title: 'Add a Contact',
    icon: ICON.CONTACTS,
    description: 'Add a Contact',
    color: 'primary',
    visible: false,
    permissions: [],
    onClick: ({ history, showModal }: {history: ReturnType<typeof useHistory>, showModal: ShowModalMethod}) => {
      showModal<NewRecordModalResult<IContactRecord>>(MODAL_TYPE.NEW_CONTACT, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/crm/contacts/${modalResult.newRecord.id}`);
        },
      });
    },
  },
  {
    name: 'Add new Portal User',
    title: 'Add a Portal User',
    icon: ICON.USER_MANAGEMENT,
    description: 'Add a Portal User',
    color: 'primary',
    visible: false,
    permissions: [
      PERMISSION.SECURITY_USER_MANAGER,
      PERMISSION.SECURITY_ADMIN,
    ],
    onClick: ({ history, showModal }: {history: ReturnType<typeof useHistory>, showModal: ShowModalMethod}) => {
      showModal<NewRecordModalResult<IUserRecord>>(MODAL_TYPE.NEW_USER, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/settings/user-management/${modalResult.newRecord.id}`);
        },
      });
    },
  },
];


/**
 * Splash Page Action Buttons
 */
export const SplashPageActionButtons: React.FC = () => {
  const { userHasPermissions } = useContext(CurrentUserContext);
  const modalProvider = useContext(ModalContext);
  const [poSearchString, setPoSearchString] = useState('');
  const history = useHistory();

  const searchAction = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    history.push(`/po-search?search=${poSearchString}`);
  }, [history, poSearchString]);

  const buttonRenders = buttons
    .filter((button) => ('permissions' in button ? userHasPermissions(button.permissions) : true))
    // eslint-disable-next-line react/no-array-index-key
    .map((button) => (
      <ActionButton
        key={button.name}
        {...button}
        onClick={() => { if (button.onClick) { button.onClick({ history, showModal: modalProvider.showModal }); } }}
      />
    ));

  // TODO: handle a situation where there are no action buttons
  return (
    <>
      {!!buttonRenders.length && (
        <div className="splash-page-action-buttons">
          <Card className="action-buttons-wrapper">
            <CardHeader tag="h4" className="bg-dark text-white">
              Your Quick Actions
            </CardHeader>
            <CardBody>
              {buttonRenders}
            </CardBody>
            <Form onSubmit={searchAction} className="pl-3 pr-3 pb-3">
              <InputGroup>
                <Input
                  type="text"
                  value={poSearchString}
                  onChange={((e: React.FormEvent<HTMLInputElement>) => setPoSearchString(e.currentTarget.value))}
                  placeholder="PO Quick Search"
                />
                <InputGroupAddon addonType="append">
                  <Button onClick={searchAction}><Icon i={ICON.SEARCH} /></Button>
                </InputGroupAddon>
              </InputGroup>
            </Form>
          </Card>
        </div>
      )}
    </>
  );
};

