import React from 'react';

import { IModalButton } from '../../types/modal/modal-button.interface';
import { ModalProps } from '../../types/modal/modal.props';
import { ReturnValueModalResult } from '../../types/modal/modal-result';


import { Modal } from './Modal';
import { MODAL_BUTTON_SIDE_TYPE } from '../../constants/modal-button-side-type.const';
import { BUTTON_COLOR } from '../../constants/button-color.const';
import { ICON } from '../../constants/icon.const';
import { QuickBooksPushForm } from '../custom-forms/quickbooks-push-form';
import { APIRecord } from '../../types/api-record.interface';
import { ICompanyRecord } from '../../types/company/company.record.interface';

export type PushToQuickbooksModalProps = ModalProps<ReturnValueModalResult<string>> & {
  entityName?: 'Project' | 'Job' | 'Internal Project',
  initialData: APIRecord & {
    id: number,
    number?: string,
    project_number?: string,
    quickbooks_subcustomer_id: number | null,
    client: ICompanyRecord
  },
  onCancel?: () => void,
}


/**
 * This modal is specific to the user form and allows a user to locate the
 * microsoft active directory user ID for a portal user.
 */
export const PushToQuickbooksModal:React.FC<PushToQuickbooksModalProps> = (props) => {
  const {
    initialData,
    entityName = 'Job',
    id,
    closeModal,
    onModalComplete,
    onCancel,
  } = props;


  // Use provided buttons or create default buttons
  const modalButtons: IModalButton[] = [
    {
      label: 'Done',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.CHECK,
      sideType: MODAL_BUTTON_SIDE_TYPE.RIGHT,
      onClick: () => {
        closeModal(id);
        if (typeof onModalComplete === 'function') {
          onModalComplete({ success: false, value: null });
        }
      },
      disabled: (typeof onModalComplete !== 'function'),
    },
    {
      label: 'Cancel',
      color: BUTTON_COLOR.SECONDARY,
      sideType: MODAL_BUTTON_SIDE_TYPE.LEFT,
      onClick: () => {
        closeModal(id, false, onCancel);
      },
    },
  ];

  return (
    <Modal
      {...props}
      className="push-record-to-quickbooks"
      title={`Push ${entityName} to QuickBooks`}
      buttons={modalButtons}
    >
      <QuickBooksPushForm
        entityName={entityName}
        baseRoute="none"
        onLinkComplete={() => null}
        parentId={1233}
        rowData={initialData}
      />

    </Modal>
  );
};
