import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Table } from 'reactstrap';
import classNames from 'classnames';
import { SelectOption } from '../../types/poly-form/select-option';
import { IColumnFilter, IColumnSort, InMemoryDataTableColumn, IPortalDataTableView } from '../../types/portal-data-table/in-memory-table-types';
import { InMemoryTableHead } from './in-memory-table/in-memory-table-head';
import { TableSearchControls } from './table-controls/table-search-controls';
import { TableDisplayControls } from './table-controls/table-display-controls';
import { IDataTableRecord } from '../../types/portal-data-table/portal-data-table-record.interface';
import ViewSelect from './view-select';
import { InMemoryPagination } from './in-memory-table/in-memory-pagination';
import { ResultsText } from '../pagination/results-text';
import PageSizeSelect from '../page-size-select/page-size-select';
import { InMemoryTableRow } from './in-memory-table/in-memory-table-row';
import { FILTER_OPERATION } from '../../constants/filter-operation.const';

interface IInMemoryTableProps<T> {
  className?: string,
  title?: string,
  data: T[]
  columns: InMemoryDataTableColumn[],
  rowIdKey?: string,
  color?: string,
  activeColor?: string,
  bordered?: boolean,
  striped?: boolean,
  paginate?: boolean,
  hover?: boolean,
  views?: IPortalDataTableView[],
  narrow?: boolean,
  tightMode?: boolean,
  renderDetailRow?: (record: T) => JSX.Element,
}

export const InMemoryTable = <T extends IDataTableRecord>(props: IInMemoryTableProps<T>) => {
  const {
    className,
    data,
    columns,
    rowIdKey = 'id',
    color = 'primary',
    activeColor = 'success',
    striped = true,
    bordered = true,
    hover = false,
    views = [],
    title = '',
    paginate = false,
    narrow = false,
    tightMode = false,
    renderDetailRow,
  } = props;

  const addDataPicklistsToColumns = (processColumns: InMemoryDataTableColumn[], columnData: T[]) => processColumns.map(
    (column: InMemoryDataTableColumn) => {
      const patchedColumn = {
        ...column,
      };
      const pickListData = getPickListOptionsFromTableData(column.name, columnData);
      if (column.filterType === 'pickListFromRecordset') {
        patchedColumn.inputOptions = pickListData;
        patchedColumn.filterType = 'idNameOptions';
      }
      return patchedColumn;
    },
  );

  const getDataSortedByColumns = (sourceData: T[], columnSortArray: IColumnSort[]) : T[] => {
    // don't mutate source data
    let sortedData = [...sourceData];

    // iteratively apply the sorts
    columnSortArray.map((columnSort: IColumnSort) => {
      const { name } = columnSort;
      const { direction } = columnSort;
      switch (direction) {
        case 'asc':
          sortedData = sortedData.sort((a, b) => {
            const aKey = a[name] || '';
            const bKey = b[name] || '';
            if (aKey < bKey) return -1;
            if (bKey < aKey) return 1;
            return 0;
          });
          break;
        case 'desc':
          sortedData = sortedData.sort((a, b) => {
            const aKey = a[name] || '';
            const bKey = b[name] || '';
            if (aKey < bKey) return 1;
            if (bKey < aKey) return -1;
            return 0;
          });
          break;
        default:
          // sort the data by its keys originalis
      }
      return true;
    });
    return sortedData;
  };

  const enforceDataRecordIds = (recordData: T[], processRowIdKey: string) => recordData.map(
    (dataRecord: T, index) => (
      (!processRowIdKey || dataRecord[processRowIdKey] === undefined || dataRecord[processRowIdKey] === null) ? {
        ...dataRecord,
        [processRowIdKey]: index,
      } : dataRecord),
  );


  /**
   * Get Pick list Options from Table Data
   *
   * @param name   the key or field name to retrieve from the table
   * @param data      the table data to retrieve the options from
   *
   * @returns an array of indexed pick list options
   */
  const getPickListOptionsFromTableData = (name: string, tableData: T[]) : SelectOption[] => {
    const uniqueItems: { [key: string]: boolean } = {};
    tableData.map((dataRecord: T) => {
      const fieldValue = dataRecord[name];
      if (fieldValue !== null && fieldValue !== undefined) {
        uniqueItems[fieldValue.toString()] = true;
      }
      return true;
    });
    return Object.keys(uniqueItems)
      .sort((a: string, b: string) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      })
      .map((item) => ({ id: item, name: item } as SelectOption));
  };


  /**
   * Get Column Filtered Data Subset
   *
   * @param sourceData the source data to filter
   * @param columnFilterArray the array of filters to apply
   *
   * @returns a new array of Data Table Records filtered by the filter array.
   */
  const getColumnFilteredDataSubset = (sourceData: T[], columnFilterArray: IColumnFilter[]) : T[] => {
    // don't mutate source data
    let filteredData: T[] = [...sourceData];

    // iteratively apply the filters
    columnFilterArray.map((columnFilter: IColumnFilter) => {
      const { name } = columnFilter;
      switch (columnFilter.operation) {
        case (FILTER_OPERATION.CONTAINS):
          if (columnFilter.values[0]) {
            filteredData = filteredData.filter((dataRecord: T) => {
              const value = (dataRecord[name] || '').toString().toLowerCase();
              const search = (columnFilter.values[0] || '').toString().toLowerCase();
              const foundAt = value.indexOf(search);
              return foundAt > -1;
            });
          }
          break;
        case (FILTER_OPERATION.EQUALS):
          if (columnFilter.values[0]) {
            filteredData = filteredData.filter((dataRecord: T) => {
              const value = (dataRecord[name] || '').toString().toLowerCase();
              const search = (columnFilter.values[0] || '').toString().toLowerCase();
              return value === search;
            });
          }
          break;
        default:
        // nada
      }
      return true;
    });
    return filteredData;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const idEnforcedData = useMemo<T[]>(() => enforceDataRecordIds(data, rowIdKey), [data, rowIdKey]);
  const processedColumns = addDataPicklistsToColumns(columns, idEnforcedData);
  const initialColumnFilters: IColumnFilter[] = [];
  const initialColumnSorts: IColumnSort[] = [];

  const [viewData, setViewData] = useState<T[]>([...idEnforcedData]);
  const [pageData, setPageData] = useState<T[]>([...idEnforcedData]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(paginate ? 10 : idEnforcedData.length);
  const [filteredColumns, setFilteredColumns] = useState<IColumnFilter[]>(initialColumnFilters);
  const [sortedColumns, setSortedColumns] = useState<IColumnSort[]>(initialColumnSorts);
  const [isColumnFilterDrawerVisible, setIsColumnFilterDrawerVisible] = useState(false);
  const [isTightModeEnabled, setIsTightModeEnabled] = useState(tightMode);

  const tableWrapperRef = useRef(null);


  /**
   * Handle Filter Column
   *
   * @param {string}              name the data key or field name to filter on
   * @param {IColumnFilter|null}  newFilter the sort to apply or null to clear
   *
   * @returns {void}
   */
  const handleFilterColumn = (name: string, newFilter: IColumnFilter | null) => {
    let newColumnFilters: IColumnFilter[] = [];
    if (newFilter === null) {
      newColumnFilters = filteredColumns.filter((columnFilter) => columnFilter.name !== name);
    } else {
      newColumnFilters = [
        ...filteredColumns,
      ];
      newColumnFilters.push(newFilter);
    }
    setFilteredColumns(newColumnFilters);
  };


  /**
   * Handle Sort Column
   *
   * @param {string}            name the data key or field name to sort on
   * @param {IColumnSort|null}  newSort the sort to apply or null to clear
   *
   * @returns {void}
   */
  const handleSortColumn = (name: string, newSort: IColumnSort | null) => {
    let newColumnSorts = sortedColumns.filter((columnSort) => columnSort.name !== name);
    if (newSort !== null) {
      newColumnSorts = [
        ...newColumnSorts,
      ];
      newColumnSorts.push(newSort);
    }
    setSortedColumns(newColumnSorts);
  };


  const handlePageChange = (newPageNumber: number) => {
    setCurrentPage(newPageNumber);
  };


  /**
   * Handle Toggle Column Drawer Visibility
   */
  const handleToggleColumnFilterDrawerVisible = useCallback(() => {
    setIsColumnFilterDrawerVisible(!isColumnFilterDrawerVisible);
  }, [setIsColumnFilterDrawerVisible, isColumnFilterDrawerVisible]);


  /**
   * Handle Toggle Column Drawer Visibility
   */
  const handleClearSortedColumns = useCallback(() => {
    setSortedColumns([]);
  }, [setSortedColumns]);


  /**
   * Handle Page Size Change
   * @param {number} newSize the new page size
   */
  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
  };


  /**
   * Update the page data when view data or pagination changes
   */
  useEffect(() => {
    const firstRecord = paginate ? ((currentPage - 1) * pageSize) : 0;
    const maxRecord = paginate ? pageSize * currentPage : viewData.length;
    const lastRecord = Math.min(maxRecord, viewData.length);
    if (paginate && (firstRecord >= viewData.length)) {
      setTimeout(() => setCurrentPage(1));
      return;
    }
    setHasError(false);
    const newPageData: T[] = [];
    for (let i = firstRecord; i < lastRecord; i += 1) {
      const record: T = viewData[i];
      newPageData.push(record);
    }
    setPageData(newPageData);
  }, [viewData, paginate, pageSize, currentPage]);


  /**
   * Regenerate the ViewData when the incoming data, column filtering or sort definitions change
   */
  useEffect(() => {
    const filteredData = getColumnFilteredDataSubset(idEnforcedData, filteredColumns);
    const sortedData = getDataSortedByColumns(filteredData, sortedColumns);
    setViewData(sortedData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredColumns, idEnforcedData, sortedColumns]);


  // Render
  return (
    <div className={classNames('portal-data-table', className)}>
      <div className={classNames('table-header-wrapper', { 'narrow-view': narrow })}>

        {/* Table Title or View Select List */}
        <div className="title-wrapper">
          {views.length === 0 && <h4>{title}</h4>}
          {views.length > 0 && (
            <ViewSelect
              views={views}
              // viewKey={viewKey}
              // availableFlags={availableFlags}
              // flags={flags}
              // onToggleFlag={this.handleToggleFlag}
              // onSetView={(view: IPortalDataTableView) => console.log('setView: ', view)}
            />
          )}
        </div>

        {/* Table Search Controls */}
        <div className="search-controls-wrapper">
          <TableSearchControls
            color={color}
            activeColor={activeColor}
            sortedColumns={sortedColumns}
            filteredColumns={filteredColumns}
            isColumnFilterDrawerVisible={isColumnFilterDrawerVisible}
            onToggleColumnFilterDrawer={handleToggleColumnFilterDrawerVisible}
            onClearSortedColumns={handleClearSortedColumns}
          />
        </div>

        {/* Table Display Controls */}
        <div className="display-controls-wrapper">
          <TableDisplayControls
            color={color}
            isColumnManagerOpen={false}
            isTextWrappingEnabled={false}
            isFullScreenActive={false}
            isTightModeEnabled={isTightModeEnabled}
            onToggleTightMode={() => setIsTightModeEnabled(!isTightModeEnabled)}
          />
        </div>


        {paginate && (
          <>
            {/* Leading Results Text */}
            <div className="leading-results-text-wrapper">
              <ResultsText
                className=""
                isLoading={false}
                hasError={hasError}
                isFiltered={filteredColumns.length > 0}
                pageSize={pageSize}
                totalRecords={viewData.length}
                activePage={currentPage}
              />
            </div>


            {/* Leading Paginator */}
            <div className="leading-pagination-wrapper">
              <InMemoryPagination
                className={`pagination-${color}`}
                color={color}
                totalRecords={viewData.length}
                pageSize={pageSize}
                activePage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          </>
        )}

      </div>
      <div className="table-wrapper">
        <div
          ref={tableWrapperRef}
          className={classNames(
            'table-responsive',
            {
              busy: false,
              'no-wrap': false,
              'no-records': false,
            },
          )}
          style={{ minHeight: '300px' }}
          onScroll={() => {}}
        >
          <Table
            className={`portal-data-table color-table ${color}-table`}
            size={isTightModeEnabled ? 'sm' : 'md'}
            striped={striped}
            bordered={bordered}
            hover={hover}
          >
            <InMemoryTableHead
              columns={processedColumns}
              filteredColumns={filteredColumns}
              sortedColumns={sortedColumns}
              onFilterColumn={handleFilterColumn}
              onSortColumn={handleSortColumn}
              isColumnFilterDrawerVisible={isColumnFilterDrawerVisible}
            />
            <tbody>
              {pageData.map((record) => (
                <InMemoryTableRow
                  key={`table-record-${record[rowIdKey]}`}
                  record={record}
                  rowIdKey={rowIdKey}
                  columns={columns}
                  renderDetailRow={renderDetailRow}
                />
                // <>
                //   <tr
                //     key={`table-record-${record[rowIdKey]}`}
                //     className={`table-record-${record[rowIdKey]}`}
                //   >
                //     {columns.map((column) => (
                //       <TableCell
                //         key={`dodgyKey-${column.name}`}
                //         row={record}
                //         column={column}
                //       />
                //     ))}
                //   </tr>
                //   {renderDetailRow && (
                //     <renderDetailRow record={record} />
                //   )}
                // </>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      {/* Table footer */}
      <div className="table-footer-wrapper">

        {paginate && (
          <>
            {/* Trailing Results Text */}
            <div className="trailing-results-text-wrapper">
              <ResultsText
                className=""
                isLoading={false}
                hasError={hasError}
                isFiltered={filteredColumns.length > 0}
                pageSize={pageSize}
                totalRecords={viewData.length}
                activePage={currentPage}
              />
            </div>

            {/* Page Size Select */}
            <div className="page-size-select-wrapper">
              <PageSizeSelect
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                pageSizes={[5, 10, 15, 25, 50, 100, 250, 500]}
              />
            </div>

            {/* Trailing Paginator */}
            <div className="trailing-pagination-wrapper">
              <InMemoryPagination
                className={`pagination-${color}`}
                totalRecords={viewData.length}
                pageSize={pageSize}
                activePage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
