import React from 'react';
import PropTypes from 'prop-types';
// import { CardText } from 'reactstrap';
import { noop } from '../../../utils/helpers';
import IntegrationPanel from './integration-panel';
import ApiConnector from './api-connector';

const QuickBooksPanel = ({ connectHandler, status }) => (
  <IntegrationPanel title="Quickbooks Online" color="success">
    {/* <CardText>
      The QuickBooks API allows us to synchronise financial data with our accounting software.
    </CardText> */}
    <ApiConnector status={status} color="success" connectHandler={connectHandler} />
  </IntegrationPanel>
);

QuickBooksPanel.propTypes = {
  status: PropTypes.shape({
    message: PropTypes.string,
    loading: PropTypes.bool,
    connected: PropTypes.bool,
  }),
  // statusHandler: PropTypes.func,
  connectHandler: PropTypes.func,
};

QuickBooksPanel.defaultProps = {
  status: {
    message: '',
    loading: true,
    connected: false,
  },
  // statusHandler: noop,
  connectHandler: noop,
};

export default QuickBooksPanel;
