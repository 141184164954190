import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { DataTablePageProps } from '../../types/data-table-page.props';
import { NewRecordModalResult } from '../../types/modal/modal-result';
import { IContractSubtypeRecord } from '../../types/service/contract-subtype.record.interface';

import { ModalContext } from '../modals/modal-context';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';

export type ContractSubtypeDataTablePageProps = DataTablePageProps;

/**
 * Display the "ContractSubtypes" data table as a whole page
 */
export const ContractSubtypesDataTablePage: React.FC<ContractSubtypeDataTablePageProps> = ({
  baseFilters,
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const history = useHistory();
  const { showModal } = useContext(ModalContext);

  const actionHandlers = {
    create: () => {
      showModal<NewRecordModalResult<IContractSubtypeRecord>>(MODAL_TYPE.NEW_CONTRACT_SUBTYPE, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/service-and-support/admin/contract-subtypes/${modalResult.newRecord.id}`);
        },
      });
    },
  };

  return (
    <DataTablePage
      title="Contract Subtypes"
      tableIdentifier={TABLE_IDENTIFIER.CONTRACT_SUBTYPES_TABLE}
      actionHandlers={actionHandlers}
      baseFilters={baseFilters}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      {children}
    </DataTablePage>
  );
};
