import React, { Component } from 'react';
import PropTypes from 'prop-types';

class RoleManagementView extends Component {
  constructor() {
    super();
    this.state = {};
    this.resetTimeout = null;
  }

  // Unmount <RoleManagementView />
  componentWillUnmount() {
    clearTimeout(this.resetTimeout);
  }

  // Render for <RoleManagementView />
  render() {
    return <div>RoleManagementView not implemented</div>;
  }
}

RoleManagementView.defaultProps = {
  user: {
    id: null,
  },
};

RoleManagementView.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default RoleManagementView;
