/* eslint-disable react/button-has-type */
import React, { useContext } from 'react';
import { CurrentUserContext } from '../providers/current-user-provider';
import Profile from './profile';

/**
 * My Profile component
 */
const MyProfile = () => {
  const { userDetails } = useContext(CurrentUserContext);

  const props = { match: { params: { userId: userDetails.id } }, currentUserDetails: userDetails };

  return (
    <Profile {...props} title="View profile" />
  );
};

export default MyProfile;
