import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { ICompanyLocationRecord } from '../../types/company/company-location.record.interface';
import { NewRecordFormProps } from '../../types/poly-form/new-record-form.props';

import { APIPolyForm } from './api-poly-form';

import { CompanyLocationLocationTypeFormFieldsMap } from '../../constants/form-fields/company-location-location-type-form-fields-map.const';
import { A_COMPANY_LOCATION_TYPE, COMPANY_LOCATION_TYPE } from '../../constants/company-location-type.const';
import { FORM_RENDERER_TYPE } from '../../constants/form-renderer-type.const';
import { GMAPS_ENABLED } from '../../utils/constants';

// @note: this form requires that a parent company_id is passed to it in the initialData
export const NewCompanyLocationForm: React.FC<NewRecordFormProps<ICompanyLocationRecord>> = (props) => {
  const {
    onFormComplete,
    onFormChange,
    initialData,
    baseRoute = '/company',
    apiRoute = '/location',
    apiQuery = ['with[]=state:id,acronym'].join('&'),
    rendererOptions = {
      showGoogleMap: GMAPS_ENABLED,
      showParentLocation: false,
      showLocationType: true,
    },
  } = props;

  const {
    showGoogleMap = GMAPS_ENABLED,
    showParentLocation = false,
    showLocationType = true,
  } = rendererOptions;

  const [locationTypeId, setLocationTypeId] = useState<A_COMPANY_LOCATION_TYPE>(initialData?.type_id as A_COMPANY_LOCATION_TYPE ?? COMPANY_LOCATION_TYPE.SITE);

  const company_id = useMemo<undefined | number>(() => (initialData?.company_id), [initialData]);

  /**
   * Fired when the APIPolyForm successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, id: FormFieldComponentProps['value'], newData?: Partial<ICompanyLocationRecord>) => {
    if (onFormComplete) onFormComplete(success, id as ICompanyLocationRecord['id'], newData as ICompanyLocationRecord);
  }, [onFormComplete]);


  /**
   * When the form is updated by the user, grab some of the details to make the UI more relevant
   */
  const handleFormChange = useCallback((record_id: number, newFormData: Partial<ICompanyLocationRecord>) => {
    if (newFormData?.type_id) setLocationTypeId(newFormData.type_id as A_COMPANY_LOCATION_TYPE);

    if (onFormChange) onFormChange(record_id, newFormData);
  }, [onFormChange]);


  /**
   * When the form is first loaded, ensure that required fields are provided
   */
  useEffect(() => {
    // company is mandatory
    if (!company_id) throw new Error('NewCompanyLocationForm: `company_id` must be provided in the initialData!');
  }, [company_id]);


  // Render
  return (
    <APIPolyForm<ICompanyLocationRecord>
      formRendererType={FORM_RENDERER_TYPE.COMPANY_LOCATION}
      rendererOptions={{
        showGoogleMap,
        showParentLocation,
        showLocationType,
      }}
      baseRoute={baseRoute}
      apiRoute={apiRoute}
      apiQuery={apiQuery}
      parentId={company_id}
      fields={CompanyLocationLocationTypeFormFieldsMap[locationTypeId]}
      formData={{
        ...(initialData ?? {}),
        type_id: locationTypeId,
      }}
      onFormComplete={handleFormComplete}
      onFormChange={handleFormChange}
      isNewRecord
      isEditing
      initialFormFocusFieldName="name"
    />
  );
};
