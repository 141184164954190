import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { EventEmitter } from 'events';
import TableHead from './table-head';
import TableBody from './table-body';
import { THEME_COLOR } from '../../../constants/theme-color.const';

/**
 * Table component for Data Table which includes table head and table body
 */
const PortalTable = (props) => {
  const {
    rowKeyField,
    recordSet,
    isColumnFilterDrawerVisible,
    tableIdentifier,
    colorTheme,
    isTightModeEnabled,
    history,
    match,
    location,
    tableWrapperRef,
    tableResizeListener,
    tableScrollListener,
    onDirtyRowChange,
  } = props;

  return (
    <Table
      className={classnames('color-table', `${colorTheme}-table`)}
      role="grid"
      size={isTightModeEnabled ? 'sm' : 'md'}
      bordered
      striped
      hover
    >
      <TableHead
        isColumnFilterDrawerVisible={isColumnFilterDrawerVisible}
        tableIdentifier={tableIdentifier}
        location={location}
      />
      <TableBody
        recordSet={recordSet}
        tableIdentifier={tableIdentifier}
        history={history}
        location={location}
        match={match}
        rowKeyField={rowKeyField}
        tableWrapperRef={tableWrapperRef}
        tableResizeListener={tableResizeListener}
        tableScrollListener={tableScrollListener}
        onDirtyRowChange={onDirtyRowChange}
      />
    </Table>
  );
};

PortalTable.defaultProps = {
  colorTheme: 'primary',
  rowKeyField: 'id',
  tableWrapperRef: null,
  tableResizeListener: null,
  tableScrollListener: null,
  onDirtyRowChange: null,
};

PortalTable.propTypes = {
  rowKeyField: PropTypes.string,
  tableIdentifier: PropTypes.string.isRequired,
  isColumnFilterDrawerVisible: PropTypes.bool.isRequired,
  isTightModeEnabled: PropTypes.bool.isRequired,
  tableWrapperRef: PropTypes.shape({ current: PropTypes.shape({}) }),
  tableResizeListener: PropTypes.instanceOf(EventEmitter),
  tableScrollListener: PropTypes.instanceOf(EventEmitter),
  recordSet: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  colorTheme: PropTypes.oneOf(Object.values(THEME_COLOR)),
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  onDirtyRowChange: PropTypes.func,
};

export default PortalTable;
