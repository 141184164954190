import React, { useState } from 'react';

import { Button } from 'reactstrap';

import { useIsDirty } from '../../react-hooks/use-is-dirty.hook';

import { Modal } from './Modal';
import { HtmlInput } from '../form-input/html-input';
import { ModalProps } from '../../types/modal/modal.props';
import { ConfirmActionModalResult } from '../../types/modal/modal-result';


export type RejectInvoiceRequestModalResult = ConfirmActionModalResult & {
  formData?: {
    reason?: string,
  }
}

export type RejectInvoiceRequestModalProps = ModalProps<RejectInvoiceRequestModalResult>;

export const RejectInvoiceRequestModal:React.FC<RejectInvoiceRequestModalProps> = (props) => {
  const {
    id, closeModal, onModalComplete,
  } = props;

  const [isDirty, setDirty] = useIsDirty();
  const [noteBody, setNoteBody] = useState<string>();

  const handleReject = () => {
    closeModal(id);
    onModalComplete({ processAction: true, formData: { reason: noteBody } });
  };

  const handleCancel = () => {
    closeModal(id);
    onModalComplete({ processAction: false });
  };

  // Render
  return (
    <Modal
      {...props}
      title="Reject Invoice Request"
      allowDismiss
      isDirty={isDirty}
      size="lg"
    >
      {' '}
      <p>Please provide a note explaining why this request has been rejected, and what will need to be corrected.</p>
      <HtmlInput
        id="note-input"
        name="note-input"
        value=""
        placeholder="Please enter a rejection note"
        onChange={({ newValue }) => { setDirty(); setNoteBody(newValue); }}
        inline
      />
      <div className="pt-3 text-right">
        <Button onClick={handleCancel} color="secondary" className="mr-2">
          Cancel
        </Button>
        <Button onClick={handleReject} color="danger" disabled={!isDirty}>
          Reject
        </Button>
      </div>
    </Modal>
  );
};
