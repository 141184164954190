import React, { useState } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import { bool } from 'prop-types';
import Icon from '../../layout-helpers/icon';
import ProjectQuickBooksInvoices from './project-quickbooks-invoices';
import { QuickBooksPushForm } from '../../custom-forms/quickbooks-push-form';
import { APIRecord } from '../../../types/api-record.interface';
import { ICompanyRecord } from '../../../types/company/company.record.interface';
import { MyobProjectPush } from './myob-project-push';
import { MyobTaskTable } from './myob-task-table';
import { MyobTransactionTable } from './myob-transaction-table';
import { MyobTransactionReport } from './myob-transaction-report';

const qbAppEndpoint = process.env.REACT_APP_QUICKBOOKS_APP_ENDPOINT;
const qbPath = `${qbAppEndpoint}app/customerdetail?nameId=`;

const companyId = process.env.REACT_APP_MYOB_COMPANY_ID;

interface IProjectAccountingWidgetProps {
  rowData: APIRecord & {
    id: number,
    project_number: string,
    quickbooks_subcustomer_id: number | null,
    myob_project_id: string | null,
    name: string,
    client: ICompanyRecord,
  },
  baseRoute: string,
  // refreshWidgetData: () => void,
  refreshRecord: () => void,
  parentId: number,
}

export const ProjectAccountingWidget: React.FC<IProjectAccountingWidgetProps> = ({ rowData, baseRoute, parentId, refreshRecord }) => {
  const [showTaskList, setShowTaskList] = useState(false);
  const [showTransactionList, setShowTransactionList] = useState(false);
  const [showTransactionReport, setShowTransactionReport] = useState(true);

  return (
    <div className="widget project-quickbooks-widget">
      <Card>
        <CardBody>
          {rowData && rowData.myob_project_id ? (
            <h4>
              Project is linked to MYOB &nbsp;&nbsp;
              <Button
                href={`https://ci.myobadvanced.com/Main?CompanyID=${companyId}&ScreenId=PM301000&BaseType=P&ContractCD=${rowData.myob_project_id}`}
                target="_blank"
                rel="noopener noreferrer"
                color="purple"
              >
                View in MYOB
                {' '}
                <Icon i="external-link" />
              </Button>
              {' '}
              {!showTransactionReport && (
                <Button color="purple" onClick={() => setShowTransactionReport(true)}>
                  MYOB Transaction Report
                </Button>
              )}
              {' '}
              {!showTransactionList && (
                <Button color="purple" onClick={() => setShowTransactionList(true)}>
                  List MYOB Transactions
                </Button>
              )}
              {' '}
              {!showTaskList && (
                <Button color="purple" onClick={() => setShowTaskList(true)}>
                  List MYOB Tasks
                </Button>
              )}
            </h4>
          ) : (
            <MyobProjectPush
              onLinkComplete={refreshRecord}
              parentId={parentId}
            />
          )}
          {rowData && rowData.myob_project_id && (
            <>
              {showTransactionReport && (
                <MyobTransactionReport projectId={rowData.id} />
              )}
              {showTransactionList && (
                <MyobTransactionTable projectId={rowData.id} />
              )}
              {showTaskList && (
                <MyobTaskTable myobProjectId={rowData.myob_project_id} />
              )}
            </>
          )}
          <p>&nbsp;</p>
          {rowData && rowData.quickbooks_subcustomer_id ? (
            <h4>
              Project is linked to QuickBooks   &nbsp;&nbsp;
              <Button
                href={`${qbPath}${rowData.quickbooks_subcustomer_id}`}
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
              >
                View in QuickBooks
                {' '}
                <Icon i="external-link" />
              </Button>
            </h4>
          ) : (
            <QuickBooksPushForm
              entityName="Project"
              baseRoute={baseRoute}
              onLinkComplete={refreshRecord}
              parentId={parentId}
              rowData={rowData}
            />
          )}
        </CardBody>
        <ProjectQuickBooksInvoices baseRoute={baseRoute} rowData={rowData} />
      </Card>
    </div>
  );
};
