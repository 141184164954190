import React from 'react';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';

export type HiddenInputProps = Pick<FormFieldComponentProps,
  'id' |
  'name' |
  'required' |
  'value' |
  'onChange'
>;

export const Hidden:React.FC<HiddenInputProps> = ({
  id,
  name,
  required,
  value,
  onChange,
}) => (
  <input
    id={id}
    name={name}
    required={required !== false}
    onChange={onChange ? (e) => onChange({ fieldName: name, newValue: e.target.value }) : undefined}
    value={(value ?? '') as string | number | undefined}
    type="hidden"
  />
);
