/**
 * Calculate the height and width of scrollbars on the current device
 */
export const getScrollbarSize = (): {
  width: number,
  height: number,
} => {
  const { body } = document;
  const scrollDiv = document.createElement('div');

  // Append element with defined styling
  scrollDiv.setAttribute(
    'style',
    'width: 1337px; height: 1337px; position: absolute; left: -9999px; overflow: scroll;',
  );
  body.appendChild(scrollDiv);

  // Collect width & height of scrollbar by detecting the difference between the div size and the divs client size
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  const scrollbarHeight = scrollDiv.offsetHeight - scrollDiv.clientHeight;

  // Remove element
  body.removeChild(scrollDiv);

  return {
    width: scrollbarWidth,
    height: scrollbarHeight,
  };
};
