import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { PERMISSION } from '../../../constants/permissions.const';
import { APIContext } from '../../providers/api-provider';
import { CurrentUserContext } from '../../providers/current-user-provider';
import ProjectQuickBooksInvoiceLine from './project-quickbooks-invoice-line';
import Icon from '../../layout-helpers/icon';
import { apiAborter } from '../../../helpers/api-aborter.helper';

interface IProjectQuickBooksInvoicesProps {
  baseRoute: string,
  rowData: {
    id: number,
    name: string,
    quickbooks_subcustomer_id: number | null
  }
}

interface IPortalQuickBooksInvoiceRecord {
  id: string,
  transaction_date: string,
  due_date: string,
  balance: string,
  document_number: string,
  amount: string,
  portal_invoice: {
    id: number,
    invoice_number: string,
    amount: number,
    type_id: number,
  },
}


interface IInvoiceLoadResult {
  loading: boolean,
  error: boolean,
  data: IPortalQuickBooksInvoiceRecord[]
}

const ProjectQuickBooksInvoices: React.FC<IProjectQuickBooksInvoicesProps> = ({ baseRoute, rowData }) => {
  const { userHasPermissions } = useContext(CurrentUserContext);
  const [invoiceListResult, setInvoiceListResult] = useState<IInvoiceLoadResult>({
    loading: false,
    error: false,
    data: [],
  });
  const { apiFetch } = useContext(APIContext);

  const loadInvoiceListAbortController = useRef<AbortController | null>(null);

  const showBalance = false; // userHasPermissions(PERMISSION.API_ADMIN);

  const loadInvoiceList = useCallback(async () => {
    if (loadInvoiceListAbortController.current) {
      loadInvoiceListAbortController.current.abort();
    }
    loadInvoiceListAbortController.current = apiAborter();

    setInvoiceListResult({
      ...invoiceListResult,
      loading: true,
    });

    const response = await apiFetch(
      `${baseRoute}/${rowData.id}/quickbooks-invoice`,
      {
        name: 'BillAndPurchaseOrderUpdatePanelOrderList:load',
        signal: loadInvoiceListAbortController.current.signal,
      },
    );

    if (response.success) {
      loadInvoiceListAbortController.current = null;
      setInvoiceListResult({
        loading: false,
        error: false,
        data: response.body.data,
      });
    } else if (!response.aborted) {
      loadInvoiceListAbortController.current = null;
      setInvoiceListResult({
        loading: false,
        error: true,
        data: [],
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseRoute]);

  /**
   * On component mount load Invoice Lists
   */
  useEffect(() => {
    // Load the invoices for the current project
    // userHasPermissions(PERMISSION.API_ADMIN) removed, as just the balance is hidden
    // @todo anyone inspecting the data could still view the balance
    //       this would have to be concealed on the back-end
    if (rowData.quickbooks_subcustomer_id && invoiceListResult.data.length < 1) {
      loadInvoiceList();
    }

    return () => {
      // Kill any outstanding load when the component is un-mounted
      if (loadInvoiceListAbortController.current) {
        loadInvoiceListAbortController.current.abort();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseRoute, rowData.quickbooks_subcustomer_id]);

  return (
    <Card>
      <CardBody><h4>Project Invoices in QuickBooks</h4></CardBody>
      {invoiceListResult.loading && (
        <CardBody>
          Loading Invoices...
          {' '}
          &nbsp;
          <Icon i="rolling" />
        </CardBody>
      )}
      {invoiceListResult.error && (
        <CardBody className="text-danger">Error loading Quickbooks invoices.</CardBody>
      )}
      {!invoiceListResult.loading && !invoiceListResult.error && invoiceListResult.data.length < 1 && (
        <CardBody>No invoices were loaded.</CardBody>
      )}
      {!userHasPermissions(PERMISSION.API_ADMIN) && (
        <CardBody className="text-danger">You do not have permission to view QuickBooks Invoices.</CardBody>
      )}
      {invoiceListResult.data.length > 0 && (
        <Table className="color-table success-table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Invoice #</th>
              <th>Transaction Date</th>
              <th>Due Date</th>
              <th className="text-right">Amount</th>
              {showBalance && (
                <>
                  <th className="text-right">Balance</th>
                  <th>Portal : Type : Value</th>
                  <th>Action</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {invoiceListResult.data.map((invoice) => (
              <ProjectQuickBooksInvoiceLine key={invoice.id} baseRoute={baseRoute} invoice={invoice} showBalance={showBalance} />
            ))}
          </tbody>
        </Table>
      )}
    </Card>
  );
};

export default ProjectQuickBooksInvoices;
