import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactJsPagination from 'react-js-pagination';

/**
 * @name Pagination
 * Pagination component for data table
 *
 * @param {{
 *  className: string,
 *  totalRecords: number,
 *  activePage: number,
 *  pageSize: number,
 *  onPageChange: (pageNumber: number) => any,
 * }} param0
 */
const Pagination = ({ className, totalRecords, activePage, pageSize, onPageChange }) => {
  const handlePageChange = (pageNumber) => {
    if (pageNumber !== activePage) onPageChange(pageNumber);
  };

  const lastPage = Math.ceil(totalRecords / pageSize);
  const lastPageText = activePage === lastPage || lastPage === 0
    ? 'Last'
    : (
      <>
        {'Last '}
        <span className="text-muted">{lastPage}</span>
      </>
    );

  return (
    <div className={classNames('portal-pagination', className)}>
      <div className="page-links">
        <ReactJsPagination
          prevPageText="Prev"
          nextPageText="Next"
          firstPageText="First"
          lastPageText={lastPageText}
          itemClass="page-item"
          linkClass="page-link"
          activePage={activePage}
          itemsCountPerPage={pageSize}
          totalItemsCount={totalRecords}
          onChange={handlePageChange}
          itemClassPrev="prev"
          itemClassNext="next"
        />
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  className: '',
};

Pagination.propTypes = {
  className: PropTypes.oneOfType([PropTypes.instanceOf(classNames), PropTypes.string]),
  totalRecords: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
