import React, { createRef } from 'react';
import classNames from 'classnames';
import { Button, Input } from 'reactstrap';
import Icon from '../layout-helpers/icon';
// import { TextInputProps } from './text-input';
import { ICON } from '../../constants/icon.const';
import { FileDropTarget } from '../layout-helpers/file-drop-target';
import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';

export type FileSelectInputProps = Pick<FormFieldComponentProps,
  'className' |
  'id' |
  'name' |
  'value' |

  'placeholder' |
  'maxLength' |
  'autoComplete' |
  'autoCompleteIdentifier' |

  'tabIndex' |
  'disabled' |
  'hasError' |
  'format' |
  'inline' |

  'onKeyPress' |
  'onKeyDown' |
  'onKeyUp' |
  'onChange' |
  'onClick'
  >
  & (
    {
      accept?: string,
      allowMultipleFiles?: undefined | false,
      value?: null | File,
      uploadProgress?: null | number,
      onChange?: (field: {fieldName: string, newValue: null | File}) => void,
    } |
    {
      accept?: string,
      allowMultipleFiles?: true,
      value?: null | File[],
      uploadProgress?: null | number,
      onChange?: (field: {fieldName: string, newValue: null | File[]}) => void,
    }
  );


/**
 * TODO: one day do file type icons
 */
export const FileSelectInput:React.FC<FileSelectInputProps> = (props) => {
  const {
    name,
    value,
    uploadProgress,
    onChange,
    disabled,
    allowMultipleFiles,
    accept,
  } = props;

  // This ref is used to trigger the file input browser dialog
  const fileInputRef = createRef<HTMLInputElement>();


  /**
   * Fired when the user selects a file (or files)
   */
  const handleAddFiles = (newFiles:File[]) => {
    if (onChange) {
      if (allowMultipleFiles) {
        (onChange as ((field: {fieldName: string, newValue: null | File[]}) => unknown))({ fieldName: name, newValue: newFiles });
      } else {
        (onChange as ((field: {fieldName: string, newValue: null | File}) => void))({ fieldName: name, newValue: newFiles[0] });
      }
    }
  };


  /**
   * Fired when the user clicks the "Browse" button next to the input
   */
  const handleBrowseClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };


  /**
   * Fired when the user accepts the "browse for files" dialog
   */
  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files) {
      const files = Array.from(e.target.files);
      if (files.length > 0) {
        handleAddFiles(files);
      }
    }

    // Reset the file input for next time
    e.target.value = '';
  };


  /**
   * Fired when the user wants to clear the input
   */
  const handleClearClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (onChange) {
      onChange({ fieldName: name, newValue: null });
    }
  };


  // Work out what to display in the text box
  let displayValue = null;
  if (value) {
    // More than one file selected?
    if (Array.isArray(value) && value.length > 1) {
      displayValue = `(${value.length} files)`;
    } else if (Array.isArray(value) && value.length === 1) {
      displayValue = value[0].name;
    } else if (value instanceof File) {
      displayValue = value.name;
    }
  }


  /**
   * Render
   */
  return (
    <div
      className={classNames(
        'file-select-input',
        {
          disabled,
          'has-selection': !!value,
        },
      )}
    >
      {/* File input for triggering the OS "Browse" dialog. */}
      <input
        type="file"
        accept={accept}
        ref={fileInputRef}
        onChange={handleFileInputChange}
        onClick={(e) => { e.stopPropagation(); }}
        multiple={allowMultipleFiles}
      />

      {/* Text input for displaying the current value */}
      <Input
        type="text"
        placeholder={allowMultipleFiles === true ? 'Drop files(s) here' : 'Drop a file here'}
        // {...props}
        disabled
        onClick={handleBrowseClick}
        onChange={() => {
          // Noop - don't care about changes to the text box.
        }}
        value={displayValue || undefined}
      />

      {/* Target for dropping files */}
      <FileDropTarget
        onClick={handleBrowseClick}
        onDropFiles={handleAddFiles}
        allowMultipleFiles={allowMultipleFiles}
      />

      {/* Only render the clear button if a file is selected */}
      {!!value && (
        <Button
          className="clear"
          onClick={handleClearClick}
          disabled={disabled}
        >
          <Icon i={ICON.CLEAR} />
        </Button>
      )}

      {/* Browse for File(s) button */}
      <Button
        className="browse"
        onClick={handleBrowseClick}
        disabled={disabled}
      >
        <Icon i={ICON.BROWSE_FOR_FILE} />
      </Button>

      {uploadProgress !== null && (
        <div className="upload-progress" style={{ width: `${uploadProgress}%` }} />
      )}
    </div>
  );
};
