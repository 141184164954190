import { ICachedMyobTransactionRecord } from '../../../../types/myob-advanced/cached-myob-project-transaction.record.interface';
import { titleCase } from '../../../../utils/title-case';

export interface ICategoryTreeItem {
  name: string,
  active: boolean,
  getTitle: (tr: ICachedMyobTransactionRecord) => string,
  getDescription: (tr: ICachedMyobTransactionRecord) => string
}

export type CategoryTree = ICategoryTreeItem[];

export const defaultCategoryTree: CategoryTree = [{
  name: 'Account Type', // Income / Expense
  active: true,
  getTitle: (transaction:ICachedMyobTransactionRecord) => transaction.debit_account_detail.type,
  getDescription: (transaction:ICachedMyobTransactionRecord) => titleCase(transaction.debit_account_detail.type),
}, {
  name: 'Project Task',
  active: true,
  getTitle: (transaction:ICachedMyobTransactionRecord) => transaction.project_task,
  getDescription: (transaction:ICachedMyobTransactionRecord) => titleCase(transaction.project_task),
}, {
  name: 'Account Group',
  active: true,
  getTitle: (transaction:ICachedMyobTransactionRecord) => transaction.debit_account_detail.account_group,
  getDescription: (transaction:ICachedMyobTransactionRecord) => titleCase(transaction.debit_account_detail.account_group),
}, {
  name: 'GL Account',
  active: true,
  getTitle: (transaction:ICachedMyobTransactionRecord) => transaction.debit_account_detail.account_code,
  getDescription: (transaction:ICachedMyobTransactionRecord) => transaction.debit_account_detail.description,
}];
