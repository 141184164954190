import React, { createContext } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import 'react-notifications/lib/notifications.css';

import { IConstructor } from '../../types/constructor.interface';
import { INotification } from '../../types/notification/notification.interface';
import { NOTIFICATION_TYPE } from '../../constants/notification-type.const';

export type NotificationContextProps = {
  addNotification: (notification: INotification) => void,
}

export const NotificationContext = createContext<NotificationContextProps>(null as never);

export const NotificationConsumer = NotificationContext.Consumer;

const DEFAULT_NOTIFICATION_TIMEOUT = 60000;

/**
 * @class NotificationProvider
 *
 * @description
 * Wraps the entire application in a provider for managing notification display
 */
export const NotificationProvider: React.FC = function NotificationProvider({ children }) {
  /**
   * Add a notification to the notifications array
   */
  function addNotification(notification: INotification) {
    switch (notification.type) {
      case NOTIFICATION_TYPE.INFO:
        NotificationManager.info(notification.message, notification.headline, notification.timeout ?? DEFAULT_NOTIFICATION_TIMEOUT, notification.onClick);
        break;
      case NOTIFICATION_TYPE.SUCCESS:
        NotificationManager.success(notification.message, notification.headline, notification.timeout ?? DEFAULT_NOTIFICATION_TIMEOUT, notification.onClick);
        break;
      case NOTIFICATION_TYPE.WARNING:
        NotificationManager.warning(notification.message, notification.headline, notification.timeout ?? DEFAULT_NOTIFICATION_TIMEOUT, notification.onClick);
        break;
      case NOTIFICATION_TYPE.DANGER:
      case NOTIFICATION_TYPE.ERROR:
        NotificationManager.error(notification.message, notification.headline, notification.timeout ?? DEFAULT_NOTIFICATION_TIMEOUT, notification.onClick);
        break;
    }
  }


  // /**
  //  * This method is a helper to test all of the notification types.
  //  */
  // function testNotifications() {
  //   const testNotificationRecord: INotificationRecord = {
  //     id: 'ABC123',
  //     type: 'App\\Notifications\\Test',
  //     notifiable_type: 'App\\Models\\User',
  //     notifiable_id: 0,
  //     data: {
  //       title: 'Test Notification,',
  //       shortMessage: 'Test Notification',
  //       message: 'This is a test notification.',
  //       style: NOTIFICATION_TYPE.INFO,
  //       link: '/',
  //       linkText: 'Test Link',
  //     },
  //     read_at: null,
  //     _is_read: false,
  //     user_audit: {
  //       created_at: new Date(),
  //       updated_at: new Date(),
  //       deleted_at: null,
  //     },
  //   };

  //   // Info
  //   addNotification({
  //     type: NOTIFICATION_TYPE.INFO,
  //     headline: 'Info Test',
  //     message: <NotificationBody data={testNotificationRecord.data} />,
  //     timeout: 1000000,
  //   });
  //   // success
  //   addNotification({
  //     type: NOTIFICATION_TYPE.SUCCESS,
  //     headline: 'Success Test',
  //     message: <NotificationBody data={testNotificationRecord.data} />,
  //     timeout: 1000000,
  //   });
  //   // Info
  //   addNotification({
  //     type: NOTIFICATION_TYPE.WARNING,
  //     headline: 'Warning Test',
  //     message: <NotificationBody data={testNotificationRecord.data} />,
  //     timeout: 1000000,
  //   });
  //   // Info
  //   addNotification({
  //     type: NOTIFICATION_TYPE.ERROR,
  //     headline: 'Error Test',
  //     message: <NotificationBody data={testNotificationRecord.data} />,
  //     timeout: 1000000,
  //   });
  // }
  // useEffect(() => {
  //   testNotifications();
  // }, []);


  /**
   * Render
   */
  return (
    <NotificationContext.Provider
      value={{
        addNotification,
      }}
    >
      {children}
      <NotificationContainer />
    </NotificationContext.Provider>
  );
};


/**
 * @description
 * Connect a component to the notification provider
 * (requires the component to be nested under the NotificationProvider component)
 */
export function connectToNotificationProvider<P>(Component: React.FC<P> | IConstructor<React.Component<P>>): React.FC<Omit<P, keyof NotificationContextProps>> {
  return function NotificationProviderConnector(props: Omit<P, keyof NotificationContextProps>) {
    return (
      <NotificationConsumer>
        {(notificationProvider) => <Component {...(props as P)} notificationProvider={notificationProvider} />}
      </NotificationConsumer>
    );
  };
}
