import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';

// import { mapSelectInputOptions } from '../helpers/map-select.helper';

import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { INVOICE_REQUEST_NOTE_TYPE_NAMES } from '../constants/invoice-request-note-type.const';
import { mapSelectInputOptions } from '../helpers/map-select.helper';

export const InvoiceRequestNotesWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.NOTE,
  className: 'notes-widget',
  reverseOrder: true,
  itemCaption: 'Note',
  fields: [
    {
      name: 'invoice_request_id',
      visible: false,
      isParentId: true,
    },
    {
      name: 'type',
      title: 'Note Type',
      formSaveField: 'type_id',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'type',
        key: 'name',
        formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
        inputOptions: mapSelectInputOptions(INVOICE_REQUEST_NOTE_TYPE_NAMES),
      },
    },
    {
      name: 'created_by',
      title: 'Created By',
      formSaveField: 'created_by_id',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'body',
      format: COLUMN_FORMAT.NUMBER,
    },
  ],
};
