import React, { useCallback, useContext } from 'react';
import AsyncSelect from './async-select';

import { CurrentUserContext } from '../providers/current-user-provider';
import { ModalContext } from '../modals/modal-context';

import { ICompanyRecord } from '../../types/company/company.record.interface';
import { FormFieldChangeProps } from '../../types/poly-form/form-field-change-props';
import { NewRecordModalResult } from '../../types/modal/modal-result';

import { MODAL_TYPE } from '../../constants/modal-type.const';
import { PERMISSION } from '../../constants/permissions.const';
import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';

export type CompanyPickerProps = Omit<AsyncSelectComponentProps, 'onChange'> & {
  onChange?: (field: FormFieldChangeProps<ICompanyRecord>) => void,
};

export const CompanyPicker:React.FC<CompanyPickerProps> = (props) => {
  const {
    name,
    formData = {},
    parentData = {},
    formSaveField = null,
    isCreatable = true,
    getNewItemData,
    onChange,
  } = props;

  const { showModal } = useContext(ModalContext);
  const { userHasPermissions } = useContext(CurrentUserContext);

  /**
   * Show a modal to create a new company
   */
  const handleCreateOption = useCallback((inputValue: string) => {
    const initialData = {
      name: inputValue,
      ...(getNewItemData ? getNewItemData(inputValue, formData, parentData) : {}),
    };

    showModal<NewRecordModalResult<ICompanyRecord>>(MODAL_TYPE.NEW_COMPANY, {
      initialData,
      onModalComplete: ({ success, newRecord: newCompany }) => {
        if (success && onChange && newCompany) {
          onChange({
            fieldName: formSaveField || `${name}_id`,
            newValue: newCompany.id,
            objectFieldName: name,
            objectFieldNewValue: newCompany,
          });
        }
      },
    });
  }, [formData, formSaveField, getNewItemData, name, onChange, parentData, showModal]);

  return (
    <AsyncSelect
      {...props}

      // Make sure the current user has permissions to create a new company before allowing them to create a company
      isCreatable={!!isCreatable && userHasPermissions([PERMISSION.COMPANY_CREATE])}
      loadAndKeepAll
      searchRoute="/company"
      onCreateOption={handleCreateOption}
    />
  );
};
