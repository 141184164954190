/**
 * These API Filter Operations map the available set of filter operations
 * in the Portal API.
 */
export const API_FILTER_OPERATION = {
  EQUALS: 'equals',
  NOT: 'not',
  CONTAINS: 'contains',
  GREATER_THAN: 'greater',
  LESS_THAN: 'less',
  BETWEEN: 'between',
  IN: 'in',
  IS_NULL: 'is-null',
  NOT_NULL: 'not-null',
} as const;
export type API_FILTER_OPERATION = typeof API_FILTER_OPERATION;
export type A_API_FILTER_OPERATION = API_FILTER_OPERATION[keyof API_FILTER_OPERATION];
