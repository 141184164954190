import React from 'react';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import PortalFusionChart from './portal-fusion-chart';
import { FUSION_CHART_TYPES_SUPPORTED, FUSION_CHART_TYPES_SINGLE_SERIES, isMultiSeriesChart } from '../../utils/fusion-chart-types';
import FUSION_CHART_DATASET_RENDER_TYPES from '../../utils/fusion-chart-dataset-render-types';
import { financialYear } from '../../helpers/financial-year-moments';
import { PROJECT_STATUS_COLOR_MAP } from '../../utils/color-maps';
import { DATE_FORMAT } from '../../constants/date-format.const';
import { PROJECTS_BY_STATUS_VALUE_TYPE } from '../../constants/projects-by-status-value-type.const';

/**
 * @class ProjectsByStatusChart
 *
 * @description
 * This chart implements the Portal2 API's "Projects By Status" report
 *
 * Can be filtered by client or owner
 *
 * @see https://wiki.ciportal.net/books/ci-portal-developer-documentation/page/projects-by-status
 */
class ProjectsByStatusChart extends React.Component {
  /**
   * @inheritdoc
   *
   * @param {{}} props
   */
  constructor(props) {
    super(props);

    this.state = {
      dataMapping: this.createDataMapping(props),
    };
  }


  /**
   * @inheritdoc
   */
  shouldComponentUpdate(nextProps) {
    const { valueType: oldValueType, renderAs: oldRenderAs } = this.props;
    const { valueType: newValueType, renderAs: newRenderAs } = nextProps;

    if (oldValueType !== newValueType || oldRenderAs !== newRenderAs) {
      this.setState({ dataMapping: this.createDataMapping(nextProps) });
      return false;
    }

    return true;
  }

  /**
   * @description
   * The starting date for the dashboard
   * Format: YYYY-MM-DD
   *
   * @returns {string}
   */
  get dateFrom() {
    const { dateFrom } = this.props;
    return dateFrom.format(DATE_FORMAT.YEAR_MONTH_DAY_DASHES);
  }


  /**
   * @description
   * The ending date for the dashboard
   * Format: YYYY-MM-DD
   *
   * @returns {string}
   */
  get dateTo() {
    const { dateTo } = this.props;
    return dateTo.format(DATE_FORMAT.YEAR_MONTH_DAY_DASHES);
  }


  /**
   * @description
   * Api url for the report
   *
   * @returns {string}
   */
  get dataUrl() {
    const {
      clientId, ownerId, primaryContactId, includeArchived,
    } = this.props;

    if (clientId) {
      return `/report/project/byclientbystatus?include_archived=${includeArchived}&client_id=${clientId}&date_from=${this.dateFrom}&date_to=${this.dateTo}`;
    }

    if (ownerId) {
      return `/report/project/byownerbystatus?include_archived=${includeArchived}&owner_id=${ownerId}&date_from=${this.dateFrom}&date_to=${this.dateTo}`;
    }

    if (primaryContactId) {
      return `/report/project/byprimarycontactbystatus?include_archived=${includeArchived}&primary_contact_id=${primaryContactId}&date_from=${this.dateFrom}&date_to=${this.dateTo}`;
    }

    return `/report/project/bystatus?include_archived=${includeArchived}&date_from=${this.dateFrom}&date_to=${this.dateTo}`;
  }


  /**
   * @description
   * Create the data mapping for the chart
   *
   * @param {{}} withProps
   */
  createDataMapping = (withProps = this.props) => {
    const { renderAs, valueType, chartType } = withProps;

    // TODO: handle multi series chart types
    if (isMultiSeriesChart(chartType)) throw new Error(`Unhandled chartType "${chartType}"`);

    return {
      type: 'single',
      chartType,
      getValue: (dataItem) => (valueType === PROJECTS_BY_STATUS_VALUE_TYPE.COUNT ? dataItem.count : dataItem.value),
      getLabel: this.handleGetLabel,
      getColor: this.handleGetDataItemColour,
      renderAs,
      // filterIn: (dataItem) => dataItem.status_id === projectStatus.statusId,
    };
  }


  /**
   * @description
   * Fired by the Fusion chart to get the labels that should be displayed along the
   * bottom row / x-axis / column etc...
   *
   * @param {object} dataItem the data item to extract an x-axis / column label
   * @returns {string}
   */
  handleGetLabel = (dataItem) => dataItem.status_name;

  /**
   * @description
   * Get the colour for a data item
   *
   * @param {{}} dataItem
   * @returns {string}
   */
  handleGetDataItemColour = (dataItem) => PROJECT_STATUS_COLOR_MAP[dataItem.status_id];


  /**
   * @description
   * Get the report data from the API's response
   *
   * @returns {object}
   */
  handleGetReportDataFromResponse = (response) => response.data;


  /**
   * @inheritdoc
   */
  render() {
    const {
      id,
      chartCaption,
      paletteColors,
      onClickDataItem,
      onLoading,
      chartType,
      valueType,
      chartSubCaption,
      chartXAxisName,
      chartYAxisName,
      showValues,
    } = this.props;

    const { dataMapping } = this.state;

    return (
      <PortalFusionChart
        id={id}
        paletteColors={paletteColors}
        dataMapping={dataMapping}
        data={{
          url: this.dataUrl,
          onLoading,
        }}
        numberPrefix={valueType === PROJECTS_BY_STATUS_VALUE_TYPE.COUNT ? null : '$'}
        chartType={chartType}
        caption={chartCaption}
        subCaption={chartSubCaption}
        xAxisName={chartXAxisName}
        yAxisName={chartYAxisName}
        showValues={showValues}
        getLabel={this.handleGetLabel}
        getDataItemColour={this.handleGetDataItemColour}
        getReportDataFromResponse={this.handleGetReportDataFromResponse}
        onClickDataItem={onClickDataItem}
        onLoading={onLoading}
      />
    );
  }
}

ProjectsByStatusChart.propTypes = {
  id: PropTypes.string,
  clientId: PropTypes.number,
  primaryContactId: PropTypes.number,
  ownerId: PropTypes.number,
  renderAs: PropTypes.oneOf(Object.values(FUSION_CHART_DATASET_RENDER_TYPES)),
  paletteColors: PropTypes.arrayOf(PropTypes.string),
  valueType: PropTypes.oneOf(Object.values(PROJECTS_BY_STATUS_VALUE_TYPE)),
  chartCaption: PropTypes.string,
  chartSubCaption: PropTypes.string,
  chartType: PropTypes.oneOf(Object.values(FUSION_CHART_TYPES_SUPPORTED)),
  chartXAxisName: PropTypes.string,
  chartYAxisName: PropTypes.string,
  showValues: PropTypes.bool,
  includeArchived: PropTypes.bool,


  onClickDataItem: PropTypes.func,
  // onClickSeriesLabel: PropTypes.func,
  // onChangeSeriesVisibility: PropTypes.func,
  onLoading: PropTypes.func,

  dateFrom: PropTypes.instanceOf(moment),
  dateTo: PropTypes.instanceOf(moment),
};

ProjectsByStatusChart.defaultProps = {
  id: 'chart_projects_by_status',
  clientId: null,
  primaryContactId: null,
  ownerId: null,
  paletteColors: null,
  renderAs: FUSION_CHART_DATASET_RENDER_TYPES.COLUMN,
  valueType: PROJECTS_BY_STATUS_VALUE_TYPE.VALUE,
  chartCaption: 'Projects by Status',
  chartType: FUSION_CHART_TYPES_SINGLE_SERIES.COLUMN_2D,
  chartSubCaption: null,
  chartXAxisName: null,
  chartYAxisName: null,
  showValues: false,
  includeArchived: true,

  onClickDataItem: null,
  // onClickSeriesLabel: null,
  // onChangeSeriesVisibility: null,
  onLoading: null,

  dateFrom: financialYear(),
  dateTo: financialYear({ endOfYear: true }),
};


export default ProjectsByStatusChart;
