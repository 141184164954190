import React from 'react';
import PropTypes from 'prop-types';

const AlphaToolbar = ({ alphaList, onClickSearch }) => (
  <div className="alpha-toolbar">
    {alphaList.map((alpha) => (
      <React.Fragment key={`alpha-button-${alpha}`}>
        <span>
          <a href={`#users-starting-with-${alpha.toLowerCase()}`}>{alpha}</a>
        </span>
        <span className="separator">|</span>
      </React.Fragment>
    ))}
    <span>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={onClickSearch} alt="Search" href="#"><span role="img" aria-label="search">🔍</span></a>
    </span>
  </div>
);

AlphaToolbar.propTypes = {
  alphaList: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClickSearch: PropTypes.func.isRequired,
};

export default AlphaToolbar;
