import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

/**
 * Table Definition for Commission Payment Batches Table
 */
const commissionPaymentBatchesTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Commission Payment Batch',
  initialFormFocusFieldName: 'first',
  baseRoute: '/commission-payment-batch',
  // baseQueryString: [
  //   'with[]=commissionPaymentIteration:id,name',
  // ].join('&'),

  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'name',
        'from_date',
        'to_date',
        'payments_generated_at',
        'payments_processed_at',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'name',
        'from_date',
        'to_date',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'name',
        'from_date',
        'to_date',
        'payments_generated_at',
        'payments_processed_at',
      ],
    },
  ],

  /**
   * Definition of columns and APIPolyForm fields for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Identity',
      description: 'Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      linkRoute: '/finance/sales-commission/payment-batches',
      visible: false,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'name',
      title: 'Name',
      description: 'Batch name',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: '/finance/sales-commission/payment-batches',
      visible: true,
      required: true,
      order: 10,
      formOrder: 10,
    },
    {
      name: 'from_date',
      title: 'From Date',
      description: 'From Date (Inclusive)',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: true,
      order: 20,
      formOrder: 20,
    },
    {
      name: 'to_date',
      title: 'To Date',
      description: 'To Date (Inclusive)',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: true,
      order: 30,
      formOrder: 30,
    },
    {
      name: 'payments_generated_at',
      title: 'Payments Generated',
      description: 'The date the payments were generated',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      order: 40,
      formOrder: 40,
    },
    {
      name: 'payments_processed_at',
      title: 'Payments Processed',
      description: 'The date the payments were processed',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      order: 50,
      formOrder: 50,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add a Batch',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new Commission Payment Batch',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the commission Payment Batch',
      successMessage: 'Updated licences',
      failMessage: 'Failed to update Commission Payment Batch',
      hideRecordActionButton: true,
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the Commission Payment Batch',
      successMessage: 'Commission Payment Batch deleted',
      failMessage: 'Failed to delete Commission Payment Batch',
      hideRecordActionButton: false,
    },
    {
      name: 'generate-commission-report',
      title: 'Generate Report',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.EXCEL_DOWNLOAD,
      description: 'This generate the report for the batch, and send it via email.',
      successMessage: 'The Commission Payment Batch report is being generated, and will be emailed to you shortly!',
      failMessage: 'Failed to generate Commission Payment Batch report',
      hideRecordActionButton: false,
    },
    {
      name: 'generate-commission-payments',
      title: 'Generate Payments',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EXPENSE_CLAIMS,
      description: 'This generate the payments for the batch.',
      successMessage: 'Commission Payment Batch payments generated!',
      failMessage: 'Failed to generate Commission Payment Batch',
      hideRecordActionButton: false,
    },
    {
      name: 'process',
      title: 'Mark Batch as Processed',
      color: BUTTON_COLOR.INFO,
      icon: ICON.PROCESS_EXPENSES,
      description: 'This marks the payments for the batch as processed.',
      successMessage: 'Commission Payment Batch payments processed!',
      failMessage: 'Failed to process the Commission Payment Batch',
      hideRecordActionButton: false,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Commission Payment Batch Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'active-rate-audit',
      title: 'Active Rate Audit',
      description: 'Missing Commission Rate Audit - Active Projects',
      widgetDefinition: WIDGET_DEFINITION.COMMISSION_BATCH_RATE_AUDIT,
      apiRoute: '/missing-rate-audit',
      apiQuery: '',
    },
    {
      name: 'completed-rate-audit',
      title: 'Completed Rate Audit',
      description: 'Missing Commission Rate Audit - Completed Projects',
      widgetDefinition: WIDGET_DEFINITION.COMMISSION_BATCH_RATE_AUDIT,
      apiRoute: '/missing-rate-audit',
      apiQuery: 'completed=true',
    },
    {
      name: 'lost-and-other-audit',
      title: 'Lost & Other Audit',
      description: 'Missing Commission Rate Audit - Completed Projects',
      widgetDefinition: WIDGET_DEFINITION.COMMISSION_BATCH_RATE_AUDIT,
      apiRoute: '/lost-and-other-audit',
      apiQuery: '',
    },
    {
      name: 'forecast-summary',
      title: 'Forecast Summary',
      description: 'Forecast of Commission Payments per Salesperson (since 1/07/2022)',
      widgetDefinition: WIDGET_DEFINITION.COMMISSION_PAYMENT_FORECAST,
      apiRoute: '/commission-payment-forecast',
      apiQuery: 'summarise=true',
    },
    {
      name: 'forecast-detail',
      title: 'Forecast Detail',
      description: 'Forecast of Commission Payments per Salesperson (since 1/07/2022)',
      widgetDefinition: WIDGET_DEFINITION.COMMISSION_PAYMENT_FORECAST_DETAIL,
      apiRoute: '/commission-payment-forecast',
      apiQuery: '',
    },
    {
      name: 'payment-summary',
      title: 'Payment Summary',
      description: 'Summary of Commission Payments per Salesperson on this batch',
      widgetDefinition: WIDGET_DEFINITION.COMMISSION_PAYMENT_SUMMARY,
      apiRoute: '/commission-payment-report',
      apiQuery: 'summarise=true',
    },
    {
      name: 'payment-detail',
      title: 'Payment Detail',
      description: 'Detail of Commission Payments per Salesperson on this batch',
      widgetDefinition: WIDGET_DEFINITION.COMMISSION_PAYMENT_DETAIL,
      apiRoute: '/commission-payment-record',
      apiQuery: [
        'with[]=user:id,name',
        'with[]=project:id,project_number,partner_program_id',
        'pagelength=-1',
      ].join('&'),
    },
    {
      ...eventsTabDefault,
      description: 'Event Audit Trail',
    },
  ],
};

export default commissionPaymentBatchesTableInitialSettings;
