import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { projectsWidgetFilter } from '../utils/helpers';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { PROJECT_STATUS } from '../constants/project-status.const';
import { ICON } from '../constants/icon.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';

/**
 * Table Definition for People (Contacts) Table
 */
const contactTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Contact',
  initialFormFocusFieldName: 'first',

  baseRoute: '/person',
  baseQueryString: 'with[]=company:id,name&with_count[]=projects',

  /**
   * Reset the local storage settings if they were stored with any of these app versions
   * Add application version numbers to this list (make sure it is a PREVIOUS application
   * version number which reflects a now invalid set of settings)
   */
  resetForAppVersion: [
    '2.1.6',
    '2.7.9',
  ],

  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'name',
        'company',
        'role',
        'email',
        'mobile',
        'office',
        'address_line_one',
        'address_line_two',
        'address_town',
        'address_state',
        'address_postcode',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'name',
        'office',
        'email',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'name',
        'first',
        'middle',
        'last',
        'company',
        'role',
        'email',
        'mobile',
        'office',
        'address_line_one',
        'address_line_two',
        'address_town',
        'address_state',
        'address_postcode',
        'profile_url',
        'avatar_url',
        'count_of_projects',
      ],
    },
  ],

  /**
   * Definition of columns and APIPolyForm fields for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Identity',
      description: 'Person Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      linkRoute: '/crm/contacts',
      visible: false,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'name',
      title: 'Full Name',
      description: 'Full Name',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: '/crm/contacts',
      getValue: (value, row) => row._full_name,
      formSaveField: '_full_name',
      sortColumn: '_full_name', // This requires special overrides in the api "person" controller
      visible: true,
      showInForm: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 10,
      formOrder: 10,
    },
    {
      name: 'first',
      title: 'First Name',
      description: 'First Name',
      format: COLUMN_FORMAT.TEXT,
      visible: false,
      required: true,
      maxLength: 50,
      order: 20,
      formOrder: 20,
    },
    {
      name: 'middle',
      title: 'Middle',
      description: 'Middle name',
      format: COLUMN_FORMAT.TEXT,
      visible: false,
      // maxLength: 3,
      order: 30,
      formOrder: 30,
    },
    {
      name: 'last',
      title: 'Last Name',
      description: 'Last name',
      format: COLUMN_FORMAT.TEXT,
      required: true,
      visible: false,
      maxLength: 50,
      order: 40,
      formOrder: 40,
    },
    {
      name: 'company',
      title: 'Company',
      description: 'Who does this person work for?',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'company',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/crm/companies',
        listSource: 'company',
      },
      formSaveField: 'company_id',
      formFieldType: FORM_FIELD_TYPE.COMPANY_PICKER,
      isCreatable: true,
      visible: true,
      order: 50,
      formOrder: 50,
    },
    {
      name: 'role',
      title: 'Company Role',
      description: "Person's role in company",
      format: COLUMN_FORMAT.TEXT,
      visible: true,
      order: 60,
      formOrder: 60,
      autoComplete: true,
      autoCompleteIdentifier: 'contact_company_role',
    },
    {
      name: 'email',
      title: 'Email Address',
      description: 'Email Address',
      format: COLUMN_FORMAT.EMAIL,
      visible: true,
      order: 70,
      formOrder: 70,
    },
    {
      name: 'mobile',
      title: 'Mobile Number',
      description: 'Mobile number',
      format: COLUMN_FORMAT.PHONE,
      visible: true,
      order: 80,
      formOrder: 80,
    },
    {
      name: 'office',
      title: 'Office Number',
      description: 'Office number',
      format: COLUMN_FORMAT.PHONE,
      visible: true,
      order: 90,
      formOrder: 90,
    },
    {
      name: 'address_line_one',
      title: 'Address Line 1',
      description: 'Address Line 1',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      order: 91,
      formOrder: 91,
    },
    {
      name: 'address_line_two',
      title: 'Address Line 2',
      description: 'Address Line 2',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      order: 92,
      formOrder: 92,
    },
    {
      name: 'address_town',
      title: 'Address Town',
      description: 'Address Town',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      order: 93,
      formOrder: 93,
    },
    {
      name: 'address_state',
      title: 'Address State',
      description: 'Address State',
      format: COLUMN_FORMAT.SHORT_TEXT,
      visible: true,
      order: 94,
      formOrder: 94,
    },
    {
      name: 'address_postcode',
      title: 'Address Postcode',
      description: 'Address Postcode',
      format: COLUMN_FORMAT.SHORT_TEXT,
      visible: true,
      order: 97,
      formOrder: 97,
    },
    {
      name: 'profile_url',
      title: 'Profile URL',
      description: '',
      format: COLUMN_FORMAT.URL,
      visible: false,
      showInForm: false,
      order: 100,
      formOrder: 100,
    },
    {
      name: 'avatar_url',
      title: 'Avatar',
      description: 'Avatar URL',
      format: COLUMN_FORMAT.URL,
      visible: false,
      showInForm: false,
      order: 110,
      formOrder: 110,
    },
    {
      name: 'count_of_projects',
      title: 'Projects & Leads',
      description: 'All Prospects and Projects',
      format: COLUMN_FORMAT.NUMBER,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: false,
      order: 120,
      formOrder: 120,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add a Contact',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new Contact',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the contact',
      successMessage: 'Updated contact',
      failMessage: 'Failed to update Contact',
      hideRecordActionButton: true,
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the Contact',
      successMessage: 'Contact deleted',
      failMessage: 'Failed to delete Contact',
      hideRecordActionButton: true,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Contact\'s Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'dashboards',
      title: 'Dashboards',
      description: 'Contact Dashboards',
      widgetDefinition: WIDGET_DEFINITION.CONTACT_DASHBOARDS,
    },
    {
      name: 'lead',
      title: 'Leads',
      description: 'Contact\'s Leads',
      widgetDefinition: WIDGET_DEFINITION.PROJECTS,
      apiRoute: (row) => projectsWidgetFilter('contact_id', row.id, PROJECT_STATUS.LEAD),
    },
    {
      name: 'proposal',
      title: 'Proposals',
      description: "Contact's Proposals",
      widgetDefinition: WIDGET_DEFINITION.PROJECTS,
      apiRoute: (row) => projectsWidgetFilter('contact_id', row.id, PROJECT_STATUS.PROPOSAL),
    },
    {
      name: 'active',
      title: 'Active Projects',
      description: 'Contact\'s Active Projects',
      widgetDefinition: WIDGET_DEFINITION.PROJECTS,
      apiRoute: (row) => projectsWidgetFilter('contact_id', row.id, PROJECT_STATUS.ACTIVE),
    },
    {
      name: 'completed',
      title: 'Completed Projects',
      description: 'Contact\'s Completed Projects',
      widgetDefinition: WIDGET_DEFINITION.PROJECTS,
      apiRoute: (row) => projectsWidgetFilter('contact_id', row.id, PROJECT_STATUS.COMPLETED),
    },
    {
      name: 'lost',
      title: 'Lost Opportunities',
      description: 'Contact\'s Lost Opportunities',
      widgetDefinition: WIDGET_DEFINITION.PROJECTS,
      apiRoute: (row) => projectsWidgetFilter('contact_id', row.id, PROJECT_STATUS.LOST),
    },
    {
      ...eventsTabDefault,
      description: "Contact's Event Audit Trail",
    },
  ],
};

export default contactTableInitialSettings;
