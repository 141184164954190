export const INTERNAL_PROJECT_TYPE: Record<string, number> = {
  ID: 1,
  IP: 2,
  RD: 3,
  PD: 4,
} as const;
export type INTERNAL_PROJECT_TYPE = typeof INTERNAL_PROJECT_TYPE;
export type AN_INTERNAL_PROJECT_TYPE = INTERNAL_PROJECT_TYPE[keyof INTERNAL_PROJECT_TYPE];

export const INTERNAL_PROJECT_TYPE_ROUTES: {[K in AN_INTERNAL_PROJECT_TYPE]: string } = {
  [INTERNAL_PROJECT_TYPE.ID]: 'internal-development-projects',
  [INTERNAL_PROJECT_TYPE.IP]: 'internal-process-projects',
  [INTERNAL_PROJECT_TYPE.RD]: 'rde-projects',
  [INTERNAL_PROJECT_TYPE.PD]: 'product-development-projects',
} as const;

export const INTERNAL_PROJECT_TYPE_NAMES: {[K in AN_INTERNAL_PROJECT_TYPE]: string } = {
  [INTERNAL_PROJECT_TYPE.ID]: 'ID - Internal Development',
  [INTERNAL_PROJECT_TYPE.IP]: 'IP - Internal Process',
  [INTERNAL_PROJECT_TYPE.RD]: 'RD - Research, Development & Engineering',
  [INTERNAL_PROJECT_TYPE.PD]: 'PD - Product Development',
} as const;
