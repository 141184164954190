import React, { PropsWithChildren, ReactElement, Ref, useCallback, useImperativeHandle, useMemo } from 'react';
import {
  Menu as ContextMenu,
  Item as ContextMenuItem,
  Separator as ContextMenuSeparator,
  Submenu as ContextSubmenu,
  useContextMenu,
  TriggerEvent as ContextMenuTriggerEvent,
  // animation as contextMenuAnimation,
  ContextMenuParams,
} from 'react-contexify';

import { IPortalTreeViewSelectedNodeIdentifier } from '../../types/portal-tree-view/portal-tree-view-selected-node-identifier.interface';
import { PortalTreeViewMenuItem, PortalTreeViewMenuItems, PortalTreeViewMenuItemTreeState } from '../../types/portal-tree-view/portal-tree-view-menu-items';

import Icon from '../layout-helpers/icon';
import { PORTAL_TREE_VIEW_MENU_TYPE } from './portal-tree-view-menu-type.const';
import { ReactPortal } from '../react-portal/react-portal';

let portalTreeViewContextMenuId = 0;

export type PortalTreeViewContextMenuProps <S extends IPortalTreeViewSelectedNodeIdentifier> = {
  treeState: PortalTreeViewMenuItemTreeState,
  menuItems?: PortalTreeViewMenuItems<S>,
  selectedNodes?: S[],
};

export type PortalTreeViewContextMenuTriggerEvent = ContextMenuTriggerEvent;

export declare type PortalTreeViewContextMenuHandlers = {
  hide(): void;
  show(event: PortalTreeViewContextMenuTriggerEvent, params?: Pick<ContextMenuParams, 'id' | 'props' | 'position'> | undefined): void;
};

/**
 * The PortalTreeViewContextMenu component is a wrapper around the react-contextify component specifically for
 * use in the PortalTreeView and is designed to complement the PortalMultiLevelDropDown component at the top
 * of the tree.
 * @see https://github.com/fkhadra/react-contexify#readme
 */
export const PortalTreeViewContextMenu = <S extends IPortalTreeViewSelectedNodeIdentifier = IPortalTreeViewSelectedNodeIdentifier, >(
  props: PropsWithChildren<PortalTreeViewContextMenuProps<S> & {
    contextMenuRef?: Ref<PortalTreeViewContextMenuHandlers>,
  }>,
): ReactElement<PropsWithChildren<PortalTreeViewContextMenuProps<S>>> => {
  const {
    contextMenuRef,
    menuItems = [],
    treeState,
    selectedNodes = [],
  } = props;

  // Generate a new unique context menu ID for this treeview
  const contextMenuId = useMemo(() => {
    portalTreeViewContextMenuId += 1;
    return portalTreeViewContextMenuId;
  }, []);

  const { show, hideAll } = useContextMenu({ id: `mid_${contextMenuId}` });


  /**
   * This adds some properties to the ref object passed back to the parent which
   * allows the parent to "call" methods on the child. This technically breaks
   * the react uni-directional flow pattern but sometimes it's easier to work around it
   */
  useImperativeHandle(contextMenuRef, () => ({
    hide: hideAll,
    show,
  }));


  /**
   * Evaluated by each context menu item to determine if it is visible
   */
  const handleContextMenuItemIsHidden = useCallback((targetItem: PortalTreeViewMenuItem<S>) => {
    // isHidden property is a function
    if (targetItem.isHidden && (typeof targetItem.isHidden === 'function')) {
      return targetItem.isHidden(PORTAL_TREE_VIEW_MENU_TYPE.CONTEXT_MENU, treeState, targetItem.key, selectedNodes);
    }

    // isHidden property is a boolean
    if (targetItem.isHidden !== undefined) {
      return targetItem.isHidden;
    }

    return false;
  }, [selectedNodes, treeState]);


  /**
   * Fired when an item in the context menu is clicked
   */
  const handleContextMenuItemClick = useCallback((clickedItem: PortalTreeViewMenuItem<S>) => {
    if (clickedItem.onClick) {
      clickedItem.onClick(PORTAL_TREE_VIEW_MENU_TYPE.CONTEXT_MENU, treeState, clickedItem.key, selectedNodes);
    }
  }, [selectedNodes, treeState]);


  /**
   * Render a set of Context Menu Items
   * This is called recursively to render sub-menu items
   */
  const rendermenuItems = useCallback((
    itemsToRender?: PortalTreeViewMenuItems<S>,
  ): React.ReactNode => {
    /**
     * Helper function to render the label the same for context sub menu items and regular context menu items
     */
    const renderContextMenuItemLabel = (itemToRender: PortalTreeViewMenuItem<S>): React.ReactNode => (
      <span>
        {itemToRender.icon && (
          <Icon i={itemToRender.icon} />
        )}
        <span>{itemToRender.label}</span>
      </span>
    );

    return (itemsToRender ?? []).map((itemToRender) => {
      // This item represents a separator
      if (itemToRender.isSeparator) {
        return (
          <ContextMenuSeparator
            key={itemToRender.key}
          />
        );
      }

      // This item represents a submenu
      if (itemToRender.subMenuItems) {
        return (
          <ContextSubmenu
            key={itemToRender.key}
            label={renderContextMenuItemLabel(itemToRender)}
            hidden={handleContextMenuItemIsHidden(itemToRender)}
          >
            {rendermenuItems(itemToRender.subMenuItems)}
          </ContextSubmenu>
        );
      }

      // Normal item
      return (
        <ContextMenuItem
          key={itemToRender.key}
          onClick={() => handleContextMenuItemClick(itemToRender)}
          hidden={handleContextMenuItemIsHidden(itemToRender)}
        >
          {renderContextMenuItemLabel(itemToRender)}
        </ContextMenuItem>
      );
    });
  }, [handleContextMenuItemClick, handleContextMenuItemIsHidden]);

  // Render
  return (
    <ReactPortal>
      <ContextMenu
        id={`mid_${contextMenuId}`}
        className="ptv-context-menu"
        animation={false}
      >
        {rendermenuItems(menuItems)}
      </ContextMenu>
    </ReactPortal>
  );
};
