import React, { useCallback, useContext } from 'react';

import { CurrentUserContext } from '../providers/current-user-provider';
import { ModalContext } from '../modals/modal-context';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';
import { IInternalProjectRecord } from '../../types/internal-project/internal-project.record.interface';
import { NewRecordModalResult } from '../../types/modal/modal-result';

import AsyncSelect from './async-select';

import { PERMISSION } from '../../constants/permissions.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';

export type InternalProjectPickerProps = AsyncSelectComponentProps;

export const InternalProjectPicker:React.FC<InternalProjectPickerProps> = (props) => {
  const {
    name,
    formSaveField = null,
    isCreatable = false,
    formData = {},
    parentData = {},
    getNewItemData,
    onChange,
  } = props;

  const { showModal } = useContext(ModalContext);
  const { userHasPermissions } = useContext(CurrentUserContext);


  /**
   * Show a modal to create a new internal Project
   */
  const handleCreateOption = useCallback((inputValue: string) => {
    const initialData = {
      ...(getNewItemData ? getNewItemData(inputValue, formData, parentData) : undefined),
    };

    showModal<NewRecordModalResult<IInternalProjectRecord>>(MODAL_TYPE.NEW_INTERNAL_PROJECT, {
      initialData,
      onModalComplete: ({ success, newRecord: newInternalProject }) => {
        if (success && onChange && newInternalProject) { onChange({
          fieldName: formSaveField || `${name}_id`,
          newValue: newInternalProject.id,
          objectFieldName: name,
          objectFieldNewValue: newInternalProject,
        }); }
      },
    });
  }, [formData, formSaveField, getNewItemData, name, onChange, parentData, showModal]);

  return (
    <AsyncSelect
      {...props}
      searchRoute="/internal-project"
      loadAndKeepAll
      onCreateOption={handleCreateOption}
      // Make sure the current internalProject has permissions to create a new internalProject before allowing them to create a internalProject
      isCreatable={isCreatable && userHasPermissions([
        PERMISSION.INTERNAL_PROJECT_ADMIN,
      ])}
    />
  );
};
