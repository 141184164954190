export const NEWS_STATUS = {
  DRAFT: 1,
  SUBMITTED: 2,
  SCHEDULED: 3,
  PUBLISHED: 4,
  ARCHIVED: 5,
} as const;
export type NEWS_STATUS = typeof NEWS_STATUS;
export type A_NEWS_STATUS = NEWS_STATUS[keyof NEWS_STATUS];

export const NEWS_STATUS_MAP: {[K in A_NEWS_STATUS]: string} = {
  [NEWS_STATUS.DRAFT]: 'Draft',
  [NEWS_STATUS.SUBMITTED]: 'Submitted',
  [NEWS_STATUS.SCHEDULED]: 'Scheduled',
  [NEWS_STATUS.PUBLISHED]: 'Published',
  [NEWS_STATUS.ARCHIVED]: 'Archived',
};
