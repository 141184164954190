import React, { ReactElement, PropsWithChildren, useCallback, Ref } from 'react';

import { IPortalTreeViewSelectedNodeIdentifier } from '../../types/portal-tree-view/portal-tree-view-selected-node-identifier.interface';
import { PortalTreeViewMenuItem, PortalTreeViewMenuItems, PortalTreeViewMenuItemTreeState } from '../../types/portal-tree-view/portal-tree-view-menu-items';

import { PortalMultiLevelDropDown, PortalMultiLevelDropDownHandlers } from '../portal-multi-level-drop-down/portal-multi-level-drop-down';
import { PortalMultiLevelDropDownItem } from '../portal-multi-level-drop-down/portal-multi-level-drop-down-item';
import { PortalMultiLevelDropDownSeparator } from '../portal-multi-level-drop-down/portal-multi-level-drop-down-separator';
import { PortalMultiLevelDropDownSubMenuItem } from '../portal-multi-level-drop-down/portal-multi-level-drop-down-sub-menu-item';

import { PORTAL_TREE_VIEW_MENU_TYPE } from './portal-tree-view-menu-type.const';
import { AN_ICON } from '../../constants/icon.const';

export type PortalTreeViewDropdownMenuProps<S extends IPortalTreeViewSelectedNodeIdentifier = IPortalTreeViewSelectedNodeIdentifier> = {
  items: PortalTreeViewMenuItems<S>,
  treeState: PortalTreeViewMenuItemTreeState,
  selectedNodes?: S[],
  rightAligned?: boolean,
  buttonIcon?: AN_ICON,
  buttonCaption?: string,
  onToggle?: (isOpen: boolean) => void,
};

/**
 * The PortalTreeViewDropdownMenu component is a wrapper around the PortalMultiLevelDropDown
 * which converts item definitions into the three menu, sub-menu and item components required for
 * the PortalMultiLevelDropDown component.
 */
export const PortalTreeViewDropdownMenu = <S extends IPortalTreeViewSelectedNodeIdentifier = IPortalTreeViewSelectedNodeIdentifier>(
  props: PropsWithChildren<PortalTreeViewDropdownMenuProps<S> & {
    innerRef?: Ref<PortalMultiLevelDropDownHandlers>
  }>,
): ReactElement<PropsWithChildren<PortalTreeViewDropdownMenuProps<S>>> => {
  const {
    innerRef,
    items,
    selectedNodes = [],
    treeState,
    rightAligned = false,
    buttonIcon,
    buttonCaption,
    onToggle,
  } = props;


  /**
   * Evaluated by each menu item to determine if it is hidden
   */
  const handleMenuItemIsHidden = useCallback((targetItem: PortalTreeViewMenuItem<S>) => {
    // isHidden property is a function
    if (targetItem.isHidden && (typeof targetItem.isHidden === 'function')) {
      return targetItem.isHidden(PORTAL_TREE_VIEW_MENU_TYPE.TITLE_MENU, treeState, targetItem.key, selectedNodes);
    }

    // isHidden property is a boolean
    if (targetItem.isHidden !== undefined) {
      return targetItem.isHidden;
    }

    return false;
  }, [selectedNodes, treeState]);


  /**
   * Evaluated by each menu item to determine if it is disabled
   */
  const handleMenuItemIsDisabled = useCallback((targetItem: PortalTreeViewMenuItem<S>) => {
    // isDisabled property is a function
    if (targetItem.isDisabled && (typeof targetItem.isDisabled === 'function')) {
      return targetItem.isDisabled(PORTAL_TREE_VIEW_MENU_TYPE.TITLE_MENU, treeState, targetItem.key, selectedNodes);
    }

    // isHidden property is a boolean
    if (targetItem.isDisabled !== undefined) {
      return targetItem.isDisabled;
    }

    return false;
  }, [selectedNodes, treeState]);


  /**
   * Fired when an item in the context menu is clicked
   */
  const handleMenuItemClick = useCallback((clickedItem: PortalTreeViewMenuItem<S>) => {
    if (clickedItem.onClick) {
      clickedItem.onClick(PORTAL_TREE_VIEW_MENU_TYPE.TITLE_MENU, treeState, clickedItem.key, selectedNodes);
    }
  }, [selectedNodes, treeState]);


  /**
   * Render a set of menu items
   * @param itemsToRender
   * @returns
   */
  const renderMenu = useCallback((itemsToRender: PortalTreeViewMenuItems<S>) => itemsToRender.map((itemToRender) => (
    <React.Fragment
      key={itemToRender.key}
    >
      {/* Separator */}
      {itemToRender.isSeparator && (
        <PortalMultiLevelDropDownSeparator />
      )}

      {/* Normal Item */}
      {(!itemToRender.subMenuItems) && (!itemToRender.isSeparator) && (!handleMenuItemIsHidden(itemToRender)) && (
        <PortalMultiLevelDropDownItem
          disabled={handleMenuItemIsDisabled(itemToRender)}
          icon={itemToRender.icon}
          label={itemToRender.label}
          onClick={() => handleMenuItemClick(itemToRender)}
        />
      )}

      {/* SubMenu */}
      {(itemToRender.subMenuItems && itemToRender.subMenuItems.length > 0) && (!itemToRender.isSeparator) && (!handleMenuItemIsHidden(itemToRender)) && (
        <PortalMultiLevelDropDownSubMenuItem
          disabled={handleMenuItemIsDisabled(itemToRender)}
          icon={itemToRender.icon}
          label={itemToRender.label}
          rightAligned={rightAligned}
        >
          {renderMenu(itemToRender.subMenuItems)}
        </PortalMultiLevelDropDownSubMenuItem>
      )}
    </React.Fragment>
  )), [handleMenuItemClick, handleMenuItemIsDisabled, handleMenuItemIsHidden, rightAligned]);


  // Render
  return (
    <PortalMultiLevelDropDown
      ref={innerRef}
      rightAligned={rightAligned}
      onToggle={onToggle}
      buttonCaption={buttonCaption}
      buttonIcon={buttonIcon}
    >
      {renderMenu(items)}
    </PortalMultiLevelDropDown>
  );
};
