import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { formatValueString } from '../../render-functions';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';

const DToolsOrderItemsTable = ({
  orderData, showDescriptions, showingOrder,
}) => (
  <table className="table table-striped table-hover color-table inverse-table">
    <thead>
      <tr>
        <th>Manufacturer</th>
        <th>Model</th>
        <th>Part Number</th>
        <th>Estimate</th>
        <th className="text-right">Product Cost</th>
        <th className="text-right">Quantity</th>
        <th className="text-right">Total Cost</th>
      </tr>
    </thead>
    <tbody>
      {orderData.map((item) => {
        if (item.TotalCost === 0 && !item.Vendor) return null;
        // Longass key because nothing is unique here
        return (
          <Fragment key={`order-${item.Manufacturer}-${item.Model}-${item.TotalCost}-${item.AcctgEstNum}`}>
            <tr>
              <td>{item.Manufacturer}</td>
              <td>{item.Model}</td>
              <td>{item.PartNumber}</td>
              <td>{item.AcctgEstNum}</td>
              <td className="text-right">{formatValueString(item.ProductCost, COLUMN_FORMAT.CURRENCY)}</td>
              <td className="text-right">{item.Quantity}</td>
              <td className="text-right">{formatValueString(item.TotalCost, COLUMN_FORMAT.CURRENCY)}</td>
            </tr>
            {showDescriptions && (
              <tr>
                <th colSpan="2" className="text-right">
                  Short Description
                </th>
                <td colSpan="5">{item.ShortDescription}</td>
              </tr>
            )}
          </Fragment>
        );
      })}
    </tbody>
    <tfoot>
      <tr>
        <th colSpan="6" className="text-right">
          Total
        </th>
        <td className="text-right">{formatValueString(showingOrder.TotalCost, COLUMN_FORMAT.CURRENCY)}</td>
      </tr>
    </tfoot>
  </table>
);

DToolsOrderItemsTable.propTypes = {
  orderData: PropTypes.arrayOf(
    PropTypes.shape({
      ShortDescription: PropTypes.string,
    }),
  ).isRequired,
  showDescriptions: PropTypes.bool.isRequired,
  showingOrder: PropTypes.shape({
    TotalCost: PropTypes.number,
  }).isRequired,
};

export default DToolsOrderItemsTable;
