import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Autocomplete, GoogleMap, GoogleMapProps, Marker, useLoadScript } from '@react-google-maps/api';
import { Spinner } from 'reactstrap';

import { shallowEqual } from 'react-redux';
import { ILocationRecord } from '../../types/location-record.interface';

import Icon from '../layout-helpers/icon';

import { GMAPS_KEY } from '../../utils/constants';
import { ICON } from '../../constants/icon.const';
import { usePreviousValue } from '../../react-hooks/use-previous-value.hook';
// import { useAppSelector } from '../../react-hooks/redux-hooks';
import { IStateRecord } from '../../types/state.record.interface';

export type LatLngLiteral = google.maps.LatLngLiteral;

export type PortalGoogleMapProps = {
  className?: string,
  options?: GoogleMapProps['options'],
  fill?: boolean,
  markerPosition?: LatLngLiteral,
  isEditing?: boolean,
  statesList?: IStateRecord[],
  onLocationChange?: (newLocationDetails?: MapLocationDetails) => void,
  onMarkerPositionChange?: (newMarkerPosition?: LatLngLiteral) => void,
}

export type MapLocationDetails = Pick<ILocationRecord,
  'name' |
  'address_line_1' |
  'address_line_2' |
  'suburb' |
  'state' |
  'state_id' |
  'postcode' |
  'lat' |
  'lng'
>

// 360 Whitehorse Road
const defaultLocation: LatLngLiteral = {
  lat: -37.818813,
  lng: 145.177283,
};


// @note: this was removed from dist and is not exported from the google maps api library so it needs to be re-declared here
declare type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[];

const libraries: Libraries = ['places'];

/**
 * Wrapper Component for implementing Google Maps
 * @see https://react-google-maps-api-docs.netlify.app/#section-getting-started
 * @see https://developers.google.com/maps/documentation/javascript/reference/places-widget#Autocomplete.setFields
 */
export const PortalGoogleMap: React.FC<PortalGoogleMapProps> = (props) => {
  const {
    className,
    options,
    markerPosition,
    fill = true,
    isEditing = false,
    statesList = [],
    onLocationChange,
    onMarkerPositionChange,
  } = props;

  // const statesList = useAppSelector((state) => state.apiQueryCache.states.queryResponse?.data);

  const { isLoaded: scriptIsLoaded, loadError: scriptLoadError } = useLoadScript({
    googleMapsApiKey: GMAPS_KEY,
    libraries,
  });
  const [mapCenter, setMapCenter] = useState<LatLngLiteral | google.maps.LatLng | undefined>(markerPosition ?? defaultLocation);
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
  const [markerInstance, setMarkerInstance] = useState<google.maps.Marker | null>(null);
  const [searchBoxInstance, setSearchBoxInstance] = useState<google.maps.places.Autocomplete | null>(null);

  const oldMarkerPosition = usePreviousValue(markerPosition);

  const internalOptions = useMemo((): GoogleMapProps['options'] => ({
    // Default internal Options
    disableDefaultUI: true,
    clickableIcons: false,
    styles: [
      // Turn off Points of Interest
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
          { visibility: 'off' },
        ],
      },
    ],

    // User overridden options
    ...options,
  }), [options]);

  /**
   * Parse a google place returned from the Autocomplete
   * @see https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
   */
  const parseGooglePlace = (place: google.maps.places.PlaceResult): MapLocationDetails => {
    const result: Partial<MapLocationDetails> = {
      name: place.name,
      address_line_1: '',
      address_line_2: undefined,
      suburb: '',
      postcode: '',
      lat: place.geometry?.location?.lat(),
      lng: place.geometry?.location?.lng(),
    };

    const getStateFromList = (stateShort: string) => (statesList ? statesList.filter(
      (state: { id: number, acronym?: string }) => state.acronym?.toUpperCase() === stateShort.toUpperCase(),
    ) : [])[0];

    // Iterate over the components of the place result
    place.address_components?.forEach(((component) => {
      const componentType = component.types[0];

      switch (componentType) {
        // Street number (Address line 1)
        case 'street_number': {
          result.address_line_1 = `${component.long_name} ${result.address_line_1}`;
          break;
        }

        // Address Line 1
        case 'route': {
          result.address_line_1 += component.short_name;
          break;
        }

        // Post Code
        case 'postal_code': {
          result.postcode = `${component.long_name}${result.postcode}`;
          break;
        }

        // Post Code suffix
        case 'postal_code_suffix': {
          result.postcode = `${result.postcode}-${component.long_name}`;
          break;
        }

        // Suburb
        case 'locality':
          result.suburb = component.short_name;
          break;

        // State
        case 'administrative_area_level_1': {
          const stateRecord = getStateFromList(component.short_name);
          result.state = stateRecord;
          result.state_id = stateRecord?.id;
          break;
        }

        // Country
        // case "country":
        // break;

        default:
          // console.log(component);
          break;
      }
    }));

    return result as MapLocationDetails;
  };


  /**
   * Fired when the google maps element is loaded to bind a reference to the object
   */
  const handleGoogleMapsLoaded = useCallback((newMapInstance: google.maps.Map | null) => {
    setMapInstance(newMapInstance);
  }, []);


  /**
   * Fired when the map marker is loaded to bind a reference to the object
   */
  const handleMarkerLoaded = useCallback((newMarkerInstance: google.maps.Marker | null) => {
    setMarkerInstance(newMarkerInstance);
  }, []);


  /**
   * Fired when the autocomplete search box is loaded to bind a reference to the object
   */
  const handleSearchBoxLoaded = (newSearchBoxInstance: google.maps.places.Autocomplete) => {
    setSearchBoxInstance(newSearchBoxInstance);
  };


  /**
   * Fired when the user completes a search query in the place search box
   */
  const handlePlaceChanged = useCallback(() => {
    if (searchBoxInstance) {
      const locationDetails: MapLocationDetails = parseGooglePlace(searchBoxInstance.getPlace());

      // Move the map to that location
      if (locationDetails && locationDetails.lng && locationDetails.lat) {
        setMapCenter({ lat: locationDetails.lat, lng: locationDetails?.lng });
      }

      // Fire the location change event
      if (onLocationChange) {
        onLocationChange(locationDetails);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLocationChange, searchBoxInstance]);


  /**
   * Fired when the centre of the google map is dragged around
   */
  const handleMapCentreChanged = useCallback(() => {
    if (mapInstance) {
      setMapCenter(mapInstance.getCenter());
    }
  }, [mapInstance]);


  /**
   * Fired when the user finishes dragging the marker around
   */
  const handleMarkerPositionChanged = useCallback(() => {
    if (markerInstance) {
      const newMarkerPosition = markerInstance.getPosition();
      if (onMarkerPositionChange) {
        onMarkerPositionChange(newMarkerPosition ? { lat: newMarkerPosition.lat(), lng: newMarkerPosition.lng() } : undefined);
      }
    }
  }, [markerInstance, onMarkerPositionChange]);


  /**
   * Fired whenever the consumer updates the marker position
   */
  useEffect(() => {
    if (!shallowEqual(markerPosition, oldMarkerPosition)) {
      setMapCenter(markerPosition ?? defaultLocation);
    }
  }, [markerPosition, oldMarkerPosition]);


  // Render
  return (
    <div
      className={classNames('portal-google-map', className, {
        fill,
      })}
    >
      {/* There was a problem loading the google map */}
      {scriptLoadError && (
        <div className="load-error">
          <Icon i={ICON.SITE} />
          <span>Google Maps could not be loaded</span>
        </div>
      )}

      {!scriptIsLoaded && !scriptLoadError && !mapInstance && (
        <div className="spinner-wrapper">
          <Spinner />
        </div>
      )}

      {scriptIsLoaded && (
        <GoogleMap
          id="circle-example"
          mapContainerClassName="map-container"
          options={internalOptions}
          onLoad={handleGoogleMapsLoaded}
          zoom={17}
          center={mapCenter}
          onCenterChanged={handleMapCentreChanged}
        >
          {isEditing && (
            <Autocomplete
              onLoad={handleSearchBoxLoaded}
              onPlaceChanged={handlePlaceChanged}
              restrictions={{
                country: ['au'],
              }}
              fields={[
                'address_components',
                'name',
                'geometry',
              ]}
            >
              <input
                type="text"
                placeholder="Search for a Location"
                style={{
                  boxSizing: 'border-box',
                  border: '1px solid transparent',
                  width: '90%',
                  height: '32px',
                  padding: '0 12px',
                  borderRadius: '3px',
                  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                  fontSize: '14px',
                  outline: 'none',
                  textOverflow: 'ellipses',
                  position: 'absolute',
                  marginLeft: '10px',
                  marginTop: '10px',
                }}
              />
            </Autocomplete>
          )}
          {markerPosition && (
            <Marker
              onLoad={handleMarkerLoaded}
              clickable={false}
              position={markerPosition}
              draggable={isEditing}
              onDragEnd={handleMarkerPositionChanged}
            />
          )}
        </GoogleMap>
      )}
    </div>
  );
};
