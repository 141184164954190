import { SORT_DIRECTION } from '@corporate-initiatives/ci-portal-js-sdk';
import {
  projectDesignTypeOptions,
  projectPhaseOptions,
} from '../utils/constants';
import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { startCase } from '../utils/helpers';
import { fullName } from '../helpers/full-name.helper';
import { PROJECT_STATUS_NAME_MAP } from '../constants/project-status.const';
import { mapSelectInputOptions } from '../helpers/map-select.helper';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';
import { MODAL_TYPE } from '../constants/modal-type.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';


/**
 * Table Definition for Project Table
 *
 * @todo all of the 'input-options' settings are getting stored in the redux store which
 *       is quite a bad choice.
 */
const orderConfirmationTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Project',
  initialFormFocusFieldName: 'name',
  baseRoute: '/project',
  defaultTabKey: 'clientorders',
  baseQueryString: [
    'with[]=phase',
    'with[]=status',
    'with[]=state:id,acronym',
    'with[]=client:id,name',
    'with[]=contact:id,first,middle,last',
    'with[]=partnerProgram:id,name',
    'with[]=projectMaintenanceType:id,name',
    'with[]=owner:id,name,initials',
    'with[]=projectManager:project_id,project_manager_id,name',
    'with[]=activatedBy:id,name',
    'with[]=activatingOrder',
    'with[]=activatingOrder.proposalProjectFile',
    'with[]=activatingOrder.activatingPurchaseOrderProjectFile',
  ].join('&'),

  /**
   * Start with the right default sort!
   */
  sortedColumns: [
    {
      name: 'activating_order_date',
      direction: SORT_DIRECTION.DESC,
      sortIndex: 0,
    },
  ],

  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      // IMPORTANT: make sure these fields are configured with their "visible" and "order" property to reflect this default preset
      fields: [
        'project_number',
        'client',
        'name',
        'activating_order_no',
        'activating_order_date',
        'order_value',
        'value',
        'activated_by',
        'state',
        'owner',
        'contact',
        'status',
        'phase',
        'billing_address',
        'project_manager',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'project_number',
        'name',
        'activating_order_no',
        'activating_order_date',
        'activated_by',
        'order_value',
        'value',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'project_number',
        'owner',
        'state',
        'client',
        'contact',
        'name',
        'activating_order_no',
        'activating_order_date',
        'activating_order_file',
        'activating_order_as_sold_file',
        'activated_by',
        'order_value',
        'value',
        'status',
        'project_manager',
        'phase',
        'partner_program',
        'external_design',
        'tender',
        'id',
        'quickbooks_subcustomer_id',
        'user_audit_created_at',
        'user_audit_updated_at',
        'project_maintenance_type',
        'project_start',
        'supply_only_job',
      ],
    },
    {
      name: 'basic',
      title: 'Basic Fields',
      fields: [
        'project_number',
        'name',
        'activating_order_no',
        'activated_by',
        'order_value',
        'value',
        'status',
        'phase',
        'client',
        'owner',
      ],
    },
  ],

  /**
   * Definition of Columns for this table
   * Includes settings for APIPolyForm fields
   */
  columns: [
    {
      name: 'id',
      title: 'ID',
      description: 'Project ID',
      format: COLUMN_FORMAT.ID_LINK,
      linkId: 'id',
      linkRoute: '/crm/projects',
      visible: false,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'project_number',
      title: 'P-Number',
      description: 'Project Unique Identifier',
      format: COLUMN_FORMAT.P_NUMBER,
      linkId: 'id',
      linkRoute: '/crm/projects',
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      sortColumn: 'id',
      order: 10,
      formOrder: 10,
    },
    {
      name: 'client',
      title: 'Client',
      description: 'The end user client for the project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'client',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/crm/companies',
        listSource: 'company',
      },
      formFieldType: FORM_FIELD_TYPE.COMPANY_PICKER,
      formSaveField: 'client_id',
      visible: true,
      showInForm: true,
      isCreatable: true,
      order: 20,
      formOrder: 20,
    },
    {
      name: 'name',
      title: 'Name',
      description: 'Project Working Title',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      required: true,
      order: 30,
      formOrder: 30,
    },
    {
      name: 'activating_order_no',
      title: 'CPO Number',
      description: 'Activating Project Order Number',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'activating_order',
        key: 'order_no',
        format: COLUMN_FORMAT.TEXT,
      },
      visible: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      showInForm: true,
      order: 40,
      formOrder: 40,
    },
    {
      name: 'activating_order_date',
      title: 'CPO Date',
      description: 'Activating Project Order Date',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'activating_order',
        key: 'received',
        format: COLUMN_FORMAT.DATE,
      },
      sortColumn: 'activatingOrder.received',
      filterOnField: 'activatingOrder.received',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 50,
      formOrder: 50,
    },
    {
      name: 'order_value',
      title: 'CPO Value',
      description: 'Project Total Value',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'activating_order',
        key: 'order_total',
        format: COLUMN_FORMAT.CURRENCY,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      order: 60,
      formOrder: 60,
    },
    {
      name: 'value',
      title: 'Project Value',
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
      allowNegative: true,
      format: COLUMN_FORMAT.CURRENCY,
      description: 'Project Total Value',
      visible: true,
      order: 70,
      formOrder: 70,
    },
    {
      name: 'activating_order_file',
      title: 'PO Doc',
      description: 'Activating Project Order Number',
      format: COLUMN_FORMAT.OBJECT,
      icon: ICON.FILE,
      object: {
        sourceField: 'activating_order',
        key: 'activating_purchase_order_project_file',
        description: 'The proposal document submitted to the client which they responded to with an order.',
        format: COLUMN_FORMAT.FILE,
        apiRoute: (row) => `/project/${row.project_id}/file`,
      },
      filterOnField: 'activatingOrder.activating_purchase_order_project_file_id',
      visible: false,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      showInForm: true,
      order: 80,
      formOrder: 80,
    },
    {
      name: 'activating_order_as_sold_file',
      title: 'As Sold Doc',
      description: 'Activating Project Order Number',
      format: COLUMN_FORMAT.OBJECT,
      icon: ICON.FILE,
      object: {
        sourceField: 'activating_order',
        key: 'proposal_project_file',
        description: 'The proposal document submitted to the client which they responded to with an order.',
        format: COLUMN_FORMAT.FILE,
        apiRoute: (row) => `/project/${row.project_id}/file`,
      },
      filterOnField: 'activatingOrder.proposal_project_file_id',
      visible: false,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      showInForm: true,
      order: 90,
      formOrder: 90,
    },
    {
      name: 'activated_by',
      title: 'Activated By',
      description: 'The Ci team member responsible for activating this project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'activated_by',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formSaveField: 'owner_id',
      sortColumn: 'owner.name',
      visible: true,
      showInForm: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 100,
      formOrder: 100,
    },
    {
      name: 'state',
      title: 'State',
      format: COLUMN_FORMAT.OBJECT,
      description: 'Where is the project taking place?',
      object: {
        sourceField: 'state',
        key: 'acronym',
        format: COLUMN_FORMAT.SHORT_TEXT,
        listSource: 'state',
      },
      formFieldType: FORM_FIELD_TYPE.STATE_PICKER,
      formSaveField: 'state_id',
      visible: true,
      showInForm: true,
      required: true,
      order: 110,
      formOrder: 110,
    },
    {
      name: 'owner',
      title: 'Salesperson',
      description: 'The Ci team member responsible for this project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'owner',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
      formSaveField: 'owner_id',
      sortColumn: 'owner.name',
      visible: true,
      showInForm: true,
      required: true,
      order: 120,
      formOrder: 120,
    },
    {
      name: 'contact',
      title: 'Contact',
      description: 'The client contact person',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'contact',
        key: 'id',
        getValue: (value, row) => fullName(row),
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/crm/contacts',
        listSource: 'person',
      },
      sortColumn: 'contact.first',
      formFieldType: FORM_FIELD_TYPE.CONTACT_PICKER,
      formSaveField: 'contact_id',
      isCreatable: true,
      getNewItemData: (inputValue, formData) => ({
        company_id: formData.client_id,
        company: formData.client,
      }),
      visible: true,
      showInForm: true,
      order: 130,
      formOrder: 130,
    },
    {
      title: 'Created',
      name: 'user_audit_created_at',
      sortColumn: 'created_at',
      formSaveField: 'created_at',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'created_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: false,
      showInForm: false,
      order: 140,
      formOrder: 140,
    },
    {
      title: 'Updated',
      name: 'user_audit_updated_at',
      sortColumn: 'updated_at',
      formSaveField: 'updated_at',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'updated_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      visible: false,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      showInForm: false,
      order: 150,
      formOrder: 150,
    },
    {
      name: 'status',
      title: 'Status',
      description: 'Current Status of Project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'status',
        key: 'name',
        format: COLUMN_FORMAT.STATUS_BADGE,
        listSource: 'projectStatus',
      },
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: mapSelectInputOptions(PROJECT_STATUS_NAME_MAP),
      formSaveField: 'status_id',
      visible: true,
      showInForm: true,
      required: true,
      isClearable: false,
      order: 160,
      formOrder: 160,
    },
    {
      name: 'phase',
      title: 'Phase',
      format: COLUMN_FORMAT.OBJECT,
      description: 'Current Phase of Project',
      object: {
        sourceField: 'phase',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
        listSource: 'projectPhase',
      },
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: projectPhaseOptions,
      formSaveField: 'phase_id',
      visible: true,
      order: 170,
      formOrder: 170,
    },
    {
      name: 'partner_program',
      title: 'Partner Program',
      description: '',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'partner_program',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
        listSource: 'partnerProgram',
      },
      formFieldType: FORM_FIELD_TYPE.PARTNER_PROGRAM_PICKER,
      formSaveField: 'partner_program_id',
      visible: false,
      showInForm: true,
      order: 180,
      formOrder: 180,
    },
    {
      name: 'billing_address',
      title: 'Billing Address',
      description: 'Billing Address',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      showInForm: true,
      required: false,
      order: 190,
      formOrder: 190,
    },
    {
      name: 'project_maintenance_type',
      title: 'Maintenance',
      format: COLUMN_FORMAT.OBJECT,
      description: 'The type of maintenance for this project',
      object: {
        sourceField: 'project_maintenance_type',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
        listSource: 'projectMaintenanceType',
        getValue: (value, row) => startCase(row.name),
      },
      formFieldType: FORM_FIELD_TYPE.PROJECT_MAINTENANCE_TYPE_PICKER,
      formSaveField: 'project_maintenance_type_id',
      visible: false,
      showInForm: true,
      required: false,
      order: 200,
      formOrder: 200,
    },
    {
      name: 'external_design',
      title: 'Project Design',
      format: COLUMN_FORMAT.ID_NAME_OPTIONS,
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: projectDesignTypeOptions,
      visible: false,
      showInForm: true,
      required: true,
      columnWidth: '18em',
      order: 210,
      formOrder: 210,
    },
    {
      name: 'tender',
      title: 'Tender',
      description: 'Project is part of a tender',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: false,
      showInForm: true,
      required: true,
      order: 220,
      formOrder: 220,
    },
    {
      name: 'quickbooks_subcustomer_id',
      title: 'QuickBooks Status',
      description: 'Status of the Project in Quickbooks',
      format: COLUMN_FORMAT.BUTTON_LINK,
      linkId: 'quickbooks_subcustomer_id',
      externalLink: (row) => `${process.env.REACT_APP_QUICKBOOKS_APP_ENDPOINT}app/customerdetail?nameId=${row.quickbooks_subcustomer_id}`,
      buttonLabel: 'View in QuickBooks',
      visible: false,
      showInForm: false,
      isReadOnly: true,
      order: 230,
      formOrder: 230,
    },
    {
      name: 'project_manager',
      title: 'Project Manager',
      description: 'The assigned project manager resource',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'project_manager',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'project_manager_id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      formSaveField: 'projectManager.project_manager_id',
      formFieldToolTip: 'The project manager can be assigned by adding a "Project Manager" resource to this project.',
      sortColumn: 'projectManager.name',
      visible: true,
      showInForm: true,
      order: 240,
      formOrder: 240,
    },
    {
      title: 'Expected Start Date',
      name: 'project_start',
      description: 'Commencement date, matching the Project Total forecast.',
      formFieldToolTip: "The start date can also be edited by changing the date on to the 'Project Total' forecast line.",
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: false,
      showInForm: true,
      order: 250,
      formOrder: 250,
    },
    {
      name: 'supply_only_job',
      title: 'Delivery Only Job',
      description: 'Is the job only for delivery of items or services? (ie. box drops or licenses).',
      format: COLUMN_FORMAT.CHECKBOX,
      formType: 'CheckBox',
      visible: false,
      formRequired: false,
      order: 260,
      formOrder: 260,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Project Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
      apiQuery: [
        'with[]=phase',
        'with[]=status',
        'with[]=partnerProgram:id,name',
        'with[]=state:id,acronym',
        'with[]=client:id,name',
        'with[]=contact:id,first,middle,last',
        'with[]=owner:id,name,initials',
      ].join('&'),
    },
    {
      name: 'checklist',
      title: 'Checklist',
      description: 'Project Checklist',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_CHECKLIST,
    },
    {
      name: 'drive',
      title: 'Drive',
      description: 'Project Documents and Files',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_DRIVE,
    },
    {
      name: 'forecasting',
      title: 'Forecasting',
      description: 'Project Forecasting',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_FORECASTS,
      apiRoute: '/forecast',
      apiQuery: 'with[]=type&pagelength=100',
    },
    {
      name: 'clientorders',
      title: 'Client Orders',
      description: 'Client Orders',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_CLIENT_ORDERS,
      apiRoute: '/order',
      apiQuery: [
        'pagelength=100',
        'with[]=activatingPurchaseOrderProjectFile',
        'with[]=proposalProjectFile',
      ].join('&'),
    },
    {
      name: 'resources',
      title: 'Resources',
      description: 'Project Resources',
      apiRoute: '/resource',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_RESOURCES,
      apiQuery: ['with[]=user:id,name', 'with[]=role:id,name', 'pagelength=100'].join('&'),
    },
    {
      name: 'dtools',
      title: 'Dtools Projects',
      description: 'Matching Dtools Projects',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_DTOOLS_PROJECT,
      apiRoute: '/dtoolslink',
    },
    {
      name: 'notes',
      title: 'Notes',
      description: 'Project Notes',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_NOTES,
      apiRoute: '/note',
      apiQuery: 'with[]=createdBy:id,name&pagelength=100',
    },
    {
      name: 'contacts',
      title: 'Contacts',
      description: 'Project Contacts',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_CONTACTS,
      apiRoute: '/contact',
      // gets `project_contacts`, joins on `people` as `item.person`
      // also gets `person.company`
      apiQuery: [
        'with[]=person',
        'with[]=type',
        'with[]=person.company:id,name',
        'pagelength=100',
      ].join('&'),
    },
    {
      name: 'projectLocations',
      title: 'Locations',
      description: 'Project Locations',
      apiRoute: '/location',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_LOCATIONS,
      apiQuery: [
        'with[]=companyLocation',
        'with[]=projectManager',
        'pagelength=100',
      ].join('&'),
    },
    {
      name: 'companies',
      description: 'Companies Associated with this Project',
      title: 'Companies',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_COMPANIES,
      apiRoute: '/company',
      apiQuery: ['with[]=company:id,name', 'with[]=type:id,name', 'pagelength=100'].join('&'),
    },
    {
      ...eventsTabDefault,
      apiQuery: [
        'with[]=createdBy:id,name',
        'with[]=projectForecastTracking:id,data,story',
        'with[]=projectInvoiceTracking:id,data,story',
        'with[]=projectCompanyTracking:id,data,story',
        'with[]=projectResourceTracking:id,data,story',
        'with[]=projectOrderTracking:id,data,story',
        'pagelength=1000',
      ].join('&'),
      description: 'Project Event Audit Trail',
      statusMap: PROJECT_STATUS_NAME_MAP,
      /* @todo use the below to retrieve the extra  detail only when a user clicks through for info */
      // trackableRelations: {
      //   projectForecastTracking: 'project_forecast_tracking',
      //   projectInvoiceTracking: 'project_invoice_tracking',
      //   projectCompanyTracking: 'project_company_tracking',
      //   projectResourceTracking: 'project_resource_tracking',
      //   projectOrderTracking: 'project_order_tracking',
      // },
    },
  ],

  /**
   * Action settings for action buttons and related dialogs
   * @see https://wiki.ciportal.net/books/ci-portal-developer-documentation/page/expense-claim-workflow-actions
   */
  possibleActions: [
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update',
      successMessage: 'Updated',
      failMessage: 'Failed to update',
      hideRecordActionButton: true,
    },
    {
      name: 'activate',
      title: 'Activate',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.ACTIVATE,
      description: 'Activate the project',
      successMessage: 'Project Activated',
      failMessage: 'Failed to activate the project',
      modalType: MODAL_TYPE.ACTIVATE_PROJECT,
      hideRecordActionButton: false,
    },
    {
      name: 'rollback',
      title: 'Rollback',
      color: BUTTON_COLOR.WARNING,
      icon: ICON.ROLLBACK,
      description: 'Roll back the project activation',
      successMessage: 'Project Rolled Back',
      failMessage: 'Failed to roll back the project activation',
      requireConfirmReason: true,
      hideRecordActionButton: true,
    },
  ],
  /**
   * View definitions
   */
  viewKey: 'active',
  views: [
    {
      key: 'active',
      title: 'Activated Projects',
      flagTitles: [],
      filters: [
        {
          field: 'activation_order_id',
          operation: API_FILTER_OPERATION.NOT_NULL,
          values: [],
        },
      ],
      sortedColumns: [
        {
          name: 'activating_order_date',
          direction: SORT_DIRECTION.DESC,
          sortIndex: 0,
        },
      ],
      flags: [],
    },
    {
      key: 'email-only',
      title: 'Email Only',
      flagTitles: [],
      filters: [
        {
          field: 'activatingOrder.email_only',
          operation: API_FILTER_OPERATION.EQUALS,
          values: [1],
        },
      ],
      sortedColumns: [
        {
          name: 'activating_order_date',
          direction: SORT_DIRECTION.DESC,
          sortIndex: 0,
        },
      ],
      flags: [],
    },
    {
      key: 'missing-as-sold',
      title: 'Missing As Sold',
      flagTitles: [],
      filters: [
        {
          field: 'activation_order_id',
          operation: API_FILTER_OPERATION.NOT_NULL,
          values: [],
        },
        {
          field: 'activatingOrder.proposal_project_file_id',
          operation: API_FILTER_OPERATION.IS_NULL,
          values: [],
        },
      ],
      sortedColumns: [
        {
          name: 'activating_order_date',
          direction: SORT_DIRECTION.DESC,
          sortIndex: 0,
        },
      ],
      flags: [],
    },
  ],
};

export default orderConfirmationTableInitialSettings;
