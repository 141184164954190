/* eslint-disable import/prefer-default-export */
import { localStorageSave, localStorageLoad } from './localStorage';

const LOCAL_STORAGE_BASE_CACHE_KEY = 'caches_v1';

/**
 * @description
 * Safely write a value to the caches in local storage
 *
 * @param {string} cacheKey
 * @param {number} lastValidatedAt
 * @param {value} any
 */
const writeToLocalStorageCaches = (cacheKey, lastValidatedAt, value) => {
  const allCaches = localStorageLoad(LOCAL_STORAGE_BASE_CACHE_KEY) || {};
  if (allCaches instanceof Object) {
    allCaches[cacheKey] = {
      value,
      lastValidatedAt,
    };

    localStorageSave(LOCAL_STORAGE_BASE_CACHE_KEY, allCaches);
  }
};

/**
 * @description
 * Safely read a value from the caches in local storage
 *
 * @param {string} cacheKey
 * @returns {{
 *  value: any,
 *  invalidAfter: number | undefined,
 *  found: boolean
 * }}
 */
const readFromLocalStorageCaches = (cacheKey) => {
  const allCaches = localStorageLoad(LOCAL_STORAGE_BASE_CACHE_KEY) || {};

  let value;
  let lastValidatedAt;
  let found = false;

  // set the new caches value to the value found in local storage
  if (allCaches instanceof Object && Object.prototype.hasOwnProperty.call(allCaches, cacheKey)) {
    ({ value, lastValidatedAt } = allCaches[cacheKey]);
    found = true;
  }

  return {
    value,
    lastValidatedAt,
    found,
  };
};

/**
 * @description
 * Creates a cache for a value
 * Invalidates the value after a timeout
 *
 * If localStorageCacheKey is provided, saves to local storage (& grabs from local storage on booting up)
 *
 * @param {number | null} validationWindow
 *  null caches that never invalidate
 *  milliseconds after which to invalidate the cache value (1000 = 1 second, 60000 = 1 minute)
 * @param {string | undefined} localStorageKey leave undefined to not use local storage
 */
export const createAutoInvalidatingCache = (validationWindow, localStorageCacheKey = undefined) => {
  // validate validationWindow
  if (!(validationWindow === null || (typeof validationWindow === 'number' && validationWindow > 0))) {
    throw new TypeError('invalid "validationWindow" supplied to "createAutoInvalidatingCache"');
  }

  // validate options
  if (!(localStorageCacheKey === undefined || (typeof localStorageCacheKey === 'string'))) {
    throw new TypeError('invalid "localStorageCacheKey" supplied to "createAutoInvalidatingCache"');
  }

  // never invalidates if validationWindow is null
  const neverInvalidates = validationWindow === null;
  let currentValue;
  let lastValidatedAt;

  // initialise from local storage
  if (localStorageCacheKey) {
    const { value, lastValidatedAt: lastValidatedAtFromCache, found } = readFromLocalStorageCaches(localStorageCacheKey);
    if (found) {
      currentValue = value;
      lastValidatedAt = lastValidatedAtFromCache;
    }
  }

  /**
   * @description
   * Determine whether the cache has been invalidated
   *
   * @returns {boolean}
   */
  const isValid = () => neverInvalidates || (
    lastValidatedAt && validationWindow
      ? (lastValidatedAt + validationWindow) > Date.now()
      : false
  );

  /**
   * @description
   * Publicly available properties on the cache
   */
  return {
    /**
     * @description
     * Determine if the cache has expired
     *
     * @returns {boolean}
     */
    isValid,

    /**
     * @description
     * Get the current value from the cache
     *
     * @returns {any}
     */
    get: () => {
      if (isValid()) return currentValue;
      return undefined;
    },

    /**
     * @description
     * Set the cached value
     *
     * @param {any} newValue
     * @returns {any}
     */
    set: (newValue) => {
      currentValue = newValue;
      lastValidatedAt = Date.now();
      if (localStorageCacheKey) writeToLocalStorageCaches(localStorageCacheKey, lastValidatedAt, currentValue);
      return currentValue;
    },
  };
};
