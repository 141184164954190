import React, { createRef, useEffect, useRef } from 'react';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';
import { usePreviousValue } from '../../react-hooks/use-previous-value.hook';

import { IFileRecord } from '../../types/file.record.interface';

export type ImageViewerProps = {
  images: Partial<IFileRecord>[],
  viewingId?: null | IFileRecord['id'],
  visible?: boolean,
  onClose?: () => void,
}


/**
 * Image Viewer / lightbox
 * Note: since the portal container is beneath the menu / navbar, it's better to use the default
 *
 * @TODO at some point implement a better "react" lightbox
 */
export const ImageViewer: React.FC<ImageViewerProps> = (props) => {
  const {
    images,
    viewingId = null,
    visible = false,
    onClose,
  } = props;

  const imageViewerElement = createRef<HTMLUListElement>();
  const viewer = useRef<undefined | Viewer>();
  const currentImageIndex = useRef<number>(0);
  const oldViewingId = usePreviousValue(viewingId);

  useEffect(() => {
    if (visible && imageViewerElement.current) {
      // Create viewerjs instance if required
      if (!viewer.current) {
        viewer.current = new Viewer(imageViewerElement.current, {
          inline: false,
          hide: () => { if (onClose) { onClose(); } },
          initialViewIndex: currentImageIndex.current,
          tooltip: false,
        });
      } else {
        viewer.current.update();
      }

      // Show lightbox
      viewer.current.show();
    }

    return () => {};
  }, [imageViewerElement, onClose, visible]);


  /**
   * Component Will Unmount
   */
  useEffect(() => () => {
    // Destroy the lightbox when visible is changed or when the component is unmounted
    viewer.current?.destroy();
  }, []);


  /**
   * When the viewing Id changes
   */
  useEffect(() => {
    if (oldViewingId !== viewingId) {
      const imageIndex = images.findIndex((image) => image.id === viewingId);
      currentImageIndex.current = imageIndex > -1 ? imageIndex : 0;

      if (viewer.current) {
        viewer.current.view(currentImageIndex.current);
      }
    }
  }, [viewingId, images, oldViewingId]);


  // Render
  return (
    <ul
      key="images"
      id="images"
      className="image-viewer"
      ref={imageViewerElement}
      style={{ display: 'none' }}
    >
      {images.map((image) => (image.downloadUrl ? (
        <li key={image.id}>
          <img src={image.downloadUrl} alt={image.comment ?? image.filename} />
        </li>
      ) : null))}
    </ul>
  );
};
