import React, { createRef } from 'react';
import Async from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import classNames from 'classnames';
import { noop, identity } from '../../utils/helpers';
import { TIMEOUT_TYPING_DEBOUNCE } from '../../utils/constants';
import Icon from '../layout-helpers/icon';
import { connectToAPIProvider } from '../providers/api-provider';
import API_PROVIDER_PROP_TYPES from '../../prop-types/api-provider-prop-types';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { prefixString } from '../../helpers/prefix-string.helper';

class AsyncSelect extends React.Component {
  /**
   * @constructor
   */
  constructor(props) {
    super(props);

    const {
      formData, name, isCreatable,
    } = props;

    // @note during an investigation into the AsyncSelect it was discovered that
    // the `name` property of the field is being used to assume the source data
    // is an object. This allows the rendered to use the currentValue.displayValue
    // system for showing the selected value prior to available options being loaded.
    // Ideally, we want to pass the current display value down here so that it can
    // show the selected display value prior to loading available options.
    const selectedOption = formData[name];

    this.state = {
      selectedOption,
      inputValue: null,
      inputChanged: false,
      inputFocused: false,
      // eslint-disable-next-line react/no-unused-state
      isSearching: false,
      key: 1,
    };

    this.abortController = null; // apiAborter();
    this.debounceTimer = null;
    this.AsyncComponent = isCreatable ? AsyncCreatable : Async;
    this.selectRef = createRef();
  }

  /**
   * @inheritdoc
   */
  componentDidUpdate(newProps) {
    const {
      key,
    } = this.state;
    const { appendQuery } = this.props;
    if (newProps.appendQuery !== appendQuery) {
      setTimeout(() => {
        this.setState({ key: 1 + key });
      });
    }
  }

  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    if (this.abortController) this.abortController.abort();
    // clearTimeout(this.state.timer);
    clearTimeout(this.debounceTimer);
  }


  /**
   * @inheritdoc
   */
  static getDerivedStateFromProps(props, state) {
    const { formData, name } = props;
    const { selectedOption: stateSelectedOption, inputChanged } = state;

    const propsSelectedOption = formData[name];

    // When the user isn't editing the input, allow the selected option to be pulled from props
    if (!inputChanged && (propsSelectedOption !== stateSelectedOption)) {
      return {
        selectedOption: propsSelectedOption,
      };
    }

    return null;
  }


  /**
   * @description
   * Who knows..... maybe the field to search for...?
   *
   * @returns {string}
   */
  get saveField() {
    const { formSaveField, name } = this.props;

    // if formSaveField is not set, make it {name}_id
    const saveField = formSaveField || `${name}_id`;

    return saveField;
  }


  /**
   * Get options from API
   * @param {string} searchTerm string for filtering options
   * @returns {Promise} new options
   */
  getData = async (searchTerm) => new Promise((resolve) => {
    const {
      appendQuery,
      useSearch,
      searchRoute,
      dataMap,
      loadAndKeepAll,
      blankOption,
      formData,
      parentData,
    } = this.props;

    // Bail if we're trying so search for bad data
    if (
      searchTerm === undefined ||
        searchTerm === 'undefined' ||
        ((searchTerm === '' || searchTerm === null) && !loadAndKeepAll)
    ) {
      // Optionally add a (blank) option to the end
      if (blankOption) {
        resolve({ options: [blankOption] });
      }
      else {
        resolve({ options: [] });
      }
    }

    else {
      const stringSearchRoute = (typeof searchRoute === 'function') ? searchRoute(formData, parentData) : searchRoute;

      // append with an ampersand if the question mark is already in the string
      let queryStringChar = stringSearchRoute.indexOf('?') > -1 ? '&' : '?';

      // Use search, otherwise use string for filtering options
      let fullSearchRoute = stringSearchRoute;

      if (searchTerm && searchTerm.length > 0) {
        if (useSearch) {
          fullSearchRoute = `${fullSearchRoute}${queryStringChar}search=${searchTerm}`;
          queryStringChar = '&';
        } else {
          fullSearchRoute = `${fullSearchRoute}${searchTerm}`;
        }
      }

      if (appendQuery && appendQuery.length > 0) {
        fullSearchRoute = `${fullSearchRoute}${prefixString(appendQuery, queryStringChar)}`;
      }

      // Kill any existing searches and create a new aborter
      if (this.abortController) this.abortController.abort();
      this.abortController = apiAborter();

      this.setState({
        // eslint-disable-next-line react/no-unused-state
        isSearching: true,
      }, async () => {
        const { apiProvider: { apiFetch } } = this.props;
        // Get results
        const response = await apiFetch(
          fullSearchRoute,
          {
            name: 'AsyncSelect::getData',
            signal: this.abortController.signal,
          },
        );

        if (response.success) {
          this.abortController = null;
          const returnObj = { options: response.body.data };
          if (typeof dataMap === 'function') {
            returnObj.options = returnObj.options.map(dataMap);
          }

          // Optionally add a (blank) option to the end
          if (blankOption) {
            returnObj.options = [...returnObj.options, blankOption];
          }

          this.setState({
            // eslint-disable-next-line react/no-unused-state
            isSearching: false,
          }, () => resolve(returnObj));
        } else if (!response.aborted) {
          this.abortController = null;
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            isSearching: false,
          }, () => resolve());
        }
      });
    }
  });


  /**
   * @param {string} search Value typed in
   * @param {Function} callback
   * @returns {Promise} promise with new options
   */
  debounceAndGetData = (search, callback) => {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = null;
    }

    // don't update on each character change, wait TIMEOUT_TYPING_DEBOUNCE
    this.debounceTimer = setTimeout(() => {
      this.getData(search).then((data) => {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = null;
        if (typeof callback === 'function') {
          if (data && ('options' in data)) {
            callback(data.options);
          }
          else {
            callback(null);
          }
        }
      });
    }, TIMEOUT_TYPING_DEBOUNCE);
  };


  /**
   * @description
   * Get the value of an option either by using the provided valueKey or by calling
   * the provided getOptionValue callback
   *
   * @param {{}} option
   * @returns {string | number | null}
   */
  getOptionValue = (option) => {
    const { getOptionValue, valueKey } = this.props;

    if (typeof getOptionValue === 'function') return getOptionValue(option);

    return option ? option[valueKey] : null;
  }


  /**
   * @description
   * Get the display label of an option either by using the provided labelKey or by calling
   * the provided getOptionLabel callback
   *
   * @param {{}} option
   * @returns {string}
   */
  getOptionLabel = (option) => {
    const { getOptionLabel, labelKey } = this.props;

    if (typeof getOptionLabel === 'function') return getOptionLabel(option);

    return ((option && option[labelKey]) || '');
  }


  /**
   * @description
   * Optionally highlight the display value
   *
   * @param {object} option the resource selected as an option
   * @returns {JSX} <Highlighter />
   */
  highlightOption = (option) => {
    const { highlight } = this.props;
    const { inputValue, inputFocused } = this.state;

    // Return the label when the AsyncSelect is rendering a "Create new XXXX" option
    if (option.__isNew__) {
      return (
        <>
          <Icon i="plus-circle" />
          <span>{option.label}</span>
        </>
      );
    }

    const optionText = this.getOptionLabel(option);

    if (!highlight || !inputFocused || !optionText) return optionText;

    // Array of words for the highlighter
    let searchWords = [];
    if (typeof inputValue === 'string') {
      // remove values that could break the Highlighters regex
      searchWords = inputValue.replace(/[|&;$%@"<>()+,]/g, '').split(' ');
    }

    return (
      <Highlighter
        highlightTag="span"
        highlightClassName="highlight-text"
        searchWords={searchWords}
        textToHighlight={optionText}
      />
    );
  };


  /**
   * @description
   * Fired when the select is changed
   *
   * @param {object} selectedOption
   * @returns {void}
   */
  handleSelectChange = (selectedOption) => {
    const { name, onChange } = this.props;
    const { saveField } = this;

    const saveValue = this.getOptionValue(selectedOption);

    this.setState({
      selectedOption,
      inputChanged: false,
      inputValue: null,
    }, () => onChange({ fieldName: saveField, newValue: saveValue, objectFieldName: name, objectFieldNewValue: selectedOption }));
  }


  /**
   * @description
   * Fired by the react select component whenever the input changes.
   * Typically we only care here about the user input (i.e. typing)
   * Action types "menu-close" and "input-blur" etc... should be ignored
   */
  handleInputChange = (newInputValue, details) => {
    const { action } = details;
    if (action === 'input-change') {
      const { inputChanged, selectedOption } = this.state;
      const newInputChanged = inputChanged || (newInputValue !== this.getOptionLabel(selectedOption));

      this.setState({
        inputValue: newInputChanged ? newInputValue : null,
        inputChanged: newInputChanged,
      });
    }
  }


  // Render <AsyncSelect />
  render = () => {
    const {
      saveField,
    } = this;

    const {
      isMulti,
      disabled,
      id,
      onCreateOption,
      className,
      renderOption,
      hasError,
      isCreatable,
      isClearable,
      tabSelectsValue,
    } = this.props;

    const {
      selectedOption,
      inputChanged,
      inputValue,
      inputFocused,
      key,
    } = this.state;

    let { placeholder } = this.props;
    placeholder = placeholder || (isCreatable ? 'Type to search or add...' : 'Type to search...');

    // @note: For some reason attempting to render <this.AsyncComponent> throws an exception when minified (i.e. in production)
    const { AsyncComponent } = this;
    return (
      <AsyncComponent
        key={key}
        className={classNames(
          'Select',
          'async-select',
          'form-control',
          className,
          {
            'form-control-danger': hasError,
            focused: inputFocused,
          },
        )}
        blurInputOnSelect
        classNamePrefix="Select"
        isMulti={isMulti}
        id={id}
        ref={this.selectRef}
        getOptionValue={this.getOptionValue}
        getOptionLabel={this.highlightOption}
        formatOptionLabel={renderOption ? (option, details) => renderOption(option, details, inputValue) : undefined}
        onInputChange={this.handleInputChange}
        onFocus={() => {
          // TODO: find a way to select the content
          this.setState({ inputFocused: true });
        }}
        onBlur={() => this.setState({
          inputFocused: false,
          inputChanged: false,
        })}
        onChange={this.handleSelectChange}
        loadOptions={(searchTerm) => new Promise((resolve) => {
          this.debounceAndGetData(((inputFocused && searchTerm) || ''), resolve);
        })}
        isDisabled={disabled}
        value={selectedOption}
        name={saveField}
        placeholder={placeholder}
        filterOption={identity}
        onCreateOption={onCreateOption}
        backspaceRemovesValue
        isClearable={isClearable}
        tabSelectsValue={tabSelectsValue}
        // defaultOptions: if true, the results for loadOptions('') will be auto-loaded.
        defaultOptions
        // See https://react-select.com/props#async-props
        // Caches loaded data
        cacheOptions
        // If the user hasn't started typing an input value, display the current value
        inputValue={(inputFocused && inputChanged ? inputValue : (inputFocused && this.getOptionLabel(selectedOption)) || undefined)}
        menuPortalTarget={document.getElementById('portal_container')}
        menuPlacement="auto"
        menuShouldScrollIntoView={false}
        isValidNewOption={(inputVal, selectValue, selectOptions /* , accessors */) => (
          inputVal &&
          (inputVal.trim() !== '') &&
          (selectOptions.findIndex((option) => this.getOptionLabel(option) === inputVal) < 0)
        )}
      />
    );
  };
}

AsyncSelect.propTypes = {
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  searchRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  appendQuery: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  useSearch: PropTypes.bool,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  renderOption: PropTypes.func,
  dataMap: PropTypes.func,
  formData: PropTypes.shape({}),
  parentData: PropTypes.shape({}),
  formSaveField: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  highlight: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isClearable: PropTypes.bool,
  tabSelectsValue: PropTypes.bool,
  onCreateOption: PropTypes.func,
  loadAndKeepAll: PropTypes.bool,
  blankOption: PropTypes.objectOf(PropTypes.string),
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
};

AsyncSelect.defaultProps = {
  isMulti: false,
  className: '',
  onChange: noop,
  formData: {},
  parentData: {},
  disabled: false,
  hasError: false,
  valueKey: 'id',
  labelKey: 'name',
  useSearch: true,
  appendQuery: null,
  getOptionValue: null,
  getOptionLabel: null,
  renderOption: null,
  dataMap: undefined,
  highlight: true,
  formSaveField: null,
  isCreatable: false,
  isClearable: true,
  tabSelectsValue: false,
  onCreateOption: noop,
  loadAndKeepAll: false,
  placeholder: null,
  blankOption: undefined,
};

export default connectToAPIProvider(AsyncSelect);
