import React, { useCallback } from 'react';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { NewRecordFormProps } from '../../types/poly-form/new-record-form.props';
import { ICompanySpaceTypeRecord } from '../../types/company/company-space-type.record.interface';

import { APIPolyForm } from './api-poly-form';

import companySpaceTypesTableInitialSettings from '../../table-definitions/company-space-types-table';
import { API_ACTION } from '../../constants/api-action.const';
import { mapTableToForm } from '../../helpers/map-table-to-form.helper';

const formFields = mapTableToForm(companySpaceTypesTableInitialSettings);

export const NewCompanySpaceTypeForm: React.FC<NewRecordFormProps<ICompanySpaceTypeRecord>> = (props) => {
  const {
    onFormComplete,
    onFormChange,
    initialData,
  } = props;

  /**
   * Fired when the APIPolyForm successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, id: FormFieldComponentProps['value'], newData?: Partial<ICompanySpaceTypeRecord>) => {
    if (onFormComplete) onFormComplete(success, id as ICompanySpaceTypeRecord['id'], newData as ICompanySpaceTypeRecord);
  }, [onFormComplete]);

  // Render
  return (
    <APIPolyForm<ICompanySpaceTypeRecord>
      {...companySpaceTypesTableInitialSettings}
      fields={formFields}
      formData={{
        ...(initialData ?? {}),
      }}
      permittedActions={companySpaceTypesTableInitialSettings.possibleActions.filter((action) => (action.name === API_ACTION.CREATE))}
      onFormComplete={handleFormComplete}
      onFormChange={onFormChange}
      isNewRecord
      isEditing
    />
  );
};
