import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Badge,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { changeColumnOrder } from '../../actions/portal-data-table/change-column-order';
import { loadLocalPrefs, saveLocalPrefs } from '../../utils/localStorage';
import Icon from '../layout-helpers/icon';
import { APP_ENV } from '../../utils/constants';
import { connectToCurrentUserProvider } from '../providers/current-user-provider';
import { CURRENT_USER_PROVIDER_PROP_TYPES } from '../../prop-types/current-user-provider-prop-types';

/** @description ID for the "Visible Columns" drop zone for draggable items */
const VISIBLE_DROP_ZONE_ID = 'visible-drop-zone-id';

/** @description ID for the "Hidden Columns" drop zone for draggable items */
const HIDDEN_DROP_ZONE_ID = 'hidden-drop-zone-id';

/** @description Map between drop zones and their corresponding column key in the components state */
const DROP_ZONE_STATE_COLUMN_KEY_MAP = {
  [VISIBLE_DROP_ZONE_ID]: 'visibleColumns',
  [HIDDEN_DROP_ZONE_ID]: 'hiddenColumns',
};

/** @description padding / margin around list items */
const ITEM_PADDING_PX = 4;


/**
 * @description
 * Get a draggable items style
 *
 * @param {boolean} isDragging
 * @param {unknown} draggableStyle
 */
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the visible look a bit nicer
  userSelect: 'none',
  padding: ITEM_PADDING_PX * 2,
  borderRadius: '2px',
  margin: `0 0 ${ITEM_PADDING_PX}px 0`,
  color: 'white',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  // change background colour if dragging
  // background: isDragging ? 'var(--info)' : 'var(--primary)',

  // styles we need to apply on draggable s
  ...draggableStyle,
});


/**
 * @description
 * Get a lists style
 *
 * @param {boolean} isDraggingOver
 */
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '#EFEFEF',
  height: '450px',
  overflowX: 'auto',
  padding: ITEM_PADDING_PX,
  width: 250,
});


/**
 * @description
 * Move an array element from a "startIndex" to an "endIndex"
 *
 * @param {any[]} list
 * @param {number} startIndex
 * @param {number} endIndex
 * @returns {any[]}
 */
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};


class ColumnManager extends Component {
  constructor(props) {
    super(props);

    const { tableIdentifier } = props;

    // give each column an id for sorting purposes
    let i = -1;
    const sortedColumns = props
      .settings
      .columns
      .map((column) => {
        i += 1;
        return { ...column, id: i };
      })
      .sort((a, b) => a.order - b.order);

    // Load local preset columns
    const localPrefs = loadLocalPrefs();
    const localPresets = localPrefs ? localPrefs.tablePresets[tableIdentifier] || [] : [];

    this.state = {
      // split the cols
      visibleColumns: sortedColumns.filter((column) => this.isColumnVisible(column)),
      hiddenColumns: sortedColumns.filter((column) => !this.isColumnVisible(column)),
      presets: props.settings.columnPresets || [],
      presetName: '',
      localPresets,
    };
  }


  /**
   * @param {object} result
   */
  onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) return;

    let newState = {};

    if (source.droppableId === destination.droppableId) {
      const items = reorder(this.state[DROP_ZONE_STATE_COLUMN_KEY_MAP[source.droppableId]], source.index, destination.index);

      if (source.droppableId === HIDDEN_DROP_ZONE_ID) { newState = { hiddenColumns: items }; }
      else { newState = { visibleColumns: items }; }
    }
    else {
      const moveResult = this.move(
        this.state[DROP_ZONE_STATE_COLUMN_KEY_MAP[source.droppableId]],
        this.state[DROP_ZONE_STATE_COLUMN_KEY_MAP[destination.droppableId]],
        source,
        destination,
      );
      newState = {
        visibleColumns: moveResult[VISIBLE_DROP_ZONE_ID],
        hiddenColumns: moveResult[HIDDEN_DROP_ZONE_ID],
      };
    }
    this.setState(newState, this.updateTable);
  };


  /**
   * @description
   * Determine whether a column should be visible
   *
   * @param {object} column column data
   *
   * @returns {boolean}
   */
  isColumnVisible = (column) => {
    const { currentUserProvider: { userHasPermissions } } = this.props;

    // don't have requisite permissions? -> not visible
    const hasPermission = ('permissions' in column) ? userHasPermissions(column.permissions) : true;

    return column.visible === hasPermission;
  };


  /**
   * @description
   * Moves an item from one droppable zone to another
   *
   * @param {any[]} source
   * @param {any[]} destination
   * @param {{index: VISIBLE_DROP_ZONE_ID | HIDDEN_DROP_ZONE_ID}} droppableSource
   * @param {{index: VISIBLE_DROP_ZONE_ID | HIDDEN_DROP_ZONE_ID}} droppableDestination
   *
   * @returns {{[VISIBLE_DROP_ZONE_ID]: any[], [HIDDEN_DROP_ZONE_ID]: any[], }}
   */
  move = (source, destination, droppableSource, droppableDestination) => {
    const newSource = Array.from(source);
    const newDestination = Array.from(destination);

    // remove the moving index from the new source
    const [removed] = newSource.splice(droppableSource.index, 1);
    // add the moving index to the new destination
    newDestination.splice(droppableDestination.index, 0, removed);

    const result = {
      [droppableSource.droppableId]: newSource,
      [droppableDestination.droppableId]: newDestination,
    };

    return result;
  };


  /**
   * @description
   * Fired when a preset is clicked
   *
   * implements a preset as the current view
   *
   * @param {{ name: string, builtIn: boolean }} obj
   */
  handlePresetClicked = ({ name, builtIn }) => {
    // find the preset
    const selectedPreset = builtIn
      ? (this.props.settings.columnPresets.find((preset) => preset.name === name) || {})
      : (this.state.localPresets.find((preset) => preset.name === name) || {});

    const selectedPresetsVisibleFieldNames = selectedPreset.fields || [];
    const allColumns = [...this.state.visibleColumns, ...this.state.hiddenColumns];

    // hide columns that aren't in the selected preset
    const hiddenColumns = allColumns.filter((column) => !selectedPresetsVisibleFieldNames.includes(column.name));

    // show columns that are in the selected preset, and add "visible" and "order"...
    // filter (and log) columns that exist in the preset but not in the columns defined in the table definition
    const visibleColumns = selectedPresetsVisibleFieldNames
      .map((presetColumnName, index) => {
        let matchingColumn = allColumns.find((column) => column.name === presetColumnName);
        // keep the original column order from the preset
        if (matchingColumn !== undefined) matchingColumn = { ...matchingColumn, visible: true, order: index };
        else if (APP_ENV === 'dev') console.warn(`Warning: unable to find column from from preset: "${presetColumnName}" in the tables column definitions`);
        return matchingColumn;
      })
      .filter((columnFromTableDefinition) => columnFromTableDefinition !== undefined);

    this.setState({
      visibleColumns,
      hiddenColumns,
    }, this.updateTable);
  };


  /**
   * @description
   * Apply new table columns through redux
   */
  updateTable = () => {
    const { visibleColumns, hiddenColumns } = this.state;
    const { dispatchChangeColumnOrder, currentUserProvider: { userHasPermissions } } = this.props;
    const newOrderMap = {};
    const newVisibleColumns = [];
    let nextSort = 0;

    visibleColumns.forEach((column) => {
      newOrderMap[column.name] = nextSort;
      nextSort += 1;
      newVisibleColumns.push(column.name);
    });

    hiddenColumns.forEach((column) => {
      newOrderMap[column.name] = nextSort;
      nextSort += 1;
    });

    dispatchChangeColumnOrder(userHasPermissions, newOrderMap, newVisibleColumns);
  };


  /**
   * @description
   * Delete a preset from local-storage and refreshes state
   *
   * @param {string} nameOfPresetToDelete
   */
  deletePreset = (nameOfPresetToDelete) => {
    const { localPresets } = this.state;
    const { tableIdentifier } = this.props;
    const localPrefs = loadLocalPrefs();

    const newPresets = localPresets.filter((preset) => preset.name !== nameOfPresetToDelete);

    // Save to localStorage
    saveLocalPrefs({
      ...localPrefs,
      tablePresets: {
        ...localPrefs.tablePresets,
        [tableIdentifier]: newPresets,
      },
    });

    const localPrefsAfterSave = loadLocalPrefs();
    this.setState({
      isSavingPreset: false,
      presetName: '',
      localPresets: localPrefsAfterSave.tablePresets[tableIdentifier],
    });
  };


  /**
   * @description
   * On input for naming a preset change
   *
   * @param {React.SyntheticEvent} e
   */
  presetNameChange = (e) => {
    this.setState({ presetName: e.target.value });
  };


  /**
   * @description
   * Opens the dialog to save a preset
   */
  toggleIsSavingPreset = () => {
    const { isSavingPreset } = this.state;
    this.setState({ isSavingPreset: !isSavingPreset });
  };


  /**
   * @description
   * Save the preset to the local store and close dialog
   *
   * @param {React.SyntheticEvent} e
   */
  savePreset = (e) => {
    e.preventDefault();
    const { presetName, visibleColumns } = this.state;
    const { tableIdentifier } = this.props;
    const newPresetName = presetName.trim();

    if (!newPresetName) return;

    const localPrefs = loadLocalPrefs();

    // get fields and their order
    const newVisibleColumnFields = visibleColumns.map((column) => column.name);

    const newPresets = [
      // old presets
      ...(localPrefs.tablePresets[tableIdentifier] || []).filter((preset) => preset.name !== newPresetName),
      // new preset
      { name: newPresetName, title: newPresetName, fields: newVisibleColumnFields },
    ];

    // Save to localStorage
    saveLocalPrefs({
      ...localPrefs,
      tablePresets: {
        ...localPrefs.tablePresets,
        [tableIdentifier]: newPresets,
      },
    });

    // Update state
    const localPrefsAfterSave = loadLocalPrefs();
    this.setState({
      isSavingPreset: false,
      presetName: '',
      localPresets: localPrefsAfterSave.tablePresets[tableIdentifier],
    });
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  // Render <ColumnManager />
  render() {
    const containerStyle = {
      background: '#EFEFEF',
      borderRadius: '4px',
      padding: '20px',
      marginRight: '20px',
      marginBottom: '10px',
    };

    const {
      presets,
      visibleColumns,
      hiddenColumns,
      isSavingPreset,
      presetName,
      localPresets,
    } = this.state;

    // Return <ColumnManager />
    return (
      <Card className="ci-select-columns">
        <CardBody>
          <Row>
            <Col>
              <p className="text-bold text-dark">Drag columns to choose order and visibility</p>
            </Col>
          </Row>
          <Row>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Col style={containerStyle}>
                {/* VISIBLE DROP ZONE */}
                <h4>Visible</h4>
                <Droppable droppableId={VISIBLE_DROP_ZONE_ID}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                      {visibleColumns.map((item, index) => (
                        <Draggable
                          key={item.name}
                          draggableId={item.name}
                          isDragDisabled={item.lockedInTable}
                          // draggableId={item.id + 1}
                          index={index}
                        >
                          {(provided2, snapshot2) => (
                            <div
                              className={item.lockedInTable ? 'bg-gray text-dark' : 'bg-primary'}
                              ref={provided2.innerRef}
                              {...provided2.draggableProps}
                              {...provided2.dragHandleProps}
                              style={getItemStyle(
                                snapshot2.isDragging,
                                provided2.draggableProps.style,
                              )}
                            >
                              {item.title}
                              <i className="ti-menu pull-right" />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Col>
              <Col style={containerStyle}>
                {/* HIDDEN DROP ZONE */}
                <h4>Hidden</h4>
                <Droppable droppableId={HIDDEN_DROP_ZONE_ID}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                      {hiddenColumns.map((item, index) => (
                        <Draggable
                          key={item.name}
                          draggableId={item.name}
                          // draggableId={item.id + 1}
                          index={index}
                        >
                          {(provided2, snapshot2) => (
                            <div
                              className="bg-dark"
                              ref={provided2.innerRef}
                              {...provided2.draggableProps}
                              {...provided2.dragHandleProps}
                              style={getItemStyle(
                                snapshot2.isDragging,
                                provided2.draggableProps.style,
                              )}
                            >
                              {item.title}
                              <i className="ti-menu pull-right" />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Col>
            </DragDropContext>
            {/* SYSTEM PRESETS */}
            <Col style={containerStyle}>
              {presets.length > 0 && (
                <>
                  <h4>Presets</h4>
                  {presets.map((preset) => (
                    <Badge key={preset.name} className="mr-2 mt-2" color="info">
                      <Button
                        style={{ color: 'white' }}
                        color="link"
                        onClick={() => this.handlePresetClicked({ name: preset.name, builtIn: true })}
                      >
                        {preset.title}
                      </Button>
                    </Badge>
                  ))}
                  <p>&nbsp;</p>
                </>
              )}
              {/* CUSTOM (LOCAL) PRESETS */}
              <h4>My Presets</h4>
              {localPresets.map((preset) => (
                <Badge key={preset.name} className="mr-2 mt-2" color="info">
                  <Button
                    style={{ color: 'white' }}
                    color="link"
                    onClick={() => this.handlePresetClicked({ name: preset.name, builtIn: false })}
                  >
                    {preset.title}
                  </Button>
                  <Button onClick={() => this.deletePreset(preset.name)} color="link" size="sm">
                    <Icon i="trash" />
                  </Button>
                </Badge>
              ))}
              {/* RENDERED ON "SAVING" PRESET */}
              {isSavingPreset && (
                <form className="mt-2 mb-2" onSubmit={this.savePreset}>
                  <Badge className="p-2" color="info">
                    <InputGroup>
                      <Input
                        placeholder="Preset name..."
                        onChange={this.presetNameChange}
                        value={presetName}
                        autoFocus
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText className="p-1">
                          <Button className="p-2" color="transparent" onClick={this.savePreset}>
                            <Icon i="save" />
                          </Button>
                          <Button
                            className="p-2"
                            color="transparent"
                            onClick={this.toggleIsSavingPreset}
                          >
                            <Icon i="close" />
                          </Button>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Badge>
                </form>
              )}
              {isSavingPreset && (
                <p className="text-danger mt-2 mb-2">
                  Note: presets with the same name are overwritten.
                </p>
              )}
              {/* SAVE PRESET */}
              <div className="mt-4 mb-4">
                <Button
                  color="primary"
                  disabled={isSavingPreset}
                  onClick={this.toggleIsSavingPreset}
                >
                  Create New Preset
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  settings: state.tableSettings[ownProps.tableIdentifier],
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchChangeColumnOrder: (userHasPermissions, newOrderMap, visibleColumns) => {
    dispatch(changeColumnOrder(ownProps.tableIdentifier, userHasPermissions, newOrderMap, visibleColumns));
  },
});

ColumnManager.defaultProps = {
  settings: {
    columns: [],
    tabs: [],
    columnPresets: [],
  },
};

ColumnManager.propTypes = {
  tableIdentifier: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({})),
    tabs: PropTypes.arrayOf(PropTypes.shape({})),
    columnPresets: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  dispatchChangeColumnOrder: PropTypes.func.isRequired,

  currentUserProvider: PropTypes.shape(CURRENT_USER_PROVIDER_PROP_TYPES).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(connectToCurrentUserProvider(ColumnManager));
