import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

import FourOhFour from '../error-pages/four-oh-four';
import { ContractsDataTablePage } from '../data-table-pages/contracts.data-table-page';
import { ContractTypesDataTablePage } from '../data-table-pages/contract-types.data-table-page';
import { ContractSubtypesDataTablePage } from '../data-table-pages/contract-subtypes.data-table-page';
import RecordDetailPage, { TAB_REGEX } from '../record-detail-page/record-detail-page';
import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { ContractRegionsDataTablePage } from '../data-table-pages/contract-regions.data-table-page';
import { LicencesDataTablePage } from '../data-table-pages/licences.data-table-page';
import { SupportDataTablePage } from '../data-table-pages/support.data-table-page';
import { ServiceJobDataTablePage } from '../data-table-pages/service-jobs.data-table-page';

// Defer loading the leave calendar to allow webpack to chunk the JS
const MaintenanceScheduleCalendar = lazy(() => import('../maintenance-schedule-calendar/maintenance-schedule-calendar'));

const ServiceRoutes = () => (
  <Switch>
    <Route
      exact
      path="/service-and-support/service-jobs"
      render={(props) => <ServiceJobDataTablePage {...props} title="Contracts" />}
    />
    <Route
      exact
      path={`/service-and-support/service-jobs/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} title="Service Jobs" tableIdentifier={TABLE_IDENTIFIER.SERVICE_JOBS_TABLE} />}
    />
    <Route
      exact
      path="/service-and-support/contracts"
      render={(props) => <ContractsDataTablePage {...props} title="Contracts" />}
    />
    <Route
      exact
      path={`/service-and-support/contracts/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} title="Contracts" tableIdentifier={TABLE_IDENTIFIER.CONTRACTS_TABLE} />}
    />
    <Route
      exact
      path="/service-and-support/licences-and-subscriptions"
      render={(props) => <LicencesDataTablePage {...props} title="Licences and Subscriptions" contractType="licences-and-subscriptions" />}
    />
    <Route
      exact
      path={`/service-and-support/licences-and-subscriptions/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} title="Licences and Subscriptions" contractType="licences-and-subscriptions" tableIdentifier={TABLE_IDENTIFIER.LICENCES_TABLE} />}
    />
    <Route
      exact
      path="/service-and-support/support-contracts"
      render={(props) => <SupportDataTablePage {...props} title="Support Contracts" contractType="support-contracts" />}
    />
    <Route
      exact
      path={`/service-and-support/support-contracts/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} title="Support Contracts" contractType="support-contracts" tableIdentifier={TABLE_IDENTIFIER.SUPPORT_TABLE} />}
    />

    <Route
      exact
      path="/service-and-support/maintenance-checks-calendar"
      render={(props) => (
        <Suspense fallback={(
          <div className="p-5 text-center">
            <Spinner />
          </div>
        )}
        >
          <MaintenanceScheduleCalendar title="Maintenance Schedule Calendar" {...props} />
        </Suspense>
      )}
    />

    {/**
     *    SERVICE ADMINISTRATION
     */}

    {/* Contract Types */}
    <Route
      exact
      path="/service-and-support/admin/contract-types"
      render={(props) => <ContractTypesDataTablePage {...props} title="Contract Types" />}
    />
    <Route
      exact
      path={`/service-and-support/admin/contract-types/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} title="Sprints" tableIdentifier={TABLE_IDENTIFIER.CONTRACT_TYPES_TABLE} />}
    />

    {/* Contract Subtypes */}
    <Route
      exact
      path="/service-and-support/admin/contract-subtypes"
      render={(props) => <ContractSubtypesDataTablePage {...props} title="Contract Subtypes" />}
    />
    <Route
      exact
      path={`/service-and-support/admin/contract-subtypes/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} title="Contract Subtypes" tableIdentifier={TABLE_IDENTIFIER.CONTRACT_SUBTYPES_TABLE} />}
    />

    {/* Contract Regions */}
    <Route
      exact
      path="/service-and-support/admin/contract-regions"
      render={(props) => <ContractRegionsDataTablePage {...props} title="Contract Regions" />}
    />
    <Route
      exact
      path={`/service-and-support/admin/contract-regions/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} title="Sprints" tableIdentifier={TABLE_IDENTIFIER.CONTRACT_REGIONS_TABLE} />}
    />

    <Route exact path="/service-and-support/*" component={FourOhFour} />
  </Switch>
);

ServiceRoutes.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default ServiceRoutes;
