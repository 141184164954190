import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import RolesView from '../roles-view/roles-view';
import { UserManagementDataTablePage } from '../data-table-pages/user-management.data-table-page';
import AppIntegrationManager from '../system-admin';
import RoleManagementView from '../view-pages/role-management-view';
import GroupManagementView from '../view-pages/group-management-view';
import FiveHundred from '../error-pages/five-hundred';
import FourOhFour from '../error-pages/four-oh-four';
import RecordDetailPage, { TAB_REGEX } from '../record-detail-page/record-detail-page';
import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import TestChartPlayground from '../report-views/test-chart-playground';
import { SecurityRolesDataTablePage } from '../data-table-pages/secuirty-roles.data-table-page';
import { SecurityPermissionsDataTablePage } from '../data-table-pages/security-permissions.data-table-page';
import { CompanySpaceTypeDataTablePage } from '../data-table-pages/company-space-types.data-table-page';
import { StatesDataTablePage } from '../data-table-pages/states.data-table-page';

const SettingsRoutes = () => (
  <Switch>
    <Route
      exact
      path="/settings/user-management"
      render={(props) => <UserManagementDataTablePage {...props} title="Manage Users" />}
    />
    <Route
      exact
      path={`/settings/user-management/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} title="User" tableIdentifier={TABLE_IDENTIFIER.USER_MANAGEMENT_TABLE} />}
    />
    <Route
      exact
      path="/settings/states"
      render={(props) => <StatesDataTablePage {...props} title="States" />}
    />
    <Route
      exact
      path="/settings/security-roles"
      render={(props) => <SecurityRolesDataTablePage {...props} />}
    />
    <Route
      exact
      path={`/settings/security-roles/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} tableIdentifier={TABLE_IDENTIFIER.SECURITY_ROLES_TABLE} />}
    />
    <Route
      exact
      path="/settings/security-permissions"
      render={(props) => <SecurityPermissionsDataTablePage {...props} />}
    />
    <Route
      exact
      path={`/settings/security-permissions/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} tableIdentifier={TABLE_IDENTIFIER.SECURITY_PERMISSIONS_TABLE} />}
    />
    <Route
      exact
      path="/settings/company-space-types"
      render={(props) => <CompanySpaceTypeDataTablePage {...props} />}
    />
    <Route
      exact
      path={`/settings/company-space-types/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} tableIdentifier={TABLE_IDENTIFIER.COMPANY_SPACE_TYPES_TABLE} />}
    />
    <Route
      exact
      path="/settings/role-management"
      render={(props) => <RoleManagementView {...props} title="User Role Management" />}
    />
    <Route
      exact
      path="/settings/group-management"
      render={(props) => <GroupManagementView {...props} title="User Group Management" />}
    />
    {/* Dev only */}
    <Route
      exact
      path="/settings/role-matrix"
      render={(props) => <RolesView {...props} title="Role Matrix" />}
    />
    <Route
      exact
      path="/settings/integrations"
      render={(props) => <AppIntegrationManager {...props} title="Manage Integrations" />}
    />
    <Route
      exact
      path="/settings/test-chart-playground"
      render={(props) => <TestChartPlayground {...props} title="Test Chart Playground" />}
    />
    <Route exact path="/settings/aaargh!" component={FiveHundred} />
    <Route path="/settings/huh?" exact component={FourOhFour} />
    <Route exact path="/settings/*" component={FourOhFour} />
  </Switch>
);

SettingsRoutes.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default SettingsRoutes;
