import React from 'react';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';

import AsyncSelect from './async-select';

export type ContractSubtypeStatusPickerProps = AsyncSelectComponentProps;

export const ContractSubtypeStatusPicker: React.FC<ContractSubtypeStatusPickerProps> = (props) => (
  <AsyncSelect
    {...props}
    searchRoute="/contract-subtype-status"
    loadAndKeepAll
  />
);
