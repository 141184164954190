import React from 'react';

import { Card } from 'reactstrap';
import { WidgetProps } from '../../../types/widget.props';
import { IProjectRecord } from '../../../types/project/project.record.interface';

import FriendlyFormMessage from '../../layout-helpers/friendly-form-message';
import { ProjectVendorOrderTable } from './project-vendor-order-table';


export type ProjectMyobOrdersWidgetProps = Omit<WidgetProps, 'rowData'> & {
  rowData: IProjectRecord,
}


/**
 * ProjectMyobOrdersWidget component for Data Table
 */
export const ProjectMyobOrdersWidget:React.FC<ProjectMyobOrdersWidgetProps> = ({ rowData }) => (
  <div className="widget project-supplier-orders-widget">
    <Card>
      {rowData.dtools_id && (
        <ProjectVendorOrderTable projectData={rowData} dtoolsId={rowData.dtools_id} />
      )}
      {!rowData.dtools_id && (
        <FriendlyFormMessage
          formMessage="This project is not yet linked to a DTools project. Please visit the DTools Projects tab and click 'Make Master' on one of the matching DTools Projects."
        />
      )}
    </Card>
  </div>
);
