import React from 'react';
import { Card, CardBody, CardText } from 'reactstrap';
import { IInternalProjectTaskRecord } from '../../types/internal-project/internal-project-task.record.interface';

interface IGanttViewTaskRowProps {
  task: IInternalProjectTaskRecord,
}

/**
 * Gantt View Project Row
 *
 * @todo this is a stub, requires completion
 */
export const GanttViewTaskRow: React.FC<IGanttViewTaskRowProps> = ({ task }) => (
  <Card className="task-bar" key={task.id}>
    <CardBody>
      <CardText>
        {task.name}
      </CardText>
    </CardBody>
  </Card>
);
