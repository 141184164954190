import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { NavLink } from 'reactstrap';
import { FormFieldRenderer } from '../form-field-renderer';
import { FormButtons } from '../form-buttons';
import Icon from '../../layout-helpers/icon';
import FriendlyFormMessage from '../../layout-helpers/friendly-form-message';
import { FormHeader } from '../form-header';
import { FormRendererProps } from '../../../types/poly-form/form-renderer.props';
import { FORM_FIELD_TYPE } from '../../../constants/form-field-type.const';

/**
 * VerticalFormRenderer takes the fields and renders them in a standard vertical form
 */
export const VerticalFormRenderer:React.FC<FormRendererProps> = (props) => {
  const {
    children,
    className,

    parentData,
    primaryKeyFieldName,
    fields,
    formData,
    showHeader,
    scrollToError,

    isReadOnly,
    isNewRecord = false,
    isEditing,
    isLocked,
    isBusy = false,
    isDirty = false,

    hasErrors,
    errors,
    hasSuccess,
    formMessage,

    permittedActions,

    showFieldInfo,
    hideBottomButtons,
    formCaption,
    formDeleteConfirmationType,

    lockForm,
    toggleShowFieldInfo,

    startEditRecord,
    endEditRecord,
    deleteRecord,

    onFieldChange,
  } = props;

  const itemPrimaryKeyValue = isNewRecord ? null : formData[primaryKeyFieldName ?? 'id'];


  /**
   * Fired when the user double clicks on the form (or form fields)
   *
   * @param focusFieldName the field that was double clicked (optional)
   */
  const handleDoubleClick = useCallback((focusFieldName?: string) => {
    if (!isEditing && startEditRecord) {
      startEditRecord(itemPrimaryKeyValue, formData, focusFieldName);
    }
  }, [isEditing, startEditRecord, itemPrimaryKeyValue, formData]);


  /**
   * When the errors array changes - scroll to the first error in the list
   */
  useEffect(() => {
    if ((scrollToError !== false) && hasErrors && !!errors) {
      const firstErrorFieldName = Object.keys(errors)[0];
      const errorField = fields.find((field) => (field.formSaveField === firstErrorFieldName || field.name === firstErrorFieldName));
      if (errorField) {
        const errorFieldId = `${errorField.name}${isNewRecord ? '_new' : `_${itemPrimaryKeyValue}`}`;
        const errorElement = document.getElementById(errorFieldId);
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  }, [scrollToError, fields, hasErrors, errors, itemPrimaryKeyValue, isNewRecord]);

  // Render
  return (
    <div
      className={classNames(className, 'vertical-layout')}
    >
      {showHeader && (
        <FormHeader toggleShowFieldInfo={toggleShowFieldInfo} title={formCaption} formIsDirty={isDirty}>
          <FormButtons
            permittedActions={permittedActions || {}}
            formDeleteConfirmationType={formDeleteConfirmationType}

            formIsEditing={isEditing}
            formIsReadOnly={isReadOnly}
            formIsCreating={isNewRecord}
            formIsBusy={isBusy}
            formIsLocked={isLocked}

            startEditRecord={startEditRecord ? () => startEditRecord(itemPrimaryKeyValue, formData) : undefined}
            endEditRecord={endEditRecord ? (saveChanges) => endEditRecord(saveChanges, itemPrimaryKeyValue, formData) : undefined}
            deleteRecord={deleteRecord ? () => deleteRecord(itemPrimaryKeyValue, formData) : undefined}

            secondary
          />
        </FormHeader>
      )}
      <div
        className="form-horizontal"
        {
          ...(children && ({
            onDoubleClick: () => handleDoubleClick(),
          }))
        }
      >
        {children}
        {
          // if no children, the fields will be
          // generated from props.fields, see new-company-form.jsx
          fields.map((field) => {
            // showInForm is true by default, but if we have set this, don't render
            if (field.showInForm === false) return null;

            // Don't render a "NotEditable" if the form is creating a new record
            if ((field.formFieldType === FORM_FIELD_TYPE.NOT_EDITABLE) && isNewRecord) return null;

            // Render the field
            return (
              <FormFieldRenderer
                id={field.id}
                key={field.id}

                parentData={parentData}
                formData={formData}
                field={field}
                errors={errors}

                showFieldInfo={showFieldInfo}
                formIsLocked={isLocked}
                isReadOnly={!isEditing || isReadOnly || field.isReadOnly || isBusy}
                isNewRecord={isNewRecord}

                lockForm={lockForm}
                onChange={onFieldChange}

                onDoubleClick={() => handleDoubleClick(field.name)}
              />
            );
          })
        }
        <FriendlyFormMessage
          formMessage={formMessage}
          hasSuccess={hasSuccess}
          hasErrors={hasErrors}
          errors={errors}
          showList
        />
        {(hideBottomButtons !== true) && (
          <div className="no-button-group form-footer">
            <NavLink color="link" className="text-primary" onClick={toggleShowFieldInfo}>
              <Icon i="info-circle" spaceRight />
              {`${showFieldInfo ? 'Hide' : 'Show'} info`}
            </NavLink>
            <FormButtons
              permittedActions={permittedActions}
              formDeleteConfirmationType={formDeleteConfirmationType}

              formIsEditing={isEditing}
              formIsReadOnly={isReadOnly}
              formIsCreating={isNewRecord}
              formIsBusy={isBusy}
              formIsLocked={isLocked}

              startEditRecord={startEditRecord ? () => startEditRecord(itemPrimaryKeyValue, formData) : undefined}
              endEditRecord={endEditRecord ? (saveChanges) => endEditRecord(saveChanges, itemPrimaryKeyValue, formData) : undefined}
              deleteRecord={deleteRecord ? () => deleteRecord(itemPrimaryKeyValue, formData) : undefined}

              secondary={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};
