import React from 'react';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';

import AsyncSelect from './async-select';

export type ContractBillingTypePickerProps = AsyncSelectComponentProps;

export const ContractBillingTypePicker: React.FC<ContractBillingTypePickerProps> = (props) => (
  <AsyncSelect
    {...props}
    searchRoute="/contract-billing-type"
    loadAndKeepAll
  />
);
