import React from 'react';
import {
  Card, CardBody, Container, Row, Col,
} from 'reactstrap';
import MultiSeriesTestChart from '../portal-fusion-chart/multi-series-test-chart';
import { connectUrlState } from '../url-state-manager/url-state-manager';
import { ScrollToTopOnMount } from '../router/scroll-to-top-on-mount';


/**
 * @class TestChartPlayground
 * @description
 * Use this page to experiment with Portal Fusion Charts
 */
// eslint-disable-next-line react/prefer-stateless-function
class TestChartPlayground extends React.Component {
  /**
   * @inheritdoc
   */
  render() {
    return (
      <>
        <ScrollToTopOnMount />
        <Container fluid>
          <Row>
            <Col lg={12}>
              <h2>Welcome to the Report Chart Playground</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <MultiSeriesTestChart />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default connectUrlState(() => ({ hello: 'world' }))(TestChartPlayground);
