import React from 'react';

import { DataTablePageProps } from '../../types/data-table-page.props';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { API_FILTER_OPERATION } from '../../constants/api-filter-operation.const';

type ProjectItemEtaItemsTablePageProps = DataTablePageProps<{id?: string}> & {
  filterColumn: string,
}

/**
 * Display the "Project Item ETA Details" data table as a whole page
 */
export const ProjectItemEtaItemsTablePage: React.FC<ProjectItemEtaItemsTablePageProps> = ({
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
  history,
  filterColumn,
  title,
}) => {
  const baseFilters = filterColumn === undefined ? undefined : [{
    field: filterColumn,
    operation: API_FILTER_OPERATION.EQUALS,
    values: [match.params.id],
  }];
  const useTitle = match.params.id === undefined ? title : `${title} ${decodeURIComponent(match.params.id)}`;
  return (
    <DataTablePage
      title={useTitle}
      baseFilters={baseFilters}
      tableIdentifier={TABLE_IDENTIFIER.PROJECT_ITEM_ETA_ITEMS_TABLE}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      {children}
    </DataTablePage>
  );
};
