import { SORT_CI_DATATABLE_COLUMNS } from '../actions/portal-data-table/change-column-order';
import {
  DATATABLE_DATA_SUCCESS,
  DATATABLE_FETCH_ERROR,
  DATATABLE_DATA_LOADING,
} from '../actions/portal-data-table/fetch-table-data';
import { DATATABLE_UPDATE_ROW } from '../actions/portal-data-table/refresh-row-data';
import {
  DATATABLE_FILTER_COLUMN,
  DATATABLE_CLEAR_COLUMN_FILTERS,
} from '../actions/portal-data-table/filter-actions';
import {
  DATABLE_SORT_COLUMN,
} from '../actions/portal-data-table/sort-columns';
import { getItemByKey } from '../utils/helpers';
import { DATATABLE_UPDATE_SETTINGS } from '../actions/portal-data-table/update-settings';
import SORT_DIRECTION from '../utils/sort-directions';
import { loadTableStateFromLocalStorage } from '../utils/localStorage';
import { TABLE_IDENTIFIER } from '../constants/table-identifier.const';

// Table definitions
import myLeaveTableInitialSettings from '../table-definitions/my-leave-table';
import manageLeaveTableInitialSettings from '../table-definitions/manage-leave-table';
import processLeaveTableInitialSettings from '../table-definitions/process-leave-table';

import projectTableInitialSettings from '../table-definitions/project-table';
import companyTableInitialSettings from '../table-definitions/company-table';
import contactTableInitialSettings from '../table-definitions/contact-table';
import userManagementTableInitialSettings from '../table-definitions/user-management-table';

import myExpensesTableInitialSettings from '../table-definitions/my-expenses-table';
import manageExpensesTableInitialSettings from '../table-definitions/manage-expenses-table';
import processExpenseClaimsTableInitialSettings from '../table-definitions/process-expense-claims-table';
import processExpenseBatchesTableInitialSettings from '../table-definitions/process-expense-batches-table';

import myAllowancesTableInitialSettings from '../table-definitions/my-allowances-table';
import manageAllowancesTableInitialSettings from '../table-definitions/manage-allowances-table';
import processAllowancesTableInitialSettings from '../table-definitions/process-allowances-table';
import projectDrilldownTableInitialSettings from '../table-definitions/project-drilldown-table';

import etaSummaryTableInitialSettings from '../table-definitions/eta-summary-table';

import { getVisibleColumnsInDisplayOrder } from '../helpers/get-visible-columns-in-display-order.helper';
import etaItemsTableInitialSettings from '../table-definitions/eta-items-table';
import sprintsTableInitialSettings from '../table-definitions/sprints-table';
import teamsTableInitialSettings from '../table-definitions/teams-table';
import processesTableInitialSettings from '../table-definitions/processes-table';
import platformsTableInitialSettings from '../table-definitions/platforms-table';
import internalProjectsTableInitialSettings from '../table-definitions/internal-projects-table';
import internalProjectTasksTableInitialSettings from '../table-definitions/internal-project-tasks-table';
import taskTemplatesTableInitialSettings from '../table-definitions/task-templates-table';

import orderConfirmationTableInitialSettings from '../table-definitions/order-confirmation-table';

import dtoolsChangeOrderTableInitialSettings from '../table-definitions/dtools-change-order-table';

import contractsTableInitialSettings from '../table-definitions/contracts-table';
import licencesTableInitialSettings from '../table-definitions/licences-table';
import contractTypesTableInitialSettings from '../table-definitions/contract-types-table';
import contractSubtypesTableInitialSettings from '../table-definitions/contract-subtypes-table';
import contractRegionsTableInitialSettings from '../table-definitions/contract-regions-table';
import supportTableInitialSettings from '../table-definitions/support-table';
import securityPermissionTableInitialSettings from '../table-definitions/security-permissions-table';
import securityRoleTableInitialSettings from '../table-definitions/security-roles-table';
import companySpaceTypesTableInitialSettings from '../table-definitions/company-space-types-table';
import statesTableInitialSettings from '../table-definitions/states-table';

import invoiceRequestsTableInitialSettings from '../table-definitions/invoice-request-table';
import serviceJobsTableInitialSettings from '../table-definitions/service-jobs-table';

import commissionStructureIterationsTableInitialSettings from '../table-definitions/commission-structure-iterations-table';
import commissionStructureBracketsTableInitialSettings from '../table-definitions/commission-structure-brackets-table';
import monthlyCommissionSnapshotsTableSettings from '../table-definitions/monthly-commission-snapshots-table';
import projectProfitabilitySnapshotsTableInitialSettings from '../table-definitions/project-profitability-snapshots-table';
import { CommissionPaymentBatchesDataTablePage } from '../components/data-table-pages/commission-payment-batches.data-table-page';
import commissionPaymentBatchesTableInitialSettings from '../table-definitions/commission-payment-batches-table';


const initialState = {
  [TABLE_IDENTIFIER.PROJECT_TABLE]: projectTableInitialSettings,
  [TABLE_IDENTIFIER.COMPANY_TABLE]: companyTableInitialSettings,
  [TABLE_IDENTIFIER.CONTACT_TABLE]: contactTableInitialSettings,
  [TABLE_IDENTIFIER.USER_MANAGEMENT_TABLE]: userManagementTableInitialSettings,
  [TABLE_IDENTIFIER.STATES_TABLE]: statesTableInitialSettings,

  [TABLE_IDENTIFIER.MY_LEAVE_TABLE]: myLeaveTableInitialSettings,
  [TABLE_IDENTIFIER.MANAGE_LEAVE_TABLE]: manageLeaveTableInitialSettings,
  [TABLE_IDENTIFIER.PROCESS_LEAVE_TABLE]: processLeaveTableInitialSettings,

  [TABLE_IDENTIFIER.MY_EXPENSES_TABLE]: myExpensesTableInitialSettings,
  [TABLE_IDENTIFIER.MANAGE_EXPENSES_TABLE]: manageExpensesTableInitialSettings,
  [TABLE_IDENTIFIER.PROCESS_EXPENSE_CLAIMS_TABLE]: processExpenseClaimsTableInitialSettings,
  [TABLE_IDENTIFIER.PROCESS_EXPENSE_BATCHES_TABLE]: processExpenseBatchesTableInitialSettings,

  [TABLE_IDENTIFIER.MY_ALLOWANCES_TABLE]: myAllowancesTableInitialSettings,
  [TABLE_IDENTIFIER.MANAGE_ALLOWANCES_TABLE]: manageAllowancesTableInitialSettings,
  [TABLE_IDENTIFIER.PROCESS_ALLOWANCES_TABLE]: processAllowancesTableInitialSettings,

  [TABLE_IDENTIFIER.SALES_PERFORMANCE_DRILLDOWN_TABLE]: projectDrilldownTableInitialSettings,
  [TABLE_IDENTIFIER.EXEC_DASH_AGED_PROPOSALS_DRILLDOWN_TABLE]: projectDrilldownTableInitialSettings,
  [TABLE_IDENTIFIER.EXEC_DASH_PIPELINE_DRILLDOWN_TABLE]: projectDrilldownTableInitialSettings,
  [TABLE_IDENTIFIER.EXEC_DASH_FY_DRILLDOWN_TABLE_TABLE]: projectDrilldownTableInitialSettings,
  [TABLE_IDENTIFIER.EXEC_DASH_FY_LAST_DRILLDOWN_TABLE]: projectDrilldownTableInitialSettings,
  [TABLE_IDENTIFIER.EXEC_DASH_FY_LAST_2_DRILLDOWN_TABLE]: projectDrilldownTableInitialSettings,

  [TABLE_IDENTIFIER.PROJECT_ITEM_ETA_SUMMARY_TABLE]: etaSummaryTableInitialSettings,
  [TABLE_IDENTIFIER.PROJECT_ITEM_ETA_ITEMS_TABLE]: etaItemsTableInitialSettings,
  [TABLE_IDENTIFIER.DTOOLS_CHANGE_ORDER_TABLE]: dtoolsChangeOrderTableInitialSettings,

  [TABLE_IDENTIFIER.ORDER_CONFIRMATION_TABLE]: orderConfirmationTableInitialSettings,

  [TABLE_IDENTIFIER.SPRINTS_TABLE]: sprintsTableInitialSettings,
  [TABLE_IDENTIFIER.PROCESSES_TABLE]: processesTableInitialSettings,
  [TABLE_IDENTIFIER.PLATFORMS_TABLE]: platformsTableInitialSettings,
  [TABLE_IDENTIFIER.TEAMS_TABLE]: teamsTableInitialSettings,
  [TABLE_IDENTIFIER.INTERNAL_PROJECTS_TABLE]: internalProjectsTableInitialSettings,
  [TABLE_IDENTIFIER.INTERNAL_PROJECT_TASKS_TABLE]: internalProjectTasksTableInitialSettings,
  [TABLE_IDENTIFIER.TASK_TEMPLATES_TABLE]: taskTemplatesTableInitialSettings,

  [TABLE_IDENTIFIER.CONTRACTS_TABLE]: contractsTableInitialSettings,
  [TABLE_IDENTIFIER.LICENCES_TABLE]: licencesTableInitialSettings,
  [TABLE_IDENTIFIER.SUPPORT_TABLE]: supportTableInitialSettings,
  [TABLE_IDENTIFIER.CONTRACT_TYPES_TABLE]: contractTypesTableInitialSettings,
  [TABLE_IDENTIFIER.CONTRACT_SUBTYPES_TABLE]: contractSubtypesTableInitialSettings,
  [TABLE_IDENTIFIER.CONTRACT_REGIONS_TABLE]: contractRegionsTableInitialSettings,
  [TABLE_IDENTIFIER.COMPANY_SPACE_TYPES_TABLE]: companySpaceTypesTableInitialSettings,

  // Service Jobs
  [TABLE_IDENTIFIER.SERVICE_JOBS_TABLE]: serviceJobsTableInitialSettings,


  // Finance Tables
  [TABLE_IDENTIFIER.INVOICE_REQUESTS_TABLE]: invoiceRequestsTableInitialSettings,
  [TABLE_IDENTIFIER.COMMISSION_STRUCTURE_ITERATIONS_TABLE]: commissionStructureIterationsTableInitialSettings,
  [TABLE_IDENTIFIER.COMMISSION_STRUCTURE_BRACKETS_TABLE]: commissionStructureBracketsTableInitialSettings,
  [TABLE_IDENTIFIER.MONTHLY_COMMISSION_SNAPSHOTS_TABLE]: monthlyCommissionSnapshotsTableSettings,
  [TABLE_IDENTIFIER.PROJECT_PROFITABILITY_SNAPSHOTS_TABLE]: projectProfitabilitySnapshotsTableInitialSettings,

  // Security Tables
  [TABLE_IDENTIFIER.SECURITY_PERMISSIONS_TABLE]: securityPermissionTableInitialSettings,
  [TABLE_IDENTIFIER.SECURITY_ROLES_TABLE]: securityRoleTableInitialSettings,
  [TABLE_IDENTIFIER.COMMISSION_PAYMENT_BATCHES_TABLE]: commissionPaymentBatchesTableInitialSettings,
};

// After initialising each of the tables to the default table settings, read the
// User's preferences from the local storage to override any defaults that have been
// changed by the user
Object.keys(initialState).forEach((tableIdentifier) => {
  const tableStateFromLocalStorage = loadTableStateFromLocalStorage(tableIdentifier, initialState[tableIdentifier]);
  if (tableStateFromLocalStorage) {
    initialState[tableIdentifier] = {
      ...initialState[tableIdentifier],
      ...tableStateFromLocalStorage,
      // TODO: we will have to handle the 2nd level of spread operation when we bring in orderedVisibleColumns and columnPresets
    };
  }
});

const dataTableSettingsReducer = (state = initialState, action) => {
  let newColumns;

  switch (action.type) {
    case DATATABLE_UPDATE_SETTINGS: {
      return {
        ...state,
        [action.tableIdentifier]: {
          ...state[action.tableIdentifier],
          forceReapplyViewDefaults: false,
          pushToHistory: false,
          replaceInHistory: false,
          reloadTable: false,
          ...action.settings,
        },
      };
    }

    case DATATABLE_FILTER_COLUMN: {
      const filteredColumns = [...state[action.tableIdentifier].filteredColumns];
      const { name, filter } = action;

      // Remove the existing filter?
      if (filter === null) {
        if (getItemByKey(filteredColumns, name, 'name')) {
          filteredColumns.splice(filteredColumns.indexOf(getItemByKey(filteredColumns, name, 'name')), 1);
        }
      }

      // Update the existing filter
      else if (getItemByKey(filteredColumns, name, 'name')) {
        filteredColumns[filteredColumns.indexOf(getItemByKey(filteredColumns, name, 'name'))] = filter;
      }

      // Add the new filter
      else filteredColumns.push(filter);

      // return the new state
      return {
        ...state,
        [action.tableIdentifier]: {
          ...state[action.tableIdentifier],
          filteredColumns,
          pushToHistory: action.pushToHistory,
          replaceInHistory: action.replaceInHistory,
        },
      };
    }

    case DATATABLE_CLEAR_COLUMN_FILTERS: {
      return {
        ...state,
        [action.tableIdentifier]: {
          ...state[action.tableIdentifier],
          filteredColumns: [],
          pushToHistory: action.pushToHistory,
          replaceInHistory: action.replaceInHistory,
        },
      };
    }

    case DATABLE_SORT_COLUMN: {
      const newSortedColumns = [...state[action.tableIdentifier].sortedColumns];
      const { name, direction } = action;
      const existingSortedColumn = getItemByKey(newSortedColumns, name, 'name');

      // Column is already sorted
      if (existingSortedColumn) {
      // When direction === null, remove the sort
        if (direction === null) {
          newSortedColumns.splice(newSortedColumns.indexOf(existingSortedColumn), 1);
        }

        // When direction is specified, force the requested direction
        else if (direction !== undefined) {
          newSortedColumns[newSortedColumns.indexOf(existingSortedColumn)] = {
            ...existingSortedColumn,
            direction,
          };
        }

        // Otherwise toggle the existing sort direction or remove the column from the sort
        else {
          switch (existingSortedColumn.direction) {
            // Current sort direction is ascending -> chang it to descending
            case SORT_DIRECTION.ASC:
              newSortedColumns[newSortedColumns.indexOf(existingSortedColumn)] = {
                ...existingSortedColumn,
                direction: SORT_DIRECTION.DESC,
              };
              break;

              // Current sort direction is descending (or invalid) -> remove it
            case SORT_DIRECTION.DESC:
            default:
              newSortedColumns.splice(newSortedColumns.indexOf(existingSortedColumn), 1);
              break;
          }
        }

      // Column is not currently sorted (and the user has not specified they want to remove it by sort direction = null)
      }
      else if (direction !== null) {
      // Calculate the new sort index
        const newSortIndex = newSortedColumns.length
          ? Math.max(...newSortedColumns.map((column) => column.sortIndex || 0)) + 1
          : 0;

        newSortedColumns.push({
          name,
          direction: direction || SORT_DIRECTION.ASC,
          sortIndex: newSortIndex,
        });
      }

      // return the new state
      return {
        ...state,
        [action.tableIdentifier]: {
          ...state[action.tableIdentifier],
          sortedColumns: [...newSortedColumns],
          pushToHistory: action.pushToHistory,
          replaceInHistory: action.replaceInHistory,
        },
      };
    }

    case DATATABLE_FETCH_ERROR: {
      return {
        ...state,
        [action.tableIdentifier]: {
          ...state[action.tableIdentifier],
          hasError: action.isError,
          errorMessage: action.errorMessage,
        },
      };
    }

    case DATATABLE_DATA_LOADING: {
      return {
        ...state,
        [action.tableIdentifier]: {
          ...state[action.tableIdentifier],
          isLoading: action.isLoading,
        },
      };
    }

    case DATATABLE_DATA_SUCCESS: {
      // return state
      return {
        ...state,
        [action.tableIdentifier]: {
          ...state[action.tableIdentifier],
          recordSet: action.data,
          activePage: action.activePage,
          totalRecords: action.totalRecords,
          availableActions: action.availableActions,
          hasError: false,
          errorMessage: null,
          isLoading: false,
          orderedVisibleColumns: getVisibleColumnsInDisplayOrder([...state[action.tableIdentifier].columns], action.userHasPermissions),
        },
      };
    }

    case DATATABLE_UPDATE_ROW: {
      return {
        ...state,
        [action.tableIdentifier]: {
          ...state[action.tableIdentifier],
          recordSet: state[action.tableIdentifier].recordSet.map((record) => {
            if (record.id === parseInt(action.id, 10)) {
              return action.rowData;
            }
            return record;
          }),
        },
      };
    }

    case SORT_CI_DATATABLE_COLUMNS: {
      // adjust the column settings
      newColumns = [...state[action.tableIdentifier].columns].map((column) => ({
        ...column,
        // if provided, set a new order. Otherwise retain the old one
        order: column.name in action.newOrderMap
          ? action.newOrderMap[column.name]
          : column.order,
        visible: action.visibleColumns.includes(column.name),
      }));
      // return the new state
      return {
        ...state,
        [action.tableIdentifier]: {
          ...state[action.tableIdentifier],
          columns: newColumns,
          orderedVisibleColumns: getVisibleColumnsInDisplayOrder(newColumns, action.userHasPermissions),
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default dataTableSettingsReducer;
