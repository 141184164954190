import React, { PropsWithChildren, useCallback } from 'react';

import { UpdateRecordModalProps } from '../../types/modal/update-record-modal.props';
import { APIPolyFormProps } from '../../types/poly-form/api-poly-form.props';
import { IFileRecord } from '../../types/file.record.interface';
import { IFormFieldDefinition } from '../../types/poly-form/form-field-definition.interface';

import { useIsDirty } from '../../react-hooks/use-is-dirty.hook';

import { Modal } from './Modal';
import { APIPolyForm } from '../poly-forms/api-poly-form';

import { FORM_FIELD_TYPE } from '../../constants/form-field-type.const';
import { getMimeTypeIcon } from '../../constants/mime-type.const';
import { AN_ICON, ICON } from '../../constants/icon.const';
import { COLUMN_FORMAT } from '../../constants/column-format.const';

export type UpdateFileModalProps<T> = UpdateRecordModalProps<T> & Pick<APIPolyFormProps<T>,
  'parentId' |
  'baseRoute' |
  'apiRoute' |
  'apiQuery'
> & {
  additionalFields?: APIPolyFormProps['fields'],
}

const defaultFileFormFields: IFormFieldDefinition[] = [
  {
    name: 'id',
    title: 'ID',
    visible: false,
    formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
  },
  {
    name: 'filename',
    title: 'Filename',
    showInForm: true,
    formFieldType: FORM_FIELD_TYPE.TEXT_INPUT,
  },
  {
    name: 'comment',
    title: 'Comment',
    showInForm: true,
    formFieldType: FORM_FIELD_TYPE.HTML_INPUT,
    format: COLUMN_FORMAT.HTML_NOTIN_TABLE,
    placeholder: 'Provide a meaningful description of what this file represents.',
  },
];

export const UpdateFileModal = <T extends IFileRecord = IFileRecord, >(props: PropsWithChildren<UpdateFileModalProps<T>>): React.ReactElement => {
  const {
    id,
    closeModal,
    onModalComplete,
    parentId,
    formData = {} as T,
    additionalFields = [],
    baseRoute,
    apiRoute,
    apiQuery,
  } = props;

  const [isDirty, setDirty] = useIsDirty();

  /**
   * Fired when the form successfully saves or cancels the changes
   */
  const handleFormComplete = useCallback((success: boolean, recordId: T['id'], updatedRecord?: Partial<T>) => {
    closeModal(id);
    onModalComplete((success && updatedRecord) ? { success, updatedRecord: (updatedRecord as T) } : { success: false, updatedRecord: null });
  }, [closeModal, id, onModalComplete]);

  // Render
  return (
    <Modal
      {...props}
      icon={formData.content_type ? (getMimeTypeIcon(formData.content_type) as AN_ICON) : ICON.FILE}
      title={formData.filename}
      allowDismiss
      isDirty={isDirty}
      size="lg"
    >
      <APIPolyForm<T>
        primaryKeyFieldName="id"
        fields={[
          ...defaultFileFormFields.filter((field) => !(additionalFields.map((additionalField) => additionalField.name)).includes(field.name)),
          ...additionalFields,
        ]}
        parentId={parentId}
        formData={formData}
        onFormComplete={handleFormComplete}
        onFormChange={setDirty}
        isEditing
        baseRoute={baseRoute}
        apiRoute={apiRoute}
        apiQuery={apiQuery}
      />
    </Modal>
  );
};
