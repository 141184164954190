import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { DToolsProjectSites } from './d-tools-project-sites';
import { DToolsProjectViewLinks } from './d-tools-project-viewlinks';
import { DToolsProjectSummary } from './d-tools-project-summary';
import { DToolsProjectTracking } from './d-tools-project-tracking';
import DToolsProjectOrders from './d-tools-project-orders';
import { DToolsProjectLocations } from './d-tools-project-locations';
import { DToolsProjectFinancialSummary } from './d-tools-financial-summary';
import { DToolsProjectSchedule } from './d-tools-project-schedule';
import { DToolsProjectItems } from './d-tools-project-items';
import { DToolsMarginTracking } from './d-tools-margin-tracking';
import { CurrentUserContext } from '../providers/current-user-provider';

/**
 * DToolsInfoPanel component for Data Table
 */
export const DToolsInfoPanel = ({ dtoolsId, projectData, showItems }) => {
  const initialState = {
    summary: true,
    tracking: true,
    trackingCharts: true,
    sites: false,
    orders: false,
    schedule: false,
    location: false,
    financials: false,
    // items: false,  // only to be included if set
  };

  if (showItems) initialState.items = false;

  const [sections, setSections] = useState(initialState);

  const { userDetails } = useContext(CurrentUserContext);

  // @todo use a permission and role group
  const userCanSeeCharts = (
    userDetails.name === 'Tim Ogilvy'
    || userDetails.name === 'Sino Guzzardi'
    || userDetails.name === 'Nicholas Guzzardi'
    || userDetails.name === 'Travis Anderson'
    || userDetails.name === 'Gary Oliver'
    || userDetails.name === 'Chris Gauci'
    || userDetails.name === 'Dom Vaiano'
    || userDetails.name === 'David Cooper'
  );

  const toggleSection = useCallback((section) => {
    setSections({
      ...sections,
      [section]: !sections[section],
    });
  }, [sections, setSections]);

  return (
    <div className="dtoolslink-info-panel">
      <Row>
        <Col md="12" lg="6" xl="4">
          <DToolsProjectViewLinks dtoolsId={dtoolsId} toggleSection={toggleSection} visibility={{ ...sections }} userCanSeeCharts={userCanSeeCharts} />
        </Col>

        {sections.summary && (
          <Col md="12" lg="6" xl="4">
            <DToolsProjectSummary dtoolsId={dtoolsId} />
          </Col>
        )}

        {sections.tracking && (
          <Col md="12" lg="6" xl="4">
            <DToolsProjectTracking dtoolsId={dtoolsId} />
          </Col>
        )}

        {sections.trackingCharts && userCanSeeCharts && (
          <Col md="12" lg="12" xl="12">
            <DToolsMarginTracking dtoolsId={dtoolsId} projectData={projectData} />
          </Col>
        )}

        {sections.orders && (
          <Col md="12" lg="12" xl="8">
            <DToolsProjectOrders dtoolsId={dtoolsId} projectData={projectData} />
          </Col>
        )}

        {sections.locations && (
          <Col md="12" lg="6" xl="4">
            <DToolsProjectLocations dtoolsId={dtoolsId} />
          </Col>
        )}

        {sections.sites && (
          <Col md="12" lg="6" xl="4">
            <DToolsProjectSites dtoolsId={dtoolsId} />
          </Col>
        )}

        {sections.financials && (
          <Col md="12" lg="12" xl="12">
            <DToolsProjectFinancialSummary dtoolsId={dtoolsId} />
          </Col>
        )}

        {sections.schedule && (
          <Col md="12" lg="6" xl="4">
            <DToolsProjectSchedule dtoolsId={dtoolsId} />
          </Col>
        )}

        <Col md="12" lg="12" xl="12">
          <div className="text-white">{userDetails.name}</div>
        </Col>

      </Row>
      {showItems && sections.items && (
        <Row>
          <Col>
            <DToolsProjectItems dtoolsId={dtoolsId} />
          </Col>
        </Row>
      )}
    </div>
  );
};

DToolsInfoPanel.defaultProps = {
  showItems: true,
};

DToolsInfoPanel.propTypes = {
  dtoolsId: PropTypes.string.isRequired,
  projectData: PropTypes.shape({ id: PropTypes.number }).isRequired,
  showItems: PropTypes.bool,
};
