import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';
import { getItemByKey, realSortField } from './helpers';


/**
 * @description
 * Converts array of query param filters into string for URL parsing
 * (WITHOUT prepending/appending question mark ? or ampersand &)
 *
 * @param {{
 *    field: string,
 *    operation: string,
 *    values: string[]
 * }[]} filters
 *
 * @returns {string} filter string for API call
 *
 * @example
 * makeFilterString(someArray, 1);
 * // > 'filter[1][field]=yag&filter[1][operation]=contains&filter[1][value]=james'
 */
export const makeFilterString = (filters) => {
  const filterStrings = [];

  if (filters && filters.length) {
    let i = 0;
    filters.forEach((filter) => {
      const { field, operation } = filter;
      let { values } = filter;

      // make sure all the proper keys exist in the clause
      if (field && values) {
        // field
        filterStrings.push(`filter[${i}][field]=${field}`);

        // Operation
        let op = operation;
        if (!operation) {
          if (values instanceof Array) {
            // if we don't specify the operation at all
            // and the values are an array
            // and more than 1 value is in it

            // assume 'in' operation.
            if (values.length > 1) op = API_FILTER_OPERATION.IN;
            // If only 1 array element assume 'equals' operation.
            else if (values.length === 1) op = API_FILTER_OPERATION.EQUALS;
          }
        }
        else if (
          operation === API_FILTER_OPERATION.EQUALS &&
          values instanceof Array &&
          (
            (values.length > 0 && values[0] === null) ||
            values.length === 0
          )
        ) {
          op = API_FILTER_OPERATION.IS_NULL;
          values = undefined;
        }

        filterStrings.push(`filter[${i}][operation]=${op}`);

        // If values are multiple or single, format value param differently
        if (values instanceof Array) {
          if (values.length > 1) {
            let j = 0;
            values.forEach((value) => {
              filterStrings.push(`filter[${i}][value][${j}]=${value}`);
              j += 1;
            });
          }
          else {
            // only 1, use first item in array
            filterStrings.push(`filter[${i}][value]=${values[0]}`);
          }
        }
        else if (values !== undefined) {
          // not array, use value directly
          filterStrings.push(`filter[${i}][value]=${values}`);
        }
        i += 1;
      }
    });
  }

  return filterStrings.join('&');
};


/**
 * @description
 * Converts array of query param sorts into string for URL parsing
 * (WITHOUT prepending/appending question mark ? or ampersand &)
 *
 * "name" is the corresponding column name
 *
 * @param {{
 *  name: string,
 *  direction: 'asc' | 'desc',
 *  sortIndex: number
 * }[]} sortedColumns
 * @param {object[]} columns
 *
 * @returns {string} sort string for API call
 *
 * @example
 * makeSortStringFromSortedColumns(sortedColumns);
 * // > 'sort[0][field]=yag&sort[0][direction]=asc'
 *
 * makeSortStringFromSortedColumns(sortedColumns, 1);
 * // > 'sort[1][field]=yag&sort[1][direction]=asc'
 */
export const makeSortStringFromSortedColumns = (sortedColumns, columns) => {
  let i = -1;
  return sortedColumns.sort((a, b) => (a.sortIndex - b.sortIndex)).map((sortedColumn) => {
    i += 1;
    const column = getItemByKey(columns, sortedColumn.name, 'name');
    const sortField = realSortField(column);
    return `sort[${i}][field]=${sortField}&sort[${i}][direction]=${sortedColumn.direction.toLowerCase()}`;
  }).join('&');
};


/**
 * @description
 * Converts array of query param sorts into string for URL parsing
 * (WITHOUT prepending/appending question mark ? or ampersand &)
 *
 * "field" is the corresponding field name
 *
 * @param {{
 *  field: string,
 *  direction: 'asc' | 'desc',
 *  sortIndex: number
 * }[]} customSortArray
 *
 * @returns {string} sort string for API call
 */
export const makeSortStringFromCustomSortArray = (customSortArray) => {
  let i = -1;
  return customSortArray.sort((a, b) => (a.sortIndex - b.sortIndex)).map((customSort) => {
    i += 1;
    return `sort[${i}][field]=${customSort.field}&sort[${i}][direction]=${customSort.direction.toLowerCase()}`;
  }).join('&');
};
