import React from 'react';
import classNames from 'classnames';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';

import { getFieldDisplayComponent, formatAlignClass } from '../render-functions';
import { COLUMN_FORMAT } from '../../constants/column-format.const';

export type NotEditableInputProps = Pick<FormFieldComponentProps,
  'formData' |
  'field' |
  'inline' |
  'disabled'
>;

/**
 * Input type that isn't an input, just displays the field value
 * This is also used in edit mode for DisabledField types
 */
export const NotEditableInput: React.FC<NotEditableInputProps> = ({
  formData, field, inline, disabled,
}) => {
  const format = field?.format ?? COLUMN_FORMAT.TEXT;
  const formattedValue = field && formData ? getFieldDisplayComponent(formData, field, inline) : undefined;
  const value = formattedValue ?? (
    <div className={classNames('text-gray', formatAlignClass(format, inline))}>
      not set
    </div>
  );
  return (
    <div
      className={classNames('portal-data-field', 'not-editable', formatAlignClass(format, inline), {
        inline,
        disabled,
        'not-set': formattedValue === null,
      })}
    >
      {value}
    </div>
  );
};
