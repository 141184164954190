import React from 'react';
import { Col, Form, FormGroup, Input, Label } from 'reactstrap';

interface INewMyobPurchaseOrderFormProps {
  vendorId?: string,
  vendorName?: string,
  projectId?: string,
  projectNumber?: string,
  orderNote?: string,
  orderNumber?: string,
  orderDate?: string,
  setOrderNote: (newValue: string) => void,
}

export const NewMyobPurchaseOrderForm: React.FC<INewMyobPurchaseOrderFormProps> = ({
  vendorId,
  vendorName,
  projectId,
  projectNumber,
  orderNote,
  orderNumber,
  orderDate,
  setOrderNote,
}) => (
  <Form>
    <FormGroup row>
      <Label for="vendorId" sm={3}>Vendor Id</Label>
      <Col sm={9}>
        <Input disabled type="text" name="vendorId" id="vendorId" value={vendorId} />
      </Col>
    </FormGroup>
    <FormGroup row>
      <Label for="vendorName" sm={3}>Vendor Name</Label>
      <Col sm={9}>
        <Input disabled type="text" name="vendorName" id="vendorName" value={vendorName} />
      </Col>
    </FormGroup>
    <FormGroup row>
      <Label for="projectId" sm={3}>Project Id</Label>
      <Col sm={9}>
        <Input disabled type="text" name="projectId" id="projectId" value={projectId} />
      </Col>
    </FormGroup>
    <FormGroup row>
      <Label for="projectNumber" sm={3}>Project Number</Label>
      <Col sm={9}>
        <Input disabled type="text" name="projectNumber" id="projectNumber" value={projectNumber} />
      </Col>
    </FormGroup>
    <FormGroup row>
      <Label for="orderNumber" sm={3}>Order Number</Label>
      <Col sm={9}>
        <Input disabled type="text" name="orderNumber" id="orderNumber" value={orderNumber} />
      </Col>
    </FormGroup>
    <FormGroup row>
      <Label for="orderDate" sm={3}>Order Date</Label>
      <Col sm={9}>
        <Input disabled type="text" name="orderDate" id="orderDate" value={orderDate} />
      </Col>
    </FormGroup>
    <FormGroup row>
      <Label for="orderNote" sm={3}>Order Note</Label>
      <Col sm={9}>
        <Input
          type="textarea"
          name="orderNote"
          id="orderNote"
          value={orderNote}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOrderNote(e.target.value)}
          placeholder="enter a note"
        />
      </Col>
    </FormGroup>
  </Form>
);
