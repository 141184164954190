import { IActionButton } from '../types/action-button.interface';
import { API_ACTION } from './api-action.const';
import { BUTTON_COLOR } from './button-color.const';
import { ICON } from './icon.const';

/**
 * These are the default actions which are typically possible on an individual
 * item in a collection widget.
 *
 * Note: These do not usually correspond with an API policy returned from a fetch
 *       instead, they are evaluated locally depending on typical factors like
 *       whether the parent record is editable or not or if the user has a
 *       particular permission.
 */
export const defaultCollectionWidgetItemPossibleActions: IActionButton[] = [
  {
    name: API_ACTION.UPDATE,
    title: 'Edit',
    color: BUTTON_COLOR.PRIMARY,
    icon: ICON.EDIT,
  },
  {
    name: API_ACTION.DELETE,
    title: 'Delete',
    color: BUTTON_COLOR.DANGER,
    icon: ICON.DELETE,
  },
];
