import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { DataTablePageProps } from '../../types/data-table-page.props';
import { IExpenseClaimRecord } from '../../types/expense-claim/expense-claim.record.interface';
import { NewRecordModalResult } from '../../types/modal/modal-result';

import { CurrentUserContext } from '../providers/current-user-provider';
import { ModalContext } from '../modals/modal-context';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { API_FILTER_OPERATION } from '../../constants/api-filter-operation.const';

export type MyExpenseClaimsDataTablePageProps = DataTablePageProps;

/**
 * Display the "My Expenses" data table as a whole page
 */
export const MyExpenseClaimsDataTablePage: React.FC<MyExpenseClaimsDataTablePageProps> = ({
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const { userDetails } = useContext(CurrentUserContext);
  const history = useHistory();
  const { showModal } = useContext(ModalContext);

  const baseFilters = [
    {
      field: 'user_id',
      operation: API_FILTER_OPERATION.EQUALS,
      values: [userDetails.id],
    },
  ];

  const actionHandlers = {
    create: () => {
      showModal<NewRecordModalResult<IExpenseClaimRecord>>(MODAL_TYPE.NEW_EXPENSE, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/hrm/my-expenses/${modalResult.newRecord.id}/files`);
        },
      });
    },
  };

  return (
    <DataTablePage
      title="My Expense Claims"
      tableIdentifier={TABLE_IDENTIFIER.MY_EXPENSES_TABLE}
      baseFilters={baseFilters}
      actionHandlers={actionHandlers}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      {children}
    </DataTablePage>
  );
};
