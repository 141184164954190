export const UPLOAD_STATE = {
  PENDING: 0,
  REQUESTING: 1,
  REQUEST_FAILED: 2,
  REQUEST_SUCCEEDED: 3,
  UPLOADING: 4,
  UPLOAD_FAILED: 5,
  UPLOAD_SUCCEEDED: 6,
  CONFIRMING: 7,
  CONFIRM_FAILED: 8,
  CONFIRM_SUCCEEDED: 9,
  NOT_UPLOADING: 99,
} as const;
export type UPLOAD_STATE = typeof UPLOAD_STATE;
export type AN_UPLOAD_STATE = UPLOAD_STATE[keyof UPLOAD_STATE];
