import React, { useCallback } from 'react';

import { ILeaveApplicationRecord } from '../../types/leave/leave-application.record.interface';
import { NewRecordModalProps } from '../../types/modal/new-record-modal.props';

import { useIsDirty } from '../../react-hooks/use-is-dirty.hook';

import { Modal } from './Modal';
import { NewLeaveApplicationForm } from '../poly-forms/new-leave-application-form';

export const NewLeaveApplicationModal: React.FC<NewRecordModalProps<ILeaveApplicationRecord>> = (props) => {
  const {
    id, closeModal, onModalComplete, initialData,
  } = props;

  const [isDirty, setDirty] = useIsDirty();

  /**
   * Fired when the form successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, recordId: ILeaveApplicationRecord['id'], newRecord?: ILeaveApplicationRecord) => {
    closeModal(id);
    onModalComplete((success && newRecord) ? { success, newRecord } : { success: false, newRecord: null });
  }, [closeModal, id, onModalComplete]);

  return (
    <Modal
      {...props}
      title="Apply for Leave"
      allowDismiss
      isDirty={isDirty}
    >
      <NewLeaveApplicationForm
        onFormComplete={handleFormComplete}
        onFormChange={setDirty}
        initialData={initialData}
      />
    </Modal>
  );
};
