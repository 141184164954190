import React from 'react';
import { ICON } from '../../../constants/icon.const';
import Icon from '../../layout-helpers/icon';

export interface IFileRecord {
  id: number,
  name: string,
  url: string,
  download: string,
  folder: boolean,
}

const getIcon = (fileRecord: IFileRecord) => {
  if (fileRecord.name.slice(-5) === '.docx') return ICON.DOCUMENT;
  if (fileRecord.name.slice(-5) === '.xlsx') return ICON.EXCEL_DOWNLOAD;
  if (fileRecord.name.slice(-4) === '.url') return ICON.EXTERNAL_LINK;
  if (fileRecord.name.slice(-4) === '.pdf') return ICON.PDF;
  return ICON.FILE;
};

export const FileRecordRow: React.FC<{ fileRecord: IFileRecord, depth: number }> = ({ fileRecord, depth }) => (
  <tr key={fileRecord.id} className="p-2">
    <td>
      {'>\u00a0'.repeat(depth)}
      {depth > 0 && '\u00a0\u00a0'}
      <Icon i={getIcon(fileRecord)} className="pr-2" />
      {fileRecord.name}
    </td>
    <td>
      <a rel="noreferrer" target="_blank" href={fileRecord.url}>
        <Icon i={ICON.EXTERNAL_LINK} className="pr-2" />
        View
      </a>
    </td>
    <td>
      <a rel="noreferrer" target="_blank" href={fileRecord.download}>
        <Icon i={ICON.DOWNLOAD_FILE} className="pr-2" />
        Download
      </a>
    </td>
  </tr>
);
