/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { valuesToOptions } from '../../../utils/helpers';


// TODO: ListSelect isn't a nice name as it is only used in column filter
// TODO: Label Highlighter
// TODO: valueKey / labelKey passed into props instead of hard coding an assumed id/name field
const ListSelect = ({ fieldName, options, onSelectChange, values, includeBlankOption, allowMultiSelect }) => {
  const [internalOptions, setInternalOptions] = useState(options);

  const isFilteringOnNull = values.includes(null);

  // API cannot handle filtering on null + other values simultaneously
  //  -> make sure the user can't filter null + other values
  if (values instanceof Array && values.length >= 1) {
    // cannot start using null filter
    if (!isFilteringOnNull && allowMultiSelect) includeBlankOption = false;
    // filtering on null + something else? -> remove the null
    if (isFilteringOnNull && values.length >= 2) setInternalOptions(internalOptions.filter((option) => option.id !== null));
  }

  /**
   * @description
   * Add the "blank" option to the list of available options (if required)
   */
  useEffect(() => {
    let setOptions = [...options];
    if (includeBlankOption) setOptions = [...setOptions, { id: null, name: '(Blanks)' }];
    if (!allowMultiSelect) setOptions = [...setOptions, { id: '*', name: '(All)' }];
    setInternalOptions(setOptions);
  }, [includeBlankOption, allowMultiSelect, options]);


  /**
   * @description
   * Fired when the resource is changed
   *
   * @param {Object} selectedOptions
   */
  const handleChange = (selectedOptions) => {
    if (selectedOptions.id && selectedOptions.id === '*') {
      selectedOptions = null;
    }

    // if multi select is turned off, wrap the results
    if (!allowMultiSelect && selectedOptions) selectedOptions = [selectedOptions];

    // use a blank array if there's nothing there
    selectedOptions = selectedOptions || [];

    // remove null from filter if filtering multiple values
    if (selectedOptions.length >= 2) selectedOptions = selectedOptions.filter((option) => option.id !== null);
    onSelectChange(selectedOptions, true);
  };

  // @todo filtering doesn't work when in single mnode
  const value = allowMultiSelect ? valuesToOptions(values, internalOptions) : valuesToOptions(values, internalOptions)[0];

  // Return <ListSelect /> (non-async)
  return (
    <Select
      isMulti={allowMultiSelect ? true : undefined}
      className="Select no-border"
      classNamePrefix="Select"
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name}
      onChange={handleChange}
      options={internalOptions}
      name={`portal-${fieldName}-select`}
      value={value}
      menuPortalTarget={document.getElementById('portal_container')}
      menuPlacement="auto"
    />
  );
};

ListSelect.defaultProps = {
  includeBlankOption: false,
  allowMultiSelect: true,
};

ListSelect.propTypes = {
  onSelectChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.string, // filter values can be null -> trying to filter for blanks
  ).isRequired,
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  includeBlankOption: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  allowMultiSelect: PropTypes.bool,
};

export default ListSelect;
