import React, { useState, useRef, useContext, useEffect } from 'react';
import { Card, CardHeader, Button } from 'reactstrap';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import rollingSvg from '../../../images/Rolling-1s-22px.svg';
import { APIContext } from '../../providers/api-provider';

export const TsheetsClassCosting = () => {
  const [loading, setLoading] = useState(true);
  const [classList, setClassList] = useState(null);
  const [error, setError] = useState(null);

  const loadCostingsAbortController = useRef(null);

  const { apiFetch } = useContext(APIContext);

  const loadCostings = () => {
    if (loadCostingsAbortController.current) {
      loadCostingsAbortController.current.abort();
    }
    loadCostingsAbortController.current = apiAborter();

    setLoading(true);
    setError(null);

    apiFetch(
      '/tsheets/syncclassitems',
      {
        name: 'TsheetsClassCosting:loadCostings',
        signal: loadCostingsAbortController.current.signal,
      },
    ).then((response) => {
      if (response.success) {
        loadCostingsAbortController.current = null;
        setLoading(false);
        setClassList(response.body);
      } else if (!response.aborted) {
        loadCostingsAbortController.current = null;
        setLoading(false);
        setError(response.error ?? 'Unable to Load Data');
        setClassList(null);
      }
    });
  };

  useEffect(() => {
    loadCostings();

    return () => {
      if (loadCostingsAbortController.current) {
        loadCostingsAbortController.current.abort();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
      <Card>
        <CardHeader className="bg-danger text-white" tag="h4">
          TSheets Labour Class Costings
          <Button
            size="sm"
            onClick={() => loadCostings()}
            style={{ position: 'absolute', right: '8px', top: '8px' }}
          >
            Resync
          </Button>
        </CardHeader>
        <div className="table-container" style={{ maxHeight: '490px', overflowY: 'auto' }}>
          <table className="table table-striped">
            <thead />
            <tbody>
              {loading && (
                <tr>
                  <td colSpan="2">
                    Loading Classes... &nbsp;
                    <img src={rollingSvg} alt="Loading Classes..." />
                  </td>
                </tr>
              )}
              {!!error && (
                <tr>
                  <td colSpan="2">
                    <h3 className="text-danger">Loading Error: </h3>
                    {error}
                  </td>
                </tr>
              )}
              {Array.isArray(classList) &&
                classList.map((classRecord) => (
                  <tr key={classRecord.class_name}>
                    <td>{classRecord.class_name}</td>
                    <td className="text-right">{classRecord.estimated_hourly_cost}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};
