import { A_FILTER_OPERATION, FILTER_OPERATION } from './filter-operation.const';

/**
 * Column Filter Query String Abbreviations are a simple map
 * of shorter strings to use when translating filter operations
 * for use in a url or query string.
 */
export const FILTER_OPERATION_ABBREVIATIONS: {
  operation: A_FILTER_OPERATION,
  abbreviation: string,
}[] = [
  {
    operation: FILTER_OPERATION.EQUALS,
    abbreviation: 'eq',
  },
  {
    operation: FILTER_OPERATION.NOT,
    abbreviation: 'n',
  },
  {
    operation: FILTER_OPERATION.CONTAINS,
    abbreviation: 'ct',
  },
  {
    operation: FILTER_OPERATION.GREATER_THAN,
    abbreviation: 'gt',
  },
  {
    operation: FILTER_OPERATION.LESS_THAN,
    abbreviation: 'lt',
  },
  {
    operation: FILTER_OPERATION.BETWEEN,
    abbreviation: 'bt',
  },
  {
    operation: FILTER_OPERATION.IN,
    abbreviation: 'in',
  },
  {
    operation: FILTER_OPERATION.NOT_IN,
    abbreviation: 'ni',
  },
  {
    operation: FILTER_OPERATION.IS_NULL,
    abbreviation: 'b',
  },
  {
    operation: FILTER_OPERATION.NOT_NULL,
    abbreviation: 'nb',
  },
  {
    operation: FILTER_OPERATION.STARTS_WITH,
    abbreviation: 'sw',
  },
  {
    operation: FILTER_OPERATION.ENDS_WITH,
    abbreviation: 'ew',
  },
];


/**
 * Get the Abbreviation of a Filter Operation
 */
export const filterOperationToAbbrev = (filterOperation: A_FILTER_OPERATION): undefined | string => {
  const found = FILTER_OPERATION_ABBREVIATIONS.find((map) => map.operation === filterOperation);
  if (!found) return undefined;
  return found.abbreviation;
};


/**
 * Get the Filter Operation for an abbreviation
 */
export const abbrevToFilterOperation = (abbrev: string): undefined | A_FILTER_OPERATION => {
  const found = FILTER_OPERATION_ABBREVIATIONS.find((map) => map.abbreviation === abbrev);
  if (!found) return undefined;
  return found.operation;
};
