import React from 'react';
import { NumericInput, NumericInputProps } from './numeric-input';

export type LeaveDaysInputProps = NumericInputProps;

export const LeaveDaysInput: React.FC<LeaveDaysInputProps> = (props) => {
  const {
    value = 0,
  } = props;

  return (
    <NumericInput
      {...props}
      fractionDigits={1}
      value={(value ?? '').toString()}
      suffix=" days"
    />
  );
};
