import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Container, Col, Row, Card, CardBody, CardHeader,
} from 'reactstrap';
import moment from 'moment';

import ProgressBar from '../layout-helpers/progress-bar';
import PageHeader from '../app-layout/page-header';
import Icon from '../layout-helpers/icon';
import { UserAvatar } from './user-avatar';
import FriendlyFormMessage from '../layout-helpers/friendly-form-message';
import { documentTitle } from '../../utils/helpers';
import { useHistory } from '../router/history';
import { connectToAPIProvider } from '../providers/api-provider';
import API_PROVIDER_PROP_TYPES from '../../prop-types/api-provider-prop-types';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { EmailFooter } from './email-footer';
import { InfoTooltip } from '../info-tooltip';
import { ICON } from '../../constants/icon.const';

// Initial state for Profile
const initialState = {
  userId: null,
  profileData: null,
  isBusy: true,
  hasLoaded: false,
  alertColor: null,
  message: null,
  capabilityData: [],
  showAddCapability: false,
  showAddTraining: false,
  newCapability: {},
  newTraining: {},
  htmlSigCopied: false,
  htmlSigCopyError: false,
  textSigCopied: false,
  textSigCopyError: false,
};

/**
 * <Profile />
 * Detailed user profile
 */
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.abortController = null;
    this.htmlSigRef = React.createRef();
    this.textSigRef = React.createRef();

    this.htmlSigCopiedTimeout = null;
    this.textSigCopiedTimeout = null;
  }

  componentDidMount = () => {
    // Get userId from route
    const { match: { params: { userId } } } = this.props;
    this.setState({ userId }, this.loadProfile);
  };

  shouldComponentUpdate = (nextProps) => {
    const { match: { params: { userId } } } = this.props;
    if (userId !== nextProps.match.params.userId) {
      this.setState({ userId: nextProps.match.params.userId }, this.loadProfile);
    }
    return true;
  };

  loadProfile = async () => {
    const { userId } = this.state;
    if (!userId) {
      return;
    }

    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortController = apiAborter();

    const { apiProvider: { apiFetch } } = this.props;

    const response = await apiFetch(`/user/${userId}?with[]=state`, {
      name: 'Profile::loadProfile',
      signal: this.abortController.signal,
    });

    if (response.success) {
      this.abortController = null;
      const { title: existingTitle } = this.props;

      documentTitle(response.body.data.name || existingTitle || null);
      this.setState({
        profileData: response.body.data,
        isBusy: false,
        hasLoaded: true,
      });
    } else if (!response.aborted) {
      this.abortController = null;
      this.setState({
        isBusy: false,
        hasLoaded: false,
        alertColor: 'danger',
        message: response.error,
      });
    }
  };

  backButtonClick = () => {
    const { history } = this.props;
    useHistory.backOrPush(history, '/hrm/browse-profiles');
  };

  handleCopyHTMLSignatureClick = async () => {
    if (this.htmlSigRef.current) {
      const success = await this.htmlSigRef.current.copyHTMLSignatureToClipboard();
      this.setState({
        htmlSigCopied: success,
        htmlSigCopyError: !success,
      }, () => setTimeout(() => {
        this.setState({
          htmlSigCopied: false,
          htmlSigCopyError: false,
        });
      }, 3000));
    }
  }

  handleCopyTextSignatureClick = async () => {
    if (this.textSigRef.current) {
      const success = await this.textSigRef.current.copyTextSignatureToClipboard();
      this.setState({
        textSigCopied: success,
        textSigCopyError: !success,
      }, () => setTimeout(() => {
        this.setState({
          textSigCopied: false,
          textSigCopyError: false,
        });
      }, 3000));
    }
  }

  /**
   * @inheritdoc
   */
  render() {
    const {
      profileData, isBusy, hasLoaded, message, alertColor, userId, htmlSigCopied, textSigCopied, htmlSigCopyError, textSigCopyError,
    } = this.state;
    const {
      currentUserDetails,
    } = this.props;

    const title = profileData === null ? 'Fetching...' : profileData.name;

    let profileText = '';
    let subtitle = '';
    let contactInfo = '';
    if (hasLoaded) {
      profileText = `<p>${profileData.name} is a staff member at <strong>Ci</strong>`;
      if (profileData.profile_body) {
        profileText = profileData.profile_body;
      }
      const position = profileData.title ? profileData.title : 'Ci Staff';
      subtitle = `${position}, ${profileData.state && (profileData.state.name || '')}`;
      contactInfo = `<p><strong>State:</strong> ${profileData.state && (profileData.state.acronym || '-')} <br />
      <strong>Office ext.</strong> ${profileData.phone_office || '-'} <br />
      <strong>Email:</strong> ${profileData.email || '-'} <br />
      <strong>Mobile:</strong> ${profileData.phone_mobile || '-'} <br />
      <strong>Birthday:</strong> ${profileData.birthday ? moment(profileData.birthday).format('Do MMMM') : '-'}
      </p>`;
    }

    // Returns <Profile />
    return (
      <Container fluid>
        <PageHeader {...this.props} />
        {profileData !== null && (
          <>
            <Row className="justify-content-md-center">
              <Col md="8">
                <Card>
                  <Button
                    size="lg"
                    onClick={this.backButtonClick}
                    className="portal-close"
                    color="default"
                    title="Back"
                  >
                    <Icon i="close" />
                  </Button>
                  <CardBody className="profile">
                    <Col>
                      <Row>
                        <Col md="8">
                          <h1>{title}</h1>
                          <h3>{subtitle}</h3>
                          {/* eslint-disable-next-line react/no-danger */}
                          <div dangerouslySetInnerHTML={{ __html: profileText }} />
                        </Col>
                        <Col md="4">
                          <div className="user-avatar-container">
                            <UserAvatar name={profileData.name} />
                          </div>
                          <h4 className="mt-4">Contact info</h4>
                          {/* eslint-disable-next-line react/no-danger */}
                          <div dangerouslySetInnerHTML={{ __html: contactInfo }} />
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {currentUserDetails && userId && currentUserDetails.id.toString() === userId.toString() && (
              <Row className="justify-content-md-center">
                <Col md="8">
                  <Card>
                    <CardHeader>
                      <div className="split">
                        <div>
                          <span><strong>HTML Email Signature</strong></span>
                          <InfoTooltip
                            buttonIcon={ICON.HELP}
                            title="HTML Email Signature"
                          >
                            <p>Copy and paste this signature content into your desktop based email clients.</p>
                            <p>
                              This applies to
                              <ul>
                                <li>Outlook for Windows</li>
                                <li>Outlook for Mac</li>
                                <li>Outlook in your Browser</li>
                              </ul>
                            </p>
                            <p>
                              <span>For more information refer to the </span>
                              <a
                                target="_blank"
                                href="https://wiki.ciportal.net/books/ci-portal-documentation/page/resetting-your-email-footer-via-portal#bkmrk-pretty-version-with-"
                                rel="noreferrer"
                              >
                                WiKi guide on how to update your email signature
                              </a>
                              <span>.</span>
                            </p>
                          </InfoTooltip>
                        </div>
                        <Button
                          onClick={this.handleCopyHTMLSignatureClick}
                          // eslint-disable-next-line no-nested-ternary
                          color={htmlSigCopied ? 'success' : (htmlSigCopyError ? 'danger' : undefined)}
                        >
                          {htmlSigCopied && (
                            <>
                              <Icon i={ICON.CHECK} />
                              <span> Copied</span>
                            </>
                          )}
                          {htmlSigCopyError && (
                            <>
                              <Icon i={ICON.ERROR} />
                              <span> Error</span>
                            </>
                          )}
                          {!htmlSigCopied && !htmlSigCopyError && (
                            <>
                              <Icon i={ICON.COPY} />
                              <span> Copy</span>
                            </>
                          )}
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <EmailFooter
                        ref={this.htmlSigRef}
                        profileData={profileData}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col md="8">
                  <Card>
                    <CardHeader>
                      <div className="split">
                        <div>
                          <span><strong>Mobile / Text Email Signature</strong></span>
                          <InfoTooltip
                            buttonIcon={ICON.HELP}
                            title="Mobile / Text Email Signature"
                          >
                            <p>Copy and paste this signature content into your mobile based email clients.</p>
                            <p>
                              This applies to
                              <ul>
                                <li>Outlook for iOS</li>
                                <li>Outlook for Android</li>
                                <li>Generic Mobile Email Clients</li>
                              </ul>
                            </p>
                            <p>
                              <span>For more information refer to the </span>
                              <a
                                target="_blank"
                                href="https://wiki.ciportal.net/books/ci-portal-documentation/page/resetting-your-email-footer-via-portal#bkmrk-text-only-version-%28m"
                                rel="noreferrer"
                              >
                                WiKi guide on how to update your email signature
                              </a>
                              <span>.</span>
                            </p>
                          </InfoTooltip>
                        </div>
                        <Button
                          onClick={this.handleCopyTextSignatureClick}
                          // eslint-disable-next-line no-nested-ternary
                          color={textSigCopied ? 'success' : (textSigCopyError ? 'danger' : undefined)}
                        >
                          {textSigCopied && (
                            <>
                              <Icon i={ICON.CHECK} />
                              <span> Copied</span>
                            </>
                          )}
                          {textSigCopyError && (
                            <>
                              <Icon i={ICON.ERROR} />
                              <span> Error</span>
                            </>
                          )}
                          {!textSigCopied && !textSigCopyError && (
                            <>
                              <Icon i={ICON.COPY} />
                              <span> Copy</span>
                            </>
                          )}
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <EmailFooter
                        ref={this.textSigRef}
                        textMode
                        profileData={profileData}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </>
        )}
        <ProgressBar complete={!isBusy} />
        {message && (
          <div className="portal-form">
            <FriendlyFormMessage
              formMessage={message}
              alertColor={alertColor}
              errors={{}}
              showList
              isOpen={!!message}
              useSimpleDefault
            />
          </div>
        )}
      </Container>
    );
  }
}

Profile.propTypes = {
  title: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
  currentUserDetails: PropTypes.shape(),
};

Profile.defaultProps = {
  title: 'User Profile',
  currentUserDetails: null,
};

export default connectToAPIProvider(Profile);
