import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PageAlert = (props) => {
  const { children, className, color } = props;
  return (
    <div className={classNames('alert page-alert', `alert-${color}`, className)}>
      {children}
    </div>
  );
};

PageAlert.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
};

PageAlert.defaultProps = {
  children: null,
  className: null,
  color: 'primary',
};


export default PageAlert;
