import React, { useState } from 'react';
import { Card, Container, CardBody, Row, Col, Label, Button } from 'reactstrap';
import classNames from 'classnames';

import Icon from '../layout-helpers/icon';
import { LoginPageHeader } from './login-page-header';
import FriendlyFormMessage from '../layout-helpers/friendly-form-message';
import { IChangePasswordResult } from '../../types/auth/change-password-result.interface';
import { FormFieldErrors } from '../form-input/form-field-errors';
import { PasswordInput } from '../form-input/password-input';

interface ChangePasswordPageProps {
  isBusy: boolean;
  onSubmit: (password: string, confirmPassword: string) => void;
  onCancel: () => void;
  formResult: undefined | IChangePasswordResult;
}

export const ChangePasswordPage: React.FC<ChangePasswordPageProps> = ({
  isBusy,
  onSubmit,
  onCancel,
  formResult,
}) => {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');


  /**
   * Fired when the user submits the form in some way
   */
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(password, confirmPassword);
  };


  /**
   * Fired when the user cancels the Change Password operation and
   * returns to the login page
   */
  const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    onCancel();
  };


  return (
    <Container className="portal-login-form-container">
      <Card className="portal-login-form-card">
        <CardBody>
          <Row>
            <Col md="5" />
            <Col md="7" className="portal-login-form right-side">
              <LoginPageHeader
                title="Change Password"
                subTitle="Please provide and confirm your new password"
              />

              {/* Login Form */}
              <Card>
                <CardBody>
                  <form className="vertical-layout" onSubmit={handleFormSubmit}>
                    {/* New Password Field */}
                    <div
                      className={classNames('form-group', {
                        'has-danger': !!formResult?.fieldErrors?.password,
                      })}
                    >
                      <Label htmlFor="password">New Password</Label>
                      <PasswordInput
                        id="password"
                        name="password"
                        onChange={({ newValue }) => {
                          setPassword(newValue);
                        }}
                        disabled={isBusy}
                        value={password}
                        hasError={!!formResult?.fieldErrors?.password}
                        placeholder="New Password"
                      />
                      {!!formResult?.fieldErrors?.password && (
                        <div className="form-control-feedback">
                          <FormFieldErrors errors={formResult.fieldErrors.password} />
                        </div>
                      )}
                      <small className="form-text text-muted">Enter your new password</small>
                    </div>

                    {/* New Password Field */}
                    <div
                      className={classNames('form-group', {
                        'has-danger': !!formResult?.fieldErrors?.confirmPassword,
                      })}
                    >
                      <Label htmlFor="confirmPassword">Confirm Password</Label>
                      <PasswordInput
                        id="confirmPassword"
                        name="confirmPassword"
                        onChange={({ newValue }) => {
                          setConfirmPassword(newValue);
                        }}
                        disabled={isBusy}
                        value={confirmPassword}
                        hasError={!!formResult?.fieldErrors?.confirmPassword}
                        placeholder="Confirm Password"
                      />
                      {!!formResult?.fieldErrors?.confirmPassword && (
                        <div className="form-control-feedback">
                          <FormFieldErrors errors={formResult.fieldErrors.confirmPassword} />
                        </div>
                      )}
                      <small className="form-text text-muted">Enter your new password again</small>
                    </div>

                    <Button
                      disabled={isBusy}
                      type="submit"
                      color="primary"
                      className="pull-right"
                    >
                      <span>Change Password</span>
                      {isBusy && <Icon i="spinner" size="lg" spaceLeft />}
                    </Button>
                    <Button
                      onClick={handleCancelClick}
                      disabled={isBusy}
                      color="secondary"
                      type="button"
                    >
                      Cancel
                    </Button>
                  </form>
                </CardBody>
              </Card>

              {formResult && (formResult.succeeded || formResult.failed) && (
                <FriendlyFormMessage
                  formMessage={formResult.message}
                  hasSuccess={formResult.succeeded}
                  hasErrors={formResult.failed}
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};
