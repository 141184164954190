import { defaultTableSettings } from './default-table-settings';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { COLUMN_FORMAT } from '../constants/column-format.const';

/**
 * Table Definition for Security Categories Table
 *
 * @todo import securityCategoriesTableInitialSettings in /reducers/portal-datatable/settings-reducer.js
 *
 */
const securityRoleTableInitialSettings = {
  ...defaultTableSettings,
  // where to focus on form render
  focusField: 'name',
  baseRoute: '/role',
  rowKeyField: 'name',
  baseQueryString: 'with[]=createdBy:id,name,initials',

  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'name',
        'description',
      ],
    },
  ],
  /**
   * Definition of Columns for this table
   */
  columns: [
    {
      name: 'name',
      title: 'Name',
      description: 'Security Role name',
      format: COLUMN_FORMAT.TEXT,
      visible: true,
      order: 1,
    },
    {
      name: 'description',
      title: 'Description',
      description: 'Long Role description',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      order: 2,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Security Role Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'permissions',
      description: 'Security Role Assigned Permissions',
      title: 'Permissions',
      widgetDefinition: WIDGET_DEFINITION.SECURITY_ROLE_PERMISSIONS,
      apiRoute: '/permission',
    },
    {
      name: 'users',
      description: 'Security Role Assigned Users',
      title: 'Users',
      widgetDefinition: WIDGET_DEFINITION.SECURITY_ROLE_USER,
      apiRoute: '/user',
    },
  ],
};

export default securityRoleTableInitialSettings;
