import React from 'react';
import { Card, CardBody } from 'reactstrap';

import { IDtoolsProjectCacheRecord } from '../../types/integration/dtools/dtools-project-cache.record.interface';
import { WidgetProps } from '../../types/widget.props';

import { DToolsInfoPanel } from '../dtools-information/d-tools-info-panel';


export type DtoolsLiveInfoWidget = Omit<WidgetProps, 'rowData'> & {
  rowData: IDtoolsProjectCacheRecord,
}

/**
 * This widget shows the checklist items which have been left out of the main form view.
 *
 * @todo convince the user form to do a patch instead of a PUT
 */
export const DtoolsLiveInfoWidget:React.FC<DtoolsLiveInfoWidget> = (props) => {
  const {
    rowData,
  } = props;

  return (
    <Card className="dtoolslink-info-panel-container">
      <CardBody>
        <DToolsInfoPanel dtoolsId={rowData.dtools_project_guid} projectData={{ id: rowData.project_id }} showItems={false} />
      </CardBody>
    </Card>
  );
};
