import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';

import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { IDataTableRecord } from '../types/portal-data-table/portal-data-table-record.interface';
import { COLUMN_FORMAT } from '../constants/column-format.const';

export const ProjectDtoolsChangeOrderWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Change Order',
  showAddBtn: false,
  widgetItemPossibleActions: [],
  fields: [{
    name: 'number',
    title: 'Number',
    description: 'Number',
    format: COLUMN_FORMAT.ID_LINK,
    linkId: 'id',
    linkRoute: (
      row: IDataTableRecord,
    ): string => `/crm/ordering/project-eta-items?pdt=v=all;ps=15;p=1;cf=change_order_number:eq+${row.number},project_id:eq+${row.project_id_string};_=1`,
    visible: true,
  }, {
    name: 'name',
    title: 'Name',
    description: 'Name',
    format: COLUMN_FORMAT.ID_LINK,
    linkId: 'id',
    linkRoute: (
      row: IDataTableRecord,
    ): string => `/crm/ordering/project-eta-items?pdt=v=all;ps=15;p=1;cf=change_order_number:eq+${row.number},project_id:eq+${row.project_id_string};_=1`,
    visible: true,
  }, {
    name: 'status',
    title: 'Status',
    description: 'Status',
    format: COLUMN_FORMAT.SHORT_TEXT,
  }, {
    name: 'type',
    title: 'Type',
    description: 'Type',
    format: COLUMN_FORMAT.SHORT_TEXT,
  }, {
    name: 'price_ex',
    title: 'Price Ex',
    description: 'Price Ex',
    format: COLUMN_FORMAT.CURRENCY,
    allowNegative: true,
    // hide totals for now as they are too confusing
    // showTotals: true,
    // totalFilter: (field: {[key: string]: unknown, status: string}) : boolean => field.status === 'Approved',
  }, {
    name: 'price',
    title: 'Price Inc',
    description: 'Price Inc',
    format: COLUMN_FORMAT.CURRENCY,
    allowNegative: true,
    // hide totals for now as they are too confusing
    // showTotals: true,
    // totalFilter: (field: {[key: string]: unknown, status: string}) : boolean => field.status === 'Approved',
  }],
};
