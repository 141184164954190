export const INVOICE_REQUEST_DETAIL_TYPE: Record<string, number> = {
  TEXT_ONLY: 1,
  ITEM: 2,
} as const;
export type INVOICE_REQUEST_DETAIL_TYPE = typeof INVOICE_REQUEST_DETAIL_TYPE;
export type AN_INVOICE_REQUEST_DETAIL_TYPE = INVOICE_REQUEST_DETAIL_TYPE[keyof INVOICE_REQUEST_DETAIL_TYPE];

export const INVOICE_REQUEST_DETAIL_TYPE_NAMES: {[K in AN_INVOICE_REQUEST_DETAIL_TYPE]: string } = {
  [INVOICE_REQUEST_DETAIL_TYPE.TEXT_ONLY]: 'Text Only',
  [INVOICE_REQUEST_DETAIL_TYPE.ITEM]: 'Item',
} as const;
