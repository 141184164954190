import React, { useState } from 'react';
import moment from 'moment';
import { UserAvatar } from '../../user-profiles/user-avatar';

import { FormRendererProps } from '../../../types/poly-form/form-renderer.props';
import { ITrackingRecord } from '../../../types/tracking.record.interface';
import { IUserRecord } from '../../../types/user/user.record.interface';
import { TrackingFormRendererItemDetail } from './tracking-form-renderer-item-detail';
import Icon from '../../layout-helpers/icon';
import { ICON } from '../../../constants/icon.const';
import StatusBadge from '../../data-format/status-badge';

export type TrackingFormRendererProps = FormRendererProps & {
  formData: ITrackingRecord,
}


/**
 * Retrieve the additional detail data for the tracking record
 * NOTE: this is a read only form
 */
const getRelatedTrackingDetail = (trackingRecord: ITrackingRecord):{
  hasRelation: boolean,
  relationJsonData: string,
  relationStory: string,
} => {
  const result = {
    hasRelation: false,
    relationJsonData: '',
    relationStory: '',
  };

  // TODO: This is not a great way to accommodate the various tracking types
  const relationArray = [
    'project_commission_payment_tracking',
    'project_forecast_tracking',
    'project_invoice_tracking',
    'project_resource_tracking',
    'project_order_tracking',
    'project_company_tracking',
    'project_note_tracking',
    'internal_project_item_tracking',
    'internal_project_stakeholder_tracking',
    'internal_project_note_tracking',
    'leave_application_note_tracking',
    'leave_block_tracking',
    'expense_claim_note_tracking',
    'expense_claim_item_tracking',
    'commission_structure_bracket_tracking',
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tr: Record<string, any> = trackingRecord;

  relationArray.forEach((trackingRelation) => {
    // Does the relation exist on the tracking record
    if (tr[trackingRelation]) {
      if (tr[trackingRelation].data) {
        // trackingRecord follows the short data format
        result.hasRelation = true;
        result.relationJsonData = tr[trackingRelation].data;
        result.relationStory = tr[trackingRelation].story;
      }
      if (tr[trackingRelation].length > 0) {
        if (tr[trackingRelation][0].data) {
          if (!Array.isArray(tr[trackingRelation][0].data) || tr[trackingRelation][0].data.length > 0) {
            // trackingRecords follows the long data format
            result.hasRelation = true;
            result.relationJsonData = tr[trackingRelation][0].data;
            result.relationStory = tr[trackingRelation][0].story;
          }
        }
      }
    }
  });

  return result;
};


/**
 * TrackingFormRenderer is designed to be used as part of the collection widget
 */
export const TrackingFormRenderer:React.FC<TrackingFormRendererProps> = (props) => {
  const {
    formData,
    statusMap,
  } = props;

  const { hasRelation, relationJsonData, relationStory } = getRelatedTrackingDetail(formData);
  const createdBy: Partial<IUserRecord> | null = formData.created_by ?? null;

  let mainChangeData;
  let relationChangeData;
  try {
    mainChangeData = JSON.parse(formData.data);
    relationChangeData = JSON.parse(relationJsonData);
  }
  catch (ex) {
    // the data didn't parse... awkward!
  }

  // the things we do to give users unique coloured blobs!
  const hueShift = ((createdBy?.id ?? 1) * 31 + 170) % 255;
  const satShift = 60 - (((createdBy?.id ?? 1) * 11) % 40);
  const lumShift = 50 - (((createdBy?.id ?? 1) * 17) % 35);
  const hsl = `HSL(${hueShift}, ${satShift}%, ${lumShift}%)`;

  const { id } = formData;
  const [showInfo, setShowInfo] = useState(false);
  const userName = createdBy?.name || 'Unknown User';

  return (
    <div className="tracking-event" key={id}>
      <div className="user-avatar-container">
        <UserAvatar
          name={userName}
          alt={userName}
          style={{
            backgroundColor: hsl,
            backgroundSize: '80px 80px',
          }}
        />
      </div>
      <div className="event-info">
        <div>
          <span className="user-name" style={{ color: hsl }}>{userName}</span>
          <span className="story">{formData.story}</span>

          {((mainChangeData !== null && (!Array.isArray(mainChangeData) || mainChangeData.length > 0)) || hasRelation) && (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
            <span
              onClick={(e) => {
                e.stopPropagation();
                setShowInfo(!showInfo);
              }}
              style={{ cursor: 'pointer' }}
            >
              <Icon i={ICON.INFO} spaceLeft className={showInfo ? 'text-info' : 'text-muted'} />
            </span>
          )}
        </div>

        <div className="time-to-event">
          {moment(formData.user_audit.created_at).fromNow()}
          &nbsp; - &nbsp;
          {moment(formData.user_audit.created_at).format('D/M/YY h:mm a')}
        </div>

        {('_status_name' in formData) && (mainChangeData) && ('status_id' in mainChangeData) && statusMap && (
          <div className="status-progression">
            {mainChangeData.status_id.old && (
              <StatusBadge status={statusMap[mainChangeData.status_id.old]} />
            )}
            {mainChangeData.status_id.old && mainChangeData.status_id.new && (
              <span> → </span>
            )}
            {mainChangeData.status_id.new && (
              <StatusBadge status={statusMap[mainChangeData.status_id.new]} />
            )}
          </div>
        )}

        {showInfo && (
          <>
            {!Array.isArray(mainChangeData) && (
              <TrackingFormRendererItemDetail changeData={mainChangeData} />
            )}
            {hasRelation && relationChangeData && (
              <div style={{ marginTop: '1em' }}>
                {!Array.isArray(mainChangeData) && (
                  <>
                    <span className="user-name" style={{ color: hsl }}>{userName}</span>
                    <span className="relation-story">{relationStory}</span>
                  </>
                )}
                <TrackingFormRendererItemDetail changeData={relationChangeData} />
              </div>
            )}
          </>
        )}

      </div>
    </div>
  );
};
