import { COLUMN_FORMAT } from '../constants/column-format.const';
import { ProjectInvoicesWidget } from '../components/widgets/project-invoices.widget';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { mapSelectInputOptions } from '../helpers/map-select.helper';
import { PROJECT_INVOICE_TYPE_NAME_MAP } from '../constants/project-invoice-type.const';

export const ProjectInvoicesWidgetDefinition: ICollectionWidgetDefinition = {
  component: ProjectInvoicesWidget,
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Invoice',
  fields: [
    {
      name: 'project_id',
      visible: false,
      isParentId: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'invoice_number',
      title: 'Invoice Number',
      visible: true,
      format: COLUMN_FORMAT.SHORT_TEXT,
    },
    {
      name: 'amount',
      title: 'Amount',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
      allowNegative: true,
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    },
    {
      name: 'sent_date',
      visible: true,
      title: 'Sent Date',
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      format: COLUMN_FORMAT.LONG_DATE,
      description: 'Invoice Sent Date',
    },
    {
      name: 'type',
      visible: true,
      title: 'Invoice type',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'type',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: mapSelectInputOptions(PROJECT_INVOICE_TYPE_NAME_MAP),
      formSaveField: 'type_id',
    },
    {
      name: 'comment',
      title: 'Comment',
      visible: true,
      format: COLUMN_FORMAT.LONG_TEXT,
    },
  ],
};
