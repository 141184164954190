import React from 'react';
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';

import App from '../app';
import CommsRoutes from './comms-routes';
import CrmRoutes from './crm-routes';
import HrRoutes from './hrm-routes';
import FourOhFour from '../error-pages/four-oh-four';
import SalesToolkitRoutes from './sales-toolkit-routes';
import SettingsRoutes from './settings-routes';
import DashboardRoutes from './dashboard-routes';
import FinanceRoutes from './finance-routes';
import SplashPage from '../splash-page/splash-page';
import HistoryTracker from './history';
import InternalProjectRoutes from './internal-project-routes';
import ServiceRoutes from './service-routes';
import { MyobPoQuickSearchPage } from '../view-pages/myob-po-quicksearch-page';

const Router = () => (
  <BrowserRouter>
    <Route render={(baseProps) => (
      <HistoryTracker {...baseProps}>
        <App>
          <Switch>
            {/* If the user hits the Reset Password or Change Password routes when authenticated - Redirect them to home */}
            <Redirect exact from="/password/reset" to="/" />
            <Redirect exact from="/password/change/*" to="/" />
            {/* root redirect */}
            <Route exact path="/" render={(props) => <SplashPage {...props} />} />
            {/* Comms */}
            <Route path="/comms" component={CommsRoutes} />
            {/* Project Routes */}
            <Route path="/crm" component={CrmRoutes} />
            {/* Service and Maintenance Routes */}
            <Route path="/service-and-support" component={ServiceRoutes} />
            {/* Dashboards */}
            <Route path="/dashboards" component={DashboardRoutes} />
            {/* HR Portal Routes */}
            <Route path="/hrm" component={HrRoutes} />
            {/* Finance Routes */}
            <Route path="/finance" component={FinanceRoutes} />
            {/* Hidden Sales Toolkit Routes */}
            <Route path="/sales-toolkit" component={SalesToolkitRoutes} />
            {/* Internal Project Routes */}
            <Route path="/internal-projects" component={InternalProjectRoutes} />
            {/* System Admin Routes */}
            <Route path="/settings" component={SettingsRoutes} />
            {/* Blank reload route. */}
            {/* See https://github.com/ReactTraining/react-router/issues/1982#issuecomment-314167564 */}
            <Route path="/po-search" component={MyobPoQuickSearchPage} />
            <Route path="/reload" component={null} key="empty" />
            <Route path="*" exact component={FourOhFour} />
          </Switch>
        </App>
      </HistoryTracker>
    )}
    />
  </BrowserRouter>
);

export default Router;
