
export const INVOICE_REQUEST_NOTE_TYPE: Record<string, number> = {
  GENERAL_NOTE: 1,
  BILLING_ADDRESS: 2,
  REJECTION_REASON: 3,
} as const;
export type INVOICE_REQUEST_NOTE_TYPE = typeof INVOICE_REQUEST_NOTE_TYPE;
export type AN_INVOICE_REQUEST_NOTE_TYPE = INVOICE_REQUEST_NOTE_TYPE[keyof INVOICE_REQUEST_NOTE_TYPE];

export const INVOICE_REQUEST_NOTE_TYPE_NAMES: { [K in AN_INVOICE_REQUEST_NOTE_TYPE]: string } = {
  [INVOICE_REQUEST_NOTE_TYPE.BILLING_ADDRESS]: 'Billing Address',
  [INVOICE_REQUEST_NOTE_TYPE.REJECTION_REASON]: 'Rejection Reason',
  [INVOICE_REQUEST_NOTE_TYPE.GENERAL_NOTE]: 'General',
} as const;
