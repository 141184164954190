import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { History, Location } from 'history';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Label, Row, Button } from 'reactstrap';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { IDtoolsEtaSummaryReportData } from '../../types/integration/dtools/dtools-eta-summary-report-data.interface';
import { loadLocalPrefs, saveLocalPref } from '../../utils/localStorage';
import PageHeader from '../app-layout/page-header';
import { AtRiskItemsTable } from '../dtools-information/eta-reporting/at-risk-items-table';
import { EtaAgeByDateTable } from '../dtools-information/eta-reporting/eta-age-by-date-table';
import { OrderStatusSummaryTable } from '../dtools-information/eta-reporting/order-status-summary-table';
import { OutstandingItemsByVendorTable } from '../dtools-information/eta-reporting/outstanding-items-by-vendor-table';
import { OverdueItemsTable } from '../dtools-information/eta-reporting/overdue-items-table';
import Icon from '../layout-helpers/icon';
import { toParams } from '../../utils/helpers';
import { APIContext } from '../providers/api-provider';
import { CurrentUserContext } from '../providers/current-user-provider';
import { UserPicker } from '../form-input/user-picker';
import { FormFieldChangeProps } from '../../types/poly-form/form-field-change-props';
import { IUserRecord } from '../../types/user/user.record.interface';

interface IProjectItemEtaOverviewProps {
  title: string,
  history: History,
  location: Location,
}

interface IDataLoadResult {
  working: boolean,
  error: boolean
  data?: null | IDtoolsEtaSummaryReportData,
}

type UserOption = Pick<IUserRecord, 'id' | 'name'>;

interface ILocalPrefs {
  etaOverviewDashViewProjectManager?: UserOption | null,
}


/**
 * Project ETA Item Overview
 *
 * @param {IProjectItemEtaOverviewProps} props
 * @param {string}  props.title    page title
 * @param {History} props.history  the route history
 * @param {Location} props.location  the route history
 *
 * @returns {ProjectItemEtaOverview}
 */
export const ProjectItemEtaOverview: React.FC<IProjectItemEtaOverviewProps> = ({ title, history, location }) => {
  const [dataLoadResult, setDataLoadResult] = useState<IDataLoadResult>({
    working: false,
    error: false,
    data: null,
  });

  // set up view user from URL, LocalPrefs, or current user in that order of priority
  const { userDetails } = useContext(CurrentUserContext);
  const { etaOverviewDashViewProjectManager } : ILocalPrefs = loadLocalPrefs();
  const objSearchParams = toParams(location.search);
  let userFromUrl: undefined | UserOption;
  if (objSearchParams.id && objSearchParams.name && (!Number.isNaN(parseInt(objSearchParams.id, 10)))) {
    userFromUrl = {
      id: parseInt(objSearchParams.id, 10),
      name: objSearchParams.name,
    };
  }
  const currentUserSelectDefault: UserOption = (userFromUrl) || { id: userDetails.id, name: userDetails.name };
  if (etaOverviewDashViewProjectManager === undefined) {
    saveLocalPref('etaOverviewDashViewProjectManager', currentUserSelectDefault);
  }
  const viewUser = userFromUrl ?? (etaOverviewDashViewProjectManager === undefined ? currentUserSelectDefault : etaOverviewDashViewProjectManager) ?? null;
  const [selectedPm, setSelectedPm] = useState<UserOption | null>(viewUser);

  // set up data loading
  const { apiFetch } = useContext(APIContext);
  const etaReportAbortController = useRef<AbortController | null>(null);

  /** Load Report Data */
  const loadData = useCallback(async () => {
    if (etaReportAbortController.current) {
      etaReportAbortController.current.abort();
    }
    etaReportAbortController.current = apiAborter();

    setDataLoadResult({
      ...dataLoadResult,
      working: true,
      data: null,
    });

    const filterString = selectedPm ? `?project_manager_id=${selectedPm.id}` : '';

    const response = await apiFetch(
      `/report/project/item-eta-report${filterString}`,
      {
        method: HTTP_METHOD.GET,
        name: 'ProjectCardViewPageListData:load',
        signal: etaReportAbortController?.current?.signal,
      },
    );

    if (response.success) {
      etaReportAbortController.current = null;
      const newProjectData = response.body.data || 0;
      setDataLoadResult({
        working: false,
        error: false,
        data: newProjectData,
      });
    } else if (!response.aborted) {
      etaReportAbortController.current = null;
      setDataLoadResult({
        working: false,
        error: true,
        data: dataLoadResult.data,
      });
    }
  }, [selectedPm, dataLoadResult, setDataLoadResult, apiFetch]);

  useEffect(() => {
    loadData();

    return () => {
      // Kill any outstanding load when the component is un-mounted
      if (etaReportAbortController.current) {
        etaReportAbortController.current.abort();
      }
    };// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPm]);

  const { data, working, error } = dataLoadResult;

  const showForAll = () => {
    setSelectedPm(null);
    saveLocalPref('etaOverviewDashViewProjectManager', null);
  };

  const pmChangeHandler = (field: FormFieldChangeProps<UserOption>) => {
    const selectedOption = field.objectFieldNewValue;
    setSelectedPm(selectedOption ?? null);
    saveLocalPref('etaOverviewDashViewProjectManager', selectedOption);
    const queryString = selectedOption ? `?id=${encodeURIComponent(selectedOption.id ?? '')}&name=${encodeURIComponent(selectedOption.name ?? '')}` : '';
    history.push(`${location.pathname}${queryString}`);
  };

  return (
    <Container fluid className="eta-item-overview">
      <PageHeader
        title={title}
        onRenderActions={() => (
          <Row className="settings form-horizontal">
            <Col>
              <Label className="pt-2" htmlFor="project_manager">Chose a PM: &nbsp;</Label>
            </Col>
            <Col style={{ minWidth: '300px' }}>
              <UserPicker
                id="project_manager_id"
                name="project_manager"
                value={viewUser ?? null}
                formData={{
                  project_manager: viewUser,
                }}
                onChange={pmChangeHandler}
              />
            </Col>
            <Col>
              <Button
                color="primary"
                onClick={showForAll}
                onKeyPress={showForAll}
              >
                View All
              </Button>
            </Col>
          </Row>
        )}
      />
      {data && (
        <Card className="dtools-item-eta-summary-panel">
          <CardHeader>
            <h3>
              National Active Projects ETA status summary for
              {' '}
              {selectedPm ? selectedPm.name : 'all active projects'}
            </h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12">
                <OverdueItemsTable recordData={data.overdue_items} projectManagerId={selectedPm?.id} />
              </Col>
            </Row>
            <p>&nbsp;</p>
            {' '}
            <Row>

              <Col md="12">
                <AtRiskItemsTable recordData={data.risky_items} projectManagerId={selectedPm?.id} />
              </Col>
            </Row>
            <p>&nbsp;</p>
            <Row>
              {data && data.vendor_eta_items && (
                <Col lg="12" xl="8">
                  <OutstandingItemsByVendorTable recordData={data.vendor_eta_items} projectManagerId={selectedPm?.id} />
                </Col>
              )}
            </Row>
            <p>&nbsp;</p>
            <Row>
              {data && data.eta_age_by_date && (
                <Col lg="6">
                  <EtaAgeByDateTable recordData={data.eta_age_by_date} projectManagerId={selectedPm?.id} />
                </Col>
              )}
              {data && data.order_status_summary && (
                <Col lg="6">
                  <OrderStatusSummaryTable recordData={data.order_status_summary} projectManagerId={selectedPm?.id} />
                </Col>
              )}
            </Row>
            <p>&nbsp;</p>
          </CardBody>
        </Card>
      )}
      {working && (
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Icon i="rolling" />
                {' '}
                Loading...

              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {error && (
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                Loading Error, please refresh the page to try again.
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};
