import React, { useCallback, useState } from 'react';

import { ICompanyLocationRecord } from '../../types/company/company-location.record.interface';
import { NewRecordModalProps } from '../../types/modal/new-record-modal.props';

import { useIsDirty } from '../../react-hooks/use-is-dirty.hook';

import { Modal } from './Modal';
import { NewCompanyLocationForm } from '../poly-forms/new-company-location-form';

import { A_COMPANY_LOCATION_TYPE, CompanyLocationTypeIconMap, CompanyLocationTypeNameMap } from '../../constants/company-location-type.const';
import { ICON } from '../../constants/icon.const';

export type NewCompanyLocationModalProps = NewRecordModalProps<ICompanyLocationRecord> & {
  showLocationType?: boolean,
  showParentLocation?: boolean,
};

export const NewCompanyLocationModal: React.FC<NewCompanyLocationModalProps> = (props) => {
  const {
    id,
    closeModal,
    onModalComplete,
    initialData,
    baseRoute,
    apiRoute,
    apiQuery,
    rendererOptions,
  } = props;

  const [isDirty, setDirty] = useIsDirty();

  // @Note, this is not the data passed to the form, but data used by the modal updated BY the form
  const [formData, setFormData] = useState<Partial<ICompanyLocationRecord>>(initialData ?? {});

  /**
   * When the form is updated by the user, grab some of the details to make the UI more relevant
   */
  const handleFormChange = useCallback((record_id: number, newFormData: Partial<ICompanyLocationRecord>) => {
    setDirty();
    setFormData(newFormData);
  }, [setDirty]);


  // Render
  return (
    <Modal
      {...props}
      title={`Create a new ${(formData?.type_id)
        ? CompanyLocationTypeNameMap[formData.type_id as A_COMPANY_LOCATION_TYPE]
        : 'Location'
      }`}
      icon={formData?.type_id
        ? CompanyLocationTypeIconMap[formData.type_id as A_COMPANY_LOCATION_TYPE]
        : ICON.LOCATION}
      allowDismiss
      isDirty={isDirty}
      size="lg"
    >
      <NewCompanyLocationForm
        baseRoute={baseRoute}
        apiRoute={apiRoute}
        apiQuery={apiQuery}
        rendererOptions={rendererOptions}
        onFormComplete={(success, recordId, newRecord) => {
          closeModal(id);
          onModalComplete((success && newRecord) ? { success, newRecord } : { success: false, newRecord: null });
        }}
        onFormChange={handleFormChange}
        initialData={initialData}
      />
    </Modal>
  );
};
