import React, { lazy, Suspense, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import { MyProfile, ProfileCards } from '../user-profiles';
import Profile from '../user-profiles/profile';
import RecordDetailPage, { TAB_REGEX } from '../record-detail-page/record-detail-page';

import { MyLeaveDataTablePage } from '../data-table-pages/my-leave.data-table-page';
import { MyExpenseClaimsDataTablePage } from '../data-table-pages/my-expense-claims.data-table-page';
import { MyAllowancesDataTablePage } from '../data-table-pages/my-allowances.data-table-page';
import { ManageLeaveDataTablePage } from '../data-table-pages/manage-leave.data-table-page';
import { ManageExpenseClaimsDataTablePage } from '../data-table-pages/manage-expense-claims.data-table-page';
import ManageAllowancesDataTablePage from '../data-table-pages/manage-allowances.data-table-page';

import FourOhFour from '../error-pages/four-oh-four';
import AvailableLeave from '../available-leave/available-leave';
import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { CurrentUserContext } from '../providers/current-user-provider';
// import NationalLeaveCalender from '../national-leave-calendar/national-leave-calendar';

// Defer loading the leave calendar to allow webpack to chunk the JS
const NationalLeaveCalender = lazy(() => import('../national-leave-calendar/national-leave-calendar'));

const HrRoutes = () => {
  const { userDetails } = useContext(CurrentUserContext);

  return (
    <Switch>
      <Route
        exact
        path="/hrm/browse-profiles"
        render={(props) => <ProfileCards {...props} currentUserDetails={userDetails} title="Browse Profiles" showBtn />}
      />
      <Route
        exact
        path="/hrm/browse-profiles/:userId"
        render={(props) => <Profile {...props} currentUserDetails={userDetails} title="View profile" />}
      />
      <Route
        exact
        path="/hrm/my-profile"
        render={(props) => <MyProfile {...props} title="My Profile" />}
      />
      <Route
        exact
        path="/hrm/my-leave"
        render={(props) => <MyLeaveDataTablePage {...props} />}
      />
      <Route // view my individual leave application
        exact
        path={`/hrm/my-leave/:id/${TAB_REGEX}`}
        render={(props) => (
          <RecordDetailPage {...props} title="Leave Application" tableIdentifier={TABLE_IDENTIFIER.MY_LEAVE_TABLE} className="my-leave-page">
            <AvailableLeave userId={userDetails.id} datePickerButton />
          </RecordDetailPage>
        )}
      />
      <Route exact path="/hrm/my-expenses" render={(props) => <MyExpenseClaimsDataTablePage {...props} showBtn />} />
      <Route // view my individual expense claim
        exact
        path={`/hrm/my-expenses/:id/${TAB_REGEX}`}
        render={(props) => (
          <RecordDetailPage {...props} title="Expense Claim" tableIdentifier={TABLE_IDENTIFIER.MY_EXPENSES_TABLE} />
        )}
      />
      <Route
        exact
        path="/hrm/my-allowances"
        render={(props) => <MyAllowancesDataTablePage {...props} showBtn />}
      />
      <Route // view my individual expense claim
        exact
        path={`/hrm/my-allowances/:id/${TAB_REGEX}`}
        render={(props) => (
          <RecordDetailPage {...props} title="Allowance Claim" tableIdentifier={TABLE_IDENTIFIER.MY_ALLOWANCES_TABLE} />
        )}
      />
      <Route
        exact
        path="/hrm/my-team/manage-leave"
        render={(props) => <ManageLeaveDataTablePage {...props} title="Your Team's Leave Requests" />}
      />
      <Route // view manage individual leave application
        exact
        path={`/hrm/my-team/manage-leave/:id/${TAB_REGEX}`}
        render={(props) => (
          <RecordDetailPage
            {...props}
            title="Manage Leave Application"
            tableIdentifier={TABLE_IDENTIFIER.MANAGE_LEAVE_TABLE}
          />
        )}
      />
      <Route
        exact
        path="/hrm/my-team/manage-expenses"
        render={(props) => <ManageExpenseClaimsDataTablePage {...props} title="Your Team's Expense Claims" />}
      />
      <Route // view manage individual expense claim
        exact
        path={`/hrm/my-team/manage-expenses/:id/${TAB_REGEX}`}
        render={(props) => (
          <RecordDetailPage
            {...props}
            title="Manage Expense Claims"
            tableIdentifier={TABLE_IDENTIFIER.MANAGE_EXPENSES_TABLE}
          />
        )}
      />
      <Route
        exact
        path="/hrm/my-team/manage-allowances"
        render={(props) => <ManageAllowancesDataTablePage {...props} title="Your Team's Allowances" />}
      />
      <Route // view manage individual expense claim
        exact
        path={`/hrm/my-team/manage-allowances/:id/${TAB_REGEX}`}
        render={(props) => (
          <RecordDetailPage
            {...props}
            title="Manage Allowance Claims"
            tableIdentifier={TABLE_IDENTIFIER.MANAGE_ALLOWANCES_TABLE}
          />
        )}
      />
      <Route
        exact
        path="/hrm/national-leave-calendar"
        render={(props) => (
          <Suspense fallback={(
            <div className="p-5 text-center">
              <Spinner />
            </div>
          )}
          >
            <NationalLeaveCalender title="National Leave Calendar" {...props} />
          </Suspense>
        )}
      />
      <Route exact path="*" component={FourOhFour} />
    </Switch>
  );
};

HrRoutes.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default HrRoutes;
