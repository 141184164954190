
export const CONTRACT_STATUS: Record<string, number> = {
  OPTION: 1,
  NEGOTIATION: 2,
  QUOTED: 3,
  PENDING: 4,
  ACTIVE: 5,
  SUPERSEDED: 6,
  EXPIRED: 7,
  CANCELLED: 8,
  SUSPENDED: 9,
  ARCHIVED: 10,
} as const;
export type CONTRACT_STATUS = typeof CONTRACT_STATUS;
export type AN_CONTRACT_STATUS = CONTRACT_STATUS[keyof CONTRACT_STATUS];

export const CONTRACT_STATUS_NAMES: { [K in AN_CONTRACT_STATUS]: string } = {
  [CONTRACT_STATUS.OPTION]: 'Option',
  [CONTRACT_STATUS.NEGOTIATION]: 'Negotiation',
  [CONTRACT_STATUS.QUOTED]: 'Quoted',
  [CONTRACT_STATUS.PENDING]: 'Pending',
  [CONTRACT_STATUS.ACTIVE]: 'Active',
  [CONTRACT_STATUS.SUPERSEDED]: 'Superseded',
  [CONTRACT_STATUS.EXPIRED]: 'Expired',
  [CONTRACT_STATUS.CANCELLED]: 'Cancelled',
  [CONTRACT_STATUS.SUSPENDED]: 'Suspended',
  [CONTRACT_STATUS.ARCHIVED]: 'Archived',
} as const;
