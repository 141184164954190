import React, { ReactNode, useRef, useState, useEffect } from 'react';
import classNames from 'classnames';

import SplitHandle from '../split-handle/split-handle';
import { useForceUpdate } from '../../react-hooks/use-force-update.hook';

export type SplitViewProps = {
  className?: string,
  initialSplitPercent?: number,
  leftChild: ReactNode,
  rightChild: ReactNode,
};

/**
 * Render out two components side by side with a resizable splitter in between
 */
export const SplitView:React.FC<SplitViewProps> = (props) => {
  const {
    className,
    initialSplitPercent = 50,
    leftChild,
    rightChild,
  } = props;

  const leftContainerRef = useRef<HTMLDivElement>(null);
  const rightContainerRef = useRef<HTMLDivElement>(null);
  const forceUpdate = useForceUpdate();

  // The width of the left scroll container is in percent
  const leftScrollContainerWidth = useRef<number>(initialSplitPercent);

  const [isResizing, setIsResizing] = useState(false);


  /**
   * Fired when the user begins dragging the split handle
   */
  const handleSplitHandleBeginDrag = () => {
    setIsResizing(true);
  };


  /**
   * Fired when the user finishes dragging the split handle
   */
  const handleSplitHandleEndDrag = () => {
    setIsResizing(false);
  };


  /**
   * Fired during the drag of the split handle
   *
   * @param position the current position (in percent) of the drag handle
   */
  const handleSplitHandleDrag = (position: number) => {
    leftScrollContainerWidth.current = position;

    // Just update the scroll container style tag, no need to re-render the whole component
    if (leftContainerRef.current) {
      leftContainerRef.current.style.maxWidth = `${position}%`;
    }
  };

  /**
   * This makes sure the initial render happens twice, after the refs from the
   * divs are assigned. The split handle can't be rendered until the refs from the
   * divs are known.
   */
  useEffect(() => {
    forceUpdate();
  }, [forceUpdate]);

  // Render
  return (
    <div
      className={classNames(
        'split-view',
        className,
        {
          resizing: isResizing,
        },
      )}
    >
      {/* Left Container */}
      <div
        className="split-view-container left"
        ref={leftContainerRef}
        style={{ maxWidth: `${leftScrollContainerWidth.current}%` }}
      >
        {leftChild}
      </div>

      {/* Right Container */}
      <div
        className="split-view-container right"
        ref={rightContainerRef}
      >
        {rightChild}
      </div>

      {/* Split Handle for controlling the size of the left scroll container */}
      {leftContainerRef.current && (
        <SplitHandle
          initialDragHandlePosition={initialSplitPercent}
          controlElement={leftContainerRef.current}
          onBeginDrag={handleSplitHandleBeginDrag}
          onDrag={handleSplitHandleDrag}
          onEndDrag={handleSplitHandleEndDrag}
        />
      )}
    </div>
  );
};
