/**
 * @description
 * Takes a full name and attempts to break it into components.
 * It's not smart, but it's universal at least.
 */
export const fullNameToNameComponents = (fullName: null | string): null | {
  first: null | string,
  middle: null | string,
  last: null | string,
} => {
  if (fullName === null) return null;

  const parts = fullName.split(' ');
  const result: {
    first: null | string,
    middle: null | string,
    last: null | string,
  } = {
    first: null,
    middle: null,
    last: null,
  };

  if (parts.length > 2) {
    // eslint-disable-next-line prefer-destructuring
    result.middle = parts[1];
    result.last = parts.filter((part, index) => (index > 1)).join(' ');
  }
  else if (parts.length > 1) {
    // eslint-disable-next-line prefer-destructuring
    result.last = parts[1];
  }

  if (parts.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    result.first = parts[0];
  }

  return result;
};
