import React, { useCallback } from 'react';


import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { NewRecordFormProps } from '../../types/poly-form/new-record-form.props';

import { APIPolyForm } from './api-poly-form';

import { mapTableToForm } from '../../helpers/map-table-to-form.helper';

import { API_ACTION } from '../../constants/api-action.const';
import commissionPaymentBatchesTableInitialSettings from '../../table-definitions/commission-payment-batches-table';
import { ICommissionPaymentBatchRecord } from '../../types/finance/commission-payment-batch.record.interface';

const formFields = mapTableToForm(commissionPaymentBatchesTableInitialSettings);

export const NewCommissionPaymentBatchForm: React.FC<NewRecordFormProps<ICommissionPaymentBatchRecord>> = (props) => {
  const {
    onFormComplete, onFormChange,
  } = props;

  /**
   * Fired when the APIPolyForm successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, id: FormFieldComponentProps['value'], newData?: Partial<ICommissionPaymentBatchRecord>) => {
    if (onFormComplete) onFormComplete(success, id as ICommissionPaymentBatchRecord['id'], newData as ICommissionPaymentBatchRecord);
  }, [onFormComplete]);

  return (
    <APIPolyForm
      {...commissionPaymentBatchesTableInitialSettings}
      fields={formFields}
      permittedActions={commissionPaymentBatchesTableInitialSettings.possibleActions.filter((action) => (action.name === API_ACTION.CREATE))}
      onFormComplete={handleFormComplete}
      onFormChange={onFormChange}
      isNewRecord
      isEditing
    />
  );
};
