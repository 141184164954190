import { COLUMN_FORMAT } from '../constants/column-format.const';
import { IProjectForecastRecord } from '../types/project/project-forecast.record.interface';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { PROJECT_FORECAST_TYPE } from '../constants/project-forecast-type.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

export const ProjectForecastsWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Forecast',
  fields: [
    {
      name: 'project_id',
      visible: false,
      isParentId: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'type',
      title: 'Forecast Type',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'type',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formFieldType: FORM_FIELD_TYPE.FORECAST_TYPE_PICKER,
      formSaveField: 'type_id',
    },
    {
      name: 'expected_by',
      title: 'Expected by',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      format: COLUMN_FORMAT.LONG_DATE,
    },
    {
      name: 'amount',
      visible: true,
      title: 'Amount',
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
      allowNegative: true,
      format: COLUMN_FORMAT.CURRENCY,
      description: 'Forecast Amount',
      showTotals: true,
      totalsFilter: (row: IProjectForecastRecord): boolean => (!!row && (row.type_id !== PROJECT_FORECAST_TYPE.PROJECT_TOTAL)),
    },
    {
      name: 'comment',
      title: 'Comment',
      visible: true,
      format: COLUMN_FORMAT.LONG_TEXT,
    },
  ],
};
