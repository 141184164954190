import { SelectOption } from '../types/poly-form/select-option';

/**
 * Takes a NAME_MAP from a constant object (like PROJECT_STATUS_NAME_MAP) and converts it to
 * an id/name object array for use in selects and drop-downs etc...
 */
export const mapSelectInputOptions = (valueNameMap: Record<number, string>): SelectOption[] => {
  const items:SelectOption[] = [];

  Object.keys(valueNameMap).forEach((id) => {
    // There is some typescript issues here when using Object.keys - ignore
    const typedId: number = parseInt(id, 10);
    items.push({ id: typedId, name: valueNameMap[typedId] } as SelectOption);
  });

  return items;
};
