import React from 'react';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';

import AsyncSelect from './async-select';

export type ContractTypePickerProps = AsyncSelectComponentProps;

export const ContractTypePicker: React.FC<ContractTypePickerProps> = (props) => (
  <AsyncSelect
    {...props}
    searchRoute="/contract-type"
    loadAndKeepAll
  />
);
