import { COLUMN_FORMAT } from '../constants/column-format.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

export const ProjectCommissionPaymentsWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Commission Payment',
  fields: [
    {
      name: 'project_id',
      visible: false,
      isParentId: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'user',
      title: 'Paid To',
      formSaveField: 'user_id',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
    },
    {
      name: 'rate',
      title: 'Rate',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.TEXT_INPUT,
      allowNegative: true,
      format: COLUMN_FORMAT.NUMBER_SHORT,
    },
    {
      name: 'amount',
      title: 'Amount',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
      allowNegative: true,
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    },
    {
      name: 'paid_date',
      visible: true,
      title: 'Paid Date',
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      format: COLUMN_FORMAT.LONG_DATE,
      description: 'Commission Paid Date',
    },
    {
      name: 'payment_batch',
      title: 'Payment Batch',
      formSaveField: 'batch_id',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'payment_batch',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/finance/sales-commission/payment-batches',
      },
      formFieldType: FORM_FIELD_TYPE.COMMISSION_BATCH_PICKER,
    },
    {
      name: 'comment',
      title: 'Comment',
      visible: true,
      format: COLUMN_FORMAT.LONG_TEXT,
    },
  ],
};
