import React, { useContext, useState } from 'react';
import { Button } from 'reactstrap';
import axios, { AxiosResponse } from 'axios';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { APIContext } from '../providers/api-provider';
import Icon from '../layout-helpers/icon';
import { ICON } from '../../constants/icon.const';
import { localStorageLoad } from '../../utils/localStorage';
import { LOCAL_STORAGE_KEYS } from '../../utils/local-storage-keys';

interface IDownloadButton {
    caption: string,
    url: string,
    filename?: string
    icon?: string,
    isEmailed?: boolean,
    style?: { [key: string]: unknown }
    type?: string,
    resetAfterSeconds?: number,
}

const apiPath = process.env.REACT_APP_API;
const apiVersion = process.env.REACT_APP_API_VERSION;

/**
 * Single Internal Project Multiview
 *
 * Allows rendering of a single internal project as either a card-view or a data record view
 *
 * @param {IReportDownloadsViewProps}  props
 * @param {string}  props.caption
 * @param {string}  props.url
 * @param {string}  props.icon
 * @param {string}  props.filename
 */
export const ReportDownloadButton: React.FC<IDownloadButton> = ({ caption, url, icon, filename, style, isEmailed = false, type, resetAfterSeconds }) => {
  const { apiFetchBlob } = useContext(APIContext);
  const [working, setWorking] = useState(false);
  const [useIcon, setIcon] = useState<string>(icon || ICON.FILE);
  const [color, setColor] = useState('primary');
  const [useCaption, setCaption] = useState(caption);
  const [disabled, setDisabled] = useState(false);

  const handleDownloadReady = (blob: Blob, downloadFilename: string) => {
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', downloadFilename);
    document.body.appendChild(link);
    link.click();
    setWorking(false);
    setColor('success');
    setIcon(ICON.CHECK);
  };

  const handleDownloadFail = () => {
    setWorking(false);
    setIcon(ICON.ERROR);
    setColor('danger');
  };

  const downloadItem = async (route: string) => {
    setWorking(true);
    setCaption(caption);
    // pdf blob
    if (type === 'pdf') {
      const token = localStorageLoad(LOCAL_STORAGE_KEYS.API_AUTH_TOKEN);
      try {
        const pdfResponse: AxiosResponse<Blob> = await axios.request({
          url: `${apiPath}${apiVersion}${route}`,
          responseType: 'blob',
          method: HTTP_METHOD.GET,
          headers: {
            Authorization: `Bearer ${token.access_token}`,
            Accept: 'application/pdf',
          },
        });
        handleDownloadReady(pdfResponse.data, filename || 'report.pdf');
      } catch ($error) {
        handleDownloadFail();
        return false;
      }
      return true;
    }
    // excel blob
    const response = await apiFetchBlob(route);
    if (response.success === false) {
      handleDownloadFail();
      return false;
    }
    setWorking(false);
    if (response.blob && !isEmailed) {
      handleDownloadReady(response.blob, filename || filename || 'ci-portal-download.xlsx');
      return true;
    }
    setColor('success');
    if (isEmailed) {
      setCaption('Report Queued');
      setIcon(ICON.EMAIL_JOB_QUEUED);
      setDisabled(true);
      if (resetAfterSeconds || resetAfterSeconds === 0) {
        setTimeout(() => {
          setDisabled(false);
          setColor('primary');
        }, resetAfterSeconds * 1000);
      }
    } else {
      setIcon(ICON.CHECK);
    }
    return true;
  };

  return (
    <Button
      color={color}
      onClick={() => downloadItem(url)}
      style={style || undefined}
      disabled={disabled}
    >
      {useCaption}
      &nbsp;&nbsp;
      <Icon i={useIcon} isBusy={working} />
    </Button>
  );
};
