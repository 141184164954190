import React, { useCallback } from 'react';

import { IInternalProjectTaskRecord } from '../../types/internal-project/internal-project-task.record.interface';
import { NewRecordModalProps } from '../../types/modal/new-record-modal.props';

import { useIsDirty } from '../../react-hooks/use-is-dirty.hook';

import { Modal } from './Modal';
import { NewInternalProjectTaskForm } from '../poly-forms/new-internal-project-task-form';

export const NewInternalProjectTaskModal:React.FC<NewRecordModalProps<IInternalProjectTaskRecord>> = (props) => {
  const {
    id, closeModal, onModalComplete, initialData,
  } = props;

  const [isDirty, setDirty] = useIsDirty();

  /**
   * Fired when the form successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, recordId: IInternalProjectTaskRecord['id'], newRecord?: IInternalProjectTaskRecord) => {
    closeModal(id);
    onModalComplete((success && newRecord) ? { success, newRecord } : { success: false, newRecord: null });
  }, [closeModal, id, onModalComplete]);

  // Render
  return (
    <Modal
      {...props}
      title="Create a new Internal Project Task"
      allowDismiss
      isDirty={isDirty}
      size="lg"
    >
      <NewInternalProjectTaskForm
        onFormComplete={handleFormComplete}
        onFormChange={setDirty}
        initialData={initialData}
      />
    </Modal>
  );
};
