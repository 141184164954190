/* eslint-disable import/no-cycle */

import classNames from 'classnames';
import React from 'react';
import { currencyAUD } from '../../../utils/helpers';
import { DetailedProfitAndLossSection } from './detailed-profit-and-loss-section';

export interface IDetailedProfitAndLossSection {
  id: number,
  name: string,
  sections: IDetailedProfitAndLossSection[],
  accountLines: {
    category_id: number,
    [key: string]: string | number | null,
  }[],
  summaryLines: {
    category_id: number,
    [key: string]: string | number | null,
  }[],
}

type DetailedProfitAndLossSectionsProps = {
  grossProfitLine?: {
    category_id: number,
    [key: string]: string | number | null,
  }
  sections?: IDetailedProfitAndLossSection[],
  columns: {
    name: string,
    title: string,
  }[],
  depth: number,
};

export const DetailedProfitAndLossSections: React.FC<DetailedProfitAndLossSectionsProps> = ({ sections, columns, depth, grossProfitLine }) => (
  <>
    {sections && sections.map((section) => {
      const currencyValue = grossProfitLine?.subt_nat_amount || grossProfitLine?.subt_nat_home_amount || null;
      return (
        <>
          <DetailedProfitAndLossSection key={section.id} section={section} columns={columns} depth={depth} />
          {section.name === 'Cost of sales' && grossProfitLine && (
            <tr key={`${section.name}-gp`} className="summary-line">
              <th colSpan={columns.length - 1}>
                {grossProfitLine[columns[0].name]}
              </th>
              <th className={classNames('text-right', { 'text-danger': currencyValue && Number(currencyValue) < 0 })}>
                {currencyAUD(Number(grossProfitLine?.subt_nat_amount || grossProfitLine?.subt_nat_home_amount || null), 0)}
              </th>
            </tr>
          )}
        </>
      );
    })}
  </>
);
