import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { ModalContext } from '../modals/modal-context';

import { NewRecordModalResult } from '../../types/modal/modal-result';
import { ITeamRecord } from '../../types/internal-project/team.record.interface';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { DataTablePageProps } from '../../types/data-table-page.props';

export type TeamsDataTablePageProps = DataTablePageProps;

/**
 * Display the "Teams" data table as a whole page
 */
export const TeamsDataTablePage: React.FC<TeamsDataTablePageProps> = ({
  baseFilters,
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const history = useHistory();
  const { showModal } = useContext(ModalContext);

  const actionHandlers = {
    create: () => {
      showModal<NewRecordModalResult<ITeamRecord>>(MODAL_TYPE.NEW_TEAM, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/internal-projects/teams/${modalResult.newRecord.id}`);
        },
      });
    },
  };

  return (
    <DataTablePage
      title="Teams"
      tableIdentifier={TABLE_IDENTIFIER.TEAMS_TABLE}
      actionHandlers={actionHandlers}
      baseFilters={baseFilters}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      {children}
    </DataTablePage>
  );
};
