import React, { useContext } from 'react';
import { Table, Button } from 'reactstrap';
import classnames from 'classnames';

import { CurrentUserContext } from '../providers/current-user-provider';
import { ModalContext } from '../modals/modal-context';

import { CollectionWidgetProps } from '../../types/collection.widget.props';
import { IProjectRecord } from '../../types/project/project.record.interface';
import { NewRecordModalResult } from '../../types/modal/modal-result';
import { IFormFieldOverride } from '../../types/poly-form/form-field-override.interface';

import TableCell from '../portal-data-table/table/table-cell';
import ProgressBar from '../layout-helpers/progress-bar';
import Icon from '../layout-helpers/icon';

import { startCase } from '../../utils/helpers';

import { A_THEME_COLOR, THEME_COLOR } from '../../constants/theme-color.const';
import { A_MODAL_TYPE, MODAL_TYPE } from '../../constants/modal-type.const';
import { PROJECT_STATUS, PROJECT_STATUS_NAME_MAP } from '../../constants/project-status.const';
import { PROJECT_PHASE, PROJECT_PHASE_NAME_MAP } from '../../constants/project-phase.const';


export type ProjectsWidgetProps = Omit<CollectionWidgetProps, 'widgetData'> & {
  widgetData: IProjectRecord[],
  colorTheme?: A_THEME_COLOR,
}


/**
 * @todo: This was copied from the old .jsx component and needs much work to improve
 *        how it interprets what "mode" it's in. Rather than evaluating content to
 *        flip into "lead" or "proposal" mode, there should be props passed down.
 */
export const ProjectsWidget: React.FC<ProjectsWidgetProps> = (props) => {
  const {
    widgetData,
    description,
    fields,
    isLoading,
    loadingError,
    rowData,
    colorTheme = THEME_COLOR.SUCCESS,
    tableIdentifier,
    history,
  } = props;

  const { userDetails } = useContext(CurrentUserContext);
  const { showModal } = useContext(ModalContext);

  const statusTypeId = description === 'Client Leads' ? PROJECT_STATUS.LEAD : PROJECT_STATUS.PROPOSAL;
  const statusType = PROJECT_STATUS_NAME_MAP[statusTypeId];

  const phaseTypeId = description === 'Client Leads' ? PROJECT_PHASE.PROSPECTING : PROJECT_PHASE.PROPOSAL_DEVELOPMENT;
  const phaseType = PROJECT_PHASE_NAME_MAP[phaseTypeId];

  // Count the number of visible columns
  let visibleColumnCount = 0;
  if (fields) { fields.forEach((f) => {
    if (f.visible) visibleColumnCount += 1;
  }); }

  // Evaluate what type of "New Project" modal is required based on the project status
  let NewProjectModalType: undefined | A_MODAL_TYPE;
  switch (statusTypeId) {
    case PROJECT_STATUS.LEAD: {
      NewProjectModalType = MODAL_TYPE.NEW_PROJECT_LEAD;
      break;
    }

    case PROJECT_STATUS.PROPOSAL: {
      NewProjectModalType = MODAL_TYPE.NEW_PROJECT_PROPOSAL;
      break;
    }
  }

  // Create a button that allows the user to create a new Project at the appropriate status
  let CreateProjectButton;

  // Checking the tableIdentifier is lazy, I know, but this is a temporary work-around until we have time to add more props
  // That filter through from the widget type selection in the TABLE (not in the widget types).
  if (NewProjectModalType && (tableIdentifier === 'companyTable')) {
    const initialData = {
      owner_id: userDetails.id,
      owner: userDetails,
      client_id: rowData.id,
      client: rowData,
      status_id: statusTypeId,
      status: { id: statusTypeId, name: statusType },
      phase_id: phaseTypeId,
      phase: { id: phaseTypeId, name: phaseType },
      state_id: userDetails.state?.id,
      state: userDetails.state,
      external_design: 0,
      tender: 0,
    };

    const formFieldOverrides: IFormFieldOverride[] = [
      {
        name: 'status',
        isReadOnly: true,
      },
      {
        name: 'client',
        isReadOnly: true,
      },
    ];

    CreateProjectButton = (
      <Button
        className="float-right"
        color="secondary"
        size="sm"
        onClick={() => {
          if (NewProjectModalType) {
            showModal<NewRecordModalResult<IProjectRecord>>(NewProjectModalType, {
              onModalComplete: (modalResult) => {
                if (modalResult.success) history.push(`/crm/projects/${modalResult.newRecord.id}`);
              },
              initialData,
              formFieldOverrides,
            });
          }
        }}
      >
        <Icon i="plus-circle" />
        <span>{startCase(`new ${statusType}`)}</span>
      </Button>
    );
  }

  // Render
  return (
    <div className="widget projects-widget">
      <div
        className="text-white bg-success"
        style={{ borderRadius: '0', padding: '10px', paddingBottom: '5px' }}
      >
        <div className="table-caption text-white" style={{ float: 'none' }}>
          <h4>
            {description}
            {CreateProjectButton}
          </h4>
        </div>
      </div>
      <Table
        striped
        bordered
        hover
        className={classnames(
          'color-table',
          `${colorTheme}-table`,
          'color-bordered-table',
          `${colorTheme}-bordered-table`,
        )}
      >
        <thead>
          <tr>
            {fields &&
              fields.map((field) => (field.visible ? <th key={`header-field-${field.name}`}>{field.title}</th> : null))}
          </tr>
        </thead>
        <tbody>
          {widgetData &&
            widgetData.map((item) => (
              <tr key={`row-${item.id}`}>
                {fields &&
                  fields.map((field) => (field.visible ? (
                    <TableCell
                      key={`row-${item.id}-field-${field.name}`}
                      row={item}
                      column={field}
                    />
                  ) : null))}
              </tr>
            ))}
        </tbody>
        <tfoot className="table-footer">
          <tr>
            <td colSpan={visibleColumnCount}>
              <ProgressBar complete={!isLoading} />

              {/* Loading Error */}
              {loadingError && (
                <div>
                  <Icon i="exclamation-triangle" className="text-danger" spaceRight />
                  <span>Error fetching data</span>
                </div>
              )}

              {/* Some Records */}
              {!loadingError && widgetData && widgetData.length > 0 && (
                <div>
                  <span>{`Showing ${widgetData.length} Records`}</span>
                </div>
              )}

              {/* No Records */}
              {!loadingError && widgetData && widgetData.length === 0 && (
                <div>
                  <span>No records</span>
                </div>
              )}
            </td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};


/*


ProjectsWidgetComponent.propTypes = {
  description: PropTypes.string,
  widgetData: PropTypes.arrayOf(PropTypes.shape({})),
  tableIdentifier: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  loadingError: PropTypes.bool.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape(HISTORY_PROP_TYPES).isRequired,
};

ProjectsWidgetComponent.defaultProps = {
  description: 'Projects',
  widgetData: [],
  fields: [],
  isLoading: true,
  rowData: null,
  colorTheme: 'success',
};

export const ProjectsWidget = connectToModalProvider(connectToCurrentUserProvider(ProjectsWidgetComponent));

*/
