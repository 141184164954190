import React from 'react';
import PropTypes from 'prop-types';
import { DToolsInfoPanel } from '../../dtools-information/d-tools-info-panel';

/**
 * DToolsLinkInfoRow component for Data Table Widget
 */
export const DToolsLinkInfoRow = ({ dtoolsId, projectData }) => (
  <tr>
    <td colSpan="7" className="dtoolslink-info-panel-container">
      <DToolsInfoPanel dtoolsId={dtoolsId} projectData={projectData} />
    </td>
  </tr>
);

DToolsLinkInfoRow.propTypes = {
  dtoolsId: PropTypes.string.isRequired,
  projectData: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
};
