/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { TIMEOUT_TYPING_DEBOUNCE } from '../../../utils/constants';
import { filterColumn } from '../../../actions/portal-data-table/filter-actions';
import {
  getItemByKey, isElementFocused, debugLog, noop,
} from '../../../utils/helpers';
import { getFilterFormat } from '../../../utils/filters';
import { ReactPortal } from '../../react-portal/react-portal';
import { formatAlignTableColumn } from '../../render-functions';
import Icon from '../../layout-helpers/icon';
import { COLUMN_FILTER_TYPES, isNumericFilterType } from '../../../utils/column-filter-types';
import { VALID_DATE_INPUT_FORMATS, DATE_FORMAT } from '../../../constants/date-format.const';
import { deepCompare } from '../../../helpers/deep-compare.helper';
import { DateInput } from '../../form-input/date-input';
import { FILTER_OPERATION } from '../../../constants/filter-operation.const';


/**
 * Custom select component for filters
 */
class CustomFilterSelect extends Component {
  /**
   * @constructor
   *
   * @param {object} props
   */
  constructor(props) {
    super(props);
    // Build options for select boxes
    const optionsForType = getFilterFormat(props.column).operations;
    const options = Object.keys(optionsForType).map((keyName) => ({
      ...optionsForType[keyName],
      operation: keyName,
    }));

    options.push({
      caption: '╳ Clear',
      inputCount: 0,
      operation: 'clear',
    });

    // Done, set state default
    this.state = {
      isOptionsDropdownVisible: false,
      options,
      selectedOption: options[0],
      // 2 values for "between" clauses (dates, numbers)
      // updated by mapPropsToState
      valueOne: '',
      valueTwo: '',
      updateFilterDebounceTimeout: null,
      inputOneRef: React.createRef(),
      inputTwoRef: React.createRef(),
      dateInputOneOpen: false,
      dateInputTwoOpen: false,
    };

    // change values based on the filter
    this.state = CustomFilterSelect.mapPropsToState(props, this.state);
  }


  /**
   * shouldComponentUpdate
   */
  shouldComponentUpdate(nextProps) {
    const { location: oldLocation } = this.props;
    const { location: newLocation, currentFilter } = nextProps;
    const { updateFilterDebounceTimeout } = this.state;

    // This is a navigation event.
    // We want the filter content to reflect the current props REGARDLESS of the state of focus
    if (oldLocation.search !== newLocation.search) {
      // Clear any debounce time-outs so that the navigation change isn't overridden by an outstanding de-bounced filter values
      clearTimeout(updateFilterDebounceTimeout);
      this.setState({
        valueOne: currentFilter ? currentFilter.values[0] || '' : '',
        valueTwo: currentFilter ? currentFilter.values[1] || '' : '',
        updateFilterDebounceTimeout: null,
      });
      return false;
    }

    return true;
  }


  /**
   * getDerivedStateFromProps
   */
  static getDerivedStateFromProps(props, state) {
    const {
      updateFilterDebounceTimeout,
      inputOneRef,
      inputTwoRef,
      isOptionsDropdownVisible,
      dateInputOneOpen,
      dateInputTwoOpen,
    } = state;

    // note: this isFocused check is different to the non-static one
    //  - checks if the day pickers are visible, rather than if they are focused
    const isFocused = isElementFocused(inputOneRef)
      || isElementFocused(inputTwoRef)
      || isOptionsDropdownVisible
      || dateInputOneOpen
      || dateInputTwoOpen;

    if (isFocused || (updateFilterDebounceTimeout !== null)) {
      return state;
    }
    const newState = CustomFilterSelect.mapPropsToState(props, state);

    return newState;
  }


  /**
   * componentWillUnmount
   */
  componentWillUnmount() {
    const { updateFilterDebounceTimeout } = this.state;
    clearTimeout(updateFilterDebounceTimeout);
  }


  /**
   * @description
   * Convert a valid date string value to a date
   *
   * @param {string} value
   * @return {import('moment').Moment}
   */
  // eslint-disable-next-line react/sort-comp
  static valueToDate = (value) => moment(value, VALID_DATE_INPUT_FORMATS, true).format(DATE_FORMAT.YEAR_MONTH_DAY_DASHES);

  /**
   * @description
   * Determine if a string is a valid date
   *
   * @param {string} value
   * @returns {boolean}
   */
  static isValidDate = (value) => moment(value, VALID_DATE_INPUT_FORMATS, true).isValid();


  /**
   * @description
   * Map props and state to a new state
   *
   * @param {object} props
   * @param {object} state
   * @returns {object} the new state
   */
  // eslint-disable-next-line react/sort-comp
  static mapPropsToState = (props, state) => {
    const { currentFilter } = props;

    // Active filter - set the current value (if the user is not currently supplying a NEW value)
    if (currentFilter) {
      const result = {
        ...state,
        selectedOption: getItemByKey(state.options, props.currentFilter.operation, 'operation'),
        valueOne: props.currentFilter.values[0] || '',
        valueTwo: props.currentFilter.values[1] || '',
      };

      return result;
    }

    const result = {
      ...state,
      selectedOption: state.selectedOption || state.options[0],
      valueOne: '',
      valueTwo: '',
    };

    return result;
  }


  /**
   * @description
   * Are inputs / date-pickers focused?
   *
   * @returns {boolean}
   */
  get isFocused() {
    const {
      inputOneRef,
      inputTwoRef,
      isOptionsDropdownVisible,
      dateInputOneOpen,
      dateInputTwoOpen,
    } = this.state;

    const result = isElementFocused(inputOneRef)
      || isElementFocused(inputTwoRef)
      || isOptionsDropdownVisible
      || dateInputOneOpen
      || dateInputTwoOpen;

    debugLog(
      `custom-filter-select - isFocused (${result})`,
      'danger',
      {
        inputOneFocused: isElementFocused(inputOneRef),
        inputTwoFocused: isElementFocused(inputTwoRef),
        isOptionsDropdownVisible,
      },
      '🎚',
    );

    return result;
  }


  /**
   * @description
   * Toggle options dropdown
   *
   * @returns {void}
   */
  // eslint-disable-next-line react/sort-comp
  handleToggleOptionsDropDown = (e) => {
    if (!e) return;

    const preventToggle = e.target.getAttribute('data-prevent-close-dropdown');

    const newOptionsDropdownVisible = !this.state.isOptionsDropdownVisible;

    // DON'T "close" the dropdown if the target was one of the options being clicked.
    // DO close the dropdown as part of the select option process
    if (!preventToggle) {
      this.setState({
        isOptionsDropdownVisible: newOptionsDropdownVisible,
      });
    }
  };


  /**
   * @description
   * Handles the select of an operation option
   *
   * @param {React.SyntheticEvent} event
   * @param {string} selectedOperation
   * @returns {void}
   */
  handleSelectOptionOperation = (event, selectedOperation) => {
    event.stopPropagation();
    const {
      options,
      valueOne: oldValueOne,
      valueTwo: oldValueTwo,
      selectedOption: oldSelectedOption,
      inputOneRef,
      inputTwoRef,
    } = this.state;

    // eslint-disable-next-line no-nested-ternary
    const newSelectedOption = selectedOperation === FILTER_OPERATION.CLEAR
      // clearing "blanks" (i.e. operation === 'is-null'), go back to the default option
      ? (![FILTER_OPERATION.IS_NULL, FILTER_OPERATION.NOT_NULL].includes(oldSelectedOption.operation) ? oldSelectedOption : options[0])
      // find the new operation
      : getItemByKey(options, selectedOperation, 'operation') || options[0];

    let newValueOne = '';
    let newValueTwo = '';
    const newOperation = newSelectedOption.operation;
    const oldOperation = oldSelectedOption.operation;

    let inputToFocus = inputOneRef;

    if (selectedOperation !== FILTER_OPERATION.CLEAR) {
      if (newSelectedOption.inputCount === 1) {
        // between -> less
        if (oldOperation === FILTER_OPERATION.BETWEEN && newOperation === FILTER_OPERATION.LESS_THAN) newValueOne = oldValueTwo || oldValueOne;
        // !(between) -> !(less)
        else newValueOne = oldValueOne || oldValueTwo;
      }
      else if (newSelectedOption.inputCount === 2) {
        // less -> between
        if (oldOperation === FILTER_OPERATION.LESS_THAN && newOperation === FILTER_OPERATION.BETWEEN) newValueTwo = oldValueOne;
        // greater -> between
        else if (oldOperation === FILTER_OPERATION.GREATER_THAN && newOperation === FILTER_OPERATION.BETWEEN) {
          newValueOne = oldValueOne;
          inputToFocus = inputTwoRef;
        }
        else if (oldValueOne && newOperation === FILTER_OPERATION.BETWEEN) {
          newValueOne = oldValueOne;
          inputToFocus = inputTwoRef;
        }
        // !(less | greater) -> !(between)
        else newValueOne = oldValueOne;

      // inputCount === 0
      }
      else { /* do nothing */ }
    }


    this.setState({
      selectedOption: newSelectedOption,
      valueOne: newValueOne,
      valueTwo: newValueTwo,
    }, () => {
      this.updateFilter(true);

      // Focus input one so that we don't lose our new selection
      // when get derived state from props fires next
      if (inputToFocus && inputToFocus.current) inputToFocus.current.select();

      this.setState({
        isOptionsDropdownVisible: false,
      });
    });
  };


  /**
   * @description
   * Request new data for column - DE-BOUNCED
   *
   * @param {boolean} [pushToHistory=false] whether these settings should become part of the user's navigation history
   *
   * @returns {void}
   */
  updateFilter = (pushToHistory = false) => {
    const {
      column, currentFilter, dispatchFilterColumn, type, setColumnFilter,
    } = this.props;
    const {
      valueOne, valueTwo, selectedOption, updateFilterDebounceTimeout,
    } = this.state;
    let allowDispatch = true;

    clearTimeout(updateFilterDebounceTimeout);

    // NOTE: Blanks have 0 selectedOption.inputCount so there's no way to trigger the `onChange`,
    // this should let the 0 options slip through

    let newFilter = {
      name: column.name,
      operation: selectedOption.operation,
      values: [],
    };

    // when inputCount is 0, do not need to evaluate values
    if (selectedOption.inputCount === 1) {
      // if filter value is empty, remove filter
      if (valueOne.trim() === '') newFilter = null;
      // remove non numeric characters from pnumber filters
      else newFilter.values = [type === 'pnumber' ? valueOne.replace(/\D/g, '') : valueOne];
    }
    else if (selectedOption.inputCount === 2) {
      // if neither values are valid, remove the filter
      if (valueOne.trim() === '' && valueTwo.trim() === '') newFilter = null;
      // if between and only one value valid, do less or greater than
      else if (selectedOption.operation === FILTER_OPERATION.BETWEEN) {
        if (valueOne.trim() !== '' && valueTwo.trim() === '') {
          newFilter.values = [valueOne, valueOne];
        }
        else if (valueOne.trim() === '' && valueTwo.trim() !== '') {
          newFilter.values = [valueTwo, valueTwo];
        }
        else newFilter.values = [valueOne, valueTwo];
      }
      // if NOT doing a between, and if one of the values is valid and the other not, do nothing
      else if (valueOne.trim() === '' || valueTwo.trim() === '') allowDispatch = false;
      // both values are valid
      else newFilter.values = [valueOne, valueTwo];
    }

    if (selectedOption.hasDatePicker && newFilter) {
      // if any dates are invalid, do nothing
      if (newFilter.values.some((value) => !CustomFilterSelect.isValidDate(value))) allowDispatch = false;
      // serialize dates
      else newFilter.values = newFilter.values.map((value) => CustomFilterSelect.valueToDate(value));
    }

    // current and new filters are different or the user has performed an action worthy of recording in the navigation history stack
    if (allowDispatch && (!deepCompare(currentFilter, newFilter) || pushToHistory)) {
      debugLog('PDT Lifecycle - Updating filters', 'info', { currentFilter, newFilter }, '👨‍⚕️');
      if (setColumnFilter) {
        setColumnFilter(column.name, newFilter);
      } else {
        dispatchFilterColumn(column.name, newFilter, pushToHistory);
      }
    }

    this.setState({
      updateFilterDebounceTimeout: null,
    });
  };


  /**
   * @description
   * Fired when the user changes a value (via input or date-picker)
   *
   * @param {string} value
   * @param { 1 | 2 } inputNumber
   * @param {boolean} [pushToHistory=false]
   * @param {() => any} callback
   *
   * @returns {void}
   */
  setInputValue = (value, inputNumber, pushToHistory = false, callback) => {
    const { updateFilterDebounceTimeout } = this.state;

    // Clear any existing debounce time-outs
    if (updateFilterDebounceTimeout) clearTimeout(updateFilterDebounceTimeout);

    const newUpdateFilterDebounceTimeout = setTimeout(() => this.updateFilter(pushToHistory), TIMEOUT_TYPING_DEBOUNCE);

    this.setState({
      [inputNumber === 1 ? 'valueOne' : 'valueTwo']: value,
      updateFilterDebounceTimeout: newUpdateFilterDebounceTimeout,
    }, callback);
  }


  /**
   * @description
   * Fired when the user changes valueOne
   *
   * @param {React.SyntheticEvent} event
   * @param {1|2} inputNumber whether the event was triggered by input one or two
   * @returns {void}
   */
  handleInputChange = (event, inputNumber) => {
    this.setInputValue(event.target.value, inputNumber, false);
  }


  /**
   * @description
   * Fired when a date input calendar is opened
   *
   * @param {1|2} inputNumber whether the event was triggered by input one or two
   * @returns {void}
   */
  handleDateInputOpen = (inputNumber) => {
    this.setState({
      [inputNumber === 1 ? 'dateInputOneOpen' : 'dateInputTwoOpen']: true,
    });
  }


  /**
   * @description
   * Fired when a date input calendar is closed
   *
   * @param {1|2} inputNumber whether the event was triggered by input one or two
   * @returns {void}
   */
  handleDateInputClose = (inputNumber) => {
    this.setState({
      [inputNumber === 1 ? 'dateInputOneOpen' : 'dateInputTwoOpen']: false,
    });
  }


  /**
   * @description
   * Callback for clicking day in date picker
   *
   * @param {object Date | null} selectedDate
   * @param { 1 | 2 } inputNumber
   * Also can have:
   * @param {object} modifiers
   * @param {React.SyntheticEvent} e event from clicking day in calendar
   */
  handleDateInputSelect = (selectedDate, inputNumber) => {
    let pushToHistory = true;
    const { selectedOption, valueOne, valueTwo } = this.state;

    if (selectedOption.inputCount === 2) {
      if ((inputNumber === 1) && (!valueTwo)) {
        pushToHistory = false;
      }
      if ((inputNumber === 2) && (!valueOne)) {
        pushToHistory = false;
      }
    }

    // Close the day picker
    if (selectedDate) {
      this.setInputValue(moment(selectedDate).format(DATE_FORMAT.YEAR_MONTH_DAY_DASHES), inputNumber, pushToHistory);
    } else {
      this.setInputValue('', inputNumber, pushToHistory);
    }
  }


  /**
   * @description
   * Fired when the user stops focusing on input one
   *
   * @returns {void}
   */
  handleBlurInput = () => {
    // Triggering a setState will result in getDerivedStateFromProps evaluating
    // whether or not to replace the internal input value with the props values

    // setTimeout to stop the race condition between the the blur of the previous input
    // and focus of the next input - which was causing getDerivedStateFromProps to be called
    // after the first input was blurred and before the second was focused
    //  - improves switching between inputs
    setTimeout(() => {
      if (this.isFocused || this.state.updateFilterDebounceTimeout !== null) return;
      const newState = CustomFilterSelect.mapPropsToState(this.props, this.state);
      this.setState(newState, () => {
        this.updateFilter(true);
      });
    }, 0);
  }


  /**
   * @description
   * Fired when the user presses a key on one of the filter inputs
   *
   * @param {React.SyntheticEvent} e
   * @param {1 | 2} inputNumber the input that fired the event
   */
  handleInputKeyDown = (e /* inputNumber */) => {
    if (e && ((e.code === 'Enter' || e.code === 'NumpadEnter'))) {
      this.updateFilter(true);
    }
  }


  // Render for <CustomFilterSelect />
  render() {
    const {
      type,
      isLoading,
      currentFilter,
      column,
      tableIdentifier,
    } = this.props;

    const {
      options,
      selectedOption,
      valueOne,
      valueTwo,
      inputOneRef,
      inputTwoRef,
      isOptionsDropdownVisible,
    } = this.state;

    let valueOneDate = null;
    let valueTwoDate = null;
    if (selectedOption.hasDatePicker) {
      // use internal date value
      // fallback to currently filtered date value
      // fallback to now
      if (CustomFilterSelect.isValidDate(valueOne)) { valueOneDate = moment(valueOne, VALID_DATE_INPUT_FORMATS, true).format(DATE_FORMAT.YEAR_MONTH_DAY_DASHES); }
      else if (currentFilter && currentFilter.values[0] && CustomFilterSelect.isValidDate(currentFilter.values[0])) {
        valueOneDate = moment(currentFilter.values[0], VALID_DATE_INPUT_FORMATS, true).format(DATE_FORMAT.YEAR_MONTH_DAY_DASHES);
      }

      if (CustomFilterSelect.isValidDate(valueTwo)) { valueTwoDate = moment(valueTwo, VALID_DATE_INPUT_FORMATS, true).format(DATE_FORMAT.YEAR_MONTH_DAY_DASHES); }
      else if (currentFilter && currentFilter.values[1] && CustomFilterSelect.isValidDate(currentFilter.values[1])) {
        valueTwoDate = moment(currentFilter.values[1], VALID_DATE_INPUT_FORMATS, true).format(DATE_FORMAT.YEAR_MONTH_DAY_DASHES);
      }
    }

    const { caption, inputCount, hasDatePicker } = selectedOption;

    // Return <CustomFilterSelect />
    return (
      <div className="custom-select-container">
        {/* the dropdown is rendered first so that the look-behind CSS can add padding to the input if required */}
        <Dropdown
          isOpen={isOptionsDropdownVisible}
          toggle={this.handleToggleOptionsDropDown}
          className="custom-filter-dropdown"
          disabled={isLoading}
        >
          <DropdownToggle tag="div" className="toggle"><Icon i="cog" /></DropdownToggle>
          <ReactPortal>
            <DropdownMenu right modifiers={{ preventOverflow: { boundariesElement: 'window' } }}>
              {options.map((option) => (
                <DropdownItem
                  key={option.operation}
                  onClick={(e) => this.handleSelectOptionOperation(e, option.operation)}
                  disabled={isLoading}
                  data-prevent-close-dropdown
                >
                  {option.caption}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ReactPortal>
        </Dropdown>

        {!inputCount && (
          <div className="input-wrapper">
            <input
              type="text"
              className={`filter-input single ${type} disabled`}
              // value={caption}
              placeholder={caption}
              disabled
            />
          </div>
        )}

        {(inputCount > 0) && (
          <div className="input-wrapper">
            { /* VALUE ONE INPUT */ }
            {!hasDatePicker && (
              <input
                id={`${tableIdentifier}_${column.name}_input_1`}
                type={isNumericFilterType(type) ? 'number' : 'text'}
                className={`filter-input ${inputCount === 1 ? 'single' : 'double'} ${type} ${formatAlignTableColumn(column)}`}
                onChange={(event) => this.handleInputChange(event, 1)}
                value={valueOne}
                placeholder={inputCount === 1 ? caption : 'from'}
                onBlur={() => this.handleBlurInput()}
                onKeyDown={(e) => this.handleInputKeyDown(e, 1)}
                ref={inputOneRef}
                autoComplete={`${tableIdentifier}_${column.name}_filter`}
              />
            )}

            { /* VALUE ONE DATE PICKER */ }
            {hasDatePicker && (
              <DateInput
                id={`${tableIdentifier}_${column.name}_date_1`}
                name={`${tableIdentifier}_${column.name}_date_1`}
                useStringValue
                dateFormat={DATE_FORMAT.YEAR_MONTH_DAY_DASHES}
                customInput={(
                  <input
                    className={`filter-input ${inputCount === 1 ? 'single' : 'double'} ${type} 'date-picker' ${formatAlignTableColumn(column)}`}
                    id={`${tableIdentifier}_${column.name}_input_1`}
                    type={isNumericFilterType(type) ? 'number' : 'text'}
                    ref={inputOneRef}
                    autoComplete={`${tableIdentifier}_${column.name}_filter`}
                  />
                )}
                placeholder={inputCount === 1 ? caption : 'from'}
                value={valueOneDate}
                onCalendarOpen={() => this.handleDateInputOpen(1)}
                onSelect={(name, newValue) => this.handleDateInputSelect(newValue, 1)}
                onCalendarClose={() => this.handleDateInputClose(1)}
              />
            )}

            {inputCount === 2 && (
              <>
                {/* Range Arrow -> */}
                <span className="filter-range-arrow"><Icon i="arrow-right" /></span>

                { /* VALUE TWO INPUT */ }
                {!hasDatePicker && (
                  <input
                    id={`${tableIdentifier}_${column.name}_input_2`}
                    type={isNumericFilterType(type) ? 'number' : 'text'}
                    className={`filter-input double ${type} ${formatAlignTableColumn(column)}`}
                    onChange={(event) => this.handleInputChange(event, 2)}
                    value={valueTwo}
                    placeholder="to"
                    onBlur={() => this.handleBlurInput()}
                    onKeyDown={(e) => this.handleInputKeyDown(e, 2)}
                    ref={inputTwoRef}
                    autoComplete={`${tableIdentifier}_${column.name}_filter`}
                  />
                )}

                { /* VALUE TWO DATE PICKER */ }
                {hasDatePicker && (
                  <DateInput
                    id={`${tableIdentifier}_${column.name}_date_2`}
                    name={`${tableIdentifier}_${column.name}_date_2`}
                    className={`filter-input double ${type} 'date-picker' ${formatAlignTableColumn(column)}`}
                    useStringValue
                    dateFormat={DATE_FORMAT.YEAR_MONTH_DAY_DASHES}
                    customInput={(
                      <input
                        id={`${tableIdentifier}_${column.name}_input_2`}
                        type={isNumericFilterType(type) ? 'number' : 'text'}
                        ref={inputTwoRef}
                        autoComplete={`${tableIdentifier}_${column.name}_filter`}
                      />
                    )}
                    value={valueTwoDate}
                    placeholder="to"
                    onCalendarOpen={() => this.handleDateInputOpen(2)}
                    onSelect={(name, newValue) => this.handleDateInputSelect(newValue, 2)}
                    onCalendarClose={() => this.handleDateInputClose(2)}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

CustomFilterSelect.defaultProps = {
  currentFilter: null,
  setColumnFilter: undefined,
  dispatchFilterColumn: noop,
};

CustomFilterSelect.propTypes = {
  column: PropTypes.PropTypes.shape({ name: PropTypes.string }).isRequired,
  // TODO make type oneOf([...])
  type: PropTypes.oneOf(Object.values(COLUMN_FILTER_TYPES)).isRequired,
  currentFilter: PropTypes.shape({
    field: PropTypes.string,
    operation: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  }),
  dispatchFilterColumn: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  tableIdentifier: PropTypes.string.isRequired,
  setColumnFilter: PropTypes.func,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchFilterColumn: (columnName, filter, pushToHistory) => dispatch(filterColumn(ownProps.tableIdentifier, columnName, filter, pushToHistory)),
});

export default connect(
  null,
  mapDispatchToProps,
)(CustomFilterSelect);
