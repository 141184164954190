import React, { useCallback, useState } from 'react';
import { TSheetsLabourSummary } from './tsheets-labour-summary';
import { ProfitAndLossTable } from './profit-and-loss-table';
import { DetailedProfitAndLossTable } from './detailed-profit-and-loss-table';
import { SideBySideLabourAnalysis } from './side-by-side-labour-analysis';
import { IUserAudit } from '../../../types/user-audit.interface';
import { DtoolsPhaseLabourTable } from './dtools-phase-labour-table';

type ProjectProfitabilityWidgetProps = {
  refreshWidgetData: () => boolean,
  widgetData: {
    user_audit: IUserAudit,
    id: number,
    title: string,
    summary_title: string,
    summary_value: number,
    has_account_lines: boolean,
    quick_books_profit_and_loss_account_lines: {
      id: number,
      title: string,
      value: number,
    }[],
  }[],
  rowData: {
    id: number,
    dtools_id: string,
    quickbooks_subcustomer_id: number,
    client: {
      name: string,
    },
    project_number: string,
  },
  baseRoute: string,
  isLoading: boolean,
  parentId: number,
}

export const ProjectProfitabilityWidget: React.FC<ProjectProfitabilityWidgetProps> = (props) => {
  const {
    isLoading = true,
    baseRoute,
    refreshWidgetData,
    widgetData = [],
    rowData,
    parentId,
  } = props;
  const [tSheetsTotal, setTSheetsTotal] = useState<string|number|null>(null);
  const [showDetail, setShowDetail] = useState(false);

  const handleShowDetailToggle = useCallback(() => {
    setShowDetail(!showDetail);
  }, [setShowDetail, showDetail]);

  return (
    <div className="project-profitability-widget">
      {showDetail && (
        <DetailedProfitAndLossTable
          rowData={rowData}
          baseRoute={baseRoute}
          parentId={parentId}
          handleShowDetailToggle={handleShowDetailToggle}
        />
      )}
      <ProfitAndLossTable
        key={tSheetsTotal}
        rowData={rowData}
        baseRoute={baseRoute}
        widgetData={widgetData}
        isLoading={isLoading}
        refreshWidgetData={(refreshWidgetData)}
        // tSheetsTotal={tSheetsTotal}
        parentId={parentId}
        handleShowDetailToggle={handleShowDetailToggle}
      />
      <TSheetsLabourSummary
        // rowData={rowData}
        baseRoute={baseRoute}
        setTSheetsTotal={(value: number|string) => setTSheetsTotal(value)}
        parentId={parentId}
      />
      <DtoolsPhaseLabourTable
        rowData={rowData}
        // baseRoute={baseRoute}
      />
      <SideBySideLabourAnalysis
        key={`${tSheetsTotal}-2`}
        rowData={rowData}
        parentId={parentId}
        baseRoute={baseRoute}
      />
    </div>
  );
};
