import { createRateLimiter } from './create-rate-limiter';
import { createAutoInvalidatingCache } from './create-auto-invalidating-cache';
import { debugLog } from './helpers';


/**
 * @typedef {
 *  "country_code": string | "AU"
 *  "country_name": string | "Australia",
 *  "city":         string | "Melbourne",
 *  "postal":       string | "3000",
 *  "latitude":     string | "-37.8103",
 *  "longitude":    string | "144.9544",
 *  "IP":           string | "103.31.112.91",
 *  "state":        string | "Victoria",
 * } GeoipResponse
 */


const DEBUG_LOG_TITLE = 'GeoipService';

const geoipApiKey = process.env.REACT_APP_GEOIP_API_KEY;
const geoipEndpoint = `https://geolocation-db.com/json/?${geoipApiKey}`;


/**
 * rate limiter
 */
const MAX_REQUESTS_PER_PERIOD = 5;
const RATE_LIMIT_PERIOD = 60000; // 10 minutes
const LOCAL_STORAGE_LOCATION_RATE_LIMITER_KEY = 'location_v1';
const rateLimiter = createRateLimiter(
  MAX_REQUESTS_PER_PERIOD,
  RATE_LIMIT_PERIOD,
  LOCAL_STORAGE_LOCATION_RATE_LIMITER_KEY,
);


/**
 * cache
 */
const INVALIDATE_CACHED_LOCATION_TIMEOUT = 60000; // 10 minutes
const LOCAL_STORAGE_LOCATION_CACHE_KEY = 'location_v1';
const locationCache = createAutoInvalidatingCache(
  INVALIDATE_CACHED_LOCATION_TIMEOUT,
  LOCAL_STORAGE_LOCATION_CACHE_KEY,
);


/**
 * @description
 * Get a string error message for the Geoip Service
 *
 * @param {string} reason
 * @returns {string}
 */
const getGeoipLocationErrorMessage = (reason) => `Unable to get location: ${reason}`;


/**
 * @description
 * Geoip Service
 * Retrieves the location of the browser based on the ip address
 *
 * @see https://geolocation-db.com/
 *
 * @singleton
 */
export const GeoipService = {
  /**
   * @description
   * Get the browsers geolocation using the geoip service
   *
   * @returns {GeoipResponse}
   */
  getLocation: () => new Promise((resolve, reject) => {
    if (!geoipApiKey) {
      debugLog(DEBUG_LOG_TITLE, 'danger', getGeoipLocationErrorMessage('no geoipApiKey'), '💣');
      reject(new Error(getGeoipLocationErrorMessage('no geoipApiKey')));
    }

    // cache first strategy
    if (locationCache.isValid()) {
      resolve(locationCache.get());
    }
    else if (rateLimiter.isOverLimit()) {
      debugLog(DEBUG_LOG_TITLE, 'danger', getGeoipLocationErrorMessage('too many requests'), '💣');
      reject(new Error(getGeoipLocationErrorMessage('too many requests')));
    }
    else {
      rateLimiter.incrementCount();

      fetch(geoipEndpoint)
        .then((res) => res.json())
        .then((location) => {
          locationCache.set(location);
          resolve(locationCache.get());
        })
        .catch((error) => {
          debugLog(DEBUG_LOG_TITLE, 'danger', getGeoipLocationErrorMessage(error.message), '💣');
          reject(error);
        });
    }
  }),
};
