import { IPortalTreeViewUrlState } from '../../types/portal-tree-view/portal-tree-view-url-state.interface';

/**
 * Take the parameters from the URL that are specific to the portal tree view and convert the contents
 * to a PortalTreeView URL State
 */
export const parsePortalTreeViewUrlState = (urlParams?: string): IPortalTreeViewUrlState => {
  const result: IPortalTreeViewUrlState = {
    id: [],
  };
  if (urlParams) {
    // Split on each of the main URL components
    const urlComponents = urlParams.split(';');

    // Iterate over each component
    urlComponents.forEach((urlComponent) => {
      // Get the Param Key and Value
      const keyValueSplit = urlComponent.split('=');
      const key: string = keyValueSplit[0];
      const value = keyValueSplit[1];

      if (Object.keys(result).includes(key)) {
        // Parse a set of array values separated by commas (,)
        if (Array.isArray(result[key as keyof IPortalTreeViewUrlState])) {
          result[key as keyof IPortalTreeViewUrlState] = value.split(',');
        }

        // Parse a single value
        else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          result[key as keyof IPortalTreeViewUrlState] = value as any;
        }
      }
    });
  }

  return result;
};
