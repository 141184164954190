import React from 'react';
import classNames from 'classnames';

import { FormRendererProps } from '../../../types/poly-form/form-renderer.props';
import { ICompanySpaceRecord } from '../../../types/company/company-space.record.interface';

import { VerticalFormRenderer } from './vertical-form-renderer';
import { CompanyLocationPicker } from '../../form-input/company-location-picker';

export type CompanySpaceFormRendererOptions = {
  showParentLocation?: boolean,
}

export type CompanySpaceFormRendererProps = Omit<FormRendererProps, 'rendererOptions' | 'formData'> & {
  rendererOptions?: CompanySpaceFormRendererOptions,
  formData: ICompanySpaceRecord,
};

/**
 * @todo If showParentLocation is no longer used, this unique type may no longer be required.
 */

export const CompanySpaceFormRenderer:React.FC<CompanySpaceFormRendererProps> = (props) => {
  const {
    className,
    formData,
    rendererOptions = {
      showParentLocation: false,
    },
    onFieldChange,
  } = props;

  const {
    showParentLocation = false,
  } = rendererOptions;

  const showHeaders = (showParentLocation);

  // Render
  return (
    <VerticalFormRenderer
      {...props}
      className={classNames('company-space', className)}
    >
      {showHeaders && (
        <div className="location-form-headers">
          {/* Picker for the Parent Location */}
          {showParentLocation && (
            <div className="field-container form-group row">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                className="control-label col-md-4 col-form-label"
                htmlFor="parent_location"
              >
                <span>Parent Location</span>
              </label>

              <div className="col-md-8">
                <CompanyLocationPicker
                  name="location"
                  id="location"
                  value={formData.location_id ?? null}
                  isClearable
                  isCreatable
                  getNewItemData={() => ({
                    company_id: formData.company_id,
                    company: formData.company,
                  })}
                  searchRoute={`/company/${formData.company_id}/location`}
                  formData={formData}
                  placeholder="No Parent Location"
                  onChange={onFieldChange}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </VerticalFormRenderer>
  );
};
