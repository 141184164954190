import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardHeader } from 'reactstrap';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { NOTIFICATION_TYPE } from '../../constants/notification-type.const';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { IContractCompanySpaceRecord } from '../../types/contact/contract-company-space.record.interface';
import { ILocationRecord } from '../../types/location-record.interface';
import { NewRecordModalResult } from '../../types/modal/modal-result';

import { IContractRecord } from '../../types/service/contract.record.interface';
import { WidgetProps } from '../../types/widget.props';

import { LoadingSpinner } from '../layout-helpers/loading-spinner';
import { ModalContext } from '../modals/modal-context';
import { APIContext } from '../providers/api-provider';
import { NotificationContext } from '../providers/notification-provider';

export type DtoolsLocationRecord = {
  Id: string,
  Abbreviation: string,
  Name: string,
  Description: string,
  FullName: string,
};

export type ContractSpacesImportWidget = Omit<WidgetProps, 'rowData'> & {
  rowData: IContractRecord,
}

/**
 * This widget shows the checklist items which have been left out of the main form view.
 *
 * @todo convince the user form to do a patch instead of a PUT
 */
export const ContractSpacesImportWidget:React.FC<ContractSpacesImportWidget> = (props) => {
  const {
    rowData,
  } = props;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { apiFetch } = useContext(APIContext);
  const { showModal } = useContext(ModalContext);
  const { addNotification } = useContext(NotificationContext);

  const loadLocationsAbortController = useRef<AbortController | null>(null);
  const saveSpaceAbortController = useRef<AbortController | null>(null);

  const contractId = rowData.id;
  const pNumber = rowData.project?.project_number;

  const loadLocations = useCallback(async () => {
    if (loadLocationsAbortController.current) {
      loadLocationsAbortController.current.abort();
    }
    loadLocationsAbortController.current = apiAborter();

    const response = await apiFetch(
      `/dtools-connector/project/${rowData?.project?.dtools_id}/locations`,
      {
        name: 'DToolsProjectLocations:load',
        signal: loadLocationsAbortController.current.signal,
      },
    );

    if (response.success) {
      loadLocationsAbortController.current = null;
      setData(response.body.data || []);
      setLoading(false);
    } else if (!response.aborted) {
      loadLocationsAbortController.current = null;
      // TODO: error state?
      console.error('DTools Project Locations Error', response.error);
      setData([]);
      setLoading(false);
    }
  }, [rowData, apiFetch]);


  /**
   * On component mount
   */
  useEffect(() => {
    // Load the forecasts for the current project
    loadLocations();

    return () => {
      // Kill any outstanding load when the component is un-mounted
      if (loadLocationsAbortController.current) {
        loadLocationsAbortController.current.abort();
      }
    };
  }, [loadLocations]);


  const handleSpaceImportClick = (location: DtoolsLocationRecord) => {
    showModal<NewRecordModalResult<IContractCompanySpaceRecord>>(MODAL_TYPE.NEW_COMPANY_SPACE, {
      initialData: {
        company_id: rowData.client_id,
        company: rowData.client,
        name: location.Name,
        description: location.FullName,
      },
      onModalComplete: (modalResult) => {
        if (!modalResult.newRecord?.id) return false;
        if (saveSpaceAbortController.current) {
          saveSpaceAbortController.current.abort();
        }
        saveSpaceAbortController.current = apiAborter();
        apiFetch(
          `/contract/${rowData.id}/space`,
          {
            name: 'DToolsProjectLocations:load',
            signal: saveSpaceAbortController.current.signal,
            method: HTTP_METHOD.POST,
            body: {
              contract_id: contractId,
              company_space_id: modalResult.newRecord.id,
            },
          },
        ).then((response) => {
          if (response.success) {
            addNotification({
              type: NOTIFICATION_TYPE.SUCCESS,
              headline: 'Space Successfully Imported',
              message: 'The client space was imported and linked to this contract',
              timeout: 5000,
            });
          } else {
            addNotification({
              type: NOTIFICATION_TYPE.DANGER,
              headline: 'Space Not Imported',
              message: `The client space was ${modalResult.newRecord.id ? 'created but not linked to this contract' : 'not created'}.`,
              timeout: 5000,
            });
          }
        });
        return true;
        // @todo Link the space to this contract
        // if (modalResult.success) history.push(`/service-and-support/spaces/${modalResult.newRecord.id}`);
      },
    });
  };

  const handleLocationImportClick = (location: DtoolsLocationRecord) => {
    const locationData = location.Name.split(/[\s]{0,}[,][/s]{0,}/g);
    let locationLength = locationData.length;
    let prefillInitialData: { [key: string]: string|number|undefined } = {
      company_id: rowData.client_id,
      company: rowData.client,
      name: location.Name,
      description: location.FullName,
    };
    if (locationLength > 1) {
      const postCodeMatch = locationData[locationLength - 1].trim().replace(/[\s]/, '').match(/^[\d]*$/);
      if (postCodeMatch) {
        locationLength -= 1;
      }
      prefillInitialData = {
        ...prefillInitialData,
        address_line_1: locationData[locationLength - 3] || undefined,
        address_line_2: locationData[locationLength - 2] || undefined,
        suburb: locationData[locationLength - 1] || undefined,
        postcode: postCodeMatch ? postCodeMatch[0] : undefined,
      };
    }
    showModal<NewRecordModalResult<ILocationRecord>>(MODAL_TYPE.NEW_COMPANY_LOCATION, {
      initialData: prefillInitialData,
      onModalComplete: (modalResult) => {
        addNotification({
          type: NOTIFICATION_TYPE.SUCCESS,
          headline: 'Location Successfully Imported',
          message: `The client location ${modalResult.newRecord?.name || ''} was imported`,
          timeout: 5000,
        });
      },
    });
  };

  return (
    <>
      {loading && <LoadingSpinner />}

      {!loading && (
        <Card>
          <CardHeader className="bg-warning" tag="h4">
            D-Tools Project Locations
          </CardHeader>
          <div className="table-container" style={{ maxHeight: '490px', overflowY: 'auto' }}>
            <table style={{ width: '100%' }} className="table table-striped color-table dark-table">
              <thead>
                <tr>
                  <th>Import As</th>
                  {/* <th>Id</th> */}
                  {/* <th>ProjectId</th> */}
                  <th>Id</th>
                  <th>Name</th>
                  {/* <th>Description</th> */}
                  <th>Full Location Name</th>
                  {/* <th>FullAbbreviation</th> */}
                  {/* <th>FullOrder</th> */}
                  {/* <th>ParentId</th> */}
                  {/* <th>Order</th> */}
                  {/* <th>OldId</th> */}
                  {/* <th>Id2</th> */}
                </tr>
              </thead>
              <tbody>
                {data.length < 1 && (
                  <tr>
                    <td colSpan={8}>No locations listed</td>
                  </tr>
                )}
                {data.map((item: DtoolsLocationRecord) => {
                  const locationName = encodeURIComponent(item.Name);
                  const drilldownLink = `/crm/ordering/project-eta-items?pdt=v=all;ps=15;p=1;cf=project_number:ct+${pNumber},location_full_name:ct+${locationName};_=1`;
                  return (
                    <tr key={`location-${item.Id}`}>
                      <td style={{ textAlign: 'center', width: '15em' }}>
                        <Button
                          name="import"
                          title="import"
                          color="warning"
                          onClick={() => handleLocationImportClick(item)}
                          onKeyPress={() => handleLocationImportClick(item)}
                        >
                          Location
                        </Button>
                        <Button
                          name="import"
                          title="import"
                          color="warning"
                          className="ml-2"
                          onClick={() => handleSpaceImportClick(item)}
                          onKeyPress={() => handleSpaceImportClick(item)}
                        >
                          Space
                        </Button>
                      </td>
                      {/* <td>{item.Id}</td> */}
                      {/* <td>{item.ProjectId}</td> */}
                      <td>{item.Abbreviation}</td>
                      <td>
                        <Link to={drilldownLink}>
                          {item.Name}
                        </Link>
                      </td>
                      {/* <td>{item.Description}</td> */}
                      <td>
                        <Link to={drilldownLink}>
                          {item.FullName}
                        </Link>
                      </td>
                      {/* <td>{item.FullAbbreviation}</td> */}
                      {/* <td>{item.FullOrder}</td> */}
                      {/* <td>{item.ParentId}</td> */}
                      {/* <td>{item.Order}</td> */}
                      {/* <td>{item.OldId}</td> */}
                      {/* <td>{item.Id2}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
          </div>
        </Card>
      )}
    </>
  );
};
