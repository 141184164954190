import React from 'react';

import { DataTablePageProps } from '../../types/data-table-page.props';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';

export type ProcessAllowancesDataTablePageProps = DataTablePageProps;

/**
 * Display the "Process Allowances" data table as a whole page
 */
export const ProcessAllowancesDataTablePage: React.FC<ProcessAllowancesDataTablePageProps> = (props) => (
  <DataTablePage
    {...props}
    tableIdentifier={TABLE_IDENTIFIER.PROCESS_ALLOWANCES_TABLE}
    title="Process Allowances"
  />
);
