import React, { useCallback, useContext } from 'react';

import { CurrentUserContext } from '../providers/current-user-provider';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { IProjectRecord } from '../../types/project/project.record.interface';
import { IFormFieldOverride } from '../../types/poly-form/form-field-override.interface';
import { IFormFieldDefinition } from '../../types/poly-form/form-field-definition.interface';
import { NewRecordFormProps } from '../../types/poly-form/new-record-form.props';

import { APIPolyForm } from './api-poly-form';

import { mapTableToForm } from '../../helpers/map-table-to-form.helper';

import projectTableInitialSettings from '../../table-definitions/project-table';
import { API_ACTION } from '../../constants/api-action.const';

const formFields = mapTableToForm(projectTableInitialSettings);

export type NewProjectFormProps = NewRecordFormProps<IProjectRecord> & {
  formFieldOverrides?: IFormFieldOverride[],
}

export const NewProjectForm: React.FC<NewProjectFormProps> = (props) => {
  const {
    onFormComplete, onFormChange, initialData, formFieldOverrides,
  } = props;

  const { userDetails } = useContext(CurrentUserContext);

  /**
   * Fired when the APIPolyForm successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, id: FormFieldComponentProps['value'], newData?: Partial<IProjectRecord>) => {
    if (onFormComplete) onFormComplete(success, id as IProjectRecord['id'], newData as IProjectRecord);
  }, [onFormComplete]);

  // Render
  return (
    <APIPolyForm
      {...projectTableInitialSettings}
      fields={formFields.map((formField: IFormFieldDefinition) => ({
        ...formField,
        ...(formFieldOverrides ? formFieldOverrides.find((f) => f.name === formField.name) : {}),
      }))}
      formData={{
        owner_id: userDetails.id,
        owner: userDetails,
        ...(initialData ?? {}),
      }}
      permittedActions={projectTableInitialSettings.possibleActions.filter((action) => (action.name === API_ACTION.CREATE))}
      onFormComplete={handleFormComplete}
      onFormChange={onFormChange}
      isNewRecord
      isEditing
    >
      <p>Create a Project, Lead, or Proposal.</p>
      <p>Please ensure the project does not exist already.</p>
      <hr />
    </APIPolyForm>
  );
};
