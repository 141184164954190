import React from 'react';
import { Card, Col, Container, Row, Table } from 'reactstrap';
import { ICON } from '../../constants/icon.const';
import { documentTitle } from '../../utils/helpers';
import PageHeader from '../app-layout/page-header';
import { ReportDownloadButton } from './report-download-button';

interface IReportDownloadsViewProps {
  title: string,
  // eslint-disable-next-line react/no-unused-prop-types
  match: {
    params: {
      [key: string]: string
    }
  }
}

/**
 * Single Internal Project Multiview
 *
 * Allows rendering of a single internal project as either a card-view or a data record view
 *
 * @param {IReportDownloadsViewProps}  props
 * @param {string}  props.title
 * @param {object}  props.match
 */
export const ReportDownloadsView: React.FC<IReportDownloadsViewProps> = ({ title }) => {
  documentTitle(title);
  return (
    <Container fluid className="card-view">
      <PageHeader
        title={title}
      />
      <Row>
        <Col md="6">
          <Card>
            <Table className="info-table table-striped color-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th className="text-center">Download</th>
                  <th className="text-center">Email Me</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Weekly Profitability Report</td>
                  <td>&nbsp;</td>
                  <td>
                    <ReportDownloadButton
                      style={{ width: '100%' }}
                      // eslint-disable-next-line max-len
                      url="/weekly-profitability-report"
                      filename="weekly-project-profitability-report.xlsx"
                      caption="Weekly Profitability Report"
                      icon={ICON.EXCEL_DOWNLOAD}
                      isEmailed
                    />
                  </td>
                </tr>
                <tr>
                  <td>Finance All Projects Report</td>
                  <td>&nbsp;</td>
                  <td>
                    <ReportDownloadButton
                      style={{ width: '100%' }}
                      // eslint-disable-next-line max-len
                      url="/all-projects-report"
                      filename="all-projects-report.xlsx"
                      caption="All Projects Report"
                      icon={ICON.EXCEL_DOWNLOAD}
                      isEmailed
                    />
                  </td>
                </tr>
                <tr>
                  <td>Combined Sales Report</td>
                  <td>&nbsp;</td>
                  <td>
                    <ReportDownloadButton
                      style={{ width: '100%' }}
                      // eslint-disable-next-line max-len
                      url="/combined-sales-report"
                      filename="combined-sales-report.xlsx"
                      caption="Combined Sales Report"
                      icon={ICON.EXCEL_DOWNLOAD}
                      isEmailed
                    />
                  </td>
                </tr>
                <tr>
                  <td>Fortnightly Leave Report</td>
                  <td>&nbsp;</td>
                  <td>
                    <ReportDownloadButton
                      style={{ width: '100%' }}
                      // eslint-disable-next-line max-len
                      url="/fortnightly-leave-report"
                      filename="fortnightly-leave-report.xlsx"
                      caption="Fortnightly Leave Report"
                      icon={ICON.EXCEL_DOWNLOAD}
                      resetAfterSeconds={20}
                      isEmailed
                    />
                  </td>
                </tr>
                <tr>
                  <td>Fortnightly Allowance Report</td>
                  <td>&nbsp;</td>
                  <td>
                    <ReportDownloadButton
                      style={{ width: '100%' }}
                      // eslint-disable-next-line max-len
                      url="/fortnightly-allowance-report"
                      filename="fortnightly-allowance-report.xlsx"
                      caption="Fortnightly Allowance Report"
                      icon={ICON.EXCEL_DOWNLOAD}
                      resetAfterSeconds={20}
                      isEmailed
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
