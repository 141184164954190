/**
 * Creates an Abort Controller so component async tasks can be cancelled
 *
 * @example
 * constructor(props) {
 *   this.abortController = apiAborter();
 * }
 *
 * componentWillUnmount() {
 *   this.abortController.abort();
 * }
 *
 * apiAction({
 *   signal: this.abortController.signal,
 * });
 */
export const apiAborter = (): AbortController => {
  if (window.AbortController) {
    return new window.AbortController();
  }

  return {
    signal: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    abort: () => {},
  } as unknown as AbortController;
};
