import React from 'react';
import { Link } from 'react-router-dom';
import { DataTablePageProps } from '../../types/data-table-page.props';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';

export type ProcessLeaveDataTablePageProps = DataTablePageProps;

/**
 * Display the "Process Leave" data table as a whole page
 */
export const ProcessLeaveDataTablePage: React.FC<ProcessLeaveDataTablePageProps> = (props) => (
  <DataTablePage
    {...props}
    headerChildren={(
      <h4>
        Process Leave Requests
        <Link to="/hrm/national-leave-calendar" className="pull-right btn btn-primary" target="_blank">View National Leave Calendar</Link>
      </h4>
    )}
    tableIdentifier={TABLE_IDENTIFIER.PROCESS_LEAVE_TABLE}
    title="Process Leave Requests"
  />
);
