import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { linkedRouteMap } from '../sidebar/sidebar';

/**
 * @description
 * Helper Fragment. We'll just have to keep iterating over the route until there's nothing left.
 *
 * `routeMap` initialises to a full top level routing map of the application. Nested routes are placed
 * in the `children` of a given route. To calculate the breadcrumbs:
 *  - Search for the next element of the `routePathArray` in the top level of the current `routeMap`
 *  - Dive deeper into the routeMap by calling this function with the found route's `children` as the next `routeMap`,
 *    and having shifted the current route from the `routePathArray`
 *
 * @param {string} currentRunningPath
 * @param {string[] | ['', 'crm', 'projects']} routePathArray path of the url, i.e. `/crm/projects/` --> `['crm', 'projects']`
 * @param {{
 *  icon: string | 'icon-home',
 *  link?: string | '/' | '/comms' | '/dashboards' | '/crm' | '...',
 *  path?: string | '/' | '/comms' | '/dashboards' | '/crm' | '...',
 *  title: string | 'Home' | 'Comms' | 'Dashboards' | 'Project Portal' | '...',
 *  [index: string]: any,
 *
 *  children?: {
 *    icon: string | 'ti-book',
 *    title: string | 'News',
 *    link: string | '/news',
 *    badgeKeys: ( string | 'news' )[]
 *    [index: string]: any,
 *
 *    children?: { [index: string]: any },
 *  }[]
 * }[]} routeMap provided by react router? - note that either link OR path is provided, not both
 */
const mapRoute = (currentRunningPath, routePathArray, routeMap) => {
  // bump the empty leading slash record off
  if (routePathArray[0] === '') routePathArray.shift();
  // shift off the current route and leave the remainder

  /**
   * @type { string | 'crm' } thisRoute
   */
  const currentPathFromPathArray = routePathArray.shift();

  /**
   * find the first `routeMap` element matching the `routePathArray`
   * check `path` and `link` since either could be provided in the routeMap
   *
   * @type {{} | {
   *    title: string | 'Project Portal',
   *    icon: string | 'ti-briefcase',
   *    path: string | '/crm',
   *  }} subRouteObj
   */
  const currentRouteFromRouteMap =
    routeMap.filter((routeFromMap) => `/${currentPathFromPathArray}` === routeFromMap.path
      || `/${currentPathFromPathArray}` === routeFromMap.link)[0] || {};

  /**
   * @type {{
   *    icon: string | 'ti-book',
   *    title: string | 'News',
   *    link: string | '/news',
   *    badgeKeys: ( string | 'news' )[],
   * }[]} nextRouteMap
   */
  const nextRouteMap = currentRouteFromRouteMap.children || [];
  const nextRunningPath = `${currentRunningPath}/${currentPathFromPathArray}`;

  return (
    <>
      <li className="breadcrumb-item">
        {currentRouteFromRouteMap.link !== undefined ? (
          <Link to={nextRunningPath}>{currentRouteFromRouteMap.title}</Link>
        ) : (
          currentRouteFromRouteMap.title || currentPathFromPathArray // @todo find a clever way to know if this means something... (´･_･`)
        )}
      </li>
      {/* If the grandchildren came to the party this is their turn to dance */}
      {routePathArray.length > 0 && mapRoute(nextRunningPath, routePathArray, nextRouteMap)}
    </>
  );
};

/**
 * That helper fragment was crummy.  Here's the real component.
 */
const Breadcrumbs = (props) => {
  const { location } = props;
  const routePathArray = location.pathname.split('/');
  return (
    <ol className="breadcrumb">
      <li className="breadcrumb-item">
        <Link to="/">Home</Link>
      </li>
      {mapRoute('', routePathArray, linkedRouteMap)}
      {/* <li className="breadcrumb-item active">Dashboard</li> */}
    </ol>
  );
};

Breadcrumbs.defaultProps = {
  //
};

Breadcrumbs.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default withRouter(Breadcrumbs);
