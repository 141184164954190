import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { DATA_TYPE } from './sales-forecast-helpers';

class SalesForecastTableRow extends React.Component {
  /**
   * @description
   * Fired when the user's mouse enters the row
   */
  handleMouseEnter = () => {
    const {
      onBeginHover, dataType, id,
    } = this.props;
    if (typeof onBeginHover === 'function') onBeginHover(dataType, id);
  }


  /**
   * @description
   * Fired when the user's mouse leaves the row
   */
  handleMouseLeave = () => {
    const {
      onEndHover, dataType, id,
    } = this.props;
    if (typeof onEndHover === 'function') onEndHover(dataType, id);
  }


  /**
   * @description
   * Fired when the user clicks the row
   */
  handleClick = (e) => {
    const {
      onClick, dataType, id,
    } = this.props;
    if (typeof onClick === 'function') {
      e.preventDefault();
      onClick(dataType, id);
    }
  }


  /**
   * @inheritdoc
   */
  render() {
    const {
      children, trackHover, className, dataType, id, onClick,
    } = this.props;
    return (
      <tr
        className={classNames(
          className,
          {
            expandable: (typeof onClick === 'function'),
          },
        )}
        data-data-type={dataType}
        data-id={id}
        onMouseEnter={trackHover ? this.handleMouseEnter : null}
        onMouseLeave={trackHover ? this.handleMouseLeave : null}
        onClick={this.handleClick}
      >
        {children}
      </tr>
    );
  }
}

SalesForecastTableRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dataType: PropTypes.oneOf(Object.values(DATA_TYPE)),
  id: PropTypes.string,
  trackHover: PropTypes.bool,

  onClick: PropTypes.func,
  onBeginHover: PropTypes.func,
  onEndHover: PropTypes.func,
};

SalesForecastTableRow.defaultProps = {
  className: null,
  children: null,
  dataType: null,
  id: null,
  trackHover: false,

  onClick: null,
  onBeginHover: null,
  onEndHover: null,
};

export default SalesForecastTableRow;
