import { SORT_DIRECTION } from '@corporate-initiatives/ci-portal-js-sdk';
import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { ICON } from '../constants/icon.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { monthsTextInputToolTipHtml } from '../components/form-input/months-text-input';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';
import { THEME_COLOR } from '../constants/theme-color.const';
import { CONTRACT_STATUS } from '../constants/contract-status.const';
import { MODAL_TYPE } from '../constants/modal-type.const';
import { PERMISSION } from '../constants/permissions.const';
import { DATA_TABLE_FLAG } from '../constants/data-table-flag.const';

/**
 * Table Definition for People (Contracts) Table
 */
const contractsTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Contracts',
  initialFormFocusFieldName: 'first',
  availableFlags: [DATA_TABLE_FLAG.MY_STATE, DATA_TABLE_FLAG.NATIONAL],
  baseRoute: '/contract',
  baseQueryString: [
    'with[]=type',
    'with[]=subtype',
    'with[]=managedByState:id,acronym',
    'with[]=status:id,name',
    'with[]=billingType:id,name',
    'with[]=client:id,name',
    'with[]=provider:id,name',
    'with[]=project:id,project_number,partner_program_id,dtools_id,name',
    'with[]=renewedFrom:id,type_id,status_id,number',
    'with[]=renewedFrom.type:id,prefix',
    'with[]=renewedTo:id,type_id,status_id,number',
    'with[]=renewedTo.type:id,prefix',
    'with[]=region:id,name',
    'with_count[]=note',
    'with_count[]=serial',
    'with_count[]=companySpace',
    'with_count[]=invoiceRequests',
  ].join('&'),

  /**
   * Reset the local storage settings if they were stored with any of these app versions
   * Add application version numbers to this list (make sure it is a PREVIOUS application
   * version number which reflects a now invalid set of settings)
   */
  resetForAppVersion: [
    '2.1.6',
    '2.7.9',
    '2.7.10',
  ],

  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'number',
        'name',
        'project',
        'project_name',
        'client',
        'type',
        'subtype',
        'region',
        'status',
        'managed_by_state',
        'contract_file_link',
        'note',
        'start_date',
        'end_date',
        'provider',
        'billing_type',
        'expires_in_days',
        'renewed_from',
        'renewed_to',
        'contract_value',
        'contract_months',
        'is_renewable',
        'auto_renew',
        'works_require_approval',
        'preventative_maintenance_included',
        'preventative_maintenance_scheduled',
        'welcome_pack_sent',
        'reminder_months',
      ],
    },
    {
      name: 'renewal',
      title: 'Renewal View',
      fields: [
        'number',
        'client',
        'subtype',
        'start_date',
        'end_date',
        'expires_in_days',
        'status',
        'contract_value',
        'contract_months',
        'billing_type',
        'project',
        'note',
        'provider',
        'reminder_months',
        'is_renewable',
        'auto_renew',
        'works_require_approval',
      ],
    },
    {
      name: 'support',
      title: 'Support View',
      fields: [
        'number',
        'client',
        'project',
        'subtype',
        'note',
        'status',
        'expires_in_days',
        'billing_type',
        'region',
        'provider',
        'start_date',
        'end_date',
        'contract_value',
        'contract_months',
        'reminder_months',
        'is_renewable',
        'auto_renew',
        'works_require_approval',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'number',
        'start_date',
        'end_date',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'number',
        'name',
        'client',
        'project',
        'type',
        'subtype',
        'note',
        'count_of_note',
        'status',
        'managed_by_state',
        'contract_file_link',
        'billing_type',
        'region',
        'provider',
        'start_date',
        'end_date',
        'expires_in_days',
        'contract_value',
        'contract_months',
        'reminder_months',
        'count_of_serial',
        'count_of_companySpace',
        'is_renewable',
        'auto_renew',
        'works_require_approval',
        'preventative_maintenance_included',
        'preventative_maintenance_scheduled',
        'welcome_pack_sent',
        'custom_metadata',
      ],
    },
  ],

  /**
   * Definition of columns and APIPolyForm fields for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Identity',
      description: 'Contract Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      linkRoute: '/service-and-support/contracts',
      visible: false,
      showInForm: false,
      order: 100,
      formOrder: 100,
    }, {
      name: 'number',
      title: 'Contract Number',
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      format: COLUMN_FORMAT.NAME_LINK_SHORT,
      linkRoute: '/service-and-support/contracts',
      sortColumn: 'id',
      visible: true,
      order: 110,
      formOrder: 110,
    }, {
      name: 'name',
      title: 'Name',
      description: 'Contract Name',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      order: 112,
      formOrder: 112,
    }, {
      name: 'project',
      title: 'From Project',
      format: COLUMN_FORMAT.OBJECT,
      placeholder: 'P12345ABC',
      object: {
        sourceField: 'project',
        key: 'project_number',
        format: COLUMN_FORMAT.P_NUMBER,
        listSource: 'project',
      },
      visible: true,
      sortColumn: 'project.id',
      formSaveField: 'project_id',
      formFieldType: FORM_FIELD_TYPE.PROJECT_PICKER,
      order: 113,
      formOrder: 113,
    }, {
      name: 'project_name',
      title: 'Project Name',
      format: COLUMN_FORMAT.OBJECT,
      placeholder: 'P12345ABC',
      object: {
        sourceField: 'project',
        key: 'name',
        format: COLUMN_FORMAT.LONG_TEXT,
        listSource: 'project',
      },
      visible: true,
      sortColumn: 'project.id',
      formSaveField: 'project_id',
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 115,
      formOrder: 115,
    }, {
      name: 'client',
      title: 'Client',
      description: 'The contract client',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'client',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/crm/companies',
        listSource: 'company',
      },
      formFieldType: FORM_FIELD_TYPE.COMPANY_PICKER,
      formSaveField: 'client_id',
      sortColumn: 'client.name',
      visible: true,
      showInForm: true,
      isCreatable: true,
      order: 117,
      formOrder: 117,
    }, {
      name: 'type',
      title: 'Contract Type',
      format: COLUMN_FORMAT.OBJECT,
      formFieldType: FORM_FIELD_TYPE.CONTRACT_TYPE_PICKER,
      object: {
        key: 'name',
        sourceField: 'type',
        formSaveField: 'type_id',
        listSource: 'contractType',
      },
      visible: true,
      sortColumn: 'type.name',
      filterOnField: 'type_id',
      order: 130,
      formOrder: 130,
    }, {
      name: 'subtype',
      title: 'Contract Subtype',
      format: COLUMN_FORMAT.OBJECT,
      formFieldType: FORM_FIELD_TYPE.CONTRACT_SUBTYPE_PICKER,
      object: {
        key: 'name',
        sourceField: 'subtype',
        formSaveField: 'subtype_id',
        listSource: 'contractSubtype',
      },
      sortColumn: 'subtype.name',
      filterOnField: 'subtype_id',
      visible: true,
      order: 140,
      formOrder: 140,
    }, {
      name: 'region',
      title: 'Service Region',
      format: COLUMN_FORMAT.OBJECT,
      formFieldType: FORM_FIELD_TYPE.CONTRACT_REGION_PICKER,
      object: {
        key: 'name',
        sourceField: 'region',
        formSaveField: 'region_id',
      },
      sortColumn: 'region.name',
      filterOnField: 'region.name',
      visible: true,
      order: 160,
      formOrder: 160,
    }, {
      name: 'status',
      title: 'Contract Status',
      format: COLUMN_FORMAT.OBJECT,
      formFieldType: FORM_FIELD_TYPE.CONTRACT_STATUS_PICKER,
      object: {
        key: 'name',
        sourceField: 'status',
        formSaveField: 'status_id',
        format: COLUMN_FORMAT.STATUS_BADGE,
        listSource: 'contractStatus',
      },
      visible: true,
      order: 170,
      formOrder: 170,
    },
    {
      name: 'managed_by_state',
      title: 'Managed by State',
      format: COLUMN_FORMAT.OBJECT,
      description: 'Where will the contract be managed from?',
      object: {
        sourceField: 'managed_by_state',
        key: 'acronym',
        format: COLUMN_FORMAT.SHORT_TEXT,
        listSource: 'state',
      },
      formFieldType: FORM_FIELD_TYPE.STATE_PICKER,
      formSaveField: 'managed_by_state_id',
      visible: true,
      showInForm: true,
      required: true,
      order: 172,
      formOrder: 172,
    }, {
      name: 'contract_file_link',
      title: 'External Document Link',
      description: 'Paste any file link, including SharePoint, OneDrive and Wiki links to contract details.',
      formFieldToolTip: 'Paste any file link, including SharePoint, OneDrive and Wiki links to contract details.',
      format: COLUMN_FORMAT.ABBREVIATED_EXT_LINK,
      visible: true,
      showInForm: true,
      order: 175,
      formOrder: 175,
    }, {
      name: 'note',
      title: 'Note',
      visible: true,
      format: COLUMN_FORMAT.HTML_NOTIN_TABLE,
      formFieldType: FORM_FIELD_TYPE.HTML_INPUT,
      order: 180,
      formOrder: 180,
    }, {
      name: 'start_date',
      title: 'Start Date',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: true,
      order: 182,
      formOrder: 182,
    }, {
      name: 'end_date',
      title: 'End Date',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: true,
      order: 184,
      formOrder: 184,
    }, {
      name: 'provider',
      title: 'Provider',
      description: 'The company providing the contract where that differs to Ci',
      formFieldToolTip: '<p>The company providing the contract where that differs to Ci</p><p>ie:</p><ul><li>Signogy</li><li>CISCO</li><li>Crestron</li><li>etc</li></ul>',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'provider',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/crm/companies',
      },
      sortColumn: 'provider.name',
      formFieldType: FORM_FIELD_TYPE.COMPANY_PICKER,
      formSaveField: 'provider_id',
      visible: true,
      isCreatable: true,
      order: 190,
      formOrder: 190,
    }, {
      name: 'billing_type',
      title: 'Contract Billing Type',
      format: COLUMN_FORMAT.OBJECT,
      formFieldType: FORM_FIELD_TYPE.CONTRACT_BILLING_TYPE_PICKER,
      object: {
        key: 'name',
        sourceField: 'billing_type',
        formSaveField: 'billing_type_id',
      },
      sortColumn: 'billingType.name',
      filterOnField: 'billingType.name',
      visible: true,
      order: 200,
      formOrder: 200,
    }, {
      name: 'expires_in_days',
      title: 'Renewal Due',
      format: COLUMN_FORMAT.NULLABLE_NUMBER,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      order: 220,
      formOrder: 220,
    }, {
      name: 'renewed_from',
      title: 'Renewed From',
      description: 'If the Contract is a renewal, this field shows the previous Contract Number.',
      formFieldToolTip: 'If the Contract is a renewal, this field shows the previous Contract Number.',
      formFieldType: FORM_FIELD_TYPE.CONTRACT_PICKER,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'renewed_from',
        key: 'number',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/service-and-support/contracts',
      },
      sortColumn: 'renewedFrom.number',
      filterOnField: 'renewedFrom.number',
      visible: true,
      order: 230,
      formOrder: 230,
    }, {
      name: 'renewed_to',
      title: 'Renewed As',
      description: 'If the Contract has been renewed, this field shows the Contract Number for the new contract.',
      formFieldToolTip: 'If the Contract has been renewed, this field shows the Contract Number for the new contract.',
      formFieldType: FORM_FIELD_TYPE.CONTRACT_PICKER,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'renewed_to',
        key: 'number',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/service-and-support/contracts',
      },
      sortColumn: 'renewedTo.number',
      filterOnField: 'renewedTo.number',
      visible: true,
      order: 240,
      formOrder: 240,
    }, {
      name: 'contract_value',
      title: 'Contract Value',
      format: COLUMN_FORMAT.WHOLE_DOLLARS,
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
      visible: true,
      order: 250,
      formOrder: 250,
    }, {
      name: 'contract_months',
      title: 'Contract Months',
      description: 'Length of the contract in months',
      format: COLUMN_FORMAT.NUMBER,
      formFieldType: FORM_FIELD_TYPE.MONTHS_TEXT_INPUT,
      formFieldToolTip: monthsTextInputToolTipHtml,
      visible: true,
      order: 260,
      formOrder: 260,
    }, {
      name: 'is_renewable',
      title: 'Can be Renewed?',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: true,
      order: 270,
      formOrder: 270,
    }, {
      name: 'auto_renew',
      title: 'Auto Renew Enabled?',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: true,
      order: 280,
      formOrder: 280,
    }, {
      name: 'works_require_approval',
      title: 'Works Require Approval',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: true,
      order: 290,
      formOrder: 290,
    }, {
      name: 'preventative_maintenance_included',
      title: 'Maintenance Included?',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: true,
      order: 292,
      formOrder: 292,
    }, {
      name: 'preventative_maintenance_scheduled',
      title: 'Maintenance Scheduled?',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: true,
      order: 295,
      formOrder: 295,
    }, {
      name: 'welcome_pack_sent',
      title: 'Welcome Pack Sent?',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: true,
      order: 298,
      formOrder: 298,
    }, {
      name: 'reminder_months',
      title: 'Reminder Months',
      description: 'How many months in advance should the reminder be triggered?',
      format: COLUMN_FORMAT.NUMBER,
      formFieldType: FORM_FIELD_TYPE.MONTHS_TEXT_INPUT,
      formFieldToolTip: monthsTextInputToolTipHtml,
      visible: true,
      order: 300,
      formOrder: 300,
    }, {
      name: 'custom_metadata',
      title: 'Custom Metadata',
      visible: false,
      showInForm: false,
      order: 310,
      formOrder: 310,
    }, {
      name: 'count_of_note',
      title: 'Count of Notes',
      description: 'How many notes have been created for this contract.',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 320,
      formOrder: 320,
      showInForm: false,
      isReadOnly: true,
    }, {
      name: 'count_of_serial',
      title: 'Count of Serial Numbers',
      description: 'How many serial or licence numbers are assigned to this contract.',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 330,
      formOrder: 330,
      showInForm: false,
      isReadOnly: true,
    }, {
      name: 'count_of_companySpace',
      title: 'Count of Company Spaces',
      description: 'How many company spaces are assigned to this contract.',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      showInForm: false,
      isReadOnly: true,
      order: 340,
      formOrder: 340,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add a Contract',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new Contract',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the contract',
      successMessage: 'Updated contracts',
      failMessage: 'Failed to update Contract',
      hideRecordActionButton: true,
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the Contract',
      successMessage: 'Contract deleted',
      failMessage: 'Failed to delete Contract',
      hideRecordActionButton: false,
    },
    {
      name: 'renew',
      title: 'Renew',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.REFRESH,
      description: 'Renew the contract',
      successMessage: 'Contract Renewed',
      failMessage: 'Failed to renew the contract',
      modalType: MODAL_TYPE.RENEW_CONTRACT,
      hideRecordActionButton: false,
    },
    // {
    //   name: 'create-contract-folder',
    //   title: 'Create the Contract Folder',
    //   color: BUTTON_COLOR.SUCCESS,
    //   icon: ICON.SERVICE_CONTRACTS_FOLDER,
    //   description: 'You are about to create a contract folder on SharePoint...',
    //   successMessage: 'Contract Folder Created',
    //   failMessage: 'Failed to create the contract folder',
    // },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Contract\'s Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'serials',
      title: 'Serials',
      description: 'Contract Serials',
      widgetDefinition: WIDGET_DEFINITION.CONTRACT_SERIALS,
      isVisible: (rowData) => rowData.type?.name === 'Product Licence',
      apiRoute: '/serial',
      badgeCountFields: [
        {
          fieldName: 'count_of_serial',
          badgeColor: THEME_COLOR.DARK,
        },
      ],
    },
    {
      name: 'files',
      title: 'Files',
      apiRoute: '/drive-items',
      apiQuery: '',
      description: 'Contract\'s Files',
      widgetDefinition: WIDGET_DEFINITION.DRIVE_FILES,
    },
    {
      name: 'notes',
      title: 'Notes',
      description: 'Notes',
      widgetDefinition: WIDGET_DEFINITION.CONTRACT_NOTE,
      apiRoute: '/note',
      apiQuery: [
        'with[]=createdBy:id,name',
        'with[]=type:id,name',
        'pagelength=100',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'count_of_note',
          badgeColor: THEME_COLOR.DARK,
        },
      ],
    },
    {
      name: 'subtypes',
      title: 'Subtypes',
      description: 'Contract Subtypes',
      widgetDefinition: WIDGET_DEFINITION.CONTRACT_CONTRACT_SUBTYPES,
      isVisible: (rowData) => rowData.subtype?.name === 'Combination Support Package',
      apiRoute: '/subtype',
      apiQuery: 'with[]=contract:id,status_id,type_id&with[]=contractSubtype&pagelength=100',
    },
    {
      name: 'spaces',
      title: 'Spaces',
      description: 'Contract Spaces',
      widgetDefinition: WIDGET_DEFINITION.CONTRACT_SPACES,
      apiRoute: '/space',
      apiQuery: 'with[]=contract:id,status_id,type_id&with[]=subtype&with[]=companySpace&with[]=companySpace.company:id,name&with[]=companySpace.type:id,name,icon&pagelength=100',
      badgeCountFields: [
        {
          fieldName: 'count_of_companySpace',
          badgeColor: THEME_COLOR.DARK,
        },
      ],
    },
    {
      name: 'space-import',
      title: 'Space Import',
      description: 'Contract Spaces Import',
      widgetDefinition: WIDGET_DEFINITION.CONTRACT_SPACES_IMPORT,
    },
    {
      name: 'scheduled-checks',
      title: 'Scheduled Checks',
      description: 'Scheduled Checks',
      widgetDefinition: WIDGET_DEFINITION.CONTRACT_SCHEDULED_CHECKS,
      apiRoute: '/scheduled-check',
    },
    {
      name: 'quickbooks',
      title: 'QuickBooks',
      description: 'QuickBooks & QuickBooks Time Details',
      widgetDefinition: WIDGET_DEFINITION.CONTRACT_QUICKBOOKS,
    },
    {
      name: 'invoice-requests',
      title: 'Invoice Requests',
      description: 'Client Invoice Requests',
      widgetDefinition: WIDGET_DEFINITION.CONTRACT_INVOICE_REQUESTS,
      apiRoute: '/invoice-request',
      apiQuery: [
        'with[]=type:id,name',
        'with[]=status:id,name',
        'with[]=billToContact:id,first,last',
        'with[]=billToCustomer:id,name',
        'pagelength=0',
        'with_count[]=details',
        'with_sum[]=details.total_price',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'count_of_invoiceRequests',
          badgeColor: THEME_COLOR.DARK,
        },
      ],
      permissions: [
        PERMISSION.INVOICE_REQUEST_VIEWER,
      ],
    },
    {
      ...eventsTabDefault,
      description: "Contract's Event Audit Trail",
    },
  ],

  /**
   * View definitions
   */
  viewKey: 'all',
  views: [
    {
      key: 'all',
      title: 'All Contracts',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.MY_STATE],
          title: 'State Contracts',
        },
        {
          flags: [DATA_TABLE_FLAG.NATIONAL],
          title: 'National Contracts',
        },
      ],
      filters: [],
      flags: [],
    },
    {
      key: 'expiry-view',
      title: 'Overdue and Expiring',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.MY_STATE],
          title: 'State Overdue and Expiring',
        },
        {
          flags: [DATA_TABLE_FLAG.NATIONAL],
          title: 'National Overdue and Expiring',
        },
      ],
      filters: [
        {
          field: 'expires_in_days',
          operation: API_FILTER_OPERATION.BETWEEN,
          values: [-90, 90],
        },
        {
          field: 'status_id',
          operation: API_FILTER_OPERATION.IN,
          values: [
            CONTRACT_STATUS.ACTIVE,
            CONTRACT_STATUS.EXPIRED,
            CONTRACT_STATUS.SUSPENDED,
          ],
        },
      ],
      sortedColumns: [
        {
          name: 'expires_in_days',
          direction: SORT_DIRECTION.ASC,
          sortIndex: 0,
        },
      ],
      flags: [DATA_TABLE_FLAG.MY_STATE],
    },
    {
      key: 'support-only',
      title: 'Support Contracts Only',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.MY_STATE],
          title: 'State Support Contracts',
        },
        {
          flags: [DATA_TABLE_FLAG.NATIONAL],
          title: 'National Support Contracts',
        },
      ],
      filters: [
        {
          field: 'type.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['Ci Support Contract'],
        },
      ],
      sortedColumns: [
        {
          name: 'expires_in_days',
          direction: SORT_DIRECTION.ASC,
          sortIndex: 0,
        },
      ],
      flags: [],
    },
    {
      key: 'licences-only',
      title: 'Licences Only',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.MY_STATE],
          title: 'State Licenses',
        },
        {
          flags: [DATA_TABLE_FLAG.NATIONAL],
          title: 'State National',
        },
      ],
      filters: [
        {
          field: 'type.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['Product Licence'],
        },
      ],
      sortedColumns: [
        {
          name: 'expires_in_days',
          direction: SORT_DIRECTION.ASC,
          sortIndex: 0,
        },
      ],
      flags: [],
    },
    {
      key: 'support-dlp-ddf',
      title: 'Support, DLP and DDF',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.MY_STATE],
          title: 'State Support, DLP and DDF',
        },
        {
          flags: [DATA_TABLE_FLAG.NATIONAL],
          title: 'National Support, DLP and DDF',
        },
      ],
      filters: [
        {
          field: 'type.name',
          operation: API_FILTER_OPERATION.IN,
          values: ['Ci Support Contract', 'Draw Down Fund', 'Defect Liability Period'],
        },
      ],
      sortedColumns: [
        {
          name: 'expires_in_days',
          direction: SORT_DIRECTION.ASC,
          sortIndex: 0,
        },
      ],
      flags: [],
    },
    {
      key: 'active',
      title: 'Active Contracts',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.MY_STATE],
          title: 'State Active Contracts',
        },
        {
          flags: [DATA_TABLE_FLAG.NATIONAL],
          title: 'National Active Contracts',
        },
      ],
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['Active'],
        },
      ],
      flags: [],
    },
  ],
};

export default contractsTableInitialSettings;
