import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { CompanyDataTablePage } from '../data-table-pages/company.data-table-page';
import { DtoolsChangeOrderDataTablePage } from '../data-table-pages/dtools-change-order.data-table-page';
import { OrderConfirmationDataTablePage } from '../data-table-pages/order-confirmation.data-table-page';
import { ProjectItemEtaItemsTablePage } from '../data-table-pages/project-item-eta-items.data-table-page';
import { ProjectItemEtaSummaryTablePage } from '../data-table-pages/project-item-eta-summary.data-table-page';
import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import FourOhFour from '../error-pages/four-oh-four';
import ProjectDetailPage from '../record-detail-page/project-detail-page';
import StockOrdersPage from '../dtools-information/stock-orders-page';
import { ContactDataTablePage } from '../data-table-pages/contact.data-table-page';
import RecordDetailPage, { TAB_REGEX } from '../record-detail-page/record-detail-page';
import { ProjectDataTablePage } from '../data-table-pages/project.data-table-page';
import { ProjectSchedulePage } from '../project-scheduling/project-schedule-page';

const CrmRoutes = () => (
  <Switch>
    {/**
     *  Contact Routes
     */}
    <Route
      exact
      path="/crm/contacts"
      render={(props) => <ContactDataTablePage {...props} title="Contacts" />}
    />
    <Route // view manage individual leave application
      exact
      path={`/crm/contacts/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} title="Contact" tableIdentifier={TABLE_IDENTIFIER.CONTACT_TABLE} />}
    />
    {/**
     *  Company Routes
     */}
    <Route
      exact
      path="/crm/companies"
      render={(props) => <CompanyDataTablePage {...props} title="Companies" />}
    />
    <Route // view manage individual leave application
      exact
      path={`/crm/companies/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} title="Company" tableIdentifier={TABLE_IDENTIFIER.COMPANY_TABLE} />}
    />
    {/**
     * Project Routes
     */}
    <Route
      exact
      path="/crm/projects"
      render={(props) => <ProjectDataTablePage {...props} title="Leads, Proposals &amp; Projects" />}
    />
    <Route // view an individual project
      exact
      path={`/crm/projects/:id/${TAB_REGEX}`}
      render={(props) => <ProjectDetailPage {...props} title="View Project" tableIdentifier={TABLE_IDENTIFIER.PROJECT_TABLE} />}
    />
    <Redirect exact from={`/crm/project/:id/${TAB_REGEX}`} to={`/crm/projects/:id/${TAB_REGEX}`} />
    <Route
      exact
      path="/crm/order-confirmation"
      render={(props) => <OrderConfirmationDataTablePage {...props} title="Order Confirmation" />}
    />
    <Route // Project Schedule
      exact
      path="/crm/project-schedule"
      render={(props) => (
        <Suspense fallback={(
          <div className="p-5 text-center">
            <Spinner />
          </div>
        )}
        >
          <ProjectSchedulePage {...props} title="Project Scheduling" tableIdentifier={TABLE_IDENTIFIER.PROJECT_TABLE} />
        </Suspense>
      )}
    />
    {/**
     * ETA Data
     */}
    <Route
      exact
      path="/crm/ordering/project-eta-summary"
      render={(props) => <ProjectItemEtaSummaryTablePage {...props} title="Project Item ETA Summary" />}
    />
    <Route
      exact
      path="/crm/ordering/project-eta-items"
      render={(props) => <ProjectItemEtaItemsTablePage {...props} filterColumn="" title="Project Item ETA Detail" />}
    />
    <Route
      exact
      path="/crm/ordering/project-eta-items/project/:id"
      render={(props) => <ProjectItemEtaItemsTablePage {...props} filterColumn="number" title="Item ETA Detail for project" />}
    />
    <Route
      exact
      path="/crm/ordering/project-eta-items/vendor/:id"
      render={(props) => <ProjectItemEtaItemsTablePage {...props} filterColumn="vendor" title="ETA Detail for vendor" />}
    />
    <Route
      exact
      path="/crm/ordering/project-eta-items/manufacturer/:id"
      render={(props) => <ProjectItemEtaItemsTablePage {...props} filterColumn="manufacturer" title="ETA Detail for manufacturer" />}
    />
    <Route
      exact
      path="/crm/ordering/project-eta-items/model/:id"
      render={(props) => <ProjectItemEtaItemsTablePage {...props} filterColumn="model" title="ETA Detail for model" />}
    />
    <Route
      exact
      path="/crm/ordering/project-eta-items/order/:id"
      render={(props) => <ProjectItemEtaItemsTablePage {...props} filterColumn="order_number" title="ETA Detail for order" />}
    />
    <Route
      exact
      path="/crm/ordering/project-change-orders"
      render={(props) => <DtoolsChangeOrderDataTablePage {...props}title="Project Change Orders" />}
    />
    {/**
      *  Stock Orders
      */}
    <Route
      exact
      path="/crm/ordering/stock-orders"
      render={(props) => <StockOrdersPage {...props} title="Stock Orders" />}
    />
    {/**
      *  No match
      */}
    <Route exact path="/crm/*" component={FourOhFour} />
  </Switch>
);

export default CrmRoutes;
