import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, ButtonGroup, Card, CardBody } from 'reactstrap';
import { FORM_RENDERER_TYPE } from '../../constants/form-renderer-type.const';
import { AN_INTERNAL_PROJECT_STATUS, INTERNAL_PROJECT_STATUS_NAME_MAP } from '../../constants/internal-project-status.const';
import { mapSelectInputOptions } from '../../helpers/map-select.helper';
import { mapTableToForm } from '../../helpers/map-table-to-form.helper';
import internalProjectTasksTableInitialSettings from '../../table-definitions/internal-project-tasks-table';
import internalProjectsTableInitialSettings from '../../table-definitions/internal-projects-table';
import { IInternalProjectRecord } from '../../types/internal-project/internal-project.record.interface';
import { FormFieldChangeProps } from '../../types/poly-form/form-field-change-props';
import { ConstSelect } from '../form-input/const-select';
import Icon from '../layout-helpers/icon';
import { APIPolyForm } from '../poly-forms/api-poly-form';
import { CardSwimlanes } from './card-swimlanes';

interface IInfiniteCardViewProps {
  data: IInternalProjectRecord[],
  totalRecords: number,
  searchTerm?: null | string,
  loadNextPage: () => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeSorterOrderSelect: (event: any) => void,
  onSearch: (term: string) => void,
  onStatusFilter: (status: AN_INTERNAL_PROJECT_STATUS) => void
}

export const InfiniteCardView: React.FC<IInfiniteCardViewProps> = ({ data, totalRecords, searchTerm, loadNextPage, changeSorterOrderSelect, onSearch, onStatusFilter }) => {
  const [visibleSwimlanes, setVisibleSwimlanes] = useState<{[key: number]: true}>([]);

  // initialise editable characteristics from the table settings
  const cardActions = internalProjectsTableInitialSettings.possibleActions;
  const formFields = mapTableToForm(internalProjectsTableInitialSettings);

  const [selectedStatus, setSelectedStatus] = useState<null | AN_INTERNAL_PROJECT_STATUS>(null);

  const [editingRecord, setEditingRecord] = useState<number | null>(null);

  const toggleSwimlane = (id: number) => {
    const newVisibleSwimlanes = { ...visibleSwimlanes };
    if (visibleSwimlanes[id]) {
      delete newVisibleSwimlanes[id];
    } else {
      newVisibleSwimlanes[id] = true;
    }
    setVisibleSwimlanes(newVisibleSwimlanes);
  };

  return (
    <InfiniteScroll
      className="project-card-view-infinite-scroll"
      dataLength={data.length}
      next={loadNextPage}
      hasMore={data.length < totalRecords}
      loader={(
        <div className="loader" key={0}>
          <Icon i="rolling" size="2x" />
          Loading
        </div>
      )}
    >
      <Card color="primary" className="text-light">
        <CardBody className="header-body">
          <h3 style={{ fontWeight: 100 }}>
            Projects and Tasks by Priority
          </h3>
          <div className="form-inline">
            <label htmlFor="sortOrderSelect">
              Sort Order: &nbsp; &nbsp;
              <select name="sortOrderSelect" className="form-control" onChange={changeSorterOrderSelect}>
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </label>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="statusSelect" className="pl-3">
              Status: &nbsp; &nbsp;
              <div style={{ width: '200px' }} className="form-vertical">
                <ConstSelect
                  id="statusSelect"
                  name="status"
                  formSaveField="status"
                  hasError={false}
                  value={selectedStatus}
                  disabled={false}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(field: FormFieldChangeProps<any, AN_INTERNAL_PROJECT_STATUS>) => {
                    setSelectedStatus(field.newValue);
                    onStatusFilter(field.newValue);
                  }}
                  isClearable
                  inputOptions={mapSelectInputOptions(INTERNAL_PROJECT_STATUS_NAME_MAP)}
                />
              </div>
            </label>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <ButtonGroup className="table-search-controls pl-2">
              <input
                onChange={(event) => onSearch(event.target.value)}
                value={searchTerm || undefined}
                // onBlur={handleBlurSearch}
                // onFocus={handleFocusSearch}
                type="search"
                className="form-control search-default"
                aria-controls="data-table"
                placeholder="Search..."
                style={{ backgroundColor: 'white' }}
                // onKeyDown={(event) => onSearch(event.target.value)}
              />
              <Button color="secondary" title="Search" active={!!searchTerm}>
                <Icon i="search" />
              </Button>

            </ButtonGroup>
          </div>
        </CardBody>
      </Card>
      {data && data.map((project: IInternalProjectRecord) => (
        <div key={project.id}>
          <APIPolyForm<IInternalProjectRecord>
            {...internalProjectsTableInitialSettings}
            formRendererType={FORM_RENDERER_TYPE.PROJECT_CARD}
            permittedActions={cardActions}
            fields={formFields}
            rendererOptions={{
              onToggleSwimlanes: () => toggleSwimlane(project.id),
              showSwimlanes: visibleSwimlanes[project.id],
            }}
            onStartEditRecord={() => setEditingRecord(project.id)}
            onEndEditRecord={() => setEditingRecord(null)}
            isEditing={editingRecord === project.id}
            key={project.id}
            formData={project}
          />
          {visibleSwimlanes[project.id] && (
            <Card>
              <CardSwimlanes
                cardItems={project?.item || []}
                cardLinkPath="/internal-projects/tasks"
                tableSettings={internalProjectTasksTableInitialSettings}
              />
            </Card>
          )}
        </div>
      ))}
    </InfiniteScroll>
  );
};

