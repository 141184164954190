import React, { useCallback, useContext } from 'react';

import { CurrentUserContext } from '../providers/current-user-provider';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { ILeaveApplicationRecord } from '../../types/leave/leave-application.record.interface';
import { NewRecordFormProps } from '../../types/poly-form/new-record-form.props';

import { APIPolyForm } from './api-poly-form';

import { mapTableToForm } from '../../helpers/map-table-to-form.helper';

import myLeaveTableInitialSettings from '../../table-definitions/my-leave-table';
import { API_ACTION } from '../../constants/api-action.const';
import { IUserRecord } from '../../types/user/user.record.interface';

const formFields = mapTableToForm(myLeaveTableInitialSettings);

export const NewLeaveApplicationForm: React.FC<NewRecordFormProps<ILeaveApplicationRecord>> = (props) => {
  const {
    onFormComplete, onFormChange, initialData,
  } = props;
  const { userDetails } = useContext(CurrentUserContext);

  /**
   * Fired when the APIPolyForm successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, id: FormFieldComponentProps['value'], newData?: Partial<ILeaveApplicationRecord>) => {
    if (onFormComplete) onFormComplete(success, id as ILeaveApplicationRecord['id'], newData as ILeaveApplicationRecord);
  }, [onFormComplete]);

  return (
    <APIPolyForm
      {...myLeaveTableInitialSettings}
      fields={formFields}
      formData={{
        user_id: userDetails.id,
        user: userDetails as unknown as IUserRecord,
        approver_id: userDetails.directManager?.id,
        approver: userDetails.directManager as IUserRecord,
        ...(initialData ?? {}),
      }}
      permittedActions={myLeaveTableInitialSettings.possibleActions.filter((action) => (action.name === API_ACTION.CREATE))}
      onFormComplete={handleFormComplete}
      onFormChange={onFormChange}
      isNewRecord
      isEditing
    >
      <p>This will create your Leave application. Once created, you will need to specify dates and attach documentation prior to submitting it to your Manager.</p>
    </APIPolyForm>
  );
};

