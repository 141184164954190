import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PageHeader from '../app-layout/page-header';
import FutureForecastTable from './future-forecast-table/future-forecast-table';

const SalesDashboard = (props) => (
  <Container fluid>
    <PageHeader {...props} />
    <Row>
      <Col>
        <FutureForecastTable />
      </Col>
    </Row>
  </Container>
);

export default SalesDashboard;
