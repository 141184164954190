import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Card, CardBody, Button } from 'reactstrap';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import classNames from 'classnames';
import { formatValueString } from '../../render-functions';
import Icon from '../../layout-helpers/icon';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { APIContext } from '../../providers/api-provider';
import { buildAPIRoute } from '../../../helpers/build-api-route.helper';
import { TSheetsLabourClassHoursRecord } from './side-by-side-labour-analysis';
import { apiAborter } from '../../../helpers/api-aborter.helper';

type TSheetsLabourSummaryProps = {
  baseRoute: string,
  setTSheetsTotal: (total: number) => void,
  parentId: number,
};

interface ITotals {
  totalHours: number,
  totalCost: number,
}

export const TSheetsLabourSummary: React.FC<TSheetsLabourSummaryProps> = (props) => {
  const { baseRoute, parentId, setTSheetsTotal } = props;

  const { apiFetch } = useContext(APIContext);

  const [refreshingCache, setRefreshingCache] = useState(false);
  const [refreshAttempted, setRefreshAttempted] = useState(false);
  const [loadingTSheetsSummaryData, setLoadingTSheetsSummaryData] = useState(true);
  const [tSheetsSummaryData, setTSheetsSummaryData] = useState < TSheetsLabourClassHoursRecord[] | null>(null);
  const [totals, setTotals] = useState<ITotals | null>(null);
  const [localProjectId, setLocalProjectId] = useState<number|null>(null);

  /**
   * @description
   * Load the TSheets Summary data from the API
   */
  const getTSheetsSummary = useCallback((projectId) => {
    setLoadingTSheetsSummaryData(true);
    /**
     * @description
     * Take some TSheets summary data and calculate the totals
     */
    const calculateTotals = (data: TSheetsLabourClassHoursRecord[]) => {
      if (!data || data.length === 0) return null;
      let totalHours = 0;
      let totalCost = 0;
      data.map((row) => {
        const hourlyRate = row.t_sheets_labour_class_costing
          ? row.t_sheets_labour_class_costing.estimated_hourly_cost
          : 0;
        const hoursCost = parseFloat(row.hours) * hourlyRate;
        totalHours += parseFloat(row.hours);
        totalCost += hoursCost;
        return true;
      });

      if (typeof setTSheetsTotal === 'function') {
        setTSheetsTotal(totalCost);
      }

      return {
        totalCost,
        totalHours,
      };
    };
    const abortController = apiAborter();
    apiFetch(
      buildAPIRoute({
        parentId: projectId,
        baseRoute,
        apiRoute: '/tsheetsclasshours',
        apiQuery: 'with[]=tSheetsLabourClassCosting:class_id,estimated_hourly_cost',
      }),
      {
        name: 'TSheetsLabourSummary:getTSheetsSummary',
        signal: abortController.signal,
      },
    ).then((response) => {
      if (response.success) {
        const newTotals = calculateTotals(response.body.data);
        setLoadingTSheetsSummaryData(false);
        setTSheetsSummaryData(response.body.data);
        setTotals(newTotals);
      } else if (!response.aborted) {
        console.error('TSheetsLabourSummary:getTSheetsSummary', response.error);
        // TODO: error state?
        setLoadingTSheetsSummaryData(false);
        setTSheetsSummaryData(null);
        setTotals(null);
      }
    });
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [apiFetch, baseRoute, setTSheetsTotal]);


  /**
   * @description
   * fire the action against the project to refresh the tSheets Data cache
   */
  const refreshTSheetsCache = useCallback(async (projectId) => {
    setRefreshingCache(true);
    setRefreshAttempted(true);
    const abortController = apiAborter();
    const syncLabourResponse = await apiFetch(
      buildAPIRoute({
        parentId: projectId,
        baseRoute,
        apiRoute: '/action/synchronise-tsheets',
      }),
      {
        name: 'TSheetsLabourSummary:refreshTSheetsCache',
        signal: abortController.signal,
        method: HTTP_METHOD.POST,
      },
    );
    if (syncLabourResponse.success) {
      setRefreshingCache(false);
      getTSheetsSummary(projectId);
    } else if (!syncLabourResponse.aborted) {
      console.error('TSheetsLabourSummary:refreshTSheetsCache', syncLabourResponse.error);
      // TODO: error state?
      setRefreshingCache(false);
    }
    const scheduleAbortController = apiAborter();
    apiFetch(
      buildAPIRoute({
        parentId: projectId,
        baseRoute,
        apiRoute: '/action/synchronise-tsheets-schedule',
      }),
      {
        name: 'TSheetsLabourSummary:refreshTSheetsCache',
        signal: scheduleAbortController.signal,
        method: HTTP_METHOD.POST,
      },
    );
    return () => {
      if (abortController) {
        abortController.abort();
      }
      if (scheduleAbortController) {
        scheduleAbortController.abort();
      }
    };
  }, [apiFetch, baseRoute, getTSheetsSummary]);


  useEffect(() => {
    if (!refreshAttempted && tSheetsSummaryData && (tSheetsSummaryData[0] || tSheetsSummaryData.length === 0)) {
      if (tSheetsSummaryData[0]) {
        const lastUpdated = moment(tSheetsSummaryData[0].user_audit.updated_at);
        if (moment().diff(lastUpdated, 'm') < 10) {
          return;
        }
      }
      if (tSheetsSummaryData.length > 0) {
        return;
      }
      refreshTSheetsCache(parentId);
    }
  }, [tSheetsSummaryData, refreshAttempted, refreshTSheetsCache, parentId]);


  /**
   * @inheritdoc
   */
  useEffect(() => {
    if (parentId !== localProjectId) {
      setLocalProjectId(parentId);
      getTSheetsSummary(parentId);
    }
  }, [parentId, localProjectId, getTSheetsSummary]);

  const hasTsheetsData = tSheetsSummaryData !== null && tSheetsSummaryData.length > 0;
  let projectHasUnclassifiedData = false;
  let projectHasContractorHours = false;
  return (
    <div className="row justify-content-lg-center">
      <Card className="col-md-12 col-lg-10 col-xl-8">
        <CardBody>
          <h3>
            TSheets Hours &nbsp;
            <Button
              onClick={() => refreshTSheetsCache(localProjectId)}
              disabled={refreshingCache}
              className="pull-right"
              color="default"
            >
              Refresh&nbsp;
              <Icon i="refresh" isBusy={refreshingCache} />
            </Button>
          </h3>
          {loadingTSheetsSummaryData && (
            <div>
              Loading... &nbsp;
              <Icon i="rolling" />
            </div>
          )}
          {!loadingTSheetsSummaryData && !hasTsheetsData && (
            <p>No T-Sheets data has been cached yet.</p>
          )}
          {hasTsheetsData && tSheetsSummaryData && (
            <p>
              Last Updated: &nbsp;
              {moment(tSheetsSummaryData[0].user_audit.updated_at).format(
                'MMMM Do YYYY, h:mm:ss a',
              )}
            </p>
          )}
          {!loadingTSheetsSummaryData && tSheetsSummaryData && (
            <table
              className="table table-striped color-table danger-table"
              style={{ border: '1px solid silver' }}
            >
              <thead>
                <tr>
                  <th>Labour Item</th>
                  <th className="text-right">Recorded Hours</th>
                  <th className="text-right">Hourly Cost</th>
                  <th className="text-right">Estimated Total Cost</th>
                </tr>
              </thead>
              <tbody>
                {tSheetsSummaryData.map((wRow) => {
                  const hourlyRate = wRow.t_sheets_labour_class_costing
                    ? wRow.t_sheets_labour_class_costing.estimated_hourly_cost
                    : 0;
                  const hoursCost = parseFloat(wRow.hours) * hourlyRate;
                  const unclassified = wRow.class_id !== 1 && (wRow.class_id === 0);
                  const contractorHours = wRow.class_id === 1;
                  if (unclassified) projectHasUnclassifiedData = true;
                  if (contractorHours) projectHasContractorHours = true;
                  return (
                    <tr key={wRow.id} className={classNames({ 'text-danger': unclassified, 'text-primary': contractorHours })}>
                      <td>{wRow.class_name}</td>
                      <td className="text-right">{wRow.hours}</td>
                      <td className="text-right">{formatValueString(hourlyRate, COLUMN_FORMAT.CURRENCY)}</td>
                      <td className="text-right">{formatValueString(hoursCost, COLUMN_FORMAT.CURRENCY)}</td>
                    </tr>
                  );
                })}
              </tbody>
              {totals && (
                <tfoot>
                  <tr className="font-weight-bold">
                    <td>Totals: </td>
                    <td className="text-right">
                      {formatValueString(totals.totalHours, COLUMN_FORMAT.DECIMAL)}
                    </td>
                    <td className="text-right">&nbsp;-&nbsp;</td>
                    <td className="text-right">
                      {formatValueString(totals.totalCost, COLUMN_FORMAT.CURRENCY)}
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
          )}
          {projectHasUnclassifiedData && (
            <>
                &nbsp;
              <p className="text-danger">
                There appears to be some incorrectly classified data which cannot be costed. Check the
                T-Sheets project report for drill down and resolution.
              </p>
              <p className="text-danger">
                Generic Project Services items from prior to October 2020 may include items where the allocation is
                listed via the class instead of the Service Item.  This may not be a concern but should be reviewed in T-Sheets.
                If the value of items following this line is equal to the value of the Generic Services Line, it&apos;s likely to be correct.
              </p>
            </>
          )}
          {projectHasContractorHours && (
            <>
                &nbsp;
              <p className="text-primary">
                {'Note, this project has contractor hours which show as a $0.00 value here because the contractor will invoice for the '}
                hours separately.
              </p>
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
