import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Badge } from 'reactstrap';
import Icon from '../layout-helpers/icon';
import { noop } from '../../utils/helpers';

const NavItem = ({
  icon, children, link, externalLink, selected, badgeCount, setHoverSidebar, badgeColor,
}) => (
  <li className={classNames({ active: selected })}>
    {/* External Link */}
    {externalLink && (
      <a href={externalLink} rel="noopener noreferrer" target="_blank">
        {icon && <Icon i={icon} />}
        <span>
          {children}
          <Icon i="external-link" />
        </span>
        {badgeCount > 0 && (
          <Badge className="badge-count" color={badgeColor}>
            {badgeCount}
          </Badge>
        )}
      </a>
    )}

    {/* Internal Link */}
    {!externalLink && (
      <Link
        onClick={() => setHoverSidebar(false, true)}
        className={classNames({ active: selected })}
        to={link}
      >
        {icon && <Icon i={icon} />}
        <span>{children}</span>
        {badgeCount > 0 && (
          <Badge className="badge-count" color={badgeColor}>
            {badgeCount}
          </Badge>
        )}
      </Link>
    )}
  </li>
);

NavItem.defaultProps = {
  icon: null,
  selected: false,
  link: '#',
  externalLink: null,
  badgeCount: 0,
  setHoverSidebar: noop,
  badgeColor: 'info',
};

NavItem.propTypes = {
  link: PropTypes.string,
  externalLink: PropTypes.string,
  selected: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  icon: PropTypes.string,
  badgeCount: PropTypes.number,
  setHoverSidebar: PropTypes.func,
  badgeColor: PropTypes.string,
};

export default NavItem;
