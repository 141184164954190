import React, { useCallback } from 'react';

import { IFormFieldOverride } from '../../types/poly-form/form-field-override.interface';
import { IProjectRecord } from '../../types/project/project.record.interface';
import { NewRecordModalProps } from '../../types/modal/new-record-modal.props';

import { useIsDirty } from '../../react-hooks/use-is-dirty.hook';
import { Modal } from './Modal';

import { NewProjectForm } from '../poly-forms/new-project-form';

export type NewProjectProposalModalProps = NewRecordModalProps<IProjectRecord> & {
  formFieldOverrides?: IFormFieldOverride[],
}

export const NewProjectProposalModal: React.FC<NewProjectProposalModalProps> = (props) => {
  const {
    id, closeModal, onModalComplete, initialData, formFieldOverrides,
  } = props;

  const [isDirty, setDirty] = useIsDirty();

  /**
   * Fired when the form successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, recordId: IProjectRecord['id'], newRecord?: IProjectRecord) => {
    closeModal(id);
    onModalComplete((success && newRecord) ? { success, newRecord } : { success: false, newRecord: null });
  }, [closeModal, id, onModalComplete]);

  // Render
  return (
    <Modal
      {...props}
      title="Create a new Proposal"
      allowDismiss
      isDirty={isDirty}
      size="lg"
    >
      <NewProjectForm
        onFormComplete={handleFormComplete}
        onFormChange={setDirty}
        initialData={{
          likelihood: 5,
          ...(initialData ?? {}),
        }}
        formFieldOverrides={formFieldOverrides}
      />
    </Modal>
  );
};
