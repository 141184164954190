import { COLUMN_FORMAT } from '../constants/column-format.const';
import { THEME_COLOR } from '../constants/theme-color.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

/**
 * Table Definition for Project Profitability Snapshots
 */
const projectProfitabilitySnapshotsTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Snapshot',
  baseRoute: '/project-profitability-snapshot',
  baseQueryString: [
    'with[]=project',
    'with[]=iteration',
    'with[]=bracket',
    'with[]=project.owner:id,name,initials',
    'with[]=project.status:id,name',
  ].join('&'),

  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'id',
        // 'project_id',
        'project',
        'project_status',
        'owner',
        'value',
        'project_name',
        'snapshot_timestamp',
        'gross_profit',
        'net_profit',
        'invoiced_prior',
        'invoiced_in_period',
        'still_to_invoice',
        'total_project_value',
        'hardware_price',
        'hardware_cost',
        'labour_price',
        'labour_cost',
        'total_cost',
        'as_sold_net_margin',
        'apply_price_adjustment',
        'adjusted_project_price',
        'apply_cost_adjustment',
        'adjusted_project_cost',
        'adjusted_project_profit',
        'adjusted_profit_percent',
        'iteration',
        'bracket',
        'percentage_invoiced_prior',
        'percentage_invoiced_in_period',
        'percentage_invoiced_future',
        'profit_in_prior',
        'profit_in_period',
        'profit_in_future',
        'commission_in_prior',
        'commission_in_period',
        'commission_in_future',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'project_id',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'project_id',
        'project',
        'project_status',
        'owner',
        'value',
        'project_name',
        'snapshot_timestamp',
        'gross_profit',
        'net_profit',
        'invoiced_prior',
        'invoiced_in_period',
        'still_to_invoice',
        'total_project_value',
        'hardware_price',
        'hardware_cost',
        'labour_price',
        'labour_cost',
        'total_cost',
        'as_sold_net_margin',
        'apply_price_adjustment',
        'adjusted_project_price',
        'apply_cost_adjustment',
        'adjusted_project_cost',
        'adjusted_project_profit',
        'adjusted_profit_percent',
        'iteration',
        'bracket',
        'percentage_invoiced_prior',
        'percentage_invoiced_in_period',
        'percentage_invoiced_future',
        'profit_in_prior',
        'profit_in_period',
        'profit_in_future',
        'commission_in_prior',
        'commission_in_period',
        'commission_in_future',
      ],
    },
  ],

  /**
   * Definition of Columns for this table
   * Includes settings for APIPolyForm fields
   */
  columns: [
    {
      name: 'id',
      title: 'Id',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      order: 10,
      visible: true,
      showInForm: false,
    },
    {
      name: 'project_id',
      title: 'Project Id',
      format: COLUMN_FORMAT.TEXT,
      order: 20,
      visible: false,
      showInForm: false,
    },
    {
      name: 'project',
      title: 'Project',
      format: COLUMN_FORMAT.OBJECT,
      description: 'Portal Project Number',
      placeholder: 'P12345ABC',
      object: {
        sourceField: 'project',
        key: 'project_number',
        format: COLUMN_FORMAT.P_NUMBER,
        listSource: 'project',
      },
      formSaveField: 'project_id',
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 30,
    },
    {
      name: 'project_status',
      title: 'Project Status',
      description: 'Current Status of Project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'project',
        key: 'name',
        format: COLUMN_FORMAT.OBJECT,
        listSource: 'projectStatus',
        object: {
          sourceField: 'status',
          key: 'name',
          format: COLUMN_FORMAT.STATUS_BADGE,
        },
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      order: 40,
    },
    {
      name: 'owner',
      title: 'Owner',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'project',
        key: 'owner',
        format: COLUMN_FORMAT.OBJECT,
        object: {
          sourceField: 'owner',
          key: 'name',
          format: COLUMN_FORMAT.TEXT,
        },
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 50,
    },
    {
      name: 'value',
      title: 'Project Value',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'project',
        key: 'value',
        format: COLUMN_FORMAT.CURRENCY,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 60,
    },
    {
      name: 'project_name',
      title: 'Project Name',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'project',
        key: 'name',
        format: COLUMN_FORMAT.LONG_TEXT,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 70,
    },
    {
      name: 'snapshot_timestamp',
      title: 'Snapshot Timestamp',
      format: COLUMN_FORMAT.TIMESTAMP,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 80,
      visible: true,
    },
    {
      name: 'gross_profit',
      title: 'Gross Profit',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 90,
      visible: true,
    },
    {
      name: 'net_profit',
      title: 'Net Profit',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 100,
      visible: true,
    },
    {
      name: 'invoiced_prior',
      title: 'Invoiced Prior',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 110,
      visible: true,
    },
    {
      name: 'invoiced_in_period',
      title: 'Invoiced In Period',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 120,
      visible: true,
    },
    {
      name: 'still_to_invoice',
      title: 'Still To Invoice',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 130,
      visible: true,
    },
    {
      name: 'total_project_value',
      title: 'Total Project Value',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 140,
      visible: true,
    },
    {
      name: 'hardware_price',
      title: 'Hardware Price',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 150,
      visible: true,
    },
    {
      name: 'hardware_cost',
      title: 'Hardware Cost',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 160,
      visible: true,
    },
    {
      name: 'labour_price',
      title: 'Labour Price',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 170,
      visible: true,
    },
    {
      name: 'labour_cost',
      title: 'Labour Cost',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 180,
      visible: true,
    },
    {
      name: 'total_cost',
      title: 'Total Cost',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 190,
      visible: true,
    },
    {
      name: 'as_sold_net_margin',
      title: 'As Sold Net Margin',
      format: COLUMN_FORMAT.NUMBER,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 200,
      visible: true,
    },
    {
      name: 'apply_price_adjustment',
      title: 'Apply Price Adjustment',
      format: COLUMN_FORMAT.CURRENCY,
      order: 210,
      visible: true,
    },
    {
      name: 'adjusted_project_price',
      title: 'Adjusted Project Price',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 220,
      visible: true,
    },
    {
      name: 'apply_cost_adjustment',
      title: 'Apply Cost Adjustment',
      format: COLUMN_FORMAT.CURRENCY,
      order: 230,
      visible: true,
    },
    {
      name: 'adjusted_project_cost',
      title: 'Adjusted Project Cost',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 240,
      visible: true,
    },
    {
      name: 'adjusted_project_profit',
      title: 'Adjusted Project Profit',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 250,
      visible: true,
    },
    {
      name: 'adjusted_profit_percent',
      title: 'Adjusted Profit Percent',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 260,
      visible: true,
    },
    {
      name: 'iteration',
      title: 'Iteration',
      format: COLUMN_FORMAT.OBJECT,
      placeholder: 'P12345ABC',
      object: {
        sourceField: 'iteration',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/finance/sales-commission/commission-iterations',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 270,
    },
    {
      name: 'bracket',
      title: 'Bracket Rate',
      format: COLUMN_FORMAT.OBJECT,
      placeholder: 'P12345ABC',
      object: {
        sourceField: 'bracket',
        key: 'commission_percentage',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: (data) => `/finance/sales-commission/commission-iterations/${data.iteration_id}/brackets`,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 280,
    },
    {
      name: 'percentage_invoiced_prior',
      title: 'Percentage Invoiced Prior',
      format: COLUMN_FORMAT.NUMBER,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 290,
      visible: true,
    },
    {
      name: 'percentage_invoiced_in_period',
      title: 'Percentage Invoiced In Period',
      format: COLUMN_FORMAT.NUMBER,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 300,
      visible: true,
    },
    {
      name: 'percentage_invoiced_future',
      title: 'Percentage Invoiced Future',
      format: COLUMN_FORMAT.NUMBER,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 310,
      visible: true,
    },
    {
      name: 'profit_in_prior',
      title: 'Profit In Prior',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 320,
      visible: true,
    },
    {
      name: 'profit_in_period',
      title: 'Profit In Period',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 330,
      visible: true,
    },
    {
      name: 'profit_in_future',
      title: 'Profit In Future',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 340,
      visible: true,
    },
    {
      name: 'commission_in_prior',
      title: 'Commission In Prior',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 350,
      visible: true,
    },
    {
      name: 'commission_in_period',
      title: 'Commission In Period',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 360,
      visible: true,
    },
    {
      name: 'commission_in_future',
      title: 'Commission In Future',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 370,
      visible: true,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add a Contract',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new Commission Snapshot',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the commission Snapshot',
      successMessage: 'Updated licences',
      failMessage: 'Failed to update Commission Snapshot',
      hideRecordActionButton: true,
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the Commission Snapshot',
      successMessage: 'Commission Snapshot deleted',
      failMessage: 'Failed to delete Commission Snapshot',
      hideRecordActionButton: false,
    },
  ],


  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'forecasting',
      title: 'Forecasting',
      description: 'Project Forecasting',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_FORECASTS,
      apiRoute: (rowData) => `/project/${rowData.project_id}/forecast`,
      apiQuery: [
        'with[]=type',
        'pagelength=0',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'forecast_count',
          badgeColor: THEME_COLOR.DARK,
          // Take out the project forecast total row
          onCalculateValue: (value) => (value ? value - 1 : 0),
        },
      ],
    },
    {
      name: 'clientorders',
      title: 'Client Orders',
      description: 'Client Orders',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_CLIENT_ORDERS,
      apiRoute: (rowData) => `/project/${rowData.project_id}/order`,
      apiQuery: [
        'with[]=activatingPurchaseOrderProjectFile',
        'with[]=proposalProjectFile',
        'pagelength=0',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'order_count',
          badgeColor: THEME_COLOR.DARK,
        },
      ],
    },
    {
      name: 'invoices',
      title: 'Invoices',
      description: 'Client Invoices',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_INVOICES,
      apiRoute: (rowData) => `/project/${rowData.project_id}/invoice`,
      apiQuery: [
        'with[]=type:id,name',
        'pagelength=0',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'total_invoice_count',
          badgeColor: THEME_COLOR.DARK,
        },
      ],
    },
    {
      ...eventsTabDefault,
      name: 'snapshot_events',
      title: 'Snapshot Events',
      apiRoute: '/tracking',
      description: 'Commission Snapshot Event Audit Trail',
      apiQuery: [
        'with[]=createdBy:id,name',
        'pagelength=-1',
      ].join('&'),
    },
    {
      ...eventsTabDefault,
      name: 'project_events',
      title: 'Project Events',
      apiRoute: (rowData) => `/project/${rowData.project_id}/tracking`,
      description: 'Project Event Audit Trail',
      apiQuery: [
        'with[]=createdBy:id,name',
        'pagelength=-1',
      ].join('&'),
    },
  ],

};

export default projectProfitabilitySnapshotsTableInitialSettings;
