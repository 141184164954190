import React from 'react';
import { getFilterFormat } from '../../../utils/filters';
import CustomFilterSelect from '../filters/custom-filter-select';
import ListSelect from '../filters/list-select';
import { realFilterValue } from '../../../utils/helpers';
import AsyncSelectFilter from '../filters/async-select-filter';
import { COLUMN_FILTER_TYPES } from '../../../utils/column-filter-types';
import { IColumnFilter, InMemoryDataTableColumn, ISetColumnFilter } from '../../../types/portal-data-table/in-memory-table-types';
import { FILTER_OPERATION } from '../../../constants/filter-operation.const';


interface IInMemoryFilterProps {
  column: InMemoryDataTableColumn,
  columnFilter: IColumnFilter | null,
  setColumnFilter: ISetColumnFilter,
}

export const InMemoryColumnFilter: React.FC<IInMemoryFilterProps> = ({ column, columnFilter, setColumnFilter }) => {
  const oldColumnFormat = column;

  /**
   * @description
   * Fired when a list-select or async-select-filter has a resource changed
   *
   * @param {Object[]} selectedResources the selectedResources resources
   * @param {boolean} [pushToHistory=false]
   */
  const handleSelectChange = (selectedResources: string[]) => {
    const filterFormatting = getFilterFormat(oldColumnFormat);

    const newColumnFilter = selectedResources.length > 0 ? {
      name: column.name,
      values: (selectedResources || []).map((value) => realFilterValue(value, filterFormatting.valueKey || 'id')),
      operation: (selectedResources instanceof Array && selectedResources.length > 1) ? FILTER_OPERATION.IN : FILTER_OPERATION.EQUALS,
    } : null;

    setColumnFilter(column.name, newColumnFilter);
  };

  const filterFormatting = getFilterFormat(oldColumnFormat);
  const includeBlanks = column.hasBlanks || true;
  const isLoading = false; // the data has loaded if this table is visible

  const location = {
    search: '',
  };

  switch (filterFormatting.filterType) {
    case COLUMN_FILTER_TYPES.ASYNC_OPTION_LIST: {
      return (
        <AsyncSelectFilter
          fieldName={column.name}
          onSelectChange={handleSelectChange}
          dataSource={filterFormatting.options}
          columnFilter={columnFilter}
          includeBlankOption={includeBlanks}
          location={location}
        />
      );
    }
    case COLUMN_FILTER_TYPES.OPTION_LIST: {
      return (
        <ListSelect
          fieldName={column.name}
          options={filterFormatting.options}
          onSelectChange={handleSelectChange}
          values={columnFilter instanceof Object ? columnFilter.values : []}
          includeBlankOption={includeBlanks}
          location={location}
        />
      );
    }
    case COLUMN_FILTER_TYPES.TEXT:
    case COLUMN_FILTER_TYPES.DATE:
    case COLUMN_FILTER_TYPES.LIKELIHOOD:
    case COLUMN_FILTER_TYPES.SET_NOT_SET:
    case COLUMN_FILTER_TYPES.NUMERIC: {
      return (
        <CustomFilterSelect
          tableIdentifier="in-memort-table"
          setColumnFilter={setColumnFilter}
          column={oldColumnFormat}
          currentFilter={columnFilter}
          type={filterFormatting.filterType}
          isLoading={isLoading}
          location={location}
        />
      );
    }
    default: {
      // Fallback - no filter
      return (
        <div className="no-filter p-1">
          <span>No Filter</span>
        </div>
      );
    }
  }
};
