export const PROJECT_COMPANY_TYPE = {
  CLIENT: 1,
  ARCHITECT: 2,
  BILLING_AGENT: 3,
  BUILDER: 4,
  CONSULTANCY: 5,
  EXTERNAL_PROJECT_MANAGER: 6,
  SERVICES_CONSULTANT: 7,
} as const;
export type PROJECT_COMPANY_TYPE = typeof PROJECT_COMPANY_TYPE;
export type A_PROJECT_COMPANY_TYPE = PROJECT_COMPANY_TYPE[keyof PROJECT_COMPANY_TYPE];

export const PROJECT_COMPANY_TYPE_NAME_MAP: {[K in A_PROJECT_COMPANY_TYPE]: string} = {
  [PROJECT_COMPANY_TYPE.CLIENT]: 'Client',
  [PROJECT_COMPANY_TYPE.ARCHITECT]: 'Architect',
  [PROJECT_COMPANY_TYPE.BILLING_AGENT]: 'Billing Agent',
  [PROJECT_COMPANY_TYPE.BUILDER]: 'Builder',
  [PROJECT_COMPANY_TYPE.CONSULTANCY]: 'Consultancy',
  [PROJECT_COMPANY_TYPE.EXTERNAL_PROJECT_MANAGER]: 'External Project Manager',
  [PROJECT_COMPANY_TYPE.SERVICES_CONSULTANT]: 'Services Consultant',
};
