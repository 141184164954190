import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { SelectOption } from '../types/poly-form/select-option';

export const optionArrayToSelectOptions = (optionArray: Record<number|string, number|string>[]): SelectOption[] => optionArray.map((option) => option as SelectOption);

export const InternalProjectStakeholdersWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Stakeholder',
  fields: [
    {
      name: 'internal_project_id',
      isParentId: true,
      visible: false,
    },
    {
      name: 'user',
      title: 'Person',
      formSaveField: 'user_id',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
    },
    {
      name: 'role',
      title: 'Role',
      formFieldType: FORM_FIELD_TYPE.STAKEHOLDER_ROLE_PICKER,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'role',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formSaveField: 'role_id',
    },
    {
      name: 'responsible',
      title: 'Responsible',
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      format: COLUMN_FORMAT.CHECKBOX_MEDIUM,
      inputOptions: optionArrayToSelectOptions([
        { id: 0, name: '' },
        { id: 1, name: 'R' },
      ]),
      label: 'R',
    },
    {
      name: 'accountable',
      title: 'Accountable',
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      format: COLUMN_FORMAT.CHECKBOX_MEDIUM,
      inputOptions: optionArrayToSelectOptions([
        { id: 0, name: '' },
        { id: 1, name: 'A' },
      ]),
      label: 'A',
    },
    {
      name: 'consulted',
      title: 'Consulted',
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      format: COLUMN_FORMAT.CHECKBOX_MEDIUM,
      inputOptions: optionArrayToSelectOptions([
        { id: 0, name: '' },
        { id: 1, name: 'C' },
      ]),
      label: 'C',
    },
    {
      name: 'informed',
      title: 'Informed',
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      format: COLUMN_FORMAT.CHECKBOX_MEDIUM,
      inputOptions: optionArrayToSelectOptions([
        { id: 0, name: '' },
        { id: 1, name: 'I' },
      ]),
      label: 'I',
    },
  ],
};
