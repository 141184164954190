import React from 'react';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';

import AsyncSelect from './async-select';

export type CommissionBatchPickerProps = AsyncSelectComponentProps;

export const CommissionBatchPicker:React.FC<CommissionBatchPickerProps> = (props) => {
  const {
    isClearable = true,
  } = props;

  return (
    <AsyncSelect
      {...props}
      searchRoute="/commission-payment-batch"
      isClearable={isClearable}
      isCreatable={false}
    />
  );
};
