import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { formatValueString } from '../../render-functions';
import { ProjectForecasts } from './project-forecasts';
import { connectToAPIProvider } from '../../providers/api-provider';
import API_PROVIDER_PROP_TYPES from '../../../prop-types/api-provider-prop-types';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { ProjectNumber } from '../../data-format/project-number';

class ProjectDrillDownTable extends Component {
  /**
   * @constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      monthData: [],
      showForecasts: {},
      loading: false,
    };

    this.abortController = null;
  }


  /**
   * @inheritdoc
   */
  componentDidMount = () => {
    this.loadProjects();
  };


  /**
   * @inheritdoc
   */
  componentDidUpdate = (oldProps) => {
    const { column: { query } } = this.props;
    if (oldProps.column.query !== query) {
      this.loadProjects();
    }
  };


  /**
   * @inheritdoc
   */
  componentWillUnmount = () => {
    if (this.abortController) {
      this.abortController.abort();
    }
  }


  /**
   * @description
   * Fetches the projects and stores the forecast data in state
   */
  loadProjects = () => {
    if (this.abortController) {
      this.abortController.abort();
    }

    this.setState({
      loading: true,
      monthData: [],
    }, () => {
      const { column: { query }, data: { year, month }, apiProvider: { apiFetch } } = this.props;
      const queryString = query ? `&${query}` : '';

      this.abortController = apiAborter();

      apiFetch(
        `/reports/forecast/monthly?month=${month}&year=${year}${queryString}`,
        {
          name: 'ProjectDrillDownTable:loadProjects',
          signal: this.abortController.signal,
        },
      ).then((response) => {
        if (response.success) {
          this.abortController = null;
          this.setState({
            monthData: response.body.forecasts || [],
            loading: false,
          });
        } else if (!response.aborted) {
          this.abortController = null;
          console.error('ProjectDrillDownTable:loadProjects', response.error);
          // TODO: error state?
          this.setState({
            monthData: [],
            loading: false,
          });
        }
      });
    });
  };


  /**
   * @param {object} recordData from monthData
   */
  toggleForecasts = (recordData) => {
    const key = recordData.forecast_id;
    const { showForecasts } = this.state;
    this.setState({
      showForecasts: {
        ...showForecasts,
        [key]: showForecasts[key] !== true,
      },
    });
  };

  // Render <ProjectDrillDownTable />
  render = () => {
    const { monthData, showForecasts, loading } = this.state;
    const { colSpan, data, column } = this.props;
    const { month_name, year } = data;
    const dataTitle = column.dataTitle || 'Forecasting';

    // Return <ProjectDrillDownTable />
    return (
      <div>
        <h4 style={{ padding: '16px 10px' }}>
          Showing
          {' '}
          {dataTitle}
          {' '}
          for
          {' '}
          {month_name}
          {' '}
          {year}
        </h4>
        <table width="100%">
          <thead>
            <tr>
              <th>Project</th>
              <th>Owner</th>
              <th>Status</th>
              <th>L</th>
              <th>Client</th>
              <th>Project Name</th>
              <th>Forecast Type</th>
              <th className="text-right">Expected Invoice</th>
              <th className="text-right">Forecast PO</th>
            </tr>
          </thead>
          <tbody>
            {loading && monthData.length === 0 && (
              <tr>
                <td colSpan={colSpan}>Loading Data...</td>
              </tr>
            )}
            {!loading && monthData.length === 0 && (
              <tr>
                <td colSpan={colSpan}>No Records were loaded.</td>
              </tr>
            )}
            {monthData.map((projectForecast) => (
              <Fragment key={`${projectForecast.project_id}-${projectForecast.id}`}>
                <tr
                  onClick={() => {
                    this.toggleForecasts(projectForecast);
                  }}
                >
                  <td>
                    <ProjectNumber pData={projectForecast.project} linkToProject />
                  </td>
                  <td>{projectForecast.project.owner.name}</td>
                  <td>{projectForecast.status_name}</td>
                  <td className="text-center">{projectForecast.project.likelihood}</td>
                  <td>{projectForecast.project.client.name}</td>
                  <td>{projectForecast.project.name}</td>
                  <td>{projectForecast.type_name}</td>
                  <td className="text-right">
                    {formatValueString(projectForecast.amount, COLUMN_FORMAT.WHOLE_DOLLARS)}
                  </td>
                  <td className="text-right">
                    {formatValueString(projectForecast.project.value, COLUMN_FORMAT.WHOLE_DOLLARS)}
                  </td>
                </tr>
                {showForecasts[projectForecast.forecast_id] && (
                  <tr>
                    <td colSpan="9" className="bg-gray">
                      <ProjectForecasts project={projectForecast.project} colSpan={colSpan} />
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
}

ProjectDrillDownTable.defaultProps = {
  colSpan: 9,
  column: {
    query: null,
    dataTitle: null,
  },
  data: {
    month_name: null,
    year: null,
    month: null,
  },
};

ProjectDrillDownTable.propTypes = {
  colSpan: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  column: PropTypes.shape({ query: PropTypes.string, dataTitle: PropTypes.string }),
  data: PropTypes.shape({
    month_name: PropTypes.string,
    year: PropTypes.number,
    month: PropTypes.number,
  }),
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
};

export default connectToAPIProvider(ProjectDrillDownTable);
