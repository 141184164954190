import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader } from 'reactstrap';
import { LoadingSpinner } from '../layout-helpers/loading-spinner';
import { APIContext } from '../providers/api-provider';
import { apiAborter } from '../../helpers/api-aborter.helper';

/**
 * DToolsProjectSites component for Data Table
 */
export const DToolsProjectSites = ({ dtoolsId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { apiFetch } = useContext(APIContext);

  useEffect(() => {
    let abortController = apiAborter();

    apiFetch(
      `/dtools-connector/project/${dtoolsId}/sites`,
      {
        name: 'DToolsProjectSites:load',
        signal: abortController.signal,
      },
    ).then((response) => {
      if (response.success) {
        abortController = null;
        setData(response.body.data || []);
        setLoading(false);
      } else if (!response.aborted) {
        abortController = null;
        // TODO: error state?
        console.error('DTools Project Sites Error', response.error);
        setData([]);
        setLoading(false);
      }
    });

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <LoadingSpinner />}

      {!loading && (
        <Card>
          <CardHeader className="bg-info text-white" tag="h4">
            Project Sites
          </CardHeader>
          <div className="table-container" style={{ height: '490px', overflowY: 'auto' }}>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>State</th>
                  <th>City</th>
                  <th>Street</th>
                </tr>
              </thead>
              <tbody>
                {data.length < 1 && (
                  <tr>
                    <td colSpan="3">No sites listed</td>
                  </tr>
                )}
                {data.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={index}>
                    <td>{item.State}</td>
                    <td>{item.City}</td>
                    <td>{item.Street}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      )}
    </>
  );
};

DToolsProjectSites.propTypes = {
  dtoolsId: PropTypes.string.isRequired,
};
