import React, { useCallback, useContext, useState, useRef } from 'react';
import classNames from 'classnames';
import { Alert, Button } from 'reactstrap';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';

import { ModalContext } from '../modals/modal-context';

import { CollectionWidgetProps } from '../../types/collection.widget.props';
import { IContractRecord } from '../../types/service/contract.record.interface';
import { IContractSerialRecord } from '../../types/contact/contract-serial.record.interface';
import { ReturnDataModalResult } from '../../types/modal/modal-result';

import { CollectionWidget } from './collection.widget';
import { CollectionWidgetHeader } from './collection-widget-header';
import Icon from '../layout-helpers/icon';

import { apiAborter } from '../../helpers/api-aborter.helper';
import { hasPermittedAction } from '../../helpers/has-permitted-action.helper';

import { API_ACTION } from '../../constants/api-action.const';
import { COLUMN_FORMAT } from '../../constants/column-format.const';
import { ICON } from '../../constants/icon.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { APIContext } from '../providers/api-provider';
import { buildAPIRoute } from '../../helpers/build-api-route.helper';


export type ContractSerialsWidgetProps = Omit<CollectionWidgetProps, 'rowData' | 'widgetData'> & {
  rowData: IContractRecord,
  widgetData: IContractSerialRecord[],
}

// Used when pasting data from the clipboard into the paste data from clipboard modal
type ImportedSerial = {
  serial: string,
}

// Holds the state of the import process for multiple serials pasted from the clipboard
type ImportSerialsState = {
  isProcessing: boolean,
  lastSuccess: null | boolean,
  lastMessage: null | string,
}

/**
 * This widget displays the Contract Serials by wrapping a table widget in a custom widget
 * for displaying other components
 */
export const ContractSerialsWidget:React.FC<ContractSerialsWidgetProps> = (props) => {
  const {
    parentId,
    baseRoute = '/contract',
    className,
    disableHeader,
    widgetPermittedActions,
    isEditingCollectionItem,
    isCreatingCollectionItem,
    isReadOnly,
    showAddBtn,
    isLoading,
    createCollectionItem,
    refreshWidgetData,
    refreshRecord,
  } = props;


  const { showModal } = useContext(ModalContext);
  const { apiFetch } = useContext(APIContext);

  const [importSerialsState, setImportSerialsState] = useState<ImportSerialsState>({ isProcessing: false, lastSuccess: null, lastMessage: null });
  const abortImportSerials = useRef<null | AbortController>(null);

  /**
   * Create a new Contract Serial
   */
  const handleCreateCollectionItem = useCallback(() => {
    if (createCollectionItem) createCollectionItem();
  }, [createCollectionItem]);


  /**
   * Import a large list of serials from the Paste from Clipboard modal
   */
  const importSerials = useCallback(async (data: ImportedSerial[]) => {
    // Don't allow this method to run twice concurrently
    if (importSerialsState.isProcessing) return;

    setImportSerialsState({
      isProcessing: true,
      lastSuccess: null,
      lastMessage: null,
    });

    if (abortImportSerials.current) {
      abortImportSerials.current.abort();
    }
    abortImportSerials.current = apiAborter();

    // Call the API
    const response = await apiFetch(
      buildAPIRoute({
        parentId,
        baseRoute,
        apiRoute: '/serial/import',
      }),
      {
        method: HTTP_METHOD.POST,
        name: 'ContractSerialsWidget::importSerials',
        signal: abortImportSerials.current.signal,
        body: {
          serials: data,
        },
      },
    );

    if (response.success) {
      abortImportSerials.current = null;

      // Update the action state
      setImportSerialsState({
        isProcessing: false,
        lastSuccess: true,
        lastMessage: null,
      });

      // Refresh the record to update the row data
      setTimeout(() => { if (refreshRecord) refreshRecord(); }, 0);

      // Refresh the widget data to update the collection
      setTimeout(() => { if (refreshWidgetData) refreshWidgetData(); }, 0);
    } else if (!response.aborted) {
      abortImportSerials.current = null;
      console.error('ContractSerialsWidget::importSerials', response.error, response.body.errors);

      // Update the action state
      setImportSerialsState({
        isProcessing: false,
        lastSuccess: false,
        lastMessage: (`${response.error} ${response.body?.errors?.serials ?? ''}`).trim(),
      });
    }
  }, [importSerialsState.isProcessing, apiFetch, parentId, baseRoute, refreshRecord, refreshWidgetData]);


  // Render
  return (
    <CollectionWidget
      {...props}
      className={classNames('contract-serials-widget', className)}
      disableHeader
      isLoading={importSerialsState.isProcessing || isLoading}
    >
      {/* Pass in a custom header (if not disabled in the definition */}
      {(disableHeader !== true) && (
        <CollectionWidgetHeader
          description="Contract Serials"

          showAddBtn={
            // pass down setting from the collection widget definition
            showAddBtn &&

            // The CREATE action exists in the widgetItemPermittedActions
            !!hasPermittedAction(widgetPermittedActions, API_ACTION.CREATE)
          }

          // Prevent the user from adding another row if they are editing a row
          addBtnDisabled={isEditingCollectionItem || isCreatingCollectionItem || isReadOnly}

          // Pass the event handler back up the chain so that the widget wrapper can add a data row
          onClickAddBtn={handleCreateCollectionItem}

          actions={(
            <>
              <div className="action">
                {/* TODO: Ensure the "create" action is available on the contract-serials endpoint and that the user has permission */}
                <Button
                  color="primary"
                  // Display the confirmation modal
                  onClick={() => showModal<ReturnDataModalResult<ImportedSerial>>(MODAL_TYPE.PASTE_DATA_FROM_CLIPBOARD_MODAL, {
                    title: 'Paste Serial / Licences from Clipboard',
                    columns: [{
                      title: 'Serial or Licence Number',
                      name: 'serial',
                      format: COLUMN_FORMAT.TEXT,
                    }],
                    onModalComplete: ({ success, data }) => {
                      if (success && data && (data.length > 0)) {
                        importSerials(data);
                      }
                    },
                  })}
                >
                  <Icon
                    i={ICON.PASTE_FROM_CLIPBOARD}
                    isBusy={importSerialsState.isProcessing}
                  />
                  <span>Paste from Clipboard</span>
                </Button>
              </div>
            </>
          )}
        />
      )}
      {!importSerialsState.isProcessing && (importSerialsState.lastSuccess === false) && (
        <Alert color="danger">
          {importSerialsState.lastMessage}
        </Alert>
      )}
    </CollectionWidget>
  );
};
