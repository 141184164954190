import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { NewRecordModalResult } from '../../types/modal/modal-result';
import { IPlatformRecord } from '../../types/internal-project/platform.record.interface';

import { ModalContext } from '../modals/modal-context';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { DataTablePageProps } from '../../types/data-table-page.props';

export type PlatformsDataTablePageProps = DataTablePageProps;

/**
 * Display the "Platforms" data table as a whole page
 */
export const PlatformsDataTablePage: React.FC<PlatformsDataTablePageProps> = ({
  baseFilters,
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const history = useHistory();
  const { showModal } = useContext(ModalContext);

  const actionHandlers = {
    create: () => {
      showModal<NewRecordModalResult<IPlatformRecord>>(MODAL_TYPE.NEW_PLATFORM, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/internal-projects/platforms/${modalResult.newRecord.id}`);
        },
      });
    },
  };

  return (
    <DataTablePage
      title="Platforms"
      tableIdentifier={TABLE_IDENTIFIER.PLATFORMS_TABLE}
      actionHandlers={actionHandlers}
      baseFilters={baseFilters}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      {children}
    </DataTablePage>
  );
};
