import React from 'react';
import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { DataTablePageProps } from '../../types/data-table-page.props';
import DataTablePage from './data-table-page';

export type ProjectDataTablePage = DataTablePageProps;

/**
 * Leads/Projects Table implementation
 */
export const ProjectDataTablePage: React.FC<ProjectDataTablePage> = (props) => (
  <DataTablePage
    title="Leads, Proposals & Projects"
    {...props}
    tableIdentifier={TABLE_IDENTIFIER.PROJECT_TABLE}
  />
);
