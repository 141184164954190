import { DELETE_CONFIRMATION_TYPE } from '../constants/delete-confirmation-type.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { fullName } from '../helpers/full-name.helper';
import { IContactRecord } from '../types/contact/contact.record.interface';
import { IProjectRecord } from '../types/project/project.record.interface';
import { ICompanyRecord } from '../types/company/company.record.interface';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { ContactsWidget } from '../components/widgets/contacts-widget/contacts-widget';

export const ProjectContactsWidgetDefinition: ICollectionWidgetDefinition = {
  // has to be table row here currently to get the wrapper to generate them
  // we can decide not to use them for card view later
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  component: ContactsWidget,
  itemCaption: 'Contact',
  className: 'contacts-widget',
  formDeleteConfirmationType: DELETE_CONFIRMATION_TYPE.REMOVE,
  fields: [
    {
      name: 'project_id',
      title: 'Project ID',
      visible: false,
      isParentId: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'person',
      title: 'Contact Person',
      placeholder: 'Contact Person',
      visible: true,
      showInForm: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'person',
        key: 'id',
        getValue: (value: number, rowData: IContactRecord) => fullName(rowData),
        format: COLUMN_FORMAT.TEXT,
      },
      formFieldType: FORM_FIELD_TYPE.CONTACT_PICKER,
      formSaveField: 'person_id',
      isCreatable: true,
      getNewItemData: (inputValue: string, formData: IContactRecord, parentData?: IProjectRecord): {
        company_id: null | number,
        company?: null | ICompanyRecord,
      } => ({
        company_id: parentData?.client_id ?? null,
        company: parentData?.client ?? null,
      }),
    },
    {
      name: 'type',
      title: 'Contact type',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.PROJECT_CONTACT_TYPE_PICKER,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'type',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formSaveField: 'type_id',
    },
    {
      name: 'send_notifications',
      title: 'Send Notifications',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      format: COLUMN_FORMAT.CHECKBOX,
      label: 'Send Notifications',
    },
  ],
};
