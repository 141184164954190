import { DELETE_CONFIRMATION_TYPE } from '../constants/delete-confirmation-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { monthsTextInputToolTipHtml } from '../components/form-input/months-text-input';

export const CompanyContractsWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Contract',
  formDeleteConfirmationType: DELETE_CONFIRMATION_TYPE.REMOVE,
  fields: [
    {
      name: 'company_id',
      visible: false,
      isParentId: true,
    },
    {
      name: 'number',
      title: 'Contract',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: '/service-and-support/contracts',
    }, {
      name: 'type',
      title: 'Contract Type',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        key: 'name',
        sourceField: 'type',
        format: COLUMN_FORMAT.TEXT,
      },
      sortColumn: 'type.name',
    }, {
      name: 'subtype',
      title: 'Contract Subtype',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        key: 'name',
        sourceField: 'subtype',
        format: COLUMN_FORMAT.SHORT_TEXT,
      },
      sortColumn: 'subtype.name',
    }, {
      name: 'status',
      title: 'Contract Status',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        key: 'name',
        sourceField: 'status',
        format: COLUMN_FORMAT.STATUS_BADGE,
      },
    }, {
      name: 'project',
      title: 'From Project',
      format: COLUMN_FORMAT.OBJECT,
      placeholder: 'P12345ABC',
      object: {
        sourceField: 'project',
        key: 'project_number',
        format: COLUMN_FORMAT.P_NUMBER,
        listSource: 'project',
      },
      sortColumn: 'project.id',
    }, {
      name: 'provider',
      title: 'Provider',
      description: 'The company providing the contract',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'provider',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/crm/companies',
      },
      sortColumn: 'provider.name',
      isCreatable: true,
    }, {
      name: 'end_date',
      title: 'End Date',
      format: COLUMN_FORMAT.DATE,
    }, {
      name: 'contract_value',
      title: 'Contract Value',
      format: COLUMN_FORMAT.WHOLE_DOLLARS,
    }, {
      name: 'contract_months',
      title: 'Contract Months',
      description: 'Length of the contract in months',
      format: COLUMN_FORMAT.NUMBER,
      formFieldToolTip: monthsTextInputToolTipHtml,
    },
  ],
};
