import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Card, CardBody, Button, CardHeader } from 'reactstrap';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import Icon from '../layout-helpers/icon';
import { APIContext } from '../providers/api-provider';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { HtmlInput } from '../form-input/html-input';
import { CurrentUserContext } from '../providers/current-user-provider';

const zdAppEndpoint = process.env.REACT_APP_ZENDESK_APP_ENDPOINT;
const zdTicketPath = `${zdAppEndpoint}agent/tickets/`;
const zdUserPath = `${zdAppEndpoint}agent/users/`;
const defaultPhotoUrl = 'https://i2.wp.com/assets.zendesk.com/images/2016/default-avatar-80.png?ssl=1';

interface IZendeskTicketDetailsWidgetProps {
  rowData: {
    zendesk_id: string,
  }
}

type ZendeskTicketCommentRecord = {
  id: number,
  html_body: string,
  type: string,
  public: boolean,
  author_id: number,
}

type ZendeskUserRecord = {
  id: number,
  name: string,
  email: string,
  photo: {
    content_url: string
  }
}

export const ZendeskTicketDetailsWidget: React.FC<IZendeskTicketDetailsWidgetProps> = ({ rowData: { zendesk_id } }) => {
  const { apiFetch } = useContext(APIContext);

  const { userDetails } = useContext(CurrentUserContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [addComment, setAddComment] = useState<boolean>(false);
  const [ticketComments, setTicketComments] = useState<ZendeskTicketCommentRecord[]>([]);
  const [ticketUsers, setTicketUsers] = useState<ZendeskUserRecord[]>([]);

  const abortLoad = useRef<AbortController | null>(null);
  const abortCreate = useRef<AbortController | null>(null);

  const handleAddComment = useCallback(() => {
    setAddComment(!addComment);
  }, [addComment]);

  const handleSaveComment = useCallback(async () => {
    if (isCreating) return;

    setIsCreating(true);

    if (abortCreate.current) {
      abortCreate.current.abort();
    }
    abortCreate.current = apiAborter();

    const response = await apiFetch(
      `/zendesk/ticket/${zendesk_id}/comments`,
      {
        method: HTTP_METHOD.POST,
        signal: abortCreate.current.signal,
        name: 'ZendeskTicketWidget::addComment',
        body: {
          body: 'This is a new comment',
        },
      },
    );

    if (response.success) {
      abortCreate.current = null;
      if (response.body.success === true) {
        setIsCreating(false);
      }
      else if (!response.body.pending) {
        setIsCreating(false);
        console.error(response.body);
      }
    } else if (!response.aborted) {
      abortCreate.current = null;
      console.error(response.body);

      setIsCreating(false);
    }
  }, [apiFetch, isCreating, zendesk_id]);

  /**
  * Initialise response
  */
  useEffect(() => {
    if (!zendesk_id || isLoading || ticketComments.length > 0) return;

    setIsLoading(true);

    if (abortLoad.current) {
      abortLoad.current.abort();
    }
    abortLoad.current = apiAborter();

    apiFetch(
      `/zendesk/ticket/${zendesk_id}/comments`,
      {
        method: HTTP_METHOD.GET,
        signal: abortLoad.current.signal,
        name: 'ZendeskTicketWidget::loadComments',
      },
    ).then((response) => {
      if (response.success) {
        abortLoad.current = null;
        if (response.body.comments) {
          setIsLoading(false);
          setTicketComments(response.body.comments.reverse());
          setTicketUsers(response.body.users);
        }
        else if (!response.body.comments) {
          setIsLoading(false);
          console.error(response);
        }
      } else if (!response.aborted) {
        abortLoad.current = null;
        console.error(response.body);

        setIsLoading(false);
      }
    });
  }, [apiFetch, isLoading, ticketComments.length, zendesk_id]);

  if (!zendesk_id) {
    return (
      <Card>
        <CardHeader>
          <h4 className="pull-left"style={{ paddingTop: '0.25em', marginBottom: 0 }}>
            Ticket Details &amp; Comments
            {' '}
            &nbsp;
          </h4>
        </CardHeader>
        <CardBody>
          Please add a Zendesk ID on the Details tab to see ticket info here.
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <h4 className="pull-left"style={{ paddingTop: '0.25em', marginBottom: 0 }}>
          Ticket Details &amp; Comments
          {' '}
          &nbsp;
        </h4>
        <div className="pull-right">
          <Button
            onClick={handleAddComment}
            size="sm"
            color="secondary"
          >
            {addComment ? 'Cancel' : 'Add Comment'}
            {' '}
            <Icon i={addComment ? 'ban' : 'plus'} />
          </Button>
          <Button
            href={`${zdTicketPath}${zendesk_id}`}
            target="_blank"
            size="sm"
            rel="noopener noreferrer"
            color="info"
          >
            View Ticket
            {' '}
            <Icon i="external-link" />
          </Button>
        </div>
      </CardHeader>
      {addComment && (
        <CardBody style={{ borderTop: '1px solid silver', maxHeight: '800px', overflowY: 'auto', overflowX: 'hidden' }}>
          <div style={{ display: 'flex', alignContent: 'stretch' }}>
            <div style={{ flexBasis: '| auto', paddingRight: '1em' }}>
              <img src="https://cihelpdesk.zendesk.com/system/photos/114107235714/170531_115244_mf8626dafb7_avatar.png" alt="author" width="50" style={{ borderRadius: '100%' }} />
            </div>
            <div style={{ flexBasis: '| auto', flexGrow: 1 }}>
              <strong>Tim Ogilvy</strong>
              <p>{`Note via portal on behalf of ${userDetails.name}: `}</p>
              <HtmlInput id="body" name="body" value={null} placeholder="your comment here..." />
            </div>
          </div>
          <div style={{ paddingTop: '2em', textAlign: 'right' }}>
            <Button
              onClick={handleAddComment}
              size="sm"
              color="secondary"
            >
              {addComment ? 'Cancel' : 'Add Comment'}
              {' '}
              <Icon i={addComment ? 'ban' : 'plus'} />
            </Button>
            <Button
              onClick={handleSaveComment}
              size="sm"
              color="success"
            >
              Save Comment
              {' '}
              <Icon i="floppy-o" />
            </Button>
          </div>
        </CardBody>
      )}
      {ticketComments && ticketComments.map((comment) => {
        const ticketUser = ticketUsers.filter((userRecord) => userRecord.id === comment.author_id)[0];
        const userPhotoUrl = ticketUser?.photo ? ticketUser.photo.content_url : defaultPhotoUrl;
        return (
          <CardBody key={comment.id} style={{ borderTop: '1px solid silver', display: 'flex', alignContent: 'stretch' }}>
            <div style={{ flexBasis: '| auto', paddingRight: '1em' }}>
              <a target="_blank" rel="noopener noreferrer" href={`${zdUserPath}${ticketUser?.id}`}>
                <img src={userPhotoUrl} alt="author" width="50" style={{ borderRadius: '100%' }} />
              </a>
            </div>
            <div style={{ flexBasis: '| auto', flexGrow: 1 }}>
              <a target="_blank" rel="noopener noreferrer" href={`${zdUserPath}${ticketUser?.id}`}>
                <strong>{ticketUser?.name}</strong>
              </a>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: comment.html_body }} />
            </div>
          </CardBody>
        );
      })}
      {/* <CardBody>
        <pre>{JSON.stringify({ ticketUsers, ticketComments }, null, 2)}</pre>
      </CardBody> */}
    </Card>
  );
};
