import React from 'react';
import PropTypes from 'prop-types';
import { Container, Card, CardBody } from 'reactstrap';
import PageHeader from '../app-layout/page-header';
import preview from './rd-preview.png';
import { documentTitle } from '../../utils/helpers';

const LEDDesignerPage = (props) => {
  const { title } = props;
  return (
    <Container fluid>
      <PageHeader {...props} />
      {documentTitle(title || null)}
      <Card>
        <CardBody>
          <h3 className="text-danger">
            This version is out of date...
            {' '}
            <a href="http://configurator.videroled.com.au/" target="_blank" rel="noopener noreferrer">
              View Updated Version
            </a>
          </h3>
          <iframe
            title="Room Desginer"
            frameBorder="0"
            src="https://led-panel-designer.ciportal.net/"
            width="100%"
            height="800px"
          >
            <img src={preview} alt="Room Designer preview" width="100%" />
          </iframe>
        </CardBody>
      </Card>
    </Container>
  );
};

LEDDesignerPage.defaultProps = {
  title: 'LED Designer',
};

LEDDesignerPage.propTypes = {
  title: PropTypes.string,
};

export default LEDDesignerPage;
