import React, { useCallback, useContext } from 'react';
import moment from 'moment';

import { CurrentUserContext } from '../providers/current-user-provider';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { IExpenseClaimRecord } from '../../types/expense-claim/expense-claim.record.interface';
import { NewRecordFormProps } from '../../types/poly-form/new-record-form.props';

import { APIPolyForm } from './api-poly-form';

import { mapTableToForm } from '../../helpers/map-table-to-form.helper';

import myExpensesTableInitialSettings from '../../table-definitions/my-expenses-table';
import { DATE_FORMAT } from '../../constants/date-format.const';
import { API_ACTION } from '../../constants/api-action.const';

const formFields = mapTableToForm(myExpensesTableInitialSettings);

export const NewExpenseForm: React.FC<NewRecordFormProps<IExpenseClaimRecord>> = (props) => {
  const {
    onFormComplete, onFormChange, initialData,
  } = props;
  const { userDetails } = useContext(CurrentUserContext);

  /**
   * Fired when the APIPolyForm successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, id: FormFieldComponentProps['value'], newData?: Partial<IExpenseClaimRecord>) => {
    if (onFormComplete) onFormComplete(success, id as IExpenseClaimRecord['id'], newData as IExpenseClaimRecord);
  }, [onFormComplete]);

  return (
    <APIPolyForm
      {...myExpensesTableInitialSettings}
      fields={formFields}
      formData={{
        user_id: userDetails.id,
        user: userDetails,
        approver_id: userDetails.directManager?.id,
        approver: userDetails.directManager,
        expense_date: moment().format(DATE_FORMAT.YEAR_MONTH_DAY_DASHES),
        ...(initialData ?? {}),
      }}
      permittedActions={myExpensesTableInitialSettings.possibleActions.filter((action) => (action.name === API_ACTION.CREATE))}
      onFormComplete={handleFormComplete}
      onFormChange={onFormChange}
      isNewRecord
      isEditing
    >
      <p>This will create your new Expense Claim. Once created, you can upload your receipts and submit it to your manager.</p>
      <hr />
    </APIPolyForm>
  );
};
