import React from 'react';
import FriendlyFormMessage from '../../layout-helpers/friendly-form-message';
import { FormButtons } from '../form-buttons';
import { SwimlaneCard } from '../../project-card-view/swimlane-card';
import { FormRendererProps } from '../../../types/poly-form/form-renderer.props';
import { ICardItem } from '../../../types/cardview/card-item.interface';
import { noop } from '../../../utils/helpers';

export type SwimlaneCardFormRendererProps = FormRendererProps & {
  formData: ICardItem
}

/**
 * <SwimlaneCardFormRenderer />
 * @param {*} props
 */
const SwimlaneCardFormRenderer:React.FC<SwimlaneCardFormRendererProps> = (props) => {
  const {
    primaryKeyFieldName,
    isBusy,
    isLocked,
    isEditing,
    isReadOnly,
    isNewRecord,
    hasErrors,
    hasSuccess,
    formMessage,
    formData,
    errors,
    formDeleteConfirmationType,
    permittedActions,
    rendererOptions,
    onFieldChange,
    startEditRecord,
    endEditRecord,
    deleteRecord,
  } = props;

  const itemPrimaryKeyValue = isNewRecord ? null : formData[primaryKeyFieldName ?? 'id'];

  /**
   * Wrap a SwimlaneCard as a FormRenderer to provide save and update capabilities.
   *
   * Returns <SwimlaneCard />
   */
  return (
    <SwimlaneCard
      countFrom={1}
      index={formData.index}
      cardItem={formData}
      isEditing={isEditing}
      onFieldChange={onFieldChange}
      save={endEditRecord ? (newFormData) => {
        // @todo move the state management back up to the CardSwimlanes Container
        endEditRecord(true, itemPrimaryKeyValue, newFormData, true);
      } : noop}
      isBusy={isBusy}
      formButtons={(
        <FormButtons
          permittedActions={permittedActions}
          formDeleteConfirmationType={formDeleteConfirmationType}

          formIsEditing={isEditing}
          formIsReadOnly={isReadOnly}
          formIsCreating={isNewRecord ?? false}
          formIsBusy={isBusy ?? false}
          formIsLocked={isLocked}

          inline
          secondary={false}

          startEditRecord={startEditRecord ? () => startEditRecord(itemPrimaryKeyValue, formData) : undefined}
          endEditRecord={endEditRecord ? (saveChanges) => endEditRecord(saveChanges, itemPrimaryKeyValue, formData) : undefined}
          deleteRecord={deleteRecord ? () => deleteRecord(itemPrimaryKeyValue, formData) : undefined}

          deleteInEditMode
          className="btn-group pull-right mt-2"
        />
      )}
      feedbackPanel={(hasErrors ? (
        <div className="pt-4">
          <FriendlyFormMessage
            formMessage={formMessage}
            hasSuccess={hasSuccess}
            hasErrors={hasErrors}
            errors={errors}
            showList
            inline
          />
        </div>
      ) : undefined)}
      {...rendererOptions}
    />
  );
};

export default SwimlaneCardFormRenderer;
