import { TOGGLE_SIDEBAR } from '../actions/settings-actions';

const initialState = {
  sidebarExpanded: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarExpanded: action.sidebarExpanded,
      };
    default:
      return state;
  }
};
