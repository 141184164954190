/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import shortId from 'shortid';
import { withRouter } from 'react-router-dom';
import { toParams, toQueryString, debugLog } from '../../utils/helpers';
import { PUSH_OR_REPLACE } from '../../utils/constants';


export const REMOVE_FROM_URL = Symbol('REMOVE_FROM_URL');

export const UrlStateManagerContext = React.createContext({ initialisedComponents: [], urlState: {}, setUrlState: () => {} });


/**
 * @class
 * @name InnerUrlStateManagerProvider
 *
 * @description
 * Provides context for Url State Management. Called "Inner" since it gets
 * wrapped in withRouter to guarantee to history and location props
 *
 * TODO: heavy optimisation (via caching)...
 *  - Stop calling toParams as much
 *  - Do not return a different context value on each render
 *
 * @private
 */
class InnerUrlStateManagerProvider extends React.Component {
  /**
   * @constructor
   *
   * @param {} props
   */
  constructor(props) {
    super(props);
    this.state = { initialisedComponents: [] };
  }


  /**
   * @description
   * Cause the QueryString (urlState) to change
   *
   * @param {Record<string, string | number | boolean | typeof REMOVE_FROM_URL>} incomingWashedComponentUrlState
   * @param {(typeof PUSH_OR_REPLACE).PUSH | (typeof PUSH_OR_REPLACE).REPLACE} [pushOrReplace=(typeof PUSH_OR_REPLACE).PUSH]
   * @param {string} [componentId=undefined]
   */
  setUrlState = (incomingWashedComponentUrlState, pushOrReplace = PUSH_OR_REPLACE.PUSH, componentId) => {
    const { history, location } = this.props;
    const urlState = toParams(location.search, true);

    // update the URL params while preserving their order
    Object.entries(incomingWashedComponentUrlState).forEach(([key, value]) => {
      const valueType = typeof value;

      if (value === REMOVE_FROM_URL) {
        delete urlState[key];
        return;
      }

      // don't allow setting of values that aren't strings, booleans, or numbers
      // usually this is only done by accident and is confusing to debug
      if (!(valueType === 'string' || valueType === 'boolean' || valueType === 'number')) {
        debugLog(
          `UrlStateManager:setUrLState - warning: cannot set urlState "${key}" to unhandled value type: "${valueType}". Removing from urlState instead`,
          'warn', { key, value, valueType }, '🐣',
        );
        delete urlState[key];
        return;
      }

      urlState[key] = value;
    });

    const newQueryString = toQueryString(urlState);

    debugLog(
      'UrlStateManager:setUrlState - changing QueryString',
      'success', {
        pushOrReplace, incomingWashedComponentUrlState, newUrlState: urlState, oldQueryString: location.search, newQueryString,
      }, '🐣',
    );

    if (pushOrReplace === PUSH_OR_REPLACE.PUSH) history.push({ search: newQueryString });
    else if (pushOrReplace === PUSH_OR_REPLACE.REPLACE) history.replace({ search: newQueryString });

    if (componentId) this.initialiseComponent(componentId);
  }


  /**
   * @description
   * Let a component be aware its urlState has been initialised
   *
   * @param {string} componentId
   */
  initialiseComponent = (componentId) => {
    if (this.state.initialisedComponents.includes(componentId)) return;
    this.setState((oldState) => ({ initialisedComponents: [...oldState.initialisedComponents, componentId] }));
  }


  /**
   * @inheritdoc
   */
  render() {
    const { children, location } = this.props;
    const { initialisedComponents } = this.state;
    return (
      //  TODO: optimise
      <UrlStateManagerContext.Provider
        value={{
          initialisedComponents,
          urlState: toParams(location.search, true),
          setUrlState: this.setUrlState,
        }}
      >
        {children}
      </UrlStateManagerContext.Provider>
    );
  }
}

InnerUrlStateManagerProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,

  history: PropTypes.shape({
    listen: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,

  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};


/**
 * @description
 * Provider component
 *
 * @public
 */
export const UrlStateManagerProvider = withRouter(InnerUrlStateManagerProvider);


/**
 * @class
 * @name UrlConnectionInitialiser
 *
 * @description
 * Intermediate component that ensures children aren't rendered until a washed urlState is ready
 *
 * TODO: optimise with caching - know which properties to care about and only re-render when those change
 *
 * @private
 */
export class UrlConnectionInitialiser extends React.Component {
  /**
   * @constructor
   *
   * @param {{}} props
   */
  constructor(props) {
    super(props);
    this.componentId = shortId();

    const { washUrlStateFromComponent, washUrlStateFromQueryString, urlState } = props;

    // wash the initial urlState received
    props.setUrlState(washUrlStateFromComponent(washUrlStateFromQueryString(urlState)), PUSH_OR_REPLACE.REPLACE, this.componentId);
  }


  /**
   * @description
   * Determine if the component has been initialised
   *
   * Lets us know if it's safe to render children
   * @returns {boolean}
   */
  hasBeenInitialised = () => this.props.initialisedComponents.includes(this.componentId)


  /**
   * @inheritdoc
   */
  render = () => {
    const { children } = this.props;
    const initialised = this.hasBeenInitialised();

    if (!initialised) return null;

    return (
      <>
        {children}
      </>
    );
  }
}

UrlConnectionInitialiser.propTypes = {
  initialisedComponents: PropTypes.arrayOf(PropTypes.string).isRequired,
  setUrlState: PropTypes.func.isRequired,
  urlState: PropTypes.shape({}).isRequired,
  washUrlStateFromComponent: PropTypes.func.isRequired,
  washUrlStateFromQueryString: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};


/**
 * @description
 * Connect a react component the the URL
 *
 * @example
 * class MyComponent {
 *  ...
 * }
 * export default connectUrlState((dirtyUrlState) => dirtyUrlState)(MyComponent)
 *
 * @param {(dirtyUrlState: Record<string, string>) => Record<string, any>} washUrlStateFromQueryString
 * @param {(componentUrlState: Record<string, any>) => Record<string, string | number | boolean | typeof REMOVE_FROM_URL>} washUrlStateFromComponent
 * @returns {(component: React.Component) => ((props: Record<string, any>) => React.Component)}
 *
 * @public
 */
export const connectUrlState = (
  washUrlStateFromQueryString = (dirtyUrlState) => dirtyUrlState,
  washUrlStateFromComponent = (componentUrlState) => componentUrlState,
) => (Component) => function ConnectUrlState(props) {
  return (
    <UrlStateManagerContext.Consumer>
      {({ initialisedComponents, urlState, setUrlState }) => (
        <UrlConnectionInitialiser
          urlState={urlState}
          washUrlStateFromQueryString={washUrlStateFromQueryString}
          washUrlStateFromComponent={washUrlStateFromComponent}
          initialisedComponents={initialisedComponents}
          setUrlState={setUrlState}
        >
          <Component
            urlState={washUrlStateFromQueryString(urlState)}
            setUrlState={(componentUrlState, pushOrReplace) => setUrlState(washUrlStateFromComponent(componentUrlState), pushOrReplace)}
            {...props}
          />
        </UrlConnectionInitialiser>
      )}
    </UrlStateManagerContext.Consumer>
  );
};

/**
 * @description
 * Wash the componentUrlState being passed into the url to mark any defaults for removal
 *
 * @param {Record<string, string | number | boolean | typeof REMOVE_FROM_URL>} defaults
 * @param {(componentUrlState: Record<string, any>) => Record<string, string | number | boolean | typeof REMOVE_FROM_URL>} washingFunction
 * @returns {(componentUrlState: Record<string, any>) => Record<string, string | number | boolean | typeof REMOVE_FROM_URL>}
 */
export const cleanDefaultsFromUrlState = (defaults, washingFunction = (componentUrlState) => componentUrlState) => (componentUrlState) => {
  const washedComponentUrlState = washingFunction(componentUrlState);

  const result = Object.entries(washedComponentUrlState).reduce((stateWithDefaultsRemoved, [key, value]) => {
    // if matching the default value, change value to REMOVE_FROM_URL
    if (value === defaults[key]) stateWithDefaultsRemoved[key] = REMOVE_FROM_URL;
    else stateWithDefaultsRemoved[key] = value;
    return stateWithDefaultsRemoved;
  }, {});

  return result;
};

