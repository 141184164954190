import { FilesWidget } from '../components/widgets/files-widget';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';

export const ExpenseClaimFilesWidgetDefinition: ICollectionWidgetDefinition = {
  component: FilesWidget,
  formRendererType: FORM_RENDERER_TYPE.FILE,
  itemCaption: 'file',
  fields: [
    {
      // Specifying the parent ID field makes sure a new file is assigned to the parent expense claim
      name: 'expense_claim_id',
      isParentId: true,
      visible: false,
    },
  ],
};
