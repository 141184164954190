import React from 'react';
import { IModalWizardPageDataError } from '../../types/modal/modal-wizard-page-data-error.interface';
import { getFieldPageDataErrors } from '../../helpers/get-field-page-data-errors.helper';

export type WizardFormErrorRowProps = {
  fieldName: string,
  pageDataErrors?: null | IModalWizardPageDataError[],
}

export const WizardFormErrorRow:React.FC<WizardFormErrorRowProps> = ({
  fieldName,
  pageDataErrors,
}) => {
  const fieldDataErrors = getFieldPageDataErrors(pageDataErrors, fieldName);

  if (fieldDataErrors === false) return null;

  return (
    <>
      <div className="error-row-spacer" />
      <div
        className="error-row"
      >
        {fieldDataErrors.map((fieldDataError, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={index}>
            {fieldDataError.error}
          </span>
        ))}
      </div>
    </>
  );
};
