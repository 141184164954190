import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import Router from './router/router';

const Root = ({ store }) => (
  <Provider store={store}>
    <Router />
  </Provider>
);

Root.propTypes = {
  store: PropTypes.shape({}).isRequired, // eslint-disable-line react/forbid-prop-types
};

export default Root;
