import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { FormRendererProps } from '../../../types/poly-form/form-renderer.props';
import { INotificationRecord } from '../../../types/notification/notification.record.interface';

export type NotificationFormRendererProps = FormRendererProps & {
  formData: INotificationRecord,
}

export const NotificationFormRenderer:React.FC<NotificationFormRendererProps> = (props) => {
  const {
    formData,
  } = props;

  const itemData = JSON.parse(formData.data as unknown as string) || {};
  return (
    <div className="user-notification" key={formData.id}>
      <div className="notification-shortmessage">{itemData.shortMessage}</div>
      {itemData.link && (
        <Link to={itemData.link} className="notification-link">
          {itemData.linkText || 'View Details'}
        </Link>
      )}
      <div className="notification-time">
        {moment(formData.user_audit.created_at).fromNow()}
        &nbsp; - &nbsp;
        {moment(formData.user_audit.created_at).format('D/M/YY h:mm a')}
      </div>
    </div>
  );
};
