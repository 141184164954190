import React from 'react';

import moment from 'moment';
import { ConfirmActionModalResult } from '../../../../types/modal/modal-result';
import { IRenewContractModalWizardPage1Data, IRenewContractModalWizardState } from '../renew-contract-data.interface';

import { CheckBox } from '../../../form-input/checkbox';
import { DateInput } from '../../../form-input/date-input';
import { Label } from '../../../layout-helpers/label';
import { ModalWizardPage, ModalWizardPageProps } from '../../modal-wizard-page';
import { WizardFormErrorRow } from '../../../layout-helpers/wizard-form-error-row';

import { getFieldPageDataErrors } from '../../../../helpers/get-field-page-data-errors.helper';
import { NumericInput } from '../../../form-input/numeric-input';
import { HtmlInput } from '../../../form-input/html-input';

export const RenewContractModalPage1 = (props: ModalWizardPageProps<
  IRenewContractModalWizardPage1Data,
  IRenewContractModalWizardState,
  ConfirmActionModalResult
>): React.ReactElement => {
  const { pageData, pageDataErrors, onPageDataFieldChanged, wizardState: { contractRecord } } = props;

  /**
   * Render
   */
  return (
    <ModalWizardPage<IRenewContractModalWizardPage1Data, IRenewContractModalWizardState, ConfirmActionModalResult>
      {...props}
    >
      <div className="wizard-page-section">
        <div className="wizard-page-section-heading">
          <span>Step 1: Renewal Dates &amp; Terms</span>
        </div>
        <div className="wizard-page-section-content">
          <div className="wizard-form">
            {/* Clone Existing Notes */}
            <Label
              for="clone_notes"
              hasError={getFieldPageDataErrors(pageDataErrors, 'clone_notes') !== false}
            >
              Clone Existing Notes
            </Label>
            <CheckBox
              id="clone_notes"
              name="clone_notes"
              // label="Clone Existing Notes"
              infoToolTip={{
                title: 'Clone Existing Notes',
                body: 'Check this box if you want to clone all existing notes.',
              }}
              inline
              value={pageData.clone_notes}
              onChange={onPageDataFieldChanged}
            />
            {contractRecord && contractRecord.type && contractRecord.type.name
              && (
                contractRecord.type.name === 'Product Licence'
                ||
                contractRecord.type.name === 'Service Subscription'
              ) && (
              <>
                {/* Clone Existing Serials */}
                <Label
                  for="clone_serials"
                  hasError={getFieldPageDataErrors(pageDataErrors, 'clone_serials') !== false}
                >
                  Clone Existing Serials
                </Label>
                <CheckBox
                  id="clone_serials"
                  name="clone_serials"
                  // label="Clone Existing Serials"
                  infoToolTip={{
                    title: 'Clone Existing Serials',
                    body: 'Check this box if you want to clone all existing serials.',
                  }}
                  inline
                  value={pageData.clone_serials}
                  onChange={onPageDataFieldChanged}
                />
              </>
            )}
            {/* Pre-Fill dates from existing */}
            <Label
              for="automatically_generate_renewal"
              hasError={getFieldPageDataErrors(pageDataErrors, 'automatically_generate_renewal') !== false}
            >
              Pre-fill Dates
            </Label>
            <CheckBox
              id="automatically_generate_renewal"
              name="automatically_generate_renewal"
              // label="Prefill from existing contract"
              infoToolTip={{
                title: 'Generate dates from existing contract',
                body: 'Check this box if you want the contract renewed from the previous expiry date, with identical settings',
              }}
              inline
              value={pageData.automatically_generate_renewal}
              onChange={onPageDataFieldChanged}
            />

            {/* === Start Date === */}
            <Label
              for="contract_months"
              hasError={getFieldPageDataErrors(pageDataErrors, 'contract_months') !== false}
            >
              Contract Months
            </Label>
            {!pageData.automatically_generate_renewal ? (
              <NumericInput
                id="contract_months"
                name="contract_months"
                isClearable
                fractionDigits={0}
                value={pageData.contract_months}
                disabled={!!pageData.automatically_generate_renewal}
                hasError={getFieldPageDataErrors(pageDataErrors, 'contract_months') !== false}
                onChange={onPageDataFieldChanged}
              />
            ) : (
              <span>{pageData.contract_months}</span>
            )}
            {/* === Start Date === */}
            <Label
              for="start_date"
              hasError={getFieldPageDataErrors(pageDataErrors, 'start_date') !== false}
            >
              Start Date
            </Label>
            {!pageData.automatically_generate_renewal ? (
              <DateInput
                id="start_date"
                name="start_date"
                placeholder="Start Date"
                value={pageData.start_date}
                disabled={!!pageData.automatically_generate_renewal}
                useStringValue
                hasError={getFieldPageDataErrors(pageDataErrors, 'start_date') !== false}
                onChange={onPageDataFieldChanged}
              />
            ) : (
              <span>{moment(pageData.start_date).format('Y-MM-DD')}</span>
            )}
            <WizardFormErrorRow fieldName="start_date" pageDataErrors={pageDataErrors} />


            {/* === End Date2 === */}
            <Label
              for="end_date"
              hasError={getFieldPageDataErrors(pageDataErrors, 'end_date') !== false}
            >
              End Date
            </Label>
            {!pageData.automatically_generate_renewal ? (
              <DateInput
                id="end_date"
                name="end_date"
                placeholder="End Date"
                value={pageData.end_date}
                disabled={!!pageData.automatically_generate_renewal}
                useStringValue
                hasError={getFieldPageDataErrors(pageDataErrors, 'end_date') !== false}
                onChange={onPageDataFieldChanged}
              />
            ) : (
              <span>{pageData.end_date ? moment(pageData.end_date).format('Y-MM-DD') : '-'}</span>
            )}
            <WizardFormErrorRow fieldName="end_date" pageDataErrors={pageDataErrors} />

            {/* === note === */}
            <Label for="note" style={{ alignItems: 'flex-start' }}>Notes</Label>
            <HtmlInput
              id="note"
              name="note"
              value={pageData.note}
              placeholder="notes"
              onChange={onPageDataFieldChanged}
            />
          </div>
        </div>
        {/* <pre>{JSON.stringify(contractRecord, null, 2)}</pre> */}
      </div>
    </ModalWizardPage>
  );
};
