import React from 'react';
import PropTypes from 'prop-types';

const DropdownSublist = ({ children, open }) => (
  <ul className={`collapse ${open ? 'in' : ''}`}>
    {children}
  </ul>
);

DropdownSublist.defaultProps = {
  children: null,
  open: false,
};

DropdownSublist.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  open: PropTypes.bool,
};

export default DropdownSublist;
