import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Card, CardHeader, CardBody, Button, Alert,
} from 'reactstrap';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { serializeQueryParams } from '../../helpers/serialize-query-params.helper';
import { formatValueString } from '../render-functions';
import Icon from '../layout-helpers/icon';
import DtoolsOrderTable from './project-orders/dtools-order-table';
import ExistingQuickBooksOrderTable from './project-orders/existing-quickbooks-order-table';
import DToolsOrderItemsTable from './project-orders/d-tools-order-items-table';
import BasicVendorPicker from './project-orders/basic-vendor-picker';
import FullPageLoadingSpinner from '../layout-helpers/full-page-loading-spinner';
import { PERMISSION } from '../../constants/permissions.const';
import { CURRENT_USER_PROVIDER_PROP_TYPES } from '../../prop-types/current-user-provider-prop-types';
import { connectToCurrentUserProvider } from '../providers/current-user-provider';
import API_PROVIDER_PROP_TYPES from '../../prop-types/api-provider-prop-types';
import { connectToAPIProvider } from '../providers/api-provider';
import { COLUMN_FORMAT } from '../../constants/column-format.const';

/**
 * @class ProjectOrders
 * ProjectOrders component for Data Table
 *
 * @todo: needs more tidy up
 */
class DToolsProjectOrders extends React.Component {
  constructor(props) {
    super(props);

    const { currentUserProvider: { userHasPermissions } } = props;

    this.state = {
      data: [],
      orderData: null,
      loading: true,
      vendorSearchTerm: '',
      searchingVendor: false,
      quickbooksResponse: null,
      searchingForOrder: false,
      showDescriptions: false,
      chosenVendor: '',
      createdResponse: null,
      creatingOrder: false,
      alert: null,
      canPushToQuickBooks: userHasPermissions(PERMISSION.PROJECT_PUSH_PURCHASE_ORDER_TO_QUICKBOOKS),
    };
  }

  /**
   * @inheritdoc
   */
  componentDidMount = () => {
    this.loadPurchaseOrders();
  };


  /**
   * @inheritdoc
   */
  componentDidUpdate = (newProps) => {
    const { projectData } = this.props;
    if (newProps.projectData.id !== projectData.id) {
      this.loadPurchaseOrders();
    }
  };


  /**
   * @description
   * @todo: description
   */
  setChosenVendor = (event) => {
    this.setState({
      chosenVendor: event.target.value,
    });
  };


  /**
   * @description
   * @todo: description
   */
  changeVendorSearchTerm = (event) => {
    this.setState({
      vendorSearchTerm: event.target.value,
    });
  };


  /**
   * @description
   * Toggle showing descriptions on or off
   */
  toggleDescriptions = () => {
    const { showDescriptions } = this.state;
    this.setState({
      showDescriptions: !showDescriptions,
    });
  };


  /**
   * @description
   * @todo: description
   */
  loadPurchaseOrderSummaryView = (rowData) => {
    this.setState({
      showingOrder: rowData,
    }, async () => {
      const { dtoolsId, apiProvider: { apiFetch } } = this.props;
      const { Vendor: rawVendor, OrderNumber: rawOrderNumber } = rowData;
      const encodedVendor = encodeURIComponent(rawVendor);
      const encodedOrder = encodeURIComponent(rawOrderNumber);
      const response = await apiFetch(
        `/dtools-connector/project/${dtoolsId}/purchaseorder?vendor=${encodedVendor}&order_number=${encodedOrder}&summarise=true`,
        {
          name: 'DToolsProjectOrders:loadPurchaseOrderSummaryView',
          // signal: // TODO: abort signal
        },
      );

      if (response.success) {
        this.setState({
          orderData: response.body.data || [],
          loading: false,
          quickbooksResponse: null,
          searchingForOrder: false,
          chosenVendor: '',
        });
      } else if (!response.aborted) {
        // TODO: error state?
        console.error('DToolsProjectOrders.loadPurchaseOrderSummaryView', response.error);
      }
    });
  };


  /**
   * @description
   * Load the purchase order data
   */
  loadPurchaseOrders = () => {
    const asyncLoadPurchaseOrders = async () => {
      const { dtoolsId, apiProvider: { apiFetch } } = this.props;

      const response = await apiFetch(
        `/dtools-connector/project/${dtoolsId}/purchaseorders`,
        {
          name: 'DToolsProjectOrders:loadPurchaseOrders',
          // signal: // TODO: abort signal
        },
      );

      if (response.success) {
        this.setState({
          data: response.body.data || [],
          loading: false,
          quickbooksResponse: null,
          chosenVendor: '',
          searchingForOrder: false,
        });
      } else if (!response.aborted) {
        // TODO: error state?
        console.error('DToolsProjectOrders.loadPurchaseOrders', response.error);
      }
    };

    const { loading } = this.state;
    if (!loading) {
      this.setState({
        data: [],
        orderData: null,
        loading: true,
        vendorSearchTerm: '',
        searchingVendor: false,
        quickbooksResponse: null,
        searchingForOrder: false,
        showDescriptions: false,
        chosenVendor: '',
        createdResponse: null,
        creatingOrder: false,
        alert: null,
      }, asyncLoadPurchaseOrders);
    } else {
      asyncLoadPurchaseOrders();
    }
  };


  /**
   * @description
   * fired when the user starts looking for a vendor
   */
  searchForVendors = () => {
    this.setState({
      searchingVendor: true,
    }, async () => {
      const { vendorSearchTerm, quickbooksResponse } = this.state;
      const { apiProvider: { apiFetch } } = this.props;

      const response = await apiFetch(
        `/quickbooks-connector/vendor?search=${vendorSearchTerm}`,
        {
          name: 'DToolsProjectOrders:searchForVendors',
          // signal: // TODO: abort signal
        },
      );

      const myobResponse = await apiFetch(
        `/myob-advanced-connector/vendor?search=${vendorSearchTerm}`,
        {
          name: 'DToolsProjectOrders:searchMyobForVendors',
          // signal: // TODO: abort signal
        },
      );

      if (myobResponse.success) {
        this.setState({
          quickbooksResponse: {
            ...quickbooksResponse,
            vendor_matches: myobResponse.body,
          },
          searchingVendor: false,
          chosenVendor: '',
        });
      } else if (!response.aborted) {
        // TODO: error state?
        console.error('DToolsProjectOrders.searchForVendors', response.error);
      }
    });
  };


  /**
   * @description
   * Search the API for an order in Quickbooks
   *
   * @param {object} rowData the currently selected row
   */
  findOrderInQuickBooks = (rowData) => {
    this.setState({
      quickbooksResponse: null,
      searchingForOrder: true,
    }, async () => {
      const { dtoolsId, projectData, apiProvider: { apiFetch } } = this.props;

      const queryString = serializeQueryParams({
        project_id: projectData.id,
        vendor_name: rowData.Vendor,
        order_number: rowData.OrderNumber,
        order_date: moment(new Date(parseInt(rowData.OrderedDate.substr(6, 13), 10))).format('YYYY-MM-DD'),
      });

      const response = await apiFetch(
        `/dtools-connector/project/${dtoolsId}/quickbooks-order-status?${queryString}`,
        {
          name: 'DToolsProjectOrders:findOrderInQuickBooks',
          // signal: // TODO: abort signal
        },
      );

      if (response.success) {
        this.setState({
          quickbooksResponse: response.body || [],
          searchingForOrder: false,
          chosenVendor: '',
          alert: null,
        });
      } else if (!response.aborted) {
        // TODO: error state?
        console.error('DToolsProjectOrders.findOrderInQuickBooks', response.error);
      }
    });
  };


  /**
   * @description
   * call the API and have it add the order to quickbooks
   *
   * @param {object} rowData the currently selected row
   */
  addOrderToQuickBooks = (rowData) => {
    this.setState({
      createdResponse: null,
      creatingOrder: true,
    }, async () => {
      const { dtoolsId, projectData, apiProvider: { apiFetch } } = this.props;
      const { chosenVendor } = this.state;

      const response = await apiFetch(
        `/dtools-connector/project/${dtoolsId}/push-quickbooks-order`,
        {
          name: 'DToolsProjectOrders:findOrderInQuickBooks',
          method: HTTP_METHOD.POST,
          body: {
            project_id: projectData.id,
            vendor_name: rowData.Vendor,
            vendor_ref: chosenVendor,
            order_number: rowData.OrderNumber,
            order_date: moment(new Date(parseInt(rowData.OrderedDate.substr(6, 13), 10))).format('YYYY-MM-DD'),
          },
        },
      );

      if (response.success) {
        let alert = null;

        if (response.body.message) {
          alert = {
            message: response.body.message,
            type: 'danger',
          };
        }

        if (response.body.Id) {
          if (rowData.OrderNumber !== response.body.DocNumber) {
            // TODO: Why is the link to quickbooks hard-coded here?
            alert = {
              message:
              ' Order was imported with an appended order number. ' +
              ' Please update quickbooks manually to correct it: ',
              href: `https://c17.qbo.intuit.com/app/purchaseorder?txnId=${response.body.Id}`,
              linkName: ` Click Me: ${response.body.DocNumber} `,
              type: 'danger',
            };
          }
          else {
            // TODO: Why is the link to quickbooks hard-coded here?
            alert = {
              message: 'Order was imported successfully!',
              href: `https://c17.qbo.intuit.com/app/purchaseorder?txnId=${response.body.Id}`,
              linkName: ` Open it in quickbooks ${response.body.DocNumber} `,
              type: 'success',
            };
          }
        }

        this.setState({
          createdResponse: response.body || [],
          creatingOrder: false,
          alert,
        }, () => {
          if (response.body.Id) {
            const { showingOrder } = this.state;
            this.findOrderInQuickBooks(showingOrder);
          }
        });
      } else if (!response.aborted) {
        // TODO: error state?
        this.setState({
          creatingOrder: false,
          alert: {
            message:
            `Failed to import the order into Quickbooks. ${response.message ?? response.body?.message ?? response.body}`,
            type: 'danger',
          },
        });
        console.error('DToolsProjectOrders.addOrderToQuickBooks', response.error);
      }
    });
  };


  /**
   * @description
   * @todo: comment
   */
  resetCreateState = () => this.setState({
    creatingOrder: false,
    createdResponse: null,
  });


  /**
   * @description
   * @todo: comment
   */
  closeOpenOrder = () => {
    this.setState({
      orderData: null,
      vendorSearchTerm: '',
      searchingVendor: false,
      quickbooksResponse: null,
      searchingForOrder: false,
      showDescriptions: false,
      chosenVendor: '',
      createdResponse: null,
      creatingOrder: false,
    });
  };


  /**
   * @inheritdoc
   */
  render = () => {
    const {
      data,
      orderData,
      loading,
      showingOrder,
      showDescriptions,
      quickbooksResponse,
      searchingVendor,
      searchingForOrder,
      vendorSearchTerm,
      chosenVendor,
      createdResponse,
      creatingOrder,
      alert,
      canPushToQuickBooks,
    } = this.state;

    if (loading) return <FullPageLoadingSpinner />;

    return (
      <Card>
        {/* Project Order Card Header */}
        <CardHeader className="bg-info text-white p-0" tag="h4">
          {orderData ? (
            <div className="p-3 pull-left">
              <span>
                {`${showingOrder.Vendor} | ${showingOrder.OrderNumber} | ${formatValueString(
                  showingOrder.OrderedDate,
                  COLUMN_FORMAT.DTOOLS_DATE,
                )}`}
              </span>
            </div>
          ) : (
            <div className="p-3 pull-left">Project Supplier Orders</div>
          )}
          {orderData && (
            <div className="pull-right m-2">
              <Button color="info" onClick={this.toggleDescriptions}>
                <Icon i="indent" />
              </Button>
              <Button color="info" onClick={this.closeOpenOrder}>
                <Icon i="close" />
              </Button>
            </div>
          )}
        </CardHeader>

        {/* Showing a Specific Order View */}
        {orderData && (
          <>
            <CardBody className="bg-dark text-light p-3 pb-1">
              <h4>
                Order Data
                <Button
                  className="ml-4"
                  color="green"
                  size="sm"
                  disabled={searchingForOrder}
                  onClick={() => this.findOrderInQuickBooks(showingOrder)}
                >
                  <Icon i="search" isBusy={searchingForOrder} />
                  &nbsp; Search QuickBooks
                </Button>
                {quickbooksResponse && quickbooksResponse.order_exists && <span> &nbsp; Exists in QuickBooks:</span>}
                {quickbooksResponse && canPushToQuickBooks && (
                  <div className="form-inline ml-2 pull-right">
                    <span style={{ color: '#444' }}>{chosenVendor}</span>
                    {quickbooksResponse.vendor_matches && (
                      <BasicVendorPicker
                        chosenVendor={chosenVendor}
                        setChosenVendor={this.setChosenVendor}
                        quickbooksResponse={quickbooksResponse}
                        changeVendorSearchTerm={this.changeVendorSearchTerm}
                        vendorSearchTerm={vendorSearchTerm}
                        searchForVendors={this.searchForVendors}
                        searchingVendor={searchingVendor}
                      />
                    )}
                    {quickbooksResponse && chosenVendor && !createdResponse && canPushToQuickBooks && (
                      <Button
                        className="ml-1"
                        color={quickbooksResponse.order_exists ? 'warning' : 'green'}
                        size="sm"
                        disabled={creatingOrder}
                        onClick={() => this.addOrderToQuickBooks(showingOrder)}
                      >
                        <Icon i="cloud-upload" isBusy={creatingOrder} />
                        &nbsp;
                        {' '}
                        {quickbooksResponse.order_exists ? 'Attempt Push Anyway!' : 'Add to QuickBooks'}
                      </Button>
                    )}
                    {quickbooksResponse && chosenVendor && createdResponse && createdResponse && canPushToQuickBooks && (
                      <Button
                        className="ml-1"
                        color={createdResponse.Id ? 'green' : 'danger'}
                        size="sm"
                        onClick={this.resetCreateState}
                      >
                        <Icon i={createdResponse.Id ? 'check' : 'times'} isBusy={creatingOrder} />
                        &nbsp;
                        {' '}
                        {createdResponse.Id ? 'Created' : 'Failed'}
                      </Button>
                    )}
                  </div>
                )}
              </h4>
            </CardBody>
            {alert && (
              <Alert color={alert.type || 'info'} className="mb-0">
                {alert.message}
                {' '}
                {alert.href && (
                  <a href={alert.href} target="_blank" rel="noopener noreferrer">
                    {alert.linkName}
                  </a>
                )}
              </Alert>
            )}
            {/**
             * Showing existing quickbooks orders
             *
             */}
            {quickbooksResponse && quickbooksResponse.order_exists && (
              <ExistingQuickBooksOrderTable quickbooksResponse={quickbooksResponse} />
            )}
            {/**
             * Showing the Order Items
             *
             */}
            <DToolsOrderItemsTable orderData={orderData} showDescriptions={showDescriptions} showingOrder={showingOrder} />
          </>
        )}
        {/**
         * If not a specific order, then show the list of D-Tools Matches
         *
         */}
        {!orderData && (
          <DtoolsOrderTable data={data} loadPurchaseOrderSummaryView={this.loadPurchaseOrderSummaryView} />
        )}
      </Card>
    );
  };
}

DToolsProjectOrders.propTypes = {
  dtoolsId: PropTypes.string.isRequired,
  projectData: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
  currentUserProvider: PropTypes.shape(CURRENT_USER_PROVIDER_PROP_TYPES).isRequired,
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
};

export default connectToAPIProvider(connectToCurrentUserProvider(DToolsProjectOrders));
