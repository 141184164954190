export const PROJECT_INVOICE_TYPE = {
  DEPOSIT: 1,
  PROGRESS_PAYMENT: 2,
  FINAL_PAYMENT: 3,
  WHOLE_ORDER: 4,
  HARDWARE: 5,
  MAINTENANCE: 6,
  SERVICES: 7,
  VARIATION: 8,
} as const;
export type PROJECT_INVOICE_TYPE = typeof PROJECT_INVOICE_TYPE;
export type A_PROJECT_INVOICE_TYPE = PROJECT_INVOICE_TYPE[keyof PROJECT_INVOICE_TYPE];

export const PROJECT_INVOICE_TYPE_NAME_MAP: {[K in A_PROJECT_INVOICE_TYPE]: string} = {
  [PROJECT_INVOICE_TYPE.DEPOSIT]: 'Deposit',
  [PROJECT_INVOICE_TYPE.PROGRESS_PAYMENT]: 'Progress Payment',
  [PROJECT_INVOICE_TYPE.FINAL_PAYMENT]: 'Final Payment',
  [PROJECT_INVOICE_TYPE.WHOLE_ORDER]: 'Whole Order',
  [PROJECT_INVOICE_TYPE.HARDWARE]: 'Hardware',
  [PROJECT_INVOICE_TYPE.MAINTENANCE]: 'Maintenance',
  [PROJECT_INVOICE_TYPE.SERVICES]: 'Services',
  [PROJECT_INVOICE_TYPE.VARIATION]: 'Variation',
};
