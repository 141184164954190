import React from 'react';

import { DataTablePageProps } from '../../types/data-table-page.props';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';

export type ProcessExpenseClaimsDataTablePageProps = DataTablePageProps;

/**
 * Display the "Process Expenses" data table as a whole page
 */
export const ProcessExpenseClaimsDataTablePage: React.FC<ProcessExpenseClaimsDataTablePageProps> = (props) => (
  <DataTablePage
    {...props}
    tableIdentifier={TABLE_IDENTIFIER.PROCESS_EXPENSE_CLAIMS_TABLE}
    title="Process Expense Claims"
  />
);
