import React, { useCallback, useContext } from 'react';
import classNames from 'classnames';
import { Button } from 'reactstrap';

import { ModalContext } from '../modals/modal-context';

import { CollectionWidgetProps } from '../../types/collection.widget.props';
import { IContractRecord } from '../../types/service/contract.record.interface';
import { ReturnDataModalResult } from '../../types/modal/modal-result';

import { CollectionWidget } from './collection.widget';
import { CollectionWidgetHeader } from './collection-widget-header';
import Icon from '../layout-helpers/icon';
import { hasPermittedAction } from '../../helpers/has-permitted-action.helper';

import { API_ACTION } from '../../constants/api-action.const';
import { ICON } from '../../constants/icon.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { IContractScheduleCheckRecord } from '../../types/contact/contract-schedule-check.record.interface';


export type ContractScheduledChecksWidgetProps = Omit<CollectionWidgetProps, 'rowData' | 'widgetData'> & {
  rowData: IContractRecord,
  widgetData: IContractScheduleCheckRecord[],
}

// Used when pasting data from the clipboard into the paste data from clipboard modal
type ImportedCheck = {
  check: string,
}

/**
 * This widget displays the Contract Checks by wrapping a table widget in a custom widget
 * for displaying other components
 */
export const ContractScheduledChecksWidget:React.FC<ContractScheduledChecksWidgetProps> = (props) => {
  const {
    rowData: contract,
    className,
    disableHeader,
    widgetPermittedActions,
    isEditingCollectionItem,
    isCreatingCollectionItem,
    isReadOnly,
    showAddBtn,
    createCollectionItem,
    refreshWidgetData,
  } = props;


  const { showModal } = useContext(ModalContext);

  /**
   * Create a new Contract Check
   */
  const handleCreateCollectionItem = useCallback(() => {
    if (createCollectionItem) createCollectionItem();
  }, [createCollectionItem]);


  // Render
  return (
    <CollectionWidget
      {...props}
      className={classNames('contract-checks-widget', className)}
      disableHeader
    >
      {/* Pass in a custom header (if not disabled in the definition */}
      {(disableHeader !== true) && (
        <CollectionWidgetHeader
          description="Contract Checks"

          showAddBtn={
            // pass down setting from the collection widget definition
            showAddBtn &&

            // The CREATE action exists in the widgetItemPermittedActions
            !!hasPermittedAction(widgetPermittedActions, API_ACTION.CREATE)
          }

          // Prevent the user from adding another row if they are editing a row
          addBtnDisabled={isEditingCollectionItem || isCreatingCollectionItem || isReadOnly}

          // Pass the event handler back up the chain so that the widget wrapper can add a data row
          onClickAddBtn={handleCreateCollectionItem}

          actions={(
            <>
              <div className="action">
                {/* TODO: Ensure the "create" action is available on the contract-checks endpoint and that the user has permission */}
                <Button
                  color="primary"
                  // Display the confirmation modal
                  onClick={() => showModal<ReturnDataModalResult<ImportedCheck>>(MODAL_TYPE.UPLOAD_MAINTENANCE_CHECKS, {
                    title: 'Upload from Excel',
                    initialData: contract,
                    onModalComplete: () => {
                      if (refreshWidgetData) refreshWidgetData();
                    },
                  })}
                >
                  <Icon
                    i={ICON.UPLOAD}
                  />
                  <span>Upload from Excel</span>
                </Button>
              </div>
            </>
          )}
        />
      )}
    </CollectionWidget>
  );
};
