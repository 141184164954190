import React, { useCallback } from 'react';
import classNames from 'classnames';

import { ICompanyLocationTypeRecord } from '../../types/company/company-location-type.record.interface';

import { ConstSelect, ConstSelectProps } from './const-select';
import Icon from '../layout-helpers/icon';

import { mapSelectInputOptions } from '../../helpers/map-select.helper';

import { A_COMPANY_LOCATION_TYPE, CompanyLocationTypeIconMap, CompanyLocationTypeNameMap } from '../../constants/company-location-type.const';

export type CompanyLocationTypePickerProps = ConstSelectProps;

export const CompanyLocationTypePicker: React.FC<CompanyLocationTypePickerProps> = (props) => {
  const {
    className,
    renderOption,
    value,
  } = props;

  /**
   * Renders an option using the icon and the location type name
   */
  const handleRenderOption = useCallback((
    option: ICompanyLocationTypeRecord | null,
    labelMeta: Record<string, unknown>,
    inputValue: string | number | null,
  ): string | React.ReactNode => {
    if (renderOption) {
      return renderOption(option, labelMeta as unknown as Record<string, unknown>, inputValue ?? null);
    }

    if (!option) return null;

    return (
      <span>
        <Icon i={CompanyLocationTypeIconMap[option.id as A_COMPANY_LOCATION_TYPE]} />
        <span>{option.name}</span>
      </span>
    );
  }, [renderOption]);


  // Render the component
  return (
    <ConstSelect
      {...props}
      className={classNames('company-location-type-picker', className)}
      labelKey="name"
      valueKey="id"
      placeholder="Location Type"
      value={value}
      inputOptions={mapSelectInputOptions(CompanyLocationTypeNameMap)}
      renderOption={(option, labelMeta, inputValue) => handleRenderOption(option as ICompanyLocationTypeRecord, labelMeta, inputValue)}
    />
  );
};
