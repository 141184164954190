import React, { useCallback } from 'react';

import { NewRecordFormProps } from '../../types/poly-form/new-record-form.props';
import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { IUserRecord } from '../../types/user/user.record.interface';

import { APIPolyForm } from './api-poly-form';
import { FormFieldRenderer } from './form-field-renderer';

import { mapTableToForm } from '../../helpers/map-table-to-form.helper';

import userTableInitialSettings from '../../table-definitions/user-management-table';
import { COLUMN_FORMAT } from '../../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../../constants/form-field-type.const';
import { API_ACTION } from '../../constants/api-action.const';

const formFields = mapTableToForm(userTableInitialSettings);

export const NewUserForm: React.FC<NewRecordFormProps<IUserRecord>> = (props) => {
  const {
    onFormComplete, onFormChange, initialData,
  } = props;

  /**
   * Fired when the APIPolyForm successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, id: FormFieldComponentProps['value'], newData?: Partial<IUserRecord>) => {
    if (onFormComplete) onFormComplete(success, id as IUserRecord['id'], newData as IUserRecord);
  }, [onFormComplete]);

  // Render
  return (
    <APIPolyForm
      {...userTableInitialSettings}
      fields={formFields}
      formData={{
        active: 1,
        enable_family_friendly_leave: 1,
        family_friendly_leave_days: 10,
        enable_birthday_leave: 0,
        ...(initialData ?? {}),
      }}
      permittedActions={userTableInitialSettings.possibleActions.filter((action) => (action.name === API_ACTION.CREATE))}
      onFormComplete={handleFormComplete}
      onFormChange={onFormChange}
      isNewRecord
    >
      <p>Use the select box below to search for a user before creating a new one.</p>
      <FormFieldRenderer
        id="userSearch"
        field={{
          name: '_user_search',
          formFieldType: FORM_FIELD_TYPE.USER_PICKER,
          format: COLUMN_FORMAT.TEXT,
          visible: true,
          title: 'Search existing users',
          placeholder: 'Check to see if your user already exists',
        }}
      />
      <hr />
    </APIPolyForm>
  );
};
