
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateTableSettings } from '../../../actions/portal-data-table/update-settings';
import { TableDisplayControls } from './table-display-controls';

/**
* Table Display Controls component
*/
class ApiReduxTableDisplayControls extends React.Component {
  /**
  * Constructor
  */
  constructor(props) {
    super(props);
    this.state = {
      isDropDownOpen: false,
    };
  }


  /**
  * Toggle isTightModeEnabled table cel mode
  */
  handleToggleTightMode = () => {
    const { dispatchUpdateTableSettings, isTightModeEnabled } = this.props;
    dispatchUpdateTableSettings({ isTightModeEnabled: !isTightModeEnabled });
  };


  /**
  * Toggle text wrapping
  */
  handleToggleTextWrapping = () => {
    const { dispatchUpdateTableSettings, isTextWrappingEnabled } = this.props;
    dispatchUpdateTableSettings({ isTextWrappingEnabled: !isTextWrappingEnabled });
  };


  /**
  * Toggle whether the dropdown version of the button list is visible or not
  */
  handleToggleDropDownOpen = () => {
    const { isDropDownOpen } = this.state;
    this.setState({ isDropDownOpen: !isDropDownOpen });
  }


  /**
  * Render
  */
  render() {
    const {
      onToggleShowColumnManager,
      onDownloadViewAsExcel,
      onToggleFullScreen,
      onCloseTable,

      isTextWrappingEnabled,
      isFullScreenActive,
      isTightModeEnabled,
      isColumnManagerOpen,
    } = this.props;

    const {
      isDropDownOpen,
    } = this.state;

    // Return <TableControls />
    return (
      <TableDisplayControls
        isFullScreenActive={isFullScreenActive}
        isTightModeEnabled={isTightModeEnabled}
        isTextWrappingEnabled={isTextWrappingEnabled}
        isColumnManagerOpen={isColumnManagerOpen}
        isDropDownOpen={isDropDownOpen}
        onToggleTightMode={this.handleToggleTightMode}
        onToggleTextWrapping={this.handleToggleTextWrapping}
        onToggleDropDownOpen={this.handleToggleDropDownOpen}
        onDownloadViewAsExcel={onDownloadViewAsExcel}
        onCloseTable={onCloseTable}
        onToggleFullScreen={onToggleFullScreen}
        onToggleShowColumnManager={onToggleShowColumnManager}
      />
    );
  }
}

ApiReduxTableDisplayControls.defaultProps = {
  onCloseTable: null, // Null prevents the close button from rendering
  onDownloadViewAsExcel: undefined,
};

ApiReduxTableDisplayControls.propTypes = {
  onToggleFullScreen: PropTypes.func.isRequired,
  onToggleShowColumnManager: PropTypes.func.isRequired,
  onDownloadViewAsExcel: PropTypes.func,
  onCloseTable: PropTypes.func,

  isTextWrappingEnabled: PropTypes.bool.isRequired,
  isFullScreenActive: PropTypes.bool.isRequired,
  isTightModeEnabled: PropTypes.bool.isRequired,
  isColumnManagerOpen: PropTypes.bool.isRequired,

  // Dispatch
  dispatchUpdateTableSettings: PropTypes.func.isRequired,
};


const mapStateToProps = (
  state,
  ownProps,
) => {
  const tableSettings = state.tableSettings[ownProps.tableIdentifier];
  return {
    isTightModeEnabled: tableSettings.isTightModeEnabled,
    isTextWrappingEnabled: tableSettings.isTextWrappingEnabled,
  };
};

const mapDispatchToProps = (
  dispatch,
  ownProps,
) => ({
  dispatchUpdateTableSettings: (settings) => dispatch(updateTableSettings(ownProps.tableIdentifier, settings)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApiReduxTableDisplayControls);
