import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { formatValueString } from '../../render-functions';
import Icon from '../../layout-helpers/icon';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { APIContext } from '../../providers/api-provider';
import { DToolsLabourSummaryRecord } from './side-by-side-labour-analysis';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import { CheckBox } from '../../form-input/checkbox';

type DtoolsPhaseLabourTableProps = {
  rowData: {
    id: number,
    dtools_id: string,
  },
};

export const DtoolsPhaseLabourTable: React.FC<DtoolsPhaseLabourTableProps> = (props) => {
  const [loadingDtoolsSummary, setLoadingDtoolsSummary] = useState(false);
  const [dtoolsSummaryData, setDtoolsSummaryData] = useState<DToolsLabourSummaryRecord[] | null>(null);
  const [showPendingCoValues, setShowPendingCoValues] = useState(0);

  const { rowData } = props;

  const { apiFetch } = useContext(APIContext);
  const abortController = apiAborter();

  /**
   * @description
   * load the DTools Summary Data from the API
   */
  const getDtoolsSummary = useCallback(async (dtoolsId) => {
    setLoadingDtoolsSummary(true);
    const response = await apiFetch(
      `/dtools-connector/project/${dtoolsId}/summary`,
      {
        name: 'DtoolsPhaseLabourTable:getDtoolsSummary',
        signal: abortController.signal,
      },
    );
    if (response.success) {
      setLoadingDtoolsSummary(false);
      setDtoolsSummaryData(response.body.data);
    } else if (!response.aborted) {
      console.error('DtoolsPhaseLabourTable:getDtoolsSummary', response.error);
      setLoadingDtoolsSummary(false);
      setDtoolsSummaryData(null);
    }
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController, apiFetch]);


  useEffect(() => {
    if (rowData.dtools_id) {
      getDtoolsSummary(rowData.dtools_id);
    }
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData.dtools_id]);

  /**
   * @inheritdoc
   */
  return (
    <div className="row justify-content-lg-center">
      <Card className="col-md-12 col-lg-10 col-xl-8">
        <CardBody>
          <h3>D-Tools Phase Summary</h3>
          {rowData.dtools_id ? (
            <p>
              Master Dtools Project is Linked (
              {rowData.dtools_id}
              )
              {' '}
            </p>
          ) : (
            <div>No Master Dtools Project Found</div>
          )}
          {loadingDtoolsSummary && (
            <div>
              Loading... &nbsp;
              <Icon i="rolling" />
            </div>
          )}
          <CheckBox
            id="pending-co-toggle"
            name="pending-co-toggle"
            label="Show Pending Change Order Values"
            value={showPendingCoValues}
            onChange={() => {
              setShowPendingCoValues(showPendingCoValues ? 0 : 1);
            }}
            onKeyPress={() => {
              setShowPendingCoValues(showPendingCoValues ? 0 : 1);
            }}
          />
          {dtoolsSummaryData && (
            <table className="table table-striped color-table warning-table" style={{ border: '1px solid silver' }}>
              <thead>
                <tr>
                  {/* <th>Status</th> */}
                  <th>Phase</th>
                  <th className="text-right">Quoted Labour Hours</th>
                  <th className="text-right">Average CPH</th>
                  <th className="text-right">Labour Cost</th>
                  <th className="text-right">Product Cost</th>
                </tr>
              </thead>
              <tbody>
                {dtoolsSummaryData.filter((phaseRow) => showPendingCoValues || phaseRow.COStatus === 'Approved').map((phaseRow) => {
                  const averageHourly =
                    parseFloat(phaseRow.TotalLaborHours || '0') > 0 ? parseFloat(phaseRow.TotalLaborCost || '0') / parseFloat(phaseRow.TotalLaborHours || '0') : 0;
                  return (
                    <tr key={phaseRow.PhaseName} className={phaseRow.PhaseName === 'Total For Project' || phaseRow.PhaseName === 'Total Pending' ? 'font-weight-bold' : ''}>
                      {/* <td>{phaseRow.COStatus}</td> */}
                      <td>{phaseRow.PhaseName || ' - no phase - '}</td>
                      <td className="text-right">{formatValueString(phaseRow.TotalLaborHours, COLUMN_FORMAT.DECIMAL)}</td>
                      <td className="text-right">{formatValueString(averageHourly, COLUMN_FORMAT.CURRENCY)}</td>
                      <td className="text-right">
                        {formatValueString(phaseRow.TotalLaborCost, COLUMN_FORMAT.CURRENCY)}
                      </td>
                      <td className="text-right">
                        {formatValueString(phaseRow.TotalProductCost, COLUMN_FORMAT.CURRENCY)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {dtoolsSummaryData && dtoolsSummaryData.filter((phaseRow) => phaseRow.PhaseName === 'Total For Project').map((phaseRow) => {
            const projectTotalFloat = parseFloat(phaseRow.TotalLaborCost || '0') + parseFloat(phaseRow.TotalProductCost || '0');
            const labourPercentage = projectTotalFloat !== 0 ? (parseFloat(phaseRow.TotalLaborCost || '0') / projectTotalFloat) * 100 : 0;
            const productPercentage = projectTotalFloat !== 0 ? (parseFloat(phaseRow.TotalProductCost || '0') / projectTotalFloat) * 100 : 0;
            return (
              <h5 key={phaseRow.PhaseName} className="p-1 mt-2">
                {`Labour Percentage: ${labourPercentage.toFixed(2)}%, Product Percentage: ${productPercentage.toFixed(2)}%`}
              </h5>
            );
          })}
        </CardBody>
      </Card>
    </div>
  );
};
