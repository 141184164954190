/* eslint-disable camelcase, react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';
import shortId from 'shortid';
import axios from 'axios';
import {
  Container, Button, Card,
} from 'reactstrap';
import { Editor } from '@tinymce/tinymce-react';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import Icon from '../layout-helpers/icon';
import { debugLog, toParams } from '../../utils/helpers';
import {
  TINYMCE_KEY, PORTAL_UPLOAD_STATUS, isUploadInProgress, isUploadError, baseUploadPercentage, uploadPercentageAllocation,
} from '../../utils/constants';
import { ApiQueryDataLoader } from '../api-query-data-loader/api-query-data-loader';
import FullPageLoadingSpinner from '../layout-helpers/full-page-loading-spinner';
import { UserAvatar } from '../user-profiles/user-avatar';
import PageAlert from '../layout-helpers/page-alert';
import { useHistory } from '../router/history';
import PageHeader from '../app-layout/page-header';
import { ScrollToTopOnMount } from '../router/scroll-to-top-on-mount';
import { UserPicker } from '../form-input/user-picker';
import AutoSizingTextArea from '../data-format/auto-sizing-text-area';
import { InfoTooltip } from '../info-tooltip';
import ProgressBar from '../layout-helpers/progress-bar';
import { PERMISSION } from '../../constants/permissions.const';

import rollingSvg from '../../images/Rolling-1s-22px.svg';
import loadingImage from '../../images/loading-image.svg';
import {
  mceToolbarIconAddImage, mceToolbarIconOneColumn, mceToolbarIconTwoColumns, mceToolbarIconThreeColumns,
} from '../../utils/mce-custom-icons';
import { CurrentUserContext } from '../providers/current-user-provider';
import { CURRENT_USER_PROVIDER_PROP_TYPES } from '../../prop-types/current-user-provider-prop-types';
import { APIContext } from '../providers/api-provider';
import API_PROVIDER_PROP_TYPES from '../../prop-types/api-provider-prop-types';
import { apiAborter } from '../../helpers/api-aborter.helper';
import StatusBadge from '../data-format/status-badge';
import { NEWS_STATUS_MAP } from '../../constants/news-status.const';
import { NewsCategoryPicker } from '../form-input/news-category-picker';

// The types of images that can be uploaded for news
const NEWS_IMAGE_TYPE = {
  PRIMARY: 'primary',
  BODY: 'body',
};

const DEFAULT_BODY_VALUE = 'Body';
const DEFAULT_TEASER_VALUE = 'Teaser';
const DEFAULT_TITLE_VALUE = 'Title';

// How long to wait before removing the upload images from the upload handler
const NEWS_IMAGE_UPLOAD_REMOVE_TIMEOUT = 1000;


/**
 * @class EditNewsPage
 *
 * TODO: Extract the image upload code from this component into a higher order component that processes uploads
 * TODO: Form Validation
 * TODO: Change thumbnail_url to link to a news_image instead of hard code the url
 * TODO: Wash image placeholders and helper spans out of the HTML before posting
 */
class EditNewsPage extends React.Component {
  /**
   * @constructor
   *
   * @param {{}} props
   */
  constructor(props) {
    super(props);
    const { article } = props;
    this.state = {
      internalArticle: {
        ...article,
        // remove previously inserted, now illegal HTML characters from the teaser.
        teaser: (article.teaser || '').replace(/<[^>]*>/g, ''),
      },
      isSaving: false,
      saveError: null,
      changesMade: false,
      selectImagesMode: null,
      primaryImageUploadLocalId: null,
    };

    this.uploadingImages = [];

    this.fileInputRef = React.createRef();
    this.bodyEditorRef = React.createRef();
    this.mceLoadingRef = React.createRef();
    this.saveAbortController = null;
    this.tinyMceEditor = null;
  }


  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    // Kill the save operation
    if (this.saveAbortController) this.saveAbortController.abort();

    // Kill any uploading images
    this.uploadingImages.forEach((image) => image && image.abortController && image.abortController.abort());
  }


  /**
   * @description
   * Get an uploading image by its local id
   *
   * @returns {{
    *  id: string,
    *  file: File,
    *  status: [PORTAL_UPLOAD_STATUS],
    *  progress: number,
    *  fileRecord: object,
    *  transport: object,
    *  apiAborter: object,
    *  axiosCancelToken: axios.CancelToken,
    * }}
    */
  getUploadingImageByLocalId = (localId) => this.uploadingImages.find((image) => image.localId === localId);


  /**
   * @description
   * Show the user a file selection dialog for adding new media
   */
  browseForFiles = (mode) => {
    this.setState({
      selectImagesMode: mode,
    }, () => {
      this.fileInputRef.current.click();
    });
  }


  /**
   * @description
   * Updates one of the internal article fields
   * @param {string} fieldName
   * @param {string | number | null} value
   */
  updateInternalArticleField = (fieldName, value) => {
    const { internalArticle } = this.state;
    this.setState({
      changesMade: true,
      internalArticle: {
        ...internalArticle,
        [fieldName]: value,
      },
    });
  }


  /**
   * @description
   * Fired when the user clicks the save button
   */
  saveArticle = () => {
    const { isSaving, internalArticle } = this.state;
    const { history, location } = this.props;

    if (isSaving) throw new Error('Already saving article.');

    const locationParams = toParams(location.search);
    const articleReturnTo = ('art' in locationParams) ? locationParams.art : null;
    const urlParamString = `?edited=true${articleReturnTo ? `&art=${encodeURIComponent(articleReturnTo)}` : ''}`;

    this.setState({
      isSaving: true,
      saveError: null,
    }, async () => {
      // Take anything out of the internal article we don't want to send to the API
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { author, created_by, ...formData } = internalArticle;

      if (this.saveAbortController) this.abortController.abort();
      this.saveAbortController = apiAborter();

      const { apiProvider: { apiFetch } } = this.props;

      const response = await apiFetch(
        `/news/${internalArticle.id}`,
        {
          method: HTTP_METHOD.PUT,
          name: 'EditNewsPage::save',
          signal: this.saveAbortController.signal,
          body: formData,
        },
      );

      if (response.success) {
        this.saveAbortController = null;
        this.setState({
          isSaving: false,
          saveError: null,
          changesMade: false,
        }, () => {
          history.push(`/comms/news/${internalArticle.id}${urlParamString}`);
        });
      } else if (!response.aborted) {
        this.saveAbortController = null;
        this.setState({ isSaving: false, saveError: response.error });
      }
    });
  }


  /**
   * @description
   * Fired when an image needs to be uploaded to the server
   *
   * @param {NEWS_IMAGE_TYPE} type the type of image(s) being uploaded (i.e. primary or body)
   * @param {File[]} files the array of files returned from the select file dialog
   */
  uploadImages = (type, files) => {
    if (files.length) {
      let { primaryImageUploadLocalId } = this.state;

      // add the images to our uploadingImagesArray to keep track of their uploads
      this.uploadingImages = [
        ...this.uploadingImages,
        ...files.map((file) => {
          const newLocalId = shortId.generate();

          if (type === NEWS_IMAGE_TYPE.PRIMARY) {
            primaryImageUploadLocalId = newLocalId;
          }

          return {
            localId: newLocalId,
            file,
            status: PORTAL_UPLOAD_STATUS.INITIALISING,
            progress: null, // Keeps the overall progress of the upload
            fileRecord: null, // Houses the file record created by the API to represent the uploaded file
            fileActions: null, // Houses the actions that can be taken on the file resource after creation
            transport: null, // Houses the transport information returned from Portal describing how to upload the file
            abortController: null,
            axiosCancelToken: null, // Houses the cancel token that axios uses to abort a transfer
          };
        }),
      ];

      // Insert placeholders into the document for each file
      if (type === NEWS_IMAGE_TYPE.BODY) {
        this.uploadingImages.forEach((image) => {
          this.tinyMceEditor.insertContent(`<img class="loading" src="${loadingImage}" data-local-id="${image.localId}">`);
        });
      }

      this.setState({
        primaryImageUploadLocalId,
      }, this.processUploadingImages);
    }
  }


  /**
   * @description
   * Iterates over the list of uploading images and determines what needs to happen next
   */
  processUploadingImages = () => {
    this.uploadingImages.forEach((image) => {
      switch (image.status) {
        // Move from INITIALISING to REQUESTING
        case PORTAL_UPLOAD_STATUS.INITIALISING:
          this.requestImageUpload(image.localId);
          break;

        case PORTAL_UPLOAD_STATUS.AUTHORISED:
          this.beginImageUpload(image.localId);
          break;

        case PORTAL_UPLOAD_STATUS.UPLOADED:
          this.confirmImageUpload(image.localId);
          break;

        case PORTAL_UPLOAD_STATUS.COMPLETE:
          // Set a timeout to remove the image from the array
          setTimeout(() => {
            this.removeLocalImageUpload(image.localId);
          }, NEWS_IMAGE_UPLOAD_REMOVE_TIMEOUT);
          break;

        // For whatever reason the upload did not complete
        case PORTAL_UPLOAD_STATUS.FAILED:
        case PORTAL_UPLOAD_STATUS.DENIED:
        case PORTAL_UPLOAD_STATUS.ABORTED:
          // Tell the API to abandon the image record
          this.deleteImageUpload(image.localId);

          // Set a timeout to remove the image from the array
          setTimeout(() => {
            this.removeLocalImageUpload(image.localId);
          }, NEWS_IMAGE_UPLOAD_REMOVE_TIMEOUT);
          break;

        default:
          break;
      }
    });

    // Trigger a render
    this.setState({});
  }


  /**
   * @description
   * Hit the API for an endpoint to upload a news image to
   *
   * @param {string} localId the local id of the image we're about to upload
   *
   * @returns {{
   *  id: string,
   *  file: File,
   *  status: [PORTAL_UPLOAD_STATUS],
   *  progress: number,
   *  fileRecord: object,
   *  transport: object,
   *  apiAborter: object,
   *  axiosCancelToken: axios.CancelToken,
   * }}
   */
  requestImageUpload = async (localId) => {
    const { internalArticle } = this.state;
    const { apiProvider: { apiFetch } } = this.props;
    const image = this.getUploadingImageByLocalId(localId);

    image.status = PORTAL_UPLOAD_STATUS.REQUESTING;
    image.abortController = apiAborter();
    image.progress = null;

    debugLog(
      '[ImageUpload:requestImageUpload]',
      'info',
      { message: 'Requesting Image Upload...', image },
      '☝',
    );

    const response = await apiFetch(
      `/news/${internalArticle.id}/image`,
      {
        method: HTTP_METHOD.POST,
        name: 'ImageUpload::requestUpload',
        body: {
          filename: image.file.name,
        },
        signal: image.abortController.signal,
      },
    );

    if (response.success) {
      const { transport, data: fileRecord, actions } = response.body;

      // TODO: actually check the response to ensure we are authorised

      // Update the image with the new details about how to upload
      image.status = PORTAL_UPLOAD_STATUS.AUTHORISED;
      image.transport = transport;
      image.fileRecord = fileRecord;
      image.fileActions = actions;
      image.abortController = null;

      debugLog(
        '[ImageUpload:requestImageUpload]',
        'success',
        { message: 'Image Upload Request Approved.', localId, image },
        '☝',
      );

      this.processUploadingImages();
    } else if (!response.aborted) {
      image.status = PORTAL_UPLOAD_STATUS.DENIED;
      image.abortController = null;

      debugLog(
        '[ImageUpload:requestImageUpload]',
        'danger',
        { message: 'Request Image Upload Failed.', image, error: response.error },
        '☝',
      );

      this.processUploadingImages();
    }
  }


  /**
   * @description
   * After authorising an upload actually begin the process of uploading the file
   *
   * @param {string} localId the localId of the file we're about to upload
   */
  beginImageUpload = (localId) => {
    const image = this.getUploadingImageByLocalId(localId);

    image.status = PORTAL_UPLOAD_STATUS.UPLOADING;
    image.progress = 0;
    image.axiosCancelToken = axios.CancelToken.source();

    // Get the HTTP method from the transport instructions
    const method = image.transport.method ? image.transport.method.toLowerCase() : 'post';
    const endpoint = image.transport.url;

    // Begin the Upload
    // @NOTE: this won't work for POST - we'll have to implement FormData() instead
    axios[method](endpoint, image.file, {
      cancelToken: image.axiosCancelToken.token,
      headers: {
        accept: 'application/json',
        // ...image.transport.headers,
      },
      onUploadProgress: (uploadProgressEvent) => {
        this.handleAxiosUploadProgress(localId, uploadProgressEvent);
      },
    }).then((response) => {
      // success
      if (response.status >= 200 && response.status <= 300) {
        this.handleAxiosUploadSuccess(localId);
      }

      // fail
      else {
        this.handleAxiosUploadError(localId, response.status, response.data);
      }

      // complete
      this.handleAxiosUploadComplete(localId);

    // error
    }, (reason) => {
      // Was the Axios Upload Cancelled by the user?
      if (axios.isCancel(reason)) {
        this.handleAxiosUploadCancelled(localId);
      }
      // Some other form of error?
      else {
        console.error('EditNewsPage:UploadImage error: ', { reason });
        this.handleAxiosUploadError(localId, reason.response.status, reason.response.data);
      }
      this.handleAxiosUploadComplete(localId);
    }).catch((error) => {
      console.error('EditNewsPage:UploadImage error: ', { error });
      this.handleAxiosUploadError(localId, 400, 'An unexpected error occurred!');
      this.handleAxiosUploadComplete(localId);
    });
  }


  /**
   * @description
   * Abort / Cancel the upload of an image no matter what the status
   *
   * @param {string} localId the id of the locally
   */
  cancelImageUpload = (localId) => {
    const image = this.getUploadingImageByLocalId(localId);

    // Is there an API request going on?
    if (image.abortController) {
      image.abortController.abort();
    }

    // If there's an axios cancel token, use that instead of just setting the status to aborted
    if (image.axiosCancelToken) {
      image.axiosCancelToken.cancel('Upload cancelled by the user.');
    }
    else {
      image.status = PORTAL_UPLOAD_STATUS.ABORTED;

      debugLog(
        '[ImageUpload:cancelImageUpload]',
        'warn',
        { message: 'Image Upload Cancelled.', localId, image },
        '☝',
      );
      this.processUploadingImages();
    }
  }


  /**
   * @description
   * Hit the API to check the file uploaded successfully
   *
   * @param {string} localId the local id of the image we need to confirm
  */
  confirmImageUpload = async (localId) => {
    const image = this.getUploadingImageByLocalId(localId);

    image.status = PORTAL_UPLOAD_STATUS.CONFIRMING;
    image.abortController = apiAborter();
    image.progress = null;

    debugLog(
      '[ImageUpload:confirmImageUpload]',
      'danger',
      { message: 'Confirming Image Upload...', image },
      '☝',
    );

    const { apiProvider: { apiFetch } } = this.props;

    const response = await apiFetch(
      image.fileActions.confirm.link,
      {
        method: image.fileActions.confirm.method,
        name: 'ImageUpload::confirmImageUpload',
        signal: image.abortController.signal,
      },
    );

    if (response.success) {
      const { data, actions } = response.body;
      const { internalArticle, primaryImageUploadLocalId } = this.state;

      // Update the image with the new details about how to upload
      image.status = PORTAL_UPLOAD_STATUS.COMPLETE;
      image.abortController = null;
      image.fileActions = actions;

      const { id } = data;
      const imageType = (primaryImageUploadLocalId === localId) ? NEWS_IMAGE_TYPE.PRIMARY : NEWS_IMAGE_TYPE.BODY;

      // Primary Image finished uploading
      if (imageType === NEWS_IMAGE_TYPE.PRIMARY) {
        // TODO: JESUS - THE URLS ARE HARD CODED. This was copied from the old edit news page
        // We need to change this to simply have an article.primary_image_id field
        const imageUrl = `${process.env.REACT_APP_API}news/${internalArticle.id}/image/${id}`;
        this.setState({
          internalArticle: {
            ...internalArticle,
            thumbnail_url: imageUrl,
          },
        }, () => {
          debugLog(
            '[ImageUpload:confirmImageUpload]',
            'success',
            { message: 'Image Confirmed as Uploaded.', localId, image },
            '☝',
          );

          this.processUploadingImages();
        });
      }

      // Body Image finished uploading
      else {
        // Don't let tinyMceEditor care about this change
        this.tinyMceEditor.undoManager.ignore(() => {
          // TODO: JESUS - THE URLS ARE HARD CODED. This was copied from the old edit news page
          // Images are loaded without the API version in the path.
          const imageUrl = `${process.env.REACT_APP_API}news/${internalArticle.id}/image/${id}`;

          const placeholderImageElement = this.tinyMceEditor.getDoc().querySelector(`img[data-local-id='${localId}']`);
          if (placeholderImageElement) {
            placeholderImageElement.classList.remove('loading');
            placeholderImageElement.removeAttribute('data-local-id');
            placeholderImageElement.setAttribute('src', imageUrl);
            placeholderImageElement.setAttribute('data-mce-src', imageUrl);

            // Using TinyMCE to set the content cleans up any erroneous HTML
            this.tinyMceEditor.setContent(this.tinyMceEditor.getBody().innerHTML);
          }

          // It the placeholder element can't be found then it's likely the user has removed the image from the dom while it was uploading
          else {
            image.status = PORTAL_UPLOAD_STATUS.ABORTED;
          }
        });

        this.processUploadingImages();
      }
    } else if (!response.aborted) {
      image.status = PORTAL_UPLOAD_STATUS.FAILED;
      image.abortController = null;

      debugLog(
        '[ImageUpload:confirmImageUpload]',
        'danger',
        { message: 'Image Upload Confirmation Failed.', image, error: response.error },
        '☝',
      );

      this.processUploadingImages();
    }
  }


  /**
   * @description
   * Hit the API and tell it to remove / delete a partial or fully uploaded file
   *
   * @note this method doesn't abort when navigating away. We don't care about the response
   * and it's more important that it executes server side rather than being cancelled by the user.
   *
   * @param {string} localId the local id of the image we need to confirm
  */
  deleteImageUpload = async (localId) => {
    const image = this.getUploadingImageByLocalId(localId);

    debugLog(
      '[ImageUpload:deleteImageUpload]',
      'warn',
      { message: 'Deleting aborted image upload from the Server...', image },
      '☝',
    );

    // Make sure we have an action to fire (cancel preferably but delete will also do)
    if (!image || !image.fileActions || (!('cancel' in image.fileActions) && !('delete' in image.fileActions))) {
      debugLog(
        '[ImageUpload:deleteImageUpload]',
        'danger',
        { message: 'No `cancel` or `delete` action can be found to clean up the aborted image upload.', image },
        '☝',
      );
      return;
    }

    const { apiProvider: { apiFetch } } = this.props;

    const response = await apiFetch(
      (image.fileActions.cancel || image.fileActions.delete).link,
      {
        name: 'ImageUpload::deleteImageUpload',
        method: (image.fileActions.cancel || image.fileActions.delete).method,
      },
    );

    if (response.success) {
      debugLog(
        '[ImageUpload:deleteImageUpload]',
        'success',
        { message: 'Aborted image upload removed from the server.', localId, image },
        '☝',
      );
    } else if (!response.aborted) {
      debugLog(
        '[ImageUpload:deleteImageUpload]',
        'danger',
        { message: 'Aborted image upload could not be removed from the server.', image, error: response.error },
        '☝',
      );
    }
  }


  /**
   * @description set the number of columns in the MCE body
   *
   * @param {number} columnCount the number of columns to assign to the current body element
   */
  setBodyColumns = (columnCount) => {
    // Deselect any selected content
    this.tinyMceEditor.selection.collapse();

    // Find the dom node that contains the article body
    const tinyMceDOM = this.tinyMceEditor.getDoc();
    const bodyElement = this.tinyMceEditor.getBody();
    const selectedElement = this.tinyMceEditor.selection.getNode();

    // Don't do anything if there isn't a selected element
    if (!selectedElement) return;

    // Find the selected element's parent. This should be a <div class="news-article-column"> or a <p> tag
    let columnWrapperElement;
    let previousColumnCount = 1;
    let newContent = [];

    // Selected element is a root level <p> tag
    if (selectedElement.tagName.toLowerCase() === 'p' && selectedElement.parentElement === bodyElement) {
      // The existing column wrapper IS the selected element
      columnWrapperElement = selectedElement;

      // Make the new first column the existing selected element content
      newContent[0] = selectedElement.innerHTML;
    }

    // Existing content is the immediate child of a root level <p> paragraph tag
    else if (selectedElement.parentElement.tagName.toLowerCase() === 'p' && selectedElement.parentElement === bodyElement) {
      // The existing column wrapper is the parent of the selected element
      columnWrapperElement = selectedElement.parent;

      // Make the new first column the existing content
      newContent[0] = columnWrapperElement.innerHTML;
    }

    // Existing content is wrapped in a <div class="news-article-column-wrapper">
    else if (selectedElement.closest('div.news-article-column-wrapper')) {
      columnWrapperElement = selectedElement.closest('div.news-article-column-wrapper');

      // If we were able to find a previous column wrapper, figure out how many columns it had
      const existingColumnDivs = Array.from(columnWrapperElement.getElementsByClassName('news-article-column'));
      previousColumnCount = existingColumnDivs.length;

      // Map the innerHTML of those divs into the new column content array
      newContent = existingColumnDivs.map((columnDiv) => {
        // is the columnDiv first child a <p> tag? if so - let's strip it.
        if (columnDiv.firstChild && columnDiv.firstChild.tagName.toLowerCase() === 'p') {
          return columnDiv.firstChild.innerHTML;
        }

        // Otherwise just return the guts.
        return columnDiv.innerHTML;
      });
    }

    // No parent element found?
    if (!columnWrapperElement) {
      console.error('Cannot create columns without a pre-existing parent element!');
      return;
    }

    // Is there any change to the column count?
    if (columnCount !== previousColumnCount) {
      // Pad out any missing columns
      if (columnCount > previousColumnCount) {
        for (let i = previousColumnCount; i < columnCount; i += 1) {
          newContent[i] = '';
        }
      }

      // Combine any removed columns
      else {
        // Trim the existing excess columns off the new content
        const trimmedContent = newContent.splice(columnCount, newContent.length - columnCount);

        // Append the excess content to the new content
        newContent[columnCount - 1] += trimmedContent.join('');
      }

      // Create the new element
      let newElement;

      if (columnCount === 1) {
        newElement = tinyMceDOM.createElement('p');
        // eslint-disable-next-line prefer-destructuring
        newElement.innerHTML = newContent[0];
      }
      else {
        newElement = tinyMceDOM.createElement('div');
        newElement.setAttribute('class', 'news-article-column-wrapper');
        newElement.innerHTML = newContent.map((newContentColumn) => `<div class="news-article-column"><p>${newContentColumn || ''}</p></div>`).join('');
      }

      // Insert the content into the dom
      this.tinyMceEditor.undoManager.ignore(() => {
        columnWrapperElement.parentNode.replaceChild(newElement, columnWrapperElement);

        // When we swap out a <p> tag for a div we need the ability to insert content after the div.
        // Appending the div with a paragraph tag allows for this
        if (columnCount > 1) {
          newElement.insertAdjacentElement('afterend', tinyMceDOM.createElement('p'));
        }
      });

      // use the tinyMceEditor to setContent so that it's added to the undo manager
      this.tinyMceEditor.setContent(bodyElement.innerHTML);
    }
  }


  /**
   * @description
   * Remove one of the images which has been uploading from the uploadingImages array
   *
   * @param {string} localId the local ID of the image to remove
   */
  removeLocalImageUpload = (localId) => {
    const { primaryImageUploadLocalId } = this.state;

    // If this is the primary image then kill the link to the primary image upload local id
    let newPrimaryImageUploadLocalId = primaryImageUploadLocalId;
    if (newPrimaryImageUploadLocalId === localId) {
      newPrimaryImageUploadLocalId = null;
    }

    const imageIndex = this.uploadingImages.findIndex((uploadingImage) => uploadingImage.localId === localId);
    if (imageIndex > -1) {
      this.uploadingImages.splice(imageIndex);
    }

    this.setState({
      primaryImageUploadLocalId: newPrimaryImageUploadLocalId,
    }, () => {
      debugLog(
        '[ImageUpload:removeLocalImageUpload]',
        'info',
        { message: 'Image Upload Removed.', localId, uploadingImages: this.uploadingImages },
        '☝',
      );

      this.processUploadingImages();
    });
  }


  /**
   * @description
   * Fired when the user accepts the "browse for files" dialog
   */
  handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    const { selectImagesMode } = this.state;

    // Reset the file input for next time
    e.target.value = '';

    this.setState({
      changesMade: true,
      selectImagesMode: null,
    }, () => {
      if (files.length > 0) {
        this.uploadImages(selectImagesMode, files);
      }
    });
  }


  /**
   * @description
   * Fired when a the title is changed
   *
   * @param {React.SyntheticEvent<HTMLInputElement>} e
   */
  handleChangeTitleInput = (e) => this.updateInternalArticleField('title', (e.currentTarget.value || ''));


  /**
   * @description
   * Fired when the teaser is changed
   *
   * @param {React.SyntheticEvent<HTMLInputElement>} e
   */
  handleChangeTeaserInput = (e) => this.updateInternalArticleField('teaser', (e.currentTarget.value || ''));


  /**
   * @description
   * Fired when the body is changed
   *
   * @param {React.SyntheticEvent<HTMLInputElement>} e
   */
  handleChangeBodyInput = (e) => this.updateInternalArticleField('body', (e.currentTarget.value || ''));


  /**
   * @description
   * Fired when the Author is changed
   *
   * @param {FormFieldChangeProps} field
   */
  handleChangeAuthor = (field) => {
    const { internalArticle } = this.state;
    const { currentUserProvider: { userDetails } } = this.props;
    this.setState({
      changesMade: true,
      internalArticle: {
        ...internalArticle,
        author_id: field.objectFieldNewValue ? field.objectFieldNewValue.id : userDetails.id,
        author: field.objectFieldNewValue ?? userDetails,
      },
    });
  }


  handleCategoryChange = (field) => {
    const { internalArticle } = this.state;
    this.setState({
      changesMade: true,
      internalArticle: {
        ...internalArticle,
        category_id: field.objectFieldNewValue.id,
        category: field.objectFieldNewValue,
      },
    });
  }


  /**
   * @description
   * Fired when the body is changed
   *
   * @param {React.SyntheticEvent} e
   */
  handleChangeBody = (e) => this.updateInternalArticleField('body', (e.target.getContent() || ''));


  /**
   * @description
   * Fired when the user clicks on the edit button over the primary image
   */
  handleChangePrimaryImage = () => this.browseForFiles(NEWS_IMAGE_TYPE.PRIMARY);


  /**
   * @description
   * Fired by the user clicking a cancel upload button
   */
  handleCancelUpload = (mode) => {
    // Cancel the primary image upload
    if (mode === NEWS_IMAGE_TYPE.PRIMARY) {
      const { primaryImageUploadLocalId } = this.state;
      this.cancelImageUpload(primaryImageUploadLocalId);
    }
    // Cancel all outstanding uploads
    else {
      console.error('TODO: handle cancelling all outstanding uploads');
    }
  }


  /**
   * @description
   * Fired by an axios multipart form request on upload progress
   *
   * @param {string} localId
   * @param {progressEvent} uploadProgressEvent
   */
  handleAxiosUploadProgress = (localId, uploadProgressEvent) => {
    const image = this.getUploadingImageByLocalId(localId);
    if (image) {
      image.progress = Math.round((uploadProgressEvent.loaded / uploadProgressEvent.total) * 100);

      // Force a render
      this.setState({});
    }
  }


  /**
   * @description
   * Fired by an axios multipart form request on upload success
   *
   * @param {string} localId
   */
  handleAxiosUploadSuccess = (localId) => {
    const image = this.getUploadingImageByLocalId(localId);
    if (image) {
      image.status = PORTAL_UPLOAD_STATUS.UPLOADED;
      image.progress = null;
    }
    this.processUploadingImages();
  }


  /**
   * @description
   * Fired by an axios multipart form request when an upload error occurs
   *
   * @param {string} localId
   * @param {number} httpStatus
   * @param {object} errorObject
   */
  handleAxiosUploadError = (localId /* , httpStatus, errorObject */) => {
    const image = this.getUploadingImageByLocalId(localId);
    if (image) {
      image.status = PORTAL_UPLOAD_STATUS.FAILED;
      image.progress = null;
    }
    this.processUploadingImages();
  }


  /**
   * @description
   * Fired by an axios multipart form request upon cancellation of the upload
   *
   * @param {string} localId
   */
  handleAxiosUploadCancelled = (localId) => {
    const image = this.getUploadingImageByLocalId(localId);
    if (image) {
      image.status = PORTAL_UPLOAD_STATUS.ABORTED;

      debugLog(
        '[ImageUpload:handleAxiosUploadCancelled]',
        'warn',
        { message: 'Image Upload Cancelled.', localId, image },
        '☝',
      );

      this.processUploadingImages();
    }
  }


  /**
   * @description
   * Fired by an axios multipart form request on the completion of an upload (successfully or otherwise)
   *
   * @param {string} localId
   */
  handleAxiosUploadComplete = (localId) => {
    // Remove the axios token
    const image = this.getUploadingImageByLocalId(localId);
    if (image) {
      image.axiosCancelToken = null;
    }
  }


  /**
   * @inheritdoc
   */
  render() {
    const { history, currentUserProvider: { userHasPermissions } } = this.props;

    const {
      internalArticle, changesMade, isSaving, saveError, primaryImageUploadLocalId, selectImagesMode,
    } = this.state;
    const {
      title, teaser, body, author, category, thumbnail_url, status_id, created_by,
    } = internalArticle;

    const disableForm = isSaving || (this.uploadingImages.length > 0);
    const primaryImageUpload = primaryImageUploadLocalId ? this.uploadingImages.find((image) => image.localId === primaryImageUploadLocalId) : null;
    return (
      <Container fluid className="edit-news-page">
        <PageHeader {...this.props}>
          <h4 className="text-themecolor">{`Edit Article ${internalArticle.id}`}</h4>
          <StatusBadge status={NEWS_STATUS_MAP[status_id]} />
        </PageHeader>
        <ScrollToTopOnMount />
        <Prompt
          when={changesMade}
          message="You have unsaved changes in your article. Are you sure you want to leave?"
        />

        {/* This input is used to browse for the images */}
        <input
          type="file"
          className="file-upload"
          ref={this.fileInputRef}
          onChange={this.handleFileInputChange}
          multiple={selectImagesMode === NEWS_IMAGE_TYPE.BODY}
          accept="image/*"
          data-mode={selectImagesMode}
        />

        <Card>
          <div className="edit-news-article">

            <div className="article-metadata">
              {/* Article Title */}
              <div className="title-wrapper">
                <div className="field-title">
                  <span>Article Title</span>
                  <InfoTooltip title="Article Title" buttonClassName="p-0">
                    <p>This is the short summary of your news story displayed in bold at the top of the article.</p>
                    <p>Try to keep your article title snappy, people need to get the gist of your news article in as few words as possible</p>
                  </InfoTooltip>
                </div>
                <AutoSizingTextArea
                  className="article-title"
                  value={title}
                  onChange={this.handleChangeTitleInput}
                  rows="1"
                  disabled={disableForm}
                  placeholder="Title"
                />
              </div>

              {/* Author */}
              <div className="author-wrapper">
                <div className="author-details">
                  <div className="field-title">
                    <span>Author</span>
                    <InfoTooltip title="Author" buttonClassName="p-0">
                      <p>Typically, this is you. However, you can select another Portal user if you are dictating or publishing an article on behalf of someone else.</p>
                      <p>
                        Additionally, you can select
                        <strong> Ci Portal </strong>
                        as the user for general announcements.
                      </p>
                    </InfoTooltip>
                  </div>
                  {/* Display the initial creator if different from the author */}
                  {created_by.id !== author.id && (
                    <div className="field-value">
                      <div className="original-creator">
                        {`Initially created by ${created_by.name} on behalf of`}
                      </div>
                    </div>
                  )}
                  <div className="field-value">
                    <div className="avatar-wrapper">
                      <UserAvatar className="avatar" name={author.name} />
                    </div>
                    <div className="author">
                      <UserPicker
                        formData={internalArticle}
                        field={{
                          name: 'author',
                          formSaveField: 'author_id',
                        }}
                        name="author"
                        value={author.name}
                        onChange={this.handleChangeAuthor}
                        id={`author_${author.id}`}
                        loadAndKeepAll
                        disabled={disableForm}
                        isCreatable={false}
                        isClearable
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Category */}
              <div className="category-wrapper">
                <div className="article-category">
                  <div className="field-title">
                    <span>Category</span>
                  </div>
                  <div className="field-value">
                    <NewsCategoryPicker
                      formData={internalArticle}
                      field={{
                        name: 'category',
                        formSaveField: 'category_id',
                      }}
                      name="category"
                      value={category.name}
                      onChange={this.handleCategoryChange}
                      id="category"
                      loadAndKeepAll
                      disabled={disableForm}
                      isCreatable={false}
                      isClearable
                    />
                  </div>
                </div>
              </div>

              {/* Primary Image */}
              <div className="image-wrapper">
                <div className="field-title">
                  <span>Primary Image</span>
                  <InfoTooltip title="Primary Image">
                    <p>The primary image is displayed as a thumbnail in the news article lists and at the top of the article when a user opens the article to read it.</p>
                  </InfoTooltip>
                </div>
                <div className="image-container">
                  <div className="image" style={{ backgroundImage: `url('${thumbnail_url}')` }} />
                  <div className="image-overlay">
                    {/* Currently uploading a primary image */}
                    {primaryImageUpload && (
                      <div className="upload-progress">
                        <div className="progress-description">
                          <span>
                            {isUploadError(primaryImageUpload.status) ?
                              primaryImageUpload.status :
                              `${primaryImageUpload.status} (${
                                baseUploadPercentage(primaryImageUpload.status) +
                                uploadPercentageAllocation(primaryImageUpload.status, (primaryImageUpload.progress || 0))
                              }%)...`}
                          </span>
                        </div>
                        <ProgressBar
                          progress={baseUploadPercentage(primaryImageUpload.status) + uploadPercentageAllocation(primaryImageUpload.status, (primaryImageUpload.progress || 0))}
                          error={isUploadError(primaryImageUpload.status)}
                        />
                        {isUploadInProgress(primaryImageUpload.status) && (
                          <Button color="danger" onClick={() => this.handleCancelUpload(NEWS_IMAGE_TYPE.PRIMARY)}>Cancel</Button>
                        )}
                      </div>
                    )}
                    {!disableForm && !primaryImageUpload && (
                      <div
                        role="presentation"
                        className="select-image-button"
                        onClick={this.handleChangePrimaryImage}
                        onKeyPress={(e) => ([13, 32].includes(e.which || e.key) ? this.handleChangePrimaryImage : null)}
                        tab-index={0}
                      >
                        <Icon i="edit" />
                      </div>
                    )}
                  </div>
                </div>
              </div>


              {/* Teaser Text */}
              <div className="teaser-wrapper">
                <div className="field-title">
                  <span>Article Teaser / Preview Text</span>
                  <InfoTooltip title="Article Teaser / Preview Text">
                    <p>This is the short and sweet summary that you can display in all news previews to try and coax people into reading the article.</p>
                    <p>
                      <strong>Important! </strong>
                      This content is not visible when reading the article. So double up the information in the article body as well.
                    </p>
                  </InfoTooltip>
                </div>
                <AutoSizingTextArea
                  className="teaser"
                  value={teaser}
                  onChange={this.handleChangeTeaserInput}
                  disabled={disableForm}
                  placeholder="Teaser Text"
                />
                <span className="text-danger">
                  This content is not visible when reading the article, so include this content below if required.
                </span>
              </div>
            </div>

            {/* Article Body */}
            <div className="body-wrapper">
              <div className="article-body">
                <div className="field-title">
                  <span>Article Body</span>
                  <InfoTooltip title="Article Body">
                    <p>This is the main content of your article. You can format this text using columns, insert images and even link to external content.</p>
                  </InfoTooltip>
                </div>
                <Editor
                  apiKey={TINYMCE_KEY}
                  ref={this.bodyEditorRef}
                  init={{
                    body_class: 'edit-news-article',
                    branding: false,
                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify ' +
                      `| bullist numlist | insertImages | image link media | oneColumn twoColumns threeColumns${userHasPermissions([PERMISSION.NEWS_ADMIN]) ? '| code' : ''}`,
                    plugins: ['media', 'image', 'link', 'code', 'lists'],
                    menubar: false,
                    inline: true,
                    statusbar: false,
                    paste_as_text: true,
                    object_resizing: false,
                    valid_elements: (
                      'a[href|target=_blank|id|class],' +
                      'strong/b,' +
                      'em,' +
                      'div[align|class],' +
                      'ol,ul,li,' +
                      'br,p[class],img[class|src|alt|data-local-id],iframe[src|width|height|allowfullscreen],mark[id]'),
                    extended_valid_elements: 'img[class|src|alt|title]',
                    image_dimensions: false,
                    browser_spellcheck: true,
                    paste_data_images: false,
                    contextmenu: false,
                    placeholder: 'Body',
                    formats: {
                      alignleft: { selector: 'p', classes: '' },
                      alignright: { selector: 'p', classes: 'right' },
                      aligncenter: { selector: 'p', classes: 'center' },
                      alignjustify: { selector: 'p', classes: 'justify' },
                    },
                    setup: (editor) => {
                      // Keep a reference to the editor
                      this.tinyMceEditor = editor;

                      // Register a custom icon
                      // @see https://www.martyfriedel.com/blog/tinymce-5-creating-a-plugin-with-a-dialog-and-custom-icons
                      editor.ui.registry.addIcon('add-image', `${mceToolbarIconAddImage}`);
                      editor.ui.registry.addIcon('one-column', `${mceToolbarIconOneColumn}`);
                      editor.ui.registry.addIcon('two-columns', `${mceToolbarIconTwoColumns}`);
                      editor.ui.registry.addIcon('three-columns', `${mceToolbarIconThreeColumns}`);

                      // Add the upload image button
                      // @see https://www.tiny.cloud/docs/ui-components/toolbarbuttons/#howtocreatecustomtoolbarbuttons
                      editor.ui.registry.addButton('insertImages', {
                        icon: 'add-image',
                        tooltip: 'Add / Upload an image from your device',
                        onAction: () => this.browseForFiles(NEWS_IMAGE_TYPE.BODY),
                      });

                      // Add the single column button
                      editor.ui.registry.addButton('oneColumn', {
                        icon: 'one-column',
                        tooltip: 'Single Column',
                        onAction: () => this.setBodyColumns(1),
                      });

                      // Add the double columns button
                      editor.ui.registry.addButton('twoColumns', {
                        icon: 'two-columns',
                        tooltip: 'Two (2) side-by-side columns',
                        onAction: () => this.setBodyColumns(2),
                      });

                      // Add the triple columns button
                      editor.ui.registry.addButton('threeColumns', {
                        icon: 'three-columns',
                        tooltip: 'Three (3) side-by-side columns',
                        onAction: () => this.setBodyColumns(3),
                      });

                      // Add the callback for removing the loading placeholder
                      editor.on('init', () => {
                        if (this.mceLoadingRef && this.mceLoadingRef.current) this.mceLoadingRef.current.remove();
                      });
                    },
                  }}
                  initialValue={body}
                  onChange={this.handleChangeBody}
                  disabled={disableForm}
                />
                <div ref={this.mceLoadingRef} className="loading-editor-placeholder">
                  <img src={rollingSvg} alt="Loading editor..." />
                  <span> Please wait...</span>
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="action-button-wrapper">
              <Button
                color="secondary"
                onClick={() => useHistory.backOrPush(history, '/comms/news')}
                disabled={disableForm}
              >
                <Icon i="times" />
                <span>Cancel</span>
              </Button>
              <Button
                color="primary"
                disabled={!changesMade || disableForm}
                onClick={() => this.saveArticle()}
              >
                <Icon i="check" />
                <span>Save</span>
              </Button>
            </div>

            {/* Error Message Alert */}
            { saveError && (
              <div className="error-wrapper">
                <PageAlert color="danger">
                  <Icon i="exclamation-triangle" />
                  <span>{ saveError }</span>
                </PageAlert>
              </div>
            )}
          </div>
        </Card>
      </Container>
    );
  }
}

EditNewsPage.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status_id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    teaser: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    published_at: PropTypes.string,
    thumbnail_url: PropTypes.string.isRequired,
    author: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  currentUserProvider: PropTypes.shape(CURRENT_USER_PROVIDER_PROP_TYPES).isRequired,
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
};

EditNewsPage.defaultProps = {
  //
};


function EditNewsPageDataLoader(props) {
  const { match, history, create } = props;

  const currentUserProvider = useContext(CurrentUserContext);
  const apiProvider = useContext(APIContext);
  const { apiFetch } = apiProvider;

  const [failedToCreateArticle, setFailedToCreateArticle] = useState(false);

  // create and push new page to history and render loading spinner
  useEffect(() => {
    if (create) {
      const createArticle = async () => {
        const response = await apiFetch(
          '/news',
          {
            name: 'EditNewsPageDataLoader::create',
            method: HTTP_METHOD.POST,
            body: {
              title: DEFAULT_TITLE_VALUE,
              body: DEFAULT_BODY_VALUE,
              teaser: DEFAULT_TEASER_VALUE,
              thumbnail_url: '/placeholder.jpg',
            },
          },
        );

        if (response.success) {
          if (response.body.data.id) {
            history.replace(`/comms/news/${response.body.data.id}/edit`);
          }
          else {
            console.error('Error: no id in response, unable to edit new News Article...');
            setFailedToCreateArticle(true);
          }
        } else if (!response.aborted) {
          setFailedToCreateArticle(true);
        }
      };
      createArticle();
    }
  }, []);

  if (failedToCreateArticle) { return (
    <div className="news-creation-error">
      <Button color="danger" onClick={() => history.replace('/comms/news')}>
        Failed to Create News Article
      </Button>
    </div>
  ); }

  if (create) return <FullPageLoadingSpinner caption="Creating News Article..." />;

  const { articleId } = match.params;

  return (
    <ApiQueryDataLoader
      apiQueryUrl={`/news/${articleId}?with[]=author&with[]=category&with[]=createdBy`}
      render={({
        response, isLoading, hasError, forceRefreshData,
      }) => {
        if (isLoading) return <FullPageLoadingSpinner caption="Loading News Article..." />;

        return (
          <EditNewsPage
            {...props}
            apiActions={response && response.actions ? response.actions : null}
            article={response && response.data ? {
              ...response.data,
              title: (response.data.title === DEFAULT_TITLE_VALUE ? null : response.data.title),
              teaser: (response.data.teaser === DEFAULT_TEASER_VALUE ? null : response.data.teaser),
              body: (response.data.body === DEFAULT_BODY_VALUE ? null : response.data.body),
            } : null}
            isLoading={isLoading}
            hasError={hasError}
            forceRefreshData={forceRefreshData}
            currentUserProvider={currentUserProvider}
            apiProvider={apiProvider}
          />
        );
      }}
    />
  );
}

EditNewsPageDataLoader.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      articleId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  create: PropTypes.bool,
};

EditNewsPageDataLoader.defaultProps = {
  create: null,
};

export default EditNewsPageDataLoader;
