// @todo THESE MUST BE KEPT IN SYNC WITH THE API
export const PERMISSION = {
  API_ADMIN: 'api-admin',

  /**
   * Notification Permissions
   * IMPORTANT: Must match permissions in the database
   */
  NOTIFICATION_ADMIN: 'notification-admin',

  /**
   * Allowance Permissions
   * IMPORTANT: Must match permissions in the database
   */
  ALLOWANCE_ADMIN: 'allowance-admin',
  ALLOWANCE_USER: 'allowance-user',
  ALLOWANCE_APPROVER: 'allowance-approver',
  ALLOWANCE_PROCESSOR: 'allowance-processor',

  /**
   * News Permissions
   * IMPORTANT: Must match permissions in the database
   */
  NEWS_ADMIN: 'news-admin',
  NEWS_VIEWER: 'news-viewer',
  NEWS_WRITER: 'news-writer',
  NEWS_EDITOR: 'news-editor',

  /**
   * Leave Permissions
   * IMPORTANT: Must match permissions in the database
   */
  LEAVE_ADMIN: 'leave-admin',
  LEAVE_USER: 'leave-user',
  LEAVE_APPROVER: 'leave-approver',
  LEAVE_CONFIRMER: 'leave-confirmer',

  /**
   * Security Permissions (ability to manage roles / permissions)
   * IMPORTANT: Must match permissions in the database
   */
  SECURITY_ADMIN: 'security-admin',
  SECURITY_ROLE_MANAGER: 'security-role-manager',
  SECURITY_USER_MANAGER: 'security-user-manager',
  SECURITY_USER_GROUP_MANAGER: 'security-user-group-manager',

  /**
   * Company Management
   * IMPORTANT: Must match permissions in the database
   */
  COMPANY_CREATE: 'company-create',
  COMPANY_UPDATE: 'company-update',
  COMPANY_DELETE: 'company-delete',
  COMPANY_ASSIGN_ACCOUNT_MANAGER: 'company-assign-account-manager',
  COMPANY_SPACE_TYPE_MAINTAINER: 'company-space-type-maintainer',

  /**
   * Partner Program management
   * IMPORTANT: Must match permissions in the database
   */
  PARTNER_PROGRAM_CREATOR: 'partner-program-create',
  PARTNER_PROGRAM_UPDATER: 'partner-program-update',
  PARTNER_PROGRAM_DELETER: 'partner-program-delete',

  /**
   * Expense Claims
   * IMPORTANT: Must match permissions in the database
   */
  EXPENSE_ADMIN: 'expense-admin',
  EXPENSE_USER: 'expense-user',
  EXPENSE_APPROVER: 'expense-approver',
  EXPENSE_PROCESSOR: 'expense-processor',

  /**
   * Projects
   * IMPORTANT: Must match permissions in the database
   */
  PROJECT_ACTIVATE: 'activate-project',
  PROJECT_ROLLBACK: 'rollback-project',
  PROJECT_REGENERATE_NUMBER: 'regenerate-project-number',
  PROJECT_PURCHASE_ORDER_MAINTAINER: 'project-purchase-order-maintainer',
  PROJECT_MAINTENANCE_TYPE_MAINTAINER: 'project-maintenance-type-maintainer',
  PROJECT_PUSH_PURCHASE_ORDER_TO_QUICKBOOKS: 'push-purchase-order-to-quickbooks',
  PROJECT_ORDER_CONFIRMATION_VIEWER: 'project-order-confirmation-viewer',

  /**
   * Reporting
   * IMPORTANT: Must match permissions in the database
   */
  REPORT_EXEC_DASH: 'report-exec-dash',
  REPORT_SALES_PERFORMANCE: 'report-sales-performance',
  REPORT_SALES_DASH: 'report-sales-dash',
  REPORT_SALES_FORECAST: 'report-sales-forecast',
  REPORT_FORECAST_INVOICE_DASH: 'report-forecast-invoice-dash',
  REPORT_PROJECT_PROFITABILITY: 'report-project-profitability',
  REPORT_LEAVE_SCHEDULE: 'report-leave-schedule',


  /**
   * Internal Projects
   * IMPORTANT: Must match permissions in the database
   */
  INTERNAL_PROJECT_VIEWER: 'internal-project-viewer',
  INTERNAL_PROJECT_REQUESTER: 'internal-project-requester',
  INTERNAL_PROJECT_EDITOR: 'internal-project-editor',
  INTERNAL_PROJECT_ADMIN: 'internal-project-admin',


  /**
   * Service Contracts
   * IMPORTANT: Must match permissions in the database
   */
  SERVICE_CONTRACT_VIEWER: 'service-contract-viewer',
  SERVICE_CONTRACT_USER: 'service-contract-requester',
  SERVICE_CONTRACT_ADVANCED_USER: 'service-contract-advanced-user',
  SERVICE_CONTRACT_ADMIN: 'service-contract-admin',

  /**
   * Invoice Requests
   * IMPORTANT: Must match permissions in the database
   */
  INVOICE_REQUEST_VIEWER: 'invoice-request-viewer',
  INVOICE_REQUEST_USER: 'invoice-request-user',
  INVOICE_REQUEST_EDITOR: 'invoice-request-editor',
  INVOICE_REQUEST_PROCESSOR: 'invoice-request-processor',

  /**
   * Service Jobs
   * IMPORTANT: Must match permissions in the database
   */
  SERVICE_JOB_VIEWER: 'service-job-viewer',
  SERVICE_JOB_USER: 'service-job-user',
  SERVICE_JOB_EDITOR: 'service-job-editor',
  SERVICE_JOB_ADMIN: 'service-job-admin',

  /**
   * Commission Payments
   * IMPORTANT: Must match permissions in the database
   */
  COMMISSION_PAYMENT_MANAGER: 'commission-payment-manager',
  COMMISSION_PAYMENT_VIEWER: 'commission-payment-viewer',

} as const;

export type PERMISSION = typeof PERMISSION;
export type A_PERMISSION = PERMISSION[keyof PERMISSION];

