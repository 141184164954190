import React, { useState } from 'react';
import classNames from 'classnames';

import { ConfirmModalResult } from '../../types/modal/modal-result';
import { IAPIAction } from '../../types/api-action.interface';
import { IModalButton } from '../../types/modal/modal-button.interface';
import { ModalProps } from '../../types/modal/modal.props';

import { Modal } from './Modal';
import { TextInput } from '../form-input/text-input';

import { MODAL_BUTTON_SIDE_TYPE } from '../../constants/modal-button-side-type.const';
import { AN_ICON, ICON } from '../../constants/icon.const';
import { BUTTON_COLOR, A_BUTTON_COLOR } from '../../constants/button-color.const';

export type ConfirmModalProps = ModalProps<ConfirmModalResult> & {
  content?: string | JSX.Element,
  confirmButtonLabel?: string,
  confirmButtonColor?: A_BUTTON_COLOR,
  confirmButtonIcon?: AN_ICON,
  requireReason?: boolean,
  action?: IAPIAction,
  onCancel?: () => void,
}


/**
 * The confirm modal makes it easy to implement a simple confirmation dialog
 *
 * Usage:
 *
 * ```
 * showModal(MODAL_TYPE.CONFIRM, {
 *  title: 'Are you sure you want to do this?',
 *  confirmButtonTitle: 'Yep, Sure.',
 *  confirmButtonClass: 'danger',
 *  confirmButtonIcon: 'check',
 *  requireReason: true,
 *  onModalComplete: ({reason}) => { [do something with `reason` here] },
 * })
 * ```
 *
 * Providing the `buttons` property will override the default construction of the
 * cancel / confirm buttons and use the provided buttons instead.
 */
export const ConfirmModal:React.FC<ConfirmModalProps> = (props) => {
  const {
    id,
    className,
    closeModal,
    buttons,
    content,
    onModalComplete,
    onCancel,
    requireReason,
    confirmButtonLabel,
    confirmButtonColor,
    confirmButtonIcon,
    action,
  } = props;

  const [reason, setReason] = useState<null | string>(null);
  const [invalidReason, setInvalidReason] = useState<boolean>(false);

  // Use provided buttons or create default buttons
  const modalButtons: IModalButton[] = buttons ?? [
    {
      label: confirmButtonLabel ?? 'OK',
      color: confirmButtonColor ?? BUTTON_COLOR.PRIMARY,
      icon: confirmButtonIcon ?? ICON.CHECK,
      sideType: MODAL_BUTTON_SIDE_TYPE.RIGHT,
      onClick: () => {
        if (requireReason && ((reason ?? '').trim().length < 10)) {
          setInvalidReason(true);
          return;
        }

        closeModal(id);
        if (typeof onModalComplete === 'function') {
          onModalComplete({
            success: true,
            reason: reason ?? 'Unexpected Error',
            ...(action ? { processAction: true } : {}),
          });
        }
      },
      disabled: (typeof onModalComplete !== 'function'),
    },
    {
      label: 'Cancel',
      color: BUTTON_COLOR.SECONDARY,
      sideType: MODAL_BUTTON_SIDE_TYPE.LEFT,
      onClick: () => {
        closeModal(id, false, onCancel);
      },
    },
  ];

  return (
    <Modal
      title="Confirm"
      {...props}
      className={classNames('modal-confirm', className)}
      buttons={modalButtons}
    >
      {content ?? 'Are you sure?'}

      {requireReason && (
        <div className="reason-wrapper">
          <TextInput
            id="reason"
            name="reason"
            value={reason}
            placeholder="Please provide a reason"
            hasError={invalidReason}
            onChange={({ newValue }) => {
              setReason(newValue);
              setInvalidReason(false);
            }}
          />
          {invalidReason && (
            <div className="reason-error">
              <span>You must provide a reason of at least 10 characters.</span>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};
