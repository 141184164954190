import { APIRecord } from '../../types/api-record.interface';
import { APIPolyFormProps } from '../../types/poly-form/api-poly-form.props';
import { PolyFormProps } from '../../types/poly-form/poly-form.props';

/**
 * Evaluate the form data and set initial values for fields when
 * the form is a newRecord
 */
export const prepareNewRecordFormData = <T extends APIRecord = APIRecord>(
  formData?: Partial<T>,
  fields: PolyFormProps['fields'] = [],
  parentId?: PolyFormProps['parentId'],
  parentData?: PolyFormProps['parentData'],
  siblingData?: T[],
): Partial<T> => {
  // Clone the form data
  const preparedFormData: APIPolyFormProps['formData'] = { ...(formData ?? {}) };

  // Initialise fields that have defined initial values
  fields
    .filter((field) => typeof field.initialValue !== 'undefined')
    .forEach((field) => {
      preparedFormData[field.name] = (typeof field.initialValue === 'function') ? field.initialValue(formData, parentData, siblingData || []) : (field.initialValue ?? null);
    });

  // Initialise parentId fields with the parentId of the form
  fields
    .filter((field) => (field.isParentId || field.useParentDataFieldValue))
    .forEach((field) => {
      if (field.isParentId) {
        preparedFormData[field.name] = parentId ?? null;
      }
      if (field.useParentDataFieldValue) {
        preparedFormData[field.name] = parentData ? parentData[field.useParentDataFieldValue] : null;
      }
    });

  return preparedFormData as Partial<T>;
};
