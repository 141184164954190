import React, { useState } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import classnames from 'classnames';
import { ICardItem } from '../../types/cardview/card-item.interface';
import { APIPolyForm } from '../poly-forms/api-poly-form';
import { mapTableToForm } from '../../helpers/map-table-to-form.helper';
import { FORM_RENDERER_TYPE } from '../../constants/form-renderer-type.const';
import { ISwimLaneDefinition } from '../../types/internal-project/swimlane-definition.interface';
import { ISwimlaneKeyVal } from '../../types/internal-project/swimlane-key-val.interface';


export type CartSwimlanesProps = {
  cardItems: ICardItem[],
  swimlaneMap?: ISwimLaneDefinition[],
  hideEmptyColumns?: boolean,
  cardLinkPath?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableSettings?: any,
}


/**
 * @var defaultSwimlanes map of default swimlanes using priority for sorting
 *
 * @todo should this be in a swimlane definitions file, or helper?
 */
const defaultSwimlanes = [
  {
    name: 'todo',
    title: 'To Do',
    filterFunction: (item: ICardItem) => item.progress === 0,
    assimilateFunction: (item: ICardItem) => ({ ...item, progress: 0 }),
  },
  {
    name: 'in-progress',
    title: 'In Progress',
    filterFunction: (item: ICardItem) => item.progress > 0 && item.progress < 100,
    assimilateFunction: (item: ICardItem) => {
      let progress = 0;
      if (item.progress === 100) progress = 99;
      if (item.progress === 0) progress = 1;
      return { ...item, progress };
    },
  },
  {
    name: 'done',
    title: 'Done',
    filterFunction: (item: ICardItem) => item.progress === 100,
    assimilateFunction: (item: ICardItem) => ({ ...item, progress: 100 }),
  },
];

/**
 * Reorder Helper
 *
 * @todo this should probably be in a helper file
 *
 * @param list the list to re-order
 * @param startIndex index to re-index from
 * @param endIndex index to re-index to
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dragAndDropReorder = (list: any[], startIndex: number, endIndex: number): any => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1); // remove the moving item
  removed.hasMoved = true;
  result.splice(endIndex, 0, removed) // reinsert it at the new location
    .filter((item) => item); // remove the 'undefined' item from the array
  return result;
};

/**
 * Move an item from one list to another
 *
 * @todo this should probably be in a helper file
 *
 * @param source the source item
 * @param destination the destination item
 * @param droppableSource the source target
 * @param droppableDestination the destination target
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const moveDragAndDropItem = (source: any[], destination: any[], droppableSource: any, droppableDestination: any): any => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  removed.hasMoved = true;
  destClone.splice(droppableDestination.index, 0, removed);

  const result = {
    [droppableSource.droppableId]: sourceClone.filter((item) => item), // clone and remove undefined items
    [droppableDestination.droppableId]: destClone.filter((item) => item),
  };

  return result;
};

/**
 * Card Swimlanes
 *
 * Allows any array of items confirming to the ICardItem interface to be rendered in swimlanes
 * using a swimlane map to define column sorting and column migration.
 *
 * @param {CartSwimlanesProps}         props
 * @param {ICardItem[]}             props.cardItems         an array of card items to render
 * @param {ISwimLaneDefinition[]}   props.swimlaneMap       an array of swimlane definitions
 * @param {boolean}                 props.hideEmptyColumns  should empty columns be collapsed?
 * @param {cardLinkPath}            props.cardLinkPath      id link path for card detail view
 *
 * @returns {Element} <CardSwimlanes />
 */
export const CardSwimlanes: React.FC<CartSwimlanesProps> = ({ cardItems, swimlaneMap, hideEmptyColumns, cardLinkPath, tableSettings }) => {
  const useSwimlanes = swimlaneMap ? [...swimlaneMap] : [...defaultSwimlanes];
  const swimlanes: ISwimlaneKeyVal = {};
  const firstRecord: ICardItem|undefined = cardItems ? cardItems[0] : undefined;
  const lowestPriorityNo = firstRecord?.priority || 1;

  /** @function   sortSwimlanes   Use each swimlane's filter function to decide it's destination */
  const sortSwimlanes = () => {
    useSwimlanes.map((swimlane: ISwimLaneDefinition) => {
      const items = cardItems.filter(swimlane.filterFunction);
      swimlanes[swimlane.name] = {
        ...swimlane,
        items,
      };
      return swimlane;
    });
  };
  sortSwimlanes();

  /** @var swimlaneState  use state to track swimlanes to allow moves */
  const [swimlaneState, setSwimlaneState] = useState<ISwimlaneKeyVal>({ ...swimlanes });

  /** @var isEditingid the id of the card currently in edit mode */
  const [isEditingId, setIsEditingId] = useState<number | null>(null);

  /**
   * @function onDragEnd Handle Drag End for Swimlane Card within, or between columns.
   *
   * @param result
   */
  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // dropped outside the list - do nothing
    if (!destination) {
      return;
    }

    // move is within the same column - just adjust the order
    if (source.droppableId === destination.droppableId) {
      const items = dragAndDropReorder( // re-order
        swimlaneState[source.droppableId].items,
        source.index,
        destination.index,
      );

      setSwimlaneState({ // push the new order to state
        ...swimlaneState,
        [source.droppableId]: {
          ...swimlaneState[source.droppableId],
          items,
        },
      });

    // move is in a different column - delete from old and add to new column
    // at dropped insertion point, also sorting the new columns items accordingly
    } else {
      const selfReturn = (item: ICardItem) => item;
      const sourceItems = [...swimlaneState[source.droppableId].items];
      const moveFunction = swimlaneState[destination.droppableId].assimilateFunction || selfReturn;
      sourceItems[source.index] = moveFunction(sourceItems[source.index]);

      const moveResult = moveDragAndDropItem( // move the item
        sourceItems,
        swimlaneState[destination.droppableId].items,
        source,
        destination,
      );

      setSwimlaneState({ // push the new columns to state
        ...swimlaneState,
        [source.droppableId]: {
          ...swimlaneState[source.droppableId],
          items: moveResult[source.droppableId],
        },
        [destination.droppableId]: {
          ...swimlaneState[destination.droppableId],
          items: moveResult[destination.droppableId],
        },
      });
    }
  };

  const onMoveSaveComplete = (item: ICardItem, columnId: string, newItemState: ICardItem) => {
    delete (item.hasMoved);
    const existingItems = swimlaneState[columnId].items;
    const newItems = existingItems.map((mapItem) => {
      if (mapItem.id === newItemState.id) {
        return {
          ...mapItem,
          ...newItemState,
        };
      }
      return mapItem;
    });

    setSwimlaneState({ // push the new columns to state
      ...swimlaneState,
      [columnId]: {
        ...swimlaneState[columnId],
        items: newItems,
      },
    });
  };

  const getFormCompleteFunction = (item: ICardItem, columnId: string) => (success: boolean, itemId: ICardItem['id'], newItemState: ICardItem) => {
    onMoveSaveComplete(item, columnId, newItemState);
  };

  return (
    <div
      className="swimlane-scroll-container"
    >
      <DragDropContext
        onBeforeCapture={() => true}
        onBeforeDragStart={() => true}
        onDragStart={() => true}
        onDragUpdate={() => true}
        onDragEnd={onDragEnd}
      >
        {Object.keys(swimlaneState).map((key: string) => {
          const swimDef = swimlaneState[key];
          if (hideEmptyColumns && swimDef.items.length === 0) {
            return (<div className="hidden-column-placeholder" title={`Hidden column: ${swimDef.title}`} />);
          }
          return (
            <div
              className="swimlane-column-container"
              key={swimDef.name}
            >
              <Card className="swimlane-column">
                <CardBody>
                  <CardTitle>
                    {swimDef.title}
                  </CardTitle>
                  <Droppable droppableId={swimDef.name}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={classnames({ 'is-dropping-over': snapshot.isDraggingOver })}
                      >
                        {swimDef.items && swimDef.items.map((item: ICardItem, index: number) => (
                          <APIPolyForm
                            {...tableSettings}
                            fields={mapTableToForm(tableSettings)}
                            formRendererType={FORM_RENDERER_TYPE.SWIMLANE_CARD}
                            rendererOptions={{
                              idLinkPath: cardLinkPath,
                              countFrom: lowestPriorityNo,
                            }}
                            onFormComplete={getFormCompleteFunction(item, swimDef.name)}
                            onFormChange={() => true}
                            isEditing={item.id === isEditingId}
                            onStartEditRecord={() => setIsEditingId(item.id)}
                            onEndEditRecord={() => setIsEditingId(null)}
                            key={item.id}
                            formDataChecksum={index}
                            formData={{
                              ...item,
                              index,
                            }}
                            permittedActions={tableSettings.possibleActions}
                          />
                        ))}
                        <div className="spacer" style={{ height: '10rem' }}>&nbsp;</div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </CardBody>
              </Card>
            </div>
          );
        })}
      </DragDropContext>
    </div>
  );
};
