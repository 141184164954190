import { alphabet } from './constants';

/**
 * Return lightened or darkened shade of hex colour
 * @param {string} color hex colour eg. '#FF8050'
 * @param {number} amount number where 0 is black, 1 is initial and 2 is 200% bright
 * @return {string} Hex colour eg. '#FFEEDD'
 */
export const brightness = (color, amount) => {
  if (amount > 0.99 && amount < 1.01) return color;
  const c = [
    parseInt(color.substring(1, 3), 16),
    parseInt(color.substring(3, 5), 16),
    parseInt(color.substring(5, 7), 16),
  ];
  const cc = [0, 0, 0];
  for (let i = 0; i < 3; i += 1) {
    c[i] = c[i] < 1 ? 1 : c[i];
    c[i] = parseInt(c[i] * amount, 10);
    c[i] = c[i] < 255 ? c[i] : 255;
    const ci16 = c[i].toString(16);
    cc[i] = ci16.length === 1 ? `0${ci16}` : ci16;
  }
  return `#${cc[0]}${cc[1]}${cc[2]}`;
};

/**
 * Return lightened or darkened shade of rgba css complient colour
 * @param {string} color hex colour eg. '#FF8050'
 * @param {number} [amount = 1] number where 0 is black, 1 is initial and 2 is 200% bright
 * @param {number} [opacity = 1] float number from 0 - 1 representing opacity percent
 * @return {string} RGBA colour eg. 'rgba(255, 128, 64, 0.1)'
 */
export const brightnessRgba = (color, amount = 1, opacity = 1) => {
  // if not much difference, just return the colour to save on computation
  if (amount > 0.99 && amount < 1.01) {
    return color;
  }
  const c = [
    parseInt(color.substring(1, 3), 16),
    parseInt(color.substring(3, 5), 16),
    parseInt(color.substring(5, 7), 16),
  ];
  for (let i = 0; i < 3; i += 1) {
    // can't do maths on 0
    c[i] = c[i] < 1 ? 1 : c[i];
    // calculate brightness, keep whole numbers only
    c[i] = Math.floor(c[i] * amount);
    // cap out at 255
    c[i] = c[i] > 255 ? 255 : c[i];
  }
  return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${opacity})`;
};

/**
 * Return 8-character hex format with opacity set to 'amount'
 * @param {string} color hex colour eg. '#FF8050'
 * @param {number} amount float number from 0 - 1 representing opacity percent
 * @return {string} Hex colour RGBA eg. '#FF8050EE'
 */
export const opacityColor = (color, amount = 1) => `${color}${Math.floor(amount * 255).toString(16)}`;

/**
 * Return rgba format with opacity set to 'amount'
 * @param {string} color hex colour eg. '#FF8050'
 * @param {number} amount float number from 0 - 1 representing opacity percent
 * @return {string} RGBA colour eg. 'rgba(255, 128, 64, 0.1)'
 */
export const opacityRgba = (color, amount = 1) => {
  const c = [
    parseInt(color.substring(1, 3), 16),
    parseInt(color.substring(3, 5), 16),
    parseInt(color.substring(5, 7), 16),
  ];
  return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${amount})`;
};

/**
 * Colors used in the console for debuggers
 */
export const consoleColors = {
  normal: {
    default: '#1D1D1D',
    danger: '#f62d51',
    warn: '#ff8f28',
    success: '#12905c',
    info: '#0091e6',
    fetch: '#77295e',
    get: '#6b7729',
  },
  light: {
    default: '#ffffff',
    danger: '#FCC9D1',
    warn: '#FFE0C8',
    success: '#C4E0D4',
    info: '#BFE4FE',
    fetch: '#DAC8D4',
    get: '#D7DAC8',
  },
  dark: {
    default: '#000000',
    danger: '#3D0A12',
    warn: '#402109',
    success: '#052115',
    info: '#00253F',
    fetch: '#1B0915',
    get: '#181B09',
  },
};

/**
 * @param {*} name One of
 * - `'default'`, `'danger'`, `'warn'`, `'success'`, `'info'`, `'fetch'`, `'get'`
 * @param {*} [variation='normal'] One of
 * - `'normal'`, `'dark'`, `'light'`
 */
export const consoleColor = (name, variation = 'normal', cssAttr = 'color') => `${cssAttr}: ${consoleColors[variation][name]};`;

/**
 * @param {string} color One of
 * - `'default'`, `'danger'`, `'warn'`, `'success'`, `'info'`, `'fetch'`, `'get'`
 * @param {string} [type='normal'] One of
 * - `'normal'`, `'invert'`
 * @param {string} [fontWeight=null] String corresponding to a valid CSS font-weight value.
 * @example
 * console.log(`%cHello there`, consoleStyle('info', 'invert', 'bold'));
 * // > Hello there
 * // Style created is 'color: #BFE4FE; background: #00253F; font-weight: bold;'
 */
export const consoleStyle = (color, type = 'normal', fontWeight = null) => {
  let variationFirst = 'normal';
  let variationSecond = 'light';
  let bold = '';
  if (type === 'invert') {
    variationFirst = 'light';
    variationSecond = 'dark';
  }
  if (fontWeight) {
    bold = `font-weight: ${fontWeight};`;
  }
  const fore = consoleColor(color, variationFirst, 'color');
  const back = consoleColor(color, variationSecond, 'background');
  return `${fore} ${back} ${bold}`;
};

export const colorHue = (input) => {
  const rainbow = [
    // '#2c2c2e',
    // '#181829',
    // '#1B0915',
    // '#480060',
    '#7d0053',
    '#F62D58',
    '#F62D58',
    '#EC7C3C',
    '#CDA937',
    '#77CD37',
    '#4DA80F',
    '#12905c',
    '#56A4A8',
    '#0091e6',
    '#5B7DD8',
    '#804ed0',
    '#9D27B0',
    '#ff64b4',
  ];
  const colorCount = rainbow.length;
  const variation = alphabet().indexOf(input) % colorCount;
  return `background: black; color: ${rainbow[variation]};`;
};
