import React from 'react';
import { Card, CardBody, CardHeader, CardText, CardTitle, Container } from 'reactstrap';
import PageHeader from '../app-layout/page-header';
import monster from '../../images/monster-liten.png';

const FiveHundred = () => (
  <Container fluid>
    <PageHeader tag="h2" title="500 - Internal Server Error" />
    <center>
      <Card
        style={{
          maxWidth: '880px',
        }}
      >
        <CardHeader
          className="text-white"
          style={{
            backgroundColor: 'rgb(219, 121, 105)',
          }}
        >
          500 - Internal Server Error
        </CardHeader>
        <CardBody
          style={{
            backgroundImage: `url(${monster})`,
            backgroundSize: 'cover',
            minHeight: '560px',
            backgroundPosition: 'center top',
          }}
        />
        <CardBody style={{ background: 'rgb(46, 56, 85)' }} className="text-white">
          <CardTitle tag="h2"> Aaaaargh! </CardTitle>
          <CardText tag="div">
            {/* <img src={monster} alt="binocular search" /> */}
            <h4>Oh no! Something bad happened, and now we can&apos;t show you that page.</h4>
            <p>
              Please don&apos;t stay lost in the jungle:&nbsp;
              <a href="mailto:support@thecigroup.com.au">let someone know</a>
              {' '}
              what you were looking
              for.
            </p>
          </CardText>
        </CardBody>
      </Card>
    </center>
  </Container>
);

export default FiveHundred;
