/* eslint-disable import/prefer-default-export */
export const COLUMN_FILTER_TYPES = {
  ASYNC_OPTION_LIST: 'async-option-list',
  DATE: 'date',
  LIKELIHOOD: 'likelihood',
  NUMERIC: 'numeric',
  OBJECT: 'object',
  OPTION_LIST: 'option-list',
  TEXT: 'text',
  YES_NO: 'yes-no',
  ICON_ONLY: 'icon-only',
  // TODO: this isn't finished yet. Nick's problem. 😁
  SET_NOT_SET: 'set-not-set',
};


/**
 * @description
 * Returns true if the filter type should be treated as numeric
 *
 * @param {COLUMN_FILTER_TYPES[keyof COLUMN_FILTER_TYPES]} filterType
 */
export const isNumericFilterType = (filterType) => {
  switch (filterType) {
    case COLUMN_FILTER_TYPES.NUMERIC:
    case COLUMN_FILTER_TYPES.LIKELIHOOD:
      return true;
    default:
      return false;
  }
};
