import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Label,
  Input,
  FormGroup,
} from 'reactstrap';
import Icon from '../layout-helpers/icon';
import { noop, getItemByKey } from '../../utils/helpers';
import { getDataTableFlagLabel } from '../../helpers/get-data-table-flag-label.helper';
import { DATA_TABLE_FLAG } from '../../constants/data-table-flag.const';


/**
 * <ViewSelect />
 * @param {object} props
 */
class ViewSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
  }


  /**
   * Toggle dropdown on or off
   */
  handleToggleDropDown = () => {
    this.setState((oldState) => ({
      dropdownOpen: !oldState.dropdownOpen,
    }));
  };


  // Renders <ViewSelect />
  render() {
    const {
      viewKey,
      views,
      onToggleFlag,
      onSetView,
      availableFlags,
      flags,
    } = this.props;

    const {
      dropdownOpen,
    } = this.state;

    if (views.length === 0) return null;

    const selectedView = getItemByKey(views, viewKey);

    // When the user has a flag selected (i.e. "Only Mine") the title may need to be overridden
    const flagTitleOverride = (selectedView && selectedView.flagTitles) ? selectedView.flagTitles.find((flagTitle) => (
      flagTitle.flags.sort((a, b) => (a < b)).toString() === flags.sort((a, b) => (a < b)).toString()
    )) : null;
    // eslint-disable-next-line no-nested-ternary
    const title = selectedView ? (flagTitleOverride ? flagTitleOverride.title : selectedView.title) : 'Showing';

    // Return <ViewSelect />
    return (
      <Dropdown
        isOpen={dropdownOpen}
        toggle={this.handleToggleDropDown}
        title="Choose View"
        direction="down"
        className="view-select"
      >
        <DropdownToggle color="outline" className="view-select-dropdown-toggle">
          <h4 className="view-title">
            {title}
          </h4>
          <Icon i="caret-down" />
        </DropdownToggle>

        {/* TODO: Put this dropdown menu back inside a ReactPortal */}

        <DropdownMenu modifiers={{ preventOverflow: { boundariesElement: 'window' } }}>
          {views.map((view) => (
            <DropdownItem
              key={view.key}
              active={view.key === viewKey}
              onClick={() => onSetView(view.key)}
            >
              {view.title}
            </DropdownItem>
          ))}
          {availableFlags.map((flag) => (
            <Fragment key={flag}>
              <DropdownItem divider />
              <DropdownItem onClick={() => onToggleFlag(flag)}>
                <FormGroup check>
                  <Label check>
                    <Input
                      checked={flags.includes(flag)}
                      type="checkbox"
                      readOnly
                    />
                    {' '}
                    &nbsp;
                    {' '}
                    {getDataTableFlagLabel(flag)}
                  </Label>
                </FormGroup>
              </DropdownItem>
            </Fragment>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

ViewSelect.defaultProps = {
  onSetView: noop,
  onToggleFlag: noop,
};

ViewSelect.propTypes = {
  views: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    filters: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string.isRequired,
      operation: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
    })).isRequired,
    flags: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DATA_TABLE_FLAG))).isRequired,
  })).isRequired,
  onSetView: PropTypes.func,
  onToggleFlag: PropTypes.func,
  viewKey: PropTypes.string.isRequired,
  availableFlags: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DATA_TABLE_FLAG))).isRequired,
  flags: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DATA_TABLE_FLAG))).isRequired,
};

export default ViewSelect;
