import React from 'react';
import classNames from 'classnames';
import { Card } from 'reactstrap';
import NotificationBody from './notification-body';
import { INotificationRecord } from '../../types/notification/notification.record.interface';

type NotificationProps = {
  notificationRecord: INotificationRecord;
}

export const Notification: React.FC<NotificationProps> = ({ notificationRecord }) => (
  <Card className="portal-notifications">
    <div className={classNames('notification', `notification-${notificationRecord.data.style || 'info'}`)}>
      <h4 className="title">{notificationRecord.data.title}</h4>
      <NotificationBody data={notificationRecord.data} />
    </div>
  </Card>
);
