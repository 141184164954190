import { A_DATA_TABLE_FLAG, DATA_TABLE_FLAG } from '../constants/data-table-flag.const';

/**
 * Get the label corresponding to a data table flag
 */
export function getDataTableFlagLabel(flag: A_DATA_TABLE_FLAG): string {
  switch (flag) {
    case DATA_TABLE_FLAG.ONLY_MINE: return 'Show Only Mine';
    case DATA_TABLE_FLAG.MY_STATE: return 'Show My State';
    case DATA_TABLE_FLAG.NATIONAL: return 'Show National (No State)';
    case DATA_TABLE_FLAG.DELETED: return 'Include Deleted';
    case DATA_TABLE_FLAG.INCLUDE_ARCHIVED: return 'Include Archived';
    case DATA_TABLE_FLAG.ADMIN_SEE_ALL_NOTIFICATIONS: return 'See All Users\' Notifications';
    default: throw new Error(`Unhandled Query Flag: ${flag}`);
  }
}
