import React, { useCallback } from 'react';
import classNames from 'classnames';

import { CollectionWidgetProps } from '../../types/collection.widget.props';
import { IProjectRecord } from '../../types/project/project.record.interface';

import { CollectionWidget } from './collection.widget';

import { hasPermittedAction } from '../../helpers/has-permitted-action.helper';

import { API_ACTION } from '../../constants/api-action.const';
import { CollectionWidgetHeader } from './collection-widget-header';


export type ProjectInvoicesWidgetProps = Omit<CollectionWidgetProps, 'rowData'> & {
  rowData: IProjectRecord,
}


/**
 * This widget displays the Project Invoices by wrapping a table widget in a custom widget
 * for displaying other components
 */
export const ProjectInvoicesWidget:React.FC<ProjectInvoicesWidgetProps> = (props) => {
  const {
    className,
    disableHeader,
    rowData: project,
    widgetPermittedActions,
    isEditingCollectionItem,
    isCreatingCollectionItem,
    isReadOnly,
    showAddBtn,
    createCollectionItem,
  } = props;

  /**
   * Create a new Project invoice
   */
  const handleCreateCollectionItem = useCallback(() => {
    if (createCollectionItem) createCollectionItem();
  }, [createCollectionItem]);

  // Render
  return (
    <CollectionWidget
      {...props}
      className={classNames('project-invoices-widget', className)}
      disableHeader
    >
      {/* Pass in a custom header (if not disabled in the definition */}
      {(disableHeader !== true) && (
        <CollectionWidgetHeader
          description="Client Invoices"

          showAddBtn={
            // pass down setting from the collection widget definition
            showAddBtn &&

            // The CREATE action exists in the widgetItemPermittedActions
            !!hasPermittedAction(widgetPermittedActions, API_ACTION.CREATE)
          }

          // Prevent the user from adding another row if they are editing a row
          addBtnDisabled={isEditingCollectionItem || isCreatingCollectionItem || isReadOnly}

          // Pass the event handler back up the chain so that the widget wrapper can add a data row
          onClickAddBtn={handleCreateCollectionItem}

          subHeading={project.billing_address ? (
            <span>
              <strong>Billing Address:</strong>
              {project.billing_address}
            </span>
          ) : undefined}
        />
      )}
    </CollectionWidget>
  );
};
