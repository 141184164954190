/**
 * Ensure a string is prefixed but don't double up
 *
 * example:
 *
 *  const myParamString = 'someParam=value';
 *  const myURL = `some.base.url/search=bob${prefixString(myParamString, '&')}`;
 */
export const prefixString = (input: string, prefix: string): string => {
  if (input.startsWith(prefix)) {
    return input;
  }
  return `${prefix}${input}`;
};
