import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader } from 'reactstrap';
import { formatValueString } from '../render-functions';
import { COLUMN_FORMAT } from '../../constants/column-format.const';
import { LoadingSpinner } from '../layout-helpers/loading-spinner';
import { APIContext } from '../providers/api-provider';
import { apiAborter } from '../../helpers/api-aborter.helper';

/**
 * ProjectFinancialSummary component for Data Table
 */
export const DToolsProjectFinancialSummary = ({ dtoolsId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { apiFetch } = useContext(APIContext);

  useEffect(() => {
    let abortController = apiAborter();

    apiFetch(
      `/dtools-connector/project/${dtoolsId}/summary`,
      {
        name: 'DToolsProjectFinancialSummary:load',
        signal: abortController.signal,
      },
    ).then((response) => {
      if (response.success) {
        abortController = null;
        setData(response.body.data || []);
        setLoading(false);
      } else if (!response.aborted) {
        abortController = null;
        // TODO: error state?
        console.error('DTools Project Financial Summary Error', response.error);
        setData([]);
        setLoading(false);
      }
    });

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <LoadingSpinner />}

      {!loading && (
        <Card>
          <CardHeader className="bg-info text-white" tag="h4">
            Project FinancialSummary
          </CardHeader>
          <div className="table-container" style={{ height: '490px', overflowY: 'auto' }}>
            <table style={{ width: '100%' }}>
              <thead>
                {/*
                "PhaseName": null,
                "PhaseOrder": null,
                "TotalProductCost": 2487334.73,
                "TotalProductPrice": 2877720.28,
                "TotalLaborHours": 480,
                "TotalLaborCost": 33600,
                "TotalLaborPrice": 50400,
                "TotalInstallationPrice": 2928120.28
              */}
                <tr>
                  <th>CO Status</th>
                  <th>Phase</th>
                  {/* <th>PhaseOrder</th> */}
                  <th>TotalProductCost</th>
                  <th>TotalProductPrice</th>
                  <th>TotalLaborHours</th>
                  <th>TotalLaborCost</th>
                  <th>TotalLaborPrice</th>
                  <th>TotalInstallationPrice</th>
                </tr>
              </thead>
              <tbody>
                {data.length < 1 && (
                  <tr>
                    <td colSpan="8">No locations listed</td>
                  </tr>
                )}
                {data.map((item) => (
                  <tr key={`${item.PhaseOrder}-${item.PhaseName}`}>
                    <td>{item.COStatus}</td>
                    <td>
                      <strong>{item.PhaseName || 'Unallocated'}</strong>
                    </td>
                    {/* <td>{item.PhaseOrder}</td> */}
                    <td className="text-right">{formatValueString(item.TotalProductCost, COLUMN_FORMAT.CURRENCY)}</td>
                    <td className="text-right">{formatValueString(item.TotalProductPrice, COLUMN_FORMAT.CURRENCY)}</td>
                    <td className="text-right">{formatValueString(item.TotalLaborHours, COLUMN_FORMAT.NUMBER)}</td>
                    <td className="text-right">{formatValueString(item.TotalLaborCost, COLUMN_FORMAT.CURRENCY)}</td>
                    <td className="text-right">{formatValueString(item.TotalLaborPrice, COLUMN_FORMAT.CURRENCY)}</td>
                    <td className="text-right">{formatValueString(item.TotalInstallationPrice, COLUMN_FORMAT.CURRENCY)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
          </div>
        </Card>
      )}
    </>
  );
};

DToolsProjectFinancialSummary.propTypes = {
  dtoolsId: PropTypes.string.isRequired,
};
