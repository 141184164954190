import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Pagination from '../../pagination/pagination';
import { updateTableSettings } from '../../../actions/portal-data-table/update-settings';

/**
 * Pagination component for data table
 */
const PortalDataTablePagination = (props) => {
  const {
    className,
    totalRecords,
    activePage,
    pageSize,
    dispatchUpdateTableSettings,
  } = props;

  const handlePageChange = (pageNumber) => {
    if ((pageNumber !== activePage)) {
      dispatchUpdateTableSettings({ activePage: pageNumber, pushToHistory: true });
    }
  };

  return (
    <Pagination
      className={classNames('portal-data-table-pagination', className)}
      totalRecords={totalRecords}
      activePage={activePage}
      onPageChange={handlePageChange}
      pageSize={pageSize}
    />
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchUpdateTableSettings: (settings) => dispatch(updateTableSettings(ownProps.tableIdentifier, settings)),
});

const mapStateToProps = (state, ownProps) => {
  const tableSettings = state.tableSettings[ownProps.tableIdentifier];
  return {
    totalRecords: tableSettings.totalRecords,
    pageSize: tableSettings.pageSize,
    activePage: tableSettings.activePage,
  };
};

PortalDataTablePagination.defaultProps = {
  className: '',
};

PortalDataTablePagination.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  tableIdentifier: PropTypes.string.isRequired,
  className: PropTypes.string,

  // Redux state
  totalRecords: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  // Dispatch
  dispatchUpdateTableSettings: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PortalDataTablePagination);
