import React, { useCallback } from 'react';

import { ICompanyRecord } from '../../types/company/company.record.interface';
import { NewRecordModalProps } from '../../types/modal/new-record-modal.props';

import { useIsDirty } from '../../react-hooks/use-is-dirty.hook';

import { Modal } from './Modal';
import { NewCompanyForm } from '../poly-forms/new-company-form';

export const NewCompanyModal:React.FC<NewRecordModalProps<ICompanyRecord>> = (props) => {
  const {
    id, closeModal, onModalComplete, initialData,
  } = props;

  const [isDirty, setDirty] = useIsDirty();

  /**
   * Fired when the form successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, recordId: ICompanyRecord['id'], newRecord?: ICompanyRecord) => {
    closeModal(id);
    onModalComplete((success && newRecord) ? { success, newRecord } : { success: false, newRecord: null });
  }, [closeModal, id, onModalComplete]);

  // Render
  return (
    <Modal
      {...props}
      title="Create a new Company"
      allowDismiss
      isDirty={isDirty}
      size="lg"
    >
      <NewCompanyForm
        onFormComplete={handleFormComplete}
        onFormChange={setDirty}
        initialData={initialData}
      />
    </Modal>
  );
};
