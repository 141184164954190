import React, { useState, useCallback, useRef, useEffect } from 'react';
import shortId from 'shortid';
import classnames from 'classnames';
import { onlyNums } from '../../utils/helpers';
import { formatAlignClass } from '../render-functions';
import { COLUMN_FORMAT } from '../../constants/column-format.const';
import { commas } from '../../helpers/commas.helper';
import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';

export type NumericInputProps = Pick<FormFieldComponentProps,
  'id' |
  'name' |
  'placeholder' |
  'className' |
  'value' |
  'disabled' |
  'inline' |
  'autoComplete' |
  'autoCompleteIdentifier' |
  'allowNegative' |
  'isClearable' |
  'prefix' |
  'suffix' |
  'hasError' |
  'onChange' |
  'fractionDigits'
>

/**
 * NumericInput
 */
export const NumericInput: React.FC<NumericInputProps> = (props) => {
  const {
    id,
    name,
    placeholder,
    className,
    value,
    allowNegative = false,
    isClearable = false,
    disabled = false,
    inline,

    prefix,
    suffix,
    fractionDigits,

    autoComplete = false,
    autoCompleteIdentifier = 'on',

    hasError,
    onChange,
  } = props;

  const [editing, setEditing] = useState(false);
  const [internalValue, setInternalValue] = useState<string>(value?.toString());

  // Used to target the dom element directly to focus and select the text in the input
  const inputRef = useRef<HTMLInputElement>(null);

  /**
   * Format the value for display only
   */
  const getDisplayValue = useCallback((inputValue) => {
    if (inputValue === '' || inputValue === null || inputValue === undefined) {
      return '';
    }
    const displayNumber = commas(Number(inputValue).toFixed(fractionDigits ?? 2));
    return `${prefix ?? ''}${displayNumber}${suffix ?? ''}`;
  }, [fractionDigits, prefix, suffix]);


  /**
   * Fired when the input value is changed
   */
  const handleInputChanged = useCallback((newValue: string) => {
    if (editing) {
      setInternalValue(newValue);
    } else if (onChange) {
      // Format true value when field changes
      let checkedValue = newValue;
      const matchedNumbers = newValue.match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
      if (matchedNumbers) {
        const firstMatch = matchedNumbers[0];
        if (
          matchedNumbers &&
            matchedNumbers.length &&
            (matchedNumbers.length > 1 || firstMatch !== newValue)
        ) {
          checkedValue = firstMatch;
        }
      }

      const newCheckedValue: null | number = (checkedValue.trim() === '' && isClearable) ? null : Number((onlyNums(checkedValue, (allowNegative ?? false) ?? '0')));

      onChange({
        fieldName: name,
        newValue: newCheckedValue,
      });
    }
  }, [editing, setInternalValue, onChange, allowNegative, isClearable, name]);


  /**
   * Keep the internal value up to date with the external value (so long as we're not editing)
   */
  useEffect(() => {
    // If the user is not editing the input, set the internal value to match the incoming value
    setInternalValue(value?.toString());
  }, [value]);


  /**
   * Respond to the input switching from edit to non edit mode
   */
  useEffect(() => {
    // When editing commences, select the entire contents of the input
    const currentInputRef = inputRef.current;
    if (editing && currentInputRef) {
      // Focus the edit field
      currentInputRef.focus();
      // Select everything in the edit field
      currentInputRef.select();
    } else if (internalValue !== value?.toString()) {
      handleInputChanged(internalValue);
    }
  // es-lint will complain about some dependencies in this effect but we only want it to fire on editing changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing]);


  // Render
  return (
    <input
      type="text"
      placeholder={placeholder}
      ref={inputRef}
      id={id}
      className={classnames('form-control', className, formatAlignClass(COLUMN_FORMAT.CURRENCY, inline), 'inline', {
        'form-control-danger': hasError,
      })}
      disabled={disabled}
      name={autoComplete ? name : `${shortId.generate()}`}
      // Using 'autocomplete="off" is not enough for chrome. Have to give it a random string so that it tries to auto-complete for a random category instead
      autoComplete={autoComplete ? (autoCompleteIdentifier ?? 'on') : `${shortId.generate()}`}
      onChange={(e) => {
        const valFromChange = onlyNums(e.target.value, (allowNegative ?? false));
        handleInputChanged(valFromChange);
      }}
      value={editing ? internalValue : getDisplayValue(value)}

      onFocus={() => {
        setEditing(true);
      }}

      onBlur={() => {
        if (editing) {
          setEditing(false);
        }
      }}
    />
  );
};
