/**
 * Returns true if two File records are the same
 *
 * Only compares name, size, type and last modified date.
 */
export const fileCompare = (file1: null | File, file2: null | File): boolean => {
  // Either of the files are null but not both
  if (
    (file1 === null && file2 !== null) ||
    (file1 !== null && file2 === null)
  ) {
    return false;
  }

  // Both files are of type File but an attribute is different
  if (
    (file1 instanceof File && file2 instanceof File) &&
    (
      (file1.name !== file2.name) ||
      (file1.size !== file2.size) ||
      (file1.type !== file2.type) ||
      (file1.lastModified !== file2.lastModified)
    )
  ) {
    return false;
  }

  return true;
};
