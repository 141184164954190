import React, { useEffect } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

export type FullScreenWrapperProps = {
  isFullScreen: boolean,
  onChange?: ((state: boolean) => void) | undefined
}


/**
 * FullScreen v1.0.0 doesn't play well with Class components and needs a function component wrapper
 */
export const FullScreenWrapper:React.FC<FullScreenWrapperProps> = ({ isFullScreen, onChange, children }) => {
  const screen = useFullScreenHandle();

  /**
   * @description
   * When the isFullScreen variable changes, toggle the full screen mode
   */
  useEffect(() => {
    if (isFullScreen) {
      screen.enter();
    }
    else {
      screen.exit();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullScreen]);

  /**
   * Render
   */
  return (
    <FullScreen handle={screen} onChange={(state) => { if (onChange) onChange(state); }}>
      {children}
    </FullScreen>
  );
};
