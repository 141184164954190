import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader } from 'reactstrap';
import { formatValueString } from '../render-functions';
import { COLUMN_FORMAT } from '../../constants/column-format.const';
import { APIContext } from '../providers/api-provider';
import { LoadingSpinner } from '../layout-helpers/loading-spinner';
import { apiAborter } from '../../helpers/api-aborter.helper';


/**
 * DToolsProjectItems component for Data Table
 */
export const DToolsProjectItems = ({ dtoolsId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { apiFetch } = useContext(APIContext);

  useEffect(() => {
    let abortController = apiAborter();

    apiFetch(
      `/dtools-connector/project/${dtoolsId}/items`,
      {
        name: 'DToolsProjectItems:load',
        signal: abortController.signal,
      },
    ).then((response) => {
      if (response.success) {
        abortController = null;
        setData(response.body.data || []);
        setLoading(false);
      } else if (!response.aborted) {
        abortController = null;
        // TODO: error state?
        console.error('DTools Project Items Error', response.error);
        setData([]);
        setLoading(false);
      }
    });

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <LoadingSpinner />}

      {!loading && (
        <Card>
          <CardHeader className="bg-info text-white" tag="h4">
            Project Items
          </CardHeader>
          <div className="table-container" style={{ height: '490px', overflowY: 'auto' }}>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Manufacturer</th>
                  <th>Short Description</th>
                  <th>Order Number</th>
                  <th>Order Status</th>
                  <th>Order Date</th>
                  <th>Unit Cost</th>
                  <th>Unit Price</th>
                </tr>
              </thead>
              <tbody>
                {data.length < 1 && (
                  <tr>
                    <td colSpan="3">No sites listed</td>
                  </tr>
                )}
                {data.map((item) => {
                  if (item.TotalCost === 0 && !item.Vendor) return null;
                  return (
                    <tr key={`item-${item.Id}`}>
                      <td>{item.Manufacturer}</td>
                      <td>{item.ShortDescription}</td>
                      <td>{item.OrderNumber}</td>
                      <td>{item.OrderStatus}</td>
                      <td>{formatValueString(item.OrderedDate, COLUMN_FORMAT.DTOOLS_DATE)}</td>
                      <td className="text-right">{formatValueString(item.UnitCost, COLUMN_FORMAT.CURRENCY)}</td>
                      <td className="text-right">{formatValueString(item.UnitPrice, COLUMN_FORMAT.CURRENCY)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
          </div>
        </Card>
      )}
    </>
  );
};

DToolsProjectItems.propTypes = {
  dtoolsId: PropTypes.string.isRequired,
};
