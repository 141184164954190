import React from 'react';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';

import AsyncSelect from './async-select';

export type ContractStatusPickerProps = AsyncSelectComponentProps;

export const ContractStatusPicker: React.FC<ContractStatusPickerProps> = (props) => (
  <AsyncSelect
    {...props}
    searchRoute="/contract-status"
    loadAndKeepAll
  />
);
