import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProjectSummaryTableCellGroup from './project-summary-table-cell-group';
import ProjectSummaryTableRow from './project-summary-table-row';
import { DATA_TYPE, buildDomDataTagId } from './project-summary-helpers';
import Icon from '../../layout-helpers/icon';
import ProjectSummaryTableProjectSummaryDataRows from './project-summary-table-project-summary-data-rows';

const ProjectSummaryTableProjectManagerSummaryDataRows = (props) => {
  const {
    summaryFields,
    visibleSummaryFieldCount,
    pmAndStateIds,

    onClick,
    onBeginHover,
    onEndHover,

    projectManagerData,
    projectData,
  } = props;

  return (
    !!projectManagerData && (
      <>
        {/* Error loading Project Manager Summary Data */}
        {projectManagerData.error && !projectManagerData.isLoading && (
          <ProjectSummaryTableRow
            className="project-manager summary data no-data error"
          >
            <ProjectSummaryTableCellGroup span={visibleSummaryFieldCount}>
              <Icon i="exclamation" />
              <span>{`Error: ${projectManagerData.error}`}</span>
            </ProjectSummaryTableCellGroup>
          </ProjectSummaryTableRow>
        )}

        {/* No Project Manager Data, No Error, Not loading */}
        {!projectManagerData.summaryData.data.length && !projectManagerData.isLoading && !projectManagerData.error && (
          <ProjectSummaryTableRow
            className="project-manager summary data no-data"
          >
            <ProjectSummaryTableCellGroup span={visibleSummaryFieldCount}>
              <span>Nothing to display.</span>
            </ProjectSummaryTableCellGroup>
          </ProjectSummaryTableRow>
        )}

        {/* No Project Manager Summary Data and Summary Data is loading */}
        {!projectManagerData.summaryData.data.length && projectManagerData.isLoading && (
          <ProjectSummaryTableRow
            className="project-manager summary data no-data loading"
          >
            <ProjectSummaryTableCellGroup span={visibleSummaryFieldCount}>
              <Icon i="rolling" />
              <span>Loading...</span>
            </ProjectSummaryTableCellGroup>
          </ProjectSummaryTableRow>
        )}

        {/* Project Manager Summary Data Exists and no error */}
        {!!projectManagerData.summaryData.data.length && !projectManagerData.error && (
          <>
            {/* Project Manager Summary Data Rows */}
            {projectManagerData.summaryData.data.map((projectManager) => (
              <React.Fragment
                key={`state_${projectManager.state_id}_project_manager_${projectManager.project_manager_id}`}
              >
                <ProjectSummaryTableRow
                  className={classNames('project-manager', 'summary', 'data', {
                    loading: projectManagerData.isLoading,
                    expanded: !!pmAndStateIds.find((pmAndStateId) => pmAndStateId.stateId === projectManager.state_id && pmAndStateId.pmId === projectManager.project_manager_id),
                  })}
                  dataType={DATA_TYPE.PROJECT_MANAGER}
                  id={buildDomDataTagId(DATA_TYPE.PROJECT_MANAGER, projectManager.state_id, projectManager.project_manager_id)}
                  trackHover
                  onBeginHover={onBeginHover}
                  onEndHover={onEndHover}
                  onClick={onClick}
                >
                  <ProjectSummaryTableCellGroup
                    rowKey={`state_${projectManager.state_id}_project_manager_${projectManager.project_manager_id}`}
                    fields={summaryFields}
                    data={projectManager}
                    onRenderFieldData={(field, data, defaultFormattedValue) => (
                      field.key === 'name' ? data.project_manager_name : defaultFormattedValue
                    )}
                  />

                </ProjectSummaryTableRow>

                {/* Project Summary Data */}
                <ProjectSummaryTableProjectSummaryDataRows
                  summaryFields={summaryFields}
                  visibleSummaryFieldCount={visibleSummaryFieldCount}

                  onBeginHover={onBeginHover}
                  onEndHover={onEndHover}
                  onClick={onClick}
                  projectData={projectData
                    ? projectData.find((project) => project.stateId === projectManager.state_id && project.projectManagerId === projectManager.project_manager_id)
                    : null}
                />

              </React.Fragment>
            ))}
          </>
        )}
      </>
    )
  );
};

ProjectSummaryTableProjectManagerSummaryDataRows.propTypes = {
  summaryFields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  })).isRequired,
  projectManagerData: PropTypes.shape({
    stateId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    summaryData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
    monthlyData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
  }),
  projectData: PropTypes.arrayOf(
    PropTypes.shape({
      stateId: PropTypes.number.isRequired,
      projectManagerId: PropTypes.number.isRequired,
      isLoading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      summaryData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
      monthlyData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
    }),
  ).isRequired,
  visibleSummaryFieldCount: PropTypes.number.isRequired,
  pmAndStateIds: PropTypes.arrayOf(PropTypes.shape({
    pmId: PropTypes.number.isRequired,
    stateId: PropTypes.number.isRequired,
  })).isRequired,
  onClick: PropTypes.func.isRequired,
  onBeginHover: PropTypes.func.isRequired,
  onEndHover: PropTypes.func.isRequired,
};

ProjectSummaryTableProjectManagerSummaryDataRows.defaultProps = {
  projectManagerData: null,
};

export default ProjectSummaryTableProjectManagerSummaryDataRows;
