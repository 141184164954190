import React, { Component, ReactNode } from 'react';
import classNames from 'classnames';
import Icon from './icon';
import { ICON } from '../../constants/icon.const';
import { getDataTransferItemListFileItems } from '../../helpers/get-data-transfer-item-list-files.helper';

export type FileDropTargetProps = {
  onDropFiles: (files: File[]) => unknown,
  onClick?: (e: MouseEvent) => unknown,
  allowMultipleFiles?: boolean,
  showHint?: boolean,
  dropHintText?: string,
  enabled?: boolean,
};

export type FileDropTargetState = {
  draggingFileOver: boolean,
  invalidMultipleFileSelection: boolean,
  eventsBound: boolean,
};

export class FileDropTarget extends Component<FileDropTargetProps, FileDropTargetState> {
  dropTargetRef: React.RefObject<HTMLDivElement>;

  /**
   * @inheritdoc
   */
  constructor(props: FileDropTargetProps) {
    super(props);

    // Initialise the state
    this.state = {
      draggingFileOver: false,
      invalidMultipleFileSelection: false,
      eventsBound: false,
    };

    // Create a reference for this component when dealing with drag / drop events
    this.dropTargetRef = React.createRef<HTMLDivElement>();

    if (props.enabled) {
      this.bindEvents();
    }
  }


  /**
   * @inheritdoc
   */
  componentDidUpdate(): void {
    const { enabled: newEnabled = true } = this.props;
    const { eventsBound } = this.state;

    // Bind / Unbind the event listeners if the component is enabled / disabled
    if (!eventsBound && newEnabled) {
      this.bindEvents();
    } else if (eventsBound && !newEnabled) {
      this.unbindEvents();
    }
  }


  /**
   * @inheritdoc
   */
  componentWillUnmount(): void {
    this.unbindEvents();
  }


  /**
   * Setup the event listeners
   */
  bindEvents = (): void => {
    const dropTarget = this.dropTargetRef.current;
    if (dropTarget instanceof HTMLDivElement) {
      this.setState({
        eventsBound: true,
      }, () => {
        dropTarget.addEventListener('dragenter', this.handleDragEnter);
        dropTarget.addEventListener('dragleave', this.handleDragLeave);
        dropTarget.addEventListener('dragover', this.handleDragOver);
        dropTarget.addEventListener('dragend', this.handleDragEnd);
        dropTarget.addEventListener('drop', this.handleDrop);
        dropTarget.addEventListener('click', this.handleClick);
      });
    }
  }


  /**
   * Remove the event listeners
   */
  unbindEvents = (): void => {
    const dropTarget = this.dropTargetRef.current;

    if (dropTarget instanceof HTMLDivElement) {
      this.setState({
        eventsBound: false,
      }, () => {
        dropTarget.addEventListener('dragenter', this.handleDragEnter);
        dropTarget.addEventListener('dragleave', this.handleDragLeave);
        dropTarget.addEventListener('dragover', this.handleDragOver);
        dropTarget.addEventListener('dragend', this.handleDragEnd);
        dropTarget.addEventListener('drop', this.handleDrop);
        dropTarget.addEventListener('click', this.handleClick);
      });
    }
  }


  /**
   * Fired by most of the event handlers for drag events, this initialises the drag.
   */
  beginDragOver = (items: DataTransferItemList): void => {
    const {
      draggingFileOver,
    } = this.state;

    // Only begin the drag if it's not already in progress
    if (!draggingFileOver) {
      const {
        allowMultipleFiles,
      } = this.props;

      // Make sure that what is being dragged are actually files
      const draggedFileItems = getDataTransferItemListFileItems(items);
      if (draggedFileItems.length > 0) {
        this.setState({
          draggingFileOver: true,
          invalidMultipleFileSelection: !allowMultipleFiles && (items.length > 1),
        });
      }
    }
  }


  /**
   * Fired by most of the event handlers for drag events. This terminates the drag.
   */
  endDragOver = (): void => {
    this.setState({
      draggingFileOver: false,
      invalidMultipleFileSelection: false,
    });
  }


  /**
   * Fired when the user is dragging a file over the drop zone
   */
  handleDragOver = (e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer && (e.dataTransfer.items?.length ?? 0) > 0) {
      this.beginDragOver(e.dataTransfer.items);
    }
  }


  /**
   * Fired when the user drags a file into the hit box of the drop zone
   */
  handleDragEnter = (e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer && (e.dataTransfer.items?.length ?? 0) > 0) {
      this.beginDragOver(e.dataTransfer.items);
    }
  }


  /**
   * Fired when the user drags a file out of the drop zone hit box
   */
  handleDragLeave = (e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    this.endDragOver();
  }


  /**
   * Fired when the user finishes dragging a file (whether the drop was successful or not)
   */
  handleDragEnd = (e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    this.endDragOver();
  }


  /**
   * Fired when the user drops a file
   */
  handleDrop = (e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer && (e.dataTransfer.items?.length ?? 0) > 0) {
      const { onDropFiles } = this.props;

      const fileItems = getDataTransferItemListFileItems(e.dataTransfer.items);
      const files:File[] = (fileItems.map((fileItem) => fileItem.getAsFile())).filter((file) => file !== null) as File[];

      onDropFiles(files);
    }

    this.endDragOver();
  }


  /**
   * Fired when the user drops a file
   */
  handleClick = (e: MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    const { onClick } = this.props;
    if (onClick instanceof Function) {
      onClick(e);
    }
  }


  /**
   * @inheritdoc
   */
  render(): ReactNode {
    const {
      allowMultipleFiles,
      showHint,
    } = this.props;

    const {
      dropHintText = (allowMultipleFiles === true ? 'Drop File(s) Here' : 'Drop File Here'),
    } = this.props;

    const {
      draggingFileOver,
      invalidMultipleFileSelection: draggingInvalidMultipleFiles,
    } = this.state;

    return (
      <div
        className={classNames(
          'file-drop-target',
          {
            'dragging-file-over': draggingFileOver,
          },
        )}
        ref={this.dropTargetRef}
      >
        {/* Show a hint so that the user knows they can drop files on this component */}
        {!draggingFileOver && showHint && (
          <div
            className="drop-zone-indicator hint"
          >
            <Icon i={allowMultipleFiles ? ICON.FILES : ICON.FILE} />
            <span>{dropHintText}</span>
          </div>
        )}

        {/* Show the Drop target when the user is dragging files over the container */}
        {draggingFileOver && (
          <div
            className={classNames(
              'drop-zone-indicator dragging',
              {
                invalid: draggingInvalidMultipleFiles,
              },
            )}
          >
            {/* If the user is attempting to drag in multiple files when allowMultipleFiles is not true, show an error */}
            {draggingInvalidMultipleFiles && (
              <>
                <Icon i={ICON.FILE} />
                <span>Only one file permitted!</span>
              </>
            )}

            {/* If the user is simply dragging a single file or multiple files are allowed, just show the drop target */}
            {!draggingInvalidMultipleFiles && (
              <>
                <Icon i={allowMultipleFiles ? ICON.FILES : ICON.FILE} />
                <span>{allowMultipleFiles === true ? 'Drop File(s) Here' : 'Drop File Here'}</span>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}
