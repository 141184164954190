/**
 * @description
 * Essentially filters a DataTransferItemList for DataTransferItems of type file only
 */
export const getDataTransferItemListFileItems = (items: DataTransferItemList): DataTransferItem[] => {
  const fileItems: DataTransferItem[] = [];
  if (items) {
    // Use DataTransferItemList interface to access the file(s)
    for (let i = 0; i < items.length; i += 1) {
      // If dropped items aren't files, reject them
      if (items[i].kind === 'file') {
        fileItems.push(items[i]);
      }
    }
  }

  return fileItems;
};
