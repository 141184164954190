import React from 'react';
import { AN_HTML_INPUT_TYPE, HTML_INPUT_TYPE } from '../../constants/html-input-type.const';

type DebouncedInputProps<T extends (HTMLInputElement | HTMLTextAreaElement) = HTMLInputElement> = {
  debounceDuration: number,
  value: string,

  name?: string,
  innerRef?: React.Ref<T>,
  className?: string,
  elementType: T extends HTMLTextAreaElement ? 'textarea' : 'input',
  type?: AN_HTML_INPUT_TYPE,
  placeholder?: string,
  // eslint-disable-next-line react/no-unused-prop-types
  ariaControls?: string,

  rows?: number,

  onBlur?: (e: React.FocusEvent<T>) => void,
  onFocus?: (e: React.FocusEvent<T>) => void,
  onKeyDown?: (e: React.KeyboardEvent<T>) => void,
  onChange?: (newValue: string) => void,
}

export type DebouncedHTMLInputProps = Omit<DebouncedInputProps<HTMLInputElement>, 'elementType'>;
export type DebouncedHTMLTextAreaProps = Omit<DebouncedInputProps<HTMLTextAreaElement>, 'elementType'>;

type DebouncedInputState = {
  internalValue: string,
}

/**
 * @name DebouncedInput
 *
 * Provides a debounced input
 *
 * After changes to the input have settled after <debounceDuration> milliseconds,
 * fires onChange with the current internal value
 */
class DebouncedInputComponent<T extends (HTMLInputElement | HTMLTextAreaElement) = HTMLInputElement> extends React.Component<DebouncedInputProps<T>, DebouncedInputState> {
  private debounceTimeout: null | ReturnType<typeof setTimeout> = null;

  /**
   * @constructor
   */
  constructor(props: DebouncedInputProps<T>) {
    super(props);
    this.state = {
      internalValue: props.value,
    };

    this.debounceTimeout = null;
  }


  /**
   * @inheritdoc
   */
  shouldComponentUpdate(nextProps: DebouncedInputProps<T>): boolean {
    const { value } = this.props;

    // Has the external value changed?
    if (value !== nextProps.value) {
      // Clear the debounce timeout
      if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
      this.debounceTimeout = null;

      // Update the internal value to match the incoming value
      this.setState({ internalValue: nextProps.value });

      // Don't update.
      return false;
    }

    return true;
  }


  /**
   * @inheritdoc
   */
  componentWillUnmount(): void {
    // Clear the debounce timeout
    if (this.debounceTimeout) {
      if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
      this.debounceTimeout = null;
    }
  }


  /**
   * Fired when the input is changed
   */
  handleInputChange = (e: React.ChangeEvent<T>): void => {
    const { debounceDuration } = this.props;

    // Clear the existing debounce timeout
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    // Update the internal value
    this.setState({
      internalValue: e.currentTarget.value,
    }, () => {
      // set a new debounce timeout
      this.debounceTimeout = setTimeout(() => {
        const { onChange } = this.props;
        const { internalValue } = this.state;

        if (this.debounceTimeout) {
          clearTimeout(this.debounceTimeout);
          this.debounceTimeout = null;
        }

        if (onChange) {
          onChange(internalValue);
        }
      }, debounceDuration);
    });
  }


  /**
   * Fired when the input is blurred
   */
  handleInputBlur = (e: React.FocusEvent<T>): void => {
    // can we get away with firing both onChange and blur at the same time...?
    const { onBlur, onChange, value } = this.props;

    if (onBlur) {
      onBlur(e);
      if (e.defaultPrevented) return;
    }

    if (this.debounceTimeout) {
      // Clear any existing timeouts
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = null;

      // fire the onChange event immediately
      if (onChange) {
        onChange(e.currentTarget.value);
      }
    }

    // Is the internal value different from the external value
    else if ((value !== e.currentTarget.value) && onChange) {
      onChange(e.currentTarget.value);
    }
  }


  /**
   * Fired when a key is pressed down
   */
  handleInputKeyDown = (e: React.KeyboardEvent<T>): void => {
    // can we get away with firing both onChange and blur at the same time...?
    const { elementType, onKeyDown, onChange } = this.props;
    const { internalValue } = this.state;

    if (onKeyDown) {
      onKeyDown(e);
      if (e.defaultPrevented) return;
    }

    if ((elementType === 'input') && e.key.toLowerCase() === 'enter') {
      // Clear the timeout
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = null;
      }

      if (onChange) {
        onChange(internalValue);
      }
    }
  }


  /**
   * @inheritdoc
   */
  render(): React.ReactNode {
    const {
      name,
      className,
      type = HTML_INPUT_TYPE.TEXT,
      placeholder = '',
      innerRef,

      elementType,
      rows = 3,

      onFocus,
    } = this.props;

    const {
      internalValue,
    } = this.state;

    return (
      <>
        {(elementType === 'textarea') && (
          <textarea
            name={name}
            ref={innerRef as React.Ref<HTMLTextAreaElement>}
            className={className}
            placeholder={placeholder}
            value={internalValue}
            rows={rows}

            onChange={this.handleInputChange as React.ChangeEventHandler<HTMLTextAreaElement>}
            onBlur={this.handleInputBlur as React.FocusEventHandler<HTMLTextAreaElement>}
            onKeyDown={this.handleInputKeyDown as React.KeyboardEventHandler<HTMLTextAreaElement>}
            onFocus={onFocus as React.FocusEventHandler<HTMLTextAreaElement>}
          />
        )}

        {(elementType === 'input') && (
          <input
            name={name}
            ref={innerRef as React.Ref<HTMLInputElement>}
            className={className}
            type={type}
            placeholder={placeholder}
            value={internalValue}

            onChange={this.handleInputChange as React.ChangeEventHandler<HTMLInputElement>}
            onBlur={this.handleInputBlur as React.FocusEventHandler<HTMLInputElement>}
            onKeyDown={this.handleInputKeyDown as React.KeyboardEventHandler<HTMLInputElement>}
            onFocus={onFocus as React.FocusEventHandler<HTMLInputElement>}
          />
        )}
      </>
    );
  }
}

export const DebouncedInput: React.FC<DebouncedHTMLInputProps> = (props: DebouncedHTMLInputProps) => (
  <DebouncedInputComponent<HTMLInputElement> {...props} elementType="input" />
);

export const DebouncedTextArea: React.FC<DebouncedHTMLTextAreaProps> = (props: DebouncedHTMLTextAreaProps) => (
  <DebouncedInputComponent<HTMLTextAreaElement> {...props} elementType="textarea" />
);
