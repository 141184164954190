import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../app-layout/page-header';
import Icon from '../layout-helpers/icon';
import RecordDetailPage from './record-detail-page';
import { ProjectNumber } from '../data-format/project-number';
import StatusBadge from '../data-format/status-badge';

/**
 * @class ProjectDetailPage
 *
 * Custom render for the record detail view for Project Semantics
 */
const ProjectDetailPage = (props) => {
  const { match } = props;
  /**
   * @description
   * Callback for the record detail page header render function
   *
   * @param {object} recordData the resource data loaded by the record detail page
   */
  const renderPageHeader = (recordData) => (
    <PageHeader
      className="project"
      title={
        recordData ? (
          <>
            <ProjectNumber pData={recordData} />
            {` - ${recordData.name}`}
            <StatusBadge status={recordData.status.name} />
          </>
        ) : (
          <>
            <Icon i="rolling" />
            <span className="loading-caption">{(`Loading... ${match ? match.params.id : ''}`).trim()}</span>
          </>
        )
      }
    />
  );

  return (
    <RecordDetailPage {...props} renderPageHeader={renderPageHeader} />
  );
};

ProjectDetailPage.defaultProps = {
};

ProjectDetailPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default ProjectDetailPage;
