import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { mapSelectInputOptions } from '../helpers/map-select.helper';
import { SERVICE_JOB_TYPE_NAMES } from '../constants/service-job-type.const';
import { SERVICE_JOB_STATUS_NAME_MAP } from '../constants/service-job-status.const';
import { SERVICE_JOB_BILLING_TYPE_NAMES } from '../constants/service-job-billing-type-name-map.const';
import { THEME_COLOR } from '../constants/theme-color.const';
import { buildCompanyLocationURL, buildCompanySpaceURL } from '../components/portal-tree-view/company-locations/company-locations-tree-view-url.helper';
import { PERMISSION } from '../constants/permissions.const';
// import { MODAL_TYPE } from '../constants/modal-type.const';

/**
 * Table Definition for People (ServiceJobs) Table
 */
const serviceJobsTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Service Job',
  initialFormFocusFieldName: 'first',

  baseRoute: '/service-job',
  baseQueryString: [
    'with[]=type:id,name',
    'with[]=client:id,name',
    'with[]=serviceState:id,acronym',
    'with[]=clientLocation:id,name,path',
    'with[]=clientSpace:id,name,path,type_id',
    'with[]=clientSpace.type:id,icon',
    'with[]=status:id,name',
    'with[]=billingType:id,name',
    'with[]=serviceCall:id,type_id,status_id,number,name',
    'with[]=contract:id,status_id,number',
    'with[]=project:id,project_number,name',
    'with[]=assignedTo:id,name',
    'with[]=createdBy:id,name',
    'with_count[]=invoiceRequests',
  ].join('&'),


  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'number',
        'type',
        'status',
        'service_state',
        'client',
        'client_location',
        'client_space',
        'service_call',
        'contract',
        'project',
        'zendesk_id',
        'name',
        'description',
        'cost',
        'price',
        'billing_type',
        'assigned_to',
        'created_by',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'number',
        'type',
        'project',
        'contract',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'number',
        'type',
        'status',
        'service_state',
        'client',
        'client_location',
        'client_space',
        'service_call',
        'contract',
        'project',
        'zendesk_id',
        'name',
        'description',
        'cost',
        'price',
        'billing_type',
        'contract',
        'assigned_to',
        'created_by',
      ],
    },
  ],

  /**
   * Definition of columns and APIPolyForm fields for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Job ID',
      description: 'Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      linkRoute: '/service-and-support/service-jobs',
      visible: false,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'number',
      title: 'Job Number',
      description: 'Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      linkRoute: '/service-and-support/service-jobs',
      visible: true,
      showInForm: true,
      order: 20,
      formOrder: 20,
    },
    {
      name: 'type',
      title: 'Type',
      description: 'Service Job Type',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'type',
        key: 'name',
      },
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: mapSelectInputOptions(SERVICE_JOB_TYPE_NAMES),
      formSaveField: 'type_id',
      visible: true,
      required: true,
      showInForm: true,
      order: 40,
      formOrder: 40,
    },
    {
      name: 'status',
      title: 'Status',
      description: 'Current Status of Job',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'status',
        key: 'name',
        format: COLUMN_FORMAT.STATUS_BADGE,
      },
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: mapSelectInputOptions(SERVICE_JOB_STATUS_NAME_MAP),
      formSaveField: 'status_id',
      visible: true,
      showInForm: true,
      required: true,
      isClearable: false,
      order: 60,
      formOrder: 60,
    },
    {
      name: 'service_state',
      title: 'Service State',
      format: COLUMN_FORMAT.OBJECT,
      description: 'Where is the job being managed?',
      object: {
        sourceField: 'service_state',
        key: 'acronym',
        format: COLUMN_FORMAT.SHORT_TEXT,
        listSource: 'state',
      },
      formFieldType: FORM_FIELD_TYPE.STATE_PICKER,
      formSaveField: 'service_state_id',
      visible: true,
      showInForm: true,
      required: true,
      order: 70,
      formOrder: 70,
    },
    {
      name: 'client',
      title: 'Client',
      description: 'The Job\'s client',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'client',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/crm/companies',
        listSource: 'company',
      },
      formFieldType: FORM_FIELD_TYPE.COMPANY_PICKER,
      formSaveField: 'client_id',
      sortColumn: 'client.name',
      visible: true,
      showInForm: true,
      isCreatable: true,
      order: 80,
      formOrder: 80,
    },
    {
      name: 'client_location',
      title: 'Location',
      formSaveField: 'client_location_id',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        key: 'path',
        sourceField: 'client_location',
        format: COLUMN_FORMAT.COMPANY_LOCATION,
        linkRoute: (formData) => buildCompanyLocationURL(formData.company_id, formData.id),
      },
      formFieldType: FORM_FIELD_TYPE.COMPANY_LOCATION_PICKER,
      searchRoute: (formData) => `/company/${formData.client_id}/location`,
      isCreatable: true,
      getNewItemData: (inputValue, formData) => ({
        company_id: formData?.client_id ?? null,
        company: formData?.client ?? null,
      }),
      order: 100,
      formOrder: 100,
    },
    {
      name: 'client_space',
      title: 'Space',
      formSaveField: 'client_space_id',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        key: 'path',
        sourceField: 'client_space',
        format: COLUMN_FORMAT.COMPANY_SPACE,
        linkRoute: (formData) => buildCompanySpaceURL(formData.company_id, formData.id),
      },
      formFieldType: FORM_FIELD_TYPE.COMPANY_SPACE_PICKER,
      searchRoute: (formData) => `/company/${formData.client_id}/space`,
      isCreatable: true,
      getNewItemData: (inputValue, formData) => ({
        name: inputValue,
        company_id: formData?.client_id,
      }),
      order: 120,
      formOrder: 120,
    },
    {
      name: 'service_call',
      title: 'Service Call',
      description: 'If the job is a Follow Up, the Service Call is required',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'service_call',
        key: 'number',
        format: COLUMN_FORMAT.NAME_LINK_SHORT,
        linkRoute: '/service-and-support/service-jobs',
      },
      formFieldType: FORM_FIELD_TYPE.SERVICE_JOB_PICKER,
      formSaveField: 'service_call_id',
      visible: true,
      showInForm: true,
      order: 140,
      formOrder: 140,
    },
    {
      name: 'contract',
      title: 'Contract',
      description: 'Contract',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'contract',
        key: 'number',
        format: COLUMN_FORMAT.NAME_LINK_SHORT,
        linkRoute: '/service-and-support/contracts',
      },
      formFieldType: FORM_FIELD_TYPE.CONTRACT_PICKER,
      formSaveField: 'contract_id',
      visible: true,
      showInForm: true,
      order: 160,
      formOrder: 160,
    },
    {
      name: 'project',
      title: 'Project',
      format: COLUMN_FORMAT.OBJECT,
      description: 'Portal Project Number',
      placeholder: 'P12345ABC',
      object: {
        sourceField: 'project',
        key: 'project_number',
        format: COLUMN_FORMAT.P_NUMBER,
        listSource: 'project',
      },
      formSaveField: 'project_id',
      formFieldType: FORM_FIELD_TYPE.PROJECT_PICKER,
      visible: true,
      showInForm: true,
      order: 180,
      formOrder: 180,
    },
    {
      name: 'zendesk_id',
      title: 'Zendesk #',
      visible: true,
      format: COLUMN_FORMAT.SHORT_TEXT,
      order: 200,
      formOrder: 200,
    },
    {
      name: 'name',
      title: 'Name',
      visible: true,
      format: COLUMN_FORMAT.SHORT_TEXT,
      order: 220,
      formOrder: 220,
    },
    {
      name: 'description',
      title: 'Description',
      visible: true,
      format: COLUMN_FORMAT.HTML_NOTIN_TABLE,
      formFieldType: FORM_FIELD_TYPE.HTML_INPUT,
      order: 240,
      formOrder: 240,
    },
    {
      name: 'cost',
      title: 'Cost',
      visible: true,
      format: COLUMN_FORMAT.CURRENCY,
      order: 260,
      formOrder: 260,
    },
    {
      name: 'price',
      title: 'Price',
      visible: true,
      format: COLUMN_FORMAT.CURRENCY,
      order: 280,
      formOrder: 280,
    },
    {
      name: 'billing_type',
      title: 'Billing Type',
      description: 'How to bill the job',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'billing_type',
        key: 'name',
        // format: COLUMN_FORMAT.STATUS_BADGE,
      },
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: mapSelectInputOptions(SERVICE_JOB_BILLING_TYPE_NAMES),
      formSaveField: 'billing_type_id',
      visible: true,
      showInForm: true,
      required: true,
      isClearable: false,
      order: 300,
      formOrder: 300,
    },
    {
      name: 'assigned_to',
      title: 'Assigned To',
      description: 'The Ci team member responsible for this Job',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'assigned_to',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
      formSaveField: 'assigned_to_id',
      sortColumn: 'assigned_to.name',
      visible: true,
      showInForm: true,
      required: true,
      order: 320,
      formOrder: 320,
    },
    {
      name: 'created_by',
      title: 'Created By',
      description: 'The Ci team member who created the project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'created_by',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      sortColumn: 'createdBy.name',
      visible: true,
      showInForm: true,
      required: false,
      isReadOnly: true,
      order: 340,
      formOrder: 340,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add a Service Job',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new Service Job',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the service job',
      successMessage: 'Updated service Job',
      failMessage: 'Failed to update ServiceJob',
      hideRecordActionButton: true,
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the Service Job',
      successMessage: 'Service Job deleted',
      failMessage: 'Failed to delete Service Job',
      hideRecordActionButton: true,
    },
    // {
    //   name: 'push-to-quickbooks',
    //   title: 'Push to Quickbooks',
    //   color: BUTTON_COLOR.SUCCESS,
    //   modalType: MODAL_TYPE.PUSH_TO_QUICKBOOKS,
    //   icon: ICON.CLOCK,
    //   description: 'This will push the service job to QuickBooks and Quickbooks for time tracking etc...',
    //   successMessage: 'QuickBooks Job Created',
    //   failMessage: 'Failed to create QuickBooks Job',
    // },
    // // this is now on the files tab
    // {
    //   name: 'create-service-job-folder',
    //   title: 'Create the Job Folder',
    //   color: BUTTON_COLOR.SUCCESS,
    //   icon: ICON.SERVICE_JOBS_FOLDER,
    //   description: 'You are about to create a job folder on SharePoint...',
    //   successMessage: 'Job Folder Created',
    //   failMessage: 'Failed to create the job folder',
    // },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Service Job Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'zendesk-ticket',
      title: 'Zendesk Ticket',
      description: 'Zendesk Ticket Information',
      widgetDefinition: WIDGET_DEFINITION.ZENDESK_TICKET_DETAILS,
      isVisible: (rowData) => !!rowData.zendesk_id,
    },
    {
      name: 'files',
      title: 'Files',
      apiRoute: '/drive-items',
      apiQuery: '',
      description: 'Job\'s Files',
      widgetDefinition: WIDGET_DEFINITION.DRIVE_FILES,
    },
    {
      name: 'quickbooks',
      title: 'QuickBooks',
      description: 'QuickBooks & QuickBooks Time Details',
      widgetDefinition: WIDGET_DEFINITION.SERVICE_JOB_QUICKBOOKS,
    },
    {
      name: 'invoice-requests',
      title: 'Invoice Requests',
      description: 'Client Invoice Requests',
      widgetDefinition: WIDGET_DEFINITION.SERVICE_JOB_INVOICE_REQUESTS,
      apiRoute: '/invoice-request',
      apiQuery: [
        'with[]=type:id,name',
        'with[]=status:id,name',
        'with[]=billToContact:id,first,last',
        'with[]=billToCustomer:id,name',
        'pagelength=0',
        'with_count[]=details',
        'with_sum[]=details.total_price',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'count_of_invoiceRequests',
          badgeColor: THEME_COLOR.DARK,
        },
      ],
      permissions: [
        PERMISSION.INVOICE_REQUEST_VIEWER,
      ],
    },
    {
      ...eventsTabDefault,
    },
  ],
};

export default serviceJobsTableInitialSettings;
