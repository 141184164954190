import { APIRecord } from '../types/api-record.interface';

/**
 * Parse some CSV text into an array of records.
 * The `columnNames` property should match the properties
 * on the supplied generic record type, but be in the order
 * they appear in the incoming CSV data.
 *
 * type MyRecord = {
 *   id: number,
 *   name: string
 * }
 *
 * const parsedRecord = parseCSVToRecords<MyRecord>(
 *   someCSVTextData,
 *   ['name', 'id']
 * );
 *
 * @param csv
 * @param columnNames
 * @param rowSeparator
 * @param columnSeparator
 * @returns
 */
export const parseCSVToRecords = <T extends APIRecord>(
  csv: string,
  columnNames: string[] = [],
  rowSeparator = '\n',
  columnSeparator = ',',
): T[] => {
  const result: T[] = [];

  // Split the incoming text on the row separator
  const rows = csv.split(rowSeparator);
  rows
    // Filter out blank rows
    .filter((row) => row.trim() !== '')

    // Iterate over the remaining rows
    .forEach((row) => {
      const columns = row.trim().split(columnSeparator);
      const newRecord: APIRecord = {};

      // Iterate over the keys in the record object and assign them to the object in index order
      let columnIndex = 0;
      columnNames.forEach((columnName) => {
        newRecord[columnName] = columns[columnIndex];
        columnIndex += 1;
      });

      result.push(newRecord as T);
    });

  return result;
};
