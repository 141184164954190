import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Input, InputGroup, InputGroupAddon,
} from 'reactstrap';
import Icon from '../../layout-helpers/icon';

/**
 * Basic Vendor Picker
 *
 * Created because react-select is being swapped out currently.
 */
const BasicVendorPicker = ({
  chosenVendor,
  setChosenVendor,
  quickbooksResponse,
  vendorSearchTerm,
  changeVendorSearchTerm,
  searchingVendor,
  searchForVendors,
}) => (
  <>
    <select
      className="form-control ml-1"
      value={chosenVendor}
      onChange={setChosenVendor}
      style={{ height: '28px' }}
      disabled={searchingVendor}
    >
      <option value="">Choose a Vendor</option>
      {quickbooksResponse.vendor_matches.map((vendor) => (
        <option value={vendor.id} key={vendor.id}>
          {vendor.name}
        </option>
      ))}
    </select>
    <InputGroup className="ml-2">
      <Input
        type="text"
        bsSize="sm"
        placeholder="vendor search..."
        value={vendorSearchTerm}
        disabled={searchingVendor}
        onChange={changeVendorSearchTerm}
        onKeyDown={(e) => (e.key === 'Enter' ? searchForVendors() : false)}
      />
      <InputGroupAddon addonType="append">
        <Button color="info" onClick={searchForVendors} disabled={searchingVendor}>
          <Icon i="search" isBusy={searchingVendor} />
          &nbsp; Search
        </Button>
      </InputGroupAddon>
    </InputGroup>
  </>
);

BasicVendorPicker.propTypes = {
  setChosenVendor: PropTypes.func.isRequired,
  changeVendorSearchTerm: PropTypes.func.isRequired,
  searchForVendors: PropTypes.func.isRequired,
  quickbooksResponse: PropTypes.shape({
    vendor_matches: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  vendorSearchTerm: PropTypes.string,
  chosenVendor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  searchingVendor: PropTypes.bool,
};

BasicVendorPicker.defaultProps = {
  chosenVendor: '',
  vendorSearchTerm: '',
  searchingVendor: false,
};

export default BasicVendorPicker;
