import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { Button } from 'reactstrap';

import { ConfirmModalResult } from '../../../types/modal/modal-result';

import { ModalContext } from '../../modals/modal-context';
import { APIContext } from '../../providers/api-provider';

import Icon from '../../layout-helpers/icon';
import FriendlyFormMessage from '../../layout-helpers/friendly-form-message';

import { apiAborter } from '../../../helpers/api-aborter.helper';

import { MODAL_TYPE } from '../../../constants/modal-type.const';
import { ICON } from '../../../constants/icon.const';


export type CreateFolderWidgetSectionProps = {
  rowData: {
    id: number,
    drive_id: string
  },
  baseRoute: string,
  refreshRecord?: () => void,
}

type CreateFolderState = {
  isCreatingFolder: boolean,
  lastCreateAttemptSuccess: null | boolean,
  lastCreateAttemptMessage: null | string,
}

/**
 * Render the project admin widget section that allows a user to create the project number
 */
export const CreateFolderWidgetSection:React.FC<CreateFolderWidgetSectionProps> = (props) => {
  const {
    rowData,
    refreshRecord,
    baseRoute,
  } = props;

  const { apiFetch } = useContext(APIContext);
  const { showModal } = useContext(ModalContext);

  const {
    id,
    drive_id,
  } = rowData;

  const createFolderDisabled = !!drive_id;

  // Create a state for the create project number
  const [creatingFolderState, setCreatingFolderState] = useState<CreateFolderState>({
    isCreatingFolder: false,
    lastCreateAttemptSuccess: null,
    lastCreateAttemptMessage: null,
  });
  const abortCreateFolder = useRef<null | AbortController>(null);


  /**
   * Fired by the onConfirm action on the dialog after asking if they are sure
   */
  const handleCreateFolder = useCallback(() => {
    setCreatingFolderState({
      isCreatingFolder: true,
      lastCreateAttemptSuccess: null,
      lastCreateAttemptMessage: null,
    });

    if (abortCreateFolder.current) {
      abortCreateFolder.current.abort();
    }
    abortCreateFolder.current = apiAborter();

    // Call the API
    apiFetch(
      `${baseRoute}/${id}/action/create-folder`,
      {
        method: HTTP_METHOD.POST,
        name: 'DriveFilesWidget::createFolder',
        signal: abortCreateFolder.current.signal,
      },
    ).then((response) => {
      if (response.success) {
        abortCreateFolder.current = null;
        if (refreshRecord) refreshRecord();
        setCreatingFolderState({
          isCreatingFolder: false,
          lastCreateAttemptSuccess: true,
          lastCreateAttemptMessage: 'Successfully created folder.',
        });
      } else if (!response.aborted) {
        console.error('DriveFilesWidget::createFolder', response.error);
        abortCreateFolder.current = null;
        setCreatingFolderState({
          isCreatingFolder: false,
          lastCreateAttemptSuccess: false,
          lastCreateAttemptMessage: `Failed to create folder: ${(response.body && response.body.message) || '(Unknown Error)'}`,
        });
      }
    });
  }, [apiFetch, baseRoute, id, abortCreateFolder, setCreatingFolderState, refreshRecord]);


  /**
   * Fired when the user clicks on "Create Project Number".
   * The user must confirm the action via a modal dialog
   */
  const handleCreateClick = () => {
    const modalContent = (
      <>
        <span>You are about to create the folder for this record</span>
      </>
    );

    // Display the confirmation modal
    showModal<ConfirmModalResult>(MODAL_TYPE.CONFIRM, {
      title: 'Create Folder',
      color: 'info',
      content: modalContent,
      confirmButtonColor: 'info',
      confirmButtonLabel: 'Create',
      confirmButtonIcon: 'refresh',
      onModalComplete: ({ success }) => {
        if (success) handleCreateFolder();
      },
    });
  };


  /**
   * (ComponentWillMount)
   */
  useEffect(() => () => {
    // (ComponentWillUnMount)
    if (abortCreateFolder.current) abortCreateFolder.current.abort();
  }, []);


  return (
    <div className="function-section pt-2">
      {/* Create Button */}
      {!createFolderDisabled && (
        <p>
          <Button
            color="primary"
            disabled={createFolderDisabled || creatingFolderState.isCreatingFolder}
            onClick={handleCreateClick}
          >
            <Icon
              isBusy={creatingFolderState.isCreatingFolder}
              i={ICON.FOLDER}
            />
            <span>Create Folder</span>
          </Button>
        </p>
      )}
      {/* Create Action Result */}
      {!creatingFolderState.isCreatingFolder && creatingFolderState.lastCreateAttemptMessage && (
        <div>
          <FriendlyFormMessage
            formMessage={creatingFolderState.lastCreateAttemptMessage}
            alertColor={creatingFolderState.lastCreateAttemptSuccess ? 'success' : 'danger'}
            useSimpleDefault
          />
        </div>
      )}

    </div>
  );
};
