import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'reactstrap';
import Icon from '../../layout-helpers/icon';
import { formatValueString } from '../../render-functions';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { DToolsLinkInfoRow } from './d-tools-link-info-row';

export class ProjectDToolsMatchRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      linking: false,
    };
  }

  handleToggleMaster = (e, rowData) => {
    e.preventDefault();
    e.stopPropagation();
    const { toggleMaster } = this.props;
    toggleMaster(rowData.Id);
  };

  render = () => {
    const { rowData, openRowId, linkProject, toggleOpenRow, projectData } = this.props;
    const { linking } = this.state;
    const thisRowIsOpen = openRowId === rowData.dtools_id || openRowId === rowData.Id;
    const isMasterRow = rowData.Id === projectData.dtools_id;
    return (
      <React.Fragment key={rowData.id}>
        <tr className="dtools-link" onClick={() => toggleOpenRow(rowData)}>
          <td>{rowData.Number || '-'}</td>
          <td>
            {rowData.Client || '-'}
            {' >> '}
            {rowData.Name || '-'}
          </td>
          <td>{rowData.Progress || '-'}</td>
          <td>{rowData.Approved ? 'Yes' : 'No'}</td>
          <td className="text-right">{formatValueString(rowData.Price || 0, COLUMN_FORMAT.CURRENCY)}</td>
          <td>{rowData.MatchType || 0}</td>
          {/* <td>{rowData.Id === projectData.dtools_id ? <span>Yes</span> : <span>&nbsp;</span>}</td> */}
          <td>
            <ButtonGroup className="pull-right">
              <Button color="info" size="sm">
                <Icon i={thisRowIsOpen ? 'eye-slash' : 'eye'} />
                &nbsp; details
              </Button>
              <Button
                color="mediumAqua"
                size="sm"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  this.setState({ linking: true }, linkProject(rowData));
                }}
              >
                <Icon i="link" isBusy={linking} />
                &nbsp; link
              </Button>
              {isMasterRow ? (
                <Button color="danger" onClick={(e) => this.handleToggleMaster(e, rowData)}>
                  Unlink Master
                </Button>
              ) : (
                <Button color="success" onClick={(e) => this.handleToggleMaster(e, rowData)}>
                  Make Master
                </Button>
              )}
            </ButtonGroup>
          </td>
        </tr>
        {(thisRowIsOpen || (isMasterRow && openRowId === null)) && (
          <DToolsLinkInfoRow dtoolsId={rowData.dtools_id || rowData.Id} projectData={projectData} />
        )}
        {/* {thisRowIsOpen && <DToolsLinkInfoRow dtoolsId={rowData.dtools_id || rowData.Id} projectData={projectData} />} */}
      </React.Fragment>
    );
  };
}

ProjectDToolsMatchRow.propTypes = {

  rowData: PropTypes.shape({
    id: PropTypes.number,
    dtools_id: PropTypes.string,
    Id: PropTypes.string,
    Price: PropTypes.number,
    Approved: PropTypes.bool,
    Client: PropTypes.string,
    Name: PropTypes.string,
    Number: PropTypes.string,
    Progress: PropTypes.string,
    CheckOutUserName: PropTypes.string,
    MatchType: PropTypes.string,
  }).isRequired,
  // matchesLoading: PropTypes.bool.isRequired,
  openRowId: PropTypes.string,
  toggleOpenRow: PropTypes.func.isRequired,
  linkProject: PropTypes.func.isRequired,
  projectData: PropTypes.shape({
    id: PropTypes.number,
    dtools_id: PropTypes.string,
  }).isRequired,
  toggleMaster: PropTypes.func.isRequired,
};

ProjectDToolsMatchRow.defaultProps = {
  openRowId: undefined,
};
