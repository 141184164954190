import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

export const ExpenseClaimItemsWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Item',
  fields: [
    {
      name: 'expense_claim_id',
      visible: false,
      isParentId: true,
    },
    {
      name: 'name',
      title: 'Item Name',
      description: 'Item Name',
      formFieldToolTip: 'Brief name for the item. Not your own name!',
      visible: true,
      format: COLUMN_FORMAT.TEXT,
      autoComplete: true,
      autoCompleteIdentifier: 'expense_claim_item_name',
    },
    {
      name: 'description',
      title: 'Description',
      description: 'Detailed Description',
      visible: true,
      format: COLUMN_FORMAT.LONG_TEXT,
    },
    {
      name: 'receipt_file',
      title: 'Receipt',
      description: 'Proof of Purchase',
      formFieldToolTip: 'Proof of Purchase',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'receipt_file',
        key: 'filename',
        format: COLUMN_FORMAT.TEXT,
      },
      formSaveField: 'receipt_file_id',
      formFieldType: FORM_FIELD_TYPE.RECEIPT_PICKER,
    },
    {
      name: 'project',
      title: 'Project',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'project',
        key: 'project_number',
        format: COLUMN_FORMAT.TEXT,
      },
      formSaveField: 'project_id',
      formFieldType: FORM_FIELD_TYPE.PROJECT_PICKER,
    },
    {
      name: 'contract',
      title: 'Contract',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'contract',
        key: 'number',
        format: COLUMN_FORMAT.TEXT,
      },
      formSaveField: 'contract_id',
      formFieldType: FORM_FIELD_TYPE.CONTRACT_PICKER,
    },
    {
      name: 'service_job',
      title: 'Service Job',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'service_job',
        key: 'number',
        format: COLUMN_FORMAT.TEXT,
      },
      formSaveField: 'service_job_id',
      formFieldType: FORM_FIELD_TYPE.SERVICE_JOB_PICKER,
    },
    {
      name: 'reference_no',
      title: 'Reference Number',
      visible: true,
      format: COLUMN_FORMAT.TEXT,
    },
    {
      name: 'category',
      title: 'Category',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'category',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      visible: true,
      formSaveField: 'category_id',
      formFieldType: FORM_FIELD_TYPE.EXPENSE_TYPE_PICKER,
    },
    {
      name: 'amount',
      title: 'Amount',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    },
  ],
};
