import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { DataTablePageProps } from '../../types/data-table-page.props';
import { NewRecordModalResult } from '../../types/modal/modal-result';
import { IContractRecord } from '../../types/service/contract.record.interface';

import { ModalContext } from '../modals/modal-context';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { API_FILTER_OPERATION } from '../../constants/api-filter-operation.const';

export type SupportDataTablePageProps = DataTablePageProps;

/**
 * Display the "Support" data table as a whole page
 */
export const SupportDataTablePage: React.FC<SupportDataTablePageProps> = ({
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
  title,
}) => {
  const history = useHistory();
  const { showModal } = useContext(ModalContext);

  const actionHandlers = {
    create: () => {
      showModal<NewRecordModalResult<IContractRecord>>(MODAL_TYPE.NEW_CONTRACT, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/service-and-support/licences-and-subscriptions/${modalResult.newRecord.id}`);
        },
      });
    },
  };

  const baseFilters = [
    {
      field: 'type.prefix',
      operation: API_FILTER_OPERATION.IN,
      values: ['SC', 'DLP', 'DDF'],
    },
  ];

  return (
    <DataTablePage
      title={title || 'Support Contracts'}
      baseFilters={baseFilters}
      tableIdentifier={TABLE_IDENTIFIER.SUPPORT_TABLE}
      actionHandlers={actionHandlers}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      {children}
    </DataTablePage>
  );
};
