import { DELETE_CONFIRMATION_TYPE } from '../constants/delete-confirmation-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';

export const CommissionPaymentForecastDetailWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Salesperson',
  isReadOnly: true,
  formDeleteConfirmationType: DELETE_CONFIRMATION_TYPE.REMOVE,
  collectionItemPrimaryKeyFieldName: 'project_id',
  fields: [
    {
      name: 'sales_owner',
      title: 'Salesperson',
      format: COLUMN_FORMAT.TEXT,
    }, {
      name: 'project',
      title: 'Project',
      format: COLUMN_FORMAT.P_NUMBER,
      linkSubRoute: '/commission-info',
      showTotals: true,
    }, {
      name: 'value',
      title: 'Project Value',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    }, {
      name: 'inclusive_invoice_amount_in_commission_window',
      title: 'Total Invoice $',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    }, {
      name: 'exclusive_invoice_amount_in_commission_window',
      title: 'New Invoice $',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    }, {
      name: 'commission_percentage',
      title: 'Rate',
      visible: true,
      allowNegative: true,
      format: COLUMN_FORMAT.NUMBER_SHORT,
    }, {
      name: 'total_commission_paid',
      title: 'Total Paid',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    }, {
      name: 'prior_commission_paid',
      title: 'Previously Paid',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    }, {
      name: 'new_commission_paid',
      title: 'Recently Paid',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    }, {
      name: 'new_commission_payable',
      title: 'Payable Now',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    },
    {
      name: 'financial_year_invoiced_total',
      title: 'FY Invoiced',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    },
    {
      name: 'financial_year_commission_total',
      title: 'FY Commission',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    },
    {
      name: 'total_commission_expected',
      title: 'Expected',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    },
  ],
};
