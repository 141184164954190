/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Table } from 'reactstrap';

import { ConfirmActionModalResult } from '../../../../types/modal/modal-result';
import { IUploadMaintenanceChecksModalWizardPage2Data, IUploadMaintenanceChecksModalWizardState, IUploadValidationResponse } from '../upload-maintenance-checks-data.interface';

import { ModalWizardPage, ModalWizardPageProps } from '../../modal-wizard-page';
import { ModalWizardItemProgress } from '../../modal-wizard-item-progress';


export const UploadMaintenanceChecksModalPage2 = (props: ModalWizardPageProps<
  IUploadMaintenanceChecksModalWizardPage2Data,
  IUploadMaintenanceChecksModalWizardState,
  ConfirmActionModalResult
>): React.ReactElement => {
  const { wizardState } = props;
  const {
    processingImport,
    importProcessed,
    uploadImportFileResult,
    importFileUploadStatus,
  } = wizardState;

  return (
    <ModalWizardPage<IUploadMaintenanceChecksModalWizardPage2Data, IUploadMaintenanceChecksModalWizardState, ConfirmActionModalResult> {...props}>
      <div className="wizard-page-section pb-4">
        <div className="wizard-page-section-heading">
          Validating
        </div>
        <div className="wizard-page-section-content pb-6">
          <div className="wizard-item-progress-wrapper">

            {/* Import File Document */}
            <ModalWizardItemProgress<IUploadValidationResponse[]>
              label={importProcessed ? 'Done' : 'Uploading data...'}
              result={uploadImportFileResult}
              aborted={!uploadImportFileResult && !processingImport && !importProcessed}
              progress={processingImport || importProcessed ? importFileUploadStatus : undefined}
            />
          </div>
        </div>
      </div>

      {uploadImportFileResult && (
        <div className="wizard-page-section">
          <div className="wizard-page-section-heading">
            Data Loaded, please verify the data has been parsed correctly before continuing with the upload.
          </div>
          <div className="wizard-page-section-content">

            {uploadImportFileResult.result && (
              <Table>
                <thead>
                  <tr>
                    <th>id</th>
                    <th>Before</th>
                    <th>Booked</th>
                    <th>Done</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  {uploadImportFileResult.result.map((row) => {
                    if (row.error) {
                      return (
                        <tr key={`row-${row.id}`}>
                          <td>{row.id}</td>
                          <td colSpan={5} className="text-danger">{row.error}</td>
                        </tr>
                      );
                    }
                    return (
                      <tr key={`row-${row.id}`}>
                        <td>{row.id}</td>
                        <td>{row.perform_before}</td>
                        <td>{row.booked_on}</td>
                        <td>{row.performed_on}</td>
                        <td>{row.notes}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}

          </div>
        </div>
      )}

      {/* <pre>{JSON.stringify(uploadImportFileResult, null, 2)}</pre> */}

    </ModalWizardPage>
  );
};
