import React from 'react';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';

import AsyncSelect from './async-select';

export type AllowanceTypePickerProps = AsyncSelectComponentProps;

export const AllowanceTypePicker: React.FC<AllowanceTypePickerProps> = (props) => (
  <AsyncSelect
    {...props}
    searchRoute="/allowancetype"
    loadAndKeepAll
  />
);
