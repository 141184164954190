import React, { useState } from 'react';
import { Label } from 'reactstrap';

import { ConfirmActionModalResult } from '../../../../types/modal/modal-result';
import { IActivateProjectModalWizardPage2Data, IActivateProjectModalWizardState } from '../activate-project-data.interface';

import { ModalWizardPage, ModalWizardPageProps } from '../../modal-wizard-page';
import ActivationDtoolsLinkWidget from '../dtools-link-widget/activation-dtools-link-widget';
import { WizardFormErrorRow } from '../../../layout-helpers/wizard-form-error-row';
import { CurrencyInput } from '../../../form-input/currency-input';
import { getFieldPageDataErrors } from '../../../../helpers/get-field-page-data-errors.helper';
import ActivationDtoolsProjectSummary from '../dtools-link-widget/activation-dtools-project-summary';
import { CheckBox } from '../../../form-input/checkbox';


export const ActivateProjectModalPage2 = (props: ModalWizardPageProps<
  IActivateProjectModalWizardPage2Data,
  IActivateProjectModalWizardState,
  ConfirmActionModalResult
>): React.ReactElement => {
  const { pageData, pageDataErrors, onPageDataFieldChanged, wizardState: { projectRecord } } = props;
  const { dtools_project_id } = pageData;

  const onSelectDtoolsProject = (dtoolsId: string | null) => {
    if (onPageDataFieldChanged !== undefined) {
      onPageDataFieldChanged({
        fieldName: 'dtools_project_id',
        newValue: dtoolsId,
      });
    }
  };

  const onReceiveDtoolsSummary = (costValue: number, priceValue: number) => {
    if (onPageDataFieldChanged !== undefined) {
      onPageDataFieldChanged([{
        fieldName: 'as_sold_cost',
        newValue: costValue,
      }, {
        fieldName: 'as_sold_price',
        newValue: priceValue,
      }]);
    }
  };

  const [allowManualInput, setAllowManualInput] = useState<boolean>(false);

  /**
   * Render
   */
  return (
    <ModalWizardPage<IActivateProjectModalWizardPage2Data, IActivateProjectModalWizardState, ConfirmActionModalResult>
      {...props}
    >
      <div className="wizard-page-section">
        <div className="wizard-page-section-heading">
          <span>Step 2: D-Tools As Sold & Commission Information</span>
        </div>
        <div className="wizard-page-section-content">
          <p>
            We need to capture some details about the as sold profitability of the job, please select the correct source project below.
          </p>

          {/* Eligible for Commission */}
          <CheckBox
            id="eligible_for_commission"
            name="eligible_for_commission"
            label="Project is Eligible for Commission"
            infoToolTip={{
              title: 'Is the project eligible for commission?',
              body: 'Uncheck this box if you have confirmed that this project will not be eligible to accrue commission.',
            }}
            inline
            value={pageData.eligible_for_commission}
            onChange={onPageDataFieldChanged}
          />


          {dtools_project_id && (
            <ActivationDtoolsProjectSummary selectedDtoolsId={dtools_project_id} onReceiveDtoolsSummary={onReceiveDtoolsSummary} />
          )}

        </div>

        {/* === Manual Entry Mode ===  */}

        {allowManualInput && (
          <div className="wizard-page-section-content">
            <p>
              No D-Tools Data was found, please enter the &apos;as Sold&apos; cost and price:
            </p>


            {/* === As Sold Cost ===  */}
            <Label
              for="as_sold_cost"
              hasError={getFieldPageDataErrors(pageDataErrors, 'as_sold_cost') !== false}
            >
              {`As Sold Cost${!pageData.as_sold_cost ? '*' : ''}`}
            </Label>
            <CurrencyInput
              id="as_sold_cost"
              name="as_sold_cost"
              value={(pageData.as_sold_cost ?? 0).toString()}
              allowNegative={false}
              hasError={getFieldPageDataErrors(pageDataErrors, 'as_sold_cost') !== false}
              onChange={onPageDataFieldChanged}
            />
            <WizardFormErrorRow fieldName="as_sold_cost" pageDataErrors={pageDataErrors} />


            {/* === As Sold Price ===  */}
            <Label
              for="as_sold_price"
              haserror={getFieldPageDataErrors(pageDataErrors, 'as_sold_price') !== false}
            >
              {`As Sold Price${!pageData.as_sold_price ? '*' : ''}`}
            </Label>
            <CurrencyInput
              id="as_sold_price"
              name="as_sold_price"
              value={(pageData.as_sold_price ?? 0).toString()}
              allowNegative={false}
              hasError={getFieldPageDataErrors(pageDataErrors, 'as_sold_price') !== false}
              onChange={onPageDataFieldChanged}
            />
            <WizardFormErrorRow fieldName="as_sold_price" pageDataErrors={pageDataErrors} />


          </div>
        )}

        <div className="wizard-page-section-content">
          <p>
            <strong>As Sold Margin:</strong>
            {' '}
            {(pageData.as_sold_price && pageData.as_sold_cost) ?
              `${Math.round(
                (((pageData.as_sold_price ?? 0) - (pageData.as_sold_cost ?? 0)) / (pageData.as_sold_price ?? 0)) * 10000,
              ) / 100
              }%` : 'not set'}
          </p>
        </div>

        <WizardFormErrorRow fieldName="dtools_project_id" pageDataErrors={pageDataErrors} />

        <p>&nbsp;</p>

        {/* === D-Tools Project Selector === */}
        <ActivationDtoolsLinkWidget
          project={projectRecord}
          onSelectDtoolsProject={onSelectDtoolsProject}
          selectedDtoolsId={dtools_project_id}
          setAllowManualInput={setAllowManualInput}
        />

      </div>
    </ModalWizardPage>
  );
};
