import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';

import { A_PROJECT_STATUS, PROJECT_STATUS } from '../../constants/project-status.const';

export const LIKELIHOOD_VALUES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const LIKELIHOOD_DESCRIPTIONS = [
  // 1
  'Dead.',

  // 2
  'Very Unlikely',

  // 3
  'Unlikely',

  // 4
  'Likely',

  // 5
  'Very Likely',

  // 6
  'Extremely Likely',

  // 7
  'Tender at 50% likelihood',

  // 8
  'P.O. landing within a few months',

  // 9
  'P.O. landing next month',

  // 10
  'P.O. received or landing this month',
];

/**
 * Helper for the likelihood component to determine the class to be added to make it look good or bad
 *
 * @param value the likelihood to convert into a quality class
 */
const getQualityClass = (value: number): string => {
  let qualityClass = 'good';
  qualityClass = value < 8 ? 'average' : qualityClass;
  qualityClass = value < 5 ? 'poor' : qualityClass;
  qualityClass = value < 2 ? 'bad' : qualityClass;
  return qualityClass;
};


export type LikelihoodInputProps = Pick<FormFieldComponentProps,
  'id' |
  'name' |
  'value' |
  'formData' |
  'formSaveField' |
  'disabled' |
  'onChange'
>


export const LikelihoodInput: React.FC<LikelihoodInputProps> = (props) => {
  const {
    id,
    name,
    value,
    formData,
    formSaveField,
    disabled,
    onChange,
  } = props;

  // Use the formData if it has been passed in, otherwise use the value
  const actualValue: number = parseInt(formData ? formData[formSaveField ?? name] : (value ?? 0), 10);

  const [futureValue, setFutureValue] = useState<number>(actualValue);
  const [qualityClass, setQualityClass] = useState<string>(getQualityClass(futureValue));


  /**
   * Evaluates the current record's status id and prevents the selection of a likelihood value
   * beyond a limit for that status.
   */
  const getMaximumValidLikelihood = (statusId: A_PROJECT_STATUS): number => {
    // Leads are not allowed to select values greater than 6. (Dom Vaiano 10/2019)
    if (statusId === PROJECT_STATUS.LEAD) return 6;

    // Otherwise any likelihood value is up for grabs.
    return 10;
  };


  /**
   * Fired when the user hovers their mouse over one of the likelihood items
   *
   * @param {number} value the likelihood value being hovered
   */
  const handleHover = useCallback((hoverValue) => () => {
    if (disabled) return;

    // Prevent the user from selecting a value greater than the allowed likelihood value
    if (formData && (formData.status_id) && (hoverValue > getMaximumValidLikelihood(formData.status_id as A_PROJECT_STATUS))) return;

    setFutureValue(hoverValue);
    setQualityClass(getQualityClass(hoverValue));
  }, [disabled, formData]);


  /**
   * Fired when the user clicks one of the likelihood indicators
   */
  const handleClick = useCallback((clickedValue: number) => (e: React.MouseEvent<HTMLDivElement> | React.FocusEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (disabled) return;

    // Prevent the user from selecting a value greater than the allowed likelihood value
    const maxValue = formData && formData.status_id ? getMaximumValidLikelihood(formData.status_id as A_PROJECT_STATUS) : clickedValue;

    if (onChange) {
      onChange({
        fieldName: formSaveField ?? name,
        newValue: Math.min(clickedValue, maxValue),
      });
    }
  }, [disabled, formData, formSaveField, name, onChange]);

  /**
   * update if new props are passed down.
   */
  useEffect(() => {
    setFutureValue(actualValue);
  }, [actualValue]);

  // Render
  return (
    <div
      className={classnames({
        'likelihood-editor': !disabled,
        'likelihood-viewer': disabled,
      }, qualityClass)}
      title={disabled ? `${futureValue}: ${LIKELIHOOD_DESCRIPTIONS[actualValue - 1]}` : undefined}
    >
      {LIKELIHOOD_VALUES.map((i) => {
        const activeClass = i <= actualValue ? 'filled' : '';
        const futureClass = i <= futureValue ? 'future' : '';

        return (
          // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
          <div
            id={id}
            key={i}
            className={`likelihood-square ${activeClass} ${futureClass}`}
            onMouseOver={handleHover(i)}
            onMouseOut={handleHover(actualValue)}
            onFocus={handleClick(i)}
            onClick={handleClick(i)}
          />
        );
      })}
      {!disabled && (
        <span className="description">{`${futureValue}: ${LIKELIHOOD_DESCRIPTIONS[futureValue - 1]}`}</span>
      )}
    </div>
  );
};
