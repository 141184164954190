/* eslint-disable no-shadow */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Card, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import Icon from '../layout-helpers/icon';
import { Notification } from './notification';
import FriendlyFormMessage from '../layout-helpers/friendly-form-message';
import { connectToCurrentUserProvider } from '../providers/current-user-provider';
import { CURRENT_USER_PROVIDER_PROP_TYPES } from '../../prop-types/current-user-provider-prop-types';
import API_PROVIDER_PROP_TYPES from '../../prop-types/api-provider-prop-types';
import { connectToAPIProvider } from '../providers/api-provider';
import { apiAborter } from '../../helpers/api-aborter.helper';

class NotificationsCard extends Component {
  /**
   * @constructor
   */
  constructor(props) {
    super(props);

    this.state = {
      notificationData: [],
      currentPage: 0,
      hasMoreItems: true,
      isLoading: false,
      hasLoaded: false,
      formMessage: null,
      alertColor: null,
    };
    this.abortController = null;
  }


  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    if (this.abortController) this.abortController.abort();
  }


  componentDidMount() {
    this.getNotifications();
  }

  /**
   * @description
   * Fetch notifications and update state
   */
  getNotifications = async () => {
    const { isLoading, currentPage } = this.state;
    const { currentUserProvider: { userDetails }, apiProvider: { apiFetch } } = this.props;

    if (isLoading) return;

    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortController = apiAborter();

    this.setState({
      isLoading: true,
      hasMoreItems: false,
      currentPage: currentPage + 1,
    }, async () => {
      const { pageLength } = this.props;

      const baseFilters = `/user/${userDetails.id}/notification`;
      const queryParams = [
        `page=${currentPage + 1}`,
        `pagelength=${pageLength}`,
        'sort[0][field]=created_at',
        'sort[0][direction]=desc',
      ].join('&');

      const response = await apiFetch(`${baseFilters}?${queryParams}`, { signal: this.abortController.signal });

      if (response.success) {
        this.abortController = null;
        const { hasLoaded, notificationData } = this.state;
        const combinedNotificationsData = hasLoaded
          ? [...notificationData]
          : [];
        response.body.data.map((notificationRecord) => {
          combinedNotificationsData.push({
            ...notificationRecord,
            data: JSON.parse(notificationRecord.data),
          });
          return true;
        });

        if (response.body.meta.current_page) {
          this.setState({
            notificationData: combinedNotificationsData,
            isLoading: false,
            hasLoaded: true,
            hasMoreItems: response.body.meta.current_page < response.body.meta.last_page,
          });
        }
        else {
          this.setState({
            hasMoreItems: false,
            isLoading: false,
            hasLoaded: true,
          });
        }
      } else if (!response.aborted) {
        this.abortController = null;
        this.setState({
          isLoading: false,
          hasLoaded: true,
          hasMoreItems: false,
          formMessage: response.error || 'Failed to get Notifications.',
          alertColor: 'danger',
        });
      }
    });
  };

  render() {
    const {
      notificationData, hasLoaded, hasMoreItems, formMessage, alertColor, isLoading,
    } = this.state;
    const { scrollContainerId, fixedHeight } = this.props;

    const loadingBox = isLoading ? (
      <p className="text-center my-4" style={{ width: '100%' }} key="loadingBox">
        <Icon i="rolling" size="lg" />
      </p>
    ) : null;

    // <NotificationsCard />
    return (
      <Card className="notifications">
        <CardHeader className="bg-dark text-white">
          <h4>My Notifications</h4>
          <Link className="text-white" to="/comms/notifications">View All</Link>
        </CardHeader>
        <div className="notification-scroll-parent" id="notification_scroll_parent" style={{ height: fixedHeight ?? 'auto' }}>

          {/* Display an infinitely scrollable set of notifications */}
          <InfiniteScroll
            // className="infinite"
            dataLength={hasLoaded ? notificationData.length : 0}
            next={this.getNotifications}
            hasMore={hasMoreItems}
            loader={loadingBox}
            scrollableTarget={scrollContainerId || (fixedHeight ? 'notification_scroll_parent' : undefined)}
            scrollThreshold={0.95}
            style={{
              overflow: 'hidden',
            }}
          >
            {hasLoaded && notificationData.map((notification) => (
              <Notification notificationRecord={notification} key={`infinite_${notification.id}`} />
            ))}
          </InfiniteScroll>

          {/* Display a different set of notifications outside of an infinite scroller when in mobile mode */}
          <div className="fixed">
            {hasLoaded &&
              notificationData.map((notification) => (
                <Notification notificationRecord={notification} key={`fixed_${notification.id}`} />
              ))}
          </div>

          {!hasLoaded && (
            <div className="text-center my-4">Loading...</div>
          )}

          {!hasMoreItems && !formMessage && hasLoaded && (
            <div className="text-center my-4">No more notifications to show</div>
          )}

          <FriendlyFormMessage
            formMessage={formMessage}
            alertColor={alertColor}
            isOpen={!!formMessage}
            useSimpleDefault
            inline
          />
        </div>
      </Card>
    );
  }
}

NotificationsCard.propTypes = {
  pageLength: PropTypes.number,
  scrollContainerId: PropTypes.string,
  currentUserProvider: PropTypes.shape(CURRENT_USER_PROVIDER_PROP_TYPES).isRequired,
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
  fixedHeight: PropTypes.string,
};

NotificationsCard.defaultProps = {
  pageLength: 10,
  scrollContainerId: undefined,
  fixedHeight: null,
};

export default connectToCurrentUserProvider(connectToAPIProvider(NotificationsCard));
