/**
 * These are all of the possible actions that could come back from the API as part of a HATEOS response
 * @note: this file must be kept up to date with the API's `Action.php`
 */
export const API_ACTION = {
  VIEW: 'view',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  SUBMIT: 'submit',
  RETRACT: 'retract',
  UNSUBMIT: 'unsubmit',
  REJECT: 'reject',
  REVOKE: 'revoke',
  APPROVE: 'approve',
  CONFIRM: 'confirm',
  CANCEL: 'cancel',
  SCHEDULE: 'schedule',
  UNSCHEDULE: 'unschedule',
  ARCHIVE: 'archive',
  UNARCHIVE: 'unarchive',
  RESTORE: 'restore',
  ADD: 'add',
  CHANGE: 'change',
  REMOVE: 'remove',
  PROCESS: 'process',
  UNPROCESS: 'unprocess',
  PUBLISH: 'publish',
  UNPUBLISH: 'unpublish',
  PAY: 'pay',
  UNPAY: 'unpay',
  ATTACH: 'attach',
  DETACH: 'detach',
  BATCH: 'batch',
  UNBATCH: 'unbatch',
  UPLOAD: 'upload',
  DOWNLOAD: 'download',
  SYNC: 'sync',
  ACTIVATE: 'activate',
  ROLLBACK: 'rollback',
  RENEW: 'renew',
  REGENERATE_PROJECT_NUMBER: 'regenerateprojectnumber',
  SYNCHRONIZE_PROJECT_PROFIT_AND_LOSS_DATA: 'synchronize-profitandloss',
  SYNCHRONIZE_PROJECT_T_SHEETS_LABOUR_CLASS_HOURS: 'synchronize-tsheets',
  SEND_WELCOME_EMAIL: 'sendwelcomeemail',
  PREPARE_MS_DRIVE_RESOURCES: 'prepare-ms-drive-resources',
  CREATE_MS_GROUP: 'create-ms-group',
  LINK_MS_GROUP_DRIVE_ITEM: 'link-ms-group-drive-item',
  SYNC_PROJECT_RESOURCES_WITH_MS_GROUP: 'sync-project-resources-with-ms-group',
  SYNC_PROJECT_MS_DRIVE_FOLDERS: 'sync-project-ms-drive-folders',
  CREATE_MS_TEAM: 'create-ms-team',
  SYNC_MS_GROUP_AND_TEAM_NAME_WITH_PROJECT: 'sync-ms-group-and-team-name-with-project',
  SYNC_MS_TEAM_PERMISSIONS_FOR_PROJECT: 'sync-ms-team-permissions-for-project',
  GET_PDF_PREVIEW: 'get-pdf-preview',
  GENERATE_COMMISSION_PAYMENTS: 'generate-commission-payments',
} as const;
export type API_ACTION = typeof API_ACTION;
export type AN_API_ACTION = API_ACTION[keyof API_ACTION];
