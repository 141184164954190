import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { Button } from 'reactstrap';

import { IProjectRecord } from '../../../types/project/project.record.interface';
import { IActionButton } from '../../../types/action-button.interface';

import { ModalContext } from '../../modals/modal-context';
import { APIContext } from '../../providers/api-provider';

import Icon from '../../layout-helpers/icon';
import FriendlyFormMessage from '../../layout-helpers/friendly-form-message';

import { apiAborter } from '../../../helpers/api-aborter.helper';
import { hasPermittedAction } from '../../../helpers/has-permitted-action.helper';

import { PROJECT_STATUS_NAME_MAP } from '../../../constants/project-status.const';
import { MODAL_TYPE } from '../../../constants/modal-type.const';
import { API_ACTION } from '../../../constants/api-action.const';
import { ICON } from '../../../constants/icon.const';
import { BUTTON_COLOR } from '../../../constants/button-color.const';
import { ConfirmModalResult } from '../../../types/modal/modal-result';
import StatusBadge from '../../data-format/status-badge';

export type RollbackProjectAdminWidgetSectionProps = {
  rowData: IProjectRecord,
  refreshRecord: () => void,
  permittedActions: IActionButton[],
}

type RollbackProjectState = {
  isProcessing: boolean,
  lastAttemptSuccess: null | boolean,
  lastAttemptMessage: null | string,
}


/**
 * Render the Project Admin Widget section that allows a user to rollback a project activation
 */
export const RollbackProjectAdminWidgetSection:React.FC<RollbackProjectAdminWidgetSectionProps> = (props) => {
  const {
    rowData,
    refreshRecord,
    permittedActions,
  } = props;

  const { apiFetch } = useContext(APIContext);
  const { showModal } = useContext(ModalContext);

  const {
    id: projectId,
    status_id: projectStatusId,
  } = rowData;

  const rollBackAction = hasPermittedAction(permittedActions, API_ACTION.ROLLBACK);
  const rollBackEnabled = !!rollBackAction;

  // Create a state for the project rollback
  const [rollbackProjectState, setRollbackProjectState] = useState<RollbackProjectState>({
    isProcessing: false,
    lastAttemptSuccess: null,
    lastAttemptMessage: null,
  });
  const abortRollbackProject = useRef<null | AbortController>(null);


  /**
   * @description
   * Process the Rollback
   */
  const handleConfirmRollback = useCallback((reason: string) => {
    setRollbackProjectState({
      isProcessing: true,
      lastAttemptSuccess: null,
      lastAttemptMessage: null,
    });

    if (abortRollbackProject.current) {
      abortRollbackProject.current.abort();
    }
    abortRollbackProject.current = apiAborter();

    // Call the API
    apiFetch(
      `/project/${projectId}/action/rollback`,
      {
        method: HTTP_METHOD.POST,
        name: 'ProjectAdminWidget::handleConfirmRollback',
        signal: abortRollbackProject.current.signal,
        body: {
          reason,
        },
      },
    ).then((response) => {
      if (response.success) {
        abortRollbackProject.current = null;
        refreshRecord();
        setRollbackProjectState({
          isProcessing: false,
          lastAttemptSuccess: true,
          lastAttemptMessage: 'Successfully rolled-back the Project.',
        });
      } else if (!response.aborted) {
        console.error('ProjectAdminWidget::handleConfirmRollback', response.error);
        abortRollbackProject.current = null;
        setRollbackProjectState({
          isProcessing: false,
          lastAttemptSuccess: false,
          lastAttemptMessage: `Failed to roll-back the project: ${(response.body && response.body.message) || '(Unknown Error)'}`,
        });
      }
    });
  }, [apiFetch, projectId, abortRollbackProject, refreshRecord]);


  /**
   * @description
   * Fired when the user clicks on "Rollback Project Activation".
   * The user must confirm the action via a modal dialog and provide a reason
   */
  const handleRollbackClick = () => {
    const modalContent = (
      <>
        <p>
          <strong>Warning!</strong>
        </p>
        <p>
          <span>You are about to rollback the activation of this project.</span>
        </p>
        <p>
          <span>
            This will impact the project milestones and may affect financial reporting.
          </span>
        </p>
        <p>
          <strong>You must provide a reason for performing this action:</strong>
        </p>
      </>
    );

    // Display the confirmation modal
    showModal<ConfirmModalResult>(MODAL_TYPE.CONFIRM, {
      title: 'Rollback Project Activation',
      color: 'warning',
      requireReason: true,
      content: modalContent,
      confirmButtonColor: 'warning',
      confirmButtonLabel: 'Rollback',
      confirmButtonIcon: ICON.ROLLBACK,
      onModalComplete: ({ success, reason }) => {
        if (success && reason) {
          handleConfirmRollback(reason);
        }
      },
    });
  };


  /**
   * (ComponentWillMount)
   */
  useEffect(() => () => {
    // (ComponentWillUnMount)
    if (abortRollbackProject.current) abortRollbackProject.current.abort();
  }, []);


  /**
   * Render
   */
  return (
    <div className="function-section">
      <h4>Rollback Project Activation</h4>
      <p>If this project has been activated in error, use this tool to rollback the activation.</p>

      {/* Disabled Info */}
      {!rollBackEnabled && (
        <p className="text-muted">
          <Icon i="info-circle" />
          <span> Only Projects that are at status &apos;active&apos; can be rolled back. (Current status: </span>
          <StatusBadge status={PROJECT_STATUS_NAME_MAP[projectStatusId]} short={false} />
          <span>)</span>
        </p>
      )}

      {/* Rollback Button */}
      <p>
        <Button
          color={rollBackEnabled ? BUTTON_COLOR.WARNING : BUTTON_COLOR.SECONDARY}
          disabled={!rollBackEnabled || rollbackProjectState.isProcessing}
          onClick={handleRollbackClick}
        >
          <Icon
            isBusy={rollbackProjectState.isProcessing}
            i={ICON.ROLLBACK}
          />
          <span>Rollback Activation</span>
        </Button>
      </p>

      {/* Rollback Action Result */}
      {!rollbackProjectState.isProcessing && rollbackProjectState.lastAttemptMessage && (
        <div>
          <FriendlyFormMessage
            formMessage={rollbackProjectState.lastAttemptMessage}
            alertColor={rollbackProjectState.lastAttemptSuccess ? 'success' : 'danger'}
            useSimpleDefault
          />
        </div>
      )}

    </div>
  );
};
