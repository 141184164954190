import React from 'react';
import { PROJECT_FORECAST_TYPE, PROJECT_FORECAST_TYPE_NAME_MAP } from '../../constants/project-forecast-type.const';
import { mapSelectInputOptions } from '../../helpers/map-select.helper';
import { ConstSelect, ConstSelectProps } from './const-select';

export type ForecastTypePickerProps = Omit<ConstSelectProps,
  'inputOptions' |
  'labelKey' |
  'valueKey' |
  'getOptionValue' |
  'getOptionLabel' |
  'renderOption'
>;

/**
 * ForecastTypePicker
 */
export const ForecastTypePicker:React.FC<ForecastTypePickerProps> = (props) => {
  const {
    name,
    formSaveField = null,
    disabled = false,
    formData = {},
  } = props;

  const saveField = formSaveField || name;
  const value = formData[saveField];

  // When the value for the forecast type is the ProjectTotal forecast type, only display that one option
  // Otherwise, display all other option types except the ProjectTotal type
  const options = mapSelectInputOptions(PROJECT_FORECAST_TYPE_NAME_MAP)
    .filter((option) => (
      value === PROJECT_FORECAST_TYPE.PROJECT_TOTAL
        ? option.id === PROJECT_FORECAST_TYPE.PROJECT_TOTAL
        : option.id !== PROJECT_FORECAST_TYPE.PROJECT_TOTAL
    ));

  return (
    <ConstSelect
      {...props}
      valueKey="id"
      labelKey="name"
      disabled={disabled || (value === PROJECT_FORECAST_TYPE.PROJECT_TOTAL)}
      inputOptions={options}
    />
  );
};
