/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { ConfirmActionModalResult } from '../../../../types/modal/modal-result';
import { IActivateProjectModalWizardPage3Data, IActivateProjectModalWizardState } from '../activate-project-data.interface';

import { CheckBox } from '../../../form-input/checkbox';
import Icon from '../../../layout-helpers/icon';
import { ModalWizardPage, ModalWizardPageProps } from '../../modal-wizard-page';
import { WizardFormErrorRow } from '../../../layout-helpers/wizard-form-error-row';

import { getFieldPageDataErrors } from '../../../../helpers/get-field-page-data-errors.helper';

import { ICON } from '../../../../constants/icon.const';

export const ActivateProjectModalPage4 = (props: ModalWizardPageProps<
  IActivateProjectModalWizardPage3Data,
  IActivateProjectModalWizardState,
  ConfirmActionModalResult
>): React.ReactElement => {
  const { pageData, wizardState: { projectRecord }, pageDataErrors, onPageDataFieldChanged } = props;

  const hasProjectDrive = !!projectRecord?.ms_group_drive_item_url;

  return (
    <ModalWizardPage<IActivateProjectModalWizardPage3Data, IActivateProjectModalWizardState, ConfirmActionModalResult> {...props}>
      <div className="wizard-page-section">
        <div className="wizard-page-section-heading">
          <span>Step 4: Checklist Items</span>
        </div>
        <div className="wizard-page-section-content">
          <p>
            Project Check-lists allow us to make sure we don’t miss any information throughout the project lifecycle.
            In addition to uploading the PO to Portal, you are required to save a copy of the PO in the Project Drive.
          </p>
          <p>
            {/* A Project Drive is a mandatory requirement for project activation */}
            {!hasProjectDrive && (
              <span className="text-danger">
                Oops! It looks like a project drive does not exist for this project.
                Please close the wizard and create a Project Drive before attempting to activate the project.
              </span>
            )}
            {hasProjectDrive && projectRecord?.ms_group_drive_item_url && (
              <a href={projectRecord.ms_group_drive_item_url} target="_blank" rel="noopener noreferrer">
                <span>Click here to open the Project Drive Folder</span>
                <Icon i={ICON.EXTERNAL_LINK} />
              </a>
            )}
          </p>
          <div className="wizard-page-section-heading">
            <span>Project Activation</span>
          </div>
          <div className="wizard-page-section-content">
            {/* PO Saved in Project Drive */}
            <CheckBox
              id="po_saved_in_project_drive"
              name="po_saved_in_project_drive"
              label="PO Saved in Project Drive*"
              disabled={!hasProjectDrive}
              inline
              value={pageData.po_saved_in_project_drive}
              onChange={onPageDataFieldChanged}
              hasError={getFieldPageDataErrors(pageDataErrors, 'po_saved_in_project_drive') !== false}
            />
            <WizardFormErrorRow fieldName="po_saved_in_project_drive" pageDataErrors={pageDataErrors} />
          </div>
          <div className="wizard-page-section-heading">
            <span>As-Sold Details</span>
          </div>
          <div className="wizard-page-section-content">
            {/* As Sold Proposal Uploaded */}
            <CheckBox
              id="as_sold_proposal_saved"
              name="as_sold_proposal_saved"
              label="As Sold Proposal Uploaded"
              disabled={!hasProjectDrive}
              inline
              value={pageData.as_sold_proposal_saved}
              onChange={onPageDataFieldChanged}
            />

            {/* PO Value Matches AS Sold Proposal */}
            <CheckBox
              id="po_value_matches_as_sold"
              name="po_value_matches_as_sold"
              label="PO Value Matches AS Sold Proposal"
              inline
              value={pageData.po_value_matches_as_sold}
              onChange={onPageDataFieldChanged}
            />

            {/* Financial Summaries Exported */}
            <CheckBox
              id="finance_reports_created"
              name="finance_reports_created"
              label="Financial Summaries Exported"
              inline
              value={pageData.finance_reports_created}
              onChange={onPageDataFieldChanged}
            />
          </div>
        </div>
      </div>
    </ModalWizardPage>
  );
};
