import React from 'react';
import { APIPolyForm } from '../poly-forms/api-poly-form';
import { mapTableToForm } from '../../helpers/map-table-to-form.helper';
import { WidgetProps } from '../../types/widget.props';
import { FORM_RENDERER_TYPE } from '../../constants/form-renderer-type.const';

export type RowDetailsWidgetProps = WidgetProps;

/**
 * Basic form for Data Table row detail editing
 */
export const RowDetailsWidget: React.FC<RowDetailsWidgetProps> = ({
  rowData,
  rowDataChecksum,
  columns,
  baseRoute,
  permittedActions,
  itemCaption,
  formCaption,
  onStartEdit,
  onEndEdit,
  onDelete,
  onDirtyChange,
  navigateBackOnDelete,
  isEditing,
  isReadOnly,
  rowPrimaryKeyFieldName = 'id',
}) => {
  const detailsFields = mapTableToForm({ columns });

  return (
    <div className="widget row-details-widget">
      <APIPolyForm
        formRendererType={FORM_RENDERER_TYPE.VERTICAL}

        baseRoute={baseRoute ?? ''}

        navigateBackOnDelete={navigateBackOnDelete}
        formCaption={formCaption ?? `${itemCaption} Details`.trim()}
        itemCaption={itemCaption}

        showHeader
        hideBottomButtons

        fields={detailsFields}

        primaryKeyFieldName={rowPrimaryKeyFieldName}
        formData={rowData}
        formDataChecksum={rowDataChecksum}

        isReadOnly={isReadOnly}
        isEditing={isEditing}

        permittedActions={permittedActions}

        onStartEditRecord={onStartEdit ? () => onStartEdit() : undefined}
        onEndEditRecord={onEndEdit}
        onDeleteRecord={onDelete ? (id, formData, redirectInstruction) => onDelete(id, redirectInstruction) : undefined}
        onFieldChange={onDirtyChange ? () => onDirtyChange(isEditing) : undefined}
      />
    </div>
  );
};
