import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Row, Col, Card, CardBody, CardHeader, Container, Badge } from 'reactstrap';
import ProgressBar from '../layout-helpers/progress-bar';
import FriendlyFormMessage from '../layout-helpers/friendly-form-message';
import Icon from '../layout-helpers/icon';
import { startCase, documentTitle } from '../../utils/helpers';
import { connectToAPIProvider } from '../providers/api-provider';
import API_PROVIDER_PROP_TYPES from '../../prop-types/api-provider-prop-types';
import { apiAborter } from '../../helpers/api-aborter.helper';

class RolesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: null,
      // role has many permissions
      permissions: null,
      roles: null,
    };

    this.abortController = null;
  }


  /**
   * @inheritdoc
   */
  componentDidMount = () => {
    const { title } = this.props;
    documentTitle(title);
    this.loadData();
  };


  /**
   * @inheritdoc
   */
  componentWillUnmount = () => {
    if (this.abortController) {
      this.abortController.abort();
    }
  };


  /**
   * @description
   * Get roles and permissions and stick em in this.state
   */
  loadData = () => {
    if (this.abortController) {
      this.abortController.abort();
    }

    this.setState({
      loaded: false,
    }, () => {
      this.abortController = apiAborter();

      const { apiProvider: { apiFetch } } = this.props;

      // Load the Roles data
      const rolesFetchRequest = apiFetch(
        '/role?with[]=permissions',
        {
          name: 'RolesView::roles',
          signal: this.abortController.signal,
        },
      );

      // Load the Permissions data
      const permissionsFetchRequest = apiFetch(
        '/permission?with[]=roles:name',
        {
          name: 'RolesView::permissions',
          signal: this.abortController.signal,
        },
      );

      Promise.all([rolesFetchRequest, permissionsFetchRequest]).then(([rolesResponse, permissionsResponse]) => {
        if (rolesResponse.success && permissionsResponse.success) {
          this.abortController = null;
          this.setState({
            permissions: permissionsResponse.body.data,
            roles: rolesResponse.body.data,
            loaded: true,
          });
        } else if (!rolesResponse.aborted && !permissionsResponse.aborted) {
          this.abortController = null;
          if (rolesResponse.error) console.error('RolesView::roles', rolesResponse.error);
          if (permissionsResponse.error) console.error('RolesView::permissions', permissionsResponse.error);
          this.setState({
            error: rolesResponse.error ?? permissionsResponse.error,
            roles: null,
            permissions: null,
            loaded: true,
          });
        }
      });
    });
  };


  /**
   *
   */
  table = () => {
    const { roles, permissions } = this.state;
    const columnHeadings = [
      <th className="text-right" key="permission-role-th">
        <strong>
          Permissions
          {' '}
          <Icon i="arrow-down" />
          {' '}
          &nbsp;&nbsp; Roles
          {' '}
          <Icon i="arrow-right" />
        </strong>
      </th>,
    ];

    // other rows
    const rows = [];

    // top header row
    Object.keys(roles).forEach((i) => {
      columnHeadings.push(
        <th className="text-center" title={roles[i].description} key={`role-th-${roles[i].name}`}>
          <code>{roles[i].name}</code>
        </th>,
      );
    });

    // the rows
    Object.keys(permissions).forEach((p) => {
      const permission = permissions[p];
      const permissionName = permission.name;
      const cols = [
        <th
          scope="row"
          key={`role-row-${permissionName}`}
          className="text-right"
          title={permission._description}
        >
          <code>{permissionName}</code>
        </th>,
      ];
      // CHeck each role against this permission row
      Object.keys(roles).forEach((i) => {
        const role = roles[i];
        const rolePermissions = role.permissions;
        let does = false;
        // left header first column item in row
        // Each permission inside each role
        for (let k = 0; k < rolePermissions.length; k += 1) {
          const rPerm = rolePermissions[k];
          if (rPerm.name === permission.name) {
            cols.push(
              <td
                title={`'${role.name}' HAS '${permissionName}'`}
                key={`cell-${role.name}-${permissionName}`}
                className="table-primary"
              >
                <Badge color="primary">
                  <Icon i="check" size="lg" />
                </Badge>
              </td>,
            );
            does = true;
            break;
          }
        }
        if (!does) {
          cols.push(
            <td
              title={`'${role.name}' DOES NOT HAVE '${permissionName}'`}
              key={`cell-${role.name}-${permissionName}`}
            >
              <Badge color="secondary">
                <Icon i="minus" />
              </Badge>
            </td>,
          );
        }
      });
      rows.push(<tr key={`tr-${p}`}>{cols}</tr>);
    });

    return (
      <>
        <thead>
          <tr>{columnHeadings}</tr>
        </thead>
        <tbody className="text-center">{rows}</tbody>
      </>
    );
  };

  rolePermissions = () => {
    const { roles } = this.state;
    const elements = [];
    Object.keys(roles).forEach((i) => {
      const role = roles[i];
      const rolePermissions = role.permissions;
      const permissionElements = [];
      for (let k = 0; k < rolePermissions.length; k += 1) {
        const thisPermission = rolePermissions[k];
        permissionElements.push(
          <li key={`rolePermission-li-${thisPermission.name}`}>
            <strong>{thisPermission._readable_name}</strong>
            <span>
              {` : ${thisPermission._description}`}
            </span>
          </li>,
        );
      }
      elements.push(
        <div key={`rolePermission-${role.name}`}>
          <h2>{startCase(role.name)}</h2>
          <p>
            <code>{role.name}</code>
            &nbsp;
            {role.description}
            <br />
            Has permissions:
          </p>
          <ul>{permissionElements}</ul>
        </div>,
      );
    });

    return elements;
  };

  permissionRoles = () => {
    const { permissions } = this.state;
    const elements = [];
    Object.keys(permissions).forEach((i) => {
      const permission = permissions[i];
      const permissionRoles = permission.roles;
      const roleElements = [];
      for (let k = 0; k < permissionRoles.length; k += 1) {
        roleElements.push(
          <li key={`permissionRoles-li-${permissionRoles[k].name}`}>
            <strong>{startCase(permissionRoles[k].name)}</strong>
            {' '}
            <small className="d-none">
              (
              <code>{permissionRoles[k].name}</code>
              )
            </small>
            :
            {' '}
            {permissionRoles[k].description}
          </li>,
        );
      }
      elements.push(
        <div key={`permissionRoles-${permission.name}`}>
          <h2>
            {permission._readable_name}
            {' '}
            <small className="d-none">
              (
              <code>{permission.name}</code>
              )
            </small>
          </h2>
          <p>
            <code>{permission.name}</code>
            &nbsp;
            {permission._description}
            <br />
            Belongs to:
          </p>
          <ul>{roleElements}</ul>
        </div>,
      );
    });

    return elements;
  };


  /**
   * @inheritdoc
   */
  render() {
    // Render
    const { loaded, error } = this.state;
    return (
      <Container fluid>
        <Row className="page-titles">
          <Col>
            <h2>Roles and Permissions</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <ProgressBar complete={loaded} />
            <FriendlyFormMessage
              formMessage={error}
              alertColor="danger"
              showList
              isOpen={!!error}
              useSimpleDefault
            />
            <Card>
              <CardHeader>User Roles and Permissions Matrix</CardHeader>
              <CardBody>
                {loaded && (
                  <Table striped responsive hover bordered size="sm">
                    {this.table()}
                  </Table>
                )}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>Permissions by Role</CardHeader>
              <CardBody>{loaded && <div>{this.rolePermissions()}</div>}</CardBody>
            </Card>
            <Card>
              <CardHeader>Roles by Permission</CardHeader>
              <CardBody>{loaded && <div>{this.permissionRoles()}</div>}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

RolesView.propTypes = {
  title: PropTypes.string,
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
};

RolesView.defaultProps = {
  title: 'View Roles',
};

export default connectToAPIProvider(RolesView);
