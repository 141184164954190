import React from 'react';
import {
  ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { PropTypes } from 'prop-types';
import Icon from '../layout-helpers/icon';
import { ReactPortal } from '../react-portal/react-portal';
import SORT_DIRECTION from '../../utils/sort-directions';

/**
 * @class SortByDropdown
 *
 * @description
 * Renders out a single button which contains a list of selected "sort-by" options
 */
class SortByDropdown extends React.Component {
  /**
   * @constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      isDropDownOpen: false,
    };
  }


  /**
   * @description
   * Toggle whether the dropdown version of the button list is visible or not
   *
   * @param {React.SyntheticEvent} e
   */
  handleToggleDropDownOpen = (e) => {
    if (!e) return;

    const preventToggle = e.target.getAttribute('data-prevent-close-dropdown');
    const { isDropDownOpen } = this.state;

    const newDropDownOpen = !isDropDownOpen;
    // DON'T "close" the dropdown if the target was one of the options being clicked.
    // DO close the dropdown as part of the select option process
    if (!preventToggle) {
      this.setState({
        isDropDownOpen: newDropDownOpen,
      });
    }
  }


  /**
   * @description
   * Fired when one of the sort options is clicked
   *
   * @param {React.SyntheticEvent} e the onClick event
   * @param {object} option the clicked option
   */
  handleOptionClick = (e, option) => {
    e.stopPropagation();

    const { onChange } = this.props;

    if (typeof onChange === 'function') {
      onChange(option);
    }

    // Manually close the dropdown
    this.setState({
      isDropDownOpen: false,
    });
  }


  /**
   * @inheritdoc
   */
  render = () => {
    const { sortOptions, activeSortOption, defaultSortOption } = this.props;
    const { isDropDownOpen } = this.state;
    let { buttonColor } = this.props;

    const icon = activeSortOption ? activeSortOption.icon : 'sort-amount-asc';
    buttonColor = activeSortOption && defaultSortOption && (activeSortOption === defaultSortOption) ? buttonColor : 'success';

    return (
      <ButtonDropdown
        className="sort-by-dropdown"
        isOpen={isDropDownOpen}
        toggle={this.handleToggleDropDownOpen}
      >
        <DropdownToggle color={buttonColor}>
          <Icon i={icon} />
        </DropdownToggle>
        <ReactPortal>
          <DropdownMenu right modifiers={{ preventOverflow: { boundariesElement: 'window' } }} tag="ul">
            {sortOptions.map((option) => (
              <DropdownItem
                className="sort-by-dropdown-item"
                key={option.id}
                active={activeSortOption && option.id === activeSortOption.id}
                onClick={(e) => {
                  this.handleOptionClick(e, option);
                }}
                data-prevent-close-dropdown
                tag="li"
              >
                <Icon i={option.icon} />
                <span>{option.name}</span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ReactPortal>
      </ButtonDropdown>
    );
  }
}

SortByDropdown.propTypes = {
  buttonColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  sortOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(Object.values(SORT_DIRECTION)).isRequired,
    icon: PropTypes.string.isRequired,
  })).isRequired,
  activeSortOption: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(Object.values(SORT_DIRECTION)).isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  defaultSortOption: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(Object.values(SORT_DIRECTION)).isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
};

SortByDropdown.defaultProps = {
  buttonColor: 'primary',
};

export default SortByDropdown;
