/**
 * This file contains constants and statuses / types that do not change much
 * However these SHOULD be updated alongside the backend to keep consistency
 * TODO create a node script / tests that reads in the laravel API endpoints for these and flags
 *      inconsistencies.
 *
 * RULES FOR CAPITALIZATION OF CONSTANTS - DEFINED in other languages:
 * - Single values
 * - Arrays of single values
 * - If it has a 's' at the end, assume it is an array
 * - Use camelCase for objects, arrays of objects and functions,
 *   and anything that uses values from elsewhere, which may or may not also be constants
 */

import { assertDefined } from '../helpers/assert-defined.helper';

/**
 * For render functions with long string
 */
export const RENDER_TEXT_MAX_LEN = 50;

/**
 * Starting per-page rows in data tables
 */
export const PAGE_SIZE_DEFAULT = 15;

/**
 * For user avatars and Ci staff profile pictures
 */
export const PROFILE_IMG_BASE = process.env.PROFILE_IMG_BASE || 'https://profiles.ciportal.net';

/**
 * Check for whether debug mode is enabled in env
 */
export const DEBUG = process.env.REACT_APP_DEBUG === 'true';

/**
 * Establish intended hosting environment
 */
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'development';

/**
 * Check for whether debug mode is enabled in env
 */
export const BUILD_VER = process.env.REACT_APP_VERSION || '2';

/**
 * Color selection for Top Bar
 */
export const TOP_BAR_START_COLOR = process.env.REACT_APP_TOP_BAR_START_COLOR ?? '#005a82';
export const TOP_BAR_END_COLOR = process.env.REACT_APP_TOP_BAR_END_COLOR ?? '#0078ad';

/**
 * Version and URL for API
 */
export const API_URL = assertDefined(process.env.REACT_APP_API);
export const API_VER = assertDefined(process.env.REACT_APP_API_VERSION);
export const API_CLIENT_ID = Number(assertDefined(process.env.REACT_APP_API_CLIENT_ID));
export const API_CLIENT_SECRET = assertDefined(process.env.REACT_APP_API_CLIENT_SECRET);

/**
 * Ability to set notification and sidebar poll delay
 */
const seconds = 1000;
const minutes = seconds * 60;
export const SIDEBAR_DELAY = Number(process.env.REACT_APP_SIDEBAR_DELAY) || 0.5 * seconds;
export const WIDGET_RELOAD_DELAY =
  Number(process.env.REACT_APP_WIDGET_RELOAD_DELAY) || 30 * seconds;

/**
 * User Data Polling
 */
export const POLL_NOTIFICATIONS_INTERVAL = Number(process.env.REACT_APP_POLL_NOTIFICATIONS_INTERVAL) || (1 * minutes);
export const POLL_PERMISSIONS_INTERVAL = Number(process.env.REACT_APP_POLL_PERMISSIONS_INTERVAL) || (60 * minutes);
export const POLL_ALERTS_INTERVAL = Number(process.env.REACT_APP_POLL_ALERTS_INTERVAL) || (5 * minutes);

/**
 * Tiny MCE key
 */
export const TINYMCE_KEY = process.env.REACT_APP_TINYMCE_KEY || '_REACT_APP_TINYMCE_KEY_Not_Set_';

/**
 * Google Maps Key
 */
export const GMAPS_ENABLED = process.env.REACT_APP_GMAPS_ENABLED.toString().toLowerCase() === 'true';
export const GMAPS_KEY = process.env.REACT_APP_GMAPS_KEY || '_REACT_APP_GMAPS_KEY_Not_Set';

/**
 * Is it DEV or PROD?
 * @returns {string} either `'prod'` or `'dev'`.
 */
export const APP_ENV =
  !process.env.NODE_ENV || process.env.NODE_ENV !== 'development' ? 'prod' : 'dev';

/**
 * Debounce for each character while typing in a auto-request field (search etc.)
 */
export const TIMEOUT_TYPING_DEBOUNCE = 350;

/**
 * First part of the document title
 */
export const DOCUMENT_MAIN_TITLE = 'Ci Portal';

// For charts
// TODO this could be done with moment.js.
export const MONTHS_SHORT = [
  null,
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

// TODO this could be done with moment.js.
export const MONTHS_LONG = [
  null,
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// TODO this could be done with moment.js.
export const monthsSelect = [
  { value: 1, label: '01', shortName: 'Jan' },
  { value: 2, label: '02', shortName: 'Feb' },
  { value: 3, label: '03', shortName: 'Mar' },
  { value: 4, label: '04', shortName: 'Apr' },
  { value: 5, label: '05', shortName: 'May' },
  { value: 6, label: '06', shortName: 'Jun' },
  { value: 7, label: '07', shortName: 'Jul' },
  { value: 8, label: '08', shortName: 'Aug' },
  { value: 9, label: '09', shortName: 'Sep' },
  { value: 10, label: '10', shortName: 'Oct' },
  { value: 11, label: '11', shortName: 'Nov' },
  { value: 12, label: '12', shortName: 'Dec' },
];

/**
 * Array of capital-letter alphabet chars
 * @returns {array} array of strings of alphabetical letters
 * @example
 * // > [ 'A', 'B', ... ]
 */
export const alphabet = () => {
  const alphabetArray = [];
  for (let i = 65; i < 91; i += 1) {
    const str = String.fromCharCode(i);
    alphabetArray.push(str);
  }
  return alphabetArray;
};

/**
 * Returns select options from and to a value
 * @param {int} from
 * @param {int} to
 * @returns {array} array of objects of { id: number, name: string }
 */
export const mapNumRangeSelect = (from, to) => {
  const y = [];
  for (let i = from; i <= to; i += 1) {
    let name = i.toString();
    if (name.length === 1) name = `0${name}`;
    y.push({ id: i, name });
  }
  return y;
};

/**
 * Maps an object's TEXT VALUE into value/label object, but only if the
 * value is a string. The rest are ignored.
 *
 * @param {object} obj object
 *
 * @example
 * mapTextSelect({ 1: 'Yag', 3: 'Foo', yag: 2, foo: 1 });
 * // > [
 * //     { id: 'Yag', name: 'Yag' },
 * //     { id: 'Foo', name: 'Foo' }
 * //   ]
 *
 * @returns {array} Array of { id: 'value' name: 'value' } objects
 */
export const mapTextSelect = (obj) => {
  const items = [];
  Object.keys(obj).forEach((k) => {
    const val = obj[k];
    if (typeof val === 'string') {
      items.push({ id: val, name: val });
    }
  });
  return items;
};

/**
 * Maps each item in an array into a value/label object
 *
 * @param {array} array List of strings
 * @example
 * mapArraySelect([ 'Yag', 'Foo' ]);
 * // > [
 * //     { id: 'Yag', name: 'Yag' },
 * //     { id: 'Foo', name: 'Foo' }
 * //   ]
 * @returns {array} Array of { id: 'value' name: 'value' } objects
 */
export const mapArraySelect = (array) => {
  const items = [];
  array.forEach((item) => {
    items.push({ id: item, name: item });
  });
  return items;
};

/**
 * @deprecated try to use the typed mapSelect helper for project constants instead
 * Maps an object's KEY AND VALUE into value/label object, but only if the
 * value is a string. The rest are ignored.
 * @example
 * mapSelect({ 1: 'Yag', 3: 'Foo', yag: 2, foo: 1 });
 * // > [
 * //     { id: 1, name: 'Yag' },
 * //     { id: 3, name: 'Foo' }
 * //   ]
 * @param {object} obj object
 * @returns {array} Array of { id: key name: 'value' } objects
 */
export const mapSelect = (obj, intId = false) => {
  const items = [];
  Object.keys(obj).forEach((key) => {
    const val = obj[key];
    if (typeof val === 'string') {
      items.push({
        id: intId ? parseInt(key, 10) : key,
        name: val,
        // className: `option-${key}`,
      });
    }
  });
  return items;
};

// Generate lists of months, days, and years
// TODO this could be done with moment.js as months don't always have 31 days.
const d = new Date();
const currYear = d.getFullYear();
export const projectYearOptions = mapNumRangeSelect(currYear - 10, currYear);
export const birthYearOptions = mapNumRangeSelect(currYear - 100, currYear - 10);
export const monthDayOptions = mapNumRangeSelect(1, 31);

// forecastInvoicedTable (aka Active Projects Report) "Year" options
// ranges a few years on either side of the current year
// @deprecated
export const forecastInvoicedTableYearOptions = mapNumRangeSelect(currYear - 2, currYear + 3);
export const projectSummaryYearOptions = mapNumRangeSelect(currYear - 2, currYear + 3);

// Certain words such as initials or acronyms should be all uppercase
// Eg. uploadToAws => Upload to AWS
export const CHANGE_UPPERCASE = [
  // Real world
  'Id',
  'Tv',
  'Dvd',
  'Cd',
  'Hr',

  // Developer jargon
  'Pdf',
  'Png',
  'Jpg',
  'Mp4',
  'Aws',
  'Uat',
  'Cdn',
  'Crm',
  'Api',

  // States / territories
  'Tas',
  'Wa',
  'Nsw',
  'Qld',
  'Vic',
  'Sa',
  'Nt',
  // Act is an actual word. ACTual.
  // 'Act',

  // Industry jargon
  'Eoi',
  'Tbs',
  'Dx',
  'Av',
  // Ci seems to not be an acronym?
  // 'Ci',
];

// Certain minor words should be left lowercase unless
//   they are the first or last words in the string
export const CHANGE_LOWERCASE = [
  'A',
  'An',
  'The',
  'And',
  'But',
  'Or',
  'For',
  'Nor',
  'As',
  'At',
  'By',
  'For',
  'From',
  'In',
  'Into',
  'Near',
  'Of',
  'On',
  'Onto',
  'To',
  'With',
];


/**
 * @description
 * Portal Upload Status manages the status of a single file as it progresses
 * through the portal upload process
 * @see https://wiki.ciportal.net/books/ci-portal-developer-documentation/page/cloud-storage
 */
export const PORTAL_UPLOAD_STATUS = {
  INITIALISING: 'Initialising Upload', // 0% -> 5%
  REQUESTING: 'Requesting Upload', // 5% -> 10%
  AUTHORISED: 'Authorised', // 10%
  UPLOADING: 'Uploading File', // 10% -> 90%
  UPLOADED: 'File Uploaded', // 90%
  CONFIRMING: 'Confirming Upload', // 90% -> 100%
  COMPLETE: 'Upload Complete', // 100%
  ABORTED: 'Upload Aborted',
  DENIED: 'Upload Request Denied',
  FAILED: 'Upload Failed',
};

/**
 * @description
 * Returns true if the given PORTAL_UPLOAD_STATUS is considered "in progress"
 *
 * @param {PORTAL_UPLOAD_STATUS} uploadStatus
 *
 * @returns {boolean}
 */
export const isUploadInProgress = (uploadStatus) => [
  PORTAL_UPLOAD_STATUS.INITIALISING,
  PORTAL_UPLOAD_STATUS.REQUESTING,
  PORTAL_UPLOAD_STATUS.AUTHORISED,
  PORTAL_UPLOAD_STATUS.UPLOADING,
  PORTAL_UPLOAD_STATUS.UPLOADED,
  PORTAL_UPLOAD_STATUS.CONFIRMING,
].includes(uploadStatus);


/**
 * @description
 * Returns true if the given PORTAL_UPLOAD_STATUS is considered an "error"
 *
 * @param {PORTAL_UPLOAD_STATUS} uploadStatus
 *
 * @returns {boolean}
 */
export const isUploadError = (uploadStatus) => [
  PORTAL_UPLOAD_STATUS.ABORTED,
  PORTAL_UPLOAD_STATUS.DENIED,
  PORTAL_UPLOAD_STATUS.FAILED,
].includes(uploadStatus);


/**
 * @description
 * Use this in progress bars to incorporate the various stages of upload request
 * into the progress bar progression. Use in conjunction with uploadPercentageAllocation
 *
 * @param {PORTAL_UPLOAD_STATUS} uploadStatus
 *
 * @returns {number}
 */
export const baseUploadPercentage = (uploadStatus) => {
  switch (uploadStatus) {
    case PORTAL_UPLOAD_STATUS.INITIALISING: return 0;
    case PORTAL_UPLOAD_STATUS.REQUESTING: return 5;
    case PORTAL_UPLOAD_STATUS.AUTHORISED: return 5;
    case PORTAL_UPLOAD_STATUS.UPLOADING: return 10;
    case PORTAL_UPLOAD_STATUS.UPLOADED: return 90;
    case PORTAL_UPLOAD_STATUS.CONFIRMING: return 90;
    default: return 100;
  }
};


/**
 * @description
 * Use this in progress bars to incorporate the various stages of upload request
 * into the progress bar progression. Use in conjunction with baseUploadPercentage
 *
 * @param {PORTAL_UPLOAD_STATUS} uploadStatus
 * @param {number} progress an optional value between 0 and 100 to scale
 *
 * @returns {number}
 */
export const uploadPercentageAllocation = (uploadStatus, progress) => {
  let allocation = 0;

  switch (uploadStatus) {
    case PORTAL_UPLOAD_STATUS.INITIALISING:
      allocation = 5;
      break;

    case PORTAL_UPLOAD_STATUS.REQUESTING:
      allocation = 5;
      break;

    case PORTAL_UPLOAD_STATUS.UPLOADING:
      allocation = 80;
      break;

    case PORTAL_UPLOAD_STATUS.CONFIRMING:
      allocation = 10;
      break;

    default:
      break;
  }

  return progress !== null ? Math.round((progress / 100) * allocation) : allocation;
};


/**
 * @description
 * Used to map binary 0/1 active status to a status descriptions
 */
export const activeInactiveOptions = [
  { id: 0, name: 'Inactive' },
  { id: 1, name: 'Active' },
];


/**
 * @description
 * Used to map a project design type primary key (id) to a value to show the user (name)
 */
export const projectDesignTypeOptions = [
  { id: 0, name: 'Internal design and construct' },
  { id: 1, name: 'External design' },
];

/**
 * @description
 * Used to map a project design type primary key (id) to a value to show the user (name)
 */
export const projectFreightTypeOptions = [
  { id: 1, name: 'Ci DC' },
  { id: 2, name: 'Ci Third Party' },
  { id: 3, name: 'Supplier Direct' },
];

/**
 * Project Phases
 * @deprecated try to use 'PROJECT_PHASE` from `project-phase.const` instead
 * Endpoint: `/project/phase`
 * @example
 * projectPhases[13];
 * // > 'Rough-in & Prewire'
 * projectPhases.roughInPrewire;
 * // > 13
 */
export const projectPhases = {
  1: 'Prospecting',
  2: 'Qualifying',
  3: 'EOI',
  4: 'Proposal Development',
  5: 'Peer Review',
  6: 'Proposal Submitted',
  7: 'Negotiation',
  8: 'Not Started',
  9: 'Site Assessment',
  10: 'Engineering',
  11: 'Procurement',
  12: 'Precommissioning',
  13: 'Rough-in & Prewire',
  14: 'Support Structures',
  15: 'Equipment Install',
  16: 'Commissioning & Testing',
  17: 'UAT',
  18: 'Hand Over & Signoff',
  prospecting: 1,
  qualifying: 2,
  eoi: 3,
  proposalDevelopment: 4,
  peerReview: 5,
  proposalSubmitted: 6,
  negotiation: 7,
  notStarted: 8,
  siteAssessment: 9,
  engineering: 10,
  procurement: 11,
  precommissioning: 12,
  roughInPrewire: 13,
  supportStructures: 14,
  equipmentInstall: 15,
  commissioningTesting: 16,
  uat: 17,
  handOverSignoff: 18,
};
export const projectPhaseOptions = mapSelect(projectPhases);


/**
 * @description
 * Used to map a resources primary key (id) to a value to show the user (name)
 */
export const yesNoOptions = [
  { id: 0, name: 'No' },
  { id: 1, name: 'Yes' },
];


/**
 * Lead sources
 * @example
 * leadSources[8];
 * // => 'Supplier'
 * leadSources.supplier;
 * // => 8
 */
export const leadSources = {
  1: 'Existing Client',
  2: 'Personal Contact',
  3: 'Property Daily',
  4: 'BidContender',
  5: 'EstimateOne',
  6: 'Tenderlink',
  7: 'Ci Website',
  8: 'Supplier',
  9: 'Consultant',
  10: 'Builder',
  11: 'Marketing',
  12: 'Other Sources',
  13: 'Direct Invitation',
  14: 'Architect',
  15: 'Property Advisor',
  16: 'Interior Designer',
  17: 'Project Manager',
  18: 'Fitout Contractor',
  19: 'Developer',
  20: 'Quantity Surveyor',
  existingClient: 1,
  personalContact: 2,
  propertyDaily: 3,
  bidContender: 4,
  estimateOne: 5,
  tenderlink: 6,
  ciWebsite: 7,
  supplier: 8,
  consultant: 9,
  builder: 10,
  marketing: 11,
  otherSources: 12,
  directInvitation: 13,
  architect: 14,
  propertyAdvisor: 15,
  interiorDesigner: 16,
  projectManager: 17,
  fitoutContractor: 18,
  developer: 19,
  quantitySurveyor: 20,
};
export const leadSourceOptions = mapSelect(leadSources, true)
  .sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1));

/**
 * Project Contact types
 */
export const contactTypes = {
  1: 'Primary Contact',
  2: 'Architect',
  3: 'AV Consultant',
  4: 'Billing Agent',
  5: 'Builder',
  6: 'Competitor',
  7: 'Client Contact',
  8: 'Developer',
  9: 'Electrician',
  10: 'External Project Manager',
  11: 'Property Agent',
  12: 'Services Consultant',
  primaryContact: 1,
  architect: 2,
  aVConsultant: 3,
  billingAgent: 4,
  builder: 5,
  competitor: 6,
  clientContact: 7,
  developer: 8,
  electrician: 9,
  externalProjectManager: 10,
  propertyAgent: 11,
  servicesConsultant: 12,
};
export const contactTypeOptions = mapSelect(contactTypes);


/**
 * Map of the alertCounts in the user summary data
 */
export const userSummaryBadgeMap = {
  myLeave: 'alertCounts.leave.personal',
  manageLeave: 'alertCounts.leave.manage',
  processLeave: 'alertCounts.leave.process',

  myExpenses: 'alertCounts.expenseClaims.personal',
  manageExpenses: 'alertCounts.expenseClaims.manage',
  processExpenses: 'alertCounts.expenseClaims.manage',
  processExpenseClaimBatches: 'alertCounts.expenseClaimBatches.process',

  myAllowances: 'alertCounts.allowances.personal',
  manageAllowances: 'alertCounts.allowances.manage',
  processAllowances: 'alertCounts.allowances.process',

  submittedInvoiceRequests: 'alertCounts.invoiceRequests.submitted',
  draftInvoiceRequests: 'alertCounts.invoiceRequests.draft',
  rejectedInvoiceRequests: 'alertCounts.invoiceRequests.rejected',
};


const PUSH = Symbol('PUSH');
const REPLACE = Symbol('REPLACE');

/**
 * @type {ReadOnly<{ REPLACE: symbol, PUSH: symbol }>} PUSH_OR_REPLACE
 */
export const PUSH_OR_REPLACE = {
  REPLACE,
  PUSH,
};


/**
 * @description
 * Which side of the button row should a button be aligned
 */
export const MODAL_BUTTON_SIDE_TYPE = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};
