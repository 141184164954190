import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Spinner } from 'reactstrap';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import moment from 'moment';
import { APIContext } from '../../providers/api-provider';
import { CurrentUserContext } from '../../providers/current-user-provider';
import { formatValueString } from '../../render-functions';
import { apiAborter } from '../../../helpers/api-aborter.helper';

interface IPortalQuickBooksInvoiceRecord {
  id: string,
  transaction_date: string,
  due_date: string,
  balance: string,
  document_number: string,
  amount: string,
  portal_invoice: {
    id: number,
    invoice_number: string,
    amount: number,
    type_id: number,
  },
}

interface IInvoiceSaveResult {
  working: boolean,
  error: boolean,
  body: Record<string, unknown>,
}

interface IProjectQuickBooksInvoiceLineProps {
  baseRoute: string,
  invoice: IPortalQuickBooksInvoiceRecord,
  // rowData: {
  //   id: number,
  //   name: string,
  //   quickbooks_subcustomer_id: string
  // },
  showBalance: boolean
}


const ProjectQuickBooksInvoiceLine: React.FC<IProjectQuickBooksInvoiceLineProps> = ({ baseRoute, invoice, showBalance }) => {
  const { userHasPermissions } = useContext(CurrentUserContext);
  const { userDetails } = useContext(CurrentUserContext);
  const [invoiceTypeId, setInvoiceTypeId] = useState<number>(2);
  const [localInvoiceData, setLocalInvoiceData] = useState<IPortalQuickBooksInvoiceRecord>(invoice);
  const [invoiceSaveResult, setInvoiceSaveResult] = useState<IInvoiceSaveResult>({
    working: false,
    error: false,
    body: {},
  });
  const { apiFetch } = useContext(APIContext);

  const loadInvoiceListAbortController = useRef<AbortController | null>(null);
  const addPortalInvoiceAbortController = useRef<AbortController | null>(null);

  const addPortalInvoice = useCallback(async () => {
    if (addPortalInvoiceAbortController.current) {
      addPortalInvoiceAbortController.current.abort();
    }
    addPortalInvoiceAbortController.current = apiAborter();

    setInvoiceSaveResult({
      ...invoiceSaveResult,
      working: true,
    });

    const response = await apiFetch(
      `${baseRoute}/invoice`,
      {
        method: HTTP_METHOD.POST,
        name: 'BillAndPurchaseOrderUpdatePanelOrderList:load',
        signal: addPortalInvoiceAbortController.current.signal,
        body: {
          type_id: invoiceTypeId,
          amount: invoice.amount,
          invoice_number: invoice.document_number,
          quickbooks_transaction_id: invoice.id,
          sent_date: invoice.transaction_date,
          comment: `Imported from QuickBooks by ${userDetails.name} at ${moment().format('DD/MM/YYYY')}`,
        },
      },
    );

    if (response.success) {
      addPortalInvoiceAbortController.current = null;
      const newInvoiceData = response.body.data;
      setLocalInvoiceData({
        ...invoice,
        portal_invoice: newInvoiceData,
      });
      setInvoiceSaveResult({
        working: false,
        error: false,
        body: response.body,
      });
    } else if (!response.aborted) {
      addPortalInvoiceAbortController.current = null;
      setInvoiceSaveResult({
        working: false,
        error: true,
        body: response.body,
      });
    }
  }, [invoiceSaveResult, apiFetch, baseRoute, invoiceTypeId, invoice, userDetails.name]);

  /**
   * On component mount load Invoice Lists
   */
  useEffect(() => {
    const aborter = loadInvoiceListAbortController;

    return () => {
      // Kill any outstanding load when the component is un-mounted
      if (aborter.current) {
        aborter.current.abort();
      }
    };
  }, [loadInvoiceListAbortController, userHasPermissions]);

  const getTypeName = (typeId: number) => {
    const matches = [].filter((type: { id: string, name: string }) => parseInt(type.id, 10) === typeId);
    const match = matches[0];
    return match; // match ? match.name : 'no type';
  };

  const changeInvoiceType = (event: { target: { value: string } }) => {
    const newValue = parseInt(event.target.value, 10);
    setInvoiceTypeId(newValue);
  };

  return (
    <>
      <tr key={localInvoiceData.id}>
        <td>{localInvoiceData.id}</td>
        <td>{localInvoiceData.document_number}</td>
        <td>{localInvoiceData.transaction_date}</td>
        <td>{localInvoiceData.due_date}</td>
        <td className="text-right">{formatValueString(localInvoiceData.amount, 'currency')}</td>
        {showBalance && (
          <>
            <td className="text-right">{formatValueString(localInvoiceData.balance, 'currency')}</td>
            {!localInvoiceData.portal_invoice ? (
              <>
                <td>
                  <select className="form-control form-control-sm" value={invoiceTypeId} onChange={changeInvoiceType}>
                    {[].map((option: { id: number, name: string }) => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                  </select>
                </td>
                <td>
                  {invoiceSaveResult.working ? (
                    <Spinner size="sm" color="white" />
                  ) : (
                    <Button
                      size="sm"
                      disabled={invoiceSaveResult.working}
                      color={invoiceSaveResult.error ? 'danger' : 'green'}
                      onClick={() => addPortalInvoice()}
                    >
                      {invoiceSaveResult.error ? 'Retry' : 'Import'}
                    </Button>
                  )}
                </td>
              </>
            ) : (
              <>
                <td>
                  {localInvoiceData.portal_invoice.invoice_number}
                  {' : '}
                  {getTypeName(localInvoiceData.portal_invoice.type_id)}
                  {' : '}
                  {formatValueString(localInvoiceData.portal_invoice.amount, 'currency')}
                </td>
                <td>Imported</td>
              </>
            )}
          </>
        )}
      </tr>
      {invoiceSaveResult.error && (
        <tr>
          <td colSpan={7} className="text-danger">
            <Alert color="danger">
              {invoiceSaveResult.body.message}
              <pre>{JSON.stringify(invoiceSaveResult.body.errors, null, 2)}</pre>
            </Alert>
          </td>
        </tr>
      )}
    </>
  );
};

export default ProjectQuickBooksInvoiceLine;
