/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import {
  Container, Row, Col, InputGroup, InputGroupAddon, Button,
} from 'reactstrap';
import { ScrollToTopOnMount } from '../router/scroll-to-top-on-mount';
import PageHeader from '../app-layout/page-header';
import ProgressBar from '../layout-helpers/progress-bar';
import ProfileCard from './profile-card';
import AlphaToolbar from './alpha-toolbar';
import Icon from '../layout-helpers/icon';
import { documentTitle } from '../../utils/helpers';
import { DebouncedInput } from '../debounced-input/debounced-input';
import { connectUrlState } from '../url-state-manager/url-state-manager';
import { PUSH_OR_REPLACE } from '../../utils/constants';
import { shallowAreObjectsDifferent } from '../../helpers/shallow-are-objects-different';
import { connectToAPIProvider } from '../providers/api-provider';
import API_PROVIDER_PROP_TYPES from '../../prop-types/api-provider-prop-types';
import { apiAborter } from '../../helpers/api-aborter.helper';


configureAnchors({ offset: -100, scrollDuration: 500 });

export const USERS_BASE_API_PATH = '/user';

/**
 * Alpha = alphabet
 */
const fullAlphaArray = [];
for (let i = 65; i < 91; i += 1) {
  const str = String.fromCharCode(i);
  fullAlphaArray.push(str);
}

const SEARCH_DEBOUNCE_DURATION = 500;

/**
 * @constructor
 * ProfileCards component for latest news
 */
class ProfileCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allUsers: [],
      isLoading: true,
      loadingError: null,
      alphaArray: [...fullAlphaArray],
      internalSearchTerm: props.urlState.searchTerm,
    };
    this.searchInputRef = React.createRef();
    this.abortLoadUsers = null;
  }


  /**
   * @inheritdoc
   */
  componentDidMount() {
    const { title } = this.props;
    documentTitle(title);
    this.loadUsers();
  }


  /**
   * @inheritdoc
   */
  shouldComponentUpdate(nextProps) {
    const { urlState } = this.props;
    if (shallowAreObjectsDifferent(nextProps.urlState, urlState)) {
      this.setState({ internalSearchTerm: nextProps.urlState.searchTerm });
      return false;
    }

    return true;
  }


  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    if (this.abortLoadUsers) {
      this.abortLoadUsers.abort();
    }
  }


  /**
   * @description
   * Are the results filtered?
   */
  get isFiltered() {
    const { searchTerm } = this.state;
    return searchTerm !== '';
  }


  /**
   * @description
   * Fired when the searchTerm is changed
   *
   * @param {string} searchTerm
   */
  handleChangeSearchTerm = (searchTerm) => {
    this.setState({ internalSearchTerm: searchTerm });
  };


  /**
   * @description
   * Fired when the searchTerm is cleared
   */
  handleClearSearchTerm = () => {
    this.setState({ internalSearchTerm: '' }, () => {
      const { urlState, setUrlState } = this.props;
      if (urlState.searchTerm !== '') {
        setUrlState({ searchTerm: '' }, PUSH_OR_REPLACE.PUSH);
      }
    });
  };


  /**
   * @description
   * Fired when the Search Term input is confirmed
   * Possibly because:
   *  - They pressed "enter",
   *  - They blurred the searchTerm box
   *
   * @param {string} searchTerm
   */
  handleConfirmSearchTerm = (searchTerm) => {
    this.setState({ internalSearchTerm: searchTerm }, () => {
      const { internalSearchTerm } = this.state;
      const { urlState, setUrlState } = this.props;
      if (internalSearchTerm === urlState.searchTerm) return;
      setUrlState({ searchTerm: internalSearchTerm }, PUSH_OR_REPLACE.PUSH);
    });
  }


  /**
   * @description
   * Fired when a key is pressed in the searchTerm input
   *
   * @param {React.SyntheticEvent<HTMLInput>} e
   */
  handleKeyDownSearchTerm = (e) => {
    if (e.keyCode === 13) {
      this.handleConfirmSearchTerm(e.currentTarget.value);
    }
  }


  /**
   * @description
   * Fired when the searchTerm input is blurred
   *
   * @param {React.SyntheticEvent<HTMLInput>} e
   */
  handleBlurSearchTerm = (e) => {
    this.handleConfirmSearchTerm(e.currentTarget.value);
  }


  /**
   * @description
   * Fired when the order of results is reversed
   */
  toggleReverse = () => {
    const { urlState, setUrlState } = this.props;
    const { alphaArray } = this.state;
    setUrlState({ reverse: !urlState.reverse });
    this.setState({ alphaArray: alphaArray.reverse() });
  };


  /**
   * @description
   * Get a sorted array of users matching the search term
   */
  alphaMapUsers = (allUsers, reverse) => {
    const { internalSearchTerm } = this.state;
    const userList = [...allUsers];
    const alphaUsers = {};
    let alphaList = [];
    if (reverse) userList.reverse();

    userList.map((user) => {
      if (user.active === 0) return false;

      if (
        this.isFiltered &&
        (user.name.toLowerCase().indexOf(internalSearchTerm.toLowerCase()) < 0)
      ) return false;
      const alpha = user.name.charAt(0).toUpperCase();
      if (alphaList.indexOf(alpha) < 0) alphaList.push(alpha);
      alphaUsers[alpha] = alphaUsers[alpha] || [];
      alphaUsers[alpha].push(user);
      return true;
    });
    alphaList = alphaList.sort();
    return { alphaList, alphaUsers };
  };


  /**
   * @description
   * Load the Users from the API using the current parameters
   */
  loadUsers() {
    if (this.abortLoadUsers) {
      this.abortLoadUsers.abort();
    }
    this.abortLoadUsers = apiAborter();

    this.setState({
      isLoading: true,
      loadingError: null,
    }, async () => {
      const { apiProvider: { apiFetch } } = this.props;

      const response = await apiFetch(`${USERS_BASE_API_PATH}?pagelength=0&with[]=state:acronym`, { signal: this.abortLoadUsers.signal });

      if (response.success) {
        this.abortLoadUsers = null;
        this.setState({
          allUsers: response.body.data,
          isLoading: false,
          loadingError: null,
        });
      }
      else if (!response.aborted) {
        this.abortLoadUsers = null;
        this.setState({
          allUsers: [],
          isLoading: false,
          loadingError: response.error,
        });
      }
    });
  }


  /**
   * @inheritdoc
   */
  render() {
    const { allUsers, loadingError, isLoading } = this.state;
    const { internalSearchTerm } = this.state;
    const { urlState } = this.props;
    const { reverse } = urlState;
    const { alphaList, alphaUsers } = this.alphaMapUsers(allUsers, reverse);

    return (
      <Container fluid>
        <ScrollToTopOnMount />
        <ScrollableAnchor id="top-of-page">
          {/* This has to be a Div otherwise ScrollableAnchor throws an error */}
          <div>
            <PageHeader {...this.props} showBtn={false}>
              <div className="portal-user-profiles-header">
                <h4 className="text-themecolor">Staff Profiles</h4>
                <div className="search-wrapper">
                  <InputGroup>
                    <DebouncedInput
                      name="search-users"
                      className="search-users"
                      placeholder="Search for Users"
                      type="text"
                      innerRef={this.searchInputRef}
                      value={internalSearchTerm}
                      onChange={this.handleChangeSearchTerm}
                      debounceDuration={SEARCH_DEBOUNCE_DURATION}
                      onBlur={this.handleBlurSearchTerm}
                      onKeyDown={this.handleKeyDownSearchTerm}
                    />
                    <InputGroupAddon addonType="append">
                      <Button onClick={this.handleClearSearchTerm} onKeyUp={this.handleClearSearchTerm}>
                        <Icon i={`${this.isFiltered ? 'times' : 'search'}`} />
                      </Button>
                      <Button onClick={this.toggleReverse} onKeyUp={this.toggleReverse}>
                        <Icon i={`sort-alpha-${reverse ? 'asc' : 'desc'}`} />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
            </PageHeader>
          </div>
        </ScrollableAnchor>
        <Container className="portal-user-profiles">

          {/* loading users */}
          {isLoading && !loadingError && (
            <div className="loader-container">
              <p>Loading...</p>
              <ProgressBar step={5} />
            </div>
          )}

          {/* Some error while loading users */}
          {loadingError && (
            <div className="text-danger">
              {loadingError}
            </div>
          )}

          {/* No issues - display the users */}
          {!loadingError && !isLoading && fullAlphaArray.map((alpha) => {
            const users = alphaUsers[alpha];
            if (!users) return false;
            return (
              <ScrollableAnchor
                id={`users-starting-with-${alpha.toLowerCase()}`}
                key={`alpha-row-${alpha}`}
              >
                <div className="user-alpha-block">
                  <div className="alpha-id-row mt-5">
                    <h1 className="profiles-alpha-header">{alpha}</h1>
                    <AlphaToolbar
                      alphaList={alphaList}
                      onClickSearch={() => {
                        if (this.searchInputRef.current) this.searchInputRef.current.focus();
                      }}
                    />
                  </div>
                  <div className="profiles-alpha-row">
                    {users.map((user) => (
                      <ProfileCard user={user} key={`user-card-${user.id}`} />
                    ))}
                  </div>
                  <Row>
                    <Col className="profiles-alpha-footer">
                      <a href="#top-of-page">Top&nbsp;</a>
                    </Col>
                  </Row>
                </div>
              </ScrollableAnchor>
            );
          })}
        </Container>
      </Container>
    );
  }
}

ProfileCards.defaultProps = {
  title: 'Browse Profiles',
};

ProfileCards.propTypes = {
  urlState: PropTypes.shape({ reverse: PropTypes.bool.isRequired, searchTerm: PropTypes.string.isRequired }).isRequired,
  setUrlState: PropTypes.func.isRequired,
  title: PropTypes.string,
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
};

/**
 * @description
 * Sanitise a dirtyUrlState
 *
 * @param {Partial<{ reverse: string, searchTerm: string }>} dirtyUrlState
 * @returns {{ reverse: boolean, searchTerm: string }}
 */
const washUrlState = (dirtyUrlState) => ({
  reverse: !!('reverse' in dirtyUrlState && dirtyUrlState.reverse === 'true'),
  searchTerm: 'searchTerm' in dirtyUrlState && dirtyUrlState.searchTerm
    ? dirtyUrlState.searchTerm
    : '',
});

export default connectUrlState(washUrlState)(connectToAPIProvider(ProfileCards));
