// eslint-disable-file no-void
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import ReactFC from 'react-fusioncharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

import PortalFusionChartDataLoader from './portal-fusion-chart-data-loader';
import { isMultiSeriesChart, FUSION_CHART_TYPES_SUPPORTED } from '../../utils/fusion-chart-types';
import rollingSvg from '../../images/Rolling-1s-22px.svg';
import { PORTAL_FUSION_CHART_DATA_PROP_TYPES, PORTAL_FUSION_CHART_DATA_MAPPING_PROP_TYPES } from '../../prop-types/portal-fusion-charts-prop-types';
import PortalFusionChartDataMapper from './portal-fusion-chart-data-mapper';

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

// @see https://www.fusioncharts.com/react-charts
// @see https://www.fusioncharts.com/dev/getting-started/react/dynamically-add-chart-event-listener-using-react
// @see https://www.fusioncharts.com/dev/
// @see https://www.fusioncharts.com/dev/chart-attributes/mscombidy2d


/**
 * @description
 * Renders a Fusion Chart and binds event listeners
 */
class PortalFusionChartRenderer extends React.Component {
  /**
   * @inheritdoc
   */
  componentDidMount = () => {
    FusionCharts.addEventListener('dataplotClick', this.handleChartEvent);
    FusionCharts.addEventListener('legendItemClicked', this.handleChartEvent);
  }


  /**
   * @inheritdoc
   */
  componentWillUnmount = () => {
    FusionCharts.removeEventListener('dataplotClick', this.handleChartEvent);
    FusionCharts.removeEventListener('legendItemClicked', this.handleChartEvent);
  }


  /**
   * @description
   * Fired when the chart is clicked
   *
   * @param {FusionCharts.EventObject} eventObject
   */
  handleChartEvent = (eventObject) => {
    // wrap handlers in setTimeout to allow FusionCharts to finish
    // handling the event before we fire our handlers

    setTimeout(() => {
      // Make sure the event being fired is for this chart
      const { id } = this.props;
      if (eventObject.sender.id !== id) return;

      switch (eventObject.eventType.toLowerCase()) {
        case 'dataplotclick':
          this.handleDataPlotClicked(eventObject.data);
          break;
        case 'legenditemclicked':
          this.handleSeriesLabelClicked(eventObject.data);
          break;
        default:
          break;
      }
    }, 0);
  }


  /**
   * @description
   * Fired when a data plot item is clicked on the chart
   *
   * @param {object} dataPlot
   */
  handleDataPlotClicked = (dataPlot) => {
    const {
      id, onClickDataItem, chartType, chartData,
    } = this.props;

    const seriesIndex = dataPlot.datasetIndex;
    const dataItemIndex = dataPlot.dataIndex;

    // TODO: verify this logic
    const { dataItem } = isMultiSeriesChart(chartType)
      ? chartData[seriesIndex].data[dataItemIndex]
      : chartData[dataItemIndex];

    // TODO: verify this is working

    if (typeof onClickDataItem === 'function') onClickDataItem(id, dataItem, dataPlot);
  }


  /**
   * @description
   * Fired when a series label is clicked in the legend
   *
   * @param {object} clickedSeries
   */
  handleSeriesLabelClicked = (clickedSeries) => {
    const {
      id, onClickSeriesLabel, onChangeSeriesVisibility,
    } = this.props;
    if (typeof onClickSeriesLabel === 'function') onClickSeriesLabel(id, clickedSeries);
    const seriesIndex = clickedSeries.datasetIndex;
    if (typeof onChangeSeriesVisibility === 'function') onChangeSeriesVisibility(id, seriesIndex, clickedSeries.visible);
  }


  /**
   * @inheritdoc
   */
  render() {
    const {
      id,
      caption,
      subCaption,
      xAxisName,
      yAxisName,
      numberPrefix,
      paletteColors,
      showValues,
      chartData,
      chartCategories,
      chartType,
      labelDisplay,
      labelStep,
      drawAnchors,
      yAxisMaxValue,
      plotToolText,
      additionalChartSettings,
    } = this.props;

    // render chart
    const chartDataSource = {
      chart: {
        labelDisplay, // 'auto' 'wrap' 'stagger' 'rotate' 'none',
        labelStep,
        drawAnchors,
        caption,
        ...(paletteColors ? { paletteColors: paletteColors.join(',') } : {}),
        subCaption,
        xAxisName,
        yAxisName,
        yAxisMaxValue,
        numberPrefix,
        showValues: showValues ? 1 : 0,
        theme: 'fusion',
        plotToolText,
        ...additionalChartSettings,
      },
      categories: chartCategories,
      dataSet: chartData,
      data: chartData,
    };

    // if there's no data, don't render the chart
    if (chartCategories[0].category.length === 0) {
      return <div className="no-data">No data</div>;
    }

    // @see https://www.fusioncharts.com/charts/column-bar-charts/grouped-column-chart-with-multiple-series
    return (
      <ReactFC
        id={id}
        type={chartType}
        dataFormat="json"
        dataSource={chartDataSource}
        width="100%"
        height="100%"
      />
    );
  }
}


PortalFusionChartRenderer.propTypes = {
  id: PropTypes.string.isRequired,
  paletteColors: PropTypes.arrayOf(PropTypes.string),
  caption: PropTypes.string,
  subCaption: PropTypes.string,
  xAxisName: PropTypes.string,
  yAxisName: PropTypes.string,
  numberPrefix: PropTypes.string,
  showValues: PropTypes.bool,
  labelDisplay: PropTypes.string,
  labelStep: PropTypes.number,
  drawAnchors: PropTypes.bool,
  yAxisMaxValue: PropTypes.number,
  plotToolText: PropTypes.string,

  additionalChartSettings: PropTypes.shape({}),

  chartType: PropTypes.oneOf(Object.values(FUSION_CHART_TYPES_SUPPORTED)).isRequired,
  chartData: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  })).isRequired,
  chartCategories: PropTypes.arrayOf(PropTypes.shape({ category: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string.isRequired })) })).isRequired,

  onClickDataItem: PropTypes.func,
  onClickSeriesLabel: PropTypes.func,
  onChangeSeriesVisibility: PropTypes.func,
};

PortalFusionChartRenderer.defaultProps = {
  paletteColors: null,
  caption: null,
  subCaption: null,
  xAxisName: null,
  yAxisName: null,
  numberPrefix: '',
  showValues: false,
  labelDisplay: 'auto',
  labelStep: 1,
  drawAnchors: true,
  yAxisMaxValue: undefined,
  plotToolText: undefined,

  additionalChartSettings: {},

  onClickDataItem: null,
  onClickSeriesLabel: null,
  onChangeSeriesVisibility: null,
};


/**
 * @description
 * Wraps rendering of a Fusion Chart
 * Provides automatic data loading and data mapping
 *
 * @param {{
 *  id: string,
 *  className?: string,
 *  onChartDataMapped?: (id: string, details: {
 *    chartCategories: [{ category: { label: string } }],
 *    chartData: { data: {}[] }[],
 *    loadedData: {}[],
 *    dataMapping: { type: 'single' } | { type: 'multi', seriesList: { [key:string]: unknown } },
 *  }) => any,
 *  data: {
 *    url: string,
 *    onLoading?: (id: string, isLoading: boolean) => any | void,
 *    getReportDataFromResponse?: (loadedData: {}) => {},
 *  } | {
 *    loadedData: {}[],
 *    hasError: boolean,
 *    error: any,
 *    isLoading: boolean,
 *  },
 *  dataMapping: {
 *    type: 'single',
 *    chartType: string,
 *    getValue: (dataItem: {}) => string | number,
 *    getLabel: (dataItem: {}) => string,
 *    getColor?: (dataItem: {}) => string | number,
 *    filterIn?: (dataItem: {}) => boolean,
 *  } | {
 *    type: 'multi',
 *    chartType: string,
 *    getLabel: (dataItem: {}) => string,
 *    seriesList: {
 *      name: string,
 *      getValue: (dataItem: {}) => string | number,
 *      filterIn?: (dataItem: {}) => boolean,
 *      renderAs?: string,
 *      visible: boolean,
 *      legendItemId?: string,
 *      color?: string,
 *      lineThickness?: number,
 *      hoverGradientColor?: string
 *    }[]
 *  },
 *  paletteColors?: string,
 *  caption?: string,
 *  xAxisName?: string,
 *  yAxisName?: string,
 *  yAxisMaxValue?: number,
 *  showValues?: boolean,
 *  labelDisplay?: string,
 *  labelStep?: number,
 *  drawAnchors?: boolean,
 *  plotToolText?: string,
 *  onDataItemClick?: (id: string, dataItem: {}, dataPlot: {}) => any,
 *  onClickSeriesLabel?: (id: string, clickedSeries: {}) => any,
 *  onChangeSeriesVisibility?: (id: string, seriesIndex: number, isVisible: boolean) => any,
 *  additionalChartSettings: any,
 * }} props
 * @returns {JSX.Element}
 */
const PortalFusionChart = (props) => {
  const {
    className, id, dataMapping, data, onChartDataMapped,
  } = props;

  const { chartType } = dataMapping;

  return (
    <div className={classNames('portal-fusion-chart', className)}>
      <PortalFusionChartDataLoader
        id={id}
        data={data}
        render={({
          isLoading,
          loadedData,
          hasError,
          error,
        }) => (
          <>
            {/* Error */}
            {hasError && console.error('TODO: handle portal fusion charts error. (Probably a Loading Error)', { error }) && null}

            {/* Loading */}
            {!hasError && isLoading && (
              <div className="loading-indicator"><img src={rollingSvg} alt="Loading chart..." /></div>
            )}

            {/* Map and render */}
            {!hasError && !isLoading && (
              <PortalFusionChartDataMapper
                id={id}
                onChartDataMapped={onChartDataMapped}
                data={{
                  isLoading,
                  loadedData,
                  hasError,
                  error,
                }}
                dataMapping={dataMapping}
                render={({
                  chartData, chartCategories, chartHeight, chartWidth,
                }) => (
                  <PortalFusionChartRenderer
                    {...props}
                    chartHeight={chartHeight}
                    chartWidth={chartWidth}
                    chartType={chartType}
                    chartData={chartData}
                    chartCategories={chartCategories}
                  />
                )}
              />
            )}
          </>
        )}
      />
    </div>
  );
};


PortalFusionChart.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  data: PORTAL_FUSION_CHART_DATA_PROP_TYPES.isRequired,
  dataMapping: PORTAL_FUSION_CHART_DATA_MAPPING_PROP_TYPES.isRequired,


  // from PortalFusionChartRenderer
  paletteColors: PropTypes.arrayOf(PropTypes.string),
  caption: PropTypes.string,
  subCaption: PropTypes.string,
  xAxisName: PropTypes.string,
  yAxisName: PropTypes.string,
  numberPrefix: PropTypes.string,
  showValues: PropTypes.bool,

  onClickDataItem: PropTypes.func,
  onClickSeriesLabel: PropTypes.func,
  onChangeSeriesVisibility: PropTypes.func,

  onChartDataMapped: PropTypes.func,
};

PortalFusionChart.defaultProps = {
  className: null,

  // from PortalFusionChartRenderer
  paletteColors: null,
  caption: null,
  subCaption: null,
  xAxisName: null,
  yAxisName: null,
  numberPrefix: '',
  showValues: false,

  onClickDataItem: null,
  onClickSeriesLabel: null,
  onChangeSeriesVisibility: null,

  onChartDataMapped: null,
};


export default PortalFusionChart;
