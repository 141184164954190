import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getFieldDisplayComponent, getColumnClasses } from '../../render-functions';

/**
 * @param {{row: any, column: any}} props
 * @returns {JSX.Element} <TableCell />
 */
const TableCell = ({ row, column }) => (
  <td className={classnames('portal-data-field', getColumnClasses(column))}>
    {getFieldDisplayComponent(row, column, true)}
  </td>
);

TableCell.propTypes = {
  row: PropTypes.shape({}).isRequired,
  column: PropTypes.shape({}).isRequired,
};

export default TableCell;
