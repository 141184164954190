import React, { useState } from 'react';
import {
  Input, FormGroup, Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Modal } from './Modal';
import { noop } from '../../utils/helpers';
import { MODAL_BUTTON_SIDE_TYPE } from '../../constants/modal-button-side-type.const';
import { ICON } from '../../constants/icon.const';
import { BUTTON_COLOR } from '../../constants/button-color.const';

export const ProjectSummaryDashboardSettingsModal = (props) => {
  const {
    id, closeModal, onModalComplete, onCancel, summaryFields, monthlyFields, hideEmptyStates,
  } = props;

  const [isDirty, setIsDirty] = useState(false);
  const [internalSummaryFields, setInternalSummaryFields] = useState(summaryFields);
  const [internalMonthlyFields, setInternalMonthlyFields] = useState(monthlyFields);
  const [internalHideEmptyStates, setInternalHideEmptyStates] = useState(hideEmptyStates);

  const modalButtons = [
    {
      label: 'Save',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.CHECK,
      sideType: MODAL_BUTTON_SIDE_TYPE.RIGHT,
      onClick: () => {
        closeModal(id);
        if (typeof onModalComplete === 'function') {
          onModalComplete({
            settingsChanged: isDirty,
            newSettings: {
              summaryFields: internalSummaryFields,
              monthlyFields: internalMonthlyFields,
              hideEmptyStates: internalHideEmptyStates,
            },
          });
        }
      },
      disabled: (typeof onModalComplete !== 'function'),
    },
    {
      label: 'Cancel',
      color: 'secondary',
      sideType: MODAL_BUTTON_SIDE_TYPE.LEFT,
      onClick: () => {
        closeModal(id, false, onCancel);
      },
    },
  ];

  // Update the internal stat of one of the visibility configurations
  const updateInternalField = (fields, updateFunction, key, { visible }) => {
    updateFunction(fields.map((field) => {
      if (key === field.key) {
        return {
          ...field,
          visible,
        };
      }
      return field;
    }));
    setIsDirty(true);
  };

  return (
    <Modal
      {...props}
      buttons={modalButtons}
      title="Project Summary Dashboard Options"
      allowDismiss
      isDirty={isDirty}
      size="lg"
      className="project-summary-dashboard settings"
    >
      <div className="separator">
        Select the fields you want to see on the dashboard.
        <hr />
      </div>
      <div className="fields">
        <div className="summary">
          <strong>Summary Fields:</strong>
          {internalSummaryFields.filter((field) => !field.locked).map((field) => (
            <FormGroup check key={field.key}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={field.visible}
                  onChange={() => updateInternalField(internalSummaryFields, setInternalSummaryFields, field.key, { visible: !field.visible })}
                />
                <span>{`${field.label}`}</span>
              </Label>
            </FormGroup>
          ))}
        </div>
        <div className="monthly">
          <strong>Monthly Fields:</strong>
          {internalMonthlyFields.filter((field) => !field.locked).map((field) => (
            <FormGroup check key={field.key}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={field.visible}
                  onChange={() => updateInternalField(internalMonthlyFields, setInternalMonthlyFields, field.key, { visible: !field.visible })}
                />
                <span>{`${field.label}`}</span>
              </Label>
            </FormGroup>
          ))}
        </div>
      </div>
      <div className="separator">
        <strong>Options</strong>
        <hr />
      </div>
      <div>
        <div className="options">

          {/* Hide Empty States */}
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={internalHideEmptyStates}
                onChange={() => {
                  setInternalHideEmptyStates(!internalHideEmptyStates);
                  setIsDirty(true);
                }}
              />
              <span>Hide Empty States</span>
            </Label>
          </FormGroup>
        </div>
      </div>
    </Modal>
  );
};

ProjectSummaryDashboardSettingsModal.propTypes = {
  id: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  summaryFields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  })).isRequired,
  monthlyFields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  })).isRequired,
  hideEmptyStates: PropTypes.bool.isRequired,
  onModalComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

ProjectSummaryDashboardSettingsModal.defaultProps = {
  onCancel: noop,
};
