import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';

import { APIContext } from '../../providers/api-provider';
import { ModalContext } from '../../modals/modal-context';

import { useMountEffect } from '../../../react-hooks/use-mount-effect.hook';
import { useForceUpdate } from '../../../react-hooks/use-force-update.hook';

import { APIPolyFormProps } from '../../../types/poly-form/api-poly-form.props';
import { ICompanyRecord } from '../../../types/company/company.record.interface';
import { ICompanyLocationRecord } from '../../../types/company/company-location.record.interface';
import { ICompanyLocationPortalTreeViewNodeData } from '../../../types/portal-tree-view/company-location-portal-tree-view-node-data.interface';
import { ICompanyLocationPortalTreeViewSelectedNodeIdentifier } from '../../../types/portal-tree-view/company-location-portal-tree-view-selected-node-identifier.interface';
import { ICompanySpaceRecord } from '../../../types/company/company-space.record.interface';
import { NewRecordModalResult } from '../../../types/modal/modal-result';
import { WidgetProps } from '../../../types/widget.props';

import { CompanyLocationsPortalTreeView } from '../../portal-tree-view/company-locations/company-locations-portal-tree-view';
import { CompanyLocationsWidgetSelectedRecordDetail } from './company-locations-widget-selected-record-detail';
import { SplitView } from '../../layout-helpers/split-view';

import { apiAborter } from '../../../helpers/api-aborter.helper';
import { buildAPIRoute } from '../../../helpers/build-api-route.helper';
import { buildLocationNodeId, buildSpaceNodeId } from '../../portal-tree-view/company-locations/build-company-locations-tree-items.helper';
import { hasPermittedAction } from '../../../helpers/has-permitted-action.helper';
import { loadLocalPrefs, saveLocalPref } from '../../../utils/localStorage';
import { shallowAreObjectsDifferent } from '../../../helpers/shallow-are-objects-different';
import { showConfirmModal } from '../../../helpers/show_confirm_modal.helper';

import { companyLocationsWidgetPossibleLocationActions } from './const/company-locations-widget-possible-location-actions.const';
import { companyLocationsWidgetPossibleSpaceActions } from './const/company-locations-widget-possible-space-actions.const';
import { API_ACTION } from '../../../constants/api-action.const';
import { MODAL_TYPE } from '../../../constants/modal-type.const';
import { COMPANY_LOCATION_PTV_NODE_TYPE } from '../../../constants/company-locations-ptv-node-type.const';
import { BUTTON_COLOR } from '../../../constants/button-color.const';
import { A_COMPANY_LOCATION_TYPE } from '../../../constants/company-location-type.const';

export type CompanyLocationsWidgetProps = Omit<WidgetProps, 'rowData' | 'baseRoute' | 'apiRoute' | 'apiQuery'> & {
  locationsBaseRoute?: APIPolyFormProps['baseRoute'],
  locationsApiRoute?: APIPolyFormProps['apiRoute'],
  locationsApiQuery?: APIPolyFormProps['apiQuery'],
  spacesBaseRoute?: APIPolyFormProps['baseRoute'],
  spacesApiRoute?: APIPolyFormProps['apiRoute'],
  spacesApiQuery?: APIPolyFormProps['apiQuery'],
  rowData: ICompanyRecord,
};

interface ICompanyLocationsWidgetLocalPrefs {
  isSafeModeEnabled?: boolean,
  isRootNodeVisible?: boolean,
  isSearchBarVisible?: boolean,
}

export const CompanyLocationsWidget: React.FC<CompanyLocationsWidgetProps> = (props) => {
  const {
    className,
    locationsBaseRoute = '/company',
    locationsApiRoute = '/location',
    locationsApiQuery = ['with[]=state', 'pagelength=0'].join('&'),
    spacesBaseRoute = '/company',
    spacesApiRoute = '/space',
    spacesApiQuery = [
      'with[]=type',
      'with[]=location',
      'pagelength=0',
    ].join('&'),
    rowData,
    permittedActions,
    onDirtyChange,
  } = props;

  const {
    id: companyId,
    name: companyName,
  } = rowData;

  const { apiFetch } = useContext(APIContext);
  const { showModal } = useContext(ModalContext);

  const forceUpdate = useForceUpdate();

  // Local preferences
  const {
    isSafeModeEnabled = true,
    isRootNodeVisible = false,
    isSearchBarVisible = false,
  } : ICompanyLocationsWidgetLocalPrefs = loadLocalPrefs();

  const [selectedNodes, setSelectedNodes] = useState<ICompanyLocationPortalTreeViewSelectedNodeIdentifier[]>([]);

  const selectedRecord = useMemo<undefined | ICompanyLocationPortalTreeViewSelectedNodeIdentifier>(() => (selectedNodes.length === 1 ? selectedNodes[0] : undefined), [selectedNodes]);
  const [selectedRecordChecksum, setSelectedRecordChecksum] = useState<number>(0);
  const [isEditingSelectedRecord, setIsEditingSelectedRecord] = useState<boolean>(false);

  const [companyLocations, setCompanyLocations] = useState<ICompanyLocationRecord[]>([]);
  const [isLoadingCompanyLocations, setIsLoadingCompanyLocations] = useState<boolean>(true);
  const [companyLocationsError, setCompanyLocationsError] = useState<null | string>(null);
  const companyLocationsAbortController = useRef<null | AbortController>(null);

  const [companySpaces, setCompanySpaces] = useState<ICompanySpaceRecord[]>([]);
  const [isLoadingCompanySpaces, setIsLoadingCompanySpaces] = useState<boolean>(false);
  const [companySpacesError, setCompanySpacesError] = useState<null | string>(null);
  const companySpacesAbortController = useRef<null | AbortController>(null);

  const [error, setError] = useState<undefined | string | string[]>(undefined);

  // The current user can edit the location tree if they have the Update action on the selected company record
  const canEditLocationTree: boolean = useMemo(() => !!hasPermittedAction(permittedActions, API_ACTION.UPDATE), [permittedActions]);

  const internalLocationsApiRoute = useMemo(() => (typeof locationsApiRoute === 'function' ? locationsApiRoute(rowData) : locationsApiRoute), [locationsApiRoute, rowData]);
  const internalLocationsApiQuery = useMemo(() => (typeof locationsApiQuery === 'function' ? locationsApiQuery(rowData) : locationsApiQuery), [locationsApiQuery, rowData]);
  const internalSpacesApiRoute = useMemo(() => (typeof spacesApiRoute === 'function' ? spacesApiRoute(rowData) : spacesApiRoute), [spacesApiRoute, rowData]);
  const internalSpacesApiQuery = useMemo(() => (typeof spacesApiQuery === 'function' ? spacesApiQuery(rowData) : spacesApiQuery), [spacesApiQuery, rowData]);

  /**
   * Add a single record in the cached company locations data
   */
  const addCachedCompanyLocationRecord = useCallback(
    (companyLocationRecord: ICompanyLocationRecord) => setCompanyLocations(
      (oldLocations) => [
        ...oldLocations,
        companyLocationRecord,
      ],
    ), [],
  );


  /**
   * Add a single record in the cached company spaces data
   */
  const addCachedCompanySpaceRecord = useCallback(
    (companySpaceRecord: ICompanySpaceRecord) => setCompanySpaces(
      (oldSpaces) => [
        ...oldSpaces,
        companySpaceRecord,
      ],
    ), [],
  );


  /**
   * Update a single record in the cached company locations data
   */
  const updateCachedCompanyLocationRecord = useCallback(
    (id: number, companyLocationRecord: ICompanyLocationRecord) => setCompanyLocations(
      (oldLocations) => oldLocations.map((companyLocation) => (companyLocation.id === id ? companyLocationRecord : companyLocation)),
    ), [],
  );


  /**
   * Update a single record in the cached company spaces data
   */
  const updateCachedCompanySpaceRecord = useCallback(
    (id: number, companySpaceRecord: ICompanySpaceRecord) => setCompanySpaces(
      (oldSpaces) => oldSpaces.map((companySpace) => (companySpace.id === id ? companySpaceRecord : companySpace)),
    ), [],
  );


  /**
   * Delete a single record in the cached company locations data
   */
  const deleteCachedCompanyLocationRecord = useCallback((id: number) => setCompanyLocations(
    (oldLocations) => oldLocations.filter((companyLocation) => (companyLocation.id !== id)),
  ), []);


  /**
   * Delete a single record in the cached company spaces data
   */
  const deleteCachedCompanySpaceRecord = useCallback((id: number) => setCompanySpaces(
    (oldSpaces) => oldSpaces.filter((companySpace) => (companySpace.id !== id)),
  ), []);


  /**
   * Called whenever we need the selected record details to update and the selectedRecord hasn't really changed
   */
  const bumpSelectedRecordChecksum = () => setSelectedRecordChecksum((oldSelectedRecordChecksum) => oldSelectedRecordChecksum + 1);


  /**
   * Load the location data
   */
  const loadLocationsData = useCallback(async () => {
    setIsLoadingCompanyLocations(true);
    setCompanyLocationsError(null);
    setCompanyLocations([]);

    try {
      // Terminate existing data load
      if (companyLocationsAbortController.current) {
        companyLocationsAbortController.current.abort();
      }
      // Create a new abort controller
      companyLocationsAbortController.current = apiAborter();


      // Call the API
      const response = await apiFetch(
        buildAPIRoute({
          baseRoute: locationsBaseRoute,
          apiRoute: internalLocationsApiRoute,
          apiQuery: internalLocationsApiQuery,
          parentId: companyId,
        }),
        {
          method: HTTP_METHOD.GET,
          name: 'CompanyLocationsPortalTreeView::loadLocationsData',
          signal: companyLocationsAbortController.current.signal,
        },
      );

      if (response.success) {
        companyLocationsAbortController.current = null;
        setCompanyLocations(response.body.data as ICompanyLocationRecord[]);
      } else if (!response.aborted) {
        companyLocationsAbortController.current = null;
      }
    } catch (err) {
      setCompanyLocationsError(err instanceof Error ? err.message : 'Unknown Error');
    } finally {
      setIsLoadingCompanyLocations(false);
    }
  }, [apiFetch, companyId, internalLocationsApiQuery, internalLocationsApiRoute, locationsBaseRoute]);


  /**
   * Load the spaces data
   */
  const loadSpacesData = useCallback(async () => {
    setIsLoadingCompanySpaces(true);
    setCompanySpacesError(null);
    setCompanySpaces([]);

    try {
      // Terminate existing data load
      if (companySpacesAbortController.current) {
        companySpacesAbortController.current.abort();
      }
      // Create a new abort controller
      companySpacesAbortController.current = apiAborter();


      // Call the API
      const response = await apiFetch(
        buildAPIRoute({
          baseRoute: spacesBaseRoute,
          apiRoute: internalSpacesApiRoute,
          apiQuery: internalSpacesApiQuery,
          parentId: companyId,
        }),
        {
          method: HTTP_METHOD.GET,
          name: 'CompanySpacesPortalTreeView::loadSpacesData',
          signal: companySpacesAbortController.current.signal,
        },
      );

      if (response.success) {
        companySpacesAbortController.current = null;
        setCompanySpaces(response.body.data as ICompanySpaceRecord[]);
      } else if (!response.aborted) {
        companySpacesAbortController.current = null;
        setCompanySpaces([]);
      }

      // setSpaces(loadResult);
    } catch (err) {
      setCompanySpacesError(err instanceof Error ? err.message : 'Unknown Error');
    } finally {
      setIsLoadingCompanySpaces(false);
    }
  }, [apiFetch, companyId, internalSpacesApiQuery, internalSpacesApiRoute, spacesBaseRoute]);


  /**
   * Move a location node to a new parent
   */
  const moveLocation = useCallback(async (locationId: number, newParentLocationId: undefined | number) => {
    setIsLoadingCompanyLocations(true);
    setCompanyLocationsError(null);

    try {
      // Terminate existing operation
      if (companyLocationsAbortController.current) {
        companyLocationsAbortController.current.abort();
      }
      // Create a new abort controller
      companyLocationsAbortController.current = apiAborter();

      // Call the API
      const response = await apiFetch(
        buildAPIRoute({
          baseRoute: locationsBaseRoute,
          apiRoute: internalLocationsApiRoute,
          apiQuery: internalLocationsApiQuery,
          primaryKeyValue: locationId,
          parentId: companyId,
        }),
        {
          method: HTTP_METHOD.PATCH,
          name: 'CompanyLocationsPortalTreeView::moveLocation',
          signal: companyLocationsAbortController.current.signal,
          body: {
            id: locationId,
            parent_id: (newParentLocationId ?? -1) > -1 ? newParentLocationId : null,
          },
        },
      );

      if (response.success) {
        companyLocationsAbortController.current = null;
        const updatedLocationRecord: ICompanyLocationRecord = response.body.data as ICompanyLocationRecord;
        updateCachedCompanyLocationRecord(locationId, updatedLocationRecord);

        // Bump the node's checksum so that the SelectedNodeDetail component picks up the change and re-loads the data
        bumpSelectedRecordChecksum();

        // Make sure the moved node is selected
        setSelectedNodes([{
          nodeId: buildLocationNodeId(updatedLocationRecord.id),
          parentNodeId: updatedLocationRecord.parent_id ? buildLocationNodeId(updatedLocationRecord.parent_id) : null,
          id: updatedLocationRecord.id,
          parentLocationId: updatedLocationRecord.parent_id ?? null,
          nodeType: COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION,
          companyLocationTypeId: updatedLocationRecord.type_id as A_COMPANY_LOCATION_TYPE,
        }]);
      } else if (!response.aborted) {
        companyLocationsAbortController.current = null;
        setCompanyLocationsError(response.body.message ?? 'Failed to move the location.');
      }
    } catch (err) {
      setCompanyLocationsError(err instanceof Error ? err.message : 'Unknown Error');
    } finally {
      setIsLoadingCompanyLocations(false);
    }
  }, [apiFetch, companyId, internalLocationsApiQuery, internalLocationsApiRoute, locationsBaseRoute, updateCachedCompanyLocationRecord]);


  /**
   * Move a company space node to a new location parent
   */
  const moveSpace = useCallback(async (spaceId: number, newParentLocationId: undefined | number) => {
    setIsLoadingCompanySpaces(true);
    setCompanySpacesError(null);

    try {
      // Terminate existing operation
      if (companySpacesAbortController.current) {
        companySpacesAbortController.current.abort();
      }
      // Create a new abort controller
      companySpacesAbortController.current = apiAborter();

      // Call the API
      const response = await apiFetch(
        buildAPIRoute({
          baseRoute: spacesBaseRoute,
          apiRoute: internalSpacesApiRoute,
          apiQuery: internalSpacesApiQuery,
          primaryKeyValue: spaceId,
          parentId: companyId,
        }),
        {
          method: HTTP_METHOD.PATCH,
          name: 'CompanyLocationsPortalTreeView::moveSpace',
          signal: companySpacesAbortController.current.signal,
          body: {
            id: spaceId,
            location_id: (newParentLocationId ?? -1) > -1 ? newParentLocationId : null,
          },
        },
      );

      if (response.success) {
        companySpacesAbortController.current = null;
        const updatedSpaceRecord: ICompanySpaceRecord = response.body.data as ICompanySpaceRecord;
        updateCachedCompanySpaceRecord(spaceId, updatedSpaceRecord);

        // Bump the node's checksum so that the SelectedNodeDetail component picks up the change and re-loads the data
        bumpSelectedRecordChecksum();

        // Make sure the moved node is selected
        setSelectedNodes([{
          nodeId: buildSpaceNodeId(updatedSpaceRecord.id),
          parentNodeId: updatedSpaceRecord.location_id ? buildSpaceNodeId(updatedSpaceRecord.location_id) : null,
          id: updatedSpaceRecord.id,
          parentLocationId: updatedSpaceRecord.location_id ?? null,
          nodeType: COMPANY_LOCATION_PTV_NODE_TYPE.SPACE,
          companySpaceType: updatedSpaceRecord.type ?? undefined,
        }]);
      } else if (!response.aborted) {
        companySpacesAbortController.current = null;
        setCompanySpacesError(response.body.message ?? 'Failed to move the space.');
      }
    } catch (err) {
      setCompanySpacesError(err instanceof Error ? err.message : 'Unknown Error');
    } finally {
      setIsLoadingCompanySpaces(false);
    }
  }, [apiFetch, companyId, internalSpacesApiQuery, internalSpacesApiRoute, spacesBaseRoute, updateCachedCompanySpaceRecord]);


  /**
   * Delete a location node
   */
  const deleteLocation = useCallback(async (locationId: number) => {
    setIsLoadingCompanyLocations(true);
    setCompanyLocationsError(null);

    try {
      // Terminate existing operation
      if (companyLocationsAbortController.current) {
        companyLocationsAbortController.current.abort();
      }
      // Create a new abort controller
      companyLocationsAbortController.current = apiAborter();

      // Call the API
      const response = await apiFetch(
        buildAPIRoute({
          baseRoute: locationsBaseRoute,
          apiRoute: internalLocationsApiRoute,
          primaryKeyValue: locationId,
          parentId: companyId,
        }),
        {
          method: HTTP_METHOD.DELETE,
          name: 'CompanyLocationsPortalTreeView::deleteLocation',
          signal: companyLocationsAbortController.current.signal,
        },
      );

      if (response.success) {
        companyLocationsAbortController.current = null;
        deleteCachedCompanyLocationRecord(locationId);

        // Clear the selected node if it is the node we just deleted
        setSelectedNodes((oldSelectedNodes) => oldSelectedNodes.filter((n) => ((n.id !== locationId) && (n.nodeType !== COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION))));
      } else if (!response.aborted) {
        companyLocationsAbortController.current = null;
        setCompanyLocationsError(response.body.message ?? 'Failed to delete the location.');
      }
    } catch (err) {
      setCompanyLocationsError(err instanceof Error ? err.message : 'Unknown Error');
    } finally {
      setIsLoadingCompanyLocations(false);
    }
  }, [apiFetch, companyId, deleteCachedCompanyLocationRecord, internalLocationsApiRoute, locationsBaseRoute]);


  /**
   * Delete a company space node
   */
  const deleteSpace = useCallback(async (spaceId: number) => {
    setIsLoadingCompanySpaces(true);
    setCompanySpacesError(null);

    try {
      // Terminate existing operation
      if (companySpacesAbortController.current) {
        companySpacesAbortController.current.abort();
      }
      // Create a new abort controller
      companySpacesAbortController.current = apiAborter();

      // Call the API
      const response = await apiFetch(
        buildAPIRoute({
          baseRoute: spacesBaseRoute,
          apiRoute: internalSpacesApiRoute,
          primaryKeyValue: spaceId,
          parentId: companyId,
        }),
        {
          method: HTTP_METHOD.DELETE,
          name: 'CompanyLocationsPortalTreeView::moveSpace',
          signal: companySpacesAbortController.current.signal,
        },
      );

      if (response.success) {
        companySpacesAbortController.current = null;
        deleteCachedCompanySpaceRecord(spaceId);

        // Clear the selected node if it is the node we just deleted
        setSelectedNodes((oldSelectedNodes) => oldSelectedNodes.filter((n) => ((n.id !== spaceId) && (n.nodeType !== COMPANY_LOCATION_PTV_NODE_TYPE.SPACE))));
      } else if (!response.aborted) {
        companySpacesAbortController.current = null;
        setCompanySpacesError(response.body.message ?? 'Failed to delete the space.');
      }
    } catch (err) {
      setCompanySpacesError(err instanceof Error ? err.message : 'Unknown Error');
    } finally {
      setIsLoadingCompanySpaces(false);
    }
  }, [apiFetch, companyId, deleteCachedCompanySpaceRecord, internalSpacesApiRoute, spacesBaseRoute]);


  /**
   * Fired when the selected node in the treeview changes
   */
  const handleCompanyLocationsTreeSelectedNodesChanged = useCallback((newSelectedNodes: ICompanyLocationPortalTreeViewSelectedNodeIdentifier[]) => {
    setSelectedNodes((oldSelectedNodes) => {
      // if the new selected nodes are different, update the selected nodes
      if (shallowAreObjectsDifferent(oldSelectedNodes.map((n) => n.id), newSelectedNodes.map((n) => n.id))) {
        return newSelectedNodes;
      }
      return oldSelectedNodes;
    });
  }, []);


  /**
   * Fired when the user starts to edit the selected (loaded) location or space record
   */
  const handleStartEditSelectedRecord = useCallback((
    /*
    node: ICompanyLocationPortalTreeViewNodeData,
    formData: Partial<ICompanyLocationRecord | ICompanySpaceRecord>
    */
  ) => {
    setIsEditingSelectedRecord(true);
  }, []);


  /**
   * Fired when the user finished editing the selected record (either by a cancel or a save)
   */
  const handleEndEditSelectedRecord = useCallback((
    saveChanges: boolean,
    node: ICompanyLocationPortalTreeViewSelectedNodeIdentifier,
    formData?: Partial<ICompanyLocationRecord | ICompanySpaceRecord>,
  ) => {
    setIsEditingSelectedRecord(false);

    // Notify the parent that viewed records are no longer "dirty"
    if (onDirtyChange) {
      onDirtyChange(false);
    }

    switch (node.nodeType) {
      case COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION:
        updateCachedCompanyLocationRecord(node.id, formData as ICompanyLocationRecord);
        break;
      case COMPANY_LOCATION_PTV_NODE_TYPE.SPACE:
        updateCachedCompanySpaceRecord(node.id, formData as ICompanySpaceRecord);
        break;
    }
  }, [onDirtyChange, updateCachedCompanyLocationRecord, updateCachedCompanySpaceRecord]);


  /**
   * Fired when the user drops a node in the company locations treeview
   */
  const handleDropNode = useCallback(async (dragSourceNode: ICompanyLocationPortalTreeViewNodeData, dropTargetNode: undefined | ICompanyLocationPortalTreeViewNodeData) => {
    if (
      // Skip the confirmation if safe mode is not enabled
      !isSafeModeEnabled ||
      // Request confirmation
      ((await showConfirmModal(showModal, {
        title: 'Move up',
        confirmButtonLabel: 'Yes, Move',
        content: (
          <p>
            <span>Are you sure you want to move </span>
            <strong>{dragSourceNode.name}</strong>

            {/* Are there any children? */}
            {((dragSourceNode.childCount ?? 0) > 0) && (
              <span> and all of its children to </span>
            )}
            {/* No Children */}
            {((dragSourceNode.childCount ?? 0) === 0) && (
              <span> to </span>
            )}

            {/* Dropping onto another location */}
            {dropTargetNode && (dropTargetNode.nodeType === COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION) && (
              <span>
                <strong>{dropTargetNode.name}</strong>
              </span>
            )}

            {/* Dropping onto the Root */}
            {(!dropTargetNode || (dropTargetNode && (dropTargetNode.nodeType === COMPANY_LOCATION_PTV_NODE_TYPE.ROOT))) && (
              <span>
                <span> the top level for </span>
                <strong>{companyName}</strong>
              </span>
            )}

            <span>?</span>
          </p>
        ),
      })).success === true)
    ) {
      if (dragSourceNode.nodeType === COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION) {
        moveLocation(dragSourceNode.id, dropTargetNode?.id ?? undefined);
      } else if (dragSourceNode.nodeType === COMPANY_LOCATION_PTV_NODE_TYPE.SPACE) {
        moveSpace(dragSourceNode.id, dropTargetNode?.id ?? undefined);
      }
    }
  }, [companyName, isSafeModeEnabled, moveLocation, moveSpace, showModal]);


  /**
   * Fired when the user uses the action to move a node up the tree without dragging / dropping
   */
  const handleMoveNodeUp = useCallback(async (
    sourceNode: ICompanyLocationPortalTreeViewNodeData,
    targetParentNode: undefined | ICompanyLocationPortalTreeViewNodeData,
  ) => {
    if (
      // Skip the confirmation if safe mode is not enabled
      !isSafeModeEnabled ||
      // Request confirmation
      ((await showConfirmModal(showModal, {
        title: 'Move up',
        confirmButtonLabel: 'Yes, Move',
        content: (
          <p>
            <span>Are you sure you want to move </span>
            <strong>{sourceNode.name}</strong>

            {/* Moving up into to another location */}
            {targetParentNode && (targetParentNode.nodeType === COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION) && (
              <span>
                <span> up to </span>
                <strong>{targetParentNode.name}</strong>
              </span>
            )}

            {/* Moving up into the Root */}
            {(!targetParentNode || (targetParentNode && (targetParentNode.nodeType === COMPANY_LOCATION_PTV_NODE_TYPE.ROOT))) && (
              <span>
                <span> up to the top level for </span>
                <strong>{companyName}</strong>
              </span>
            )}

            <span>?</span>
          </p>
        ),
      })).success === true)
    ) {
      if (sourceNode.nodeType === COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION) {
        moveLocation(sourceNode.id, targetParentNode?.id ?? undefined);
      } else if (sourceNode.nodeType === COMPANY_LOCATION_PTV_NODE_TYPE.SPACE) {
        moveSpace(sourceNode.id, targetParentNode?.id ?? undefined);
      }
    }
  }, [companyName, isSafeModeEnabled, moveLocation, moveSpace, showModal]);


  /**
   * Fired when one of the actions inside the locations tree requests that the tree go into safe mode
   */
  const handleCompanyLocationsTreeEnterSafeMode = useCallback(() => {
    saveLocalPref('isSafeModeEnabled', true);
    // TODO: once the local preferences are stored in a provider, this will not be necessary.
    forceUpdate();
  }, [forceUpdate]);


  /**
   * Fired when one of the actions inside the locations tree requests that the tree exit safe mode
   */
  const handleCompanyLocationsTreeExitSafeMode = useCallback(async () => {
    // Request confirmation
    if (
      (await showConfirmModal(showModal, {
        title: 'Exit Safe Mode',
        content: (
          <>
            <p>
              <span>
                <strong>Safe Mode</strong>
                <span> protects you from accidentally moving locations and spaces around without confirmation.</span>
              </span>
            </p>
            <p>If you exit safe mode, you will no longer be prompted to confirm your drag/drop operations.</p>
            <hr />
            <p>
              <sub>It&apos;s at this point you should be reminded that there is no UNDO function for company location management... 😬</sub>
            </p>
          </>
        ),
        confirmButtonColor: BUTTON_COLOR.WARNING,
        confirmButtonLabel: 'Yes, Exit Safe Mode',
      })).success === true
    ) {
      saveLocalPref('isSafeModeEnabled', false);
      // TODO: once the local preferences are stored in a provider, this will not be necessary.
      forceUpdate();
    }
  }, [showModal, forceUpdate]);


  /**
   * Fired when the user clicks on a create space menu item
   */
  const handleCreateLocation = useCallback(async (locationTypeId: A_COMPANY_LOCATION_TYPE, parentLocation: undefined | ICompanyLocationRecord) => {
    showModal<NewRecordModalResult<ICompanyLocationRecord>>(MODAL_TYPE.NEW_COMPANY_LOCATION, {
      baseRoute: locationsBaseRoute,
      apiRoute: internalLocationsApiRoute,
      apiQuery: internalLocationsApiQuery,
      initialData: {
        company_id: rowData.id,
        type_id: locationTypeId,
        ...(parentLocation ? {
          parent: parentLocation,
          parent_id: parentLocation.id,
        } : {
          parent: null,
          parent_id: null,
        }),
      },
      onModalComplete: (modalResult) => {
        if (modalResult.success) {
          const newCompanyLocation = modalResult.newRecord;

          // Add the company location record to the cache
          addCachedCompanyLocationRecord(newCompanyLocation);

          // Focus the newly created record
          if (newCompanyLocation.type_id) {
            setSelectedNodes([{
              nodeId: buildLocationNodeId(newCompanyLocation.id),
              parentNodeId: parentLocation?.id ? buildLocationNodeId(parentLocation.id) : null,
              id: newCompanyLocation.id,
              parentLocationId: parentLocation?.id ?? null,
              nodeType: COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION,
              companyLocationTypeId: newCompanyLocation.type_id as A_COMPANY_LOCATION_TYPE,
            }]);
          } else {
            console.warn('Company Location type_id was not populated by the new record modal dialog');
          }
        }
      },
    });
  }, [showModal, locationsBaseRoute, internalLocationsApiRoute, internalLocationsApiQuery, rowData.id, addCachedCompanyLocationRecord]);


  /**
   * Fired when the user clicks on a create space menu item
   */
  const handleCreateSpace = useCallback(async (parentLocation: undefined | ICompanyLocationRecord) => {
    showModal<NewRecordModalResult<ICompanySpaceRecord>>(MODAL_TYPE.NEW_COMPANY_SPACE, {
      baseRoute: spacesBaseRoute,
      apiRoute: internalSpacesApiRoute,
      apiQuery: internalSpacesApiQuery,
      initialData: {
        company_id: rowData.id,
        ...(parentLocation ? {
          location: parentLocation,
          location_id: parentLocation.id,
        } : {
          location: null,
          location_id: null,
        }),
      },
      onModalComplete: (modalResult) => {
        if (modalResult.success) {
          const newCompanySpace = modalResult.newRecord;

          // Add the company space record to the cache
          addCachedCompanySpaceRecord(newCompanySpace);

          // Focus the newly created record
          if (newCompanySpace.type) {
            setSelectedNodes([{
              nodeId: buildSpaceNodeId(newCompanySpace.id),
              parentNodeId: parentLocation?.id ? buildLocationNodeId(parentLocation.id) : null,
              id: newCompanySpace.id,
              parentLocationId: parentLocation?.id ?? null,
              nodeType: COMPANY_LOCATION_PTV_NODE_TYPE.SPACE,
              companySpaceType: newCompanySpace.type,
            }]);
          } else {
            console.warn('Company Space type was not populated by the new record modal dialog');
          }
        }
      },
    });
  }, [showModal, spacesBaseRoute, internalSpacesApiRoute, internalSpacesApiQuery, rowData.id, addCachedCompanySpaceRecord]);


  /**
   * Fired when the user clicks on delete location menu item
   */
  const handleDeleteLocation = useCallback(async (targetLocation: ICompanyLocationRecord) => {
    if (
      // Skip the confirmation if safe mode is not enabled
      !isSafeModeEnabled ||
      // Request confirmation
      ((await showConfirmModal(showModal, {
        title: 'Delete Location',
        confirmButtonLabel: 'Yes, Delete',
        confirmButtonColor: BUTTON_COLOR.DANGER,
        content: (
          <p>
            <span>Are you sure you want to delete </span>
            <strong>{targetLocation.name}</strong>
            <span>?</span>
          </p>
        ),
      })).success === true)
    ) {
      deleteLocation(targetLocation.id);
    }
  }, [deleteLocation, isSafeModeEnabled, showModal]);


  /**
   * Fired when the user clicks on a delete space menu item
   */
  const handleDeleteSpace = useCallback(async (targetSpace: ICompanySpaceRecord) => {
    if (
      // Skip the confirmation if safe mode is not enabled
      !isSafeModeEnabled ||
      // Request confirmation
      ((await showConfirmModal(showModal, {
        title: 'Delete Space',
        confirmButtonLabel: 'Yes, Delete',
        confirmButtonColor: BUTTON_COLOR.DANGER,
        content: (
          <p>
            <span>Are you sure you want to delete </span>
            <strong>{targetSpace.name}</strong>
            <span>?</span>
          </p>
        ),
      })).success === true)
    ) {
      deleteSpace(targetSpace.id);
    }
  }, [deleteSpace, isSafeModeEnabled, showModal]);


  /**
   * Fired when the user clicks the "Show Root Node" menu item in the context menu
   */
  const handleShowRootNode = useCallback(() => {
    saveLocalPref('isRootNodeVisible', true);
    // TODO: once the local preferences are stored in a provider, this will not be necessary.
    forceUpdate();
  }, [forceUpdate]);


  /**
   * Fired when the user clicks the "Hide Root Node" menu item in the context menu
   */
  const handleHideRootNode = useCallback(() => {
    saveLocalPref('isRootNodeVisible', false);
    // TODO: once the local preferences are stored in a provider, this will not be necessary.
    forceUpdate();
  }, [forceUpdate]);


  /**
   * Fired when the user toggles the search bar on or off
   */
  const handleToggleSearchBar = useCallback((newVisible: boolean) => {
    saveLocalPref('isSearchBarVisible', newVisible);
    // TODO: once the local preferences are stored in a provider, this will not be necessary.
    forceUpdate();
  }, [forceUpdate]);


  /**
   * Combines the two error values for both locations and spaces into a single error for display
   */
  useEffect(() => {
    if (companyLocationsError && companySpacesError) setError([companyLocationsError, companySpacesError]);
    else if (companyLocationsError) setError(companyLocationsError);
    else if (companySpacesError) setError(companySpacesError);
    else setError(undefined);
  }, [companyLocationsError, companySpacesError]);


  /**
   * When the component us un-mounted, make sure any data loading operations are aborted
   */
  useEffect(() => () => {
    if (companyLocationsAbortController.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      companyLocationsAbortController.current.abort();
    }
    if (companySpacesAbortController.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      companySpacesAbortController.current.abort();
    }
  }, []);


  /**
   * When the component is mounted, load the data
   */
  useMountEffect(() => {
    loadLocationsData();
    loadSpacesData();
  });


  // Render the component
  return (
    <div
      className={
        classNames('widget company-locations', className)
      }
    >
      {/* The SplitView renders the tree on the left and the vertical form on the right */}
      <SplitView
        initialSplitPercent={35}
        leftChild={(
          // Render the company locations tree
          <CompanyLocationsPortalTreeView
            isLoading={isLoadingCompanyLocations || isLoadingCompanySpaces}
            error={error}
            isReadOnly={isEditingSelectedRecord || !canEditLocationTree}
            isLocked={isEditingSelectedRecord}
            isSafeModeEnabled={isSafeModeEnabled}
            isRootNodeVisible={isRootNodeVisible}
            isSearchBarVisible={isSearchBarVisible}
            company={rowData}
            companyLocations={companyLocations}
            companySpaces={companySpaces}
            selectedNodes={selectedNodes}
            onSelectedNodesChanged={handleCompanyLocationsTreeSelectedNodesChanged}
            onDropNode={handleDropNode}
            onEnterSafeMode={handleCompanyLocationsTreeEnterSafeMode}
            onExitSafeMode={handleCompanyLocationsTreeExitSafeMode}
            onMoveNodeUp={handleMoveNodeUp}
            onCreateLocation={handleCreateLocation}
            onCreateSpace={handleCreateSpace}
            onDeleteLocation={handleDeleteLocation}
            onDeleteSpace={handleDeleteSpace}
            onHideRootNode={handleHideRootNode}
            onShowRootNode={handleShowRootNode}
            onToggleSearchBar={handleToggleSearchBar}
          />
        )}
        rightChild={(
          // Render data for the selected record in a form
          <CompanyLocationsWidgetSelectedRecordDetail
            selectedRecord={selectedRecord}
            selectedRecordChecksum={selectedRecordChecksum}
            selectedNodeCount={selectedNodes.length}
            companyId={companyId}
            isEditing={isEditingSelectedRecord}
            locationsBaseRoute={locationsBaseRoute}
            locationsApiRoute={internalLocationsApiRoute}
            locationsApiQuery={internalLocationsApiQuery}
            locationsPossibleActions={companyLocationsWidgetPossibleLocationActions}
            spacesBaseRoute={spacesBaseRoute}
            spacesApiRoute={internalSpacesApiRoute}
            spacesApiQuery={internalSpacesApiQuery}
            spacesPossibleActions={companyLocationsWidgetPossibleSpaceActions}

            onStartEditRecord={handleStartEditSelectedRecord}
            onEndEditRecord={handleEndEditSelectedRecord}
            onDirtyChange={onDirtyChange}
          />
        )}
      />
    </div>
  );
};
