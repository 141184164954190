import React from 'react';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';

import AsyncSelect from './async-select';

export type ContractRegionPickerProps = AsyncSelectComponentProps;

export const ContractRegionPicker: React.FC<ContractRegionPickerProps> = (props) => (
  <AsyncSelect
    {...props}
    searchRoute="/contract-region"
    loadAndKeepAll
  />
);
