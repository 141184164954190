import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Table } from 'reactstrap';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import { ICachedMyobTransactionRecord } from '../../../types/myob-advanced/cached-myob-project-transaction.record.interface';
import { IMyobTransactionReportRecord } from '../../../types/myob-advanced/myob-transaction-report-record.interface';
import Icon from '../../layout-helpers/icon';
import { APIContext } from '../../providers/api-provider';
import { CategoryTreeManager } from './report-category-tree/category-tree-manager';
import { CategoryTree, defaultCategoryTree } from './report-category-tree/default-category-tree';
import { TransactionReportDetailRows } from './transaction-report-detail-rows';


/**
 * Myob Transaction Report
 *
 * @param props
 * @returns {React.FC}
 */
export const MyobTransactionReport: React.FC<{ projectId: number }> = (props) => {
  const { projectId } = props;
  const { apiFetch } = useContext(APIContext);
  const transactionsAbortController = useRef<AbortController | null>(null);
  const [reportData, setReportData] = useState<IMyobTransactionReportRecord[] | null>(null);
  const [reportSource, setReportSource] = useState<ICachedMyobTransactionRecord[] | null>(null);
  const [reportRefreshCount, setReportRefreshCount] = useState(1);
  const [loadingError, setLoadingError] = useState<string | null>(null);
  const [controlCategoryTree, setControlCategoryTree] = useState<CategoryTree>(defaultCategoryTree);
  const [workingCategoryTree, setWorkingCategoryTree] = useState<CategoryTree>(defaultCategoryTree.filter((category) => category.active));
  const [showTotals, setShowTotals] = useState(true);
  const refTimer = useRef<number | null>(null);


  /**
   * Assign Transaction to Category
   *
   * @param mapContainer
   * @param transaction
   * @param treeDepth
   */
  const assignTransactionToCategory = (
    mapContainer: IMyobTransactionReportRecord[],
    transaction: ICachedMyobTransactionRecord,
    treeDepth: number,
  ) => {
    if (!workingCategoryTree[treeDepth]) return;
    const transactionTitle = workingCategoryTree[treeDepth].getTitle(transaction) || '-';
    const transactionDescription = workingCategoryTree[treeDepth].getDescription(transaction) || '-';
    let mappedRecord = mapContainer.find((record) => record.title === transactionTitle);
    if (mappedRecord) {
      mappedRecord.amount += parseFloat(transaction.amount);
      mappedRecord.transactions.push(transaction);
    } else {
      mappedRecord = {
        title: transactionTitle,
        description: transactionDescription,
        amount: parseFloat(transaction.amount),
        transactions: [
          transaction,
        ],
        details: [],
      };
      mapContainer.push(mappedRecord);
    }
  };


  /**
   * Augment Summary line
   *
   * @param summaryLine
   * @param treeDepth
   *
   * @returns array
   */
  const augmentSummaryLine = (
    summaryLine: IMyobTransactionReportRecord,
    treeDepth: number,
  ) => {
    summaryLine.transactions.map(
      (transaction: ICachedMyobTransactionRecord) => assignTransactionToCategory(
        summaryLine.details,
        transaction,
        treeDepth + 1,
      ),
    );
    const nextTreeDepth = treeDepth + 1;
    if (workingCategoryTree[nextTreeDepth + 1]) {
      summaryLine.details.map(
        (detailLine: IMyobTransactionReportRecord) => augmentSummaryLine(detailLine, nextTreeDepth),
      );
    }
    return summaryLine;
  };


  /**
   * Map Report Data
   */
  const mapReportData = useCallback((transactionRecords: ICachedMyobTransactionRecord[]) => {
    const mappedReportData: IMyobTransactionReportRecord[] = [];
    for (let index = 0; index < transactionRecords.length; index += 1) {
      const transaction = transactionRecords[index];
      assignTransactionToCategory(mappedReportData, transaction, 0);
    }
    mappedReportData.map((summaryLine: IMyobTransactionReportRecord) => {
      augmentSummaryLine(summaryLine, 0);
      return summaryLine;
    });
    setReportData(mappedReportData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workingCategoryTree]);


  /**
   * Load Project Transactions
   */
  const loadProjectTransactions = useCallback(async () => {
    if (transactionsAbortController.current) {
      transactionsAbortController.current.abort();
    }
    transactionsAbortController.current = apiAborter();

    const response = await apiFetch(
      `/project/${projectId}/myob-transaction-report`,
      {
        name: 'BillAndPurchaseOrderUpdatePanelOrderList:load',
        signal: transactionsAbortController.current.signal,
      },
    );

    if (response.success) {
      transactionsAbortController.current = null;
      if (reportRefreshCount < 4) {
        if (refTimer.current !== null) {
          window.clearTimeout(refTimer.current);
          refTimer.current = null;
        }

        /**
         * Run a timer to refresh the report in increasing intervals
         */
        refTimer.current = window.setTimeout(() => {
          setReportRefreshCount(reportRefreshCount + 1);
          loadProjectTransactions();
        }, 5000 * (reportRefreshCount + 1));
      }
      setReportSource(response.body.data);
    } else if (!response.aborted) {
      setLoadingError(response.error);
    }
  }, [apiFetch, projectId, reportRefreshCount]);


  /**
   * Update Category Tree
   */
  const updateControlCategoryTree = useCallback((newCategoryTree: CategoryTree) => {
    setControlCategoryTree(newCategoryTree);
  }, []);


  /**
   * Update Category Tree
   */
  const updateWorkingCategoryTree = useCallback((newCategoryTree: CategoryTree) => {
    setWorkingCategoryTree(newCategoryTree);
    if (reportSource) mapReportData(reportSource);
  }, [mapReportData, reportSource]);


  /**
   * Tree Change Effect
   */
  useEffect(() => {
    if (reportSource) {
      mapReportData(reportSource);
    }
  }, [workingCategoryTree, mapReportData, reportSource]);


  /**
   * Mount Effect and Cleanup
   */
  useEffect(() => {
    loadProjectTransactions();
    return () => {
      if (transactionsAbortController.current) {
        transactionsAbortController.current.abort();
      }
      if (refTimer.current !== null) {
        window.clearTimeout(refTimer.current);
      }
    };
  }, [loadProjectTransactions, projectId]);


  /**
   * Render
   */
  return (
    <div className="mt-3 myob-transaction-report">
      <h4 className="report-title">
        <div>
          Transactions Report
        </div>
        <div>
          <CategoryTreeManager
            controlCategoryTree={controlCategoryTree}
            setControlCategoryTree={updateControlCategoryTree}
            setWorkingCategoryTree={updateWorkingCategoryTree}
          />
          {' '}
          <Button color="purple" size="sm" onClick={() => setShowTotals(!showTotals)}>
            <Icon i="money" className="pr-2" />
            {showTotals ? 'Hide' : 'Show'}
            {' '}
            Totals
          </Button>
        </div>
      </h4>
      {loadingError && <Alert color="danger">{loadingError}</Alert>}
      {reportSource === null ? <div className="text-center"><Icon i="rolling" /></div> : (
        <Table className="color-table purple-table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Description</th>
              <th className="text-right currency">Amount</th>
            </tr>
          </thead>
          <tbody>
            {(reportSource.length === 0) ? (
              <tr>
                <td colSpan={3} className="text-center">
                  No transactions stored yet... will refresh shortly.
                  NB: if there are no transactions in MYOB for this project, this will remain empty.
                  <div className="text-center pt-3"><Icon i="rolling" /></div>
                </td>
              </tr>
            )
              :
              <TransactionReportDetailRows reportData={reportData || []} indentLevel={0} showTotals={showTotals} title="Net" />}
          </tbody>
        </Table>
      )}
    </div>
  );
};
