import React from 'react';
import Select from 'react-select';

type YearMonthPickerProps = {
  monthOptions: { [key: string]: unknown }[],
  monthOptionValueKey?: string,
  monthOptionLabelKey?: string,
  yearOptions: { [key: string]: unknown }[],
  yearOptionValueKey?: string,
  yearOptionLabelKey?: string,

  year: number,
  month: number,
  label: string,

  onChange?: (field: {
    fieldName: 'month' | 'year',
    newValue: number
  }) => unknown,
};

export const YearMonthPicker: React.FC<YearMonthPickerProps> = (props) => {
  const {
    year,
    month,
    label,
    yearOptions,
    monthOptions,
    onChange,
    monthOptionValueKey = 'id',
    monthOptionLabelKey = 'name',
    yearOptionValueKey = 'id',
    yearOptionLabelKey = 'name',
  } = props;
  return (
    <div className="year-month-picker">
      <div className="form-inline">
        <div className="form-group">
          {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
          <label className="form-label">{label || 'Date:'}</label>
          <Select
            className="Select month-select date-start-month"
            classNamePrefix="Select"
            options={monthOptions}
            getOptionValue={(option) => option[monthOptionValueKey]}
            getOptionLabel={(option) => option[monthOptionLabelKey]}
            value={monthOptions.find((option) => option[monthOptionValueKey] === month)}
            isClearable={false}
            onChange={(selection) => {
              if (onChange) {
                onChange({
                  fieldName: 'month',
                  newValue: selection ? selection[monthOptionValueKey] : null,
                });
              }
            }}
            menuPortalTarget={document.getElementById('portal_container')}
            menuPlacement="auto"
            menuShouldScrollIntoView={false}
          />
          <Select
            className="Select year-select date-start-year"
            classNamePrefix="Select"
            options={yearOptions}
            getOptionValue={(option) => option[yearOptionValueKey]}
            getOptionLabel={(option) => option[yearOptionLabelKey]}
            value={yearOptions.find((option) => option[yearOptionValueKey] === year)}
            isClearable={false}
            onChange={(selection) => {
              if (onChange) {
                onChange({
                  fieldName: 'year',
                  newValue: selection ? selection[yearOptionValueKey] : null,
                });
              }
            }}
            menuPortalTarget={document.getElementById('portal_container')}
            menuPlacement="auto"
            menuShouldScrollIntoView={false}
          />
        </div>
      </div>
    </div>
  );
};
