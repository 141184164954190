import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Card, CardBody, CardHeader, CardText } from 'reactstrap';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import TimeSeries from 'fusioncharts/fusioncharts.timeseries';

import moment from 'moment';
import { LoadingSpinner } from '../layout-helpers/loading-spinner';
import { APIContext } from '../providers/api-provider';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { IProjectRecord } from '../../types/project/project.record.interface';


ReactFC.fcRoot(FusionCharts, TimeSeries, Charts, FusionTheme);

export interface IDToolsMarginTrackingProps {
  dtoolsId: string,
  projectData: IProjectRecord,
}

/* {{
  Number: 'P14099QSW',
  Name: 'Pine Rivers Magistrates Court 2',
  UserName: 'Andy Photellis',
  ComputerUserName: 'Andy Photellis',
  Name1: 'Check In',
  Date: '/Date(1543426784647)/',
  Field: 'Total Price',
  OldValue: '$152,177.40',
  NewValue: '$139,472.40',
}} */
export type DtoolsTrackingRecord = {
  Number: string,
  Name: string,
  UserName: string,
  ComputerUserName: string,
  Name1: string,
  Date: string,
  Field: string,
  OldValue: string,
  NewValue: string,
}

// @todo find or create this universally for fusion charts somewhere else!
interface IDataSource<T> {
  type: string,
  renderAt: string,
  width: string,
  height: string,
  dataSource: {
    data: T | null,
    chart: Record<string, unknown>,
    caption: {
      text: string,
    },
    subcaption: {
      text: string,
    },
    series?: string,
    yaxis:
      {
        plot: string,
        title: string,
        type?: string,
        connectnulldata?: boolean,
        format?: {
          prefix?: string,
          suffix?: string,
        },
      }[],
  },
}

type Chart = {
  dispose: () => void,
}

/**
 * MarginTracking component
 */
export const DToolsMarginTracking: React.FC<IDToolsMarginTrackingProps> = ({ dtoolsId, projectData }) => {
  const [loading, setLoading] = useState(true);
  const [marginChart, setMarginChart] = useState<Chart | null>(null);
  const [dataTable, setDataTable] = useState<[string, string, number][]>([]);
  const [marginDataTable, setMarginDataTable] = useState<[string, string, number][]>([]);
  const [dollarstimeseriesDs, setDollarsTimeseriesDs] = useState<IDataSource<unknown>>({
    type: 'timeseries',
    renderAt: 'container',
    width: '100%',
    height: '400',
    dataSource: {
      data: null,
      chart: {},
      caption: {
        text: 'Cost and Price Over Time',
      },
      subcaption: {
        text: 'From D-Tools Check-In History',
      },
      series: 'Type',
      yaxis: [
        {
          plot: 'value',
          title: 'Value',
          format: {
            prefix: '$',
          },
        },
      ],
    },
  });

  const [marginTimeSeriesDs, setMarginTimeSeriesDs] = useState<IDataSource<unknown>>({
    type: 'timeseries',
    renderAt: 'container',
    width: '100%',
    height: '400',
    dataSource: {
      data: null,
      chart: {},
      caption: {
        text: 'Margin Percentage over Time',
      },
      subcaption: {
        text: 'From D-Tools Check-In History',
      },
      series: 'Type',
      yaxis: [
        {
          plot: 'value',
          title: 'Value',
          connectnulldata: true,
          format: {
            prefix: '%',
          },
        },
      ],
    },
  });

  const { apiFetch } = useContext(APIContext);
  const abortController = useRef<AbortController | null>(null);


  /**
   * Load Data
   *
   * @returns void
   */
  const loadData = useCallback(() => {
    if (abortController.current) {
      abortController.current.abort();
    }
    abortController.current = apiAborter();
    apiFetch(
      `/dtools-connector/project/${dtoolsId}/tracking`,
      {
        name: 'DToolsMarginTracking:load',
        signal: abortController?.current?.signal,
      },
    ).then((response) => {
      if (response.success) {
        abortController.current = null;
        const data: DtoolsTrackingRecord[] = response.body.data || [];
        const dateTable: [ string, string, number ][] = [];
        const dates: { [key: string]: { Price?: number, Cost?: number } } = {};
        const doubleDateMargins: [ string, string, number ][] = [];
        const runningDateMargins: [ string, string, number ][] = [];

        // storing running last value
        let runningCost: number | null = null;
        let runningPrice: number | null = null;

        // iterate records
        for (let i = 0; i < data.length; i += 1) {
          const logRecord = data[i];
          const formattedTime = moment(parseInt(logRecord.Date.substr(6, 13), 10)).add(moment().utcOffset(), 'minute').format();
          if (logRecord.Field === 'Price' || logRecord.Field === 'Cost') {
            // Cost/Price Chart Calculations
            const numberValue = parseFloat(logRecord.NewValue.replace(/[^0-9.-]+/g, ''));
            dateTable.push([formattedTime, logRecord.Field, numberValue]);

            // Margin Chart Calculations
            if (logRecord.Field === 'Cost' && numberValue) {
              runningCost = numberValue + 0;
            }
            if (logRecord.Field === 'Price' && numberValue) {
              runningPrice = numberValue + 0;
            }
            // if it exists, add the other column.
            if (dates[formattedTime]) {
              dates[formattedTime][logRecord.Field] = numberValue;
              const priceValue = dates[formattedTime].Price;
              const costValue = dates[formattedTime].Cost;

              // if we have both values, calculate margin
              if (costValue && priceValue) {
                doubleDateMargins.push([
                  formattedTime,
                  'Actual',
                  Math.round(((priceValue - costValue) / priceValue) * 10000) / 100,
                ]);
              }

              // if we have both running values, calculate running margin
              if (runningCost && runningPrice) {
                runningDateMargins.push([
                  formattedTime,
                  'Interpolated',
                  Math.round(((runningPrice - runningCost) / runningPrice) * 10000) / 100,
                ]);
              }

            // otherwise, create the date
            } else {
              dates[formattedTime] = { [logRecord.Field]: numberValue };
            }
          }
        }
        // sort the merged data
        const sortedMergedData = [...doubleDateMargins, ...runningDateMargins].sort((a, b) => (a[0] < b[0] ? -1 : 1));

        // update the data table states and trigger chart render
        setMarginDataTable(Object.keys(dates).length > 1 ? sortedMergedData : []);
        setDataTable(Object.keys(dates).length > 1 ? dateTable : []);
        setLoading(false);
      } else if (!response.aborted) {
        abortController.current = null;
        // TODO: error state?
        console.error('DTools Project Tracking Error', response.error);
        setLoading(false);
      }
    });

    return () => {
      if (abortController.current) abortController.current.abort();
    };
  }, [apiFetch, dtoolsId]);

  //  on mount effect
  useEffect(() => {
    loadData();
    return () => {
      if (abortController.current) abortController.current.abort();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // remove chart on dismount
  useEffect(() => () => {
    if (marginChart) marginChart.dispose();
    if (abortController.current) abortController.current.abort();
  }, [loadData, marginChart]);

  useEffect(() => {
    const fusionTable = new FusionCharts.DataStore().createDataTable(
      dataTable,
      [{
        name: 'Time',
        type: 'date',
      }, {
        name: 'Type',
        type: 'string',
      }, {
        name: 'Sales Value',
        type: 'number',
      }],
    );
    const newDollarsTimeseriesDs = { ...dollarstimeseriesDs };
    newDollarsTimeseriesDs.dataSource.data = fusionTable;
    setDollarsTimeseriesDs(newDollarsTimeseriesDs);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTable]);


  useEffect(() => {
    const fusionTable = new FusionCharts.DataStore().createDataTable(
      marginDataTable,
      [{
        name: 'Time',
        type: 'date',
      }, {
        name: 'Type',
        type: 'string',
      }, {
        name: 'Margin',
        type: 'number',
      }],
    );
    const newMarginTimeSeriesDs = { ...marginTimeSeriesDs };
    newMarginTimeSeriesDs.dataSource.data = fusionTable;
    setMarginTimeSeriesDs(newMarginTimeSeriesDs);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marginDataTable]);

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <Card>
          <CardHeader className="bg-info text-white" tag="h4">
            Project Margin Tracking
          </CardHeader>
          <CardBody>
            <CardText>
              <strong>Project Activation Date: &nbsp;</strong>
              {(projectData.user_audit.activated_at && projectData.user_audit.activated_at !== null) ? (
                moment(projectData.user_audit.activated_at).format('D/M/YY h:mm a')
              ) : (
                ' N/A here. Check Events Tab, activated prior to OC Process'
              )}
            </CardText>
            {dataTable.length > 0 ? (
              <ReactFC {...dollarstimeseriesDs} onRender={(chart: Chart) => setMarginChart(chart)} />
            ) : (
              <CardText>
                There were not enough cost/price records for this project to be able to render the Cost/Price chart
                - it may pre-date the tracking in D-Tools, or have only one data point.
              </CardText>
            )}
            {marginDataTable.length > 0 ? (
              <ReactFC {...marginTimeSeriesDs} />
            ) : (
              <CardText>
                There were not enough cost/price records for this project to be able to render the Margin chart
                - it may pre-date the tracking in D-Tools, or have only one data point.
              </CardText>
            )}
            <div>&nbsp;</div>
          </CardBody>
          {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
        </Card>
      )}
    </>
  );
};
