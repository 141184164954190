import ApiQueryAssertions from '../api-query-assertions';

/**
 * @class
 * @name BaseApiQueryDefinition
 *
 * @description
 * Contains settings required to query the Portal API
 *
 * @abstract
 *
 * TODO document in the wiki
 */
class BaseApiQueryDefinition {
  /**
   * @constructor
   *
   * @param {{
   *    availableFlags?: string[],
   *    baseWithParameters?: string[],
   *    baseFlags?: string[],
   *    baseFilters?: { field: string, operation: string, values: ( string | number | boolean )[] }[],
   *    customFlags?: string[],
   *    customFilters?: { field: string, operation: string, values: ( string | number | boolean )[] }[],
   *    customSorts?: { field: string, direction: 'asc' | 'desc', sortIndex: number }[],
   *    searchTerm?: string,
   *    activePage?: number,
   *    pageSize?: number,
   *  }} param0
   */
  constructor({
    availableFlags,
    baseWithParameters,
    baseFlags,
    baseFilters,
    customFlags,
    customFilters,
    customSorts,
    searchTerm,
    activePage,
    pageSize,
  } = {}) {
    if (availableFlags) this.availableFlags = availableFlags;
    if (baseWithParameters) this.baseWithParameters = baseWithParameters;
    if (baseFlags) this.baseFlags = baseFlags;
    if (baseFilters) this.baseFilters = baseFilters;

    if (customFlags) this.customFlags = customFlags;
    if (customFilters) this.customFilters = customFilters;
    if (customSorts) this.customSorts = customSorts;
    if (searchTerm) this.searchTerm = searchTerm;
    if (activePage) this.activePage = activePage;
    if (pageSize) this.pageSize = pageSize;

    this.customFilters.forEach((customFilter) => ApiQueryAssertions.assertFilterShape(this.constructor.name, customFilter, 'customFilter'));
    this.baseFilters.forEach((baseFilter) => ApiQueryAssertions.assertFilterShape(this.constructor.name, baseFilter, 'baseFilter'));
    ApiQueryAssertions.assertArrayOfSortsShape(this.constructor.name, this.customSorts);
  }

  /**
   * @description
   * Base route path for the query
   *
   * @example
   * '/news'
   * '/projects'
   *
   * @type {string} routePath
   *
   * @protected
   * @abstract
   */
  routePath = Error('Abstract property "routePath" must be overridden');

  /**
   * @description
   * Flags that are allowed to exist within the query
   *
   * @example
   * [ DATA_TABLE_FLAG.ONLY_MINE ]
   *
   * @type {string[]} availableFlags
   *
   * @protected
   */
  availableFlags = []

  /**
   * @description
   * Static "with"'s to always exist within the query
   *
   * @example
   * ['author', 'user_audit', ...]
   *
   * @type {string[]} baseWithParameters
   *
   * @protected
   */
  baseWithParameters = [];

  /**
   * @description
   * Static flags to always exist within the query
   *
   * @example
   * [ DATA_TABLE_FLAG.ONLY_MINE ]
   *
   * @type {string[]} baseFlags
   *
   * @protected
   */
  baseFlags = [];

  /**
   * @description
   * Static filters to always exist within the query
   *
   * @example
   * [{field: 'updated_at', operation: 'greater', values: '2010-10-25'}]
   *
   * @type {{
   *  field: string,
   *  operation: string,
   *  values: ( string | number | boolean )[]
   * }} baseFilters
   *
   * @protected
   */
  baseFilters = [];

  /**
   * @description
   * Dynamic set of flags to be changed by the user
   *
   * @example
   * [ DATA_TABLE_FLAG.ONLY_MINE ]
   *
   * @type {string[]} customFlags
   *
   * @public
   */
  customFlags = [];

  /**
   * @description
   * Dynamic set of filters to be changed by the user
   *
   * @example
   * [{field: 'updated_at', operation: 'greater', values: '2010-10-25'}]
   *
   * @type {{
   *  field: string,
   *  operation: string,
   *  values: ( string | number | boolean )[],
   * }[]} customFilters
   *
   * @public
   */
  customFilters = [];

  /**
   * @description
   * Dynamic set of sorts to be changed by the user
   *
   * note: there are no "baseSorts" currently because when a user wants a sort, they intend to override the "default" sorts provided
   *
   * @type {{
   *  field: string,
   *  direction: 'asc' | 'desc',
   *  sortIndex: number
   * }[]} customSorts
   *
   * @public
   */
  // TODO
  customSorts = [];

  /**
   * @description
   * Dynamic search term to be changed by the user
   *
   * @example
   * 'P12345'
   *
   * @type {string | null} searchTerm
   *
   * @public
   */
  searchTerm = null;

  /**
   * @description
   * Dynamic page number from the API response to be changed by the user
   *
   * @example
   * 13
   *
   * @type {number} activePage
   *
   * @public
   */
  activePage = 1;

  /**
   * @description
   * Dynamic page size (number of records to return from the API) to be changed by the user
   *
   * @example
   * 15
   *
   * @type {number} pageSize
   */
  pageSize = 15;
}

export default BaseApiQueryDefinition;
