import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from 'reactstrap';

const ToggleSwitch = ({ sectionVis, section, toggleSection, labels }) => (
  <ButtonGroup
    className="pull-right"
    style={{
      width: '110px',
    }}
  >
    <Button
      color="secondary"
      size="sm"
      onClick={() => toggleSection(section)}
      active={!sectionVis[section]}
    >
      {labels[0]}
    </Button>
    <Button
      color="secondary"
      size="sm"
      onClick={() => toggleSection(section)}
      active={sectionVis[section]}
    >
      {labels[1]}
    </Button>
  </ButtonGroup>
);

ToggleSwitch.defaultProps = {
  labels: ['hide', 'show'],
};

ToggleSwitch.propTypes = {
  section: PropTypes.string.isRequired,
  sectionVis: PropTypes.shape({}).isRequired,
  toggleSection: PropTypes.func.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string),
};

export default ToggleSwitch;
