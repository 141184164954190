import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Base64 } from 'js-base64';
import { Container, ButtonGroup, Button } from 'reactstrap';
import PageHeader from '../app-layout/page-header';
import * as urlStateManager from '../url-state-manager/url-state-manager';
import { PUSH_OR_REPLACE } from '../../utils/constants';
import ProjectsByStatusAgeChart, { PROJECTS_BY_STATUS_AGE_VALUE_TYPE } from '../portal-fusion-chart/projects-by-status-age-chart';
import { FUSION_CHART_TYPES_SINGLE_SERIES, FUSION_CHART_TYPES_COMBINATION } from '../../utils/fusion-chart-types';
import { startCase } from '../../utils/helpers';
import ProjectsByStatusChart from '../portal-fusion-chart/projects-by-status-chart';
import { ScrollToTopOnMount } from '../router/scroll-to-top-on-mount';
import { financialYear } from '../../helpers/financial-year-moments';
import ProjectsByStatusMonthChart, { PROJECTS_BY_STATUS_MONTH_VALUE_TYPE } from '../portal-fusion-chart/projects-by-status-month-chart';
import DrillDownTable from '../portal-data-table/drill-down-table';
import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { SERObject } from '../../helpers/ser-object.helper';
import { PROJECT_STATUS } from '../../constants/project-status.const';
import { PROJECTS_BY_STATUS_VALUE_TYPE } from '../../constants/projects-by-status-value-type.const';


const chartIds = {
  AGED_PROPOSALS: 'chart_aged_proposals',
  PIPELINE: 'chart_pipeline',
  FINANCIAL_YEAR_NOW: 'chart_financial_year_now',
  FINANCIAL_YEAR_LAST: 'chart_financial_year_last',
  FINANCIAL_YEAR_LAST_2: 'chart_financial_year_last_2',
};

const getFinancialYearChartStatusOptions = () => [
  {
    name: 'New Leads',
    statusId: PROJECT_STATUS.LEAD,
    visible: true,
  },
  {
    name: 'New Proposals',
    statusId: PROJECT_STATUS.PROPOSAL,
    visible: true,
  },
  {
    name: 'Lost Opportunities',
    statusId: PROJECT_STATUS.LOST,
    visible: false,
  },
  {
    name: 'Projects Activated',
    statusId: PROJECT_STATUS.ACTIVE,
    visible: true,
  },
  {
    name: 'Projects Completed',
    statusId: PROJECT_STATUS.COMPLETED,
    visible: true,
  },
];

const agedProposalsStatusOptions = [{
  statusId: PROJECT_STATUS.PROPOSAL,
}];

// TODO: rename Pipeline to Pipeline for 19/20 FY

class ExecDashboard extends React.Component {
  /**
   * @constructor
   *
   * @param {{}} props
   */
  constructor(props) {
    super(props);

    this.state = {
      charts: {
        [chartIds.AGED_PROPOSALS]: {
          isLoading: true,
        },
        [chartIds.PIPELINE]: {
          isLoading: true,
        },
        [chartIds.FINANCIAL_YEAR_NOW]: {
          isLoading: true,
          statusOptions: getFinancialYearChartStatusOptions(),
        },
        [chartIds.FINANCIAL_YEAR_LAST]: {
          isLoading: true,
          statusOptions: getFinancialYearChartStatusOptions(),
        },
        [chartIds.FINANCIAL_YEAR_LAST_2]: {
          isLoading: true,
          statusOptions: getFinancialYearChartStatusOptions(),
        },
      },
    };
  }


  /**
   * @description
   * Get the current drillDownSource
   *
   * @returns {{ title: string, params: Record<string, string>, chartId: string } |  null}
   */
  get drillDownSource() {
    const { urlState, setUrlState } = this.props;
    const { drillDownSource } = urlState;

    const decodeOperation = this.decodeDrillDownSource(drillDownSource);

    if (!decodeOperation.success) {
      // failed - unset drillDownSource
      setUrlState({ drillDownSource: null });
      return null;
    }

    return decodeOperation.result;
  }


  /**
   * @description
   * decode a drillDownSource object
   *
   * @returns {SERObject}
   */
  decodeDrillDownSource = (drillDownSource) => {
    const ser = new SERObject(true);
    try {
      ser.result = drillDownSource ? JSON.parse(Base64.decode(drillDownSource)) : null;
    }
    catch (e) {
      ser.success = false;
      ser.error = `Unable to get drillDownSource ${e}`;
      console.error('Unable to get drillDownSource', e);
    }
    return ser;
  }


  /**
   * @description
   * Remove the drill down source from the URL and effectively close whatever drilldown table is visible
   */
  closeDrillDownTable = () => {
    const { setUrlState } = this.props;
    setUrlState({ drillDownSource: null });
  }


  /**
   * @description
   * Fired when the user asks to change the value type of one of the charts
   */
  setChartValueType = (chartId, valueType) => {
    const { urlState, setUrlState } = this.props;

    if (chartId === chartIds.AGED_PROPOSALS && urlState.agedProposalsChartValueType !== valueType) {
      setUrlState({ agedProposalsChartValueType: valueType }, PUSH_OR_REPLACE.REPLACE);
    }

    else if (chartId === chartIds.PIPELINE && urlState.pipelineChartValueType !== valueType) {
      setUrlState({ pipelineChartValueType: valueType }, PUSH_OR_REPLACE.REPLACE);
    }

    else if (chartId === chartIds.FINANCIAL_YEAR_NOW && urlState.financialYearNowValueType !== valueType) {
      setUrlState({ financialYearNowValueType: valueType }, PUSH_OR_REPLACE.REPLACE);
    }

    else if (chartId === chartIds.FINANCIAL_YEAR_LAST && urlState.financialYearLastValueType !== valueType) {
      setUrlState({ financialYearLastValueType: valueType }, PUSH_OR_REPLACE.REPLACE);
    }

    else if (chartId === chartIds.FINANCIAL_YEAR_LAST_2 && urlState.financialYearLast2ValueType !== valueType) {
      setUrlState({ financialYearLast2ValueType: valueType }, PUSH_OR_REPLACE.REPLACE);
    }
  }


  /**
   * @description
   * Fired when one of the charts starts or finishes loading
   */
  handleChartLoading = (chartId, isLoading) => {
    const { charts } = this.state;

    this.setState({
      charts: {
        ...charts,
        [chartId]: {
          ...charts[chartId],
          isLoading,
        },
      },
    });
  }


  /**
   * @description
   * Get the title for a Drill Down Table corresponding to the Plot Data Item
   *
   * @param {(typeof chartIds)} chartId
   * @param {{
   *  source: {
   *    params: Record<string, string>,
   *    source: string | 'http://api.ciportal.local/v1/project',
   *  },
   *  [responseKey: string]: string | number,
   * }} dataItem
   * @param {{
   *  categoryLabel: string | '120 Days'
   *  dataValue: string | number | 319
   *  displayValue: string | '319'
   *  toolText: '120 Days, 319',
   * }} dataPlot
   */
  getDrillDownTitleFromDataItem = (chartId, dataItem, dataPlot) => {
    switch (chartId) {
      // Aged proposals
      case chartIds.AGED_PROPOSALS: {
        const statusId = dataItem.status_id;
        let statusDescription = '';

        if (statusId === PROJECT_STATUS.LEAD) statusDescription = 'Leads';
        else if (statusId === PROJECT_STATUS.PROPOSAL) statusDescription = 'Proposals';
        else if (statusId === PROJECT_STATUS.LOST) statusDescription = 'Lost Projects';
        else if (statusId === PROJECT_STATUS.ACTIVE) statusDescription = 'Active Projects';
        else if (statusId === PROJECT_STATUS.COMPLETED) statusDescription = 'Completed Projects';
        else if (statusId === PROJECT_STATUS.ARCHIVED) statusDescription = 'Archived Projects';
        else throw new ReferenceError(`Unable to get phrase for Project Status: "${statusId}"`);

        return `${statusDescription} at ${dataPlot.categoryLabel}`;
      }
      // Pipeline
      case chartIds.PIPELINE: {
        const statusId = dataItem.status_id;
        let statusDescription = '';

        if (statusId === PROJECT_STATUS.LEAD) statusDescription = 'Leads Created';
        else if (statusId === PROJECT_STATUS.PROPOSAL) statusDescription = 'Proposals Created';
        else if (statusId === PROJECT_STATUS.LOST) statusDescription = 'Opportunities Lost';
        else if (statusId === PROJECT_STATUS.ACTIVE) statusDescription = 'Projects Activated';
        else if (statusId === PROJECT_STATUS.COMPLETED) statusDescription = 'Projects Completed';
        else if (statusId === PROJECT_STATUS.ARCHIVED) statusDescription = 'Projects Archived';
        else throw new ReferenceError(`Unable to get phrase for Project Status: "${statusId}"`);

        const start = financialYear();
        const end = financialYear({ endOfYear: true });
        return `${statusDescription} in FY ${start.format('YY')}/${end.format('YY')}`;
      }
      // Financial year summary
      case chartIds.FINANCIAL_YEAR_LAST:
      case chartIds.FINANCIAL_YEAR_LAST_2:
      case chartIds.FINANCIAL_YEAR_NOW: {
        const statusId = dataItem.status_id;
        let statusDescription = '';

        if (statusId === PROJECT_STATUS.LEAD) statusDescription = 'Leads Created';
        else if (statusId === PROJECT_STATUS.PROPOSAL) statusDescription = 'Proposals Created';
        else if (statusId === PROJECT_STATUS.LOST) statusDescription = 'Opportunities Lost';
        else if (statusId === PROJECT_STATUS.ACTIVE) statusDescription = 'Projects Activated';
        else if (statusId === PROJECT_STATUS.COMPLETED) statusDescription = 'Projects Completed';
        else if (statusId === PROJECT_STATUS.ARCHIVED) statusDescription = 'Projects Archived';
        else throw new ReferenceError(`Unable to get phrase for Project Status: "${statusId}"`);

        return `${statusDescription} in ${dataPlot.categoryLabel}`;
      }
      default: throw new ReferenceError(`Unhandled chartId "${chartId}"`);
    }
  }


  /**
   * @description
   * Get details for rendering out a financial year chart or drilldown
   *
   * @param {string} chartId
   */
  getFinancialYearChartDetails = (chartId) => {
    const { urlState } = this.props;
    const { charts } = this.state;

    let addYears = 0;
    let financialYearValueType = PROJECTS_BY_STATUS_MONTH_VALUE_TYPE.VALUE;
    let tableIdentifier = TABLE_IDENTIFIER.EXEC_DASH_FY_DRILLDOWN_TABLE_TABLE;
    let chartClassName = 'current';

    switch (chartId) {
      case chartIds.FINANCIAL_YEAR_NOW:
        addYears = 0;
        financialYearValueType = urlState.financialYearNowValueType;
        chartClassName = 'current';
        tableIdentifier = TABLE_IDENTIFIER.EXEC_DASH_FY_DRILLDOWN_TABLE_TABLE;
        break;
      case chartIds.FINANCIAL_YEAR_LAST:
        addYears = -1;
        financialYearValueType = urlState.financialYearLastValueType;
        chartClassName = 'last';
        tableIdentifier = TABLE_IDENTIFIER.EXEC_DASH_FY_LAST_DRILLDOWN_TABLE;
        break;
      case chartIds.FINANCIAL_YEAR_LAST_2:
        addYears = -2;
        financialYearValueType = urlState.financialYearLast2ValueType;
        chartClassName = 'last-2';
        tableIdentifier = TABLE_IDENTIFIER.EXEC_DASH_FY_LAST_2_DRILLDOWN_TABLE;
        break;
      default: throw new Error('Invalid chartId');
    }

    const { isLoading } = charts[chartId];

    const start = financialYear({ addYears });
    const end = financialYear({ addYears, endOfYear: true });

    const title = `FY ${start.format('YY')}/${end.format('YY')}`;

    return {
      start,
      end,
      title,
      tableIdentifier,
      chartClassName,
      financialYearValueType,
      isLoading,
    };
  }


  /**
   * @description
   * Fired when a Data Item is clicked
   *
   * @param {string} chartId
   * @param {object} dataItem
   * @param {object} dataPlot
   */
  handleClickDataItem = (chartId, dataItem, dataPlot) => {
    const { setUrlState } = this.props;

    const encodedDrillDownSource = Base64.encodeURI(JSON.stringify({
      chartId,
      title: this.getDrillDownTitleFromDataItem(chartId, dataItem, dataPlot),
      source: dataItem.source,
    }));

    setUrlState({ drillDownSource: encodedDrillDownSource }, PUSH_OR_REPLACE.REPLACE);
  }


  /**
   * @description
   * Fired when a statusOptions on the the fusion chart is made visible or invisible by the user
   *
   * @param {string} chartId
   * @param {number} seriesIndex
   * @param {boolean} visible
   */
  handleSeriesVisibilityChanged = (chartId, seriesIndex, visible) => {
    const { charts } = this.state;
    if (!((chartId in charts) && ('statusOptions' in charts[chartId]))) return;

    this.setState({
      charts: {
        ...charts,
        [chartId]: {
          ...charts[chartId],
          // keep the statusOptions array in order, setting visibility of targeted statusOptions
          statusOptions: charts[chartId]
            .statusOptions
            .map((statusOption, index) => (index === seriesIndex
              ? { ...statusOption, visible }
              : statusOption)),
        },
      },
    });
  }


  /**
   * @description
   * Render out the `Aged Proposals` chart
   *
   * @returns {React.Component}
   */
  renderAgedProposalsChart = () => {
    const { charts } = this.state;
    const { urlState } = this.props;
    const { agedProposalsChartValueType } = urlState;
    const { isLoading } = charts[chartIds.AGED_PROPOSALS];

    return (
      <div className="chart-wrapper aged-proposals">
        <div className="chart-title">
          <h4>Aged Proposals (Days)</h4>
          <ButtonGroup>
            {
              Object.values(PROJECTS_BY_STATUS_AGE_VALUE_TYPE).map((valueType) => (
                <Button
                  key={valueType}
                  onClick={() => this.setChartValueType(chartIds.AGED_PROPOSALS, valueType)}
                  disabled={isLoading}
                  size="sm"
                  color="secondary"
                  active={valueType === agedProposalsChartValueType}
                >
                  {startCase(valueType)}
                </Button>
              ))
            }
          </ButtonGroup>
        </div>
        <div className="chart-container">
          <ProjectsByStatusAgeChart
            id={chartIds.AGED_PROPOSALS}
            chartCaption={null}
            chartType={FUSION_CHART_TYPES_SINGLE_SERIES.COLUMN_2D}
            valueType={agedProposalsChartValueType}
            showValues
            onClickDataItem={this.handleClickDataItem}
            onClickSeriesLabel={this.handleClickSeriesLabel}
            onChangeSeriesVisibility={this.handleSeriesVisibilityChanged}
            statusOptions={agedProposalsStatusOptions}
            onLoading={this.handleChartLoading}
          />
        </div>
      </div>
    );
  }


  /**
   * @description
   * Render out the `Financial Year` chart
   *
   * @param {string} chartId
   * @returns {React.Component}
   */
  renderFinancialYearChart = (chartId) => {
    const { charts } = this.state;

    const {
      isLoading,
      financialYearValueType,
      end,
      start,
      chartClassName,
      title,
    } = this.getFinancialYearChartDetails(chartId);

    const { statusOptions } = charts[chartId];
    if (!statusOptions) throw new ReferenceError(`No statusOptions found on state for chartId ${chartId}`);

    return (
      <div className={classNames('chart-wrapper financial-year', chartClassName)}>
        <div className="chart-title">
          <h4>{title}</h4>
          <ButtonGroup>
            {
              Object.values(PROJECTS_BY_STATUS_MONTH_VALUE_TYPE).map((valueType) => (
                <Button
                  key={valueType}
                  onClick={() => this.setChartValueType(chartId, valueType)}
                  disabled={isLoading}
                  size="sm"
                  color="secondary"
                  active={valueType === financialYearValueType}
                >
                  {startCase(valueType)}
                </Button>
              ))
            }
          </ButtonGroup>
        </div>
        <div className="chart-container">
          <ProjectsByStatusMonthChart
            id={chartId}
            chartCaption={null}
            chartType={FUSION_CHART_TYPES_COMBINATION.MULTI_SERIES_2D_SINGLE_Y_COMBINATION_CHART_COLUMN_LINE_AREA}
            valueType={financialYearValueType}
            onClickDataItem={this.handleClickDataItem}
            onClickSeriesLabel={this.handleClickSeriesLabel}
            onChangeSeriesVisibility={this.handleSeriesVisibilityChanged}
            statusOptions={statusOptions}
            onLoading={this.handleChartLoading}
            monthFrom={start}
            monthTo={end}
          />
        </div>
      </div>
    );
  };


  /**
   * @description
   * TODO: render a table filtering (drilling down) to the selected dataItem
   *
   * @param {string} financialYearChartId
   * @returns {React.Component}
   */
  renderFinancialYearDrillDown = (financialYearChartId) => {
    const { drillDownSource } = this;
    const { chartId, source, title } = drillDownSource || {};

    if (chartId !== financialYearChartId) return null;

    const {
      chartClassName,
      tableIdentifier,
    } = this.getFinancialYearChartDetails(chartId);

    return (
      source.params && (
        <div className={classNames('drilldown-wrapper financial-year', chartClassName)}>
          <DrillDownTable
            title={title}
            urlIdentifier={financialYearChartId}
            tableIdentifier={tableIdentifier}
            reportFilters={source.params}
            onCloseTable={this.closeDrillDownTable}
          />
        </div>
      ));
  };


  /**
   * @description
   * Render out the `Pipeline` chart
   *
   * @returns {React.Component}
   */
  renderPipelineChart = () => {
    const { charts } = this.state;
    const { urlState } = this.props;
    const { pipelineChartValueType } = urlState;
    const { isLoading } = charts[chartIds.PIPELINE];

    const start = financialYear();
    const end = financialYear({ endOfYear: true });
    const title = `Pipeline FY ${start.format('YY')}/${end.format('YY')}`;

    return (
      <div className="chart-wrapper pipeline">
        <div className="chart-title">
          <h4>{title}</h4>
          <ButtonGroup>
            {
              Object.values(PROJECTS_BY_STATUS_VALUE_TYPE).map((valueType) => (
                <Button
                  key={valueType}
                  onClick={() => this.setChartValueType(chartIds.PIPELINE, valueType)}
                  disabled={isLoading}
                  size="sm"
                  color="secondary"
                  active={valueType === pipelineChartValueType}
                >
                  {startCase(valueType)}
                </Button>
              ))
            }
          </ButtonGroup>
        </div>

        <div className="chart-container">
          <ProjectsByStatusChart
            id={chartIds.PIPELINE}
            chartType={FUSION_CHART_TYPES_SINGLE_SERIES.COLUMN_2D}
            chartCaption={null}
            valueType={pipelineChartValueType}
            showValues
            onClickDataItem={this.handleClickDataItem}
            onLoading={this.handleChartLoading}
            dateFrom={financialYear()}
            dateTo={financialYear({ endOfYear: true })}
          />
        </div>
      </div>
    );
  }


  /**
   * @description
   * Render the drill down details of the aged proposals chart
   *
   * @returns {React.Component}
   */
  renderAgedProposalsDrillDown = () => {
    const { drillDownSource } = this;
    const { chartId, source, title } = drillDownSource || {};

    return (
      chartId && (chartId === chartIds.AGED_PROPOSALS) && (source.params) && (
        <div className="drilldown-wrapper aged-proposals">
          <DrillDownTable
            title={title}
            urlIdentifier={chartId}
            tableIdentifier={TABLE_IDENTIFIER.EXEC_DASH_AGED_PROPOSALS_DRILLDOWN_TABLE}
            reportFilters={source.params}
            onCloseTable={this.closeDrillDownTable}
          />
        </div>
      ));
  }


  /**
   * @description
   * Render the drill down details of the pipeline chart
   *
   * @returns {React.Component}
   */
  renderPipelineDrillDown = () => {
    const { drillDownSource } = this;
    const { chartId, source, title } = drillDownSource || {};

    return (
      chartId && (chartId === chartIds.PIPELINE) && (source.params) && (
        <div className="drilldown-wrapper pipeline">
          <DrillDownTable
            title={title}
            urlIdentifier={chartId}
            tableIdentifier={TABLE_IDENTIFIER.EXEC_DASH_PIPELINE_DRILLDOWN_TABLE}
            reportFilters={source.params}
            onCloseTable={this.closeDrillDownTable}
          />
        </div>
      ));
  }


  /**
   * @inheritdoc
   */
  render = () => (
    <Container fluid className="exec-dashboard">
      <PageHeader {...this.props} />
      <ScrollToTopOnMount />

      <div className="dashboard-wrapper">

        {/* Aged Proposals */}
        {this.renderAgedProposalsChart()}
        {this.renderAgedProposalsDrillDown()}

        {/* Pipeline */}
        {this.renderPipelineChart()}
        {this.renderPipelineDrillDown()}

        {/* This financial year */}
        {this.renderFinancialYearChart(chartIds.FINANCIAL_YEAR_NOW)}
        {this.renderFinancialYearDrillDown(chartIds.FINANCIAL_YEAR_NOW)}

        {/* Previous financial year */}
        {this.renderFinancialYearChart(chartIds.FINANCIAL_YEAR_LAST)}
        {this.renderFinancialYearDrillDown(chartIds.FINANCIAL_YEAR_LAST)}

        {/* Two financial years ago */}
        {this.renderFinancialYearChart(chartIds.FINANCIAL_YEAR_LAST_2)}
        {this.renderFinancialYearDrillDown(chartIds.FINANCIAL_YEAR_LAST_2)}

      </div>
    </Container>
  )
}

ExecDashboard.propTypes = {
  urlState: PropTypes.shape({
    agedProposalsChartValueType: PropTypes.oneOf(Object.values(PROJECTS_BY_STATUS_AGE_VALUE_TYPE)),
    pipelineChartValueType: PropTypes.oneOf(Object.values(PROJECTS_BY_STATUS_VALUE_TYPE)),
    financialYearNowValueType: PropTypes.oneOf(Object.values(PROJECTS_BY_STATUS_MONTH_VALUE_TYPE)),
    financialYearLastValueType: PropTypes.oneOf(Object.values(PROJECTS_BY_STATUS_MONTH_VALUE_TYPE)),
    financialYearLast2ValueType: PropTypes.oneOf(Object.values(PROJECTS_BY_STATUS_MONTH_VALUE_TYPE)),
    drillDownSource: PropTypes.string,
  }).isRequired,
  setUrlState: PropTypes.func.isRequired,
};

const URL_STATE_DEFAULTS = {
  drillDownSource: null,
  agedProposalsChartValueType: PROJECTS_BY_STATUS_AGE_VALUE_TYPE.VALUE,
  pipelineChartValueType: PROJECTS_BY_STATUS_VALUE_TYPE.VALUE,
  financialYearNowValueType: PROJECTS_BY_STATUS_MONTH_VALUE_TYPE.VALUE,
  financialYearLastValueType: PROJECTS_BY_STATUS_MONTH_VALUE_TYPE.VALUE,
  financialYearLast2ValueType: PROJECTS_BY_STATUS_MONTH_VALUE_TYPE.VALUE,
};

/**
 * @description
 * Wash the urlState coming in from the queryString
 *
 * @param {
 *  agedProposalsChartValueType?: PROJECTS_BY_STATUS_AGE_VALUE_TYPE,
 *  pipelineChartValueType?: PROJECTS_BY_STATUS_AGE_VALUE_TYPE,
 *  financialYearNowValueType?: PROJECTS_BY_STATUS_MONTH_VALUE_TYPE,
 *  financialYearLastValueType?: PROJECTS_BY_STATUS_MONTH_VALUE_TYPE,
 *  financialYearLast2ValueType?: PROJECTS_BY_STATUS_MONTH_VALUE_TYPE,
 *  drillDownSource?: string,
 * } dirtyUrlState
 */
const washUrlState = (dirtyUrlState) => {
  const cleanUrlState = {};

  cleanUrlState.agedProposalsChartValueType = Object.values(PROJECTS_BY_STATUS_AGE_VALUE_TYPE).includes(dirtyUrlState.agedProposalsChartValueType)
    ? dirtyUrlState.agedProposalsChartValueType
    : URL_STATE_DEFAULTS.agedProposalsChartValueType;

  cleanUrlState.pipelineChartValueType = Object.values(PROJECTS_BY_STATUS_AGE_VALUE_TYPE).includes(dirtyUrlState.pipelineChartValueType)
    ? dirtyUrlState.pipelineChartValueType
    : URL_STATE_DEFAULTS.pipelineChartValueType;

  cleanUrlState.drillDownSource = dirtyUrlState.drillDownSource
    ? dirtyUrlState.drillDownSource
    : URL_STATE_DEFAULTS.drillDownSource;

  cleanUrlState.financialYearNowValueType = dirtyUrlState.financialYearNowValueType
    ? dirtyUrlState.financialYearNowValueType
    : URL_STATE_DEFAULTS.financialYearNowValueType;

  cleanUrlState.financialYearLastValueType = dirtyUrlState.financialYearLastValueType
    ? dirtyUrlState.financialYearLastValueType
    : URL_STATE_DEFAULTS.financialYearLastValueType;

  cleanUrlState.financialYearLast2ValueType = dirtyUrlState.financialYearLast2ValueType
    ? dirtyUrlState.financialYearLast2ValueType
    : URL_STATE_DEFAULTS.financialYearLast2ValueType;

  return cleanUrlState;
};

export default urlStateManager.connectUrlState(
  washUrlState,
  urlStateManager.cleanDefaultsFromUrlState(URL_STATE_DEFAULTS),
)(ExecDashboard);
