
import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { startCase } from '../../../utils/helpers';

export type TrackingFormRendererItemDetailProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeData: Record<string, any>,
};

export const TrackingFormRendererItemDetail: React.FC<TrackingFormRendererItemDetailProps> = ({ changeData }) => {
  let isOldFormat = false;
  if (changeData && changeData.tv && changeData.tv === 1 && changeData.request) {
    isOldFormat = true;
  }

  const request = changeData.request || {};
  return (
    <Card>
      <CardBody>
        <table cellPadding="5px">
          <tbody>
            {isOldFormat ? Object.keys(request).map((key) => {
              const val = request[key] || '';
              return (
                <tr key={key}>
                  <td><strong>{startCase(key.replace('_', ' '))}</strong></td>
                  <td>{val !== '' ? val : <span className="text-gray">( blank )</span>}</td>
                </tr>
              );
            })
              : Object.keys(changeData).map((key) => {
                const changeSet = changeData[key];
                const changeAction = changeSet.action || '';
                const oldValue: string = changeSet.old ?? '';
                const newValue: string = changeSet.new ?? '';
                return (
                  <React.Fragment key={key}>
                    <tr>
                      <td colSpan={2}><strong>{`${startCase(key)} ${changeAction}`}</strong></td>
                    </tr>
                    {oldValue ? (
                      <tr>
                        <td><strong>Old Value:</strong></td>
                        {/* eslint-disable-next-line react/no-danger */}
                        <td><span dangerouslySetInnerHTML={{ __html: oldValue }} /></td>
                      </tr>
                    ) : null}
                    {newValue ? (
                      <tr>
                        <td><strong>New Value:</strong></td>
                        {/* eslint-disable-next-line react/no-danger */}
                        <td><span dangerouslySetInnerHTML={{ __html: newValue }} /></td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
};
