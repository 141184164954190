import React, { useContext } from 'react';
import { mapSelectInputOptions } from '../../helpers/map-select.helper';
import { ConstSelect, ConstSelectProps } from './const-select';
import { CurrentUserContext } from '../providers/current-user-provider';
import { PERMISSION } from '../../constants/permissions.const';
import { PROJECT_STATUS, PROJECT_STATUS_NAME_MAP } from '../../constants/project-status.const';

export type ProjectStatusPickerProps = Omit<ConstSelectProps,
  'inputOptions' |
  'labelKey' |
  'valueKey' |
  'getOptionValue' |
  'getOptionLabel' |
  'renderOption'
>;

/**
 * ProjectStatusPicker
 */
export const ProjectStatusPicker:React.FC<ProjectStatusPickerProps> = (props) => {
  const {
    name,
    formSaveField = null,
    formData = {},
  } = props;

  const { userHasPermissions } = useContext(CurrentUserContext);
  const canChooseArchived = userHasPermissions([PERMISSION.API_ADMIN]);


  // Only allow system administrators to choose archived
  const options = mapSelectInputOptions(PROJECT_STATUS_NAME_MAP)
    .filter((option) => (
      option.id !== PROJECT_STATUS.ARCHIVED || canChooseArchived
    ));

  const saveField = formSaveField || name;
  const value = formData[saveField];

  return (
    <ConstSelect
      {...props}
      valueKey="id"
      labelKey="name"
      value={value}
      inputOptions={options}
    />
  );
};
