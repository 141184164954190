import { GeoipService } from './geoip-service';
import { OpenWeatherMapService } from './open-weather-map-service';

/**
 * @description
 * Get the weather at the browsers location
 * Temperature is in celsius
 *
 * @returns {Promise<{
 *  temp:         string | "15",
 *  description:  string | 'clean sky',
 *  icon:         string | '01d',
 * }>}
 */
export const getCurrentWeather = () => new Promise((resolve, reject) => GeoipService
  .getLocation()
  .then((location) => OpenWeatherMapService.getWeatherAt(location))
  .then((weather) => resolve(weather))
  .catch((error) => reject(error)));
