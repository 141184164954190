import React, { useCallback } from 'react';

import { ISprintRecord } from '../../types/internal-project/sprint.record.interface';
import { NewRecordModalProps } from '../../types/modal/new-record-modal.props';

import { useIsDirty } from '../../react-hooks/use-is-dirty.hook';

import { Modal } from './Modal';
import { NewSprintForm } from '../poly-forms/new-sprint-form';

export const NewSprintModal:React.FC<NewRecordModalProps<ISprintRecord>> = (props) => {
  const {
    id, closeModal, onModalComplete, initialData,
  } = props;

  const [isDirty, setDirty] = useIsDirty();

  /**
   * Fired when the form successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, recordId: ISprintRecord['id'], newRecord?: ISprintRecord) => {
    closeModal(id);
    onModalComplete((success && newRecord) ? { success, newRecord } : { success: false, newRecord: null });
  }, [closeModal, id, onModalComplete]);

  // Render
  return (
    <Modal
      {...props}
      title="Create a new Sprint"
      allowDismiss
      isDirty={isDirty}
      size="lg"
    >
      <NewSprintForm
        onFormComplete={handleFormComplete}
        onFormChange={setDirty}
        initialData={initialData}
      />
    </Modal>
  );
};
