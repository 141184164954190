/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, forwardRef, PropsWithChildren, Ref } from 'react';
import classNames from 'classnames';
import {
  Button,
  ButtonGroup,
  Modal as ReactStrapModal,
  ModalHeader as ReactStrapModalHeader,
  ModalBody as ReactStrapModalBody,
  ModalFooter as ReactStrapModalFooter,
} from 'reactstrap';

import { IModalButton } from '../../types/modal/modal-button.interface';
import { ModalProps } from '../../types/modal/modal.props';

import Icon from '../layout-helpers/icon';

import { THEME_COLOR } from '../../constants/theme-color.const';
import { MODAL_BUTTON_SIDE_TYPE } from '../../constants/modal-button-side-type.const';
import { AModalResultType, ModalResult } from '../../types/modal/modal-result';
import { BUTTON_COLOR } from '../../constants/button-color.const';

/**
 * Base Modal to be composited with children modals
 */
const ModalInner = <T extends AModalResultType = ModalResult>(
  props: PropsWithChildren<ModalProps<T>>,
  ref?: Ref<HTMLElement>,
) => {
  // export const Modal = forwardRef<HTMLElement, PropsWithChildren<ModalProps<AModalResultType>>>((props, ref?) => {
  // Optional props
  let {
    color,
    isBusy,
    isDirty,
    size,
    allowDismiss,
    buttons,
  } = props;

  // Set some defaults for undefined props
  color = color ?? THEME_COLOR.PRIMARY;
  isBusy = isBusy ?? false;
  isDirty = isDirty ?? false;
  size = size ?? 'md';
  allowDismiss = allowDismiss ?? true;
  buttons = buttons ?? [];

  const {
    // Required or immutable props
    className,
    title,
    icon,
    modalHeader,
    modalFooter,
    children,

    // From the Modal Provider
    id,
    isVisible,
    modalContainer,
    closeModal,
  } = props;


  // Create a reference for the modal element (if not using the forwarded ref)
  const localRef = useRef(null);
  const modalRef = ref ?? localRef;


  /**
   * Fired when the user closes the modal using the native ReactStrap modal close functionality
   */
  const handleToggle = () => {
    if (isVisible && allowDismiss) {
      closeModal(id, (isDirty ?? false));
    }
  };


  /**
   * Render out one of the buttons for display in the modal footer
   */
  const renderButton = (key: string, button: IModalButton) => (
    <Button
      key={key}
      className={classNames({
        left: button.sideType === MODAL_BUTTON_SIDE_TYPE.LEFT,
        right: button.sideType === MODAL_BUTTON_SIDE_TYPE.RIGHT || !button.sideType,
      })}
      onClick={button.onClick}
      color={button.color ?? BUTTON_COLOR.PRIMARY}
      disabled={button.disabled ?? false}
      title={button.label}
    >
      {button.icon && (
        <Icon i={button.icon} isBusy={isBusy} />
      )}
      <span>{button.label}</span>
    </Button>
  );


  /**
   * Render the Modal
   */
  return (
    <ReactStrapModal
      container={modalContainer}
      innerRef={modalRef}
      className={classNames('portal-modal', className)}
      isOpen={isVisible}
      toggle={handleToggle}
      size={size}
      unmountOnClose={false}
      keyboard={false}
    >
      {/* The presence of a modalHeader element will override the `title` property */}
      {(modalHeader || title) && (
        <ReactStrapModalHeader
          toggle={allowDismiss ? handleToggle : undefined}
          className={classNames(`bg-${color}`, 'text-white')}
        >
          {modalHeader}
          {!modalHeader && title && (
            <span>
              {icon && (
                <Icon i={icon} />
              )}
              <span>{`${title}${isDirty ? '*' : ''}`}</span>
            </span>
          )}
        </ReactStrapModalHeader>
      )}

      <ReactStrapModalBody>{children}</ReactStrapModalBody>

      {(modalFooter || (buttons && buttons.length > 0)) && (
        <ReactStrapModalFooter>
          {modalFooter}
          {!modalFooter && buttons && (buttons.length > 0) && (
            <div className="buttons">
              <ButtonGroup className="left">
                {buttons.filter((button) => button.sideType === MODAL_BUTTON_SIDE_TYPE.LEFT).map((button) => renderButton(`left-${button.label}`, button))}
              </ButtonGroup>
              <ButtonGroup className="right">
                {buttons.filter((button) => (button.sideType === MODAL_BUTTON_SIDE_TYPE.RIGHT || !button.sideType)).map((button) => renderButton(`right-${button.label}`, button))}
              </ButtonGroup>
            </div>
          )}
        </ReactStrapModalFooter>
      )}

    </ReactStrapModal>
  );
};

export const Modal = forwardRef(ModalInner);
