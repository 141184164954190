import React from 'react';

import { AModalResultType } from '../../types/modal/modal-result';
import { IConstructor } from '../../types/constructor.interface';
import { IFileRecord } from '../../types/file.record.interface';
import { IModalRecord } from '../../types/modal/modal-record.interface';
import { ShowModalProps } from '../../types/modal/show-modal.props';
import { ShowUpdateFileModalProps } from '../../types/modal/show-update-file-modal.props';

import { A_MODAL_TYPE } from '../../constants/modal-type.const';

export type ShowModalMethod = <T extends AModalResultType>(modalType: A_MODAL_TYPE, modalProps: ShowModalProps<T>) => IModalRecord;
export type ShowUpdateFileModalMethod = <T extends IFileRecord>(modalProps: ShowUpdateFileModalProps<T>) => IModalRecord;

// @Note: Don't forget to update MODAL_PROVIDER_PROP_TYPES when this interface is updated
export type ModalContextProps = {
  showModal: ShowModalMethod,
  showUpdateFileModal: ShowUpdateFileModalMethod,
  closeModal: (modalId?: number, confirmClose?: boolean, callback?: () => unknown) => void,
}

export const ModalContext = React.createContext<ModalContextProps>(null as never);

export const ModalConsumer = ModalContext.Consumer;


/**
 * @description
 * Connect a component to the modal provider
 * (requires the component to be nested under the ModalProvider component)
 */
export function connectToModalProvider<P>(Component: React.FC<P> | IConstructor<React.Component<P>>): React.FC<Omit<P, keyof ModalContextProps>> {
  return function modalProviderConnector(props: Omit<P, keyof ModalContextProps>) {
    return (
      <ModalConsumer>
        {(modalProvider) => <Component {...(props as P)} modalProvider={modalProvider} />}
      </ModalConsumer>
    );
  };
}
