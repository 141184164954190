import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Button, Card, CardBody, ButtonGroup,
} from 'reactstrap';
import classNames from 'classnames';
import NewsArticleTeaserList from './news-article-teaser-list';
import ViewSelect from '../portal-data-table/view-select';
import { DebouncedInput } from '../debounced-input/debounced-input';
import Pagination from '../pagination/pagination';
import PageSizeSelect from '../page-size-select/page-size-select';
import SORT_DIRECTION from '../../utils/sort-directions';
import { ScrollToTopOnMount } from '../router/scroll-to-top-on-mount';
import { ResultsText } from '../pagination/results-text';
import SortByDropdown from '../sort-by-dropdown/sort-by-dropdown';
import { noop, mergeActions } from '../../utils/helpers';
import PageHeader from '../app-layout/page-header';
import { ICON } from '../../constants/icon.const';
import { BUTTON_COLOR } from '../../constants/button-color.const';
import { NewsCategoryPicker } from '../form-input/news-category-picker';
import { HTML_INPUT_TYPE } from '../../constants/html-input-type.const';

const FAILED_TO_LOAD_NEWS_ARTICLES_ERROR_MESSAGE = 'Unable to load news articles. Try refreshing your browser.';
const SEARCH_DEBOUNCE_TIMEOUT = 250;

/**
 * @description
 * Fired when the error message is clicked
 *
 * @param {React.SyntheticEvent} e
 */
const handleClickErrorMessage = () => {
  // refresh the page
  window.location.reload();
};


/**
 * @description
 * Error with a button for when there was an error fetching news
 *
 * @returns {React.Component}
 */
const renderNewsFetchingError = () => <Button color="danger" onClick={handleClickErrorMessage}>{FAILED_TO_LOAD_NEWS_ARTICLES_ERROR_MESSAGE}</Button>;


/**
 * @class NewsListPage
 *
 * @description
 * The news page that displays all searchable & sortable articles.
 */
const NewsListPage = (props) => {
  const {
    availableActions,
    expectedNewsArticleCount,
    isLoading,
    hasError,
    history,
    location,
    articles,
    onSetView,
    onToggleFlag,
    onCategoryChange,
    availableFlags,
    onChangeSearchTerm,
    customFlags,
    views,
    viewKey,
    categoryFilterId,
    pagination,
    searchTerm,
    sortOptions,
    activeSortOption,
    defaultSortOption,
    onSortChange,
    allowablePageSizes,
    onConfirmSearchTerm,
  } = props;

  const {
    activePage,
    pageSize,
    totalRecords,
    onPageSizeChange,
    onPageChange,
  } = {
    ...{
      activePage: 1,
      pageSize: 15,
      totalRecords: 0,
      onPageSizeChange: noop,
      onPageChange: noop,
    },
    ...(pagination || {}),
  };

  const handlePageChange = (newActivePage) => {
    if (typeof onPageChange === 'function') onPageChange(newActivePage);

    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  const actionHandlers = {
    create: () => {
      history.push('/comms/news/create');
    },
  };

  const possibleActions = [
    {
      name: 'create',
      title: 'Write an Article',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.NEWS,
      description: 'Write a news Article',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
  ];
  const permittedActions = mergeActions(availableActions, possibleActions, actionHandlers);

  return (
    <Container fluid className="news-list-page">
      <ScrollToTopOnMount />
      <PageHeader
        title="News"
        permittedActions={permittedActions}
        actionHandlers={actionHandlers}
      />
      <Card>
        <CardBody className="news-header">
          {/* View Select / Title */}
          <div className="title-wrapper">
            <ViewSelect
              views={views}
              viewKey={viewKey}
              onSetView={onSetView}
              onToggleFlag={onToggleFlag}
              flags={customFlags}
              availableFlags={availableFlags}
            />
          </div>

          {/* Category Selection */}
          <div className="category-filter-wrapper">
            {/* <div className="pt-2 pr-2">Category</div> */}
          </div>

          {/* Search Controls */}
          <div className="search-controls-wrapper">
            <NewsCategoryPicker
              formData={{ category_id: categoryFilterId }}
              onChange={onCategoryChange}
              formSaveField="category_id"
              placeholder="Choose a category to filter"
              value={categoryFilterId}
              isClearable
            />
            <ButtonGroup className="news-list-page-search-controls">
              {/* Search Input */}
              <DebouncedInput
                className="form-control search-input"
                onChange={onChangeSearchTerm}
                onBlur={(e) => onConfirmSearchTerm(e.currentTarget.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) onConfirmSearchTerm(e.currentTarget.value);
                }}
                debounceDuration={SEARCH_DEBOUNCE_TIMEOUT}
                value={searchTerm || ''}
                type={HTML_INPUT_TYPE.TEXT}
                placeholder="Search..."
              />

              {/* Sort Dropdown */}
              <SortByDropdown
                sortOptions={sortOptions}
                onChange={onSortChange}
                activeSortOption={activeSortOption}
                defaultSortOption={defaultSortOption}
              />
            </ButtonGroup>
          </div>

          {/* Leading Results Text */}
          <div className="leading-results-text-wrapper">
            <ResultsText
              pageSize={pageSize}
              totalRecords={totalRecords}
              activePage={activePage}
              isLoading={isLoading}
              hasError={hasError}
              isFiltered={!!searchTerm}
            />
          </div>

          {/* Leading Paginator */}
          <div className="leading-pagination-wrapper">
            <Pagination
              totalRecords={totalRecords}
              activePage={activePage}
              pageSize={pageSize}
              onPageChange={handlePageChange}
            />
          </div>
        </CardBody>
      </Card>

      <div className={classNames('news-article-teaser-list-wrapper', { loading: isLoading })}>
        <>
          {hasError && renderNewsFetchingError()}

          <NewsArticleTeaserList
            articles={articles}
            history={history}
            renderPlaceholders={articles.length === 0 && isLoading}
            placeholderCount={expectedNewsArticleCount}
            columnLayout
            articleReturnToLocation={location}
          />

          {(isLoading || true) && (
            <div className="loading-mask" />
          )}
        </>
      </div>

      {/* Footer */}
      <div className="news-footer">

        {/* Trailing Results Text */}
        <div className="trailing-results-text-wrapper">
          <ResultsText
            pageSize={pageSize}
            totalRecords={totalRecords}
            activePage={activePage}
            isLoading={isLoading}
            hasError={hasError}
            isFiltered={!!searchTerm}
          />
        </div>

        {/* Page Size Select */}
        <div className="page-size-select-wrapper">
          <PageSizeSelect
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            pageSizes={allowablePageSizes}
          />
        </div>

        {/* Trailing Paginator */}
        <div className="trailing-pagination-wrapper">
          <Pagination
            totalRecords={totalRecords}
            activePage={activePage}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>

    </Container>
  );
};

NewsListPage.propTypes = {
  expectedNewsArticleCount: PropTypes.number.isRequired,
  availableActions: PropTypes.objectOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired,
  })).isRequired,
  articles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    teaser: PropTypes.string.isRequired,
    published_at: PropTypes.string,
    author: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
  allowablePageSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  hasError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  viewKey: PropTypes.string.isRequired,
  categoryFilterId: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  onSetView: PropTypes.func.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  onToggleFlag: PropTypes.func.isRequired,
  views: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    filters: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string.isRequired,
      operation: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
    })).isRequired,
    flagTitles: PropTypes.arrayOf(PropTypes.shape({
      flags: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
    })),
    flags: PropTypes.arrayOf(PropTypes.string).isRequired,
  })).isRequired,
  pagination: PropTypes.shape({
    pageSize: PropTypes.number.isRequired,
    totalRecords: PropTypes.number.isRequired,
    activePage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
  }),
  searchTerm: PropTypes.string,
  availableFlags: PropTypes.arrayOf(PropTypes.string).isRequired,
  customFlags: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeSearchTerm: PropTypes.func.isRequired,
  onConfirmSearchTerm: PropTypes.func.isRequired,
  sortOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(Object.values(SORT_DIRECTION)).isRequired,
    icon: PropTypes.string.isRequired,
  })).isRequired,
  activeSortOption: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(Object.values(SORT_DIRECTION)).isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  defaultSortOption: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(Object.values(SORT_DIRECTION)).isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  onSortChange: PropTypes.func.isRequired,
};

NewsListPage.defaultProps = {
  pagination: undefined,
  searchTerm: null,
  categoryFilterId: null,
};

export default NewsListPage;
