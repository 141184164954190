import React, { useCallback, useEffect, useState } from 'react';
import Select, { FormatOptionLabelMeta } from 'react-select';
import classnames from 'classnames';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { SelectOption } from '../../types/poly-form/select-option';

const DEFAULT_LABEL_KEY = 'name';
const DEFAULT_VALUE_KEY = 'id';

export type ConstSelectProps = Pick<FormFieldComponentProps,
  'id' |
  'name' |
  'className' |
  'placeholder' |
  'disabled' |

  'value' |
  'formData' |
  'formSaveField' |
  'hasError' |
  'isClearable' |
  'isSearchable' |
  'tabSelectsValue' |

  'renderOption' |

  'labelKey' |
  'valueKey' |
  'getOptionValue' |
  'getOptionLabel' |
  'inputOptions' |

  'onChange'
>;


/**
 * Takes the input options, looks at the current formData value for this input, and
 * identifies which of the input values is the "selected" input value.
 *
 * Also type casts the result in the expected label/value format for the <Select> component
 */
const findSelectedOption = (
  inputOptions: ConstSelectProps['inputOptions'],
  labelKey: ConstSelectProps['labelKey'] = DEFAULT_LABEL_KEY,
  valueKey: ConstSelectProps['valueKey'] = DEFAULT_VALUE_KEY,
  formDataValue: unknown,
): null | {label: string, value: string} => {
  if (formDataValue !== null) {
    const foundOption = (inputOptions ?? [])
      .find((option) => (option && (String(option[valueKey]) === String(formDataValue))));

    if (foundOption) {
      return {
        label: foundOption[labelKey],
        value: String(foundOption[valueKey]),
      };
    }
  }

  return null;
};


/**
 * Strip the detail out of the original option for the Select component
 */
const deHydrateOption = (
  optionToDeHydrate: null | SelectOption,
  labelKey: ConstSelectProps['labelKey'] = DEFAULT_LABEL_KEY,
  valueKey: ConstSelectProps['valueKey'] = DEFAULT_VALUE_KEY,
): null | {label: string, value: string} => {
  if (optionToDeHydrate) { return {
    label: String(optionToDeHydrate[labelKey]),
    value: String(optionToDeHydrate[valueKey]),
  }; }
  return null;
};


/**
 * Pump the original information back into an option for consumption
 */
const hydrateOption = (
  optionToHydrate: null | {label: string, value: string},
  inputOptions: null | SelectOption[],
  valueKey: ConstSelectProps['valueKey'] = DEFAULT_VALUE_KEY,
): null | SelectOption => {
  const foundOption = (inputOptions ?? []).find((option) => (option && optionToHydrate && (String(option[valueKey]) === String(optionToHydrate.value))));
  if (foundOption) return foundOption;
  return null;
};


/**
 * <ConstSelect/>
 */
export const ConstSelect:React.FC<ConstSelectProps> = ({
  id,
  name,
  className,
  disabled = false,
  placeholder,

  value = null,
  formData,
  formSaveField = null,

  hasError = false,
  isClearable,
  isSearchable,
  tabSelectsValue = false,

  labelKey = DEFAULT_LABEL_KEY,
  valueKey = DEFAULT_VALUE_KEY,
  inputOptions = [],

  getOptionValue,
  getOptionLabel,
  renderOption,

  onChange,
}) => {
  const saveField = formSaveField || name;
  const [selectedOption, setSelectedOption] = useState<null | {label: string, value: string}>(findSelectedOption(
    inputOptions, labelKey, valueKey, formData !== undefined ? formData[saveField] : value,
  ));

  /**
   * Get the value of an option either by using the provided valueKey or by calling
   * the provided getOptionValue callback
   */
  const internalGetOptionValue = useCallback((option: {label: string, value: string} | null): string => {
    const hydratedOption = hydrateOption(option, inputOptions, valueKey);
    if (getOptionValue) { return getOptionValue(hydratedOption); }
    return (option !== undefined && option !== null) ? String(option.value) : '';
  }, [getOptionValue, inputOptions, valueKey]);


  /**
   * Get the display label of an option either by using the provided labelKey or by calling
   * the provided getOptionLabel callback
   */
  const internalGetOptionLabel = useCallback((option: {label: string, value: string} | null): string => {
    const hydratedOption = hydrateOption(option, inputOptions, valueKey);
    if (getOptionLabel) return getOptionLabel(hydratedOption);
    return ((option && option.label) ?? '');
  }, [getOptionLabel, inputOptions, valueKey]);


  /**
   *
   */
  const internalFormatOptionLabel = useCallback((
    option: {label: string, value: string} | null,
    labelMeta: FormatOptionLabelMeta<{label: string, value: string}, false>,
  ): string | React.ReactNode => {
    const hydratedOption = hydrateOption(option, inputOptions, valueKey);

    if (renderOption) {
      return renderOption(hydratedOption, labelMeta as unknown as Record<string, unknown>, selectedOption?.value ?? null);
    }

    return option?.label ?? '';
  }, [inputOptions, renderOption, selectedOption?.value, valueKey]);


  /**
   * Fired when the select is changed.
   * This ensures the "onChange" value is exactly what was passed in to the inputOptions
   */
  const handleSelectChange = useCallback((option: {label: string, value: string} | null) => {
    const hydratedOption = hydrateOption(option, inputOptions, valueKey);
    const newValue = hydratedOption ? hydratedOption[valueKey] : null;
    if (onChange) { onChange({
      fieldName: saveField,
      newValue,
      objectFieldName: name,
      objectFieldNewValue: hydratedOption,
    }); }
  }, [inputOptions, name, onChange, saveField, valueKey]);


  /**
   * Whenever the form data or input options change, update the selected option so that
   * the <Select> input knows what is currently selected
   */
  useEffect(() => {
    setSelectedOption(findSelectedOption(
      inputOptions, labelKey, valueKey, formData !== undefined ? formData[saveField] : value,
    ));
  }, [formData, inputOptions, labelKey, saveField, value, valueKey]);


  // Render
  return (
    <Select
      className={classnames('Select', 'const-select', 'form-control', className, {
        'form-control-danger': hasError,
      })}
      classNamePrefix="Select"
      id={id}
      getOptionValue={internalGetOptionValue}
      getOptionLabel={internalGetOptionLabel}
      formatOptionLabel={renderOption ? internalFormatOptionLabel : undefined}
      onFocus={(e) => {
        if (e.currentTarget) {
          (e.currentTarget as HTMLInputElement).select();
        }
      }}
      onChange={handleSelectChange}
      isDisabled={disabled}
      isClearable={isClearable}
      isSearchable={isSearchable}
      value={selectedOption}
      name={saveField}
      placeholder={placeholder ?? 'Select...'}
      backspaceRemovesValue
      tabSelectsValue={tabSelectsValue}
      options={inputOptions.map((inputOption) => deHydrateOption(inputOption, labelKey, valueKey)) as {label: string, value: string}[]}
      menuPortalTarget={document.getElementById('portal_container')}
      menuPlacement="auto"
      menuShouldScrollIntoView={false}
    />
  );
};
