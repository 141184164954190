/* eslint-disable import/prefer-default-export */
import { absoluteTemperatureToCelsius } from './absolute-temperature-to-celsius';
import { createRateLimiter } from './create-rate-limiter';
import { createAutoInvalidatingCache } from './create-auto-invalidating-cache';
import { debugLog } from './helpers';

import WI_01d from '../images/weather-icons/wi_01d_clear_sky.svg';
import WI_01n from '../images/weather-icons/wi_01n_clear_sky.svg';
import WI_02d from '../images/weather-icons/wi_02d_few_clouds.svg';
import WI_02n from '../images/weather-icons/wi_02n_few_clouds.svg';
import WI_03d from '../images/weather-icons/wi_03d_scattered_clouds.svg';
import WI_03n from '../images/weather-icons/wi_03n_scattered_clouds.svg';
import WI_04d from '../images/weather-icons/wi_04d_broken_clouds.svg';
import WI_04n from '../images/weather-icons/wi_04n_broken_clouds.svg';
import WI_09d from '../images/weather-icons/wi_09d_shower_rain.svg';
import WI_09n from '../images/weather-icons/wi_09n_shower_rain.svg';
import WI_10d from '../images/weather-icons/wi_10d_rain.svg';
import WI_10n from '../images/weather-icons/wi_10n_rain.svg';
import WI_11d from '../images/weather-icons/wi_11d_thunderstorm.svg';
import WI_11n from '../images/weather-icons/wi_11n_thunderstorm.svg';
import WI_13d from '../images/weather-icons/wi_13d_snow.svg';
import WI_13n from '../images/weather-icons/wi_13n_snow.svg';
import WI_50d from '../images/weather-icons/wi_50d_mist.svg';
import WI_50n from '../images/weather-icons/wi_50n_mist.svg';

const weatherIconImageMap = {
  '01d': WI_01d,
  '01n': WI_01n,
  '02d': WI_02d,
  '02n': WI_02n,
  '03d': WI_03d,
  '03n': WI_03n,
  '04d': WI_04d,
  '04n': WI_04n,
  '09d': WI_09d,
  '09n': WI_09n,
  '10d': WI_10d,
  '10n': WI_10n,
  '11d': WI_11d,
  '11n': WI_11n,
  '13d': WI_13d,
  '13n': WI_13n,
  '50d': WI_50d,
  '50n': WI_50n,
};

const openWeatherMapApiKey = process.env.REACT_APP_OPEN_WEATHER_MAP_API_KEY;
const openWeatherMapEndpoint = `https://api.openweathermap.org/data/2.5/weather?APPID=${openWeatherMapApiKey}`;

/**
 * @typedef {
 *  base:             string | "stations",
 *  clouds: {
 *    all:            number | 0,
 *  },
 *  cod:              number | 200,
 *  coord: {
 *    lon:            number | 144.96,
 *    lat:            number | -37.81,
 *  },
 *  dt:               number | 1562219434,
 *  id:               number | 2158177,
 *  main: {
 *    temp:           number | 285.72,
 *    pressure:       number | 1034,
 *    humidity:       number | 71,
 *    temp_min:       number | 283.71,
 *    temp_max:       number | 287.59,
 *  },
 *  name:             string | "Melbourne",
 *  sys: {
 *    type:           number | 1,
 *    id:             number | 9548,
 *    message:        number | 0.0109,
 *    country:        string | "AU",
 *    sunrise:        number | 1562189767,
 *  },
 *  timezone:         number | 36000,
 *  visibility:       number | 10000,
 *  weather: {
 *    id:             number | 900,
 *    description:    string | 'clear sky',
 *    icon:           string | '01d',
 *    main:           string | 'Clear'
 *  }[],
 *  wind: {
 *    speed:          number | 1.86,
 *    deg:            number | 127.086
 *  },
 * } OpenWeatherMapResponse
 */

/**
 * // temp in degrees celsius
 *
 * @typedef {
 *  temp:         string | "15",
 *  description:  string | 'clean sky',
 *  icon:         string | '01d',
 * } Weather
 */

const DEBUG_LOG_TITLE = 'OpenWeatherMapService';

/**
 * rate limiter
 */
const MAX_REQUESTS_PER_MINUTE = 30;
const RATE_LIMIT_PERIOD = 600000; // 10 minutes
const LOCAL_STORAGE_WEATHER_RATE_LIMITER_KEY = 'weather_v1';
const rateLimiter = createRateLimiter(
  MAX_REQUESTS_PER_MINUTE,
  RATE_LIMIT_PERIOD,
  LOCAL_STORAGE_WEATHER_RATE_LIMITER_KEY,
);


/**
 * cache
 */
const INVALIDATE_CACHED_WEATHER_TIMEOUT = 600000; // 10 minutes
const LOCAL_STORAGE_WEATHER_CACHE_KEY = 'weather_v1';
const weatherCache = createAutoInvalidatingCache(
  INVALIDATE_CACHED_WEATHER_TIMEOUT,
  LOCAL_STORAGE_WEATHER_CACHE_KEY,
);


/**
 * @description
 * Get a string error message for the Weather Map Service
 *
 * @param {string} reason
 * @returns {string}
 */
const getWeatherErrorMessage = (reason) => `Unable to get weather: ${reason}`;


/**
 * @description
 * Generate a current weather query endpoint from a location
 *
 * @see https://openweathermap.org/current#one
 *
 * @param {{ city?: string, postal?: string, latitude?: string, longitude?: string, state?: string, }} forLocation
 * @returns {{endpoint: null, errorMessage: string} | {endpoint: string, errorMessage: null}}
 */
const getCurrentWeatherEndpoint = (forLocation) => {
  let endpoint = null;
  let errorMessage = null;

  // If country_name causes issues in the request then consider taking it out. It MAY not be required...
  if ('longitude' in forLocation && 'latitude' in forLocation) endpoint = `${openWeatherMapEndpoint}&lon=${forLocation.longitude}&lat=${forLocation.latitude}`;
  else if ('city' in forLocation && 'country_name' in forLocation) endpoint = `${openWeatherMapEndpoint}&q=${forLocation.city},${forLocation.country_name}`;
  else if ('postal' in forLocation) endpoint = `${openWeatherMapEndpoint}&zip=${forLocation.postal}`;
  else {
    errorMessage = getWeatherErrorMessage('insufficient location information');
  }

  return { endpoint, errorMessage };
};


/**
 * @description
 * Fetch weather at a given location
 *
 * @param {{ country_name?: string, city?: string, postal?: string, latitude?: string, longitude?: string, state?: string, }} location
 *
 * All properties that aren't asserted are optional
 * @returns {Weather}
 */
const fetchWeatherAt = (location) => new Promise((resolve, reject) => {
  if (!openWeatherMapApiKey) {
    debugLog(DEBUG_LOG_TITLE, 'danger', getWeatherErrorMessage('no openWeatherMapApiKey'), '💣');
    reject(new Error(getWeatherErrorMessage('no openWeatherMapApiKey')));
  }

  const { endpoint, errorMessage } = getCurrentWeatherEndpoint(location);

  if (errorMessage) {
    // unable to get an endpoint using the location given
    debugLog(DEBUG_LOG_TITLE, 'danger', getWeatherErrorMessage(errorMessage), '💣');
    reject(new Error(errorMessage));
    return;
  }

  fetch(endpoint)
    .then((res) => res.json())
    .then((json) => {
      // normalize the response
      /** @var {Partial<OpenWeatherMapResponse>} json */
      if (
        ((json.weather instanceof Array) && json.weather.length > 0)
        && (typeof json.weather[0].description === 'string')
        && (typeof json.weather[0].icon === 'string')
        && (typeof json.main.temp === 'number')
      ) {
        // normalize response
        /** @var {Weather} weather */
        const weather = {
          description: json.weather[0].description,
          icon: json.weather[0].icon,
          temp: absoluteTemperatureToCelsius(Math.floor(json.main.temp + 0.5)).toFixed(0),
        };
        resolve(weather);
      }
      else {
        debugLog(DEBUG_LOG_TITLE, 'danger', getWeatherErrorMessage('received unexpected response'), '💣');
        reject(getWeatherErrorMessage('received unexpected response'));
      }
    })
    .catch((error) => {
      // unable to fetch data successfully
      debugLog(DEBUG_LOG_TITLE, 'danger', getWeatherErrorMessage(error.message), '💣');
      reject(error);
    });
});


/**
 * @description
 * OpenWeatherMap service
 *
 * @see https://openweathermap.org/api
 *
 * @example api.openweathermap.org/data/2.5/weather?q=London,uk&APPID=4fc975cbc7fe94d6cdbc05dc321c68a6
 *
 * Useful links:
 * - API documentation https://openweathermap.org/api
 * - Details of your plan https://openweathermap.org/price
 * - Please, note that 16-days daily forecast and History API are not available for Free subscribers
 *
 * @singleton
 */
export const OpenWeatherMapService = {
  /**
   * @description
   * Get weather at a given location
   * Caches retrieved data
   *
   * @param {{ country_name?: string, city?: string, postal?: string, latitude?: string, longitude?: string, state?: string, }} location
   *
   * All properties that aren't asserted are optional
   * @returns {Promise<Weather>}
   */
  getWeatherAt: (location) => new Promise((resolve, reject) => {
    // cache first strategy
    if (weatherCache.isValid()) resolve(weatherCache.get());
    else if (rateLimiter.isOverLimit()) {
      debugLog(DEBUG_LOG_TITLE, 'danger', getWeatherErrorMessage('too many requests'), '💣');
      reject(new Error(getWeatherErrorMessage('too many requests')));
    }
    else {
      rateLimiter.incrementCount();

      fetchWeatherAt(location)
        .then((weather) => {
          weatherCache.set({ ...weather, location });
          resolve(weatherCache.get());
        })
        .catch((error) => reject(error));
    }
  }),

  weatherIconImageMap,

  /**
   * @description
   * Get the url to display an icon
   *
   * For details @see https://openweathermap.org/weather-conditions
   * For example response @see https://openweathermap.org/img/wn/10d@2x.png
   *
   * @param {string} iconName
   * @returns {string}
   */
  iconUrl: (iconName) => `https://openweathermap.org/img/wn/${iconName}@2x.png`,
};
