import React, { createRef, forwardRef, useImperativeHandle } from 'react';
// import footerImage from '../../images/email-signature/footer.png';
import moment from 'moment';
import footerImage from '../../images/email-signature/the_ci_group_logo_signature.png';
import christmasImage from '../../images/email-signature/ci_group_xmas_signature_logo.png';
import { copyElementToClipboard } from '../../helpers/copy-element-to-clipboard.helper';
import ci2021 from '../../images/email-signature/campaign-images/ci_2021.png';
import ci202109 from '../../images/email-signature/campaign-images/ci_202109.png';
import ci202110 from '../../images/email-signature/campaign-images/ci_202110.png';
import ci202111 from '../../images/email-signature/campaign-images/ci_202111.png';
// import ci202204 from '../../images/email-signature/campaign-images/ci_202204.png';
import ci202205 from '../../images/email-signature/campaign-images/ci_202205.png';
import ci202209 from '../../images/email-signature/campaign-images/ci_202209.png';
import ci202210 from '../../images/email-signature/campaign-images/ci_202210.png';
import ci202211 from '../../images/email-signature/campaign-images/ci_202211.png';
import ci202212 from '../../images/email-signature/campaign-images/ci_202212.png';
import ci202302 from '../../images/email-signature/campaign-images/ci_202302.png';
import ci202311 from '../../images/email-signature/campaign-images/ci_202311.png';
import ci202401 from '../../images/email-signature/campaign-images/ci_202401.png';


export type EmailFooterProps = {
  textMode?: boolean,
  profileData: {
    name: string,
    title: string,
    phone_mobile: string,
    email: string,
  }
}

type CampaignImage = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  image: any,
  validFrom: Date,
  alt: string,
  url: string,
};

const campaignImages: CampaignImage[] = [
  // Default 2021 email sig campaign
  {
    image: ci2021,
    validFrom: new Date('2021-01-01'),
    alt: 'Ask us about WELL Workplace Technology',
    url: 'https://www.thecigroup.com.au/news/the-iwbi-is-an-invaluable-resource-for-workplace-health-safety',
  },

  // 2021-09: Finalist, Poly ANZ Partner Awards 2021
  {
    image: ci202109,
    validFrom: new Date('2021-09-01'),
    alt: 'Finalist, Poly ANZ Partner Awards 2021',
    url: 'https://www.thecigroup.com.au/news/the-ci-group-is-nominated-twice-as-poly-anz-partner-of-the-year',
  },

  // 2021-10: Upgrade your boardroom with LED
  {
    image: ci202110,
    validFrom: new Date('2021-10-01'),
    alt: 'Upgrade your boardroom with LED',
    url: 'https://www.thecigroup.com.au/workplace-technology/video-conference-rooms/',
  },

  // 2021-11: Signify Certified System Integrator
  {
    image: ci202111,
    validFrom: new Date('2021-11-01'),
    alt: 'Signify Certified System Integrator',
    url: 'https://www.thecigroup.com.au/news/the-ci-group-adds-philips-uv-c-philips-dynalite-and-signify-interact-to-its-capability/',
  },

  // // 2022-04: Its official: Neat is now certified for Teams
  // {
  //   image: ci202204,
  //   validFrom: new Date('2022-04-01'),
  //   alt: 'Its official: Neat is now certified for Teams',
  //   url: 'https://www.thecigroup.com.au/news/its-official-neat-is-now-certified-for-teams/',
  // },

  // 2022-05: The Tasman Hobart is a truly beautiful project to work on!
  {
    image: ci202205,
    validFrom: new Date('2022-04-01'),
    alt: 'The Tasman Hobart is a truly beautiful project to work on!',
    url: 'https://www.thecigroup.com.au/news/the-tasman-hobart-is-a-truly-beautiful-project-to-work-on/',
  },
  {
    image: ci202209,
    validFrom: new Date('2022-09-01'),
    alt: 'Heritage Lanes, Brisbane - World class media architecture',
    url: 'https://www.thecigroup.com.au/news/heritage-lanes-brisbane-world-class-media-architecture/',
  },
  {
    image: ci202210,
    validFrom: new Date('2022-10-01'),
    alt: 'The Ci Group is an Inavate 2022 Finalist',
    url: 'https://www.thecigroup.com.au/news/heritage-lanes-brisbane-world-class-media-architecture/',
  },
  {
    image: ci202211,
    validFrom: new Date('2022-11-08'),
    alt: 'The Ci Group Winner of Innavate Retail Award',
    url: 'https://www.thecigroup.com.au/news/automall-west-qld-shows-the-road-ahead-for-the-australian-automotive-industry/',
  },
  {
    image: ci202212,
    validFrom: new Date('2022-12-15'),
    alt: 'Ci is a 2023 Digital Signage Awards Finalist!',
    url: 'https://www.thecigroup.com.au/news/heritage-lanes-brisbane-world-class-media-architecture/',
  },
  {
    image: ci202302,
    validFrom: new Date('2023-02-13'),
    alt: 'Ci is a 2023 Digital Signage Awards Winner!',
    url: 'https://www.thecigroup.com.au/news/heritage-lanes-brisbane-wins-global-award-for-creative-execution/',
  },
  {
    image: ci202311,
    validFrom: new Date('2023-09-11'),
    alt: 'Two out of seven AVIA winners in 2023 are Ci Group Projects!',
    url: 'https://www.thecigroup.com.au/news/two-out-of-seven-avia-winners-in-2023-are-ci-group-projects/',
  },
  {
    image: ci202401,
    validFrom: new Date('2024-01-01'),
    alt: 'The Ci Group named as finalist for two INAVATE EMEA 2024 awards, an amazing feat.',
    url: 'https://www.thecigroup.com.au/news/the-ci-group-named-as-finalist-for-two-inavate-emea-2024-awards-an-amazing-feat/',
  },
].filter((ci) => ci.validFrom <= new Date()).sort((a, b) => (a.validFrom < b.validFrom ? 1 : -1));


const ciBlue = '#0078ad';

export const EmailFooter: React.FC<EmailFooterProps> = forwardRef((props, ref) => {
  const {
    profileData,
    textMode = false,
  } = props;

  const textModeRef = createRef<HTMLDivElement>();
  const htmlModeRef = createRef<HTMLDivElement>();

  const currentCampaignImage: null | CampaignImage = campaignImages.length > 0 ? campaignImages[0] : null;

  // This adds some properties to the ref object passed back to the parent which
  // allows the parent to "call" methods on the child. This technically breaks
  // the react uni-directional flow pattern but sometimes it's easier to work around it
  useImperativeHandle(ref, () => ({
    copyHTMLSignatureToClipboard: async ():Promise<boolean> => {
      if (htmlModeRef.current) return copyElementToClipboard(htmlModeRef.current);
      return false;
    },
    copyTextSignatureToClipboard: async ():Promise<boolean> => {
      if (textModeRef.current) return copyElementToClipboard(textModeRef.current);
      return false;
    },
  }));

  // Text Mode
  if (textMode) {
    return (
      <div ref={textModeRef}>
        <span>{profileData.name}</span>
        <br />

        {/* Role */}
        {profileData.title && (
          <>
            <span>{profileData.title}</span>
            <br />
          </>
        )}

        {/* Company Name */}
        <span>The Ci Group</span>
        <br />
        <br />

        {/* Company Phone */}
        <span>P:&nbsp;1300&nbsp;242&nbsp;742</span>
        <br />

        {/* Profile Mobile */}
        {profileData.phone_mobile && (
          <>
            <span>
              M:&nbsp;
              {profileData.phone_mobile}
            </span>
            <br />
          </>
        )}

        {/* Profile Email */}
        <span>{`E: ${profileData.email}`}</span>
        <br />

        {/* Company Website */}
        <span>W: www.thecigroup.com.au</span>
        <br />

      </div>
    );
  }

  // HTML Mode
  return (
    <div ref={htmlModeRef}>
      <div style={{ color: 'black', fontFamily: 'Arial' }}>
        <style
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: `
          a.el, a.el:visited, a.el:active, a.el:active:visited, a.el span {
            color: ${ciBlue};
          }` }}
        />
        <div>

          {/* Name */}
          <div>
            <span style={{ fontSize: '12pt' }}><b>{profileData.name}</b></span>
          </div>

          {/* Role */}
          {profileData.title && (
            <div>
              <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '10pt', color: ciBlue }}><i>{profileData.title}</i></span>
            </div>
          )}

          {/* Logo */}
          <div>
            <br />
            <img src={moment().format('M') === '12' ? christmasImage : footerImage} alt="The Ci Group, 30 years enhancing the human experience" />
          </div>

          {/* Contact Details */}
          <div style={{
            position: 'relative',
          }}
          >
            <br />
            <span style={{ fontSize: '11pt', color: ciBlue }}><b>The Ci Group</b></span>
            <br />
            <span style={{ fontSize: '9pt' }}>
              <span>
                {/* Company Phone */}
                <b>P&nbsp;</b>
                <a className="el" style={{ color: ciBlue }} href="tel:1300 242 742"><span style={{ color: ciBlue }}>1300&nbsp;242&nbsp;742</span></a>

                {/* Profile Mobile */}
                {profileData.phone_mobile && (
                  <span>
                    <b>&nbsp;|&nbsp;M&nbsp;</b>
                    <a className="el" style={{ color: ciBlue }} href={`tel:${profileData.phone_mobile}`}><span style={{ color: ciBlue }}>{profileData.phone_mobile}</span></a>
                  </span>
                )}
              </span>
            </span>
            <br />
            <span style={{ fontSize: '9pt' }}>
              {/* Email */}
              <b>E&nbsp;</b>
              <a className="el" style={{ color: ciBlue }} href={`mailto:${profileData.email}`}><span style={{ color: ciBlue }}>{profileData.email}</span></a>

              {/* Website */}
              <b>&nbsp;|&nbsp;W&nbsp;</b>
              <a className="el" style={{ color: ciBlue }} href="https://www.thecigroup.com.au/" target="_blank" rel="noreferrer">
                <span style={{ color: ciBlue }}>www.thecigroup.com.au</span>
              </a>
            </span>

            {/* Campaign Image */}
            {currentCampaignImage && (
              <>
                <br />
                <br />
                <span style={{ fontSize: '9pt' }}>
                  <a className="el" style={{ color: ciBlue }} href={currentCampaignImage.url} target="_blank" rel="noreferrer">
                    <img
                      style={{
                        maxWidth: '100%',
                      }}
                      src={currentCampaignImage.image}
                      alt="Ask us about WELL Workplace Technology"
                    />
                  </a>
                </span>
                <br />
                <span style={{ fontSize: '10pt' }}>
                  <span>Visit </span>
                  <a className="el" style={{ color: ciBlue }} href={currentCampaignImage.url} target="_blank" rel="noreferrer">
                    <span style={{ color: ciBlue }}>{currentCampaignImage.url}</span>
                  </a>
                  <span> to learn more.</span>
                </span>
              </>
            )}

            {/* Support Message */}
            <br />
            <br />
            <span style={{ fontSize: '9pt' }}>
              <span>For industry-leading technical Support &amp; Service please contact our Client Support Centre on&nbsp;</span>
              <a className="el" style={{ color: ciBlue }} href="tel:1800 771 085">
                <span style={{ color: ciBlue }}>1800&nbsp;771&nbsp;085</span>
              </a>
              <span>, or email&nbsp;</span>
              <a className="el" style={{ color: ciBlue }} href="mailto:support@thecigroup.com.au">
                <span style={{ color: ciBlue }}>support@thecigroup.com.au</span>
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});
