import React from 'react';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';

import AsyncSelect from './async-select';

export type ResourceTypePickerProps = AsyncSelectComponentProps;

export const ResourceTypePicker:React.FC<ResourceTypePickerProps> = (props) => {
  const {
    formSaveField = 'type_id',
  } = props;

  return (
    <AsyncSelect
      {...props}
      formSaveField={formSaveField}
      searchRoute="/project/resource/role"
      loadAndKeepAll
    />
  );
};
