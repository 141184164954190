/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Button } from 'reactstrap';

import { ConfirmActionModalResult } from '../../../../types/modal/modal-result';
import { IActivateProjectModalWizardPage5Data, IActivateProjectModalWizardState } from '../activate-project-data.interface';
import { IProjectRecord } from '../../../../types/project/project.record.interface';
import { IProjectFileRecord } from '../../../../types/project/project-file.record.interface';

import { ModalWizardPage, ModalWizardPageProps } from '../../modal-wizard-page';
import { ModalWizardItemProgress } from '../../modal-wizard-item-progress';

import { ACTIVATE_PROJECT_MODAL_WIZARD_PAGE } from '../activate-project-modal-wizard-page.const';

export const ActivateProjectModalPage6 = (props: ModalWizardPageProps<
  IActivateProjectModalWizardPage5Data,
  IActivateProjectModalWizardState,
  ConfirmActionModalResult
>): React.ReactElement => {
  const { wizardState, onModalComplete, closeModal, modalId } = props;
  const {
    processing,
    success,
    activateProjectResult,
    uploadActivatingPODocumentResult,
    uploadAsSoldProposalDocumentResult,
    activatingPoDocument_uploadStatus,
    asSoldProposalDocument_uploadStatus,
    pageData,
    projectRecord,
  } = wizardState;

  const requiresHasSoldDocumentUpload = !!pageData[ACTIVATE_PROJECT_MODAL_WIZARD_PAGE.PAGE_1_PURCHASE_ORDER_INFORMATION].as_sold_proposal_document_file;

  return (
    <ModalWizardPage<IActivateProjectModalWizardPage5Data, IActivateProjectModalWizardState, ConfirmActionModalResult> {...props}>
      <div className="wizard-page-section">
        <div className="wizard-page-section-heading">
          {processing && (
            <span>Processing... Please wait...</span>
          )}
          {!processing && success && (
            <span>Success!</span>
          )}
          {!processing && !success && (
            <span className="danger">Failed!</span>
          )}
        </div>
        <div className="wizard-page-section-content">
          <div className="wizard-item-progress-wrapper">

            {/* Upload Activating PO Document */}
            <ModalWizardItemProgress<IProjectFileRecord>
              label="Upload Activating PO Document"
              result={uploadActivatingPODocumentResult}
              aborted={!uploadActivatingPODocumentResult && !processing && !success}
              progress={processing || success ? activatingPoDocument_uploadStatus : undefined}
            />

            {/* Upload As Sold Proposal Document (if required) */}
            {requiresHasSoldDocumentUpload && (
              <ModalWizardItemProgress<IProjectFileRecord>
                label="Upload As Sold Proposal Document"
                result={uploadAsSoldProposalDocumentResult}
                aborted={!uploadAsSoldProposalDocumentResult && !processing && !success}
                progress={processing || success ? asSoldProposalDocument_uploadStatus : undefined}
              />
            )}

            {/* Activate Project */}
            <ModalWizardItemProgress<IProjectRecord>
              label="Activate Project"
              result={activateProjectResult}
              aborted={!activateProjectResult && !processing && !success}
            />

          </div>

          {projectRecord && projectRecord.supply_only_job === 1 && (
            <>
              <h6 className="text-primary">
                <br />
                <strong>NB: This is a delivery only job, so ordering can commence as soon as authorisation is confirmed.</strong>
              </h6>
              {projectRecord && projectRecord.project_freight_type && projectRecord.project_freight_type.name && (
                <p className="text-primary">
                  <strong>Freight Type: </strong>
                  &nbsp;
                  {projectRecord.project_freight_type.name}
                </p>
              )}
            </>
          )}

        </div>

        {/* When the processing has completed, show the close button */}
        {!processing && !!success && (
          <div className="wizard-page-section-content">
            <Button
              color="primary"
              onClick={() => {
                closeModal(modalId, false, () => onModalComplete({ processAction: false }));
              }}
            >
              Close
            </Button>
          </div>
        )}

      </div>
    </ModalWizardPage>
  );
};
