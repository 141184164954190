import React, { useCallback, useContext } from 'react';

import { CurrentUserContext } from '../providers/current-user-provider';
import { ModalContext } from '../modals/modal-context';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';
import { ITaskTemplateRecord } from '../../types/internal-project/task-template.record.interface';
import { NewRecordModalResult } from '../../types/modal/modal-result';

import AsyncSelect from './async-select';

import { PERMISSION } from '../../constants/permissions.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';

export type TaskTemplatePicketProps = AsyncSelectComponentProps;

export const TaskTemplatePicker: React.FC<TaskTemplatePicketProps> = (props) => {
  const {
    name,
    formSaveField = null,
    isCreatable = false,
    formData = {},
    parentData = {},
    getNewItemData,
    onChange,
  } = props;

  const { showModal } = useContext(ModalContext);
  const { userHasPermissions } = useContext(CurrentUserContext);

  // Show a modal to create a new taskTemplate
  const handleCreateOption = useCallback((inputValue: string) => {
    const initialData = {
      ...(getNewItemData ? getNewItemData(inputValue, formData, parentData) : undefined),
    };

    showModal<NewRecordModalResult<ITaskTemplateRecord>>(MODAL_TYPE.NEW_TASK_TEMPLATE, {
      initialData,
      onModalComplete: ({ success, newRecord: newTaskTemplate }) => {
        if (success && onChange && newTaskTemplate) { onChange({
          fieldName: formSaveField || `${name}_id`,
          newValue: newTaskTemplate.id,
          objectFieldName: name,
          objectFieldNewValue: newTaskTemplate,
        }); }
      },
    });
  }, [formData, formSaveField, getNewItemData, name, onChange, parentData, showModal]);

  return (
    <AsyncSelect
      {...props}
      searchRoute="/task-template"
      loadAndKeepAll
      onCreateOption={handleCreateOption}
      // Make sure the current taskTemplate has permissions to create a new taskTemplate before allowing them to create a taskTemplate
      isCreatable={isCreatable && userHasPermissions([
        PERMISSION.INTERNAL_PROJECT_ADMIN,
      ])}
    />
  );
};
