import { arrayWrap } from './array-wrap.helper';
import { A_PERMISSION } from '../constants/permissions.const';
import { AN_API_ACTION } from '../constants/api-action.const';
import { userHasPermissionsFunction } from '../components/providers/current-user-provider';

/**
 * canTakeActionOnWidgetItems
 *
 * whether a user can take actions on child resources
 * (for example; Leave Block
 * which are owned by a Leave Application)
 * currently the API's HATEAOS CANNOT assess
 * whether the user can take actions on SPECIFIC resources
 * in a resource collection (when querying the collection,
 * no HATEAOS actions are returned for specific resources,
 * only for the group as a whole)
 *
 * @param actions                   name of the action - must match with the appropriate entry of widgetItemActionPermissions
 * @param widgetItemActionPermissions   key/value object of actions for the widget and their corresponding permissions
 * @param userHasPermissions        provide a userHasPermissions function to ensure the action is available to the user
 * @param mustHaveAllActions        true to require user can do all permissions, false to require user can do any permission
 * @param falseIfActionIsNotFound
 */
export const canTakeActionOnWidgetItems = (
  actions: AN_API_ACTION | AN_API_ACTION[],
  widgetItemActionPermissions: Partial<Record<AN_API_ACTION, A_PERMISSION>>,
  userHasPermissions: userHasPermissionsFunction,
  mustHaveAllActions = false,
  falseIfActionIsNotFound = false,
): boolean => {
  const actionsArray: AN_API_ACTION[] = arrayWrap(actions);

  let result = false;

  if (!widgetItemActionPermissions) return false;

  // loop over each provided action
  for (let i = 0; i < actionsArray.length; i += 1) {
    let userCanDoAction = false;
    const action = actionsArray[i];

    if (action in widgetItemActionPermissions) {
      // action has required permissions in the widgetItemActionPermissions array
      // does the user have it?
      userCanDoAction = userHasPermissions(widgetItemActionPermissions[action]);
    }
    else if (falseIfActionIsNotFound) {
      userCanDoAction = false;
    }
    else {
      // action not found in the widgetActionsPermissions
      // just pass the user through
      userCanDoAction = true;
    }

    // EXIT EARLY
    // USER Doesn't have permission for this action
    // BUT they need permission for EVERY action
    if (mustHaveAllActions && !userCanDoAction) {
      return false;
    }

    // on the first true, result stays true forever
    result = result || userCanDoAction;
  }

  return result;
};
