import React from 'react';
import { Button } from 'reactstrap';
import Icon from '../layout-helpers/icon';

export type FormHeaderProps = {
  title?: string,
  formIsDirty?: boolean,
  toggleShowFieldInfo?: () => void,
}

/**
 * Blue header above vertical forms (has buttons)
 */
export const FormHeader: React.FC<FormHeaderProps> = ({ children, toggleShowFieldInfo, title, formIsDirty }) => (
  <div className="details-header no-button-group">
    <span className="panel-title">
      {`${title ?? 'Details'}${formIsDirty ? '*' : ''}`}
      <Button className="btn-transparent text-white" color="default" onClick={toggleShowFieldInfo}>
        <Icon i="info-circle" />
      </Button>
    </span>
    {children && (
      <div>
        {children}
      </div>
    )}
  </div>
);
