import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

/**
 * @deprecated ??
 * @see variables.scss
 */
const statusColor = {
  // Projects
  Lead: 'mediumAqua',
  Proposal: 'info',
  Active: 'primary',
  Completed: 'green',
  Lost: 'danger',

  // Other Workflows
  Draft: 'gray',
  Unsubmitted: 'gray',
  Submitted: 'warning',

  Rejected: 'danger',
  Revoked: 'danger',

  Approved: 'green',
  Batched: 'cyan',

  Confirmed: 'purple',
  Processed: 'purple',
  Scheduled: 'purple',

  Published: 'blue',
  Paid: 'blue',

  Archived: 'gray',
  Deleted: 'gray',
  Cancelled: 'gray',
  Inactive: 'gray',

  // Internal Projects
  Requested: 'mediumAqua',
  Reviewing: 'mediumAqua',
  'In Progress': 'blue',
  'On Hold': 'danger',
  'In UAT': 'purple',

  // Service Plan
  'Coming Soon': 'mediumAqua',
  Available: 'primary',
  Restricted: 'warning',
  Pending: 'info',
  Expired: 'dark',

  // Contract Status
  Option: 'gray',
  Negotiation: 'mediumAqua',
  Quoted: 'info',
  Superseded: 'purple',
  Suspended: 'danger',
};

const StatusBadge = ({ status, short, append }) => {
  // default to 'dark' (mainly for Archived)
  const color = statusColor[status] || 'dark';
  return (
    <Badge color={color}>
      {short ? status.substring(0, 1) : status}
      {append || ''}
    </Badge>
  );
};

StatusBadge.defaultProps = {
  status: null,
  short: false,
  append: null,
};

StatusBadge.propTypes = {
  status: PropTypes.string,
  short: PropTypes.bool,
  append: PropTypes.element,
};

export default StatusBadge;
