import React from 'react';
import { Button, ButtonGroup, Card, CardBody, CardText, CardTitle, Input, Row } from 'reactstrap';
import moment from 'moment';
import { Editor } from '@tinymce/tinymce-react';
import { ProgressPercentInput } from '../form-input/progress-percent-input';
import { formatValueJSX, getFieldDisplayComponent } from '../render-functions';
import { COLUMN_FORMAT } from '../../constants/column-format.const';
import { TINYMCE_KEY } from '../../utils/constants';
import { ICardItem } from '../../types/cardview/card-item.interface';
import Icon from '../layout-helpers/icon';
import { AbbreviatedExternalLink } from '../data-format/abbreviated-external-link';
import StatusBadge from '../data-format/status-badge';
import { mapSelectInputOptions } from '../../helpers/map-select.helper';
import { IMPACT_LEVEL_NAME_MAP } from '../../constants/impact-level.const';
import { FormRendererProps } from '../../types/poly-form/form-renderer.props';


type ProjectCardProps = Omit<FormRendererProps,
'primaryKeyFieldName' |
'primaryKeyValue' |
'formData' |
'fields' |
'isReadOnly' |
'isLocked' |
'formDeleteConfirmationType' |
'permittedActions'
> & {
  project: ICardItem,
  showSwimlanes?: boolean,
  inTable?: boolean,
  extraButtons?: JSX.Element,
  feedbackPanel?: JSX.Element,
  isEditing?: boolean,
  onToggleSwimlanes?: () => void,
}

/**
 * Project Card
 *
 * @param {ProjectCardProps} props
 * @param {ICardItem}         project   the project to render, needs to confirm to ICardItem Interface
 * @param {boolean}           showSwimlanes should the tasks dropdown be displayed?
 */
export const ProjectCard: React.FC<ProjectCardProps> = ({
  project, showSwimlanes, inTable, extraButtons, feedbackPanel, onFieldChange, isEditing, onToggleSwimlanes,
}) => (
  <Card key={project.id} style={showSwimlanes || inTable ? { marginBottom: 0 } : {}}>
    <Row className="project-card-row">
      <div className="project-card-panel priority-status">
        <CardBody className="text-center">
          <p>&nbsp;priority</p>
          <h1>{project.priority}</h1>
          <StatusBadge status={project._status_name} />
        </CardBody>
      </div>
      <div className="project-card-panel detail-body">
        <CardBody>
          <CardTitle>
            {isEditing ? (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              <Input type="text" value={project.name} onChange={(event: any) => (onFieldChange ? onFieldChange({ fieldName: 'name', newValue: event.target.value }) : undefined)} />
            ) : (
              <h3>
                {project.number}
              &nbsp;
              &nbsp;
                {getFieldDisplayComponent(project, {
                  name: 'name',
                  linkRoute: '/internal-projects/internal-projects',
                  format: COLUMN_FORMAT.NAME_LINK,
                }, false)}
              </h3>
            )}
          </CardTitle>
          {isEditing ? (
            <div className="pb-4">
              <Editor
                apiKey={TINYMCE_KEY}
                initialValue={project.description}
                init={{
                  branding: false,
                  menubar: false,
                  plugins: 'lists',
                  statusbar: false,
                  placeholder: 'Insert your description here...',
                  inline: true,
                  paste_as_text: true,
                  toolbar: 'undo redo | bold italic | bullist numlist',
                  valid_elements: 'strong/b,em,div,br,p,ol,ul,li',
                  contextmenu: false,
                }}
                disabled={!isEditing}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(event: any) => (onFieldChange ? onFieldChange({ fieldName: 'description', newValue: event.level.content }) : undefined)}
              />
            </div>
          ) : (
            // eslint-disable-next-line react/no-danger
            <div id="tiny" className="body" dangerouslySetInnerHTML={{ __html: project.description }} />
          )}
          <CardText>
            <AbbreviatedExternalLink href={project.external_url} />
          </CardText>
          <ProgressPercentInput id="progress" name="progress" value={project.progress} onChange={onFieldChange} disabled={!isEditing} />
          <div className="detail-buttonbar">
            <ButtonGroup>
              {onToggleSwimlanes && (
                <Button size="sm" onClick={onToggleSwimlanes} className="pull-left">
                  <Icon i={showSwimlanes ? 'icon-arrow-up' : 'icon-arrow-down'} />
                  &nbsp;&nbsp;
                  Swimlanes
                </Button>
              )}
              {extraButtons}
            </ButtonGroup>
            {project.created_by && (
              <div className="pull-right text-gray">
                #
                {project.id}
                  &nbsp;created by&nbsp;
                {project.created_by.name}
                  &nbsp;
                {moment(project.user_audit.created_at).format('D MMM YY')}
              </div>
            )}
          </div>
          {feedbackPanel}
        </CardBody>
      </div>
      <div className="project-card-panel summary-box">
        <CardBody>
          <div className="detail-title">Summary</div>
          {project.owner && (
            <div className="detail-row">
              <div className="detail-left">Project Owner: </div>
              <div className="detail-right">{project.owner.name}</div>
            </div>
          )}
          {project.project_lead && (
            <div className="detail-row">
              <div className="detail-left">Project Lead: </div>
              <div className="detail-right">{project.project_lead.name}</div>
            </div>
          )}
          <div className="detail-row">
            <div className="detail-left">Internal Impact: </div>
            <div className="detail-right">{formatValueJSX(project.internal_impact, COLUMN_FORMAT.ID_NAME_OPTIONS, mapSelectInputOptions(IMPACT_LEVEL_NAME_MAP), '', false)}</div>
          </div>
          <div className="detail-row">
            <div className="detail-left">Customer Impact: </div>
            <div className="detail-right">{formatValueJSX(project.customer_impact, COLUMN_FORMAT.ID_NAME_OPTIONS, mapSelectInputOptions(IMPACT_LEVEL_NAME_MAP), '', false)}</div>
          </div>
          {project.estimated_hours && (
            <div className="detail-row">
              <div className="detail-left">Est. Hours: </div>
              <div className="detail-right">{formatValueJSX(project.estimated_hours, COLUMN_FORMAT.DURATION_BUSINESS_TIME, [], '', false)}</div>
            </div>
          )}
          <div className="detail-row">
            <div className="detail-left">Tasks: </div>
            <div className="detail-right">
              {project.count_of_item}
              {' '}
                &nbsp;
            </div>
          </div>
        </CardBody>
      </div>
    </Row>
  </Card>
);
