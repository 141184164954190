import React, {
  PropsWithChildren,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Tree, NodeModel, TreeMethods } from '@minoru/react-dnd-treeview';
import { useHistory, useLocation } from 'react-router';

import { IPortalTreeViewNodeData } from '../../types/portal-tree-view/portal-tree-view-node-data.interface';
import { IPortalTreeViewSelectedNodeIdentifier } from '../../types/portal-tree-view/portal-tree-view-selected-node-identifier.interface';
import { PortalTreeViewNodeItem } from '../../types/portal-tree-view/portal-tree-view-node-item';
import { PortalTreeViewNode } from './portal-tree-view-node';
import { PortalTreeViewMenuItems } from '../../types/portal-tree-view/portal-tree-view-menu-items';

import { usePreviousValue } from '../../react-hooks/use-previous-value.hook';
import { useIsMounted } from '../../react-hooks/use-is-mounted.hook';
import { useIsDataLoaded } from '../../react-hooks/use-is-data-loaded.hook';

import FriendlyFormMessage from '../layout-helpers/friendly-form-message';
import { LoadingSpinner } from '../layout-helpers/loading-spinner';
import { PortalMultiLevelDropDownHandlers } from '../portal-multi-level-drop-down/portal-multi-level-drop-down';
import { PortalTreeViewHeader } from './portal-tree-view-header';
import { PortalTreeViewContextMenu, PortalTreeViewContextMenuHandlers, PortalTreeViewContextMenuTriggerEvent } from './portal-tree-view-context-menu';

import { calculatePortalTreeViewNodeData } from './calculate-portal-tree-view-node-data.helper';
import { findPortalTreeViewNode } from './find-portal-tree-view-node-child.helper';
import { parsePortalTreeViewUrlState } from './portal-tree-view-url.helper';
import { shallowAreObjectsDifferent } from '../../helpers/shallow-are-objects-different';
import { toParams, toQueryString } from '../../utils/helpers';

import { AN_ICON } from '../../constants/icon.const';


// Re-declare forwardRef to allow generics
// @see https://fettblog.eu/typescript-react-generic-forward-refs/
declare module 'react' {
  function forwardRef<T, P = Record<string, unknown>>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}


export const buildPortalTreeViewSelectedNodeIdentifier = <
  D extends IPortalTreeViewNodeData,
  S extends IPortalTreeViewSelectedNodeIdentifier,
// eslint-disable-next-line @typescript-eslint/no-unused-vars
>(node: PortalTreeViewNodeItem<D>, nodeData: D): S => ({
    nodeId: node.id,
    parentNodeId: node.parent,
  } as S);


/**
 * Build a string from some details about the state of the portal tree view
 * to embed in the URL parameter string
 */
const buildPtvUrlParamString = <S extends IPortalTreeViewSelectedNodeIdentifier>(nodes: S[]): string => (
  `${nodes.length > 0 ? `id=${nodes.map((n) => n.nodeId).join(',')}` : ''};`
);


/**
 * These handlers are available to the ref that is passed in to trigger active functions
 * directly on the treeview
 */
export declare type PortalTreeViewHandlers = {
  expandAll(): void;
  collapseAll(): void;
};


export type PortalTreeViewProps<
  D extends IPortalTreeViewNodeData = IPortalTreeViewNodeData,
  S extends IPortalTreeViewSelectedNodeIdentifier = IPortalTreeViewSelectedNodeIdentifier,
> = {
  className?: string,
  showHeader?: boolean,
  title?: string,
  urlKey?: string,

  // The root node can be hidden to tidy up tree structures
  isRootNodeVisible?: boolean,

  // The data is currently loading
  isLoading: boolean,

  // Dont' allow the tree to enter edit mode
  isReadOnly?: boolean,

  // Don't allow the selected node to change
  isLocked?: boolean,

  // The user will be prompted to confirm every action
  isSafeModeEnabled?: boolean,

  isSearchBarVisible?: boolean,
  multiSelect?: boolean,
  deselectOnBackgroundClick?: boolean,
  items?: PortalTreeViewNodeItem<D>[],
  menuItems?: PortalTreeViewMenuItems<S>,
  addRecordMenuItems?: PortalTreeViewMenuItems<S>,
  noItemsMessage?: string,
  error?: string | string[],
  selectedNodes?: S[],
  searchTerm?: null | string,

  onNodeClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, node: PortalTreeViewNodeItem<D>, nodeData: D, parentNode?: PortalTreeViewNodeItem<D>, parentNodeData?: D) => void,
  onSelectedNodesChanged?: (selectedNodes: S[]) => void,
  onGetNodeIcon?: (node: PortalTreeViewNodeItem<D>, nodeData: D, isOpen: boolean, isActive: boolean) => AN_ICON | undefined,
  onDropNode?: (tree: PortalTreeViewNodeItem<D>[], options: {
    dragSourceId: string | number;
    dropTargetId: string | number;
    dragSource: PortalTreeViewNodeItem<D> | undefined;
    dropTarget: PortalTreeViewNodeItem<D> | undefined;
  }) => void,
  onCanDropNode?: (tree: PortalTreeViewNodeItem<D>[], options: {
    dragSourceId: string | number;
    dropTargetId: string | number;
    dragSource: PortalTreeViewNodeItem<D> | undefined;
    dropTarget: PortalTreeViewNodeItem<D> | undefined;
  }) => boolean,
  onStartEdit?: () => void,
  onEndEdit?: () => void,
  onEnterSafeMode?: () => void,
  onExitSafeMode?: () => void,
  onToggleSearchBar?: (newVisible: boolean) => void,
  onSearchTermChanged?: (newSearchTerm?: null | string) => void,

  buildSelectedNodeIdentifier?: (node: PortalTreeViewNodeItem<D>, nodeData: D) => S,
};

/**
 * Generic wrapper around the React DND TreeView
 * @see react dnd treeview documentation here: https://www.npmjs.com/package/@minoru/react-dnd-treeview
 */
const PortalTreeViewComponent = <
  D extends IPortalTreeViewNodeData = IPortalTreeViewNodeData,
  S extends IPortalTreeViewSelectedNodeIdentifier = IPortalTreeViewSelectedNodeIdentifier,
>(
    props: PropsWithChildren<PortalTreeViewProps<D, S>>,
    ref?: Ref<PortalTreeViewHandlers>,
  ): ReactElement<PropsWithChildren<PortalTreeViewProps>> => {
  const {
    className,
    showHeader = true,
    title,
    urlKey = 'ptv',
    isRootNodeVisible = true,
    isLoading = false,
    isReadOnly = false,
    isLocked = false,
    multiSelect = false,
    isSafeModeEnabled = false,
    isSearchBarVisible = false,
    items,
    menuItems,
    addRecordMenuItems,
    noItemsMessage = 'Nothing to display!',
    error,
    deselectOnBackgroundClick = false,
    selectedNodes = [],
    searchTerm = null,

    onNodeClick,
    onSelectedNodesChanged,
    onGetNodeIcon,
    onDropNode,
    onCanDropNode,
    onStartEdit,
    onEndEdit,
    onEnterSafeMode,
    onExitSafeMode,
    onToggleSearchBar,
    onSearchTermChanged,

    buildSelectedNodeIdentifier = buildPortalTreeViewSelectedNodeIdentifier,
  } = props;

  const { search: locationQueryString } = useLocation();
  const oldLocationQueryString = usePreviousValue(locationQueryString);
  const history = useHistory();

  const isMounted = useIsMounted();
  const [isDataLoaded, setIsDataLoaded] = useIsDataLoaded();

  const [treeData, setTreeData] = useState<undefined | NodeModel<D>[]>(undefined);
  const treeDataChecksum = useMemo<string>(() => (treeData ?? []).map((n) => n.id).join(''), [treeData]);
  const oldTreeDataChecksum = usePreviousValue(treeDataChecksum);

  const [internalSelectedNodes, setInternalSelectedNodes] = useState<S[]>(selectedNodes);
  const oldInternalSelectedNodes = usePreviousValue(internalSelectedNodes);

  const selectedNodesChecksum = useMemo<string>(() => (selectedNodes ?? []).map((n) => n.nodeId).join(''), [selectedNodes]);
  const oldSelectedNodesChecksum = usePreviousValue(selectedNodesChecksum);

  const treeRef = useRef<TreeMethods>(null);
  const headerMenuRef = useRef<PortalMultiLevelDropDownHandlers>(null);
  const addRecordMenuRef = useRef<PortalMultiLevelDropDownHandlers>(null);
  const contextMenuRef = useRef<PortalTreeViewContextMenuHandlers>(null);

  const hasContextMenu = useMemo(() => (!!menuItems && !!menuItems.length), [menuItems]);

  const hasNoItems = useMemo(() => (
    // No Tree Data
    !treeData ||
    (treeData.length === 0) ||

    // One root record and root node is hidden
    (
      (treeData.length === 1) &&
      (treeData[0].id === 'ROOT') &&
      (!isRootNodeVisible)
    )
  ), [isRootNodeVisible, treeData]);


  /**
   * This adds some properties to the ref object passed back to the parent which
   * allows the parent to "call" methods on the child. This technically breaks
   * the react uni-directional flow pattern but sometimes it's easier to work around it
   */
  useImperativeHandle(ref, () => ({
    expandAll: ():void => {
      treeRef.current?.openAll();
    },
    collapseAll: ():void => {
      treeRef.current?.closeAll();
      setTimeout(() => treeRef.current?.open('ROOT'), 0);
    },
  }), []);


  /**
   * Manually open or close the header menu
   */
  const toggleHeaderMenu = useCallback((setOpen: boolean) => {
    if (headerMenuRef && headerMenuRef.current) {
      if (setOpen) {
        headerMenuRef.current.open();
      } else {
        headerMenuRef.current.close();
      }
    }
  }, [headerMenuRef]);


  /**
   * Manually open or close the add record menu
   */
  const toggleAddRecordMenu = useCallback((setOpen: boolean) => {
    if (addRecordMenuRef && addRecordMenuRef.current) {
      if (setOpen) {
        addRecordMenuRef.current.open();
      } else {
        addRecordMenuRef.current.close();
      }
    }
  }, [addRecordMenuRef]);


  /**
   * Manually close the context menu
   */
  const hideContextMenu = useCallback(() => {
    if (contextMenuRef && contextMenuRef.current) {
      contextMenuRef.current.hide();
    }
  }, [contextMenuRef]);


  /**
   * Manually open the context menu
   */
  const showContextMenu = useCallback((e: PortalTreeViewContextMenuTriggerEvent) => {
    if (contextMenuRef && contextMenuRef.current) {
      contextMenuRef.current.show(e);
    }
  }, [contextMenuRef]);


  /**
   * Make sure a set of nodes are visible by opening their parents
   *
   * This takes in treeData as a parameter to make sure the treeData is always current
   * at the time of execution
   */
  const ensureNodesAreVisible = useCallback((nodes: S[], tData: NodeModel<D>[]) => {
    const nodesToOpen: D['id'][] = [];

    // Map the node identifiers to node data
    const treeDataNodes = nodes.map((n) => tData?.find((treeNode) => treeNode.id === n.nodeId));

    // Iterate over each of the treeDataNodes
    treeDataNodes.forEach((treeDataNode) => {
      // Find the parent node
      let parentNodeId: D['id'] = treeDataNode?.parent as D['id'];
      let parentNode = findPortalTreeViewNode(tData ?? [] as PortalTreeViewNodeItem<D>[], parentNodeId);

      let safety = 100;
      while (parentNode && safety > 0) {
        safety -= 1;

        // Mark the node as one that should be opened
        nodesToOpen.push(parentNodeId);

        // Find the next parent node
        parentNodeId = parentNode?.parent as D['id'];
        parentNode = findPortalTreeViewNode(tData ?? [] as PortalTreeViewNodeItem<D>[], parentNodeId);
      }
    });

    // Only ask the tree to open nodes if we ended up with any nodes to open
    if (nodesToOpen.length > 0) {
      treeRef.current?.open(nodesToOpen);
    }
  }, []);


  /**
   * Update the Url to save the state details to the URL
   */
  const updateUrlFromState = useCallback(() => {
    const urlParams = toParams(locationQueryString);
    const ptvParamString = urlParams[urlKey];

    const newPtvParamString = buildPtvUrlParamString(internalSelectedNodes);

    if (ptvParamString !== newPtvParamString) {
      urlParams[urlKey] = newPtvParamString;
      const newQueryString = toQueryString(urlParams);
      history.replace({ search: newQueryString });
    }
  }, [locationQueryString, urlKey, internalSelectedNodes, history]);


  /**
   * Fired when the URL changes and the state managed by the URL needs to be reflected inside the component
   */
  const updateStateFromURL = useCallback((newLocationQueryString: string) => {
    const urlParams = toParams(newLocationQueryString);
    const ptvParamString = urlParams[urlKey];

    const newPtvParamString = buildPtvUrlParamString(internalSelectedNodes);

    if (ptvParamString !== newPtvParamString && (treeData !== [])) {
      const urlState = parsePortalTreeViewUrlState(ptvParamString);

      // Are the IDs in the URL different from the internal selected node ids?
      if (shallowAreObjectsDifferent(urlState.id, internalSelectedNodes.map((n) => n.nodeId))) {
        const newSelectedNodes = urlState.id
          .map((n) => {
            const treeNode = findPortalTreeViewNode(treeData ?? [], n);
            if (treeNode && treeNode.data) {
              return buildSelectedNodeIdentifier(treeNode, treeNode.data);
            }
            return null;
          })
          .filter((n) => n !== null) as S[];

        setInternalSelectedNodes(newSelectedNodes);
      }
    }
  }, [urlKey, internalSelectedNodes, treeData, buildSelectedNodeIdentifier]);


  /**
   * Fired when the user drops a node on the Tree
   */
  const handleDropNode = useCallback((tree: NodeModel<unknown>[], options: {
    dragSourceId: string | number;
    dropTargetId: string | number;
    dragSource: NodeModel<unknown> | undefined;
    dropTarget: NodeModel<unknown> | undefined;
  }) => {
    // Fire the event for the implementer
    if (onDropNode) {
      onDropNode(tree as PortalTreeViewNodeItem<D>[], {
        dragSourceId: options.dragSourceId,
        dropTargetId: options.dropTargetId,
        dragSource: options.dragSource ? options.dragSource as PortalTreeViewNodeItem<D> : undefined,
        dropTarget: options.dropTarget ? options.dropTarget as PortalTreeViewNodeItem<D> : undefined,
      });
    }
  }, [onDropNode]);


  /**
   * Fired when the user drags a node over another node
   *
   * @note the documentation states that by overriding the canDrop property
   *       on the tree, the droppable property on the node data is ignored.
   *       Inherently, the safety checks around dropping a parent on a child
   *       are also lost. Therefore there is some additional work done here
   *       to re-introduce those checks.
   */
  const handleCanDropNode = useCallback((tree: NodeModel<unknown>[],
    {
      dragSourceId,
      dropTargetId,
      dragSource,
      dropTarget,
    }: {
      dragSourceId: string | number;
      dropTargetId: string | number;
      dragSource: NodeModel<unknown> | undefined;
      dropTarget: NodeModel<unknown> | undefined;
    }) => {
    // Fire the event for the implementer
    if (onCanDropNode) {
      const canDrop = onCanDropNode(tree as PortalTreeViewNodeItem<D>[], {
        dragSourceId,
        dropTargetId,
        dragSource: dragSource ? dragSource as PortalTreeViewNodeItem<D> : undefined,
        dropTarget: dropTarget ? dropTarget as PortalTreeViewNodeItem<D> : undefined,
      });

      // If the implementer is happy with the drag / drop relationship, perform the safety checks
      // (which are relatively heavy)
      if (canDrop) {
        if (
          // Don't allow a node to be dropped on itself
          (dragSourceId !== dropTargetId) &&

          // Don't allow a node to be dropped on its children
          (!findPortalTreeViewNode<D>(tree as PortalTreeViewNodeItem<D>[], dropTargetId, dragSource as PortalTreeViewNodeItem<D>)) &&

          // Don't allow a node to be dropped on its parent
          (dropTargetId !== dragSource?.parent)
        ) {
          return true;
        }
      }
    }

    return false;
  }, [onCanDropNode]);


  /**
   * Fired when a user clicks a node
   */
  const handleClickNode = useCallback((e: React.MouseEvent<HTMLSpanElement, MouseEvent>, node: NodeModel<D>, nodeData: D, parentNode?: NodeModel<D>, parentNodeData?: D) => {
    // Selecting nodes is forboden when the tree view is locked
    if (!isLocked) {
      setInternalSelectedNodes((previousInternalSelectedNodes) => {
        const newInternalSelectedNodes = [
          ...((e.ctrlKey && multiSelect) ? previousInternalSelectedNodes : []),
          buildSelectedNodeIdentifier(node, nodeData),
        ];
        return newInternalSelectedNodes;
      });
    }

    if (onNodeClick) {
      onNodeClick(e, node, nodeData, parentNode, parentNodeData);
    }
  }, [isLocked, multiSelect, onNodeClick, buildSelectedNodeIdentifier]);


  /**
   * Fired when the tree view item's context menu is requested
   */
  const handleShowContextMenu = useCallback(async (e: PortalTreeViewContextMenuTriggerEvent, node?: NodeModel<D>) => {
    // Don't override the context menu if the user is depressing the CTRL key
    if (!e.ctrlKey) {
      if (hasContextMenu) {
        e.preventDefault();
        e.stopPropagation();

        // Showing the context menu is forboden when the tree view is locked
        if (!isLocked) {
          // Check to see if the node is in the current selected list
          const alreadySelected = internalSelectedNodes.findIndex((n) => n.nodeId === node?.id) > -1;

          // If the node is in not already selected, change the selection to just the node under the context menu
          if (!alreadySelected) {
            const newInternalSelectedNodes = (node && node.data) ? [
              buildSelectedNodeIdentifier(node, node.data),
            ] : [];
            setInternalSelectedNodes(newInternalSelectedNodes);
          }

          showContextMenu(e);
        }
      }
      // Close the other menus
      toggleHeaderMenu(false);
      toggleAddRecordMenu(false);
    }
  }, [hasContextMenu, isLocked, internalSelectedNodes, toggleHeaderMenu, toggleAddRecordMenu, showContextMenu, buildSelectedNodeIdentifier]);


  /**
   * Fired when the menu in the header is toggled open or closed
   */
  const handleHeaderMenuToggle = useCallback((isHeaderMenuOpen: boolean) => {
    // Close the other menus
    hideContextMenu();
    if (isHeaderMenuOpen) toggleAddRecordMenu(false);
  }, [hideContextMenu, toggleAddRecordMenu]);


  /**
   * Fired when the add record menu in the header is toggled open or closed
   */
  const handleAddRecordMenuToggle = useCallback((isAddRecordMenuOpen: boolean) => {
    // Close the other menus
    hideContextMenu();
    if (isAddRecordMenuOpen) toggleHeaderMenu(false);
  }, [hideContextMenu, toggleHeaderMenu]);


  /**
   * Render an individual tree node
   */
  const renderTreeNode = useCallback((node: NodeModel, { isOpen, onToggle }: { isOpen: boolean, onToggle: (id: string | number) => void}) => {
    if ((!isRootNodeVisible) && (node.id === 'ROOT')) return <div className="hidden-root-node" />;

    const parentNode = (treeData ?? []).find((n) => n.id === node.parent);
    const nodeData: undefined | D = node.data as (undefined | D);
    const parentNodeData: undefined | D = parentNode?.data as (undefined | D);
    const isActive = internalSelectedNodes.findIndex((n) => n.nodeId === node.id) > -1;

    let nodeIcon: AN_ICON | undefined;

    if (onGetNodeIcon) {
      nodeIcon = onGetNodeIcon(node as NodeModel<D>, (nodeData ?? {}) as D, isOpen, isActive);
    }

    return (
      <PortalTreeViewNode
        key={node.id}
        id={node.id as string}
        parentId={node.parent as string}
        label={node.text}
        icon={nodeIcon}
        allowDrag={!isReadOnly && !isLocked}

        depth={nodeData?.depth ?? 0}
        isOpen={isOpen}
        isActive={isActive}
        childCount={nodeData?.childCount ?? 0}
        displayIndex={nodeData?.displayIndex ?? 0}
        siblingCount={parentNodeData?.childCount ?? 1}
        treeLineDisplayFlags={nodeData?.treeLineDisplayFlags ?? []}
        isRootNodeVisible={isRootNodeVisible}
        searchTerm={searchTerm}
        onClick={(e) => {
          // Toggle the node open if not already open
          if (nodeData?.childCount && (!isOpen || ((isActive || isLocked) && isOpen))) {
            onToggle(node.id);
          }
          handleClickNode(e, node as NodeModel<D>, (nodeData ?? {}) as D, parentNode, parentNodeData);
        }}
        onToggleOpen={onToggle}
        onContextMenu={(e: PortalTreeViewContextMenuTriggerEvent) => {
          handleShowContextMenu(e, node as NodeModel<D>);
        }}
      />
    );
  }, [isRootNodeVisible, treeData, internalSelectedNodes, onGetNodeIcon, isReadOnly, isLocked, searchTerm, handleClickNode, handleShowContextMenu]);


  /**
   * Whenever the data underpinning the tree changes, make sure the tree view node data
   * is calculated and updated
   */
  useEffect(() => {
    if (items) {
      setTreeData(calculatePortalTreeViewNodeData(items, isRootNodeVisible));
    }
  }, [items, isRootNodeVisible]);


  /**
   * Fired whenever the internal selected nodes change
   */
  useEffect(() => {
    if (
      oldInternalSelectedNodes &&
      // The old and the new values are different
      (
        shallowAreObjectsDifferent((oldInternalSelectedNodes).map((n) => n.nodeId), internalSelectedNodes.map((n) => n.nodeId)) ||
        shallowAreObjectsDifferent((oldInternalSelectedNodes).map((n) => n.parentNodeId), internalSelectedNodes.map((n) => n.parentNodeId))
      )
    ) {
      // Callback is defined
      if (onSelectedNodesChanged) {
        onSelectedNodesChanged(internalSelectedNodes);
      }

      // Update the URL to ensure the selected nodes are in the URL
      updateUrlFromState();
    }
  }, [internalSelectedNodes, oldInternalSelectedNodes, onSelectedNodesChanged, updateUrlFromState]);


  /**
   * Fired whenever the location query string changes and the component needs to be updated to match
   * the state managed inside the query string. Also fires when the component has finished loading.
   */
  useEffect(() => {
    if (
      // Don't do anything if the component is loading
      !isLoading &&

      // Location string has changed
      (oldLocationQueryString !== locationQueryString)
    ) {
      updateStateFromURL(locationQueryString);
    }
  }, [locationQueryString, oldLocationQueryString, updateStateFromURL, isLoading]);


  /**
   * When the component is first mounted, there is no tree data.
   * This effect ensures that the loading of the tree data
   * is followed by an evaluation of the URL to ensure the incoming URL
   * state is repspected.
   *
   * If the URL state is evaluated prior to the data being available, the URL
   * is cleared because it doesn't match valid data.
   */
  useEffect(() => {
    // Component has finished loading and the treeData has changed
    if (isMounted && !isDataLoaded && oldTreeDataChecksum !== treeDataChecksum) {
      setIsDataLoaded();
      updateStateFromURL(locationQueryString);
    }
  }, [isDataLoaded, isMounted, locationQueryString, oldTreeDataChecksum, setIsDataLoaded, treeDataChecksum, updateStateFromURL]);


  /**
   * Fired when the consumer passes down some new selected nodes.
   * There is a serious disconnect between the selected nodes provided by the consumer and the internal
   * selected nodes.
   *
   * The provided selected nodes will be parsed, compared against both the internal selected nodes and the
   * tree data. If there is a valid set of selected nodes, those will be applied to the internal selected
   * nodes. After which time, the onSelectedNodes event will fire, and the consumer will be given a new
   * set of valid selectedNodes for it's state.
   */
  useEffect(() => {
    // Only process this effect if it was the selected nodes that changed
    if (oldSelectedNodesChecksum && oldSelectedNodesChecksum !== selectedNodesChecksum) {
      // Now that we know that the incoming selected nodes are different, filter out nodes we don't have
      // const validSelectedNodes = [...selectedNodes.filter((n) => ((treeData?.findIndex((tn) => tn.id === n.nodeId) ?? -1) > -1))];

      // Compare the valid selected nodes against the internalSelectedNodes
      if ((
        shallowAreObjectsDifferent((internalSelectedNodes).map((n) => n.nodeId), selectedNodes.map((n) => n.nodeId)) ||
        shallowAreObjectsDifferent((internalSelectedNodes).map((n) => n.parentNodeId), selectedNodes.map((n) => n.parentNodeId))
      )) {
        setInternalSelectedNodes(selectedNodes);
      }
    }
  }, [selectedNodes, treeData, internalSelectedNodes, oldSelectedNodesChecksum, selectedNodesChecksum, ensureNodesAreVisible]);


  /**
   * Whenever there is a change to the internally selected nodes or the tree data this effect
   * will fire to ensure the selected nodes are visible.
   */
  useEffect(() => {
    ensureNodesAreVisible(internalSelectedNodes, treeData ?? []);
  }, [treeData, internalSelectedNodes, ensureNodesAreVisible]);


  /**
   * When the search term changes, make sure the entire tree is expanded
   */
  useEffect(() => {
    if (searchTerm) {
      setTimeout(() => {
        treeRef.current?.openAll();
      }, 0);
    }
  }, [treeData, searchTerm]);


  // Render
  return (
    <div
      className={classNames('ptv', className, {
        'no-items': hasNoItems,
      })}
    >
      {showHeader && (
        <PortalTreeViewHeader<S>
          title={title}
          menuRef={headerMenuRef}
          menuItems={menuItems}
          addRecordMenuRef={addRecordMenuRef}
          addRecordMenuItems={addRecordMenuItems}
          treeState={{
            isTreeReadOnly: isReadOnly,
            isTreeLocked: isLocked,
            isRootNodeVisible,
            isSafeModeEnabled,
          }}
          selectedNodes={internalSelectedNodes}
          isSearchBarVisible={isSearchBarVisible}
          searchTerm={searchTerm}
          onMenuToggle={handleHeaderMenuToggle}
          onAddRecordMenuToggle={handleAddRecordMenuToggle}
          onStartEdit={onStartEdit}
          onEndEdit={onEndEdit}
          onEnterSafeMode={onEnterSafeMode}
          onExitSafeMode={onExitSafeMode}
          onToggleSearchBar={onToggleSearchBar}
          onSearchTermChanged={onSearchTermChanged}
        />
      )}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className="ptv-tree-wrapper"
        onClick={deselectOnBackgroundClick ? () => {
          setInternalSelectedNodes([]);
        } : undefined}
        onContextMenu={(e) => handleShowContextMenu(e)}

        // This workaround isn't fool-proof but it will help until the `allowDrag` property can be properly implemented
        onDragStart={(!isReadOnly && !isLocked) ? undefined : (e) => { e.preventDefault(); e.stopPropagation(); }}
      >
        {/* No Items */}
        {!isLoading && hasNoItems && (
          <div className="no-items">
            <span>{noItemsMessage}</span>
          </div>
        )}

        {/* Not loading and we have tree data */}
        {treeData && (treeData.length > 0) && (
          <Tree
            ref={treeRef}
            classes={{
              root: classNames('ptv-tree', {
                'hide-root-node': !isRootNodeVisible,
                'is-locked': isLocked,
              }),
              dropTarget: 'drop-target',
              draggingSource: 'drag-source',
            }}
            tree={treeData}
            rootId={0}
            initialOpen={['ROOT']}
            onDrop={handleDropNode}
            canDrop={onCanDropNode ? handleCanDropNode : undefined}
            render={renderTreeNode}
          />
        )}

        {/* Loading */}
        {(isLoading) && (
          <div className="loading">
            <LoadingSpinner caption="Please wait..." />
          </div>
        )}
      </div>

      {/* Context Menu */}
      {hasContextMenu && (
        <PortalTreeViewContextMenu
          contextMenuRef={contextMenuRef}
          treeState={{
            isTreeReadOnly: isReadOnly,
            isTreeLocked: isLocked,
            isRootNodeVisible,
            isSafeModeEnabled,
          }}
          menuItems={menuItems}
          selectedNodes={internalSelectedNodes}
        />
      )}


      {/* Some kind of error has occurred */}
      {error && (
        <FriendlyFormMessage
          formMessage={Array.isArray(error) ? 'An error has occurred!' : error}
          errors={Array.isArray(error) ? error : undefined}
          hasErrors
        />
      )}
    </div>
  );
};

export const PortalTreeView = React.forwardRef(PortalTreeViewComponent);
