import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';

import { ProjectResourcesWidget } from '../components/widgets/project-resources.widget';

import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

export const ProjectResourcesWidgetDefinition: ICollectionWidgetDefinition = {
  component: ProjectResourcesWidget,
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Resource',
  fields: [
    {
      name: 'project_id',
      title: 'Project Id',
      visible: false,
      isParentId: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'user',
      title: 'Resource',
      formSaveField: 'user_id',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
    },
    {
      name: 'role',
      title: 'Role',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.RESOURCE_TYPE_PICKER,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'role',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formSaveField: 'role_id',
    },
  ],
};
