import React, { ChangeEvent, useContext, useEffect, useRef, useState, useCallback } from 'react';
import { Card, CardBody, CardHeader, Container, Input } from 'reactstrap';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { ColCellContentArg } from '@fullcalendar/resource-common';
import enAuLocale from '@fullcalendar/core/locales/en-au';

import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { documentTitle } from '../../utils/helpers';
import PageHeader from '../app-layout/page-header';
import { APIContext } from '../providers/api-provider';
import { IProjectRecord } from '../../types/project/project.record.interface';
import Icon from '../layout-helpers/icon';
import { loadLocalPrefs, saveLocalPref } from '../../utils/localStorage';


interface IFullCalendarProjectRecord {
  resourceId: string,
  applicationId: string,
  color: string,
  borderColor: string,
  status?: string,
  title?: string,
  display: string,
  username: string,
  start: string,
  end: string,
}

interface ICellContentArgs extends ColCellContentArg {
  fieldValue: string
}


export const ProjectSchedulePage: React.FC<{ title: string }> = ({ title }) => {
  const { apiFetch } = useContext(APIContext);

  const { projectScheduleCalendarPrefs } : {
    projectScheduleCalendarPrefs?: { showState: string, statusFilter: string, likelihoodThreshold: number }
  } = loadLocalPrefs();

  const abortProjectRequest = useRef<AbortController | null>(null);

  const [isLoadingProjects, setIsLoadingProjects] = useState(false);
  // const [projectData, setProjectData] = useState<IFullCalendarProjectRecord[]>([]);
  const [resources, setResources] = useState<{ name?: string, title?: string, id: string }[]>([]);
  const [eventData, setEventData] = useState<IFullCalendarProjectRecord[]>([]);
  const [startDay, setStartDay] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDay, setEndDay] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [showState, setShowState] = useState(projectScheduleCalendarPrefs?.showState || 'Vic');
  const [statusFilter, setStatusFilter] = useState(projectScheduleCalendarPrefs?.statusFilter || 'all');
  const [likelihoodThreshold, setLikelihoodThreshold] = useState(projectScheduleCalendarPrefs?.likelihoodThreshold || 8);

  const mapEventRecords = (results: IProjectRecord[]) : IFullCalendarProjectRecord[] => results.map((result: IProjectRecord) => ({
    resourceId: String(result.id),
    applicationId: String(result.id),
    color: `rgba(0,${(result._status_name === 'Active') ? 119 : 135},${(result._status_name === 'Active') ? 172 : 230},${(result.likelihood || 0) / 10})`,
    borderColor: 'rgba(0,0,0,0)',
    status: String(result._status_name),
    title: `${result._status_name && result._status_name === 'Proposal' ? `L${result.likelihood} | ` : ''}${(result.project_number)} | ${(result.name)}`,
    display: 'default',
    username: result.leave_application?.user?.name ?? 'Name not set',
    start: moment(result.project_start).startOf('day').format(),
    end: moment(result.project_end).endOf('day').format(),
    sourceData: result,
  }));

  const mapResources = (results: IProjectRecord[]) : { name?: string, id: string, title?: string }[] => results.map((result: IProjectRecord) => ({
    name: String(result.project_number),
    id: String(result.id),
    title: String(result.project_number),
    state: String(result.state),
    status: String(result._status_name),
    startDate: result.project_start,
    likelihood: result.likelihood,
  }));


  const statusOptions = [
    { value: 'all', label: 'Active & Proposal' },
    { value: 'active', label: 'Active' },
    { value: 'proposal', label: 'Proposal' },
  ];

  const likelihoodOptions = [
    { value: '10', label: 'Likelihood: 10' },
    { value: '8', label: 'Likelihood: 8+' },
    { value: '3', label: 'Likelihood: 3+' },
    { value: '0', label: 'Likelihood: All' },
  ];

  const stateOptions = [
    { value: 'Vic', label: 'Vic' },
    { value: 'Tas', label: 'Tas' },
    { value: 'NSW', label: 'NSW' },
    { value: 'ACT', label: 'ACT' },
    { value: 'QLD', label: 'QLD' },
    { value: 'SA', label: 'SA' },
    { value: 'WA', label: 'WA' },
    { value: 'NT', label: 'NT' },
    { value: 'all', label: 'All States' },
  ];

  /**
   * Component has mounted useEffect to get initial data
   */
  useEffect(() => {
    documentTitle(title);
    loadProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Load the resource list (people)
   *
   * @returns void
   */
  const loadProjects = useCallback(() => {
    if (isLoadingProjects) return;

    if (abortProjectRequest.current) {
      abortProjectRequest.current.abort();
    }
    abortProjectRequest.current = apiAborter();

    setIsLoadingProjects(true);

    const queryString = [
      `from=${startDay}`,
      `to=${endDay}`,
      `state=${showState}`,
      `likelihood_threshold=${likelihoodThreshold}`,
      `status_filter=${statusFilter}`,
    ].join('&');

    apiFetch(
      `/report/resourcing/projects?${queryString}`,
      {
        name: 'NationalProjectScheduleView::loadProjects',
        signal: abortProjectRequest.current.signal,
      },
    ).then((response) => {
      if (response.success) {
        abortProjectRequest.current = null;

        const results = response.body;

        setIsLoadingProjects(false);

        // Map the project for fullcalendar
        // setProjectData(results);
        setEventData(mapEventRecords(results));
        setResources(mapResources(results));
      } else if (!response.aborted) {
        abortProjectRequest.current = null;
        console.error('NationalProjectScheduleView::loadProjects', response.error);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDay, isLoadingProjects, showState, startDay, statusFilter, likelihoodThreshold]);


  useEffect(() => {
    saveLocalPref('projectScheduleCalendarPrefs', { showState, likelihoodThreshold, statusFilter });
    loadProjects();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showState, likelihoodThreshold, statusFilter]);

  const handleStateChage = (event: ChangeEvent<HTMLInputElement>) => {
    setShowState(event.target.value);
  };

  const handleChangeLikelihood = (event: ChangeEvent<HTMLInputElement>) => {
    setLikelihoodThreshold(Number(event.target.value));
  };

  const handleChangeStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setStatusFilter(event.target.value);
  };

  return (
    <Container fluid>
      <PageHeader title={title} />
      <Card>
        <CardHeader style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
          <h4 style={{ display: 'flex' }}>
            Schedule
            {' '}
            {isLoadingProjects && (
              <Icon i="rolling" className="ml-2" />
            )}
          </h4>
          <div style={{ display: 'flex ' }}>
            <div style={{ display: 'inline-block', minWidth: '7rem', marginLeft: '1rem' }}>
              <Input type="select" onChange={handleStateChage} value={showState}>
                {stateOptions.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </Input>
            </div>
            <div style={{ display: 'inline-block', minWidth: '10rem', marginLeft: '1rem' }}>
              <Input type="select" onChange={handleChangeStatus} value={statusFilter}>
                {statusOptions.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </Input>
            </div>
            <div style={{ display: 'inline-block', minWidth: '8rem', marginLeft: '1rem' }}>
              <Input type="select" onChange={handleChangeLikelihood} value={likelihoodThreshold}>
                {likelihoodOptions.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </Input>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <FullCalendar
            locale={enAuLocale}
            plugins={[resourceTimelinePlugin]}
            datesSet={(dateInfo) => {
              setStartDay(moment(dateInfo.start).format('YYYY-MM-DD'));
              setEndDay(moment(dateInfo.end).format('YYYY-MM-DD'));
            }}
            resources={resources}
            resourceAreaWidth="10rem"
            resourceAreaColumns={[
              {
                field: 'title',
                headerContent: 'Project',
                cellContent: (args: ICellContentArgs) => (
                  <Link to={`/crm/projects/${args.resource?.id}`}>
                    {args.fieldValue}
                    {/* {JSON.stringify(args)} */}
                  </Link>
                ),
              },
              // {
              //   field: 'status',
              //   headerContent: 'Status',
              // },
              // {
              //   field: 'likelihood',
              //   headerContent: 'Likelihood',
              // },
            ]}
            resourceOrder="startDate,likelihood,status"
            resourceGroupField={showState === 'all' ? 'state' : undefined}
            events={eventData}
            displayEventTime={false}
            resourcesInitiallyExpanded={showState !== 'all'}
            initialView="resourceTimelineMonth"
            schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
            headerToolbar={{
              left: 'today prev,next',
              center: 'title',
              right: 'resourceTimelineTwoWeeks,resourceTimelineMonth,resourceTimelineSixMonth',
            }}
            buttonText={{
              today: 'Today',
            }}
            aspectRatio={2}
            views={{
              resourceTimelineTwoWeeks: {
                type: 'resourceTimeline',
                duration: { weeks: 2 },
                slotDuration: '24:00:00',
                slotLabelFormat: [
                  { month: 'long' },
                  { weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true },
                ],
                buttonText: 'Fortnight',
              },
              resourceTimelineMonth: {
                type: 'resourceTimeline',
                slotLabelFormat: [
                  { month: 'long' },
                  { weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true },
                ],
                duration: { month: 1 },
                buttonText: 'Month',
              },
              resourceTimelineSixMonth: {
                type: 'resourceTimeline',
                slotLabelFormat: [
                  { month: 'long' },
                  { weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true },
                ],
                duration: { months: 6 },
                buttonText: '6 Months',
              },
            }}
            editable={false}
            stickyFooterScrollbar
            nowIndicator
          />
        </CardBody>
      </Card>
    </Container>
  );
};
