/* eslint-disable key-spacing */
export const SERVICE_JOB_STATUS = {
  REQUESTED: 1,
  VERIFYING: 2,
  QUOTING: 3,
  QUOTED:  4,
  APPROVED:  5,
  SCHEDULED: 6,
  IN_PROGRESS: 7,
  CONFIRMING:  8,
  CI_HOLD: 9,
  CLIENT_HOLD: 10,
  CANCELLED: 11,
  REFUSED: 12,
  INVOICING: 13,
  INVOICED:  14,
  CLOSED:  15,
  ARCHIVED:  16,
} as const;
export type SERVICE_JOB_STATUS = typeof SERVICE_JOB_STATUS;
export type A_SERVICE_JOB_STATUS = SERVICE_JOB_STATUS[keyof SERVICE_JOB_STATUS];

export const SERVICE_JOB_STATUS_NAME_MAP: {[K in A_SERVICE_JOB_STATUS]: string} = {
  [SERVICE_JOB_STATUS.REQUESTED]:      'Requested',
  [SERVICE_JOB_STATUS.VERIFYING]:      'Verifying',
  [SERVICE_JOB_STATUS.QUOTING]:        'Quoting',
  [SERVICE_JOB_STATUS.QUOTED]:         'Quoted',
  [SERVICE_JOB_STATUS.APPROVED]:       'Approved',
  [SERVICE_JOB_STATUS.SCHEDULED]:      'Scheduled',
  [SERVICE_JOB_STATUS.IN_PROGRESS]:    'In Progress',
  [SERVICE_JOB_STATUS.CONFIRMING]:     'Confirming',
  [SERVICE_JOB_STATUS.CI_HOLD]:        'Ci Hold',
  [SERVICE_JOB_STATUS.CLIENT_HOLD]:    'Client Hold',
  [SERVICE_JOB_STATUS.CANCELLED]:      'Cancelled',
  [SERVICE_JOB_STATUS.REFUSED]:        'Refused',
  [SERVICE_JOB_STATUS.INVOICING]:      'Invoicing',
  [SERVICE_JOB_STATUS.INVOICED]:       'Invoiced',
  [SERVICE_JOB_STATUS.CLOSED]:         'Closed',
  [SERVICE_JOB_STATUS.ARCHIVED]:       'Archived',
};
