import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { THEME_COLOR } from '../constants/theme-color.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { defaultTableSettings } from './default-table-settings';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';

/**
 * Table Definition for My Allowances page
 */
const monthlyCommissionSnapshotsTableSettings = {
  ...defaultTableSettings,
  itemCaption: 'Snapshot',
  baseRoute: '/commission-snapshot',

  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        // 'id',
        'project_number',
        'state',
        'project_name',
        'status',
        'partner_program',
        'client_name',
        'sales_owner',
        'project_manager',
        'maintenance_type',
        'value',
        'forecast_amount',
        'order_amount',
        'prior_invoice_amount',
        'prior_invoice_count',
        'prior_invoice_numbers',
        'period_invoice_count',
        'period_invoice_numbers',
        'period_invoice_amount',
        'new_invoice_count',
        'new_invoice_numbers',
        'new_invoice_amount',
        'total_invoice_amount',
        'total_invoice_count',
        'all_invoice_numbers',
        'forecast_balance',
        'created_at',
        'lead_at',
        'proposal_at',
        'lost_at',
        'active_at',
        'completed_at',
        'mat_1_cost',
        'mat_1_price',
        'mat_2_cost',
        'mat_2_price',
        'mat_3_cost',
        'mat_3_price',
        'mat_4_cost',
        'mat_4_price',
        'mat_5_cost',
        'mat_5_price',
        'mat_6_cost',
        'mat_6_price',
        'commissioning',
        'design_and_documentation',
        'installation',
        'logistics',
        'parking',
        'project_management',
        'rack_build',
        'rough_in',
        'software_development',
        'supplier_freight',
        'training',
        'pending_labour_on_change_orders',
        'pending_product_cost_on_change_orders',
        'pending_product_price_on_change_orders',
        'total_labour_hours',
        'total_labour_cost',
        'total_labour_price',
        'total_product_cost',
        'total_product_price',
        'total_installation_price',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'project_number',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'project_number',
        'dtools_id',
        'state',
        'project_name',
        'status',
        'partner_program',
        'client_name',
        'sales_owner',
        'project_manager',
        'maintenance_type',
        'value',
        'forecast_amount',
        'order_amount',
        'prior_invoice_amount',
        'prior_invoice_count',
        'prior_invoice_numbers',
        'period_invoice_amount',
        'new_invoice_count',
        'new_invoice_numbers',
        'new_invoice_amount',
        'period_invoice_count',
        'period_invoice_numbers',
        'total_invoice_amount',
        'total_invoice_count',
        'all_invoice_numbers',
        'forecast_balance',
        'created_at',
        'lead_at',
        'proposal_at',
        'lost_at',
        'active_at',
        'completed_at',
        'mat_1_cost',
        'mat_1_price',
        'mat_2_cost',
        'mat_2_price',
        'mat_3_cost',
        'mat_3_price',
        'mat_4_cost',
        'mat_4_price',
        'mat_5_cost',
        'mat_5_price',
        'mat_6_cost',
        'mat_6_price',
        'commissioning',
        'design_and_documentation',
        'installation',
        'logistics',
        'parking',
        'project_management',
        'rack_build',
        'rough_in',
        'software_development',
        'supplier_freight',
        'training',
        'pending_labour_on_change_orders',
        'pending_product_cost_on_change_orders',
        'pending_product_price_on_change_orders',
        'total_labour_hours',
        'total_labour_cost',
        'total_labour_price',
        'total_product_cost',
        'total_product_price',
        'total_installation_price',
      ],
    },
  ],

  /**
   * Definition of Columns for this table
   * Includes settings for APIPolyForm fields
   */
  columns: [
    {
      name: 'id',
      title: 'ID',
      description: 'Project ID',
      format: COLUMN_FORMAT.ID_LINK,
      linkId: 'id',
      linkRoute: '/crm/projects',
      visible: false,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'project_number',
      title: 'P-Number',
      description: 'Project Unique Identifier',
      format: COLUMN_FORMAT.P_NUMBER,
      linkId: 'id',
      linkRoute: '/crm/projects',
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      sortColumn: 'id',
      order: 1,
      formOrder: 10,
    },
    {
      name: 'dtools_id',
      title: 'Dtools Id',
      description: 'Dtools id',
      visible: false,
      order: 20,
    },
    {
      name: 'state',
      title: 'State',
      description: 'State',
      format: COLUMN_FORMAT.SHORT_TEXT,
      visible: true,
      order: 30,
    },
    {
      name: 'project_name',
      title: 'Project Name',
      description: 'Project name',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      order: 40,
    },
    {
      name: 'status',
      title: 'Status',
      description: 'Status',
      visible: true,
      order: 50,
    },
    {
      name: 'partner_program',
      title: 'Partner Program',
      description: 'Partner program',
      visible: true,
      order: 60,
    },
    {
      name: 'client_name',
      title: 'Client Name',
      description: 'Client name',
      visible: true,
      order: 70,
    },
    {
      name: 'sales_owner',
      title: 'Sales Owner',
      description: 'Sales owner',
      visible: true,
      order: 80,
    },
    {
      name: 'project_manager',
      title: 'Project Manager',
      description: 'Project manager',
      visible: true,
      order: 90,
    },
    {
      name: 'maintenance_type',
      title: 'Maintenance Type',
      description: 'Maintenance type',
      visible: true,
      order: 100,
    },
    {
      name: 'value',
      title: 'Value',
      description: 'Value',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 110,
    },
    {
      name: 'forecast_amount',
      title: 'Forecast Amount',
      description: 'Forecast amount',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 120,
    },
    {
      name: 'order_amount',
      title: 'Order Amount',
      description: 'Order amount',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 130,
    },
    {
      name: 'prior_invoice_amount',
      title: 'Prior Invoice Amount',
      description: 'Prior invoice amount',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 140,
    },
    {
      name: 'prior_invoice_count',
      title: 'Prior Invoice Count',
      description: 'Prior invoice count',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      visible: true,
      order: 150,
    },
    {
      name: 'prior_invoice_numbers',
      title: 'Prior Invoice Numbers',
      description: 'Prior invoice numbers',
      visible: true,
      order: 160,
    },
    {
      name: 'period_invoice_count',
      title: 'Period Invoice Count',
      description: 'Period invoice count',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      visible: true,
      order: 170,
    },
    {
      name: 'period_invoice_numbers',
      title: 'Period Invoice Numbers',
      description: 'Period invoice numbers',
      visible: true,
      order: 180,
    },
    {
      name: 'period_invoice_amount',
      title: 'Period Invoice Amount',
      description: 'Period invoice amount',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 190,
    },
    {
      name: 'new_invoice_count',
      title: 'New Invoice Count',
      description: 'New invoice count',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      visible: true,
      order: 200,
    },
    {
      name: 'new_invoice_numbers',
      title: 'New Invoice Numbers',
      description: 'New invoice numbers',
      visible: true,
      order: 210,
    },
    {
      name: 'new_invoice_amount',
      title: 'New Invoice Amount',
      description: 'New invoice amount',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 220,
    },
    {
      name: 'total_invoice_amount',
      title: 'Total Invoice Amount',
      description: 'Total invoice amount',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 230,
    },
    {
      name: 'total_invoice_count',
      title: 'Total Invoice Count',
      description: 'Total invoice count',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      visible: true,
      order: 240,
    },
    {
      name: 'all_invoice_numbers',
      title: 'All Invoice Numbers',
      description: 'All invoice numbers',
      visible: true,
      order: 250,
    },
    {
      name: 'forecast_balance',
      title: 'Forecast Balance',
      description: 'Forecast balance',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 260,
    },
    {
      name: 'created_at',
      title: 'Created At',
      description: 'Created at',
      format: COLUMN_FORMAT.DATE,
      visible: true,
      order: 270,
    },
    {
      name: 'lead_at',
      title: 'Lead At',
      description: 'Lead at',
      format: COLUMN_FORMAT.DATE,
      visible: true,
      order: 280,
    },
    {
      name: 'proposal_at',
      title: 'Proposal At',
      description: 'Proposal at',
      format: COLUMN_FORMAT.DATE,
      visible: true,
      order: 290,
    },
    {
      name: 'lost_at',
      title: 'Lost At',
      description: 'Lost at',
      format: COLUMN_FORMAT.DATE,
      visible: true,
      order: 300,
    },
    {
      name: 'active_at',
      title: 'Active At',
      description: 'Active at',
      format: COLUMN_FORMAT.DATE,
      visible: true,
      order: 310,
    },
    {
      name: 'completed_at',
      title: 'Completed At',
      description: 'Completed at',
      format: COLUMN_FORMAT.DATE,
      visible: true,
      order: 320,
    },
    {
      name: 'mat_1_cost',
      title: 'Mat 1 Cost',
      description: 'Mat 1 cost',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 330,
    },
    {
      name: 'mat_1_price',
      title: 'Mat 1 Price',
      description: 'Mat 1 price',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 340,
    },
    {
      name: 'mat_2_cost',
      title: 'Mat 2 Cost',
      description: 'Mat 2 cost',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 350,
    },
    {
      name: 'mat_2_price',
      title: 'Mat 2 Price',
      description: 'Mat 2 price',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 360,
    },
    {
      name: 'mat_3_cost',
      title: 'Mat 3 Cost',
      description: 'Mat 3 cost',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 370,
    },
    {
      name: 'mat_3_price',
      title: 'Mat 3 Price',
      description: 'Mat 3 price',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 380,
    },
    {
      name: 'mat_4_cost',
      title: 'Mat 4 Cost',
      description: 'Mat 4 cost',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 390,
    },
    {
      name: 'mat_4_price',
      title: 'Mat 4 Price',
      description: 'Mat 4 price',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 400,
    },
    {
      name: 'mat_5_cost',
      title: 'Mat 5 Cost',
      description: 'Mat 5 cost',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 410,
    },
    {
      name: 'mat_5_price',
      title: 'Mat 5 Price',
      description: 'Mat 5 price',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 420,
    },
    {
      name: 'mat_6_cost',
      title: 'Mat 6 Cost',
      description: 'Mat 6 cost',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 430,
    },
    {
      name: 'mat_6_price',
      title: 'Mat 6 Price',
      description: 'Mat 6 price',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 440,
    },
    {
      name: 'commissioning',
      title: 'Commissioning',
      description: 'Commissioning',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 450,
    },
    {
      name: 'design_and_documentation',
      title: 'Design And Documentation',
      description: 'Design and documentation',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 460,
    },
    {
      name: 'installation',
      title: 'Installation',
      description: 'Installation',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 470,
    },
    {
      name: 'logistics',
      title: 'Logistics',
      description: 'Logistics',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 480,
    },
    {
      name: 'parking',
      title: 'Parking',
      description: 'Parking',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 490,
    },
    {
      name: 'project_management',
      title: 'Project Management',
      description: 'Project management',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 500,
    },
    {
      name: 'rack_build',
      title: 'Rack Build',
      description: 'Rack build',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 510,
    },
    {
      name: 'rough_in',
      title: 'Rough In',
      description: 'Rough in',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 520,
    },
    {
      name: 'software_development',
      title: 'Software Development',
      description: 'Software development',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 530,
    },
    {
      name: 'supplier_freight',
      title: 'Supplier Freight',
      description: 'Supplier freight',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 540,
    },
    {
      name: 'training',
      title: 'Training',
      description: 'Training',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 550,
    },
    {
      name: 'pending_labour_on_change_orders',
      title: 'Pending Labour On Change Orders',
      description: 'Pending labour on change orders',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 560,
    },
    {
      name: 'pending_product_cost_on_change_orders',
      title: 'Pending Product Cost On Change Orders',
      description: 'Pending product cost on change orders',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 570,
    },
    {
      name: 'pending_product_price_on_change_orders',
      title: 'Pending Product Price On Change Orders',
      description: 'Pending product price on change orders',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 580,
    },
    {
      name: 'total_labour_hours',
      title: 'Total Labour Hours',
      description: 'Total labour hours',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      visible: true,
      order: 590,
    },
    {
      name: 'total_labour_cost',
      title: 'Total Labour Cost',
      description: 'Total labour cost',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 600,
    },
    {
      name: 'total_labour_price',
      title: 'Total Labour Price',
      description: 'Total labour price',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 610,
    },
    {
      name: 'total_product_cost',
      title: 'Total Product Cost',
      description: 'Total product cost',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 620,
    },
    {
      name: 'total_product_price',
      title: 'Total Product Price',
      description: 'Total product price',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 630,
    },
    {
      name: 'total_installation_price',
      title: 'Total Installation Price',
      description: 'Total installation price',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 640,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add a Snapshot',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new Commission Snapshot',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the commission Snapshot',
      successMessage: 'Updated licences',
      failMessage: 'Failed to update Commission Snapshot',
      hideRecordActionButton: true,
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the Commission Snapshot',
      successMessage: 'Commission Snapshot deleted',
      failMessage: 'Failed to delete Commission Snapshot',
      hideRecordActionButton: false,
    },
  ],


  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Leave Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'forecasting',
      title: 'Forecasting',
      description: 'Project Forecasting',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_FORECASTS,
      apiRoute: (rowData) => `/project/${rowData.id}/forecast`,
      apiQuery: [
        'with[]=type',
        'pagelength=0',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'forecast_count',
          badgeColor: THEME_COLOR.DARK,
          // Take out the project forecast total row
          onCalculateValue: (value) => (value ? value - 1 : 0),
        },
      ],
    },
    {
      name: 'clientorders',
      title: 'Client Orders',
      description: 'Client Orders',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_CLIENT_ORDERS,
      apiRoute: (rowData) => `/project/${rowData.id}/order`,
      apiQuery: [
        'with[]=activatingPurchaseOrderProjectFile',
        'with[]=proposalProjectFile',
        'pagelength=0',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'order_count',
          badgeColor: THEME_COLOR.DARK,
        },
      ],
    },
    {
      name: 'invoices',
      title: 'Invoices',
      description: 'Client Invoices',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_INVOICES,
      apiRoute: (rowData) => `/project/${rowData.id}/invoice`,
      apiQuery: [
        'with[]=type:id,name',
        'pagelength=0',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'total_invoice_count',
          badgeColor: THEME_COLOR.DARK,
        },
      ],
    },
  ],

};

export default monthlyCommissionSnapshotsTableSettings;
