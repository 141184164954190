import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { EventEmitter } from 'events';
import { Prompt } from 'react-router';

import RecordDetailView from '../record-detail-view/record-detail-view';
import ProgressBar from '../layout-helpers/progress-bar';
import PageHeader from '../app-layout/page-header';
import FourOhFour from '../error-pages/four-oh-four';
import Icon from '../layout-helpers/icon';
import { documentTitle } from '../../utils/helpers';
import { updateTableSettings } from '../../actions/portal-data-table/update-settings';
import { ScrollToTopOnMount } from '../router/scroll-to-top-on-mount';
import { getFieldValue } from '../render-functions';
import { connectToAPIProvider } from '../providers/api-provider';
import API_PROVIDER_PROP_TYPES from '../../prop-types/api-provider-prop-types';
import { apiAborter } from '../../helpers/api-aborter.helper';

export const TAB_REGEX = ':tab([a-zA-Z-]+)?';

/**
 * @class RecordDetailPage
 * Used for viewing a single record as a page (i.e. not inline in a table)
 *
 * @TODO make the header behave like the old product page and render <ProjectNumber> in the header
 */
class RecordDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDirty: false,
      isBusy: true,
      hasError: false,
      recordData: undefined,
      recordDataChecksum: 0,
      currentId: props.match.params.id,
    };

    this.pageResizeListener = new EventEmitter();
    this.abortController = null;
  }


  /**
   * @inheritdoc
   */
  componentDidMount = () => {
    // Check to see if the initial URL request has a different activeTabKey
    const { activeTabKey, dispatchUpdateTableSettings, match } = this.props;
    const { currentId } = this.state;
    const urlActiveTabKey = match.params.tab || null;

    if (activeTabKey !== urlActiveTabKey) {
      dispatchUpdateTableSettings({ activeTabKey: urlActiveTabKey });
    }

    // Load the current record data
    this.loadRecord(currentId);
  };


  /**
   * @inheritdoc
   */
  shouldComponentUpdate = (nextProps, nextState) => {
    let shouldRender = true;

    let { currentId } = nextState;

    const {
      activeTabKey, location, history, dispatchUpdateTableSettings, defaultTabKey,
    } = this.props;
    const {
      activeTabKey: nextActiveTabKey, pushToHistory, match, replaceInHistory,
    } = nextProps;

    const urlId = match.params.id;
    const urlActiveTabKey = match.params.tab;

    // If different ID, load record
    if (currentId !== urlId) {
      currentId = urlId;
      this.loadRecord(urlId);

      // do not update if loading new record
      shouldRender = false;
    }

    // TODO
    //  - come up with a better way of building a url from the route
    //  - this is currently hard coded to match the crm-router
    // build new string with regex from match.path
    const newUrl = match
      .path
      .replace(':id', currentId)
      .replace(TAB_REGEX, nextActiveTabKey || defaultTabKey);

    // Did the active tab key change as a result of redux?
    if (activeTabKey !== nextActiveTabKey) {
      if (newUrl !== location.pathname) {
        if (pushToHistory) history.push(newUrl);
        else if (replaceInHistory) history.replace(newUrl);
      }
    }

    // Did the active tab key change as a result of a URL change? (i.e. back / forward navigation)
    else if (activeTabKey !== urlActiveTabKey) {
      // If the URL Tab Key is empty, assume we want to "fix" the active tab in the URL that stored in props
      if (urlActiveTabKey) {
        dispatchUpdateTableSettings({ activeTabKey: urlActiveTabKey });
      }
      else {
        history.replace(newUrl);
      }
      shouldRender = false;
    }

    return shouldRender;
  };


  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    // Stop any data from loading
    if (this.abortController) this.abortController.abort();
  }


  /**
   * @description
   * Fired when the wrapper around the record detail view is resized and notifies any children
   * who are subscribed to the pageResizeListener that the container width has changed.
   */
  handlePageWidthResize = (width) => {
    this.pageResizeListener.emit('resizeWidth', width);
  }


  /**
   * @description
   * Fired by the record detail view when the user makes a change deemed worthy of confirming abandonment
   *
   * @param {boolean} newIsDirty
   */
  handleDirtyChange = (newIsDirty) => {
    this.setState({
      isDirty: newIsDirty,
    });
  };


  /**
   * @description
   * Load the Current Record Id
   *
   * @param {number | string} recordId the record to load
   * @param {boolean} [quiet=false] whether to set the "isBusy" flag and interrupt children
   */
  loadRecord = (recordId, quiet) => {
    if (this.abortController) {
      this.abortController.abort();
    }

    if (!recordId) {
      this.setState({ hasError: true, currentId: null, isBusy: false });
      return;
    }

    this.setState({
      currentId: recordId,
      isBusy: (quiet !== true),
      hasError: false,
    }, async () => {
      const {
        baseRoute,
        baseQueryString,
        apiProvider: { apiFetch },
      } = this.props;
      const { recordDataChecksum } = this.state;

      this.abortController = apiAborter();

      const response = await apiFetch(`${baseRoute}/${recordId}?${baseQueryString}`, { signal: this.abortController.signal });

      if (response.success) {
        this.abortController = null;
        documentTitle(response.body.data.name || response.body.data.description || 'Record');
        this.setState({
          recordData: response.body.data || response.body,
          recordDataChecksum: (recordDataChecksum + 1),
          isBusy: false,
        });
      } else if (!response.aborted) {
        this.abortController = null;
        this.setState({
          hasError: true,
          isBusy: false,
          recordData: null,
          recordDataChecksum: (recordDataChecksum + 1),
        });
      }
    });
  };


  /**
   * @description
   * Called by the render method when rendering out the page header.
   * Can be overridden by consumers.
   *
   * @return {React.node}
   */
  renderPageHeader = () => {
    const {
      renderPageHeader, itemCaption, match, columns,
    } = this.props;
    const { currentId, recordData } = this.state;
    if (renderPageHeader) {
      return renderPageHeader(recordData);
    }

    // TODO: this still isn't perfect - maybe put a getter function on the table definition
    function getRecordName() {
      if (!recordData) return '';
      const nameColumn = columns.find((column) => column.name === 'name') || columns.find((column) => column.name === 'description');
      if (nameColumn) {
        return getFieldValue(recordData, nameColumn);
      }
      return '';
    }

    return (
      <PageHeader
        title={
          recordData ? (
            <span>
              {`${itemCaption || ''} [${currentId}]: ${getRecordName() || ''}`.trim()}
            </span>
          ) : (
            <>
              <Icon i="rolling" />
              <span className="loading-caption">{(`Loading... ${match ? match.params.id : ''}`).trim()}</span>
            </>
          )
        }
      />
    );
  }


  /**
   * @inheritdoc
   */
  render() {
    const {
      children, match, tableIdentifier, history, location, className,
    } = this.props;
    const {
      recordData, recordDataChecksum, hasError, isBusy, activeTabKey, currentId, isDirty,
    } = this.state;

    if (hasError) return <FourOhFour />;
    return (
      <Container fluid className={className}>
        <ScrollToTopOnMount />
        {isDirty && (
          // Use a React Router Prompt to remind the user that they have unsaved changes
          <Prompt
            message={
              (navLocation, navAction) => {
                // if the location action represents a tab change (i.e. not a navigate away event)
                // then the Record Detail View will have handled the action. Don't prompt the user twice.
                if ((navLocation.pathname === location.pathname) || (navAction !== 'PUSH')) {
                  return true;
                }

                // This is a proper "navigate away" action. Perform the prompt.
                return 'Discard your unsaved changes?';
              }
            }

          />
        )}
        {this.renderPageHeader()}
        <div className="record-detail-page">
          <ReactResizeDetector handleWidth onResize={this.handlePageWidthResize}>
            <>
              {children}
              {recordData && (
                <RecordDetailView
                  key={tableIdentifier}
                  tableIdentifier={tableIdentifier}
                  rowData={recordData}
                  rowDataChecksum={recordDataChecksum}
                  activeTabKey={activeTabKey}
                  refreshRecord={(quiet) => this.loadRecord(currentId, quiet)}
                  busy={isBusy}
                  location={location}
                  history={history}
                  match={match}
                  navigateBackOnDelete
                  onDirtyChange={this.handleDirtyChange}
                />
              )}
              <ProgressBar complete={recordData !== undefined} />
            </>
          </ReactResizeDetector>
        </div>
      </Container>
    );
  }
}

RecordDetailPage.defaultProps = {
  children: null,
  itemCaption: 'Viewing Record',
  activeTabKey: null,
  renderPageHeader: null,
  className: '',
};

RecordDetailPage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  tableIdentifier: PropTypes.string.isRequired,
  renderPageHeader: PropTypes.func,
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
  className: PropTypes.string,

  // Router
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      tab: PropTypes.string,
    }).isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired, // Router
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  // Redux
  activeTabKey: PropTypes.string,
  defaultTabKey: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  pushToHistory: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  replaceInHistory: PropTypes.bool.isRequired,
  baseRoute: PropTypes.string.isRequired,
  itemCaption: PropTypes.string,
  baseQueryString: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

  // Dispatch
  dispatchUpdateTableSettings: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  activeTabKey: state.tableSettings[ownProps.tableIdentifier].activeTabKey,
  defaultTabKey: state.tableSettings[ownProps.tableIdentifier].defaultTabKey,
  pushToHistory: state.tableSettings[ownProps.tableIdentifier].pushToHistory,
  replaceInHistory: state.tableSettings[ownProps.tableIdentifier].replaceInHistory,
  baseRoute: state.tableSettings[ownProps.tableIdentifier].baseRoute,
  itemCaption: state.tableSettings[ownProps.tableIdentifier].itemCaption,
  baseQueryString: state.tableSettings[ownProps.tableIdentifier].baseQueryString,
  columns: state.tableSettings[ownProps.tableIdentifier].columns,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const { tableIdentifier } = ownProps;
  return {
    dispatchUpdateTableSettings: (settings) => dispatch(updateTableSettings(tableIdentifier, settings)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(connectToAPIProvider(RecordDetailPage));
