/* eslint-disable key-spacing */
export const INVOICE_REQUEST_STATUS = {
  DRAFT: 1,
  SUBMITTED: 2,
  REJECTED: 3,
  PROCESSED: 4,
} as const;
export type INVOICE_REQUEST_STATUS = typeof INVOICE_REQUEST_STATUS;
export type AN_INVOICE_REQUEST_STATUS = INVOICE_REQUEST_STATUS[keyof INVOICE_REQUEST_STATUS];

export const INVOICE_REQUEST_STATUS_NAMES: {[K in AN_INVOICE_REQUEST_STATUS]: string } = {
  [INVOICE_REQUEST_STATUS.DRAFT]: 'Draft',
  [INVOICE_REQUEST_STATUS.SUBMITTED]: 'Submitted',
  [INVOICE_REQUEST_STATUS.REJECTED]: 'Rejected',
  [INVOICE_REQUEST_STATUS.PROCESSED]: 'Processed',
};
