/* eslint-disable class-methods-use-this */
import React from 'react';
import { PropTypes } from 'prop-types';
import PortalFusionChart from './portal-fusion-chart';
import { FUSION_CHART_TYPES_SUPPORTED, FUSION_CHART_TYPES_MULTIPLE_SERIES } from '../../utils/fusion-chart-types';
import FUSION_CHART_DATASET_RENDER_TYPES from '../../utils/fusion-chart-dataset-render-types';
import { PROJECT_STATUS_NAME_MAP } from '../../constants/project-status.const';

export const PROJECTS_BY_STATUS_AGE_VALUE_TYPE = {
  VALUE: 'value',
  COUNT: 'count',
};

/**
 * @class ProjectsByStatusAgeChart
 *
 * @description
 * This chart implements the Portal2 API's "Projects By Status Age" report
 * @see https://wiki.ciportal.net/books/ci-portal-developer-documentation/page/projects-by-status-age
 */
class ProjectsByStatusAgeChart extends React.Component {
  /**
   * @inheritdoc
   *
   * @param {{}} props
   */
  constructor(props) {
    super(props);

    this.state = {
      seriesList: this.createSeriesList(props),
    };
  }


  /**
   * @inheritdoc
   */
  shouldComponentUpdate(nextProps) {
    const { valueType: oldValueType, statusOptions: oldStatusOptions } = this.props;
    const { valueType: newValueType, statusOptions: newStatusOptions } = nextProps;

    if (oldValueType !== newValueType || oldStatusOptions !== newStatusOptions) {
      this.setState({ seriesList: this.createSeriesList(nextProps) });
      return false;
    }

    return true;
  }


  /**
   * @description
   * Api url for the report
   *
   * @returns {string}
   */
  get dataUrl() {
    return '/report/project/bystatusage';
  }


  /**
   * @description
   * Create and return a new seriesList
   *
   * @param {{}} withProps
   * @returns {object}
   */
  createSeriesList = (withProps = this.props) => {
    const { statusOptions, valueType } = withProps;

    return statusOptions.map((projectStatus) => ({
      name: PROJECT_STATUS_NAME_MAP[projectStatus.statusId],
      filterIn: (dataItem) => dataItem.status_id === projectStatus.statusId,
      getValue: (dataItem) => (valueType === PROJECTS_BY_STATUS_AGE_VALUE_TYPE.COUNT ? dataItem.count : dataItem.value),
      renderAs: projectStatus.renderAs || FUSION_CHART_DATASET_RENDER_TYPES.COLUMN,
      visible: projectStatus.visible !== false,
    }));
  }


  /**
   * @description
   * Fired by the Fusion chart to get the labels that should be displayed along the
   * bottom row / x-axis / column etc...
   *
   * @param {object} dataItem the data item to extract an x-axis / column label
   */
  handleGetLabel = (dataItem) => dataItem.days_name;


  /**
   * @inheritdoc
   */
  render() {
    const {
      id,
      paletteColors,
      chartCaption,
      onClickDataItem,
      onClickSeriesLabel,
      onChangeSeriesVisibility,
      onLoading,
      chartType,
      valueType,
      chartSubCaption,
      chartXAxisName,
      chartYAxisName,
      showValues,
    } = this.props;

    const { seriesList } = this.state;

    return (
      <>
        <PortalFusionChart
          id={id}
          dataUrl={this.dataUrl}
          paletteColors={paletteColors}
          data={{
            url: this.dataUrl,
            onLoading,
          }}
          dataMapping={{
            type: 'multi',
            chartType,
            getLabel: this.handleGetLabel,
            seriesList,
          }}
          numberPrefix={valueType === PROJECTS_BY_STATUS_AGE_VALUE_TYPE.COUNT ? null : '$'}
          chartType={chartType}
          caption={chartCaption}
          subCaption={chartSubCaption}
          xAxisName={chartXAxisName}
          yAxisName={chartYAxisName}
          showValues={showValues}
          getLabel={this.handleGetLabel}

          onClickDataItem={onClickDataItem}
          onClickSeriesLabel={onClickSeriesLabel}
          onChangeSeriesVisibility={onChangeSeriesVisibility}
          onLoading={onLoading}
        />
      </>
    );
  }
}

ProjectsByStatusAgeChart.propTypes = {
  id: PropTypes.string,
  statusOptions: PropTypes.arrayOf(PropTypes.shape({
    statusId: PropTypes.number.isRequired,
    visible: PropTypes.bool,
    renderAs: PropTypes.oneOf(Object.values(FUSION_CHART_DATASET_RENDER_TYPES)),
  })).isRequired,
  paletteColors: PropTypes.arrayOf(PropTypes.string),
  valueType: PropTypes.oneOf(Object.values(PROJECTS_BY_STATUS_AGE_VALUE_TYPE)),
  chartCaption: PropTypes.string,
  chartType: PropTypes.oneOf(Object.values(FUSION_CHART_TYPES_SUPPORTED)),
  chartSubCaption: PropTypes.string,
  chartXAxisName: PropTypes.string,
  chartYAxisName: PropTypes.string,
  showValues: PropTypes.bool,

  onClickDataItem: PropTypes.func,
  onClickSeriesLabel: PropTypes.func,
  onChangeSeriesVisibility: PropTypes.func,
  onLoading: PropTypes.func,
};

ProjectsByStatusAgeChart.defaultProps = {
  id: 'chart_projects_by_status_age',
  paletteColors: null,
  showValues: false,
  valueType: PROJECTS_BY_STATUS_AGE_VALUE_TYPE.VALUE,
  chartCaption: 'Projects by Status Age',
  chartType: FUSION_CHART_TYPES_MULTIPLE_SERIES.MULTI_SERIES_COLUMN_2D,
  chartSubCaption: null,
  chartXAxisName: null,
  chartYAxisName: null,

  onClickDataItem: null,
  onClickSeriesLabel: null,
  onChangeSeriesVisibility: null,
  onLoading: null,
};


export default ProjectsByStatusAgeChart;
