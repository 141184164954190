import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Card, Table } from 'reactstrap';
import { INVOICE_REQUEST_TYPE } from '../../../constants/invoice-request-type.const';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import { ICompanyRecord } from '../../../types/company/company.record.interface';
import { IContactRecord } from '../../../types/contact/contact.record.interface';
import { IInvoiceRequestRecord } from '../../../types/invoice-request/invoice-request.record.interface';
import { INoteRecord } from '../../../types/note.record.interface';
// import { IProjectRecord } from '../../../types/project/project.record.interface';
import { WidgetProps } from '../../../types/widget.props';
import { APIContext } from '../../providers/api-provider';
import { IrbCompanySummary } from './irb-company-summary';
import { IrbContactSummary } from './irb-contact-summary';
import { IbrNotesSummary } from './irb-notes-summary';

export type InvoiceRequestBillingWidgetProps = WidgetProps & {
  rowData: IInvoiceRequestRecord,
  widgetData: INoteRecord[],
  refreshRecord: () => void,
}

type ITypedContact = IContactRecord & {
  type: string
}

type ITypedClient = ICompanyRecord & {
  type: string,
  typeId: number,
}

/**
 * This is the widget that holds the admin functionality for the project
 */
export const InvoiceRequestBillingWidget:React.FC<InvoiceRequestBillingWidgetProps> = (props) => {
  const {
    widgetData,
    rowData: invoiceRequest,
    refreshRecord,
  } = props;

  // const [projectData, setProjectData] = useState<IProjectRecord | null>(null);

  const [contactList, setContactList] = useState<ITypedContact[]>([]);
  const [clientList, setClientList] = useState<ITypedClient[]>([]);

  const { apiFetch } = useContext(APIContext);

  const loadProjectAbortController = useRef<AbortController | null>(null);
  const loadCompanyAbortController = useRef<AbortController | null>(null);
  const patchInvoiceRequestAbortController = useRef<AbortController | null>(null);


  const addClient = useCallback((client) => {
    // eslint-disable-next-line no-shadow
    setClientList((clientList) => [
      ...clientList,
      client,
    ]);
  }, []);

  const addContact = useCallback((contact) => {
    // eslint-disable-next-line no-shadow
    setContactList((contactList) => [
      ...contactList,
      contact,
    ]);
  }, []);

  /**
   * Patch the invoice request
   */
  const patchInvoiceRequest = useCallback(async (field: 'bill_to_customer_id' | 'bill_to_contact_id', value: number) => {
    if (patchInvoiceRequestAbortController.current) {
      patchInvoiceRequestAbortController.current.abort();
    }
    patchInvoiceRequestAbortController.current = apiAborter();

    const response = await apiFetch(
      `/invoice-request/${invoiceRequest.id}`,
      {
        name: 'ProjectProjectsWidget::loadProject',
        signal: patchInvoiceRequestAbortController.current.signal,
        method: HTTP_METHOD.PATCH,
        body: {
          [field]: value,
        },
      },
    );

    if (response.success) {
      refreshRecord();
    } else if (!response.aborted) {
      patchInvoiceRequestAbortController.current = null;
    }
  }, [apiFetch, invoiceRequest.id, refreshRecord]);

  /**
   * Company Data Loader
   */
  const loadCompany = useCallback((company: ITypedClient) => {
    if (!loadCompanyAbortController.current) {
      loadCompanyAbortController.current = apiAborter();
    }
    apiFetch(
      `/company/${company?.id}?with[]=defaultBillingContact`,
      {
        name: 'IRBCompanySummary::loadCompany',
        signal: loadCompanyAbortController.current.signal,
      },
    ).then((response) => {
      if (response.success) {
        loadCompanyAbortController.current = null;
        // eslint-disable-next-line no-shadow
        setClientList((clientList) => [
          ...clientList,
          {
            ...company,
            ...response.body.data,
          },
        ]);
        if (response.body.data.default_billing_contact) {
          addContact({
            ...response.body.data.default_billing_contact,
            type: `${company.type} - Default Contact`,
          });
        }
      } else if (!response.aborted) {
        loadCompanyAbortController.current = null;
      }
    });
  }, [addContact, apiFetch]);


  /**
   * Project Data Loader
   */
  const loadProject = useCallback(async () => {
    if (loadProjectAbortController.current) {
      loadProjectAbortController.current.abort();
    }
    loadProjectAbortController.current = apiAborter();

    const response = await apiFetch(
      `/project/${invoiceRequest.project?.id}?with[]=billingAgent&with[]=billingAgent.company&with[]=billingAgent.company.defaultBillingContact`,
      {
        name: 'ProjectProjectsWidget::loadProject',
        signal: loadProjectAbortController.current.signal,
      },
    );

    if (response.success) {
      loadProjectAbortController.current = null;
      // setProjectData(response.body.data);
      if (response.body.data.billing_agent) {
        addClient({
          ...response.body.data.billing_agent.company,
          type: 'Billing Agent',
          typeId: INVOICE_REQUEST_TYPE.PROJECT,
        });
      }
      if (response.body.data.billing_agent?.company?.default_billing_contact) {
        addContact({
          ...response.body.data.billing_agent?.company?.default_billing_contact,
          type: 'Billing Agent - Default Contact',
        });
      }
    } else if (!response.aborted) {
      loadProjectAbortController.current = null;
      // setProjectData(null);
    }
  }, [apiFetch, invoiceRequest.project?.id, addClient, addContact]);


  /**
   * Invoice Request Change
   */
  useEffect(() => {
    const initialClients: ITypedClient[] = [];

    setClientList([]);
    setContactList([]);

    if (invoiceRequest.service_job?.contract?.client) {
      initialClients.push({
        ...invoiceRequest.service_job?.contract?.client,
        type: 'Service Job - Contract',
        typeId: INVOICE_REQUEST_TYPE.JOB,
      });
    }
    if (invoiceRequest.service_job?.project?.client) {
      initialClients.push({
        ...invoiceRequest.service_job?.project?.client,
        type: 'Service Job - Project',
        typeId: INVOICE_REQUEST_TYPE.JOB,
      });
    }
    if (invoiceRequest.service_job?.client) {
      initialClients.push({
        ...invoiceRequest.service_job?.client,
        type: 'Service Job',
        typeId: INVOICE_REQUEST_TYPE.JOB,
      });
    }
    if (invoiceRequest.project?.client) {
      initialClients.push({
        ...invoiceRequest.project?.client,
        quickbooks_subcustomer_id: invoiceRequest.project.quickbooks_subcustomer_id,
        type: 'Project',
        typeId: INVOICE_REQUEST_TYPE.PROJECT,
      });
    }
    if (invoiceRequest.contract?.client) {
      initialClients.push({
        ...invoiceRequest.contract?.client,
        type: 'Contract',
        typeId: INVOICE_REQUEST_TYPE.CONTRACT,
      });
    }

    // setClientList(initialClients);

    // Load the company details
    for (let index = 0; index < initialClients.length; index += 1) {
      const client = initialClients[index];
      loadCompany(client);
    }

    // Load the forecasts for the current project
    if (invoiceRequest.project?.client) {
      loadProject();
    }

    return () => {
      // Kill any outstanding load when the component is un-mounted
      if (loadProjectAbortController.current) {
        loadProjectAbortController.current.abort();
      }
      if (loadCompanyAbortController.current) {
        loadCompanyAbortController.current.abort();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceRequest]);

  const sortFunc = (a: ITypedClient) => {
    if (a.typeId === invoiceRequest.type_id) { return -1; }
    if (a.typeId === 0) { return -3; }
    return a.typeId;
  };

  /**
   * Render
   */
  return (
    <Card className="widget p-4" style={{ whiteSpace: 'normal' }}>
      <h4 className="pb-3 text-dark">
        {invoiceRequest.type.name}
        &nbsp;Invoice Request - Billing Details Check
      </h4>
      {invoiceRequest.type_id === INVOICE_REQUEST_TYPE.JOB && invoiceRequest.service_job.billing_type && (
        <p>
          This is a Service Job invoice request with a billing type set to&nbsp;
          &apos;
          {invoiceRequest.service_job?.billing_type?.name}
          &apos;
        </p>
      )}
      <IbrNotesSummary notes={widgetData} />
      <h4 className="text-dark pt-4 pb-2">Prioritised Billing Contacts</h4>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {invoiceRequest.bill_to_contact && (
          <IrbContactSummary
            type="Invoice Request - Bill-To Contact"
            contact={invoiceRequest.bill_to_contact}
          />
        )}
        {invoiceRequest.project?.invoicing_contact?.person && (
          <IrbContactSummary
            type={'Project\'s Invoicing Contact'}
            contact={invoiceRequest.project.invoicing_contact.person}
            setContact={(newContactId) => patchInvoiceRequest('bill_to_contact_id', newContactId)}
          />
        )}
        {contactList.map((contact) => (
          <IrbContactSummary
            type={contact.type}
            contact={contact}
            key={contact.type}
            setContact={(newContactId) => patchInvoiceRequest('bill_to_contact_id', newContactId)}
          />
        ))}
      </div>
      <h4 className="text-dark pt-4">Client Relationships</h4>
      <Table bordered className="table table-striped color-table primary-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>QuickBooks</th>
            <th>Default Contact</th>
            <th>Billing Client</th>
          </tr>
        </thead>
        <tbody>
          {invoiceRequest.bill_to_customer && (
            <IrbCompanySummary type="Invoice Request - Bill to Client" company={invoiceRequest.bill_to_customer} key={invoiceRequest.bill_to_customer.id} selected />
          )}
          {clientList.sort(sortFunc).map((client) => (
            <IrbCompanySummary type={`${client.type} Client`} company={client} key={client.id} setClient={(newClientId) => patchInvoiceRequest('bill_to_customer_id', newClientId)} />
          ))}
        </tbody>
      </Table>
      {/* <pre>{JSON.stringify(invoiceRequest.service_job, null, 2)}</pre> */}
    </Card>
  );
};
