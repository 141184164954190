export const DATABLE_SORT_COLUMN = 'DATABLE_SORT_COLUMN';

/**
 * Sets sorting mode for table column
 * - Reducer: `DATABLE_SORT_COLUMN`
 *
 * @param {string} tableIdentifier table identifier in redux, eg. 'projectsTable'
 *  See `initialState` in `table-settings-reducer`
 * @param {string} name column name in which to sort
 * @param {boolean} [pushToHistory=false] whether to push the changes resulting from the action to history
 * @param {boolean} [replaceInHistory=false] whether to replace the changes resulting from the action in history
 * @param {string} [direction=undefined] undefined = toggle existing, null = clear, 'asc' or 'desc'
 */
export const sortColumn = (tableIdentifier, name, pushToHistory = false, replaceInHistory = false, direction) => ({
  type: DATABLE_SORT_COLUMN,
  tableIdentifier,
  name,
  pushToHistory,
  replaceInHistory,
  direction,
});
