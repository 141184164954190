import React, { Component } from 'react';

import { APIContextProps, connectToAPIProvider } from '../providers/api-provider';
import { connectToCurrentUserProvider, CurrentUserContextProps } from '../providers/current-user-provider';

import { APIRecord } from '../../types/api-record.interface';
import { WidgetProps } from '../../types/widget.props';
import { CollectionWidgetProps } from '../../types/collection.widget.props';
import { ICollectionWidgetDefinition } from '../../types/collection.widget-definition';
import { IFormFieldDefinition } from '../../types/poly-form/form-field-definition.interface';
import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { PolyFormProps } from '../../types/poly-form/poly-form.props';
import { IActionButton } from '../../types/action-button.interface';

import { CollectionWidget } from './collection.widget';

import { apiAborter } from '../../helpers/api-aborter.helper';
import { deepCompare } from '../../helpers/deep-compare.helper';
import { prepareNewRecordFormData } from '../poly-forms/prepare-new-record-form-data.helper';
import { hasPermittedAction } from '../../helpers/has-permitted-action.helper';
import { canTakeActionOnWidgetItems } from '../../helpers/can-take-action-on-widget-items.helper';

import { DEBUG } from '../../utils/constants';
import { FORM_RENDERER_TYPE } from '../../constants/form-renderer-type.const';
import { DELETE_CONFIRMATION_TYPE } from '../../constants/delete-confirmation-type.const';
import { defaultWidgetPossibleActions } from '../../constants/default-widget-possible-actions.const';
import { defaultCollectionWidgetItemPossibleActions } from '../../constants/default-collection-widget-item-possible-actions.const';
import { AN_API_ACTION, API_ACTION } from '../../constants/api-action.const';
import { FormRendererProps } from '../../types/poly-form/form-renderer.props';
import { mergeActions } from '../../utils/helpers';

// When collection items change in rapid succession they may attempt
// to re-load the parent too quickly. This debounce timeout prevents that
// from overwhelming the application.
const REFRESH_RECORD_DEBOUNCE_TIMEOUT = 250;


export type WidgetWrapperProps = Pick<CollectionWidgetProps,
  'name' |
  'description' |
  'itemCaption' |
  'statusMap' |
  'parentId' |
  'rowDataChecksum' |
  'baseRoute' |
  'apiRoute' |
  'apiQuery' |
  'rowData' |
  'onStartEdit' |
  'onEndEdit' |
  'onDelete' |
  'onDirtyChange' |
  'refreshRecord' |
  'refreshActions' |
  'navigateBackOnDelete' |
  'isEditing' |
  'columns' |
  'history' |
  'tableIdentifier' |
  'permittedActions'
> & {
  autoLoadAllData: boolean,
  apiProvider: APIContextProps,
  currentUserProvider: CurrentUserContextProps,
  widgetDefinition: ICollectionWidgetDefinition,
};

export type WidgetWrapperState = Pick<CollectionWidgetProps,
  'widgetData' |
  'widgetDataChecksum' |
  'widgetDataChecksums' |
  'loadingError' |
  'isLoading' |
  'totals' |
  'hasMorePages' |
  'headings' |
  'isEditingCollectionItem' |
  'isCreatingCollectionItem' |
  'editingCollectionItemId' |
  'widgetPermittedActions' |
  'widgetItemPermittedActions'
>
& {
  currentPage: null | number,
  lastPage: null | number,
  perPage: null | number,
  widgetDataChecksumOffset: number,
  refreshRecordDebounceTimeout: null | ReturnType<typeof setTimeout>,
  refreshActionsDebounceTimeout: null | ReturnType<typeof setTimeout>,
};


/**
 * Helper function used to calculate the total widget data checksum
 */
const sumWidgetDataChecksums = (newWidgetDataChecksums: WidgetWrapperState['widgetDataChecksums'], widgetDataChecksumOffset: number) => (
  widgetDataChecksumOffset + (Object.values(newWidgetDataChecksums ?? {}).reduce(
    (acc: number, checkSum: number) => (acc + checkSum),
    0,
  ))
);


/**
 * <WidgetWrapper>
 */
class WidgetWrapperComponent extends Component<WidgetWrapperProps, WidgetWrapperState> {
  private abortController: null | AbortController = null;

  /**
   * @constructor
   */
  constructor(props: WidgetWrapperProps) {
    super(props);
    this.state = {
      widgetData: undefined,
      widgetDataChecksums: undefined,
      widgetDataChecksum: 0,
      widgetDataChecksumOffset: 0,
      loadingError: false,
      isLoading: false,
      totals: {},
      headings: this.generateTableColumnHeadings(),
      currentPage: null,
      lastPage: null,
      perPage: null,
      hasMorePages: false,
      isEditingCollectionItem: false,
      isCreatingCollectionItem: false,
      editingCollectionItemId: null,
      widgetPermittedActions: [],
      widgetItemPermittedActions: [],
      refreshRecordDebounceTimeout: null,
      refreshActionsDebounceTimeout: null,
    };
  }


  /**
   * @inheritdoc
   */
  componentDidMount = (): void => {
    this.getWidgetData();
  };


  /**
   * @inheritdoc
   */
  componentDidUpdate = (previousProps: WidgetWrapperProps) => {
    const {
      rowDataChecksum: newRowDataChecksum,
      parentId: newParentId,
      widgetDefinition: newWidgetDefinition,
      currentUserProvider: { userPermissionsChecksum: newUserPermissionsChecksum },
      permittedActions: newPermittedActions,
    } = this.props;

    const {
      rowDataChecksum: oldRowDataChecksum,
      parentId: oldParentId,
      widgetDefinition: oldWidgetDefinition,
      currentUserProvider: { userPermissionsChecksum: oldUserPermissionsChecksum },
      permittedActions: oldPermittedActions,
    } = previousProps;

    const reloadWidgetData = () => {
      // Wrap the setState inside a setTimeout to prevent the "Do not use setState inside componentDidUpdate" warning
      setTimeout(() => {
        this.getWidgetData();
      }, 0);
    };

    // If the rowDataChecksum has changed (indicating that the parent record has been refreshed or re-loaded
    if ((newRowDataChecksum !== oldRowDataChecksum) && newWidgetDefinition.refreshWidgetDataOnParentDataChange) {
      reloadWidgetData();
    }

    // If the ParentId has changed
    else if (oldParentId !== newParentId) {
      // Wrap the setState inside a setTimeout to prevent the "Do not use setState inside componentDidUpdate" warning
      setTimeout(() => {
        const { widgetDataChecksum } = this.state;
        this.setState({
          widgetData: undefined,
          widgetDataChecksum: widgetDataChecksum + 1,
          widgetDataChecksumOffset: widgetDataChecksum + 1,
        }, this.getWidgetData);
      }, 0);
    }

    // If the fields in the widget definition have changed
    if (
      (('fields' in oldWidgetDefinition) && (!('fields' in newWidgetDefinition))) ||
      (('fields' in newWidgetDefinition) && (!('fields' in oldWidgetDefinition))) ||
      (!deepCompare(oldWidgetDefinition.fields ?? [], newWidgetDefinition.fields ?? [])) ||
      // User permissions have changed
      (oldUserPermissionsChecksum !== newUserPermissionsChecksum) ||
      (!deepCompare(oldPermittedActions, newPermittedActions))
    ) {
      // Wrap the setState inside a setTimeout to prevent the "Do not use setState inside componentDidUpdate" warning
      setTimeout(() => {
        this.setState({
          headings: this.generateTableColumnHeadings(),
          widgetItemPermittedActions: this.getWidgetItemPermittedActions(),
        });
      }, 0);
    }
  };


  /**
   * @inheritdoc
   */
  componentWillUnmount = () => {
    const { refreshRecordDebounceTimeout } = this.state;
    if (refreshRecordDebounceTimeout !== null) {
      clearTimeout(refreshRecordDebounceTimeout);
    }

    if (this.abortController) {
      this.abortController.abort();
    }
  };


  /**
   * Prevent widgets from attempting to reload the parent record too frequently
   */
  debouncedRefreshRecord = () => {
    const { refreshRecord } = this.props;
    const { refreshRecordDebounceTimeout } = this.state;
    let newTimeout: WidgetWrapperState['refreshRecordDebounceTimeout'] = refreshRecordDebounceTimeout;

    if (refreshRecordDebounceTimeout !== null) {
      clearTimeout(refreshRecordDebounceTimeout);
      newTimeout = null;
    }

    if (refreshRecord) {
      newTimeout = setTimeout(() => refreshRecord(), REFRESH_RECORD_DEBOUNCE_TIMEOUT);
    }

    this.setState({
      refreshRecordDebounceTimeout: newTimeout,
    });
  }


  /**
   * Prevent widgets from attempting to reload the parent actions too frequently
   */
   debounceRefreshActions = () => {
     const { refreshActions } = this.props;
     const { refreshActionsDebounceTimeout } = this.state;
     let newTimeout: WidgetWrapperState['refreshActionsDebounceTimeout'] = refreshActionsDebounceTimeout;

     if (refreshActionsDebounceTimeout !== null) {
       clearTimeout(refreshActionsDebounceTimeout);
       newTimeout = null;
     }

     if (refreshActions) {
       newTimeout = setTimeout(() => refreshActions(), REFRESH_RECORD_DEBOUNCE_TIMEOUT);
     }

     this.setState({
       refreshActionsDebounceTimeout: newTimeout,
     });
   }

  /**
   * Magic using redux to get the widget data
   * Also calls refreshRecord if the data changes because some totals need to be updated
   */
  getWidgetData = (loadingNextPage = false, loadAll = false) => {
    const {
      rowData,
      parentId,
      apiRoute,
      apiQuery,
      baseRoute,
      autoLoadAllData,
      widgetDefinition,
      apiProvider: { apiFetch },
    } = this.props;

    const {
      currentPage,
      lastPage,
      perPage,
    } = this.state;

    // Make sure we have a route to load from
    if (!apiRoute) return;

    // build the request URL
    const baseRouteWithParentId = `${baseRoute}${parentId ? `/${parentId}` : ''}`;
    let requestUrl = `${baseRouteWithParentId}${apiRoute}`;

    if (typeof apiRoute === 'function') {
      requestUrl = apiRoute(rowData);
    }

    if (typeof apiQuery === 'function') {
      requestUrl = `${requestUrl}?${apiQuery(rowData)}`;
    } else if (apiQuery) {
      requestUrl = `${requestUrl}?${apiQuery}`;
    }

    if (currentPage !== null && lastPage !== null && currentPage < lastPage) {
      const joiner = (apiQuery || requestUrl.indexOf('?') > -1) ? '&' : '?';
      requestUrl = `${requestUrl}${joiner}page=${currentPage + 1}`;
    }

    // Kill any existing data load attempts
    if (this.abortController) {
      this.abortController.abort();
    }

    // Set the isLoading flag so that the app knows to render as "busy"
    this.setState({
      isLoading: true,
      loadingError: false,
    }, async () => {
      // Create a new Abort Controller
      this.abortController = apiAborter();

      // Debug which widget we are doing the request for
      let name = 'CollectionWidget';
      if (DEBUG) {
        name = `${this.constructor.name}::${widgetDefinition.component || 'CollectionWidget'}`;
      }

      // Fetch the data asynchronously
      const response = await apiFetch(
        requestUrl,
        {
          name,
          signal: this.abortController.signal,
        },
      );

      if (response.success) {
        this.abortController = null;

        const responseBody = response.body || {};
        const recordData: WidgetProps['widgetData'] = responseBody.data || [];
        const meta = responseBody.meta || {};
        const newCurrentPage = meta.current_page || null;
        const newLastPage = meta.last_page || null;
        const newPerPage = meta.per_page || perPage || null;
        let newWidgetPermittedActions: IActionButton[] = [];

        // handle additional page load data
        let allLoadedItems: WidgetProps['widgetData'] = [];
        const allLoadedItemChecksums: Record<number | string, number> = {};
        const hasMorePages = (newCurrentPage !== null && newLastPage !== null && newCurrentPage < newLastPage);
        if (loadingNextPage) {
          const existingData = this.state.widgetData || [];
          allLoadedItems = [...existingData];
          recordData?.forEach((item) => {
            // push the item onto the array
            allLoadedItems?.push(item);
            // Initialise the checksum for the item if the primary key value can be established
            if (item[widgetDefinition.collectionItemPrimaryKeyFieldName ?? 'id']) {
              allLoadedItemChecksums[item[widgetDefinition.collectionItemPrimaryKeyFieldName ?? 'id']] = 0;
            }
          });
        } else {
          newWidgetPermittedActions = mergeActions(responseBody.actions || [], widgetDefinition.widgetPossibleActions ?? defaultWidgetPossibleActions);

          if (recordData) {
            allLoadedItems = Array.isArray(recordData) ? recordData : [recordData];
            // Initialise the checksum for the item if the primary key value can be established
            allLoadedItems?.forEach((item) => {
              if (item[widgetDefinition.collectionItemPrimaryKeyFieldName ?? 'id']) {
                allLoadedItemChecksums[item[widgetDefinition.collectionItemPrimaryKeyFieldName ?? 'id']] = 0;
              }
            });
          }
        }

        const {
          widgetDataChecksumOffset,
        } = this.state;

        this.setState({
          hasMorePages,
          widgetData: allLoadedItems,
          widgetDataChecksums: allLoadedItemChecksums,
          widgetDataChecksumOffset: widgetDataChecksumOffset + 1,
          widgetDataChecksum: widgetDataChecksumOffset + 1,
          currentPage: newCurrentPage,
          lastPage: newLastPage,
          perPage: newPerPage,
          isLoading: false,
          totals: this.calculateTotals(allLoadedItems ?? []),
          widgetPermittedActions: newWidgetPermittedActions,
          widgetItemPermittedActions: this.getWidgetItemPermittedActions(),
        },
        // once set, callback
        () => {
          // let the api breathe and load more data?
          if (hasMorePages && (autoLoadAllData || loadAll)) {
            setTimeout(() => this.getWidgetData(true, loadAll), 100);
          }
          // Calculate the totals
          else {
            this.setState({
              totals: this.calculateTotals(allLoadedItems ?? []),
              widgetItemPermittedActions: this.getWidgetItemPermittedActions(),
            });
          }
        });
      } else if (!response.aborted) {
        // TODO: API error handling

        this.abortController = null;
        this.setState({
          hasMorePages: false,
          widgetData: [],
          currentPage: null,
          lastPage: null,
          perPage: null,
          isLoading: false,
          loadingError: true,
          totals: {},
          widgetPermittedActions: [],
          widgetItemPermittedActions: this.getWidgetItemPermittedActions(),
        });
      }
    });
  };


  /**
   * When there are more pages and the widget data is not currently loading,
   * allow the child components to call the loadNextPage callback to get the
   * remainder of the data
   */
  handleLoadNextPage = (loadAll: boolean) => {
    const { isLoading, hasMorePages } = this.state;
    if (!isLoading && hasMorePages) {
      this.getWidgetData(true, loadAll);
    }
  }


  /**
   * Figure out headings here
   */
  generateTableColumnHeadings = (): IFormFieldDefinition[] => {
    const { widgetDefinition: { fields, formRendererType } } = this.props;

    const headings: IFormFieldDefinition[] = [];
    if ((formRendererType === FORM_RENDERER_TYPE.TABLE_ROW) && fields) {
      fields.forEach((field) => {
        let showField = false;
        if (field) {
          showField = true;
          if (typeof field.showInForm !== 'undefined' && field.showInForm !== true) {
            showField = false;
          }
        }
        if (field.visible === false) {
          showField = false;
        }

        if (showField) {
          headings.push(field);
        }
      });
    }

    return headings;
  };


  /**
   * Calculate numerical totals of each row so we can display it underneath
   *
   * TODO need to potentially load the totals from API instead of adding them up
   *   This is because if there are multiple pages the total would be WRONG
   */
  calculateTotals = (widgetDataRows: Record<string, unknown>[]): Record<string, number> => {
    const { widgetDefinition: { fields } } = this.props;

    // let didAlert = false;
    if (widgetDataRows && fields) {
      const fieldTotals: Record<string, number> = {};
      fields.map((field) => {
        const fieldName = field.name;
        if (fieldName && field.showTotals) {
          let total = 0;
          const filteredItems = widgetDataRows.filter(field.totalsFilter ?? (() => true));

          // reduce doesn't run on single items, detect and return;
          if (filteredItems.length === 1) {
            total = Number(widgetDataRows[0][fieldName] ?? 0);
          }

          const totalReducer = (acc: number, item: Record<string, unknown>) => acc + Number(item[fieldName] ?? 0);
          total = filteredItems.reduce(totalReducer, 0);

          fieldTotals[fieldName] = total;
          return true;
        }
        return false;
      });
      return fieldTotals;
    }
    return {};
  };


  /**
   * The API does not provide individual HATEOS information for each record in a collection
   * As a result, we need to infer the possible actions from the parent record
   */
  getWidgetItemPermittedActions = (): IActionButton[] => {
    const {
      // Permitted actions come from the parent row
      permittedActions,

      widgetDefinition: {
        widgetItemActionPermissions = {},
        widgetItemPossibleActions = defaultCollectionWidgetItemPossibleActions,
      },
      currentUserProvider: { userHasPermissions },
    } = this.props;

    const {
      isLoading,
      loadingError,
    } = this.state;

    // Don't allow any actions if the widget data is loading or there was a loading error
    if (isLoading || loadingError) return [];

    const canEditParent = !!hasPermittedAction(permittedActions, API_ACTION.UPDATE);

    return (widgetItemPossibleActions)
      .filter((possibleAction) => (
        canEditParent &&
        canTakeActionOnWidgetItems(possibleAction.name as AN_API_ACTION, widgetItemActionPermissions, userHasPermissions)
      )) ?? [];
  }


  /**
   * Determine whether a collection item can be created or not
   */
  handleCanCreateCollectionItem = () => {
    const { isLoading, isCreatingCollectionItem, isEditingCollectionItem } = this.state;
    return (!isLoading && !isCreatingCollectionItem && !isEditingCollectionItem);
  }


  /**
   * Fired by the widgetWrapper when the user adds a new collection item to a collection widget
   *
   * @param initialData any initial data to populate the new collection item with
   */
  handleCreateCollectionItem = (initialData?: APIRecord): APIRecord => {
    const {
      widgetDefinition,
      parentId,
      rowData,
    } = this.props;

    const {
      widgetData = [],
      widgetDataChecksums = {},
      widgetDataChecksumOffset,
    } = this.state;

    const temporaryId = -1;

    const newRecord = {
      ...prepareNewRecordFormData(initialData, widgetDefinition.fields ?? [], parentId, rowData, widgetData),
      [(widgetDefinition.collectionItemPrimaryKeyFieldName ?? 'id')]: temporaryId,
    };

    const newWidgetData = [
      ...widgetData,
      newRecord,
    ];

    const newWidgetDataChecksums = {
      ...widgetDataChecksums,
      [temporaryId]: 1,
    };

    this.setState({
      isCreatingCollectionItem: true,
      isEditingCollectionItem: true,
      editingCollectionItemId: temporaryId,
      widgetData: newWidgetData,
      widgetDataChecksums: newWidgetDataChecksums,
      widgetDataChecksum: sumWidgetDataChecksums(newWidgetDataChecksums, widgetDataChecksumOffset),
      totals: this.calculateTotals(newWidgetData),
    }, () => {
      // Update the parent record
      this.debouncedRefreshRecord();
    });

    return newRecord;
  };


  /**
   * Fired by a widget that prefers to handle the creation of its own widget items
   * @note at this point the collection items should be hydrated and have a unique id
   *
   * @param collectionItem the collection item(s) to add
   */
  handleAddCollectionItem = (collectionItem: FormRendererProps['formData'] | FormRendererProps['formData'][]): void => {
    const { widgetDefinition } = this.props;
    const {
      widgetData = [],
      widgetDataChecksums = {},
      widgetDataChecksumOffset,
    } = this.state;

    const newCollectionItems: FormRendererProps['formData'][] = Array.isArray(collectionItem) ? collectionItem : [collectionItem];

    // Add the new collection items to the widgetData
    const newWidgetData = [
      ...widgetData,
      ...newCollectionItems,
    ];

    // Establish the data checksums for each of the collection items
    const newWidgetDataChecksums = { ...widgetDataChecksums };
    newCollectionItems.forEach((newCollectionItem) => {
      if (newCollectionItem[widgetDefinition.collectionItemPrimaryKeyFieldName ?? 'id']) {
        newWidgetDataChecksums[newCollectionItem[widgetDefinition.collectionItemPrimaryKeyFieldName ?? 'id']] = 1;
      }
    });

    this.setState({
      widgetData: newWidgetData,
      widgetDataChecksums: newWidgetDataChecksums,
      widgetDataChecksum: sumWidgetDataChecksums(newWidgetDataChecksums, widgetDataChecksumOffset),
      totals: this.calculateTotals(newWidgetData),
    }, () => {
      // Update the parent record
      this.debouncedRefreshRecord();
    });
  };


  /**
   * Fired by a widget that prefers to handle updating its own widget items
   * This bypasses the typical can edit / is editing workflow for collection items
   *
   * @param id the id of the collection item to update
   * @param formData the collection item data
   * @param refreshParentRecord whether to refresh the parent record (default = true)
   */
  handleUpdateCollectionItem = (id: FormFieldComponentProps['value'], formData: PolyFormProps['formData'], refreshParentRecord?: boolean): void => {
    const { widgetDefinition } = this.props;
    const {
      widgetData = [],
      widgetDataChecksums = {},
      widgetDataChecksumOffset,
    } = this.state;

    const newId = formData ? formData[(widgetDefinition.collectionItemPrimaryKeyFieldName ?? 'id')] : null;

    // Apply the updated collection item
    const newWidgetData = widgetData.map(
      (item) => ((item[(widgetDefinition.collectionItemPrimaryKeyFieldName ?? 'id')] === id) ? (formData ?? {}) : item),
    );

    // If the ID has changed we need to remove the old widget data checksum
    const newWidgetDataChecksums = { ...widgetDataChecksums };
    if (newWidgetDataChecksums && id !== newId) {
      const oldChecksumValue = newWidgetDataChecksums[id] ?? 0;
      delete newWidgetDataChecksums[id];
      newWidgetDataChecksums[newId] = oldChecksumValue + 1;
    }

    // Otherwise just increment the checksum of the existing record
    else if (newWidgetDataChecksums && newId) {
      newWidgetDataChecksums[newId] = (newWidgetDataChecksums[newId] ?? 0) + 1;
    }

    this.setState({
      widgetData: newWidgetData,
      widgetDataChecksum: sumWidgetDataChecksums(newWidgetDataChecksums, widgetDataChecksumOffset),
      widgetDataChecksums: newWidgetDataChecksums,
      totals: this.calculateTotals(newWidgetData),
    }, () => {
      // Update the parent record
      if (refreshParentRecord !== true) {
        this.debouncedRefreshRecord();
      }
    });
  };


  /**
   * Fired by the widgetWrapper when the user wants to begin editing a collection item
   *
   * @param id the primary key of the collection item the user is attempting to edit
   * @param formData the data of the collection item the user is attempting to edit
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleCanStartEditCollectionItem = (id: FormFieldComponentProps['value'], formData: PolyFormProps['formData']): boolean => {
    const { isCreatingCollectionItem, isEditingCollectionItem } = this.state;
    return (!isCreatingCollectionItem && !isEditingCollectionItem);
  };


  /**
   * Fired by the widgetWrapper when the user begins editing a collection item loaded into the widget
   */
  handleStartEditCollectionItem = (id: FormFieldComponentProps['value'], formData: PolyFormProps['formData']) => {
    if (this.handleCanStartEditCollectionItem(id, formData)) {
      this.setState({
        isEditingCollectionItem: true,
        editingCollectionItemId: id,
      });
    }
  };


  /**
   * Fired by the widgetWrapper when the user wants to end editing a collection item
   *
   * @param saveChanges whether the endEdit is a save or a cancel
   * @param id the primary key of the collection item the user is attempting to edit
   * @param formData the data of the collection item the user is attempting to edit
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleCanEndEditCollectionItem = (saveChanges: boolean, id: FormFieldComponentProps['value'], formData: PolyFormProps['formData']): boolean => {
    const { isEditingCollectionItem } = this.state;
    return (isEditingCollectionItem);
  };


  /**
   * Fired by the WidgetWrapper when the user ends editing the a collection item
   *
   * @param saveChanges whether the end edit represents a cancel or a save
   * @param id the primary key of the collection item the user is attempting to edit
   * @param formData the data of the collection item the user is attempting to edit
   */
  handleEndEditCollectionItem = (saveChanges: boolean, id: FormFieldComponentProps['value'], formData: PolyFormProps['formData']) => {
    const {
      widgetDefinition,
      onDirtyChange,
    } = this.props;

    const {
      widgetData = [],
      widgetDataChecksums = {},
      widgetDataChecksumOffset,
      isCreatingCollectionItem,
      editingCollectionItemId,
    } = this.state;

    let newWidgetData: WidgetWrapperState['widgetData'] = [...widgetData];
    const newWidgetDataChecksums = { ...widgetDataChecksums };
    let newWidgetDataChecksumOffset = widgetDataChecksumOffset;

    // If the edit was cancelled and this was a new record, remove the new record from the widgetData
    if (!saveChanges && isCreatingCollectionItem) {
      // Remove the record
      newWidgetData = newWidgetData.filter(
        (item) => (item[(widgetDefinition.collectionItemPrimaryKeyFieldName ?? 'id')] !== editingCollectionItemId),
      );
      // Remove the checksum
      newWidgetDataChecksumOffset = widgetDataChecksumOffset + newWidgetDataChecksums[editingCollectionItemId] ?? 0;
      delete newWidgetDataChecksums[editingCollectionItemId];
    }

    // Otherwise apply the form data to the collection item. Saving or Cancelling is irrelevant, the APIPolyForm
    // returns the cached "old data" here when a cancel occurs
    else {
      newWidgetData = newWidgetData.map(
        (item) => ((item[(widgetDefinition.collectionItemPrimaryKeyFieldName ?? 'id')] === editingCollectionItemId) ? (formData ?? {}) : item),
      );
      // Update the checksum
      newWidgetDataChecksums[editingCollectionItemId] = (newWidgetDataChecksums[editingCollectionItemId] ?? 0) + 1;
    }

    this.setState({
      isCreatingCollectionItem: false,
      isEditingCollectionItem: false,
      editingCollectionItemId: null,
      widgetData: newWidgetData,
      widgetDataChecksums: newWidgetDataChecksums,
      widgetDataChecksumOffset: newWidgetDataChecksumOffset,
      widgetDataChecksum: sumWidgetDataChecksums(newWidgetDataChecksums, newWidgetDataChecksumOffset),
      totals: this.calculateTotals(newWidgetData),
    }, () => {
      // Notify the parent that the widget is no longer "dirty"
      if (onDirtyChange) {
        onDirtyChange(false);
      }

      // Update the parent record
      this.debouncedRefreshRecord();
    });
  };


  /**
   * Fired by the widgetWrapper when the user wants to delete a collection item
   *
   * @param id the primary key of the collection item the user is attempting to edit
   * @param formData the data of the collection item the user is attempting to edit
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleCanDeleteCollectionItem = (id: FormFieldComponentProps['value'], formData: PolyFormProps['formData']): boolean => true;


  /**
   * Fired by the WidgetWrapper when the user deletes a collection item
   *
   * @param id the primary key of the collection item the user is attempting to edit
   * @param formData the data of the collection item the user is attempting to edit
   */
  handleDeleteCollectionItem = (
    id: FormFieldComponentProps['value'],
    // formData: PolyFormProps['formData'],
  ) => {
    const {
      onDirtyChange,
      widgetDefinition,
    } = this.props;

    const {
      widgetData = [],
      widgetDataChecksums = {},
      widgetDataChecksumOffset,
    } = this.state;

    const newWidgetDataChecksums = { ...widgetDataChecksums };

    // Keep track of the old deleted checksum so that the total checksum doesn't go backwards when this item is deleted
    const newWidgetDataChecksumOffset = widgetDataChecksumOffset + (widgetDataChecksums[id] ?? 0) + 1;

    // Remove the item from the widgetData
    const newWidgetData = widgetData.filter(
      (item) => (item[(widgetDefinition.collectionItemPrimaryKeyFieldName ?? 'id')] !== id),
    );

    // Remove the checksum
    delete newWidgetDataChecksums[id];

    this.setState({
      isCreatingCollectionItem: false,
      isEditingCollectionItem: false,
      editingCollectionItemId: null,
      widgetData: newWidgetData,
      widgetDataChecksums: newWidgetDataChecksums,
      widgetDataChecksumOffset: newWidgetDataChecksumOffset,
      widgetDataChecksum: sumWidgetDataChecksums(newWidgetDataChecksums, newWidgetDataChecksumOffset),
    }, () => {
      // Notify the parent that the widget is no longer "dirty"
      if (onDirtyChange) {
        onDirtyChange(false);
      }

      // Update the parent record
      this.debouncedRefreshRecord();
    });
  };


  /**
   * Fired by the WidgetWrapper when the user edits a field for a collection item
   *
   * @param id the primary key of the collection item the user is editing
   * @param formData the new data of the collection item
   */
  handleCollectionItemChange = (
    id: FormFieldComponentProps['value'],
    formData: PolyFormProps['formData'],
  ) => {
    const {
      widgetDefinition,
      onDirtyChange,
    } = this.props;

    const {
      widgetData = [],
      widgetDataChecksums = {},
      widgetDataChecksumOffset,
      isEditingCollectionItem,
      isCreatingCollectionItem,
    } = this.state;

    // Update the checksum
    const newWidgetDataChecksums = { ...widgetDataChecksums };
    newWidgetDataChecksums[id] = (newWidgetDataChecksums[id] ?? 0) + 1;

    // Update the provided form data into the collection item
    this.setState({
      widgetData: widgetData.map(
        (item) => ((item[(widgetDefinition.collectionItemPrimaryKeyFieldName ?? 'id')] === id) ? (formData ?? {}) : item),
      ),
      widgetDataChecksums: newWidgetDataChecksums,
      widgetDataChecksum: sumWidgetDataChecksums(newWidgetDataChecksums, widgetDataChecksumOffset),
    }, () => {
      // Notify the parent that the widget is "dirty"
      if ((isEditingCollectionItem || isCreatingCollectionItem) && onDirtyChange) {
        onDirtyChange(true);
      }
    });
  };


  /**
   * @inheritdoc
   */
  render() {
    const {
      name,
      description,
      statusMap,
      parentId,
      columns,
      rowData,
      rowDataChecksum,
      widgetDefinition,
      history,
      baseRoute,
      apiRoute,
      apiQuery,
      tableIdentifier,
      navigateBackOnDelete,
      itemCaption,
      isEditing,
      permittedActions,
      onStartEdit,
      onEndEdit,
      onDelete,
      onDirtyChange,
    } = this.props;

    const {
      widgetData,
      widgetDataChecksum,
      widgetDataChecksums,
      isLoading,
      loadingError,
      totals,
      headings,
      hasMorePages,
      widgetItemPermittedActions,
      isCreatingCollectionItem,
      isEditingCollectionItem,
      editingCollectionItemId,
      widgetPermittedActions,
    } = this.state;

    const WidgetComponent = widgetDefinition.component ?? CollectionWidget;

    return (
      <WidgetComponent
        // Widget Definition
        isReadOnly={widgetDefinition.isReadOnly ?? false}
        itemCaption={widgetDefinition.itemCaption ?? itemCaption}
        formCaption={widgetDefinition.formCaption}
        className={widgetDefinition.className}

        // Default Collection Widget Definition
        formRendererType={widgetDefinition.formRendererType}
        showAddBtn={widgetDefinition.showAddBtn !== false}
        reverseOrder={widgetDefinition.reverseOrder ?? false}
        sortWidgetRecords={widgetDefinition.sortWidgetRecords ?? false}
        formDeleteConfirmationType={widgetDefinition.formDeleteConfirmationType ?? DELETE_CONFIRMATION_TYPE.DELETE}
        fields={widgetDefinition.fields ?? []}
        disableHeader={widgetDefinition.disableHeader}
        collectionItemPrimaryKeyFieldName={widgetDefinition.collectionItemPrimaryKeyFieldName}
        widgetItemPossibleActions={widgetDefinition.widgetItemPossibleActions ?? defaultCollectionWidgetItemPossibleActions}
        widgetItemActionPermissions={widgetDefinition.widgetItemActionPermissions ?? []}

        // Widget Props
        name={name}
        description={description}
        statusMap={statusMap}
        parentId={parentId}
        columns={widgetDefinition.columns ?? columns}
        rowData={rowData}
        rowDataChecksum={rowDataChecksum}
        widgetData={widgetData}
        widgetDataChecksum={widgetDataChecksum}
        widgetDataChecksums={widgetDataChecksums}
        isLoading={isLoading}
        loadingError={loadingError}
        hasMorePages={hasMorePages}
        loadNextPage={this.handleLoadNextPage}
        history={history}
        baseRoute={baseRoute}
        apiRoute={apiRoute}
        apiQuery={apiQuery}
        tableIdentifier={tableIdentifier}
        navigateBackOnDelete={navigateBackOnDelete}
        isEditing={isEditing}
        permittedActions={permittedActions}
        widgetPermittedActions={widgetPermittedActions}

        onStartEdit={onStartEdit}
        onEndEdit={onEndEdit}
        onDelete={onDelete}
        onDirtyChange={onDirtyChange}
        refreshRecord={this.debouncedRefreshRecord}
        refreshActions={this.debounceRefreshActions}
        refreshWidgetData={this.getWidgetData}

        // Collection Widget Props
        headings={headings}
        totals={totals}
        widgetItemPermittedActions={widgetItemPermittedActions}
        isCreatingCollectionItem={isCreatingCollectionItem}
        isEditingCollectionItem={isEditingCollectionItem}
        editingCollectionItemId={editingCollectionItemId}
        addCollectionItem={this.handleAddCollectionItem}
        updateCollectionItem={this.handleUpdateCollectionItem}
        createCollectionItem={this.handleCreateCollectionItem}
        onCanCreateCollectionItem={this.handleCanCreateCollectionItem}
        onCanStartEditCollectionItem={this.handleCanStartEditCollectionItem}
        onStartEditCollectionItem={this.handleStartEditCollectionItem}
        onCanEndEditCollectionItem={this.handleCanEndEditCollectionItem}
        onEndEditCollectionItem={this.handleEndEditCollectionItem}
        onCanDeleteCollectionItem={this.handleCanDeleteCollectionItem}
        onDeleteCollectionItem={this.handleDeleteCollectionItem}
        onCollectionItemChange={this.handleCollectionItemChange}
      />
    );
  }
}

export const WidgetWrapper = connectToAPIProvider(connectToCurrentUserProvider(WidgetWrapperComponent));
