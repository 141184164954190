/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { Button } from 'reactstrap';
import { ConfirmActionModalResult } from '../../../../types/modal/modal-result';
import { IRenewContractModalWizardPage3Data, IRenewContractModalWizardState } from '../renew-contract-data.interface';

import { ModalWizardPage, ModalWizardPageProps } from '../../modal-wizard-page';

export const RenewContractModalPage3 = (props: ModalWizardPageProps<
  IRenewContractModalWizardPage3Data,
  IRenewContractModalWizardState,
  ConfirmActionModalResult
>): React.ReactElement => {
  const { wizardState, onModalComplete, closeModal, modalId } = props;
  const {
    processing,
    success,
    renewContractResult,
  } = wizardState;

  return (
    <ModalWizardPage<IRenewContractModalWizardPage3Data, IRenewContractModalWizardState, ConfirmActionModalResult> {...props}>
      <div className="wizard-page-section">
        <div className="wizard-page-section-heading">
          {processing && (
            <span>Processing... Please wait...</span>
          )}
          {!processing && success && (
            <span>Success!</span>
          )}
          {!processing && !success && (
            <span className="danger">Failed!</span>
          )}
        </div>
        <span className="text-danger">{renewContractResult?.error}</span>
        <p>
          {renewContractResult?.result && `Renewed as ${renewContractResult.result.number}`}
        </p>
        {/* <pre>{JSON.stringify(renewContractResult?.result, null, 2)}</pre> */}
        {/* When the processing has completed, show the close button */}
        {!processing && !!success && (
          <div className="wizard-page-section-content">
            <Button
              color="primary"
              onClick={() => {
                closeModal(modalId, false, () => onModalComplete({ processAction: false }));
              }}
            >
              Close
            </Button>
          </div>
        )}
      </div>
    </ModalWizardPage>
  );
};
