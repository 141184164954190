import React from 'react';
import { IExpenseClaimRecord } from '../../types/expense-claim/expense-claim.record.interface';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';

import AsyncSelect from './async-select';

export type ReceiptPickerProps = Omit<AsyncSelectComponentProps, 'formData'> & {
  formData: IExpenseClaimRecord,
};

export const ReceiptPicker:React.FC<ReceiptPickerProps> = (props) => {
  const {
    formSaveField = 'receipt_file_id',
    formData: {
      expense_claim_id,
    },
  } = props;


  return (
    <AsyncSelect
      {...props}
      getOptionLabel={(file: { id: number, filename: string }) => file?.filename || null}
      formSaveField={formSaveField}
      searchRoute={`/expenseclaim/${expense_claim_id}/file`}
      loadAndKeepAll
    />
  );
};
