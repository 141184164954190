import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import { IDtoolsEtaSummaryReportData } from '../../types/integration/dtools/dtools-eta-summary-report-data.interface';
import { WidgetProps } from '../../types/widget.props';

import { OverdueItemsTable } from '../dtools-information/eta-reporting/overdue-items-table';
import { AtRiskItemsTable } from '../dtools-information/eta-reporting/at-risk-items-table';
import { OutstandingItemsByVendorTable } from '../dtools-information/eta-reporting/outstanding-items-by-vendor-table';
import { EtaAgeByDateTable } from '../dtools-information/eta-reporting/eta-age-by-date-table';
import { OrderStatusSummaryTable } from '../dtools-information/eta-reporting/order-status-summary-table';


export type ProjectItemEtaSummaryWidgetProps = Omit<WidgetProps, 'rowData' | 'widgetData'> & {
  rowData: {
    id?: number,
    project_id?: number,
  },
  widgetData: IDtoolsEtaSummaryReportData[],
}


/**
 * This widget shows the checklist items which have been left out of the main form view.
 *
 * @todo convince the user form to do a patch instead of a PUT
 */
export const ProjectItemEtaSummaryWidget:React.FC<ProjectItemEtaSummaryWidgetProps> = (props) => {
  const {
    rowData,
    widgetData,
  } = props;

  const { id, project_id } = rowData;

  const projectId = project_id || id || undefined;

  const data = (widgetData?.length > 0) ? widgetData[0] : null;

  return (
    <Card className="dtools-item-eta-summary-panel">
      <CardBody>
        <Row>
          {data && data.overdue_items && (
            <Col md="12">
              <OverdueItemsTable recordData={data.overdue_items} projectId={projectId} />
            </Col>
          )}
        </Row>
        <p>&nbsp;</p>
        <Row>
          {data && data.risky_items && (
            <Col md="12">
              <AtRiskItemsTable recordData={data.risky_items} projectId={projectId} />
            </Col>
          )}
        </Row>
        <p>&nbsp;</p>
        <Row>
          {data && data.vendor_eta_items && (
            <Col md="12" xl="8">
              <OutstandingItemsByVendorTable recordData={data.vendor_eta_items} projectId={projectId} />
            </Col>
          )}
        </Row>
        <p>&nbsp;</p>
        <Row>
          {data && data.eta_age_by_date && (
            <Col xl="6">
              <EtaAgeByDateTable recordData={data.eta_age_by_date} projectId={projectId} />
            </Col>
          )}
          {data && data.order_status_summary && (
            <Col xl="6">
              <OrderStatusSummaryTable recordData={data.order_status_summary} projectId={projectId} />
            </Col>
          )}
        </Row>
        <p>&nbsp;</p>
      </CardBody>
    </Card>
  );
};
