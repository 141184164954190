import { IModalWizardState } from '../../../types/modal/modal-wizard-state.interface';
import { IModalWizardPageData } from '../../../types/modal/modal-wizard-page-data.interface';
import { SERObject } from '../../../helpers/ser-object.helper';
import { IContractRecord } from '../../../types/service/contract.record.interface';
import { RENEW_CONTRACT_MODAL_WIZARD_PAGE } from './renew-contract-modal-wizard-page.const';
import { IContractCompanySpaceRecord } from '../../../types/contact/contract-company-space.record.interface';

// Page 1
export interface IRenewContractModalWizardPage1Data extends IModalWizardPageData {
  automatically_generate_renewal: 0 | 1,
  clone_notes: 1 | 0,
  clone_serials: 1 | 0,
  contract_months: null | number,
  start_date: null | string,
  end_date: null | string,
  note: null | string,
}

// Page 2
export interface IRenewContractModalWizardPage2Data extends IModalWizardPageData {
  cover_spaces: {
    [id: number]: 1|0,
  },
}

// Page 3
export type IRenewContractModalWizardPage3Data = IModalWizardPageData

// Wizard State interface
export interface IRenewContractModalWizardState extends IModalWizardState {
  contractSpaces: IContractCompanySpaceRecord[] | null,

  pageData: {
    [RENEW_CONTRACT_MODAL_WIZARD_PAGE.PAGE_1_NEW_CONTRACT_SETTINGS]: IRenewContractModalWizardPage1Data,
    [RENEW_CONTRACT_MODAL_WIZARD_PAGE.PAGE_2_COVERED_LOCATIONS]: IRenewContractModalWizardPage2Data,
    [RENEW_CONTRACT_MODAL_WIZARD_PAGE.PAGE_3_FINALISE]: IRenewContractModalWizardPage3Data,
  },

  initialising: boolean,
  isDirty: boolean,
  processing: boolean,
  success: boolean,

  // The details about the contract record
  contractRecord: null | IContractRecord,
  renewContractResult: null | SERObject<IContractRecord>,
}

// Wizard initial state
export const renewContractModalWizardInitialState: IRenewContractModalWizardState = {
  currentPageIndex: 0,
  contractSpaces: null,
  pageData: [

    // Page 1 initial state
    {
      automatically_generate_renewal: 0,
      clone_notes: 0,
      clone_serials: 1,
      contract_months: null,
      start_date: null,
      end_date: null,
      note: null,
    },

    // Page 2 initial state
    {
      cover_spaces: {},
    },

    // Page 3 initial state
    {},
  ],
  pageDataErrors: [],

  initialising: true,
  isDirty: false,
  processing: false,
  success: false,

  contractRecord: null,
  renewContractResult: null,
};
