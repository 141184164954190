import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { SERObject } from './ser-object.helper';
import { APIFetchFunction } from '../components/providers/api-provider';
import { IAPIActions } from '../types/api-actions.interface';

export const getAvailableActions = async (resourceRoute: string, apiFetch: APIFetchFunction): Promise<SERObject<IAPIActions>> => {
  const result = new SERObject<IAPIActions>(false);

  const response = await apiFetch(
    resourceRoute,
    {
      method: HTTP_METHOD.GET,
    },
  );

  if (response.success) {
    result.result = response.body.actions;
    result.success = true;
  } else if (!response.aborted) {
    result.error = (response.body && response.body.errors) ? response.body.errors : response.error;
  }

  return result;
};
