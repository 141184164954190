import React from 'react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import classNames from 'classnames';

/**
 * @class SplitHandle
 *
 * @description
 * Used primarily when two divs need to be resizable
 */
class SplitHandle extends React.Component {
  /**
   * @constructor
   */
  constructor(props) {
    super(props);
    const { initialDragHandlePosition } = props;

    this.state = {
      dragging: false,
    };

    this.dragHandleRef = React.createRef();

    // This is the touch that started a drag of the drag handle (if it was initiated by a touch event)
    this.dragTouch = null;

    // Drag Handle Position is stored as a Percent of the control element parent
    // (minDragHandlePosition -> maxDragHandlePosition)
    this.dragHandlePosition = initialDragHandlePosition;

    // The cursor or touch start position
    this.dragStartOffset = null;
    // The cursor or touch position during a drag operation (as a percent of the control element parent)
    this.dragPosition = null;

    // Use this flag to slow down the notifications sent to parents
    this.updateAmnestyTimeout = null;
  }


  /**
   * @inheritdoc
   */
  componentDidMount = () => {
    window.addEventListener('resize', this.updateDragHandlePosition);
    this.updateDragHandlePosition();
  }


  /**
   * @inheritdoc
   */
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDragHandlePosition);
    this.unbindDragEventListeners();
    // this.unbindDragEndTouchListeners();
  }


  /**
   * @inheritdoc
   */
  componentDidUpdate = (prevProps) => {
    const { forceUpdateId } = this.props;
    const { forceUpdateId: oldForceUpdateId } = prevProps;

    // This is a nasty solution but fixes a problem with the drag handle being off centre after the parent component is changed in some way
    if (forceUpdateId !== oldForceUpdateId) { setTimeout(() => {
      this.updateDragHandlePosition();
    }, 100); }
  }


  /**
   * @description
   * Update the drag handle position. This is not a state based property
   * to improve performance.
   */
  updateDragHandlePosition = () => {
    const { controlElement } = this.props;
    const { dragging } = this.state;

    if (controlElement && this.dragHandleRef.current) {
      const { offsetLeft, offsetWidth, parentElement } = controlElement;
      const { offsetWidth: parentWidth } = parentElement;

      // When dragging, ignore the position of the controlElement and instead use the provided dragPosition
      if (dragging) {
        this.dragHandlePosition = this.dragPosition;
      }

      // Move the handle to match the position of the controlElement
      else {
        this.dragHandlePosition = ((offsetLeft + offsetWidth) / parentWidth) * 100;
      }

      this.dragHandleRef.current.style.left = `${this.dragHandlePosition}%`;
    }
  }


  /**
   * @description
   * Fired by a mouse or touch event, this will convert a page coordinate into the
   * coordinate system we need which is relative to the paren element
   *
   * @param {number} pageXPos
   *
   * @returns {number}
   */
  getPageCoordinateRelativeToParent = (pageXPos) => {
    const { controlElement } = this.props;
    if (controlElement) {
      const { parentElement } = controlElement;
      return pageXPos - parentElement.getBoundingClientRect().left;
    }
    return pageXPos;
  }


  /**
   * @description
   * This sorts through a touch list (from a touchEnd or touchMove event) and
   * picks out the touch which matches our original dragTouch (from a touchStart event)
   *
   * @returns {Touch | null}
   */
  findDragTouchInTouchList = (touchList) => {
    if (!touchList || !touchList.length || !this.dragTouch) return null;

    for (let i = 0; i < touchList.length; i += 1) {
      if (touchList[i].identifier === this.dragTouch.identifier) return touchList[i];
    }
    return null;
  }


  /**
   * @description
   * Bind any listeners that will be required to terminate the dragging of the drag handle
   */
  bindDragEventListeners = () => {
    // This drag event was initiated by a touch
    if (this.dragTouch) {
      // Touch End will end the drag
      document.addEventListener('touchend', this.handleTouchEnd);

      // Touch move will move the drag handle
      document.addEventListener('touchmove', this.handleTouchMove);
    }

    // This drag event was initiated by a mouse
    else {
      // Mouse up will end the drag
      document.addEventListener('mouseup', this.handleMouseUp);

      // Mouse move will move the drag handle
      document.addEventListener('mousemove', this.handleMouseMove);
    }

    // When the mouse leaves the document the drag will end
    document.addEventListener('blur', this.handleDocumentBlur);
  }


  /**
   * @description
   * Unbind any mouse listeners that were required to terminate the dragging of the
   * drag handle (by mouse)
   */
  unbindDragEventListeners = () => {
    // Just remove all potential event listeners
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('blur', this.handleDocumentBlur);
  }


  /**
   * @description
   * Begin dragging the drag handle
   *
   * @param {number} startX the startPosition (relative to the controlElement's parent) of the drag
   * @param {Touch} [touch=null] the touch that initiated the drag (if initiated by touch)
   */
  beginDragging = (startX, touch = null) => {
    this.setState({
      dragging: true,
    }, () => {
      this.dragTouch = touch;
      this.bindDragEventListeners();

      // Keep an offset of the mouse / touch coordinate relative to the drag handle
      // This will be removed from the ongoing calculations based on the mouse / touch position
      const { controlElement: { parentElement: { offsetWidth: parentWidth } } } = this.props;
      const dragHandleComputedStyle = window.getComputedStyle(this.dragHandleRef.current);
      this.dragStartOffset = startX - this.dragHandleRef.current.offsetLeft + parseInt(dragHandleComputedStyle.marginLeft, 10);
      this.dragPosition = ((startX - this.dragStartOffset) / parentWidth) * 100;

      this.updateDragHandlePosition();

      // Fire the onBeginDrag event
      const { onBeginDrag } = this.props;
      if (typeof onBeginDrag === 'function') onBeginDrag();
    });
  }


  /**
   * @description
   * Fired by either the touchMove or mouseMove events while dragging
   */
  drag = (xPos) => {
    const { dragging } = this.state;
    if (dragging) {
      const {
        controlElement: { parentElement: { offsetWidth: parentWidth } },
        minDragHandlePosition,
        maxDragHandlePosition,
      } = this.props;
      this.dragPosition = Math.max(Math.min(((xPos - this.dragStartOffset) / parentWidth) * 100, maxDragHandlePosition), minDragHandlePosition);
      this.updateDragHandlePosition();

      // debounce and Fire the onDrag event
      this.fireOnDragEvent();
    }
  }


  /**
   * Notify listeners of an OnDrag event
   */
  fireOnDragEvent = () => {
    if (!this.updateAmnestyTimeout) {
      const { onDrag, dragUpdateInterval } = this.props;

      // Prevent this event from firing too often
      this.updateAmnestyTimeout = setTimeout(() => {
        this.updateAmnestyTimeout = null;
      }, dragUpdateInterval);

      if (typeof onDrag === 'function') onDrag(this.dragPosition);
    }
  }


  /**
   * @description
   * End dragging the drag handle
   *
   * @param {boolean} [aborted=false] whether the end drag was intended or not
   */
  endDragging = (aborted = false) => {
    this.unbindDragEventListeners();
    this.setState({
      dragging: false,
    }, () => {
      this.dragTouch = null;
      this.dragStartOffset = null;
      this.dragPosition = null;

      this.updateDragHandlePosition();

      this.dragHandleRef.current.blur();

      // Fire the onEndDrag event
      this.fireOnEndDragEvent(aborted);
    });
  }


  /**
   * Notify listeners of an onEndDrag event
   *
   * @param {boolean} [aborted=false] whether the end drag was intended or not
   */
  fireOnEndDragEvent = (aborted = false) => {
    if (this.updateAmnestyTimeout) {
      clearTimeout(this.updateAmnestyTimeout);
      this.updateAmnestyTimeout = null;
      const { onEndDrag } = this.props;

      if (typeof onEndDrag === 'function') onEndDrag(aborted);
    }
  }


  /**
   * @description
   * Fired when the user presses their mouse button while hovering over the drag handle
   *
   * @param {React.SyntheticEvent} e
   */
  handleMouseDown = (e) => {
    const { dragging } = this.state;
    if (!dragging) {
      this.beginDragging(this.getPageCoordinateRelativeToParent(e.pageX));
    }
  }


  /**
   * @description
   * Fired when the user is dragging (via mouse) and they move their cursor over the screen
   */
  handleMouseMove = (e) => {
    const { dragging } = this.state;
    if (dragging) {
      e.stopPropagation();
      this.drag(this.getPageCoordinateRelativeToParent(e.pageX));
    }
  }


  /**
   * @description
   * Fired when the user releases their mouse button somewhere over the document while dragging
   */
  handleMouseUp = () => {
    this.endDragging();
  }


  /**
   * @description
   * Fired when the user touches the drag handle
   *
   * @param {React.SyntheticEvent} e
   */
  handleTouchStart = (e) => {
    const { dragging } = this.state;
    if (!dragging && e.changedTouches[0]) {
      this.beginDragging(this.getPageCoordinateRelativeToParent(e.changedTouches[0].pageX), e.changedTouches[0]);
    }
  }


  /**
   * @description
   * Fired when the user is dragging (via touch) and they move their finger over the screen
   */
  handleTouchMove = (e) => {
    const { dragging } = this.state;
    if (dragging) {
      const targetTouch = this.findDragTouchInTouchList(e.changedTouches);
      if (targetTouch) {
        e.stopPropagation();
        this.drag(this.getPageCoordinateRelativeToParent(targetTouch.pageX));
      }
    }
  }


  /**
   * @description
   * Fired when the user releases their finger somewhere over the document while dragging
   *
   * @param {React.SyntheticEvent} e
   */
  handleTouchEnd = (e) => {
    const { dragging } = this.state;
    if (dragging) {
      const targetTouch = this.findDragTouchInTouchList(e.changedTouches);
      if (targetTouch) {
        e.stopPropagation();
        this.endDragging();
      }
    }
  }


  /**
   * @description
   * Fired when the window or the container resize detector is resized
   */
  handleWindowResize = () => {
    // Immediately end dragging to prevent glitches
    const { dragging } = this.state;
    if (dragging) {
      this.endDragging(true);
    }
    else {
      // Update the resize handle to the position of the parent
      this.updateDragHandlePosition();
    }
  }


  /**
   * @description
   * Fired during a drag when the document loses focus
   */
  handleDocumentBlur = () => {
    this.endDragging(true);
  }


  /**
   * @inheritdoc
   */
  render = () => {
    const { dragging } = this.state;

    return (
      <ReactResizeDetector handleWidth handleHeight={false} onResize={this.handleWindowResize}>
        <>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className={classNames('split-handle', {
              dragging,
            })}
            ref={this.dragHandleRef}
            style={{
              left: `${this.dragHandlePosition}%`,
            }}
            onMouseDown={this.handleMouseDown}
            onTouchStart={this.handleTouchStart}
          />
        </>
      </ReactResizeDetector>
    );
  }
}

SplitHandle.propTypes = {
  controlElement: PropTypes.instanceOf(Element).isRequired,
  forceUpdateId: PropTypes.number,
  initialDragHandlePosition: PropTypes.number,
  minDragHandlePosition: PropTypes.number,
  maxDragHandlePosition: PropTypes.number,
  dragUpdateInterval: PropTypes.number,

  onBeginDrag: PropTypes.func,
  onDrag: PropTypes.func.isRequired,
  onEndDrag: PropTypes.func,
};

SplitHandle.defaultProps = {
  initialDragHandlePosition: 50,
  forceUpdateId: 0,
  minDragHandlePosition: 15,
  maxDragHandlePosition: 85,
  dragUpdateInterval: 25,
  onBeginDrag: null,
  onEndDrag: null,
};

export default SplitHandle;
