import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { formatValueString } from '../../render-functions';
import ForecastMonths from './ForecastMonths';
import { documentTitle } from '../../../utils/helpers';
import { connectToAPIProvider } from '../../providers/api-provider';
import API_PROVIDER_PROP_TYPES from '../../../prop-types/api-provider-prop-types';
import { LoadingSpinner } from '../../layout-helpers/loading-spinner';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';

const columnMap = [
  // {
  //   key: 'year',
  //   title: 'Year/Month',
  // },
  {
    key: 'project_count',
    title: 'Projects',
    visible: true,
    format: COLUMN_FORMAT.NUMBER,
  },
  {
    key: 'project_value',
    title: 'Project Worth',
    visible: false,
    format: COLUMN_FORMAT.WHOLE_DOLLARS,
  },
  {
    key: 'low_high',
    title: 'Low | High',
    visible: true,
    format: COLUMN_FORMAT.NUMBER,
    query: 'status=Proposal',
  },
  {
    key: 'unlikely_proposal_amount',
    title: 'Low Forecasts',
    dataTitle: 'Low Likelihood Forecasts',
    visible: true,
    format: COLUMN_FORMAT.WHOLE_DOLLARS,
    query: 'status=Proposal&likelihood=low',
  },
  {
    key: 'unlikely_proposal_total_value',
    title: 'Low Project Worth',
    dataTitle: 'Low Likelihood Forecasts',
    visible: true,
    format: COLUMN_FORMAT.WHOLE_DOLLARS,
    query: 'status=Proposal&likelihood=low',
  },
  {
    key: 'likely_proposal_amount',
    title: 'High Forecasts',
    dataTitle: 'High Likelihood Forecasts',
    visible: true,
    format: COLUMN_FORMAT.WHOLE_DOLLARS,
    query: 'status=Proposal&likelihood=high',
  },
  {
    key: 'likely_proposal_total_value',
    title: 'High Project Worth',
    dataTitle: 'High Likelihood Forecasts',
    visible: true,
    format: COLUMN_FORMAT.WHOLE_DOLLARS,
    query: 'status=Proposal&likelihood=high',
  },
  {
    key: 'lead_amount',
    title: 'Lead',
    dataTitle: 'Lead Forecasts',
    visible: false,
    format: COLUMN_FORMAT.WHOLE_DOLLARS,
    query: 'status=Lead',
  },
  {
    key: 'proposal_amount',
    title: 'All Proposals',
    dataTitle: 'Proposal Forecasts',
    visible: true,
    format: COLUMN_FORMAT.WHOLE_DOLLARS,
    query: 'status=Proposal',
  },
  {
    key: 'active_amount',
    title: 'Active',
    dataTitle: 'Active Project Forecasts',
    visible: true,
    format: COLUMN_FORMAT.WHOLE_DOLLARS,
    query: 'status=Active',
  },
  {
    key: 'completed_amount',
    title: 'Completed',
    dataTitle: 'Completed Project Forecasts',
    visible: true,
    format: COLUMN_FORMAT.WHOLE_DOLLARS,
    query: 'status=Completed',
  },
  {
    key: 'active_and_completed_amount',
    title: 'In Progress',
    dataTitle: 'In Progress Forecasts',
    visible: false,
    format: COLUMN_FORMAT.WHOLE_DOLLARS,
    query: 'status[]=Active&status[]=completed',
  },
  {
    key: 'total_value',
    title: 'Maximum',
    visible: false,
    format: COLUMN_FORMAT.WHOLE_DOLLARS,
  },
];

class FutureForecastTable extends Component {
  constructor(props) {
    super(props);
    const colSpan = columnMap.reduce((n, val) => {
      const add = val.visible ? 1 : 0;
      return n + add;
    }, 1);
    this.state = {
      yearData: [],
      monthData: [],
      columns: columnMap,
      colSpan,
      showYears: {},
      loading: true,
      error: null,
    };

    this.abortController = null;
  }

  componentDidMount = () => {
    const { title } = this.props;
    documentTitle(title || null);
    this.loadForecastData();
  };

  loadForecastData = () => {
    const { apiProvider: { apiFetch } } = this.props;

    if (this.abortController) {
      this.abortController.abort();
    }

    this.setState({
      loading: true,
      error: null,
    }, async () => {
      this.abortController = apiAborter();

      const response = await apiFetch(
        '/reports/forecast/summary',
        {
          signal: this.abortController.signal,
        },
      );

      if (response.success) {
        this.abortController = null;
        this.setState({
          loading: false,
          yearData: response.body.year_summaries,
          monthData: response.body.month_summaries,
        });
      } else if (!response.aborted) {
        this.abortController = null;
        this.setState({
          error: response.error,
          loading: false,
          yearData: [],
          monthData: [],
        });
      }
    });
  };

  toggleYearDrillDown = (year) => {
    const { showYears } = this.state;
    this.setState({
      showYears: {
        ...showYears,
        [year]: showYears[year] === undefined ? true : !showYears[year],
      },
    });
  };

  render = () => {
    const { yearData, monthData, columns, showYears, colSpan, loading, error } = this.state;

    return (
      <Card>
        <CardHeader>
          <h3>Forecasting Summary</h3>
        </CardHeader>
        <CardBody>

          {loading && <LoadingSpinner />}

          {!loading && !!error && (
            <span className="error">{error}</span>
          )}

          {!loading && !error && (
            <table className="table">
              <thead>
                <tr>
                  <th>Year/Month</th>
                  {columns.map((column) => {
                    if (column.visible !== true) return null;
                    return (
                      <th key={`title-column-${column.title}`} className="text-right">
                        {column.title}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {yearData.map((forecast) => (
                  <React.Fragment key={`year-row-${forecast.year}`}>
                    <tr onClick={() => this.toggleYearDrillDown(forecast.year)}>
                      <td>{forecast.year}</td>
                      {columns.map((column) => {
                        if (column.visible !== true) return null;
                        if (column.key === 'low_high') {
                          return (
                            <td
                              className="text-right"
                              key={`year-${forecast.year}-column-${column.title}`}
                            >
                              {`${forecast.low_proposal_count} | ${forecast.high_proposal_count}`}
                            </td>
                          );
                        }
                        return (
                          <td
                            className="text-right"
                            key={`year-${forecast.year}-column-${column.title}`}
                          >
                            {formatValueString(forecast[column.key], column.format)}
                          </td>
                        );
                      })}
                    </tr>
                    {showYears[forecast.year] && (
                      <ForecastMonths
                        monthData={monthData.filter((month) => month.year === forecast.year)}
                        columns={columns}
                        colSpan={colSpan}
                      />
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          )}
        </CardBody>
      </Card>
    );
  };
}

FutureForecastTable.propTypes = {
  title: PropTypes.string,
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
};

FutureForecastTable.defaultProps = {
  title: null,
};

export default connectToAPIProvider(FutureForecastTable);
