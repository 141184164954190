/**
 * Iterates over all available columns for a datatable, hides those which are not
 * visible or the user does not have permission to see, and returns them ordered
 * by their display order
 *
 * @param {*} columns columns from redux tableSettings
 * @param {(permissions: PERMISSION[] | PERMISSION) => boolean} userHasPermissions a function for checking whether the column visibility permissions match those of the logged in user
 * @returns {object[]} Visible Sorted Columns
 */
export const getVisibleColumnsInDisplayOrder = (columns, userHasPermissions) => columns.filter((column) => {
  if (!column.visible) return false; // column not visible
  if (column.permissions === undefined) return true; // no permissions required
  if (userHasPermissions(column.permissions)) return true; // user has requisite permissions
  return false;
}).sort((a, b) => a.order - b.order);
