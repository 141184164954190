import React, { KeyboardEvent, useCallback } from 'react';
import { Button } from 'reactstrap';
import { AN_ICON } from '../../constants/icon.const';
import Icon from '../layout-helpers/icon';

export type CollectionWidgetHeaderProps = {
  icon?: AN_ICON,
  description?: string,
  subHeading?: string | React.ReactNode,
  actions?: string | React.ReactNode,
  showAddBtn?: boolean,
  addBtnDisabled?: boolean,
  onClickAddBtn?: () => void,
}

export const CollectionWidgetHeader: React.FC<CollectionWidgetHeaderProps> = (props) => {
  const {
    showAddBtn, onClickAddBtn, description, subHeading, actions, addBtnDisabled, icon,
  } = props;

  const handleAddBtnKeyPress = useCallback((event: KeyboardEvent<HTMLButtonElement>) => {
    if (onClickAddBtn && event.key.toLowerCase() === 'enter') {
      onClickAddBtn();
    }
  }, [onClickAddBtn]);

  return (
    <div className="collection-widget-header">
      <div className="heading">
        <div className="heading-row">
          {description && (
            <span>{description}</span>
          )}
          {(showAddBtn === true) && (
            <Button
              className="icon-btn add-btn"
              onClick={onClickAddBtn}
              onKeyPress={handleAddBtnKeyPress}
              size="sm"
              color="secondary"
              title={`Add new ${description}`}
              role="button"
              tabIndex={-1}
              disabled={addBtnDisabled === true}
            >
              <Icon i={icon || 'plus'} />
            </Button>
          )}
        </div>
        {subHeading && (
          <div className="heading-row">
            {subHeading}
          </div>
        )}
      </div>
      {actions && (
        <div className="actions">
          {actions}
        </div>
      )}
    </div>
  );
};
