import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Col, Form, Label, FormGroup } from 'reactstrap';
import moment from 'moment';
import { EventApi } from '@fullcalendar/react';

import { ILeaveBlockRecord } from '../../../types/leave/leave-block.record.interface';
import { UpdateRecordModalProps } from '../../../types/modal/update-record-modal.props';
import { Modal } from '../Modal';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import { APIContext } from '../../providers/api-provider';
import { DELETE_CONFIRMATION_TYPE } from '../../../constants/delete-confirmation-type.const';
import manageLeaveTableInitialSettings from '../../../table-definitions/manage-leave-table';
import { mergeActions } from '../../../utils/helpers';
import RecordActionButtons from '../../record-action-buttons/record-action-buttons';
import { CurrentUserContext } from '../../providers/current-user-provider';
import { PERMISSION } from '../../../constants/permissions.const';
import { MODAL_BUTTON_SIDE_TYPE } from '../../../constants/modal-button-side-type.const';
import { BUTTON_COLOR } from '../../../constants/button-color.const';
import LeaveCalendarEventModalBlocks from './leave-calendar-event-modal-blocks';

interface LeaveCalendarEventModalProps<T> extends UpdateRecordModalProps<T> {
  eventApi: EventApi,
}

/**
 * Leave Calendar Event Modal
 *
 * @param props form data and event api
 *
 * @returns {React.FC}
 */
export const LeaveCalendarEventModal: React.FC<LeaveCalendarEventModalProps<ILeaveBlockRecord>> = (props) => {
  const {
    id,
    formData,
    eventApi,
    closeModal,
  } = props;

  const { apiFetch } = useContext(APIContext);
  const { userHasPermissions } = useContext(CurrentUserContext);
  const abortEventsRequest = useRef<AbortController | null>(null);

  const [isLoadingEvent, setIsLoadingEvent] = useState(false);
  const [actions, setActions] = useState([]);
  const [status, setStatus] = useState(formData?.leave_application?.status?.name ?? 'Unknown');
  const [approver, setApprover] = useState(formData?.leave_application?.approver);
  const [blockList, setBlockList] = useState<ILeaveBlockRecord[] | null>(null);

  const userCanSeeDetail = userHasPermissions([PERMISSION.LEAVE_APPROVER, PERMISSION.LEAVE_CONFIRMER, PERMISSION.LEAVE_ADMIN]);

  /**
   * Load Event Data
   *
   * @gets the leave application record for actions and live status
   * @sets state values action and status
   *
   * @param {boolean} edited has the event data been edited?
   *
   * @returns void
   */
  const loadEventData = useCallback((edited = false) => {
    if (isLoadingEvent) return;

    setIsLoadingEvent(true);

    if (abortEventsRequest.current) {
      abortEventsRequest.current.abort();
    }
    abortEventsRequest.current = apiAborter();

    const queryString = [
      'deleted',
      'with[]=status:id,name',
      'with[]=approver:id,name',
      'with[]=leaveBlocks:id,start_date,end_date',
      'with[]=leaveBlocks.leaveBlockType:id,name',
    ].join('&');

    apiFetch(
      `/leaveapplication/${formData?.leave_application_id}?${queryString}`,
      {
        name: 'NationalLeaveScheduleView::loadLeave',
        signal: abortEventsRequest.current.signal,
      },
    ).then((response) => {
      if (response.success) {
        // handle success responses
        abortEventsRequest.current = null;
        const result = response.body;
        const mergedActions = mergeActions(
          result.actions ?? [],
          manageLeaveTableInitialSettings.possibleActions,
        );
        const newStatus = result.data.status.name ?? 'Unknown';
        if (edited) {
          eventApi.setProp('title', newStatus);
        }
        setBlockList(result.data?.leave_blocks);
        setApprover(result.data?.approver);
        setStatus(newStatus);
        setActions(mergedActions);
        setIsLoadingEvent(false);

      // handle aborted responses
      } else if (!response.aborted) {
        abortEventsRequest.current = null;
        console.error('NationalLeaveScheduleView::loadLeave', response.error);
      }
    });
  }, [apiFetch, eventApi, formData?.leave_application_id, isLoadingEvent]);

  /**
   * Component Mount Event
   */
  useEffect(
    () => loadEventData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const title = `${formData?.leave_application?.user?.name ?? 'User'} ${status} Leave - ${moment(formData?.start_date).format('MMM YYYY')}`;


  const modalButtons = [
    {
      label: 'Cancel',
      color: BUTTON_COLOR.SECONDARY,
      sideType: MODAL_BUTTON_SIDE_TYPE.RIGHT,
      onClick: () => {
        closeModal(id);
      },
    },
  ];

  // render
  return (
    <Modal
      {...props}
      title={title}
      size="lg"
      allowDismiss
      buttons={modalButtons}
    >
      <h5>Leave Details:</h5>
      <Form inline={false} className="portal-form text-dark">
        <FormGroup row>
          <Label md={4}>User</Label>
          <Col md={8}>
            <span>{formData?.leave_application?.user?.name ?? 'Unknown'}</span>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={4}>Status</Label>
          <Col md={8}>
            <span>{status}</span>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={4}>Start Date</Label>
          <Col md={8}>
            <span>{moment(formData?.start_date).format('DD/MM/YY')}</span>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={4}>End Date</Label>
          <Col md={8}>
            <span>{moment(formData?.end_date).format('DD/MM/YY')}</span>
          </Col>
        </FormGroup>
        {approver && (
          <FormGroup row>
            <Label md={4}>Approver</Label>
            <Col md={8}>
              <span>{approver.name}</span>
            </Col>
          </FormGroup>
        )}
      </Form>
      {userCanSeeDetail && actions.length > 0 && (
        <>
          <p>&nbsp;</p>
          <h5>Leave Application Actions:</h5>
          <RecordActionButtons
            rowData={{ id: formData?.leave_application_id }}
            permittedActions={actions}
            formDeleteConfirmationType={DELETE_CONFIRMATION_TYPE.DELETE}

            formIsEditing={false}
            formIsCreating={false}
            formIsBusy={false}
            formIsLocked={false}
            secondary={false}
            hasLoaded
            formIsReadOnly

            startEditRecord={() => {}}
            endEditRecord={() => {}}
            deleteRecord={() => {}}
            className="btn-group"

            onSuccess={() => loadEventData(true)}
          />
        </>
      )}
      {blockList && <LeaveCalendarEventModalBlocks blockList={blockList} userCanSeeDetail={userCanSeeDetail} />}
    </Modal>
  );
};
