import React, { SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { Button, Col, Row, Spinner } from 'reactstrap';
import { APIContext } from '../../../providers/api-provider';
import { IProjectRecord } from '../../../../types/project/project.record.interface';
import { apiAborter } from '../../../../helpers/api-aborter.helper';
import { PortalAPIResponse } from '../../../../helpers/portal-api-response';
import { formatValueString } from '../../../render-functions';
import { COLUMN_FORMAT } from '../../../../constants/column-format.const';
import Icon from '../../../layout-helpers/icon';


interface IActivationDtoolsProjectSummaryProps {
  onReceiveDtoolsSummary: (costValue: number, priceValue: number) => void,
  selectedDtoolsId: string | null,
}

/**
 * D-Tools Match Record Interface
 *
 * note that the non-compliant capitalisation of variables is from the D-Tools API
 *
 * @todo: globalise this
 * */
interface IDtoolsSummaryRecord {
  Id: string,
  Number: string,
  Client: string,
  Name: string,
  CheckOutUserName: string,
  Cost: number,
  Price: number,
  TotalPrice: number,
  Progress: string,
  Approved: false,
  MatchType: string,
  MatchOrder: number
}

const ActivationDtoolsProjectSummary: React.FC<IActivationDtoolsProjectSummaryProps> = (props) => {
  const { apiFetch } = useContext(APIContext);

  const abortLoadSummaryRequest = useRef<AbortController | null>(null);

  const { onReceiveDtoolsSummary, selectedDtoolsId } = props;

  const [dtoolsSummaryLoading, setDtoolsSummaryLoading] = useState<boolean>(false);
  const [dtoolsSummary, setDtoolsSummary] = useState<IDtoolsSummaryRecord | null>(null);


  /**
   * Load D-Tools Summary
   */
  const loadDtoolsSummary = useCallback((dtoolsId: string) => {
    if (abortLoadSummaryRequest.current) {
      abortLoadSummaryRequest.current.abort();
    }
    abortLoadSummaryRequest.current = apiAborter();

    setDtoolsSummaryLoading(true);

    apiFetch(
      `/dtools-connector/project/${dtoolsId}`,
      {
        name: 'ProjectActivationModal: DtoolsLinkCheck',
        method: HTTP_METHOD.GET,
        signal: abortLoadSummaryRequest.current.signal,
      },
    ).then((response: PortalAPIResponse) => {
      if (response.success) {
        const data = response.body?.data;
        setDtoolsSummary(data);
        setDtoolsSummaryLoading(false);
        onReceiveDtoolsSummary(data.Cost, data.TotalPrice);
      } else if (!response.aborted) {
        setDtoolsSummaryLoading(false);
      }
    });
  }, [apiFetch, onReceiveDtoolsSummary]);

  useEffect(() => {
    if (selectedDtoolsId && (dtoolsSummaryLoading === false && dtoolsSummary === null)) {
      loadDtoolsSummary(selectedDtoolsId);
    }
    return undefined;
  }, [loadDtoolsSummary, dtoolsSummaryLoading, dtoolsSummary, selectedDtoolsId]);

  // cleanup function only
  useEffect(() => () => {
    if (abortLoadSummaryRequest.current) {
      abortLoadSummaryRequest.current.abort();
    }
  }, []);

  return (
    <div>
      {dtoolsSummaryLoading && (
        <Icon i="rolling" />
      )}
      {dtoolsSummary && (
        <>
          <p>
            Project Summary from D-Tools
            {' '}
            {dtoolsSummary?.Number}
            :
          </p>
          <p>
            <strong>Cost:</strong>
            {' '}
            {formatValueString(dtoolsSummary?.Cost, COLUMN_FORMAT.CURRENCY)}
          </p>
          <p>
            <strong>Price:</strong>
            {' '}
            {formatValueString(dtoolsSummary?.TotalPrice, COLUMN_FORMAT.CURRENCY)}
          </p>

        </>
      )}
    </div>
  );
};

export default ActivationDtoolsProjectSummary;
