import { RowDetailsWidget } from '../components/widgets/row-details.widget';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { IWidgetDefinition } from '../types/widget-definition.interface';

export const ProjectChecklistWidgetDefinition: IWidgetDefinition = {
  component: RowDetailsWidget,
  formCaption: 'Project Checklist Items',
  columns: [
    {
      name: 'po_saved_in_project_drive',
      title: 'PO Saved in Drive',
      description: 'Is the client Purchase Order saved in the Project Drive?',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: true,
      showInForm: true,
      required: false,
      order: 190,
      formOrder: 190,
    },
    {
      name: 'as_sold_proposal_saved',
      title: 'As Sold in Drive',
      description: 'Has the As Sold proposal document been saved in the Project Drive?',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: true,
      showInForm: true,
      required: false,
      order: 190,
      formOrder: 190,
    },
    {
      name: 'po_value_matches_as_sold',
      title: 'PO matches As Sold',
      description: 'Does the value of the PO match the value of the As Sold proposal?',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: true,
      showInForm: true,
      required: false,
      order: 190,
      formOrder: 190,
    },
    {
      name: 'finance_reports_created',
      title: 'Finance Reports Uploaded',
      description: 'Have the Finance Reports have been created and saved to the drive?',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: true,
      showInForm: true,
      required: false,
      order: 190,
      formOrder: 190,
    },
  ],
};
