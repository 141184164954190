import React from 'react';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { DataTablePageProps } from '../../types/data-table-page.props';

export type ProcessExpenseBatchesDataTablePageProps = DataTablePageProps;

/**
 * Process Expense Batches table
 */
export const ProcessExpenseBatchesDataTablePage: React.FC<ProcessExpenseBatchesDataTablePageProps> = ({
  baseFilters,
  baseFlags,
  match,
  history,
  location,
  availableActions,
  possibleActions,
  children,
}) => (
  <DataTablePage
    title="Expense Claim Batches"
    tableIdentifier={TABLE_IDENTIFIER.PROCESS_EXPENSE_BATCHES_TABLE}
    baseFilters={baseFilters}
    baseFlags={baseFlags}
    match={match}
    history={history}
    location={location}
    availableActions={availableActions}
    possibleActions={possibleActions}
  >
    {children}
  </DataTablePage>
);
