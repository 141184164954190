/**
 * @description
 * Determine if an input is a finite number
 *
 * @param {unknown} input
 * @returns {input is number}
 */
const isConvertableToFiniteNumber = (input) => Number.isFinite(Number(input));

export default isConvertableToFiniteNumber;
