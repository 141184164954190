import React from 'react';
import { Card, CardBody, Button, CardText, CardTitle } from 'reactstrap';
import Icon from '../layout-helpers/icon';
import { QuickBooksPushForm } from '../custom-forms/quickbooks-push-form';
import { APIRecord } from '../../types/api-record.interface';
import { ICompanyRecord } from '../../types/company/company.record.interface';

const qbAppEndpoint = process.env.REACT_APP_QUICKBOOKS_APP_ENDPOINT;
const qbPath = `${qbAppEndpoint}app/customerdetail?nameId=`;

interface IServiceJobQuickbooksWidgetProps {
  rowData: APIRecord & {
    id: number,
    project_number: string,
    quickbooks_id: number | null,
    name: string,
    client: ICompanyRecord,
  },
  baseRoute: string,
  // refreshWidgetData: () => void,
  refreshRecord: () => void,
  parentId: number,
}

export const ServiceJobQuickbooksWidget: React.FC<IServiceJobQuickbooksWidgetProps> = ({ rowData, baseRoute, parentId, refreshRecord }) => (
  <div className="widget project-quickbooks-widget">
    <Card>
      {rowData && rowData.quickbooks_id ? (
        <CardBody>
          <CardTitle>
            Service Job is linked! &nbsp;&nbsp;
            <Button
              href={`${qbPath}${rowData.quickbooks_id}`}
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
            >
              View in QuickBooks
              {' '}
              <Icon i="external-link" />
            </Button>
          </CardTitle>
        </CardBody>
      ) : (
        <CardBody>
          <CardTitle>Service Job Linking for QuickBooks and QuickBooks Time Tracking</CardTitle>
          <CardText>Use this form to link the job to QuickBooks for time tracking, invoicing etc.</CardText>
          <CardText>Please make sure the client is correct before proceeding, as this could complicate billing.</CardText>
          <QuickBooksPushForm
            entityName="Job"
            baseRoute={baseRoute}
            onLinkComplete={refreshRecord}
            parentId={parentId}
            rowData={rowData}
          />
        </CardBody>
      )}
    </Card>
  </div>
);
