import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ProcessLeaveDataTablePage } from '../data-table-pages/process-leave.data-table-page';
import { ProcessAllowancesDataTablePage } from '../data-table-pages/process-allowances.data-table-page';
import { ProcessExpenseClaimsDataTablePage } from '../data-table-pages/process-expense-claims.data-table-page';
import { ProcessExpenseBatchesDataTablePage } from '../data-table-pages/process-expense-batches.data-table-page';
import RecordDetailPage, { TAB_REGEX } from '../record-detail-page/record-detail-page';
import FourOhFour from '../error-pages/four-oh-four';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { InvoiceRequestDataTablePage } from '../data-table-pages/invoice-requests.data-table-page';
import { MonthlyCommissionSnapshotDatatablePage } from '../data-table-pages/monthly-commission-snapshots.data-table-page';
import { SalepersonInvoiceView } from '../sales-commission/salesperson-invoice-view';
import { CommissionStructureIterationsDataTablePage } from '../data-table-pages/commission-structure-iterations.data-table-page';
import { CommissionStructureBracketsDataTablePage } from '../data-table-pages/commission-structure-brackets.data-table-page';
import { ProjectProfitabilitySnapshotsDataTablePage } from '../data-table-pages/project-profitability-snapshots.data-table-page';
import { CommissionPaymentBatchesDataTablePage } from '../data-table-pages/commission-payment-batches.data-table-page';

const FinanceRoutes = () => (
  <Switch>
    {/*
    * Commission Reports
    */}
    <Route
      exact
      path="/finance/sales-commission/invoices-by-salesperson"
      render={(props) => <SalepersonInvoiceView {...props} title="Invoices by Salesperson" />}
    />
    {/*
    * Commission Structure Iterations
    */}
    <Route
      exact
      path="/finance/sales-commission/commission-iterations"
      render={(props) => <CommissionStructureIterationsDataTablePage {...props} title="Commission Structure Iterations" />}
    />
    <Route // view manage individual iterations
      exact
      path={`/finance/sales-commission/commission-iterations/:id/${TAB_REGEX}`}
      render={(props) => (
        <RecordDetailPage {...props} title="Commission Structure Iterations" tableIdentifier={TABLE_IDENTIFIER.COMMISSION_STRUCTURE_ITERATIONS_TABLE} />
      )}
    />
    {/*
    * Commission Structure Brackets
    */}
    <Route
      exact
      path="/finance/sales-commission/commission-brackets"
      render={(props) => <CommissionStructureBracketsDataTablePage {...props} title="Commission Structure Brackets" />}
    />
    <Route // view manage individual brackets
      exact
      path={`/finance/sales-commission/commission-brackets/:id/${TAB_REGEX}`}
      render={(props) => (
        <RecordDetailPage {...props} title="Commission Structure Brackets" tableIdentifier={TABLE_IDENTIFIER.COMMISSION_STRUCTURE_BRACKETS_TABLE} />
      )}
    />
    {/*
    * Commission Payment Batches
    */}
    <Route
      exact
      path="/finance/sales-commission/payment-batches"
      render={(props) => <CommissionPaymentBatchesDataTablePage {...props} title="Commission Payment Batches" />}
    />
    <Route // view manage individual batches
      exact
      path={`/finance/sales-commission/payment-batches/:id/${TAB_REGEX}`}
      render={(props) => (
        <RecordDetailPage {...props} title="Commission Payment Batches" tableIdentifier={TABLE_IDENTIFIER.COMMISSION_PAYMENT_BATCHES_TABLE} />
      )}
    />
    {/*
    * Commission Snapshots
    */}
    <Route
      exact
      path="/finance/sales-commission/commission-snapshots"
      render={(props) => <MonthlyCommissionSnapshotDatatablePage {...props} title="Commission Snapshots" />}
    />
    {/*
    * Profitability Snapshots
    */}
    <Route
      exact
      path="/finance/sales-commission/profitability-snapshots"
      render={(props) => <ProjectProfitabilitySnapshotsDataTablePage {...props} title="Commission Snapshots" />}
    />
    {/*
    * Invoice Requests
    */}
    <Route
      exact
      path="/finance/invoice-requests"
      render={(props) => <InvoiceRequestDataTablePage {...props} title="Invoice Requests" />}
    />
    <Route // view manage individual expense claim
      exact
      path={`/finance/invoice-requests/:id/${TAB_REGEX}`}
      render={(props) => (
        <RecordDetailPage {...props} title="Invoice Requests" tableIdentifier={TABLE_IDENTIFIER.INVOICE_REQUESTS_TABLE} />
      )}
    />
    {/*
     * Leave
     */}
    <Route
      exact
      path="/finance/process-leave"
      render={(props) => <ProcessLeaveDataTablePage {...props} title="Process Leave" />}
    />
    <Route // view manage individual expense claim
      exact
      path={`/finance/process-leave/:id/${TAB_REGEX}`}
      render={(props) => (
        <RecordDetailPage {...props} title="Process Leave Claims" tableIdentifier={TABLE_IDENTIFIER.PROCESS_LEAVE_TABLE} />
      )}
    />
    {/*
     * Expense claims and batches
     */}
    <Route
      exact
      path="/finance/process-expenses/claims"
      render={(props) => <ProcessExpenseClaimsDataTablePage {...props} />}
    />
    <Route // view manage individual expense claim
      exact
      path={`/finance/process-expenses/claims/:id/${TAB_REGEX}`}
      render={(props) => (
        <RecordDetailPage
          {...props}
          title="Process Expense Claims"
          tableIdentifier={TABLE_IDENTIFIER.PROCESS_EXPENSE_CLAIMS_TABLE}
        />
      )}
    />
    <Route
      exact
      path="/finance/process-expenses/batches"
      render={(props) => <ProcessExpenseBatchesDataTablePage {...props} />}
    />
    <Route // view manage individual expense claim
      exact
      path={`/finance/process-expenses/batches/:id/${TAB_REGEX}`}
      render={(props) => (
        <RecordDetailPage
          {...props}
          title="Expense Claim Batches"
          tableIdentifier={TABLE_IDENTIFIER.PROCESS_EXPENSE_BATCHES_TABLE}
        />
      )}
    />
    {/*
     * Allowances
     */}
    <Route
      exact
      path="/finance/process-allowances"
      render={(props) => <ProcessAllowancesDataTablePage {...props} />}
    />
    <Route // view manage individual expense claim
      exact
      path={`/finance/process-allowances/:id/${TAB_REGEX}`}
      render={(props) => (
        <RecordDetailPage
          {...props}
          title="Process Allowance Claims"
          tableIdentifier={TABLE_IDENTIFIER.PROCESS_ALLOWANCES_TABLE}
        />
      )}
    />
    <Route exact path="*" component={FourOhFour} />
  </Switch>
);

FinanceRoutes.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default FinanceRoutes;
