import { AN_API_ACTION } from '../constants/api-action.const';
import { IActionButton } from '../types/action-button.interface';

/**
 * Check if an array of permitted action / buttons records has a given action key
 */
export const hasPermittedAction = (permittedActions: IActionButton[], actionKey: AN_API_ACTION): null | IActionButton => {
  if (permittedActions.length && actionKey) {
    for (let i = 0; i < permittedActions.length; i += 1) {
      if (permittedActions[i].name === actionKey) {
        return permittedActions[i];
      }
    }
  }
  return null;
};
