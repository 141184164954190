import React, { PropsWithChildren, useCallback } from 'react';
import classNames from 'classnames';

import { Alert, Button } from 'reactstrap';

import { APIRecord } from '../../types/api-record.interface';
import { CollectionWidgetProps } from '../../types/collection.widget.props';
import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';

import { APIPolyForm } from '../poly-forms/api-poly-form';
import Icon from '../layout-helpers/icon';
import { CollectionWidgetHeader } from './collection-widget-header';

import { hasPermittedAction } from '../../helpers/has-permitted-action.helper';
import { formatAlignClass, getFieldDisplayComponent } from '../render-functions';
import { getColumnFormatWidth } from '../../helpers/column-format-width.helper';

import { API_ACTION } from '../../constants/api-action.const';
import { ICON } from '../../constants/icon.const';
import { FORM_RENDERER_TYPE } from '../../constants/form-renderer-type.const';
import { InfoTooltip } from '../info-tooltip';

/**
 * CollectionWidget component
 */
export const CollectionWidget = <T extends APIRecord = APIRecord, >(
  props: PropsWithChildren<CollectionWidgetProps<T>>,
): React.ReactElement<PropsWithChildren<CollectionWidgetProps<T>>> => {
  const {
    children,
    name,
    className,
    itemCaption,
    statusMap,
    formRendererType = FORM_RENDERER_TYPE.TABLE_ROW,

    parentId,
    isLoading,
    loadingError,
    hasMorePages,

    collectionItemPrimaryKeyFieldName = 'id',
    reverseOrder,
    rowData = {},
    widgetData = [] as T[],
    widgetDataChecksums = {},
    fields = [],
    headings,
    totals,

    baseRoute,
    apiRoute,
    apiQuery,

    disableHeader,
    widgetPermittedActions,
    widgetItemPermittedActions,
    description,
    showAddBtn,
    formDeleteConfirmationType,

    isCreatingCollectionItem,
    isEditingCollectionItem,
    editingCollectionItemId,
    isReadOnly,

    sortWidgetRecords,

    loadNextPage,

    createCollectionItem,
    updateCollectionItem,
    onCanCreateCollectionItem,
    onCanStartEditCollectionItem,
    onStartEditCollectionItem,
    onCanEndEditCollectionItem,
    onEndEditCollectionItem,
    onCanDeleteCollectionItem,
    onConfirmDeleteCollectionItem,
    onDeleteCollectionItem,
    onCollectionItemChange,
    onCollectionItemClick,
  } = props;


  /**
   * Check if the user can create a new collection item
   */
  const canCreateNewCollectionItem = useCallback((): boolean => {
    if (onCanCreateCollectionItem) {
      return onCanCreateCollectionItem();
    }
    return true;
  }, [onCanCreateCollectionItem]);


  /**
   * Create a new collection item when triggered by the
   */
  const handleCreateCollectionItem = useCallback(() => {
    if (canCreateNewCollectionItem()) {
      if (createCollectionItem) {
        createCollectionItem();
      }
    }
  }, [canCreateNewCollectionItem, createCollectionItem]);


  /**
   * Fired when the user wants to delete a collection item
   *
   * @param id the primary key of the collection item the user is attempting to edit
   * @param formData the data of the collection item the user is attempting to edit
   */
  const handleDeleteCollectionItem = useCallback((id: FormFieldComponentProps['value'], formData: Partial<T>) => {
    if (onDeleteCollectionItem) {
      onDeleteCollectionItem(id, formData);
    }
  }, [onDeleteCollectionItem]);

  // Row Headings
  const rowHeadings = (
    <>
      {headings && headings.length > 0 && (
        <div className="portal-form datatable-inline-editor header-row" key={`${name}-headings`}>
          <div className="datatable-widget-line">
            <Button
              size="sm"
              color="secondary"
              // title="This is where we'd have 'select all' if the functionality was there"
              disabled
            >
              <Icon />
            </Button>
            {headings.map((field) => {
              const fieldWidth = getColumnFormatWidth(field);

              return (
                <div
                  field-type="CollectionWidget Column Header"
                  className="field-container"
                  key={`field-heading-${field.name}`}
                  style={{ minWidth: fieldWidth, width: fieldWidth }}
                >
                  <div title={field.description || field.title}>
                    <div
                      className={classNames(
                        'portal-data-field',
                        'header',
                        'not-editable',
                        'inline',
                        'disabled',
                        'font-weight-bold',
                        formatAlignClass(field.format),
                      )}
                    >
                      {field.icon && (
                        <Icon i={field.icon} />
                      )}
                      <span>{field.title}</span>
                      {field.formFieldToolTip && (
                        <InfoTooltip title={field.title} tabIndex={-1}>
                          {/* eslint-disable-next-line react/no-danger */}
                          <span dangerouslySetInnerHTML={{ __html: field.formFieldToolTip }} />
                        </InfoTooltip>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            {headings.length > 0 && (
              <>
                <Button size="sm" color="secondary" disabled><Icon /></Button>
                <Button size="sm" color="secondary" disabled><Icon /></Button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );

  // Totals Row
  const totalsRow = (
    <>
      {widgetData && totals && Object.keys(totals).length > 0 && (
        <div className="totals-row portal-form datatable-inline-editor">

          <div className="datatable-widget-line">
            <Button size="sm" color="secondary" title="Totals" disabled>
              <Icon i="toggle-right" />
            </Button>
            {isLoading && hasMorePages && (
              <div style={{ padding: '0.2rem' }}><Icon i="rolling" /></div>
            )}
            {!hasMorePages && fields && fields.map((field) => {
              if (field.showInForm === false || field.visible === false) return null;
              const fieldWidth = getColumnFormatWidth(field);

              return (
                <div
                  field-type="NotEditable"
                  className="field-container"
                  key={`rendered-field-${field.name}`}
                  style={{ minWidth: fieldWidth, width: fieldWidth }}
                >
                  <div title={totals[field.name] ? `${field.title} total` : ''}>
                    <div
                      className={classNames('portal-data-field', 'inline', field.format)}
                    >
                      <strong>
                        {totals[field.name] ? getFieldDisplayComponent(totals, field, true) : ''}
                      </strong>
                    </div>
                  </div>
                </div>
              );
            })}
            <Button size="sm" color="secondary" disabled><Icon /></Button>
            <Button size="sm" color="secondary" disabled><Icon /></Button>
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className={classNames('widget', 'collection-widget', className)}>
      {children}
      {!disableHeader && (
        <CollectionWidgetHeader
          description={description}

          // Only show the "create" button if
          // the user can "update" the parent resource,
          // AND there aren't any additional permissions
          // required of the user in actionPermissions
          // @todo: return here
          showAddBtn={
            // pass down setting from the collection widget definition
            showAddBtn &&

            !!hasPermittedAction(widgetPermittedActions, API_ACTION.CREATE)
          }

          // Prevent the user from adding another row if they are editing a row
          addBtnDisabled={isEditingCollectionItem || isCreatingCollectionItem || isReadOnly}

          // Pass the event handler back up the chain so that the widget wrapper can add a data row
          onClickAddBtn={handleCreateCollectionItem}
        />
      )}
      <div className="collection-widget-items-container">
        {loadingError && (
          <Alert color="danger">
            There was an error loading your data, please refresh your browser and try again.
          </Alert>
        )}
        <div className="collection-widget-items-inner">
          {rowHeadings}

          {/* Render a APIPolyForm for each item in the collection */}
          {((() => {
            if (reverseOrder) return [...widgetData].reverse();
            if (sortWidgetRecords) return [...widgetData].sort(sortWidgetRecords);
            return widgetData;
          })()).map((widgetDataRow) => (
            <APIPolyForm<T>
              key={`row-${widgetDataRow[collectionItemPrimaryKeyFieldName ?? 'id']}`}

              formRendererType={formRendererType}
              itemCaption={itemCaption}
              statusMap={statusMap}
              formDeleteConfirmationType={formDeleteConfirmationType}
              scrollToError={false}

              parentId={parentId}
              parentData={rowData}
              siblingData={widgetData}
              primaryKeyFieldName={collectionItemPrimaryKeyFieldName}
              fields={fields}
              formData={widgetDataRow}
              formDataChecksum={widgetDataChecksums[widgetDataRow[collectionItemPrimaryKeyFieldName ?? 'id'] as string | number] ?? undefined}

              baseRoute={baseRoute ?? ''}
              apiRoute={apiRoute}
              apiQuery={apiQuery}

              permittedActions={widgetItemPermittedActions}

              isReadOnly={isReadOnly || (isEditingCollectionItem && editingCollectionItemId !== widgetDataRow[collectionItemPrimaryKeyFieldName ?? 'id'])}
              isEditing={isEditingCollectionItem && editingCollectionItemId === widgetDataRow[collectionItemPrimaryKeyFieldName ?? 'id']}
              isNewRecord={isCreatingCollectionItem && editingCollectionItemId === widgetDataRow[collectionItemPrimaryKeyFieldName ?? 'id']}

              updateRecord={updateCollectionItem}

              onCanStartEditRecord={onCanStartEditCollectionItem}
              onStartEditRecord={onStartEditCollectionItem}
              onCanEndEditRecord={onCanEndEditCollectionItem}
              onEndEditRecord={onEndEditCollectionItem}
              onCanDeleteRecord={onCanDeleteCollectionItem}
              onDeleteRecord={handleDeleteCollectionItem}
              onConfirmDeleteRecord={onConfirmDeleteCollectionItem}

              onClick={onCollectionItemClick}
              onFormChange={onCollectionItemChange}
            />
          ))}

          {totalsRow}
        </div>
        {isLoading && (
          <div className="loading">
            <Icon i={ICON.LOADING} spaceRight spin />
            <span>Loading...</span>
          </div>
        )}
        {!isLoading && hasMorePages && loadNextPage && (
          <div className="load-more">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={() => loadNextPage(false)}>Load More</a>
            <span> | </span>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={() => loadNextPage(true)}>Load All</a>
          </div>
        )}
      </div>
    </div>
  );
};
