/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Button, Card, CardBody, CardText, Table } from 'reactstrap';
import { ICON } from '../../../constants/icon.const';
import { WidgetProps } from '../../../types/widget.props';
import Icon from '../../layout-helpers/icon';
import { CreateFolderWidgetSection } from './create-folder-widget-section';
import { FileRecordRow, IFileRecord } from './file-record-row';
import { FolderRecordRow } from './folder-record-row';

export type DriveFilesWidgetProps = Omit<WidgetProps, 'rowData' | 'widgetData'> & {
  rowData: {
    id: number,
    drive_id: string,
    drive_item_id: string,
    drive_item_url: string,
  }
  widgetData: IFileRecord[],
  baseRoute: string,
}

/**
 * This widget shows the checklist items which have been left out of the main form view.
 *
 * @todo convince the user form to do a patch instead of a PUT
 */
export const DriveFilesWidget:React.FC<DriveFilesWidgetProps> = (props) => {
  const { rowData, widgetData = [], refreshRecord, baseRoute, apiRoute, refreshWidgetData } = props;

  return (
    <Card className="drive-files-widget">
      {!rowData.drive_id ? (
        <CardBody>
          <h4>SharePoint Folder does not yet exist...</h4>
          <CreateFolderWidgetSection
            baseRoute={baseRoute}
            rowData={rowData}
            refreshRecord={refreshRecord}
          />
        </CardBody>
      ) : (
        <>
          <Table>
            <thead>
              <tr>
                <th>
                  <a rel="noreferrer" target="_blank" href={rowData.drive_item_url} style={{ color: '#FFF' }}>
                    <Icon i={ICON.EXTERNAL_LINK} className="pr-2" />
                    SharePoint Files
                  </a>
                </th>
                <th>View</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {widgetData.map((fileRecord) => {
                if (fileRecord.folder) {
                  return (
                    <FolderRecordRow
                      key={fileRecord.id}
                      fileRecord={fileRecord}
                      rowData={rowData}
                      refreshRecord={refreshRecord}
                      baseRoute={baseRoute}
                      apiRoute={apiRoute}
                      depth={0}
                    />
                  );
                }
                return (
                  <FileRecordRow key={fileRecord.id} fileRecord={fileRecord} depth={0} />
                );
              })}
            </tbody>
          </Table>
          <CardBody>
            <CardText>
              <Button onClick={refreshWidgetData} onKeyPress={refreshWidgetData}>
                <Icon i={ICON.REFRESH} className="pr-2" />
                Refresh
              </Button>
              {' '}
              {' '}
              <a rel="noreferrer" target="_blank" href={rowData.drive_item_url} className="pl-4">
                <Icon i={ICON.EXTERNAL_LINK} className="pr-2" />
                Click Here to Upload More Files
              </a>
            </CardText>
          </CardBody>
        </>
      )}
    </Card>
  );
};
