import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { Button } from 'reactstrap';

import { IProjectRecord } from '../../../types/project/project.record.interface';
import { ConfirmModalResult } from '../../../types/modal/modal-result';

import { ModalContext } from '../../modals/modal-context';
import { APIContext } from '../../providers/api-provider';

import Icon from '../../layout-helpers/icon';
import FriendlyFormMessage from '../../layout-helpers/friendly-form-message';

import { apiAborter } from '../../../helpers/api-aborter.helper';

import { A_PROJECT_STATUS, PROJECT_STATUS, PROJECT_STATUS_NAME_MAP } from '../../../constants/project-status.const';
import { MODAL_TYPE } from '../../../constants/modal-type.const';
import StatusBadge from '../../data-format/status-badge';


export type RegenerateProjectNumberProjectAdminWidgetSectionProps = {
  rowData: IProjectRecord,
  refreshRecord: () => void,
}

type RegenerateProjectNumberState = {
  isRegeneratingProjectNumber: boolean,
  lastRegenerateAttemptSuccess: null | boolean,
  lastRegenerateAttemptMessage: null | string,
}

/**
 * Render the project admin widget section that allows a user to regenerate the project number
 */
export const RegenerateProjectNumberProjectAdminWidgetSection:React.FC<RegenerateProjectNumberProjectAdminWidgetSectionProps> = (props) => {
  const {
    rowData,
    refreshRecord,
  } = props;

  const { apiFetch } = useContext(APIContext);
  const { showModal } = useContext(ModalContext);

  const {
    id: projectId,
    status_id: projectStatusId,
  } = rowData;

  const regeneratePNumberDisabled = ([PROJECT_STATUS.LEAD, PROJECT_STATUS.PROPOSAL] as A_PROJECT_STATUS[]).includes(projectStatusId);

  // Create a state for the regenerate project number
  const [regeneratingProjectNumberState, setRegeneratingProjectNumberState] = useState<RegenerateProjectNumberState>({
    isRegeneratingProjectNumber: false,
    lastRegenerateAttemptSuccess: null,
    lastRegenerateAttemptMessage: null,
  });
  const abortRegenerateProjectNumber = useRef<null | AbortController>(null);


  /**
   * Fired by the onConfirm action on the dialog after asking if they are sure
   */
  const handleRegenerateProjectNumber = useCallback(() => {
    setRegeneratingProjectNumberState({
      isRegeneratingProjectNumber: true,
      lastRegenerateAttemptSuccess: null,
      lastRegenerateAttemptMessage: null,
    });

    if (abortRegenerateProjectNumber.current) {
      abortRegenerateProjectNumber.current.abort();
    }
    abortRegenerateProjectNumber.current = apiAborter();

    // Call the API
    apiFetch(
      `/project/${projectId}/action/regenerateprojectnumber`,
      {
        method: HTTP_METHOD.POST,
        name: 'ProjectAdminWidget::regenerateProjectNumber',
        signal: abortRegenerateProjectNumber.current.signal,
      },
    ).then((response) => {
      if (response.success) {
        abortRegenerateProjectNumber.current = null;
        refreshRecord();
        setRegeneratingProjectNumberState({
          isRegeneratingProjectNumber: false,
          lastRegenerateAttemptSuccess: true,
          lastRegenerateAttemptMessage: 'Successfully regenerated P-Number.',
        });
      } else if (!response.aborted) {
        console.error('ProjectAdminWidget::regenerateProjectNumber', response.error);
        abortRegenerateProjectNumber.current = null;
        setRegeneratingProjectNumberState({
          isRegeneratingProjectNumber: false,
          lastRegenerateAttemptSuccess: false,
          lastRegenerateAttemptMessage: `Failed to regenerate P-Number: ${(response.body && response.body.message) || '(Unknown Error)'}`,
        });
      }
    });
  }, [apiFetch, projectId, abortRegenerateProjectNumber, setRegeneratingProjectNumberState, refreshRecord]);


  /**
   * Fired when the user clicks on "Regenerate Project Number".
   * The user must confirm the action via a modal dialog
   */
  const handleRegenerateClick = () => {
    const modalContent = (
      <>
        <p>
          <strong>Warning!</strong>
        </p>
        <p>
          <span>You are about to re-generate the P-Number for this project based on the current </span>
          <strong>status</strong>
          <span>, </span>
          <strong>sales owner</strong>
          <span> and </span>
          <strong>state</strong>
          <span>.</span>
        </p>
        <p>
          <span>
            This may impact any physical documentation relating to this project (invoices, quotes etc...) which make reference to the existing P-Number.
          </span>
        </p>
        <p>
          <strong>Be very certain that you want to perform this action.</strong>
        </p>
      </>
    );

    // Display the confirmation modal
    showModal<ConfirmModalResult>(MODAL_TYPE.CONFIRM, {
      title: 'Regenerate Project Number',
      color: 'warning',
      content: modalContent,
      confirmButtonColor: 'warning',
      confirmButtonLabel: 'Regenerate',
      confirmButtonIcon: 'refresh',
      onModalComplete: ({ success }) => {
        if (success) handleRegenerateProjectNumber();
      },
    });
  };


  /**
   * (ComponentWillMount)
   */
  useEffect(() => () => {
    // (ComponentWillUnMount)
    if (abortRegenerateProjectNumber.current) abortRegenerateProjectNumber.current.abort();
  }, []);


  return (
    <div className="function-section">
      <h4>Re-Generate Project Number</h4>
      <p>Sometimes the original P-Number may become invalid. Use this button to re-generate the P-Number.</p>

      {/* Disabled Info */}
      {regeneratePNumberDisabled && (
        <p className="text-muted">
          <Icon i="info-circle" />
          <span> Only Projects that are not Leads or Proposals can have their P-Numbers regenerated. (Current status: </span>
          <StatusBadge status={PROJECT_STATUS_NAME_MAP[projectStatusId]} short={false} />
          <span>)</span>
        </p>
      )}

      {/* Regenerate Button */}
      <p>
        <Button
          color="primary"
          disabled={regeneratePNumberDisabled || regeneratingProjectNumberState.isRegeneratingProjectNumber}
          onClick={handleRegenerateClick}
        >
          <Icon
            isBusy={regeneratingProjectNumberState.isRegeneratingProjectNumber}
            i="refresh"
          />
          <span>Re-Generate P-Number</span>
        </Button>
      </p>

      {/* Regenerate Action Result */}
      {!regeneratingProjectNumberState.isRegeneratingProjectNumber && regeneratingProjectNumberState.lastRegenerateAttemptMessage && (
        <div>
          <FriendlyFormMessage
            formMessage={regeneratingProjectNumberState.lastRegenerateAttemptMessage}
            alertColor={regeneratingProjectNumberState.lastRegenerateAttemptSuccess ? 'success' : 'danger'}
            useSimpleDefault
          />
        </div>
      )}

    </div>
  );
};
