import React, { useCallback, useContext } from 'react';
import moment from 'moment';

import { CurrentUserContext } from '../providers/current-user-provider';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { IAllowanceRecord } from '../../types/allowance/allowance.record.interface';
import { NewRecordFormProps } from '../../types/poly-form/new-record-form.props';

import { APIPolyForm } from './api-poly-form';

import { mapTableToForm } from '../../helpers/map-table-to-form.helper';

import myAllowancesTableInitialSettings from '../../table-definitions/my-allowances-table';
import { DATE_FORMAT } from '../../constants/date-format.const';
import { API_ACTION } from '../../constants/api-action.const';

const formFields = mapTableToForm(myAllowancesTableInitialSettings);

export const NewAllowanceForm: React.FC<NewRecordFormProps<IAllowanceRecord>> = (props) => {
  const {
    onFormComplete, onFormChange, initialData,
  } = props;
  const { userDetails } = useContext(CurrentUserContext);

  /**
   * Fired when the APIPolyForm successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, id: FormFieldComponentProps['value'], newData?: Partial<IAllowanceRecord>) => {
    if (onFormComplete) onFormComplete(success, id as IAllowanceRecord['id'], (newData as IAllowanceRecord));
  }, [onFormComplete]);

  // Render
  return (
    <APIPolyForm<IAllowanceRecord>
      {...myAllowancesTableInitialSettings}
      fields={formFields}
      formData={{
        user_id: userDetails.id,
        user: userDetails,
        approver_id: userDetails.directManager?.id ?? null,
        approver: userDetails.directManager,
        start_date: moment().format(DATE_FORMAT.YEAR_MONTH_DAY_DASHES),
        end_date: moment().format(DATE_FORMAT.YEAR_MONTH_DAY_DASHES),
        ...(initialData ?? {}),
      }}
      permittedActions={myAllowancesTableInitialSettings.possibleActions.filter((action) => (action.name === API_ACTION.CREATE))}
      onFormComplete={handleFormComplete}
      onFormChange={onFormChange}
      isNewRecord
      isEditing
    >
      <p>This will create your Allowance Claim. Once created, review it carefully before submitting it to your manager.</p>
      <hr />
    </APIPolyForm>
  );
};
