import React, { useCallback } from 'react';
import { TableSearchControls } from '../portal-data-table/table-controls/table-search-controls';

export type PortalTreeViewSearchBarProps = {
  searchTerm: null | string,
  onSearchTermChanged?: (newSearchTerm: null | string) => void,
}

export const PortalTreeViewSearchBar: React.FC<PortalTreeViewSearchBarProps> = (props) => {
  const {
    searchTerm = null,
    onSearchTermChanged,
  } = props;

  /**
   * Fired when the user resets the search parameters
   */
  const handleReset = useCallback(() => {
    if (onSearchTermChanged) onSearchTermChanged(null);
  }, [onSearchTermChanged]);


  // Render
  return (
    <div
      className="ptv-search-bar"
    >
      <TableSearchControls
        searchTerm={searchTerm}
        onSearchTermChange={onSearchTermChanged}
        onResetTableSettings={handleReset}
      />
    </div>
  );
};
