import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../layout-helpers/icon';

const NavHeading = (props) => {
  const {
    onClick, active, open, icon, children,
  } = props;
  return (
    <a
      href="/"
      onClick={onClick}
      className={classNames('has-arrow', {
        active: active === 'temporary falseness',
      })}
      aria-expanded={open}
    >
      {icon && <Icon i={icon} />}
      <span>{children}</span>
    </a>
  );
};

NavHeading.defaultProps = {
  children: null,
  icon: null,
  active: false,
  open: false,
};

NavHeading.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  icon: PropTypes.string,
  active: PropTypes.bool,
  open: PropTypes.bool,
};

export default NavHeading;
