import React, { useCallback } from 'react';

import { ICompanySpaceTypeRecord } from '../../types/company/company-space-type.record.interface';
import { NewRecordModalProps } from '../../types/modal/new-record-modal.props';

import { useIsDirty } from '../../react-hooks/use-is-dirty.hook';

import { Modal } from './Modal';
import { NewCompanySpaceTypeForm } from '../poly-forms/new-company-space-type-form';
import { ICON } from '../../constants/icon.const';

export const NewCompanySpaceTypeModal: React.FC<NewRecordModalProps<ICompanySpaceTypeRecord>> = (props) => {
  const {
    id, closeModal, onModalComplete, initialData,
  } = props;

  const [isDirty, setDirty] = useIsDirty();

  /**
   * Fired when the form successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, recordId: ICompanySpaceTypeRecord['id'], newRecord?: ICompanySpaceTypeRecord) => {
    closeModal(id);
    onModalComplete((success && newRecord) ? { success, newRecord } : { success: false, newRecord: null });
  }, [closeModal, id, onModalComplete]);

  return (
    <Modal
      {...props}
      title="Create a new Company Space"
      icon={ICON.SPACE}
      allowDismiss
      isDirty={isDirty}
      size="lg"
    >
      <NewCompanySpaceTypeForm
        onFormComplete={handleFormComplete}
        onFormChange={setDirty}
        initialData={initialData}
      />
    </Modal>
  );
};
