import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useDragOver } from '@minoru/react-dnd-treeview';
import classNames from 'classnames';

import Highlighter from 'react-highlight-words';
import Icon from '../layout-helpers/icon';

import { AN_ICON } from '../../constants/icon.const';
import { A_PORTAL_TREE_VIEW_TREE_LINE_TYPE, PortalTreeViewTreeLineTypeClassMap, PORTAL_TREE_VIEW_TREE_LINE_TYPE } from './portal-tree-view-tree-line-type.const';

export type PortalTreeViewNodeProps = {
  className?: string,
  id: string,
  parentId: string,
  allowDrag: boolean,
  label: string,
  depth: number,
  childCount: number,
  displayIndex: number,
  siblingCount: number,
  treeLineDisplayFlags: Array<A_PORTAL_TREE_VIEW_TREE_LINE_TYPE>,
  isOpen: boolean,
  isActive: boolean,
  isRootNodeVisible: boolean,
  icon?: AN_ICON,
  searchTerm?: null | string,

  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void,
  onToggleOpen: (id: string | number) => void,
  onContextMenu?: (e: React.MouseEvent<HTMLDivElement>) => void,
}

export const PortalTreeViewNode: React.FC<PortalTreeViewNodeProps> = (props) => {
  const {
    label,
    id,
    parentId,
    allowDrag,
    className,
    childCount,
    displayIndex,
    siblingCount,
    treeLineDisplayFlags,
    depth,
    isOpen,
    isActive,
    isRootNodeVisible,
    icon,
    searchTerm,

    onClick,
    onToggleOpen,
    onContextMenu,
  } = props;

  const [treeLines, setTreeLines] = useState<ReactNode[]>([]);
  const dragOverProps = useDragOver(id, isOpen, onToggleOpen);

  const isFirstSibling = useMemo(() => (siblingCount > 1) && (displayIndex === 0), [displayIndex, siblingCount]);
  const isLastSibling = useMemo(() => (siblingCount > 1) && (displayIndex === siblingCount - 1), [displayIndex, siblingCount]);
  const isMiddleSibling = useMemo(() => (siblingCount > 1) && !isLastSibling && !isFirstSibling, [siblingCount, isLastSibling, isFirstSibling]);

  /**
   * Update the divs used to display the tree and branch lines
   */
  useEffect(() => {
    const newTreeLines: ReactNode[] = [];

    // For each of the treeLineDisplayFlags
    treeLineDisplayFlags
      // Iterate over each of the remaining tree line display flags
      .forEach((treeLineDisplayFlag, depthIndex) => {
        // Calculate the display depth
        // @Note: this renders in the reverse direction as expected from right to left away from the node
        const displayDepth = depthIndex;

        // Push a tree line component for rendering
        if (treeLineDisplayFlag !== PORTAL_TREE_VIEW_TREE_LINE_TYPE.NONE) {
          newTreeLines.push((
            <div
              key={`${id}_${displayDepth}`}
              className={`ptv-tl ${PortalTreeViewTreeLineTypeClassMap[treeLineDisplayFlag]}`}
              style={{
                '--depth': displayDepth,
              } as React.CSSProperties}
            />
          ));
        }
      });

    setTreeLines(newTreeLines);
  }, [treeLineDisplayFlags, depth, id, parentId, isRootNodeVisible, siblingCount, displayIndex]);


  // Render
  return (
    <div
      className={classNames('ptv-node', className, {
        active: isActive,
        open: (childCount > 0) && isOpen,
        fs: isFirstSibling,
        ms: isMiddleSibling,
        ls: isLastSibling,
        // Only show an additional connector (in addition to the tree lines) if the root node is hidden and this node is at level 1
        'show-connector': (!isRootNodeVisible && depth === 1),
      })}
      onContextMenu={onContextMenu}
      {...dragOverProps}

      // This doesn't work and can't figure out how to stop it from allowing the node to drag
      draggable={allowDrag}

      // This work around isn't fool-proof but it will help until the `allowDrag` property can be properly implemented
      onDragStart={allowDrag ? undefined : (e) => { e.preventDefault(); e.stopPropagation(); }}
    >
      {/* Node Tree Lines */}
      {depth > 0 && (
        <div className="ptv-tl-wrapper">
          { treeLines }
        </div>
      )}

      {/* Node Icon (if provided) */}
      {icon && (
        <Icon i={icon} />
      )}

      {/* Node Text */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <span
        className="ptv-l"
        onClick={onClick}
      >
        {searchTerm && (
          <Highlighter
            highlightTag="span"
            highlightClassName="highlight-text"
            searchWords={[searchTerm]}
            textToHighlight={label}
          />
        )}
        {!searchTerm && label}
        {/* {`${label} [${displayIndex + 1}/${siblingCount} (D: ${depth} ${JSON.stringify(treeLineDisplayFlags)})]`} */}
      </span>

      {/* Render a Chevron if the node has children */}
      {(childCount > 0) && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div
          className={classNames('chevron', {
            down: isOpen,
          })}
          onClick={() => {
            if (onToggleOpen) {
              onToggleOpen(id);
            } }}
        />
      )}
    </div>
  );
};
