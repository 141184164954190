export const BUTTON_COLOR = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  DARK: 'dark',
  PURPLE: 'purple',
  GREEN: 'green',
  TRANSPARENT: 'transparent',
} as const;
export type BUTTON_COLOR = typeof BUTTON_COLOR;
export type A_BUTTON_COLOR = BUTTON_COLOR[keyof BUTTON_COLOR];
