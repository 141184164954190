import { useState, useCallback } from 'react';

/**
 * Effectively force a Functional Component to re-render
 * @see https://stackoverflow.com/a/55862077
 */
export function useForceUpdate():() => void {
  const [, setTick] = useState(0);
  return useCallback(() => {
    setTick((tick) => tick + 1);
  }, []);
}
