import React from 'react';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { IDataTableRecord } from '../../../types/portal-data-table/portal-data-table-record.interface';
import { InMemoryTable } from '../../portal-data-table/in-memory-table';
import { getEddUpdatedLink } from './eta-reporting-utilities';

interface IEtaAgeByDateTableProps {
  recordData: IDataTableRecord[],
  projectId?: number,
  projectManagerId?: string | number | null,
}

export const EtaAgeByDateTable: React.FC<IEtaAgeByDateTableProps> = ({ recordData, projectId, projectManagerId }) => (
  <InMemoryTable
    title="Item Counts by ETA Last Updated Date"
    data={recordData}
    columns={[{
      title: 'Updated Date',
      name: 'edd_last_updated',
      format: COLUMN_FORMAT.DATE,
    }, {
      title: 'Items Updated',
      name: 'items',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: (row: IDataTableRecord) => getEddUpdatedLink(true, row.edd_last_updated, projectId, projectManagerId),
      filterType: 'number',
    }]}
    paginate
    narrow
  />
);
