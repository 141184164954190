import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * <ProgressBar />
 *
 * @example
 * <ProgressBar />
 * // > loads forever
 *
 * <ProgressBar complete={some.bool} />
 * // > Loads until some.bool is true and then animates to 100% and fades away
 *
 * <ProgressBar complete={some.bool} error={some.otherBool} />
 * // > Error state
 *
 * <ProgressBar progress={some.percentage} />
 * // > Show progress
 *
 * <ProgressBar tiny />
 * // > Smaller one
 */
class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: props.start,
      wait: props.wait,
      step: props.step,
    };
  }

  // <ProgressBar /> mounted
  componentDidMount() {
    const { progress: propsProgress, complete: propsComplete } = this.props;
    const { wait } = this.state;
    if (propsProgress === null) {
      this.timerID = setInterval(() => {
        const { progress, step } = this.state;
        // Prevent progress bar from "updating" after complete
        if (propsComplete) {
          clearInterval(this.timerID);
        }
        else {
          if (progress + step >= 90) {
            clearInterval(this.timerID);
          }
          this.setState((oldState) => ({
            ...oldState,
            progress: progress + step,
            step: step > 1 ? step - 1 : 1,
          }));
        }
      }, wait);
    }
  }

  // <ProgressBar /> unmounted
  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  // Renders <ProgressBar />
  render() {
    const {
      tiny, error, complete, progress,
    } = this.props;
    const { progress: stateProgress } = this.state;

    let progressPercentage = progress !== null ? progress : stateProgress;
    if (complete) progressPercentage = 100;

    const style = {
      width: `${progressPercentage}%`,
    };

    // Prevent the progress transition from "bouncing" back to 0%;
    if (progressPercentage === 0) style.transition = 'none';

    return (
      <div className={classnames('progress', { tiny, complete })}>
        <div
          className={classnames('progress-bar', 'active', 'animate', {
            'progress-bar-striped': progressPercentage !== 100,
            'bg-danger': error,
          })}
          style={style}
          role="progressbar"
          aria-valuenow={progressPercentage}
        >
          &nbsp;
        </div>
      </div>
    );
  }
}

ProgressBar.defaultProps = {
  start: 0,
  wait: 500,
  progress: null,
  step: 10,
  tiny: false,
  error: false,
  complete: false,
};

ProgressBar.propTypes = {
  start: PropTypes.number,
  wait: PropTypes.number,
  progress: PropTypes.number,
  step: PropTypes.number,
  tiny: PropTypes.bool,
  error: PropTypes.bool,
  complete: PropTypes.bool,
};

export default ProgressBar;
