import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Col } from 'reactstrap';
import QuickBooksPanel from './quickbooks-panel';
import DtoolsPanel from './dtools-panel';
import KeyPayPanel from './keypay-panel';
import TsheetsPanel from './tsheets-panel';
import PageHeader from '../../app-layout/page-header';
import { DToolsUserList } from '../../dtools-information/d-tools-user-list';
import { TsheetsClassCosting } from './tsheets-class-costing';
import { documentTitle } from '../../../utils/helpers';
import MicrosoftGraphPanel from './microsoft-graph-panel';
import { connectToAPIProvider } from '../../providers/api-provider';
import API_PROVIDER_PROP_TYPES from '../../../prop-types/api-provider-prop-types';
import { DtoolsUserSaturation } from '../../dtools-information/dtools-user-saturation';
import ZendeskPanel from './zendesk-panel';
import MyobPanel from './myob-panel';

const apiPath = process.env.REACT_APP_API;
class AppIntegrationManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quickbooks: {
        loading: true,
        connected: false,
      },
      dtools: {
        loading: true,
        connected: false,
      },
      cidtools: {
        loading: true,
        connected: false,
      },
      keypay: {
        loading: true,
        connected: false,
      },
      tsheets: {
        loading: true,
        connected: false,
      },
      zendesk: {
        loading: true,
        connected: false,
      },
      microsoftGraph: {
        loading: true,
        connected: false,
      },
      myobAdvanced: {
        loading: true,
        connected: false,
      },
    };

    const { title } = this.props;
    documentTitle(title || null);
  }


  /**
   * @inheritdoc
   */
  componentDidMount() {
    this.getAsyncStatus('quickbooks-status', 'quickbooks');
    this.getAsyncStatus('ci-dtools-status', 'cidtools');
    this.getAsyncStatus('dtools-status', 'dtools');
    this.getAsyncStatus('keypay-status', 'keypay');
    this.getAsyncStatus('tsheets-status', 'tsheets');
    this.getAsyncStatus('microsoft-graph-status', 'microsoftGraph');
    this.getAsyncStatus('zendesk-status', 'zendesk');
    this.getAsyncStatus('myob-advanced-status', 'myobAdvanced');
  }


  /**
   *
   */
  getAsyncStatus = (route, component) => {
    const { apiProvider: { apiFetch } } = this.props;

    apiFetch(
      `/integration/${route}`,
      {
        // signal: // TODO: Abort Controller?
        name: `AppIntegrationManager:getAsyncStatus:${route}`,
      },
    ).then((response) => {
      if (response.success) {
        this.setState({
          [component]: response.body,
        });
      } else if (!response.aborted) {
        this.setState({
          [component]: {
            connected: false,
            message: `${response.error ?? 'A server error occurred.'}`,
          },
        });
      }
    });
  };


  /**
   *
   */
  bindConnectHandler = (route) => {
    const w = 800;
    const h = 730;
    let authWindow;
    const windowSettings = [
      'resizable=no',
      'toolbar=no',
      'scrollbars=no',
      'menubar=no',
      'status=no',
      'directories=no',
      `width=${w}`,
      `height=${h}`,
    ].join(',');
    return () => window.open(`${apiPath}${route}`, authWindow, windowSettings);
  };


  /**
   * @inheritdoc
   */
  render() {
    const {
      quickbooks, dtools, cidtools, keypay, tsheets, zendesk, microsoftGraph, myobAdvanced,
    } = this.state;
    return (
      <Container fluid>
        <PageHeader title="API Integrations" />
        <Row>
          <Col md="12">
            <DtoolsUserSaturation />
          </Col>
        </Row>
        <Row>
          <MicrosoftGraphPanel
            status={microsoftGraph}
            connectHandler={this.bindConnectHandler('microsoft-graph-connector', 'microsoftGraph')}
          />
          <QuickBooksPanel
            status={quickbooks}
            connectHandler={this.bindConnectHandler('quickbooks-connector', 'quickbooks')}
          />
          <KeyPayPanel status={keypay} connected={keypay.connected} />
          <ZendeskPanel
            status={zendesk}
            connectHandler={this.bindConnectHandler('zendesk-connector', 'zendesk')}
          />
          <MyobPanel
            status={myobAdvanced}
            connectHandler={this.bindConnectHandler('myob-advanced-connector', 'myobAdvanced')}
          />
          <TsheetsPanel
            status={tsheets}
            connectHandler={this.bindConnectHandler('tsheets-connector', 'tsheets')}
          />
          <TsheetsClassCosting />
        </Row>
        <Row>
          <DtoolsPanel status={{ dtools, cidtools }} />
          <Col md="12" lg="12" xl="12">
            <DToolsUserList />
          </Col>
        </Row>
      </Container>
    );
  }
}

AppIntegrationManager.propTypes = {
  title: PropTypes.string,
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
};

AppIntegrationManager.defaultProps = {
  title: 'API Integration',
};

export default connectToAPIProvider(AppIntegrationManager);
