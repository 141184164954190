import { IPortalTreeViewNodeData } from '../../types/portal-tree-view/portal-tree-view-node-data.interface';
import { PortalTreeViewNodeItem } from '../../types/portal-tree-view/portal-tree-view-node-item';
import { portalTreeViewNodeIsChildOfParent } from './portal-tree-view-node-is-child-of-parent.helper';

/**
 * Locate a node within a portal tree view.
 *
 * @param tree the tree data to search
 * @param targetNodeId the ID of the node to locate
 * @param parentNode an optional parent to search within
 *
 * @Returns undefined if not found otherwise returns the node
 */
export const findPortalTreeViewNode = <T extends IPortalTreeViewNodeData>(
  tree: PortalTreeViewNodeItem<T>[],
  targetNodeId: number | string,
  parentNode?: PortalTreeViewNodeItem<T>,
): undefined | PortalTreeViewNodeItem<T> => {
  // Looking for the child of a node
  if (parentNode) {
    const nodesInScope = tree.filter((node) => portalTreeViewNodeIsChildOfParent(tree, node, parentNode.id));

    // Only look for the node under the specified parent node
    return nodesInScope.find((node) => node.id === targetNodeId) ?? undefined;
  }

  // Just Looking for a node in the whole tree
  return tree.find((node) => node.id === targetNodeId) ?? undefined;
};
