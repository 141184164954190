import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, Button, Alert } from 'reactstrap';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import Icon from '../../layout-helpers/icon';
import { ProjectDToolsMatchRow } from './project-dtools-match-row';
import { ProjectDtoolsLinkRow } from './project-dtools-link-row';
import { connectToAPIProvider } from '../../providers/api-provider';
import API_PROVIDER_PROP_TYPES from '../../../prop-types/api-provider-prop-types';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import { buildAPIRoute } from '../../../helpers/build-api-route.helper';

class ProjectDToolsProjectsWidgetComponent extends React.Component {
  /**
   * @constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      dtoolsMatches: [],
      linkedRows: props.widgetData || [],
      matchesLoading: false,
      loading: props.loading,
      linkLoadError: props.loadingError,
      matchLoadError: false,
      // linking: false,
      // unlinking: false,
      openRowId: null,
    };

    this.linkDtoolsProjectAbortController = null;
    this.unlinkDtoolsProjectAbortController = null;
    this.loadDtoolsMatchesAbortController = null;
    this.toggleMasterAbortController = null;
  }


  /**
   * @inheritdoc
   */
  shouldComponentUpdate = (newProps) => {
    const { widgetData } = this.props;
    if (newProps.widgetData !== widgetData && typeof newProps.widgetData === 'object') {
      this.loadDtoolsMatches();
      this.setState({
        linkedRows: newProps.widgetData,
      });
      return false;
    }
    return true;
  };


  /**
   * @inheritdoc
   */
  componentWillUnmount = () => {
    if (this.linkDtoolsProjectAbortController) this.linkDtoolsProjectAbortController.abort();
    if (this.unlinkDtoolsProjectAbortController) this.unlinkDtoolsProjectAbortController.abort();
    if (this.loadDtoolsMatchesAbortController) this.loadDtoolsMatchesAbortController.abort();
    if (this.toggleMasterAbortController) this.toggleMasterAbortController.abort();
  }


  /**
   *  Load D-Tools project records which match the P-Number
   */
  loadDtoolsMatches = () => {
    if (this.loadDtoolsMatchesAbortController) {
      this.loadDtoolsMatchesAbortController.abort();
    }

    this.setState({
      matchesLoading: true,
    }, () => {
      const asyncLoadDtoolsMatches = async () => {
        this.loadDtoolsMatchesAbortController = apiAborter();

        const { apiProvider: { apiFetch }, baseRoute, widgetData, parentId } = this.props;

        const response = await apiFetch(
          buildAPIRoute({
            parentId,
            baseRoute,
            apiRoute: '/dtoolsmatch',
          }),
          {
            name: 'DToolsProjects:loadDtoolsMatches',
            signal: this.loadDtoolsMatchesAbortController.signal,
          },
        );

        if (response.success) {
          this.loadDtoolsMatchesAbortController = null;
          const { data } = response.body;
          if (data) {
            const clonedLinkData = (widgetData || []).map((linkedItem) => {
              const itemMatch = data.filter((dtoolsItem) => linkedItem.dtools_id === dtoolsItem.Id)[0] || {};
              itemMatch.linked = true;
              return {
                ...linkedItem,
                ...itemMatch,
                dtoolsLoaded: true,
              };
            });
            this.setState({
              dtoolsMatches: data,
              linkedRows: clonedLinkData,
              matchesLoading: false,
            });
          }
        } else if (!response.aborted) {
          this.loadDtoolsMatchesAbortController = null;
          console.error(response.error);
          // TODO: some form of error state?
          this.setState({
            dtoolsMatches: null,
            matchLoadError: true,
            matchesLoading: false,
          });
        }
      };

      asyncLoadDtoolsMatches();
    });
  };

  /**
   *  Toggles the d-tools detail view for the clicked row
   */
  toggleOpenRow = (rowData) => {
    const dtoolsId = rowData.dtools_id || rowData.Id;
    const { openRowId } = this.state;

    if (openRowId === dtoolsId) {
      return this.setState({
        openRowId: null,
      });
    }
    // this.loadDtoolsSummary(dtoolsId);
    return this.setState({ openRowId: rowData.dtools_id || rowData.Id });
  };


  /**
   *  Adds a project-dtools-link record for target record
   */
  linkProject = (rowData) => {
    const asyncLinkProject = async () => {
      if (this.linkDtoolsProjectAbortController) {
        this.linkDtoolsProjectAbortController.abort();
      }
      this.linkDtoolsProjectAbortController = apiAborter();

      const { apiProvider: { apiFetch }, baseRoute, parentId } = this.props;

      const response = await apiFetch(
        `${baseRoute}/${parentId}/dtoolslink`,
        {
          name: 'DtoolsProjects:linkProject',
          method: HTTP_METHOD.POST,
          signal: this.linkDtoolsProjectAbortController.signal,
          body: {
            dtools_name: rowData.Name,
            dtools_pnumber: rowData.Number,
            dtools_id: rowData.Id,
          },
        },
      );

      if (response.success) {
        this.linkDtoolsProjectAbortController = null;
        if (response.body.data && response.body.data.id) {
          const { linkedRows, dtoolsMatches } = this.state;
          const newLinkedRows = [...linkedRows];
          const newMatchRows = [...dtoolsMatches];
          const linkMatch = newMatchRows.filter((matchRow) => matchRow.Id === rowData.Id)[0] || {};
          linkMatch.linked = true;
          newLinkedRows.push({
            ...rowData,
            ...response.body.data,
            dtoolsLoaded: true,
          });
          this.setState({
            linkedRows: newLinkedRows,
            dtoolsMatches: newMatchRows,
          });
        }
      } else if (!response.aborted) {
        // TODO: error state?
        this.linkDtoolsProjectAbortController = null;
        console.error('Failed to link project to DTools Project', response.error);
      }
    };
    asyncLinkProject();
  };


  /**
   *  Deletes a project-dtools-link record
   */
  unlinkProject = (rowData) => {
    const asyncUnlinkProject = async () => {
      if (this.unlinkDtoolsProjectAbortController) {
        this.unlinkDtoolsProjectAbortController.abort();
      }
      this.unlinkDtoolsProjectAbortController = apiAborter();

      const { baseRoute, apiProvider: { apiFetch }, parentId } = this.props;

      const response = await apiFetch(
        `${baseRoute}/${parentId}/dtoolslink/${rowData.id}`,
        {
          name: 'DToolsProjects:unlinkProject',
          signal: this.unlinkDtoolsProjectAbortController.signal,
          method: HTTP_METHOD.DELETE,
        },
      );

      if (response.success) {
        this.unlinkDtoolsProjectAbortController = null;
        const { linkedRows, dtoolsMatches } = this.state;
        const newLinkedRows = linkedRows.filter((linkRow) => linkRow.id !== rowData.id);
        const newMatchRows = [...dtoolsMatches];
        const linkMatch = newMatchRows.filter((matchRow) => matchRow.Id === rowData.dtools_id)[0] || {};
        linkMatch.linked = false;
        this.setState({
          linkedRows: newLinkedRows,
          dtoolsMatches: newMatchRows,
        });
      } else if (!response.aborted) {
        // TODO: error state?
        this.unlinkDtoolsProjectAbortController = null;
        console.error('Failed to unlink project from DTools Project', response.error);
      }
    };
    asyncUnlinkProject();
  };


  /**
   *  Toggles the satus of the dtools record as the referenced 'master project' in D-Tools
   */
  toggleMaster = (dtoolsId) => {
    const asyncToggleMaster = async () => {
      const { apiProvider: { apiFetch }, baseRoute, refreshRecord, parentId } = this.props;

      const { rowData: { dtools_id: existingDToolsId } } = this.props;

      if (this.toggleMasterAbortController) {
        this.toggleMasterAbortController.abort();
      }
      this.toggleMasterAbortController = apiAborter();

      const response = await apiFetch(
        `${baseRoute}/${parentId}`,
        {
          name: 'DToolsProjects:toggleMaster',
          method: HTTP_METHOD.PATCH,
          signal: this.toggleMasterAbortController.signal,
          body: {
            dtools_id: existingDToolsId === dtoolsId ? null : dtoolsId,
          },
        },
      );

      if (response.success) {
        this.toggleMasterAbortController = null;
        refreshRecord();
      } else if (!response.aborted) {
        // TODO: error condition?
        this.toggleMasterAbortController = null;
        console.error('Failed to unlink project from DTools Project', response.error);
      }
    };

    asyncToggleMaster();
  };


  /**
   * @inheritdoc
   */
  render = () => {
    const {
      linkedRows,
      loading,
      matchesLoading,
      dtoolsMatches,
      openRowId,
      linkLoadError,
      matchLoadError,
    } = this.state;
    const { rowData } = this.props;
    const projectData = rowData; // alias this to avoid confusion
    return (
      <div className="widget dtools-projects-widget">
        <Card>
          <CardHeader>
            <h4>
              Linked Projects:
              <Button color="info" onClick={this.loadDtoolsMatches} size="sm" className="pull-right">
                <Icon i="search" isBusy={matchesLoading} />
                &nbsp; Find Matches
              </Button>
            </h4>
          </CardHeader>
          {(loading || !linkedRows) && (
            <div className="loading">
              <CardBody>
                &nbsp; &nbsp;
                {' '}
                <Icon i="rolling" />
              </CardBody>
            </div>
          )}
          {linkLoadError && (
            <Alert color="danger">
              Error Loading Links
            </Alert>
          )}
          {matchLoadError && (
            <Alert color="danger">
              Error Loading Matches
            </Alert>
          )}
          <table className="table table-striped color-table muted-table wrap-normal">
            <thead>
              <tr>
                <th>Number</th>
                <th>Name</th>
                <th>Progress</th>
                <th>Approved</th>
                <th>Price</th>
                <th>Match Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {linkedRows &&
                linkedRows.map((dtoolsRowData) => (
                  <ProjectDtoolsLinkRow
                    key={dtoolsRowData.dtools_id}
                    rowData={dtoolsRowData}
                    projectData={projectData}
                    matchesLoading={matchesLoading}
                    matchLoadError={matchLoadError}
                    toggleOpenRow={this.toggleOpenRow}
                    unlinkProject={this.unlinkProject}
                    openRowId={openRowId}
                    toggleMaster={this.toggleMaster}
                  />
                ))}
              {dtoolsMatches &&
                dtoolsMatches
                  .filter((match) => !match.linked)
                  .map((dtoolsRowData) => (
                    <ProjectDToolsMatchRow
                      key={dtoolsRowData.Id}
                      rowData={dtoolsRowData}
                      projectData={projectData}
                      matchesLoading={matchesLoading}
                      toggleOpenRow={this.toggleOpenRow}
                      linkProject={this.linkProject}
                      openRowId={openRowId}
                      toggleMaster={this.toggleMaster}
                    />
                  ))}
            </tbody>
          </table>
        </Card>
      </div>
    );
  };
}

ProjectDToolsProjectsWidgetComponent.defaultProps = {
  widgetData: [],
  loading: false,
  loadingError: false,
};

ProjectDToolsProjectsWidgetComponent.propTypes = {
  widgetData: PropTypes.arrayOf(PropTypes.shape({})),
  rowData: PropTypes.shape({
    id: PropTypes.number,
    dtools_id: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool,
  loadingError: PropTypes.bool,
  baseRoute: PropTypes.string.isRequired,
  refreshRecord: PropTypes.func.isRequired,
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
  parentId: PropTypes.number.isRequired,
};

export const ProjectDToolsProjectsWidget = connectToAPIProvider(ProjectDToolsProjectsWidgetComponent);
