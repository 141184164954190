import { IWidgetDefinition } from '../types/widget-definition.interface';

import { A_WIDGET_DEFINITION, WIDGET_DEFINITION } from './widget-definition.const';

import { AvailableLeaveWidgetDefinition } from '../widget-definitions/available-leave.widget-definition';
import { CompanyContactsWidgetDefinition } from '../widget-definitions/company-contacts.widget-definition';
import { CompanyContractsWidgetDefinition } from '../widget-definitions/company-contracts.widget-definition';
import { CompanyDashboardsWidgetDefinition } from '../widget-definitions/company-dashboards.widget-definition';
import { CompanyLocationsWidgetDefinition } from '../widget-definitions/company-locations.widget-definition';
import { CompanyNotesWidgetDefinition } from '../widget-definitions/company-notes.widget-definition';
import { ContactDashboardsWidgetDefinition } from '../widget-definitions/contact-dashboards.widget-definition';
import { ContractContractSubtypesWidgetDefinition } from '../widget-definitions/contract-contract-subtypes.widget-definition';
import { ContractInvoiceRequestsWidgetDefinition } from '../widget-definitions/contract-invoice-requests.widget-definition';
import { ContractNoteWidgetDefinition } from '../widget-definitions/contract-note.widget-definition';
import { ContractQuickbooksWidgetDefinition } from '../widget-definitions/contract-quickbooks.widget-definition';
import { ContractScheduledChecksWidgetDefinition } from '../widget-definitions/contract-scheduled-checks.widget-definition';
import { ContractSerialsWidgetDefinition } from '../widget-definitions/contract-serials.widget.definition';
import { ContractSpacesImportWidgetDefinition } from '../widget-definitions/contract-spaces-import.widget-definition';
import { ContractSpacesWidgetDefinition } from '../widget-definitions/contract-spaces.widget.definition';
import { ContractTypeSubtypesWidgetDefinition } from '../widget-definitions/contract-type-subtypes.widget-definition';
import { DriveFilesWidgetDefinition } from '../widget-definitions/drive-files.widget-definition';
import { DtoolsItemNotesWidgetDefinition } from '../widget-definitions/dtools-item-notes.widget.definition';
import { DtoolsLiveInfoWidgetDefinition } from '../widget-definitions/dtools-live-info.widget-definition';
import { EventTrackingWidgetDefinition } from '../widget-definitions/event-tracking.widget-definition';
import { ExpenseBatchExpenseClaimsWidgetDefinition } from '../widget-definitions/expense-batch-expense-claims.widget-definition';
import { ExpenseBatchItemSummaryWidgetDefinition } from '../widget-definitions/batch-items.widget-definition';
import { ExpenseClaimFilesWidgetDefinition } from '../widget-definitions/expense-claim-files.widget-definition';
import { ExpenseClaimItemsSummaryWidgetDefinition } from '../widget-definitions/expense-claim-item-summary.widget-definition';
import { ExpenseClaimItemsWidgetDefinition } from '../widget-definitions/expense-claim-items.widget-definition';
import { ExpenseClaimNotesWidgetDefinition } from '../widget-definitions/expense-claim-notes.widget-definition';
import { InternalProjectCardWidgetDefinition } from '../widget-definitions/internal-project-card-widget-definition';
import { InternalProjectDetailsWidgetDefinition } from '../widget-definitions/internal-project-details.widget-definition';
import { InternalProjectNoteWidgetDefinition } from '../widget-definitions/internal-project-note.widget-definition';
import { InternalProjectStakeholdersWidgetDefinition } from '../widget-definitions/internal-project-stakeholders.widget-definition';
import { InternalProjectsWidgetDefinition } from '../widget-definitions/internal-projects.widget-definition';
import { InternalProjectTasksWidgetDefinition } from '../widget-definitions/internal-project-tasks.widget-definition';
import { InvoiceBillingInfoWidgetDefinition } from '../widget-definitions/invoice-billing-info.widget-definition';
import { InvoiceRequestAttachmentsWidgetDefinition } from '../widget-definitions/invoice-request-attachments.widget-definition';
import { InvoiceRequestLinesWidgetDefinition } from '../widget-definitions/invoice-request-lines.widget-definition';
import { InvoiceRequestNotesWidgetDefinition } from '../widget-definitions/invoice-request-notes.widget-definition';
import { InvoiceRequestQuickbooksWidgetDefinition } from '../widget-definitions/invoice-request-quickbooks.widget-definition';
import { LeaveApplicationFilesWidgetDefinition } from '../widget-definitions/leave-application-files.widget-definition';
import { LeaveApplicationNotesWidgetDefinition } from '../widget-definitions/leave-application-notes.widget-definition';
import { LeaveBlocksWidgetDefinition } from '../widget-definitions/leave-blocks.widget-definition';
import { NameAndDescriptionOnlyWidgetDefinition } from '../widget-definitions/name-and-description-only.widget-definition';
import { ProjectAccountingWidgetDefinition } from '../widget-definitions/project-quickbooks.widget-definition';
import { ProjectAdminWidgetDefinition } from '../widget-definitions/project-admin.widget-definition';
import { ProjectChecklistWidgetDefinition } from '../widget-definitions/project-checklist.widget-definition';
import { ProjectClientOrdersWidgetDefinition } from '../widget-definitions/project-client-orders.widget-definition';
import { ProjectCompaniesWidgetDefinition } from '../widget-definitions/project-companies.widget-definition';
import { ProjectContactsWidgetDefinition } from '../widget-definitions/project-contacts.widget-definition';
import { ProjectContractsWidgetDefinition } from '../widget-definitions/project-contracts.widget-definition';
import { ProjectDriveWidgetDefinition } from '../widget-definitions/project-drive.widget-definition';
import { ProjectDtoolsChangeOrderWidgetDefinition } from '../widget-definitions/project-dtools-change-orders.widget-definition';
import { ProjectDtoolsProjectsWidgetDefinition } from '../widget-definitions/project-dtools-project.widget-definition';
import { ProjectForecastsWidgetDefinition } from '../widget-definitions/project-forecasts.widget-definition';
import { ProjectInvoiceRequestsWidgetDefinition } from '../widget-definitions/project-invoice-requests.widget-definition';
import { ProjectInvoicesWidgetDefinition } from '../widget-definitions/project-invoices.widget-definition';
import { ProjectItemEtaSummaryWidgetDefinition } from '../widget-definitions/project-item-eta-summary.widget-definition';
import { ProjectLocationsWidgetDefinition } from '../widget-definitions/project-locations.widget-definition';
import { ProjectMyobOrdersWidgetDefinition } from '../widget-definitions/project-myob-orders.widget-definition';
import { ProjectNotesWidgetDefinition } from '../widget-definitions/project-notes.widget-definition';
import { ProjectProfitabilityWidgetDefinition } from '../widget-definitions/project-profitability.widget-definition';
import { ProjectQuickbooksOrdersWidgetDefinition } from '../widget-definitions/project-quickbooks-orders.widget-definition';
import { ProjectResourcesWidgetDefinition } from '../widget-definitions/project-resources.widget-definition';
import { ProjectsWidgetDefinition } from '../widget-definitions/projects.widget-definition';
import { RowDetailsWidgetDefinition } from '../widget-definitions/row-details.widget-definition';
import { SecurityPermissionRolesWidgetDefinition } from '../widget-definitions/security-permission-role.widget-definition';
import { SecurityPermissionUserWidgetDefinition } from '../widget-definitions/security-permission-user.widget-definition';
import { SecurityRolePermissionWidgetDefinition } from '../widget-definitions/security-role-permissions.widget-definition';
import { SecurityRoleUserWidgetDefinition } from '../widget-definitions/security-role-user.widget-definition';
import { ServiceJobInvoiceRequestsWidgetDefinition } from '../widget-definitions/service-job-invoice-requests.widget-definition';
import { ServiceJobQuickbooksWidgetDefinition } from '../widget-definitions/service-job-quickbooks.widget-definition';
import { SprintSwimlanesWidgetDefinition } from '../widget-definitions/sprint-swimlanes.widget-definition';
import { SprintTasksWidgetDefinition } from '../widget-definitions/sprint-tasks.widget-definition';
import { TypeContractsWidgetDefinition } from '../widget-definitions/type-contracts.widget-definition';
import { UserNotificationsWidgetDefinition } from '../widget-definitions/user-notifications.widget-definition';
import { UserMyobInfoWidgetDefinition } from '../widget-definitions/user-myob-info.widget-definition';
import { UserRolesWidgetDefinition } from '../widget-definitions/user-roles.widget-definition';
import { ZendeskTicketDetailsWidgetDefinition } from '../widget-definitions/zendesk-ticket-details.widget-definition';
import { UserSalesTargetsWidgetDefinition } from '../widget-definitions/user-sales-targets.widget-definition';
import { CommissionStructureBracketsWidgetDefinition } from '../widget-definitions/commission-structure-bracket.widget-definition';
import { ProjectCommissionInformationWidgetDefinition } from '../widget-definitions/project-commission-information.widget-definition';
import { ProjectCommissionPaymentsWidgetDefinition } from '../widget-definitions/project-commission-payments.widget-definition';
import { CommissionBatchRateAuditWidgetDefinition } from '../widget-definitions/commission-batch-rate-audit.widget-definition';
import { CommissionPaymentSummaryWidgetDefinition } from '../widget-definitions/commission-payment-summary.widget-definition';
import { CommissionPaymentForecastWidgetDefinition } from '../widget-definitions/commission-payment-forecast.widget-definition';
import { CommissionPaymentDetailWidgetDefinition } from '../widget-definitions/commission-payment-detail.widget-definition';
import { CommissionPaymentForecastDetailWidgetDefinition } from '../widget-definitions/commission-payment-forecast-detail.widget-definition';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const WidgetDefinitionMap: { [K in A_WIDGET_DEFINITION]: IWidgetDefinition } = {
  [WIDGET_DEFINITION.AVAILABLE_LEAVE]: AvailableLeaveWidgetDefinition,
  [WIDGET_DEFINITION.COMMISSION_STRUCTURE_BRACKETS]: CommissionStructureBracketsWidgetDefinition,
  [WIDGET_DEFINITION.COMMISSION_BATCH_RATE_AUDIT]: CommissionBatchRateAuditWidgetDefinition,
  [WIDGET_DEFINITION.COMMISSION_PAYMENT_DETAIL]: CommissionPaymentDetailWidgetDefinition,
  [WIDGET_DEFINITION.COMMISSION_PAYMENT_SUMMARY]: CommissionPaymentSummaryWidgetDefinition,
  [WIDGET_DEFINITION.COMMISSION_PAYMENT_FORECAST]: CommissionPaymentForecastWidgetDefinition,
  [WIDGET_DEFINITION.COMMISSION_PAYMENT_FORECAST_DETAIL]: CommissionPaymentForecastDetailWidgetDefinition,
  [WIDGET_DEFINITION.COMPANY_CONTACTS]: CompanyContactsWidgetDefinition,
  [WIDGET_DEFINITION.COMPANY_CONTRACTS]: CompanyContractsWidgetDefinition,
  [WIDGET_DEFINITION.COMPANY_DASHBOARDS]: CompanyDashboardsWidgetDefinition,
  [WIDGET_DEFINITION.COMPANY_LOCATIONS]: CompanyLocationsWidgetDefinition,
  [WIDGET_DEFINITION.COMPANY_NOTES]: CompanyNotesWidgetDefinition,
  [WIDGET_DEFINITION.CONTACT_DASHBOARDS]: ContactDashboardsWidgetDefinition,
  [WIDGET_DEFINITION.CONTRACT_CONTRACT_SUBTYPES]: ContractContractSubtypesWidgetDefinition,
  [WIDGET_DEFINITION.CONTRACT_INVOICE_REQUESTS]: ContractInvoiceRequestsWidgetDefinition,
  [WIDGET_DEFINITION.CONTRACT_NOTE]: ContractNoteWidgetDefinition,
  [WIDGET_DEFINITION.CONTRACT_QUICKBOOKS]: ContractQuickbooksWidgetDefinition,
  [WIDGET_DEFINITION.CONTRACT_SCHEDULED_CHECKS]: ContractScheduledChecksWidgetDefinition,
  [WIDGET_DEFINITION.CONTRACT_SERIALS]: ContractSerialsWidgetDefinition,
  [WIDGET_DEFINITION.CONTRACT_SPACES_IMPORT]: ContractSpacesImportWidgetDefinition,
  [WIDGET_DEFINITION.CONTRACT_SPACES]: ContractSpacesWidgetDefinition,
  [WIDGET_DEFINITION.CONTRACT_TYPE_SUBTYPES]: ContractTypeSubtypesWidgetDefinition,
  [WIDGET_DEFINITION.DRIVE_FILES]: DriveFilesWidgetDefinition,
  [WIDGET_DEFINITION.DTOOLS_ITEM_NOTES]: DtoolsItemNotesWidgetDefinition,
  [WIDGET_DEFINITION.DTOOLS_LIVE_INFO]: DtoolsLiveInfoWidgetDefinition,
  [WIDGET_DEFINITION.EVENT_TRACKING]: EventTrackingWidgetDefinition,
  [WIDGET_DEFINITION.EXPENSE_BATCH_EXPENSE_CLAIMS]: ExpenseBatchExpenseClaimsWidgetDefinition,
  [WIDGET_DEFINITION.EXPENSE_BATCH_ITEM_SUMMARY]: ExpenseBatchItemSummaryWidgetDefinition,
  [WIDGET_DEFINITION.EXPENSE_CLAIM_FILES]: ExpenseClaimFilesWidgetDefinition,
  [WIDGET_DEFINITION.EXPENSE_CLAIM_ITEM_SUMMARY]: ExpenseClaimItemsSummaryWidgetDefinition,
  [WIDGET_DEFINITION.EXPENSE_CLAIM_ITEMS]: ExpenseClaimItemsWidgetDefinition,
  [WIDGET_DEFINITION.EXPENSE_CLAIM_NOTES]: ExpenseClaimNotesWidgetDefinition,
  [WIDGET_DEFINITION.INTERNAL_PROJECT_CARD]: InternalProjectCardWidgetDefinition,
  [WIDGET_DEFINITION.INTERNAL_PROJECT_DETAILS]: InternalProjectDetailsWidgetDefinition,
  [WIDGET_DEFINITION.INTERNAL_PROJECT_NOTE]: InternalProjectNoteWidgetDefinition,
  [WIDGET_DEFINITION.INTERNAL_PROJECT_STAKEHOLDERS]: InternalProjectStakeholdersWidgetDefinition,
  [WIDGET_DEFINITION.INTERNAL_PROJECT_TASKS]: InternalProjectTasksWidgetDefinition,
  [WIDGET_DEFINITION.INTERNAL_PROJECTS]: InternalProjectsWidgetDefinition,
  [WIDGET_DEFINITION.INVOICE_BILLING_INFO]: InvoiceBillingInfoWidgetDefinition,
  [WIDGET_DEFINITION.INVOICE_REQUEST_ATTACHMENTS]: InvoiceRequestAttachmentsWidgetDefinition,
  [WIDGET_DEFINITION.INVOICE_REQUEST_LINES]: InvoiceRequestLinesWidgetDefinition,
  [WIDGET_DEFINITION.INVOICE_REQUEST_NOTES]: InvoiceRequestNotesWidgetDefinition,
  [WIDGET_DEFINITION.INVOICE_REQUEST_QUICKBOOKS]: InvoiceRequestQuickbooksWidgetDefinition,
  [WIDGET_DEFINITION.LEAVE_APPLICATION_FILES]: LeaveApplicationFilesWidgetDefinition,
  [WIDGET_DEFINITION.LEAVE_APPLICATION_NOTES]: LeaveApplicationNotesWidgetDefinition,
  [WIDGET_DEFINITION.LEAVE_BLOCKS]: LeaveBlocksWidgetDefinition,
  [WIDGET_DEFINITION.NAME_AND_DESCRIPTION_ONLY]: NameAndDescriptionOnlyWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_ACCOUNTING]: ProjectAccountingWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_ADMIN]: ProjectAdminWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_CHECKLIST]: ProjectChecklistWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_CLIENT_ORDERS]: ProjectClientOrdersWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_COMMISSION_INFORMATION]: ProjectCommissionInformationWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_COMMISSION_PAYMENTS]: ProjectCommissionPaymentsWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_COMPANIES]: ProjectCompaniesWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_CONTACTS]: ProjectContactsWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_CONTRACTS]: ProjectContractsWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_DRIVE]: ProjectDriveWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_DTOOLS_CHANGE_ORDERS]: ProjectDtoolsChangeOrderWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_DTOOLS_PROJECT]: ProjectDtoolsProjectsWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_FORECASTS]: ProjectForecastsWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_INVOICE_REQUESTS]: ProjectInvoiceRequestsWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_INVOICES]: ProjectInvoicesWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_ITEM_ETA_SUMMARY]: ProjectItemEtaSummaryWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_LOCATIONS]: ProjectLocationsWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_MYOB_ORDERS]: ProjectMyobOrdersWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_NOTES]: ProjectNotesWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_PROFITABILITY]: ProjectProfitabilityWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_QUICKBOOKS_ORDERS]: ProjectQuickbooksOrdersWidgetDefinition,
  [WIDGET_DEFINITION.PROJECT_RESOURCES]: ProjectResourcesWidgetDefinition,
  [WIDGET_DEFINITION.PROJECTS]: ProjectsWidgetDefinition,
  [WIDGET_DEFINITION.ROW_DETAILS]: RowDetailsWidgetDefinition,
  [WIDGET_DEFINITION.SECURITY_PERMISSION_ROLE]: SecurityPermissionRolesWidgetDefinition,
  [WIDGET_DEFINITION.SECURITY_PERMISSION_USER]: SecurityPermissionUserWidgetDefinition,
  [WIDGET_DEFINITION.SECURITY_ROLE_PERMISSIONS]: SecurityRolePermissionWidgetDefinition,
  [WIDGET_DEFINITION.SECURITY_ROLE_USER]: SecurityRoleUserWidgetDefinition,
  [WIDGET_DEFINITION.SERVICE_JOB_INVOICE_REQUESTS]: ServiceJobInvoiceRequestsWidgetDefinition,
  [WIDGET_DEFINITION.SERVICE_JOB_QUICKBOOKS]: ServiceJobQuickbooksWidgetDefinition,
  [WIDGET_DEFINITION.SPRINT_SWIMLANES]: SprintSwimlanesWidgetDefinition,
  [WIDGET_DEFINITION.SPRINT_TASKS]: SprintTasksWidgetDefinition,
  [WIDGET_DEFINITION.TYPE_CONTRACTS]: TypeContractsWidgetDefinition,
  [WIDGET_DEFINITION.USER_NOTIFICATIONS]: UserNotificationsWidgetDefinition,
  [WIDGET_DEFINITION.USER_ROLES]: UserRolesWidgetDefinition,
  [WIDGET_DEFINITION.USER_MYOB_INFO]: UserMyobInfoWidgetDefinition,
  [WIDGET_DEFINITION.USER_SALES_TARGETS]: UserSalesTargetsWidgetDefinition,
  [WIDGET_DEFINITION.ZENDESK_TICKET_DETAILS]: ZendeskTicketDetailsWidgetDefinition,
};
