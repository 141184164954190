import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { NewRecordModalResult } from '../../types/modal/modal-result';
import { IUserRecord } from '../../types/user/user.record.interface';

import { ModalContext } from '../modals/modal-context';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { DataTablePageProps } from '../../types/data-table-page.props';

export type UserManagementDataTablePageProps = DataTablePageProps;

/**
 * Display the "Users" data table as a whole page
 */
export const UserManagementDataTablePage: React.FC<UserManagementDataTablePageProps> = ({
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const history = useHistory();
  const { showModal } = useContext(ModalContext);

  const actionHandlers = {
    create: () => {
      showModal<NewRecordModalResult<IUserRecord>>(MODAL_TYPE.NEW_USER, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/settings/user-management/${modalResult.newRecord.id}`);
        },
      });
    },
  };

  return (
    <DataTablePage
      title="Manage Users"
      tableIdentifier={TABLE_IDENTIFIER.USER_MANAGEMENT_TABLE}
      actionHandlers={actionHandlers}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      {children}
    </DataTablePage>
  );
};
