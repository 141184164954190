import { IStateRecord } from '../types/state.record.interface';

export const STORE_API_QUERY_CACHE = 'STORE_API_QUERY_CACHE';

export type ApiQueryCacheData = {
  lastRefresh: string | null,
  expiresInMinutes?: number,
  queryResponse: {
    data: IStateRecord[],
   } | null,
}

export type StoreApiQueryCacheAction = {
  type: 'STORE_API_QUERY_CACHE',
  queryName: string,
  queryCacheData: ApiQueryCacheData,
}

export const storeApiQueryCache = (queryName: string, queryCacheData: ApiQueryCacheData): StoreApiQueryCacheAction => ({
  type: STORE_API_QUERY_CACHE,
  queryName,
  queryCacheData,
});
