import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 * <list /> Returns JSX unordered list from array
 * @example
 * <List items={['Foo', 'Bar']} />
 * // => <ul>
 * //      <li>Foo</li>
 * //      <li>Bar</li>
 * //    </ul>
 *
 * <List items={['Foo']} multipleOnly />
 * // => Foo
 *
 * <List items={['Yag']} multipleOnly={false} />
 * // => <ul>
 * //      <li>Yag</li>
 * //    </ul>
 */
const List = ({ items, multipleOnly }) => {
  if (typeof items !== 'undefined' && items.length > 0) {
    if (multipleOnly && items.length === 1) {
      return <>{items[0]}</>;
    }
    const listArray = [];
    let i = 0;
    Object.keys(items).forEach((key) => {
      listArray.push(<li key={`LiItem-${i}`}>{items[key]}</li>);
      i += 1;
    });
    return <ul>{listArray}</ul>;
  }
  return null;
};

List.defaultProps = {
  items: [],
  multipleOnly: false,
};

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  multipleOnly: PropTypes.bool,
};

export default List;
