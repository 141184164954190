import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { APIContext } from '../../providers/api-provider';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import { buildAPIRoute } from '../../../helpers/build-api-route.helper';
import Icon from '../../layout-helpers/icon';
import { IUserAudit } from '../../../types/user-audit.interface';
import { CheckBox } from '../../form-input/checkbox';

export type DToolsLabourSummaryRecord = {
  COStatus: string | null,
  PhaseName: string | null,
  PhaseOrder: string | null,
  TotalProductCost: string | null,
  TotalProductPrice: string | null,
  TotalLaborHours: string | null,
  TotalLaborCost: string | null,
  TotalLaborPrice: string | null,
  TotalInstallationPrice: string | null,
}

export type TSheetsLabourClassHoursRecord = {
  id: number,
  class_id: number,
  class_name: string,
  hours: string,
  t_sheets_labour_class_costing?: {
    estimated_hourly_cost: number,
  },
  user_audit: IUserAudit,
}

type TSheetsScheduleRecord = {
  class_name: string,
  minutes: string,
}

type ClassData = DToolsLabourSummaryRecord & {
  name: string | number | null,
  type: string | number | null,
  tSheetsScheduledHours: number,
  tSheetsTeamLoggedHours: number,
  tSheetsContractorLoggedHours: number,
  totalLoggedHours: number,
  dToolsQuotedHours: number,
  dToolsPendingHours: number,
}

type SideBySideLabourAnalysisProps = {
  parentId: number,
  baseRoute: string,
  rowData: {
    dtools_id: string,
  }
};

export const SideBySideLabourAnalysis: React.FC<SideBySideLabourAnalysisProps> = ({ parentId, baseRoute, rowData }) => {
  const dtoolsId = rowData.dtools_id || null;
  const [isLoadingDtoolsSummary, setIsLoadingDtoolsSummary] = useState(false);
  const [isLoadingTsheetsHours, setIsLoadingTsheetsHours] = useState(false);
  const [isLoadingSchedule, setIsLoadingSchedule] = useState(false);
  const [dToolsData, setDtoolsData] = useState<DToolsLabourSummaryRecord[] | null>(null);
  const [tsheetsLabourData, setTsheetsLabourData] = useState<TSheetsLabourClassHoursRecord[] | null>(null);
  const [tsheetsScheduleData, setTsheetsScheduleData] = useState<TSheetsScheduleRecord[] | null>(null);
  const [localProjectId, setLocalProjectId] = useState(parentId);
  const [showPendingCoValues, setShowPendingCoValues] = useState(0);
  const [totals, setTotals] = useState({
    dtoolsPending: 0,
    dtoolsQuote: 0,
    tsheetsSchedule: 0,
    contractorLabour: 0,
    teamLabour: 0,
    totalLabour: 0,
  });

  const { apiFetch } = useContext(APIContext);
  const labourAbortController = apiAborter();
  const scheduleAbortController = apiAborter();
  const dtoolsAbortController = apiAborter();

  const [mappedClassData, setMappedClassData] = useState<ClassData[]>([]);

  const mapData = useCallback((
    newDtoolsData: DToolsLabourSummaryRecord[],
    newTsheetsLabourData: TSheetsLabourClassHoursRecord[],
    newTsheetsScheduleData: TSheetsScheduleRecord[],
  ) => {
    const classData: ClassData[] = [];
    let tSheetsLabourTotal = 0;
    let tSheetsContractorLabourTotal = 0;
    let tSheetsTeamLabourTotal = 0;
    newTsheetsLabourData.map((tSheetsRecord) => {
      const isContractorHours = (tSheetsRecord.class_name.indexOf(' (Contractor)') > -1);
      let type = tSheetsRecord.class_name.replace(' (Contractor)', '');
      if (type === 'Rough In') {
        type = 'Rough-In';
      }
      // get D-Tools Values
      const dToolsMatchRecord = newDtoolsData.filter((dToolsRecord) => ((dToolsRecord.PhaseName || 'UNCLASSIFIED') === type))[0] || null;
      const dToolsHours = parseFloat(dToolsMatchRecord?.TotalLaborHours || '0');
      const dToolsQuotedHours = dToolsMatchRecord?.COStatus === 'Approved' ? dToolsHours : 0;
      const dToolsPendingHours = dToolsMatchRecord?.COStatus === 'Pending' ? dToolsHours : 0;
      // find an existing match?
      const existingRecord = classData.filter((classRecord) => classRecord.type === type)[0] || null;
      const numericHours = parseFloat(tSheetsRecord.hours);
      // add T-Sheets Detail
      tSheetsLabourTotal += numericHours;
      tSheetsContractorLabourTotal += isContractorHours ? numericHours : 0;
      tSheetsTeamLabourTotal += isContractorHours ? 0 : numericHours;
      if (existingRecord && isContractorHours) {
        existingRecord.tSheetsContractorLoggedHours = numericHours;
        existingRecord.totalLoggedHours += numericHours;
        existingRecord.dToolsQuotedHours = dToolsQuotedHours;
        existingRecord.dToolsPendingHours = dToolsPendingHours;
        return true;
      }
      if (existingRecord && !isContractorHours) {
        existingRecord.tSheetsTeamLoggedHours = numericHours;
        existingRecord.totalLoggedHours += numericHours;
        existingRecord.dToolsQuotedHours = dToolsQuotedHours;
        existingRecord.dToolsPendingHours = dToolsPendingHours;
        return true;
      }
      if (!existingRecord) {
        classData.push({
          ...dToolsMatchRecord,
          name: tSheetsRecord.class_name,
          type,
          tSheetsScheduledHours: 0,
          tSheetsTeamLoggedHours: isContractorHours ? 0 : numericHours,
          tSheetsContractorLoggedHours: isContractorHours ? numericHours : 0,
          totalLoggedHours: numericHours,
          dToolsQuotedHours,
          dToolsPendingHours,
        });
      }
      return true;
    });

    // map any remaining D-tools Records
    let dtoolsQuoteTotal = 0;
    let dtoolsPendingTotal = 0;
    newDtoolsData.map((dToolsRecord) => {
      const usePhaseName = dToolsRecord?.PhaseName || 'UNCLASSIFIED';
      const existingRecord = classData.filter((classRecord) => classRecord.type === usePhaseName)[0] || null;
      if (dToolsRecord.PhaseName === 'Total For Project' || dToolsRecord.PhaseName === 'Total Pending') {
        return false;
      }
      const dToolsHours = parseFloat(dToolsRecord?.TotalLaborHours || '0');
      const dToolsQuotedHours = dToolsRecord?.COStatus === 'Approved' ? dToolsHours : 0;
      const dToolsPendingHours = dToolsRecord?.COStatus === 'Pending' ? dToolsHours : 0;
      dtoolsQuoteTotal += dToolsQuotedHours;
      dtoolsPendingTotal += dToolsPendingHours;
      if (existingRecord) {
        // existingRecord.dToolsQuotedHours = dToolsQuotedHours;
        existingRecord.dToolsPendingHours = dToolsPendingHours;
      }
      if (!existingRecord) {
        classData.push({
          ...dToolsRecord,
          name: usePhaseName,
          type: usePhaseName,
          tSheetsScheduledHours: 0,
          tSheetsTeamLoggedHours: 0,
          tSheetsContractorLoggedHours: 0,
          totalLoggedHours: 0,
          dToolsQuotedHours,
          dToolsPendingHours,
        });
      }
      return true;
    });

    // Schedule
    let tSheetsScheduleTotal = 0;
    newTsheetsScheduleData.map((tSheetsRecord) => {
      let type = tSheetsRecord.class_name.replace(' (Contractor)', '');
      if (type === 'Rough In') {
        type = 'Rough-In';
      }
      if (type === 'No Category') {
        type = 'UNCLASSIFIED';
      }
      const dToolsMatchRecord = newDtoolsData?.filter((dToolsRecord) => (dToolsRecord.PhaseName === type))[0] || null;
      const existingRecord = classData.filter((classRecord) => classRecord.type === type)[0] || null;
      const numericHours = Math.ceil(parseFloat(tSheetsRecord.minutes) / 60);
      tSheetsScheduleTotal += numericHours;
      if (existingRecord) {
        existingRecord.tSheetsScheduledHours = numericHours;
        return true;
      }
      if (!existingRecord) {
        classData.push({
          ...dToolsMatchRecord,
          name: tSheetsRecord.class_name,
          type,
          tSheetsScheduledHours: numericHours,
          tSheetsTeamLoggedHours: 0,
          tSheetsContractorLoggedHours: 0,
          totalLoggedHours: 0,
          dToolsQuotedHours: 0,
          dToolsPendingHours: 0,
        });
      }
      return existingRecord;
    });

    setMappedClassData(classData);
    setTotals({
      dtoolsQuote: dtoolsQuoteTotal,
      dtoolsPending: dtoolsPendingTotal,
      teamLabour: tSheetsTeamLabourTotal,
      contractorLabour: tSheetsContractorLabourTotal,
      totalLabour: tSheetsLabourTotal,
      tsheetsSchedule: tSheetsScheduleTotal,
    });
  }, []);

  const loadFullData = useCallback(async (projectId, projectDtoolsId) => {
    setIsLoadingTsheetsHours(true);
    setIsLoadingSchedule(true);

    apiFetch(
      buildAPIRoute({
        parentId: projectId,
        baseRoute,
        apiRoute: '/tsheetsclasshours',
        apiQuery: 'with[]=tSheetsLabourClassCosting:class_id,estimated_hourly_cost',
      }),
      {
        name: 'TSheetsLabourSummary:getTSheetsSummary',
        signal: labourAbortController.signal,
      },
    ).then((response) => {
      setTsheetsLabourData(response.body?.data || []);
      setIsLoadingTsheetsHours(false);
    });

    if (projectDtoolsId) {
      setIsLoadingDtoolsSummary(true);
      apiFetch(
        `/dtools-connector/project/${projectDtoolsId}/summary`,
        {
          name: 'SideBySideLabourTable:getDtoolsSummary',
          signal: dtoolsAbortController.signal,
        },
      ).then((response) => {
        setDtoolsData(response.body?.data || []);
        setIsLoadingDtoolsSummary(false);
      });
    } else {
      setDtoolsData([]);
    }

    apiFetch(
      buildAPIRoute({
        parentId: projectId,
        baseRoute,
        apiRoute: '/tsheets-schedule-minutes',
      }),
      {
        name: 'TSheetsScheduleSummary:getTSheetsSummary',
        signal: scheduleAbortController.signal,
      },
    ).then((response) => {
      setTsheetsScheduleData(response.body?.data || []);
      setIsLoadingSchedule(false);
    });

    return () => {
      if (labourAbortController) {
        labourAbortController.abort();
      }
      if (scheduleAbortController) {
        scheduleAbortController.abort();
      }
      if (dtoolsAbortController) {
        dtoolsAbortController.abort();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseRoute, dtoolsId]);

  /**
   * Get the t-sheets data
   */
  useEffect(() => {
    loadFullData(parentId, dtoolsId);
    return () => {
      if (labourAbortController) {
        labourAbortController.abort();
      }
      if (scheduleAbortController) {
        scheduleAbortController.abort();
      }
      if (dtoolsAbortController) {
        dtoolsAbortController.abort();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId, baseRoute, dtoolsId]);

  /**
   * Process the data
   */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    console.log(dToolsData, tsheetsLabourData, tsheetsScheduleData);
    if (dToolsData && tsheetsLabourData && tsheetsScheduleData) {
      mapData(dToolsData, tsheetsLabourData, tsheetsScheduleData);
      return () => {
        if (labourAbortController) {
          labourAbortController.abort();
        }
        if (scheduleAbortController) {
          scheduleAbortController.abort();
        }
        if (dtoolsAbortController) {
          dtoolsAbortController.abort();
        }
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapData, dToolsData, tsheetsLabourData, tsheetsScheduleData]);

  // reset the component if the project changes
  useEffect(() => {
    if (localProjectId !== parentId) {
      setLocalProjectId(parentId);
    }
    loadFullData(parentId, dtoolsId);
    return () => {
      if (labourAbortController) {
        labourAbortController.abort();
      }
      if (scheduleAbortController) {
        scheduleAbortController.abort();
      }
      if (dtoolsAbortController) {
        dtoolsAbortController.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId]);

  return (
    <div className="row justify-content-lg-center">
      <Card className={showPendingCoValues ? 'col-md-11' : 'col-md-12 col-lg-10 col-xl-8'}>
        <CardBody>
          <h3>Labour Hours Comparison</h3>
          {(isLoadingTsheetsHours || isLoadingDtoolsSummary || isLoadingSchedule) && (
            <div>
              Loading... &nbsp;
              <Icon i="rolling" />
            </div>
          )}
          {!isLoadingTsheetsHours && (tsheetsLabourData && tsheetsLabourData.length === 0) && (
            <p>No T-Sheets data has been loaded yet.</p>
          )}
          {!isLoadingDtoolsSummary && dtoolsId && (tsheetsLabourData && tsheetsLabourData.length === 0) && (
            <p>No D-Tools data has been loaded yet.</p>
          )}
          {!dtoolsId && (
            <p>No D-Tools project has been linked yet.</p>
          )}
          {!isLoadingSchedule && (tsheetsScheduleData && tsheetsScheduleData.length === 0) && (
            <p>No T-Sheets Schedule data has been loaded yet.</p>
          )}
          <CheckBox
            id="pending-co-toggle"
            name="pending-co-toggle"
            label="Show Pending Change Order Values"
            value={showPendingCoValues}
            onChange={() => {
              setShowPendingCoValues(showPendingCoValues ? 0 : 1);
            }}
            onKeyPress={() => {
              setShowPendingCoValues(showPendingCoValues ? 0 : 1);
            }}
          />
          {tsheetsLabourData && dToolsData && tsheetsScheduleData && (
            <Table striped color="warning" className="color-table primary-table" style={{ border: '1px solid silver' }}>
              <thead>
                <tr>
                  <th>Type</th>
                  <th className="text-right">Schedule Hours</th>
                  <th className="text-right">Contractor Hours</th>
                  <th className="text-right">Team Hours</th>
                  <th className="text-right">Total Hours</th>
                  {showPendingCoValues === 1 && (
                    <th className="text-right">CO Pending</th>
                  )}
                  <th className="text-right">Quoted Hours</th>
                </tr>
              </thead>
              <tbody>
                {mappedClassData.sort((a, b) => {
                  if (a.dToolsQuotedHours || b.dToolsQuotedHours) {
                    return b.dToolsQuotedHours - a.dToolsQuotedHours;
                  }
                  if (a.totalLoggedHours || b.totalLoggedHours) {
                    return b.totalLoggedHours - a.totalLoggedHours;
                  }
                  return a.tSheetsScheduledHours - b.tSheetsScheduledHours;
                }).map((record) => (
                  <tr key={record.type}>
                    <td>{record.type}</td>
                    <td className="text-right">
                      {Math.round(record.tSheetsScheduledHours).toLocaleString('en-AU')}
                    </td>
                    <td className="text-right">
                      {Math.round(record.tSheetsContractorLoggedHours).toLocaleString('en-AU')}
                    </td>
                    <td className="text-right">
                      {Math.round(record.tSheetsTeamLoggedHours).toLocaleString('en-AU')}
                    </td>
                    <td className={record.totalLoggedHours > record.dToolsQuotedHours ? 'text-right text-danger' : 'text-right'}>
                      {Math.round(record.totalLoggedHours).toLocaleString('en-AU')}
                    </td>
                    {showPendingCoValues === 1 && (
                      <td className="text-right">
                        {Math.round(record.dToolsPendingHours).toLocaleString('en-AU')}
                      </td>
                    )}
                    <td className="text-right">
                      {Math.round(record.dToolsQuotedHours).toLocaleString('en-AU')}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th>Totals</th>
                  <th className="text-right">
                    {Math.round(totals.tsheetsSchedule).toLocaleString('en-AU')}
                  </th>
                  <th className="text-right">
                    {Math.round(totals.contractorLabour).toLocaleString('en-AU')}
                  </th>
                  <th className="text-right">
                    {Math.round(totals.teamLabour).toLocaleString('en-AU')}
                  </th>
                  <th className={totals.totalLabour > totals.dtoolsQuote ? 'text-right' : 'text-right text-danger'}>
                    {Math.round(totals.totalLabour).toLocaleString('en-AU')}
                  </th>
                  {showPendingCoValues === 1 && (
                    <th className="text-right">
                      {Math.round(totals.dtoolsPending).toLocaleString('en-AU')}
                    </th>
                  )}
                  <th className="text-right">
                    { Math.round(totals.dtoolsQuote).toLocaleString('en-AU') }
                  </th>
                </tr>
              </tfoot>
            </Table>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
