import React from 'react';
import { Card, CardBody, CardText } from 'reactstrap';
import { IInternalProjectRecord } from '../../types/internal-project/internal-project.record.interface';

interface IGanttViewProjectRowProps {
  project: IInternalProjectRecord,
}

/**
 * Gantt View Project Row
 *
 * @todo this is a stub, requires completion
 *
 * @param {IGanttViewProjectRowProps}   props
 * @param {IInternalProjectRecord}      props.project
 */
export const GanttViewProjectRow: React.FC<IGanttViewProjectRowProps> = ({ project }) => (
  <Card className="project-bar" key={project.id}>
    <CardBody>
      <CardText>
        {project.name}
      </CardText>
    </CardBody>
  </Card>
);
