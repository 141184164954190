import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProjectSummaryTableCellGroup from './project-summary-table-cell-group';
import ProjectSummaryTableRow from './project-summary-table-row';
import { DATA_TYPE, buildDomDataTagId } from './project-summary-helpers';
import Icon from '../../layout-helpers/icon';
import ProjectSummaryTableProjectManagerMonthlyDataRows from './project-summary-table-project-manager-monthly-data-rows';

const ProjectSummaryTableStateMonthlyDataRows = (props) => {
  const {
    monthlyFields,
    monthGroups,
    visibleMonthlyFieldCount,
    stateIds,
    pmAndStateIds,

    onClick,
    onBeginHover,
    onEndHover,

    geographicalStateData,
    projectManagerData,
    projectData,
  } = props;

  return (
    <>
      {/* No Geographical State Monthly Data - just display an empty cell. */}
      {(!!geographicalStateData.error) && (
        <ProjectSummaryTableRow
          className={classNames('geographical-state', 'monthly', 'data', 'no-data', { error: !!geographicalStateData.error, loading: geographicalStateData.isLoading })}
        >
          {monthGroups.map((monthGroup) => (
            <ProjectSummaryTableCellGroup
              key={`geographical_state_${monthGroup.key}`}
              span={visibleMonthlyFieldCount}
            />
          ))}
        </ProjectSummaryTableRow>
      )}

      {!geographicalStateData.error && !!geographicalStateData.summaryData.data.length && (
        <>
          {/* State Monthly Data Fields */}
          {geographicalStateData.summaryData.data.map((geographicalState) => (
            <React.Fragment
              key={`state_${geographicalState.state_id}`}
            >

              <ProjectSummaryTableRow
                key={`state_${geographicalState.state_id}_monthly_data`}
                className={classNames('geographical-state', 'monthly', 'data', {
                  loading: geographicalStateData.isLoading,
                  expanded: stateIds.includes(geographicalState.state_id),
                })}
                trackHover
                dataType={DATA_TYPE.GEOGRAPHICAL_STATE}
                id={buildDomDataTagId(DATA_TYPE.GEOGRAPHICAL_STATE, geographicalState.state_id)}
                onBeginHover={onBeginHover}
                onEndHover={onEndHover}
                onClick={onClick}
              >
                {monthGroups.map((monthGroup) => {
                  // Look for the state / month combination in the geographical state monthly data
                  const geographicalStateMonthData = geographicalStateData.monthlyData.data
                    .find((data) => data.state_id === geographicalState.state_id && data.month === monthGroup.key);

                  // Found it; Render it.
                  if (geographicalStateMonthData) {
                    return (
                      <ProjectSummaryTableCellGroup
                        key={`state_${geographicalState.state_id}_${monthGroup.key}`}
                        rowKey={`state_${geographicalState.state_id}_${monthGroup.key}`}
                        fields={monthlyFields}
                        data={geographicalStateMonthData}
                      />
                    );
                  }

                  // Doesn't exist - render an empty / loading cell
                  return (
                    <ProjectSummaryTableCellGroup
                      key={`state_${geographicalState.state_id}_${monthGroup.key}_loading`}
                      span={visibleMonthlyFieldCount}
                    >
                      {geographicalStateData.isLoading && (
                        <>
                          <Icon i="rolling" />
                          <span>Loading...</span>
                        </>
                      )}
                    </ProjectSummaryTableCellGroup>
                  );
                })}
              </ProjectSummaryTableRow>

              {/* Project Manager Monthly Data */}
              {projectManagerData.find((projectManager) => projectManager.stateId === geographicalState.state_id) && (
                <ProjectSummaryTableProjectManagerMonthlyDataRows
                  monthGroups={monthGroups}
                  monthlyFields={monthlyFields}
                  visibleMonthlyFieldCount={visibleMonthlyFieldCount}
                  pmAndStateIds={pmAndStateIds}

                  onClick={onClick}
                  onBeginHover={onBeginHover}
                  onEndHover={onEndHover}

                  stateId={geographicalState.state_id}
                  projectManagerData={projectManagerData.find((projectManager) => projectManager.stateId === geographicalState.state_id)}
                  projectData={projectData}
                />
              )}

            </React.Fragment>

          ))}

          {/* Monthly Data totals row */}
          <ProjectSummaryTableRow
            className={classNames('geographical-state', 'monthly', 'total', { loading: geographicalStateData.isLoading })}
          >
            {monthGroups.map((monthGroup) => (
              <ProjectSummaryTableCellGroup
                key={`monthly_totals_${monthGroup.key}`}
                rowKey={`monthly_totals_${monthGroup.key}`}
                fields={monthlyFields}
                data={geographicalStateData.monthlyData.data
                  .filter((geographicalStateMonth) => geographicalStateMonth.month === monthGroup.key)
                  .reduce((accumulator, currentValue) => {
                    const result = {};
                    monthlyFields.forEach((monthField) => {
                      result[monthField.key] = (accumulator[monthField.key] || 0) + currentValue[monthField.key];
                    });
                    return result;
                  }, {})}
              />
            ))}
          </ProjectSummaryTableRow>
        </>
      )}
    </>
  );
};

ProjectSummaryTableStateMonthlyDataRows.propTypes = {
  monthlyFields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  })).isRequired,
  monthGroups: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  geographicalStateData: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    summaryData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
    monthlyData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
  }).isRequired,
  projectManagerData: PropTypes.arrayOf(
    PropTypes.shape({
      stateId: PropTypes.number.isRequired,
      isLoading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      summaryData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
      monthlyData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
    }),
  ).isRequired,
  projectData: PropTypes.arrayOf(
    PropTypes.shape({
      stateId: PropTypes.number.isRequired,
      projectManagerId: PropTypes.number.isRequired,
      isLoading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      summaryData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
      monthlyData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
    }),
  ).isRequired,
  visibleMonthlyFieldCount: PropTypes.number.isRequired,
  stateIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  pmAndStateIds: PropTypes.arrayOf(PropTypes.shape({
    pmId: PropTypes.number.isRequired,
    stateId: PropTypes.number.isRequired,
  })).isRequired,
  onClick: PropTypes.func.isRequired,
  onBeginHover: PropTypes.func.isRequired,
  onEndHover: PropTypes.func.isRequired,
};

export default ProjectSummaryTableStateMonthlyDataRows;
