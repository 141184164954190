export const LOCAL_STORAGE_KEYS = {
  API_AUTH_TOKEN: 'apiAuthToken',
  USER_DETAILS: 'userDetails',
  USER_PERMISSIONS: 'userPermissions',
  USER_PERMISSIONS_CHECKSUM: 'userPermissionsChecksum',
  PAGE_AGE: 'pageAge',
  LAST_NOTIFICATIONS_POLL_TIMESTAMP: 'lastNotificationsPollTimestamp',
} as const;
export type LOCAL_STORAGE_KEYS = typeof LOCAL_STORAGE_KEYS;
export type A_LOCAL_STORAGE_KEY = LOCAL_STORAGE_KEYS[keyof LOCAL_STORAGE_KEYS];
