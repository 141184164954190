import React from 'react';
import { ButtonToolbar, ButtonGroup, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Icon from '../../layout-helpers/icon';

interface ITableDisplayControlsProps {
  // Formatting:
  color?: string,

  // Boolean State Info
  isDropDownOpen?: boolean,
  isTightModeEnabled?: boolean,
  isTextWrappingEnabled?: boolean,
  isFullScreenActive?: boolean,
  isColumnManagerOpen?: boolean,

  // Button Actions and Event Handlers
  onCloseTable?: () => boolean,
  onToggleShowColumnManager?: () => boolean,
  onToggleFullScreen?: () => boolean,
  onToggleTightMode?: () => void,
  onToggleTextWrapping?: () => void,
  onToggleDropDownOpen?: () => void,
  onDownloadViewAsExcel?: () => void,
}

/**
 * Table Display Controls Component
 */
export const TableDisplayControls: React.FC<ITableDisplayControlsProps> = (props) => {
  const {
    color = 'primary',
    isDropDownOpen = false,
    isTightModeEnabled = false,
    isTextWrappingEnabled = false,
    isFullScreenActive = false,
    isColumnManagerOpen = false,
    onCloseTable,
    onToggleShowColumnManager,
    onToggleFullScreen,
    onToggleTightMode,
    onToggleTextWrapping,
    onToggleDropDownOpen,
    onDownloadViewAsExcel,
  } = props;

  const buttonItems = [
    // Download as Excel
    {
      title: 'Excel Download',
      icon: 'file-excel-o',
      color: 'primary',
      active: isColumnManagerOpen,
      action: onDownloadViewAsExcel,
    },
    // Toggle Column Manager
    {
      title: 'Manage columns',
      icon: 'columns',
      color,
      active: isColumnManagerOpen,
      action: onToggleShowColumnManager,
    },

    // Toggle Text Wrapping Mode
    {
      title: 'Text Wrapping',
      icon: 'indent',
      iconMods: ['flip-horizontal'],
      color,
      active: isTextWrappingEnabled,
      action: onToggleTextWrapping,
    },

    // Toggle Tight Mode
    {
      title: 'Tight Table Layout',
      icon: isTightModeEnabled ? 'ti-layout-grid4' : 'ti-layout-grid3',
      color,
      active: isTightModeEnabled,
      action: onToggleTightMode,
    },

    // Toggle Full Screen Mode
    {
      title: 'Full Screen',
      icon: 'ti-fullscreen',
      color,
      active: isFullScreenActive,
      action: onToggleFullScreen,
    },
  ];

  // Return <TableControls />
  return (
    <ButtonToolbar className="table-display-controls">

      {/* This is the mobile mode dropdown which replaces the buttons */}
      <ButtonDropdown className="mobile-mode-dropdown" isOpen={isDropDownOpen} toggle={onToggleDropDownOpen}>
        <DropdownToggle color="primary">
          <Icon i="bars" />
        </DropdownToggle>
        <DropdownMenu right modifiers={{ preventOverflow: { boundariesElement: 'window' } }}>

          {buttonItems.map((buttonItem) => (
            <DropdownItem
              key={buttonItem.title}
              active={buttonItem.active}
              onClick={buttonItem.action}
            >
              <Icon i={buttonItem.icon} mods={buttonItem.iconMods} />
              {buttonItem.title}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>

      <ButtonGroup>
        {buttonItems.map((buttonItem) => (buttonItem.action ? (
          <Button
            key={buttonItem.title}
            color={buttonItem.color}
            active={buttonItem.active}
            onClick={buttonItem.action}
            onKeyPress={buttonItem.action}
            title={buttonItem.title}
            tabIndex={-1}
            className="hide-in-mobile"
          >
            <Icon i={buttonItem.icon} mods={buttonItem.iconMods} />
          </Button>
        ) : undefined))}
      </ButtonGroup>

      {/* Close table button */}
      {onCloseTable && (
        <ButtonGroup className="close-button">
          <Button
            color={color}
            onClick={onCloseTable}
            onKeyPress={onCloseTable}
            title="Close Table"
          >
            <Icon i="ti-close" />
          </Button>
        </ButtonGroup>
      )}
    </ButtonToolbar>
  );
};
