export const MODAL_TYPE = {
  CONFIRM: 'CONFIRM',
  NEW_USER: 'NEW_USER',
  NEW_PROJECT_LEAD: 'NEW_PROJECT_LEAD',
  NEW_PROJECT_PROPOSAL: 'NEW_PROJECT_PROPOSAL',
  NEW_CONTACT: 'NEW_CONTACT',
  NEW_COMPANY: 'NEW_COMPANY',
  NEW_COMPANY_LOCATION: 'NEW_COMPANY_LOCATION',
  NEW_COMPANY_SPACE: 'NEW_COMPANY_SPACE',
  NEW_COMPANY_SPACE_TYPE: 'NEW_COMPANY_SPACE_TYPE',
  NEW_LEAVE_APPLICATION: 'NEW_LEAVE_APPLICATION',
  NEW_EXPENSE: 'NEW_EXPENSE',
  NEW_ALLOWANCE: 'NEW_ALLOWANCE',
  NEW_INTERNAL_PROJECT: 'NEW_INTERNAL_PROJECT',
  NEW_INTERNAL_PROJECT_TASK: 'NEW_INTERNAL_PROJECT_TASK',
  NEW_SPRINT: 'NEW_SPRINT',
  NEW_TASK_TEMPLATE: 'NEW_TASK_TEMPLATE',
  NEW_PLATFORM: 'NEW_PLATFORM',
  NEW_TEAM_PROCESS: 'NEW_TEAM_PROCESS',
  NEW_TEAM: 'NEW_TEAM',
  NEW_CONTRACT: 'NEW_CONTRACT',
  NEW_CONTRACT_TYPE: 'NEW_CONTRACT_TYPE',
  NEW_CONTRACT_SUBTYPE: 'NEW_CONTRACT_SUBTYPE',
  NEW_SERVICE_JOB: 'NEW_SERVICE_JOB',
  NEW_INVOICE_REQUEST: 'NEW_INVOICE_REQUEST',
  RENEW_CONTRACT: 'RENEW_CONTRACT',
  PASTE_DATA_FROM_CLIPBOARD_MODAL: 'PASTE_DATA_FROM_CLIPBOARD_MODAL',
  PROJECT_SUMMARY_DASHBOARD_SETTINGS: 'PROJECT_SUMMARY_DASHBOARD_SETTINGS',
  SALES_FORECAST_DASHBOARD_SETTINGS: 'SALES_FORECAST_DASHBOARD_SETTINGS',
  FIND_MICROSOFT_AD_USER_ID: 'FIND_MICROSOFT_AD_USER_ID',
  ACTIVATE_PROJECT: 'ACTIVATE_PROJECT',
  UPDATE_FILE: 'UPDATE_FILE',
  LEAVE_CALENDAR_EVENT_MODAL: 'LEAVE_CALENDAR_EVENT_MODAL',
  UPLOAD_MAINTENANCE_CHECKS: 'UPLOAD_MAINTENANCE_CHECKS',
  REJECT_INVOICE_REQUEST: 'REJECT_INVOICE_REQUEST',
  PUSH_TO_QUICKBOOKS: 'PUSH_TO_QUICKBOOKS',
  NEW_MYOB_PURCHASE_ORDER: 'NEW_MYOB_PURCHASE_ORDER',
  NEW_COMMISSION_PAYMENT_BATCH: 'NEW_COMMISSION_PAYMENT_BATCH',
} as const;

export type MODAL_TYPE = typeof MODAL_TYPE;
export type A_MODAL_TYPE = MODAL_TYPE[keyof MODAL_TYPE];
