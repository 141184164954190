export const DATA_TABLE_FLAG = {
  ONLY_MINE: 'onlyMine',
  DELETED: 'deleted',
  INCLUDE_ARCHIVED: 'includeArchived',
  ADMIN_SEE_ALL_NOTIFICATIONS: 'adminSeeAllNotifications',
  MY_STATE: 'myState',
  NATIONAL: 'national',
} as const;
export type DATA_TABLE_FLAG = typeof DATA_TABLE_FLAG;
export type A_DATA_TABLE_FLAG = DATA_TABLE_FLAG[keyof DATA_TABLE_FLAG];
