import React from 'react';
import PropTypes from 'prop-types';
import { noop } from '../../../utils/helpers';
import IntegrationPanel from './integration-panel';
import ApiConnector from './api-connector';

const TsheetsPanel = ({ connectHandler, status }) => (
  <IntegrationPanel title="T-Sheets API Connector" color="danger">
    <ApiConnector status={status} color="danger" connectHandler={connectHandler} />
  </IntegrationPanel>
);

TsheetsPanel.propTypes = {
  connectHandler: PropTypes.func,
  status: PropTypes.shape({
    message: PropTypes.string,
    loading: PropTypes.bool,
    connected: PropTypes.bool,
  }),
};

TsheetsPanel.defaultProps = {
  connectHandler: noop,
  status: null,
};

export default TsheetsPanel;
