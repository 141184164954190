import React from 'react';
import { InfoTooltip } from '../../info-tooltip';

export const LongStringToolTipMore: React.FC<{ string: string, length?: number }> = ({ string, length = 30 }) => (
  <span>
    {string.substr(0, length)}
    ...
    <InfoTooltip>
      {string}
    </InfoTooltip>
  </span>
);
