import { IActionButton } from '../../../../types/action-button.interface';
import { BUTTON_COLOR } from '../../../../constants/button-color.const';
import { ICON } from '../../../../constants/icon.const';

export const companyLocationsWidgetPossibleSpaceActions: IActionButton[] = [
  // {
  //   name: 'create',
  //   title: 'Add a new space',
  //   color: BUTTON_COLOR.PRIMARY,
  //   icon: ICON.ADD,
  //   description: 'Create a new company space',
  //   successMessage: 'Created the new company space',
  //   failMessage: 'Failed to create the company space',
  // },
  {
    name: 'update',
    title: 'Edit',
    color: BUTTON_COLOR.PRIMARY,
    icon: ICON.EDIT,
    description: 'This will update the company space',
    successMessage: 'Company space updated',
    failMessage: 'Failed to update the company space',
  },
  // {
  //   name: 'delete',
  //   title: 'Delete',
  //   color: BUTTON_COLOR.DANGER,
  //   icon: ICON.DELETE,
  //   description: 'This will DELETE the company space',
  //   successMessage: 'Company space deleted',
  //   failMessage: 'Failed to delete the company space',
  // },
];
