// Map of action buttons that could appear and which
// Titles / colours / icons they should have

import { BUTTON_COLOR } from '../../constants/button-color.const';
import { ICON } from '../../constants/icon.const';

/**
 * Visit Wordflow Actions in the Wiki under News
 * @see http://wiki.ciportal.net/books/ci-portal-developer-documentation/page/workflow-actions-d9f
 * NOTE: Ordered by priority, first at the top
 */
export default [
  {
    name: 'update',
    title: 'Edit this article',
    label: 'Edit',
    color: BUTTON_COLOR.PRIMARY,
    icon: ICON.EDIT,
    // Update is not a modal confirmation.
    description: '',
    successMessage: '',
    failMessage: '',
    isSecondaryAction: false,
  },
  {
    name: 'submit',
    title: 'Submit this article for review',
    color: BUTTON_COLOR.SUCCESS,
    icon: ICON.SUBMIT,
    description: 'This will submit the article to an approver for publishing.',
    successMessage: 'Article submitted for approval.',
    failMessage: 'Failed to submit the article for approval.',
    isSecondaryAction: false,
  },
  {
    name: 'publish',
    title: 'Publish this article',
    color: BUTTON_COLOR.SUCCESS,
    icon: 'paper-plane-o',
    description: 'This will publish the article and it will become visible to news readers.',
    successMessage: 'Article published',
    failMessage: 'Failed to publish the article',
    isSecondaryAction: false,
  },
  {
    name: 'approve',
    title: 'Approve this article',
    color: BUTTON_COLOR.SUCCESS,
    icon: ICON.CHECK,
    description: 'This will mark this article as approved so that it can be published.',
    successMessage: 'Article approved',
    failMessage: 'Failed to approve the article',
    isSecondaryAction: false,
  },
  {
    name: 'retract',
    label: 'Un-publish',
    title: 'Un-publish this article',
    color: BUTTON_COLOR.WARNING,
    icon: ICON.UNDO,
    description: 'If you proceed, this article will no longer be visible to news readers.',
    successMessage: 'Article retracted',
    failMessage: 'Failed to retract the article',
    isSecondaryAction: true,
  },
  {
    name: 'reject',
    title: 'Reject this article',
    color: BUTTON_COLOR.DANGER,
    icon: ICON.REJECT,
    description: 'This will reject the contents of this article.',
    successMessage: 'Article rejected',
    failMessage: 'Failed to reject article',
    isSecondaryAction: false,
  },
  {
    name: 'revoke',
    title: 'Revoke this article\'s approval',
    color: BUTTON_COLOR.DANGER,
    icon: ICON.REVOKE,
    description: 'This will the revoke approval of this article and it will no be able to be published.',
    successMessage: 'Article revoked',
    failMessage: 'Failed to revoke the article',
    isSecondaryAction: true,
  },
  {
    name: 'unarchive',
    title: 'Un-archive this article',
    color: BUTTON_COLOR.DARK,
    icon: ICON.UNARCHIVE,
    description: 'This will un-archive the article',
    successMessage: 'Article un-archived',
    failMessage: 'Failed to un-archive the article',
    isSecondaryAction: true,
  },
  {
    name: 'archive',
    title: 'Archive this article',
    color: BUTTON_COLOR.DARK,
    icon: ICON.ARCHIVE,
    description: 'This will archive the article so that is does not appear in the readers\' news feed.',
    successMessage: 'Article archived',
    failMessage: 'Failed to archive the article',
    isSecondaryAction: true,
  },
  {
    name: 'delete',
    title: 'Delete this article',
    color: BUTTON_COLOR.DANGER,
    icon: ICON.DELETE,
    description: 'This will PERMANENTLY DELETE the article. Be very sure you want to perform this action.',
    successMessage: 'Article deleted',
    failMessage: 'Failed to delete the article',
    isSecondaryAction: true,
  },
];
