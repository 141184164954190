import React from 'react';
import PropTypes from 'prop-types';
// import { CardText } from 'reactstrap';
import { noop } from '../../../utils/helpers';
import IntegrationPanel from './integration-panel';
import ApiConnector from './api-connector';

const MicrosoftGraphPanel = ({ connectHandler, status }) => (
  <IntegrationPanel title="Microsoft Graph" color="info">
    <ApiConnector status={status} color="info" connectHandler={connectHandler} />
  </IntegrationPanel>
);

MicrosoftGraphPanel.propTypes = {
  status: PropTypes.shape({
    message: PropTypes.string,
    loading: PropTypes.bool,
    connected: PropTypes.bool,
  }),
  // statusHandler: PropTypes.func,
  connectHandler: PropTypes.func,
};

MicrosoftGraphPanel.defaultProps = {
  status: {
    message: '',
    loading: true,
    connected: false,
  },
  // statusHandler: noop,
  connectHandler: noop,
};

export default MicrosoftGraphPanel;
