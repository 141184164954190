import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Row, Table } from 'reactstrap';
import { useHistory, useLocation } from 'react-router';
import { apiAborter } from '../../helpers/api-aborter.helper';
import Icon from '../layout-helpers/icon';
import { APIContext } from '../providers/api-provider';
import deadCanary from '../../images/canary_spot_art.jpeg';
import happyCanary from '../../images/happy_canary.png';

const CHECK_STATUS_INTERVAL = 60000;

interface IProjectCacheStatusWidgetProps {
  title: string,
  showStatusTable: boolean,
  onCacheErrorDetected: (newStatus: boolean) => void,
}

interface IDataLoadRecord {
  'table': string,
  'generation_id': number,
  'expires_at': null,
  'checksum': number,
  'latest_checksum': string,
  'updated_at': string,
  'latest_trigger': string,
  'minutes_old': number,
  'needs_updating': 'yes' | 'no',
  'updating': boolean,
  'update_started_at': null,
  'last_execution_ms': number,
  'timeouts': boolean
}


/**
 * Project Chache Status Widget
 *
 * @param {IProjectCacheStatusWidgetProps} props
 * @param {string}  props.title    page title
 *
 * @returns {ProjectCacheStatusWidget}
 */
export const ProjectCacheStatusWidget: React.FC<IProjectCacheStatusWidgetProps> = (props) => {
  const {
    title = 'Cache Status',
    showStatusTable = false,
    onCacheErrorDetected = () => {},
  } = props;


  const history = useHistory();
  const location = useLocation();

  const [working, setWorking] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [data, setData] = useState<IDataLoadRecord[]>([]);

  const [hasDataIssue, setHasDataIssue] = useState<boolean>(false);
  // set up data loading
  const { apiFetch } = useContext(APIContext);
  const etaReportAbortController = useRef<AbortController | null>(null);

  /** Load Report Data */
  const loadData = useCallback(async () => {
    if (etaReportAbortController.current) {
      etaReportAbortController.current.abort();
    }
    etaReportAbortController.current = apiAborter();

    setWorking(true);
    setLoadingError(false);

    const response = await apiFetch(
      '/report/project/report-cache-status',
      {
        method: HTTP_METHOD.GET,
        name: 'ProjectReportCacheStatus:load',
        signal: etaReportAbortController?.current?.signal,
      },
    );

    if (response.success) {
      etaReportAbortController.current = null;
      const statusData = response.body.data || [];
      // check for issues
      let newHasDataIssue = false;
      for (let index = 0; index < statusData.length; index += 1) {
        const row = statusData[index];
        if (row.needs_updating === 'yes' && (row.minutes_old > 15 || row.minutes_old === null)) {
          newHasDataIssue = true;
        }
      }
      if (hasDataIssue && newHasDataIssue === false) {
        setTimeout(() => {
          history.push(location.pathname);
        }, 10000);
      }
      setHasDataIssue(newHasDataIssue === true);
      onCacheErrorDetected(newHasDataIssue);
      setData(statusData);
      setWorking(false);
      setLoadingError(false);
    } else if (!response.aborted) {
      etaReportAbortController.current = null;
    }
  }, [apiFetch, hasDataIssue, history, location.pathname, onCacheErrorDetected]);


  const intervalDataLoader = useCallback(() => {
    loadData();
  }, [loadData]);


  useEffect(() => {
    loadData();
    const refreshInterval = setInterval(intervalDataLoader, CHECK_STATUS_INTERVAL);

    return () => {
      clearInterval(refreshInterval);
      // Kill any outstanding load when the component is un-mounted
      if (etaReportAbortController.current) {
        etaReportAbortController.current.abort();
      }
    };// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!showStatusTable) return <></>;

  return (
    <Card className="project-cache-status-widget">
      <CardHeader className={`${hasDataIssue ? 'bg-danger' : 'bg-primary'} text-white`}>
        {hasDataIssue ? `${title}: Issues detected` : title}
      </CardHeader>
      <Row>
        <Col xl="9" lg="8" md="7" sm="6">
          {working && data.length === 0 && (
            <CardBody>
              <CardTitle>Updating Status</CardTitle>
              <CardText>
                <Icon i="rolling" />
                {' '}
                Loading...
              </CardText>
            </CardBody>
          )}

          {loadingError && data.length > 0 && (
            <CardBody>
              <CardTitle>
                Status Check Error
                {working && (
                  <Icon i="rolling" />
                )}
              </CardTitle>
              <CardText>
                Error loading status data, please refresh the page to try again.
              </CardText>
            </CardBody>
          )}

          {hasDataIssue && !loadingError && data.length > 0 && (
            <CardBody>
              <CardTitle>
                There seems to be an error with the financial data cache at the moment.
                {working && (
                  <Icon i="rolling" />
                )}
              </CardTitle>
              <CardText>
                Please contact &nbsp;
                <a href="mailto:itsupport@thecigroup.com.au">Ci IT Support</a>
              &nbsp; with a screenshot of this error, if the issue does not resolve itself in within 10 minutes.
              </CardText>
            </CardBody>
          )}
          {data && (
            <Table responsive>
              <thead>
                <tr>
                  <th>Cache Table</th>
                  {/* <th>Generation Id</th> */}
                  {/* <th>Expires At</th> */}
                  {/* <th>Checksum</th> */}
                  {/* <th>Latest Checksum</th> */}
                  <th>Updated At</th>
                  <th className="d-md-none">Latest Trigger</th>
                  <th>Minutes Old</th>
                  <th>Needs Updating</th>
                  <th>Updating</th>
                  <th>Started At</th>
                  <th className="d-md-none">Last Run Ms</th>
                  <th>Timeouts</th>
                </tr>
              </thead>
              <tbody>
                {data.map((record) => (
                  <tr key={record.table}>
                    <td>{record.table.replace(/_/g, ' ').replace('cached project ', '')}</td>
                    {/* <td>{record.generation_id}</td> */}
                    {/* <td>{record.expires_at}</td> */}
                    {/* <td>{record.checksum}</td>
                <td>{record.latest_checksum}</td> */}
                    <td>{record.updated_at ? moment(record.updated_at).format('D/M/YY h:mm') : '-'}</td>
                    <td className="d-md-none">{record.latest_trigger ? moment(record.latest_trigger).format('D/M/YY h:mm') : '-'}</td>
                    <td>{record.minutes_old}</td>
                    <td>{record.needs_updating}</td>
                    <td>{record.updating ? 'yes' : 'no'}</td>
                    <td>{record.update_started_at ? moment(record.update_started_at).format('D/M/YY h:mm') : '-'}</td>
                    <td className="d-md-none">{record.last_execution_ms}</td>
                    <td>{record.timeouts}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
        <Col xl="3" lg="4" md="5" sm="6">
          <img width="100%" src={hasDataIssue ? deadCanary : happyCanary} alt="Canary in a Coal Mine" />
        </Col>
      </Row>
    </Card>
  );
};
