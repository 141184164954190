export const EXPENSE_CLAIM_STATUS = {
  UNSUBMITTED: 1,
  SUBMITTED: 2,
  APPROVED: 3,
  REJECTED: 4,
  REVOKED: 5,
  BATCHED: 6,
  PROCESSED: 7,
  PAID: 8,
} as const;
export type EXPENSE_CLAIM_STATUS = typeof EXPENSE_CLAIM_STATUS;
export type AN_EXPENSE_CLAIM_STATUS = EXPENSE_CLAIM_STATUS[keyof EXPENSE_CLAIM_STATUS];

export const EXPENSE_CLAIM_STATUS_MAP: {[K in AN_EXPENSE_CLAIM_STATUS]: string} = {
  [EXPENSE_CLAIM_STATUS.UNSUBMITTED]: 'Unsubmitted',
  [EXPENSE_CLAIM_STATUS.SUBMITTED]: 'Submitted',
  [EXPENSE_CLAIM_STATUS.APPROVED]: 'Approved',
  [EXPENSE_CLAIM_STATUS.REJECTED]: 'Rejected',
  [EXPENSE_CLAIM_STATUS.REVOKED]: 'Revoked',
  [EXPENSE_CLAIM_STATUS.BATCHED]: 'Batched',
  [EXPENSE_CLAIM_STATUS.PROCESSED]: 'Processed',
  [EXPENSE_CLAIM_STATUS.PAID]: 'Paid',
};
