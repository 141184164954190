import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import rollingSvg from '../../images/Rolling-1s-22px.svg';

const FullPageLoadingSpinner = ({ children, className, caption }) => (
  <div className={classNames('full-page-loading-spinner', className)}>
    <img className="spinner" src={rollingSvg} alt={caption} />
    {caption && (
      <span>{caption}</span>
    )}
    {children}
  </div>
);

FullPageLoadingSpinner.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  caption: PropTypes.string,
};

FullPageLoadingSpinner.defaultProps = {
  children: null,
  className: null,
  caption: null,
};

export default FullPageLoadingSpinner;
