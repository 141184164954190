import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';

import { APIContext } from '../providers/api-provider';

import { ILeaveApplicationRecord } from '../../types/leave/leave-application.record.interface';
import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';

import AsyncSelect from './async-select';

import { apiAborter } from '../../helpers/api-aborter.helper';

export type LeaveTypePickerProps = AsyncSelectComponentProps;

/**
 * TODO: This component is for some reason coupled to only be displayed on a leave block row
 */
export const LeaveTypePicker: React.FC<LeaveTypePickerProps> = (props) => {
  const {
    value = null,
    inline = false,
    formData,
  } = props;

  const { apiFetch } = useContext(APIContext);
  const { leave_application_id } = formData as ILeaveApplicationRecord;

  const [searchRoute, setSearchRoute] = useState<null | string>(null);

  /**
   * Get the search route for the dropdown based on the user who owns the leave application
   */
  useEffect(() => {
    const abortController = apiAborter();

    // Get the User's ID associated with THIS LEAVE REQUEST
    apiFetch(
      `/leaveapplication/${leave_application_id}`,
      {
        name: 'LeaveTypePicker::user',
        signal: abortController.signal,
      },
    ).then((response) => {
      if (response.success) {
        setSearchRoute(`/leaveblocktype?userid=${response.body.data.user_id}`);
      } else if (!response.aborted) {
        setSearchRoute('/leaveblocktype');
      }
    });

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [apiFetch, leave_application_id]);


  if (!searchRoute) {
    return (
      <div
        className={classNames('portal-data-field', 'not-editable', 'disabled', {
          inline,
        })}
      >
        {value && <span>{value}</span>}
        {!value && <span>Loading...</span>}
      </div>
    );
  }
  return (
    <AsyncSelect
      {...props}
      classNamePrefix="Select"

      searchRoute={searchRoute}
      loadAndKeepAll
    />
  );
};
