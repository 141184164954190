import React from 'react';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { formatAlignClass } from '../render-functions';

export type CalculatedFieldProps = Pick<FormFieldComponentProps,
  'formData' |
  'calculate' |
  'id' |
  'name' |
  'required' |
  'value' |
  'onChange' |
  'format' |
  'inline'
>;

export const CalculatedField:React.FC<CalculatedFieldProps> = ({
  id,
  name,
  required,
  onChange,
  formData,
  calculate,
  format,
  inline,
}) => (
  <input
    className={`form-control inline ${formatAlignClass(format, inline)}`}
    id={id}
    name={name}
    required={required !== false}
    onChange={onChange ? (e) => onChange({ fieldName: name, newValue: e.target.value }) : undefined}
    value={calculate && calculate(formData)}
    disabled
  />
);
