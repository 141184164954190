import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { usePopper } from 'react-popper';

import Icon from '../layout-helpers/icon';

import { AN_ICON } from '../../constants/icon.const';

export type PortalMultiLevelDropDownSubMenuItemProps = {
  className?: string,
  disabled?: boolean,
  icon?: AN_ICON,
  label?: string
  rightAligned?: boolean,
};

/**
 * This component is partially unmanaged and looks for siblings and parent submenus
 */
export const PortalMultiLevelDropDownSubMenuItem: React.FC<PortalMultiLevelDropDownSubMenuItemProps> = (props) => {
  const {
    className,
    children,
    disabled = false,
    rightAligned = false,
    icon,
    label = 'more',
  } = props;

  const [referenceElement, setReferenceElement] = useState<HTMLLIElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: rightAligned ? 'left-start' : 'right-start',
  });

  /**
   * Toggle whether the submenu is open or not
   */
  const toggleSubMenuOpen = (newValue?: boolean) => setIsSubMenuOpen((oldValue) => ((newValue !== undefined) ? newValue : !oldValue));


  /**
   * find the parent <ul> and add the 'has-sub-menu' class
   * This ensures that the styling for the entire menu accommodates the presence of this submenu
   */
  useEffect(() => {
    // Wait for the reference to be updated by the first render
    if (referenceElement) {
      // Locate the parent and add the 'has-sub-menu-item' classname to it
      if (referenceElement.parentElement && (referenceElement.parentElement.tagName.toLowerCase() === 'ul')) {
        referenceElement.parentElement.classList.add('has-sub-menu-item');
      }
    }
  }, [referenceElement]);


  /**
   * Add an onClick event handler to all of the sibling menu items so that we can close this submenu
   * when they are clicked
   */
  useEffect(() => {
    // Wait for the reference to be updated by the first render
    if (referenceElement && referenceElement.parentElement && (referenceElement.parentElement.tagName.toLowerCase() === 'ul')) {
      // Get a list of all of the sibling `pmldd-item` <li> tags.
      let siblings = Array.from(referenceElement.parentElement.children);

      // Filter out this element and non-interactive elements
      siblings = siblings.filter((item) => (
        // Item must be a `pmldd-item`
        item.classList.contains('pmldd-item') &&

        // Item must not be this item
        item !== referenceElement
      ));

      // Create a pointer to a method we can bind and unbind later
      const handleSiblingItemOnClick = () => {
        toggleSubMenuOpen(false);
      };

      // Add an onClick event listener to close this sub-menu when the sibling is clicked
      siblings.forEach((item) => item.addEventListener('mousedown', handleSiblingItemOnClick));

      // Make sure the event listener is un-bound when this component is un-mounted or if the reference element changes
      return () => {
        siblings.forEach((item) => item.addEventListener('mousedown', handleSiblingItemOnClick));
      };
    }

    return () => {};
  }, [referenceElement]);


  // Render
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <li
        className={classNames('pmldd-item sub-menu', className, {
          disabled,
          active: isSubMenuOpen,
        })}
        role="menuitem"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleSubMenuOpen();
        }}
        ref={setReferenceElement}
      >
        {icon && (
          <Icon i={icon} />
        )}
        <span>{label}</span>
      </li>
      {isSubMenuOpen && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <ul
            className={classNames('pmldd-menu sub-menu', className, {
              right: rightAligned,
              left: !rightAligned,
            })}
          >
            {children}
          </ul>
        </div>
      )}
    </>
  );
};
