import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader } from 'reactstrap';
import ToggleSwitch from './toggle-switch';

/**
 * ProjectViewLinks component for Data Table
 */
export const DToolsProjectViewLinks = ({ toggleSection, visibility, userCanSeeCharts }) => (
  <Card>
    <CardHeader className="bg-info text-white" tag="h4">
      Project Summary Items
    </CardHeader>
    <table>
      <tbody>
        <tr>
          <td>Project Summary</td>
          <td>
            <ToggleSwitch sectionVis={visibility} section="summary" toggleSection={toggleSection} />
          </td>
        </tr>
        <tr>
          <td>Tracking</td>
          <td>
            <ToggleSwitch
              sectionVis={visibility}
              section="tracking"
              toggleSection={toggleSection}
            />
          </td>
        </tr>
        {userCanSeeCharts && (
          <tr>
            <td>Margin Charts</td>
            <td>
              <ToggleSwitch
                sectionVis={visibility}
                section="trackingCharts"
                toggleSection={toggleSection}
              />
            </td>
          </tr>
        )}
        <tr>
          <td>Items</td>
          <td>
            <ToggleSwitch sectionVis={visibility} section="items" toggleSection={toggleSection} />
          </td>
        </tr>
        <tr>
          <td>Orders</td>
          <td>
            <ToggleSwitch sectionVis={visibility} section="orders" toggleSection={toggleSection} />
          </td>
        </tr>
        <tr>
          <td>Locations</td>
          <td>
            <ToggleSwitch
              sectionVis={visibility}
              section="locations"
              toggleSection={toggleSection}
            />
          </td>
        </tr>
        <tr>
          <td>Sites</td>
          <td>
            <ToggleSwitch sectionVis={visibility} section="sites" toggleSection={toggleSection} />
          </td>
        </tr>
        <tr>
          <td>Financial Summary</td>
          <td>
            <ToggleSwitch
              sectionVis={visibility}
              section="financials"
              toggleSection={toggleSection}
            />
          </td>
        </tr>
        <tr>
          <td>Schedule</td>
          <td>
            <ToggleSwitch
              sectionVis={visibility}
              section="schedule"
              toggleSection={toggleSection}
            />
          </td>
        </tr>
      </tbody>
    </table>
  </Card>
);

DToolsProjectViewLinks.propTypes = {
  visibility: PropTypes.shape({}).isRequired,
  toggleSection: PropTypes.func.isRequired,
  userCanSeeCharts: PropTypes.bool.isRequired,
};
