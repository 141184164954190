import React from 'react';
import PropTypes from 'prop-types';
import { formatValueString } from '../../render-functions';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';

const ExistingQuickBooksOrderTable = ({ quickbooksResponse }) => (
  <table className="table table-striped color-table success-table">
    <thead>
      <tr>
        <th>Existing Number</th>
        <th>Vendor Name</th>
        <th>Lines</th>
        <th className="text-right">Total Value</th>
      </tr>
    </thead>
    <tbody>
      {quickbooksResponse.order_data.map((order) => {
        // overcompensating for a weird match where the vendor is not being returned.
        const vendorMatch = quickbooksResponse.order_vendors
          ? quickbooksResponse.order_vendors.filter((vendor) => (vendor ? vendor.Id === order.VendorRef : false))[0]
          : {};
        // sometimes quickbooks returns a single line, but not in an array just as an object. Irritating!
        let lineCount = order.Line.length || 0;
        if (Object.prototype.hasOwnProperty.call(order.Line, 'Id')) lineCount = 1;
        let totalValue = 0;
        if (Object.prototype.hasOwnProperty.call(order.Line, 'Amount')) {
          totalValue = order.Line.Amount;
        } else if (order.Line.length > 0) {
          totalValue = order.Line.reduce((accumulator, current) => accumulator + parseFloat(current.Amount), 0);
        }
        return (
          <tr key={`${order.DocNumber}-${order.VendorRef}-${lineCount}`}>
            <td>
              {/* TODO why is the QuickBooks URL hardcoded here? */}
              <a
                href={`https://c17.qbo.intuit.com/app/purchaseorder?txnId=${order.Id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {order.DocNumber}
              </a>
            </td>
            <td>
              {`${order.VendorRef || 'No Vendor Found'} ${(vendorMatch && vendorMatch.DisplayName) || ''}`}
            </td>
            <td>{lineCount}</td>
            <td className="text-right">{formatValueString(totalValue, COLUMN_FORMAT.CURRENCY)}</td>
          </tr>
        );
      })}
    </tbody>
  </table>
);

ExistingQuickBooksOrderTable.propTypes = {
  quickbooksResponse: PropTypes.shape({
    order_vendors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    order_data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default ExistingQuickBooksOrderTable;
