import React from 'react';
import PropTypes from 'prop-types';

/**
 * @class VHFix
 *
 * @description
 * Pete?! What the hell is this all about!?
 * Well, I'll tell you.
 *
 * The css unit `vh`, is awesome. It represents 1% of the viewport height.
 * Using it, we can dictate that an element should be, say, `100%` of the viewport height, or `100vh`.
 *
 * BUT, there's a caveat. On Some systems, VH includes the non webpage part of the browser, like the URL bar.
 * *cough* iOS *cough*
 *
 * So this simple hack creates a css variable called `--vh` which can be used in css in
 * conjunction with css `calc()`. The `--vh` variable is set at all times to the `window.innerheight`,
 * thus ensuring that the viewport height is always that which the user considers their viewport.
 *
 * So, don't use `vh` anywhere in your css. use `var(--vh)` instead.
 *
 * `100vh` becomes `calc(100 * var(--vh))`
 * `50vh` becomes `calc(50 * var(--vh))`
 * etc...
 */
class VHFix extends React.Component {
  constructor(props) {
    super(props);

    this.vh = null;
  }

  componentDidMount = () => {
    this.updateDocumentStyle();
    window.addEventListener('resize', this.updateDocumentStyle);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDocumentStyle);
  }

  updateDocumentStyle = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

VHFix.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

VHFix.defaultProps = {
  children: null,
};

export default VHFix;
