import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SalesForecastTableCell from './sales-forecast-table-cell';
import { formatAlignClass } from '../../render-functions';


/**
 * @description
 * Render out the heading row of one side of the project summary table
 */
const SalesForecastTableHeaderGroup = (props) => {
  const {
    className, fields, children, span,
  } = props;

  const visibleFieldCount = (fields && fields.filter((field) => field.visible).length) || 0;

  return (
    <>
      {/* Render out a list of fields */}
      {fields && fields.filter((field) => field.visible).map((field, index) => (
        <SalesForecastTableCell
          key={field.key}
          className={classNames(
            className,
            formatAlignClass(field.format, true),
            {
              'cell-border-right': index === (visibleFieldCount - 1),
            },
          )}
          isHeaderCell
          field={field}
        >
          {field.label}
        </SalesForecastTableCell>
      ))}

      {/* No fields, Render out a single wide span cell */}
      {(!fields || fields.length === 0) && (
        <SalesForecastTableCell
          isHeaderCell
          className={classNames(
            className,
            'cell-border-right',
          )}
          span={span}
        >
          {children}
        </SalesForecastTableCell>
      )}
    </>
  );
};

SalesForecastTableHeaderGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  fields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  })),
  span: PropTypes.number,
};

SalesForecastTableHeaderGroup.defaultProps = {
  className: null,
  children: null,
  fields: null,
  span: 1,
};

export default SalesForecastTableHeaderGroup;
