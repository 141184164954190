import React, { useState } from 'react';
import { Card, Container, CardBody, Row, Col, Label, Button } from 'reactstrap';
import classNames from 'classnames';

import { LoginPageHeader } from './login-page-header';
import { TextInput } from '../form-input/text-input';
import Icon from '../layout-helpers/icon';
import FriendlyFormMessage from '../layout-helpers/friendly-form-message';
import { IPasswordResetResult } from '../../types/auth/password-reset-result.interface';
import { FormFieldErrors } from '../form-input/form-field-errors';

interface PasswordResetPageProps {
  initialEmail: null | string,
  isBusy: boolean;
  onSubmit: (email: string) => void;
  onCancel: () => void;
  formResult: undefined | IPasswordResetResult;
}

export const PasswordResetPage: React.FC<PasswordResetPageProps> = ({
  initialEmail,
  isBusy,
  onSubmit,
  onCancel,
  formResult,
}) => {
  const [email, setEmail] = useState<string>(initialEmail ?? '');

  /**
   * Fired when the user submits the form in some way
   */
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(email);
  };


  /**
   * Fired when the user cancels the Password reset operation and
   * returns to the login page
   */
  const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    onCancel();
  };


  return (
    <Container className="portal-login-form-container">
      <Card className="portal-login-form-card">
        <CardBody>
          <Row>
            <Col md="5" />
            <Col md="7" className="portal-login-form right-side">
              <LoginPageHeader
                title="Reset Password"
                subTitle="Forgotten your password? Enter your email below and we'll send you a reset link"
              />

              {/* Password Reset Form */}
              <Card>
                <CardBody>
                  <form className="vertical-layout" onSubmit={handleFormSubmit}>
                    <div
                      className={classNames('form-group', {
                        'has-danger': !!formResult?.fieldErrors?.email,
                      })}
                    >
                      <Label htmlFor="username">Email</Label>
                      <TextInput
                        id="username"
                        name="username"
                        onChange={({ newValue }) => setEmail(newValue)}
                        disabled={isBusy}
                        value={email}
                        placeholder="Email"
                        hasError={!!formResult?.fieldErrors?.email}
                      />
                      {!!formResult?.fieldErrors?.email && (
                        <div className="form-control-feedback">
                          <FormFieldErrors errors={formResult.fieldErrors.email} />
                        </div>
                      )}
                      <small className="form-text text-muted">
                        Enter your email address and we will send you a password
                        reset link.
                      </small>
                    </div>
                    <Button
                      disabled={isBusy}
                      type="submit"
                      color="primary"
                      className="pull-right"
                    >
                      <span>Send Reset Email</span>
                      {isBusy && <Icon i="spinner" size="lg" spaceLeft />}
                    </Button>
                    <Button
                      onClick={handleCancelClick}
                      disabled={isBusy}
                      color="secondary"
                      type="button"
                    >
                      Cancel
                    </Button>
                  </form>
                </CardBody>
              </Card>

              {formResult && (formResult.succeeded || formResult.failed) && (
                <FriendlyFormMessage
                  formMessage={formResult.message}
                  hasSuccess={formResult.succeeded}
                  hasErrors={formResult.failed}
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};
