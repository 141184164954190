import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import moment from 'moment';

import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { NewRecordModalProps } from '../../types/modal/new-record-modal.props';

import { Modal } from './Modal';
import { NewMyobPurchaseOrderForm } from '../custom-forms/new-myob-purchase-order-form';
import { BUTTON_COLOR } from '../../constants/button-color.const';
import { IModalButton } from '../../types/modal/modal-button.interface';
import { MODAL_BUTTON_SIDE_TYPE } from '../../constants/modal-button-side-type.const';
import { ICON } from '../../constants/icon.const';
import { APIContext } from '../providers/api-provider';
import { apiAborter } from '../../helpers/api-aborter.helper';
import FriendlyFormMessage from '../layout-helpers/friendly-form-message';
import { DATE_FORMAT } from '../../constants/date-format.const';

interface ReturnType {
  vendorId?: string,
  vendorName?: string,
  projectId?: string,
  projectNumber?: string,
  orderNumber?: string,
  orderDate?: string,
}

export const NewMyobPurchaseOrderModal: React.FC<NewRecordModalProps<ReturnType>> = (props) => {
  const {
    id, closeModal, onModalComplete, initialData,
  } = props;

  const { apiFetch } = useContext(APIContext);

  const createOrderAbortController = useRef<AbortController | null>(null);


  const [orderCreating, setOrderCreating] = useState<boolean>(false);
  const [orderCreatingError, setOrderCreatingError] = useState<boolean>(false);
  const [orderNote, setOrderNote] = useState<string|undefined>(undefined);
  const [orderLoadingErrorResponse, setOrderLoadingErrorResponse] = useState<{
    errors: {
      [key: string]: string[]
    },
    message: string,
   } | null>(null);

  /**
   * Load Vendor Orders
   *
   * @param {string} vendorId
   */
  const createOrder = useCallback((callback: (response: ReturnType) => void) => {
    if (createOrderAbortController.current) {
      createOrderAbortController.current.abort();
    }
    createOrderAbortController.current = apiAborter();
    setOrderCreatingError(false);
    setOrderCreating(true);
    apiFetch(
      `/myob-advanced-connector/supplier-order?vendor_id=${initialData?.vendorId}`,
      {
        method: HTTP_METHOD.POST,
        name: 'DToolsProjectOrders:loadOrders',
        signal: createOrderAbortController?.current?.signal,
        body: {
          project_id: initialData?.projectId,
          project_number: initialData?.projectNumber,
          vendor_id: initialData?.vendorId,
          vendor_name: initialData?.vendorName,
          order_number: initialData?.orderNumber,
          order_date: initialData?.orderDate ? moment(initialData?.orderDate, 'D/M/YY').format(DATE_FORMAT.YEAR_MONTH_DAY_DASHES) : null,
          order_note: orderNote || null,
        },
      },
    ).then((response) => {
      setOrderCreating(false);
      if (response.success) {
        callback(response.body);
        // setVendorOrder(response.body);
      } else if (response.error) {
        setOrderCreatingError(true);
        setOrderLoadingErrorResponse(response.body);
      } else if (!response.aborted) {
        setOrderCreatingError(true);
      }
    });
  }, [apiFetch, initialData, orderNote]);


  const modalButtons = useMemo<IModalButton[]>(() => [
    {
      label: 'Create PO',
      color: BUTTON_COLOR.PURPLE,
      icon: ICON.CLOUD_UPLOAD,
      sideType: MODAL_BUTTON_SIDE_TYPE.RIGHT,
      onClick: () => {
        createOrder((response) => {
          closeModal(id);
          if (onModalComplete) {
            onModalComplete({
              success: true,
              newRecord: response,
            });
          }
        });
      },
      disabled: (!onModalComplete || orderCreating),
    },
    {
      label: 'Cancel',
      color: 'secondary',
      sideType: MODAL_BUTTON_SIDE_TYPE.LEFT,
      onClick: () => {
        closeModal(id);
      },
    },
  ], [closeModal, createOrder, id, onModalComplete, orderCreating]);


  // Render
  return (
    <Modal
      {...props}
      title="Create a new MYOB Purchase Order"
      allowDismiss
      size="lg"
      buttons={modalButtons}
    >
      <NewMyobPurchaseOrderForm
        vendorId={initialData?.vendorId}
        vendorName={initialData?.vendorName}
        projectId={initialData?.projectId}
        projectNumber={initialData?.projectNumber}
        orderNumber={initialData?.orderNumber}
        orderDate={initialData?.orderDate}
        orderNote={orderNote}
        setOrderNote={setOrderNote}
      />
      <FriendlyFormMessage
        errors={orderLoadingErrorResponse?.errors}
        formMessage={orderLoadingErrorResponse?.message}
        hasErrors={orderCreatingError}
        hasSuccess
        showList
        inline
        showIcon
        isOpen
        useSimpleDefault
      />
    </Modal>
  );
};
