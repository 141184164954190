/* eslint-disable import/prefer-default-export */
import React from 'react';
import { ApiQueryDataLoader } from '../api-query-data-loader/api-query-data-loader';
import { ConstSelect, ConstSelectProps } from './const-select';
import Icon from '../layout-helpers/icon';
import { IRoleRecord } from '../../types/user/role.record.interface';
import { SelectOption } from '../../types/poly-form/select-option';

export type UserRolePickerProps = ConstSelectProps

/**
 * Loads data and renders a form for the selected users roles
 */
export const UserRolePicker: React.FC<UserRolePickerProps> = (props) => {
  const {
    hasError,
  } = props;

  return (
    // get available roles
    <ApiQueryDataLoader
      apiQueryUrl="/role?pageLength=0"
      render={({
        response,
        isLoading,
        hasError: hasRoleLoadingError,
      }) => {
        if (isLoading) { return (
          <div className="text-center">
            <Icon i="rolling" />
          </div>
        ); }

        const data: IRoleRecord[] = (response?.data as IRoleRecord[]) ?? [];

        return (
          <ConstSelect
            {...props}
            hasError={hasError || hasRoleLoadingError}
            inputOptions={data.map((dataItem: IRoleRecord) => ({ id: dataItem.name, name: dataItem.name } as SelectOption))}
          />
        );
      }}
    />
  );
};
