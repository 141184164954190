/**
 * @description
 * Some delete actions are not as severe as others. Let's not scare
 * off users with nasty red modals unless we have to!
 */
export const DELETE_CONFIRMATION_TYPE = {
  DELETE: 'DELETE',
  REMOVE: 'REMOVE',
} as const;

export type DELETE_CONFIRMATION_TYPE = typeof DELETE_CONFIRMATION_TYPE;
export type A_DELETE_CONFIRMATION_TYPE = DELETE_CONFIRMATION_TYPE[keyof DELETE_CONFIRMATION_TYPE];
