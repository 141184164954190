import { IFormFieldDefinition } from '../../types/poly-form/form-field-definition.interface';
import { COLUMN_FORMAT } from '../column-format.const';
import { FORM_FIELD_TYPE } from '../form-field-type.const';

export const companyLocationZoneFormFields: IFormFieldDefinition[] = [
  {
    name: 'company_id',
    visible: false,
    isParentId: true,
  },

  {
    name: 'location',
    title: 'Parent Location',
    description: 'Parent Location - the detail-level location of this Space',
    format: COLUMN_FORMAT.OBJECT,
    object: {
      sourceField: 'location',
      key: 'name',
      format: COLUMN_FORMAT.TEXT,
    },
    formFieldType: FORM_FIELD_TYPE.COMPANY_LOCATION_PICKER,
    formSaveField: 'location_id',
    visible: true,
    searchRoute: (formData) => `/company/${formData.company_id}/location`,
    isCreatable: true,
    getNewItemData: (inputValue, formData) => ({
      company_id: formData?.company_id ?? null,
      company: formData?.company ?? null,
    }),
  },

  {
    name: 'name',
    title: 'Zone Name',
    description: 'The zone name',
    format: COLUMN_FORMAT.TEXT,
    visible: true,
  },

  {
    name: 'description',
    title: 'Description',
    description: 'Any additional information about the zone',
    format: COLUMN_FORMAT.TEXT,
    visible: true,
  },

  {
    name: 'path',
    title: 'Full Location Name',
    description: 'The location name with hierarchy.',
    format: COLUMN_FORMAT.TEXT,
    formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    visible: true,
    required: true,
  },
];
