/* eslint-disable @typescript-eslint/no-explicit-any */

import moment from 'moment';
import React from 'react';

import FUSION_CHART_DATASET_RENDER_TYPES from '../../utils/fusion-chart-dataset-render-types';
import FUSION_CHART_TYPES from '../../utils/fusion-chart-types';
import PortalFusionChart from './portal-fusion-chart';

type DtoolsUserSaturationChartProps = { onItemClick: (data: { [key: string]: any }) => void };

/**
 * @class DtoolsUserSaturationChart
 *
 * @description
 * This chart implements the Portal2 API's "Dtools User Count" report
 * @see https://wiki.ciportal.net/books/ci-portal-developer-documentation/page/projects-by-status-age
 */
export const DtoolsUserSaturationChart: React.FC<DtoolsUserSaturationChartProps> = ({ onItemClick }) => {
  /**
   * @description
   * Create and return a new seriesList
   *
   * @param {{}} withProps
   * @returns {{}[]}
   */
  const createSeriesList = () => {
    const months = [];
    for (let index = 0; index < 5; index += 1) {
      const month = moment().subtract(index, 'month');
      months.push({
        number: month.format('Mo'),
        name: month.format('MMMM'),
      });
    }
    const seriesList = months.map((month) => ({
      name: `${month.name} Seat Usage`,
      filterIn: (dataItem: any) => {
        const itemMonth = moment(dataItem.at).format('Mo');
        return itemMonth === month.number;
      },
      getValue: (dataItem: any) => (dataItem.user_count),
      renderAs: FUSION_CHART_DATASET_RENDER_TYPES.LINE,
      visible: true,
    }));
    return seriesList;
  };


  /**
   * @description
   * Fired by the Fusion chart to get the labels that should be displayed along the
   * bottom row / x-axis / column etc...
   *
   * @param {object} dataItem the data item to extract an x-axis / column label
   */
  const handleGetLabel = (dataItem: any) => moment(dataItem.at).format('Do h:mm a');

  return (
    <PortalFusionChart
      id="user-saturation-chart"
      dataMapping={{
        type: 'multi',
        chartType: FUSION_CHART_TYPES.MULTI_SERIES_LINE_2D,
        getLabel: handleGetLabel,
        seriesList: createSeriesList(),
      }}
      data={{
        url: '/dtools-cache/user-saturation-poll?pagelength=-1',
      }}
      // xAxisName="time"
      yAxisName="Logged in Users"
      labelDisplay="rotate"
      labelStep={103}
      drawAnchors={false}
      yAxisMaxValue={30}
      plotToolText="Click to show names below table <br> $seriesName $label <br> $dataValue Users "

      additionalChartSettings={{
        // numVisiblePlot: 720,
        height: '500',
      }}

      onClickDataItem={(id: number, data: { [key: string]: any}) => onItemClick(data)}
    />
  );
};

