import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Button, Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import classNames from 'classnames';
import ReactResizeDetector from 'react-resize-detector';
import { EventEmitter } from 'events';

import HISTORY_PROP_TYPES from '../../prop-types/history-prop-types';
import {
  PAGE_SIZE_DEFAULT,
  PUSH_OR_REPLACE,
} from '../../utils/constants';
import SORT_DIRECTION from '../../utils/sort-directions';
import { defaultTableSettings } from '../../table-definitions/default-table-settings';
import {
  realFilterField,
  documentTitle,
  getItemByKey,
  getMaxSortIndex,
  realFilterValue,
  debugLog,
  toParams,
  toQueryString,
} from '../../utils/helpers';
import {
  makeFilterString,
  makeSortStringFromSortedColumns,
} from '../../utils/api-calls';
import { saveTableStateToLocalStorage } from '../../utils/localStorage';

import PortalTable from './table';
import ColumnManager from './column-manager';
import PortalDataTablePagination from './pagination/portal-data-table-pagination';
import ViewSelect from './view-select';
import PortalDataTableResultsText from './pagination/portal-data-table-results-text';
import PortalDataTablePageSizeSelect from './portal-data-table-page-size-select';
import ApiReduxTableDisplayControls from './table-controls/api-redux-display-controls';
import ApiReduxTableSearchControls from './table-controls/api-redux-search-controls';
import {
  tableData,
  dataError,
  dataLoading,
} from '../../actions/portal-data-table/fetch-table-data';

import { clearColumnFilters } from '../../actions/portal-data-table/filter-actions';
import { updateTableSettings } from '../../actions/portal-data-table/update-settings';
import {
  tableSettingsToQueryString, queryStringToTableSettings, tableSettingsToQueryParamKeyStringValue, queryStringToTableSettingsQueryString,
} from '../../utils/datatable-query-string-parameters';
import { shallowAreObjectsDifferent } from '../../helpers/shallow-are-objects-different';
import { deepCompare } from '../../helpers/deep-compare.helper';
import { connectToAPIProvider } from '../providers/api-provider';
import API_PROVIDER_PROP_TYPES from '../../prop-types/api-provider-prop-types';
import { connectToCurrentUserProvider } from '../providers/current-user-provider';
import { CURRENT_USER_PROVIDER_PROP_TYPES } from '../../prop-types/current-user-provider-prop-types';
import { THEME_COLOR } from '../../constants/theme-color.const';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { DATA_TABLE_FLAG } from '../../constants/data-table-flag.const';
import { FILTER_OPERATION } from '../../constants/filter-operation.const';

// Used for the purposes of debugging the portal data table component update lifecycle
let pdtLifeCycleIndex = 0;


/**
 * Common CI datatable used for multiple APIs.
 * @see https://wiki.ciportal.net/books/ci-portal-developer-documentation/page/portal-data-table-event-lifecycle
 * <PortalDataTable />
 */
class PortalDataTable extends Component {
  /**
   * @constructor
   *
   * @param {object} props
   */
  constructor(props) {
    super(props);

    // Set the initial state
    this.state = {
      isColumnFilterDrawerVisible: false,
      isFullScreenActive: false,
      isColumnManagerOpen: false,
    };

    this.abortController = null;
    this.debounce = null;
    this.tableWrapperRef = React.createRef();
    this.overflowIndicatorLeftRef = React.createRef();
    this.overflowIndicatorRightRef = React.createRef();
    this.tableResizeListener = new EventEmitter();
    this.tableScrollListener = new EventEmitter();
  }


  /**
   * <PortalDataTable /> mounted
   */
  componentDidMount() {
    const {
      title, location, dispatchUpdateTableSettings,
    } = this.props;

    // Update the page title
    documentTitle(title || null);


    // Update the current settings from the url
    const { updatedSettings } = this.updateTableSettingsFromUrl(location.search, this.props);

    // If the URL contains the "default" table data settings we need to fire a loadData manually to get the initial recordset
    // if (!tableDataSettingsChanged) {
    this.loadData(updatedSettings);
    // }

    // Push the default or url updated settings to Redux
    this.updateUrlFromTableSettings(updatedSettings, PUSH_OR_REPLACE.REPLACE);
    dispatchUpdateTableSettings({ ...updatedSettings, replaceInHistory: true });

    this.updateOverflowIndicators();

    window.addEventListener('resize', this.handleWindowResize);
  }


  /**
   * @inheritdoc
   * @see https://wiki.ciportal.net/books/ci-portal-developer-documentation/page/portal-data-table-event-lifecycle
   * @param {{}} nextProps
   */
  shouldComponentUpdate(nextProps) {
    pdtLifeCycleIndex += 1;

    // Compare the View, Pager, Column Filters, Column Sorts and Search properties
    const {
      isTextWrappingEnabled,
      isTightModeEnabled,
      viewKey,
      orderedVisibleColumns,
      location,
      views,
      tableIdentifier,
      dispatchUpdateTableSettings,
      reportFilters: oldReportFilters,
      baseFilters: oldBaseFilters,
      currentUserProvider: { userPermissionsChecksum },
    } = this.props;

    const {
      isTextWrappingEnabled: newIsTextWrappingEnabled,
      isTightModeEnabled: newIsTightModeEnabled,
      viewKey: newViewKey,
      sortedColumns: newSortedColumns,
      orderedVisibleColumns: newOrderedVisibleColumns,
      location: newLocation,
      pushToHistory,
      replaceInHistory,
      forceReapplyViewDefaults,
      reloadTable,
      urlIdentifier,
      reportFilters: newReportFilters,
      baseFilters: newBaseFilters,
      currentUserProvider: { userPermissionsChecksum: newUserPermissionsChecksum },
    } = nextProps;

    const oldPropsSettings = this.validateAndCleanTableSettings(this.props);
    const nextPropsSettings = this.validateAndCleanTableSettings(nextProps);

    const oldLocationSearch = queryStringToTableSettingsQueryString(location.search, urlIdentifier) || '';
    const newLocationSearch = queryStringToTableSettingsQueryString(newLocation.search, urlIdentifier) || '';

    if (reloadTable || (userPermissionsChecksum !== newUserPermissionsChecksum)) {
      // reload table and bail on any other state changes
      // this only works because reloadTable implies NO other props have changed
      this.loadData(nextPropsSettings);
      dispatchUpdateTableSettings({ reloadTable: false });
      return false;
    }

    // Check to see if the view has changed and potentially apply any sorted columns that
    // may be defined in the selected view
    if (viewKey !== newViewKey || forceReapplyViewDefaults) {
      if (this.hasViews && newViewKey) {
        const view = getItemByKey(views, newViewKey);
        // Does the new view have any sorted column "defaults" to apply to the current sortedColumns list?
        if (view && ('sortedColumns' in view) && (view.sortedColumns.length > 0)) {
          const sortedColumnsForDispatch = [...newSortedColumns];
          view.sortedColumns.sort((a, b) => (a.sortIndex - b.sortIndex)).forEach((viewSortedColumn) => {
            // If the field is not already in the currently sortedColumns
            if (!getItemByKey(newSortedColumns, viewSortedColumn.name, 'name')) {
              // Add the field to the end of the existing sorted columns
              const newSortIndex = (getMaxSortIndex(newSortedColumns) || -1) + 1;
              sortedColumnsForDispatch.push({
                ...viewSortedColumn,
                sortIndex: newSortIndex,
              });
            }
          });

          // If the new sorted columns are different, dispatch and don't allow render
          if (!deepCompare(newSortedColumns, sortedColumnsForDispatch)) {
            //  Update the url to select the new sorted columns
            this.updateUrlFromTableSettings({ ...nextPropsSettings, sortedColumns: sortedColumnsForDispatch }, PUSH_OR_REPLACE.REPLACE);
            saveTableStateToLocalStorage(tableIdentifier, nextProps);
            dispatchUpdateTableSettings({});
            return false;
          }
        }
      }
    }

    // Convert the new settings to a url
    const nextPropsSearchUrl = tableSettingsToQueryString(nextPropsSettings, urlIdentifier);

    // Was there a window navigation event? (back / history / forward etc...)
    if ((oldLocationSearch !== newLocationSearch) && oldLocationSearch.trim() !== '') {
      debugLog(`PDT Lifecycle ${pdtLifeCycleIndex} - shouldComponentUpdate - Location Changed`, 'info', {
        location_search: oldLocationSearch, newLocation_search: newLocationSearch,
      }, '👨‍⚕️');

      // In the case where there is a blank url - reset the table settings
      // (This typically happens when the user re-navigates to the same menu item in the nav)
      if (newLocationSearch.trim() === '') {
        // Reset the table settings
        const resetTableSettings = this.resetTableSettings(nextPropsSettings);
        dispatchUpdateTableSettings(resetTableSettings);
        this.updateUrlFromTableSettings(resetTableSettings, PUSH_OR_REPLACE.REPLACE);
        return false;
      }

      // currentSearchUrl is the source of truth so pull any and all settings from the current url
      const { updatedSettings, tableDataSettingsChanged, UISettingsChanged } = this.updateTableSettingsFromUrl(newLocationSearch, nextPropsSettings);
      if (!tableDataSettingsChanged && !UISettingsChanged) {
        // Update the current URL to reflect the updated settings
        this.updateUrlFromTableSettings(updatedSettings, PUSH_OR_REPLACE.REPLACE);
      }
      else {
        // Update those settings in Redux so that we loop back around and ultimately perform a loadData() (if required)
        dispatchUpdateTableSettings(updatedSettings);
      }
    }

    // Have we changed something in props that would require a data re-load?
    else if (!this.compareTableSettings(
      oldPropsSettings,
      nextPropsSettings,
      [
        'viewKey',
        'activePage',
        'pageSize',
        'searchTerm',
      ],
      [
        'flags',
        'sortedColumns',
        'filteredColumns',
      ],
    )) {
      // Props are the source of truth
      // Load the data from the API
      debugLog(`PDT Lifecycle ${pdtLifeCycleIndex} - shouldComponentUpdate - Data Props changed`, 'info', { oldPropsSettings, nextPropsSettings }, '👨‍⚕️');
      this.loadData(nextPropsSettings);
    }

    else if (
      // Otherwise, has there been a URL change (i.e. a back / forward navigation)?
      (newLocationSearch !== nextPropsSearchUrl) ||

      // Have we changed something in props that is for the UI only which would require a URL update?
      (!this.compareTableSettings(oldPropsSettings, nextPropsSettings, ['activeTabKey', 'openRowId'], []))

    ) {
      // Push the new URL to history?
      if (pushToHistory) {
        debugLog(`PDT Lifecycle ${pdtLifeCycleIndex} - shouldComponentUpdate - Pushing new location to history`, 'info', {
          newLocation_search: newLocationSearch, nextPropsSearchUrl,
        }, '👨‍⚕️');
        this.updateUrlFromTableSettings(nextPropsSettings, PUSH_OR_REPLACE.PUSH);
      }

      // Otherwise, replace the new URL in the current history stack entry?
      else if (replaceInHistory) {
        debugLog(`PDT Lifecycle ${pdtLifeCycleIndex} - shouldComponentUpdate - Replacing new location in history`, 'info', {
          newLocation_search: newLocationSearch, nextPropsSearchUrl,
        }, '👨‍⚕️');
        this.updateUrlFromTableSettings(nextPropsSettings, PUSH_OR_REPLACE.REPLACE);
      }
    }

    // Store specific UI changes to local storage
    if (
      (isTightModeEnabled !== newIsTightModeEnabled) ||
      (isTextWrappingEnabled !== newIsTextWrappingEnabled) ||
      !deepCompare(newOrderedVisibleColumns, orderedVisibleColumns)
    ) {
      saveTableStateToLocalStorage(tableIdentifier, nextProps);
    }

    // are report filters different?
    if ((oldReportFilters !== newReportFilters) && shallowAreObjectsDifferent(oldReportFilters, newReportFilters)) {
      this.loadData(nextPropsSettings, newReportFilters);
    } else if ((oldBaseFilters !== newBaseFilters) && !deepCompare(oldBaseFilters, newBaseFilters)) {
      this.loadData(nextPropsSettings, newReportFilters);
    }

    // Allow the render
    return true;
  }


  /**
   * componentDidUpdate
   */
  componentDidUpdate(/* prevProps, prevState */) {
    this.updateOverflowIndicators();
    this.checkBodyClasses();
  }


  /**
   * getDerivedStateFromProps
   */
  static getDerivedStateFromProps(props, state) {
    if (
      'filteredColumns' in props &&
      Array.isArray(props.filteredColumns) &&
      props.filteredColumns.length > 0 &&
      !state.isColumnFilterDrawerVisible
    ) {
      return {
        ...state,
        isColumnFilterDrawerVisible: true,
      };
    }
    return state;
  }


  /**
   * componentWillUnmount
   */
  componentWillUnmount() {
    if (this.abortController) {
      this.abortController.abort();
    }
    clearTimeout(this.debounce);

    window.removeEventListener('resize', this.handleWindowResize);
  }


  get hasViews() {
    const { views } = this.props;
    return !!views.length;
  }

  get scrollTop() {
    return this.tableWrapperRef && this.tableWrapperRef.current ? this.tableWrapperRef.current.scrollTop : null;
  }

  get scrollLeft() {
    return this.tableWrapperRef && this.tableWrapperRef.current ? this.tableWrapperRef.current.scrollLeft : null;
  }

  get clientWidth() {
    return this.tableWrapperRef && this.tableWrapperRef.current ? this.tableWrapperRef.current.clientWidth : null;
  }

  get scrollWidth() {
    return this.tableWrapperRef && this.tableWrapperRef.current ? this.tableWrapperRef.current.scrollWidth : null;
  }


  /**
   * @description
   * Evaluate two table settings objects and return true if they are the same
   *
   * @param {object} oldSettings
   * @param {object} newSettings
   * @param {object} keysToCompare e.g. `['viewKey', 'activePage', 'pageSize', 'searchTerm']`
   * @param {object} keysToDeepCompare e.g. `['flags', 'sortedColumns', 'filteredColumns']`
   */
  compareTableSettings = (oldSettings, newSettings, keysToCompare, keysToDeepCompare) => {
    let result = true;

    // Both settings objects exist
    result = result && (oldSettings instanceof Object) && (newSettings instanceof Object);

    if (!(oldSettings instanceof Object)) debugLog(`PDT Lifecycle ${pdtLifeCycleIndex} - compareTableSettings - oldSettings is not an object`, 'info', undefined, '👨‍⚕️');
    if (!(newSettings instanceof Object)) debugLog(`PDT Lifecycle ${pdtLifeCycleIndex} - compareTableSettings - newSettings is not an object`, 'info', undefined, '👨‍⚕️');

    // Shallow compare keys match
    keysToCompare.forEach((key) => {
      result = result && (
        ((key in oldSettings) &&
          (key in newSettings) &&
          (oldSettings[key] === newSettings[key])
        ) || (
          oldSettings[key] === undefined &&
          newSettings[key] === undefined
        ));
      if (
        !((key in oldSettings) &&
          (key in newSettings) &&
          (oldSettings[key] === newSettings[key])
        ) && !(!(key in oldSettings) && !(key in newSettings))
      ) { debugLog(`PDT Lifecycle ${pdtLifeCycleIndex} - compareTableSettings: "${key}" is different`, 'info', {
        'oldSettings[key]': oldSettings[key], 'newSettings[key]': newSettings[key],
      }, '👨‍⚕️'); }
    });

    // Deep compare keys match
    keysToDeepCompare.forEach((key) => {
      result = result && deepCompare(oldSettings[key], newSettings[key]);
      if (!(deepCompare(oldSettings[key], newSettings[key]))) { debugLog(`PDT Lifecycle ${pdtLifeCycleIndex} - compareTableSettings - "${key}" is different`, 'info', {
        [`old[${key}]`]: JSON.stringify(oldSettings[key]), [`new[${key}]`]: JSON.stringify(newSettings[key]),
      }, '👨‍⚕️'); }
    });

    return result;
  }


  /**
   * @description
   * Take a table settings object and re-apply the "default" (i.e not set) values
   *
   * @param {object} oldSettings
   *
   * @returns {object} the reset table settings object
   */
  resetTableSettings = (oldSettings) => ({
    ...oldSettings,
    searchTerm: null,
    activePage: 1,
    filteredColumns: [],
    sortedColumns: [],
    flags: [],
    pushToHistory: false,
    replaceInHistory: false,
    forceReapplyViewDefaults: true,
    openRowId: null,
    activeTabKey: null,
    // pageSize: <- leave pageSize alone
    // viewKey: <- leave viewKey alone
  })


  /**
   * @description
   * Update the browser url with the contents of the new table settings
   */
  updateUrlFromTableSettings = (tableSettings, pushOrReplace) => {
    const {
      history, urlIdentifier, location,
    } = this.props;

    const { search: oldQueryString } = location;
    const urlParams = toParams(oldQueryString);
    // append table settings to the query string
    const [tableUrlKey, tableUrlValue] = tableSettingsToQueryParamKeyStringValue(tableSettings, urlIdentifier);
    urlParams[tableUrlKey] = tableUrlValue;
    const newQueryString = toQueryString(urlParams);

    debugLog(`PDT Lifecycle ${pdtLifeCycleIndex} - updateUrlFromTableSettings`, 'info', {
      tableSettings, urlParams, pushOrReplace, newQueryString,
    }, '👨‍⚕️');

    // Replace the current location URL
    if (pushOrReplace === PUSH_OR_REPLACE.REPLACE) {
      history.replace({ search: newQueryString });
      return;
    }

    // Push to History
    history.push({ search: newQueryString });
  }


  /**
   * @description
   * Parse a URL string and update the props associated with parameters stored in the URL
   * Returns an object with flags that can be used to determine if other actions should be performed
   * {
   *  updatedSettings: the passed in settings (oldSettings) augmented by the settings extracted from the URL
   *  tableDataSettingsChanged: true if any of the settings which trigger data fetches were different from those in the oldSettings
   *  UISettingsChanged: true if any of the settings which drive UI behaviour were different from those in the oldSettings
   * }
   *
   * @param {string} url
   * @param {object} oldSettings
   *
   * @returns {{
   *  updatedSettings: object,
   *  tableDataSettingsChanged: boolean,
   *  UISettingsChanged: boolean
   * }}
   */
  updateTableSettingsFromUrl = (url, oldSettings) => {
    const { urlIdentifier } = this.props;

    // Convert the URL to a settings object and clean it up
    let updatedSettings = queryStringToTableSettings(url, urlIdentifier);
    updatedSettings = this.validateAndCleanTableSettings(updatedSettings);

    const result = {
      updatedSettings,
      tableDataSettingsChanged: false,
      UISettingsChanged: false,
    };

    // Have any of the settings changed? (both data and UI settings)
    result.tableDataSettingsChanged = !this.compareTableSettings(
      oldSettings,
      updatedSettings,
      [
        'viewKey',
        'activePage',
        'pageSize',
        'searchTerm',
      ],
      [
        'flags',
        'sortedColumns',
        'filteredColumns',
      ],
    );

    // Have any of the UI settings changed?
    result.UISettingsChanged = !this.compareTableSettings(
      oldSettings,
      updatedSettings,
      [
        'openRowId',
        'activeTabKey',
      ],
      [],
    );

    return result;
  }


  /**
   * @description
   * Analyses the table and determines if either of the left / right overflow indicators
   * need to be shown and at what level.
   */
  updateOverflowIndicators = () => {
    const indicationZone = 30.0;

    const leftOpacity = Math.min(this.scrollLeft / indicationZone, 1);
    const rightOpacity = Math.min((this.scrollWidth - (this.scrollLeft + this.clientWidth)) / indicationZone, 1);

    if (this.overflowIndicatorLeftRef && this.overflowIndicatorLeftRef.current) {
      this.overflowIndicatorLeftRef.current.style.opacity = leftOpacity;
    }

    if (this.overflowIndicatorRightRef && this.overflowIndicatorRightRef.current) {
      this.overflowIndicatorRightRef.current.style.opacity = rightOpacity;
    }
  }


  /**
   * @description
   * We need to add a class to the body whenever the portal data table is full screen to
   * assist with some z-index shuffling
   */
  checkBodyClasses = () => {
    const { isFullScreenActive } = this.state;

    if (!isFullScreenActive && document.body.classList.contains('has-full-screen-table')) {
      document.body.classList.remove('has-full-screen-table');
    }

    else if (isFullScreenActive && !document.body.classList.contains('has-full-screen-table')) {
      document.body.classList.add('has-full-screen-table');
    }
  }


  /**
   * @description
   * Used by methods that receive updates from user controls or changes to the window.location
   * to validate and clean the settings we're about to apply to the table
   *
   * @returns {{}} the validated / cleaned settings object
   */
  validateAndCleanTableSettings = (settings) => {
    const newSettings = {};
    const {
      columns,
      views,
      availableFlags,
      viewKey,
    } = this.props;

    // Check the supplied view and/or set to the default view
    if (this.hasViews) {
      newSettings.viewKey = viewKey;
      if (('viewKey' in settings) && getItemByKey(views, settings.viewKey)) {
        newSettings.viewKey = settings.viewKey;
      }
    }

    // Page Size
    newSettings.pageSize = PAGE_SIZE_DEFAULT;
    if ('pageSize' in settings && !Number.isNaN(parseInt(settings.pageSize, 10)) && parseInt(settings.pageSize, 10) > 0) {
      newSettings.pageSize = parseInt(settings.pageSize, 10);
    }

    // Page Number
    newSettings.activePage = 1;
    if ('activePage' in settings && !Number.isNaN(parseInt(settings.activePage, 10)) && parseInt(settings.activePage, 10) > 0) {
      newSettings.activePage = parseInt(settings.activePage, 10);
    }

    // Search
    newSettings.searchTerm = null;
    if ('searchTerm' in settings && typeof settings.searchTerm === 'string' && settings.searchTerm.trim()) {
      newSettings.searchTerm = settings.searchTerm.trim();
    }

    // Flags
    newSettings.flags = [];
    if ('flags' in settings) {
      // Validate that a provided flag is available for the current table
      const newFlags = settings.flags.filter((flag) => availableFlags.includes(flag));
      if (newFlags.length) {
        newSettings.flags = newFlags;
      }
    }

    // SortedColumns
    newSettings.sortedColumns = [];
    if ('sortedColumns' in settings) {
      // filter the sorted columns for columns that exist on the table definition
      const newSortedColumns = settings.sortedColumns.filter((sortedColumn) => (
        // Column exists in the list of table columns
        getItemByKey(columns, sortedColumn.name, 'name') &&
        // Sort direction is one of the possible sort directions
        Object.values(SORT_DIRECTION).includes(sortedColumn.direction)
      ));
      newSettings.sortedColumns = newSortedColumns;
    }

    // Filtered Columns
    newSettings.filteredColumns = [];
    if ('filteredColumns' in settings) {
      // filter the filtered columns for columns that exist on the table definition
      const newFilteredColumns = settings.filteredColumns.filter((filteredColumn) => (
        // Field exists in the list of table columns
        getItemByKey(columns, filteredColumn.name, 'name')

        // TODO: someday validate the operation
      ));
      newSettings.filteredColumns = newFilteredColumns;
    }

    // OpenRowId
    newSettings.openRowId = null;
    if ('openRowId' in settings) {
      newSettings.openRowId = settings.openRowId;
    }

    // activeTabKey
    newSettings.activeTabKey = null;
    if ('activeTabKey' in settings) {
      newSettings.activeTabKey = settings.activeTabKey;
    }

    // action
    newSettings.action = null;
    if ('action' in settings) {
      newSettings.action = settings.action;
    }

    return newSettings;
  }


  /**
   * Fired when the select view dropdown is changed
   * @param {string} newViewKey
   */
  handleSetView = (newViewKey) => {
    const {
      views,
      viewKey,
      dispatchUpdateTableSettings,
    } = this.props;

    if ((viewKey !== newViewKey) && getItemByKey(views, newViewKey)) {
      dispatchUpdateTableSettings({ viewKey: newViewKey, pushToHistory: true });
    }
  };


  /**
   * @description
   * Fired when the select view dropdown "flags" are toggled on or off
   *
   * @param {string} flag
   * @returns {void}
   */
  handleToggleFlag = (flag) => {
    const {
      availableFlags,
      flags,
      dispatchUpdateTableSettings,
    } = this.props;

    if (availableFlags.includes(flag)) {
      const newFlags = [...flags];
      const index = flags.indexOf(flag);

      // toggle the flag
      if (index === -1) newFlags.push(flag);
      else newFlags.splice(index, 1);

      dispatchUpdateTableSettings({ flags: newFlags, pushToHistory: true });
    }
  }


  /**
   * Used to show and hide filter columns in datatable
   */
  handleToggleColumnFilterDrawer = () => {
    const { isColumnFilterDrawerVisible } = this.state;
    const { filteredColumns, dispatchClearColumnFilters } = this.props;
    if (isColumnFilterDrawerVisible && (filteredColumns.length > 0)) {
      dispatchClearColumnFilters(true);
      this.setState({
        isColumnFilterDrawerVisible: false,
      });
    }
    else {
      this.setState({
        isColumnFilterDrawerVisible: !isColumnFilterDrawerVisible,
      });
    }
  };


  /**
   * Toggle fullscreen table view
   */
  handleToggleFullScreen = () => {
    const { isFullScreenActive } = this.state;
    this.setState({ isFullScreenActive: !isFullScreenActive });
  };


  /**
   * Toggle the column manager
   */
  handleToggleShowColumnManager = () => {
    const { isColumnManagerOpen } = this.state;
    this.setState({ isColumnManagerOpen: !isColumnManagerOpen });
  };


  /**
   * @description
   * Reset the table to its default settings
   *
   * @param {boolean} [pushToHistory=false] whether to push the changes resulting from the action to history
   * @param {boolean} [replaceInHistory=false] whether to replace the changes resulting from the action in history
   */
  handleResetTableSettings = (pushToHistory = false, replaceInHistory = false) => {
    const { dispatchUpdateTableSettings } = this.props;
    let resetTableSettings = this.resetTableSettings(this.validateAndCleanTableSettings(this.props));
    resetTableSettings = {
      ...resetTableSettings,
      pushToHistory,
      replaceInHistory,
    };
    dispatchUpdateTableSettings(resetTableSettings);
  }


  handleDownloadViewAsExcel = () => {
    this.loadData(this.props, {}, true);
  }


  /**
   * @description
   * Fired when the user scrolls the portal data table
   */
  handleScroll = () => {
    this.updateOverflowIndicators();
    this.tableScrollListener.emit('scroll', this.scrollLeft, this.scrollWidth);
  }


  /**
   * @description
   * Fired when the window is resized
   */
  handleWindowResize = () => {
    this.updateOverflowIndicators();
  }


  /**
   * @description
   * Fired when the wrapper around the table is resized and notifies any children
   * who are subscribed to the tableResizeListener that the table width has changed.
   */
  handleTableWidthResize = (width) => {
    this.tableResizeListener.emit('resizeWidth', width);
  }


  /**
   * @description
   * Request new data for table
   *
   * @see https://wiki.ciportal.net/books/ci-portal-developer-documentation/page/portal-data-table-event-lifecycle
   *
   * @param {{
   *  activePage?: number,
   *  pageSize?: number,
   *  flags?: string[],
   *  searchTerm: string,
   *  viewKey: string
   * }} settings table (query) settings to use to load data
   * @param {{} | undefined} [newReportFilters=undefined]
   */
  // eslint-disable-next-line consistent-return
  loadData = async (settings, newReportFilters, asExcelDownload = false) => {
    // static table settings
    const {
      rowKeyField,
      baseRoute,
      baseQueryString,
      baseFilters,
      baseFlags,
      columns,
      views,
      dispatchDataLoading,
      dispatchTableData,
      dispatchDataError,
      reportFilters: oldReportFilters,
      apiProvider: { apiFetch, apiFetchBlob },
      availableActions,
    } = this.props;

    const {
      activePage,
      pageSize,
      flags,
      searchTerm,
      viewKey,
      sortedColumns,
      filteredColumns,
    } = settings;

    dispatchDataLoading(true);

    // Get the current view object (if any)
    const view = getItemByKey(views, viewKey);
    const viewFilters = view ? view.filters : [];
    const viewFlags = view ? view.flags : [];

    // Transform filteredColumns into columnFilters
    const columnFilters = filteredColumns.map((filteredColumn) => {
      const column = getItemByKey(columns, filteredColumn.name, 'name');
      let filterValues = filteredColumn.values instanceof Array ? filteredColumn.values : [filteredColumn.values];
      filterValues = filterValues.map((filterValue) => realFilterValue(filterValue));

      return {
        field: realFilterField(column),
        operation: filteredColumn.operation,
        values: filterValues,
      };
    });

    const reportFilters = newReportFilters || oldReportFilters;

    const queryParams = [
      baseQueryString,
      ...(reportFilters ? Object.entries(reportFilters).map(([reportFilterKey, reportFilterValue]) => `${reportFilterKey}=${reportFilterValue}`) : []),
      makeFilterString([...(baseFilters || []), ...(viewFilters || []), ...(columnFilters || [])]),
      (baseFlags || []).join('&'),
      (viewFlags || []).join('&'),
      (flags || []).join('&'),
      ...((typeof searchTerm === 'string' && searchTerm.trim()) ? [`search=${searchTerm.trim()}`] : []),
      ...((activePage && activePage > 1) ? [`page=${activePage}`] : []),
      ...((pageSize && pageSize > 0) ? [`pagelength=${pageSize}`] : []),
      ...((sortedColumns.length > 0) ? [makeSortStringFromSortedColumns(sortedColumns, columns)] : []),
    ];

    // merge sort from sorted columns & from view
    // if there are sortedColumns, insert sortedColumns first, then insert view sort

    const apiSearchParamsString = `?${queryParams.filter((param) => param !== '').join('&')}`;

    const url = `${baseRoute}${apiSearchParamsString}`;

    if (asExcelDownload) {
      // eslint-disable-next-line react/prop-types
      const downloadUrl = `${availableActions['download-excel'].link}${apiSearchParamsString}`;
      const viewName = views.filter((filterView) => filterView.key === viewKey)[0].title;
      const filtered = filteredColumns.length > 0 ? '-filtered' : '';
      const searched = searchTerm ? ` Search - ${searchTerm}` : '';
      const filename = (`${viewName}${filtered}${searched}`).replace(/[^a-z0-9,& -]/gi, '_');
      const response = await apiFetchBlob(downloadUrl);
      if (response.blob) {
        const href = window.URL.createObjectURL(await response.blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename || 'ci-portal-download.xlsx');
        document.body.appendChild(link);
        link.click();
      }
      dispatchDataLoading(false);
      return true;
    }

    debugLog(`PDT Lifecycle ${pdtLifeCycleIndex} - loadData`, 'info', { url }, '👨‍⚕️');

    // Abort any previous loads
    if (this.abortController) this.abortController.abort();
    this.abortController = apiAborter();

    const response = await apiFetch(url, { signal: this.abortController.signal });

    if (response.success) {
      this.abortController = null;
      const totalRecords = response.body.total || response.body.meta.total;
      // minimum of 1
      const totalPages = Math.ceil(totalRecords / pageSize) || 1;
      const newAvailableActions = response.body.actions || {};

      // Double check that the activePage is within the totalPages range
      let newActivePage = activePage;
      if (activePage > totalPages) {
        newActivePage = totalPages;

        const newSettings = this.validateAndCleanTableSettings({
          ...settings,
          activePage: newActivePage,
        });
        // replace the current URL if the page number changed.
        this.updateUrlFromTableSettings(newSettings, PUSH_OR_REPLACE.REPLACE);
      }

      // Check to see if the openRowId exists in the record set
      const { openRowId } = settings;
      if (openRowId && !response.body.data.find((record) => String(record[rowKeyField]) === String(openRowId))) {
        const newSettings = this.validateAndCleanTableSettings({
          ...settings,
          openRowId: null,
        });

        // replace the current URL if the page number changed.
        this.updateUrlFromTableSettings(newSettings, PUSH_OR_REPLACE.REPLACE);
      }

      // Success, update via redux
      dispatchDataLoading(false);
      dispatchTableData(response.body.data, newActivePage, totalRecords, newAvailableActions);
    } else if (!response.aborted) {
      this.abortController = null;
      dispatchDataError(true, response.error);
      dispatchDataLoading(false);
    }
  };


  // Render <PortalDataTable />
  render() {
    const {
      recordSet,
      tableIdentifier,
      title,
      hasError,
      isLoading,
      colorTheme,
      columns,
      onCloseTable,
      views,
      viewKey,
      history,
      location,
      match,
      availableFlags,
      flags,
      isTightModeEnabled,
      isTextWrappingEnabled,
      availableActions,
      onDirtyRowChange,
      rowKeyField,
    } = this.props;

    const {
      isFullScreenActive,
      isColumnFilterDrawerVisible,
      isColumnManagerOpen,
    } = this.state;

    if (!columns) {
      return (
        <div className="note note-danger">
          <h4 className="text-danger">
            Table Definition Not Found
            <code> initialState in reducers/portal-datatable/settings-reducer.js</code>
          </h4>
        </div>
      );
    }

    const canDownloadExcel = 'download-excel' in availableActions;
    const downloadHandler = canDownloadExcel ? this.handleDownloadViewAsExcel : undefined;

    // Return <PortalDataTable />
    return (
      <Card className={classNames({ 'full-screen': isFullScreenActive }, 'portal-data-table shadow')}>
        <CardBody>
          <ReactResizeDetector handleWidth onResize={this.handleTableWidthResize}>
            <>

              {/* display an error message if something has gone wrong with the most recent data fetch */}
              {hasError && (
                <div className="error-wrapper">
                  <Button
                    color="danger"
                    onClick={() => {
                      // load the base page without any query params ie, default table
                      history.push({ search: '' });
                    }}
                  >
                    Request Error. Try refreshing the page.
                  </Button>
                </div>
              )}

              {/* Table header including search controls */}
              <div className="table-header-wrapper">

                {/* Table Title or View Select List */}
                <div className="title-wrapper">
                  {!this.hasViews && <h4>{title}</h4>}
                  {this.hasViews && (
                    <ViewSelect
                      views={views}
                      viewKey={viewKey}
                      availableFlags={availableFlags}
                      flags={flags}
                      onToggleFlag={this.handleToggleFlag}
                      onSetView={this.handleSetView}
                    />
                  )}
                </div>

                {/* Table Search Controls */}
                <div className="search-controls-wrapper">
                  <ApiReduxTableSearchControls
                    tableIdentifier={tableIdentifier}
                    location={location}
                    isColumnFilterDrawerVisible={isColumnFilterDrawerVisible}
                    onToggleColumnFilterDrawer={this.handleToggleColumnFilterDrawer}
                    onResetTableSettings={this.handleResetTableSettings}
                  />
                </div>

                {/* Table Display Controls */}
                <div className="display-controls-wrapper">
                  <ApiReduxTableDisplayControls
                    tableIdentifier={tableIdentifier}


                    isColumnManagerOpen={isColumnManagerOpen}
                    isColumnFilterDrawerVisible={isColumnFilterDrawerVisible}
                    isTextWrappingEnabled={isTextWrappingEnabled}
                    isFullScreenActive={isFullScreenActive}
                    isTightModeEnabled={isTightModeEnabled}

                    // eslint-disable-next-line react/prop-types
                    onDownloadViewAsExcel={downloadHandler}
                    onCloseTable={onCloseTable}
                    onToggleFullScreen={this.handleToggleFullScreen}
                    onToggleShowColumnManager={this.handleToggleShowColumnManager}
                  />
                </div>

                {/* Column Manager */}
                {isColumnManagerOpen && (
                  <div className="column-manager-wrapper">
                    <ColumnManager tableIdentifier={tableIdentifier} />
                  </div>
                )}

                {/* Leading Results Text */}
                <div className="leading-results-text-wrapper">
                  <PortalDataTableResultsText tableIdentifier={tableIdentifier} />
                </div>

                {/* Leading Paginator */}
                <div className="leading-pagination-wrapper">
                  <PortalDataTablePagination tableIdentifier={tableIdentifier} />
                </div>
              </div>

              {/* Table */}
              <div className="table-wrapper">
                <div
                  ref={this.tableWrapperRef}
                  className={classNames(
                    'table-responsive',
                    {
                      busy: isLoading,
                      'no-wrap': !isTextWrappingEnabled,
                      'no-records': (!isLoading && (!recordSet || recordSet.length === 0)),
                    },
                  )}
                  style={{ minHeight: '300px' }}
                  onScroll={this.handleScroll}
                >
                  <PortalTable
                    isColumnFilterDrawerVisible={isColumnFilterDrawerVisible}
                    recordSet={recordSet}
                    tableIdentifier={tableIdentifier}
                    colorTheme={colorTheme}
                    isTightModeEnabled={isTightModeEnabled}
                    history={history}
                    location={location}
                    match={match}
                    rowKeyField={rowKeyField}
                    tableWrapperRef={this.tableWrapperRef}
                    tableResizeListener={this.tableResizeListener}
                    tableScrollListener={this.tableScrollListener}
                    onDirtyRowChange={onDirtyRowChange}
                  />
                </div>
                <div ref={this.overflowIndicatorLeftRef} className="overflow-indicator left" />
                <div ref={this.overflowIndicatorRightRef} className="overflow-indicator right" />
              </div>

              {/* Table footer */}
              <div className="table-footer-wrapper">

                {/* Trailing Results Text */}
                <div className="trailing-results-text-wrapper">
                  <PortalDataTableResultsText tableIdentifier={tableIdentifier} />
                </div>

                {/* Page Size Select */}
                <div className="page-size-select-wrapper">
                  <PortalDataTablePageSizeSelect tableIdentifier={tableIdentifier} />
                </div>

                {/* Trailing Paginator */}
                <div className="trailing-pagination-wrapper">
                  <PortalDataTablePagination tableIdentifier={tableIdentifier} />
                </div>
              </div>
            </>
          </ReactResizeDetector>

        </CardBody>
      </Card>
    );
  }
}

PortalDataTable.defaultProps = {
  urlIdentifier: undefined,
  baseFilters: [],
  baseFlags: [],
  filteredColumns: [],
  sortedColumns: [],
  colorTheme: 'primary',
  rowKeyField: 'id',
  onCloseTable: null,
  onDirtyRowChange: null,
  viewKey: undefined,

  availableActions: {},
  reportFilters: null,

  // Props loaded via redux state
  ...defaultTableSettings,
};

PortalDataTable.propTypes = {
  tableIdentifier: PropTypes.string.isRequired,
  baseFilters: PropTypes.arrayOf(PropTypes.shape({})),
  baseFlags: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DATA_TABLE_FLAG))),
  rowKeyField: PropTypes.string,

  availableFlags: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DATA_TABLE_FLAG))).isRequired,
  flags: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DATA_TABLE_FLAG))).isRequired,
  viewKey: PropTypes.string,
  filteredColumns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      operation: PropTypes.oneOf(Object.values(FILTER_OPERATION)).isRequired,
      values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
    }),
  ),
  sortedColumns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      direction: PropTypes.oneOf(Object.values(SORT_DIRECTION)).isRequired,
      sortIndex: PropTypes.number.isRequired,
    }),
  ),
  availableActions: PropTypes.shape({
    'download-excel': PropTypes.shape({}),
  }),

  onCloseTable: PropTypes.func,
  onDirtyRowChange: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  urlIdentifier: PropTypes.string,

  // Router
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape(HISTORY_PROP_TYPES).isRequired,
  match: PropTypes.shape({}).isRequired,
  colorTheme: PropTypes.oneOf(Object.values(THEME_COLOR)),
  // State (table settings) - these should either be set in default-table-settings or
  // this particular table settings
  recordSet: PropTypes.arrayOf(PropTypes.shape({})),
  // eslint-disable-next-line react/no-unused-prop-types
  totalRecords: PropTypes.number,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isTextWrappingEnabled: PropTypes.bool.isRequired,
  isTightModeEnabled: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  activePage: PropTypes.number.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  pageSize: PropTypes.number.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  // eslint-disable-next-line react/no-unused-prop-types
  searchTerm: PropTypes.string,
  views: PropTypes.arrayOf(PropTypes.shape({})),
  baseQueryString: PropTypes.string.isRequired,
  orderedVisibleColumns: PropTypes.arrayOf(PropTypes.shape({})),
  baseRoute: PropTypes.string.isRequired,
  pushToHistory: PropTypes.bool.isRequired,
  replaceInHistory: PropTypes.bool.isRequired,
  forceReapplyViewDefaults: PropTypes.bool.isRequired,

  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
  currentUserProvider: PropTypes.shape(CURRENT_USER_PROVIDER_PROP_TYPES).isRequired,

  // Dispatch
  dispatchUpdateTableSettings: PropTypes.func.isRequired,
  dispatchTableData: PropTypes.func.isRequired,
  dispatchDataError: PropTypes.func.isRequired,
  // dispatchSortByColumn: PropTypes.func.isRequired,
  dispatchClearColumnFilters: PropTypes.func.isRequired,
  dispatchDataLoading: PropTypes.func.isRequired,
  reloadTable: PropTypes.bool.isRequired,

  reportFilters: PropTypes.shape({}),
};

/**
 * This is why we have redux
 */
const mapDispatchToProps = (dispatch, ownProps) => {
  const tableId = ownProps.tableIdentifier;
  const { currentUserProvider: { userHasPermissions } } = ownProps;
  return {
    dispatchUpdateTableSettings: (settings) => {
      dispatch(updateTableSettings(tableId, settings));
    },

    dispatchTableData: (data, activePage, totalRecords, availableActions) => dispatch(tableData(tableId, data, activePage, totalRecords, availableActions, userHasPermissions)),
    dispatchDataError: (hasError, errorMessage) => dispatch(dataError(hasError, tableId, errorMessage)),
    dispatchClearColumnFilters: (pushToHistory) => dispatch(clearColumnFilters(tableId, pushToHistory)),
    dispatchDataLoading: (isLoading) => dispatch(dataLoading(isLoading, tableId)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const tableId = ownProps.tableIdentifier;
  return {
    ...state.tableSettings[tableId],
  };
};

export default connectToAPIProvider(connectToCurrentUserProvider(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(PortalDataTable))));
