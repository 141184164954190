import React from 'react';
import { ICompanyLocationRecord } from '../../../types/company/company-location.record.interface';
import { APIPolyFormProps } from '../../../types/poly-form/api-poly-form.props';
import { APIPolyForm } from '../../poly-forms/api-poly-form';

export type CompanyLocationsWidgetSelectedLocationDetailProps = APIPolyFormProps<ICompanyLocationRecord>;

export const CompanyLocationsWidgetSelectedLocationDetail: React.FC<CompanyLocationsWidgetSelectedLocationDetailProps> = (props) => (
  <div className="company-location-detail">
    <APIPolyForm<ICompanyLocationRecord>
      {...props}
      showHeader
      hideBottomButtons
      primaryKeyFieldName="id"
    />
  </div>
);
