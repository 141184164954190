
export const PROJECT_NOTE_TYPE: Record<string, number> = {
  GENERAL: 1,
  CONTACT: 2,
  QUALIFYING: 3,
  ACTIVATION: 4,
  RISK: 5,
  DEFECT: 6,
  BILLING: 7,
} as const;
export type PROJECT_NOTE_TYPE = typeof PROJECT_NOTE_TYPE;
export type AN_PROJECT_NOTE_TYPE = PROJECT_NOTE_TYPE[keyof PROJECT_NOTE_TYPE];

export const PROJECT_NOTE_TYPE_NAMES: { [K in AN_PROJECT_NOTE_TYPE]: string } = {
  [PROJECT_NOTE_TYPE.GENERAL]: 'General',
  [PROJECT_NOTE_TYPE.CONTACT]: 'Contact',
  [PROJECT_NOTE_TYPE.QUALIFYING]: 'Qualifying',
  [PROJECT_NOTE_TYPE.ACTIVATION]: 'Activation',
  [PROJECT_NOTE_TYPE.RISK]: 'Risk',
  [PROJECT_NOTE_TYPE.DEFECT]: 'Defect',
  [PROJECT_NOTE_TYPE.BILLING]: 'Billing',
} as const;
