/* eslint-disable key-spacing */
export const INTERNAL_PROJECT_VISIBILITY = {
  UNPUBLISHED: 1,
  STAKEHOLDERS: 2,
  COMPANY: 3,
} as const;
export type INTERNAL_PROJECT_VISIBILITY = typeof INTERNAL_PROJECT_VISIBILITY;
export type AN_INTERNAL_PROJECT_VISIBILITY = INTERNAL_PROJECT_VISIBILITY[keyof INTERNAL_PROJECT_VISIBILITY];

export const INTERNAL_PROJECT_VISIBILITY_NAME_MAP = {
  [INTERNAL_PROJECT_VISIBILITY.UNPUBLISHED]: 'Unpublished',
  [INTERNAL_PROJECT_VISIBILITY.STAKEHOLDERS]: 'Stakeholders',
  [INTERNAL_PROJECT_VISIBILITY.COMPANY]: 'Company',
};
