import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { projectsWidgetFilter } from '../utils/helpers';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { PROJECT_STATUS } from '../constants/project-status.const';
import { PERMISSION } from '../constants/permissions.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { fullName } from '../helpers/full-name.helper';


/**
 * Table Definition for Company Table
 */
const companyTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Company',
  initialFormFocusFieldName: 'name',
  baseRoute: '/company',
  baseQueryString: [
    'with[]=assignedTo:id,name,initials',
    'with[]=createdBy:id,name,initials',
    'with[]=defaultBillingContact:id,first,last',
    'with_count[]=projects',
    'with_count[]=activeProjects',
    'with_count[]=archivedProjects',
    'with_count[]=completedProjects',
    'with_count[]=leads',
    'with_count[]=proposals',
    'with_count[]=lostProposals',
  ].join('&'),

  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'name',
        'description',
        'email',
        'office',
        'assigned_to',
        'default_billing_contact',
        'count_of_leads',
        'count_of_proposals',
        'count_of_activeProjects',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'name',
        'description',
        'office',
        'email',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'name',
        'description',
        'assigned_to',
        'default_billing_contact',
        'email',
        'office',
        'count_of_leads',
        'count_of_activeProjects',
        'count_of_completedProjects',
        'count_of_proposals',
        'count_of_lostProposals',
        'count_of_archivedProjects',
        'count_of_projects',
      ],
    },
  ],

  /**
   * Definition of Columns for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Identity',
      description: 'Company Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      linkRoute: '/crm/companies',
      showInForm: false,
      visible: false,
      order: 1,
      formOrder: 1,
    },
    {
      name: 'name',
      title: 'Name',
      description: 'Company name',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: '/crm/companies',
      visible: true,
      required: true,
      order: 10,
      formOrder: 10,
    },
    {
      name: 'description',
      title: 'Description',
      description: 'Long Company Name',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      required: true,
      order: 15,
      formOrder: 20,
    },
    {
      name: 'email',
      title: 'Email Address',
      description: 'Email Address',
      format: COLUMN_FORMAT.EMAIL,
      visible: true,
      order: 20,
      formOrder: 30,
    },
    {
      name: 'office',
      title: 'Office Number',
      description: 'Office number',
      format: COLUMN_FORMAT.PHONE,
      visible: true,
      order: 20,
      formOrder: 40,
    },
    {
      name: 'assigned_to',
      title: 'Account Manager',
      description: 'The Ci team member responsible for this company account',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'assigned_to',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      permissions: [PERMISSION.COMPANY_ASSIGN_ACCOUNT_MANAGER],
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
      formFieldToolTip: 'Once assigned, you need special permissions to change the Account Manager. Contact your sales manager if you are having trouble changing this field.',
      formSaveField: 'assigned_to_id',
      required: true,
      sortColumn: 'assignedTo.name',
      visible: true,
      order: 130,
      formOrder: 16,
    },
    {
      name: 'default_billing_contact',
      title: 'Default Billing Contact',
      description: 'The client contact person',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'default_billing_contact',
        key: 'id',
        getValue: (value, row) => fullName(row),
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/crm/contacts',
        listSource: 'person',
      },
      sortColumn: 'default_billing_contact.first',
      formFieldType: FORM_FIELD_TYPE.CONTACT_PICKER,
      formSaveField: 'default_billing_contact_id',
      isCreatable: true,
      getNewItemData: (inputValue, formData) => ({
        company_id: formData.client_id,
        company: formData.client,
      }),
      showInForm: true,
      order: 140,
      formOrder: 17,
    },
    {
      name: 'count_of_leads',
      title: 'Leads',
      description: 'Leads assigned to the company',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      order: 150,
      formOrder: 60,
    },
    {
      name: 'count_of_proposals',
      title: 'Proposals',
      description: 'Proposals assigned to the company',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      order: 160,
      formOrder: 70,
    },
    {
      name: 'count_of_activeProjects',
      title: 'Active Projects',
      description: 'Active projects assigned to the company',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      order: 170,
      formOrder: 80,
    },
    {
      name: 'count_of_completedProjects',
      title: 'Completed Projects',
      description: 'Completed projects assigned to the company',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: false,
      order: 190,
      formOrder: 90,
    },
    {
      name: 'count_of_lostProposals',
      title: 'Lost Proposals',
      description: 'The proposals the company rejected',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: false,
      order: 190,
      formOrder: 100,
    },
    {
      name: 'count_of_archivedProjects',
      title: 'Archived Projects',
      description: 'Projects that have been archived',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: false,
      order: 200,
      formOrder: 11,
    },
    {
      name: 'count_of_projects',
      title: 'All Projects & Leads',
      description: 'All Prospects and Projects',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: false,
      order: 200,
      formOrder: 12,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Company Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'dashboards',
      title: 'Dashboards',
      description: 'Company Dashboards',
      widgetDefinition: WIDGET_DEFINITION.COMPANY_DASHBOARDS,
    },
    {
      name: 'notes',
      title: 'Notes',
      description: 'Company Notes',
      widgetDefinition: WIDGET_DEFINITION.COMPANY_NOTES,
      apiRoute: '/note',
      apiQuery: 'with[]=createdBy:id,name&pagelength=100',
    },
    {
      name: 'contacts',
      title: 'Contacts',
      description: 'Company Contacts',
      widgetDefinition: WIDGET_DEFINITION.COMPANY_CONTACTS,
      apiRoute: '/contact',
      apiQuery: 'with[]=company:id,name&pagelength=100',
    },
    {
      name: 'locations',
      title: 'Locations',
      description: 'Company Locations',
      widgetDefinition: WIDGET_DEFINITION.COMPANY_LOCATIONS,
      apiRoute: '/location',
      apiQuery: 'with[]=state:id,acronym&with[]=company:id,name&pagelength=1000',
    },
    {
      name: 'contracts',
      title: 'Contracts',
      description: 'Company Contracts',
      widgetDefinition: WIDGET_DEFINITION.COMPANY_CONTRACTS,
      apiRoute: (rowData) => {
        const apiQuery = [
          'with[]=type',
          'with[]=subtype',
          'with[]=status:id,name',
          'with[]=billingType:id,name',
          'with[]=client:id,name',
          'with[]=provider:id,name',
          'with[]=project:id,project_number,partner_program_id,dtools_id',
          'with[]=renewedFrom:id,type_id,status_id',
          'with[]=renewedFrom.type:id,prefix',
          'with[]=renewedTo:id,type_id,status_id',
          'with[]=renewedTo.type:id,prefix',
          'with[]=region:id,name',
          'filter[0][field]=client_id',
          'filter[0][operation]=equals',
          `filter[0][value]=${rowData.id}`,
          'pagelength=1000',
        ].join('&');
        return `/contract?${apiQuery}`;
      },
    },
    {
      name: 'leads',
      title: 'Leads',
      description: 'Client Leads',
      widgetDefinition: WIDGET_DEFINITION.PROJECTS,
      apiRoute: (row) => projectsWidgetFilter('client_id', row.id, PROJECT_STATUS.LEAD),
    },
    {
      name: 'proposal',
      title: 'Proposals',
      description: 'Client Proposals',
      widgetDefinition: WIDGET_DEFINITION.PROJECTS,
      apiRoute: (row) => projectsWidgetFilter('client_id', row.id, PROJECT_STATUS.PROPOSAL),
    },
    {
      name: 'active',
      title: 'Active Projects',
      description: 'Client Active Projects',
      widgetDefinition: WIDGET_DEFINITION.PROJECTS,
      apiRoute: (row) => projectsWidgetFilter('client_id', row.id, PROJECT_STATUS.ACTIVE),
    },
    {
      name: 'completed',
      title: 'Completed Projects',
      description: 'Client Completed Projects',
      widgetDefinition: WIDGET_DEFINITION.PROJECTS,
      apiRoute: (row) => projectsWidgetFilter('client_id', row.id, PROJECT_STATUS.COMPLETED),
    },
    {
      name: 'lost',
      title: 'Lost Opportunities',
      description: 'Client Lost Opportunities',
      widgetDefinition: WIDGET_DEFINITION.PROJECTS,
      apiRoute: (row) => projectsWidgetFilter('client_id', row.id, PROJECT_STATUS.LOST),
    },
    {
      name: 'archived',
      title: 'Archived',
      description: 'Archived Leads, Proposals and Projects',
      widgetDefinition: WIDGET_DEFINITION.PROJECTS,
      apiRoute: (row) => projectsWidgetFilter('client_id', row.id, PROJECT_STATUS.ARCHIVED),
    },
    {
      ...eventsTabDefault,
      description: 'Company Event Audit Trail',
    },
  ],

  /**
   * Action settings for action buttons and related dialogs
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add a Company',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the company details',
      successMessage: 'Company details updated',
      failMessage: 'Failed to update company details',
      hideRecordActionButton: true,
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the company',
      successMessage: 'Company deleted',
      failMessage: 'Failed to delete company',
      hideRecordActionButton: true,
    },
  ],
};

export default companyTableInitialSettings;
