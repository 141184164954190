import React, { useCallback, useContext } from 'react';

import { CurrentUserContext } from '../providers/current-user-provider';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { NewRecordFormProps } from '../../types/poly-form/new-record-form.props';
import { ICompanyRecord } from '../../types/company/company.record.interface';

import { APIPolyForm } from './api-poly-form';
import { FormFieldRenderer } from './form-field-renderer';

import { mapTableToForm } from '../../helpers/map-table-to-form.helper';

import companyTableInitialSettings from '../../table-definitions/company-table';
import { COLUMN_FORMAT } from '../../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../../constants/form-field-type.const';
import { API_ACTION } from '../../constants/api-action.const';

const formFields = mapTableToForm(companyTableInitialSettings);

export const NewCompanyForm: React.FC<NewRecordFormProps<ICompanyRecord>> = (props) => {
  const {
    onFormComplete, onFormChange, initialData,
  } = props;
  const { userDetails } = useContext(CurrentUserContext);

  /**
   * Fired when the APIPolyForm successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, id: FormFieldComponentProps['value'], newData?: Partial<ICompanyRecord>) => {
    if (onFormComplete) onFormComplete(success, id as ICompanyRecord['id'], (newData as ICompanyRecord));
  }, [onFormComplete]);

  // Render
  return (
    <APIPolyForm
      {...companyTableInitialSettings}
      fields={formFields}
      formData={{
        assigned_to_id: userDetails.id,
        assigned_to: userDetails,
        ...(initialData ?? {}),
      }}
      permittedActions={companyTableInitialSettings.possibleActions.filter((action) => (action.name === API_ACTION.CREATE))}
      onFormComplete={handleFormComplete}
      onFormChange={onFormChange}
      isNewRecord
      isEditing
    >
      {/* TODO: change this to some kind of lookup that renders out company name links, which, once clicked return the company from this modal instead of creating a new one */}
      <p>Use the select box below to search for a company before adding a new one.</p>
      <FormFieldRenderer
        id="companySearch"
        field={{
          name: '_company_search',
          formFieldType: FORM_FIELD_TYPE.COMPANY_PICKER,
          format: COLUMN_FORMAT.TEXT,
          visible: true,
          title: 'Search existing companies',
          placeholder: 'Check to see if your company already exists',
          isCreatable: false,
        }}
        errors={{}}
      />
      <hr />
    </APIPolyForm>
  );
};
