import { COLUMN_FORMAT } from '../constants/column-format.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

export const InvoiceRequestAttachmentsWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Attachment',
  fields: [
    {
      name: 'invoice_request_id',
      visible: false,
      isParentId: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'sharepoint_url',
      title: 'Sharepoint Link',
      visible: true,
      format: COLUMN_FORMAT.ABBREVIATED_EXT_LINK,
    },
    {
      name: 'description',
      title: 'Description',
      visible: true,
      format: COLUMN_FORMAT.LONG_TEXT,
    },
  ],
};
