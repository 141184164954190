import { SORT_DIRECTION } from '@corporate-initiatives/ci-portal-js-sdk';
import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { ICON } from '../constants/icon.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { DATA_TABLE_FLAG } from '../constants/data-table-flag.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';
import { INTERNAL_PROJECT_TYPE } from '../constants/internal-project-type.const';

/**
 * Table Definition for People (Tasks) Table
 */
const internalProjectTasksTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Task',
  initialFormFocusFieldName: 'first',
  availableFlags: [DATA_TABLE_FLAG.ONLY_MINE],
  baseRoute: '/internal-project-item',
  baseQueryString: [
    'with[]=sprint:id,name',
    'with[]=internalProject:id,name,status_id,type_id',
    'with[]=internalProject.type',
    'with[]=createdBy:id,name',
    'with[]=assignedTo:id,name',
  ].join('&'),

  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'id',
        'internal_project_type',
        'internal_project',
        'priority',
        'name',
        'sprint',
        'assigned_to',
        'progress',
        'estimated_hours',
        'external_url',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'name',
        'description',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'internal_project_type',
        'internal_project',
        'priority',
        'name',
        'description',
        'sprint',
        'assigned_to',
        'progress',
        'estimated_hours',
        'actual_hours',
        'external_url',
      ],
    },
  ],

  /**
   * Definition of columns and APIPolyForm fields for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Identity',
      description: 'Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK_SHORT,
      linkRoute: '/internal-projects/tasks',
      visible: true,
      showInForm: false,
      order: 10,
      formOrder: 10,
    },
    {
      name: 'internal_project_type',
      title: 'Type',
      description: 'The Ci team member responsible for this task',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'internal_project',
        key: 'type',
        format: COLUMN_FORMAT.OBJECT,
        listSource: 'internalProjectType',
        object: {
          sourceField: 'type',
          key: 'name',
        },
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
      filterOnField: 'internalProject.type_id',
      visible: true,
      showInForm: true,
      required: true,
      order: 15,
      formOrder: 15,
    },
    {
      name: 'internal_project',
      title: 'On Project',
      description: 'Task is assigned to Project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'internal_project',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/internal-projects/internal-projects',
        listSource: 'internalProject',
      },
      formFieldType: FORM_FIELD_TYPE.INTERNAL_PROJECT_PICKER,
      formSaveField: 'internal_project_id',
      visible: true,
      showInForm: true,
      required: true,
      order: 16,
      formOrder: 16,
    },
    {
      name: 'priority',
      title: 'Priority',
      description: 'Task Priority',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      visible: true,
      required: false,
      order: 20,
      formOrder: 20,
    },
    {
      name: 'name',
      title: 'Name',
      description: 'Task Name',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: '/internal-projects/tasks',
      visible: true,
      showInForm: true,
      required: true,
      order: 30,
      formOrder: 30,
    },
    {
      name: 'description',
      title: 'Description',
      description: 'Task Description',
      format: COLUMN_FORMAT.HTML_NOTIN_TABLE,
      formFieldType: FORM_FIELD_TYPE.HTML_INPUT,
      visible: false,
      required: true,
      order: 40,
      formOrder: 40,
    },
    {
      name: 'sprint',
      title: 'Sprint',
      description: 'Current Sprint of Task',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'sprint',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/internal-projects/sprints',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.SPRINT_PICKER,
      formSaveField: 'sprint_id',
      visible: true,
      showInForm: true,
      required: false,
      isClearable: false,
      order: 60,
      formOrder: 60,
    },
    {
      name: 'assigned_to',
      title: 'Assigned To',
      description: 'The Ci team member responsible for this task',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'assigned_to',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
      formSaveField: 'assigned_to_id',
      sortColumn: 'assigned_to.name',
      visible: true,
      showInForm: true,
      required: true,
      order: 70,
      formOrder: 70,
    },
    {
      name: 'progress',
      title: 'Progress',
      description: 'Project Progress',
      format: COLUMN_FORMAT.PROGRESS_PERCENT,
      formFieldType: FORM_FIELD_TYPE.PROGRESS_PERCENT_INPUT,
      filterFormat: COLUMN_FORMAT.NUMBER,
      visible: true,
      required: false,
      order: 80,
      formOrder: 80,
    },
    {
      name: 'estimated_hours',
      title: 'Estimated Time',
      description: "Project's estimated labour hours",
      format: COLUMN_FORMAT.DURATION_BUSINESS_TIME,
      filterFormat: COLUMN_FORMAT.NUMBER,
      visible: true,
      required: false,
      order: 90,
      formOrder: 90,
    },
    {
      name: 'actual_hours',
      title: 'Actual Hours',
      description: "Project's estimated actual labour hours",
      format: COLUMN_FORMAT.DURATION_BUSINESS_TIME,
      filterFormat: COLUMN_FORMAT.NUMBER,
      visible: false,
      required: false,
      order: 100,
      formOrder: 100,
    },
    {
      name: 'external_url',
      title: 'External Link',
      description: '',
      format: COLUMN_FORMAT.ABBREVIATED_EXT_LINK,
      visible: true,
      showInForm: true,
      order: 110,
      formOrder: 110,
    },
  ],


  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add a Task',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new Task',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the task',
      successMessage: 'Updated task',
      failMessage: 'Failed to update Task',
      hideRecordActionButton: true,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Task Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      ...eventsTabDefault,
    },
  ],

  /**
   * View definitions
   */
  viewKey: 'all',
  views: [
    {
      key: 'all',
      title: 'All Internal Project Tasks',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'All My Tasks',
        },
      ],
      filters: [],
      flags: [],
      sortedColumns: [
        {
          name: 'internal_project',
          direction: SORT_DIRECTION.ASC,
          sortIndex: 0,
        },
        {
          name: 'priority',
          direction: SORT_DIRECTION.ASC,
          sortIndex: 1,
        },
      ],
    },
    {
      key: 'rde',
      title: 'RD&E Project Tasks',
      sortedColumns: [
        {
          name: 'internal_project',
          direction: SORT_DIRECTION.ASC,
          sortIndex: 0,
        },
        {
          name: 'priority',
          direction: SORT_DIRECTION.ASC,
          sortIndex: 1,
        },
      ],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'All My RDE Tasks',
        },
      ],
      filters: [
        {
          field: 'internalProject.type_id',
          operation: API_FILTER_OPERATION.EQUALS,
          values: [INTERNAL_PROJECT_TYPE.RD],
        },
      ],
      flags: [],
    },
    {
      key: 'ip',
      title: 'Internal Process Tasks',
      sortedColumns: [
        {
          name: 'internal_project',
          direction: SORT_DIRECTION.ASC,
          sortIndex: 0,
        },
        {
          name: 'priority',
          direction: SORT_DIRECTION.ASC,
          sortIndex: 1,
        },
      ],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Internal Process Tasks',
        },
      ],
      filters: [
        {
          field: 'internalProject.type_id',
          operation: API_FILTER_OPERATION.EQUALS,
          values: [INTERNAL_PROJECT_TYPE.IP],
        },
      ],
      flags: [],
    },
    {
      key: 'id',
      title: 'Internal Development Tasks',
      sortedColumns: [
        {
          name: 'internal_project',
          direction: SORT_DIRECTION.ASC,
          sortIndex: 0,
        },
        {
          name: 'priority',
          direction: SORT_DIRECTION.ASC,
          sortIndex: 1,
        },
      ],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Internal Development Tasks',
        },
      ],
      filters: [
        {
          field: 'internalProject.type_id',
          operation: API_FILTER_OPERATION.EQUALS,
          values: [INTERNAL_PROJECT_TYPE.ID],
        },
      ],
      flags: [],
    },
    {
      key: 'pd',
      title: 'Product Development Tasks',
      sortedColumns: [
        {
          name: 'internal_project',
          direction: SORT_DIRECTION.ASC,
          sortIndex: 0,
        },
        {
          name: 'priority',
          direction: SORT_DIRECTION.ASC,
          sortIndex: 1,
        },
      ],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Product Development Tasks',
        },
      ],
      filters: [
        {
          field: 'internalProject.type_id',
          operation: API_FILTER_OPERATION.EQUALS,
          values: [INTERNAL_PROJECT_TYPE.PD],
        },
      ],
      flags: [],
    },
  ],
};

export default internalProjectTasksTableInitialSettings;
