import { IModalWizardState } from '../../../types/modal/modal-wizard-state.interface';
import { IModalWizardPageData } from '../../../types/modal/modal-wizard-page-data.interface';
import { SERObject } from '../../../helpers/ser-object.helper';
import { UPLOAD_MAINTENANCE_CHECKS_MODAL_WIZARD_PAGE } from './upload-maintenance-checks-modal-wizard-page.const';
import { IContractRecord } from '../../../types/service/contract.record.interface';

// Page 1
export interface IUploadMaintenanceChecksModalWizardPage1Data extends IModalWizardPageData {
  schedule_import_file: null | File,
}

// Page 2
export interface IUploadMaintenanceChecksModalWizardPage2Data extends IModalWizardPageData {
  import_array: {
    contract_id: string,
  }[],
}

// Page 3
export type IUploadMaintenanceChecksModalWizardPage3Data = IModalWizardPageData;

export interface IUploadValidationResponse {
  id: string,
  contract_id: string,
  perform_before?: string,
  booked_on?: string,
  performed_on?: string,
  error?: string,
  notes: string,
}

// Wizard State interface
export interface IUploadMaintenanceChecksModalWizardState extends IModalWizardState {
  pageData: {
    [UPLOAD_MAINTENANCE_CHECKS_MODAL_WIZARD_PAGE.PAGE_1_CHOOSE_FILE]: IUploadMaintenanceChecksModalWizardPage1Data,
    [UPLOAD_MAINTENANCE_CHECKS_MODAL_WIZARD_PAGE.PAGE_2_VERIFICATION]: IUploadMaintenanceChecksModalWizardPage2Data,
    [UPLOAD_MAINTENANCE_CHECKS_MODAL_WIZARD_PAGE.PAGE_2_VERIFICATION]: IUploadMaintenanceChecksModalWizardPage2Data,
  },

  initialising: boolean,
  isDirty: boolean,
  processing: boolean,
  success: boolean,

  processingImport: boolean,
  importProcessed: boolean,

  // The details about the contract record
  contractRecord: null | IContractRecord,
  importMaintenanceChecksResult: null | SERObject<IContractRecord>,


  // The details about the import file
  importFileUploadStatus: number, // 0 -> 100
  uploadImportFileResult: null | SERObject<IUploadValidationResponse[]>,
  importFile: File | null,
}

// Wizard initial state
export const uploadMaintenanceChecksModalWizardInitialState: IUploadMaintenanceChecksModalWizardState = {
  currentPageIndex: 0,
  pageData: [

    // Page 1 initial state
    {
      schedule_import_file: null,
    },

    // Page 2 initial state
    {
      import_array: [],
    },

    // Page 3 initial state
    {
    },
  ],
  pageDataErrors: [],

  initialising: true,
  isDirty: false,
  processing: false,
  success: false,

  processingImport: false,
  importProcessed: false,

  contractRecord: null,
  importMaintenanceChecksResult: null,

  importFileUploadStatus: 0,
  uploadImportFileResult: null,
  importFile: null,
};
