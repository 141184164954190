export const ALLOWANCE_STATUS = {
  UNSUBMITTED: 1,
  REJECTED: 2,
  SUBMITTED: 3,
  REVOKED: 4,
  APPROVED: 5,
  PROCESSED: 6,
  PAID: 7,
} as const;
export type ALLOWANCE_STATUS = typeof ALLOWANCE_STATUS;
export type AN_ALLOWANCE_STATUS = ALLOWANCE_STATUS[keyof ALLOWANCE_STATUS];

export const ALLOWANCE_STATUS_MAP: {[K in AN_ALLOWANCE_STATUS]: string} = {
  [ALLOWANCE_STATUS.UNSUBMITTED]: 'Unsubmitted',
  [ALLOWANCE_STATUS.REJECTED]: 'Rejected',
  [ALLOWANCE_STATUS.SUBMITTED]: 'Submitted',
  [ALLOWANCE_STATUS.REVOKED]: 'Revoked',
  [ALLOWANCE_STATUS.APPROVED]: 'Approved',
  [ALLOWANCE_STATUS.PROCESSED]: 'Processed',
  [ALLOWANCE_STATUS.PAID]: 'Paid',
};
