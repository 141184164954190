export type SERError = null | Error | string | string[] | Record<string, string[]>;

/**
 * @class SERObject
 *
 * @description
 * An object for holding the `S`uccess, `E`rror and `R`esult of an operation
 *
 * Use this when a black box function requires more bandwidth for feedback
 */
export class SERObject<T = unknown> {
  private _success: boolean;

  private _error: SERError;

  private _result: null | T;


  /**
   * @constructor
   *
   * @param {boolean} initialSuccess
   */
  constructor(initialSuccess: boolean) {
    this._success = (initialSuccess === true);
    this._error = null;
    this._result = null;
  }


  /**
   * Returns true if the operation passes
   */
  get success(): boolean { return this._success; }

  set success(value: boolean) { this._success = value; }


  /**
   * Returns the error associated with a failed operation
   */
  get error(): SERError { return this._error; }

  set error(value: SERError) { this._error = value; }


  /**
   * @description
   * Returns the result associated with an operation
   */
  get result(): null | T { return this._result; }

  set result(value: null | T) { this._result = value; }
}
