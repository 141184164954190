export const PROJECT_CONTACT_TYPE = {
  PRIMARY_CONTACT: 1,
  ARCHITECT: 2,
  AV_CONSULTANT: 3,
  BILLING_AGENT: 4,
  BUILDER: 5,
  COMPETITOR: 6,
  CLIENT_CONTACT: 7,
  DEVELOPER: 8,
  ELECTRICIAN: 9,
  EXTERNAL_PROJECT_MANAGER: 10,
  PROPERTY_AGENT: 11,
  SERVICES_CONSULTANT: 12,
  INVOICING_CONTACT: 13,
} as const;
export type PROJECT_CONTACT_TYPE = typeof PROJECT_CONTACT_TYPE;
export type A_PROJECT_CONTACT_TYPE = PROJECT_CONTACT_TYPE[keyof PROJECT_CONTACT_TYPE];
export const MAX_PROJECT_CONTACT_TYPE = 13;
