import { IActionButton } from '../types/action-button.interface';
import { API_ACTION } from './api-action.const';
import { BUTTON_COLOR } from './button-color.const';
import { ICON } from './icon.const';

/**
 * These are the default actions which are typically possible on a widget.
 * These will be evaluated against the actions returned from the fetch
 * of the widget data to determine if they are permitted or not.
 */
export const defaultWidgetPossibleActions: IActionButton[] = [
  {
    name: API_ACTION.CREATE,
    title: 'Create',
    color: BUTTON_COLOR.PRIMARY,
    icon: ICON.ADD,
  },
];
