import moment from 'moment';
import { createAutoInvalidatingCache } from './create-auto-invalidating-cache';

// create a never-invalidating cache of read articles
const LOCAL_STORAGE_READ_ARTICLES_CACHE_KEY = 'read_news_articles_v1';
const alreadyReadArticlesCache = createAutoInvalidatingCache(null, LOCAL_STORAGE_READ_ARTICLES_CACHE_KEY);


// cache a moment to avoid creating hundreds of objects when the user is browsing news
// ( this may seem excessive? :} )
const createOneMonthAgoMoment = () => moment().subtract(30, 'days');
const oneMonthAgoCache = createAutoInvalidatingCache(1000 * 60 * 60);
oneMonthAgoCache.set(createOneMonthAgoMoment());

// initialise to empty array
if (!(alreadyReadArticlesCache.get() instanceof Array)) alreadyReadArticlesCache.set([]);

/**
 * @description
 * Has an article already been read?
 *
 * @param {number | string} articleId
 * @returns {boolean}
 */
const hasArticleBeenRead = (articleId) => alreadyReadArticlesCache.get().find((id) => String(id) === String(articleId));

/**
 * @description
 * Mark a news article as read
 *
 * @param {number | string} articleId
 * @returns {void}
 */
const markArticleAsRead = (articleId) => {
  if (!hasArticleBeenRead(articleId)) {
    alreadyReadArticlesCache.set([...alreadyReadArticlesCache.get(), articleId]);
  }
};

/**
 * @description
 * Determine if an article is "new"
 *
 * @param {{published_at?: string}} article
 * @param {moment} publishedDate
 * @returns {boolean}
 */
const isArticleNew = (article, publishedDate) => {
  if (!publishedDate.isValid()) return false;

  if (!oneMonthAgoCache.isValid()) oneMonthAgoCache.set(createOneMonthAgoMoment());
  const oneMonthAgo = oneMonthAgoCache.get();
  const isArticleNewResult = publishedDate.isSameOrAfter(oneMonthAgo) && !hasArticleBeenRead(article.id);
  return isArticleNewResult;
};

/**
 * @description
 * New News Service
 * Works with a cached (in local storage) array of already read news articles
 *
 * @singleton
 */
export const NewNewsService = {
  hasArticleBeenRead,
  markArticleAsRead,
  isArticleNew,
};
