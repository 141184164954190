import { awaitTimeout } from './await-timeout.helper';
import { clearSelection } from './clear-selection.helper';

/**
 * @description
 * Copies an HTML element to clipboard by "selecting" it then "copying" it using the
 * browser's `executeCommand` method
 *
 * @note: document.executeCommand is listed as a deprecated feature and may need to be replaced
 */
export const copyElementToClipboard = async (element: HTMLElement): Promise<boolean> => {
  let success = false;
  let selection: Selection | null = null;
  let range: Range | null = null;

  if (window.getSelection && document.createRange) {
    // Clear existing document selections
    clearSelection();

    selection = window.getSelection();
    if (selection) {
      await awaitTimeout(1);
      // create a range object
      range = document.createRange();
      // sets the range
      range.selectNodeContents(element);
      if (selection) {
        // remove all ranges from selection
        selection.removeAllRanges();
        // add Range to a Selection.
        selection.addRange(range);

        // Execute th `copy` command
        document.execCommand('copy');

        success = true;

        await awaitTimeout(1);

        // clear the selection again
        clearSelection();
      }
    }
  }

  return success;
};
