import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, CardTitle, Col, Row } from 'reactstrap';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { ICON } from '../../../constants/icon.const';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import { IProjectRecord } from '../../../types/project/project.record.interface';
import Icon from '../../layout-helpers/icon';
import { APIContext } from '../../providers/api-provider';
import { formatValueString } from '../../render-functions';
import { IDtoolsOrderRecord } from '../../../types/integration/dtools/dtools-order-record.interface';
import { IMyobSupplierOrder } from '../../../types/integration/myob/myob-supplier-order.interface';

interface IVenderOrderItemSummaryLine {
  Manufacturer: string | null,
  Model: string | null,
  PartNumber: string | null,
  AcctgEstNum: string | null,
  ShortDescription: string | null,
  ProductCost: string | null,
  Quantity: number | null,
  TotalCost: number | null,
}

interface IDtoolsVendorOrderItemsTableProps {
  rowData: IDtoolsOrderRecord,
  dtoolsId: string | null,
  selectedPoId: string | null
  projectData: IProjectRecord,
  addProcessedOrder: (orderId: string, newOrder: IMyobSupplierOrder) => boolean,
}

export const DtoolsVendorOrderItemsTable: React.FC<IDtoolsVendorOrderItemsTableProps> = ({ rowData, dtoolsId, selectedPoId, projectData, addProcessedOrder }) => {
  const { apiFetch } = useContext(APIContext);
  const [vendorOrderItems, setVendorOrderItems] = useState<IVenderOrderItemSummaryLine[] | null>(null);
  const [showDescriptions, setShowDescriptions] = useState(false);
  const [loadingItems, setLoadingItems] = useState(true);
  const [pushStatus, setPushStatus] = useState<'un-pushed' | 'pushing' | 'error' | 'pushed'>('un-pushed');
  const [orderProcessingError, setOrderProcessingError] = useState<{
    message: string,
    errors: string[],
  } | null>(null);

  const retrieveItemsAbortController = useRef<AbortController | null>(null);
  const pushItemsAbortController = useRef<AbortController | null>(null);

  /**
   * Load Supplier Orders
   */
  const loadOrders = useCallback(() => {
    const { Vendor: rawVendor, OrderNumber: rawOrderNumber } = rowData;
    const encodedVendor = rawVendor ? encodeURIComponent(rawVendor) : '';
    const encodedOrder = rawOrderNumber ? encodeURIComponent(rawOrderNumber) : '';

    if (retrieveItemsAbortController.current) {
      retrieveItemsAbortController.current.abort();
    }
    retrieveItemsAbortController.current = apiAborter();

    setLoadingItems(true);
    apiFetch(
      `/dtools-connector/project/${dtoolsId}/purchaseorder?vendor=${encodedVendor}&order_number=${encodedOrder}&summarise=true`,
      {
        name: 'DtoolsVendorOrderItemsTable:loadPurchaseOrderSummaryView',
        signal: retrieveItemsAbortController.current.signal,
      },
    ).then((response) => {
      if (response.success) {
        setVendorOrderItems(response.body.data || null);
      } else if (!response.aborted) {
        // TODO: error state?
        console.error('DtoolsVendorOrderItemsTable.loadPurchaseOrderSummaryView', response.error);
      }
      setLoadingItems(false);
    });
  }, [apiFetch, dtoolsId, rowData]);


  /**
   * Push Items to an Order
   */
  const pushItemsToOrder = useCallback(() => {
    const encodedOrder = selectedPoId ? encodeURIComponent(selectedPoId) : '';

    setOrderProcessingError(null);
    setPushStatus('pushing');

    if (pushItemsAbortController.current) {
      pushItemsAbortController.current.abort();
    }
    pushItemsAbortController.current = apiAborter();

    apiFetch(
      `/myob-advanced-connector/supplier-order/${encodedOrder}`,
      {
        method: HTTP_METHOD.PATCH,
        name: 'DtoolsVendorOrderItemsTable:loadPurchaseOrderSummaryView',
        body: {
          order_id: encodedOrder,
          project_id: projectData.project_id,
          project_number: projectData.project_number,
          items: vendorOrderItems,
        },
        signal: pushItemsAbortController.current.signal,
      },
    ).then((response) => {
      if (response.success) {
        loadOrders();
        if (selectedPoId) {
          addProcessedOrder(selectedPoId, response.body.data);
        }
        setPushStatus('pushed');
      } else if (!response.aborted) {
        // TODO: error state?
        console.error('DtoolsVendorOrderItemsTable.loadPurchaseOrderSummaryView', response.error);
        setPushStatus('error');
        setOrderProcessingError(response.body);
      }
    });
  }, [addProcessedOrder, apiFetch, loadOrders, projectData.project_id, projectData.project_number, selectedPoId, vendorOrderItems]);


  /**
   * Component mount effect
   */
  useEffect(() => {
    loadOrders();
    return () => {
      if (pushItemsAbortController.current) {
        pushItemsAbortController.current.abort();
      }
      if (retrieveItemsAbortController.current) {
        retrieveItemsAbortController.current.abort();
      }
    };
  }, [dtoolsId, loadOrders]);


  return (
    <div className="pt-4">
      <CardTitle className="p-2">
        <Row>
          <Col md={6}>
            D-Tools Vendor Order Items
            {' '}
          </Col>
          <Col md={6} className="text-right">
            <Button onClick={() => setShowDescriptions(!showDescriptions)} size="sm">Toggle Details</Button>
            &nbsp;
            {selectedPoId && (pushStatus !== 'pushed') && (
              <Button onClick={() => pushItemsToOrder()} size="sm" color="purple" disabled={pushStatus === 'pushing'} working={pushStatus === 'pushing'}>
                Push Items to
                {' '}
                {selectedPoId}
                {' '}
                <Icon i={pushStatus === 'pushing' ? 'spinner' : ICON.CLOUD_UPLOAD} size="sm" />
              </Button>
            )}
          </Col>
        </Row>
      </CardTitle>
      {pushStatus === 'error' && orderProcessingError && (
        <Alert color="danger">
          <h4 className="alert-heading">
            MYOB API ERROR:
          </h4>
          <CardTitle>{orderProcessingError.message}</CardTitle>
          {orderProcessingError.errors && orderProcessingError.errors.map((error) => (
            <p key={error}>{error}</p>
          ))}
        </Alert>
      )}
      <table className="table table-striped default-table">
        <thead>
          <tr>
            <th>Manufacturer</th>
            <th>Model</th>
            <th>Part Number</th>
            <th>Estimate</th>
            <th className="text-right">Product Cost</th>
            <th className="text-right">Quantity</th>
            <th className="text-right">Total Cost</th>
          </tr>
        </thead>
        <tbody>
          {loadingItems && (
            <tr>
              <td colSpan={7}>
                <Icon i="rolling" />
                {' '}
                Loading items...
              </td>
            </tr>
          )}
          {vendorOrderItems && vendorOrderItems.map((item) => (
            <React.Fragment key={`order-${item.Manufacturer}-${item.Model}-${item.TotalCost}-${item.AcctgEstNum}`}>
              <tr>
                <td>{item.Manufacturer}</td>
                <td>{item.Model}</td>
                <td>{item.PartNumber}</td>
                <td>{item.AcctgEstNum}</td>
                <td className="text-right">{formatValueString(item.ProductCost, COLUMN_FORMAT.CURRENCY)}</td>
                <td className="text-right">{item.Quantity}</td>
                <td className="text-right">{formatValueString(item.TotalCost, COLUMN_FORMAT.CURRENCY)}</td>
              </tr>
              {showDescriptions && (
                <tr>
                  <th colSpan={1} className="text-right">
                    Short Description
                  </th>
                  <td colSpan={6} style={{ wordWrap: 'normal', whiteSpace: 'normal' }}>{item.ShortDescription}</td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={6} className="text-right">
              Total
            </th>
            <td className="text-right">{formatValueString(rowData.TotalCost, COLUMN_FORMAT.CURRENCY)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
