import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { PAGE_SIZE_DEFAULT } from '../utils/constants';

/**
 * Defaults for Table Definitions
 */
export const defaultTableSettings = {
  recordSet: [],
  totalRecords: 0,
  hasError: false,
  errorMessage: null,
  isLoading: true,
  searchTerm: null,
  pageSize: PAGE_SIZE_DEFAULT,
  activePage: 1, // Starting page number
  defaultTabKey: 'detail',
  activeTabKey: null,
  itemCaption: null,
  initialFormFocusFieldName: null,
  baseRoute: '/project',
  pushToHistory: false,
  replaceInHistory: false,
  forceReapplyViewDefaults: true,
  isTextWrappingEnabled: false,
  isTightModeEnabled: false,
  openRowId: null,
  // TODO might not need any more
  // permissions: {
  //   create: null,
  //   update: null,
  //   delete: null,
  // },
  baseQueryString: '',
  /**
   * Definition of presets for the table columns
   */
  columnPresets: [],
  /**
   * Definition of Columns for this table
   * Includes settings for APIPolyForm fields
   */
  columns: [],
  orderedVisibleColumns: [],
  /**
   * Definition of Tab Groups for this table
   */
  tabs: [],
  /**
   * Possible actions
   */
  possibleActions: [],
  /**
   * View definitions
   */
  viewKey: undefined,
  views: [],
  /**
   * Flags
   */
  flags: [],
  availableFlags: [],
  /**
   * Sorting
   */
  sortedColumns: [],
  /**
   * Filtering
   */
  filteredColumns: [],

  /**
   * Reset the local storage settings if they were stored with any of these app versions
   * Add application version numbers to this list (make sure it is a PREVIOUS application
   * version number which reflects a now invalid set of settings)
   */
  resetForAppVersion: [
    '',
  ],

  /**
   * Should the data table reload ASAP?
   */
  reloadTable: false,
};

/**
 * Some commonly used defaults
 */
export const eventsTabDefault = {
  name: 'events',
  title: 'Events',
  description: 'Events',
  widgetDefinition: WIDGET_DEFINITION.EVENT_TRACKING,
  apiRoute: '/tracking',
  apiQuery: [
    'with[]=createdBy:id,name',
    'pagelength=20',
  ].join('&'),
};
