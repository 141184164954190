import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { ICON } from '../constants/icon.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

/**
 * Table Definition for People (Task Templates) Table
 */
const taskTemplatesTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Task Template',
  initialFormFocusFieldName: 'first',

  baseRoute: '/task-template',
  baseQueryString: [
    'with[]=createdBy:id,name,initials',
    'with_count[]=task',
  ].join('&'),


  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'name',
        'description',
        'count_of_tasks',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'name',
        'description',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'name',
        'description',
        'count_of_tasks',
      ],
    },
  ],

  /**
   * Definition of columns and APIPolyForm fields for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Identity',
      description: 'Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      linkRoute: '/internal-projects/task-templates',
      visible: false,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'name',
      title: 'Name',
      description: 'Task Template Name',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: '/internal-projects/task-templates',
      visible: true,
      showInForm: true,
      order: 10,
      formOrder: 10,
    },
    {
      name: 'description',
      title: 'Task Template Description',
      description: 'Task Template Description',
      format: COLUMN_FORMAT.STRIP_HTML,
      visible: true,
      required: true,
      maxLength: 50,
      order: 20,
      formOrder: 20,
    },
    {
      name: 'count_of_tasks',
      title: 'Tasks',
      description: 'Total count of tasks for project',
      format: COLUMN_FORMAT.NUMBER,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: false,
      order: 30,
      formOrder: 30,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add a Template',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new Template',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the template',
      successMessage: 'Updated template',
      failMessage: 'Failed to update Template',
      hideRecordActionButton: true,
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the Template',
      successMessage: 'Template deleted',
      failMessage: 'Failed to delete Template',
      hideRecordActionButton: true,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Task Template Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'tasks',
      title: 'Tasks',
      description: 'Tasks Assigned to Project',
      widgetDefinition: WIDGET_DEFINITION.NAME_AND_DESCRIPTION_ONLY,
      apiRoute: '/task',
    },
    {
      ...eventsTabDefault,
      apiQuery: [
        'with[]=createdBy:id,name',
        'with[]=taskTemplateTaskTracking:id,data,story',
      ].join('&'),
    },
  ],
};

export default taskTemplatesTableInitialSettings;
