import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';

import { Alert, Card, CardBody, CardHeader, CardText, CardTitle, Table } from 'reactstrap';
import classNames from 'classnames';
import { APIContext } from '../../providers/api-provider';

import { IUserRecord } from '../../../types/user/user.record.interface';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import { IMyobEmployeeRecord } from '../../../types/myob-advanced/myob-employee.record.interface';
import Icon from '../../layout-helpers/icon';


export const MyobUserLink: React.FC<{ user: IUserRecord }> = ({ user }) => {
  const abortGetUserInfo = useRef<null | AbortController>(null);
  const [employeeInfo, setEmployeeInfo] = useState<IMyobEmployeeRecord|undefined>(undefined);
  const [loadingError, setLoadingError] = useState<string|undefined>(undefined);
  const [payrollDataLoading, setPayrollDataLoading] = useState<boolean>(false);

  const { apiFetch } = useContext(APIContext);
  abortGetUserInfo.current = apiAborter();

  const retrieveData = useCallback(async (userId: number) => {
    setLoadingError(undefined);
    setPayrollDataLoading(true);
    const response = await apiFetch(
      `/user/${userId}/myob-info`,
      {
        method: HTTP_METHOD.GET,
        name: 'UserAdmin::verifyMyobUser',
        signal: abortGetUserInfo?.current?.signal,
      },
    );
    if (response.success) {
      abortGetUserInfo.current = null;
      setEmployeeInfo(response.body.data);
      setPayrollDataLoading(false);
    }
    else if (!response.aborted) {
      setLoadingError(response.body.message);
      setPayrollDataLoading(false);
    }
  }, [apiFetch]);

  useEffect(() => {
    retrieveData(user.id);
    return () => {
      if (abortGetUserInfo.current) {
        abortGetUserInfo.current.abort();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Card>
      <CardHeader className={classNames('text-white', user.accounting_id ? 'bg-success' : 'bg-danger')}>
        <h4>MYOB User Info</h4>
      </CardHeader>
      <CardBody>
        <CardText>
          User
          {' '}
          {user.first}
          {' '}
          {user.last}
          {' '}
          {user.accounting_id ? 'is' : 'is not'}
          {' '}
          linked to Payroll.
          {user.accounting_id && (
            ` with employee id: '${user.accounting_id}'`
          )}
        </CardText>
        <CardTitle>
          Payroll Data:
        </CardTitle>
        {payrollDataLoading && (
          <div>
            <Icon i="rolling" />
            &nbsp;
            Loading...
          </div>
        )}
        {loadingError && (
          <Alert color="danger">
            <strong>Unable to load payroll data</strong>
            <pre>{loadingError}</pre>
          </Alert>
        )}
        {!payrollDataLoading && employeeInfo && (
          <Table className="color-table purple-table">
            <thead>
              <tr>
                <th>Employee ID</th>
                <th>Employee Name</th>
                <th>Employee Status</th>
                <th>Employee Email</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{employeeInfo?.EmployeeID?.value || '-'}</td>
                <td>{employeeInfo?.EmployeeName?.value || '-'}</td>
                <td>{employeeInfo?.Status?.value || '-'}</td>
                <td>{employeeInfo?.ContactInfo?.Email?.value || '-'}</td>
              </tr>
            </tbody>
          </Table>
        )}
      </CardBody>
    </Card>
  );
};
