import React from 'react';
import PropTypes, { string } from 'prop-types';
import { Container, Card, CardBody } from 'reactstrap';
import PageHeader from '../app-layout/page-header';
import DToolsProjectOrders from './d-tools-project-orders';
import { ProjectVendorOrderTable } from '../widgets/project-supplier-orders-widget/project-vendor-order-table';

const stockOrders = [
  { pid: 'S2020', dtoolsId: '9160a375-811d-4188-b5ca-a546395b9020' },
  { pid: 'S3020', dtoolsId: 'cbfd31ab-ebbc-4724-9271-3c649640b57d' },
  { pid: 'S4020', dtoolsId: 'd360b76f-f771-4f17-81f7-aaf82be6462d' },
  { pid: 'S5020', dtoolsId: 'f34d726a-2045-479f-ac71-5129dc5479f6' },
  { pid: 'S6020', dtoolsId: '04f354fb-1938-4a44-8f5d-bd5d93b1f70c' },
  { pid: 'S2000', dtoolsId: 'a7eb2853-37cd-40a8-9c93-4695f5ef5b5f' },
  { pid: 'S3000', dtoolsId: '2b73426b-49e9-4e3f-9040-46447f5cb93d' },
  { pid: 'S4000', dtoolsId: 'af4cc93e-b19d-40f7-b1d5-62d7efa05ae7' },
  { pid: 'S5000', dtoolsId: 'cb958e03-ed69-4e39-bf14-007a2d986f39' },
  { pid: 'S6000', dtoolsId: '618d48b3-f0c2-4a5d-aef1-326787bd0cf3' },
  { pid: 'L1000', dtoolsId: 'cbe818ad-403d-4b72-b7a2-cc8318084a46' },
  { pid: 'D1000', dtoolsId: '6e24b966-a68c-4ed8-9aba-4ecac4513a97' },
];

class StockOrdersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProject: props.showProject,
      destination: 'MYOB',
    };
  }

  changeOrderDisplay = (e) => {
    const projectMatches = stockOrders.filter((order) => order.pid === e.target.value);
    const showProject = projectMatches[0];
    this.setState({
      showProject,
    });
  };

  changePushDestination = (e) => {
    this.setState({
      destination: e.target.value,
    });
  }

  render = () => {
    const { showProject } = this.state;
    return (
      <Container fluid>
        <PageHeader {...this.props} showBtn={false} />
        <Card>
          <CardBody>
            <div className="form-inline">
              <label className="form-label p-2 mb-4" htmlFor="ProjectChooser">
                Choose Order: &nbsp;
                <select
                  className="form-control"
                  name="ProjectChooser"
                  value={showProject.pid}
                  onChange={this.changeOrderDisplay}
                >
                  {stockOrders.map((stockOrder) => (
                    <option value={stockOrder.pid} key={stockOrder.pid}>
                      {stockOrder.pid}
                    </option>
                  ))}
                </select>
              </label>

              <label className="form-label p-2 mb-4" htmlFor="ProjectChooser">
                {' '}
                &nbsp;
                Choose Destination: &nbsp;
                <select
                  className="form-control"
                  name="DestinationChooser"
                  value={showProject.pid}
                  onChange={this.changeOrderDisplay}
                >
                  <option value="MYOB">MYOB</option>
                  <option value="QuickBooks">QuickBooks</option>
                </select>
              </label>

            </div>

            {/* Note: the project_id that is passed in here does not actually exist in the Portal DB  */}
            {this.state.destination === 'QuickBooks' ? (
              <DToolsProjectOrders
                dtoolsId={showProject.dtoolsId}
                projectData={{ id: Number.parseInt(showProject.pid.substr(1, showProject.pid.length - 1), 10) }}
              />
            ) : (
              <ProjectVendorOrderTable
                dtoolsId={showProject.dtoolsId}
                projectData={{
                  id: Number.parseInt(showProject.pid.substr(1, showProject.pid.length - 1), 10),
                  project_number: showProject.pid,
                  dtools_id: showProject.dtoolsId,
                }}
              />
            )}
          </CardBody>
        </Card>
      </Container>
    );
  };
}

StockOrdersPage.propTypes = {
  title: PropTypes.string,
  showProject: PropTypes.shape({
    pid: string,
    dtoolsId: string,
  }),
};

StockOrdersPage.defaultProps = {
  title: 'A string Value',
  showProject: stockOrders[0],
};

export default StockOrdersPage;
