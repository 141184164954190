import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NotificationBody = ({ data }) => (
  <div>
    <p>
      {data.shortMessage || ''}
    </p>

    {data.link && (
      <p>
        <Link to={data.link}>{data.linkText || 'view details'}</Link>
      </p>
    )}

  </div>
);

NotificationBody.defaultProps = {
  data: { link: '', linkText: '' },
};

NotificationBody.propTypes = {
  data: PropTypes.shape({
    shortMessage: PropTypes.string,
    link: PropTypes.string,
    linkText: PropTypes.string,
  }),
};

export default NotificationBody;
