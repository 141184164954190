import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { mapSelectInputOptions } from '../helpers/map-select.helper';
import { INVOICE_REQUEST_TYPE_NAMES } from '../constants/invoice-request-type.const';
import { DATA_TABLE_FLAG } from '../constants/data-table-flag.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';
import { fullName } from '../helpers/full-name.helper';
import { MODAL_TYPE } from '../constants/modal-type.const';
import { THEME_COLOR } from '../constants/theme-color.const';
import { INVOICE_REQUEST_NOTE_TYPE } from '../constants/invoice-request-note-type.const';

const qbAppEndpoint = process.env.REACT_APP_QUICKBOOKS_APP_ENDPOINT;
const qbPath = `${qbAppEndpoint}app/invoice?txnId=`;
/**
 * Table Definition for People (InvoiceRequests) Table
 */
const invoiceRequestsTableInitialSettings = {
  ...defaultTableSettings,
  availableFlags: [DATA_TABLE_FLAG.ONLY_MINE],
  itemCaption: 'Invoice Request',
  initialFormFocusFieldName: 'first',

  baseRoute: '/invoice-request',
  // so much of this data is overkill for the tabs... perhaps the tabs should be calling their own 'with' statements.
  baseQueryString: [
    'with[]=type:id,name',
    'with[]=status:id,name',
    'with[]=project:id,project_number,name,client_id,owner_id,quickbooks_subcustomer_id',
    'with[]=project.client:id,name,quickbooks_id',
    'with[]=project.owner:id,name',
    'with[]=project.invoicingContact',
    'with[]=project.invoicingContact.person:first,last,email',
    'with[]=projectForecast:id,project_id,amount,comment,expected_by',
    'with[]=serviceJob:id,status_id,number,name,client_id,quickbooks_id,billing_type_id,contract_id,project_id',
    'with[]=serviceJob.project:id,project_number,client_id,quickbooks_subcustomer_id',
    'with[]=serviceJob.project.client:id,name,quickbooks_id',
    'with[]=serviceJob.client:id,name,quickbooks_id',
    'with[]=serviceJob.billingType:id,name',
    'with[]=serviceJob.contract:id,status_id,client_id,number,name,quickbooks_id',
    'with[]=serviceJob.contract.client:id,name,quickbooks_id',
    'with[]=contract:id,status_id,number,client_id,quickbooks_id',
    'with[]=contract.client:id,name,quickbooks_id',
    'with[]=createdBy:id,name',
    'with[]=billToCustomer:id,name',
    'with[]=billToContact',
    'with_count[]=details',
    'with_count[]=notes',
    'with_sum[]=details.total_price',
  ].join('&'),


  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'type',
        'status_id',
        'bill_to_customer',
        'bill_to_contact',
        'project',
        'contract',
        'service_job',
        'comment',
        'to_be_sent',
        'project_forecast',
        'count_of_details',
        'sum_of_details_total_price',
        'quickbooks_invoice_number',
        'quickbooks_invoice_id',
        'created_by',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'type',
        'project',
        'service_job',
        'contract',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'type',
        'status_id',
        'bill_to_customer',
        'bill_to_contact',
        'project',
        'service_job',
        'contract',
        'comment',
        'to_be_sent',
        'project_forecast',
        'count_of_details',
        'sum_of_details_total_price',
        'quickbooks_invoice_number',
        'quickbooks_invoice_id',
        'created_by',
      ],
    },
  ],

  /**
   * Definition of columns and APIPolyForm fields for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Request ID',
      description: 'Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK_SHORT,
      linkRoute: '/finance/invoice-requests',
      visible: false,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'type',
      title: 'Type',
      description: 'Invoice Request Type',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'type',
        key: 'name',
      },
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: mapSelectInputOptions(INVOICE_REQUEST_TYPE_NAMES),
      formSaveField: 'type_id',
      visible: true,
      required: true,
      showInForm: true,
      order: 10,
      formOrder: 10,
    },
    {
      name: 'status_id',
      title: 'Status',
      format: COLUMN_FORMAT.OBJECT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      object: {
        key: 'name',
        sourceField: 'status',
        formSaveField: 'status_id',
        format: COLUMN_FORMAT.STATUS_BADGE,
        listSource: 'invoiceRequestStatus',
      },
      visible: true,
      order: 12,
      formOrder: 12,
    },
    {
      name: 'bill_to_customer',
      title: 'Bill to Client',
      description: 'The end user client for the project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'bill_to_customer',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/crm/companies',
        listSource: 'company',
      },
      formFieldType: FORM_FIELD_TYPE.COMPANY_PICKER,
      formSaveField: 'bill_to_customer_id',
      required: true,
      visible: true,
      showInForm: true,
      isCreatable: true,
      order: 15,
      formOrder: 15,
    },
    {
      name: 'bill_to_contact',
      title: 'Recipient',
      description: 'The client contact person',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'bill_to_contact',
        key: 'id',
        getValue: (value, row) => fullName(row),
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/crm/contacts',
        listSource: 'person',
      },
      required: true,
      sortColumn: 'contact.first',
      formFieldType: FORM_FIELD_TYPE.CONTACT_PICKER,
      formSaveField: 'bill_to_contact_id',
      isCreatable: true,
      getNewItemData: (inputValue, formData) => ({
        company_id: formData.bill_to_customer_id,
        company: formData.bill_to_customer,
      }),
      showInForm: true,
      order: 19,
      formOrder: 19,
    },
    {
      name: 'project',
      title: 'Project',
      format: COLUMN_FORMAT.OBJECT,
      description: 'Portal Project Number',
      placeholder: 'P12345ABC',
      object: {
        sourceField: 'project',
        key: 'project_number',
        format: COLUMN_FORMAT.P_NUMBER,
        listSource: 'project',
      },
      formSaveField: 'project_id',
      formFieldType: FORM_FIELD_TYPE.PROJECT_PICKER,
      visible: true,
      showInForm: true,
      order: 20,
      formOrder: 20,
    },
    {
      name: 'contract',
      title: 'Contract',
      description: '',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'contract',
        key: 'number',
        format: COLUMN_FORMAT.NAME_LINK_SHORT,
        linkRoute: '/service-and-support/contracts',
      },
      formFieldType: FORM_FIELD_TYPE.CONTRACT_PICKER,
      formSaveField: 'contract_id',
      visible: true,
      showInForm: true,
      order: 30,
      formOrder: 30,
    },
    {
      name: 'service_job',
      title: 'Service Job',
      description: '',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'service_job',
        key: 'number',
        format: COLUMN_FORMAT.NAME_LINK_SHORT,
        linkRoute: '/service-and-support/service-jobs',
      },
      formFieldType: FORM_FIELD_TYPE.SERVICE_JOB_PICKER,
      formSaveField: 'service_job_id',
      visible: true,
      showInForm: true,
      order: 32,
      formOrder: 32,
    },
    {
      name: 'comment',
      title: 'Comment',
      visible: true,
      format: COLUMN_FORMAT.LONG_TEXT,
      order: 35,
      formOrder: 35,
    },
    {
      name: 'to_be_sent',
      title: 'To be sent',
      visible: true,
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      order: 38,
      formOrder: 38,
    },
    {
      name: 'project_forecast',
      visible: true,
      title: 'Linked Forecast',
      formFieldType: FORM_FIELD_TYPE.PROJECT_FORECAST_PICKER,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'project_forecast',
        key: 'id',
        format: COLUMN_FORMAT.PROJECT_FORECAST_LINK,
      },
      // getValue: (row) => { console.log(row); return `${row.expected_by} - ${row.amount}`; },
      // linkRoute: (row) => `/crm/projects/${row.project_id}/forecasting`,
      formSaveField: 'project_forecast_id',
      order: 39,
      formOrder: 39,
    },
    {
      name: 'count_of_details',
      title: 'Detail lines',
      description: 'Detail lines assigned to the company',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      order: 45,
      formOrder: 45,
    },
    {
      name: 'sum_of_details_total_price',
      title: 'Total',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      order: 50,
      formOrder: 50,
    },
    {
      name: 'quickbooks_invoice_number',
      title: 'QuickBooks #',
      description: 'Invoice Number/Identifier in QuickBooks',
      format: COLUMN_FORMAT.NAME_LINK_SHORT,
      linkId: 'quickbooks_invoice_id',
      linkRoute: (row) => `${qbPath}${row.quickbooks_invoice_id}`,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      order: 55,
      formOrder: 55,
    },
    {
      name: 'quickbooks_invoice_id',
      title: 'QuickBooks Link',
      description: 'Invoice in QuickBooks',
      format: COLUMN_FORMAT.BUTTON_LINK,
      linkId: 'quickbooks_invoice_id',
      externalLink: (row) => `${qbPath}${row.quickbooks_invoice_id}`,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      buttonLabel: 'View in QuickBooks',
      visible: true,
      order: 60,
      formOrder: 60,
    },
    {
      name: 'created_by',
      title: 'Created By',
      description: 'The Ci team member who created the project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'created_by',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      formSaveField: 'owner_id',
      sortColumn: 'createdBy.name',
      visible: true,
      showInForm: true,
      required: false,
      isReadOnly: true,
      order: 70,
      formOrder: 70,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add an Invoice Request',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new Invoice Request',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the invoice request',
      successMessage: 'Updated invoiceRequest',
      failMessage: 'Failed to update InvoiceRequest',
      hideRecordActionButton: true,
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the Invoice Request',
      successMessage: 'InvoiceRequest deleted',
      failMessage: 'Failed to delete InvoiceRequest',
      hideRecordActionButton: true,
    },
    {
      name: 'submit',
      title: 'Submit',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.CHECK,
      description: 'You are about to submit this invoice request',
      successMessage: 'Invoice request submitted',
      failMessage: 'Failed to submit invoice request',
    },
    {
      name: 'process',
      title: 'Process',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.CHECK,
      description: 'You are about to import this invoice request into QuickBooks and mark it as processed...',
      successMessage: 'Invoice request processed',
      failMessage: 'Failed to process invoice request',
    },
    {
      name: 'unsubmit',
      title: 'Withdraw',
      color: BUTTON_COLOR.WARNING,
      icon: ICON.UNDO,
      description: 'This will unsubmit/withdraw the invoice request',
      successMessage: 'Invoice request withdrawn',
      failMessage: 'Failed to withdraw invoice request',
    },
    {
      name: 'reject',
      title: 'Reject',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.REJECT,
      description: 'You are about to REJECT this invoice request',
      successMessage: 'Invoice request rejected',
      failMessage: 'Failed to reject invoice request',
      modalType: MODAL_TYPE.REJECT_INVOICE_REQUEST,
    },
    {
      name: 'cancel',
      title: 'Cancel',
      color: BUTTON_COLOR.WARNING,
      icon: ICON.CANCEL,
      description:
        'This will CANCEL the invoice request. Please only use this if the invoice is no longer required and will not be sent.',
      successMessage: 'Invoice request cancelled',
      failMessage: 'Failed to cancel invoice request',
    },
    {
      name: 'push-to-quickbooks',
      title: 'Push to Quickbooks',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.CLOUD_UPLOAD,
      description: 'You are about to import this invoice request into QuickBooks...',
      successMessage: 'Invoice imported to QuickBooks',
      failMessage: 'Failed to import the Invoice Request',
      hideRecordActionButton: true,
    },
    {
      name: 'void-quickbooks',
      title: 'Void in QB',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'You are about to VOID this invoice request in QuickBooks, and unlink it here...',
      successMessage: 'Invoice VOID in QuickBooks',
      failMessage: 'Failed to void the Invoice Request',
      hideRecordActionButton: true,
    },
    {
      name: 'create-invoice-attachments-folder',
      title: 'Create the Attachments Folder',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.FOLDER,
      description: 'You are about to create an attachments folder on SharePoint...',
      successMessage: 'Attachments Folder Created',
      failMessage: 'Failed to create the attachments folder',
      hideRecordActionButton: true,
    },
    // {
    //   // this action currently requires a specialised download button
    //   // to receive blob dara so can't really function here
    //   name: 'get-pdf-preview',
    //   title: 'Preview Invoice',
    //   color: BUTTON_COLOR.PRIMARY,
    //   icon: ICON.PDF,
    //   description: 'Preview the Quickbooks Invoice as a PDF...',
    //   successMessage: 'PDF Downloaded',
    //   failMessage: 'Failed to download the PDF',
    //   hideRecordActionButton: true,
    // },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Invoice Request Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'billing',
      title: 'Recipients',
      description: 'Invoice Request Recipient Selection',
      apiRoute: '/note',
      apiQuery: [
        'filter[0][field]=type_id',
        `filter[0][operation]=${API_FILTER_OPERATION.EQUALS}`,
        `filter[0][value]=${INVOICE_REQUEST_NOTE_TYPE.BILLING_ADDRESS}`,
        'with[]=createdBy:id,name',
        'with[]=type:id,name',
        'pagelength=100',
      ].join('&'),
      widgetDefinition: WIDGET_DEFINITION.INVOICE_BILLING_INFO,
    },
    {
      name: 'quickbooks',
      title: 'QuickBooks',
      // apiRoute: '/drive-items',
      // apiQuery: '',
      description: 'Invoice Request\'s QuickBooks Links',
      widgetDefinition: WIDGET_DEFINITION.INVOICE_REQUEST_QUICKBOOKS,
    },
    {
      name: 'lines',
      title: 'Lines',
      description: 'Invoice Detail Lines',
      apiRoute: '/detail',
      apiQuery: [
        'with[]=createdBy:id,name',
        'with[]=type:id,name',
        // 'sort[0][field]=sort_order',
        // 'sort[0][direction]=asc',
        'pagelength=1000',
      ].join('&'),
      widgetDefinition: WIDGET_DEFINITION.INVOICE_REQUEST_LINES,
    },
    {
      name: 'files',
      title: 'Attachments',
      apiRoute: '/drive-items',
      apiQuery: '',
      description: 'Invoice\'s Files',
      widgetDefinition: WIDGET_DEFINITION.DRIVE_FILES,
    },
    // {
    //   name: 'attachments',
    //   title: 'Attachments',
    //   description: 'Invoice Attachments',
    //   apiRoute: '/attachment',
    //   widgetDefinition: WIDGET_DEFINITION.INVOICE_REQUEST_ATTACHMENTS,
    // },
    {
      name: 'notes',
      title: 'Notes',
      description: 'Notes',
      widgetDefinition: WIDGET_DEFINITION.INVOICE_REQUEST_NOTES,
      apiRoute: '/note',
      apiQuery: [
        'with[]=createdBy:id,name',
        'with[]=type:id,name',
        'pagelength=100',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'count_of_notes',
          badgeColor: THEME_COLOR.PRIMARY,
        },
      ],
    },
    {
      ...eventsTabDefault,
    },
  ],


  /**
   * View definitions
   */
  viewKey: 'submitted',
  views: [
    {
      key: 'actionable',
      title: 'Actionable Invoice Requests',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Actionable Invoice Requests',
        },
      ],
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.IN,
          values: ['draft', 'rejected'],
        },
      ],
      flags: [],
    },
    {
      key: 'draft',
      title: 'Draft Invoice Requests',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Draft Invoice Requests',
        },
      ],
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['draft'],
        },
      ],
      flags: [],
    },
    {
      key: 'submitted',
      title: 'Submitted Invoice Requests',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Submitted Invoice Requests',
        },
      ],
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['submitted'],
        },
      ],
      flags: [],
    },
    {
      key: 'rejected',
      title: 'Rejected Invoice Requests',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Rejected Invoice Requests',
        },
      ],
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['rejected'],
        },
      ],
      flags: [],
    },
    {
      key: 'processed',
      title: 'Processed Invoice Requests',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Processed Invoice Requests',
        },
      ],
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['processed'],
        },
      ],
      flags: [],
    },
    {
      key: 'all',
      title: 'All Invoice Requests',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'All My Leads, Proposals & Invoice Requests',
        },
      ],
      onlyMineTitle: 'My Leads, Proposals & Invoice Requests',
      filters: [],
      flags: [],
    },
  ],
};

export default invoiceRequestsTableInitialSettings;
