import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sortColumn } from '../../../actions/portal-data-table/sort-columns';
import ColumnFilter from '../filters/column-filter';
import { formatAlignTableColumn } from '../../render-functions';
import Icon from '../../layout-helpers/icon';
import { getFilterFormat } from '../../../utils/filters';
import SORT_DIRECTION from '../../../utils/sort-directions';
import { getItemByKey } from '../../../utils/helpers';
import { CurrentUserContext } from '../../providers/current-user-provider';
import { getColumnFormatWidth } from '../../../helpers/column-format-width.helper';

/**
 * @description
 * Figure out the filter description
 *
 * @example
 * getFilterDescription({ field: 'likelihood', operation: 'greater', values: [6], }, 'string', 'likelihood')
 * // > 'Is more likely than "6"'
 *
 * @returns {string} `{ columnCaption } { valueString }`
 */
export const getFilterDescription = (filter, filterFormatting) => {
  let columnCaption = 'Filtered';

  const { operations } = filterFormatting;
  if (filter && filter.operation) {
    const { operation } = filter;
    columnCaption = operations[operation] ? operations[operation].caption : operation;
  }

  return columnCaption;
};

/**
 * @description
 * Figure out which fontAwesome icon to use for the sort
 *
 * @example
 * getSortIcon({ direction: 'asc' }, 'numeric');
 * // > 'sort-numeric-asc'
 *
 * @return {string} font-awesome icon name string, or `null` if not sorting the column
 */
export const getSortIcon = (sort, filterFormatting) => {
  let sortCaret = null;
  if (sort) { sortCaret = `sort-${filterFormatting.sortIcon}-${sort}`; }
  return sortCaret;
};

/**
 * <TableHead /> component for Data Table
 */
const TableHead = (props) => {
  const {
    tableIdentifier,
    dispatchSortColumn,
    orderedVisibleColumns,
    isColumnFilterDrawerVisible,
    sortedColumns,
    filteredColumns,
    location,
    isTextWrappingEnabled,
  } = props;

  const { userHasPermissions } = useContext(CurrentUserContext);

  /**
   * @description
   * Handle sorting of a column
   *
   * @param {object} column
   */
  const handleSortColumn = (column) => {
    dispatchSortColumn(column.name, userHasPermissions);
  };

  // Returns <TableHead />
  return (
    <thead>
      <tr className="portal-datatable-head" role="row">
        {orderedVisibleColumns.map((column) => {
          const columnFilter = getItemByKey(filteredColumns, column.name, 'name');
          const filterFormatting = getFilterFormat(column);
          const minWidth = isTextWrappingEnabled ? null : getColumnFormatWidth(column);
          const filterDescription = columnFilter ? getFilterDescription(columnFilter, filterFormatting) : null;
          const sortedColumn = getItemByKey(sortedColumns, column.name, 'name');
          const columnHeaderCellStyle = {
            minWidth,
          };

          // Sort icon
          const sortCaret = getSortIcon(
            sortedColumn ? sortedColumn.direction : null,
            filterFormatting,
          );

          const uniqueId = `portal-datatable-th-${column.name}`;

          // Return column header
          return (
            <th
              title={column.description}
              onClick={() => {
                // dispatchSortColumn(column, tableIdentifier);
                handleSortColumn(column);
              }}
              className={classNames(formatAlignTableColumn(column), {
                'bg-success': columnFilter,
              })}
              key={uniqueId}
              id={uniqueId}
              style={columnHeaderCellStyle}
            >
              <div className="column-header-inner">
                <div className="title-wrapper">
                  {sortCaret && (
                    <span className="column-sort-icon">
                      <Icon i={sortCaret} />
                    </span>
                  )}
                  <span className="title">
                    {column.title}
                  </span>
                </div>
                {columnFilter && (
                  <div className="filter-description">
                    <Icon i="filter" />
                    {filterDescription && (
                      <>
                        &nbsp;
                        <span className="filter-type">{filterDescription}</span>
                      </>
                    )}
                  </div>
                )}
              </div>
            </th>
          );
        })}
      </tr>
      {isColumnFilterDrawerVisible && (
        <tr className="datatable-header-filter" role="row">
          {orderedVisibleColumns.map((column) => (
            <td
              className={classNames('datatable-header-filter-col', {
                'has-filter': getItemByKey(filteredColumns, column.name, 'name'),
              })}
              key={`filter-column-${column.name}`}
            >
              <ColumnFilter column={column} tableIdentifier={tableIdentifier} location={location} />
            </td>
          ))}
        </tr>
      )}
    </thead>
  );
};

TableHead.defaultProps = {
  isColumnFilterDrawerVisible: false,
};

TableHead.propTypes = {
  tableIdentifier: PropTypes.string.isRequired,
  isColumnFilterDrawerVisible: PropTypes.bool,
  isTextWrappingEnabled: PropTypes.bool.isRequired,

  // State
  orderedVisibleColumns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sortedColumns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    direction: PropTypes.oneOf(Object.values(SORT_DIRECTION)),
    sortIndex: PropTypes.number,
  })).isRequired,
  filteredColumns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    direction: PropTypes.oneOf(Object.values(SORT_DIRECTION)),
    sortIndex: PropTypes.number,
  })).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,

  // Dispatch
  dispatchSortColumn: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  orderedVisibleColumns: state.tableSettings[ownProps.tableIdentifier].orderedVisibleColumns,
  sortedColumns: state.tableSettings[ownProps.tableIdentifier].sortedColumns,
  filteredColumns: state.tableSettings[ownProps.tableIdentifier].filteredColumns,
  isTextWrappingEnabled: state.tableSettings[ownProps.tableIdentifier].isTextWrappingEnabled,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchSortColumn: (column) => dispatch(sortColumn(ownProps.tableIdentifier, column, true)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableHead);
