import React from 'react';
import PropTypes from 'prop-types';
import MainView from './app-layout/main-view';
import '../app.scss';
import AppNav from './app-nav';
import { ReactPortalProvider } from './react-portal/react-portal-context';
import { ModalProvider } from './modals/modal-provider';
import VHFix from './layout-helpers/vh-fix';
import MsTeamsProvider from './layout-helpers/msteams-provider';
import { APIProvider } from './providers/api-provider';
import { CurrentUserProvider } from './providers/current-user-provider';
import { UrlStateManagerProvider } from './url-state-manager/url-state-manager';
import { NotificationProvider } from './providers/notification-provider';
import 'react-contexify/dist/ReactContexify.css';
import MaintenanceMode from './error-pages/maintenance-mode';


/**
 * Main App component
 */
const App = ({ children }) => {
  const inMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE;

  if (inMaintenanceMode === true || inMaintenanceMode === 'true') return <MaintenanceMode />;

  // return app if not in maintenance mode

  return (
    <>
      <VHFix>
        <ReactPortalProvider>
          <MainView>
            <NotificationProvider>
              <APIProvider>
                <CurrentUserProvider>
                  <MsTeamsProvider>
                    <UrlStateManagerProvider>
                      <ModalProvider>
                        {children}
                        <AppNav />
                        {/* @note: Modals get rendered here */}
                      </ModalProvider>
                    </UrlStateManagerProvider>
                  </MsTeamsProvider>
                </CurrentUserProvider>
              </APIProvider>
            </NotificationProvider>
          </MainView>
        </ReactPortalProvider>
      </VHFix>
    </>
  );
};

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default App;
