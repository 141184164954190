import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import ExecDashboard from '../report-views/exec-dashboard';
import SalesPerformance from '../report-views/sales-performance';
import SalesDashboard from '../report-views/sales-dashboard';
import ProjectSummaryDashboard from '../report-views/project-summary-dashboard/project-summary-dashboard';
import SalesForecastingDashboard from '../report-views/sales-forecast-dashboard/sales-forecast-dashboard';
import FourOhFour from '../error-pages/four-oh-four';
import { ProjectItemEtaOverview } from '../report-views/project-item-eta-overview';
import { ReportDownloadsView } from '../report-views/report-downloads-view';

const DashboardRoutes = () => (
  <Switch>
    <Route
      path="/dashboards/executive-dash"
      render={(props) => <ExecDashboard {...props} title="Executive Dash" />}
    />
    <Route
      path="/dashboards/sales-performance"
      render={(props) => <SalesPerformance {...props} title="Sales Performance" />}
    />
    <Route
      path="/dashboards/sales-dashboard"
      render={(props) => <SalesDashboard {...props} title="Sales Dashboard" />}
    />
    <Redirect exact from="/dashboards/active-projects" to="/dashboards/project-summary" />
    <Route
      path="/dashboards/project-summary"
      render={(props) => <ProjectSummaryDashboard {...props} title="Project Summary" />}
    />
    <Route
      path="/dashboards/sales-forecasting"
      render={(props) => <SalesForecastingDashboard {...props} title="Sales Forecasting" />}
    />
    <Route
      path="/dashboards/item-eta-reporting"
      render={(props) => <ProjectItemEtaOverview {...props} title="ETA Status Overview" />}
    />
    <Route path="/dashboards/report-downloads" component={() => <ReportDownloadsView title="Report Downloads" />} />

    <Route exact path="/dashboards/*" component={FourOhFour} />
  </Switch>
);

DashboardRoutes.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default DashboardRoutes;
