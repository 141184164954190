import isConvertableToFiniteNumber from '../helpers/is-convertable-to-finite-number';
import SORT_DIRECTIONS from './sort-directions';
import { debugLog } from './helpers';
import { abbrevToFilterOperation, filterOperationToAbbrev } from '../constants/filter-operation-abbreviation-map.const';

/**
 * @description
 * This is the current format identifier the portal application uses
 * When a user pastes an old url into their browser, the format identifier
 * can be used to parse the url differently.
 */
export const DATATABLE_QUERY_STRING_FORMAT = 1;


const REMOVE_FROM_TABLE_SETTINGS = Symbol('REMOVE_FROM_TABLE_SETTINGS');


/**
 * @description
 * Facilitates the mapping between table settings and their associated query string representations
 */
export const tableSettingsQueryStringMap = [
  {
    format: 0,
    map: [],
  },
  {
    format: 1,
    map: [
      // Format
      {
        tableSettingKey: 'format',
        abbreviation: '_',
        toQueryStringValue: (tableSettingValue) => tableSettingValue,
        toTableSettingValue: (queryStringValue) => (isConvertableToFiniteNumber(queryStringValue)
          ? Number(queryStringValue)
          : REMOVE_FROM_TABLE_SETTINGS),
      },

      // Sorted Columns
      {
        tableSettingKey: 'sortedColumns',
        abbreviation: 'cs',
        // table settings -> query string
        toQueryStringValue: (tableSettingValue) => Object
          .values(tableSettingValue)
          .sort((a, b) => (a.sortIndex - b.sortIndex))
          .map((columnSort) => `${columnSort.name}:${columnSort.direction}`)
          .join(','),

        // query string -> table settings
        toTableSettingValue: (queryStringValue) => {
          const sortsAsStrings = queryStringValue.split(',');
          const sorts = sortsAsStrings.map((sortString, sortIndex) => {
            const [name, direction] = sortString.split(':');
            return {
              name,
              direction: Object.values(SORT_DIRECTIONS).includes(direction) ? direction : SORT_DIRECTIONS.ASC,
              sortIndex,
            };
          });
          return sorts;
        },
      },

      // Filtered Columns
      {
        tableSettingKey: 'filteredColumns',
        abbreviation: 'cf',

        // table settings -> query string
        toQueryStringValue: (tableSettingValue) => Object
          .values(tableSettingValue)
          .map((columnFilter) => {
            const abbreviatedOperation = filterOperationToAbbrev(columnFilter.operation);
            if (!abbreviatedOperation) {
              debugLog(`tableSettingsQueryStringMap.filteredColumns.toQueryStringValue: Could not abbreviate column filter operation "${columnFilter.operation}".`, 'warn', '', '🎁');
              return null;
            }
            return `${columnFilter.name}:${abbreviatedOperation}+${columnFilter
              .values
              .map((v) => (v === null ? '_null' : encodeURIComponent(v)))
              .join('+')}`;
          })
          .filter((columnFilterString) => columnFilterString !== null)
          .join(','),

        // query string -> table settings
        toTableSettingValue: (queryStringValue) => {
          const filtersAsStrings = queryStringValue.split(',');
          const filters = filtersAsStrings.map((filterString) => {
            const [fieldName, operationAndValues] = filterString.split(':');

            if (!operationAndValues) {
              debugLog(`tableSettingsQueryStringMap.filteredColumns.toTableSettingValue: Could not find filter "operationAndValues" for fieldName "${fieldName}".`, 'warn', '', '🎁');
              return null;
            }

            const [operation, ...values] = operationAndValues.split('+');
            const fullOperation = abbrevToFilterOperation(operation);

            if (!fullOperation) {
              debugLog(`tableSettingsQueryStringMap.filteredColumns.toTableSettingValue: Could not find filter "fullOperation" matching abbreviation "${operation}".`, 'warn', '', '🎁');
              return null;
            }

            return {
              name: fieldName,
              operation: fullOperation,
              values: values.map((v) => (v === '_null' ? null : decodeURIComponent(v))),
            };
          }).filter((columnFilter) => columnFilter !== null);
          return filters;
        },
      },

      // View Key
      {
        tableSettingKey: 'viewKey',
        abbreviation: 'v',
        toQueryStringValue: (tableSettingValue) => tableSettingValue,
        toTableSettingValue: (queryStringValue) => queryStringValue,
      },

      // Page Size
      {
        tableSettingKey: 'pageSize',
        abbreviation: 'ps',
        toQueryStringValue: (tableSettingValue) => tableSettingValue,
        toTableSettingValue: (queryStringValue) => (isConvertableToFiniteNumber(queryStringValue)
          ? Number(queryStringValue)
          : REMOVE_FROM_TABLE_SETTINGS),
      },

      // Active Page
      {
        tableSettingKey: 'activePage',
        abbreviation: 'p',
        toQueryStringValue: (tableSettingValue) => tableSettingValue,
        toTableSettingValue: (queryStringValue) => (isConvertableToFiniteNumber(queryStringValue)
          ? Number(queryStringValue)
          : REMOVE_FROM_TABLE_SETTINGS),
      },

      // Open Row Id
      {
        tableSettingKey: 'openRowId',
        abbreviation: 'r',
        toQueryStringValue: (tableSettingValue) => tableSettingValue,
        toTableSettingValue: (queryStringValue) => queryStringValue,
      },

      // Active Tab Key
      {
        tableSettingKey: 'activeTabKey',
        abbreviation: 't',
        toQueryStringValue: (tableSettingValue) => tableSettingValue,
        toTableSettingValue: (queryStringValue) => queryStringValue,
      },

      // Search Term
      {
        tableSettingKey: 'searchTerm',
        abbreviation: 's',
        toQueryStringValue: (tableSettingValue) => encodeURIComponent(tableSettingValue),
        toTableSettingValue: (queryStringValue) => decodeURIComponent(queryStringValue),
      },

      // Flags
      {
        tableSettingKey: 'flags',
        abbreviation: 'f',
        toQueryStringValue: (tableSettingsValue) => Object.values(tableSettingsValue).join(','),
        toTableSettingValue: (queryStringValue) => queryStringValue.split(','),
      },

      // Action
      {
        tableSettingKey: 'action',
        abbreviation: 'a',
        toQueryStringValue: (tableSettingValue) => tableSettingValue,
        toTableSettingValue: (queryStringValue) => queryStringValue,
      },
    ],
  },
];


/**
 * @description
 * Transform a Table Setting key and value to a Table Query Param component
 *
 * @param {(typeof tableSettingsQueryStringMap)[0]} mapFormat
 * @param {string} tableSettingKey
 * @param {any} tableSettingValue
 * @returns {undefined | any}
 */
const singleTableSettingToTableQueryParam = (mapFormat, tableSettingKey, tableSettingValue) => {
  const tableQueryMapping = mapFormat.map.find((map) => map.tableSettingKey === tableSettingKey);
  if (!tableQueryMapping) return undefined;

  const key = tableQueryMapping.abbreviation;
  const value = tableQueryMapping.toQueryStringValue(tableSettingValue);

  const result = `${key}=${value}`;
  return result;
};


/**
 * @description
 * Map table settings to query params
 *
 * @param {number} format
 * @param {object} tableSettings
 * @returns {string[]}
 */
const tableSettingsToTableQueryParams = (format, tableSettings) => {
  const mapFormat = tableSettingsQueryStringMap.find((whichMapping) => whichMapping.format === format);
  if (!mapFormat) return []; // invalid format;

  const queryParams = Object
    .entries({ ...tableSettings, format })
    .map(([key, value]) => ((!value || (value instanceof Array && value.length === 0))
      ? undefined
      : singleTableSettingToTableQueryParam(mapFormat, key, value)))
    .filter((param) => param !== undefined);

  return queryParams;
};

/**
 * @description
 * Map table settings to its query parameter
 *
 * @param {{
 *  sortedColumns?: {
 *    name: string,
 *    direction: 'asc' | 'desc',
 *    sortIndex: number,
 *  },
 *  filteredColumns?: {
 *    name: string,
 *    operation: string,
 *    values: (string | number)[],
 *  }[],
 *  viewKey?: string,
 *  pageSize?: number,
 *  activePage?: number,
 *  openRowId?: number,
 *  activeTabKey?: string,
 *  searchTerm?: string,
 *  flags?: string[],
 *  action?: string,
 * }} tableSettings
 * @param {string} [urlIdentifier='pdt']
 * @returns {[string, string]} [key, value] for the query string
 */
export const tableSettingsToQueryParamKeyStringValue = (tableSettings, urlIdentifier = 'pdt') => {
  const format = tableSettings.usingFormat || DATATABLE_QUERY_STRING_FORMAT;
  const queryParams = tableSettingsToTableQueryParams(format, tableSettings);

  if (queryParams.length === 0) {
    debugLog('tableSettingsToQueryParamKeyStringValue: Unable to convert table settings to its corresponding query parameter', 'warn', { tableSettings, queryParams }, '🎁');
    return [urlIdentifier, ''];
  }
  return [urlIdentifier, queryParams.sort((a, b) => a - b).join(';')];
};


/**
 * @description
 * Map table settings to a query string
 *
 * @param {{
 *  sortedColumns?: {
 *    name: string,
 *    direction: 'asc' | 'desc',
 *    sortIndex: number,
 *  },
 *  filteredColumns?: {
 *    name: string,
 *    operation: string,
 *    values: (string | number)[],
 *  }[],
 *  viewKey?: string,
 *  pageSize?: number,
 *  activePage?: number,
 *  openRowId?: number,
 *  activeTabKey?: string,
 *  searchTerm?: string,
 *  flags?: string[],
 *  action?: string,
 * }} tableSettings
 * @param {string} [urlIdentifier='pdt']
 * @returns {string}
 */
export const tableSettingsToQueryString = (tableSettings, urlIdentifier = 'pdt') => {
  const [paramKey, paramStringValue] = tableSettingsToQueryParamKeyStringValue(tableSettings, urlIdentifier);
  return `${paramKey}=${paramStringValue}`;
};


/**
 * @description
 * Extract the Table Settings component from a Query String
 *
 * @example
 * return `pdt=...`
 * return undefined
 *
 * @param {string} queryString
 * @param {string} urlIdentifier
 * @returns {string | undefined} undefined if not found
 */
export const queryStringToTableSettingsQueryString = (queryString, urlIdentifier = 'pdt') => {
  // cut out the urlIdentifier (removing ? from start of query string if necessary)
  const allQueryParams = (queryString.charAt(0) === '?'
    ? queryString.substr(1, queryString.length)
    : queryString).split('&');

  const tableQueryString = allQueryParams.find((param) => param.startsWith(`${urlIdentifier}=`));

  return tableQueryString;
};


/**
 * @description
 *
 * @param {string} queryString
 * @param {string} [urlIdentifier='pdt']
 * @returns {{
 *  sortedColumns?: {
 *    name: string,
 *    direction: 'asc' | 'desc',
 *    sortIndex: number,
 *  },
 *  filteredColumns?: {
 *    name: string,
 *    operation: string,
 *    values: (string | number)[],
 *  }[],
 *  viewKey?: string,
 *  pageSize?: number,
 *  activePage?: number,
 *  openRowId?: number,
 *  activeTabKey?: string,
 *  searchTerm?: string,
 *  flags?: string[],
 *  action?: string,
 * }}
 */
export const queryStringToTableSettings = (queryString, urlIdentifier = 'pdt') => {
  const tableQueryString = queryStringToTableSettingsQueryString(queryString, urlIdentifier);

  if (!tableQueryString) {
    debugLog(`queryStringToTableSettings: Could not find "${urlIdentifier}=" in queryString: "${queryString}"`, 'warn', { tableQueryString }, '🎁');
    return {};
  }

  const paramValueStartsAt = tableQueryString.indexOf('=') + 1;
  if (paramValueStartsAt === tableQueryString.length) {
    debugLog(`queryStringToTableSettings: url key "${urlIdentifier}" doesn't have a value`, 'warn', { tableQueryString }, '🎁');
    return {};
  }

  /**
   * Array of keys and values from the tableQueryString
   * @type {[string, string][]} params
   */
  const params = tableQueryString
    .substr(paramValueStartsAt, tableQueryString.length)
    .split(';')
    // split keys and values
    .map((stringParam) => stringParam.split('='));

  // get format
  const formatKeyValue = params.find((param) => param[0] === '_');
  const format = (formatKeyValue instanceof Array) && isConvertableToFiniteNumber(formatKeyValue[1])
    ? Number(formatKeyValue[1])
    : DATATABLE_QUERY_STRING_FORMAT;

  const mapFormat = tableSettingsQueryStringMap.find((whichMapping) => whichMapping.format === format);
  if (!mapFormat) {
    debugLog(`queryStringToTableSettings: Could not find mapping for format "${format}"`, 'warn', '', '🎁');
    return {};
  }

  const tableSettings = params
    .map(([key, value]) => {
      const mapping = mapFormat.map.find((map) => map.abbreviation === key);
      if (!mapping) return null;

      const tableSettingsKey = mapping.tableSettingKey;
      const tableSettingsValue = mapping.toTableSettingValue(value);
      if (tableSettingsValue === REMOVE_FROM_TABLE_SETTINGS) return null;

      return [tableSettingsKey, tableSettingsValue];
    })
    .filter((param) => param !== null)
    .reduce((accumulatingTableSettings, [tableSettingsKey, tableSettingsValue]) => {
      accumulatingTableSettings[tableSettingsKey] = tableSettingsValue;
      return accumulatingTableSettings;
    }, {});

  return tableSettings;
};
