import React, { useCallback, useContext } from 'react';

import { CurrentUserContext } from '../providers/current-user-provider';

import { useIsDirty } from '../../react-hooks/use-is-dirty.hook';

import { IFormFieldOverride } from '../../types/poly-form/form-field-override.interface';
import { IInternalProjectRecord } from '../../types/internal-project/internal-project.record.interface';
import { NewRecordModalProps } from '../../types/modal/new-record-modal.props';

import { Modal } from './Modal';
import { FormFieldRenderer } from '../poly-forms/form-field-renderer';
import { NewInternalProjectForm } from '../poly-forms/new-internal-project-form';

import { PERMISSION } from '../../constants/permissions.const';
import { FORM_FIELD_TYPE } from '../../constants/form-field-type.const';
import { COLUMN_FORMAT } from '../../constants/column-format.const';

export type NewInternalProjectModalProps = NewRecordModalProps<IInternalProjectRecord> & {
  formFieldOverrides?: IFormFieldOverride[],
}

export const NewInternalProjectModal:React.FC<NewInternalProjectModalProps> = (props) => {
  const {
    id, closeModal, onModalComplete, initialData, formFieldOverrides,
  } = props;

  const { userHasPermissions } = useContext(CurrentUserContext);
  const showFullForm = userHasPermissions([
    PERMISSION.INTERNAL_PROJECT_ADMIN,
    PERMISSION.INTERNAL_PROJECT_EDITOR,
  ]);

  const [isDirty, setDirty] = useIsDirty();

  /**
   * Fired when the form successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, recordId: IInternalProjectRecord['id'], newRecord?: IInternalProjectRecord) => {
    closeModal(id);
    onModalComplete((success && newRecord) ? { success, newRecord } : { success: false, newRecord: null });
  }, [closeModal, id, onModalComplete]);

  /**
   * <projectForm/>
   */
  const projectForm = (
    <NewInternalProjectForm
      onFormComplete={handleFormComplete}
      onFormChange={setDirty}
      initialData={initialData}
      formFieldOverrides={formFieldOverrides}
    />
  );

  return (
    <Modal
      title="Create a new Internal Project"
      allowDismiss
      isDirty={isDirty}
      size="lg"
      {...props}
    >
      {showFullForm ? projectForm : (
        <>
          <p><strong>Search for Existing Projects:</strong></p>
          <div className="p-2">
            <p>
              Please search for internal projects which might already match your requirement.
            </p>
            <p>
              If you&apos;re unsure, feel free to have a chat with the team to see if we&apos;re already
              working on something that might cover your need.
            </p>
            <div className="p-2">
              <FormFieldRenderer
                id="internal_project_search"
                field={{
                  name: '_internal_project_search',
                  formFieldType: FORM_FIELD_TYPE.INTERNAL_PROJECT_PICKER,
                  format: COLUMN_FORMAT.TEXT,
                  visible: true,
                  title: 'Search for existing internal projects',
                  placeholder: 'Check to see if your internal projects already exists',
                  isCreatable: false,
                }}
                errors={{}}
              />
            </div>
          </div>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <div className="pt-2 pb-3"><strong>Create a new Project:</strong></div>
          <div className="p-2">
            {projectForm}
          </div>
        </>
      )}
    </Modal>
  );
};
