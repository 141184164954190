import React from 'react';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';

import AsyncSelect from './async-select';

export type StakeholderRolePickerProps = AsyncSelectComponentProps;

export const StakeholderRolePicker: React.FC<StakeholderRolePickerProps> = (props) => {
  const {
    formSaveField = 'type_id',
  } = props;
  return (
    <AsyncSelect
      {...props}
      formSaveField={formSaveField}
      searchRoute="/internal-project/stakeholder/role"
      loadAndKeepAll
    />
  );
};
