/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

/**
 * Hook to run a function on first load only.
 * This is basically something to get around eslint always complaining about
 * exhaustive-deps for the callback.
 *
 * Since we only want this callback to fire on the first mount, there shouldn't be
 * any complaint here.
 *
 * usage:
 *    useMountEffect(() => {
 *      // Do your on-mount work here
 *    });
 *
 * @returns [true] if the component is mounted and has rendered at least once
 */
export const useMountEffect = (callback: () => void): void => useEffect(callback, []);
