import { IPortalTreeViewSelectedNodeIdentifier } from '../../types/portal-tree-view/portal-tree-view-selected-node-identifier.interface';

/**
 * Helper function to ensure that only a single node is the "selected" node.
 * If a selectedNodes array contains more or less than one item, this function returns undefined
 */
export const getSelectedNode = <S extends IPortalTreeViewSelectedNodeIdentifier = IPortalTreeViewSelectedNodeIdentifier>(
  selectedNodes: S[],
): undefined | S => {
  if (selectedNodes.length === 1) {
    return selectedNodes[0];
  }
  return undefined;
};
