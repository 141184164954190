import React, {
  useState, useCallback, useRef, useEffect, useContext,
} from 'react';
import classNames from 'classnames';
import { Input, Button, Label } from 'reactstrap';

import { IModalButton } from '../../types/modal/modal-button.interface';
import { ModalProps } from '../../types/modal/modal.props';
import { ReturnValueModalResult } from '../../types/modal/modal-result';

import { APIContext } from '../providers/api-provider';

import { Modal } from './Modal';
import Icon from '../layout-helpers/icon';

import { apiAborter } from '../../helpers/api-aborter.helper';

import { MODAL_BUTTON_SIDE_TYPE } from '../../constants/modal-button-side-type.const';
import { BUTTON_COLOR } from '../../constants/button-color.const';
import { ICON } from '../../constants/icon.const';

export type FindMicrosoftADUserIdModalProps = ModalProps<ReturnValueModalResult<string>> & {
  userRecord: {
    name: string,
    email: string,
  },
  onCancel?: () => void,
}


/**
 * This modal is specific to the user form and allows a user to locate the
 * microsoft active directory user ID for a portal user.
 */
export const FindMicrosoftADUserIdModal:React.FC<FindMicrosoftADUserIdModalProps> = (props) => {
  const {
    id,
    closeModal,
    onModalComplete,
    onCancel,
    userRecord: {
      name,
      email,
    },
  } = props;

  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [currentEmailAddress, setCurrentEmailAddress] = useState<string>(email);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<{
    id: string,
    accountEnabled: boolean,
    name: string,
    email: string,
  }[]>([]);
  const [lastAttemptSuccess, setLastAttemptSuccess] = useState<boolean | null>(null);
  const [lastAttemptErrorMessage, setLastAttemptErrorMessage] = useState<string | null>(null);

  const abortSearchRequest = useRef<AbortController | null>(null);

  const { apiFetch } = useContext(APIContext);

  // Use provided buttons or create default buttons
  const modalButtons: IModalButton[] = [
    {
      label: 'Select',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.CHECK,
      sideType: MODAL_BUTTON_SIDE_TYPE.RIGHT,
      onClick: () => {
        closeModal(id);
        if (typeof onModalComplete === 'function') {
          onModalComplete(selectedUserId ? { success: true, value: selectedUserId } : { success: false, value: null });
        }
      },
      disabled: (typeof onModalComplete !== 'function' || !selectedUserId),
    },
    {
      label: 'Cancel',
      color: BUTTON_COLOR.SECONDARY,
      sideType: MODAL_BUTTON_SIDE_TYPE.LEFT,
      onClick: () => {
        closeModal(id, false, onCancel);
      },
    },
  ];

  /**
   * Handle the click of the "Find button"
   */
  const handleClickFind = useCallback(() => {
    if (isSearching) return;

    setIsSearching(true);
    setSearchResults([]);
    setLastAttemptSuccess(null);
    setLastAttemptErrorMessage(null);
    setSelectedUserId(null);

    if (abortSearchRequest.current) {
      abortSearchRequest.current.abort();
    }
    abortSearchRequest.current = apiAborter();

    apiFetch(
      `/microsoft-graph-connector/users?filter=startswith('${currentEmailAddress}',mail)`,
      {
        name: 'FindMicrosoftADUserIDModal::findUsers',
        signal: abortSearchRequest.current.signal,
      },
    ).then((response) => {
      if (response.success) {
        abortSearchRequest.current = null;

        const results = Array.isArray(response.body) ? response.body : [];

        setIsSearching(false);
        setSearchResults(results.map((item) => (
          {
            id: item.id,
            accountEnabled: item.accountEnabled,
            name: item.displayName,
            email: item.mail,
          }
        )));
        setLastAttemptSuccess(true);
        setLastAttemptErrorMessage(null);

        // If there is only one result, automatically select that result
        if (results.length === 1) {
          setSelectedUserId(results[0].id);
        }
      } else if (!response.aborted) {
        abortSearchRequest.current = null;
        console.error('FindMicrosoftADUserIDModal::findUsers', response.error);

        setIsSearching(false);
        setSearchResults([]);
        setLastAttemptSuccess(false);
        setLastAttemptErrorMessage(response.error);
      }
    });
  }, [apiFetch, isSearching, currentEmailAddress, setIsSearching, setLastAttemptSuccess, setLastAttemptErrorMessage, setSearchResults]);


  /**
   * @description
   * Make sure any outgoing calls to the API are terminated gracefully if the component is unmounted
   */
  useEffect(() => {
    // Kick off the search as soon as the component is mounted
    handleClickFind();

    // Component will Un-Mount
    return () => {
      if (abortSearchRequest.current) {
        abortSearchRequest.current.abort();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Modal
      {...props}
      className="find-microsoft-ad-user-id-modal"
      title="Find Microsoft Active Directory User ID"
      buttons={modalButtons}
    >
      <div>
        <p>
          Specify the registered email as entered for
          <strong>{` ${name} `}</strong>
          in the
          <a href="https://admin.microsoft.com/Adminportal/Home?source=applauncher#/users" target="_blank" rel="noopener noreferrer"> Microsoft Active Directory user list </a>
          to find their Microsoft A.D. User ID.
        </p>
      </div>

      <div className="email-wrapper">
        <Label for="email">Email:</Label>
        <Input
          id="email"
          name="email"
          value={currentEmailAddress}
          onChange={(e) => setCurrentEmailAddress(e.target.value)}
          onKeyPress={(e) => {
            if (e.key.toLowerCase() === 'enter') {
              e.currentTarget.blur();
              handleClickFind();
            }
          }}
        />
        <Button
          onClick={handleClickFind}
          disabled={isSearching}
        >
          {!isSearching && (
            <Icon i="search" />
          )}
          {isSearching && (
            <Icon i="spinner" />
          )}
          <span>Find</span>
        </Button>
      </div>

      {/* Last search failed for some reason */}
      {!isSearching && (lastAttemptSuccess === false) && (
        <p>
          <Label className="text-danger">{`Error: ${lastAttemptErrorMessage}`}</Label>
        </p>
      )}

      {/* No results or search has not yet been performed */}
      {isSearching && (
        <p>
          Searching...
        </p>
      )}

      {/* No results or search has not yet been performed */}
      {!isSearching && (searchResults.length === 0) && (
        <p>
          No user(s) found. Try only searching for the start of their email address.
        </p>
      )}

      {/* results returned from the search */}
      {!isSearching && (
        <div className="results-wrapper">
          <div>
            <p>
              {`${searchResults.length} result(s) found:`}
            </p>
          </div>
          {searchResults
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((result) => (
              <div className={classNames('microsoft-account', { disabled: !result.accountEnabled })} key={result.id}>
                <div className="user-details">
                  <span>
                    <strong>{result.name}</strong>
                  </span>
                  <span>{result.email}</span>
                  <span>{result.id}</span>
                </div>

                {/* Don't bother rendering out a "use" button if there's only one result */}
                {searchResults.length > 1 && (
                  <div className="button-wrapper">
                    <Button
                      color={selectedUserId === result.id ? 'primary' : undefined}
                      active={selectedUserId === result.id}
                      onClick={() => setSelectedUserId(result.id)}
                    >
                      Use
                    </Button>
                  </div>
                )}
              </div>
            ))}
        </div>
      )}

    </Modal>
  );
};
