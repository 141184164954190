export const FORM_RENDERER_TYPE = {
  VERTICAL: 'VERTICAL_FORM',
  TABLE_ROW: 'TABLE_ROW',
  NOTE: 'NOTE',
  FILE: 'FILE',
  EVENT: 'EVENT',
  CONTACT: 'CONTACT',
  PROJECT_CARD: 'PROJECT_CARD',
  SWIMLANE_CARD: 'SWIMLANE_CARD',
  NOTIFICATION: 'NOTIFICATION',
  COMPANY_LOCATION: 'COMPANY_LOCATION',
  COMPANY_SPACE: 'COMPANY_SPACE',
} as const;

export type FORM_RENDERER_TYPE = typeof FORM_RENDERER_TYPE;
export type A_FORM_RENDERER_TYPE = FORM_RENDERER_TYPE[keyof FORM_RENDERER_TYPE];
