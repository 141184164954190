import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';

import { ContractSerialsWidget } from '../components/widgets/contract-serials.widget';

import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';

export const ContractSerialsWidgetDefinition: ICollectionWidgetDefinition = {
  component: ContractSerialsWidget,
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Serial',
  fields: [
    {
      name: 'contract_id',
      visible: false,
      isParentId: true,
    },
    {
      name: 'serial',
      title: 'Serial / Licence Number',
      visible: true,
      format: COLUMN_FORMAT.MEDIUM_TEXT,
    },
  ],
};
