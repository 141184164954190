import { API_FILTER_OPERATION } from './api-filter-operation.const';

/**
 * These Filter Operations are used in the portal data table
 * filtered columns list to describe what kind of filter is applied
 * to the column
 */
export const FILTER_OPERATION = {
  // Identical to the Portal API IndexFilter operations
  ...API_FILTER_OPERATION,

  // Not available in the Portal API
  NOT_IN: 'not-in',
  STARTS_WITH: 'start-with',
  ENDS_WITH: 'end-with',
  CLEAR: 'clear',
};
export type FILTER_OPERATION = typeof FILTER_OPERATION;
export type A_FILTER_OPERATION = FILTER_OPERATION[keyof FILTER_OPERATION];
