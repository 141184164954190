import React, { useCallback, useContext } from 'react';

import { CurrentUserContext } from '../providers/current-user-provider';
import { ModalContext } from '../modals/modal-context';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';
import { FormFieldChangeProps } from '../../types/poly-form/form-field-change-props';
import { IUserRecord } from '../../types/user/user.record.interface';
import { NewRecordModalResult } from '../../types/modal/modal-result';

import AsyncSelect from './async-select';

import { fullNameToNameComponents } from '../../helpers/full-name-to-components.helper';

import { PERMISSION } from '../../constants/permissions.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';

export type UserPickerProps = Omit<AsyncSelectComponentProps, 'onChange'> & {
  onChange?: (field: FormFieldChangeProps<IUserRecord>) => void,
  type?: 'commission-users' | null
};

const activeUserFilter = '&filter[0][field]=active&filter[0][value]=1&filter[0][operation]=equals';
const commissionUserFilter = '&filter[1][field]=eligible_for_commission&filter[1][value]=1&filter[1][operation]=equals';

/**
 * User Picker
 * By default, the search results wil be filtered for active users
 * If a user presses the Control Key, the active user filter will be bypassed
 */
export const UserPicker: React.FC<UserPickerProps> = (props) => {
  const {
    name,
    formData = {},
    parentData = {},
    formSaveField = null,
    isCreatable = false,
    includeArchived = false,
    type = null,
    onChange,
    getNewItemData,
  } = props;

  const { showModal } = useContext(ModalContext);
  const { userHasPermissions } = useContext(CurrentUserContext);

  // Show a modal to create a new user
  const handleCreateOption = useCallback((inputValue) => {
    const initialData = {
      ...fullNameToNameComponents(inputValue),
      ...(getNewItemData ? getNewItemData(inputValue, formData, parentData) : undefined),
    };

    showModal<NewRecordModalResult<IUserRecord>>(MODAL_TYPE.NEW_USER, {
      initialData,
      onModalComplete: ({ success, newRecord: newUser }) => {
        if (success && onChange && newUser) { onChange({
          fieldName: formSaveField || `${name}_id`,
          newValue: newUser.id,
          objectFieldName: name,
          objectFieldNewValue: newUser,
        }); }
      },
    });
  }, [formData, formSaveField, getNewItemData, name, onChange, parentData, showModal]);


  let appendQuery: string | undefined;

  if (includeArchived) {
    appendQuery = activeUserFilter;
  }

  if (type === 'commission-users') {
    appendQuery = includeArchived ? `${activeUserFilter}${commissionUserFilter}` : commissionUserFilter;
  }

  return (
    <AsyncSelect
      {...props}
      searchRoute="/user"
      appendQuery={appendQuery}
      loadAndKeepAll
      onCreateOption={handleCreateOption}
      // Make sure the current user has permissions to create a new user before allowing them to create a user
      isCreatable={!!isCreatable && userHasPermissions([
        PERMISSION.SECURITY_USER_MANAGER,
        PERMISSION.SECURITY_ADMIN,
      ])}
    />
  );
};
