import React, { useCallback } from 'react';

import { ITaskTemplateRecord } from '../../types/internal-project/task-template.record.interface';
import { NewRecordModalProps } from '../../types/modal/new-record-modal.props';

import { useIsDirty } from '../../react-hooks/use-is-dirty.hook';
import { Modal } from './Modal';

import { NewTaskTemplateForm } from '../poly-forms/new-task-template-form';

export const NewTaskTemplateModal:React.FC<NewRecordModalProps<ITaskTemplateRecord>> = (props) => {
  const {
    id, closeModal, onModalComplete, initialData,
  } = props;

  const [isDirty, setDirty] = useIsDirty();

  /**
   * Fired when the form successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, recordId: ITaskTemplateRecord['id'], newRecord?: ITaskTemplateRecord) => {
    closeModal(id);
    onModalComplete((success && newRecord) ? { success, newRecord } : { success: false, newRecord: null });
  }, [closeModal, id, onModalComplete]);

  // Render
  return (
    <Modal
      {...props}
      title="Create a new Task Template"
      allowDismiss
      isDirty={isDirty}
      size="lg"
    >
      <NewTaskTemplateForm
        onFormComplete={handleFormComplete}
        onFormChange={setDirty}
        initialData={initialData}
      />
    </Modal>
  );
};
