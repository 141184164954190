import { AN_ICON, ICON } from './icon.const';

export const COMPANY_LOCATION_TYPE = {
  SITE: 1,
  BUILDING: 2,
  LEVEL: 3,
  ZONE: 4,
} as const;
export type COMPANY_LOCATION_TYPE = typeof COMPANY_LOCATION_TYPE;
export type A_COMPANY_LOCATION_TYPE = COMPANY_LOCATION_TYPE[keyof COMPANY_LOCATION_TYPE];

export const CompanyLocationTypeNameMap: Record<A_COMPANY_LOCATION_TYPE, string> = {
  [COMPANY_LOCATION_TYPE.SITE]: 'Site',
  [COMPANY_LOCATION_TYPE.BUILDING]: 'Building',
  [COMPANY_LOCATION_TYPE.LEVEL]: 'Level',
  [COMPANY_LOCATION_TYPE.ZONE]: 'Zone',
};

export const CompanyLocationTypeIconMap: Record<A_COMPANY_LOCATION_TYPE, AN_ICON> = {
  [COMPANY_LOCATION_TYPE.SITE]: ICON.SITE,
  [COMPANY_LOCATION_TYPE.BUILDING]: ICON.BUILDING,
  [COMPANY_LOCATION_TYPE.LEVEL]: ICON.LEVEL,
  [COMPANY_LOCATION_TYPE.ZONE]: ICON.ZONE,
};
