import React from 'react';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { IDataTableRecord } from '../../../types/portal-data-table/portal-data-table-record.interface';
import { InMemoryTable } from '../../portal-data-table/in-memory-table';
import { getOrderStatusLink } from './eta-reporting-utilities';

interface IOrderStatusSummaryTableProps {
  recordData: IDataTableRecord[],
  projectId?: number,
  projectManagerId?: string | number | null,
}

const makeBlanksLinkable = (value: string | number | null) => (value === null ? '[ Blank ]' : value);

export const OrderStatusSummaryTable: React.FC<IOrderStatusSummaryTableProps> = ({ recordData, projectId, projectManagerId }) => (
  <InMemoryTable
    title="Items by Status"
    data={recordData}
    columns={[{
      title: 'Status',
      name: 'order_status',
      format: COLUMN_FORMAT.NAME_LINK,
      getValue: makeBlanksLinkable,
      linkRoute: (row: IDataTableRecord) => getOrderStatusLink(row.order_status, projectId, projectManagerId),
      filterType: 'pickListFromRecordset',
    }, {
      title: 'Items',
      name: 'items',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      filterType: 'number',
    }]}
    paginate
  />
);
