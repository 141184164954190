import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFilterFormat } from '../../../utils/filters';
import CustomFilterSelect from './custom-filter-select';
import ListSelect from './list-select';
import { filterColumn } from '../../../actions/portal-data-table/filter-actions';
import { getItemByKey, realFilterValue } from '../../../utils/helpers';
import AsyncSelectFilter from './async-select-filter';
import { COLUMN_FILTER_TYPES } from '../../../utils/column-filter-types';
import { FILTER_OPERATION } from '../../../constants/filter-operation.const';

/**
 * ColumnFilter component for Data Columns filters
 */
class ColumnFilter extends Component {
  constructor(props) {
    super(props);

    // this.state = ColumnFilter.getDerivedStateFromProps(props, {});
    this.debounceTimer = null;
  }


  /**
   * @description
   * Fired when a list-select or async-select-filter has a resource changed
   *
   * @param {Object[]} selectedResources the selectedResources resources
   * @param {boolean} [pushToHistory=false]
   */
  handleSelectChange = (selectedResources, pushToHistory = false) => {
    const { column } = this.props;
    const filterFormatting = getFilterFormat(column);

    const newColumnFilter = selectedResources.length > 0 ? {
      name: column.name,
      values: (selectedResources || []).map((value) => realFilterValue(value, filterFormatting.valueKey || 'id')),
      operation: (selectedResources instanceof Array && selectedResources.length > 1) ? FILTER_OPERATION.IN : FILTER_OPERATION.EQUALS,
    } : null;

    this.props.dispatchFilterColumn(column.name, newColumnFilter, pushToHistory);
  };


  /**
   * Render <ColumnFilters />
   * @todo fix styles and change 'status' to 'status_id'
   */
  render() {
    const {
      column,
      tableIdentifier,
      columnFilter,
      isLoading,
      location,
    } = this.props;

    const filterFormatting = getFilterFormat(column);

    switch (filterFormatting.filterType) {
      case COLUMN_FILTER_TYPES.ASYNC_OPTION_LIST: {
        return (
          <AsyncSelectFilter
            fieldName={column.name}
            onSelectChange={this.handleSelectChange}
            dataSource={filterFormatting.options}
            columnFilter={columnFilter}
            includeBlankOption={!column.required}
            location={location}
          />
        );
      }
      case COLUMN_FILTER_TYPES.YES_NO: {
        return (
          <ListSelect
            fieldName={column.name}
            options={filterFormatting.options}
            onSelectChange={this.handleSelectChange}
            values={columnFilter instanceof Object ? columnFilter.values : []}
            includeBlankOption={!column.formRequired}
            location={location}
            allowMultiSelect={false}
          />
        );
      }
      case COLUMN_FILTER_TYPES.OPTION_LIST: {
        return (
          <ListSelect
            fieldName={column.name}
            options={filterFormatting.options}
            onSelectChange={this.handleSelectChange}
            values={columnFilter instanceof Object ? columnFilter.values : []}
            includeBlankOption={!column.required}
            location={location}
          />
        );
      }
      case COLUMN_FILTER_TYPES.TEXT:
      case COLUMN_FILTER_TYPES.DATE:
      case COLUMN_FILTER_TYPES.LIKELIHOOD:
      case COLUMN_FILTER_TYPES.SET_NOT_SET:
      case COLUMN_FILTER_TYPES.NUMERIC: {
        return (
          <CustomFilterSelect
            tableIdentifier={tableIdentifier}
            column={column}
            currentFilter={columnFilter}
            type={filterFormatting.filterType}
            isLoading={isLoading}
            location={location}
          />
        );
      }
      case COLUMN_FILTER_TYPES.ICON_ONLY: {
        // Don't put a wide no-filter in
        return (
          <div className="no-filter p-1">
            <span>N/A</span>
          </div>
        );
      }
      default: {
        // Fallback - no filter
        return (
          <div className="no-filter p-1">
            <span>No Filter</span>
          </div>
        );
      }
    }
  }
}

ColumnFilter.propTypes = {
  tableIdentifier: PropTypes.string.isRequired,
  column: PropTypes.shape({
    name: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    required: PropTypes.bool,
    formRequired: PropTypes.bool,
  }).isRequired,
  columnFilter: PropTypes.shape({
    name: PropTypes.string.isRequired,
    operation: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(
      PropTypes.string, // filter values can be null -> trying to filter for blanks
    ).isRequired,
  }),
  dispatchFilterColumn: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

ColumnFilter.defaultProps = {
  columnFilter: null,
};

const mapStateToProps = (state, ownProps) => {
  const {
    column,
  } = ownProps;

  const {
    pageSize,
    activePage,
    filteredColumns,
    isLoading,
  } = state.tableSettings[ownProps.tableIdentifier];

  const columnFilter = getItemByKey(filteredColumns, column.name, 'name') || null;

  return {
    pageSize,
    activePage,
    columnFilter,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchFilterColumn: (columnName, filter, pushToHistory) => dispatch(filterColumn(ownProps.tableIdentifier, columnName, filter, pushToHistory)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ColumnFilter);
