import { ApiQueryCacheData, StoreApiQueryCacheAction, STORE_API_QUERY_CACHE } from '../actions/api-query-cache-actions';

const initialState: {
  // @todo: strictly type this to avaliable cache types only
  [key: string]: ApiQueryCacheData
} = {
  states: {
    lastRefresh: null,
    expiresInMinutes: 30,
    queryResponse: null,
  },
};

export default (state = initialState, action: StoreApiQueryCacheAction): {
  [key: string]: ApiQueryCacheData
} => {
  const { queryCacheData } = action;
  switch (action.type) {
    case STORE_API_QUERY_CACHE:
      // return state
      return {
        ...state,
        [action.queryName]: {
          ...state[action.queryName],
          lastRefresh: queryCacheData.lastRefresh,
          expiresInMinutes: queryCacheData.expiresInMinutes || state[action.queryName].expiresInMinutes,
          queryResponse: queryCacheData.queryResponse,
        },
      };
    default:
      return state;
  }
};
