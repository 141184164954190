import { defaultTableSettings } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

/**
 * Table Definition for States Table
 */
const statesTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'State',
  initialFormFocusFieldName: 'first',
  baseRoute: '/state',
  baseQueryString: [
    'with[]=manager:id,name',
    'with[]=supportPerson:id,name',
  ].join('&'),

  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'name',
        'acronym',
        'initial',
        'manager',
        'support_person',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'name',
        'acronym',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'name',
        'acronym',
        'initial',
        'manager',
        'support_person',
      ],
    },
  ],

  /**
   * Definition of columns and APIPolyForm fields for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Identity',
      description: 'Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      linkRoute: '/internal-projects/states',
      visible: false,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'name',
      title: 'Name',
      description: 'State Name',
      format: COLUMN_FORMAT.NAME_LINK,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      linkRoute: '/internal-projects/states',
      visible: true,
      showInForm: true,
      required: true,
      order: 10,
      formOrder: 10,
    },
    {
      name: 'acronym',
      title: 'State Acronym',
      description: 'State Acronym',
      format: COLUMN_FORMAT.SHORT_TEXT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      required: true,
      order: 20,
      formOrder: 20,
    },
    {
      name: 'initial',
      title: 'State Initial',
      description: 'State Initial',
      format: COLUMN_FORMAT.SHORT_TEXT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      required: true,
      order: 25,
      formOrder: 25,
    },
    {
      name: 'manager',
      title: 'Manager',
      description: 'The State Manager',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'manager',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
      formSaveField: 'manager_id',
      sortColumn: 'manager.name',
      visible: true,
      showInForm: true,
      required: true,
      order: 30,
      formOrder: 30,
    },
    {
      name: 'support_person',
      title: 'Support Person',
      description: 'The State Support Person',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'support_person',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
      formSaveField: 'support_user_id',
      sortColumn: 'support_person.name',
      visible: true,
      showInForm: true,
      required: true,
      order: 30,
      formOrder: 30,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the state',
      successMessage: 'Updated state',
      failMessage: 'Failed to update State',
      hideRecordActionButton: true,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'State Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
  ],
};

export default statesTableInitialSettings;
