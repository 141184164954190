import React from 'react';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';

import AsyncSelect from './async-select';

export type CommissionIterationPickerProps = AsyncSelectComponentProps;

export const CommissionIterationPicker: React.FC<CommissionIterationPickerProps> = (props) => (
  <AsyncSelect
    {...props}
    searchRoute="/commission-structure/iteration"
    loadAndKeepAll
  />
);
