/**
 * @description
 * Concatenates a person's first, middle and last names together and cleanly trims the result
 */
export const fullName = (param0: null | {
   first?: null | string,
   middle?: null | string,
   last?: null | string
}): null | string => {
  if (param0 === null) return null;

  return `${`${param0.first ?? ''} ${param0.middle ?? ''}`.trim()} ${param0.last ?? ''}`.trim();
};
