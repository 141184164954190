import { defaultTableSettings } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { yesNoOptions } from '../utils/constants';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { DATA_TABLE_FLAG } from '../constants/data-table-flag.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';

/**
 * Table Definition for Project ETA item Summary
 */
const etaSummaryTableInitialSettings = {
  ...defaultTableSettings,
  baseRoute: '/dtools-cache/project-eta-summary',
  availableFlags: [DATA_TABLE_FLAG.ONLY_MINE],
  baseQueryString: [
    'with[]=project:id,project_number,partner_program_id',
    'with[]=status',
    'with[]=state:id,name,initial',
    'with[]=client:id,name',
    'with[]=projectManager:project_id,project_manager_id,name',
  ].join('&'),

  columnPresets: [
    {
      name: 'default',
      title: 'default',
      fields: [
        'cache_status',
        'project_number',
        'number',
        'name',
        'state',
        'project_manager',
        'progress',
        'status',
        'item_count',
        'past_expected_items',
      ],
    },
    {
      name: 'all',
      title: 'all',
      fields: [
        'cache_status',
        'project_id',
        'project_number',
        'number',
        'name',
        'state',
        'client',
        'project_manager',
        'progress',
        'status',
        'approved',
        'cost',
        'price',
        'tax',
        'margin',
        'markup',
        'item_count',
        'past_expected_items',
        'past_required_items',
        'updated_at',
        'last_cache_started',
        'last_cache_finished',
        'item_pages_to_cache',
        'item_pages_cached',
        'last_cache_success',
        'dtools_last_updated',
        'checked_out_to',
      ],
    },
  ],

  /**
  * Definition of Columns for this table
  */
  columns: [
    {
      name: 'cache_status',
      sortColumn: 'checked_out_to',
      title: '?',
      description: 'D-Tools Cache Status',
      format: COLUMN_FORMAT.DTOOLS_CACHE_STATUS,
      lockedInTable: true,
      visible: true,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'project_id',
      title: 'Project ID',
      description: 'Numeric ID',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 100,
      formOrder: 100,
    },
    {
      name: 'project_number',
      title: 'P-Number',
      description: 'Project Unique Identifier',
      format: COLUMN_FORMAT.OBJECT,
      formSaveField: 'project.project_number', // filthy filter hack
      sortColumn: 'project_id',
      object: {
        key: 'project_number',
        sourceField: 'project',
        format: COLUMN_FORMAT.P_NUMBER,
        linkId: 'id',
        linkRoute: '/crm/projects',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      order: 110,
      formOrder: 110,
    },
    {
      name: 'number',
      title: 'Dtools Number',
      description: 'P-Number from D-Tools',
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      format: COLUMN_FORMAT.NAME_LINK,
      linkId: 'number',
      linkRoute: '/crm/ordering/project-eta-items/project',
      visible: true,
      order: 120,
      formOrder: 120,
    },
    {
      name: 'name',
      title: 'Name',
      description: 'Project Working Title',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      required: true,
      order: 130,
      formOrder: 130,
    },
    {
      name: 'state',
      title: 'State',
      format: COLUMN_FORMAT.OBJECT,
      description: 'Where is the project taking place?',
      object: {
        sourceField: 'state',
        key: 'acronym',
        format: COLUMN_FORMAT.SHORT_TEXT,
        listSource: 'state',
      },
      visible: true,
      order: 140,
      formOrder: 140,
    },
    {
      name: 'client',
      title: 'Client',
      description: 'The end user client for the project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'client',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/crm/companies',
        listSource: 'company',
      },
      formFieldType: FORM_FIELD_TYPE.COMPANY_PICKER,
      formSaveField: 'client_id',
      visible: false,
      showInForm: true,
      isCreatable: true,
      order: 150,
      formOrder: 150,
    },
    {
      name: 'project_manager',
      title: 'Project Manager',
      description: 'The assigned project manager resource',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'project_manager',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'project_manager_id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      formSaveField: 'projectManager.project_manager_id',
      sortColumn: 'projectManager.name',
      visible: true,
      showInForm: true,
      order: 160,
      formOrder: 160,
    },
    {
      name: 'progress',
      title: 'D-Tools Phase',
      description: 'DTools Phase',
      format: COLUMN_FORMAT.TEXT,
      visible: true,
      order: 170,
      formOrder: 170,
    },
    {
      name: 'status',
      title: 'Status',
      description: 'Current Status of Project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'status',
        key: 'name',
        format: COLUMN_FORMAT.STATUS_BADGE,
        listSource: 'projectStatus',
      },
      visible: true,
      order: 180,
      formOrder: 180,
    },
    {
      name: 'approved',
      title: 'Approved',
      description: 'DTools Approved',
      format: COLUMN_FORMAT.ID_NAME_OPTIONS,
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: yesNoOptions,
      visible: false,
      order: 190,
      formOrder: 190,
    },
    {
      name: 'cost',
      title: 'Cost',
      formType: 'DollarAmount',
      allowNegative: true,
      format: COLUMN_FORMAT.CURRENCY,
      description: 'Project Total Cost',
      visible: false,
      order: 200,
      formOrder: 200,
    },
    {
      name: 'price',
      title: 'Price',
      formType: 'DollarAmount',
      allowNegative: true,
      format: COLUMN_FORMAT.CURRENCY,
      description: 'Project Total Price',
      visible: false,
      order: 210,
      formOrder: 210,
    },
    {
      name: 'tax',
      title: 'Tax',
      formType: 'DollarAmount',
      allowNegative: true,
      format: COLUMN_FORMAT.CURRENCY,
      description: 'Project Total Tax',
      visible: false,
      order: 220,
      formOrder: 220,
    },
    {
      name: 'margin',
      title: 'Margin',
      allowNegative: true,
      format: COLUMN_FORMAT.NUMBER,
      description: 'Project Margin',
      visible: false,
      order: 230,
      formOrder: 230,
    },
    {
      name: 'markup',
      title: 'Markup',
      allowNegative: true,
      format: COLUMN_FORMAT.NUMBER,
      description: 'Project Markup',
      visible: false,
      order: 240,
      formOrder: 240,
    },
    {
      name: 'item_count',
      title: 'Items',
      description: 'DTools Items',
      format: COLUMN_FORMAT.NUMBER,
      visible: true,
      order: 250,
      formOrder: 250,
    },
    {
      name: 'past_expected_items',
      title: 'Overdue',
      description: 'The number of items which have passed their expected delivery date',
      format: COLUMN_FORMAT.NUMBER,
      visible: true,
      order: 260,
      formOrder: 260,
    },
    {
      name: 'past_required_items',
      title: 'Past Required',
      description: 'The number of items which have passed their required by date',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 270,
      formOrder: 270,
    },
    {
      title: 'Last Refreshed',
      name: 'updated_at',
      sortColumn: 'updated_at',
      formSaveField: 'updated_at', // hack the filter? if so this is filthy
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'updated_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: false,
      order: 280,
      formOrder: 280,
    },
    {
      title: 'Last Cache Started',
      name: 'last_cache_started',
      format: COLUMN_FORMAT.TIMESTAMP,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: false,
      order: 290,
      formOrder: 290,
    },
    {
      title: 'Last Cache Completed',
      name: 'last_cache_finished',
      format: COLUMN_FORMAT.TIMESTAMP,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: false,
      order: 300,
      formOrder: 300,
    },
    {
      title: 'Pages to Cache',
      name: 'item_pages_to_cache',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: false,
      order: 310,
      formOrder: 310,
    },
    {
      title: 'Pages Cached',
      name: 'item_pages_cached',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: false,
      order: 320,
      formOrder: 320,
    },
    {
      title: 'Cache Success',
      name: 'last_cache_success',
      format: COLUMN_FORMAT.CHECKBOX,
      visible: false,
      order: 330,
      formOrder: 330,
    },
    {
      title: 'D-Tools Last Modified',
      name: 'dtools_last_updated',
      format: COLUMN_FORMAT.TIMESTAMP,
      visible: false,
      order: 340,
      formOrder: 340,
    },
    {
      name: 'checked_out_to',
      title: 'Checked Out To',
      description: 'User the project is checked out to, if any.',
      format: COLUMN_FORMAT.TEXT,
      visible: false,
      order: 350,
      formOrder: 350,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'ETA Summary Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'eta-summary',
      title: 'ETA Summary',
      description: 'ETA Summary',
      apiRoute: '/item-eta-report',
      widgetDefinition: WIDGET_DEFINITION.PROJECT_ITEM_ETA_SUMMARY,
    },
    {
      name: 'dtools-live-info',
      title: 'Dtools Live Info',
      description: 'Dtools Live Info',
      widgetDefinition: WIDGET_DEFINITION.DTOOLS_LIVE_INFO,
    },
  ],

  /**
   * View Definitions
   */
  viewKey: 'active',
  views: [
    {
      key: 'active',
      title: 'All Current Portal Projects',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.IN,
          values: ['active', 'proposal'],
        },
      ],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Current Portal Projects',
        },
      ],
      flags: [],
    },
    {
      key: 'all',
      title: 'All D-Tools Projects',
      filters: [],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My D-Tools Projects',
        },
      ],
      flags: [],
    },
    {
      key: 'VIC',
      title: 'Victorian Projects',
      filters: [
        {
          field: 'state.acronym',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['VIC'],
        },
      ],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Victorian Projects',
        },
      ],
      flags: [],
    },
    {
      key: 'NSW',
      title: 'New South Wales Projects',
      filters: [
        {
          field: 'state.acronym',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['NSW'],
        },
      ],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My New South Wales Projects',
        },
      ],
      flags: [],
    },
    {
      key: 'QLD',
      title: 'Queensaland Projects',
      filters: [
        {
          field: 'state.acronym',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['QLD'],
        },
      ],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Queensaland Projects',
        },
      ],
      flags: [],
    },
    {
      key: 'SA',
      title: 'South Australian Projects',
      filters: [
        {
          field: 'state.acronym',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['SA'],
        },
      ],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My South Australian Projects',
        },
      ],
      flags: [],
    },
    {
      key: 'WA',
      title: 'West Australian Projects',
      filters: [
        {
          field: 'state.acronym',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['WA'],
        },
      ],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My West Australian Projects',
        },
      ],
      flags: [],
    },
    {
      key: 'TAS',
      title: 'Tasmanian Projects',
      filters: [
        {
          field: 'state.acronym',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['TAS'],
        },
      ],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Tasmanian Projects',
        },
      ],
      flags: [],
    },
    {
      key: 'ACT',
      title: 'Canberra Projects',
      filters: [
        {
          field: 'state.acronym',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['ACT'],
        },
      ],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Canberra Projects',
        },
      ],
      flags: [],
    },
  ],
};

export default etaSummaryTableInitialSettings;
