import { IModalWizardState } from '../../../types/modal/modal-wizard-state.interface';
import { IModalWizardPageData } from '../../../types/modal/modal-wizard-page-data.interface';
import { SERObject } from '../../../helpers/ser-object.helper';
import { IProjectOrderRecord } from '../../../types/project/project-order.record.interface';
import { IProjectRecord } from '../../../types/project/project.record.interface';
import { IProjectFileRecord } from '../../../types/project/project-file.record.interface';
import { ACTIVATE_PROJECT_MODAL_WIZARD_PAGE } from './activate-project-modal-wizard-page.const';

// Page 1
export interface IActivateProjectModalWizardPage1Data extends IModalWizardPageData {
  email_only_activation: 0 | 1,
  order_no: null | string,
  order_total: number,
  received_date: null | string,
  activating_po_document_file: null | File,
  as_sold_proposal_document_file: null | File,
  comment: null | string,
}

// Page 2

export interface IActivateProjectModalWizardPage2Data extends IModalWizardPageData {
  eligible_for_commission: 0 | 1,
  dtools_project_id: string | null,
  as_sold_cost: number | null,
  as_sold_price: number | null,
}

// Page 3
export interface IActivateProjectModalWizardPage3Data extends IModalWizardPageData {
  billing_address: null | string,
  billing_agent_id: null | number,
  billing_agent: null | {
    id: number,
    name: string,
  },
  invoicing_contact_id: null | number,
  invoicing_contact: null | {
    id: number,
    first: string,
    middle: null | string,
    last: string,
  },
  notify_invoicing_contact: 0 | 1,
  project_maintenance_type_id: null | number,
  project_maintenance_type: null | {
    id: number,
    name: string,
  },
}

// Page 3
export interface IActivateProjectModalWizardPage4Data extends IModalWizardPageData {
  po_saved_in_project_drive: 0 | 1,
  as_sold_proposal_saved: 0 | 1,
  po_value_matches_as_sold: 0 | 1,
  finance_reports_created: 0 | 1,
}

// Page 4
export interface IActivateProjectModalWizardPage5Data extends IModalWizardPageData {
  project_activation_team_note: string | number | readonly string[] | undefined,
}

// Page 5
export type IActivateProjectModalWizardPage6Data = IModalWizardPageData;

// Wizard State interface
export interface IActivateProjectModalWizardState extends IModalWizardState {
  pageData: {
    [ACTIVATE_PROJECT_MODAL_WIZARD_PAGE.PAGE_1_PURCHASE_ORDER_INFORMATION]: IActivateProjectModalWizardPage1Data,
    [ACTIVATE_PROJECT_MODAL_WIZARD_PAGE.PAGE_2_DTOOLS_AS_SOLD_PROJECT]: IActivateProjectModalWizardPage2Data,
    [ACTIVATE_PROJECT_MODAL_WIZARD_PAGE.PAGE_3_REVIEW_INVOICE_INFORMATION]: IActivateProjectModalWizardPage3Data,
    [ACTIVATE_PROJECT_MODAL_WIZARD_PAGE.PAGE_4_CHECKLIST_ITEMS]: IActivateProjectModalWizardPage4Data,
    [ACTIVATE_PROJECT_MODAL_WIZARD_PAGE.PAGE_5_SEND_NOTIFICATIONS]: IActivateProjectModalWizardPage5Data,
    [ACTIVATE_PROJECT_MODAL_WIZARD_PAGE.PAGE_6_PROCESSING]: IActivateProjectModalWizardPage6Data,
  },

  initialising: boolean,
  isDirty: boolean,
  processing: boolean,
  success: boolean,

  // The details about the project record
  projectRecord: null | IProjectRecord,
  activateProjectResult: null | SERObject<IProjectRecord>,

  // The details about the activating project order
  activatingProjectOrderRecord: null | IProjectOrderRecord,

  // The details about the activating PO Document
  uploadActivatingPODocumentResult: null | SERObject<IProjectFileRecord>,
  activatingPoDocument: null | IProjectFileRecord,
  activatingPoDocument_uploadStatus: number, // 0 -> 100

  // The details about the As Sold Proposal Document
  uploadAsSoldProposalDocumentResult: null | SERObject<IProjectFileRecord>,
  asSoldProposalDocument: null | IProjectFileRecord,
  asSoldProposalDocument_uploadStatus: number, // (optional) 0 -> 100
}

// Wizard initial state
export const activateProjectModalWizardInitialState: IActivateProjectModalWizardState = {
  currentPageIndex: 0,
  pageData: [

    // Page 1 initial state
    {
      email_only_activation: 0,
      order_no: null,
      order_total: 0,
      received_date: null,
      activating_po_document_file: null,
      as_sold_proposal_document_file: null,
      comment: null,
    },

    // Page 2 initial state
    {
      eligible_for_commission: 1,
      dtools_project_id: null,
      as_sold_cost: null,
      as_sold_price: null,
    },


    // Page 3 initial state
    {
      billing_address: null,
      billing_agent_id: null,
      billing_agent: null,
      invoicing_contact_id: null,
      invoicing_contact: null,
      notify_invoicing_contact: 0,
      project_maintenance_type_id: null,
      project_maintenance_type: null,
    },

    // Page 3 initial state
    {
      po_saved_in_project_drive: 0,
      as_sold_proposal_saved: 0,
      po_value_matches_as_sold: 0,
      finance_reports_created: 0,
    },

    // Page 4 initial state
    {
      project_activation_team_note: '',
    },

    // Page 5 initial state
    {
    },
  ],
  pageDataErrors: [],

  initialising: true,
  isDirty: false,
  processing: false,
  success: false,

  projectRecord: null,
  activateProjectResult: null,

  activatingProjectOrderRecord: null,

  uploadActivatingPODocumentResult: null,
  activatingPoDocument: null,
  activatingPoDocument_uploadStatus: 0,

  uploadAsSoldProposalDocumentResult: null,
  asSoldProposalDocument: null,
  asSoldProposalDocument_uploadStatus: 0,
};
