import { DELETE_CONFIRMATION_TYPE } from '../constants/delete-confirmation-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';

export const CommissionBatchRateAuditWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Project',
  isReadOnly: true,
  formDeleteConfirmationType: DELETE_CONFIRMATION_TYPE.REMOVE,
  collectionItemPrimaryKeyFieldName: 'project_id',
  fields: [
    {
      name: 'project_number',
      title: 'Project',
      format: COLUMN_FORMAT.P_NUMBER,
    }, {
      name: 'status',
      title: 'Status',
      format: COLUMN_FORMAT.STATUS_BADGE,
      visible: false,
    }, {
      name: 'active_at',
      title: 'Activated',
      format: COLUMN_FORMAT.DATE,
    }, {
      name: 'project_name',
      title: 'Name',
      format: COLUMN_FORMAT.LONG_TEXT,
    }, {
      name: 'sales_owner',
      title: 'Salesperson',
      format: COLUMN_FORMAT.TEXT,
    }, {
      name: 'invoice_amount',
      title: 'Total Invoiced',
      format: COLUMN_FORMAT.CURRENCY,
    }, {
      name: 'inclusive_invoice_amount_in_commission_window',
      title: 'Inclusive Inv',
      format: COLUMN_FORMAT.CURRENCY,
    }, {
      name: 'exclusive_invoice_amount_in_commission_window',
      title: 'Exclusive Inv',
      format: COLUMN_FORMAT.CURRENCY,
    }, {
      name: 'last_invoice_date',
      title: 'Latest Invoice',
      format: COLUMN_FORMAT.DATE,
    }, {
      name: 'as_sold_cost',
      title: 'As sold cost',
      format: COLUMN_FORMAT.CURRENCY,
    }, {
      name: 'as_sold_price',
      title: 'As sold price',
      format: COLUMN_FORMAT.CURRENCY,
    },
  ],
};
