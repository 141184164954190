import React from 'react';
import Highlighter from 'react-highlight-words';

import AsyncSelect from './async-select';
import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';

import { IServiceJobRecord } from '../../types/service/service-job.record.interface';

export type ServiceJobPickerProps = AsyncSelectComponentProps;

/**
 * Called by the select to render one of the options in the dropdown
 *
 * @see https://react-select.com/props
 *
 * @param option the option data to render
 * @param details information about where and what is being rendered
 * @param inputValue the search term the user has entered into the input
 */
const renderOption = (option: IServiceJobRecord, labelMeta: Record<string, unknown>, inputValue: string | number | null): string | React.ReactNode => {
  const searchWords = inputValue ? inputValue.toString().trim().replace(/[|&;$%@"<>()+,]/g, '').split(' ') : [];
  return (
    <div className="mini-service-job-summary">
      <span className="service-job-number">
        <Highlighter
          highlightTag="span"
          highlightClassName="highlight-text"
          searchWords={option.number ? searchWords : []}
          textToHighlight={option && option.number ? option.number : ''}
        />
      </span>
      <span className="client-name ml-2">
        <Highlighter
          highlightTag="span"
          highlightClassName="highlight-text"
          searchWords={(option.client && option.client.name) ? searchWords : []}
          textToHighlight={option && option.client && option.client.name ? option.client.name : ''}
        />
      </span>
      <span className="subtype-name ml-2">
        <Highlighter
          highlightTag="span"
          highlightClassName="highlight-text"
          searchWords={(option.name) ? searchWords : []}
          textToHighlight={option && option.name ? ` - ${option.name}` : ''}
        />
      </span>
    </div>
  );
};

/**
 * ServiceJob Picker
 */
export const ServiceJobPicker: React.FC<ServiceJobPickerProps> = (props) => (
  <AsyncSelect
    {...props}
    valueKey="id"
    labelKey="number"
    searchRoute="/service-job"
    appendQuery="&with[]=type:id,name&with[]=status:id,name"
    renderOption={renderOption}
    loadAndKeepAll
    useSearch
  />
);
