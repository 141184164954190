import React from 'react';
import PropTypes from 'prop-types';
import PortalDataTable from './portal-data-table';
import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';

/**
 * @class DrillDownTable
 *
 * @description
 * Used primarily inline with charts to display drilldown data when clicking on a data point,
 * this wrapper ensures the table follows instructions from the parent rather than owning its own behavior
 */
class DrillDownTable extends React.Component {
  constructor(props) {
    super(props);
    this.eslintBlocker = null;
  }

  /**
   * @inheritdoc
   */
  render() {
    const {
      tableIdentifier, reportFilters, urlIdentifier, onCloseTable,
    } = this.props;

    return (
      <PortalDataTable
        tableIdentifier={tableIdentifier}
        reportFilters={reportFilters}
        urlIdentifier={urlIdentifier}
        onCloseTable={onCloseTable}
        {...this.props}
      />
    );
  }
}

DrillDownTable.defaultProps = {
  title: 'Project Data Drill-Down',
  urlIdentifier: undefined,
};

DrillDownTable.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  urlIdentifier: PropTypes.string,
  reportFilters: PropTypes.shape({}).isRequired,
  tableIdentifier: PropTypes.oneOf(Object.values(TABLE_IDENTIFIER)).isRequired,
  onCloseTable: PropTypes.func.isRequired,
};

export default DrillDownTable;
