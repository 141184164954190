// Constants for displaying and converting dates
// See https://devhints.io/datetime#momentjs-format for reference
export const DATE_FORMAT = {
  YEAR_MONTH_DAY_DASHES: 'YYYY-MM-DD',
  YEAR_MONTH_DASHES: 'YYYY-MM',
  DAY_MONTH_YEAR_SLASHES: 'DD/MM/YYYY',
  AUSTRALIAN_READABLE: 'D/M/Y',
  NEWS: 'D MMM, YYYY',
  LONG_DAY_NAME: 'dddd',
  SHORT_DAY_NAME: 'ddd',
  LONG_MONTH_NAME: 'MMMM',
  LONG_MONTH_NAME_AND_YEAR: 'MMMM YY',
} as const;
export type DATE_FORMAT = typeof DATE_FORMAT;
export type A_DATE_FORMAT = DATE_FORMAT[keyof DATE_FORMAT];

// String input formats that are acceptable for users to input into date fields (i.e. which can be parsed systematically)
export const VALID_DATE_INPUT_FORMATS = [
  'D-M-YYYY',
  'D/M/YYYY',
  'YYYY-M-D',
  'D/M/YY',
  'D-M-YY',
  'YYYY/M/D',
];

