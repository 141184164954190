import React from 'react';
import PortalFusionChart from './portal-fusion-chart';
import FUSION_CHART_TYPES, { FUSION_CHART_TYPES_SUPPORTED } from '../../utils/fusion-chart-types';
import { PROJECT_STATUS } from '../../constants/project-status.const';


/**
 * @description
 * Create and return a new seriesList
 *
 * @param {boolean} showCount
 * @returns {object}
 */
const createSeriesList = (showCount) => ([
  {
    name: 'Leads',
    filter: (dataItem) => dataItem.status_id === PROJECT_STATUS.LEAD,
    getValue: (dataItem) => (showCount ? dataItem.count : dataItem.value),
    renderAs: 'COLUMN',
    visible: true,
  },
  {
    name: 'Proposals',
    filter: (dataItem) => dataItem.status_id === PROJECT_STATUS.PROPOSAL,
    getValue: (dataItem) => (showCount ? dataItem.count : dataItem.value),
    renderAs: 'AREA',
    visible: true,
  },
  {
    name: 'Active Projects',
    filter: (dataItem) => dataItem.status_id === PROJECT_STATUS.ACTIVE,
    getValue: (dataItem) => (showCount ? dataItem.count : dataItem.value),
    renderAs: 'LINE',
    visible: true,
  },
  {
    name: 'Completed Projects',
    filter: (dataItem) => dataItem.status_id === PROJECT_STATUS.COMPLETED,
    getValue: (dataItem) => (showCount ? dataItem.count : dataItem.value),
    renderAs: 'SPLINEAREA',
    visible: true,
  },
]);


/**
 * @class MultiSeriesTestChart
 *
 * @description
 * This chart is a single example of how to implement a multi-series PortalFusionChart
 */
class MultiSeriesTestChart extends React.Component {
  /**
   * @constructor
   *
   * @param {{}} props
   */
  constructor(props) {
    super(props);

    const chartCaption = 'Multi Series Test Chart';
    const chartSubCaption = 'This is a test chart, baby!';
    const chartXAxisName = 'This way';
    const chartYAxisName = 'That way';

    this.state = {
      showCount: false,
      seriesList: createSeriesList(false),
      chartType: FUSION_CHART_TYPES.MULTI_SERIES_2D_SINGLE_Y_COMBINATION_CHART_COLUMN_LINE_AREA,
      chartCaption,
      chartSubCaption,
      chartXAxisName,
      chartYAxisName,
    };
  }


  /**
   * @description
   * Fired by our component to toggle the visibility of a series
   *
   * @param {number} seriesIndex
   * @param {boolean} visible
   */
  setSeriesVisible = (seriesIndex, visible) => {
    const { seriesList } = this.state;

    this.setState({
      seriesList: seriesList.map((singleSeries, index) => (index === seriesIndex
        ? { ...singleSeries, visible }
        : singleSeries)),
    });
  }


  /**
   * @description
   * Fired when the user clicks the show count / value button
   */
  setShowCount = (newShowCount) => {
    const { seriesList } = this.state;

    this.setState({
      showCount: newShowCount,
      seriesList: seriesList.map((singleSeries) => ({
        ...singleSeries,
        getValue: (dataItem) => (newShowCount ? dataItem.count : dataItem.value),
      })),
    });
  }


  /**
   * @description
   * Fired when a series on the the fusion chart is made visible or invisible by the user
   *
   * @param {string} id chartId
   * @param {number} seriesIndex
   * @param {boolean} visible
   */
  handleSeriesVisibilityChanged = (id, seriesIndex, visible) => this.setSeriesVisible(seriesIndex, visible);


  /**
   * @description
   * Fired when the user changes the title of the chart
   *
   * @param {React.SyntheticEvent<HTMLInputElement>} e
   */
  handleChartCaptionChange = (e) => {
    this.setState({ chartCaption: e.currentTarget.value });
  }


  /**
   * @description
   * Fired when a user clicks on a data item on one of the charts
   *
   * @param {object} dataItem the data item that was clicked
   * @param {object} dataPlot the fusion charts data plot details of the item that was clicked
   * @param {string | number} chartId the id of the chart that was clicked
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleChartDataItemClick = (dataItem, dataPlot, chartId) => {
    // console.log('handleChartDataItemClick', { dataItem, dataPlot, chartId });
  }


  /**
   * @description
   * Fired when a user clicks on a series label on one of the charts
   *
   * @param {object} series the details of the series label that was clicked
   * @param {string | number} chartId the id of the chart that was clicked
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleChartSeriesLabelClick = (series, chartId) => {
    // console.log('handleChartSeriesLabelClick', { series, chartId });
  }


  /**
   * @description
   * Fired by the Fusion chart to get the labels that should be displayed along the
   * bottom row / x-axis / column etc...
   *
   * @param {object} dataItem the data item to extract an x-axis / column label
   */
  handleGetLabel = (dataItem) => dataItem.days_name;


  /**
   * @description
   * Fired when the chart type is changed
   *
   * @param {React.SyntheticEvent} e
   */
  handleChartTypeChange = (e) => {
    this.setState({ chartType: e.currentTarget.value });
  }


  /**
   * @inheritdoc
   */
  render() {
    const {
      showCount,
      seriesList,
      chartCaption,
      chartSubCaption,
      chartXAxisName,
      chartYAxisName,
      chartType,
    } = this.state;

    return (
      <>
        <PortalFusionChart
          id="test_chart"
          data={{
            url: '/report/project/bystatusage',
          }}
          dataMapping={{
            type: 'multi',
            seriesList,
            chartType,
            getLabel: this.handleGetLabel,
          }}
          numberPrefix={showCount ? '' : '$'}
          caption={chartCaption}
          subCaption={chartSubCaption}
          xAxisName={chartXAxisName}
          yAxisName={chartYAxisName}
          getLabel={(dataItem) => dataItem.days_name}
          onDataItemClick={this.handleChartDataItemClick}
          onSeriesLabelClick={this.handleChartSeriesLabelClick}
          onSeriesVisibilityChange={this.handleSeriesVisibilityChanged}
        />
        <div>
          {
            seriesList.map((singleSeries, index) => (
              <button
                type="button"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={() => this.setSeriesVisible(index, !singleSeries.visible)}
              >
                <span>{singleSeries.visible ? 'Hide' : 'Show'}</span>
                <span>{`Series ${singleSeries.name}`}</span>
              </button>
            ))
          }
        </div>
        <div>
          <select onChange={this.handleChartTypeChange} value={chartType}>
            {FUSION_CHART_TYPES_SUPPORTED.map((fusionChartType) => (
              <option
                value={fusionChartType}
                key={fusionChartType}
              >
                {fusionChartType}
              </option>
            ))}
          </select>
        </div>
        <div>
          <span>Chart Title: </span>
          <input value={chartCaption} onChange={this.handleChartCaptionChange} />
        </div>
        <div>
          <button type="button" onClick={() => this.setShowCount(!showCount)}>{showCount ? 'Show Value' : 'Show Count'}</button>
        </div>


      </>
    );
  }
}

export default MultiSeriesTestChart;
