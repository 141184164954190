import { ILocation } from '../types/location.interface';

export const REDIRECT_INSTRUCTION_TYPE = {
  NONE: 'none',
  REFRESH: 'refresh',
  REDIRECT: 'redirect',
} as const;
export type REDIRECT_INSTRUCTION_TYPE = typeof REDIRECT_INSTRUCTION_TYPE;
export type A_REDIRECT_INSTRUCTION_TYPE = REDIRECT_INSTRUCTION_TYPE[keyof REDIRECT_INSTRUCTION_TYPE];

class RedirectInstruction {
  type: A_REDIRECT_INSTRUCTION_TYPE = REDIRECT_INSTRUCTION_TYPE.NONE;

  targetLocation: ILocation | null = null;

  /**
   * @constructor
   */
  constructor(
    type: A_REDIRECT_INSTRUCTION_TYPE = REDIRECT_INSTRUCTION_TYPE.NONE,
    targetLocation: ILocation | Record<string, unknown> | null = null,
  ) {
    this.type = type;
    this.targetLocation = targetLocation as unknown as ILocation;
  }
}

export default RedirectInstruction;
