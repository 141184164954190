import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader } from 'reactstrap';
import { formatValueString } from '../render-functions';
import { COLUMN_FORMAT } from '../../constants/column-format.const';
import { APIContext } from '../providers/api-provider';
import { LoadingSpinner } from '../layout-helpers/loading-spinner';
import { apiAborter } from '../../helpers/api-aborter.helper';

/**
 * ProjectSchedule component for Data Table
 */
export const DToolsProjectSchedule = ({ dtoolsId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { apiFetch } = useContext(APIContext);

  useEffect(() => {
    let abortController = apiAborter();

    apiFetch(
      `/dtools-connector/project/${dtoolsId}/paymentschedule`,
      {
        name: 'DToolsProjectSchedule:load',
        signal: abortController.signal,
      },
    ).then((response) => {
      if (response.success) {
        abortController = null;
        setData(response.body.data || []);
        setLoading(false);
      } else if (!response.aborted) {
        abortController = null;
        // TODO: error state?
        console.error('DTools Project Schedule Error', response.error);
        setData([]);
        setLoading(false);
      }
    });

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <LoadingSpinner />}

      {!loading && (

        <Card>
          <CardHeader className="bg-info text-white" tag="h4">
            Payment Schedule
          </CardHeader>
          <div className="table-container" style={{ height: '490px', overflowY: 'auto' }}>
            <table style={{ width: '100%' }}>
              <thead>
                {/*
                "ProjectId": "218429e2-4cd8-4291-82ee-f34d1b009c3c",
                "Order": 1,
                "PaymentType": "Initial Deposit",
                "UseAmount": false,
                "BillingPercentage": 0.5,
                "Amount": 70968.04,
                "BillingDate": null
              */}
                <tr>
                  {/* <th>ProjectId</th> */}
                  {/* <th>Order</th> */}
                  <th>Type</th>
                  {/* <th>UseAmount</th> */}
                  <th>Ratio</th>
                  <th>Value</th>
                  <th>Due</th>
                </tr>
              </thead>
              <tbody>
                {data.length < 1 && (
                  <tr>
                    <td colSpan="8">No locations listed</td>
                  </tr>
                )}
                {data.sort((a, b) => b.Order - a.Order).map((item) => (
                  <tr key={`location-${item.Id}`}>
                    {/* <td>{item.ProjectId}</td> */}
                    {/* <td className="text-right">{item.Order}</td> */}
                    <td>{item.PaymentType}</td>
                    {/* <td className="text-right">{formatValue(item.UseAmount, COLUMN_FORMAT.CURRENCY)}</td> */}
                    <td className="text-right">
                      {item.BillingPercentage * 100}
                      %
                    </td>
                    <td className="text-right">{formatValueString(item.Amount, COLUMN_FORMAT.CURRENCY)}</td>
                    <td className="text-right">{formatValueString(item.BillingDate, COLUMN_FORMAT.DTOOLS_TIME)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
          </div>
        </Card>
      )}
    </>
  );
};

DToolsProjectSchedule.propTypes = {
  dtoolsId: PropTypes.string.isRequired,
};
