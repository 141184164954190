import React from 'react';
import { formatValueString } from '../../render-functions';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';


type ProjectSummaryProps = {
  summaryData: {
    guestimate_project_total: number,
    financial_summary: {
      client_order_total: number,
      project_partial_total: number,
      variation_total: number,
      retention_total: number,
      guestimate_project_total: number,
    },
  },
};

export const ProjectSummary: React.FC<ProjectSummaryProps> = ({ summaryData }) => (
  <table
    className="table table-striped color-table primary-table mt-3"
    style={{ border: '1px solid silver' }}
  >
    <thead>
      <tr>
        <th>Project Summary</th>
        <th className="text-right">Value</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Sum of Client Orders</td>
        <td className="text-right">
          {formatValueString(summaryData.financial_summary.client_order_total, COLUMN_FORMAT.CURRENCY)}
        </td>
      </tr>
      <tr>
        <td>Sum of Partial Forecasts</td>
        <td className="text-right">
          {formatValueString(summaryData.financial_summary.project_partial_total, COLUMN_FORMAT.CURRENCY)}
        </td>
      </tr>
      <tr>
        <td>Sum of Variation Forecasts</td>
        <td className="text-right">
          {formatValueString(summaryData.financial_summary.variation_total, COLUMN_FORMAT.CURRENCY)}
        </td>
      </tr>
      <tr>
        <td>Sum of Retention</td>
        <td className="text-right">
          {formatValueString(summaryData.financial_summary.retention_total, COLUMN_FORMAT.CURRENCY)}
        </td>
      </tr>
      <tr>
        <td>Project Total</td>
        <td className="text-right">
          {formatValueString(summaryData.financial_summary.guestimate_project_total, COLUMN_FORMAT.CURRENCY)}
        </td>
      </tr>
    </tbody>
  </table>
);
