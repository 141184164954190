import React from 'react';
import { ICON } from '../../constants/icon.const';
import Icon from '../layout-helpers/icon';

interface IDtoolsCacheStatusProps {
  id: string,
  row: {
    cache_hash: string,
    item_pages_cached?: null|number,
    item_pages_to_cache?: null|number,
    last_cache_success: null|number,
    checked_out_to: null|string,
  },
}

/**
 * Dtools Cache Status Element
 *
 * Use reference fields to render an icon representing the status of the D-Tools cache.
 *
 * @param {IDtoolsCacheStatusProps} props
 * @param {null|number}           props.value     percentage value or null
 * @param {string}                props.name      string name of the field in the form
 *
 * @returns {Element} <DtoolsCacheStatus>
 */
export const DtoolsCacheStatus: React.FC<IDtoolsCacheStatusProps> = ({ id, row }) => {
  let icon: string = ICON.DTOOLS_CACHE_SUCCESS;
  let color = 'success';
  let title = 'Cache is up to date';
  let append = '';
  const isCheckedOut = row.checked_out_to !== null;
  const isInProgress = row.last_cache_success === null || (row.item_pages_cached || 0) < (row.item_pages_to_cache || 0);
  // still caching
  if (isInProgress) {
    const cachePercent = row.item_pages_to_cache ?
      ` - ${Math.floor(((row.item_pages_cached || 0) / (row.item_pages_to_cache || 0)) * 100)}%`
      : '';
    icon = ICON.DTOOLS_CACHE_IN_PROGRESS;
    color = 'dark';
    title = `Caching is in progress${cachePercent}`;
    append = 'fa-spin';
  }
  // checked out
  if (isCheckedOut) {
    icon = isInProgress ? ICON.DTOOLS_CACHE_IN_PROGRESS : ICON.DTOOLS_PROJECT_CHECKED_OUT;
    color = 'primary';
    title = `Checked out to ${row.checked_out_to}. ${isInProgress ? title : ''}`;
  }
  // failed
  if (row.last_cache_success === 0) {
    icon = isCheckedOut ? ICON.DTOOLS_PROJECT_CHECKED_OUT : ICON.DTOOLS_CACHE_FAILED;
    color = 'danger';
    title = `Cache refresh failed! ${isCheckedOut ? title : ''}`;
  }
  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div id={id} className="dtools-cache-status">
      <Icon i={icon} className={`text-${color} ${append}`} title={title} />
    </div>
  );
};
