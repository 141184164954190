import React, { PropsWithChildren, useCallback, useState, useContext, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { Button } from 'reactstrap';

import { APIContext } from '../providers/api-provider';
import { NotificationContext } from '../providers/notification-provider';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { IAPIAction } from '../../types/api-action.interface';
import { IFileRecord } from '../../types/file.record.interface';
import { IFileRequestTransportResponse } from '../../types/file-request-transport-response.interface';

import Icon from '../layout-helpers/icon';
import { FileSelectInput } from './file-select-input';

import { apiAborter } from '../../helpers/api-aborter.helper';
import { downloadFile } from '../../helpers/download-file.helper';
import { requestFileUpload, uploadFile, confirmFileUpload, cancelFileUpload } from '../../helpers/file-upload.helper';
import { getAvailableActions } from '../../helpers/get-available-actions.helper';
import { SERObject, SERError } from '../../helpers/ser-object.helper';

import { API_ACTION } from '../../constants/api-action.const';
import { ICON } from '../../constants/icon.const';
import { NOTIFICATION_TYPE } from '../../constants/notification-type.const';


// eslint-disable-next-line @typescript-eslint/no-explicit-any
type fileInputPropValueCallback<T, R> = (formData: any, fileId?: null | number, fileRecord?: T | null) => R;

export type FileInputProps<T extends IFileRecord> = Pick<FormFieldComponentProps,
  'className' |
  'name' |
  'formData' |
  'isReadOnly' |
  'disabled' |
  'formIsLocked' |
  'formSaveField' |
  'lockForm'
> & {
  apiRoute: string | ((row: Record<string, unknown>) => string),
  value?: null | T,
  newFileData?: Record<string, unknown> | fileInputPropValueCallback<T, Record<string, unknown>>,
  // onChange?: (idFieldName: string, idValue: null | number, fileFieldName: string, file: null | T) => unknown,
  onChange?: (field: {
    fieldName: string,
    newValue: null | number,
    objectFieldName: string,
    objectFieldNewValue: null | T
  }) => void,
};

/**
 * The File Input is a Portal Form Input used in the APIPolyForm and Table cell renderers.
 *
 * @note: Currently does not allow file changes.
 *
 * TODO: Add a "preview" button depending on the mime-type of the file to open up either the PDF viewer or the file preview popup
 * TODO: enable upload functionality via the FileSelectInput
 */
export const FileInput = <T extends IFileRecord, >(props: PropsWithChildren<FileInputProps<T>>): React.ReactElement => {
  const {
    name,
    className,
    formData = {},
    isReadOnly = false,
    formIsLocked = false,
    value = null,
    disabled = false,
    formSaveField,
    apiRoute,
    newFileData,
    lockForm,
    onChange,
  } = props;

  const [fileId, setFileId] = useState(value ? value.id : null);
  const [fileRecord, setFileRecord] = useState<T | null>(value ?? null);
  const [file, setFile] = useState(value ? new File([''], value.filename, { type: value.content_type ?? undefined }) : null);
  const { apiFetch } = useContext(APIContext);
  const { addNotification } = useContext(NotificationContext);
  const isEditing = !isReadOnly;

  // When the user wants to preview or download the file, the requestDownloadState state manages the URL generation
  const [requestDownloadState, setRequestDownloadState] = useState<{
    requesting: boolean,
    error: SERError,
  }>({
    requesting: false,
    error: null,
  });

  // When a new file is being uploaded this stores the upload progress and is passed into the FileSelectInput control for visual representation
  const [uploadProgress, setUploadProgress] = useState<null | number>(null);

  // The request to download the file is pre-ceeded by a request to download said file.
  // This abort controller ensures that the component doesn't attempt to update itself if unloaded mid-request
  const downloadFileTransportAbortController = useRef<null | AbortController>(null);

  // The request to upload a file requires an abort controller for most of the transport functions
  const fileUploadAbortController = useRef<null | AbortController>(null);

  // This flag is set to false when the component is unmounted so that we can avoid invalid react state updates
  const componentMounted = useRef<boolean>(true);


  /**
   * Returns true if the upload was aborted for whatever reason (component un-mounting most likely)
   */
  const uploadAborted = (): boolean => (!componentMounted.current || (!!fileUploadAbortController.current && fileUploadAbortController.current.signal.aborted));


  /**
   * Actually parse the string or function passed in in the props and concatenate the file ID if required
   */
  const getAPIRequestDownloadURL = useCallback((): string => {
    let url = typeof apiRoute === 'function' ? apiRoute(formData) : apiRoute;

    if (fileId) {
      url = `${url}/${fileId}`;
    }

    return url;
  }, [apiRoute, formData, fileId]);


  /**
   * Get the details about how to download the file.
   */
  const getDownloadFileTransport = useCallback(async (): Promise<SERObject<IFileRequestTransportResponse<T>>> => {
    const result = new SERObject<IFileRequestTransportResponse<T>>(false);

    // Can't download a file that isn't defined in the value
    if (!fileId) {
      result.error = 'File Input has no file ID';
      return result;
    }

    // Get the URL for requesting transport
    const url = typeof apiRoute === 'function' ? apiRoute(formData) : apiRoute;
    if (!url) {
      result.error = `Malformed Download URL: ${url}`;
      return result;
    }

    // Cancel any existing requests and create a new Abort signal
    if (downloadFileTransportAbortController.current) {
      downloadFileTransportAbortController.current.abort();
    }
    downloadFileTransportAbortController.current = apiAborter();

    // First get the available actions for the file so we can get the Download action
    const getAvailableActionsResult = await getAvailableActions(getAPIRequestDownloadURL(), apiFetch);

    if (getAvailableActionsResult.success && getAvailableActionsResult.result) {
      // Look for the Download action
      const downloadAction: undefined | IAPIAction = getAvailableActionsResult.result[API_ACTION.DOWNLOAD];
      if (downloadAction) {
        // Request the download using the action
        const response = await apiFetch(
          downloadAction.link,
          {
            method: downloadAction.method,
            signal: downloadFileTransportAbortController.current.signal,
          },
        );

        if (response.success) {
          downloadFileTransportAbortController.current = null;

          result.success = true;
          result.result = response.body;
        } else if (!response.aborted) {
          downloadFileTransportAbortController.current = null;
        }
      } else {
        result.error = 'There is no download capability provided on the target file!';
      }
    } else {
      result.error = getAvailableActionsResult.error || 'An unexpected error occurred.';
    }

    return result;
  }, [apiFetch, apiRoute, fileId, formData, getAPIRequestDownloadURL]);


  /**
   * Actually perform the sequence of actions required to upload a file.
   */
  const performUpload = useCallback(async (newFile: File): Promise<SERObject<T>> => {
    const performUploadResult = new SERObject<T>(false);

    let cancelFileUploadAction: null | IAPIAction = null;
    let confirmFileUploadAction: null | IAPIAction = null;
    setUploadProgress(0);

    try {
      const requestFileUploadResult = await requestFileUpload<T>(
        (typeof apiRoute === 'function' ? apiRoute(formData) : apiRoute),
        apiFetch,
        fileUploadAbortController.current,
        (newAbortController: null | AbortController) => { fileUploadAbortController.current = newAbortController; },
        newFile,
        (typeof newFileData === 'function' ? newFileData(formData, fileId, fileRecord) : newFileData),
      );

      // If the request was successful
      if (!uploadAborted() && requestFileUploadResult.success && requestFileUploadResult.result) {
        cancelFileUploadAction = requestFileUploadResult.result.actions[API_ACTION.CANCEL];
        confirmFileUploadAction = requestFileUploadResult.result.actions[API_ACTION.CONFIRM];

        // Perform the Upload
        const uploadFileResult = await uploadFile(
          newFile,
          requestFileUploadResult.result?.transport,
          (progress) => {
            if (componentMounted.current) {
              setUploadProgress(progress);
            }
          },
        );

        // If the upload was successful
        if (!uploadAborted() && uploadFileResult.success) {
          // Confirm the Upload
          const confirmFileUploadResult = await confirmFileUpload<T>(
            apiFetch,
            confirmFileUploadAction,
          );

          if (confirmFileUploadResult.success && confirmFileUploadResult.result) {
            // Pass the successful result back to the caller
            performUploadResult.result = confirmFileUploadResult.result.data;
            performUploadResult.success = true;

            // Clear the cancel file upload action so that we don't attempt to fire it later
            cancelFileUploadAction = null;
          }
        } else if (!uploadAborted()) {
          performUploadResult.error = `File Upload Failed while attempting to send file data: ${uploadFileResult.error}`;
        }
      } else if (!uploadAborted()) {
        performUploadResult.error = `File Upload Failed when requesting file upload: ${requestFileUploadResult.error}`;
      }
    } catch (err) {
      performUploadResult.error = `File Upload Failed due to an unexpected error: ${err}`;
      console.error('FileInput::performUpload error', err);
    } finally {
      // Cancel the Upload if something went wrong of the upload was aborted
      if (cancelFileUploadAction) {
        cancelFileUpload(apiFetch, cancelFileUploadAction);
      }

      if (!uploadAborted()) {
        setUploadProgress(null);
      }
    }

    return performUploadResult;
  }, [apiFetch, apiRoute, fileId, fileRecord, formData, newFileData]);


  /**
   * Fired when the user clicks the View button
   */
  const handleClickDownload = useCallback(async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    setRequestDownloadState({
      requesting: true,
      error: null,
    });

    const getDownloadTransportResult = await getDownloadFileTransport();

    if (getDownloadTransportResult.success && getDownloadTransportResult.result) {
      const { transport } = getDownloadTransportResult.result;

      setRequestDownloadState({
        requesting: false,
        error: null,
      });

      // Trigger the download
      downloadFile(transport.filename, transport.url);
    } else {
      addNotification({
        headline: 'Failed to download file',
        type: NOTIFICATION_TYPE.DANGER,
        message: getDownloadTransportResult.error ?? 'An unexpected error occurred!',
      });
      setRequestDownloadState({
        requesting: false,
        error: getDownloadTransportResult.error ?? 'An unexpected error occurred!',
      });
      console.error('FileInput::handleClickDownload - Error:', getDownloadTransportResult.error ?? 'An unexpected error occurred!');
    }
  }, [getDownloadFileTransport, addNotification]);


  /**
   * Fired in edit mode when the user changes the value of the input
   */
  const handleFileSelectInputChange = useCallback(async (field: {fieldName: string, newValue: null | File}) => {
    // File was "cleared"
    if (!field.newValue) {
      // Update the internal file value
      setFile(null);

      if (onChange) {
        onChange({
          fieldName: formSaveField ?? name,
          newValue: null,
          objectFieldName: name,
          objectFieldNewValue: null,
        });
      } else {
        // Update internally
        setFileId(null);
        setFileRecord(null);
      }
    }

    // File was "set" or "changed"
    else {
      // Lock the parent form
      if (lockForm) lockForm(true);

      try {
        // Update the internal file value
        setFile(field.newValue);

        // Process the sequence of steps to upload the file
        const performUploadResult = await performUpload(field.newValue);
        if (!uploadAborted()) {
          if (performUploadResult.success && performUploadResult.result) {
            const newFileRecord = performUploadResult.result;

            // Fire the onChange handler with the new data
            if (onChange) {
              onChange({
                fieldName: formSaveField ?? name,
                newValue: newFileRecord.id,
                objectFieldName: name,
                objectFieldNewValue: newFileRecord,
              });
            } else {
              // Update the other internal values that won't come through a props update
              setFileId(newFileRecord.id);
              setFileRecord(newFileRecord);
            }
          } else {
            addNotification({
              headline: 'Failed to upload file',
              type: NOTIFICATION_TYPE.DANGER,
              message: performUploadResult.error ?? 'An unexpected error occurred!',
            });
          }
        }
      } finally {
        // Unlock the parent form
        if (lockForm) lockForm(false);
      }
    }
  }, [onChange, formSaveField, name, lockForm, performUpload, addNotification]);


  /**
   * Whenever the value changes - update the virtual file object that is passed down to the file select input
   */
  useEffect(() => {
    setFile(value ? new File([''], value.filename, { type: value.content_type ?? undefined }) : null);
    setFileRecord(value ?? null);
    setFileId(value ? value.id : null);
  }, [value]);


  /**
   * Fired when the component is unloaded
   */
  useEffect(() => () => {
    // Flag that the component has been unmounted.
    // This prevents unnecessary updates on asynchronous functions
    componentMounted.current = false;

    // fire the abort controller if required
    if (fileUploadAbortController.current) {
      fileUploadAbortController.current.abort();
    }
  }, []);


  /**
   * Render
   */
  return (
    <div
      className={classNames(
        'file-input',
        className,
        {
          disabled,
          'read-only': isReadOnly,
          'not-set': !value,
        },
      )}
    >
      {/* When editing use a specialised FileSelectInput */}
      {isEditing && (
        <FileSelectInput
          allowMultipleFiles={false}
          id={name}
          name={name}
          value={file}
          onChange={handleFileSelectInputChange}
          disabled={formIsLocked}
          uploadProgress={uploadProgress}
        />
      )}

      {/* When not editing, display the read only value */}
      {!isEditing && (
        <div className="filename-wrapper">
          {/* No value (file record) */}
          {!fileRecord && (
            <span>not set</span>
          )}

          {/* Value (file record) exists */}
          {fileRecord && (
            <>
              {/* Filename and Icon */}
              <div className="text-wrapper">
                <Icon i={ICON.FILE} />
                <span>{fileRecord.filename}</span>
              </div>

              {/* Buttons */}
              <div className="button-wrapper">
                {/* Download Button */}
                <Button
                  className="download"
                  title="Download"
                  onClick={handleClickDownload}
                  disabled={requestDownloadState.requesting}
                >
                  <Icon i={ICON.DOWNLOAD_FILE} isBusy={requestDownloadState.requesting} />
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
