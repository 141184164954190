export const SORT_CI_DATATABLE_COLUMNS = 'SORT_CI_DATATABLE_COLUMNS';

/**
 * Sets sorting mode for table column
 * - Reducer: `SORT_CI_DATATABLE_COLUMNS`
 *
 * @param {string} tableIdentifier table identifier in redux, eg. 'projectsTable'
 *  See `initialState` in `table-settings-reducer`
 * @param {string} newOrderMap key value object of order number for each visible field
 * @param {(permissions: PERMISSION[] | PERMISSION) => boolean} userHasPermissions a function to use to check whether the user can see the column
 * @param {array} [visibleColumns=[]]
 */
export const changeColumnOrder = (tableIdentifier, userHasPermissions, newOrderMap, visibleColumns = []) => ({
  type: SORT_CI_DATATABLE_COLUMNS,
  tableIdentifier,
  userHasPermissions,
  newOrderMap,
  visibleColumns,
});
