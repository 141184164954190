import { SORT_DIRECTION } from '@corporate-initiatives/ci-portal-js-sdk';
import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { ICON } from '../constants/icon.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';
import { businessHoursToolTipHtml } from '../components/form-input/business-hours-input';
import { mapSelectInputOptions } from '../helpers/map-select.helper';
import { INTERNAL_PROJECT_TYPE_NAMES } from '../constants/internal-project-type.const';
import { INTERNAL_PROJECT_STATUS_NAME_MAP } from '../constants/internal-project-status.const';
import { IMPACT_LEVEL_NAME_MAP } from '../constants/impact-level.const';
import { INTERNAL_PROJECT_VISIBILITY_NAME_MAP } from '../constants/internal-project-visibility.const';
import { DATA_TABLE_FLAG } from '../constants/data-table-flag.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';

/**
 * Table Definition for People (Internal Projects) Table
 */
const internalProjectsTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Internal Project',
  initialFormFocusFieldName: 'first',
  defaultTabKey: 'card',
  baseRoute: '/internal-project',
  availableFlags: [DATA_TABLE_FLAG.ONLY_MINE],
  baseQueryString: [
    'with[]=type',
    'with[]=status',
    'with[]=visibility',
    'with[]=sprint:id,name',
    'with[]=createdBy:id,name',
    'with[]=owner:id,name,initials',
    'with[]=projectLead:id,name',
    'with_count[]=item',
  ].join('&'),

  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'number',
        'priority',
        'status',
        'name',
        'progress',
        'estimated_hours',
        'external_url',
        'internal_impact',
        'customer_impact',
        'owner',
        'project_lead',
        'count_of_item',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'number',
        'name',
        'description',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'number',
        'type',
        'visibility',
        'name',
        'description',
        'progress',
        'estimated_hours',
        'actual_hours',
        'priority',
        'external_url',
        'internal_impact',
        'customer_impact',
        'owner',
        'project_lead',
        'status',
        'count_of_item',
        'sprint',
        'created_by',
      ],
    },
  ],

  /**
   * Definition of columns and APIPolyForm fields for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Identity',
      description: 'Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK_SHORT,
      linkRoute: '/internal-projects/internal-projects',
      visible: false,
      required: false,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'number',
      title: 'Number',
      description: 'Internal Project Number',
      format: COLUMN_FORMAT.ID_LINK_SHORT,
      sortColumn: 'id',
      linkRoute: '/internal-projects/internal-projects',
      visible: true,
      required: false,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      showInForm: true,
      order: 1,
      formOrder: 1,
    },
    {
      name: 'type',
      title: 'Project Type',
      description: 'Unique Identifier',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'type',
        key: 'name',
      },
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: mapSelectInputOptions(INTERNAL_PROJECT_TYPE_NAMES),
      formSaveField: 'type_id',
      visible: false,
      required: true,
      showInForm: true,
      order: 2,
      formOrder: 2,
    },
    {
      name: 'visibility',
      title: 'Visibility',
      description: 'Unique Identifier',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'visibility',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: mapSelectInputOptions(INTERNAL_PROJECT_VISIBILITY_NAME_MAP),
      formSaveField: 'visibility_id',
      visible: false,
      required: true,
      showInForm: true,
      order: 2,
      formOrder: 2,
    },
    {
      name: 'priority',
      title: 'Priority',
      description: 'Project Priority',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      visible: true,
      required: false,
      order: 10,
      formOrder: 40,
    },
    {
      name: 'status',
      title: 'Status',
      description: 'Current Status of Project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'status',
        key: 'name',
        format: COLUMN_FORMAT.STATUS_BADGE,
      },
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: mapSelectInputOptions(INTERNAL_PROJECT_STATUS_NAME_MAP),
      formSaveField: 'status_id',
      visible: true,
      showInForm: true,
      required: true,
      isClearable: false,
      order: 20,
      formOrder: 10,
    },
    {
      name: 'name',
      title: 'Name',
      description: 'Internal Project Name',
      format: COLUMN_FORMAT.NAME_LINK_LONG,
      linkRoute: '/internal-projects/internal-projects',
      visible: true,
      showInForm: true,
      required: true,
      order: 30,
      formOrder: 20,
    },
    {
      name: 'description',
      title: 'Description',
      description: 'Internal Project Description',
      format: COLUMN_FORMAT.HTML_NOTIN_TABLE,
      formFieldType: FORM_FIELD_TYPE.HTML_INPUT,
      visible: false,
      placeholder: 'Provide a meaningful description of your project',
      required: true,
      order: 40,
      formOrder: 30,
    },
    {
      name: 'progress',
      title: 'Progress',
      description: 'Project Progress',
      format: COLUMN_FORMAT.PROGRESS_PERCENT,
      formFieldType: FORM_FIELD_TYPE.PROGRESS_PERCENT_INPUT,
      filterFormat: COLUMN_FORMAT.NUMBER,
      visible: true,
      required: false,
      order: 50,
      formOrder: 50,
    },
    {
      name: 'estimated_hours',
      title: 'Estimated Time',
      description: "Project's estimated business day labour hours",
      formFieldToolTip: businessHoursToolTipHtml,
      format: COLUMN_FORMAT.DURATION_BUSINESS_TIME,
      visible: true,
      required: false,
      formFieldType: FORM_FIELD_TYPE.BUSINESS_HOURS_INPUT,
      filterFormat: COLUMN_FORMAT.NUMBER,
      order: 60,
      formOrder: 60,
    },
    {
      name: 'actual_hours',
      title: 'Actual Hours',
      description: "Project's actual business day labour hours",
      formFieldToolTip: businessHoursToolTipHtml,
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      required: false,
      formFieldType: FORM_FIELD_TYPE.BUSINESS_HOURS_INPUT,
      filterFormat: COLUMN_FORMAT.NUMBER,
      order: 70,
      formOrder: 70,
    },
    {
      name: 'external_url',
      title: 'External Link',
      description: '',
      format: COLUMN_FORMAT.ABBREVIATED_EXT_LINK,
      visible: true,
      showInForm: true,
      order: 80,
      formOrder: 80,
    },
    {
      name: 'internal_impact',
      title: 'Internal Impact',
      description: 'Project Internal Impact',
      format: COLUMN_FORMAT.ID_NAME_OPTIONS,
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: mapSelectInputOptions(IMPACT_LEVEL_NAME_MAP),
      visible: true,
      required: false,
      order: 90,
      formOrder: 90,
    },
    {
      name: 'customer_impact',
      title: 'Customer Impact',
      description: 'Project Customer Impact',
      format: COLUMN_FORMAT.ID_NAME_OPTIONS,
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: mapSelectInputOptions(IMPACT_LEVEL_NAME_MAP),
      visible: true,
      required: false,
      order: 100,
      formOrder: 100,
    },
    {
      name: 'owner',
      title: 'Owner',
      description: 'The Ci team member responsible for this project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'owner',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
      formSaveField: 'owner_id',
      sortColumn: 'owner.name',
      visible: true,
      showInForm: true,
      required: false,
      order: 101,
      formOrder: 101,
    },
    {
      name: 'project_lead',
      title: 'Project Lead',
      description: 'The assigned project manager resource',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'project_lead',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
      formSaveField: 'project_lead_id',
      sortColumn: 'projectLead.name',
      visible: true,
      showInForm: true,
      order: 102,
      formOrder: 102,
    },
    {
      name: 'count_of_item',
      title: 'Tasks',
      description: 'Total count of tasks for project',
      format: COLUMN_FORMAT.NUMBER,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: false,
      order: 110,
      formOrder: 110,
    },
    {
      name: 'sprint',
      title: 'Sprint',
      description: 'Current Sprint of Project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'sprint',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/internal-projects/sprints',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.SPRINT_PICKER,
      formSaveField: 'sprint_id',
      visible: false,
      showInForm: true,
      required: false,
      isClearable: false,
      order: 120,
      formOrder: 120,
    },
    {
      name: 'template',
      title: 'Apply Task Template...',
      description: 'Internal Project Description',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'template',
        key: 'id',
        format: COLUMN_FORMAT.TEXT,
      },
      formFieldType: FORM_FIELD_TYPE.TASK_TEMPLATE_PICKER,
      formSaveField: 'apply_template_id',
      visible: false,
      // required: false,
      order: 120,
      formOrder: 120,
    },
    {
      name: 'created_by',
      title: 'Created By',
      description: 'The Ci team member who created the project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'created_by',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      formSaveField: 'owner_id',
      sortColumn: 'createdBy.name',
      visible: false,
      showInForm: true,
      required: false,
      isReadOnly: true,
      order: 130,
      formOrder: 130,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add a Project',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new Project',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the project',
      successMessage: 'Updated project',
      failMessage: 'Failed to update Project',
      hideRecordActionButton: true,
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the Project',
      successMessage: 'Project deleted',
      failMessage: 'Failed to delete Project',
      hideRecordActionButton: true,
    },
    {
      name: 'push-to-quickbooks',
      title: 'Push to Quickbooks',
      color: BUTTON_COLOR.GREEN,
      icon: ICON.CLOCK,
      description: 'This will add the project to Quickbooks for time tracking etc.',
      successMessage: 'Project added to QuickBooks',
      failMessage: 'Failed to add the Project to QuickBooks',
      hideRecordActionButton: false,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'card',
      title: 'Project',
      description: 'Internal Project Card',
      widgetDefinition: WIDGET_DEFINITION.INTERNAL_PROJECT_CARD,
      apiRoute: '/item',
      apiQuery: [
        'sort[0][field]=priority&sort[0][direction]=asc',
        'with[]=createdBy:id,name,initials',
        'with[]=assignedTo:id,name',
        'pagelength=1000',
      ].join('&'),
    },
    {
      name: 'detail',
      title: 'Details',
      description: 'Internal Project Details',
      widgetDefinition: WIDGET_DEFINITION.INTERNAL_PROJECT_DETAILS,
      apiQuery: [
        'with[]=status',
        'with[]=sprint:id,name',
        'with[]=createdBy:id,name,initials',
        'with_count[]=item',
      ].join('&'),
    },
    {
      name: 'stakeholders',
      title: 'Stakeholders',
      description: 'Project Stakeholders',
      apiRoute: '/stakeholder',
      widgetDefinition: WIDGET_DEFINITION.INTERNAL_PROJECT_STAKEHOLDERS,
      apiQuery: ['with[]=user:id,name', 'with[]=role:id,name', 'pagelength=100'].join('&'),
    },
    {
      name: 'tasks',
      title: 'Tasks',
      description: 'Tasks Assigned to Project',
      widgetDefinition: WIDGET_DEFINITION.INTERNAL_PROJECT_TASKS,
      apiRoute: '/item',
      apiQuery: [
        'sort[0][field]=priority&sort[0][direction]=asc',
        'with[]=assignedTo:id,name',
        'with[]=sprint:id,name',
        'pagelength=100',
      ].join('&'),
    },
    {
      name: 'notes',
      title: 'Notes',
      description: 'Notes',
      widgetDefinition: WIDGET_DEFINITION.INTERNAL_PROJECT_NOTE,
      apiRoute: '/note',
      apiQuery: [
        'with[]=createdBy:id,name',
        'with[]=type:id,name',
        'pagelength=100',
      ].join('&'),
    },
    {
      ...eventsTabDefault,
      apiQuery: [
        'with[]=createdBy:id,name',
        'with[]=internalProjectItemTracking:id,data,story',
        'with[]=internalProjectStakeholderTracking:id,data,story',
        'with[]=internalProjectNoteTracking:id,data,story',
        'pagelength=1000',
      ].join('&'),
      statusMap: INTERNAL_PROJECT_STATUS_NAME_MAP,
    },
  ],

  /**
   * View definitions
   */
  viewKey: 'all',
  views: [
    {
      key: 'all',
      title: 'All Projects',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'All My Projects',
        },
      ],
      filters: [],
      flags: [],
      sortedColumns: [
        {
          name: 'priority',
          direction: SORT_DIRECTION.ASC,
          sortIndex: 0,
        },
      ],
    },
    {
      key: 'in_progress',
      title: 'In Progress',
      sortedColumns: [
        {
          name: 'priority',
          direction: SORT_DIRECTION.ASC,
          sortIndex: 0,
        },
      ],
      flagTitles: [],
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['in progress'],
        },
      ],
      flags: [],
    },
  ],
};

export default internalProjectsTableInitialSettings;
