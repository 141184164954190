import { localStorageSave, localStorageLoad } from './localStorage';

const LOCAL_STORAGE_BASE_RATE_LIMITER_KEY = 'rate_limiters_v1';

/**
 * @description
 * Safely write a value to the rate limiters in local storage
 *
 * @param {string} rateLimiterKey
 * @param {number} count
 */
const writeToLocalStorageRateLimiters = (rateLimiterKey, count) => {
  const allRateLimiters = localStorageLoad(LOCAL_STORAGE_BASE_RATE_LIMITER_KEY) || {};
  if (allRateLimiters instanceof Object) {
    allRateLimiters[rateLimiterKey] = {
      count,
      savedAt: Date.now(),
    };

    localStorageSave(LOCAL_STORAGE_BASE_RATE_LIMITER_KEY, allRateLimiters);
  }
};

/**
 * @description
 * Safely read a value from the rate limiters in local storage
 *
 * @param {string} rateLimiterKey
 * @returns {{
 *  count: any,
 *  savedAt: number,
 *  found: boolean
 * }}
 */
const readFromLocalStorageRateLimiters = (rateLimiterKey) => {
  const allRateLimiters = localStorageLoad(LOCAL_STORAGE_BASE_RATE_LIMITER_KEY) || {};

  let count;
  let savedAt;
  let found = false;

  // set the rate limiters count to the count found in local storage
  if (allRateLimiters instanceof Object && Object.prototype.hasOwnProperty.call(allRateLimiters, 'rateLimiterKey')) {
    ({ count, savedAt } = allRateLimiters[rateLimiterKey]);
    found = true;
  }

  return {
    count,
    savedAt,
    found,
  };
};


/**
 * @description
 * Creates a rate limiter
 * Can be used to track whether we are over a maximum number of __ per __
 *
 * @param {number} maxRequestsPerPeriod  maximum amount of requests that can be made over the period specified
 * @param {number} fullResetPeriod milliseconds per fullResetPeriod (1000 = 1 second, 60000 = 1 minute)
 * @param {string | undefined} localStorageRateLimiterKey leave undefined to not use local storage
 */
export const createRateLimiter = (maxRequestsPerPeriod, fullResetPeriod, localStorageRateLimiterKey = undefined) => {
  // validate maxRequestsPerPeriod
  if (!maxRequestsPerPeriod || typeof maxRequestsPerPeriod !== 'number' || maxRequestsPerPeriod <= 0) {
    throw new TypeError('invalid "maxRequestsPerPeriod" supplied to "createRateLimiter"');
  }

  // validate fullResetPeriod
  if (!fullResetPeriod || typeof fullResetPeriod !== 'number' || fullResetPeriod <= 0) {
    throw new TypeError('invalid "fullResetPeriod" supplied to "createRateLimiter"');
  }

  // validate localStorageRateLimiterKey
  if (!(localStorageRateLimiterKey === undefined || (typeof localStorageRateLimiterKey === 'string'))) {
    throw new TypeError('invalid "localStorageRateLimiterKey" supplied to "createRateLimiter"');
  }

  const period = fullResetPeriod / maxRequestsPerPeriod;
  let currentCount = 0;
  let decrementCountInterval = null;

  /**
   * @description
   * every <period> -> remove one request from the currentCount  counter
   */
  const startCountPerPeriodDecrementer = () => {
    // do nothing if already running
    if (decrementCountInterval) return;

    // start the interval
    decrementCountInterval = setInterval(() => {
      currentCount = Math.max(0, currentCount - 1);
      if (localStorageRateLimiterKey) writeToLocalStorageRateLimiters(localStorageRateLimiterKey, currentCount);
      if (currentCount === 0) {
        // requestsPerMinute can't get any lower -> remove interval
        clearInterval(decrementCountInterval);
        decrementCountInterval = null;
      }
    }, period);
  };

  /**
   * Initialise from local storage if required
   */
  if (localStorageRateLimiterKey) {
    const { count: oldCount, savedAt: oldCountSavedAt, found } = readFromLocalStorageRateLimiters(localStorageRateLimiterKey);

    if (found) {
      const decrementedSinceSave = Math.floor((Date.now() - oldCountSavedAt) / period);

      if (
        oldCount !== null && oldCount !== undefined &&
        decrementedSinceSave !== null && decrementedSinceSave !== undefined
      ) {
        currentCount = Math.max(oldCount - decrementedSinceSave, 0);
        startCountPerPeriodDecrementer();
      }
    }
  }

  return {
    incrementCount: () => {
      currentCount += 1;
      if (localStorageRateLimiterKey) writeToLocalStorageRateLimiters(localStorageRateLimiterKey, currentCount);
      startCountPerPeriodDecrementer();
    },
    isOverLimit: () => currentCount >= maxRequestsPerPeriod,
  };
};
