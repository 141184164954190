/**
 * Serialize form object keys to submit-able parameters
 *
 * @param {Object} formData an object containing keys for each form field to submit
 */
export const serializeQueryParams = (params) => {
  const queryString = Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
  return queryString;
};
