import React, { useCallback, useContext } from 'react';
import classNames from 'classnames';
import { FormatOptionLabelMeta } from 'react-select';

import { ModalContext } from '../modals/modal-context';
import { CurrentUserContext } from '../providers/current-user-provider';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';
import { ICompanySpaceTypeRecord } from '../../types/company/company-space-type.record.interface';
import { NewRecordModalResult } from '../../types/modal/modal-result';

import AsyncSelect from './async-select';
import Icon from '../layout-helpers/icon';

import { MODAL_TYPE } from '../../constants/modal-type.const';
import { PERMISSION } from '../../constants/permissions.const';
import { ICON } from '../../constants/icon.const';


export type CompanySpaceTypePickerProps = AsyncSelectComponentProps;

export const CompanySpaceTypePicker: React.FC<CompanySpaceTypePickerProps> = (props) => {
  const {
    className,
    name,
    formData = {},
    value,
    parentData = {},
    formSaveField = null,
    isCreatable = true,

    getNewItemData,
    renderOption,
    onChange,
  } = props;

  const { userHasPermissions } = useContext(CurrentUserContext);
  const { showModal } = useContext(ModalContext);

  /**
   * Show a modal to create a new company space type
   */
  const handleCreateOption = useCallback((inputValue: string) => {
    const initialData = {
      name: inputValue,
      ...(getNewItemData ? getNewItemData(inputValue, formData, parentData) : {}),
    };

    showModal<NewRecordModalResult<ICompanySpaceTypeRecord>>(MODAL_TYPE.NEW_COMPANY_SPACE_TYPE, {
      initialData,
      onModalComplete: ({ success, newRecord: newCompanySpaceType }) => {
        if (success && onChange && newCompanySpaceType) {
          onChange({
            fieldName: formSaveField || `${name}_id`,
            newValue: newCompanySpaceType.id,
            objectFieldName: name,
            objectFieldNewValue: newCompanySpaceType,
          });
        }
      },
    });
  }, [formData, formSaveField, getNewItemData, name, onChange, parentData, showModal]);


  /**
   * Renders an option using the icon and the space path
   */
  const handleRenderOption = useCallback((
    option: ICompanySpaceTypeRecord | null,
    labelMeta: FormatOptionLabelMeta<{label: string, value: string}, false>,
  ): string | React.ReactNode => {
    if (renderOption) {
      return renderOption(option, labelMeta as unknown as Record<string, unknown>, value ?? null);
    }

    if (!option) return null;

    // Return the label when the AsyncSelect is rendering a "Create new XXXX" option
    if (option.__isNew__) { return (
      <>
        <Icon i="plus-circle" />
        <span>{option.label as string}</span>
      </>
    ); }

    return (
      <span>
        <Icon i={option.icon ?? ICON.SPACE} />
        <span>{option.name}</span>
      </span>
    );
  }, [renderOption, value]);

  // Render
  return (
    <AsyncSelect
      {...props}
      className={classNames('company-space-type-picker', className)}
      searchRoute="/companyspacetype"
      loadAndKeepAll
      isCreatable={isCreatable && userHasPermissions([PERMISSION.COMPANY_SPACE_TYPE_MAINTAINER])}
      onCreateOption={handleCreateOption}
      renderOption={handleRenderOption}
    />
  );
};
