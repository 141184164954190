// @see https://www.fusioncharts.com/dev/chart-guide/list-of-charts

export const FUSION_CHART_TYPES_SINGLE_SERIES = {
  COLUMN_2D: 'column2d',
  COLUMN_3D: 'column3d',
  LINE: 'line',
  AREA_2D: 'area2d',
  BAR_2D: 'bar2d',
  BAR_3D: 'bar3d',
  PIE_2D: 'pie2d',
  PIE_3D: 'pie3d',
  DOUGHNUT_2D: 'doughnut2d',
  DOUGHNUT_3D: 'doughnut3d',
  PARETO_2D: 'pareto2d',
  PARETO3: 'pareto3',
};

export const FUSION_CHART_TYPES_MULTIPLE_SERIES = {
  MULTI_SERIES_COLUMN_2D: 'mscolumn2d',
  MULTI_SERIES_COLUMN_3D: 'mscolumn3d',
  MULTI_SERIES_LINE_2D: 'msline',
  MULTI_SERIES_BAR_2D: 'msbar2d',
  MULTI_SERIES_BAR_3D: 'msbar3d',
  OVERLAPPED_COLUMN_2D: 'overlappedcolumn2d',
  OVERLAPPED_BAR_2D: 'overlappedbar2d',
  MULTI_SERIES_AREA_2D: 'msarea',
  MARIMEKKO: 'marimekko',
  ZOOM_LINE: 'zoomline',
  ZOOM_LINE_DY: 'zoomliney',
};

export const FUSION_CHART_TYPES_STACKED = {
  STACKED_COLUMN_2D: 'stackedcolumn2d',
  STACKED_COLUMN_3D: 'stackedcolumn3d',
  STACKED_BAR_2D: 'stackedbar2d',
  STACKED_BAR_3D: 'stackedbar3d',
  STACKED_AREA_2D: 'stackedarea2d',
  MULTI_SERIES_STACKED_COLUMN_2D: 'msstackedcolumn2d',
};

export const FUSION_CHART_TYPES_COMBINATION = {
  MULTI_SERIES_2D_SINGLE_Y_COMBINATION_CHART_COLUMN_LINE_AREA: 'mscombi2d',
  MULTI_SERIES_3D_SINGLE_Y_COMBINATION_CHART_COLUMN_LINE_AREA: 'mscombi3d',
  MULTI_SERIES_COLUMN_3D_LINE_SINGLE_Y_AXIS: 'mscolumnline3d',
  STACKED_COLUMN_2D_LINE_SINGLE_Y_AXIS: 'stackedcolumn2dline',
  STACKED_COLUMN_3D_LINE_SINGLE_Y_AXIS: 'stackedcolumn3dline',
  MULTI_SERIES_2D_DUAL_Y_COMBINATION_CHART_COLUMN_LINE_AREA: 'mscombidy2d',
  MULTI_SERIES_COLUMN_3D_LINE_DUAL_Y_AXIS: 'mscolumn3dlinedy',
  STACKED_COLUMN_3D_LINE_DUAL_Y_AXIS: 'stackedcolumn3dlinedy',
  MULTI_SERIES_STACKED_COLUMN_2D_LINE_DUAL_Y_AXIS: 'msstackedcolumn2dliney',
};

export const FUSION_CHART_TYPES_XY_PLOT = {
  SCATTER_CHART: 'scatter',
  ZOOM_SCATTER_CHART: 'zoomscatter',
  BUBBLE_CHART: 'bubble',
};

export const FUSION_CHART_TYPES_SCROLL = {
  SCROLL_COLUMN_2D: 'scrollcolumn2d',
  SCROLL_LINE_2D: 'scrollline2d',
  SCROLL_AREA_2D: 'scrollarea2d',
  SCROLL_STACKED_COLUMN_2D: 'scrollstackedcolumn2d',
  SCROLL_COMBINATION_2D_SINGLE_Y: 'scrollcombi2d',
  SCROLL_COMBINATION_2D_DUAL_Y: 'scrollcombidyd',
};

export const FUSION_CHART_TYPES_TIME = {
  TIMESERIES: 'timeseries',
};


const FUSION_CHART_TYPES = {
  ...FUSION_CHART_TYPES_SINGLE_SERIES,
  ...FUSION_CHART_TYPES_MULTIPLE_SERIES,
  ...FUSION_CHART_TYPES_STACKED,
  ...FUSION_CHART_TYPES_COMBINATION,
  ...FUSION_CHART_TYPES_XY_PLOT,
  ...FUSION_CHART_TYPES_SCROLL,
  ...FUSION_CHART_TYPES_TIME,
};

const FUSION_CHART_TYPES_UNSUPPORTED = [
  FUSION_CHART_TYPES.PARETO3,
  FUSION_CHART_TYPES.OVERLAPPED_COLUMN_2D,
  FUSION_CHART_TYPES.OVERLAPPED_BAR_2D,
  FUSION_CHART_TYPES.ZOOM_LINE,
  FUSION_CHART_TYPES.ZOOM_LINE_DY,
  FUSION_CHART_TYPES.MULTI_SERIES_STACKED_COLUMN_2D,
  FUSION_CHART_TYPES.MULTI_SERIES_STACKED_COLUMN_2D_LINE_DUAL_Y_AXIS,

  FUSION_CHART_TYPES.SCATTER_CHART,
  FUSION_CHART_TYPES.ZOOM_SCATTER_CHART,
  FUSION_CHART_TYPES.BUBBLE_CHART,
];

export const FUSION_CHART_TYPES_SUPPORTED = Object
  .values(FUSION_CHART_TYPES)
  .filter((type) => !FUSION_CHART_TYPES_UNSUPPORTED.includes(type));

const FUSION_CHART_ALL_MULTI_SERIES_TYPES = [
  ...Object.values(FUSION_CHART_TYPES_MULTIPLE_SERIES),
  ...Object.values(FUSION_CHART_TYPES_COMBINATION),
  ...Object.values(FUSION_CHART_TYPES_STACKED),
  ...Object.values(FUSION_CHART_TYPES_SCROLL),
];

export const FUSION_CHART_SUPPORTED_MULTI_SERIES_TYPES = FUSION_CHART_ALL_MULTI_SERIES_TYPES
  .filter((chart) => !FUSION_CHART_TYPES_UNSUPPORTED.includes(chart));

export const FUSION_CHART_SUPPORTED_SINGLE_SERIES_TYPES = Object.values(FUSION_CHART_TYPES_SINGLE_SERIES)
  .filter((chart) => !FUSION_CHART_TYPES_UNSUPPORTED.includes(chart));

export const isMultiSeriesChart = (chartType) => (FUSION_CHART_ALL_MULTI_SERIES_TYPES.includes(chartType));

export default FUSION_CHART_TYPES;
