
import { DELETE_CONFIRMATION_TYPE } from '../constants/delete-confirmation-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

export const CommissionPaymentDetailWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Salesperson',
  formDeleteConfirmationType: DELETE_CONFIRMATION_TYPE.REMOVE,
  fields: [
    {
      name: 'sales_owner',
      title: 'Salesperson',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
    }, {
      name: 'project',
      title: 'Project',
      format: COLUMN_FORMAT.OBJECT,
      placeholder: 'P12345ABC',
      object: {
        sourceField: 'project',
        key: 'project_number',
        format: COLUMN_FORMAT.P_NUMBER,
        listSource: 'project',
      },
      sortColumn: 'project.id',
    }, {
      name: 'rate',
      title: 'Rate',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.TEXT_INPUT,
      allowNegative: true,
      format: COLUMN_FORMAT.NUMBER_SHORT,
    }, {
      name: 'amount',
      title: 'Amount',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
      allowNegative: true,
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    }, {
      name: 'paid_date',
      visible: true,
      title: 'Paid Date',
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      format: COLUMN_FORMAT.LONG_DATE,
      description: 'Commission Paid Date',
    },
    {
      name: 'comment',
      title: 'Comment',
      visible: true,
      format: COLUMN_FORMAT.LONG_TEXT,
    },
  ],
};
