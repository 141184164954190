import React, { useState } from 'react';
import classnames from 'classnames';
import shortId from 'shortid';

import { TextInputProps } from './text-input';

export type BusinessHoursInputProps = TextInputProps;

// export the tooltip
export const businessHoursToolTipHtml: string = [
  '<strong>Business Day Hours:</strong>',
  '<p>Will be displayed in terms of hours, days and weeks, <br/>calculated on 8 hour days and 40 hour weeks.<p>',
  '<p>You can also use some simple shorthand to enter <br/>pre-calculated time. eg. \'5d\' will calculate 5 days</p>',
  '<strong>Shortcuts:</strong>',
  '<table class="table text-center"><thead><tr><th>Example</th><th>Period</th><th class="text-right">Hours</th></tr></thead><tbody>',
  '<tr><td>1d</td><td>days</td><td class="text-right">8</td></tr>',
  '<tr><td>1w</td><td>weeks</td><td class="text-right">40</td></tr>',
  '<tr><td>1m</td><td>months</td><td class="text-right">244</td></tr>',
  '<tr><td>1y</td><td>years</td><td class="text-right">2000</td></tr>',
  '</tbody></table>',
].join('\n');


export const BusinessHoursInput:React.FC<BusinessHoursInputProps> = ({
  id,
  onChange,
  onKeyPress,
  disabled,
  value,
  placeholder,
  hasError,
  name,
  inline,
  maxLength,
  className,
  autoComplete,
  autoCompleteIdentifier,
  tabIndex,
}) => {
  // This is to prevent chrome's auto complete from getting in the way
  const [autoCompleteBlockingName] = useState(() => ((autoComplete === true) ? (autoCompleteIdentifier ?? name) : `${shortId.generate()}`));

  const timeMultipliers: { [key: string]: number } = {
    d: 8,
    w: 8 * 5, //  a week of business hours
    m: (8 * 30.5), // roughly a month of business hours
    y: 250 * 8, // roughly a year of business hours
  };

  return (
    <input
      id={id}
      required
      onChange={(e) => {
        if (onChange) { onChange({
          fieldName: name,
          newValue: e.target.value,
        }); }
      }}
      onBlur={(e) => {
        const matchResult = e.currentTarget.value.match(/^([\d]{1,})([hdwmyHDWMY]{0,1})$/i);
        if (matchResult && matchResult.length > 2) {
          const number = matchResult[1];
          const multiplierKey = matchResult[2].toLocaleLowerCase();
          const calculatedValue = multiplierKey ? parseFloat(number) * timeMultipliers[multiplierKey] : number;
          if (onChange) { onChange({
            fieldName: name,
            newValue: calculatedValue.toString(),
          }); }
        }
      }}
      onKeyPress={(e) => {
        if (e.key.toLowerCase() === 'enter') {
          e.currentTarget.blur();
        }
        if (onKeyPress) {
          onKeyPress(e);
        }
      }}
      disabled={disabled === true}
      name={autoCompleteBlockingName}
      value={value ?? ''}
      type="text"
      placeholder={placeholder}
      className={classnames(className, 'form-control', {
        inline: (inline === true),
        'form-control-danger': (hasError === true),
      })}
      maxLength={maxLength}
      // Using 'autocomplete="off" is not enough for chrome. Have to give it a random string so that it tries to auto-complete for a random category instead
      autoComplete={(autoComplete === true) ? (autoCompleteIdentifier ?? 'on') : autoCompleteBlockingName}
      tabIndex={tabIndex}
    />
  );
};
