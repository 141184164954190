import { ContractScheduledChecksWidget } from '../components/widgets/contract-schedule-checks.widget';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';

export const ContractScheduledChecksWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  component: ContractScheduledChecksWidget,
  itemCaption: 'Scheduled Checks',
  fields: [
    {
      name: 'id',
      visible: false,
      isParentId: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'contract_id',
      visible: false,
      isParentId: true,
    },
    {
      name: 'perform_before',
      title: 'Perform Before',
      visible: true,
      required: true,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      format: COLUMN_FORMAT.LONG_DATE,
    },
    {
      name: 'booked_on',
      title: 'Booked On',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      format: COLUMN_FORMAT.LONG_DATE,
    },
    {
      name: 'performed_on',
      title: 'Performed On',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      format: COLUMN_FORMAT.LONG_DATE,
    },
    {
      name: 'notes',
      title: 'Notes',
      formFieldType: FORM_FIELD_TYPE.TEXT_INPUT,
      format: COLUMN_FORMAT.STRIP_HTML,
    },
  ],
};
