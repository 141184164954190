import React, {
  useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import ProjectSummaryTableHeaderGroup from './project-summary-table-header-group';
import SplitHandle from '../../split-handle/split-handle';
import { deConstructDomDataTagId } from './project-summary-helpers';
import ProjectSummaryTableStateSummaryDataRows from './project-summary-table-state-summary-data-rows';
import ProjectSummaryTableStateMonthlyDataRows from './project-summary-table-state-monthly-data-rows';
import ProjectSummaryTableRow from './project-summary-table-row';
import ProjectSummaryTableHeaderMonthCell from './project-summary-table-header-month-cell';
import ProjectSummaryTableScrollContainer from './project-summary-table-scroll-container';
import Icon from '../../layout-helpers/icon';


/**
 * @description
 * Render out the Project Summary Data as a CSS Grid Table
 *
 * @Note: the grid tables are rendered out in RTL to render the left most table over the right most table
 */
const ProjectSummaryTable = (props) => {
  const {
    summaryFields,
    monthlyFields,
    monthGroups,
    hideEmptyStates,
    geographicalStateData,
    projectManagerData,
    projectData,
    stateIds,
    pmAndStateIds,
    allowCollapseAllRows,
    allowExpandAllRows,
    splitHandleForceUpdateId,

    onRowClick,
    onAddMonthStart,
    onAddMonthEnd,
    onRemoveMonthStart,
    onRemoveMonthEnd,
    onExpandAllRows,
    onCollapseAllRows,
  } = props;

  const tableRef = useRef();
  const leftScrollContainerRef = useRef();
  const rightScrollContainerRef = useRef();
  const [isResizing, setIsResizing] = useState(false);

  // The width of the left scroll container is in percent
  let leftScrollContainerWidth = 50;

  if (!summaryFields && (!monthlyFields || !monthGroups)) return null;

  const visibleSummaryFieldCount = summaryFields.filter((field) => field.visible).length;
  const visibleMonthlyFieldCount = monthlyFields.filter((field) => field.visible).length;
  const visibleStateIds = geographicalStateData.summaryData.data.filter((gsd) => gsd.count > 0).map((gsd) => gsd.state_id);


  /**
   * @description
   * Fired when the user begins dragging the split handle
   */
  const handleSplitHandleBeginDrag = () => {
    setIsResizing(true);
  };


  /**
   * @description
   * Fired when the user finishes dragging the split handle
   */
  const handleSplitHandleEndDrag = () => {
    setIsResizing(false);
  };


  /**
   * @description
   * Fired during the drag of the split handle
   *
   * @param {number} position the current position (in percent) of the drag handle
   */
  const handleSplitHandleDrag = (position) => {
    leftScrollContainerWidth = position;

    // Just update the scroll container style tag, no need to re-render the whole component
    if (leftScrollContainerRef.current) {
      leftScrollContainerRef.current.style.maxWidth = `${position}%`;
    }
  };


  /**
   * @description
   * Fired when an item in the table begins it's hover
   *
   * @param {DATA_TYPE} dataType
   * @param {string} id the constructed dom `data-id` attribute
   */
  const handleBeginHover = (dataType, id) => {
    // find all of the elements with the matching data type and id
    if (tableRef.current) {
      const hoverElements = tableRef.current.querySelectorAll(`tr[data-data-type='${dataType}'][data-id='${id}']`);
      // Add the hover class
      hoverElements.forEach((row) => {
        row.classList.add('hover');
      });
    }
  };


  /**
   * @description
   * Fired when an item in the table ends it's hover
   *
   * @param {DATA_TYPE} dataType
   * @param {string} id the constructed dom `data-id` attribute
   */
  const handleEndHover = (dataType, id) => {
    // find all of the elements with the matching data type and id
    if (tableRef.current) {
      const hoverElements = tableRef.current.querySelectorAll(`tr[data-data-type='${dataType}'][data-id='${id}']`);
      // Remove the hover class
      hoverElements.forEach((row) => {
        row.classList.remove('hover');
      });
    }
  };


  /**
   * @description
   * Fired when the user clicks a row
   *
   * @param {DATA_TYPE} dataType
   * @param {string} id the constructed dom `data-id` attribute
   */
  const handleRowClick = (dataType, id) => {
    const data = deConstructDomDataTagId(dataType, id);
    if (typeof onRowClick === 'function') {
      onRowClick(dataType, data);
    }
  };


  /**
   * @description
   * Apply some local client side filters to the state data
   */
  const filteredGeographicalStateData = () => ({
    ...geographicalStateData,
    summaryData: {
      ...geographicalStateData.summaryData,
      data: hideEmptyStates ? geographicalStateData.summaryData.data.filter((sd) => visibleStateIds.includes(sd.state_id)) : geographicalStateData.summaryData.data,
    },
    monthlyData: {
      ...geographicalStateData.monthlyData,
      data: hideEmptyStates ? geographicalStateData.monthlyData.data.filter((md) => visibleStateIds.includes(md.state_id)) : geographicalStateData.monthlyData.data,
    },
  });

  return (
    <div
      ref={tableRef}
      className={classNames(
        'project-summary-table',
        {
          resizing: isResizing,
        },
      )}
    >
      {/* Left Table (Summary Data) */}
      <ProjectSummaryTableScrollContainer
        className="scroll-container left"
        innerRef={leftScrollContainerRef}
        partnerRef={rightScrollContainerRef}
        innerStyle={{ maxWidth: `${leftScrollContainerWidth}%` }}
        pinToHorizontalEnd={false}
      >
        {/* Fixed Buttons */}
        <div
          className="fixed-buttons"
        >
          <div className="fixed-buttons-wrapper">
            <Button
              title="Collapse all rows"
              onClick={onCollapseAllRows}
              className={classNames({ hide: !allowCollapseAllRows })}
            >
              <Icon i="step-forward" className="fa-rotate-270" />
            </Button>
            <Button
              title="Expand all rows"
              onClick={onExpandAllRows}
              className={classNames({ hide: !allowExpandAllRows })}
            >
              <Icon i="step-forward" className="fa-rotate-90" />
            </Button>
          </div>
        </div>

        <table className="left">
          {/* Table Headings */}
          <thead>

            {/* Summary Group Header */}
            <ProjectSummaryTableRow>
              <ProjectSummaryTableHeaderGroup span={visibleSummaryFieldCount}>Summary</ProjectSummaryTableHeaderGroup>
            </ProjectSummaryTableRow>

            {/* Summary Column Headers */}
            <ProjectSummaryTableRow>
              <ProjectSummaryTableHeaderGroup fields={summaryFields} />
            </ProjectSummaryTableRow>
          </thead>

          {/* Table Body */}
          <tbody>
            <ProjectSummaryTableStateSummaryDataRows
              summaryFields={summaryFields}
              visibleSummaryFieldCount={visibleSummaryFieldCount}
              stateIds={stateIds}
              pmAndStateIds={pmAndStateIds}

              onClick={handleRowClick}
              onBeginHover={handleBeginHover}
              onEndHover={handleEndHover}

              geographicalStateData={filteredGeographicalStateData()}
              projectManagerData={projectManagerData}
              projectData={projectData}
            />
          </tbody>
        </table>
      </ProjectSummaryTableScrollContainer>

      {/* Right Table (Monthly Data */}
      <ProjectSummaryTableScrollContainer
        className="scroll-container right"
        innerRef={rightScrollContainerRef}
        partnerRef={leftScrollContainerRef}
      >
        <table className="right">
          {/* Table Headings */}
          <thead>

            {/* Month Group Header Fields (i.e. "July 19") */}
            <ProjectSummaryTableRow>
              {monthGroups.map((monthGroup) => (
                <ProjectSummaryTableHeaderMonthCell
                  key={monthGroup.key}
                  monthGroups={monthGroups}
                  monthGroup={monthGroup}
                  span={visibleMonthlyFieldCount}
                  onAddMonthStart={onAddMonthStart}
                  onAddMonthEnd={onAddMonthEnd}
                  onRemoveMonthStart={onRemoveMonthStart}
                  onRemoveMonthEnd={onRemoveMonthEnd}
                />
              ))}
            </ProjectSummaryTableRow>

            {/* Monthly Data Header Fields (i.e. "Monthly Forecast") */}
            <ProjectSummaryTableRow>
              {monthGroups.map((monthGroup) => (
                <ProjectSummaryTableHeaderGroup
                  key={`headers_${monthGroup.key}`}
                  fields={monthlyFields}
                />
              ))}
            </ProjectSummaryTableRow>
          </thead>

          {/* Table Body */}
          <tbody>
            <ProjectSummaryTableStateMonthlyDataRows
              monthGroups={monthGroups}
              monthlyFields={monthlyFields}
              visibleMonthlyFieldCount={visibleMonthlyFieldCount}
              stateIds={stateIds}
              pmAndStateIds={pmAndStateIds}

              onClick={handleRowClick}
              onBeginHover={handleBeginHover}
              onEndHover={handleEndHover}

              geographicalStateData={filteredGeographicalStateData()}
              projectManagerData={projectManagerData}
              projectData={projectData}
            />
          </tbody>
        </table>
      </ProjectSummaryTableScrollContainer>

      {/* Split Handle for controlling the size of the left scroll container */}
      {leftScrollContainerRef.current && (
        <SplitHandle
          controlElement={leftScrollContainerRef.current}
          forceUpdateId={splitHandleForceUpdateId}
          onBeginDrag={handleSplitHandleBeginDrag}
          onDrag={handleSplitHandleDrag}
          onEndDrag={handleSplitHandleEndDrag}
        />
      )}

    </div>
  );
};

ProjectSummaryTable.propTypes = {
  summaryFields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  })).isRequired,
  monthlyFields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  })).isRequired,
  monthGroups: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  geographicalStateData: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    summaryData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
    monthlyData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
  }).isRequired,
  projectManagerData: PropTypes.arrayOf(
    PropTypes.shape({
      stateId: PropTypes.number.isRequired,
      isLoading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      summaryData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
      monthlyData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
    }),
  ).isRequired,
  projectData: PropTypes.arrayOf(
    PropTypes.shape({
      stateId: PropTypes.number.isRequired,
      projectManagerId: PropTypes.number.isRequired,
      isLoading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      summaryData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
      monthlyData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
    }),
  ).isRequired,
  stateIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  pmAndStateIds: PropTypes.arrayOf(PropTypes.shape({
    pmId: PropTypes.number.isRequired,
    stateId: PropTypes.number.isRequired,
  })).isRequired,
  hideEmptyStates: PropTypes.bool.isRequired,
  allowCollapseAllRows: PropTypes.bool.isRequired,
  allowExpandAllRows: PropTypes.bool.isRequired,
  splitHandleForceUpdateId: PropTypes.number.isRequired,

  onRowClick: PropTypes.func.isRequired,
  onAddMonthStart: PropTypes.func.isRequired,
  onAddMonthEnd: PropTypes.func.isRequired,
  onRemoveMonthStart: PropTypes.func.isRequired,
  onRemoveMonthEnd: PropTypes.func.isRequired,
  onExpandAllRows: PropTypes.func.isRequired,
  onCollapseAllRows: PropTypes.func.isRequired,
};

export default ProjectSummaryTable;
