import React, { useEffect } from 'react';

/**
 * Place this component inside any other component that should be scrolled to the top when mounted
 *
 * It's possible to pass in a `scrollRef` reference to define a scrollable container to use instead of `window`
 */
export const ScrollToTopOnMount:React.FC<{
  scrollRef?: React.RefObject<HTMLElement> | null,
}> = ({ scrollRef }) => {
  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [scrollRef]);

  return null;
};
