import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import { ICompanyLocationPortalTreeViewNodeData } from '../../../types/portal-tree-view/company-location-portal-tree-view-node-data.interface';
import { ICompanyLocationRecord } from '../../../types/company/company-location.record.interface';
import { ICompanyLocationPortalTreeViewSelectedNodeIdentifier } from '../../../types/portal-tree-view/company-location-portal-tree-view-selected-node-identifier.interface';
import { ICompanyRecord } from '../../../types/company/company.record.interface';
import { ICompanySpaceRecord } from '../../../types/company/company-space.record.interface';
import { PortalTreeViewNodeItem } from '../../../types/portal-tree-view/portal-tree-view-node-item';
import { PortalTreeViewMenuItems, PortalTreeViewMenuItemTreeState } from '../../../types/portal-tree-view/portal-tree-view-menu-items';

import { PortalTreeView, PortalTreeViewHandlers, PortalTreeViewProps } from '../portal-tree-view';

import { buildCompanyLocationsTreeItems } from './build-company-locations-tree-items.helper';
import { buildCompanyLocationsNewRecordMenuItems, buildCompanyLocationsTreeMenuItems } from './build-company-locations-tree-menu-items.helper';
import { findPortalTreeViewNode } from '../find-portal-tree-view-node-child.helper';
import { getSelectedNode } from '../portal-tree-view-get-selected-node.helper';

import { CompanyLocationPTVNodeTypeIconMap, COMPANY_LOCATION_PTV_NODE_TYPE } from '../../../constants/company-locations-ptv-node-type.const';
import { A_COMPANY_LOCATION_TYPE, CompanyLocationTypeIconMap, COMPANY_LOCATION_TYPE } from '../../../constants/company-location-type.const';
import { AN_ICON, ICON } from '../../../constants/icon.const';
import { A_PORTAL_TREE_VIEW_MENU_TYPE } from '../portal-tree-view-menu-type.const';
import { A_COMPANY_LOCATION_PTV_MENU_ITEM_TYPE, COMPANY_LOCATION_PTV_MENU_ITEM_TYPE } from './company-locations-portal-tree-menu-item.const';

export const buildCompanyLocationsPortalTreeViewSelectedNodeIdentifier = (
  node: PortalTreeViewNodeItem<ICompanyLocationPortalTreeViewNodeData>,
  nodeData: ICompanyLocationPortalTreeViewNodeData,
): ICompanyLocationPortalTreeViewSelectedNodeIdentifier => ({
  nodeId: node.id,
  parentNodeId: node.parent,
  id: nodeData.id,
  nodeType: nodeData.nodeType,
  parentLocationId: nodeData.parentId ?? null,
  companyLocationTypeId: nodeData.companyLocationTypeId,
  companySpaceType: nodeData.companySpaceType,
});

export type CompanyLocationsPortalTreeViewProps = Pick<PortalTreeViewProps,
  'className' |
  'title' |
  'showHeader' |
  'isLoading' |
  'isReadOnly' |
  'isLocked' |
  'isSafeModeEnabled' |
  'error' |
  'isRootNodeVisible' |
  'isSearchBarVisible' |

  'onStartEdit' |
  'onEndEdit' |
  'onEnterSafeMode' |
  'onExitSafeMode' |
  'onToggleSearchBar'
> & {
  company: undefined | ICompanyRecord,
  companyLocations: undefined | ICompanyLocationRecord[],
  companySpaces: undefined | ICompanySpaceRecord[],
  selectedNodes?: ICompanyLocationPortalTreeViewSelectedNodeIdentifier[],

  onDropNode?: (dragSourceNode: ICompanyLocationPortalTreeViewNodeData, dropTargetNode: undefined | ICompanyLocationPortalTreeViewNodeData) => void,
  onSelectedNodesChanged?: (newSelectedNodes: ICompanyLocationPortalTreeViewSelectedNodeIdentifier[]) => void,
  onMoveNodeUp?: (sourceNode: ICompanyLocationPortalTreeViewNodeData, newParentNode: undefined | ICompanyLocationPortalTreeViewNodeData) => void,
  onCreateLocation?: (locationType: A_COMPANY_LOCATION_TYPE, parentLocation: undefined | ICompanyLocationRecord) => void,
  onCreateSpace?: (parentLocation: undefined | ICompanyLocationRecord) => void,
  onDeleteLocation?: (location: ICompanyLocationRecord) => void,
  onDeleteSpace?: (space: ICompanySpaceRecord) => void,
  onShowRootNode?: () => void,
  onHideRootNode?: () => void,
};


/**
 * Wrapper around the PortalTreeView for company location data
 */
export const CompanyLocationsPortalTreeView: React.FC<CompanyLocationsPortalTreeViewProps> = (props) => {
  const {
    className,
    title = 'Company Locations',
    showHeader = true,
    isLoading,
    isReadOnly = false,
    isLocked = false,
    isSafeModeEnabled = true,
    isRootNodeVisible = false,
    error,
    isSearchBarVisible = false,

    company,
    companyLocations,
    companySpaces,
    selectedNodes = [],

    onDropNode,
    onSelectedNodesChanged,
    onMoveNodeUp,
    onStartEdit,
    onEndEdit,
    onEnterSafeMode,
    onExitSafeMode,
    onShowRootNode,
    onHideRootNode,
    onCreateLocation,
    onCreateSpace,
    onDeleteLocation,
    onDeleteSpace,
    onToggleSearchBar,
  } = props;

  const [treeItems, setTreeItems] = useState<undefined | PortalTreeViewNodeItem<ICompanyLocationPortalTreeViewNodeData>[]>(undefined);
  const treeViewRef = useRef<PortalTreeViewHandlers>(null);
  const [searchTerm, setSearchTerm] = useState<null | string>();

  /**
   * Fired by the Portal Tree View while the user is dragging a node to determine
   * if a node can have a node dropped on it
   */
  const handleCanDropNode = useCallback((sourceTreeData: PortalTreeViewNodeItem<ICompanyLocationPortalTreeViewNodeData>[],
    {
      dragSource,
      dropTarget,
    }: {
      dragSourceId: string | number;
      dropTargetId: string | number;
      dragSource: PortalTreeViewNodeItem<ICompanyLocationPortalTreeViewNodeData> | undefined,
      dropTarget: PortalTreeViewNodeItem<ICompanyLocationPortalTreeViewNodeData> | undefined,
    }): boolean => {
    // Don't even bother evaluating if the tree isn't in edit mode
    if (isReadOnly || isLocked) return false;

    const sourceNodeType = dragSource?.data?.nodeType ?? COMPANY_LOCATION_PTV_NODE_TYPE.ROOT;
    const targetNodeType = dropTarget?.data?.nodeType ?? COMPANY_LOCATION_PTV_NODE_TYPE.ROOT;

    const validTargetType = (
      // Allow dropping anything onto the root node
      (
        targetNodeType === COMPANY_LOCATION_PTV_NODE_TYPE.ROOT
      ) ||

      // Allow dropping spaces onto Locations but not other spaces
      (
        sourceNodeType === COMPANY_LOCATION_PTV_NODE_TYPE.SPACE &&
        targetNodeType === COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION
      ) ||

      // Allow dropping locations onto other locations but not spaces
      (
        sourceNodeType === COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION &&
        targetNodeType === COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION
      )
    );

    return validTargetType;
  }, [isLocked, isReadOnly]);


  /**
   * Fired when a node is dropped on another node
   */
  const handleDropNode = useCallback((sourceTreeData: PortalTreeViewNodeItem<ICompanyLocationPortalTreeViewNodeData>[], options: {
    dragSourceId: string | number;
    dropTargetId: string | number;
    dragSource: PortalTreeViewNodeItem<ICompanyLocationPortalTreeViewNodeData> | undefined;
    dropTarget: PortalTreeViewNodeItem<ICompanyLocationPortalTreeViewNodeData> | undefined;
  }) => {
    if (onDropNode && options.dragSourceId && options.dragSource && options.dragSource.data) {
      const dragSourceNode = options.dragSource.data;
      const dropTargetNode = options.dropTarget && options.dropTarget.data && (options.dropTargetId !== 'ROOT') ? options.dropTarget.data : undefined;

      // Call the event handler from the consumer
      if (onDropNode) {
        onDropNode(dragSourceNode, dropTargetNode);
      }
    }
  }, [onDropNode]);


  /**
   * Fired by the tree when a node is being rendered to calculate the icon that should be displayed
   */
  const handleGetNodeIcon = useCallback((
    node: PortalTreeViewNodeItem<ICompanyLocationPortalTreeViewNodeData>,
    nodeData: ICompanyLocationPortalTreeViewNodeData,
  ): AN_ICON | undefined => {
    const nodeType = nodeData.nodeType ?? COMPANY_LOCATION_PTV_NODE_TYPE.ROOT;
    let nodeIcon: undefined | AN_ICON = CompanyLocationPTVNodeTypeIconMap[nodeType];

    // Calculate the icon for a location
    if (nodeType === COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION) {
      const companyLocationTypeId = nodeData?.companyLocationTypeId;
      nodeIcon = companyLocationTypeId ? CompanyLocationTypeIconMap[companyLocationTypeId] : nodeIcon;
    }

    else if (nodeType === COMPANY_LOCATION_PTV_NODE_TYPE.SPACE) {
      const companySpaceType = nodeData?.companySpaceType;
      nodeIcon = companySpaceType ? ((companySpaceType.icon ?? ICON.SPACE) as AN_ICON) : ICON.SPACE;
    }

    return nodeIcon;
  }, []);


  /**
   * Fired when one of the context menu items is clicked
   */
  const handleClickMenuItem = useCallback((
    menuType: A_PORTAL_TREE_VIEW_MENU_TYPE,
    treeState: PortalTreeViewMenuItemTreeState,
    key: A_COMPANY_LOCATION_PTV_MENU_ITEM_TYPE,
    miSelectedNodes: ICompanyLocationPortalTreeViewSelectedNodeIdentifier[],
  ) => {
    const selectedNode = getSelectedNode(miSelectedNodes);

    switch (key) {
      case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.SHOW_ROOT_NODE:
        if (onShowRootNode) onShowRootNode();
        break;

      case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.HIDE_ROOT_NODE:
        if (onHideRootNode) onHideRootNode();
        break;

      case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.EXPAND_ALL:
        if (treeViewRef && treeViewRef.current) treeViewRef.current.expandAll();
        break;

      case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.COLLAPSE_ALL:
        if (treeViewRef && treeViewRef.current) treeViewRef.current.collapseAll();
        break;

      case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.ENABLE_SAFE_MODE:
        if (onEnterSafeMode) onEnterSafeMode();
        break;

      case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.DISABLE_SAFE_MODE:
        if (onExitSafeMode) onExitSafeMode();
        break;

      case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.MOVE_UP:
        if (treeItems && selectedNode && onMoveNodeUp) {
          const sourceNode = findPortalTreeViewNode<ICompanyLocationPortalTreeViewNodeData>(treeItems, selectedNode.nodeId);
          if (sourceNode?.data) {
            if (selectedNode.parentNodeId) {
              const parentNode = findPortalTreeViewNode<ICompanyLocationPortalTreeViewNodeData>(treeItems, selectedNode.parentNodeId);
              const parentParentNode = parentNode ? findPortalTreeViewNode<ICompanyLocationPortalTreeViewNodeData>(treeItems, parentNode.parent) : undefined;
              onMoveNodeUp(sourceNode.data, parentParentNode?.data ?? undefined);
            } else {
              onMoveNodeUp(sourceNode.data, undefined);
            }
          }
        }
        break;

      case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE_SITE:
      case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE_BUILDING:
      case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE_LEVEL:
      case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE_ZONE:
        if (onCreateLocation) {
          let newLocationType: A_COMPANY_LOCATION_TYPE = COMPANY_LOCATION_TYPE.SITE;
          if (key === COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE_BUILDING) newLocationType = COMPANY_LOCATION_TYPE.BUILDING;
          if (key === COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE_LEVEL) newLocationType = COMPANY_LOCATION_TYPE.LEVEL;
          if (key === COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE_ZONE) newLocationType = COMPANY_LOCATION_TYPE.ZONE;
          onCreateLocation(newLocationType, (!!selectedNode && !!companyLocations) ? (companyLocations.find((location) => location.id === selectedNode.id) ?? undefined) : undefined);
        }
        break;

      case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE_SPACE:
        if (onCreateSpace) onCreateSpace((!!selectedNode && !!companyLocations) ? (companyLocations.find((location) => location.id === selectedNode.id) ?? undefined) : undefined);
        break;

      case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.DELETE:
        // TODO: at some point support the deletion of multiple locations and spaces
        if (onDeleteLocation && companyLocations && selectedNode && selectedNode.nodeType === COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION) {
          const targetLocation = companyLocations.find((location) => location.id === selectedNode.id);
          if (targetLocation) {
            onDeleteLocation(targetLocation);
          }
        } else if (onDeleteSpace && companySpaces && selectedNode && selectedNode.nodeType === COMPANY_LOCATION_PTV_NODE_TYPE.SPACE) {
          const targetSpace = companySpaces.find((space) => space.id === selectedNode.id);
          if (targetSpace) {
            onDeleteSpace(targetSpace);
          }
        }
        break;

      default:
        console.warn('unhandled PortalTreeView menu item click', { menuType, treeState, key, miSelectedNodes });
    }
  }, [
    companyLocations,
    companySpaces,
    treeItems,
    onCreateLocation,
    onCreateSpace,
    onDeleteLocation,
    onDeleteSpace,
    onEnterSafeMode,
    onExitSafeMode,
    onHideRootNode,
    onMoveNodeUp,
    onShowRootNode,
  ]);


  /**
   * The treeView Context Menu items are a critical part of the user experience
   */
  const treeViewMenuItems: PortalTreeViewMenuItems<ICompanyLocationPortalTreeViewSelectedNodeIdentifier> = useMemo((
  ): PortalTreeViewMenuItems<ICompanyLocationPortalTreeViewSelectedNodeIdentifier> => buildCompanyLocationsTreeMenuItems(
    handleClickMenuItem,
  ), [handleClickMenuItem]);


  /**
   * The Add button Menu items are located in the top right of the tree for easy access
   */
  const addRecordMenuItems: PortalTreeViewMenuItems<ICompanyLocationPortalTreeViewSelectedNodeIdentifier> = useMemo((
  ): PortalTreeViewMenuItems<ICompanyLocationPortalTreeViewSelectedNodeIdentifier> => buildCompanyLocationsNewRecordMenuItems(
    handleClickMenuItem,
  ), [handleClickMenuItem]);


  /**
   * When the locations or spaces are updated, rebuild the tree data
   */
  useEffect(() => {
    // Don't do this if one of the data components is still loading
    // @note, pass in [] to either company locations or spaces if you don't want to load that data
    if (!isLoading && company && companyLocations && companySpaces) {
      setTreeItems(buildCompanyLocationsTreeItems(company, companyLocations, companySpaces, isRootNodeVisible, searchTerm));
    }
  }, [company, companyLocations, companySpaces, isLoading, searchTerm, isRootNodeVisible]);


  // Render
  return (
    <PortalTreeView<
      ICompanyLocationPortalTreeViewNodeData,
      ICompanyLocationPortalTreeViewSelectedNodeIdentifier
    >
      ref={treeViewRef}
      className={classNames('company-locations', className)}
      showHeader={showHeader}
      title={title}
      multiSelect={false}
      isRootNodeVisible={isRootNodeVisible}
      items={treeItems}
      menuItems={treeViewMenuItems}
      addRecordMenuItems={addRecordMenuItems}
      noItemsMessage={searchTerm ? 'There are no locations matching your search' : 'This company has no location data.'}
      isLoading={isLoading}
      isReadOnly={isReadOnly}
      isLocked={isLocked}
      isSafeModeEnabled={isSafeModeEnabled}
      isSearchBarVisible={isSearchBarVisible}
      error={error}
      selectedNodes={selectedNodes}
      searchTerm={searchTerm}
      onSelectedNodesChanged={onSelectedNodesChanged}
      onGetNodeIcon={handleGetNodeIcon}
      onCanDropNode={handleCanDropNode}
      onDropNode={handleDropNode}
      onStartEdit={onStartEdit}
      onEndEdit={onEndEdit}
      onEnterSafeMode={onEnterSafeMode}
      onExitSafeMode={onExitSafeMode}
      onToggleSearchBar={onToggleSearchBar}
      onSearchTermChanged={setSearchTerm}
      buildSelectedNodeIdentifier={buildCompanyLocationsPortalTreeViewSelectedNodeIdentifier}
    />
  );
};
