import React from 'react';
import { useHistory } from 'react-router';

import { DataTablePageProps } from '../../types/data-table-page.props';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';

export type ContractRegionsDataTablePageProps = DataTablePageProps;

/**
 * Display the "ContractRegions" data table as a whole page
 */
export const ContractRegionsDataTablePage: React.FC<ContractRegionsDataTablePageProps> = ({
  baseFilters,
  baseFlags,
  match,
  location,
  // availableActions,
  children,
}) => {
  const history = useHistory();
  return (
    <DataTablePage
      title="Contract Regions"
      tableIdentifier={TABLE_IDENTIFIER.CONTRACT_REGIONS_TABLE}
      baseFilters={baseFilters}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={[]}
      actionHandlers={{}}
    >
      {children}
    </DataTablePage>
  );
};
