import React from 'react';
import PropTypes from 'prop-types';
import { Container, Button } from 'reactstrap';

import SplashPageDateAndWeather from './splash-page-date-and-weather';
import { SplashPageActionButtons } from './splash-page-action-buttons';
import { ApiQueryDataLoader } from '../api-query-data-loader/api-query-data-loader';
import NotificationsList from '../notifications/notifications-card';
import NewsArticleTeaserList from '../news/news-article-teaser-list';
import { documentTitle } from '../../utils/helpers';
import { ScrollToTopOnMount } from '../router/scroll-to-top-on-mount';
import { DToolsUserList } from '../dtools-information/d-tools-user-list';
import { API_FILTER_OPERATION } from '../../constants/api-filter-operation.const';
import { NEWS_STATUS } from '../../constants/news-status.const';

const MAX_NEWS_ARTICLES = 7;

const FAILED_TO_LOAD_NEWS_ARTICLES_ERROR_MESSAGE = 'Unable to load news articles. Try refreshing your browser.';

/**
 * @description
 * Fired when the error message is clicked
 *
 * @param {React.SyntheticEvent} e
 */
const handleClickErrorMessage = (e) => {
  e.preventDefault();
  // refresh the page without query params
  window.location.reload(true);
};


/**
 * @description
 * Error with a button for when there was an error fetching news
 *
 * @returns {React.Component}
 */
const renderNewsFetchingError = () => <Button color="danger" onClick={handleClickErrorMessage}>{FAILED_TO_LOAD_NEWS_ARTICLES_ERROR_MESSAGE}</Button>;

/**
 * @description
 * Splash page for the Portal application
 *
 * @param {{History}} param0
 */
const SplashPage = ({ history }) => (
  <Container fluid className="splash-page">
    {documentTitle('Home')}
    <ScrollToTopOnMount />
    <div className="left-column">
      <div className="news-section">
        <SplashPageDateAndWeather />

        <ApiQueryDataLoader
          apiQueryUrl={`/news?${[
            `pagelength=${MAX_NEWS_ARTICLES}`,
            'with[]=author',
            'filter[0][field]=status_id',
            `filter[0][value]=${NEWS_STATUS.PUBLISHED}`,
            `filter[0][operation]=${API_FILTER_OPERATION.EQUALS}`,
            'sort[0][field]=published_at',
            'sort[0][direction]=desc',
          ].join('&')}`}
          render={({ hasError, isLoading, response }) => (
            <>
              {hasError && renderNewsFetchingError(history)}
              <NewsArticleTeaserList
                articles={(response && response.data) ? response.data : []}
                history={history}
                renderPlaceholders={isLoading || hasError}
                placeholderCount={MAX_NEWS_ARTICLES}
                renderFirstAsPrimary
                showMoreNewsLink
              />
            </>
          )}
        />
      </div>
    </div>
    <div className="right-column">
      <SplashPageActionButtons history={history} />
      <NotificationsList fixedHeight="300px" />
      <DToolsUserList loggedInOnly />
    </div>
  </Container>
);

SplashPage.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

export default SplashPage;
