import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';

export const NameAndDescriptionOnlyWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Item',
  fields: [
    {
      name: 'name',
      title: 'Name',
      visible: true,
      format: COLUMN_FORMAT.LONG_TEXT,
    },
    {
      name: 'description',
      title: 'Description',
      visible: true,
      format: COLUMN_FORMAT.STRIP_HTML,
    },
  ],
};
