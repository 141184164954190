import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactResizeDetector from 'react-resize-detector';
import { Input } from 'reactstrap';

/**
 * @class AutoSizingTextArea
 *
 * This is a wrapper around the reactstrap (Bootstrap) Text Area Input that
 * automatically resizes to accommodate its content.
 */
class AutoSizingTextArea extends React.Component {
  /**
   * @constructor
   */
  constructor(props) {
    super(props);

    this.textAreaRef = React.createRef();
  }


  /**
   * @inheritdoc
   */
  componentDidUpdate = () => {
    this.resizeToFitContent();
  }


  /**
   * @description
   * Fired when the input's value is changed
   */
  handleChange = (e) => {
    this.resizeToFitContent();
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(e);
    }
  }


  /**
   * @description
   * Fired by the React Resize Detector whenever the size of the container is changed.
   */
  handleResizeContainer = () => {
    this.resizeToFitContent();
  }


  /**
   * @description
   * Automatically resize the text area to fit the contents
   */
  resizeToFitContent = () => {
    if (this.textAreaRef.current) {
      const input = this.textAreaRef.current;

      // Reset the field height
      input.style.height = 'inherit';

      // Get the computed style from the dom
      const computed = window.getComputedStyle(input);

      // Calculate the height
      const height = (
        parseInt(computed.getPropertyValue('border-top-width'), 10) +
        input.scrollHeight +
        parseInt(computed.getPropertyValue('border-bottom-width'), 10)
      );

      // Set the height;
      input.style.height = `${height}px`;
    }
  }


  /**
   * @inheritdoc
   */
  render = () => {
    const { props } = this;

    // Use this de-structuring to take stuff out of the props we don't want to pass into the Input
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { className, onChange, ...textAreaProps } = props;

    return (
      <ReactResizeDetector handleWidth handleHeight onResize={this.handleResizeContainer}>
        {/* Using a React.Fragment here prevents the ReactResizeDetector from passing down a width and height prop to the input */}
        <>
          <Input
            className={classNames('auto-sizing-text-area', className)}
            type="textarea"
            onChange={this.handleChange}
            innerRef={this.textAreaRef}
            rows="3"
            cols="0"
            {...textAreaProps}
          />
        </>
      </ReactResizeDetector>
    );
  }
}

AutoSizingTextArea.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
};

AutoSizingTextArea.defaultProps = {
  className: null,
  onChange: null,
};

export default AutoSizingTextArea;
