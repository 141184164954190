/* eslint-disable key-spacing */
export const IMPACT_LEVEL = {
  NONE: 0,
  MINOR: 1,
  MODERATE: 2,
  SIGNIFICANT: 3,
  EXTENSIVE: 4,
} as const;
export type IMPACT_LEVEL = typeof IMPACT_LEVEL;
export type A_IMPACT_LEVEL = IMPACT_LEVEL[keyof IMPACT_LEVEL];

export const IMPACT_LEVEL_NAME_MAP = {
  [IMPACT_LEVEL.NONE]: 'none',
  [IMPACT_LEVEL.MINOR]: 'minor',
  [IMPACT_LEVEL.MODERATE]: 'moderate',
  [IMPACT_LEVEL.SIGNIFICANT]: 'significant',
  [IMPACT_LEVEL.EXTENSIVE]: 'extensive',
};
