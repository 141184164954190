import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';

export const InternalProjectsWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Internal Project',
  className: 'internal-projects-widget',
  isReadOnly: true,
  showAddBtn: false,
  fields: [
    {
      name: 'id',
      title: 'ID',
      visible: true,
      format: COLUMN_FORMAT.ID_LINK_SHORT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      linkRoute: '/internal-projects/internal-projects',
    },
    {
      name: 'priority',
      title: 'Priority',
      visible: true,
      format: COLUMN_FORMAT.NUMBER_SHORT,
    },
    {
      name: 'name',
      title: 'Project Name',
      visible: true,
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: '/internal-projects/internal-projects',
    },
    {
      name: 'description',
      title: 'Description',
      visible: true,
      format: COLUMN_FORMAT.STRIP_HTML,
    },
    {
      name: 'progress',
      title: 'Project Progress',
      visible: true,
      format: COLUMN_FORMAT.PROGRESS_PERCENT,
      formFieldType: FORM_FIELD_TYPE.PROGRESS_PERCENT_INPUT,
    },
    {
      name: 'estimated_hours',
      title: 'Estimated Time',
      description: "Project's estimated labour hours",
      format: COLUMN_FORMAT.DURATION_BUSINESS_TIME,
      visible: true,
    },
  ],
};
