/* eslint-disable import/prefer-default-export */
/**
 * @description
 * Determine if there is any difference between the keys/values of objA and objB
 *
 * @param {Record<PropertyKey, any>} objA
 * @param {Record<PropertyKey, any>} objB
 * @returns {boolean}
 */
export const shallowAreObjectsDifferent = (objA, objB) => {
  if (objA === null && objB !== null) return true;
  if (objA !== null && objB === null) return true;
  if (objA === null && objB === null) return false;

  const aKeys = Object.keys(objA);
  const bKeys = Object.keys(objB);
  if (aKeys.length !== bKeys.length) return true;

  if ([...new Set([...aKeys, ...bKeys])].some((key) => objA[key] !== objB[key])) return true;
  return false;
};
