import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PAGE_SIZE_DEFAULT } from '../../utils/constants';
import { updateTableSettings } from '../../actions/portal-data-table/update-settings';
import PageSizeSelect from '../page-size-select/page-size-select';

/**
 * @class PortalDataTablePageSizeSelect
 *
 * @description
 * This is a wrapper around the PageSizeSelect component specifically for
 * use with a Portal Data Table
 */
class PortalDataTablePageSizeSelect extends React.Component {
  /**
   * @description
   * Handle changing of the page size
   *
   * @param {number} newPageSize
   * @returns {void}
   */
  handlePageSizeChange = (newPageSize) => {
    const { dispatchUpdateTableSettings } = this.props;
    dispatchUpdateTableSettings({ pageSize: newPageSize, pushToHistory: true });
  };


  /**
   * @inheritdoc
   */
  render() {
    const { pageSize } = this.props;

    return (
      <PageSizeSelect
        pageSize={pageSize}
        onPageSizeChange={this.handlePageSizeChange}
        pageSizes={[5, 10, 15, 25, 50, 100, 200]}
      />
    );
  }
}

PortalDataTablePageSizeSelect.defaultProps = {
  pageSize: PAGE_SIZE_DEFAULT,
};

PortalDataTablePageSizeSelect.propTypes = {
  // Redux
  pageSize: PropTypes.number,

  // Dispatch
  dispatchUpdateTableSettings: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const tableSettings = state.tableSettings[ownProps.tableIdentifier];
  return {
    pageSize: tableSettings.pageSize,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchUpdateTableSettings: (settings) => dispatch(updateTableSettings(ownProps.tableIdentifier, settings)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PortalDataTablePageSizeSelect);
