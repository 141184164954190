import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { fullName } from '../../../helpers/full-name.helper';
import { IContactRecord } from '../../../types/contact/contact.record.interface';

interface IIrbContactSummary {
  type: string,
  contact: IContactRecord,
  setContact?: (newContactId: number) => void,
}

export const IrbContactSummary: React.FC<IIrbContactSummary> = ({ type, contact, setContact }) => (
  <Card style={{ boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)', minWidth: '20rem', maxWidth: '20rem' }} className="m-2">
    <CardHeader className={type === 'Invoice Request - Bill-To Contact' ? 'text-white bg-success' : 'text-white bg-info'}>
      <strong>{type}</strong>
    </CardHeader>
    <CardBody>
      <p>
        <Link to={`/crm/contacts/${contact.id}`}>
          {fullName(contact)}
        </Link>
        {contact.role && (
          <>
            <br />
            {contact.role}
          </>
        )}
      </p>
      <p />
      <p>
        <em>Contact Email:</em>
        <br />
        {contact.email ? (
          <a href={`mailto:${contact.email}`}>{contact.email}</a>
        ) : (
          <span className="text-gray"> - not listed - </span>
        )}
      </p>
      {contact.address_line_one && (
        <>
          <p><em>Address:</em></p>
          <p>
            {contact.address_line_one}
            {contact.address_line_two && (
              <>
                <br />
                {contact.address_line_two}
              </>
            )}
            <br />
            {contact.address_town}
            {', '}
            {contact.address_postcode}
          </p>
        </>
      )}
    </CardBody>
    {type !== 'Invoice Request - Bill-To Contact' && (
      <div style={{ position: 'absolute', bottom: '0', right: 0, padding: '0.5rem', textAlign: 'right' }}>
        <Button size="sm" onClick={() => setContact && setContact(contact.id)}>Set as Recipient</Button>
      </div>
    )}
  </Card>
);
