import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { Card, CardBody, CardText } from 'reactstrap';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../../../constants/form-field-type.const';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import { IProjectRecord } from '../../../types/project/project.record.interface';
import Icon from '../../layout-helpers/icon';
import { InMemoryTable } from '../../portal-data-table/in-memory-table';
import { APIContext } from '../../providers/api-provider';
import { IDtoolsOrderRecord } from '../../../types/integration/dtools/dtools-order-record.interface';
import { VendorOpenOrdersTable } from './vendor-open-orders-table';


interface IProjectVendorOrderTableProps {
  projectData: IProjectRecord,
  dtoolsId: string
}

export const ProjectVendorOrderTable:React.FC<IProjectVendorOrderTableProps> = ({ projectData, dtoolsId }) => {
  const { apiFetch } = useContext(APIContext);
  const dtoolsOrderAbortController = useRef<AbortController | null>(null);

  const [orderRecords, setOrderRecords] = useState<IDtoolsOrderRecord[] | null>(null);
  const [orderDataLoading, setOrderDataLoading] = useState<boolean>(false);
  const [orderLoadingError, setOrderLoadingError] = useState<boolean>(false);

  const loadProjectOrderData = useCallback(() => {
    if (dtoolsOrderAbortController.current) {
      dtoolsOrderAbortController.current.abort();
    }
    dtoolsOrderAbortController.current = apiAborter();
    setOrderLoadingError(false);
    setOrderDataLoading(true);
    apiFetch(
      `/dtools-connector/project/${dtoolsId}/purchaseorders`,
      {
        name: 'DToolsProjectOrders:loadPurchaseOrders',
        signal: dtoolsOrderAbortController?.current?.signal,
      },
    ).then((response) => {
      setOrderDataLoading(false);
      if (response.success) {
        setOrderRecords(response?.body?.data);
      } else if (!response.aborted) {
        setOrderLoadingError(true);
      }
    });

    return () => {
      if (dtoolsOrderAbortController.current) dtoolsOrderAbortController.current.abort();
    };
  }, [apiFetch, dtoolsId]);

  useEffect(() => {
    loadProjectOrderData();
    return () => {
      if (dtoolsOrderAbortController.current) {
        dtoolsOrderAbortController.current.abort();
      }
    };
  }, [dtoolsId, loadProjectOrderData]);

  return (
    <Card>
      <CardBody>
        {orderDataLoading && (
          <CardText><Icon i="rolling" /></CardText>
        )}
        {orderLoadingError ? (
          <CardText>
            Error Loading D-Tools Orders
          </CardText>
        ) : (
          <InMemoryTable<IDtoolsOrderRecord>
            title="Project Supplier Orders"
            columns={[{
              title: 'Vendor',
              name: 'Vendor',
              format: COLUMN_FORMAT.ID_NAME_OPTIONS,
              filterType: 'pickListFromRecordset',
            }, {
              name: 'OrderNumber',
              format: COLUMN_FORMAT.TEXT,
              formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
              filterType: 'pickListFromRecordset',
              title: 'Order Number',
            }, {
              title: 'Order Date',
              name: 'OrderedDate',
              format: COLUMN_FORMAT.DTOOLS_DATE,
            }, {
              title: 'Total Cost',
              name: 'TotalCost',
              format: COLUMN_FORMAT.CURRENCY,
              filterType: 'number',
            }]}
            paginate
            data={orderRecords || []}
            color="warning"
            activeColor="primary"
            renderDetailRow={(record) => (
              <tr className="bg-white">
                <td colSpan={4}>
                  <VendorOpenOrdersTable vendorItem={record} projectData={projectData} />
                </td>
              </tr>
            )}
          />
        )}
      </CardBody>
    </Card>
  );
};
