import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader } from 'reactstrap';
import { formatValueString } from '../render-functions';
import { LoadingSpinner } from '../layout-helpers/loading-spinner';
import { APIContext } from '../providers/api-provider';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { COLUMN_FORMAT } from '../../constants/column-format.const';

/**
 * ProjectTracking component for Data Table
 */
export const DToolsProjectTracking = ({ dtoolsId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { apiFetch } = useContext(APIContext);

  useEffect(() => {
    let abortController = apiAborter();

    apiFetch(
      `/dtools-connector/project/${dtoolsId}/tracking`,
      {
        name: 'DToolsProjectTracking:load',
        signal: abortController.signal,
      },
    ).then((response) => {
      if (response.success) {
        abortController = null;
        setData(response.body.data || []);
        setLoading(false);
      } else if (!response.aborted) {
        abortController = null;
        // TODO: error state?
        console.error('DTools Project Tracking Error', response.error);
        setData([]);
        setLoading(false);
      }
    });

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <LoadingSpinner />}

      {!loading && (
        <Card>
          <CardHeader className="bg-info text-white" tag="h4">
            Project Tracking
          </CardHeader>
          <div className="table-container" style={{ height: '490px', overflowY: 'auto' }}>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  {/* {{
                  Number: 'P14099QSW',
                  Name: 'Pine Rivers Magistrates Court 2',
                  UserName: 'Andy Photellis',
                  ComputerUserName: 'Andy Photellis',
                  Name1: 'Check In',
                  Date: '/Date(1543426784647)/',
                  Field: 'Total Price',
                  OldValue: '$152,177.40',
                  NewValue: '$139,472.40',
                }} */}
                  <th>UserName</th>
                  <th>Action</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {data.length < 1 && (
                  <tr>
                    <td colSpan="3">No tracking listed</td>
                  </tr>
                )}
                {data
                  .sort((a, b) => b.Date.substr(6, 13) - a.Date.substr(6, 13))
                  .map((item, index) => {
                    const key = `tracking-${item.Date}-${item.Name1}-${index}`;
                    return (
                      <React.Fragment key={key}>
                        <tr>
                          <td>{item.UserName}</td>
                          <td>{item.Name1}</td>
                          <td>{formatValueString(item.Date, COLUMN_FORMAT.DTOOLS_UTC_TIME)}</td>
                          {/* {detailed && <td>{item.Field}</td>} */}
                        </tr>
                        {(item.OldValue || item.NewValue) && (
                          <tr>
                            <td colSpan="3">
                              <p>
                                <span>
                                  <strong>Field: </strong>
                                  {item.Field}
                                </span>
                              </p>
                              <p>
                                <span>
                                  <strong>Old Value: </strong>
                                  {item.OldValue}
                                </span>
                              </p>
                              <p>
                                <span>
                                  <strong>New Value: </strong>
                                  {item.NewValue}
                                </span>
                              </p>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
              </tbody>
            </table>
            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
          </div>
        </Card>
      )}
    </>
  );
};

DToolsProjectTracking.propTypes = {
  dtoolsId: PropTypes.string.isRequired,
};
