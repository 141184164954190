import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SalesForecastTableCellGroup from './sales-forecast-table-cell-group';
import SalesForecastTableRow from './sales-forecast-table-row';
import { DATA_TYPE, buildDomDataTagId } from './sales-forecast-helpers';
import Icon from '../../layout-helpers/icon';

const SalesForecastTableProjectMonthlyDataRows = (props) => {
  const {
    monthlyFields,
    monthGroups,
    visibleMonthlyFieldCount,
    hideZeroValues,

    onClick,
    onBeginHover,
    onEndHover,

    stateId,
    salespersonId,
    projectData,
  } = props;

  return (
    !!projectData && (
      <>
        {/* No Project Monthly Data - just display an empty cell. */}
        {(!!projectData.error || !projectData.monthlyData.data.length) && (
          <SalesForecastTableRow
            className={classNames('project', 'monthly', 'data', 'no-data', 'error', { loading: projectData.isLoading })}
          >
            {monthGroups.map((monthGroup) => (
              <SalesForecastTableCellGroup
                key={`project_${salespersonId}_${stateId}_${monthGroup.key}`}
                span={visibleMonthlyFieldCount}
              />
            ))}
          </SalesForecastTableRow>
        )}

        {!projectData.error && !!projectData.summaryData.data.length && (
          <>
            {/* Project Monthly Data Fields */}
            {projectData.summaryData.data.map((project) => (
              <React.Fragment
                key={`project_${project.project_id}`}
              >

                <SalesForecastTableRow
                  key={`project_${project.project_id}_monthly_data`}
                  className={classNames('project', 'monthly', 'data', { loading: projectData.isLoading })}
                  trackHover
                  dataType={DATA_TYPE.PROJECT}
                  id={buildDomDataTagId(DATA_TYPE.PROJECT, project.state_id, project.salesperson_id, project.project_id)}
                  onBeginHover={onBeginHover}
                  onEndHover={onEndHover}
                  onClick={onClick}
                >
                  {monthGroups.map((monthGroup) => {
                    // Look for the project / month combination in the project monthly data
                    const projectMonthData = projectData.monthlyData.data
                      .find((data) => data.project_id === project.project_id && data.month === monthGroup.key);

                    // Found it; Render it.
                    if (projectMonthData) {
                      return (
                        <SalesForecastTableCellGroup
                          key={`project_${project.project_id}_${monthGroup.key}`}
                          rowKey={`project_${project.project_id}_${monthGroup.key}`}
                          hideZeroValues={hideZeroValues}
                          fields={monthlyFields}
                          data={projectMonthData}
                        />
                      );
                    }

                    // Doesn't exist - render an empty / loading cell
                    return (
                      <SalesForecastTableCellGroup
                        key={`project_${project.project_id}_${monthGroup.key}_loading`}
                        span={visibleMonthlyFieldCount}
                      >
                        {projectData.isLoading && (
                          <>
                            <Icon i="rolling" />
                            <span>Loading...</span>
                          </>
                        )}
                      </SalesForecastTableCellGroup>
                    );
                  })}
                </SalesForecastTableRow>

              </React.Fragment>
            ))}
          </>
        )}
      </>
    )
  );
};

SalesForecastTableProjectMonthlyDataRows.propTypes = {
  monthlyFields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  })).isRequired,
  monthGroups: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  stateId: PropTypes.number.isRequired,
  salespersonId: PropTypes.number.isRequired,
  projectData: PropTypes.shape({
    stateId: PropTypes.number.isRequired,
    salespersonId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    summaryData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
    monthlyData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
  }),
  visibleMonthlyFieldCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onBeginHover: PropTypes.func.isRequired,
  onEndHover: PropTypes.func.isRequired,
  hideZeroValues: PropTypes.bool.isRequired,
};

SalesForecastTableProjectMonthlyDataRows.defaultProps = {
  projectData: null,
};


export default SalesForecastTableProjectMonthlyDataRows;
