import React, { useCallback, useContext, useState } from 'react';
import { Button } from 'reactstrap';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { APIContext } from '../../providers/api-provider';
import Icon from '../../layout-helpers/icon';
import { ICON } from '../../../constants/icon.const';


interface IMyobProjectPushProps {
  onLinkComplete: () => void,
  parentId: number,
}

interface IMyobProjectPushState {
  linking: boolean,
  linkSuccess: boolean,
  qbAdding: boolean,
  addSuccess: boolean,
  sourceProjectError: string | false,
  checkingStatus: boolean,
  qbCompany: null | {
    Id: string,
    Name: string,
    DisplayName: string,
  },
  qbCompanyError?: string | null,
  qbSubCustomer?: null | {
    Id: string,
    Name: string,
    DisplayName: string,
  },
  qbSubCustomerError?: string | null,
}

export const MyobProjectPush : React.FC<IMyobProjectPushProps> = (props) => {
  const [state, setState] = useState<IMyobProjectPushState>({
    qbCompany: null,
    qbSubCustomer: null,
    linking: false,
    linkSuccess: false,
    qbAdding: false,
    addSuccess: false,
    sourceProjectError: false,
    checkingStatus: false,
  });

  const { apiFetch } = useContext(APIContext);


  /**
   * Push MYOB Project
   */
  const pushMyobProject = useCallback(() => {
    setState({
      ...state,
      linking: true,
    });
    const { onLinkComplete, parentId } = props;

    apiFetch(
      `/project/${parentId}/action/push-to-myob`,
      {
        name: 'ProjectAccountingWidgetComponent: clientCheckFetch',
        method: HTTP_METHOD.POST,
        // signal: // TODO: Abort controller?
      },
    ).then((response) => {
      if (response.success) {
        setState({
          ...state,
          linking: false,
          linkSuccess: true,
        });
        onLinkComplete();
      } else if (!response.aborted) {
        setState({
          ...state,
          linking: false,
          linkSuccess: false,
        });
        // onLinkComplete();
      }
    });
  }, [apiFetch, props, state]);

  return (
    <h4>
      Project is not in MYOB &nbsp;&nbsp;
      <Button
        color="purple"
        onClick={pushMyobProject}
      >
        Link to MYOB
        {' '}
        <Icon i={ICON.CLOUD_UPLOAD} />
      </Button>
    </h4>
  );
};
