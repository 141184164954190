import React, { useCallback } from 'react';

import { IContactRecord } from '../../types/contact/contact.record.interface';
import { NewRecordFormProps } from '../../types/poly-form/new-record-form.props';

import { APIPolyForm } from './api-poly-form';
import { FormFieldRenderer } from './form-field-renderer';

import { mapTableToForm } from '../../helpers/map-table-to-form.helper';

import contactTableInitialSettings from '../../table-definitions/contact-table';
import { COLUMN_FORMAT } from '../../constants/column-format.const';
import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { FORM_FIELD_TYPE } from '../../constants/form-field-type.const';
import { API_ACTION } from '../../constants/api-action.const';

const formFields = mapTableToForm(contactTableInitialSettings);

export const NewContactForm: React.FC<NewRecordFormProps<IContactRecord>> = (props) => {
  const {
    onFormComplete, onFormChange, initialData,
  } = props;

  /**
   * Fired when the APIPolyForm successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, id: FormFieldComponentProps['value'], newData?: Partial<IContactRecord>) => {
    if (onFormComplete) onFormComplete(success, id as IContactRecord['id'], newData as IContactRecord);
  }, [onFormComplete]);

  // Render
  return (
    <APIPolyForm
      {...contactTableInitialSettings}
      fields={formFields}
      formData={initialData ?? {}}
      permittedActions={contactTableInitialSettings.possibleActions.filter((action) => (action.name === API_ACTION.CREATE))}
      onFormComplete={handleFormComplete}
      onFormChange={onFormChange}
      isNewRecord
      isEditing
    >
      <>
        {/* TODO: change this to some kind of lookup that renders out contact name links, which, once clicked return the contact from this modal instead of creating a new one */}
        <p>Use the select box below to search for a contact before adding a new one.</p>
        <FormFieldRenderer
          id="personSearch"
          field={{
            name: '_person_search',
            formFieldType: FORM_FIELD_TYPE.CONTACT_PICKER,
            format: COLUMN_FORMAT.TEXT,
            visible: true,
            title: 'Search existing contacts',
            placeholder: 'Check to see if your contact already exists',
            isCreatable: false,
          }}
          errors={{}}
        />
        <hr />
      </>
    </APIPolyForm>
  );
};
