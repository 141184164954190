import React from 'react';
import PropTypes from 'prop-types';
import { debugLog } from '../../utils/helpers';
import { PORTAL_FUSION_CHART_DATA_PROP_TYPES, PORTAL_FUSION_CHART_DATA_URL_PROP_TYPES } from '../../prop-types/portal-fusion-charts-prop-types';
import API_PROVIDER_PROP_TYPES from '../../prop-types/api-provider-prop-types';
import { connectToAPIProvider } from '../providers/api-provider';
import { apiAborter } from '../../helpers/api-aborter.helper';

/**
 * @class
 * @name PortalFusionChartDataLoaderInner
 *
 * Container component - Loads data
 */
class PortalFusionChartDataLoaderInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedData: [],
      isLoading: true,
      hasError: false,
      error: null,
    };

    this.abortController = null;
  }


  /**
   * @inheritdoc
   */
  componentDidMount() {
    const { data } = this.props;
    this.loadResponse(data.url);
  }


  /**
   * @inheritdoc
   */
  shouldComponentUpdate(nextProps) {
    const { data: oldData } = this.props;
    const { data: newData } = nextProps;

    const { url: oldUrl } = oldData;
    const { url: newUrl } = newData;

    // reload if the query changes
    debugLog(
      '[PortalFusionChartDataLoaderInner:shouldComponentUpdate]',
      'info',
      { message: 'Deciding whether to load data...', oldUrl, newUrl },
      '🏗',
    );

    if (oldUrl !== newUrl) {
      debugLog('[PortalFusionChartDataLoaderInner:shouldComponentUpdate]', 'warn', '...Loading data', '🏗');
      this.loadResponse(newUrl);
      return false;
    }

    debugLog('[PortalFusionChartDataLoaderInner:shouldComponentUpdate]', 'success', '...No loading required', '🏗');
    return true;
  }


  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    if (this.abortController) this.abortController.abort();
  }


  /**
   * @description
   * Fire off the "onLoading" event (if bound)
   */
  notifyLoading = () => {
    const { id, data } = this.props;
    const { isLoading } = this.state;

    const { onLoading } = data;
    if (typeof onLoading === 'function') onLoading(id, isLoading);
  }


  /**
   * @description
   * Do loading of the data
   *
   * @param {string} dataUrl
   */
  loadResponse = (dataUrl) => {
    if (this.abortController) this.abortController.abort();

    // if dataUrl is empty, kill the data
    if (!dataUrl) {
      this.setState({
        isLoading: false,
        hasError: false,
        error: null,
        loadedData: [],
      }, this.notifyLoading);
      return;
    }

    this.abortController = apiAborter();
    this.setState({ isLoading: true }, async () => {
      this.notifyLoading();

      const { apiProvider: { apiFetch } } = this.props;

      const response = await apiFetch(
        dataUrl,
        {
          signal: this.abortController.signal,
        },
      );

      if (response.success) {
        this.abortController = null;

        const { data: { getReportDataFromResponse } } = this.props;

        const loadedData = typeof getReportDataFromResponse === 'function'
          ? getReportDataFromResponse(response.body)
          : response.body.data;

        this.setState({
          isLoading: false,
          hasError: false,
          error: null,
          loadedData,
        }, this.notifyLoading);
      } else if (!response.aborted) {
        this.abortController = null;

        this.setState({
          isLoading: false,
          hasError: true,
          error: response.error,
          loadedData: [],
        }, this.notifyLoading);
      }
    });
  }


  /**
   * @inheritdoc
   */
  render() {
    const { render } = this.props;
    const {
      isLoading, loadedData, hasError, error,
    } = this.state;

    // Follow Render Props design pattern
    // @see https://chrisnoring.gitbooks.io/react/content/patterns/render-props.html
    return render({
      loadedData, isLoading, hasError, error,
    });
  }
}

PortalFusionChartDataLoaderInner.propTypes = {
  id: PropTypes.string.isRequired,
  data: PORTAL_FUSION_CHART_DATA_URL_PROP_TYPES.isRequired,
  render: PropTypes.func.isRequired,
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
};


/**
 * @description
 * Loads report data if a URL is provided, otherwise uses pre-loaded data
 *  - Errors if neither data NOR URL is provided
 *  - Errors if both data AND URL are provided
 *
 * @param {{
 *  id: string,
 *  data: {
 *    url: string,
 *    onLoading?: (isLoading: boolean) => any,
 *    getReportDataFromResponse: (response: {}) => {},
 *  } | {
 *    isLoading: boolean,
 *    loadedData: {},
 *    hasError: boolean,
 *    error: any
 *  },
 *  render: ({isLoading: boolean, loadedData: {}, hasError: boolean, error: any}) => JSX.Element
 * }} param0
 */
const PortalFusionChartDataLoader = ({ id, data, render, apiProvider }) => {
  const hasDataUrl = 'url' in data;
  const alreadyHasData = 'loadedData' in data || 'isLoading' in data || 'hasError' in data || 'error' in data;

  // must have either dataUrl or data
  if (hasDataUrl && alreadyHasData) throw new TypeError('Cannot provide BOTH "loadedData" AND "dataUrl" to PortalFusionChartDataLoader');
  if (!hasDataUrl && !alreadyHasData) throw new TypeError('Must provide EITHER "loadedData" OR "dataUrl" to PortalFusionChartDataLoader');

  // data loading responsibility is elsewhere
  // assume we already have data
  if (alreadyHasData) {
    const {
      loadedData, isLoading, hasError, error,
    } = data;
    return (
      <>
        {render({
          loadedData, isLoading, hasError, error,
        })}
      </>
    );
  }

  // we need to do data loading
  // wrap in data loader
  return (
    <PortalFusionChartDataLoaderInner
      data={data}
      id={id}
      render={({
        isLoading,
        loadedData,
        hasError,
        error,
      }) => render({
        isLoading, loadedData, hasError, error,
      })}
      apiProvider={apiProvider}
    />
  );
};

PortalFusionChartDataLoader.propTypes = {
  data: PORTAL_FUSION_CHART_DATA_PROP_TYPES.isRequired,
  id: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
};

export default connectToAPIProvider(PortalFusionChartDataLoader);
