import React from 'react';

import { ConfirmActionModalResult } from '../../../../types/modal/modal-result';
import { IUploadMaintenanceChecksModalWizardPage1Data, IUploadMaintenanceChecksModalWizardState } from '../upload-maintenance-checks-data.interface';

import { FileSelectInput } from '../../../form-input/file-select-input';
import { Label } from '../../../layout-helpers/label';
import { ModalWizardPage, ModalWizardPageProps } from '../../modal-wizard-page';
import { WizardFormErrorRow } from '../../../layout-helpers/wizard-form-error-row';

import { getFieldPageDataErrors } from '../../../../helpers/get-field-page-data-errors.helper';
import { ICON } from '../../../../constants/icon.const';
import Icon from '../../../layout-helpers/icon';

// eslint-disable-next-line max-len
const templateUrl = 'https://corporateinitiatives.sharepoint.com/:x:/g/ER68PA81ua1HvaB-eP6D-dABH1jhKKA-wO7kZ2f3uukDnw?e=2dKKfr';

export const UploadMaintenanceChecksModalPage1 = (props: ModalWizardPageProps<
  IUploadMaintenanceChecksModalWizardPage1Data,
  IUploadMaintenanceChecksModalWizardState,
  ConfirmActionModalResult
>): React.ReactElement => {
  const { pageData, pageDataErrors, onPageDataFieldChanged } = props;

  /**
   * Render
   */
  return (
    <ModalWizardPage<IUploadMaintenanceChecksModalWizardPage1Data, IUploadMaintenanceChecksModalWizardState, ConfirmActionModalResult>
      {...props}
    >
      <div className="wizard-page-section">
        <div className="wizard-page-section-heading">
          <span>Step 1: Select Import File</span>
        </div>
        <div className="wizard-page-section-content">
          <p>
            Please use the
            {'  '}
            <a href={templateUrl} target="_blank" rel="noreferrer">
              <Icon i={ICON.EXCEL_DOWNLOAD} className="pr-1" />
              Import Template
            </a>
            {'  '}
            to make sure your data is correctly formatted.
          </p>
        </div>
        <div className="wizard-page-section-content">
          <div className="wizard-form">
            {/* === Schedule IMPORT Document File === */}
            <Label
              for="schedule_import_file"
              hasError={getFieldPageDataErrors(pageDataErrors, 'schedule_import_file') !== false}
            >
              Schedule import document*
            </Label>
            <FileSelectInput
              id="schedule_import_file"
              name="schedule_import_file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              value={pageData.schedule_import_file}
              onChange={onPageDataFieldChanged}
              allowMultipleFiles={false}
              hasError={getFieldPageDataErrors(pageDataErrors, 'schedule_import_file') !== false}
            />
            <WizardFormErrorRow fieldName="schedule_import_file" pageDataErrors={pageDataErrors} />
          </div>
        </div>
      </div>
    </ModalWizardPage>
  );
};
