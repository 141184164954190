import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { ICON } from '../constants/icon.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';

/**
 * Table Definition for People (Contracts) Table
 */
const contractTypesTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Contract Type',
  initialFormFocusFieldName: 'first',

  baseRoute: '/contract-type',
  // baseQueryString: 'with[]=type',

  /**
   * Reset the local storage settings if they were stored with any of these app versions
   * Add application version numbers to this list (make sure it is a PREVIOUS application
   * version number which reflects a now invalid set of settings)
   */
  resetForAppVersion: [
    '2.1.6',
    '2.7.9',
  ],

  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'id',
        'prefix',
        'name',
        'description',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'id',
        'prefix',
        'name',
        'description',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'prefix',
        'name',
        'description',
      ],
    },
  ],

  /**
   * Definition of columns and APIPolyForm fields for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Identity',
      description: 'Contract Type Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      linkRoute: '/service-and-support/admin/contract-types',
      visible: true,
      showInForm: false,
      order: 10,
      formOrder: 10,
    }, {
      name: 'prefix',
      title: 'Prefix',
      visible: true,
      order: 20,
      formOrder: 20,
    }, {
      name: 'name',
      title: 'Name',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: '/service-and-support/admin/contract-types',
      visible: true,
      order: 30,
      formOrder: 30,
    }, {
      name: 'description',
      title: 'Description',
      visible: true,
      order: 40,
      formOrder: 40,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add a Contract Type',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new Contract Type',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the contract Type',
      successMessage: 'Updated contracts',
      failMessage: 'Failed to update Contract Type',
      hideRecordActionButton: true,
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the Contract Type',
      successMessage: 'Contract deleted',
      failMessage: 'Failed to delete Contract Type',
      hideRecordActionButton: true,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Contract\'s Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'subtypes',
      title: 'Subtypes',
      description: 'Contract Subtypes',
      widgetDefinition: WIDGET_DEFINITION.CONTRACT_TYPE_SUBTYPES,
      apiRoute: (row) => {
        const baseRoute = '/contract-subtype';
        const apiQuery = [
          'with[]=status',
          'filter[0][field]=contract_type_id',
          `filter[0][operation]=${API_FILTER_OPERATION.EQUALS}`,
          `filter[0][value]=${row.id}`,
          'pagelength=1000',
        ].join('&');
        return `${baseRoute}?${apiQuery}`;
      },
    },
    {
      ...eventsTabDefault,
      description: "Contract Type's Event Audit Trail",
    },
  ],
};

export default contractTypesTableInitialSettings;
