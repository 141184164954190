import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';
import moment from 'moment';
import shortid from 'shortid';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';

import { DATE_FORMAT, VALID_DATE_INPUT_FORMATS } from '../../constants/date-format.const';
import { formatAlignClass } from '../render-functions';

export type DateInputProps = Pick<FormFieldComponentProps,
  'id' |
  'name' |
  'disabled' |
  'placeholder' |
  'className' |
  'dateFormat' |
  'useStringValue' |
  'hasError' |
  'format' |
  'inline' |
  'onChange' |
  'onKeyDown'
> & {
  value: null | Date | string,
  customInput?: React.ReactNode,

  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
  onCalendarOpen?: () => void,
  onCalendarClose?: () => void,
  onSelect?: (fieldName: string, newValue: null | Date | string) => void,
}

/**
 * Portal Date Input is a wrapper around the React Datepicker
 *
 * @see https://github.com/Hacker0x01/react-datepicker/ for documentation
 * @see https://reactdatepicker.com/ for excellent examples
 */
export const DateInput:React.FC<DateInputProps> = (props) => {
  const {
    id,
    name,
    className,
    value = null,
    disabled = false,
    placeholder,
    dateFormat,
    useStringValue,
    customInput,
    hasError,
    format,
    inline,
    onChange,
    onFocus,
    onBlur,
    onCalendarOpen,
    onCalendarClose,
    onSelect,
    onKeyDown,
  } = props;

  // So very rarely would we need to auto-complete a damn date input
  const [autoCompleteBlockingName] = useState(`${name}_${shortid.generate()}`);

  // The ReactDatePicker likes to work in date values
  const [internalValue, setInternalValue] = useState<null | Date>(null);

  // The actual value of the input
  const [rawValue, setRawValue] = useState<null | string>(null);

  // The date format needs to be converted to work with the DatePicker
  // @see https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
  const [internalDateFormat, setInternalDateFormat] = useState<undefined | string>(undefined);

  // A ref to the datepicker for performing tasks on it
  const datePickerRef = useRef<ReactDatePicker>();

  /**
   * Fired when the React Date Picker notifies the app of a change
   */
  const handleChange = useCallback((date: Date) => {
    if (onChange) {
      const parsedValue = moment(date, VALID_DATE_INPUT_FORMATS, true);
      if (parsedValue.isValid()) {
        if (useStringValue === true) {
          onChange({
            fieldName: name,
            newValue: parsedValue.format(dateFormat ?? DATE_FORMAT.YEAR_MONTH_DAY_DASHES),
          });
        } else {
          onChange({
            fieldName: name,
            newValue: parsedValue.toDate(),
          });
        }
      } else {
        onChange({
          fieldName: name,
          newValue: null,
        });
      }
    }
  }, [onChange, dateFormat, name, useStringValue]);


  /**
   * Fired when the React Date Picker has an exact date selected
   */
  const handleSelect = useCallback((date: Date | null) => {
    if (onSelect) {
      const parsedValue = moment(date, VALID_DATE_INPUT_FORMATS, true);
      if (parsedValue.isValid()) {
        if (useStringValue === true) {
          onSelect(name, parsedValue.format(dateFormat ?? DATE_FORMAT.YEAR_MONTH_DAY_DASHES));
        } else {
          onSelect(name, parsedValue.toDate());
        }
      } else {
        onSelect(name, null);
      }
    }
  }, [onSelect, dateFormat, name, useStringValue]);


  /**
   * When the input content is changed but the date value hasn't necessarily been updated
   */
  const handleChangeRaw = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    setRawValue(event.target.value);
  }, [setRawValue]);


  /**
   * When the input is focused, select all of the text already in the input
   */
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.currentTarget) {
      event.currentTarget.setSelectionRange(0, event.currentTarget.value.length);
    }

    // Forward the event
    if (onFocus) onFocus(event);
  };


  /**
   * When the input focus is lost
   */
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    // Forward the event
    if (onBlur) onBlur(event);
  };


  /**
   * When the input receives a keystroke is lost
   */
  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    // Forward the event
    if (onKeyDown) onKeyDown(event);

    if (event && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
      // Attempt to submit the raw value
      const parsedValue = moment(rawValue, VALID_DATE_INPUT_FORMATS, true);
      if (parsedValue.isValid()) {
        handleSelect(parsedValue.toDate());
      } else {
        handleSelect(null);
      }
    }

    // Close the date picker on tab
    else if (event && (event.code === 'Tab')) {
      if (datePickerRef && datePickerRef.current) {
        (datePickerRef.current as unknown as ReactDatePicker).setOpen(false);
      }
    }
  }, [handleSelect, onKeyDown, rawValue]);


  /**
   * Whenever the value changes, update the internal value
   */
  useEffect(() => {
    const parsedValue = moment(value, VALID_DATE_INPUT_FORMATS, true);
    if (parsedValue.isValid()) {
      setInternalValue(parsedValue.toDate());
    } else {
      setInternalValue(null);
    }
  }, [value]);


  /**
   * DatePicker doesn't use Moment.js date formats
   * This is a temporary work around given that it's unlikely we'd use some of the conflicting format patterns
   * but at some point it should be mapped properly.
   * @see https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
   */
  useEffect(() => {
    const newInternalDateFormat: string = dateFormat ?? DATE_FORMAT.DAY_MONTH_YEAR_SLASHES;
    setInternalDateFormat(newInternalDateFormat.replace(/D/g, 'd').replace(/Y/g, 'y'));
  }, [dateFormat]);

  /**
   * Render
   */
  return (
    <ReactDatePicker
      id={id}
      name={autoCompleteBlockingName}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={datePickerRef as any}
      wrapperClassName="date-input"
      className={classNames(
        'form-control',
        formatAlignClass(format, inline),
        className,
        {
          'form-control-danger': hasError,
        },
      )}
      selected={internalValue}
      portalId="portal_container"
      placeholderText={placeholder}
      todayButton="Today"
      autoComplete={autoCompleteBlockingName}
      dateFormat={internalDateFormat}
      disabled={disabled}

      customInput={customInput}

      popperModifiers={[
        {
          name: 'preventOverflow',
          options: {
            rootBoundary: 'viewport',
          },
        },
      ]}

      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onCalendarOpen={onCalendarOpen}
      onCalendarClose={onCalendarClose}
      onSelect={handleSelect}
      onKeyDown={handleKeyDown}
      onChangeRaw={handleChangeRaw}
    />
  );
};
