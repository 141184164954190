import React from 'react';
import moment from 'moment';
import { OpenWeatherMapService } from '../../utils/open-weather-map-service';
import { getCurrentWeather } from '../../utils/get-current-weather';
import Icon from '../layout-helpers/icon';
import { titleCase } from '../../utils/title-case';
import { DATE_FORMAT } from '../../constants/date-format.const';

/**
 * TODO: break these open into two components that are not bound to the splash page
 */
class SplashPageDateAndWeather extends React.Component {
  /**
   * @constructor
   */
  constructor(props) {
    super(props);

    this.state = {
      weather: null,
      hasError: false,
      isLoading: true,
    };

    // @note: "isMounted" is reserved by react
    this.isComponentMounted = false;
  }

  /**
   * @inheritdoc
   */
  componentDidMount() {
    this.isComponentMounted = true;
    getCurrentWeather().then(
      (weather) => {
        // do not set state if the component is no longer mounted
        if (this.isComponentMounted) this.setState({ weather, isLoading: false });
      },
      (error) => {
        // do not set state if the component is no longer mounted
        if (this.isComponentMounted) this.setState({ hasError: true, isLoading: false });
        console.error('Unable to set weather', error);
      },
    );
  }


  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    this.isComponentMounted = false;
  }


  /**
   * @inheritdoc
   */
  render() {
    const { hasError, weather, isLoading } = this.state;
    const currentDate = moment();

    return (
      <div className="splash-page-date-and-weather">
        <div className="date">
          <h3 className="full">
            {`${currentDate.format(DATE_FORMAT.LONG_DAY_NAME)}, ${currentDate.date()} ${currentDate.format(DATE_FORMAT.LONG_MONTH_NAME)}`}
          </h3>
          <h3 className="short">
            {`${currentDate.format(DATE_FORMAT.LONG_DAY_NAME)}, ${currentDate.date()}`}
          </h3>
          <div>
            {`${currentDate.year()} (Week ${currentDate.week()})`}
          </div>
        </div>
        <div className="current-weather">
          {isLoading && <Icon i="rolling" />}

          {!isLoading && !hasError && weather && (
            <>
              <div className="weather">
                <div className="temperature">
                  <span>{`${(weather.location && weather.location.city) || ''} ${weather.temp}`}</span>
                  <span>&deg;c</span>
                </div>
                <div>{titleCase(weather.description)}</div>
              </div>
              <img src={OpenWeatherMapService.weatherIconImageMap[weather.icon] || OpenWeatherMapService.iconUrl(weather.icon)} alt="weather" />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default SplashPageDateAndWeather;
