import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { DataTablePageProps } from '../../types/data-table-page.props';
import { IInternalProjectTaskRecord } from '../../types/internal-project/internal-project-task.record.interface';
import { NewRecordModalResult } from '../../types/modal/modal-result';

import { ModalContext } from '../modals/modal-context';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';


export type TasksDataTablePageProps = DataTablePageProps;

/**
 * Display the "Internal Project Tasks" data table as a whole page
 */
export const TasksDataTablePage: React.FC<TasksDataTablePageProps> = ({
  baseFilters,
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const history = useHistory();
  const { showModal } = useContext(ModalContext);

  const actionHandlers = {
    create: () => {
      showModal<NewRecordModalResult<IInternalProjectTaskRecord>>(MODAL_TYPE.NEW_INTERNAL_PROJECT_TASK, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/internal-projects/tasks/${modalResult.newRecord.id}`);
        },
      });
    },
  };

  return (
    <DataTablePage
      title="Tasks"
      tableIdentifier={TABLE_IDENTIFIER.INTERNAL_PROJECT_TASKS_TABLE}
      actionHandlers={actionHandlers}
      baseFilters={baseFilters}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      {children}
    </DataTablePage>
  );
};
