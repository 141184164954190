import React from 'react';
import PropTypes from 'prop-types';
import IntegrationPanel from './integration-panel';
import ApiConnector from './api-connector';

const KeyPayPanel = ({ status }) => (
  <IntegrationPanel title="KeyPay Api Conector" color="primary">
    <ApiConnector status={status} color="primary" />
  </IntegrationPanel>
);

KeyPayPanel.propTypes = {
  status: PropTypes.shape({
    message: PropTypes.string,
    loading: PropTypes.bool,
    connected: PropTypes.bool,
  }),
  // statusHandler: PropTypes.func,
};

KeyPayPanel.defaultProps = {
  status: {
    message: '',
    loading: true,
    connected: false,
  },
  // statusHandler: noop,
};

export default KeyPayPanel;
