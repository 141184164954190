import React from 'react';
import { PROJECT_STATUS, A_PROJECT_STATUS, PROJECT_STATUS_NAME_MAP } from '../../../constants/project-status.const';

import { IProjectRecord } from '../../../types/project/project.record.interface';
import StatusBadge from '../../data-format/status-badge';

import { CheckBox } from '../../form-input/checkbox';
import Icon from '../../layout-helpers/icon';

export type ProjectActivationOverviewProjectAdminWidgetSectionProps = {
  rowData: IProjectRecord,
}


/**
 * Render the Project Admin Widget section that presents the fields relating to project activation that aren't displayed anywhere else
 */
export const ProjectActivationOverviewProjectAdminWidgetSection:React.FC<ProjectActivationOverviewProjectAdminWidgetSectionProps> = (props) => {
  const {
    rowData,
  } = props;

  const {
    status_id: projectStatusId,
    po_saved_in_project_drive: poSavedInProjectDrive,
    as_sold_proposal_saved: asSoldProposalSaved,
    po_value_matches_as_sold: poValueMatchesAsSold,
    finance_reports_created: financeReportsCreated,
    user_audit: { activated_at: activatedAt },
    activated_by: activatedBy,
  } = rowData;

  const projectIsActivated = ([PROJECT_STATUS.ACTIVE, PROJECT_STATUS.COMPLETED] as A_PROJECT_STATUS[]).includes(projectStatusId);

  /**
   * Render
   */
  return (
    <div className="function-section">
      <h4>Project Activation</h4>

      {/* Not yet activated */}
      {!projectIsActivated && (
        <p className="text-muted">
          <Icon i="info-circle" />
          <span> This project has not yet been &apos;activated&apos;. (Current status: </span>
          <StatusBadge status={PROJECT_STATUS_NAME_MAP[projectStatusId]} short={false} />
          <span>)</span>
        </p>
      )}

      {/* Activated */}
      {projectIsActivated && (
        <>
          {activatedBy && activatedAt && (
            <p>
              <span>This project was activated by </span>
              <span>
                <strong>
                  {activatedBy.name}
                </strong>
                <span> on </span>
                <strong>
                  {activatedAt}
                </strong>
              </span>
            </p>
          )}
          {(!activatedBy || !activatedAt) && (
            <p className="text-danger">There appears to be a problem with the activation data for this project. No record of who or when it was activated could be found!</p>
          )}
          <CheckBox
            id="po_saved_in_project_drive"
            name="po_saved_in_project_drive"
            label="PO Saved in Project Drive"
            disabled
            value={poSavedInProjectDrive}
            hasError={!poSavedInProjectDrive}
          />

          <CheckBox
            id="as_sold_proposal_saved"
            name="as_sold_proposal_saved"
            label="As Sold Proposal Uploaded"
            disabled
            value={asSoldProposalSaved}
          />

          {/* PO Value Matches AS Sold Proposal */}
          <CheckBox
            id="po_value_matches_as_sold"
            name="po_value_matches_as_sold"
            label="PO Value Matches AS Sold Proposal"
            disabled
            value={poValueMatchesAsSold}
          />

          {/* Financial Summaries Exported */}
          <CheckBox
            id="finance_reports_created"
            name="finance_reports_created"
            label="Financial Summaries Exported"
            disabled
            value={financeReportsCreated}
          />
        </>
      )}

    </div>
  );
};
