import React from 'react';

import { AllowanceTypePicker } from '../components/form-input/allowance-type-picker';
import { BirthdaySelect } from '../components/form-input/birthday-select';
import { BusinessHoursInput } from '../components/form-input/business-hours-input';
import { CheckBox } from '../components/form-input/checkbox';
import { CompanyPicker } from '../components/form-input/company-picker';
import { CompanyLocationPicker } from '../components/form-input/company-location-picker';
import { CompanySpacePicker } from '../components/form-input/company-space-picker';
import { CompanySpaceTypePicker } from '../components/form-input/company-space-type-picker';
import { ConstSelect } from '../components/form-input/const-select';
import { ContactPicker } from '../components/form-input/contact-picker';
import { CurrencyInput } from '../components/form-input/currency-input';
import { DateInput } from '../components/form-input/date-input';
import { FileInput } from '../components/form-input/file-input';
import { ForecastTypePicker } from '../components/form-input/forecast-type-picker';
import { Hidden } from '../components/form-input/hidden-input';
import { HtmlInput } from '../components/form-input/html-input';
import { InternalProjectPicker } from '../components/form-input/internal-project-picker';
import { LeaveDaysInput } from '../components/form-input/leave-days';
import { LeaveTypePicker } from '../components/form-input/leave-type-picker';
import { LikelihoodInput } from '../components/form-input/likelihood-input';
import { MicrosoftADUserIdInput } from '../components/form-input/microsoft-ad-user-id-input';
import { NotEditableInput } from '../components/form-input/not-editable-input';
import { PartnerProgramPicker } from '../components/form-input/partner-program-picker';
import { PasswordInput } from '../components/form-input/password-input';
import { ProgressPercentInput } from '../components/form-input/progress-percent-input';
import { ProjectContactTypePicker } from '../components/form-input/project-contact-type-picker';
import { ProjectMaintenanceTypePicker } from '../components/form-input/project-maintenance-type-picker';
import { ProjectPicker } from '../components/form-input/project-picker';
import { ResourceTypePicker } from '../components/form-input/resource-type-picker';
import { SprintPicker } from '../components/form-input/sprint-picker';
import { StakeholderRolePicker } from '../components/form-input/stakeholder-type-picker';
import { StatePicker } from '../components/form-input/state-picker';
import { TaskTemplatePicker } from '../components/form-input/task-template-picker';
import { TextInput } from '../components/form-input/text-input';
import { UserPicker } from '../components/form-input/user-picker';
import { UserRolePicker } from '../components/form-input/user-role-picker';

import { A_FORM_FIELD_TYPE, FORM_FIELD_TYPE } from './form-field-type.const';
import { FormFieldComponentProps } from '../types/poly-form/form-field-component.props';
import { ContractTypePicker } from '../components/form-input/contract-type-picker';
import { ContractSubtypePicker } from '../components/form-input/contract-subtype-picker';
import { ContractSubtypeStatusPicker } from '../components/form-input/contract-subtype-status-picker';
import { ContractStatusPicker } from '../components/form-input/contract-status-picker';
import { ContractBillingTypePicker } from '../components/form-input/contract-billing-type-picker';
import { ContractPicker } from '../components/form-input/contract-picker';
import { MonthsTextInput } from '../components/form-input/months-text-input';
import { ContractRegionPicker } from '../components/form-input/contract-region-picker';
import { SpaceSubtypePicker } from '../components/form-input/space-subtype-picker';
import { CalculatedField } from '../components/form-input/calculated-field';
import { ServiceJobPicker } from '../components/form-input/service-job-picker';
import { TeamPicker } from '../components/form-input/team-picker';
import { ProjectForecastPicker } from '../components/form-input/project-forecast-picker';
import { ExpenseTypePicker } from '../components/form-input/expense-type-picker';
import { ReceiptPicker } from '../components/form-input/receipt-picker';
import { CommissionBatchPicker } from '../components/form-input/commission-batch-picker';
import { CommissionIterationPicker } from '../components/form-input/commission-iteration-picker';
import { CommissionBracketPicker } from '../components/form-input/commission-iteration-bracket-picker';
import { ProjectStatusPicker } from '../components/form-input/project-status-picker';

// TODO: Not happy with the any here. The FileInput is the only component holding it up from being a pure FormFieldComponentProps
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormFieldTypeComponentMap: {[K in A_FORM_FIELD_TYPE]: React.FC<FormFieldComponentProps | any>} = {
  [FORM_FIELD_TYPE.BIRTHDAY_SELECT]: BirthdaySelect,
  [FORM_FIELD_TYPE.BUSINESS_HOURS_INPUT]: BusinessHoursInput,
  [FORM_FIELD_TYPE.MONTHS_TEXT_INPUT]: MonthsTextInput,
  [FORM_FIELD_TYPE.CHECKBOX]: CheckBox,
  [FORM_FIELD_TYPE.DATE_INPUT]: DateInput,
  [FORM_FIELD_TYPE.FILE_INPUT]: FileInput,
  [FORM_FIELD_TYPE.HIDDEN]: Hidden,
  [FORM_FIELD_TYPE.LIKELIHOOD]: LikelihoodInput,
  [FORM_FIELD_TYPE.MICROSOFT_AD_USER_ID_INPUT]: MicrosoftADUserIdInput,
  [FORM_FIELD_TYPE.NOT_EDITABLE]: NotEditableInput,
  [FORM_FIELD_TYPE.PASSWORD_INPUT]: PasswordInput,
  [FORM_FIELD_TYPE.PROGRESS_PERCENT_INPUT]: ProgressPercentInput,
  [FORM_FIELD_TYPE.TEXT_INPUT]: TextInput,
  [FORM_FIELD_TYPE.HTML_INPUT]: HtmlInput,
  [FORM_FIELD_TYPE.CALCULATED]: CalculatedField,

  // All of these components derive their props from AsyncSelectComponentProps
  [FORM_FIELD_TYPE.ALLOWANCE_TYPE_PICKER]: AllowanceTypePicker,
  [FORM_FIELD_TYPE.COMPANY_PICKER]: CompanyPicker,
  [FORM_FIELD_TYPE.COMPANY_LOCATION_PICKER]: CompanyLocationPicker,
  [FORM_FIELD_TYPE.COMPANY_SPACE_PICKER]: CompanySpacePicker,
  [FORM_FIELD_TYPE.COMPANY_SPACE_TYPE_PICKER]: CompanySpaceTypePicker,
  [FORM_FIELD_TYPE.COMMISSION_BATCH_PICKER]: CommissionBatchPicker,
  [FORM_FIELD_TYPE.COMMISSION_ITERATION_PICKER]: CommissionIterationPicker,
  [FORM_FIELD_TYPE.COMMISSION_BRACKET_PICKER]: CommissionBracketPicker,
  [FORM_FIELD_TYPE.CONTACT_PICKER]: ContactPicker,
  [FORM_FIELD_TYPE.INTERNAL_PROJECT_PICKER]: InternalProjectPicker,
  [FORM_FIELD_TYPE.LEAVE_TYPE_PICKER]: LeaveTypePicker,
  [FORM_FIELD_TYPE.PARTNER_PROGRAM_PICKER]: PartnerProgramPicker,
  [FORM_FIELD_TYPE.PROJECT_CONTACT_TYPE_PICKER]: ProjectContactTypePicker,
  [FORM_FIELD_TYPE.PROJECT_MAINTENANCE_TYPE_PICKER]: ProjectMaintenanceTypePicker,
  [FORM_FIELD_TYPE.PROJECT_PICKER]: ProjectPicker,
  [FORM_FIELD_TYPE.PROJECT_STATUS_PICKER]: ProjectStatusPicker,
  [FORM_FIELD_TYPE.RESOURCE_TYPE_PICKER]: ResourceTypePicker,
  [FORM_FIELD_TYPE.SPRINT_PICKER]: SprintPicker,
  [FORM_FIELD_TYPE.STAKEHOLDER_ROLE_PICKER]: StakeholderRolePicker,
  [FORM_FIELD_TYPE.TASK_TEMPLATE_PICKER]: TaskTemplatePicker,
  [FORM_FIELD_TYPE.USER_PICKER]: UserPicker,
  [FORM_FIELD_TYPE.CONTRACT_STATUS_PICKER]: ContractStatusPicker,
  [FORM_FIELD_TYPE.CONTRACT_BILLING_TYPE_PICKER]: ContractBillingTypePicker,
  [FORM_FIELD_TYPE.CONTRACT_TYPE_PICKER]: ContractTypePicker,
  [FORM_FIELD_TYPE.CONTRACT_SUBTYPE_PICKER]: ContractSubtypePicker,
  [FORM_FIELD_TYPE.CONTRACT_SUBTYPE_STATUS_PICKER]: ContractSubtypeStatusPicker,
  [FORM_FIELD_TYPE.CONTRACT_REGION_PICKER]: ContractRegionPicker,
  [FORM_FIELD_TYPE.CONTRACT_PICKER]: ContractPicker,
  [FORM_FIELD_TYPE.SPACE_SUBTYPE_PICKER]: SpaceSubtypePicker,
  [FORM_FIELD_TYPE.SERVICE_JOB_PICKER]: ServiceJobPicker,
  [FORM_FIELD_TYPE.TEAM_PICKER]: TeamPicker,
  [FORM_FIELD_TYPE.PROJECT_FORECAST_PICKER]: ProjectForecastPicker,
  [FORM_FIELD_TYPE.EXPENSE_TYPE_PICKER]: ExpenseTypePicker,
  [FORM_FIELD_TYPE.RECEIPT_PICKER]: ReceiptPicker,

  // All of these components derive their props from ConstSelectProps
  [FORM_FIELD_TYPE.CONST_SELECT]: ConstSelect,
  [FORM_FIELD_TYPE.FORECAST_TYPE_PICKER]: ForecastTypePicker,
  [FORM_FIELD_TYPE.STATE_PICKER]: StatePicker,
  [FORM_FIELD_TYPE.USER_ROLE_PICKER]: UserRolePicker,

  // All of these components derive their props from NumericInputProps
  [FORM_FIELD_TYPE.CURRENCY_INPUT]: CurrencyInput,
  [FORM_FIELD_TYPE.LEAVE_DAYS_INPUT]: LeaveDaysInput,
};
