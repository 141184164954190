import React from 'react';
import { Button } from 'reactstrap';
import Icon from '../layout-helpers/icon';
import { AN_ICON, ICON } from '../../constants/icon.const';
import { A_BUTTON_COLOR, BUTTON_COLOR } from '../../constants/button-color.const';

export type ActionButtonProps = {
  name: string,
  title: string,

  className?: string,
  color: A_BUTTON_COLOR,
  icon?: AN_ICON,
  description?: string,
  disabled?: boolean,
  busy?: boolean,
  block?: boolean,
  size?: 'xs' | 'sm' | 'md' | 'lg',
  inline?: boolean,
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void,

  // Todo: create an action interface and remove these
  // eslint-disable-next-line react/no-unused-prop-types
  handleAction?: (e?: React.MouseEvent<HTMLButtonElement>) => void,
  // eslint-disable-next-line react/no-unused-prop-types
  visible?: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  label?: string,
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  name,
  title,
  className,
  color,
  description,
  disabled,
  busy,
  block,
  size,
  inline,
  icon,

  onClick,
}) => (
  <Button
    className={className}
    key={`action-button-${name}`}
    onClick={onClick}
    color={color || BUTTON_COLOR.PRIMARY}
    disabled={disabled === true}
    block={block === true}
    title={description}
    size={size || 'md'}
  >
    <Icon i={icon || ICON.ADD} isBusy={busy} />
    {(inline !== true) && <span>{title}</span>}
  </Button>
);
