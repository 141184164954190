import React from 'react';

type PreLoaderProps = {
  label: string,
  message: string,
}

export const PreLoader:React.FC<PreLoaderProps> = ({ label, message }) => (
  <div className="preloader">
    <div className="preloader-message">
      {message}
    </div>
    <div className="loader">
      <div className="loader__figure" />
      <p className="loader__label">{label}</p>
    </div>
  </div>
);
