import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { APIContext } from '../providers/api-provider';
import { buildAPIRoute } from '../../helpers/build-api-route.helper';
import Icon from '../layout-helpers/icon';
import { ICompanyRecord } from '../../types/company/company.record.interface';

const qbAppEndpoint = process.env.REACT_APP_QUICKBOOKS_APP_ENDPOINT;
const qbPath = `${qbAppEndpoint}app/customerdetail?nameId=`;


interface IQuickBooksPushFormProps {
  baseRoute: string,
  onLinkComplete: () => void,
  entityName?: 'Project' | 'Job' | 'Contract' | 'Internal Project',
  parentId: number,
  rowData: {
    id: number,
    number?: string,
    project_number?: string,
    quickbooks_subcustomer_id?: number | null,
    quickbooks_id?: number | null,
    client: ICompanyRecord,
  }
}

interface IQuickBooksPushFormState {
  linking: boolean,
  linkSuccess: boolean,
  qbAdding: boolean,
  addSuccess: boolean,
  sourceProjectError: string | false,
  checkingStatus: boolean,
  qbCompany: null | {
    Id: string,
    Name: string,
    DisplayName: string,
  },
  qbCompanyError?: string | null,
  qbSubCustomer?: null | {
    Id: string,
    Name: string,
    DisplayName: string,
  },
  qbSubCustomerError?: string | null,
}

export const QuickBooksPushForm : React.FC<IQuickBooksPushFormProps> = (props) => {
  const { entityName, rowData } = props;
  const [state, setState] = useState<IQuickBooksPushFormState>({
    qbCompany: null,
    qbSubCustomer: null,
    linking: false,
    linkSuccess: false,
    qbAdding: false,
    addSuccess: false,
    sourceProjectError: false,
    checkingStatus: false,
  });

  const { apiFetch } = useContext(APIContext);

  let entityPath = '';
  let numberIdentifierField: 'number' | 'project_number' = 'number';
  let quickbooksLinkField: 'quickbooks_id' | 'quickbooks_subcustomer_id' = 'quickbooks_id';
  let childSearchString: string | undefined = rowData?.number;

  switch (entityName) {
    case 'Contract':
      entityPath = `/contract/${rowData?.id}/action/push-to-quickbooks`;
      break;
    case 'Job':
      entityPath = `/service-job/${rowData?.id}/action/push-to-quickbooks`;
      break;
    case 'Project':
      numberIdentifierField = 'project_number';
      entityPath = '/quickbooks-connector/project';
      quickbooksLinkField = 'quickbooks_subcustomer_id';
      childSearchString = String(rowData?.id);
  }


  /**
   *
   */
  const checkQuickBooksStatus = () => {
    const client = rowData?.client || null;

    if (!client) {
      setState({
        ...state,
        sourceProjectError: `The ${entityName} does not have a valid client. Add a client to continue.`,
      });
      return;
    }

    setState({
      ...state,
      checkingStatus: true,
    });


    const clientCheckFetch = apiFetch(
      `/quickbooks-connector/client?DisplayName=${client.name}`,
      {
        name: 'ProjectAccountingWidgetComponent: clientCheckFetch',
        // signal: // TODO: Abort controller?
      },
    );

    const projectCheckFetch = apiFetch(
      `/quickbooks-connector/project?CompanyName=${childSearchString}`,
      {
        name: 'ProjectAccountingWidgetComponent: projectCheckFetch',
        // signal: // TODO: Abort controller?
      },
    );

    const promises = [];
    promises.push(projectCheckFetch);

    // only search if not already found
    if (!client.quickbooks_id) {
      promises.push(clientCheckFetch);
    }

    Promise.all(promises).then(
      ([projectCheckResponse, clientCheckResponse]) => {
        // Check the response from the Client Check
        let qbCompany = null;
        let qbCompanyError = null;
        if (clientCheckResponse && clientCheckResponse.success) {
          qbCompany = clientCheckResponse.body[0];
        } else if (clientCheckResponse && !clientCheckResponse.aborted) {
          qbCompanyError = clientCheckResponse.error;
          console.error('ProjectAccountingWidgetComponent:clientCheckFetch', clientCheckResponse.error);
        }

        // Check the response from the Project check
        let qbSubCustomer = null;
        let qbSubCustomerError = null;
        if (projectCheckResponse.success) {
          qbSubCustomer = projectCheckResponse.body[0];
        } else if (!projectCheckResponse.aborted) {
          qbSubCustomerError = projectCheckResponse.error;
          console.error('ProjectAccountingWidgetComponent:projectCheckFetch', projectCheckResponse.error);
        }

        setState({
          ...state,
          checkingStatus: false,
          qbCompany,
          qbCompanyError,
          qbSubCustomer,
          qbSubCustomerError,
        });
      },
    );
  };


  /**
   *
   */
  const linkSubCustomer = useCallback((qbSubCustomerId) => {
    setState({
      ...state,
      linking: true,
    });
    const { baseRoute, onLinkComplete, parentId } = props;

    apiFetch(
      buildAPIRoute({
        parentId,
        baseRoute,
        apiRoute: '/quickbookslink',
      }),
      {
        name: 'ProjectAccountingWidgetComponent: clientCheckFetch',
        method: HTTP_METHOD.POST,
        // signal: // TODO: Abort controller?
        body: {
          quickbooks_job_id: qbSubCustomerId,
        },
      },
    ).then((response) => {
      if (response.success) {
        setState({
          ...state,
          linking: false,
          linkSuccess: true,
        });
        onLinkComplete();
      } else if (!response.aborted) {
        setState({
          ...state,
          linking: false,
          linkSuccess: false,
          // qbSubCustomer: undefined,
          // qbSubCustomerError: response.error,
        });
        onLinkComplete();
      }
    });
  }, [apiFetch, props, state]);


  /**
   * Mount Effect
   */
  useEffect(() => {
    checkQuickBooksStatus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  /**
   *
   */
  const addSubcustomer = () => {
    setState({
      ...state,
      qbAdding: true,
    });
    const { onLinkComplete } = props;

    apiFetch(
      entityPath,
      {
        method: HTTP_METHOD.POST,
        name: 'ProjectAccountingWidgetComponent: clientCheckFetch',
        // signal: //TODO: Abort Controller?,
        body: {
          project_id: rowData.id,
        },
      },
    ).then((response) => {
      if (response.success && response.body && response.body.quickbooks_job) {
        setState(
          {
            ...state,
            qbAdding: false,
            addSuccess: true,
            qbSubCustomer: response.body.quickbooks_job,
          },
        );
        if (entityName === 'Project') {
          linkSubCustomer(response.body.quickbooks_job.Id);
        }
        onLinkComplete();
      } else if (!response.aborted) {
        console.error('ProjectAccountingWidgetComponent:addSubcustomer', response.error);
        setState({
          ...state,
          qbAdding: false,
          addSuccess: false,
          qbSubCustomer: undefined,
          qbSubCustomerError: response.error || (response.body && response.body.message) || `Failed to add the ${entityName} to Quickbooks.`,
        });
      }
    });
  };

  const { checkingStatus } = state;

  return (
    <div>
      {rowData && rowData[quickbooksLinkField] && (
        <h5>
          {`${entityName} is linked`}
           &nbsp;&nbsp;
          <Button
            href={`${qbPath}${rowData[quickbooksLinkField]}`}
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
          >
            View in QuickBooks
            {' '}
            <Icon i="external-link" />
          </Button>
        </h5>
      )}
      {(rowData && !rowData[quickbooksLinkField]) && (
        <div>
          <h4>{`${entityName} is not linked to QuickBooks`}</h4>
          <div className="mt-2">
            <strong>Client Name: </strong>
          </div>
          <div className="mt-2">
            {state.qbCompanyError && (
              <span className="text-danger">
                {`Company Search Failed: ${state.qbCompanyError}`}
              </span>
            )}
            {rowData.client && rowData.client.quickbooks_id && (
              <div>
                <p>
                  {`${rowData.client.name} is already in QuickBooks. ID: ${rowData.client.quickbooks_id} `}
                </p>
                <p>
                  <a
                    href={`${qbPath}${rowData.client.quickbooks_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      {'View in QuickBooks '}
                    </span>
                    <Icon i="external-link" />
                  </a>
                </p>
              </div>
            )}
            {!checkingStatus && !state.qbCompanyError && state.qbCompany && (
              <div>
                <p>
                  {`Found as ${state.qbCompany?.DisplayName} in QuickBooks. ID: ${state.qbCompany?.Id} `}
                </p>
                <p>
                  <a
                    href={`${qbPath}${state.qbCompany?.Id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      {'View in QuickBooks '}
                    </span>
                    <Icon i="external-link" />
                  </a>
                </p>
              </div>
            )}
            {rowData.client && !rowData.client.quickbooks_id && !state.qbCompanyError && state.qbCompany === null && (
              <div>
                <span>
                  {`Searching for ${(rowData.client && rowData.client.name) || '[no client]'} in the QuickBooks database... `}
                </span>
                <Icon i="rolling" />
              </div>
            )}
            {!checkingStatus && !state.qbCompanyError && state.qbCompany === undefined && (
              <div>{`Company ${(rowData.client && rowData.client.name) || '[no client]'} does not exist in QuickBooks.`}</div>
            )}
          </div>
          <div className="mt-2">
            <strong>
              {entityName}
              {' '}
              Number:
            </strong>
          </div>
          <div className="mt-2">
            {state.qbSubCustomerError && (
              <span className="text-danger">
                {`${entityName} Search Failed: ${state.qbSubCustomerError}`}
              </span>
            )}
            {/* If the subcustomer is found, provide an option to link it */}
            {!state.qbSubCustomerError && state.qbSubCustomer && (
              <div>
                <p>
                  {`Found as ${state.qbSubCustomer.DisplayName} in QuickBooks. ID: ${state.qbSubCustomer.Id} `}
                </p>
                <Button
                  href={`${qbPath}${state.qbSubCustomer.Id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                >
                  <span>
                    {'View in QuickBooks '}
                  </span>
                  <Icon i="external-link" />
                </Button>
                {!state.linking && !state.linkSuccess && (
                  <Button color="info" className="ml-2" onClick={entityName === 'Project' ? linkSubCustomer : addSubcustomer}>
                    {`Link to ${entityName}`}
                  </Button>
                )}
                <br />
                {(state.linking || state.linkSuccess) && <Icon i="rolling" />}
                {state.linkSuccess && <span> Linked, updating table...</span>}
              </div>
            )}
            {!state.qbSubCustomerError && state.qbSubCustomer === null && (
              <div>
                <span>
                  {`Searching for ${rowData[numberIdentifierField]} in the QuickBooks database... `}
                </span>
                <Icon i="rolling" />
              </div>
            )}
            {!state.qbSubCustomerError && state.qbSubCustomer === undefined && (
              <div>
                {`${entityName} ${rowData[numberIdentifierField]} does not exist in QuickBooks.`}
                <br />
                {!state.qbAdding && !state.addSuccess && (
                  <Button color="info" size="sm" className="mt-2" onClick={addSubcustomer}>
                    Add
                  </Button>
                )}
                {(state.qbAdding || state.addSuccess) && <Icon i="rolling" />}
                {state.addSuccess && <span> Added, refreshing data...</span>}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
