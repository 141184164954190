/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import { PERMISSION_PROP_TYPES } from './permission-prop-types';
import { CURRENT_USER_DETAILS_PROP_TYPES } from './current-user-details-prop-types';
import { CURRENT_USER_ALERTS_PROP_TYPES } from './current-user-alerts-prop-types';

export const CURRENT_USER_PROVIDER_PROP_TYPES = {
  userDetails: PropTypes.shape(CURRENT_USER_DETAILS_PROP_TYPES),
  userPermissions: PropTypes.arrayOf(PropTypes.shape(PERMISSION_PROP_TYPES)),
  userAlerts: PropTypes.shape(CURRENT_USER_ALERTS_PROP_TYPES),
  userPermissionsChecksum: PropTypes.string,

  userHasPermissions: PropTypes.func.isRequired,
  refreshUserDetails: PropTypes.func.isRequired,
  refreshUserAlerts: PropTypes.func.isRequired,
  refreshUserPermissions: PropTypes.func.isRequired,
  refreshUserNotifications: PropTypes.func.isRequired,
};
