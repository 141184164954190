/**
 * List of mime types that are images and can be viewed in a <img src="" />
 */
export const IMG_MIME_TYPES = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/vnd.microsoft.icon',
  'image/tiff',
  'image/svg+xml',
];

/**
 * List of mime types that are PDFs and can be viewed in the PDF reader
 */
export const PDF_MIME_TYPES = [
  'application/pdf',
];

/**
 * Mime types to icon classes
 */
export const MIME_TYPE_ICON_MAP: Record<string, string> = {
  // code/text
  'text/plain': 'file-text-o',
  'application/json': 'file-code-o',
  'text/html': 'file-code-o',
  'text/css': 'file-code-o',
  'application/javascript': 'file-code-o',
  'application/xml': 'file-code-o',
  // video/audio
  'application/x-shockwave-flash': 'file-video-o',
  'video/quicktime': 'file-video-o',
  'video/x-flv': 'file-video-o',
  'audio/mpeg': 'file-audio-o',
  // archive
  'application/zip': 'file-archive-o',
  'application/x-msdownload': 'file-archive-o',
  'application/x-rar-compressed': 'file-archive-o',
  'application/vnd.ms-cab-compressed': 'file-archive-o',
  // image
  'image/jpeg': 'file-image-o',
  'image/png': 'file-image-o',
  'image/gif': 'file-image-o',
  'image/bmp': 'file-image-o',
  'image/vnd.microsoft.icon': 'file-image-o',
  'image/tiff': 'file-image-o',
  'image/svg+xml': 'file-image-o',
  'image/vnd.adobe.photoshop': 'file-image-o',
  'application/postscript': 'file-image-o',
  // office/pdf
  'application/pdf': 'file-pdf-o',
  'application/rtf': 'file-word-o',
  'application/msword': 'file-word-o',
  'application/vnd.oasis.opendocument.text': 'file-word-o',
  'application/vnd.ms-excel': 'file-excel-o',
  'application/vnd.oasis.opendocument.spreadsheet': 'file-excel-o',
  'application/vnd.ms-powerpoint': 'file-powerpoint-o',
};


/**
 * Return icon string that matches the mimeType, otherwise returns `'file-o'`
 *
 * @example
 * mimeIcon(item.content_type)
 * // > 'file-powerpoint-o'
 *
 * @returns {string} the icon name without 'fa-
 */
export const getMimeTypeIcon = (mimeType: null | string): string => (mimeType && MIME_TYPE_ICON_MAP[mimeType] ? MIME_TYPE_ICON_MAP[mimeType] : 'file-o');


/**
 * Returns true if mime type is an image that could be viewed inside an <img /> tag
 */
export const isImageMimeType = (mimeType: null | string): boolean => !!mimeType && IMG_MIME_TYPES.includes(mimeType);


/**
 * Returns true if mime type is a PDF document that could be viewed inside the PDF viewer
 */
export const isPDFMimeType = (mimeType: null | string): boolean => !!mimeType && PDF_MIME_TYPES.includes(mimeType);


/**
 * Returns a color to apply to a mime type icon if it is more easily recognisable by a color
 */
export const getMimeTypeIconColor = (mimeType: null | string): string | undefined => {
  const iconType = getMimeTypeIcon(mimeType);
  switch (iconType) {
    case 'file-excel-o':
      return '#1D6F42';
    case 'file-word-o':
      return '#2B579A';
    case 'file-pdf-o':
      return '#B20B01';
  }
  return undefined;
};
