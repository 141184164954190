import React from 'react';
import classnames from 'classnames';
import FriendlyFormMessage from '../../layout-helpers/friendly-form-message';
import { FormButtons } from '../form-buttons';
import { ProjectCard } from '../../project-card-view/project-card';
import { FormRendererProps } from '../../../types/poly-form/form-renderer.props';
import { ICardItem } from '../../../types/cardview/card-item.interface';

export type ProjectCardFormRendererProps = FormRendererProps & {
  formData: ICardItem
}

/**
 * <ProjectCardFormRenderer />
 * @param {*} props
 */
const ProjectCardFormRenderer:React.FC<ProjectCardFormRendererProps> = (props) => {
  const {
    primaryKeyFieldName,
    children,
    isBusy,
    isLocked,
    isEditing,
    isReadOnly,
    isNewRecord,
    hasErrors,
    hasSuccess,
    formMessage,
    formData,
    errors,
    formDeleteConfirmationType,
    permittedActions,
    rendererOptions,
    onFieldChange,
    startEditRecord,
    endEditRecord,
    deleteRecord,
  } = props;

  const itemPrimaryKeyValue = isNewRecord ? null : formData[primaryKeyFieldName ?? 'id'];

  /**
   * Wrap a ProjectCard as a FormRenderer to provide save and update capabilities.
   *
   * Returns <ProjectCardFormRenderer />
   */
  return (
    <ProjectCard
      {...rendererOptions}
      className={classnames('portal-form', 'datatable-inline-editor', {
        'has-errors': hasErrors,
      })}
      project={formData}
      onFieldChange={onFieldChange}
      isEditing={isEditing}
      extraButtons={(
        <FormButtons
          permittedActions={permittedActions}
          formDeleteConfirmationType={formDeleteConfirmationType}

          formIsEditing={isEditing}
          formIsReadOnly={isReadOnly}
          formIsCreating={isNewRecord ?? false}
          formIsBusy={isBusy ?? false}
          formIsLocked={isLocked}

          inline
          secondary={false}

          startEditRecord={startEditRecord ? () => startEditRecord(itemPrimaryKeyValue, formData) : undefined}
          endEditRecord={endEditRecord ? (saveChanges) => endEditRecord(saveChanges, itemPrimaryKeyValue, formData) : undefined}
          deleteRecord={deleteRecord ? () => deleteRecord(itemPrimaryKeyValue, formData) : undefined}

          deleteInEditMode
          className="btn-group"
        />
      )}
      feedbackPanel={(
        <div className="pt-4">
          <FriendlyFormMessage
            formMessage={formMessage}
            hasSuccess={hasSuccess}
            hasErrors={hasErrors}
            errors={errors}
            showList
            inline
          />
        </div>
      )}
    >
      {children}
    </ProjectCard>
  );
};

export default ProjectCardFormRenderer;
