export const FORM_FIELD_TYPE = {
  ALLOWANCE_TYPE_PICKER: 'ALLOWANCE_TYPE_PICKER',
  BIRTHDAY_SELECT: 'BIRTHDAY_SELECT',
  BUSINESS_HOURS_INPUT: 'BUSINESS_HOURS_INPUT',
  MONTHS_TEXT_INPUT: 'MONTHS_TEXT_INPUT',
  CHECKBOX: 'CHECKBOX',
  COMPANY_PICKER: 'COMPANY_PICKER',
  COMMISSION_ITERATION_PICKER: 'COMMISSION_ITERATION_PICKER',
  COMMISSION_BRACKET_PICKER: 'COMMISSION_BRACKET_PICKER',
  COMMISSION_BATCH_PICKER: 'COMMISSION_BATCH_PICKER',
  CONST_SELECT: 'CONST_SELECT',
  CONTACT_PICKER: 'CONTACT_PICKER',
  DATE_INPUT: 'DATE_INPUT',
  CURRENCY_INPUT: 'CURRENCY_INPUT',
  FILE_INPUT: 'FILE_INPUT',
  FORECAST_TYPE_PICKER: 'FORECAST_TYPE_PICKER',
  HIDDEN: 'HIDDEN',
  HTML_INPUT: 'HTML_INPUT',
  INTERNAL_PROJECT_PICKER: 'INTERNAL_PROJECT_PICKER',
  LEAVE_DAYS_INPUT: 'LEAVE_DAYS_INPUT',
  LEAVE_TYPE_PICKER: 'LEAVE_TYPE_PICKER',
  LIKELIHOOD: 'LIKELIHOOD',
  COMPANY_LOCATION_PICKER: 'COMPANY_LOCATION_PICKER',
  COMPANY_SPACE_PICKER: 'COMPANY_SPACE_PICKER',
  COMPANY_SPACE_TYPE_PICKER: 'COMPANY_SPACE_TYPE_PICKER',
  MICROSOFT_AD_USER_ID_INPUT: 'MICROSOFT_AD_USER_ID_INPUT',
  NOT_EDITABLE: 'NOT_EDITABLE',
  PARTNER_PROGRAM_PICKER: 'PARTNER_PROGRAM_PICKER',
  PASSWORD_INPUT: 'PASSWORD_INPUT',
  PROGRESS_PERCENT_INPUT: 'PROGRESS_PERCENT_INPUT',
  PROJECT_CONTACT_TYPE_PICKER: 'PROJECT_CONTACT_TYPE_PICKER',
  PROJECT_MAINTENANCE_TYPE_PICKER: 'PROJECT_MAINTENANCE_TYPE_PICKER',
  PROJECT_PICKER: 'PROJECT_PICKER',
  RESOURCE_TYPE_PICKER: 'RESOURCE_TYPE_PICKER',
  SPRINT_PICKER: 'SPRINT_PICKER',
  STAKEHOLDER_ROLE_PICKER: 'STAKEHOLDER_ROLE_PICKER',
  STATE_PICKER: 'STATE_PICKER',
  TASK_TEMPLATE_PICKER: 'TASK_TEMPLATE_PICKER',
  TEXT_INPUT: 'TEXT_INPUT',
  USER_PICKER: 'USER_PICKER',
  USER_ROLE_PICKER: 'USER_ROLE_PICKER',
  CONTRACT_STATUS_PICKER: 'CONTRACT_STATUS_PICKER',
  CONTRACT_BILLING_TYPE_PICKER: 'CONTRACT_BILLING_TYPE_PICKER',
  CONTRACT_TYPE_PICKER: 'CONTRACT_TYPE_PICKER',
  CONTRACT_SUBTYPE_PICKER: 'CONTRACT_SUBTYPE_PICKER',
  CONTRACT_SUBTYPE_STATUS_PICKER: 'CONTRACT_SUBTYPE_STATUS_PICKER',
  CONTRACT_REGION_PICKER: 'CONTRACT_REGION_PICKER',
  CONTRACT_PICKER: 'CONTRACT_PICKER',
  SPACE_SUBTYPE_PICKER: 'SPACE_SUBTYPE_PICKER',
  SERVICE_JOB_PICKER: 'SERVICE_JOB_PICKER',
  CALCULATED: 'CALCULATED',
  TEAM_PICKER: 'TEAM_PICKER',
  PROJECT_FORECAST_PICKER: 'PROJECT_FORECAST_PICKER',
  PROJECT_STATUS_PICKER: 'PROJECT_STATUS_PICKER',
  EXPENSE_TYPE_PICKER: 'EXPENSE_TYPE_PICKER',
  RECEIPT_PICKER: 'RECEIPT_PICKER',
} as const;

export type FORM_FIELD_TYPE = typeof FORM_FIELD_TYPE;
export type A_FORM_FIELD_TYPE = FORM_FIELD_TYPE[keyof FORM_FIELD_TYPE];
