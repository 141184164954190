import { RowPrivateDetailsWidget } from '../components/widgets/row-private-details-widget';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { IDataTableRecord } from '../types/portal-data-table/portal-data-table-record.interface';
import { IWidgetDefinition } from '../types/widget-definition.interface';

export const ProjectCommissionInformationWidgetDefinition: IWidgetDefinition = {
  component: RowPrivateDetailsWidget,
  formCaption: 'Project Commission Information',
  refreshWidgetDataOnParentDataChange: true,
  columns: [
    {
      name: 'eligible_for_commission',
      title: 'Eligible for commission',
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      allowNegative: true,
      format: COLUMN_FORMAT.CHECKBOX,
      description: 'Is the project eligible for commission?',
      visible: true,
      order: 1,
      formOrder: 1,
    },
    {
      name: 'as_sold_cost',
      title: 'As Sold Cost',
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
      allowNegative: true,
      format: COLUMN_FORMAT.CURRENCY,
      description: 'As Sold Cost of Project',
      visible: true,
      order: 1,
      formOrder: 1,
    },
    {
      name: 'as_sold_price',
      title: 'As Sold Price',
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
      allowNegative: true,
      format: COLUMN_FORMAT.CURRENCY,
      description: 'As Sold Price of Project',
      visible: true,
      order: 2,
      formOrder: 2,
    },
    {
      name: 'as_sold_margin',
      title: 'As Sold Margin',
      formFieldType: FORM_FIELD_TYPE.TEXT_INPUT,
      allowNegative: true,
      format: COLUMN_FORMAT.NUMBER_SHORT,
      description: 'As Sold Margin of Project',
      visible: true,
      order: 3,
      formOrder: 3,
    },
    {
      name: 'commission_iteration',
      title: 'Commission Iteration',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        key: 'name',
        sourceField: 'commission_iteration',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: (
          rowData: { id: number },
        ) => `/finance/sales-commission/commission-iterations/${rowData.id}`,
      },
      formFieldType: FORM_FIELD_TYPE.COMMISSION_ITERATION_PICKER,
      sortColumn: 'commission_iteration_id',
      formSaveField: 'commission_iteration_id',
      visible: true,
      order: 4,
      formOrder: 4,
    },
    {
      name: 'commission_bracket',
      title: 'Commission Bracket',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        key: 'id',
        sourceField: 'commission_bracket',
        format: COLUMN_FORMAT.COMMISSION_BRACKET,
      },
      formFieldType: FORM_FIELD_TYPE.COMMISSION_BRACKET_PICKER,
      sortColumn: 'commission_bracket_id',
      formSaveField: 'commission_bracket_id',
      visible: true,
      order: 5,
      formOrder: 5,
    },
  ],
};
