import React from 'react';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { IDataTableRecord } from '../../../types/portal-data-table/portal-data-table-record.interface';
import { InMemoryTable } from '../../portal-data-table/in-memory-table';
import { getVendorManufacturerModelLink } from './eta-reporting-utilities';

interface IAtRiskItemsTableProps {
  recordData: IDataTableRecord[],
  projectId?: number,
  projectManagerId?: string | number | null,
}

export const AtRiskItemsTable: React.FC<IAtRiskItemsTableProps> = ({ recordData, projectId, projectManagerId }) => (
  <InMemoryTable
    title="Items at risk of being late"
    columns={[{
      title: 'Vendor',
      name: 'vendor',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: (row: IDataTableRecord) => getVendorManufacturerModelLink(true, null, null, row.vendor, projectId, null, projectManagerId),
      filterType: 'pickListFromRecordset',
    }, {
      name: 'manufacturer',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: (row: IDataTableRecord) => getVendorManufacturerModelLink(true, null, row.manufacturer, row.vendor, projectId, null, projectManagerId),
      filterType: 'pickListFromRecordset',
      title: 'Manufacturer',
    }, {
      title: 'Model',
      name: 'model',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: (row: IDataTableRecord) => getVendorManufacturerModelLink(true, row.model, row.manufacturer, row.vendor, projectId, null, projectManagerId),
    }, {
      title: 'Qty',
      name: 'items',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      filterType: 'number',
    }, {
      title: 'Min Required Days',
      name: 'soonest_required_item',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      filterType: 'number',
    }, {
      title: 'Min Due Days',
      name: 'soonest_expected_item',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      filterType: 'number',
    }, {
      title: 'Short Description',
      name: 'short_description',
      format: COLUMN_FORMAT.LONG_TEXT,
    }]}
    paginate
    data={recordData}
    color="warning"
    activeColor="primary"
  />
);
