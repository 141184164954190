import { ApiJsonResponse } from '@corporate-initiatives/ci-portal-js-sdk';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class PortalAPIResponse<T = any> {
  public body: null | T;

  public status: null | number;

  public statusText: null | string;

  public success: boolean;

  constructor(
    public readonly apiResponse: null | ApiJsonResponse<T> = null,
    public error: null | string = null,
    public aborted: boolean = false,
  ) {
    // Re-publish a few apiResponse properties
    this.body = apiResponse?.body ?? null;
    this.status = apiResponse?.status ?? null;
    this.statusText = apiResponse?.statusText ?? null;

    // Attempt to automatically interpret any responses from the API that are in the 400 and 500 range
    if ((typeof this.status === 'number') && (this.status >= 400)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.error = this.error ?? (this.body as any)?.message ?? 'Portal API Error';
    }

    this.success = (!apiResponse || (apiResponse && apiResponse.status >= 200 && apiResponse.status < 300)) && !error;
  }
}
