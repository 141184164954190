import React from 'react';
import Highlighter from 'react-highlight-words';

import { IProjectRecord } from '../../types/project/project.record.interface';

import { ProjectNumber } from '../data-format/project-number';

export type MiniProjectSummaryProps = {
  pData: IProjectRecord,
  highlightText?: null | string,
}


/**
   * @description
   * A tiny summary of a project with Project Number, Project Name, and Client Name
   *
   * @param {{
   *  pData: {id: number, name?: string, client?: { name?: string, [index: string]: any }},
   *  highlightText: null | string,
   * }} props to render
   * @return { React.ReactNode }
   */
export const MiniProjectSummary:React.FC<MiniProjectSummaryProps> = (props) => {
  const {
    pData,
    highlightText = '',
  } = props;

  const searchWords = highlightText ? highlightText.trim().replace(/[|&;$%@"<>()+,]/g, '').split(' ') : [];

  return (
    <div className="mini-project-summary">
      <span className="project-number">
        <ProjectNumber
          pData={pData}
          linkToProject={false}
          highlightText={highlightText}
        />
      </span>
      <span className="project-name">
        <Highlighter
          highlightTag="span"
          highlightClassName="highlight-text"
          searchWords={pData.name ? searchWords : []}
          textToHighlight={pData && pData.name ? pData.name : '(No Project Name)'}
        />
      </span>
      <span className="client-name">
        <Highlighter
          highlightTag="span"
          highlightClassName="highlight-text"
          searchWords={(pData.client && pData.client.name) ? searchWords : []}
          textToHighlight={pData && pData.client && pData.client.name ? pData.client.name : '(No Client Assigned)'}
        />
      </span>
    </div>
  );
};
