import React from 'react';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import PortalFusionChart from './portal-fusion-chart';
import { FUSION_CHART_TYPES_SUPPORTED, FUSION_CHART_TYPES_MULTIPLE_SERIES } from '../../utils/fusion-chart-types';
import FUSION_CHART_DATASET_RENDER_TYPES from '../../utils/fusion-chart-dataset-render-types';
import { financialYear } from '../../helpers/financial-year-moments';
import { PROJECT_STATUS_COLOR_MAP } from '../../utils/color-maps';
import { startCase } from '../../utils/helpers';
import { DATE_FORMAT } from '../../constants/date-format.const';
import { PROJECT_STATUS_NAME_MAP } from '../../constants/project-status.const';

export const PROJECTS_BY_STATUS_BY_RELATION_VALUE_TYPE = {
  VALUE: 'value',
  COUNT: 'count',
};

export const PROJECT_BY_STATUS_BY_RELATION_RELATION_TYPE = {
  CLIENT: 'client',
  PRIMARY_CONTACT: 'primary-contact',
  OWNER: 'owner',
};

/**
 * @class ProjectsByStatusByRelation
 *
 * @description
 * This chart implements the Portal2 API's "Projects By Status" report
 *
 * Can be filtered by client or owner
 *
 * @see https://wiki.ciportal.net/books/ci-portal-developer-documentation/page/projects-by-status
 */
class ProjectsByStatusByRelation extends React.Component {
  /**
   * @inheritdoc
   *
   * @param {{}} props
   */
  constructor(props) {
    super(props);

    this.state = {
      seriesList: this.createSeriesList(props),
    };
  }


  /**
   * @inheritdoc
   */
  shouldComponentUpdate(nextProps) {
    const { valueType: oldValueType, statusOptions: oldStatusOptions, hideInactiveUsers: oldHideInactiveUsers } = this.props;
    const { valueType: newValueType, statusOptions: newStatusOptions, hideInactiveUsers: newHideInactiveUsers } = nextProps;

    if (oldValueType !== newValueType || oldStatusOptions !== newStatusOptions || oldHideInactiveUsers !== newHideInactiveUsers) {
      this.setState({ seriesList: this.createSeriesList(nextProps) });
      return false;
    }

    return true;
  }


  /**
   * @description
   * The starting date for the dashboard
   * Format: YYYY-MM-DD
   *
   * @returns {string}
   */
  get dateFrom() {
    const { dateFrom } = this.props;
    return dateFrom.format(DATE_FORMAT.YEAR_MONTH_DAY_DASHES);
  }


  /**
   * @description
   * The ending date for the dashboard
   * Format: YYYY-MM-DD
   *
   * @returns {string}
   */
  get dateTo() {
    const { dateTo } = this.props;
    return dateTo.format(DATE_FORMAT.YEAR_MONTH_DAY_DASHES);
  }


  /**
   * @description
   * Api url for the report
   *
   * @returns {string}
   */
  get dataUrl() {
    const { relationType, includeArchived, hideInactiveUsers } = this.props;
    // Note: the hide inactive users flag actually does nothing on the backend, it just tricks the chart into reloading the data
    switch (relationType) {
      case PROJECT_BY_STATUS_BY_RELATION_RELATION_TYPE.CLIENT:
        return `/report/project/byclientbystatus?include_archived=${includeArchived}&date_from=${this.dateFrom}&date_to=${this.dateTo}&hideInactiveUsers=${hideInactiveUsers}`;
      case PROJECT_BY_STATUS_BY_RELATION_RELATION_TYPE.OWNER:
        return `/report/project/byownerbystatus?include_archived=${includeArchived}&date_from=${this.dateFrom}&date_to=${this.dateTo}&hideInactiveUsers=${hideInactiveUsers}`;
      case PROJECT_BY_STATUS_BY_RELATION_RELATION_TYPE.PRIMARY_CONTACT:
        return `/report/project/byprimarycontactbystatus?include_archived=${includeArchived}&date_from=${this.dateFrom}&date_to=${this.dateTo}&hideInactiveUsers=${hideInactiveUsers}`;
      default: throw new TypeError(`Unhandled relationType "${relationType}"`);
    }
  }


  /**
   * @description
   * Create the data mapping for the chart
   *
   * @param {{}} withProps
   */
  createSeriesList = (withProps = this.props) => {
    const { statusOptions, valueType } = withProps;

    return statusOptions.map((statusOption) => ({
      name: statusOption.name || PROJECT_STATUS_NAME_MAP[statusOption.statusId],
      color: PROJECT_STATUS_COLOR_MAP[statusOption.statusId],
      filterIn: (dataItem) => dataItem.status_id === statusOption.statusId,
      whatisit: valueType === PROJECTS_BY_STATUS_BY_RELATION_VALUE_TYPE.COUNT ? 'BY COUNT!!!' : 'BY VALUE!!!',
      getValue: (dataItem) => (valueType === PROJECTS_BY_STATUS_BY_RELATION_VALUE_TYPE.COUNT ? dataItem.count : dataItem.value),
      renderAs: statusOption.renderAs || FUSION_CHART_DATASET_RENDER_TYPES.COLUMN,
      visible: statusOption.visible !== false,
    }));
  }


  /**
   * @description
   * Fired by the Fusion chart to get the labels that should be displayed along the
   * bottom row / x-axis / column etc...
   *
   * @param {object} dataItem the data item to extract an x-axis / column label
   * @returns {string}
   */
  handleGetLabel = (dataItem) => {
    const { relationType } = this.props;

    switch (relationType) {
      case PROJECT_BY_STATUS_BY_RELATION_RELATION_TYPE.CLIENT: return startCase(dataItem.client_name);
      case PROJECT_BY_STATUS_BY_RELATION_RELATION_TYPE.OWNER: return startCase(dataItem.owner_name);
      case PROJECT_BY_STATUS_BY_RELATION_RELATION_TYPE.PRIMARY_CONTACT: return startCase(dataItem.primary_contact_name);
      default: throw new TypeError(`Unhandled relationType "${relationType}"`);
    }
  }

  /**
   * @description
   * Get the colour for a data item
   *
   * @param {{}} dataItem
   * @returns {string}
   */
  handleGetDataItemColour = (dataItem) => PROJECT_STATUS_COLOR_MAP[dataItem.status_id];


  /**
   * @description
   * Get the report data from the API's response
   *
   * @returns {object}
   */
  handleGetReportDataFromResponse = (response) => {
    const { hideInactiveUsers } = this.props;
    const { data } = response;
    const userCounts = {};
    const relevantRecords = [];
    // first, get the total count for the user
    for (let i = 0; i < data.length; i += 1) {
      const statusRecord = data[i];
      if (userCounts[statusRecord.owner_name]) {
        userCounts[statusRecord.owner_name] += statusRecord.count;
      } else {
        userCounts[statusRecord.owner_name] = statusRecord.count;
      }
    }
    // now filter out the users that have no data at all
    for (let i = 0; i < data.length; i += 1) {
      const statusRecord = data[i];
      if (userCounts[statusRecord.owner_name] > 0 && (statusRecord.owner_active || !hideInactiveUsers)) {
        relevantRecords.push(statusRecord);
      }
    }
    return relevantRecords;
  }


  /**
   * @inheritdoc
   */
  render() {
    const {
      id,
      chartCaption,
      paletteColors,
      onClickDataItem,
      onClickSeriesLabel,
      onChangeSeriesVisibility,
      onLoading,
      chartType,
      valueType,
      chartSubCaption,
      chartXAxisName,
      chartYAxisName,
      showValues,
      onChartDataMapped,
    } = this.props;

    const { seriesList } = this.state;

    return (
      <PortalFusionChart
        id={id}
        paletteColors={paletteColors}
        dataMapping={{
          type: 'multi',
          chartType,
          getLabel: this.handleGetLabel,
          seriesList,
        }}
        data={{
          url: this.dataUrl,
          onLoading,
          getReportDataFromResponse: this.handleGetReportDataFromResponse,
        }}
        numberPrefix={valueType === PROJECTS_BY_STATUS_BY_RELATION_VALUE_TYPE.COUNT ? null : '$'}
        chartType={chartType}
        caption={chartCaption}
        subCaption={chartSubCaption}
        xAxisName={chartXAxisName}
        yAxisName={chartYAxisName}
        showValues={showValues}
        getLabel={this.handleGetLabel}
        getDataItemColour={this.handleGetDataItemColour}
        getReportDataFromResponse={this.handleGetReportDataFromResponse}

        onChartDataMapped={onChartDataMapped}
        onClickDataItem={onClickDataItem}
        onClickSeriesLabel={onClickSeriesLabel}
        onChangeSeriesVisibility={onChangeSeriesVisibility}
        onLoading={onLoading}
      />
    );
  }
}

ProjectsByStatusByRelation.propTypes = {
  id: PropTypes.string,
  relationType: PropTypes.oneOf(Object.values(PROJECT_BY_STATUS_BY_RELATION_RELATION_TYPE)).isRequired,
  paletteColors: PropTypes.arrayOf(PropTypes.string),
  valueType: PropTypes.oneOf(Object.values(PROJECTS_BY_STATUS_BY_RELATION_VALUE_TYPE)),
  chartCaption: PropTypes.string,
  chartSubCaption: PropTypes.string,
  chartType: PropTypes.oneOf(Object.values(FUSION_CHART_TYPES_SUPPORTED)),
  chartXAxisName: PropTypes.string,
  chartYAxisName: PropTypes.string,
  showValues: PropTypes.bool,
  includeArchived: PropTypes.bool,

  statusOptions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    statusId: PropTypes.number.isRequired,
    visible: PropTypes.bool,
    renderAs: PropTypes.oneOf(Object.values(FUSION_CHART_DATASET_RENDER_TYPES)),
  })).isRequired,


  onClickDataItem: PropTypes.func,
  onClickSeriesLabel: PropTypes.func,
  onChangeSeriesVisibility: PropTypes.func,
  onLoading: PropTypes.func,
  onChartDataMapped: PropTypes.func,

  hideInactiveUsers: PropTypes.bool,
  dateFrom: PropTypes.instanceOf(moment),
  dateTo: PropTypes.instanceOf(moment),
};

ProjectsByStatusByRelation.defaultProps = {
  id: 'chart_projects_by_status_by_relation',
  paletteColors: null,
  valueType: PROJECTS_BY_STATUS_BY_RELATION_VALUE_TYPE.VALUE,
  chartCaption: 'Projects by Status by Relation',
  chartType: FUSION_CHART_TYPES_MULTIPLE_SERIES.MULTI_SERIES_BAR_2D,
  chartSubCaption: null,
  chartXAxisName: null,
  chartYAxisName: null,
  showValues: false,
  includeArchived: true,

  onClickDataItem: null,
  onClickSeriesLabel: null,
  onChangeSeriesVisibility: null,
  onLoading: null,
  onChartDataMapped: null,

  hideInactiveUsers: true,
  dateFrom: financialYear(),
  dateTo: financialYear({ endOfYear: true }),
};


export default ProjectsByStatusByRelation;
