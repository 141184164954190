import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Icon from '../layout-helpers/icon';

/**
 * @class ColumnLinkButton
 *
 * @description
 * This button is typically used in a portal data table or user form.
 * All of the drivers for the button come from the column definition
 *
 * `linkId` (string) this is the field that the button will use as it's primary id. If this field on the row is null the button will not be rendered
 * `linkRoute` (string) this is an internal route to follow when the button is clicked. This is overridden by externalLink
 * `externalLink` (function(row) | string) can either be a function(row) or "string". If provided the button will open in a target="_blank"
 * `buttonLabel` (function(row) | string) can either be function(row) or "string" and represents the text to display inside the button
 * `buttonColor` (string) the color string to assign to the button (i.e. "green" or "primary" etc...)
 */
const ColumnLinkButton = (props) => {
  const { row, column } = props;

  // The button will not render if the linkId field is null
  const recordId = typeof column.linkId === 'string' ? row[column.linkId] : row.id;
  if (!recordId) {
    return null;
  }

  const linkRoute = 'linkRoute' in column ? `${column.linkRoute}/${recordId}` : null;
  const buttonColor = 'buttonColor' in column ? column.buttonColor : 'green';

  let buttonLabel = recordId;
  if ('buttonLabel' in column) buttonLabel = typeof column.buttonLabel === 'function' ? column.buttonLabel(row) : column.buttonLabel;

  let externalLink;
  if ('externalLink' in column) externalLink = typeof column.externalLink === 'function' ? column.externalLink(row) : column.externalLink;

  // The icon will only be added if the external link is provided
  const externalLinkIcon = externalLink ? <Icon i="external-link" /> : null;

  return (
    <Button
      className="link-button"
      size="sm"
      color={buttonColor}
      href={externalLink ? `${externalLink}` : linkRoute}
      target={externalLink ? '_blank' : null}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {buttonLabel && (
        <span>{buttonLabel}</span>
      )}
      {externalLinkIcon}
    </Button>
  );
};

ColumnLinkButton.defaultProps = {};

ColumnLinkButton.propTypes = {
  column: PropTypes.shape({
    linkId: PropTypes.string,
    linkRoute: PropTypes.string,
    externalLink: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    buttonColor: PropTypes.string,
  }).isRequired,
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default ColumnLinkButton;
