import React, { useContext } from 'react';

import { CurrentUserContext } from '../providers/current-user-provider';

import { RowDetailsWidget, RowDetailsWidgetProps } from './row-details.widget';

import { PERMISSION } from '../../constants/permissions.const';

export type InternalProjectDetailsWidgetProps = RowDetailsWidgetProps;

/**
 * Data component for Data Table which includes table head and table body
 */
export const InternalProjectDetailsWidget: React.FC<InternalProjectDetailsWidgetProps> = (props) => {
  const {
    columns,
  } = props;

  const { userHasPermissions } = useContext(CurrentUserContext);

  const showFullForm = userHasPermissions([
    PERMISSION.INTERNAL_PROJECT_ADMIN,
    PERMISSION.INTERNAL_PROJECT_EDITOR,
  ]);

  const userOnlyFields = ['name', 'description', 'external_url'];

  // <RowDetails />
  return (
    <RowDetailsWidget
      {...props}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      columns={showFullForm ? columns : (columns as Record<string, any>).filter((column: Record<string, any>) => (userOnlyFields.includes(column.name)))}
    />
  );
};
