import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PROFILE_IMG_BASE } from '../../utils/constants';

const getImageFile = (name) => {
  const imageName = (name || '')
    .toLowerCase()
    .replace(/\W/g, '_')
    .replace(/![w_]/g, '');
  return `${PROFILE_IMG_BASE}/${imageName}.jpg`;
};

const ProfileCard = ({ user }) => (
  <div className="profile-card">
    <Link to={`browse-profiles/${user.id}`} key={user.id}>
      <div className="inner-wrapper">
        <div className="image-wrapper">
          <div className="profile-image" style={{ backgroundImage: `url("${getImageFile(user.name)}")` }} />
        </div>
        <div className="detail-wrapper">
          <div className="detail-card">
            <div className="detail-row user-name">
              <span>{user.name}</span>
            </div>
            <div className="detail-row user-state-and-title">
              <span className="user-state">{user.state.acronym}</span>
              {user.title && (
                <>
                  <span className="separator">|</span>
                  <span className="user-title">{user.title}</span>
                </>
              )}
            </div>
            <div className="detail-row user-email">
              <span>{user.email}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  </div>
);

ProfileCard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    title: PropTypes.string,
    state: PropTypes.shape({
      id: PropTypes.number,
      acronym: PropTypes.string,
    }),
  }).isRequired,
};

export default ProfileCard;
