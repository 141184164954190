export const PROJECT_PHASE = {
  PROSPECTING: 1,
  QUALIFYING: 2,
  EOI: 3,
  PROPOSAL_DEVELOPMENT: 4,
  PEER_REVIEW: 5,
  PROPOSAL_SUBMITTED: 6,
  NEGOTIATION: 7,
  NOT_STARTED: 8,
  SITE_ASSESSMENT: 9,
  ENGINEERING: 10,
  PROCUREMENT: 11,
  PRECOMMISSIONING: 12,
  ROUGH_IN_AND_PREWIRE: 13,
  SUPPORT_STRUCTURES: 14,
  EQUIPMENT_INSTALL: 15,
  COMMISSIONING_AND_TESTING: 16,
  UAT: 17,
  HAND_OVER_AND_SIGNOFF: 18,
} as const;
export type PROJECT_PHASE = typeof PROJECT_PHASE;
export type A_PROJECT_PHASE = PROJECT_PHASE[keyof PROJECT_PHASE];

export const PROJECT_PHASE_NAME_MAP = {
  [PROJECT_PHASE.PROSPECTING]: 'Prospecting',
  [PROJECT_PHASE.QUALIFYING]: 'Qualifying',
  [PROJECT_PHASE.EOI]: 'EOI',
  [PROJECT_PHASE.PROPOSAL_DEVELOPMENT]: 'Proposal Development',
  [PROJECT_PHASE.PEER_REVIEW]: 'Peer Review',
  [PROJECT_PHASE.PROPOSAL_SUBMITTED]: 'Proposal Submitted',
  [PROJECT_PHASE.NEGOTIATION]: 'Negotiation',
  [PROJECT_PHASE.NOT_STARTED]: 'Not Started',
  [PROJECT_PHASE.SITE_ASSESSMENT]: 'Site Assessment',
  [PROJECT_PHASE.ENGINEERING]: 'Engineering',
  [PROJECT_PHASE.PROCUREMENT]: 'Procurement',
  [PROJECT_PHASE.PRECOMMISSIONING]: 'Precommissioning',
  [PROJECT_PHASE.ROUGH_IN_AND_PREWIRE]: 'Rough-in & Prewire',
  [PROJECT_PHASE.SUPPORT_STRUCTURES]: 'Support Structures',
  [PROJECT_PHASE.EQUIPMENT_INSTALL]: 'Equipment Install',
  [PROJECT_PHASE.COMMISSIONING_AND_TESTING]: 'Commissioning & Testing',
  [PROJECT_PHASE.UAT]: 'UAT',
  [PROJECT_PHASE.HAND_OVER_AND_SIGNOFF]: 'Hand Over & Signoff',
};
