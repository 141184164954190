import {
  CHANGE_LOWERCASE,
  CHANGE_UPPERCASE,
  DEBUG,
  DOCUMENT_MAIN_TITLE,
} from './constants';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';


/**
 * Convert text into a URL slug with hyphens
 *
 * @param {string} title Words to convert
 * @returns {string} Converted string
 *
 * @example
 * slug('{Bats} are *COOL*')
 * // > 'bats-are-cool'
 */
export const slug = (title) => title
  .replace(/[^a-zA-Z0-9 ]+/g, '')
  .replace(/\s+/g, '-')
  .toLowerCase();


/**
 * Placeholder method
 */
export const noop = () => {};


/**
 * Placeholder identity methd
 * Returns its first param - good for placeholder callbacks
 */
export const identity = (a) => a;


/**
 * Converts `string` to
 * [start case](https://en.wikipedia.org/wiki/Letter_case#Stylistic_or_specialised_usage).
 *
 * @see https://stackoverflow.com/a/6475125/2740286 original
 * @example
 * startCase('--foo-bar--')
 * // => 'Foo Bar'
 *
 * startCase('fooOfBar')
 * // => 'Foo of Bar'
 *
 * startCase("__FOO_AND_BAR'S_TV__SHOW__&_pelicans")
 * // => 'Foo and Bar's TV Show & Pelicans'
 */
export const startCase = (string) => {
  // replace dashes and underscores, then replace double spaces with single spaces
  let result = (string || '').toString().replace(/[-_]/g, ' ');
  result = result.replace(/\s\s+/g, ' ');
  // Something
  result = result.replace(
    /([^\W_]+[^\s-]*) */g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
  // Keep lowercase words like 'of', 'the' etc.
  CHANGE_LOWERCASE.forEach((lcItem) => {
    result = result.replace(new RegExp(`\\s${lcItem}\\s`, 'g'), (txt) => txt.toLowerCase());
  });
  // Keep uppercase words like 'ID', 'TV' etc.
  CHANGE_UPPERCASE.forEach((ucItem) => {
    result = result.replace(new RegExp(`\\b${ucItem}\\b`, 'g'), ucItem.toUpperCase());
  });
  // trim result and return
  return result.trim();
};


/**
 * String to camelCase
 * Naming rules for named keys:
 * - camel-case (first letter lowercase)
 * - & symbols removed
 * - spaces removed
 * - dashes removed
 * @param {*} string
 */
export const camelCase = (string) => {
  let result = string.replace(/[-_& ]/g, '');
  result = result.replace(/\s/g, '');
  result = result.trim();
  // first letter lowercase
  result[0] = result.substr(0).toLowerCase();

  // trim result and return
  return result;
};


/**
 * @description
 * Strips everything from a string other than:
 * - numbers (0 - 9)
 * - dashes (- for negatives)
 * - and periods (.)
 *
 * @example
 * onlyNums('123abc');
 * // > '123'
 *
 * onlyNums('-4567 days');
 * // > '-4567'
 *
 * onlyNums('$ 12,345.67');
 * // > '12345.67'
 *
 * onlyNums('- 1234yag5.6789 ');
 * // > '-12345.6789'
 *
 * onlyNums('-1234.56', false);
 * // > '1234.56'
 *
 * @param {string} str String of characters
 * @param {boolean} allowNegative Allow end result to have negative
 *
 * @returns {string} resulting string - NOTE: String not Number
 *   Returns 'NaN' if there are poor formatted numbers.
 */
export const onlyNums = (str, allowNegative = true) => {
  if (str && typeof str === 'string') {
    const regx = allowNegative ? /[^0-9.-]/g : /[^0-9.]/g;
    const numbersOnly = str.replace(regx, '');
    return numbersOnly;
  }
  return '';
};


/**
 * Formats a NUMBER to a AUD formatted string
 *
 * @example
 * currencyAUD(-200);
 * // > '-$200.00'
 *
 * currencyAUD(20098.9);
 * // > '$20,098.90'
 *
 * currencyAUD(12.99, 0);
 * // > '$12'
 *
 * @param {number} value
 * @param {number} [fractionDigits=2] number of fractional decimal places
 *
 * @returns {string} formatted currency value string
 */
export const currencyAUD = (value, fractionDigits = 2) => typeof value !== 'undefined' &&
  value !== null &&
  Number(value).toLocaleString('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });


/**
 * Formats a NUMBER to a AU formatted number string
 *
 * @example
 * decimalAU(-200);
 * // > '-200.00'
 *
 * decimalAU(20098.9);
 * // > '20,098.90'
 *
 * decimalAU(12.99, 0);
 * // > '12'
 *
 * @param {number} value
 * @param {number} [fractionDigits=2] number of fractional decimal places
 *
 * @returns {string} formatted dollar value string
 */
export const decimalAU = (value, fractionDigits = 2) => typeof value !== 'undefined' &&
  value !== null &&
  Number(value).toLocaleString('en-AU', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });


/**
 * @deprecated
 * Triggers a file upload browser box
 * @param {func} callback called on fake click format: callback( {array} files )
 *   passes `null` to `callback` on fail.
 * @param {string} accept accept file types, eg `'*'` or `'image/*'`
 */
export const triggerFileBrowser = (callback, accept = '*') => {
  // Fake click, otherwise will cause popup blockers to trigger
  const tempField = document.createElement('input');
  tempField.style.opacity = '0';
  tempField.setAttribute('type', 'file');
  tempField.setAttribute('name', 'file');
  tempField.setAttribute('accept', accept);
  tempField.addEventListener('change', (e) => {
    // Chrome
    if (e.path && e.path[0] && e.path[0].files) {
      callback(e.path[0].files);
      // Firefox
    }
    else if (e.target && e.target.files) {
      callback(e.target.files);
    }
    return null;
  });
  document.body.appendChild(tempField);
  tempField.click();
  document.body.removeChild(tempField);
};


/**
 * Gets possible actions and available actions and merges them into a list
 *   of buttons that are permitted for this user from what the API returned us
 * @param {object} actionsFromApi
 * @param {string[] | [permission: string]: string[]} possibleActions either
 *   1. an array of actions (keys) with their
 *     required permissions (array of values)
 *   OR
 *   2. an array of strings
 * @param {object} [actionHandlers={}] (optional) an object of key/function for handling each action
 *
 * @returns {array} key value object of objects eg.
 *   `[ { action: {}, name: 'retract', color: 'warning', ... }, ]`
 */
export const mergeActions = (actionsFromApi, possibleActions, actionHandlers = {}) => {
  const permittedActions = [];
  if (possibleActions) {
    possibleActions.forEach((possibleAction) => {
      if (actionsFromApi) {
        const action = actionsFromApi[possibleAction.name] || false;
        const actionHandler = actionHandlers[possibleAction.name] || null;
        if (action !== false) {
          const mergedAction = {
            ...possibleAction,
            action,
          };
          // Attach an optional action handler
          if (actionHandler) {
            mergedAction.handleAction = actionHandler;
          }
          permittedActions.push(mergedAction);
        }
      }
    });
  }
  return permittedActions;
};


/**
 * Console logs with a more noticable text colour.
 * This function WILL ONLY WORK IN DEBUG MODE
 * Do not use for logging to console in production because it won't output anything.
 * @param {string} [title=null]
 * @param {string} [color='info'] one of `'danger'`, `'warn'`, `'success'`, `'info'` (default)
 * @param {any} [message=null]
 * @param {string} [identifier='🏓']
 * @example
 * debugLog('Aged chart settings', 'info', this.props.chartData);
 */
export const debugLog = (title = null, color = 'info', message = '', identifier = '🏓') => {
  // Only use this if debug mode is on in env
  if (DEBUG) {
    const colouredMessage = `${title || 'Portal'}:`;
    switch (color) {
      case 'danger': {
      // red
      // eslint-disable-next-line no-console
        console.log(`%c${identifier} ${colouredMessage}`, 'color: #f62d51', message);
        break;
      }
      case 'warn': {
      // orange
      // eslint-disable-next-line no-console
        console.log(`%c${identifier} ${colouredMessage}`, 'color: #ff8f28', message);
        break;
      }
      case 'success': {
      // green
      // eslint-disable-next-line no-console
        console.log(`%c${identifier} ${colouredMessage}`, 'color: #12905c', message);
        break;
      }
      default: {
      // blue
      // eslint-disable-next-line no-console
        console.log(`%c${identifier} ${colouredMessage}`, 'color: #0091e6', message);
        break;
      }
    }
  }
};


/**
 * @description
 * Returns an object of URL search params
 *
 * @example
 * toParams('?s=yag&description=foo');
 * // > {
 * //     s: 'yag',
 * //     description: 'foo',
 * //   }
 *
 * toParams('?s=John&p=12345&pl=15&name=Sconne&yag=99');
 * // > {
 * //     s: 'John',
 * //     p: '12345',
 * //     pl: '15',
 * //     name: 'Sconne'
 * //     yag: '99',
 * //   }
 *
 * toParams('?cl=id:asc,employee,status,description');
 * // > { cl: 'id:asc,employee,status,description' }
 *
 * @param {string} search
 * @param {boolean} [undefinedToEmptyStrings=false]
 *
 * @returns {{[key: string]: string }} structure of key values of search params
 */
export const toParams = (search, undefinedToEmptyString = false) => {
  const params = {};
  /**
   * @description
   * Decode left and right side of equals
   * Converts plus into space, and decodes URI
   *
   * @param {string} s string
   *
   * @returns {string} Decoded string result
   */
  const decode = (s) => decodeURIComponent(s);
  // TODO: determine if + needs to be converted to spaces... Removed it to enhance queryString capability,
  // (to use + as a delimiter) however not sure why it was converting "+" to space in the first place,
  // or if it's even necessary...
  // const decode = s => decodeURIComponent(s.replace(/\+/g, ' '));

  // Split combinations of params and build object
  if (search && typeof search === 'string') {
    const paramCombos = search.substring(1).split('&');
    paramCombos.forEach((combo) => {
      const equalsIndex = combo.indexOf('=');
      const containsSetter = equalsIndex !== -1;
      // only split on the first "=" sign
      const match = containsSetter
        ? [
          combo.substring(0, equalsIndex),
          combo.length > (equalsIndex + 1) ? combo.substring(equalsIndex + 1, combo.length) : '',
        ] : [combo];

      const key = decode(match[0]);
      // Map if key is not blank (caused by an extra `&` in URL)
      if (key !== '') {
        if (match[1]) {
          params[key] = decode(match[1]);
        }
        else if (!containsSetter) {
          params[decode(match[0])] = true;
        }
        else {
          params[decode(match[0])] = undefinedToEmptyString ? '' : undefined;
        }
      }
    });
  }
  return params;
};


/**
 * @description
 * Turn the Query String object into a Query String
 *
 * @param {URLSearchParams | Record<string, undefined | number | string>} params
 * @returns {string}
 */
export const toQueryString = (params) => {
  if (params instanceof URLSearchParams) return params.toString();

  // add each key and value to the query string
  const fullQueryString = Object
    .entries(params)
    .reduce((runningQueryString, [nextKey, nextValue]) => {
      const prepend = (runningQueryString !== '')
        ? `${runningQueryString}&`
        : '';

      // no "value"? set "nextKey" as a flag in the URL
      if (nextValue === undefined) {
        debugLog(`toQueryString - tried to encode value of type "${typeof nextValue}". Setting key "${nextKey}" as flag (without =...)`, 'info', { params, nextKey, nextValue }, '️🗺');
        return `${prepend}${nextKey}`;
      }

      return `${prepend}${nextKey}=${nextValue}`;
    }, '');

  return fullQueryString;
};


/**
 * @description
 * Generate project api request path to list projects filtered by status and filterField.
 *
 * @param {string} filterField Filter field for search param (ie. `filter[0][field]=...`)
 * @param {string} parentId ID to filter with the field, (ie. `filter[0][value]=...`)
 * @param {A_PROJECT_STATUS} projectStatusId eg. PROJECT_STATUS.LOST, PROJECT_STATUS.ACTIVE etc.
 *
 * @returns {string} Full API path eg. `'/project?filter[0][field]=client_id&...&pagelength=100'`
 */
export const projectsWidgetFilter = (filterField, parentId, projectStatusId) => {
  const baseRoute = '/project';
  const apiQuery = [
    'with[]=phase',
    'with[]=status',
    'with[]=partnerProgram:id,name',
    'with[]=state:id,acronym',
    'with[]=client:id,name',
    'with[]=contact:id,first,last',
    'with[]=owner:id,name,initials',
    `filter[0][field]=${filterField}`,
    `filter[0][operation]=${API_FILTER_OPERATION.EQUALS}`,
    `filter[0][value]=${parentId}`,
    'filter[1][field]=status_id',
    `filter[1][operation]=${API_FILTER_OPERATION.EQUALS}`,
    `filter[1][value]=${projectStatusId}`,
    'sort[0][field]=updated_at',
    'sort[0][direction]=desc',
    'pagelength=100',
  ].join('&');
  return apiQuery ? `${baseRoute}?${apiQuery}` : baseRoute;
};


/**
 * @description
 * Returns the field name of a column for using in an API request
 * eg. column: `{ name: 'state', query: {...}, type: 'object', ...}` > `'state_id'`
 *
 * @param {object} column column info as you'd see from table data `columns`
 *
 * @returns {string} name of field used in API filter query
 */
export const realFilterField = (column) => {
  const { name, format, filterOnField, formSaveField } = column;

  // Use explicit filter field if it is set in the column
  if (filterOnField) return filterOnField;

  // Use form save field if it is set in the column
  if (formSaveField) return formSaveField;

  // fallback if not set but is an object, assume we use `{name}_id
  if (format === COLUMN_FORMAT.OBJECT) return `${name}_id`;

  // Otherwise, return name
  return column.name;
};


/**
 * @description
 * For a given column filter,
 * return the filters value to be sent to the API
 *
 * @param {string | number | boolean | Object} filterValue
 * @param {string} [valueKey='id'] the key of the id/value in the filterValue (when the filterValue is an object)
 *
 * @returns {string}
 */
export const realFilterValue = (filterValue, valueKey = 'id') => {
  // When the filter value is an object
  if (filterValue instanceof Object) {
    return filterValue[valueKey] === null ? null : String(filterValue[valueKey]);
  }

  // When the filter value is null
  if (filterValue === null) return null;

  // When the filter value is a primitive - force a string value
  return String(filterValue);
};


/**
 * @description
 * Check if sort column is explicitly defined
 *
 * @param {object} column column info as you'd see from table data `columns`
 *
 * @returns {string} name of field used in API sort query
 */
export const realSortField = (column) => {
  const { name, format, sortColumn } = column;
  if (sortColumn) return sortColumn;

  // assume use id field if it's an object
  if (format === 'object') return `${name}_id`;

  return name;
};


/**
 * @description
 * Change the document's title so it appears in the tab and in the history correctly
 *
 * @param {string} [subString1=null] string displayed first in the heading
 * @param {string} [subString2=null] string (if any) displayed second in the heading
 *
 * @returns `null`
 */
export const documentTitle = (subString1, subString2) => {
  let title = `${DOCUMENT_MAIN_TITLE}`;
  if (subString1) {
    title = `${subString1} – ${DOCUMENT_MAIN_TITLE}`;
    if (subString2) title = `${subString1} | ${subString2} – ${DOCUMENT_MAIN_TITLE}`;
  }
  if (document && document.title) document.title = title;
  return null;
};

/**
 * @description
 * Get the first element of an array of objects where the objects key is equal to a provided key
 * Returns undefined if not found
 *
 * @param {{key: string, [index: string]: any}[]} items
 * @param {string} key
 * @param {string} [keyName='key'] an optional key identifier if 'key' is not the key of the object
 * @returns {undefined | {[index: string]: any}}
 */
export const getItemByKey = (items, key, keyName = 'key') => items.find((view) => view[keyName] === key);

// TODO: remove
window.getItemByKey = getItemByKey;


/**
 * @description
 * Iterate over an array of sorted columns and determine the maximum value of sortIndex
 *
 * @param {{
 *    name: string,
 *    direction: 'asc' | 'desc',
 *    sortIndex: number
 *  }[]} sortedColumns
 */
export const getMaxSortIndex = (sortedColumns) => ((sortedColumns && sortedColumns.length) ? Math.max(...sortedColumns.map((column) => column.sortIndex || 0)) : 0);


/**
 * @description
 * Determine whether an element is currently focused
 *
 * @param {React.RefObject<T>}
 *
 * @return {boolean} true if the element is focused
 */
export const isElementFocused = (reactRef) => (
  (
    reactRef &&
      reactRef.current &&
      document.activeElement === reactRef.current
  // if not true, return false
  ) || false
);


/**
 * @description
 * Converts an object path to an array of keys
 * Those keys can be used to traverse nested object/array/combination
 *
 * @param {string} keyPath for example, "myObject.someProperty.someKey[3].someOtherKey"
 * @returns {string[]}
 */
export const traversableObjectPath = (keyPath) => {
  // convert indexes to properties
  let newKeyPath = keyPath.replace(/\[(\w+)\]/g, '.$1');

  // strip a leading dot
  newKeyPath = newKeyPath.replace(/^\./, '');

  const allKeys = newKeyPath.split('.');

  return allKeys;
};


/**
 * @name objectByString
 *
 * @description
 * Copied from objectByString, but returns an object notifying whether or not the vlaue was found
 *
 * Find a property of an object by passing in its string path
 * i.e. "myObject.someProperty.someKey[3].someOtherKey"
 * Borrowed (Stolen) from https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key/6491621
 *
 * @param { object } o the object to retrieve the property from
 * @param { string } keyPath the dot notation path of the key to look for in the object
 *
 * @returns {{value: any, found: boolean}} the value of the associated keyPath from the object
 */
export const objectByString = (o, keyPath) => {
  const allKeys = traversableObjectPath(keyPath);
  let obj = o;

  for (let i = 0, n = allKeys.length; i < n; i += 1) {
    const key = allKeys[i];
    // fail - non traversable
    if (typeof obj !== 'object' || obj === null) return { value: undefined, found: false };
    // success
    if (key in obj) obj = obj[key];
    // fail - not found
    else return { value: undefined, found: false };
  }
  return { value: obj, found: true };
};


/**
 * @name compareVersion
 *
 * @description
 * Compare two version number strings and return a number <> 0 depending on whether v1
 * is greater or less than v2
 *
 * If the number of the input version groups is different, the result is treated as
 * adding more “.0” to the smaller version for example, comparing “0.1” and “0.1.2”
 * is same as comparing “0.1.0” to “0.1.2”
 *
 * @param {string} v1 the first version to compare
 * @param {string} v2 the second version to compare
 *
 * @return {boolean | -1 | 0 | 1} false if it is an invalid comparison or -1, 0, or 1
 */
export const compareVersion = (v1, v2) => {
  // Invalid comparison
  if (typeof v1 !== 'string') return false;
  if (typeof v2 !== 'string') return false;

  const v1Parts = v1.split('.');
  const v2Parts = v2.split('.');

  const k = Math.min(v1Parts.length, v2Parts.length);
  for (let i = 0; i < k; i += 1) {
    v1Parts[i] = parseInt(v1Parts[i], 10);
    v2Parts[i] = parseInt(v2Parts[i], 10);
    if (v1Parts[i] > v2Parts[i]) return 1;
    if (v1Parts[i] < v2Parts[i]) return -1;
  }

  // Same / Match
  if (v1Parts.length === v2Parts.length) return 0;

  // Greater or Less than
  return v1Parts.length < v2Parts.length ? -1 : 1;
};


/**
 * @description
 * Returns the options corresponding to a given set of values (ids)
 *
 * useful for `react-select`'s `Select` component which (for Portal) usually requires { id: _, name: _ }
 *
 * @param {string[]} givenValues ids of the selected options
 * @param {{ id: string, name: string }[]} givenOptions
 * @returns {{ id: string, name: string }[]} options corresponding to the values
 */
export const valuesToOptions = (
  givenValues,
  givenOptions,
) => givenValues
  .map((givenValue) => givenOptions.find((givenOption) => String(givenValue) === String(givenOption.id)))
  // remove values that weren't found
  .filter((foundOption) => foundOption !== undefined);
