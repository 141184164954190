import { ProjectClientOrdersWidget } from '../components/widgets/project-client-orders.widget';

import { IProjectFileRecord } from '../types/project/project-file.record.interface';
import { IProjectOrderRecord } from '../types/project/project-order.record.interface';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';

import { COLUMN_FORMAT } from '../constants/column-format.const';
import { ICON } from '../constants/icon.const';
import { PERMISSION } from '../constants/permissions.const';
import { PROJECT_FILE_TYPE } from '../constants/project-file-type.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { API_ACTION } from '../constants/api-action.const';

export const ProjectClientOrdersWidgetDefinition: ICollectionWidgetDefinition = {
  component: ProjectClientOrdersWidget,
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Client Order',
  fields: [
    {
      name: 'project_id',
      visible: false,
      isParentId: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'order_no',
      visible: true,
      title: 'Order Number',
      format: COLUMN_FORMAT.TEXT,
    },
    {
      name: 'email_only',
      visible: true,
      title: 'Email',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      showInForm: true,
      required: true,
    },
    {
      name: 'order_total',
      visible: true,
      title: 'Value',
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
      allowNegative: true,
      format: COLUMN_FORMAT.CURRENCY,
      description: 'Order Total Value',
      showTotals: true,
    },
    {
      name: 'received',
      title: 'Received Date',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      format: COLUMN_FORMAT.LONG_DATE,
    },
    {
      name: 'activating_purchase_order_project_file',
      title: 'P.O. Document',
      icon: ICON.FILE,
      description: 'The purchase order document received from the Client.',
      visible: true,
      showInForm: true,
      formFieldType: FORM_FIELD_TYPE.FILE_INPUT,
      format: COLUMN_FORMAT.FILE,
      apiRoute: (row: IProjectFileRecord): string => `/project/${row.project_id}/file`,
      newFileData: { file_type_id: PROJECT_FILE_TYPE.ORDER },
      formSaveField: 'activating_purchase_order_project_file_id',
    },
    {
      name: 'proposal_project_file',
      title: 'Proposal Document',
      icon: ICON.FILE,
      description: 'The proposal document submitted to the client which they responded to with an order.',
      visible: true,
      showInForm: true,
      formFieldType: FORM_FIELD_TYPE.FILE_INPUT,
      format: COLUMN_FORMAT.FILE,
      apiRoute: (row: IProjectOrderRecord): string => `/project/${row.project_id}/file`,
      newFileData: { file_type_id: PROJECT_FILE_TYPE.PROPOSAL },
      formSaveField: 'proposal_project_file_id',
    },
    {
      name: 'comment',
      title: 'Comment',
      visible: true,
      format: COLUMN_FORMAT.LONG_TEXT,
    },
  ],
  widgetItemActionPermissions: {
    [API_ACTION.UPDATE]: PERMISSION.PROJECT_PURCHASE_ORDER_MAINTAINER,
    [API_ACTION.DELETE]: PERMISSION.PROJECT_PURCHASE_ORDER_MAINTAINER,
  },
};
