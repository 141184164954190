import React from 'react';
import classnames from 'classnames';
import {
  FormFeedback, Label, Col, FormText,
} from 'reactstrap';

import { APIRecord } from '../../types/api-record.interface';
import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { FormRendererProps } from '../../types/poly-form/form-renderer.props';
import { IFormFieldDefinition } from '../../types/poly-form/form-field-definition.interface';
import { IFormRendererField } from '../../types/poly-form/form-renderer-field.interface';

import { InfoTooltip } from '../info-tooltip';
import { getFieldFormattedValue, getFieldValue, getColumnClasses } from '../render-functions';

import List from '../layout-helpers/list';

import { A_FORM_FIELD_TYPE, FORM_FIELD_TYPE } from '../../constants/form-field-type.const';
import { FormFieldTypeComponentMap } from '../../constants/form-field-type-component-map.const';
import { getColumnFormatWidth } from '../../helpers/column-format-width.helper';

type FormFieldRendererProps<T extends APIRecord = APIRecord> = {
  className?: string,

  id?: IFormRendererField['id'],
  field: IFormFieldDefinition,
  formData?: T,
  parentData?: FormRendererProps['parentData'],
  errors?: FormRendererProps['errors'],

  inline?: boolean,
  isReadOnly?: boolean,
  isNewRecord?: boolean,
  formIsLocked?: boolean,
  showFieldInfo?: boolean,
  editPermission?: boolean,

  lockForm?: FormRendererProps['lockForm'],

  onDoubleClick?: (fieldName: string) => void,
  onChange?: FormRendererProps['onFieldChange'],
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => unknown,
}

export const FormFieldRenderer = <T extends APIRecord = APIRecord, >(props: FormFieldRendererProps<T>): React.ReactElement<FormFieldRendererProps<T>> => {
  const {
    className,

    id,
    field,
    formData = {} as T,
    parentData = {},
    errors = {},

    inline = false,
    isReadOnly = false,
    isNewRecord = false,
    formIsLocked = false,
    showFieldInfo = false,
    editPermission = true,

    lockForm,

    onChange,
    onDoubleClick,
    onKeyPress,

  } = props;

  // Start with a FORM_FIELD_TYPE.NOT_EDITABLE
  let finalFieldType: A_FORM_FIELD_TYPE = FORM_FIELD_TYPE.NOT_EDITABLE;

  // Override hidden fields to hidden inputs
  if (field.visible === false) {
    finalFieldType = FORM_FIELD_TYPE.HIDDEN;
  }

  // Attempt to locate the appropriate field component
  else if (
    // Leave read only fields as NOT_EDITABLE
    !isReadOnly &&

    // Leave fields users have no permission to edit as NOT_EDITABLE
    (editPermission !== false || isNewRecord)
  ) {
    // Make sure the FormFieldTypeComponentMap includes the specified field type
    if (field.formFieldType && Object.keys(FormFieldTypeComponentMap).includes(finalFieldType)) {
      finalFieldType = field.formFieldType;
    }
    // Dunno what this FormFieldType is, use the default text input
    else {
      finalFieldType = FORM_FIELD_TYPE.TEXT_INPUT;
    }
  }

  // Map the field type to the component for rendering
  const FieldComponent = FormFieldTypeComponentMap[finalFieldType] as React.FC<FormFieldComponentProps>;

  // Vertical Form is the opposite of Inline Form (for code readability)
  // Not to be confused by vertical LAYOUT forms (labels on the left, fields on tight right)
  const verticalOnly = !inline;
  const fieldErrors = errors[field.name] || errors[`${field.name}_id`] || errors[field.formSaveField ?? ''] || [];
  const hasError = fieldErrors.length > 0;
  const noErrors = !hasError;
  const formattedValue = getFieldFormattedValue(formData, field);
  const value = getFieldValue(formData, field);
  const errorClass = hasError ? 'has-danger' : '';
  const groupErrorClass = hasError ? 'has-danger' : '';
  const hiddenClass = field.visible === false ? 'd-none' : '';
  const containerClass = verticalOnly ? 'form-group' : '';
  const doubleClickable = !!onDoubleClick;

  const fieldId = id ?? field.name;
  const title = field.title ?? field.name;
  const isEditing = (!isReadOnly || (editPermission !== false && !isNewRecord));

  const inlineDataTableFieldWidth = getColumnFormatWidth(field);
  const inlineDataTableStyle: React.CSSProperties = {};
  if (inline && inlineDataTableFieldWidth) {
    inlineDataTableStyle.minWidth = inlineDataTableFieldWidth;
    inlineDataTableStyle.width = inlineDataTableFieldWidth;
  }

  // Prepare input field for output in render, <FieldComponent /> is a placeholder component
  const inputField = (
    <FieldComponent
      // TODO: eventually we want to remove the field definition property in favour of individual field props
      // @deprecated
      field={field}

      formData={formData}
      parentData={parentData}
      value={isEditing ? value : formattedValue}

      inline={inline}
      disabled={!isEditing}
      hasError={hasError}
      formIsLocked={formIsLocked}

      useStringValue={finalFieldType === FORM_FIELD_TYPE.DATE_INPUT ? true : undefined}

      lockForm={lockForm}
      onChange={onChange}
      onKeyPress={onKeyPress}

      // Because the fieldComponent is derived from the FormFieldComponentProps, we can spread the field into props here safely
      {...field}

      // These form field component properties are re-definitions or overrides of the field definition props which require some special TLC
      id={fieldId}
      name={field.name}
      title={title}
      isReadOnly={isReadOnly || !isEditing}
      placeholder={inline ? field.description || field.title : field.placeholder}
      tabIndex={isReadOnly ? -1 : undefined}
      autoComplete={!!field.autoComplete}
      autoCompleteIdentifier={field.autoComplete ? (field.autoCompleteIdentifier ?? 'on') : undefined}
      isClearable={field.isClearable !== false}
    />
  );

  // Renders <Hidden />, <TextInput />, <DateField />, <ConstSelect /> etc.
  return (
    <div
      field-type={finalFieldType}
      className={classnames(
        'field-container',
        className,
        containerClass,
        groupErrorClass,
        hiddenClass,
        {
          'double-clickable': doubleClickable,
          row: verticalOnly,
        },
      )}
      key={`${fieldId}Container`}
      onDoubleClick={onDoubleClick ? () => onDoubleClick(field.name) : undefined}
      style={inlineDataTableStyle}
    >
      {verticalOnly && (
        <Label className={classnames('control-label', errorClass)} md="4" htmlFor={fieldId}>
          <span>
            {`${title}`}
            {!isReadOnly && field.required && (
              <sup>*</sup>
            )}
          </span>
          {!isReadOnly && field.formFieldToolTip && (
            <InfoTooltip title={title} tabIndex={-1}>
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: field.formFieldToolTip }} />
            </InfoTooltip>
          )}
        </Label>
      )}
      {inline && <div title={title} className={getColumnClasses(field)}>{inputField}</div>}
      {verticalOnly && (
        <Col md="8">
          {inputField}
          {hasError && (
            <FormFeedback valid={noErrors}>
              <List items={fieldErrors} multipleOnly />
            </FormFeedback>
          )}
          {showFieldInfo && field.description && (
            <FormText color="muted">{field.description}</FormText>
          )}
        </Col>
      )}
    </div>
  );
};
