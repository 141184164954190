import React, { useCallback, useContext } from 'react';

import { CurrentUserContext } from '../providers/current-user-provider';
import { ModalContext } from '../modals/modal-context';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';
import { ISprintRecord } from '../../types/internal-project/sprint.record.interface';
import { NewRecordModalResult } from '../../types/modal/modal-result';

import AsyncSelect from './async-select';

import { PERMISSION } from '../../constants/permissions.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';

export type SprintPicketProps = AsyncSelectComponentProps;

export const SprintPicker:React.FC<SprintPicketProps> = (props) => {
  const {
    name,
    formSaveField = null,
    isCreatable = false,
    isClearable = true,
    formData = {},
    parentData = {},
    getNewItemData,
    onChange,
  } = props;

  const { showModal } = useContext(ModalContext);
  const { userHasPermissions } = useContext(CurrentUserContext);

  /**
   * Show a modal to create a new sprint
   */
  const handleCreateOption = useCallback((inputValue: string) => {
    const initialData = {
      ...(getNewItemData ? getNewItemData(inputValue, formData, parentData) : undefined),
    };

    showModal<NewRecordModalResult<ISprintRecord>>(MODAL_TYPE.NEW_SPRINT, {
      initialData,
      onModalComplete: ({ success, newRecord: newSprint }) => {
        if (success && onChange && newSprint) { onChange({
          fieldName: formSaveField || `${name}_id`,
          newValue: newSprint.id,
          objectFieldName: name,
          objectFieldNewValue: newSprint,
        }); }
      },
    });
  }, [formData, formSaveField, getNewItemData, name, onChange, parentData, showModal]);

  return (
    <AsyncSelect
      {...props}
      searchRoute="/sprint"
      loadAndKeepAll
      isClearable={isClearable}
      onCreateOption={handleCreateOption}
      // Make sure the current sprint has permissions to create a new sprint before allowing them to create a sprint
      isCreatable={isCreatable && userHasPermissions([
        PERMISSION.INTERNAL_PROJECT_EDITOR,
        PERMISSION.INTERNAL_PROJECT_ADMIN,
      ])}
    />
  );
};
