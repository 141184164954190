import React from 'react';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';

import AsyncSelect from './async-select';

export type TeamPickerProps = AsyncSelectComponentProps;

export const TeamPicker:React.FC<TeamPickerProps> = (props) => (
  <AsyncSelect
    {...props}
    searchRoute="/team"
    loadAndKeepAll
    isCreatable={false}
  />
);
