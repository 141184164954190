import React from 'react';
import classnames from 'classnames';
import { ActionButton } from '../record-action-buttons/action-button';
import { DELETE_CONFIRMATION_TYPE } from '../../constants/delete-confirmation-type.const';
import { hasPermittedAction } from '../../helpers/has-permitted-action.helper';
import { API_ACTION } from '../../constants/api-action.const';
import { FormRendererProps } from '../../types/poly-form/form-renderer.props';
import { ICON } from '../../constants/icon.const';
import { IActionButton } from '../../types/action-button.interface';
import { A_BUTTON_COLOR, BUTTON_COLOR } from '../../constants/button-color.const';

export type FormButtonsProps = Pick<FormRendererProps,
  'permittedActions' |
  'itemCaption' |
  'formDeleteConfirmationType'
> & {
  className?: string,
  buttonsClassName?: string,
  deleteInEditMode?: boolean,
  inline?: boolean,
  secondary?: boolean,
  formIsCreating: boolean,
  formIsEditing: boolean,
  formIsReadOnly: boolean,
  formIsBusy: boolean,
  formIsLocked: boolean,

  additionalButtonsBefore?: IActionButton[],
  additionalButtonsAfter?: IActionButton[],

  startEditRecord?: () => void,
  endEditRecord?: (saveChanges: boolean) => void,
  deleteRecord?: () => void,
}

/**
 * Appear in APIPolyForm and FormHeader
 */
export const FormButtons: React.FC<FormButtonsProps> = ({
  permittedActions,
  itemCaption = 'item',
  className,
  buttonsClassName,
  formIsReadOnly,
  formIsCreating,
  formIsEditing,
  formIsBusy,
  formIsLocked,
  inline,
  secondary,
  deleteInEditMode,
  formDeleteConfirmationType,

  additionalButtonsBefore = [],
  additionalButtonsAfter = [],

  startEditRecord,
  endEditRecord,
  deleteRecord,
}) => {
  // figure out which button look to use
  const buttonSize = (inline === true) || (secondary === true) ? 'sm' : 'md';
  const buttonColor: A_BUTTON_COLOR = (inline === true) || (secondary === true) ? BUTTON_COLOR.SECONDARY : BUTTON_COLOR.PRIMARY;

  // Check if the Delete button should be shown
  const showDeleteButton: boolean =
    // Not currently editing or the deleteInEditMode is true
    (((!formIsEditing && !deleteInEditMode) || (formIsEditing && !!deleteInEditMode))) &&

    // User has permission to Delete the record
    (!!hasPermittedAction(permittedActions, API_ACTION.DELETE)) &&

    // The form is not in Create mode (we should show a cancel button instead)
    (!formIsCreating);

  // NOTE this is a small hack so we are able to remove expense claims from a batch
  const showUnbatchButton: boolean = (!!hasPermittedAction(permittedActions, API_ACTION.PROCESS)) && (!!inline) && !showDeleteButton;

  // Button settings to use in render
  const buttons: IActionButton[] = [
    // custom buttons to add prior to the standard buttons
    ...additionalButtonsBefore,

    // Edit Button
    {
      name: 'edit',
      title: 'Edit',
      icon: ICON.EDIT,
      description: 'Edit row',
      color: buttonColor,
      onClick: () => {
        if (startEditRecord) startEditRecord();
      },
      visible: (
        // User has permission to UPDATE (edit) the form data
        !!hasPermittedAction(permittedActions, API_ACTION.UPDATE) &&

        // The form is not in edit mode
        !formIsEditing &&

        // The form is not in create mode
        !formIsCreating
      ),
      disabled: formIsReadOnly,
      permissions: [],
    },

    // Cancel Button
    {
      name: 'cancel',
      title: 'Cancel',
      icon: ICON.CANCEL,
      description: 'Cancel edits',
      color: buttonColor,
      onClick: () => {
        if (endEditRecord) endEditRecord(false);
      },
      visible: formIsCreating || formIsEditing,
      permissions: [],
    },

    // Delete Button
    {
      name: 'delete',
      title: formDeleteConfirmationType === DELETE_CONFIRMATION_TYPE.REMOVE ? 'Remove' : 'Delete',
      icon: formDeleteConfirmationType === DELETE_CONFIRMATION_TYPE.REMOVE ? ICON.UNLINK : ICON.DELETE,
      description: `Remove ${itemCaption}`,
      color: buttonColor,
      onClick: () => {
        if (deleteRecord) deleteRecord();
      },
      visible: showDeleteButton,
      permissions: [],
      disabled: formIsReadOnly,
    },

    // Unbatch Button
    {
      name: 'unbatch',
      title: 'Un-batch',
      icon: ICON.DELETE,
      description: 'Un-batch from expense claim batch',
      color: buttonColor,
      onClick: () => {
        if (deleteRecord) deleteRecord();
      },
      visible: showUnbatchButton,
      permissions: [],
    },

    // Save Button
    {
      name: 'save',
      title: 'Save',
      icon: ICON.SAVE,
      description: 'Save changes',
      color: buttonColor,
      onClick: () => {
        if (endEditRecord) endEditRecord(true);
      },
      // Skip the permission check here. The form shouldn't be in edit mode if the user couldn't edit/create it in the first place
      visible: (formIsCreating || formIsEditing),
      permissions: [],
    },

    // Custom buttons to add after the standard action buttons
    ...additionalButtonsAfter,
  ];

  // Render
  return (
    <div className={classnames('form-buttons', className)}>
      {buttons.map(
        (button) => button.visible && (
          <ActionButton
            key={button.name}
            className={buttonsClassName}
            disabled={formIsBusy || formIsLocked || button.disabled}
            busy={formIsBusy || formIsLocked}
            size={buttonSize}
            inline={inline}
            name={button.name}
            title={button.title}
            icon={button.icon}
            description={button.description || undefined}
            color={button.color}
            onClick={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
              if (button.onClick) {
                button.onClick();
              }
            }}
          />
        ),
      )}
    </div>
  );
};
