/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

export const CURRENT_USER_ALERTS_PROP_TYPES = {
  alertCounts: PropTypes.shape({
    leave: PropTypes.shape({
      personal: PropTypes.number.isRequired,
      manage: PropTypes.number.isRequired,
      process: PropTypes.number.isRequired,
    }).isRequired,
    allowances: PropTypes.shape({
      personal: PropTypes.number.isRequired,
      manage: PropTypes.number.isRequired,
      process: PropTypes.number.isRequired,
    }).isRequired,
    expenseClaims: PropTypes.shape({
      personal: PropTypes.number.isRequired,
      manage: PropTypes.number.isRequired,
      process: PropTypes.number.isRequired,
    }).isRequired,
    expenseClaimBatches: PropTypes.shape({
      process: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};
