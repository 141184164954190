import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import { ICON } from '../../../constants/icon.const';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import Icon from '../../layout-helpers/icon';
import { APIContext } from '../../providers/api-provider';
import { FileRecordRow } from './file-record-row';

export interface IFileRecord {
  id: number,
  name: string,
  url: string,
  download: string,
  folder: boolean,
}

export interface IFolderRecordRowProps {
  fileRecord: IFileRecord,
  prefix?: string
  rowData: {
    [key: string]: unknown,
    drive_id: string,
  },
  refreshRecord?: () => void,
  baseRoute: string,
  apiRoute?: null | string | ((row: Record<string, unknown>) => string),
  depth: number,
}

type LoadSubfolderState = {
  isLoadingSubfolder: boolean,
  loadSubfolderSuccess: null | boolean,
  loadAttemptMessage: null | string,
}


export const FolderRecordRow: React.FC<IFolderRecordRowProps> = (props) => {
  const { rowData, refreshRecord, baseRoute, apiRoute, fileRecord, prefix, depth } = props;

  const [subFolderData, setSubFolderData] = useState<Record<string, IFileRecord[]>>({});

  const { apiFetch } = useContext(APIContext);

  const {
    id,
  } = rowData;

  // Create a state for the create project number
  const [getSubfolderState, setGetSubfolderState] = useState<LoadSubfolderState>({
    isLoadingSubfolder: false,
    loadSubfolderSuccess: null,
    loadAttemptMessage: null,
  });
  const abortLoadSubfolder = useRef<null | AbortController>(null);


  /**
   * Fired by the onConfirm action on the dialog after asking if they are sure
   */
  const handleLoadSubfolder = useCallback((subfolderId) => {
    if (subFolderData[subfolderId]) {
      const newData = { ...subFolderData };
      delete newData[subfolderId];
      setSubFolderData(newData);
      return true;
    }

    setGetSubfolderState({
      isLoadingSubfolder: true,
      loadSubfolderSuccess: null,
      loadAttemptMessage: null,
    });

    if (abortLoadSubfolder.current) {
      abortLoadSubfolder.current.abort();
    }
    abortLoadSubfolder.current = apiAborter();

    // Call the API
    apiFetch(
      `${baseRoute}/${id}${apiRoute}?subfolder=${subfolderId}`,
      {
        method: HTTP_METHOD.GET,
        name: 'DriveFilesWidget::loadSubfolder',
        signal: abortLoadSubfolder.current.signal,
      },
    ).then((response) => {
      if (response.success) {
        setSubFolderData({ ...subFolderData, [subfolderId]: response.body.data });
        abortLoadSubfolder.current = null;
        if (refreshRecord) refreshRecord();
        setGetSubfolderState({
          isLoadingSubfolder: false,
          loadSubfolderSuccess: true,
          loadAttemptMessage: 'Successfully found folder.',
        });
      } else if (!response.aborted) {
        console.error('DriveFilesWidget::loadSubfolder', response.error);
        abortLoadSubfolder.current = null;
        setGetSubfolderState({
          isLoadingSubfolder: false,
          loadSubfolderSuccess: false,
          loadAttemptMessage: `Failed to find folder: ${(response.body && response.body.message) || '(Unknown Error)'}`,
        });
      }
    });

    return true;
  }, [apiFetch, baseRoute, id, apiRoute, subFolderData, refreshRecord]);

  const nextPrefix = `  ${prefix || ''} ${fileRecord.name} \\ `;

  return (
    <>
      <tr
        key={fileRecord.id}
        className="p-2"
        onClick={() => handleLoadSubfolder(fileRecord.id)}
        onKeyUp={() => handleLoadSubfolder(fileRecord.id)}
        style={{ cursor: 'pointer' }}
      >
        <td>
          {'>\u00a0'.repeat(depth)}
          {depth > 0 && '\u00a0\u00a0'}
          {fileRecord.folder && (<Icon i={ICON.FOLDER} className="pr-2" />)}
          {prefix}
          {fileRecord.name}
          {' \\'}
        </td>
        <td>
          <a rel="noreferrer" target="_blank" href={fileRecord.url}>
            <Icon i={ICON.EXTERNAL_LINK} className="pr-2" />
            View
          </a>
        </td>
        <td>
          <Button color="default" size="sm" onClick={() => handleLoadSubfolder(fileRecord.id)} onKeyUp={() => handleLoadSubfolder(fileRecord.id)}>
            <Icon i={ICON.FOLDER} className="pr-2" />
            {subFolderData[fileRecord.id] ? 'Close' : 'Open'}
          </Button>
        </td>
      </tr>
      {!getSubfolderState.loadSubfolderSuccess && (
        <tr>
          <td colSpan={3}>{getSubfolderState.loadAttemptMessage}</td>
        </tr>
      )}
      {subFolderData[fileRecord.id] && subFolderData[fileRecord.id].map((subFolderRecord: IFileRecord) => {
        if (subFolderRecord.folder) {
          return (
            <FolderRecordRow
              key={subFolderRecord.id}
              fileRecord={subFolderRecord}
              rowData={rowData}
              refreshRecord={refreshRecord}
              baseRoute={baseRoute}
              apiRoute={apiRoute}
              prefix={nextPrefix}
              depth={depth + 1}
            />
          );
        }
        return (
          <FileRecordRow key={subFolderRecord.id} fileRecord={subFolderRecord} depth={depth + 1} />
        );
      })}
    </>
  );
};
