import React, { useCallback, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';

import moment from 'moment';

import { DtoolsUserSaturationChart } from '../portal-fusion-chart/dtools-user-saturation-chart';
import { APIRecord } from '../../types/api-record.interface';

interface IPollRecord extends APIRecord {
  data: string,
}

/**
 * DtoolsUserSaturation component for Data Table
 */
export const DtoolsUserSaturation: React.FC = () => {
  const [viewItem, setViewItem] = useState < IPollRecord | null>(null);

  const handleMomentClick = useCallback((item) => {
    setViewItem(item || null);
  }, [setViewItem]);

  return (
    <Card>
      <CardHeader className="bg-dark text-white mb-5" tag="h4">
        D-Tools Seat Usage Poll
      </CardHeader>
      <DtoolsUserSaturationChart onItemClick={handleMomentClick} />
      <CardBody>
        {viewItem?.data && (
          <>
            <h4>
              Users
              {' '}
              {moment(viewItem.user_audit.created_at).format('MMMM Do YYYY, h:mm:ss a')}
            </h4>
            <p>{JSON.parse(viewItem.data).join(', ')}</p>
          </>
        )}
      </CardBody>
    </Card>
  );
};
