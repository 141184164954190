import { FORM_FIELD_TYPE } from '../../constants/form-field-type.const';
import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { IFormFieldDefinition } from '../../types/poly-form/form-field-definition.interface';
import { IFormRendererField } from '../../types/poly-form/form-renderer-field.interface';
import { PolyFormProps } from '../../types/poly-form/poly-form.props';
import { CurrentUserContextProps } from '../providers/current-user-provider';

/**
 * Take fields definitions and prepare them for use in the form
 *
 * @param primaryKeyValue the value of the primary key for the current record
 * @param fields an array of form field definitions
 * @param userHasPermissions a pointer to the method used to determine if a the current user has a permission
 *
 * @returns the fields transformed into FormRendererFields
 */
export const makeFormRendererFields = (
  primaryKeyValue: FormFieldComponentProps['value'],
  fields: PolyFormProps['fields'] = [],
  userHasPermissions: CurrentUserContextProps['userHasPermissions'],
): IFormRendererField[] => fields.map((field: IFormFieldDefinition) => {
  // Block field if doesn't have permission to edit
  const hasPermission = userHasPermissions(field.permissions);

  // Add additional working data to the fields
  return {
    ...field,
    id: `${field.name}_${primaryKeyValue ?? 'new'}`,
    editPermission: hasPermission || false,
    editable: hasPermission && (field.showInForm !== false) && (field.formFieldType !== FORM_FIELD_TYPE.NOT_EDITABLE),
  };
}).sort((fieldA, fieldB) => (fieldA.formOrder ?? 10000) - (fieldB.formOrder ?? 10000));
