import { SORT_DIRECTION } from '@corporate-initiatives/ci-portal-js-sdk';
import { ICON } from '../constants/icon.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';
import contractsTableInitialSettings from './contracts-table';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { monthsTextInputToolTipHtml } from '../components/form-input/months-text-input';
import { MODAL_TYPE } from '../constants/modal-type.const';
import { DATA_TABLE_FLAG } from '../constants/data-table-flag.const';

/**
 * Table Definition for Support Contracts
 */
const supportTableInitialSettings = {
  ...contractsTableInitialSettings,
  itemCaption: 'Support',
  initialFormFocusFieldName: 'first',


  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'number',
        'name',
        'project',
        'project_name',
        'client',
        'subtype',
        'region',
        'status',
        'managed_by_state',
        'note',
        'start_date',
        'end_date',
        'provider',
        'billing_type',
        'contract_file_link',
        'expires_in_days',
        'renewed_from',
        'renewed_to',
        'contract_value',
        'contract_months',
        'is_renewable',
        'auto_renew',
        'works_require_approval',
        'preventative_maintenance_included',
        'preventative_maintenance_scheduled',
        'welcome_pack_sent',
        'reminder_months',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'number',
        'name',
        'client',
        'project',
        'type',
        'subtype',
        'note',
        'count_of_note',
        'serial',
        'status',
        'managed_by_state',
        'billing_type',
        'contract_file_link',
        'region',
        'provider',
        'start_date',
        'end_date',
        'expires_in_days',
        'contract_value',
        'contract_months',
        'is_renewable',
        'auto_renew',
        'works_require_approval',
        'preventative_maintenance_included',
        'preventative_maintenance_scheduled',
        'welcome_pack_sent',
        'reminder_months',
        'custom_metadata',
        'count_of_serial',
        'count_of_companySpace',
      ],
    },
  ],


  /**
   * Definition of columns and APIPolyForm fields for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Identity',
      description: 'Contract Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      linkRoute: '/service-and-support/support-contracts',
      visible: false,
      showInForm: false,
      order: 100,
      formOrder: 100,
    }, {
      name: 'number',
      title: 'Contract Number',
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      format: COLUMN_FORMAT.NAME_LINK_SHORT,
      linkRoute: '/service-and-support/support-contracts',
      sortColumn: 'id',
      visible: true,
      order: 110,
      formOrder: 110,
    }, {
      name: 'name',
      title: 'Name',
      description: 'Contract Name',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      order: 112,
      formOrder: 112,
    }, {
      name: 'project',
      title: 'From Project',
      format: COLUMN_FORMAT.OBJECT,
      placeholder: 'P12345ABC',
      object: {
        sourceField: 'project',
        key: 'project_number',
        format: COLUMN_FORMAT.P_NUMBER,
        listSource: 'project',
      },
      visible: true,
      sortColumn: 'project.id',
      formSaveField: 'project_id',
      formFieldType: FORM_FIELD_TYPE.PROJECT_PICKER,
      order: 113,
      formOrder: 113,
    }, {
      name: 'project_name',
      title: 'Project Name',
      format: COLUMN_FORMAT.OBJECT,
      placeholder: 'P12345ABC',
      object: {
        sourceField: 'project',
        key: 'name',
        format: COLUMN_FORMAT.LONG_TEXT,
        listSource: 'project',
      },
      visible: true,
      sortColumn: 'project.id',
      formSaveField: 'project_id',
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 115,
      formOrder: 115,
    }, {
      name: 'client',
      title: 'Client',
      description: 'The contract client',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'client',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/crm/companies',
        listSource: 'company',
      },
      formFieldType: FORM_FIELD_TYPE.COMPANY_PICKER,
      formSaveField: 'client_id',
      sortColumn: 'client.name',
      visible: true,
      showInForm: true,
      isCreatable: true,
      order: 117,
      formOrder: 117,
    }, {
      name: 'serial',
      title: 'Serial',
      visible: false,
      format: COLUMN_FORMAT.TEXT,
      order: 121,
      formOrder: 141,
    }, {
      name: 'type',
      title: 'Contract Type',
      format: COLUMN_FORMAT.OBJECT,
      formFieldType: FORM_FIELD_TYPE.CONTRACT_TYPE_PICKER,
      object: {
        key: 'name',
        sourceField: 'type',
        formSaveField: 'type_id',
        listSource: 'contractType',
      },
      visible: false,
      sortColumn: 'type.name',
      filterOnField: 'type_id',
      order: 130,
      formOrder: 130,
    }, {
      name: 'subtype',
      title: 'Contract Subtype',
      format: COLUMN_FORMAT.OBJECT,
      formFieldType: FORM_FIELD_TYPE.CONTRACT_SUBTYPE_PICKER,
      object: {
        key: 'name',
        sourceField: 'subtype',
        formSaveField: 'subtype_id',
        listSource: 'contractSubtype',
      },
      sortColumn: 'subtype.name',
      filterOnField: 'subtype_id',
      visible: true,
      order: 140,
      formOrder: 140,
    }, {
      name: 'region',
      title: 'Service Region',
      format: COLUMN_FORMAT.OBJECT,
      formFieldType: FORM_FIELD_TYPE.CONTRACT_REGION_PICKER,
      object: {
        key: 'name',
        sourceField: 'region',
        formSaveField: 'region_id',
      },
      sortColumn: 'region.name',
      filterOnField: 'region.name',
      visible: true,
      order: 160,
      formOrder: 160,
    }, {
      name: 'status',
      title: 'Contract Status',
      format: COLUMN_FORMAT.OBJECT,
      formFieldType: FORM_FIELD_TYPE.CONTRACT_STATUS_PICKER,
      object: {
        key: 'name',
        sourceField: 'status',
        formSaveField: 'status_id',
        format: COLUMN_FORMAT.STATUS_BADGE,
        listSource: 'contractStatus',
      },
      visible: true,
      order: 170,
      formOrder: 170,
    },
    {
      name: 'managed_by_state',
      title: 'Managed by State',
      format: COLUMN_FORMAT.OBJECT,
      description: 'Where will the contract be managed from?',
      object: {
        sourceField: 'managed_by_state',
        key: 'acronym',
        format: COLUMN_FORMAT.SHORT_TEXT,
        listSource: 'state',
      },
      formFieldType: FORM_FIELD_TYPE.STATE_PICKER,
      formSaveField: 'managed_by_state_id',
      visible: true,
      showInForm: true,
      required: true,
      order: 172,
      formOrder: 172,
    }, {
      name: 'note',
      title: 'Note',
      visible: true,
      format: COLUMN_FORMAT.HTML_NOTIN_TABLE,
      formFieldType: FORM_FIELD_TYPE.HTML_INPUT,
      order: 180,
      formOrder: 180,
    }, {
      name: 'start_date',
      title: 'Start Date',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: true,
      order: 182,
      formOrder: 182,
    }, {
      name: 'end_date',
      title: 'End Date',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: true,
      order: 184,
      formOrder: 184,
    }, {
      name: 'provider',
      title: 'Provider',
      description: 'The company providing the contract where that differs to Ci',
      formFieldToolTip: '<p>The company providing the contract where that differs to Ci</p><p>ie:</p><ul><li>Signogy</li><li>CISCO</li><li>Crestron</li><li>etc</li></ul>',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'provider',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/crm/companies',
      },
      sortColumn: 'provider.name',
      formFieldType: FORM_FIELD_TYPE.COMPANY_PICKER,
      formSaveField: 'provider_id',
      visible: true,
      isCreatable: true,
      order: 190,
      formOrder: 190,
    }, {
      name: 'billing_type',
      title: 'Contract Billing Type',
      format: COLUMN_FORMAT.OBJECT,
      formFieldType: FORM_FIELD_TYPE.CONTRACT_BILLING_TYPE_PICKER,
      object: {
        key: 'name',
        sourceField: 'billing_type',
        formSaveField: 'billing_type_id',
      },
      sortColumn: 'billingType.name',
      filterOnField: 'billingType.name',
      visible: true,
      order: 200,
      formOrder: 200,
    }, {
      name: 'contract_file_link',
      title: 'External Document Link',
      description: 'Paste any file link, including SharePoint, OneDrive and Wiki links to contract details.',
      formFieldToolTip: 'Paste any file link, including SharePoint, OneDrive and Wiki links to contract details.',
      format: COLUMN_FORMAT.ABBREVIATED_EXT_LINK,
      visible: true,
      showInForm: true,
      order: 210,
      formOrder: 210,
    }, {
      name: 'expires_in_days',
      title: 'Renewal Due',
      format: COLUMN_FORMAT.NULLABLE_NUMBER,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      order: 220,
      formOrder: 220,
    }, {
      name: 'renewed_from',
      title: 'Renewed From',
      description: 'If the Contract is a renewal, this field shows the previous Contract Number.',
      formFieldToolTip: 'If the Contract is a renewal, this field shows the previous Contract Number.',
      formFieldType: FORM_FIELD_TYPE.CONTRACT_PICKER,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'renewed_from',
        key: 'number',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/service-and-support/support-contracts',
      },
      sortColumn: 'renewedFrom.number',
      filterOnField: 'renewedFrom.number',
      visible: true,
      order: 230,
      formOrder: 230,
    }, {
      name: 'renewed_to',
      title: 'Renewed As',
      description: 'If the Contract has been renewed, this field shows the Contract Number for the new contract.',
      formFieldToolTip: 'If the Contract has been renewed, this field shows the Contract Number for the new contract.',
      formFieldType: FORM_FIELD_TYPE.CONTRACT_PICKER,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'renewed_to',
        key: 'number',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/service-and-support/support-contracts',
      },
      sortColumn: 'renewedTo.number',
      filterOnField: 'renewedTo.number',
      visible: true,
      order: 240,
      formOrder: 240,
    }, {
      name: 'contract_value',
      title: 'Contract Value',
      format: COLUMN_FORMAT.WHOLE_DOLLARS,
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
      visible: true,
      order: 250,
      formOrder: 250,
    }, {
      name: 'contract_months',
      title: 'Contract Months',
      description: 'Length of the contract in months',
      format: COLUMN_FORMAT.NUMBER,
      formFieldType: FORM_FIELD_TYPE.MONTHS_TEXT_INPUT,
      formFieldToolTip: monthsTextInputToolTipHtml,
      visible: true,
      order: 260,
      formOrder: 260,
    }, {
      name: 'is_renewable',
      title: 'Can be Renewed?',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: true,
      order: 270,
      formOrder: 270,
    }, {
      name: 'auto_renew',
      title: 'Auto Renew Enabled?',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: true,
      order: 280,
      formOrder: 280,
    }, {
      name: 'works_require_approval',
      title: 'Works Require Approval',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: true,
      order: 290,
      formOrder: 290,
    }, {
      name: 'preventative_maintenance_included',
      title: 'Maintenance Included?',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: true,
      order: 292,
      formOrder: 292,
    }, {
      name: 'preventative_maintenance_scheduled',
      title: 'Maintenance Scheduled?',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: true,
      order: 295,
      formOrder: 295,
    }, {
      name: 'welcome_pack_sent',
      title: 'Welcome Pack Sent?',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: true,
      order: 298,
      formOrder: 298,
    }, {
      name: 'reminder_months',
      title: 'Reminder Months',
      description: 'How many months in advance should the reminder be triggered?',
      format: COLUMN_FORMAT.NUMBER,
      formFieldType: FORM_FIELD_TYPE.MONTHS_TEXT_INPUT,
      formFieldToolTip: monthsTextInputToolTipHtml,
      visible: true,
      order: 300,
      formOrder: 300,
    }, {
      name: 'custom_metadata',
      title: 'Custom Metadata',
      visible: false,
      showInForm: false,
      order: 310,
      formOrder: 310,
    }, {
      name: 'count_of_note',
      title: 'Count of Notes',
      description: 'How many notes have been created for this contract.',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 320,
      formOrder: 320,
      showInForm: false,
      isReadOnly: true,
    }, {
      name: 'count_of_serial',
      title: 'Count of Serial Numbers',
      description: 'How many serial or licence numbers are assigned to this contract.',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 330,
      formOrder: 330,
      showInForm: false,
      isReadOnly: true,
    }, {
      name: 'count_of_companySpace',
      title: 'Count of Company Spaces',
      description: 'How many company spaces are assigned to this contract.',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      showInForm: false,
      isReadOnly: true,
      order: 340,
      formOrder: 340,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add a Contract',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new Support Contract',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the Support Contract',
      successMessage: 'Updated support',
      failMessage: 'Failed to update Support Contract',
      hideRecordActionButton: true,
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the Support Contract',
      successMessage: 'Support Contract deleted',
      failMessage: 'Failed to delete Support Contract',
      hideRecordActionButton: false,
    },
    {
      name: 'renew',
      title: 'Renew',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.REFRESH,
      description: 'Renew the contract',
      successMessage: 'Contract Renewed',
      failMessage: 'Failed to renew the contract',
      modalType: MODAL_TYPE.RENEW_CONTRACT,
      hideRecordActionButton: false,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: contractsTableInitialSettings.tabs.filter((tab) => [
    'detail', 'notes', 'spaces', 'subtypes', 'files', 'space-import', 'scheduled-checks', 'quickbooks', 'invoice-requests', 'events',
  ].indexOf(tab.name) > -1),

  /**
   * View definitions
   */
  viewKey: 'all',
  views: [
    {
      key: 'all',
      title: 'All Support',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.MY_STATE],
          title: 'State Support',
        },
        {
          flags: [DATA_TABLE_FLAG.NATIONAL],
          title: 'National Support',
        },
      ],
      filters: [],
      flags: [],
    },
    {
      key: 'expiry-view',
      title: 'Overdue and Expiring Support',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.MY_STATE],
          title: 'State Overdue and Expiring Support',
        },
        {
          flags: [DATA_TABLE_FLAG.NATIONAL],
          title: 'National Overdue and Expiring Support',
        },
      ],
      filters: [
        {
          field: 'expires_in_days',
          operation: API_FILTER_OPERATION.NOT_NULL,
          values: [],
        },
      ],
      sortedColumns: [
        {
          name: 'expires_in_days',
          direction: SORT_DIRECTION.ASC,
          sortIndex: 0,
        },
      ],
      flags: [],
    },
    {
      key: 'active',
      title: 'Active Support',
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.MY_STATE],
          title: 'State Active Support',
        },
        {
          flags: [DATA_TABLE_FLAG.NATIONAL],
          title: 'National Active Support',
        },
      ],
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['Active'],
        },
      ],
      flags: [],
    },
  ],
};

export default supportTableInitialSettings;
