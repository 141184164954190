import React, { useState, useCallback } from 'react';
import { Card, Container, CardBody, Row, Col, Label, Button } from 'reactstrap';
import classNames from 'classnames';

import { ILoginResult } from '../../types/auth/login-result.interface';

import { LoginPageHeader } from './login-page-header';
import { TextInput } from '../form-input/text-input';
import Icon from '../layout-helpers/icon';
import FriendlyFormMessage from '../layout-helpers/friendly-form-message';
import { PasswordInput } from '../form-input/password-input';


interface LoginPageProps {
  initialEmail: null | string,
  isBusy: boolean;
  onSubmit: (email: string, password: string) => void;
  onShowResetPasswordPage: (email: string) => void;
  formResult: undefined | ILoginResult;
}


export const LoginPage: React.FC<LoginPageProps> = ({
  initialEmail,
  isBusy,
  onSubmit,
  onShowResetPasswordPage,
  formResult,
}) => {
  const [email, setEmail] = useState<string>(initialEmail ?? '');
  const [password, setPassword] = useState<string>('');


  /**
   * Fired when the user submits the form in some way
   */
  const handleFormSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(email, password);
  }, [onSubmit, email, password]);


  /**
   * Fired when the user clicks the Reset Password button
   */
  const handleFormReset = useCallback((event:React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setPassword('');
    onShowResetPasswordPage(email);
  }, [onShowResetPasswordPage, setPassword, email]);


  return (
    <Container className="portal-login-form-container">
      <Card className="portal-login-form-card">
        <CardBody>
          <Row>
            <Col md="5" />
            <Col md="7" className="portal-login-form right-side">
              <LoginPageHeader
                title="Log in"
                subTitle="Enter your username and password to continue"
              />

              {/* Login Form */}
              <Card>
                <CardBody>
                  <form className="vertical-layout" onSubmit={handleFormSubmit} onReset={handleFormReset}>
                    {/* Email Field */}
                    <div
                      className={classNames('form-group', {
                        'has-danger': !!formResult?.fieldErrors?.email,
                      })}
                    >
                      <Label htmlFor="username">Email</Label>
                      <TextInput
                        id="username"
                        name="username"
                        className="form-control"
                        onChange={({ newValue }) => setEmail(newValue)}
                        disabled={isBusy}
                        value={email}
                        placeholder="Email Address"
                        hasError={!!formResult?.fieldErrors?.email}
                      />
                      {!!formResult?.fieldErrors?.email && (
                        <div className="form-control-feedback">
                          {formResult.fieldErrors.email}
                        </div>
                      )}
                    </div>

                    {/* Password Field */}
                    <div
                      className={classNames('form-group', {
                        'has-danger': !!formResult?.fieldErrors?.password,
                      })}
                    >
                      <Label htmlFor="password">Password</Label>
                      <PasswordInput
                        id="password"
                        onChange={({ newValue }) => {
                          setPassword(newValue);
                        }}
                        disabled={isBusy}
                        value={password}
                        hasError={!!formResult?.fieldErrors?.password}
                        placeholder="Account Password"
                        name="password"
                      />
                      {!!formResult?.fieldErrors?.password && (
                        <div className="form-control-feedback">
                          {formResult.fieldErrors.password}
                        </div>
                      )}
                    </div>

                    <Button
                      disabled={isBusy}
                      type="submit"
                      color="primary"
                      className="pull-right"
                    >
                      <span>Sign In</span>
                      {isBusy && <Icon i="spinner" size="lg" spaceLeft />}
                    </Button>
                    <Button
                      disabled={isBusy}
                      color="secondary"
                      type="reset"
                    >
                      Reset Password
                    </Button>
                  </form>
                </CardBody>
              </Card>

              {formResult && (formResult.succeeded || formResult.failed) && (
                <FriendlyFormMessage
                  formMessage={formResult.message}
                  hasSuccess={formResult.succeeded}
                  hasErrors={formResult.failed}
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};
