import React, { Component } from 'react';
import { EventEmitter } from 'events';

import { AppHeader } from '../app-layout/app-header';
import { SideBar } from '../sidebar/sidebar';
import { loadLocalPrefs, saveLocalPref } from '../../utils/localStorage';

const HOVER_SIDEBAR_TIMEOUT = 250;

/**
 * <AppNav /> Wrapper for Sidebar and AppHeader, and anything
 * that needs to control state of the body tag
 * @param {*} props
 */
class AppNav extends Component {
  constructor(props) {
    super(props);

    const { isSidebarVisible } = loadLocalPrefs();

    this.state = {
      isSidebarVisible,
      isSidebarHovering: false,
      isMobileSidebarVisible: false,
    };

    this.hoverTimeOut = null;
    this.navChangeListener = new EventEmitter();
  }


  /**
   * @inheritdoc
   */
  componentDidMount() {
    window.addEventListener('resize', this.scrollbarCheck);
    window.addEventListener('orientationchange', this.scrollbarCheck);
    window.addEventListener('scroll', this.scrollbarCheck);

    this.scrollbarCheck();
    this.showSidebarCheck();
  }


  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.scrollbarCheck);
    window.removeEventListener('orientationchange', this.scrollbarCheck);
    window.removeEventListener('scroll', this.scrollbarCheck);

    // Remove any manually added classes to the document body
    document.body.classList.remove('hover-sidebar');
    document.body.classList.remove('show-sidebar');
    document.body.classList.remove('show-mobile-sidebar');
    document.body.classList.remove('scrollbar');

    clearTimeout(this.hoverTimeOut);
  }


  /**
   * @description
   * Toggles body class show-sidebar and tracks it in app state
   *
   * @param {boolean} [show=true] optional sidebar on/off state
   */
  setShowSidebar = (show = true) => {
    const { isSidebarHovering } = this.state;

    this.setState(
      {
        isSidebarVisible: show,
      },
      () => {
        this.showSidebarCheck();

        // Tell everyone about the fact that the navbar is now hidden
        if (show !== isSidebarHovering) {
          this.navChangeListener.emit('show', show);
        }

        this.setHoverSidebar(false, true);
        this.setShowMobileSidebar(false);
      },
    );
  };


  /**
   * @description
   * Toggles body class show-sidebar specifically for mobile displays
   *
   * @param {boolean} [show=true] optional sidebar on/off state
   */
  setShowMobileSidebar = (show = true) => {
    const { isMobileSidebarVisible } = this.state;

    this.setState(
      {
        isMobileSidebarVisible: show,
      },
      () => {
        this.showMobileSidebarCheck();

        // Tell everyone about the fact that the navbar is now hidden
        if (show !== isMobileSidebarVisible) {
          this.navChangeListener.emit('show', show);
        }
      },
    );
  };


  /**
   * Handles cursor hovering and leaving the sidebar and buttons
   * @param {boolean} [hover=false] optional sidebar on/off state
   * @param {boolean} [immediate=false] optional don't wait for the timeout after hover out
   */
  setHoverSidebar = (hover = false, immediate = false) => {
    clearTimeout(this.hoverTimeOut);

    const { isSidebarVisible, isSidebarHovering } = this.state;

    this.setShowMobileSidebar(hover);

    if (!isSidebarVisible) {
      if (hover || immediate === true) {
        this.setState(
          {
            isSidebarHovering: hover,
          },
          () => {
            this.sidebarHoverCheck();
            this.navChangeListener.emit('show', hover);
          },
        );
      }
      else if (!hover) {
        this.hoverTimeOut = setTimeout(() => {
          if (isSidebarHovering) {
            this.setState(
              {
                isSidebarHovering: false,
              },
              () => {
                this.sidebarHoverCheck();
                this.navChangeListener.emit('show', false);
              },
            );
          }
        }, HOVER_SIDEBAR_TIMEOUT);
      }
    }
  };


  /**
   * @description
   * Evaluate the state of the sidebar toggle and add the appropriate class to the body
   */
  showSidebarCheck = () => {
    const { isSidebarVisible } = this.state;

    // Need this pure JS to access <body>
    if (isSidebarVisible) {
      document.body.classList.add('show-sidebar');
    }
    else {
      document.body.classList.remove('show-sidebar');
    }
    saveLocalPref('isSidebarVisible', isSidebarVisible);
  };


  /**
   * @description
   * Evaluate the state of the desktop mode sidebar hover and add the appropriate class to the body
   */
  sidebarHoverCheck = () => {
    const { isSidebarHovering } = this.state;

    // Need this pure JS to access <body>
    if (isSidebarHovering === true) {
      document.body.classList.add('hover-sidebar');
    }
    else {
      document.body.classList.remove('hover-sidebar');
    }
  };


  /**
   * @description
   * Evaluate the state of the mobile mode sidebar visibility and add the appropriate class to the body
   */
  showMobileSidebarCheck = () => {
    const { isMobileSidebarVisible } = this.state;

    // Need this pure JS to access <body>
    if (isMobileSidebarVisible === true) {
      document.body.classList.add('show-mobile-sidebar');
    }
    else {
      document.body.classList.remove('show-mobile-sidebar');
    }
  };


  /**
   * Do a check of resize of browser window
   * if scrollbar exists, add the `scrollbar` class top body
   */
  scrollbarCheck = () => {
    // const fullWindow = window.innerWidth;
    // const inside = document.body.clientWidth;
    const scrollbarSize = window.innerWidth - document.body.clientWidth;
    // console.log('Window Size:', fullWindow, inside, scrollbarSize);
    const hasScrollbarClass = document.body.classList.contains('scrollbar');
    if (scrollbarSize) {
      if (!hasScrollbarClass) {
        document.body.classList.add('scrollbar');
      }
    }
    else if (hasScrollbarClass) {
      document.body.classList.remove('scrollbar');
    }
  };


  /**
   * Render <AppNav />
   */
  render() {
    const { isSidebarVisible, isSidebarHovering, isMobileSidebarVisible } = this.state;

    // Render <AppNav />
    return (
      <>
        <div className="page-mask" onTouchStart={() => this.setShowMobileSidebar(false)} />
        <AppHeader
          setShowSidebar={this.setShowSidebar}
          setShowMobileSidebar={this.setShowMobileSidebar}
          isSidebarVisible={isSidebarVisible}
          isMobileSidebarVisible={isMobileSidebarVisible}
        />
        <SideBar
          setShowSidebar={this.setShowSidebar}
          setHoverSidebar={this.setHoverSidebar}
          isSidebarVisible={isSidebarVisible}
          isSidebarHovering={isSidebarHovering}
          navChangeListener={this.navChangeListener}
        />
      </>
    );
  }
}

export default AppNav;
