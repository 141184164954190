import React from 'react';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { IMyobSupplierOrder } from '../../../types/integration/myob/myob-supplier-order.interface';
import { formatValueString } from '../../render-functions';

export const MyobOrderItemsTable: React.FC<{ orderRecord: IMyobSupplierOrder }> = (props) => {
  const { orderRecord: {
    Details = [],
  } } = props;
  return (
    <table>
      <thead>
        <tr>
          <th>Project</th>
          <th>Description</th>
          <th>Qty</th>
          <th>$ Item</th>
          <th>$ Total</th>
          <th>Account</th>
          <th>State</th>
        </tr>
      </thead>
      {Details.map((recordDetail) => (
        <tr key={recordDetail.id}>
          <td>
            {recordDetail.Project.value}
          </td>
          <td style={{ whiteSpace: 'normal' }}>
            {recordDetail.LineDescription.value}
          </td>
          <td className="text-right">
            {recordDetail.OrderQty.value}
          </td>
          <td className="currency text-right">
            {formatValueString(recordDetail.UnitCost.value, COLUMN_FORMAT.CURRENCY)}
          </td>
          <td className="currency text-right">
            {formatValueString(recordDetail.ExtendedCost.value, COLUMN_FORMAT.CURRENCY)}
          </td>
          <td>
            {recordDetail.Account.value}
          </td>
          <td>
            {recordDetail.Subaccount.value}
          </td>
        </tr>
      ))}
    </table>
  );
};
