import { DELETE_CONFIRMATION_TYPE } from '../constants/delete-confirmation-type.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { ContactsWidget } from '../components/widgets/contacts-widget/contacts-widget';

// @note: THESE ARE PEOPLE RESOURCES, !!!NOT!!! CONTACTS
export const CompanyContactsWidgetDefinition: ICollectionWidgetDefinition = {
  // has to be table row here currently to get the wrapper to generate them
  // we can decide not to use them for card view later
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  component: ContactsWidget,
  itemCaption: 'Contact',
  className: 'contacts-widget',
  formDeleteConfirmationType: DELETE_CONFIRMATION_TYPE.REMOVE,
  fields: [
    {
      name: 'company_id',
      visible: false,
      isParentId: true,
    },
    {
      name: 'first',
      title: 'First Name',
      visible: true,
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: '/crm/contacts',
    },
    {
      name: 'last',
      title: 'Last Name',
      visible: true,
      format: COLUMN_FORMAT.TEXT,
      formSaveField: 'type_id',
    },
    {
      name: 'role',
      title: 'Role',
      visible: true,
      format: COLUMN_FORMAT.TEXT,
      formSaveField: 'role',
    },
    {
      name: 'email',
      title: 'Email',
      visible: true,
      format: COLUMN_FORMAT.EMAIL,
      formSaveField: 'type_id',
    },
    {
      name: 'office',
      title: 'Office',
      visible: true,
      format: COLUMN_FORMAT.PHONE,
      formSaveField: 'type_id',
    },
    {
      name: 'mobile',
      title: 'Mobile',
      visible: true,
      format: COLUMN_FORMAT.PHONE,
      formSaveField: 'type_id',
    },
    {
      name: 'address_line_one',
      title: 'Address L1',
      visible: true,
      format: COLUMN_FORMAT.TEXT,
      formSaveField: 'address_line_one',
    },
    {
      name: 'address_line_two',
      title: 'Address L2',
      visible: true,
      format: COLUMN_FORMAT.TEXT,
      formSaveField: 'address_line_two',
    },
    {
      name: 'address_town',
      title: 'Address Town',
      visible: true,
      format: COLUMN_FORMAT.TEXT,
      formSaveField: 'address_town',
    },
    {
      name: 'address_state',
      title: 'Address State',
      visible: true,
      format: COLUMN_FORMAT.TEXT,
      formSaveField: 'address_state',
    },
    {
      name: 'address_postcode',
      title: 'Address Postcode',
      visible: true,
      format: COLUMN_FORMAT.TEXT,
      formSaveField: 'address_postcode',
    },
  ],
};
