import classNames from 'classnames';
import React from 'react';
import Pagination from '../../pagination/pagination';

interface IInMemoryPaginationProps {
  totalRecords: number,
  activePage: number,
  onPageChange: (newPageNumber: number) => void,
  pageSize: number,
  className?: string,
  color?: string
}

export const InMemoryPagination: React.FC<IInMemoryPaginationProps> = ({
  totalRecords,
  activePage,
  onPageChange,
  pageSize,
  className,
  color,
}) => {
  const handlePageChange = (newPageNumber: number) => {
    onPageChange(newPageNumber);
  };

  return (
    <Pagination
      className={classNames('portal-data-table-pagination', className)}
      totalRecords={totalRecords}
      activePage={activePage}
      onPageChange={handlePageChange}
      pageSize={pageSize}
      item-class={`btn btn-${color || 'success'}`}
    />
  );
};
