import React, { useEffect, useContext, useCallback, useRef } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { EventEmitter } from 'events';

import NavItem from './nav-item';
import DropdownSubmenu from './dropdown-submenu';
import ProjectFinder from './project-finder';
import { slug, noop, objectByString } from '../../utils/helpers';
import { APP_ENV, userSummaryBadgeMap } from '../../utils/constants';
import { PERMISSION } from '../../constants/permissions.const';
import { ICON } from '../../constants/icon.const';
import { CurrentUserContext } from '../providers/current-user-provider';


/**
 * Map of all routes for sidebar.
 */
export const linkedRouteMap = [
  {
    title: 'Home',
    icon: ICON.HOME,
    link: '/',
  },
  {
    title: 'Comms',
    icon: ICON.COMMS,
    path: '/comms',
    children: [
      {
        title: 'News',
        icon: ICON.NEWS,
        link: '/news',
        badgeKeys: ['news'],
      },
      {
        title: 'Notifications',
        icon: ICON.NOTIFICATIONS,
        link: '/notifications',
        badgeKeys: ['notifications'],
      },
    ],
  },
  {
    title: 'Dashboards',
    icon: ICON.DASHBOARDS,
    path: '/dashboards',
    permissions: [
      PERMISSION.REPORT_EXEC_DASH,
      PERMISSION.REPORT_SALES_DASH,
      PERMISSION.REPORT_SALES_PERFORMANCE,
      PERMISSION.REPORT_FORECAST_INVOICE_DASH,
      PERMISSION.REPORT_SALES_FORECAST,
    ],
    children: [
      {
        title: 'Executive Dash',
        icon: ICON.EXECUTIVE_DASHBOARD,
        link: '/executive-dash',
        permissions: [PERMISSION.REPORT_EXEC_DASH],
      },
      {
        title: 'Sales Dash',
        icon: ICON.SALES_DASHBOARD,
        link: '/sales-dashboard',
        devOnly: true,
        permissions: [PERMISSION.REPORT_SALES_DASH],
      },
      {
        title: 'Sales Performance',
        icon: ICON.SALES_PERFORMANCE,
        link: '/sales-performance',
        permissions: [PERMISSION.REPORT_SALES_PERFORMANCE],
      },
      {
        title: 'Project Summary',
        icon: ICON.PROJECT_SUMMARY,
        link: '/project-summary',
        permissions: [PERMISSION.REPORT_FORECAST_INVOICE_DASH],
      },
      {
        title: 'Sales Forecasting',
        icon: ICON.SALES_FORECAST,
        link: '/sales-forecasting',
        permissions: [PERMISSION.REPORT_SALES_FORECAST],
      },
      {
        title: 'ETA Overview',
        icon: ICON.ETA_DASHBOARD,
        link: '/item-eta-reporting',
      },
      {
        title: 'Report Downloads',
        icon: ICON.EXCEL_DOWNLOAD,
        link: '/report-downloads',
        permissions: [PERMISSION.SECURITY_ADMIN],
      },
    ],
  },
  {
    title: 'Project Portal',
    icon: ICON.PROJECT_PORTAL,
    path: '/crm',
    children: [
      {
        title: 'Leads and Projects',
        icon: ICON.LEADS_AND_PROJECTS,
        link: '/projects',
      },
      {
        title: 'Project Schedule',
        icon: ICON.NATIONAL_LEAVE_CALENDAR, // @todo fix this
        permissions: [PERMISSION.REPORT_FORECAST_INVOICE_DASH],
        link: '/project-schedule',
      },
      {
        title: 'Companies',
        icon: ICON.COMPANIES,
        link: '/companies',
      },
      {
        title: 'Contacts',
        icon: ICON.CONTACTS,
        link: '/contacts',
      },
      {
        title: 'Orders & ETAs',
        icon: ICON.ORDERING,
        path: '/ordering',
        children: [
          {
            title: 'Projects Overview',
            icon: ICON.ETA_SUMMARY,
            link: '/project-eta-summary',
          },
          {
            title: 'Item ETA Details',
            icon: ICON.ETA_ITEMS,
            link: '/project-eta-items',
          },
          {
            title: 'Stock Orders',
            icon: ICON.STOCK_ORDERS,
            link: '/stock-orders',
            permissions: [PERMISSION.PROJECT_PUSH_PURCHASE_ORDER_TO_QUICKBOOKS, PERMISSION.SECURITY_ADMIN],
          },
          {
            title: 'Change Orders',
            icon: ICON.CHANGE_ORDERS,
            link: '/project-change-orders',
          },
        ],
      },
      {
        title: 'Order Confirmation',
        icon: ICON.CONTACTS,
        link: '/order-confirmation',
        permissions: [PERMISSION.PROJECT_ORDER_CONFIRMATION_VIEWER, PERMISSION.SECURITY_ADMIN],
      },
    ],
  },
  {
    title: 'Service & Support',
    icon: ICON.SERVICE_AND_SUPPORT,
    permissions: [PERMISSION.SERVICE_CONTRACT_VIEWER, PERMISSION.SERVICE_CONTRACT_USER],
    path: '/service-and-support',
    children: [
      {
        title: 'Service Jobs',
        icon: ICON.SERVICE_JOBS,
        permissions: [PERMISSION.SERVICE_JOB_VIEWER],
        link: '/service-jobs',
      },
      {
        title: 'Contracts',
        icon: ICON.CONTRACTS,
        link: '/contracts',
      },
      {
        title: 'Support Contracts',
        icon: ICON.SUPPORT_CONTRACTS,
        link: '/support-contracts',
      },
      {
        title: 'Licences & Subscriptions',
        icon: ICON.LICENCES,
        link: '/licences-and-subscriptions',
      },
      {
        title: 'Maintenance Checks',
        icon: ICON.NATIONAL_LEAVE_CALENDAR,
        link: '/maintenance-checks-calendar',
      },
      {
        title: 'CSM Admin',
        icon: ICON.INTERNAL_PROJECTS,
        permissions: [PERMISSION.SERVICE_CONTRACT_ADVANCED_USER, PERMISSION.SERVICE_CONTRACT_ADMIN],
        path: '/admin',
        children: [
          {
            title: 'Contract Types',
            icon: ICON.CONTRACT_TYPES,
            link: '/contract-types',
            permissions: [PERMISSION.SERVICE_CONTRACT_ADVANCED_USER, PERMISSION.SERVICE_CONTRACT_ADMIN],
          },
          {
            title: 'Contract Sub Types',
            icon: ICON.CONTRACT_SUBTYPES,
            link: '/contract-subtypes',
            permissions: [PERMISSION.SERVICE_CONTRACT_ADVANCED_USER, PERMISSION.SERVICE_CONTRACT_ADMIN],
          },
          {
            title: 'Contract Regions',
            icon: ICON.REGIONS,
            link: '/contract-regions',
            permissions: [PERMISSION.SERVICE_CONTRACT_ADVANCED_USER, PERMISSION.SERVICE_CONTRACT_ADMIN],
          },
        ],
      },
    ],
  },
  {
    title: 'Docs & Resources',
    icon: ICON.DOCS_AND_RESOURCES,
    link: '/docs-and-resources',
    children: [
      {
        title: 'Ci Wiki',
        icon: ICON.CI_WIKI,
        externalLink: 'https://wiki.ciportal.net',
      },
      {
        title: 'Ci Documents',
        icon: ICON.CI_DOCUMENTS,
        children: [
          {
            title: 'View All',
            icon: ICON.CI_DOCUMENTS,
            externalLink: 'https://corporateinitiatives.sharepoint.com/Shared%20Documents',
          },
          {
            title: 'Product Resources',
            icon: ICON.PRODUCT_RESOURCES,
            externalLink: 'https://corporateinitiatives.sharepoint.com/Shared%20Documents/Product%20Resources',
          },
          {
            title: 'Marketing Resources',
            icon: ICON.MARKETING_RESOURCES,
            externalLink: 'https://corporateinitiatives.sharepoint.com/Shared%20Documents/Marketing%20Resources',
          },
          {
            title: 'Price Lists',
            icon: ICON.PRICE_LISTS,
            externalLink: 'https://corporateinitiatives.sharepoint.com/Shared%20Documents/Price%20Lists',
          },
          {
            title: 'Templates',
            icon: ICON.TEMPLATES,
            externalLink: 'https://corporateinitiatives.sharepoint.com/Shared%20Documents/Ci%20Templates',
          },
        ],
      },
    ],
  },
  {
    title: 'HR Portal',
    icon: ICON.HR_PORTAL,
    path: '/hrm',
    badgeKeys: [
      'manageLeave',
      'manageExpenses',
      'manageAllowances',
      'myLeave',
      'myExpenses',
      'myAllowances',
    ],
    children: [
      {
        title: 'Staff Profiles',
        icon: ICON.STAFF_PROFILES,
        link: '/browse-profiles',
      },
      {
        title: 'My Leave',
        icon: ICON.MY_LEAVE,
        link: '/my-leave',
        badgeKeys: ['myLeave'],
      },
      {
        title: 'My Expenses',
        icon: ICON.MY_EXPENSES,
        link: '/my-expenses',
        badgeKeys: ['myExpenses'],
      },
      {
        title: 'My Allowances',
        icon: ICON.MY_ALLOWANCES,
        link: '/my-allowances',
        badgeKeys: ['myAllowances'],
        permissions: [PERMISSION.ALLOWANCE_USER],
      },
      {
        title: 'National Leave Calendar',
        icon: ICON.NATIONAL_LEAVE_CALENDAR,
        link: '/national-leave-calendar',
      },
      {
        title: 'My Team',
        icon: ICON.MY_TEAM,
        path: '/my-team',
        badgeKeys: [PERMISSION.MANAGE_LEAVE, PERMISSION.MANAGE_EXPENSES, PERMISSION.MANAGE_ALLOWANCES],
        permissions: [
          PERMISSION.LEAVE_APPROVER,
          PERMISSION.LEAVE_CONFIRMER,
          PERMISSION.LEAVE_ADMIN,
          PERMISSION.EXPENSE_APPROVER,
          PERMISSION.EXPENSE_PROCESSOR,
          PERMISSION.EXPENSE_ADMIN,
          PERMISSION.ALLOWANCE_APPROVER,
          PERMISSION.ALLOWANCE_PROCESSOR,
          PERMISSION.ALLOWANCE_ADMIN,
        ],
        children: [
          {
            title: 'Manage Leave',
            icon: ICON.MANAGE_LEAVE,
            link: '/manage-leave',
            badgeKeys: ['manageLeave'],
            permissions: [PERMISSION.LEAVE_APPROVER, PERMISSION.LEAVE_CONFIRMER, PERMISSION.LEAVE_ADMIN],
          },
          {
            title: 'Manage Expenses',
            icon: ICON.MANAGE_EXPENSES,
            link: '/manage-expenses',
            badgeKeys: ['manageExpenses'],
            permissions: [PERMISSION.EXPENSE_APPROVER, PERMISSION.EXPENSE_PROCESSOR, PERMISSION.EXPENSE_ADMIN],
          },
          {
            title: 'Manage Allowances',
            icon: ICON.MANAGE_ALLOWANCES,
            link: '/manage-allowances',
            badgeKeys: ['manageAllowances'],
            permissions: [PERMISSION.ALLOWANCE_APPROVER, PERMISSION.ALLOWANCE_PROCESSOR, PERMISSION.ALLOWANCE_ADMIN],
          },
        ],
      },
    ],
  },
  {
    title: 'Finance Portal',
    icon: ICON.FINANCE_PORTAL,
    path: '/finance',
    permissions: [
      PERMISSION.EXPENSE_PROCESSOR,
      PERMISSION.EXPENSE_ADMIN,
      PERMISSION.LEAVE_CONFIRMER,
      PERMISSION.LEAVE_ADMIN,
      PERMISSION.ALLOWANCE_PROCESSOR,
      PERMISSION.ALLOWANCE_ADMIN,
      PERMISSION.INVOICE_REQUEST_VIEWER,
      PERMISSION.INVOICE_REQUEST_USER,
      PERMISSION.INVOICE_REQUEST_PROCESSOR,
    ],
    badgeKeys: [
      'processLeave',
      'processExpenses',
      'processExpenseClaimBatches',
      'processAllowances',
      'submittedInvoiceRequests',
    ],
    colorKeys: {
      danger: 'rejectedInvoiceRequests',
      warning: 'draftInvoiceRequests',
    },
    children: [
      {
        title: 'Sales Commission',
        icon: ICON.INVOICE_REQUESTS,
        path: '/sales-commission',
        permissions: [
          PERMISSION.COMMISSION_PAYMENT_MANAGER,
          PERMISSION.COMMISSION_PAYMENT_VIEWER,
        ],
        children: [
          {
            title: 'Payment Batches',
            icon: ICON.INVOICE_REQUESTS,
            link: '/payment-batches',
            permissions: [
              PERMISSION.COMMISSION_PAYMENT_MANAGER,
              PERMISSION.COMMISSION_PAYMENT_VIEWER,
            ],
          },
          {
            title: 'Invoices by Salesperson',
            icon: ICON.INVOICE_REQUESTS,
            link: '/invoices-by-salesperson',
            permissions: [
              PERMISSION.COMMISSION_PAYMENT_MANAGER,
              PERMISSION.COMMISSION_PAYMENT_VIEWER,
            ],
          },
          {
            title: 'Structure Iterations',
            icon: ICON.INVOICE_REQUESTS,
            link: '/commission-iterations',
            permissions: [
              PERMISSION.COMMISSION_PAYMENT_MANAGER,
              PERMISSION.COMMISSION_PAYMENT_VIEWER,
            ],
          },
          {
            title: 'Structure Brackets',
            icon: ICON.INVOICE_REQUESTS,
            link: '/commission-brackets',
            permissions: [
              PERMISSION.COMMISSION_PAYMENT_MANAGER,
              PERMISSION.COMMISSION_PAYMENT_VIEWER,
            ],
          },
        ],
      },
      {
        title: 'Invoice Requests',
        icon: ICON.INVOICE_REQUESTS,
        link: '/invoice-requests',
        badgeKeys: ['rejectedInvoiceRequests', 'submittedInvoiceRequests', 'draftInvoiceRequests'],
        colorKeys: {
          danger: 'rejectedInvoiceRequests',
          warning: 'draftInvoiceRequests',
        },
        permissions: [
          PERMISSION.INVOICE_REQUEST_VIEWER,
          PERMISSION.INVOICE_REQUEST_USER,
          PERMISSION.INVOICE_REQUEST_PROCESSOR,
        ],
      },
      {
        title: 'Process Leave',
        icon: ICON.PROCESS_LEAVE,
        link: '/process-leave',
        badgeKeys: ['processLeave'],
        permissions: [PERMISSION.LEAVE_CONFIRMER, PERMISSION.LEAVE_ADMIN],
      },
      {
        title: 'Process Expenses',
        icon: ICON.PROCESS_EXPENSES,
        path: '/process-expenses',
        badgeKeys: ['processExpenses', 'processExpenseClaimBatches'],
        permissions: [PERMISSION.EXPENSE_PROCESSOR, PERMISSION.EXPENSE_ADMIN],
        children: [
          {
            title: 'Expense Claims',
            icon: ICON.EXPENSE_CLAIMS,
            link: '/claims',
            badgeKeys: ['processExpenses'],
            permissions: [PERMISSION.EXPENSE_PROCESSOR, PERMISSION.EXPENSE_ADMIN],
          },
          {
            title: 'Expense Claim Batches',
            icon: ICON.EXPENSE_CLAIM_BADGES,
            link: '/batches',
            badgeKeys: ['processExpenseClaimBatches'],
            permissions: [PERMISSION.EXPENSE_PROCESSOR, PERMISSION.EXPENSE_ADMIN],
          },
        ],
      },
      {
        title: 'Process Allowances',
        icon: ICON.PROCESS_ALLOWANCES,
        link: '/process-allowances',
        badgeKeys: ['processAllowances'],
        permissions: [PERMISSION.ALLOWANCE_PROCESSOR, PERMISSION.ALLOWANCE_ADMIN],
      },
    ],
  },
  {
    title: 'Sales Toolkit',
    icon: ICON.SALES_TOOLKIT,
    path: '/sales-toolkit',
    children: [
      {
        title: 'LED Designer',
        icon: ICON.LED_DESIGNER,
        link: '/led-designer',
      },
      {
        title: 'Room Designer',
        icon: ICON.ROOM_DESIGNER,
        link: '/room-designer',
      },
    ],
  },
  {
    title: 'Internal Projects',
    icon: ICON.INTERNAL_PROJECTS,
    path: '/internal-projects',
    permissions: [PERMISSION.INTERNAL_PROJECT_VIEWER, PERMISSION.INTERNAL_PROJECT_EDITOR],
    children: [
      {
        title: 'RD&E',
        icon: ICON.RDE_PROJECTS,
        link: '/rde-projects',
        permissions: [PERMISSION.INTERNAL_PROJECT_VIEWER],
      },
      {
        title: 'Product Development',
        icon: ICON.PRODUCT_DEVELOPMENT_PROJECTS,
        link: '/product-development-projects',
        permissions: [PERMISSION.INTERNAL_PROJECT_VIEWER],
      },
      {
        title: 'Internal Process',
        icon: ICON.INTERNAL_PROCESS_PROJECTS,
        link: '/internal-process-projects',
        permissions: [PERMISSION.INTERNAL_PROJECT_VIEWER],
      },
      {
        title: 'Internal Development',
        icon: ICON.INTERNAL_DEVELOPMENT_PROJECTS,
        link: '/internal-development-projects',
        permissions: [PERMISSION.INTERNAL_PROJECT_VIEWER],
      },
      {
        title: 'All Internal Projects',
        icon: ICON.INTERNAL_PROJECTS,
        link: '/internal-projects',
        permissions: [PERMISSION.SECURITY_ADMIN],
      },
      {
        title: 'Sprints',
        icon: ICON.SPRINTS,
        link: '/sprints',
        permissions: [PERMISSION.INTERNAL_PROJECT_VIEWER],
      },
      {
        title: 'Tasks',
        icon: ICON.TASKS,
        link: '/tasks',
        permissions: [PERMISSION.INTERNAL_PROJECT_VIEWER],
      },
      {
        title: 'IP Admin',
        icon: ICON.INTERNAL_PROJECTS,
        permissions: [PERMISSION.SECURITY_ADMIN],
        children: [
          {
            title: 'Sprints Gantt',
            icon: ICON.SPRINTS,
            link: '/sprints-gantt',
            permissions: [PERMISSION.INTERNAL_PROJECT_VIEWER],
          },
          {
            title: 'Task Templates',
            icon: ICON.TASK_TEMPLATES,
            link: '/task-templates',
            permissions: [PERMISSION.SECURITY_ADMIN],
          },
          {
            title: 'Teams',
            icon: ICON.TEAMS,
            link: '/teams',
            permissions: [PERMISSION.SECURITY_ADMIN],
          },
          {
            title: 'Platforms',
            icon: ICON.PLATFORMS,
            link: '/platforms',
            permissions: [PERMISSION.SECURITY_ADMIN],
          },
          {
            title: 'Processes',
            icon: ICON.PROCESSES,
            link: '/processes',
            permissions: [PERMISSION.SECURITY_ADMIN],
          },
        ],
      },
    ],
  },
  {
    title: 'Portal Settings',
    icon: ICON.PORTAL_SETTINGS,
    path: '/settings',
    permissions: [
      PERMISSION.SECURITY_USER_MANAGER,
      PERMISSION.SECURITY_ROLE_MANAGER,
      PERMISSION.API_ADMIN,
      PERMISSION.SECURITY_ADMIN,
    ],
    children: [
      {
        title: 'User Management',
        icon: ICON.USER_MANAGEMENT,
        link: '/user-management',
        permissions: [PERMISSION.SECURITY_USER_MANAGER, PERMISSION.SECURITY_ADMIN],
      },
      {
        title: 'States',
        icon: ICON.USER_MANAGEMENT,
        link: '/states',
        permissions: [PERMISSION.SECURITY_ADMIN],
      },
      {
        title: 'Integrations',
        icon: ICON.INTEGRATIONS,
        link: '/integrations',
        permissions: [PERMISSION.API_ADMIN],
      },
      {
        title: 'Security',
        icon: ICON.SECURITY,
        permissions: [
          PERMISSION.SECURITY_USER_MANAGER,
          PERMISSION.SECURITY_ROLE_MANAGER,
          PERMISSION.SECURITY_ADMIN,
        ],
        children: [
          {
            title: 'Role Audit',
            icon: ICON.ROLE_MANAGEMENT,
            link: '/security-roles',
            permissions: [PERMISSION.SECURITY_USER_MANAGER, PERMISSION.SECURITY_ROLE_MANAGER, PERMISSION.SECURITY_ADMIN],
          },
          {
            title: 'Permission Audit',
            icon: ICON.ROLE_MANAGEMENT,
            link: '/security-permissions',
            permissions: [PERMISSION.SECURITY_USER_MANAGER, PERMISSION.SECURITY_ROLE_MANAGER, PERMISSION.SECURITY_ADMIN],
          },
          {
            title: 'Role Matrix',
            icon: ICON.ROLES,
            link: '/role-matrix',
            permissions: [PERMISSION.SECURITY_USER_MANAGER, PERMISSION.SECURITY_ROLE_MANAGER, PERMISSION.SECURITY_ADMIN],
          },
          // {
          //   title: 'Group Management',
          //   icon: ICON.GROUP_MANAGEMENT,
          //   link: '/group-management',
          //   permissions: [PERMISSION.SECURITY_USER_MANAGER, PERMISSION.SECURITY_ROLE_MANAGER, PERMISSION.SECURITY_ADMIN],
          // },
        ],
      },
      {
        title: 'Maintenance',
        icon: ICON.MAINTENANCE,
        permissions: [
          PERMISSION.COMPANY_SPACE_TYPE_MAINTAINER,
        ],
        children: [
          {
            title: 'Company Space Types',
            icon: ICON.SPACE,
            link: '/company-space-types',
            permissions: [PERMISSION.COMPANY_SPACE_TYPE_MAINTAINER],
          },
        ],
      },
      {
        title: 'Debug',
        icon: ICON.DEBUG,
        devOnly: true,
        children: [
          {
            title: 'Lost Monkey / UFO',
            icon: ICON.LOST_MONKEY_UFO,
            link: '/huh? ',
            devOnly: true,
          },
          {
            title: 'Scared Monster',
            icon: ICON.SCARED_MONSTER,
            link: '/aaargh!',
            devOnly: true,
          },
          {
            title: 'Test Chart Playground',
            icon: ICON.EXECUTIVE_DASHBOARD,
            link: '/test-chart-playground',
            devOnly: true,
          },
        ],
      },
    ],
  },
];


/**
 * @description
 * Calculate the sidebar badge values by mapping the menu item definitions
 * to the locally cached list of user summary alert summary values.
 *
 * @param {string[]} badgeKeys a list of keys from `userSummaryBadgeMap` to accumulate
 * @param {Array} alertSummary an array containing the current known summary of alertSummary
 *
 * @returns {number}
 */
function getBadgeData(badgeKeys, alertSummary, colorKeys) {
  // No point proceeding if we don't yet know what the alert data looks like
  if (
    !alertSummary ||
    !badgeKeys ||
    (Array.isArray(alertSummary) && alertSummary.length === 0) ||
    (Array.isArray(badgeKeys) && badgeKeys.length === 0)
  ) {
    return 0;
  }

  let result = 0;

  badgeKeys.forEach((badgeKey) => {
    if (typeof userSummaryBadgeMap[badgeKey] === 'string') {
      const { value: count, found } = objectByString(alertSummary, userSummaryBadgeMap[badgeKey]);
      if (found) {
        result += count;
      }
    }
  });

  let color = 'info';

  const keyPriority = ['danger', 'warning', 'success', 'info'];

  // if the menu item includes keys for coloring badges, iterate over those keys, and flag accordingly
  if (colorKeys) {
    for (let index = 0; index < keyPriority.length; index += 1) {
      const keyColor = keyPriority[index];
      const badgeKey = colorKeys[keyColor];
      if (badgeKey && typeof userSummaryBadgeMap[badgeKey] === 'string') {
        const { value: count, found } = objectByString(alertSummary, userSummaryBadgeMap[badgeKey]);
        if (found && count > 0) {
          color = keyColor;
          // early return if match found, to avoid unnecessary looping
          return {
            badgeCount: result,
            badgeColor: color,
          };
        }
      }
    }
  }

  // should be the same as early return above but as fallthrough
  return {
    badgeCount: result,
    badgeColor: color,
  };
}


/**
 * @class
 * <SideBar /> component
 */
const SideBarComponent = ({ location, setHoverSidebar, navChangeListener }) => {
  const scrollbarPortalContainerRef = useRef(React.createRef());

  const { userAlerts, userHasPermissions } = useContext(CurrentUserContext);

  /**
   * Don't animate the sidebar on the first load
   */
  useEffect(() => {
    // Don't animate the sidebar on the first load.
    setTimeout(() => {
      document.body.classList.add('animate-sidebar');
    }, 500);

    // Kill the animate-sidebar class from the body on un-load
    return () => {
      document.body.classList.remove('animate-sidebar');
    };
  }, []);


  /**
   * @description
   * nestable iterator method, renders menus and child menus.
   * { { Recursive } }
   *
   * @param {string} path
   * @param {*} menuArray
   * @param {*} routeArray
   * @param {{id: string, alertCounts: [][]}} alertSummary
   */
  const renderArrayMenu = useCallback((path, menuArray, routeArray) => {
    // get the current route depth
    const clonedRouteArray = [...routeArray];
    const thisNavRoute = clonedRouteArray.shift();

    // iterate the menu items
    return menuArray.map((menuItem) => {
      // skip devonly items
      if (menuItem.devOnly === true && APP_ENV !== 'dev') return null;

      // if user doesn't have sufficient permissions, don't render the sidebar button
      if (
        // permissions are required
        menuItem.permissions &&
        !userHasPermissions(menuItem.permissions)
      ) {
        // then don't render anything yet
        return null;
      }

      const currentMenuPath = menuItem.path || menuItem.link || '';
      const nextRouteArray = `/${thisNavRoute}` === currentMenuPath ? clonedRouteArray : [];
      const selected = `/${thisNavRoute}` === currentMenuPath;

      const { badgeCount, badgeColor } = getBadgeData(menuItem.badgeKeys, userAlerts || [], menuItem.colorKeys);

      // If there are children, render a DropdownSubmenu, and recursively the children
      if (menuItem.children instanceof Object) {
        const nextPath = `${path}${currentMenuPath}`;
        return (
          <DropdownSubmenu
            icon={menuItem.icon}
            title={menuItem.title}
            key={`submenu-${slug(menuItem.title)}`}
            open={menuItem.open}
            selected={selected}
            badgeCount={badgeCount}
            badgeColor={badgeColor}
          >
            {renderArrayMenu(nextPath, menuItem.children, nextRouteArray)}
          </DropdownSubmenu>
        );
      }

      // If there are NO children, render the NavItem
      return (
        <NavItem
          icon={menuItem.icon}
          link={`${path}${menuItem.link}`}
          externalLink={menuItem.externalLink}
          key={`navitem-${slug(menuItem.title)}`}
          active={menuItem.link === `/${thisNavRoute}`}
          selected={selected}
          badgeCount={badgeCount}
          badgeColor={badgeColor}
          setHoverSidebar={setHoverSidebar}
        >
          {menuItem.title}
        </NavItem>
      );
    });
  }, [userHasPermissions, userAlerts, setHoverSidebar]);


  // shift off first empty value
  const routeArray = location.pathname.split('/');
  routeArray.shift();

  const projectFinderInput = document.getElementById('project_finder_input');

  // Returns <SideBar />
  return (
    <aside
      className="left-sidebar"
      onMouseEnter={() => setHoverSidebar(true)}
      onMouseLeave={() => {
        if (projectFinderInput && document.activeElement !== projectFinderInput) setHoverSidebar(false);
      }}
    >
      <div className="scroll-sidebar">
        <nav className="sidebar-nav">
          <ul id="sidebarnav">
            <li className="project-finder-wrapper">
              <ProjectFinder navChangeListener={navChangeListener} setHoverSidebar={setHoverSidebar} portalContainer="portal_container_sidebar" />
            </li>
            {renderArrayMenu('', linkedRouteMap, routeArray)}
          </ul>
        </nav>
        <div ref={scrollbarPortalContainerRef} id="portal_container_sidebar" className="portal-container sidebar" />
      </div>
    </aside>
  );
};

SideBarComponent.defaultProps = {
  setHoverSidebar: noop,
  navChangeListener: null,
};

SideBarComponent.propTypes = {
  setHoverSidebar: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  navChangeListener: PropTypes.instanceOf(EventEmitter),
};

export const SideBar = withRouter(SideBarComponent);
