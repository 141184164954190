import React, { createRef, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import 'viewerjs/dist/viewer.css';

import { CollectionWidgetProps } from '../../types/collection.widget.props';
import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { IFileRecord } from '../../types/file.record.interface';

import { CollectionWidget } from './collection.widget';
import { FileDropTarget } from '../layout-helpers/file-drop-target';
import { ImageViewer } from '../layout-helpers/image-viewer';
import { PDFViewer } from '../layout-helpers/pdf-viewer';

import { prepareNewRecordFormData } from '../poly-forms/prepare-new-record-form-data.helper';
import { hasPermittedAction } from '../../helpers/has-permitted-action.helper';

import { FORM_RENDERER_TYPE } from '../../constants/form-renderer-type.const';
import { isImageMimeType } from '../../constants/mime-type.const';
import { API_ACTION } from '../../constants/api-action.const';
import { usePreviousValue } from '../../react-hooks/use-previous-value.hook';

export type FilesWidgetProps<T extends IFileRecord> = CollectionWidgetProps<T>;

let newFileId = -1;

export const FilesWidget = <T extends IFileRecord, >(props: PropsWithChildren<FilesWidgetProps<T>>): React.ReactElement => {
  const {
    className,
    itemCaption = 'file',
    fields,
    parentId,
    widgetData = [],
    permittedActions,
    isReadOnly,
    widgetDataChecksum,

    addCollectionItem,
  } = props;

  // Keep track of the old checksum value
  const oldWidgetDataChecksum = usePreviousValue(widgetDataChecksum);

  // This ref is used to trigger the file input browser dialog
  const fileInputRef = createRef<HTMLInputElement>();

  // Keep a secondary list of image only items that is used to populate the lightbox viewer
  const [imageItems, setImageItems] = useState<T[]>([]);

  // This is the item that the PDF viewer is currently viewing
  const [viewingPDFItem, setViewingPDFItem] = useState<null | T>(null);

  // Whether the image viewer is visible
  const [lightboxVisible, setLightboxVisible] = useState<boolean>(false);
  const [lightboxImageId, setLightboxImageId] = useState<null | T['id']>(null);

  // Don't allow the addition of files if the parent record cannot be modified
  const [filesAreReadOnly, setFilesAreReadOnly] = useState<boolean>(!!isReadOnly || !hasPermittedAction(permittedActions, API_ACTION.UPDATE));

  /**
   * Fired when the user selects or drops file(s) onto the widget
   *
   * The FileFormRenderer is responsible for evaluating that the record is "new"
   * and performing the upload cycle
   */
  const handleAddFiles = useCallback((newFiles:File[]) => {
    if (addCollectionItem) {
      // Iterate over each of the files and initialise a collection item for each one.
      // Pass the collection items back up to the widget wrapper to be added to the collection
      addCollectionItem(newFiles.map((file) => (prepareNewRecordFormData(
        {
          // eslint-disable-next-line no-plusplus
          id: (newFileId--),
          filename: file.name,
          file_size: file.size,
          file,
        } as Partial<T>,
        fields,
        parentId,
      ))));
    }
  }, [addCollectionItem, fields, parentId]);


  /**
   * Fired when the user accepts the "browse for files" dialog
   */
  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files) {
      const files = Array.from(e.target.files);
      if (files.length > 0) {
        handleAddFiles(files);
      }
    }

    // Reset the file input for next time
    e.target.value = '';
  };


  /**
   * Handle the click of the add button
   */
  const handleCreateCollectionItem = useCallback(() => {
    // Don't do anything in this call. Browse for files instead.
    if (fileInputRef.current) fileInputRef.current.click();
    return {};
  }, [fileInputRef]);


  /**
   * Fired when the user clicks a collection item
   */
  const handleCollectionItemClick = useCallback((id: FormFieldComponentProps['value'], formData: Partial<T>) => {
    if (isImageMimeType(formData.content_type ?? null)) {
      setLightboxImageId(id);
      setLightboxVisible(true);
    } else {
      setViewingPDFItem(formData as T);
    }
  }, []);


  /**
   * Evaluate whether files can be added, removed or deleted whenever the permitted actions of the parent record change
   */
  useEffect(() => {
    setFilesAreReadOnly(!!isReadOnly || !hasPermittedAction(permittedActions, API_ACTION.UPDATE));
  }, [permittedActions, isReadOnly]);


  /**
   * When the widgetDataChecksum changes, we need to update the image items in our image array
   */
  useEffect(() => {
    if (oldWidgetDataChecksum !== widgetDataChecksum) {
      // Update the image items
      setImageItems(widgetData.filter((widgetDataItem) => isImageMimeType(widgetDataItem.content_type)));
    }
  }, [widgetDataChecksum, oldWidgetDataChecksum, widgetData]);


  // Render
  return (
    <>
      <CollectionWidget<T>
        {...props}
        className={classNames('files-widget', className)}
        formRendererType={FORM_RENDERER_TYPE.FILE}
        isReadOnly={filesAreReadOnly}
        itemCaption={itemCaption}
        createCollectionItem={handleCreateCollectionItem}
        onCollectionItemClick={handleCollectionItemClick}
      >
        {/* File input for triggering the OS "Browse" dialog. */}
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileInputChange}
          onClick={(e) => { e.stopPropagation(); }}
          multiple
        />
        <FileDropTarget
          onDropFiles={handleAddFiles}
          allowMultipleFiles
          showHint={widgetData.length === 0}
          dropHintText={filesAreReadOnly && (widgetData.length === 0) ? 'No files found' : undefined}
          enabled={!filesAreReadOnly}
        />
      </CollectionWidget>
      <ImageViewer
        images={imageItems}
        visible={lightboxVisible}
        onClose={() => setLightboxVisible(false)}
        viewingId={lightboxImageId}
      />
      <PDFViewer
        closePdfViewer={() => setViewingPDFItem(null)}
        isOpen={!!viewingPDFItem}
        filePath={viewingPDFItem?.downloadUrl ?? ''}
        fileSize={viewingPDFItem?.file_size ?? 0}
        filename={viewingPDFItem?.filename ?? ''}
        comment={viewingPDFItem?.comment ?? undefined}
      />
    </>
  );
};
