import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { ENVIRONMENT } from './utils/constants';
import Root from './components/root';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { store } from './redux-store';
import { clog, pr } from './components/layout-helpers/pr';

const mainWrapper = document.getElementById('main-wrapper');
mainWrapper.className = classNames({
  'env-dev': ENVIRONMENT === 'development',
  'env-uat': ENVIRONMENT === 'uat',
  'env-prod': !['development', 'uat'].includes(ENVIRONMENT),
});

global.clog = clog;
global.pr = pr;

// eslint-disable-next-line react/jsx-filename-extension
ReactDOM.render(<Root store={store} />, mainWrapper);

// Register the Service Worker and reload the page if a new version is detected
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    registration.unregister().then(() => {
      window.location.reload();
    });
  },
});
