export const SERVICE_JOB_TYPE: Record<string, number> = {
  SJ: 1,
  SF: 2,
  SM: 3,
  SR: 4,
} as const;
export type SERVICE_JOB_TYPE = typeof SERVICE_JOB_TYPE;
export type AN_SERVICE_JOB_TYPE = SERVICE_JOB_TYPE[keyof SERVICE_JOB_TYPE];

export const SERVICE_JOB_TYPE_NAMES: {[K in AN_SERVICE_JOB_TYPE]: string } = {
  [SERVICE_JOB_TYPE.SJ]: 'Service Call Job',
  [SERVICE_JOB_TYPE.SF]: 'Service Follow-Up',
  [SERVICE_JOB_TYPE.SM]: 'Scheduled Maintenance',
  [SERVICE_JOB_TYPE.SR]: 'Service Request',
} as const;

// 1    Service Call Job        A callout job for an incident           SJ
// 2    Service Follow-Up       A follow up visit for a Service Call    SF
// 3    Scheduled Maintenance   A Scheduled Maintenance Visit           SM
// 4    Service Request         Changes, Moves, Enhancements            SR
