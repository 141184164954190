import React from 'react';
import PropTypes from 'prop-types';

import DataTablePage from './data-table-page';
import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { CURRENT_USER_PROVIDER_PROP_TYPES } from '../../prop-types/current-user-provider-prop-types';
import { connectToCurrentUserProvider } from '../providers/current-user-provider';
import { API_FILTER_OPERATION } from '../../constants/api-filter-operation.const';

/**
 * @description
 * Display the "Manage Allowances" data table as a whole page
 */
const ManageAllowancesDataTablePage = (props) => {
  const { currentUserProvider: { userDetails } } = props;
  const baseFilters = [
    {
      field: 'approver_id',
      operation: API_FILTER_OPERATION.EQUALS,
      values: [userDetails.id],
    },
  ];

  return (
    <DataTablePage
      {...props}
      baseFilters={baseFilters}
      tableIdentifier={TABLE_IDENTIFIER.MANAGE_ALLOWANCES_TABLE}
    />
  );
};

ManageAllowancesDataTablePage.propTypes = {
  title: PropTypes.string,
  urlIdentifier: PropTypes.string,
  currentUserProvider: PropTypes.shape(CURRENT_USER_PROVIDER_PROP_TYPES).isRequired,
};

ManageAllowancesDataTablePage.defaultProps = {
  title: 'Manage Allowances',
  urlIdentifier: undefined,
};

export default connectToCurrentUserProvider(ManageAllowancesDataTablePage);

