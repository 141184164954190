import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { usePreviousValue } from '../../../react-hooks/use-previous-value.hook';

import { APIContext } from '../../providers/api-provider';

import { APIPolyFormProps } from '../../../types/poly-form/api-poly-form.props';
import { ICompanySpaceRecord } from '../../../types/company/company-space.record.interface';
import { IContractRecord } from '../../../types/service/contract.record.interface';
import { ITabDefinition } from '../../../types/tabs-with-more/tab-definition.interface';

import { APIPolyForm } from '../../poly-forms/api-poly-form';
import { LoadingSpinner } from '../../layout-helpers/loading-spinner';
import { TabsWithMore } from '../../tabs-with-more/tabs-with-more';

import { apiAborter } from '../../../helpers/api-aborter.helper';
import FriendlyFormMessage from '../../layout-helpers/friendly-form-message';
import { buildAPIRoute } from '../../../helpers/build-api-route.helper';
import { InMemoryTable } from '../../portal-data-table/in-memory-table';
import contractsTableInitialSettings from '../../../table-definitions/contracts-table';
import { InMemoryDataTableColumn } from '../../../types/portal-data-table/in-memory-table-types';
import { toParams, toQueryString } from '../../../utils/helpers';

export type CompanyLocationsWidgetSelectedSpaceDetailProps = APIPolyFormProps<ICompanySpaceRecord> & {
  isLoading: boolean,
};

const SPACE_DETAIL_TAB = {
  DETAILS: 'details',
  CONTRACTS: 'contracts',
} as const;
type SPACE_DETAIL_TAB = typeof SPACE_DETAIL_TAB;
type A_SPACE_DETAIL_TAB = SPACE_DETAIL_TAB[keyof SPACE_DETAIL_TAB];

interface ISpaceDetailTabDefinition extends ITabDefinition {
  name: A_SPACE_DETAIL_TAB,
}

const spaceDetailTabs: ISpaceDetailTabDefinition[] = [
  {
    name: SPACE_DETAIL_TAB.DETAILS,
    title: 'Details',
  },
  {
    name: SPACE_DETAIL_TAB.CONTRACTS,
    title: 'Contracts',
  },
];

interface ICompanyLocationsWidgetSelectedSpaceDetailUrlState {
  t: string,
}

/**
 * Build a string from some details about the state of the company locations widget
 * selected space
 */
const buildClwssUrlParamString = (_activeTabKey: string): string => (
  `t=${_activeTabKey}`
);


/**
 * Take the parameters from the URL that are specific to the company locations widget selected space detail
 * and convert the contents to a PortalTreeView URL State
 */
export const parseCompanyLocationsWidgetSelectedSpaceDetailUrlState = (urlParams?: string): ICompanyLocationsWidgetSelectedSpaceDetailUrlState => {
  const result: ICompanyLocationsWidgetSelectedSpaceDetailUrlState = {
    t: SPACE_DETAIL_TAB.DETAILS,
  };
  if (urlParams) {
    // Split on each of the main URL components
    const urlComponents = urlParams.split(';');

    // Iterate over each component
    urlComponents.forEach((urlComponent) => {
      // Get the Param Key and Value
      const keyValueSplit = urlComponent.split('=');
      const key: string = keyValueSplit[0];
      const value = keyValueSplit[1];

      if (Object.keys(result).includes(key)) {
        // Parse a set of array values separated by commas (,)
        // if (Array.isArray(result[key as keyof ICompanyLocationsWidgetSelectedSpaceDetailUrlState])) {
        //   result[key as keyof ICompanyLocationsWidgetSelectedSpaceDetailUrlState] = value.split(',');
        // }

        // Parse a single value
        // else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        result[key as keyof ICompanyLocationsWidgetSelectedSpaceDetailUrlState] = value as any;
        // }
      }
    });
  }

  return result;
};


const URL_PARAM_KEY = 'clwss';

export const CompanyLocationsWidgetSelectedSpaceDetail: React.FC<CompanyLocationsWidgetSelectedSpaceDetailProps> = (props) => {
  const {
    isLoading,
    parentId,
    formData: companyLocation,
  } = props;

  const [activeTabKey, setActiveTabKey] = useState<A_SPACE_DETAIL_TAB>('details');
  const oldActiveTabKey = usePreviousValue(activeTabKey);

  const { apiFetch } = useContext(APIContext);
  const contractsAbortController = useRef<undefined | AbortController>();

  const { search: locationQueryString } = useLocation();
  const oldLocationQueryString = usePreviousValue(locationQueryString);
  const history = useHistory();

  const companyLocationId = useMemo(() => companyLocation?.id, [companyLocation]);
  const oldCompanyLocationId = usePreviousValue(companyLocationId);

  const [assignedContracts, setContractsData] = useState<{
    isLoading: boolean,
    error: null | string,
    data: IContractRecord[],
  }>({
    isLoading: true,
    error: null,
    data: [],
  });


  /**
   * Load the Contracts associated with the location
   */
  const loadContracts = useCallback(async () => {
    if (contractsAbortController.current) {
      contractsAbortController.current.abort();
      contractsAbortController.current = undefined;
    }

    setContractsData((oldContractsData) => ({
      ...oldContractsData,
      isLoading: true,
      error: null,
    }));

    const url = buildAPIRoute({
      baseRoute: '/company',
      parentId,
      primaryKeyValue: companyLocationId,
      apiRoute: '/space',
      apiQuery: [
        'with[]=contracts',
        'with[]=contracts.type',
        'with[]=contracts.subtype',
        'with[]=contracts.status',
      ].join('&'),
    });

    contractsAbortController.current = apiAborter();
    const response = await apiFetch(url, {
      signal: contractsAbortController.current.signal,
    });

    if (response.success) {
      contractsAbortController.current = undefined;
      setContractsData(() => ({
        isLoading: false,
        error: null,
        data: response.body.data.contracts as IContractRecord[],
      }));
    } else if (!response.aborted) {
      contractsAbortController.current = undefined;
      setContractsData(() => ({
        isLoading: false,
        error: response.error ?? 'An unexpected error has occurred',
        data: [],
      }));
    }
  }, [apiFetch, companyLocationId, parentId]);


  /**
   * Update the Url to save the state details to the URL
   */
  const updateUrlFromState = useCallback(() => {
    const urlParams = toParams(locationQueryString);
    const clwssParamString = urlParams[URL_PARAM_KEY];

    const newClwssParamString = buildClwssUrlParamString(activeTabKey);

    if (clwssParamString !== newClwssParamString) {
      urlParams[URL_PARAM_KEY] = newClwssParamString;
      const newQueryString = toQueryString(urlParams);
      history.replace({ search: newQueryString });
    }
  }, [locationQueryString, activeTabKey, history]);


  /**
   * Fired when the URL changes and the state managed by the URL needs to be reflected inside the component
   */
  const updateStateFromURL = useCallback((newLocationQueryString: string) => {
    const urlParams = toParams(newLocationQueryString);
    const clwssParamString = urlParams[URL_PARAM_KEY];

    const newClwssParamString = buildClwssUrlParamString(activeTabKey);

    if (clwssParamString !== newClwssParamString) {
      const urlState = parseCompanyLocationsWidgetSelectedSpaceDetailUrlState(clwssParamString);

      // Are the IDs in the URL different from the internal selected node ids?
      if (activeTabKey !== urlState.t) {
        setActiveTabKey(urlState.t as A_SPACE_DETAIL_TAB);
      }
    }
  }, [activeTabKey]);


  /**
   * Whenever the location ID changes, load the contracts
   */
  useEffect(() => {
    if (!isLoading && (companyLocationId !== oldCompanyLocationId)) {
      loadContracts();
    }
  }, [companyLocationId, oldCompanyLocationId, isLoading, loadContracts]);


  /**
   * Prevent the data from loading after the component has unmounted
   */
  useEffect(() => () => {
    if (contractsAbortController.current) contractsAbortController.current.abort();
  }, []);


  /**
   * Fired whenever the location query string changes and the component needs to be updated to match
   * the state managed inside the query string
   */
  useEffect(() => {
    if (
      // Location string has changed
      (oldLocationQueryString !== locationQueryString)
    ) {
      updateStateFromURL(locationQueryString);
    }
  }, [locationQueryString, oldLocationQueryString, updateStateFromURL]);


  /**
   * Fired whenever the data that should be stored in the URL changes
   */
  useEffect(() => {
    if (oldActiveTabKey && (activeTabKey !== oldActiveTabKey)) {
      // Update the URL to ensure the selected nodes are in the URL
      updateUrlFromState();
    }
  }, [activeTabKey, oldActiveTabKey, updateUrlFromState]);


  // Render
  return (
    <div className="company-space-detail">
      <TabsWithMore
        activeTabKey={activeTabKey}
        tabs={spaceDetailTabs}
        rowData={companyLocation ?? {}}
        changeTab={(newTabKey) => setActiveTabKey(newTabKey as A_SPACE_DETAIL_TAB)}
      />

      {/* Record Details Tab */}
      {activeTabKey === SPACE_DETAIL_TAB.DETAILS && (
        <APIPolyForm<ICompanySpaceRecord>
          {...props}
          showHeader
          hideBottomButtons
          primaryKeyFieldName="id"
        />
      )}

      {/* Contracts Tab */}
      {/* TODO: at some point provide a refresh / reload capability */}
      {activeTabKey === SPACE_DETAIL_TAB.CONTRACTS && (
        <>
          {/* No assigned contracts */}
          {!assignedContracts.isLoading && !assignedContracts.error && (assignedContracts.data.length === 0) && (
            <div className="no-contracts">
              <span>This space is not assigned to any Contracts.</span>
            </div>
          )}

          {/* In Memory Table */}
          {!assignedContracts.isLoading && !assignedContracts.error && (assignedContracts.data.length > 0) && (
            <div className="imt-wrapper">
              <InMemoryTable
                title="Assigned Contracts"
                columns={
                  contractsTableInitialSettings.columns.filter((column) => [
                    'number',
                    'type',
                    'subtype',
                    'status',
                    'start_date',
                    'end_date',
                  ].includes(column.name)) as InMemoryDataTableColumn[]
                }
                data={assignedContracts.data}
              />
            </div>
          )}

          {/* Loading */}
          {(assignedContracts.isLoading || isLoading) && (
            <div className="no-contracts loading">
              <LoadingSpinner caption="Please wait..." />
            </div>
          )}

          {/* Error */}
          {(!assignedContracts.isLoading && assignedContracts.error) && (
            <div className="no-contracts error">
              <FriendlyFormMessage
                hasErrors
                formMessage={assignedContracts.error}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
