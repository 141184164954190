import React from 'react';
import classNames from 'classnames';

export type PortalMultiLevelDropDownSeparatorProps = {
  className?: string,
};

export const PortalMultiLevelDropDownSeparator: React.FC<PortalMultiLevelDropDownSeparatorProps> = (props) => {
  const {
    className,
  } = props;

  return (
    <li
      className={classNames('pmldd-separator', className)}
      role="menuitem"
    >
      <div className="separator-line" />
    </li>
  );
};
