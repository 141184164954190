import { IFormFieldDefinition } from '../../types/poly-form/form-field-definition.interface';
import { COLUMN_FORMAT } from '../column-format.const';
import { FORM_FIELD_TYPE } from '../form-field-type.const';

export const companySpaceFormFields: IFormFieldDefinition[] = [
  {
    name: 'company_id',
    visible: false,
    isParentId: true,
  },

  {
    name: 'location',
    title: 'Parent Location',
    description: 'Parent Location - the detail-level location of this Space',
    format: COLUMN_FORMAT.OBJECT,
    object: {
      sourceField: 'location',
      key: 'name',
      format: COLUMN_FORMAT.TEXT,
    },
    formFieldType: FORM_FIELD_TYPE.COMPANY_LOCATION_PICKER,
    formSaveField: 'location_id',
    visible: true,
    searchRoute: (formData) => `/company/${formData.company_id}/location`,
    isCreatable: true,
    getNewItemData: (inputValue, formData) => ({
      company_id: formData?.company_id ?? null,
      company: formData?.company ?? null,
    }),
  },


  {
    name: 'name',
    title: 'Space Name',
    description: 'The space name',
    format: COLUMN_FORMAT.TEXT,
    visible: true,
    required: true,
  },

  {
    name: 'type',
    title: 'Space Type',
    description: 'I.e. Meeting Room, Huddle Space, Scoreboard, Tin Shed, etc...',
    format: COLUMN_FORMAT.OBJECT,
    object: {
      sourceField: 'type',
      key: 'name',
      format: COLUMN_FORMAT.COMPANY_SPACE_TYPE,
    },
    formFieldType: FORM_FIELD_TYPE.COMPANY_SPACE_TYPE_PICKER,
    formSaveField: 'type_id',
    visible: true,
    required: true,
    isCreatable: true,
  },

  {
    name: 'description',
    title: 'Description',
    description: 'A detailed description about where this space is, what it is used for etc...',
    format: COLUMN_FORMAT.TEXT,
    visible: true,
  },
  {
    name: 'path',
    title: 'Full Space Name',
    description: 'The space name with hierarchy.',
    format: COLUMN_FORMAT.TEXT,
    formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    visible: true,
    required: true,
  },
];
