import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { useHistory, useLocation } from 'react-router';

import { IInternalProjectRecord } from '../../types/internal-project/internal-project.record.interface';

import { APIContext } from '../providers/api-provider';
import { CurrentUserContext } from '../providers/current-user-provider';
import { ModalContext } from '../modals/modal-context';

import PageHeader from '../app-layout/page-header';
import { CardSwimlanes } from '../project-card-view/card-swimlanes';
import DataTablePage from '../data-table-pages/data-table-page';

import { documentTitle, mergeActions } from '../../utils/helpers';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { loadLocalPrefs, saveLocalPref } from '../../utils/localStorage';

import { PERMISSION } from '../../constants/permissions.const';
import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import internalProjectsTableInitialSettings from '../../table-definitions/internal-projects-table';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { NewRecordModalResult } from '../../types/modal/modal-result';
import { InfiniteCardView } from '../project-card-view/infinite-card-view';
import { statusSwimlaneMap } from '../project-card-view/status-swimlane-map';
import { titleCase } from '../../utils/title-case';
import { INTERNAL_PROJECT_TYPE_NAMES, INTERNAL_PROJECT_TYPE_ROUTES } from '../../constants/internal-project-type.const';
import { IPortalDataTablePageProps } from '../../types/portal-data-table/portal-data-table-page.props';
import { API_FILTER_OPERATION } from '../../constants/api-filter-operation.const';
import { AN_INTERNAL_PROJECT_STATUS } from '../../constants/internal-project-status.const';

interface IInternalProjectMultiViewProps extends IPortalDataTablePageProps {
  projectTypeId?: number,
  [key: string]: unknown,
}

interface IProjectLoadResult {
  working: boolean,
  error: boolean,
  data: IInternalProjectRecord[],
  availableActions: { [key:string]: unknown }
  totalRecords: number,
}
interface IQueryData {
  page: number, sortOrder: string
}

interface ILocalPrefs {
  internalProjectsMultiViewMode?: string,
}

/**
 * Internal Project Multi-View
 *
 * @param {InternalProjectMultiViewProps}  props
 * @param {string}                          props.title   a default title for the view
 */
export const InternalProjectMultiView: React.FC<IInternalProjectMultiViewProps> = ({
  title,
  projectTypeId,
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const urlQuery = new URLSearchParams(useLocation().search);
  const { internalProjectsMultiViewMode }: ILocalPrefs = loadLocalPrefs();

  let multiViewMode = urlQuery.get('multiview-mode') || internalProjectsMultiViewMode || 'cards';
  if (['cards', 'swimlanes', 'table'].indexOf(`${multiViewMode}`) < 0) multiViewMode = 'cards';

  const [projectLoadResult, setProjectLoadResult] = useState<IProjectLoadResult>({
    working: false,
    error: false,
    data: [],
    availableActions: {},
    totalRecords: -1,
  });
  const [queryData, setQueryData] = useState<IQueryData>({ page: 1, sortOrder: 'asc' });
  const [viewMode, setViewMode] = useState<string>(multiViewMode);
  const [hideEmptyColumns, setHideEmptyColumns] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string|null>(null);
  const [showStatus, setShowStatus] = useState<AN_INTERNAL_PROJECT_STATUS|null>(null);

  const { apiFetch } = useContext(APIContext);
  const { userHasPermissions } = useContext(CurrentUserContext);
  const history = useHistory();
  const { showModal } = useContext(ModalContext);

  const addInternalProjectAbortController = useRef<AbortController | null>(null);

  // set baseFilters for when a type ID is supplied
  const baseFilters = [
    {
      field: 'type_id',
      operation: API_FILTER_OPERATION.EQUALS,
      values: [projectTypeId],
    },
  ];

  // portal datatable doesn't requery for undefined basefilters
  // this is a workaround
  const uselessBaseFilters = [
    {
      field: 'type_id',
      operation: API_FILTER_OPERATION.GREATER_THAN,
      values: [0],
    },
  ];

  const viewRoute = projectTypeId ? INTERNAL_PROJECT_TYPE_ROUTES[projectTypeId] : 'internal-projects';

  const loadInternalProjects = useCallback(async (isPageAdd = false) => {
    if (addInternalProjectAbortController.current) {
      addInternalProjectAbortController.current.abort();
    }
    addInternalProjectAbortController.current = apiAborter();

    const workingLoadResult = {
      ...projectLoadResult,
      working: true,
    };
    if (!isPageAdd) workingLoadResult.data = [];
    setProjectLoadResult(workingLoadResult);

    const pageString = `page=${queryData.page}`;
    const searchString = searchTerm === null || searchTerm === '' ? '' : `&search=${encodeURIComponent(searchTerm)}`;
    const typeIdFilter = projectTypeId ? `&filter[0][field]=type_id&filter[0][operation]=equals&filter[0][value]=${projectTypeId}` : '';
    const statusIdFilter = showStatus ? `&filter[1][field]=status_id&filter[1][operation]=equals&filter[1][value]=${showStatus}` : '';
    const baseQueryString = [
      'sort[0][field]=priority',
      `sort[0][direction]=${queryData.sortOrder}`,
      'with[]=item',
      'with[]=item.createdBy:id,name',
      'with[]=item.internalProject:id,name,status_id',
      'with[]=projectLead:id,name',
      'with[]=owner:id,name',
      'with[]=createdBy:id,name',
      'with_count[]=item',
      'pagelength=100',
    ].join('&');

    const response = await apiFetch(
      `/internal-project?${pageString}&${baseQueryString}${typeIdFilter}${searchString}${statusIdFilter}`,
      {
        method: HTTP_METHOD.GET,
        name: 'ProjectCardViewPageListData:load',
        signal: addInternalProjectAbortController?.current?.signal,
      },
    );

    const existingData = projectLoadResult.data || [];
    const updateData = isPageAdd ? [...existingData] : [];

    if (response.success) {
      addInternalProjectAbortController.current = null;
      const newProjectData = response.body.data;
      const totalRecords = response.body.meta.total || -1;
      newProjectData.map((item: IInternalProjectRecord) => updateData.push(item));
      setProjectLoadResult({
        working: false,
        error: false,
        data: updateData,
        availableActions: response.body.actions || {},
        totalRecords,
      });
    } else if (!response.aborted) {
      addInternalProjectAbortController.current = null;
      setProjectLoadResult({
        working: false,
        error: true,
        data: existingData,
        availableActions: {},
        totalRecords: -1,
      });
    }
  }, [projectTypeId, showStatus, searchTerm, queryData, projectLoadResult, setProjectLoadResult, apiFetch]);

  /** handler for additional page load */
  const loadNextPage = () => {
    setQueryData({
      ...queryData,
      page: queryData.page += 1,
    });
    if (userHasPermissions(PERMISSION.INTERNAL_PROJECT_VIEWER)) {
      loadInternalProjects(true);
    }
  };

  /**
   * @function changeSortOrderSelect Handle Sort Order <select /> change
   * @param event
   */
  const changeSorterOrderSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setQueryData({
      page: 1,
      sortOrder: event.target.value,
    });
  };

  /** Load Projects on load, and when sort order changes */
  useEffect(() => {
    if (userHasPermissions(PERMISSION.INTERNAL_PROJECT_VIEWER)) {
      loadInternalProjects();
    }

    return () => {
      // Kill any outstanding load when the component is un-mounted
      if (addInternalProjectAbortController.current) {
        addInternalProjectAbortController.current.abort();
      }
    };// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData.sortOrder, viewMode, showStatus, searchTerm, projectTypeId]);

  /**
   * @function changeViewMode change view and document title on <select /> change
   *
   * @param event system event for select change
   */
  const changeViewMode = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const view = event.target.value;
    setViewMode(view);
    saveLocalPref('internalProjectsMultiViewMode', view);
    documentTitle(`Internal Project ${titleCase(view)}`);
  };

  const searchHandler = (term: string) => {
    setSearchTerm(term);
  };

  const statusFilterHandler = (status: AN_INTERNAL_PROJECT_STATUS) => {
    setShowStatus(status);
  };

  const createNewProjectHandler = () => {
    showModal<NewRecordModalResult<IInternalProjectRecord>>(MODAL_TYPE.NEW_INTERNAL_PROJECT, {
      onModalComplete: (modalResult) => {
        if (modalResult.success) history.push(`/internal-projects/${viewRoute}/${modalResult.newRecord.id}`);
      },
      initialData: {
        type_id: projectTypeId,
        type: projectTypeId ? { id: projectTypeId, name: INTERNAL_PROJECT_TYPE_NAMES[projectTypeId] } : {},
        status_id: 1,
      },
      formFieldOverrides: [
        {
          name: 'type',
          visible: true,
          isReadOnly: projectTypeId !== undefined,
        },
        {
          name: 'status',
          isReadOnly: false,
        },
      ],
    });
  };

  /** @var actionHandlers create new project actionHandler for table view */
  const actionHandlers = {
    create: createNewProjectHandler,
  };

  const permittedActions = mergeActions(
    projectLoadResult.availableActions,
    internalProjectsTableInitialSettings.possibleActions,
    actionHandlers,
  );

  /** Picker title to pass in */
  const viewPickerTitle = (
    <div className="form-inline m-r-2 m-l-2">
      {title}
      <label htmlFor="sort-order-select">
        &nbsp; | &nbsp; View as: &nbsp; &nbsp;
        <select
          name="sort-order-select"
          className="form-control form-control-sm"
          value={viewMode}
          onChange={changeViewMode}
        >
          <option value="cards">Cards</option>
          <option value="swimlanes">Swimlanes</option>
          <option value="table">Table</option>
        </select>
      </label>
    </div>
  );

  return viewMode !== 'table' ? (
    <Container fluid className="card-view">
      <PageHeader
        title={title}
        permittedActions={permittedActions}
      >
        {viewPickerTitle}
      </PageHeader>
      {viewMode === 'swimlanes' && (
        <Card>
          <CardHeader className="header-body">
            <h3 style={{ fontWeight: 100 }}>
              Projects by Priority
            </h3>
            <div className="form-inline">
              <label htmlFor="hide-empty-columns">
                Hide empty columns &nbsp;
                <input type="checkbox" name="hide-empty-columns" checked={hideEmptyColumns} onChange={(event) => setHideEmptyColumns(event.target.checked)} />
              </label>
            </div>
          </CardHeader>
          <CardBody>
            {projectLoadResult.data.length > 0 && (
              <CardSwimlanes
                cardItems={projectLoadResult.data}
                swimlaneMap={statusSwimlaneMap}
                cardLinkPath={`/internal-projects/${viewRoute}`}
                hideEmptyColumns={hideEmptyColumns}
                tableSettings={internalProjectsTableInitialSettings}
              />
            )}
          </CardBody>
        </Card>
      )}
      {viewMode === 'cards' && (
        <InfiniteCardView
          data={projectLoadResult.data}
          totalRecords={projectLoadResult.totalRecords}
          searchTerm={searchTerm}
          loadNextPage={loadNextPage}
          changeSorterOrderSelect={changeSorterOrderSelect}
          onSearch={searchHandler}
          onStatusFilter={statusFilterHandler}
        />
      )}
    </Container>
  ) : (
    <DataTablePage
      title={title}
      headerChildren={viewPickerTitle}
      tableIdentifier={TABLE_IDENTIFIER.INTERNAL_PROJECTS_TABLE}
      actionHandlers={actionHandlers}
      baseFilters={projectTypeId ? baseFilters : uselessBaseFilters}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      {children}
    </DataTablePage>
  );
};
