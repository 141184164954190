import React from 'react';
import moment from 'moment';

import { ILeaveApplicationRecord } from '../../types/leave/leave-application.record.interface';
import { WidgetProps } from '../../types/widget.props';

import AvailableLeave from '../available-leave/available-leave';

import { DATE_FORMAT } from '../../constants/date-format.const';

export type AvailableLeaveWidgetProps = Omit<WidgetProps, 'rowData'> & {
  rowData: ILeaveApplicationRecord,
}

export const AvailableLeaveWidget: React.FC<AvailableLeaveWidgetProps> = ({ rowData: { user_id: userId, min_of_leaveBlocks_start_date } }) => {
  const atDate = moment(min_of_leaveBlocks_start_date, DATE_FORMAT.YEAR_MONTH_DAY_DASHES).toDate();
  return <AvailableLeave userId={userId} atDate={atDate} datePickerButton />;
};
