import React, { useEffect, useState } from 'react';
import AsyncSelect from './async-select';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';
import { ICommissionBracketRecord } from '../../types/finance/commission-bracket.record.interface';

export type CommissionBracketPickerProps = AsyncSelectComponentProps;

export const CommissionBracketPicker: React.FC<CommissionBracketPickerProps> = (props) => {
  const {
    name,
    formData = {},
    parentData = {},
    formSaveField = null,
    onChange,
  } = props;

  const [iterationId, setIterationId] = useState(formData.commission_iteration_id || parentData.commission_iteration_id || null);

  useEffect(() => {
    const newIterationId = formData.commission_iteration_id || parentData.commission_iteration_id || null;
    if (newIterationId !== iterationId) {
      setIterationId(formData.commission_iteration_id || parentData.commission_iteration_id || null);
      // reset the field if the value has changed
      if (onChange) {
        onChange({
          fieldName: formSaveField || `${name}_id`,
          newValue: null,
          objectFieldName: name,
          objectFieldNewValue: null,
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentData, formData]);


  return (
    <AsyncSelect
      {...props}
      loadAndKeepAll
      cacheOptions
      defaultOptions
      useSearch={false}
      getOptionLabel={(option: ICommissionBracketRecord) => (
        option ? `${option.id}. Margin Threshold: ${option.net_profit_threshold}% | Commission Rate: ${option.commission_percentage}%` : null
      )}
      searchRoute={`/commission-structure/iteration/${iterationId}/bracket?annoying=`}
    />
  );
};
