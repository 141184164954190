import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { ICON } from '../constants/icon.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';
import { CONTRACT_STATUS } from '../constants/contract-status.const';

/**
 * Table Definition for People (Contracts) Table
 */
const contractSubtypesTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Contract Subtype',
  initialFormFocusFieldName: 'first',

  baseRoute: '/contract-subtype',
  baseQueryString: 'with[]=type&with[]=status',

  /**
   * Reset the local storage settings if they were stored with any of these app versions
   * Add application version numbers to this list (make sure it is a PREVIOUS application
   * version number which reflects a now invalid set of settings)
   */
  resetForAppVersion: [
    '2.1.6',
    '2.7.9',
  ],

  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'contract_type',
        'status',
        'name',
        'description',
        'available_start_date',
        'available_end_date',
        'allow_multiple_subtypes_on_spaces',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'contract_type',
        'status',
        'name',
        'description',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'contract_type',
        'status',
        'name',
        'description',
        'available_start_date',
        'available_end_date',
        'allow_multiple_subtypes_on_spaces',
      ],
    },
  ],

  /**
   * Definition of columns and APIPolyForm fields for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Identity',
      description: 'Contract Subtype Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      linkRoute: '/service-and-support/admin/contract-subtypes',
      visible: false,
      showInForm: false,
      order: 10,
      formOrder: 10,
    }, {
      name: 'contract_type',
      title: 'Contract Type',
      format: COLUMN_FORMAT.OBJECT,
      formFieldType: FORM_FIELD_TYPE.CONTRACT_TYPE_PICKER,
      object: {
        key: 'name',
        sourceField: 'type',
      },
      visible: true,
      order: 11,
      formOrder: 11,
    }, {
      name: 'status',
      title: 'Status',
      format: COLUMN_FORMAT.OBJECT,
      formFieldType: FORM_FIELD_TYPE.CONTRACT_SUBTYPE_STATUS_PICKER,
      object: {
        key: 'name',
        sourceField: 'status',
        format: COLUMN_FORMAT.STATUS_BADGE,
      },
      visible: true,
      order: 12,
      formOrder: 12,
    }, {
      name: 'name',
      title: 'Name',
      visible: true,
      order: 20,
      formOrder: 20,
    }, {
      name: 'description',
      title: 'Description',
      visible: true,
      order: 30,
      formOrder: 30,
    }, {
      name: 'available_start_date',
      title: 'Available Start Date',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: true,
      order: 40,
      formOrder: 40,
    }, {
      name: 'available_end_date',
      title: 'Available End Date',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: true,
      order: 50,
      formOrder: 50,
    }, {
      name: 'allow_multiple_subtypes_on_spaces',
      title: 'Allow Space Subtypes',
      format: COLUMN_FORMAT.CHECKBOX,
      formFieldType: FORM_FIELD_TYPE.CHECKBOX,
      visible: true,
      order: 60,
      formOrder: 60,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add a Contract Subtype',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new Contract Subtype',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the contract Subtype',
      successMessage: 'Updated contracts',
      failMessage: 'Failed to update Contract Subtype',
      hideRecordActionButton: true,
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the Contract Subtype',
      successMessage: 'Contract deleted',
      failMessage: 'Failed to delete Contract Subtype',
      hideRecordActionButton: true,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Contract\'s Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'contracts',
      title: 'Live Contracts',
      description: 'Active & Potential Future Contrats',
      widgetDefinition: WIDGET_DEFINITION.TYPE_CONTRACTS,
      apiRoute: (type) => `/contract?${[
        'with[]=type',
        'with[]=subtype',
        'with[]=managedByState:id,acronym',
        'with[]=status:id,name',
        'with[]=billingType:id,name',
        'with[]=client:id,name',
        'with[]=provider:id,name',
        'with[]=project:id,project_number,partner_program_id,dtools_id,name',
        'with[]=renewedFrom:id,type_id,status_id,number',
        'with[]=renewedFrom.type:id,prefix',
        'with[]=renewedTo:id,type_id,status_id,number',
        'with[]=renewedTo.type:id,prefix',
        'with[]=region:id,name',
        'with_count[]=note',
        'with_count[]=serial',
        'with_count[]=companySpace',
        'with_count[]=invoiceRequests',
        'filter[0][field]=status_id',
        `filter[0][operation]=${API_FILTER_OPERATION.IN}`,
        `filter[0][value][0]=${CONTRACT_STATUS.OPTION}`,
        `filter[0][value][1]=${CONTRACT_STATUS.NEGOTIATION}`,
        `filter[0][value][2]=${CONTRACT_STATUS.QUOTED}`,
        `filter[0][value][3]=${CONTRACT_STATUS.PENDING}`,
        `filter[0][value][4]=${CONTRACT_STATUS.ACTIVE}`,
        'filter[1][field]=subtype_id',
        `filter[1][operation]=${API_FILTER_OPERATION.EQUALS}`,
        `filter[1][value]=${type.id}`,
        'pagelength=30',
      ].join('&')}` },
    {
      ...eventsTabDefault,
      description: "Contract Subtype's Event Audit Trail",
    },
  ],
};

export default contractSubtypesTableInitialSettings;
