import { useState } from 'react';

/**
 * Simple hook to flag that data has been loaded
 *
 * usage:
 *    const [isDataLoaded, setDataIsLoaded] = useIsDataLoaded();
 *
 *    if (isDataLoaded) {
 *      // do Something different when the data has been loaded
 *    }
 *
 *    useEffect(() => {
 *      setDataIsLoaded();
 *    }, []);
 */
export const useIsDataLoaded = (): [boolean, ()=>void] => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const setDataLoaded = () => setIsDataLoaded(true);

  return [isDataLoaded, setDataLoaded];
};
