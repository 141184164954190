import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProjectSummaryTableCellGroup from './project-summary-table-cell-group';
import ProjectSummaryTableRow from './project-summary-table-row';
import { DATA_TYPE, buildDomDataTagId } from './project-summary-helpers';
import Icon from '../../layout-helpers/icon';
import { projectNameWithClientName } from '../sales-forecast-dashboard/sales-forecast-helpers';
import { ProjectNumber } from '../../data-format/project-number';
import StatusBadge from '../../data-format/status-badge';

const ProjectSummaryTableProjectSummaryDataRows = (props) => {
  const {
    summaryFields,
    visibleSummaryFieldCount,

    onClick,
    onBeginHover,
    onEndHover,

    projectData,
  } = props;

  const summaryFieldsWithoutCountField = summaryFields.filter((summaryField) => summaryField.key !== 'count');

  return (
    !!projectData && (
      <>
        {/* Error loading Project Summary Data */}
        {projectData.error && !projectData.isLoading && (
          <ProjectSummaryTableRow
            className="project summary data no-data error"
          >
            <ProjectSummaryTableCellGroup span={visibleSummaryFieldCount}>
              <Icon i="exclamation" />
              <span>{`Error: ${projectData.error}`}</span>
            </ProjectSummaryTableCellGroup>
          </ProjectSummaryTableRow>
        )}

        {/* No Project Data, No Error, Not loading */}
        {!projectData.summaryData.data.length && !projectData.isLoading && !projectData.error && (
          <ProjectSummaryTableRow
            className="project summary data no-data"
          >
            <ProjectSummaryTableCellGroup span={visibleSummaryFieldCount}>
              <span>Nothing to display.</span>
            </ProjectSummaryTableCellGroup>
          </ProjectSummaryTableRow>
        )}

        {/* No Project Summary Data and Summary Data is loading */}
        {!projectData.summaryData.data.length && projectData.isLoading && (
          <ProjectSummaryTableRow
            className="project summary data no-data loading"
          >
            <ProjectSummaryTableCellGroup span={visibleSummaryFieldCount}>
              <Icon i="rolling" />
              <span>Loading...</span>
            </ProjectSummaryTableCellGroup>
          </ProjectSummaryTableRow>
        )}

        {/* Project Summary Data Exists and no error */}
        {!!projectData.summaryData.data.length && !projectData.error && (
          <>
            {/* Project Summary Data Rows */}
            {projectData.summaryData.data.map((project) => (
              <React.Fragment
                key={`project_${project.project_id}`}
              >
                <ProjectSummaryTableRow
                  className={classNames('project', 'summary', 'data', { loading: projectData.isLoading })}
                  dataType={DATA_TYPE.PROJECT}
                  id={buildDomDataTagId(DATA_TYPE.PROJECT, project.state_id, project.project_manager_id, project.project_id)}
                  trackHover
                  onBeginHover={onBeginHover}
                  onEndHover={onEndHover}
                  onClick={onClick}
                >
                  <ProjectSummaryTableCellGroup
                    rowKey={`project_${project.project_id}`}
                    fields={summaryFieldsWithoutCountField}
                    data={project}
                    onGetFieldColSpan={(field) => {
                      // Give the name field a col-span of two and filter out the count field
                      switch (field.key) {
                        case 'name':
                          return 2;
                        case 'count':
                          return 0;
                        default:
                          return 1;
                      }
                    }}
                    onRenderFieldData={(field, data, defaultFormattedValue) => (
                      field.key === 'name'
                        ? (
                          <>
                            <div className="project-no-and-status" title={projectNameWithClientName(data.project_name, data.client_name)}>
                              <ProjectNumber pData={data} linkToProject />
                              <StatusBadge status={data.status_name} />
                            </div>
                            <div className="project-name">
                              {projectNameWithClientName(data.project_name, data.client_name)}
                            </div>
                          </>
                        )
                        : defaultFormattedValue
                    )}
                  />

                </ProjectSummaryTableRow>

              </React.Fragment>
            ))}
          </>
        )}
      </>
    )
  );
};

ProjectSummaryTableProjectSummaryDataRows.propTypes = {
  summaryFields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  })).isRequired,
  projectData: PropTypes.shape({
    stateId: PropTypes.number.isRequired,
    projectManagerId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    summaryData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
    monthlyData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
  }).isRequired,
  visibleSummaryFieldCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onBeginHover: PropTypes.func.isRequired,
  onEndHover: PropTypes.func.isRequired,
};

export default ProjectSummaryTableProjectSummaryDataRows;
