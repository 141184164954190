import React, { useCallback, useState } from 'react';
import { InMemoryDataTableColumn } from '../../../types/portal-data-table/in-memory-table-types';
import { IDataTableRecord } from '../../../types/portal-data-table/portal-data-table-record.interface';
import TableCell from '../table/table-cell';

interface IInMemoryTableRowProps<T> {
  columns: InMemoryDataTableColumn[],
  renderDetailRow?: (record: T) => JSX.Element
  rowIdKey: string,
  record: T,
 }

export const InMemoryTableRow = <T extends IDataTableRecord>(props: IInMemoryTableRowProps<T>) => {
// export const InMemoryTableRow: React.FC<IInMemoryTableRowProps> = ({ columns, renderDetailRow, rowIdKey, record }) => {
  const { columns, renderDetailRow, rowIdKey, record } = props;
  const [detailOpen, setDetailOpen] = useState(false);

  const toggleDetaileOpen = useCallback(() => {
    setDetailOpen(!detailOpen);
  }, [detailOpen]);

  return (
    <>
      <tr
        key={`table-record-${record[rowIdKey]}`}
        className={`table-record-${record[rowIdKey]}`}
        onClick={toggleDetaileOpen}
      >
        {columns.map((column) => (
          <TableCell
            key={`dodgyKey-${column.name}`}
            row={record}
            column={column}
          />
        ))}
      </tr>
      {detailOpen && renderDetailRow &&
        renderDetailRow(record)}
    </>
  );
};
