import React from 'react';
import Icon from '../layout-helpers/icon';

interface IAbbreviatedExternalLinkProps {
  href?: string;
  name?: string;
}

/**
 * Abbreviated External Link
 *
 * Abbreviates known links. Opptionally provide a default name to use if there's no match.
 * returns an empty element if no href provided
 *
 * @param {IAbbreviatedExternalLinkProps} props
 * @param {string?} props.href  the link URL
 * @param {string?} props.name  an optional name to use for unmatched items
 */
export const AbbreviatedExternalLink: React.FC<IAbbreviatedExternalLinkProps> = ({ href, name }) => {
  let linkName: string = href || name || '';
  let icon = 'link';
  if (linkName.indexOf('https://github.com/') === 0) {
    linkName = 'View on Github';
    icon = 'github';
  }
  if (linkName.indexOf('https://github.com/orgs/ciasia/projects') === 0) {
    linkName = 'View Project on Github';
  }
  if (linkName.indexOf('https://github.com/ciasia/') === 0 && linkName.indexOf('/issues/') > 0) {
    linkName = 'View Issue on Github';
  }
  if (linkName.indexOf('https://corporateinitiatives-my.sharepoint.com/') === 0) {
    linkName = 'View on OneDrive';
    icon = 'cloud';
  }
  if (linkName.indexOf('https://corporateinitiatives.sharepoint.com/') === 0) {
    linkName = 'View on SharePoint';
  }
  if (linkName.indexOf('https://ciportal.net/') === 0) {
    linkName = 'View on Ci Portal';
  }
  if (href && href.indexOf('.doc') > 0) {
    icon = 'file-word-o';
  }
  if (href && href.indexOf('.xls') > 0) {
    icon = 'file-excel-o';
  }
  if (href && href.indexOf('.pdf') > 0) {
    icon = 'file-pdf-o';
  }
  return href ? (
    <a rel="noopener noreferrer" target="_blank" href={href} title={href}>
      <Icon i={icon} spaceRight />
      {linkName}
    </a>
  ) : <></>;
};
