/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getItemByKey } from '../../../utils/helpers';
import { makeFilterString } from '../../../utils/api-calls';
import AsyncSelect from '../../form-input/async-select';
import { useIsMounted } from '../../../react-hooks/use-is-mounted.hook';
import { APIContext } from '../../providers/api-provider';
import { apiAborter } from '../../../helpers/api-aborter.helper';


/**
 * @note: this is hard coded as a column filter for a table
 *  TODO maybe combine this with async-select.jsx
 *  TODO AsyncSelectFilter isn't a nice name as it is only used in column filter
 *  TODO server worker for caching
 */
const AsyncSelectFilter = ({
  className,
  fieldName,
  dataSource,
  onSelectChange,
  columnFilter,
  includeBlankOption,
  location,
}) => {
  const {
    valueKey = 'id', labelKey = 'name', searchUrl, loadAndKeepAll, renderOption, getOptionValue, getOptionLabel,
  } = dataSource;

  const [filteredResources, setFilteredResources] = useState([]);
  const isMounted = useIsMounted();

  const { apiFetch } = useContext(APIContext);
  let abortController = null;


  // do not allow filtering on blank + other values
  // note
  //  - if blank is included in the current filter then we assume there is no other value
  //  - includeBlankOption must be true for the (Blank) button to show (while Blank is actually filtered)
  if (columnFilter instanceof Object && !columnFilter.values.includes(null) && columnFilter.values.length > 0) includeBlankOption = false;


  const loadData = useCallback(async (search) => {
    if (abortController) {
      abortController.abort();
    }

    abortController = apiAborter();

    const response = await apiFetch(
      `${searchUrl}?${search}`,
      {
        name: '?_ASYNC_SELECT_FILTER_?',
        signal: abortController.signal,
      },
    );

    if (response.success) {
      abortController = null;
      const data = [...response.body.data];
      if (includeBlankOption && columnFilter.values.some((val) => val === null)) {
        data.push({ [valueKey]: null, [labelKey]: '(Blanks)' });
      }
      setFilteredResources(data);
    } else if (!response.aborted) {
      console.error(response.error);
      abortController = null;
      setFilteredResources([]);
    }
  }, [searchUrl, includeBlankOption, columnFilter, setFilteredResources]);


  /**
   * @description
   * Fired when component will mount
   */
  useEffect(() => {
    if (columnFilter === null || columnFilter.values.length === 0) return;
    const search = makeFilterString([{ field: valueKey, values: columnFilter.values }]);

    loadData(search);
  }, []);


  /**
   * @description
   * Fired when columnFilter changes
   */
  useEffect(() => {
    if (!isMounted.current) return;

    // if awaiting previous results, abort
    if (abortController) abortController.abort();

    if (columnFilter === null || columnFilter.values.length === 0) {
      setFilteredResources([]);
      return;
    }

    const valuesNotAlreadyFiltered = columnFilter.values.filter((value) => !getItemByKey(filteredResources, value, valueKey));
    // don't need to fetch
    if (valuesNotAlreadyFiltered.length === 0) {
      setFilteredResources(columnFilter.values.map((value) => getItemByKey(filteredResources, value, valueKey)));
      return;
    }

    // fetch resources from API
    const search = makeFilterString([{ field: valueKey, values: columnFilter.values }]);
    loadData(search);
  }, [columnFilter]);


  /**
   * @description
   * Fired when a value is selected
   * For example, a sales person is selected
   */
  const handleChange = ({ objectFieldNewValue: selectedOptions }) => {
    selectedOptions = selectedOptions || [];

    // remove null from filter if filtering multiple values
    if (selectedOptions.length >= 2) selectedOptions = selectedOptions.filter((option) => option[valueKey] !== null);
    setFilteredResources(selectedOptions);
    onSelectChange(selectedOptions, true);
  };


  return (
    <AsyncSelect
      className={className}
      id="a"
      formData={{ [`${fieldName}-filter`]: filteredResources }}
      isMulti
      onChange={handleChange}
      renderOption={renderOption || null}
      getOptionValue={getOptionValue || null}
      getOptionLabel={getOptionLabel || null}
      name={`${fieldName}-filter`}
      value={filteredResources}
      valueKey={valueKey}
      labelKey={labelKey}
      formSaveField={`${fieldName}-filter`}
      searchRoute={searchUrl}
      loadAndKeepAll={loadAndKeepAll}
      blankOption={includeBlankOption ? { [valueKey]: null, [labelKey]: '(Blanks)' } : undefined}
      location={location}
      placeholder="Search..."
    />
  );
};

AsyncSelectFilter.defaultProps = {
  includeBlankOption: false,
  columnFilter: null,
  className: '',
};

AsyncSelectFilter.propTypes = {
  // (val1: selectedValue, val2: pushToHistory) => void
  onSelectChange: PropTypes.func.isRequired,
  columnFilter: PropTypes.shape({
    name: PropTypes.string.isRequired,
    operation: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(
      PropTypes.string, // filter values can be null -> trying to filter for blanks
    ).isRequired,
  }),
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  fieldName: PropTypes.string.isRequired,
  dataSource: PropTypes.shape({
    valueKey: PropTypes.string,
    labelKey: PropTypes.string,
    searchUrl: PropTypes.string.isRequired,
    loadAndKeepAll: PropTypes.bool,
    getOptionLabel: PropTypes.func,
    getOptionValue: PropTypes.func,
    renderOption: PropTypes.func,
  }).isRequired,
  includeBlankOption: PropTypes.bool,
  className: PropTypes.string,
};

export default AsyncSelectFilter;
