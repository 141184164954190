import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Redirect } from 'react-router-dom';
import { TabContent, TabPane } from 'reactstrap';
import { EventEmitter } from 'events';
import { WidgetWrapper } from '../widgets/widget-wrapper';
import { noop, mergeActions } from '../../utils/helpers';
import RecordActionButtons from '../record-action-buttons/record-action-buttons';
import FriendlyFormMessage from '../layout-helpers/friendly-form-message';
import { TabsWithMore } from '../tabs-with-more/tabs-with-more';
import { updateTableSettings } from '../../actions/portal-data-table/update-settings';
import RedirectInstruction, { REDIRECT_INSTRUCTION_TYPE } from '../../helpers/redirect-instruction';
import HISTORY_PROP_TYPES from '../../prop-types/history-prop-types';
import { connectToCurrentUserProvider } from '../providers/current-user-provider';
import { connectToAPIProvider } from '../providers/api-provider';
import { CURRENT_USER_PROVIDER_PROP_TYPES } from '../../prop-types/current-user-provider-prop-types';
import API_PROVIDER_PROP_TYPES from '../../prop-types/api-provider-prop-types';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { PERMISSION } from '../../constants/permissions.const';
import { WidgetDefinitionMap } from '../../constants/widget-definition-map.const';
import { WIDGET_DEFINITION } from '../../constants/widget-definition.const';
import { THEME_COLOR } from '../../constants/theme-color.const';


/**
 * @class RecordDetailView
 * Detailed data component for Data Table
 */
class RecordDetailView extends React.Component {
  /**
   * @constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      availableActions: {},
      actionsLoaded: false,
      alertColor: null,
      isEditing: false,
      isDirty: false,
      redirect: null,
      maxWidth: this.getMaxWidth(),
      ...this.mapPropsToState(props),
    };
    this.abortController = null;
    this.recordDetailViewRef = React.createRef();
    this.tableScrollOffsetLeft = (props.tableWrapperRef && props.tableWrapperRef.current) ? props.tableWrapperRef.current.scrollLeft : 0;

    const { tableResizeListener, tableScrollListener } = this.props;

    if (tableResizeListener) {
      tableResizeListener.addListener('resizeWidth', this.handleTableWidthChanged);
    }

    if (tableScrollListener) {
      tableScrollListener.addListener('scroll', this.handleTableScroll);
    }
  }


  /**
   * @inheritdoc
   */
  componentDidMount = () => {
    this.getAvailableActions();
  };


  /**
   * @inheritdoc
   */
  shouldComponentUpdate(nextProps) {
    const { rowData, baseRoute, rowKeyField } = this.props;
    if (!rowData) return true;

    const { id: oldId, status_id: oldStatusId } = rowData;
    const { id: newId, status_id: newStatusId } = nextProps.rowData;

    if (
      (oldId !== newId) ||
      (oldStatusId !== 'undefined' && (oldStatusId !== newStatusId))
    ) {
      // change the base route if the resource changed
      if (oldId !== newId) {
        this.setState({
          innerBaseRoute: `${baseRoute}/${rowData[rowKeyField]}`,
        },
        this.getAvailableActions);
        return false;
      }

      this.getAvailableActions();
    }

    return true;
  }


  /**
   * @inheritdoc
   * note: this is dumb
   */
  componentDidUpdate = (prevProps, prevState) => {
    const { onDirtyChange } = this.props;
    const { isDirty: newIsDirty } = this.state;
    const { isDirty: prevIsDirty } = prevState;

    // Whenever the isDirty state property changes, notify the parent
    if (onDirtyChange && (newIsDirty !== prevIsDirty)) {
      onDirtyChange(newIsDirty);
    }
  };


  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    const { tableResizeListener, tableScrollListener } = this.props;
    if (this.abortController) {
      this.abortController.abort();
    }

    if (tableResizeListener) {
      tableResizeListener.removeListener('resizeWidth', this.handleTableWidthChanged);
    }

    if (tableScrollListener) {
      tableScrollListener.removeListener('scroll', this.handleTableScroll);
    }
  }


  /**
   * @description
   * Get any new available actions
   */
  getAvailableActions = async () => {
    const { possibleActions } = this.props;
    const { innerBaseRoute } = this.state;

    // @note: this will hit the GET SINGLE
    // endpoint for the resource
    // to get its actions from HATEAOS

    // @note: currently this does not
    // have to be used EVERY
    // time we need to refresh the actions
    // for a resource - only needs to
    // be called if the actions
    // are available from another
    // response response
    if (possibleActions.length > 0) {
      const { apiProvider: { apiFetch } } = this.props;

      // Terminate any pre-existing data loads
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = apiAborter();

      const response = await apiFetch(`${innerBaseRoute}?deleted`, { signal: this.abortController.signal });

      if (response.success) {
        this.abortController = null;
        this.setState({
          availableActions: response.body.actions,
          actionsLoaded: true,
        });
      } else if (!response.aborted) {
        this.abortController = null;
        this.setState({
          availableActions: {},
          formMessage: response.error,
          actionsLoaded: true,
          alertColor: 'danger',
        });
      }
    }
    else {
      // No 'possible actions'
      this.setState({
        actionsLoaded: true,
      });
    }
  };


  /**
   * @description
   * Get the maximum width that a record detail view should be (for inline style render)
   */
  getMaxWidth = () => {
    const { tableWrapperRef } = this.props;
    if (tableWrapperRef && tableWrapperRef.current) {
      return `${tableWrapperRef.current.offsetWidth}px`;
    }
    return 'auto';
  }


  /**
   * @description
   * Directly targets the record detail view (outside of the render) and offsets it by the scroll
   * position of the table to ensure it fills the current viewport
   *
   * @param {number} offsetLeft the new left offset to position the record detail view
   */
  offsetRecordDetailViewLeft = (offsetLeft) => {
    this.tableScrollOffsetLeft = offsetLeft;

    // We don't want to offset the position of the record detail view component via state or render, that would be too heavy.
    // Instead, we'll just attach the margin-left property directly to the component via the
    if (this.recordDetailViewRef.current) {
      this.recordDetailViewRef.current.style.marginLeft = `${this.tableScrollOffsetLeft}px`;
    }
  }


  /**
   * @description
   * Fired by the tableResizeListener passed down from the portal data table
   * when the portal data table width changes
   *
   * @param {number} width the new width of the portal data table
   */
  handleTableWidthChanged = (width) => {
    const { maxWidth } = this.state;
    if (width !== maxWidth) {
      this.setState({ maxWidth: width });
    }
  }


  /**
   * @description
   * Fired by the tableScrollListener passed down from the portal data table
   * when the portal data table is scrolled (horizontally)
   *
   * @param {number} scrollLeft the new scroll left offset of the portal data table
   * @param {number} scrollWidth the new scroll width of the portal data table
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleTableScroll = (scrollLeft, scrollWidth) => {
    this.offsetRecordDetailViewLeft(scrollLeft);
  }


  /**
   * @description
   * Fired when the permissions associated with the logged in user change for some reason
   */
  handlePermissionsUpdated = () => {
    this.setState({});
  }


  /**
   * @description
   * Convert a set of props to their corresponding
   * representation in this components state
   *
   * Return a JSON object that can be used to directly
   * set the state or inside of this.setState(...)
   *
   * @param {object} nextProps
   */
  mapPropsToState = (nextProps) => ({
    innerBaseRoute: `${nextProps.baseRoute}/${nextProps.rowData[nextProps.rowKeyField]}`, // || this.state.innerBaseRoute
  });


  /**
   * @description
   * Fired by the widgetWrapper when the user begins editing the the a widget which represents the current record
   */
  handleStartEdit = () => {
    this.setState({
      isEditing: true,
      isDirty: false,
    });
  };


  /**
   * @description
   * Fired by the widgetWrapper when the user makes a change that the widget deems worthy of confirming abandonment
   */
  handleDirtyChange = (newIsDirty) => {
    this.setState({
      isDirty: newIsDirty,
    });
  };


  /**
   * @description
   * Fired by the WidgetWrapper when the user ends editing the current record
   *
   * @param {boolean} saveChanges whether the end edit represents a save or a cancel
   */
  handleEndEdit = (saveChanges) => {
    const {
      refreshRecord,
    } = this.props;

    this.setState(
      {
        isEditing: false,
        isDirty: false,
      },
      () => {
        if (saveChanges && refreshRecord) {
          refreshRecord();
        }
      },
    );
  };


  /**
   * @description
   * Callback for when the record the user is looking at is deleted
   *
   * @param {RedirectInstruction | React.SyntheticEvent} [redirectInstruction=undefined] how to re-direct the user
   */
  handleDelete = (redirectInstruction) => {
    const {
      history,
      dispatchUpdateTableSettings,
    } = this.props;

    if (redirectInstruction instanceof RedirectInstruction) {
      switch (redirectInstruction.type) {
        case REDIRECT_INSTRUCTION_TYPE.NONE: break;

        case REDIRECT_INSTRUCTION_TYPE.REDIRECT:
          history.replace(redirectInstruction.targetLocation);
          break;

        case REDIRECT_INSTRUCTION_TYPE.REFRESH:
          dispatchUpdateTableSettings({ reloadTable: true });
          break;

        default: throw new TypeError(`Unhandled REDIRECT_INSTRUCTION_TYPE: ${redirectInstruction.type}`);
      }
    }
  };


  /**
   * @description
   * Fired when the user changes the active tab in the detail widget
   *
   * @param {string} newTabKey new tab key to change to
   */
  handleChangeTab = (newTabKey) => {
    const {
      activeTabKey,
    } = this.props;
    if (newTabKey && activeTabKey !== newTabKey) {
      const { isDirty } = this.state;

      // confirm whether the user wants to abandon changes?
      // eslint-disable-next-line no-alert
      if (!isDirty || (isDirty && window.confirm('Discard your unsaved changes?'))) {
        this.setState({
          isEditing: false,
          isDirty: false,
        }, () => {
          const { dispatchUpdateTableSettings } = this.props;
          dispatchUpdateTableSettings({ activeTabKey: newTabKey, replaceInHistory: true });
        });
      }
    }
  };


  /**
   * @description
   * Called when clicking close button on an Alert message
   */
  handleClearActionMessage = () => {
    this.setState({
      formMessage: null,
      alertColor: null,
    });
  };


  /**
   * @inheritdoc
   */
  render() {
    const {
      rowKeyField,
      className,
      baseRoute,
      rowData,
      rowDataChecksum,
      tabs,
      columns,
      tableIdentifier,
      possibleActions,
      refreshRecord,
      busy,
      activeTabKey,
      defaultTabKey,
      history,
      navigateBackOnDelete,
      itemCaption,
      currentUserProvider: { refreshUserAlerts, userHasPermissions },
    } = this.props;

    const {
      redirect,
    } = this.state;

    // Redirect if something set it
    // https://stackoverflow.com/a/43230829/2740286
    if (redirect !== null) {
      return <Redirect to={redirect} />;
    }

    const {
      availableActions,
      actionsLoaded,
      formMessage,
      alertColor,
      isEditing,
      maxWidth,
    } = this.state;

    const permittedActions = mergeActions(availableActions, possibleActions);
    const actualActiveTabKey = tabs.find((tab) => tab.name === activeTabKey) ? activeTabKey : defaultTabKey;
    const activeTab = tabs.find((tab) => tab.name === actualActiveTabKey);

    /**
     * Returns <RecordDetailView />
     */
    return (
      <>
        <div
          className={classnames('record-detail-view', className, { busy })}
          style={{ maxWidth, width: maxWidth, marginLeft: this.tableScrollOffsetLeft }}
          ref={this.recordDetailViewRef}
        >
          <div id={`record-${rowData[rowKeyField]}-tab-container`} className="record-tab-container">
            {/* ---  Tabs With More Menu!  --- */}
            <TabsWithMore
              tabs={tabs}
              rowData={rowData}
              activeTabKey={actualActiveTabKey}
              changeTab={this.handleChangeTab}
            />
            <div className="tab-content-wrapper">
              <TabContent activeTab={actualActiveTabKey}>
                {activeTab && (() => {
                  // Don't render the current tab if it's not the active tab
                  if (actualActiveTabKey !== activeTab.name) return null;

                  // Ensure the current user has permission to see the active tab
                  if (
                    // permissions are required
                    activeTab.permissions &&
                    // and user doesn't have them
                    !userHasPermissions(activeTab.permissions)
                  ) {
                    return (
                      <TabPane key={`tabpane-${activeTab.name}`} tabId={activeTab.name}>
                        <span>You do not have permission to see this widget</span>
                      </TabPane>
                    );
                  }

                  // Render the active tab
                  return (
                    <TabPane key={`tabpane-${activeTab.name}`} tabId={activeTab.name}>
                      <WidgetWrapper
                        name={activeTab.name}
                        description={activeTab.description}
                        statusMap={activeTab.statusMap ?? {}}
                        parentId={rowData[rowKeyField]}
                        columns={columns}
                        rowData={rowData}
                        rowDataChecksum={rowDataChecksum}
                        navigateBackOnDelete={navigateBackOnDelete}
                        itemCaption={itemCaption}
                        isEditing={isEditing}

                        history={history}
                        tableIdentifier={tableIdentifier}

                        baseRoute={baseRoute}
                        apiRoute={activeTab.apiRoute}
                        apiQuery={activeTab.apiQuery}
                        autoLoadAllData={activeTab.autoLoadAllData}

                        permittedActions={permittedActions}

                        widgetDefinition={WidgetDefinitionMap[activeTab.widgetDefinition]}

                        refreshRecord={() => refreshRecord(true)}
                        refreshActions={() => this.getAvailableActions()}

                        onStartEdit={this.handleStartEdit}
                        onEndEdit={this.handleEndEdit}
                        onDelete={(id, redirectInstruction) => { this.handleDelete(redirectInstruction); }}
                        onDirtyChange={this.handleDirtyChange}
                      />
                    </TabPane>
                  );
                })()}
              </TabContent>

              {formMessage && (
                <div className="portal-form">
                  <FriendlyFormMessage
                    formMessage={formMessage}
                    alertColor={alertColor}
                    errors={{}}
                    showList
                    isOpen={!!formMessage}
                    toggle={this.handleClearActionMessage}
                    useSimpleDefault
                  />
                </div>
              )}

              {possibleActions.length > 0 && (
                <RecordActionButtons
                  permittedActions={permittedActions}
                  hasLoaded={actionsLoaded}
                  disabled={isEditing}
                  onEndEdit={this.handleEndEdit}
                  rowData={rowData}
                  onSuccess={() => {
                    refreshRecord();
                    this.getAvailableActions();
                    refreshUserAlerts();
                  }}
                />
              )}
            </div>

          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  itemCaption: state.tableSettings[ownProps.tableIdentifier].itemCaption || 'Record',
  columns: state.tableSettings[ownProps.tableIdentifier].columns,
  tabs: state.tableSettings[ownProps.tableIdentifier].tabs,
  defaultTabKey: state.tableSettings[ownProps.tableIdentifier].defaultTabKey,
  activeTabKey: state.tableSettings[ownProps.tableIdentifier].activeTabKey,
  baseRoute: state.tableSettings[ownProps.tableIdentifier].baseRoute,
  possibleActions: state.tableSettings[ownProps.tableIdentifier].possibleActions,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const { tableIdentifier } = ownProps;
  return {
    dispatchUpdateTableSettings: (settings) => dispatch(updateTableSettings(tableIdentifier, settings)),
  };
};

RecordDetailView.propTypes = {
  className: PropTypes.string,
  rowData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status_id: PropTypes.number,
  }).isRequired,
  rowDataChecksum: PropTypes.number.isRequired,
  tableIdentifier: PropTypes.oneOf(Object.values(TABLE_IDENTIFIER)).isRequired,
  tableWrapperRef: PropTypes.shape({
    current: PropTypes.shape({
      offsetWidth: PropTypes.number,
      scrollLeft: PropTypes.number,
    }),
  }),
  tableResizeListener: PropTypes.instanceOf(EventEmitter),
  tableScrollListener: PropTypes.instanceOf(EventEmitter),
  refreshRecord: PropTypes.func,
  busy: PropTypes.bool,
  // Router
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape(HISTORY_PROP_TYPES).isRequired,
  match: PropTypes.shape({}).isRequired,
  // State
  rowKeyField: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    widgetDefinition: PropTypes.oneOf(Object.values(WIDGET_DEFINITION)),
    apiRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    apiQuery: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]),
    autoLoadAllData: PropTypes.bool,
    permissions: PropTypes.oneOfType([
      PropTypes.oneOf(Object.values(PERMISSION)),
      PropTypes.arrayOf(PropTypes.oneOf(Object.values(PERMISSION))),
    ]),
    badgeCountFields: PropTypes.arrayOf(PropTypes.shape({
      fieldName: PropTypes.string.isRequired,
      badgeColor: PropTypes.oneOf(Object.values(THEME_COLOR)),
    })),
  })).isRequired,
  baseRoute: PropTypes.string.isRequired,
  possibleActions: PropTypes.arrayOf(PropTypes.shape({})),
  activeTabKey: PropTypes.string,
  defaultTabKey: PropTypes.string.isRequired,
  itemCaption: PropTypes.string,
  // Dispatch
  dispatchUpdateTableSettings: PropTypes.func.isRequired,
  navigateBackOnDelete: PropTypes.bool,
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
  currentUserProvider: PropTypes.shape(CURRENT_USER_PROVIDER_PROP_TYPES).isRequired,
  onDirtyChange: PropTypes.func,
};

RecordDetailView.defaultProps = {
  className: null,
  possibleActions: [],
  refreshRecord: noop,
  busy: false,
  tableWrapperRef: null,
  tableResizeListener: null,
  tableScrollListener: null,
  activeTabKey: null,
  navigateBackOnDelete: false,
  itemCaption: 'Record',
  rowKeyField: 'id',
  onDirtyChange: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(connectToAPIProvider(connectToCurrentUserProvider(RecordDetailView)));
