import React from 'react';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { ICachedMyobTransactionRecord } from '../../../types/myob-advanced/cached-myob-project-transaction.record.interface';
import { formatValueString } from '../../render-functions';

type TransactionReportTransactionRowsProps = {
  transactions: ICachedMyobTransactionRecord[],
  indentLevel: number,
  showTotals: boolean,
  totalName: string,
};

export const TransactionReportTransactionRows: React.FC<TransactionReportTransactionRowsProps> = ({
  transactions,
  indentLevel,
  showTotals,
  totalName,
}) => {
  // Running total
  let totalAmount = 0;
  return (
    <>
      {transactions && transactions.map((transaction) => {
        totalAmount += parseFloat(transaction.amount);
        return (
          <tr key={transaction.myob_uuid}>
            <td>
              <span style={{ marginLeft: `${indentLevel / 2}rem`, display: 'inline-block' }}>
                {transaction.ref_number}
              </span>
            </td>
            <td>
              <span style={{ marginLeft: `${indentLevel / 2}rem`, display: 'inline-block' }}>
                {transaction.orig_doc_type}
                {transaction.orig_doc_type && ': '}
                {(transaction.project_task === 'ONGOING' && !transaction.description) ?
                  <em className="text-medium">*Pre July 2022 Migration amount</em>
                  :
                  (transaction.description || transaction.project_task)}
              </span>
            </td>
            <td className="text-right currency">{formatValueString(parseFloat(transaction.amount) * (-1), COLUMN_FORMAT.CURRENCY)}</td>
            {/* <td colSpan={3}><code><pre>{JSON.stringify(transaction, null, 2)}</pre></code></td> */}
          </tr>
        );
      })}
      {showTotals && (
        <tr>
          <td>&nbsp;</td>
          <td className="text-right">
            <strong>
              Total for
              {' '}
              {totalName || 'section'}
            </strong>
          </td>
          <td className="text-right currency">{formatValueString(totalAmount * (-1), COLUMN_FORMAT.CURRENCY)}</td>
        </tr>
      )}
    </>
  );
};
