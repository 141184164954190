import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { useHistory, useLocation } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import { APIContext } from '../providers/api-provider';
import { CurrentUserContext } from '../providers/current-user-provider';

import { IInternalProjectRecord } from '../../types/internal-project/internal-project.record.interface';

import { loadLocalPrefs, saveLocalPref } from '../../utils/localStorage';
import { APIPolyForm } from '../poly-forms/api-poly-form';
import Icon from '../layout-helpers/icon';
import PageHeader from '../app-layout/page-header';
import RecordDetailPage from '../record-detail-page/record-detail-page';
import { CardSwimlanes } from '../project-card-view/card-swimlanes';

import { mapTableToForm } from '../../helpers/map-table-to-form.helper';
import { apiAborter } from '../../helpers/api-aborter.helper';

import internalProjectsTableInitialSettings from '../../table-definitions/internal-projects-table';
import internalProjectTasksTableInitialSettings from '../../table-definitions/internal-project-tasks-table';
import { PERMISSION } from '../../constants/permissions.const';
import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { FORM_RENDERER_TYPE } from '../../constants/form-renderer-type.const';

interface IProjectLoadResult {
  working: boolean,
  error: boolean,
  data: IInternalProjectRecord | null,
}

interface ISingleProjectMultiViewProps {
  title: string,
  match: {
    params: {
      [key: string]: string | number
    }
  }
}

interface ILocalPrefs {
  internalProjectsSingleMultiViewMode?: string,
}

const cardActions = internalProjectsTableInitialSettings.possibleActions;
const formFields = mapTableToForm(internalProjectsTableInitialSettings);

/**
 * Single Internal Project Multiview
 *
 * Allows rendering of a single internal project as either a card-view or a data record view
 *
 * @param {ISingleProjectMultiViewProps}  props
 * @param {string}  props.title
 * @param {object}  props.match
 */
export const SingleInternalProjectMultiView: React.FC<ISingleProjectMultiViewProps> = ({ title, match, ...props }) => {
  const [projectLoadResult, setProjectLoadResult] = useState<IProjectLoadResult>({
    working: false,
    error: false,
    data: null,
  });

  const [showSwimlanes, setShowswimlanes] = useState(false);

  const urlQuery = new URLSearchParams(useLocation().search);
  let useViewMode = 'editor';
  const { internalProjectsSingleMultiViewMode }: ILocalPrefs = loadLocalPrefs();
  useViewMode = internalProjectsSingleMultiViewMode || 'editor';
  useViewMode = urlQuery.get('multiview-mode') || useViewMode;

  const { apiFetch } = useContext(APIContext);
  const { userHasPermissions } = useContext(CurrentUserContext);
  const history = useHistory();
  const [viewMode, setViewMode] = useState<string>(useViewMode);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const internalProjectAbortController = useRef<AbortController | null>(null);

  /** Load Project Data */
  const loadProject = useCallback(async () => {
    if (internalProjectAbortController.current) {
      internalProjectAbortController.current.abort();
    }
    internalProjectAbortController.current = apiAborter();

    setProjectLoadResult({
      ...projectLoadResult,
      working: true,
      data: null,
    });

    const baseQueryString = [
      'with[]=item',
      'with[]=item.createdBy:id,name',
      'with[]=item.assignedTo:id,name',
      'with[]=createdBy:id,name',
      'with[]=owner:id,name',
      'with[]=projectLead:id,name',
      'with_count[]=item',
      'pagelength=100',
    ].join('&');

    const response = await apiFetch(
      `/internal-project/${match.params.id}?${baseQueryString}`,
      {
        method: HTTP_METHOD.GET,
        name: 'ProjectCardViewPageListData:load',
        signal: internalProjectAbortController?.current?.signal,
      },
    );

    if (response.success) {
      internalProjectAbortController.current = null;
      const newProjectData = response.body.data || 0;
      setProjectLoadResult({
        working: false,
        error: false,
        data: newProjectData,
      });
    } else if (!response.aborted) {
      internalProjectAbortController.current = null;
      setProjectLoadResult({
        working: false,
        error: true,
        data: projectLoadResult.data,
      });
    }
  }, [projectLoadResult, setProjectLoadResult, apiFetch, match.params.id]);

  const changeViewMode = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const view = e.target.value;
    setViewMode(view);
    saveLocalPref('internalProjectsSingleMultiViewMode', view);
    history.push({ search: `multiview-mode=${view}` });
  }, [history]);

  useEffect(() => {
    if (userHasPermissions(PERMISSION.INTERNAL_PROJECT_VIEWER)) {
      loadProject();
    }

    return () => {
      // Kill any outstanding load when the component is un-mounted
      if (internalProjectAbortController.current) {
        internalProjectAbortController.current.abort();
      }
    };// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  const { data } = projectLoadResult;

  // title for dropping in alternate locations
  const effectiveTitle = data ? `Internal Project #${data.id}  ${data.name}` : (
    <span>
      {title}
      {' '}
      <Icon i="rolling" />
    </span>
  );

  const viewPickerTitle = (
    <div className="form-inline m-r-2 m-l-2">
      {effectiveTitle}
      <label htmlFor="sort-order-select">
        &nbsp; | &nbsp; View as: &nbsp; &nbsp;
        <select
          name="sort-order-select"
          className="form-control form-control-sm"
          value={viewMode}
          onChange={changeViewMode}
        >
          <option value="card">Card</option>
          <option value="editor">Editor</option>
        </select>
      </label>
    </div>
  );

  // header function for recordDetail View
  const renderRecordDetailPageHeader = () => (
    <PageHeader
      title={viewPickerTitle}
    />
  );

  const toggleSwimlanesHandler = () => setShowswimlanes(!showSwimlanes);

  return viewMode === 'card' ? (
    <Container fluid className="card-view">
      <PageHeader
        title={viewPickerTitle}
      />
      {data ? (
        <>
          <APIPolyForm
            {...internalProjectsTableInitialSettings}
            fields={formFields}
            formRendererType={FORM_RENDERER_TYPE.PROJECT_CARD}
            rendererOptions={{
              showSwimlanes,
              onToggleSwimlanes: toggleSwimlanesHandler,
            }}
            onStartEditRecord={() => setIsEditing(true)}
            onEndEditRecord={() => setIsEditing(false)}
            isEditing={isEditing}
            navigateBackOnDelete
            key={data.id}
            formData={data}
            permittedActions={cardActions}
          />
          {showSwimlanes && (
            <Card>
              <CardSwimlanes
                cardItems={data?.item || []}
                cardLinkPath="/internal-projects/tasks"
                tableSettings={internalProjectTasksTableInitialSettings}
              />
            </Card>
          )}
        </>
      ) : (
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Icon i="rolling" />
                {' '}
                Loading...

              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  ) : (
    <RecordDetailPage
      {...props}
      renderPageHeader={renderRecordDetailPageHeader}
      match={match}
      tableIdentifier={TABLE_IDENTIFIER.INTERNAL_PROJECTS_TABLE}
    />
  );
};
