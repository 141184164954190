import React from 'react';
import classNames from 'classnames';
import Icon from '../layout-helpers/icon';
import { commas } from '../../helpers/commas.helper';

interface IResultsTextProps {
  className?: string,
  isLoading: boolean,
  hasError: boolean,
  isFiltered?: boolean,
  pageSize: number,
  totalRecords: number,
  activePage: number,
}

/**
 * @class ResultsText
 *
 * @description
 * Displays results text for API Queries
 */
export const ResultsText: React.FC<IResultsTextProps> = ({
  className,
  isLoading,
  hasError,
  isFiltered,
  pageSize,
  totalRecords,
  activePage,
}) => {
  const firstRecord = (activePage - 1) * pageSize + 1;
  const lastRecord = firstRecord + pageSize <= totalRecords ? firstRecord + pageSize - 1 : totalRecords;

  // Style results status based on filters and errors etc.
  const returnClassName = classNames('results-text', className, { 'text-success': (!isLoading && !hasError && isFiltered) });
  const recordCountText = `${commas(firstRecord)} to ${commas(lastRecord)}`;
  const totalRecordsText = `(${commas(totalRecords)} Total)`;

  let resultsText = isFiltered ? 'No results for current filters.' : 'No results';
  if (totalRecords > 0) {
    resultsText = `Showing ${isFiltered ? 'filtered ' : ''}results ${recordCountText} ${totalRecordsText}`;
  }

  return (
    <div className={returnClassName}>
      {/* No Records */}
      {!isLoading && (
        <span>{resultsText}</span>
      )}

      {/* Show a spinner if still loading */}
      {isLoading && !hasError && (
        <Icon i="rolling" title="Table is loading more data" />
      )}
    </div>
  );
};
