import React from 'react';
import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { IPortalDataTablePageProps } from '../../types/portal-data-table/portal-data-table-page.props';

/**
 * Display the "Dtools Change Orders" data table as a whole page
 */
export const DtoolsChangeOrderDataTablePage: React.FC<IPortalDataTablePageProps> = ({
  baseFilters,
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
  history,
}) => (
  <DataTablePage
    title="Dtools Change Orders"
    tableIdentifier={TABLE_IDENTIFIER.DTOOLS_CHANGE_ORDER_TABLE}
    actionHandlers={{}}
    baseFilters={baseFilters}
    baseFlags={baseFlags}
    match={match}
    history={history}
    location={location}
    availableActions={availableActions}
    possibleActions={possibleActions}
  >
    {children}
  </DataTablePage>
);
