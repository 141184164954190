import { A_COLUMN_FORMAT, COLUMN_FORMAT, DEFAULT_COLUMN_FORMAT_WIDTHS } from '../constants/column-format.const';
import { IFormFieldDefinition } from '../types/poly-form/form-field-definition.interface';

/**
 * Get the default (min) width for a field based on a supplied format or object.format
 */
export const getFormatWidth = (format: A_COLUMN_FORMAT): string => DEFAULT_COLUMN_FORMAT_WIDTHS[format] ?? 'auto';


/**
 * Get the default (min) width for a column based on its format or object.format
 */
export const getColumnFormatWidth = (fieldDefinition: IFormFieldDefinition): string => {
  if (fieldDefinition && fieldDefinition.columnWidth) {
    return fieldDefinition.columnWidth;
  } if (fieldDefinition && fieldDefinition.format === COLUMN_FORMAT.OBJECT && fieldDefinition.object && fieldDefinition.object.format) {
    return getFormatWidth(fieldDefinition.object.format);
  } if (fieldDefinition && fieldDefinition.format) {
    return getFormatWidth(fieldDefinition.format);
  }
  // Fall back to the text column width
  return getFormatWidth(COLUMN_FORMAT.TEXT);
};
