import React from 'react';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { IMyobTransactionReportRecord } from '../../../types/myob-advanced/myob-transaction-report-record.interface';
import { formatValueString } from '../../render-functions';
import { TransactionReportDetailRow } from './transaction-report-detail-row';

type TransactionReportDetailRowsProps = {
  reportData: IMyobTransactionReportRecord[],
  indentLevel: number,
  showTotals: boolean,
  title?: string,
};

export const TransactionReportDetailRows: React.FC<TransactionReportDetailRowsProps> = ({ reportData, indentLevel, showTotals, title }) => {
  // Running total
  let totalAmount = 0;
  return (
    <>
      {reportData && reportData.map((category) => {
        totalAmount += (category.amount);
        return (
          <TransactionReportDetailRow key={category.title} category={category} indentLevel={indentLevel} showTotals={showTotals} />
        );
      })}
      {showTotals && (
        <tr>
          <td>&nbsp;</td>
          <td className="text-right">
            <strong>
              {title || ''}
              {' '}
              Total
            </strong>
          </td>
          <td className="text-right currency">{formatValueString(totalAmount * (-1), COLUMN_FORMAT.CURRENCY)}</td>
        </tr>
      )}
    </>
  );
};
