import React from 'react';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import Async from 'react-select/async';
import { EventEmitter } from 'events';

import { TIMEOUT_TYPING_DEBOUNCE } from '../../utils/constants';
import { noop } from '../../utils/helpers';
import { MiniProjectSummary } from '../mini-project-summary/mini-project-summary';
import { connectToAPIProvider } from '../providers/api-provider';
import API_PROVIDER_PROP_TYPES from '../../prop-types/api-provider-prop-types';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { ProjectNumber } from '../data-format/project-number';

// How many projects to load in the dropdown
const PROJECT_LIMIT = 10;

// How long to wait before resetting the control to defaults
const RESET_TIMEOUT = 30000; // 30s

class ProjectFinder extends React.Component {
  /**
   * @inheritdoc
   */
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: null,
      searchResults: [],
      inputValue: '',
      menuOpen: false,
    };

    // Listen to the navbar and capture changes to the hidden state
    const { navChangeListener } = this.props;
    if (navChangeListener) {
      navChangeListener.addListener('show', this.handleNavShow);
    }

    this.abortController = null;
    this.inputId = 'project_finder_input';

    this.resetTimeout = null;
    this.debounceTimeout = null;
  }


  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    // Stop any data from loading
    if (this.abortController) {
      this.abortController.abort();
    }

    // Stop listening to the navbar
    const { navChangeListener } = this.props;
    if (navChangeListener) {
      navChangeListener.addListener('show', this.handleNavShow);
    }

    // Clear time outs
    clearTimeout(this.debounceTimeout);
    clearTimeout(this.resetTimeout);
  }


  /**
   * @property {HTMLElement} inputElement
   * Gets the input element of the select by id (if available)
   */
  get inputElement() {
    return document.getElementById(this.inputId);
  }


  /**
   * @description
   * Get the options from API based on the search term
   *
   * @param {string} search string for filtering options
   * @returns {Promise} new options
   */
  getData = async (searchTerm) => {
    // Use search, otherwise use string for filtering options
    let searchRoute = '/project?';
    searchRoute += [
      `search=${searchTerm}`,
      `pagelength=${PROJECT_LIMIT}`,
      'with[]=client:id,name',
      'sort[0][field]=updated_at',
      'sort[0][direction]=desc',
    ].join('&');

    // Abort any ongoing requests
    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortController = apiAborter();

    const { apiProvider: { apiFetch } } = this.props;

    const response = await apiFetch(searchRoute, { signal: this.abortController.signal });

    if (response.success) {
      this.abortController = null;
      this.setState({
        searchResults: response.body.data,
      });
      return { options: response.body.data };
    } if (!response.aborted) {
      this.abortController = null;
      this.setState({
        searchResults: [],
      });
    }
    return { options: [] };
  };


  /**
   * @description
   * Debounce the user's typing before getting the data
   *
   * @param {string} search Value typed in
   * @param {Function} callback
   * @returns {void} promise with new options
   */
  debounceAndGetData = (search, callback) => {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = null;
    }

    // don't update on each character change, wait TIMEOUT_TYPING_DEBOUNCE
    this.debounceTimeout = setTimeout(() => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = null;
      this.getData(search).then((data) => {
        if (data && ('options' in data) && (typeof callback === 'function')) callback(data.options);
      });
    }, TIMEOUT_TYPING_DEBOUNCE);
  };


  /**
   * @description
   * Load the selected project
   */
  jumpToProject = () => {
    const { selectedOption } = this.state;
    const { setHoverSidebar, history } = this.props;
    if (selectedOption) {
      history.push(`/crm/projects/${selectedOption.id}`);
      setHoverSidebar(false, true);
    }
  };


  /**
   * @description
   * Start a timeout that resets the project number lookup after a specified amount of inactivity
   */
  startResetTimeout = () => {
    clearTimeout(this.resetTimeout);
    this.resetTimeout = setTimeout(() => {
      this.reset();
    }, RESET_TIMEOUT);
  }


  /**
   * @description
   * Reset the component to defaults
   */
  reset = () => {
    this.setState({
      selectedOption: null,
      searchResults: [],
      inputValue: '',
    });
  }


  /**
   * @description
   * Fired whenever the nav menu is hidden / shown
   *
   * @param {bool} show whether the action was a hide or show action
   */
  handleNavShow = (show) => {
    // When the nav bar is closed, we need to blur this component (if focused) so the dropdown
    // doesn't do funky things and draw in weird places once the nav scrolls off the screen.
    if (!show) {
      // Only bother to do something when the input is focused.
      if (document.activeElement === document.getElementById(this.inputId)) {
        document.activeElement.blur();
      }
    }
  }


  /**
   * @description
   * Fired by the select when a new project number is selected
   */
  handleChange = (option) => {
    this.setState({
      selectedOption: option,
    }, () => {
      // Navigate to the selected option
      this.jumpToProject();
    });
  };


  /**
   * @description
   * Fired when the user is typing in the input
   */
  handleInputChange = (inputValue, details) => {
    if (details.action === 'input-change') {
      this.setState({
        inputValue,
      });
    }
  }


  /**
   * @description
   * Fired when the select dropdown is opened
   */
  handleMenuOpen = () => {
    const { menuOpen } = this.state;
    this.startResetTimeout();

    clearTimeout(this.menuCloseDebounceTimeout);
    if (!menuOpen) {
      this.setState({ menuOpen: true }, () => {
        // Selecting the text inside the input element makes it easier to change the value next time
        if (this.inputElement) {
          this.inputElement.select();
        }
      });
    }
  }


  /**
   * @description
   * Fired when the select dropdown is closed
   */
  handleMenuClose = () => {
    this.startResetTimeout();

    clearTimeout(this.menuCloseDebounceTimeout);
    this.menuCloseDebounceTimeout = setTimeout(() => {
      this.setState({ menuOpen: false });
    }, 1);
  }


  /**
   * @description
   * Fired when the user clicks the button
   */
  handleButtonClick = () => {
    this.jumpToProject();
  }


  /**
   * @description
   * Fired when the input loses focus
   */
  handleInputBlur = () => {
    const { setHoverSidebar } = this.props;
    // Stop hovering the sidebar (if required)
    setHoverSidebar(false);
  }


  /**
   * @description
   * Called by the select to render one of the options in the dropdown
   *
   * @see https://react-select.com/props
   *
   * @param {object} option the option data to render
   * @param {object} details information about where and what is being rendered
   * @param {string} searchTerm
   *
   * @return {React.ReactNode}
   */
  renderOption = (option, details, searchTerm) => {
    if (details.context === 'menu') return <MiniProjectSummary pData={option} highlightText={searchTerm} />;
    return <ProjectNumber pData={option} linkToProject={false} />;
  };


  /**
   * @inheritdoc
   */
  render() {
    const {
      selectedOption, searchResults, inputValue, menuOpen,
    } = this.state;

    const { portalContainer } = this.props;

    return (
      <div className="pnum-search">
        <div className="input-group input-group-sm">
          <Async
            className="Select search-field"
            classNamePrefix="pnum-search-Select"
            id="p_number_search"
            cacheOptions={false}
            loadOptions={(searchTerm) => new Promise((resolve) => {
              this.debounceAndGetData(searchTerm, resolve);
            })}
            // Note this select component cannot be hacked by the applyReactSelectScrollYHack because it exists in a different scrollY container
            menuPortalTarget={document.getElementById(portalContainer || 'portal_container')}
            menuPlacement="bottom"
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.project_number}
            formatOptionLabel={(option, details) => this.renderOption(option, details, inputValue)}
            isMulti={false}
            placeholder="Project Search..."
            value={selectedOption}
            onChange={this.handleChange}
            defaultOptions={searchResults}
            onInputChange={this.handleInputChange}
            onMenuOpen={this.handleMenuOpen}
            onMenuClose={this.handleMenuClose}
            inputId={this.inputId}
            noOptionsMessage={(current) => (current.inputValue ? 'Nothing found' : 'i.e. customer, project number, sales owner etc...')}
            openMenuOnFocus
            isClearable
            escapeClearsValue
            backspaceRemovesValue
            inputValue={menuOpen ? inputValue : undefined}
            onBlur={this.handleInputBlur}
            tabSelectsValue={false}
          />

          <div className="search-icon">
            <i className="ti-search" />
          </div>

        </div>
      </div>
    );
  }
}

ProjectFinder.defaultProps = {
  navChangeListener: null,
  setHoverSidebar: noop,
};

ProjectFinder.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
  }).isRequired,
  navChangeListener: PropTypes.instanceOf(EventEmitter),
  setHoverSidebar: PropTypes.func,
  portalContainer: PropTypes.string.isRequired,
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
};

export default withRouter(connectToAPIProvider(ProjectFinder));
