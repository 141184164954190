import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { PERMISSION } from '../constants/permissions.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { ICON } from '../constants/icon.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';
import { EXPENSE_CLAIM_STATUS_MAP } from '../constants/expense-claim-status.const';
import { THEME_COLOR } from '../constants/theme-color.const';

/**
 * Table Definition for My Expenses page
 */
const processExpenseClaimsTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Expense Claim',
  baseRoute: '/expenseclaim',
  // Where status != Deleted
  baseQueryString: [
    'with[]=user:id,name',
    'with[]=approver:id,name',
    'with[]=status:id,name',
    'with_count[]=items',
    'with_count[]=notes',
    'with_count[]=files',
  ].join('&'),

  columnPresets: [
    {
      name: 'default',
      title: 'Default',
      fields: [
        'id',
        'expense_claim_batch_id',
        'user',
        'status',
        'amount',
        'count_of_items',
        'name',
        'status_id',
        'user_audit_submitted_at',
        'approver',
        'user_audit_approved_at',
      ],
    },
    {
      name: 'all',
      title: 'All',
      fields: [
        'id',
        'expense_claim_batch_id',
        'user',
        'status',
        'amount',
        'count_of_items',
        'name',
        'status_id',
        'user_audit_submitted_at',
        'approver',
        'user_audit_approved_at',
        'count_of_items',
        'count_of_files',
        'count_of_notes',
      ],
    },
  ],

  /**
   * Definition of Columns for this table
   */
  columns: [
    {
      name: 'id',
      title: 'ID',
      description: 'Expense Claim Unique Identifier',
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      format: COLUMN_FORMAT.ID_LINK,
      linkId: 'id',
      linkRoute: '/finance/process-expenses/claims',
      visible: true,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'expense_claim_batch_id',
      title: 'Batch',
      description: 'Expense Claim Batch Unique Identifier',
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      format: COLUMN_FORMAT.ID_LINK,
      linkId: 'expense_claim_batch_id',
      linkRoute: '/finance/process-expenses/batches',
      visible: true,
      showInForm: true,
      order: 5,
      formOrder: 5,
    },
    {
      name: 'user',
      title: 'Employee',
      description: 'Person claiming expense',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/hrm/browse-profiles',
        linkId: 'id',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      formSaveField: 'user_id',
      visible: true,
      showInForm: true,
      order: 10,
      formOrder: 10,
    },
    {
      name: 'approver',
      title: 'Approver',
      description: 'Person responsible for approving',
      placeholder: 'Select...',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'approver',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/hrm/browse-profiles',
        linkId: 'id',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      formSaveField: 'approver_id',
      visible: true,
      showInForm: true,
      required: true,
      order: 90,
      formOrder: 20,
    },
    {
      name: 'status',
      title: 'Status',
      description: 'Expense claim status - use actions to change',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'status',
        key: 'name',
        format: COLUMN_FORMAT.STATUS_BADGE,
        listSource: 'expenseStatus',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 20,
      formOrder: 30,
    },
    {
      name: 'name',
      title: 'Short Description',
      description: 'Description',
      placeholder: 'Enter a brief description',
      format: COLUMN_FORMAT.TEXT,
      visible: true,
      order: 50,
      formOrder: 40,
      required: true,
    },
    {
      name: 'amount',
      title: 'Amount',
      description: 'Expense Claim total amount',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 30,
      formOrder: 50,
    },
    {
      title: 'Items',
      name: 'count_of_items',
      description: 'The count of items in the Expense Claim',
      format: COLUMN_FORMAT.NUMBER,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: false,
      order: 40,
      formOrder: 60,
    },
    {
      name: 'user_audit_submitted_at',
      title: 'Submitted',
      sortColumn: 'submitted_at',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'submitted_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 80,
      formOrder: 70,
    },
    {
      name: 'user_audit_approved_at',
      title: 'Approved',
      format: COLUMN_FORMAT.OBJECT,
      sortColumn: 'approved_at',
      object: {
        sourceField: 'user_audit',
        key: 'approved_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 100,
      formOrder: 80,
    },
    {
      // @todo: eventually we need a way to sort on this column without having to bind to a surrogate data field
      // The name:'status_id' was chosen to stop the UI crashing when sorting the column
      name: 'status_id', // So sorting doesn't error
      title: 'Batching',
      description: 'Batching and un-batching actions',
      format: COLUMN_FORMAT.BATCH_BUTTON,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      permissions: [PERMISSION.EXPENSE_PROCESSOR],
      visible: true,
      showInForm: false,
      order: 60,
      formOrder: 90,
    },
    {
      name: 'count_of_items',
      title: 'Count of Items',
      description: 'The count of items claimed',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 110,
      showInForm: false,
      isReadOnly: true,
    }, {
      name: 'count_of_notes',
      title: 'Count of Notes',
      description: 'The count of the notes assigned to this claim',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 120,
      showInForm: false,
      isReadOnly: true,
    }, {
      name: 'count_of_files',
      title: 'Count of Files',
      description: 'The count of the files uploaded to support this claim',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 130,
      showInForm: false,
      isReadOnly: true,
    },
  ],
  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Expense Claim Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'Summary',
      title: 'summary',
      description: 'Expense Batch ITEMS',
      widgetDefinition: WIDGET_DEFINITION.EXPENSE_CLAIM_ITEM_SUMMARY,
      apiRoute: '/item',
      apiQuery: [
        'with[]=project:id,name,project_number,myob_project_id',
        'with[]=contract:id,status_id,number', // ,quickbooks_id
        'with[]=serviceJob:id,status_id,number', // ,quickbooks_id
        'with[]=receiptFile',
        'with[]=category',
        'pagelength=0',
      ].join('&'),
    },
    {
      name: 'expenseitems',
      title: 'Items',
      description: 'Items on this Expense Claim',
      widgetDefinition: WIDGET_DEFINITION.EXPENSE_CLAIM_ITEMS,
      apiRoute: '/item',
      apiQuery: [
        'with[]=project:id,name,project_number',
        'with[]=contract:id,status_id,number',
        'with[]=serviceJob:id,status_id,number',
        'with[]=receiptFile:id,filename',
        'with[]=category',
        'pagelength=0',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'count_of_items',
        },
      ],
    },
    {
      name: 'files',
      title: 'Receipts',
      description: 'Documents and Files',
      widgetDefinition: WIDGET_DEFINITION.EXPENSE_CLAIM_FILES,
      apiRoute: '/file',
      apiQuery: [
        'filter[0][field]=confirmed_at',
        `filter[0][operation]=${API_FILTER_OPERATION.NOT_NULL}`,
        'sort[0][field]=filename',
        'sort[0][direction]=asc',
        'pagelength=0',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'count_of_files',
        },
      ],
    },
    {
      name: 'notes',
      title: 'Notes',
      description: 'Expense Claim Notes',
      widgetDefinition: WIDGET_DEFINITION.EXPENSE_CLAIM_NOTES,
      apiRoute: '/note',
      apiQuery: 'with[]=createdBy:id,name&pagelength=100',
      badgeCountFields: [
        {
          fieldName: 'count_of_notes',
          badgeColor: THEME_COLOR.WARNING,
        },
      ],
    },
    {
      ...eventsTabDefault,
      apiQuery: [
        'with[]=createdBy:id,name',
        'with[]=expenseClaimNoteTracking:id,data,story',
        'with[]=expenseClaimItemTracking:id,data,story',
        'pagelength=20',
      ].join('&'),
      description: 'Expense Claim Events',
      statusMap: EXPENSE_CLAIM_STATUS_MAP,
    },
  ],

  /**
   * Action settings for action buttons and related dialogs
   * @see https://wiki.ciportal.net/books/ci-portal-developer-documentation/page/expense-claim-workflow-actions
   */
  possibleActions: [
    // Commented out because we want admins to create expense claims via the correct route
    // {
    //   name: 'create',
    //   title: 'New Expense Claim',
    //   color: BUTTON_COLOR.PRIMARY,
    //   icon: ICON.ADD,
    //   description: 'Create a new',
    //   successMessage: 'Created',
    //   failMessage: 'Failed to Create',
    //   hideRecordActionButton: true,
    // },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update',
      successMessage: 'Updated',
      failMessage: 'Failed to update',
      hideRecordActionButton: true,
    },
    {
      name: 'submit',
      title: 'Submit',
      color: BUTTON_COLOR.INFO,
      icon: ICON.SUBMIT,
      description: 'This will submit the Expense Claim',
      successMessage: 'Expense claim submitted',
      failMessage: 'Failed to submit expense claim',
    },
    // Commented-out for now as we don't have back-end functionality for it yet
    // it will currently only change status. Use the batch button in column
    // {
    //   name: 'batch',
    //   title: 'Batch',
    //   color: BUTTON_COLOR.PRIMARY,
    //   icon: ICON.CHECK,
    //   description: 'This will assign the expense claim to a Batch',
    //   successMessage: 'Expense claim batched',
    //   failMessage: 'Failed to batch expense claim',
    // },
    {
      name: 'unbatch',
      title: 'Un-batch',
      color: BUTTON_COLOR.WARNING,
      icon: 'undo',
      description: 'This will un-batch the expense claim and mark it as Approved',
      successMessage: 'Expense claim successfully un-batched',
      failMessage: 'Failed to un-batch expense claim',
    },
    {
      name: 'reject',
      title: 'Reject',
      color: BUTTON_COLOR.DANGER,
      icon: 'times',
      description: 'This will REJECT the expense claim',
      successMessage: 'Expense claim rejected',
      failMessage: 'Failed to reject expense claim',
    },
    {
      name: 'unsubmit',
      title: 'Withdraw',
      color: BUTTON_COLOR.WARNING,
      icon: 'undo',
      description: 'This will WITHDRAW (unsubmit) the expense claim',
      successMessage: 'Expense claim withdrawn',
      failMessage: 'Failed to withdraw expense claim',
    },
    {
      name: 'approve',
      title: 'Approve',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.CHECK,
      description: 'This will approve the expense claim',
      successMessage: 'Expense claim approved',
      failMessage: 'Failed to delete expense claim',
    },
    {
      name: 'revoke',
      title: 'Revoke',
      color: BUTTON_COLOR.DANGER,
      icon: 'undo',
      description: 'This will REVOKE the approval of this expense claim',
      successMessage: 'Expense claim revoked',
      failMessage: 'Failed to revoke expense claim',
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the expense claim',
      successMessage: 'Expense claim deleted',
      failMessage: 'Failed to delete expense claim',
      hideRecordActionButton: true,
    },
  ],

  /**
   * View definitions
   */
  viewKey: 'pay_run',
  views: [
    {
      key: 'pay_run',
      title: 'For Current Pay Run',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.IN,
          values: ['approved', 'batched', 'processed'],
        },
      ],
      flags: [],
    },
    {
      key: 'approved',
      title: 'Approved Expense Claims',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['approved'],
        },
      ],
      flags: [],
    },
    {
      key: 'batched',
      title: 'Batched / Processed Expense Claims',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.IN,
          values: ['batched', 'processed'],
        },
      ],
      flags: [],
    },
    {
      key: 'paid',
      title: 'Paid Expense Claims',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['paid'],
        },
      ],
      flags: [],
    },
    {
      key: 'all',
      title: 'All Expense Claims',
      filters: [],
      flags: [],
    },
  ],
};

export default processExpenseClaimsTableInitialSettings;
