import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SalesForecastTableCellGroup from './sales-forecast-table-cell-group';
import SalesForecastTableRow from './sales-forecast-table-row';
import { DATA_TYPE, buildDomDataTagId } from './sales-forecast-helpers';
import Icon from '../../layout-helpers/icon';
import SalesForecastTableSalespersonMonthlyDataRows from './sales-forecast-table-salesperson-monthly-data-rows';

const SalesForecastTableStateMonthlyDataRows = (props) => {
  const {
    monthlyFields,
    monthGroups,
    visibleMonthlyFieldCount,
    stateIds,
    pmAndStateIds,
    hideZeroValues,

    onClick,
    onBeginHover,
    onEndHover,

    geographicalStateData,
    salespersonData,
    projectData,
  } = props;

  return (
    <>
      {/* No Geographical State Monthly Data - just display an empty cell. */}
      {(!!geographicalStateData.error) && (
        <SalesForecastTableRow
          className={classNames('geographical-state', 'monthly', 'data', 'no-data', { error: !!geographicalStateData.error, loading: geographicalStateData.isLoading })}
        >
          {monthGroups.map((monthGroup) => (
            <SalesForecastTableCellGroup
              key={`geographical_state_${monthGroup.key}`}
              span={visibleMonthlyFieldCount}
            />
          ))}
        </SalesForecastTableRow>
      )}

      {!geographicalStateData.error && !!geographicalStateData.summaryData.data.length && (
        <>
          {/* State Monthly Data Fields */}
          {geographicalStateData.summaryData.data.map((geographicalState) => (
            // Iterate over the visible months
            <React.Fragment
              key={`state_${geographicalState.state_id}`}
            >

              <SalesForecastTableRow
                key={`state_${geographicalState.state_id}_monthly_data`}
                className={classNames('geographical-state', 'monthly', 'data', {
                  loading: geographicalStateData.isLoading,
                  expanded: stateIds.includes(geographicalState.state_id),
                })}
                trackHover
                dataType={DATA_TYPE.GEOGRAPHICAL_STATE}
                id={buildDomDataTagId(DATA_TYPE.GEOGRAPHICAL_STATE, geographicalState.state_id)}
                onBeginHover={onBeginHover}
                onEndHover={onEndHover}
                onClick={onClick}
              >
                {monthGroups.map((monthGroup) => {
                  // Look for the state / month combination in the geographical state monthly data
                  const geographicalStateMonthData = geographicalStateData.monthlyData.data
                    .find((data) => data.state_id === geographicalState.state_id && data.month === monthGroup.key);

                  // Found it; Render it.
                  if (geographicalStateMonthData) {
                    return (
                      <SalesForecastTableCellGroup
                        key={`state_${geographicalState.state_id}_${monthGroup.key}`}
                        rowKey={`state_${geographicalState.state_id}_${monthGroup.key}`}
                        hideZeroValues={hideZeroValues}
                        fields={monthlyFields}
                        data={geographicalStateMonthData}
                      />
                    );
                  }

                  // Doesn't exist - render an empty / loading cell
                  return (
                    <SalesForecastTableCellGroup
                      key={`state_${geographicalState.state_id}_${monthGroup.key}_loading`}
                      span={visibleMonthlyFieldCount}
                    >
                      {geographicalStateData.isLoading && (
                        <>
                          <Icon i="rolling" />
                          <span>Loading...</span>
                        </>
                      )}
                    </SalesForecastTableCellGroup>
                  );
                })}
              </SalesForecastTableRow>

              {/* Salesperson Monthly Data */}
              {salespersonData.find((salesperson) => salesperson.stateId === geographicalState.state_id) && (
                <SalesForecastTableSalespersonMonthlyDataRows
                  monthGroups={monthGroups}
                  monthlyFields={monthlyFields}
                  visibleMonthlyFieldCount={visibleMonthlyFieldCount}
                  pmAndStateIds={pmAndStateIds}
                  hideZeroValues={hideZeroValues}

                  onClick={onClick}
                  onBeginHover={onBeginHover}
                  onEndHover={onEndHover}

                  stateId={geographicalState.state_id}
                  salespersonData={salespersonData.find((salesperson) => salesperson.stateId === geographicalState.state_id)}
                  projectData={projectData}
                />
              )}

            </React.Fragment>

          ))}

          {/* Monthly Data totals row */}
          <SalesForecastTableRow
            className={classNames('geographical-state', 'monthly', 'total', { loading: geographicalStateData.isLoading })}
          >
            {monthGroups.map((monthGroup) => (
              <SalesForecastTableCellGroup
                key={`monthly_totals_${monthGroup.key}`}
                rowKey={`monthly_totals_${monthGroup.key}`}
                fields={monthlyFields}
                hideZeroValues={hideZeroValues}
                data={geographicalStateData.monthlyData.data
                  .filter((geographicalStateMonth) => geographicalStateMonth.month === monthGroup.key)
                  .reduce((accumulator, currentValue) => {
                    const result = {};
                    monthlyFields.forEach((monthField) => {
                      result[monthField.key] = (accumulator[monthField.key] || 0) + parseFloat(currentValue[monthField.key] || 0);
                    });
                    return result;
                  }, {})}
              />
            ))}
          </SalesForecastTableRow>
        </>
      )}
    </>
  );
};

SalesForecastTableStateMonthlyDataRows.propTypes = {
  monthlyFields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  })).isRequired,
  monthGroups: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  geographicalStateData: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    summaryData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
    monthlyData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
  }).isRequired,
  salespersonData: PropTypes.arrayOf(
    PropTypes.shape({
      stateId: PropTypes.number.isRequired,
      isLoading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      summaryData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
      monthlyData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
    }),
  ).isRequired,
  projectData: PropTypes.arrayOf(
    PropTypes.shape({
      stateId: PropTypes.number.isRequired,
      salespersonId: PropTypes.number.isRequired,
      isLoading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      summaryData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
      monthlyData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
    }),
  ).isRequired,
  visibleMonthlyFieldCount: PropTypes.number.isRequired,
  stateIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  pmAndStateIds: PropTypes.arrayOf(PropTypes.shape({
    pmId: PropTypes.number.isRequired,
    stateId: PropTypes.number.isRequired,
  })).isRequired,
  onClick: PropTypes.func.isRequired,
  onBeginHover: PropTypes.func.isRequired,
  onEndHover: PropTypes.func.isRequired,
  hideZeroValues: PropTypes.bool.isRequired,
};

export default SalesForecastTableStateMonthlyDataRows;
