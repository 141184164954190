import React, { useCallback, useState } from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import moment from 'moment';

import { IContactRecord } from '../../types/contact/contact.record.interface';
import { WidgetProps } from '../../types/widget.props';

import ProjectsByStatusChart from '../portal-fusion-chart/projects-by-status-chart';
import { PROJECTS_BY_STATUS_MONTH_VALUE_TYPE } from '../portal-fusion-chart/projects-by-status-month-chart';

import { startCase } from '../../utils/helpers';

import { PROJECTS_BY_STATUS_VALUE_TYPE } from '../../constants/projects-by-status-value-type.const';

const PRIMARY_CONTACTS_PROJECTS_BY_STATUS_CHART = 'projects_by_primary_contact_by_status_chart';


export type ContactDashboardsWidgetProps = Omit<WidgetProps, 'rowData'> & {
  rowData: IContactRecord,
}


export const ContactDashboardsWidget:React.FC<ContactDashboardsWidgetProps> = (props) => {
  const {
    rowData,
  } = props;

  const [primaryContactsProjectsByStatusChartIsLoading, setSrimaryContactsProjectsByStatusChartIsLoading] = useState<boolean>(true);
  const [primaryContactsProjectsByStatusChartValueType, setPrimaryContactsProjectsByStatusChartValueType] = useState<string>(PROJECTS_BY_STATUS_VALUE_TYPE.VALUE);
  const [primaryContactsProjectsByStatusChartIncludeArchived, setPrimaryContactsProjectsByStatusChartIncludeArchived] = useState(false);


  /**
   * Fired when the a chart starts or finishes loading
   *
   * @param chartId
   * @param isLoading
   */
  const handleChartLoading = useCallback((chartId: string, isLoading: boolean) => {
    if (chartId === PRIMARY_CONTACTS_PROJECTS_BY_STATUS_CHART) {
      setSrimaryContactsProjectsByStatusChartIsLoading(isLoading);
    }
  }, []);


  /**
   * Set the value type for a chart
   *
   * @param chartId
   * @param valueType
   */
  const handleSetChartValueType = useCallback((chartId: string, valueType: string) => {
    if (chartId === PRIMARY_CONTACTS_PROJECTS_BY_STATUS_CHART) {
      setPrimaryContactsProjectsByStatusChartValueType(valueType);
    }
  }, []);


  /**
   * Render the Primary Contact Projects By Status chart
   *
   * @returns {JSX.Element}
   */
  const renderPrimaryContactProjectsByStatusChart = () => {
    let primaryContactsProjectsByStatusChartCaption = 'Value by Status (AUD)';
    if (primaryContactsProjectsByStatusChartValueType === PROJECTS_BY_STATUS_MONTH_VALUE_TYPE.COUNT) primaryContactsProjectsByStatusChartCaption = 'Count by Status';

    const { id: personId, user_audit: userAudit } = rowData;
    const { created_at: rawCreatedAt } = userAudit;
    const personCreatedAt = moment(rawCreatedAt);

    return (
      <div className="chart-wrapper client-projects-by-status">
        <div className="chart-title">
          <h4>Contact&apos;s Projects</h4>
          <div className="chart-buttons">
            <Button
              onClick={() => setPrimaryContactsProjectsByStatusChartIncludeArchived(!primaryContactsProjectsByStatusChartIncludeArchived)}
              active={primaryContactsProjectsByStatusChartIncludeArchived}
              size="sm"
              color="secondary"
            >
              Include archived?
            </Button>
            <ButtonGroup>
              {
                Object.values(PROJECTS_BY_STATUS_MONTH_VALUE_TYPE).map((valueType) => (
                  <Button
                    key={valueType}
                    onClick={() => handleSetChartValueType(PRIMARY_CONTACTS_PROJECTS_BY_STATUS_CHART, valueType)}
                    disabled={primaryContactsProjectsByStatusChartIsLoading}
                    size="sm"
                    color="secondary"
                    active={valueType === primaryContactsProjectsByStatusChartValueType}
                  >
                    {startCase(valueType)}
                  </Button>
                ))
              }
            </ButtonGroup>
          </div>
        </div>
        <ProjectsByStatusChart
          id={PRIMARY_CONTACTS_PROJECTS_BY_STATUS_CHART}
          chartCaption={primaryContactsProjectsByStatusChartCaption}
          includeArchived={primaryContactsProjectsByStatusChartIncludeArchived}
          valueType={primaryContactsProjectsByStatusChartValueType}
          onLoading={handleChartLoading}
          showValues
          primaryContactId={personId}
          dateTo={moment()}
          dateFrom={personCreatedAt}
        />
      </div>
    );
  };


  // Render
  return (
    <div className="widget dashboard-widget company-dashboard">
      {renderPrimaryContactProjectsByStatusChart()}
    </div>
  );
};
