/* eslint-disable import/no-cycle */

import React, { useState, Fragment } from 'react';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { ICON } from '../../../constants/icon.const';
import Icon from '../../layout-helpers/icon';
import { formatValueString } from '../../render-functions';

type ProfitAndLossTableAccountLineSectionProps = {
  categoryRow: {
    id: number,
    title: string,
    summary_title: string,
    summary_value: number,
    has_account_lines: boolean
    quick_books_profit_and_loss_account_lines: {
      id: number,
      title: string,
      value: number,
    }[],
  }
};

export const ProfitAndLossTableAccountLineSection: React.FC<ProfitAndLossTableAccountLineSectionProps> = ({ categoryRow }) => {
  const [showDetail, setShowDetail] = useState(false);
  const handleRowClick = () => setShowDetail(!showDetail);
  return (
    <Fragment key={categoryRow.id}>
      <tr onClick={handleRowClick}>
        <td className="font-italic" style={{ cursor: 'pointer' }}>
          <Icon i={showDetail ? ICON.CARET_DOWN : ICON.CARET_RIGHT} className="pr-1" />
          {categoryRow.title}
        </td>
        <td className="text-right">&nbsp;</td>
      </tr>
      {showDetail && categoryRow.quick_books_profit_and_loss_account_lines &&
        categoryRow.quick_books_profit_and_loss_account_lines.map((accountLine) => (
          <tr key={accountLine.id}>
            <td className="pl-5">{accountLine.title}</td>
            <td className="text-right">
              {formatValueString(accountLine.value, COLUMN_FORMAT.CURRENCY)}
            </td>
          </tr>
        ))}
      <tr>
        <td className="">{categoryRow.summary_title}</td>
        <td className="text-right">
          {formatValueString(categoryRow.summary_value, COLUMN_FORMAT.CURRENCY)}
        </td>
      </tr>
    </Fragment>
  );
};
