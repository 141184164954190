export const WIDGET_DEFINITION = {
  AVAILABLE_LEAVE: 'AVAILABLE_LEAVE',
  COMMISSION_STRUCTURE_BRACKETS: 'COMMISSION_STRUCTURE_BRACKETS',
  COMMISSION_BATCH_RATE_AUDIT: 'COMMISSION_BATCH_RATE_AUDIT',
  COMMISSION_PAYMENT_FORECAST: 'COMMISSION_PAYMENT_FORECAST',
  COMMISSION_PAYMENT_FORECAST_DETAIL: 'COMMISSION_PAYMENT_FORECAST_DETAIL',
  COMMISSION_PAYMENT_SUMMARY: 'COMMISSION_PAYMENT_SUMMARY',
  COMMISSION_PAYMENT_DETAIL: 'COMMISSION_PAYMENT_DETAIL',
  COMPANY_CONTACTS: 'COMPANY_CONTACTS',
  COMPANY_CONTRACTS: 'COMPANY_CONTRACTS',
  COMPANY_DASHBOARDS: 'COMPANY_DASHBOARDS',
  COMPANY_LOCATIONS: 'COMPANY_LOCATIONS',
  COMPANY_NOTES: 'COMPANY_NOTES',
  CONTACT_DASHBOARDS: 'CONTACT_DASHBOARDS',
  CONTRACT_CONTRACT_SUBTYPES: 'CONTRACT_CONTRACT_SUBTYPES',
  CONTRACT_INVOICE_REQUESTS: 'CONTRACT_INVOICE_REQUESTS',
  CONTRACT_NOTE: 'CONTRACT_NOTE',
  CONTRACT_QUICKBOOKS: 'CONTRACT_QUICKBOOKS',
  CONTRACT_SCHEDULED_CHECKS: 'CONTRACT_SCHEDULED_CHECKS',
  CONTRACT_SERIALS: 'CONTRACT_SERIALS',
  CONTRACT_SPACES_IMPORT: 'CONTRACT_SPACES_IMPORT',
  CONTRACT_SPACES: 'CONTRACT_SPACES',
  CONTRACT_TYPE_SUBTYPES: 'CONTRACT_TYPE_SUBTYPES',
  DRIVE_FILES: 'DRIVE_FILES',
  DTOOLS_ITEM_NOTES: 'DTOOLS_ITEM_NOTES',
  DTOOLS_LIVE_INFO: 'DTOOLS_LIVE_INFO',
  EVENT_TRACKING: 'EVENT_TRACKING',
  EXPENSE_BATCH_EXPENSE_CLAIMS: 'EXPENSE_BATCH_EXPENSE_CLAIMS',
  EXPENSE_BATCH_ITEM_SUMMARY: 'EXPENSE_BATCH_ITEM_SUMMARY',
  EXPENSE_CLAIM_FILES: 'EXPENSE_CLAIM_FILES',
  EXPENSE_CLAIM_ITEM_SUMMARY: 'EXPENSE_CLAIM_ITEM_SUMMARY',
  EXPENSE_CLAIM_ITEMS: 'EXPENSE_CLAIM_ITEMS',
  EXPENSE_CLAIM_NOTES: 'EXPENSE_CLAIM_NOTES',
  INTERNAL_PROJECT_CARD: 'INTERNAL_PROJECT_CARD',
  INTERNAL_PROJECT_DETAILS: 'INTERNAL_PROJECT_DETAILS',
  INTERNAL_PROJECT_NOTE: 'INTERNAL_PROJECT_NOTE',
  INTERNAL_PROJECT_STAKEHOLDERS: 'INTERNAL_PROJECT_STAKEHOLDERS',
  INTERNAL_PROJECT_TASKS: 'INTERNAL_PROJECT_TASKS',
  INTERNAL_PROJECTS: 'INTERNAL_PROJECTS',
  INVOICE_BILLING_INFO: 'INVOICE_BILLING_INFO',
  INVOICE_REQUEST_ATTACHMENTS: 'INVOICE_REQUEST_ATTACHMENTS',
  INVOICE_REQUEST_LINES: 'INVOICE_REQUEST_LINES',
  INVOICE_REQUEST_NOTES: 'INVOICE_REQUEST_NOTES',
  INVOICE_REQUEST_QUICKBOOKS: 'INVOICE_REQUEST_QUICKBOOKS',
  LEAVE_APPLICATION_FILES: 'LEAVE_APPLICATION_FILES',
  LEAVE_APPLICATION_NOTES: 'LEAVE_APPLICATION_NOTES',
  LEAVE_BLOCKS: 'LEAVE_BLOCKS',
  NAME_AND_DESCRIPTION_ONLY: 'NAME_AND_DESCRIPTION_ONLY',
  PROJECT_ACCOUNTING: 'PROJECT_ACCOUNTING',
  PROJECT_ADMIN: 'PROJECT_ADMIN',
  PROJECT_CHECKLIST: 'PROJECT_CHECKLIST',
  PROJECT_CLIENT_ORDERS: 'PROJECT_CLIENT_ORDERS',
  PROJECT_COMMISSION_INFORMATION: 'PROJECT_COMMISSION_INFORMATION',
  PROJECT_COMMISSION_PAYMENTS: 'PROJECT_COMMISSION_PAYMENTS',
  PROJECT_COMPANIES: 'PROJECT_COMPANIES',
  PROJECT_CONTACTS: 'PROJECT_CONTACTS',
  PROJECT_CONTRACTS: 'PROJECT_CONTRACTS',
  PROJECT_DRIVE: 'PROJECT_DRIVE',
  PROJECT_DTOOLS_CHANGE_ORDERS: 'PROJECT_DTOOLS_CHANGE_ORDERS',
  PROJECT_DTOOLS_PROJECT: 'PROJECT_DTOOLS_PROJECT',
  PROJECT_FORECASTS: 'PROJECT_FORECASTS',
  PROJECT_INVOICE_REQUESTS: 'PROJECT_INVOICE_REQUESTS',
  PROJECT_INVOICES: 'PROJECT_INVOICES',
  PROJECT_ITEM_ETA_SUMMARY: 'PROJECT_ITEM_ETA_SUMMARY',
  PROJECT_LOCATIONS: 'PROJECT_LOCATIONS',
  PROJECT_MYOB_ORDERS: 'PROJECT_MYOB_ORDERS',
  PROJECT_NOTES: 'PROJECT_NOTES',
  PROJECT_PROFITABILITY: 'PROJECT_PROFITABILITY',
  PROJECT_QUICKBOOKS_ORDERS: 'PROJECT_QUICKBOOKS_ORDERS',
  PROJECT_RESOURCES: 'PROJECT_RESOURCES',
  PROJECTS: 'PROJECTS',
  ROW_DETAILS: 'ROW_DETAILS',
  SECURITY_PERMISSION_ROLE: 'SECURITY_PERMISSION_ROLE',
  SECURITY_PERMISSION_USER: 'SECURITY_PERMISSION_USER',
  SECURITY_ROLE_PERMISSIONS: 'SECURITY_ROLE_PERMISSIONS',
  SECURITY_ROLE_USER: 'SECURITY_ROLE_USER',
  SERVICE_JOB_INVOICE_REQUESTS: 'SERVICE_JOB_INVOICE_REQUESTS',
  SERVICE_JOB_QUICKBOOKS: 'SERVICE_JOB_QUICKBOOKS',
  SPRINT_SWIMLANES: 'SPRINT_SWIMLANES',
  SPRINT_TASKS: 'SPRINT_TASKS',
  TYPE_CONTRACTS: 'TYPE_CONTRACTS',
  USER_MYOB_INFO: 'USER_MYOB_INFO',
  USER_SALES_TARGETS: 'USER_SALES_TARGETS',
  USER_NOTIFICATIONS: 'USER_NOTIFICATIONS',
  USER_ROLES: 'USER_ROLES',
  ZENDESK_TICKET_DETAILS: 'ZENDESK_TICKET_DETAILS',
} as const;

export type WIDGET_DEFINITION = typeof WIDGET_DEFINITION;
export type A_WIDGET_DEFINITION = WIDGET_DEFINITION[keyof WIDGET_DEFINITION];
