import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { LikelihoodInput } from './form-input/likelihood-input';
import Icon from './layout-helpers/icon';
import BatchButton from './data-format/batch-button';
import { ProgressPercentInput } from './form-input/progress-percent-input';
import StatusBadge from './data-format/status-badge';
import { ProjectNumber } from './data-format/project-number';
import ColumnLinkButton from './data-format/column-link-button';
import { FileInput } from './form-input/file-input';
import { AbbreviatedExternalLink } from './data-format/abbreviated-external-link';
import { DtoolsCacheStatus } from './form-input/dtools-cache-status';

import { currencyAUD, decimalAU, debugLog } from '../utils/helpers';

import { RENDER_TEXT_MAX_LEN, monthsSelect } from '../utils/constants';
import { COLUMN_FORMAT, DEFAULT_COLUMN_ALIGNMENTS, DEFAULT_COLUMN_CLASSES } from '../constants/column-format.const';
import { CompanyLocationTypeIconMap } from '../constants/company-location-type.const';
import { ICON } from '../constants/icon.const';


/**
 * @description
 * return string representing whether the number is positive, negative or neutral
 *
 * @param {string|number} value string of test eg. '-$10,000.00'
 * @returns {string} one of 'neutral', 'positive', 'negative'
 */
export const currencyColorClass = (value) => {
  if (typeof value === 'string') {
    if (value.indexOf('-') > -1) {
      return 'negative';
    }
    if (value.indexOf('$0') > -1 || value === '0') {
      return 'neutral';
    }
  }
  else {
    if (value === null || value === 0) {
      return 'neutral';
    }
    if (value < 0) {
      return 'negative';
    }
  }
  return 'positive';
};


/**
 * @description
 * Get a classname for surrounding div tag based on a defined format
 *
 * @param {string} format
 * @param {boolean} inTable [inTable=false] the component is specifically for display in a table
 *
 * @returns className for surrounding div
 */
const formatAlignClass = (format = COLUMN_FORMAT.TEXT, inTable = false) => {
  // Format alignments don't apply to forms. They always align left.
  if (!inTable) return 'text-left';

  return (DEFAULT_COLUMN_ALIGNMENTS[format] && DEFAULT_COLUMN_ALIGNMENTS[format] !== 'left') ? `text-${DEFAULT_COLUMN_ALIGNMENTS[format]}` : '';
};


const getFormatNameFromColumn = (column) => {
  if (column && column.format === COLUMN_FORMAT.OBJECT && column.object && column.object.format) {
    return column.object.format;
  }
  if (column) {
    return column.format;
  }
  return null;
};


/**
 * @description
 * Get the alignment class name for surrounding div tag based on a defined column type definition
 *
 * @param {object} column
 * @returns className for surrounding div
 */
const formatAlignTableColumn = (column) => formatAlignClass(getFormatNameFromColumn(column), true);


/**
 * @description
 * Get all class names for the surrounding div tag based on the defined column type definition
 *
 * @returns
 * @param {object} column
 */
const getColumnClasses = (column) => {
  const defaultColumnClass = DEFAULT_COLUMN_CLASSES[getFormatNameFromColumn(column)] || '';
  return classNames(formatAlignTableColumn(column), defaultColumnClass);
};


/**
 * @description
 * Process a value to be formatted as a plain string.
 * If the format does not exist, the value is returned unmodified.
 *
 * @note: There is no HTML / JSX allowed to be returned from this function
 *
 * No alignment, css or formatting other than plain text is performed by this function
 *
 * @param {*} value Any value type
 * @param {COLUMN_FORMAT[keyof COLUMN_FORMAT]} format
 * @param { { id: string | number, name: string | number }[] } [idNameOptions=undefined]
 *
 * @return {string} formatted value
 */
const formatValueString = (value, format, idNameOptions) => {
  // testing this here to see if it's a more appropriate response for undefined data.
  if (value === undefined) return undefined;

  // @todo this could probably be a helper somewhere
  const momentRoundingDefault = moment.relativeTimeRounding();
  const momentRoundingTwoPlaces = (tVal) => {
    const modulo = tVal % 1;
    // if it's so damn close, round it to a whole
    if (modulo < 0.03 || modulo > 0.97) return tVal.toFixed(0);
    // return whole or rounded
    return modulo === 0 ? tVal : tVal.toPrecision(2);
  };
  let returnVal; // for when we need to restore moment after rounding.
  let div;

  switch (format) {
    case COLUMN_FORMAT.ID_NAME_OPTIONS: {
      if (!(idNameOptions) || !(idNameOptions instanceof Array)) {
        debugLog('FormatValueString', 'warn', 'idNameOptions should be provided for COLUMN_FORMAT.ID_NAME_OPTIONS', '😡');
        return value;
      }

      const foundOption = idNameOptions.find((option) => option.id === value);
      if (!foundOption) {
        if (value !== null) {
          debugLog('FormatValueString', 'warn', `the value "${value}" could not be found in the idNameOptions list`, '🧩');
        }
        return value;
      }
      return foundOption.name;
    }

    case COLUMN_FORMAT.TIMESTAMP:
      return value === null || value.toString() === '0'
        ? null
        : moment(value).format('D/M/YY h:mm a');

    case COLUMN_FORMAT.LONG_DATE:
      return value === null || value.toString() === '0' ? null : moment(value).format('DD/MM/YYYY');

    case COLUMN_FORMAT.CURRENCY:
      return currencyAUD(value);

    case COLUMN_FORMAT.DATE:
      return value === null || value.toString() === '0' ? null : moment(value).format('D/M/YY');

    case COLUMN_FORMAT.LEAVE_DAYS:
      return `${Number(value).toFixed(1)} days`;

    case COLUMN_FORMAT.CHECKBOX:
    case COLUMN_FORMAT.CHECKBOX_MEDIUM:
      if (idNameOptions && idNameOptions.length === 2) return idNameOptions[value].name;
      return (value === 1) ? 'Yes' : 'No';

    case COLUMN_FORMAT.BIRTHDAY: {
      if (value) {
        const dateBits = value.split('-');
        const month = monthsSelect.find((option) => option.label === dateBits[1]);
        return `${(month && month.shortName) || ''} ${dateBits[2]} ${dateBits[0] !== '0000' ? `, ${dateBits[0]}` : ''}`;
      }
      return null;
    }

    case COLUMN_FORMAT.NULLABLE_NUMBER:
      if (value === null || value === undefined) return null;
      return `${value}`;

    case COLUMN_FORMAT.NUMBER:
    case COLUMN_FORMAT.NUMBER_SHORT:
      return `${value || 0}`;

    case COLUMN_FORMAT.DURATION_HOURS:
      if (value === 0 || value === '0') {
        return '0 hrs';
      }
      if (value === null) {
        return '-';
      }
      moment.relativeTimeRounding(momentRoundingTwoPlaces);
      returnVal = moment.duration(value, 'hours').humanize();
      moment.relativeTimeRounding(momentRoundingDefault);
      return returnVal;

    // @todo consider using https://github.com/lennym/moment-business-time
    case COLUMN_FORMAT.DURATION_BUSINESS_TIME:
      if (value === 0 || value === '0') {
        return '0 hrs';
      }
      if (value === null) {
        return '-';
      }
      moment.relativeTimeRounding(momentRoundingTwoPlaces);
      if (value < 8) {
        returnVal = moment.duration(value, 'hours').humanize();
      } else {
        returnVal = moment.duration(value / 8, 'days').humanize();
      }
      moment.relativeTimeRounding(momentRoundingDefault);
      return returnVal;

    case COLUMN_FORMAT.FRACTIONAL_PERCENTAGE:
      return ((value || value === 0) ? `${Math.round(parseFloat(value) * 10000) / 100}%` : value);

    case COLUMN_FORMAT.HTML_NOTIN_TABLE:
    case COLUMN_FORMAT.STRIP_HTML:
      // This is the recommended way to use the browser functionality to remove html from a string
      div = document.createElement('div');
      div.innerHTML = value;
      return div.textContent || div.innerText || '';

    case COLUMN_FORMAT.MONTH_OF_YEAR:
      return value === null || value.toString() === '0' ? null : moment(value).format('MMM YYYY');

    case COLUMN_FORMAT.WHOLE_DOLLARS:
      if (value === undefined) return undefined;
      return currencyAUD(value, 0);

    case COLUMN_FORMAT.DTOOLS_DATE:
      return value === null || value.toString() === '0'
        ? null
        : moment(new Date(parseInt(value.substr(6, 13), 10))).format('D/M/YY');

    case COLUMN_FORMAT.DTOOLS_TIME:
      return value === null || value.toString() === '0'
        ? null
        : moment(new Date(parseInt(value.substr(6, 13), 10))).format('D/M/YY h:mm:ss a');

    case COLUMN_FORMAT.DTOOLS_UTC_TIME:
      if (value === null || value.toString() === '0') {
        return null;
      }
      return moment(parseInt(value.substr(6, 13), 10)).add(moment().utcOffset(), 'minute').format('D/M/YY h:mm:ss a');

    case COLUMN_FORMAT.DECIMAL:
      return decimalAU(value, 2);

    default:
      return value || '';
  }
};


/**
 * @description
 * Process a value to be formatted. If no the format does not exist, the value is returned
 *
 * @param {*} value Any value type
 * @param {COLUMN_FORMAT[keyof COLUMN_FORMAT]} format
 * @param { { id: string | number, name: string | number }[] | SelectOption[] }  [idNameOptions=undefined]
 * @param {string | (value: any, inTable: boolean) => string} columnClassName a string or function defined on the table definition for providing a custom className
 * @param {boolean} inTable [inTable=false] the component is specifically for display in a table
 *
 * @return {JSX|string} formatted value for render as JSX
 */
const formatValueJSX = (value, format, idNameOptions, columnClassName, inTable = false) => {
  const className = classNames(
    formatAlignClass(format, inTable),
    // eslint-disable-next-line no-nested-ternary
    columnClassName ? (typeof columnClassName === 'function' ? columnClassName(value, inTable) : columnClassName) : undefined,
  );

  switch (format) {
    case COLUMN_FORMAT.TIMESTAMP:
      return <div className={className}>{formatValueString(value, format, idNameOptions)}</div>;

    case COLUMN_FORMAT.LONG_TEXT:
      // truncate with ellipsis only if too long
      return value && typeof value === 'string' && value.length > RENDER_TEXT_MAX_LEN && inTable === true ? (
        <span>
          <span title={formatValueString(value, format, idNameOptions)}>
            {formatValueString(value, format, idNameOptions).substr(0, RENDER_TEXT_MAX_LEN - 1)}
          </span>
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: '&hellip;' }} />
        </span>
      ) : (
        <span>{formatValueString(value, format, idNameOptions)}</span>
      );

    case COLUMN_FORMAT.HTML_NOTIN_TABLE:
      // eslint-disable-next-line react/no-danger
      return !inTable ? <div dangerouslySetInnerHTML={{ __html: value }} /> : formatValueString(value, format, idNameOptions);

    case COLUMN_FORMAT.CURRENCY:
      return <div className={className}>{formatValueString(value, format, idNameOptions)}</div>;

    case COLUMN_FORMAT.STATUS_BADGE:
      return <StatusBadge className={className} status={value} />;

    case COLUMN_FORMAT.ACTIVE_INACTIVE_BADGE:
      return <StatusBadge className={className} status={value ? 'Active' : 'Inactive'} />;

    case COLUMN_FORMAT.URL:
      if (!value) return value;
      return (
        <a rel="noopener noreferrer" target="_blank" href={!(value.startsWith('http')) ? `http://${value}` : value}>
          {value && <Icon i="link" spaceRight />}
          {value}
        </a>
      );

    case COLUMN_FORMAT.ABBREVIATED_EXT_LINK:
      return <AbbreviatedExternalLink href={value} />;

    case COLUMN_FORMAT.EMAIL:
      return (
        <a href={`mailto:${value}`}>
          {value && <Icon i="envelope-o" spaceRight />}
          {value}
        </a>
      );

    case COLUMN_FORMAT.PHONE:
      return (
        <a href={`tel:${value}`}>
          {value && <Icon i="phone" spaceRight />}
          {value}
        </a>
      );

    case COLUMN_FORMAT.NULLABLE_NUMBER:
    case COLUMN_FORMAT.NUMBER:
    case COLUMN_FORMAT.DATE:
    case COLUMN_FORMAT.LONG_DATE:
    case COLUMN_FORMAT.LEAVE_DAYS:
    case COLUMN_FORMAT.CHECKBOX:
    case COLUMN_FORMAT.FILE:
    case COLUMN_FORMAT.MONTH_OF_YEAR:
      return <div className={className}>{formatValueString(value, format, idNameOptions)}</div>;

    case COLUMN_FORMAT.WHOLE_DOLLARS:
      if (value === undefined) return undefined;
      return <div className={className}>{formatValueString(value, format, idNameOptions)}</div>;

    case COLUMN_FORMAT.COMPANY_LOCATION:
    case COLUMN_FORMAT.COMPANY_SPACE:
      return <div className={className}>{formatValueString(value, format)}</div>;

    case COLUMN_FORMAT.ICON_NAME:
      return (
        <div className={className}>
          <Icon i={value} />
          <span>{formatValueString(value, format)}</span>
        </div>
      );

    default:
      return formatValueString(value, format, idNameOptions);
  }
};


/**
 * @description
 *
 * Get the raw value for a table column field
 *
 * @param {object} row object containing value defined by 'column',
 *   parsed down as `formData` from <APIPolyForm />
 * @param {object} column shape of { name, format }
 *
 * @returns {any}
 */
const getFieldValue = (row, column) => {
  let value = row[column.objectKey || column.name];

  if ('getValue' in column && typeof column.getValue === 'function') {
    value = column.getValue(value, row);
  }

  // @todo consolidate getValue and calculate since they probably do the same thing
  if ('calculate' in column && typeof column.calculate === 'function') {
    value = column.calculate(row);
  }

  if (column.format === COLUMN_FORMAT.OBJECT) {
    if (!column.object) {
      throw new Error(`Table Column definition "${column.name}" must have an "object" property to implement the column format "COLUMN_FORMAT.OBJECT"`);
    }
    if (!('sourceField' in column.object)) {
      throw new Error(`Table Column definition "${column.name}" must have an "object.sourceField" property to implement the column format "COLUMN_FORMAT.OBJECT"`);
    }
    if (!('key' in column.object)) {
      throw new Error(`Table Column definition "${column.name}" must have an "object.key" property to implement the column format "COLUMN_FORMAT.OBJECT"`);
    }

    // Cycle back recursively to this function to determine the format of the object rather than re-define formats again
    return getFieldValue(
      row[column.object.sourceField] || {},
      {
        ...column,
        ...column.object,
        getValue: column.object.getValue || column.getValue,
        format: column.object.format || COLUMN_FORMAT.TEXT,
        objectKey: column.object.key,
      },
    );
  }

  return value;
};


/**
 * @description
 * Get the basic string value for a table column field
 *
 * @param {object} row object containing value defined by 'column',
 *   parsed down as `formData` from <APIPolyForm />
 * @param {object} column shape of { name, format }
 *
 * @returns {string}
 */
const getFieldFormattedValue = (row, column) => formatValueString(getFieldValue(row, column), column.format, column.inputOptions);


/**
 * @description
 * Output value for TABLE CELLS AND NOT EDITABLE TEXT FIELDS
 *
 * @param {object} row object containing value defined by 'column'
 * @param {object} column shape of { sourceField, name, format }
 * @param {boolean} inTable [inTable=false] the component is specifically for display in a table
 *
 * @returns {JSX|string} JSX or string rendered
 */
const getFieldDisplayComponent = (row, column, inTable = false) => {
  if (!row) return null;

  let value = row[column.objectKey || column.name];
  let resolvedLinkRouteString = '';

  if ('getValue' in column && typeof column.getValue === 'function') {
    value = column.getValue(value, row);
  }
  // @todo consolidate getValue and calculate since they probably do the same thing
  if ('calculate' in column && typeof column.calculate === 'function') {
    value = column.calculate(row);
  }
  if ('linkRoute' in column && typeof column.linkRoute === 'function') {
    resolvedLinkRouteString = column.linkRoute(row, column);
  }

  switch (column.format) {
    case COLUMN_FORMAT.P_NUMBER:
      return (
        <ProjectNumber
          linkToProject
          linkSubRoute={column.linkSubRoute}
          pData={{ ...row, id: 'project_id' in row ? row.project_id : row.id }}
        />
      );

    case COLUMN_FORMAT.ID_LINK:
    case COLUMN_FORMAT.ID_LINK_SHORT:
    case COLUMN_FORMAT.NAME_LINK:
    case COLUMN_FORMAT.NAME_LINK_LONG:
    case COLUMN_FORMAT.NAME_LINK_SHORT:
      if (!('linkRoute' in column)) {
        throw new Error(`Table Column definition "${column.name}" must have a "linkRoute" property to implement the column format "COLUMN_FORMAT.NAME_LINK"`);
      }
      if (typeof column.linkRoute === 'function') {
        return <Link to={resolvedLinkRouteString}>{value}</Link>;
      }
      return <Link to={`${column.linkRoute}/${('linkId' in column) ? encodeURIComponent(row[column.linkId]) : row.id}`}>{value}</Link>;

    case COLUMN_FORMAT.COMMISSION_BRACKET:
      return (
        <Link to={`/finance/sales-commission/commission-iterations/${row.iteration_id}/brackets`}>
          {`Bracket ${row.id}. `}
          &nbsp;
          {` Threshold: ${row.net_profit_threshold}% `}
              &nbsp; &nbsp;
          {` Rate: ${row.commission_percentage}% `}
        </Link>
      );

    case COLUMN_FORMAT.COMPANY_SPACE_TYPE:
      return (
        <>
          <Icon i={row.icon ?? ICON.SPACE} />
          <span>{value}</span>
        </>
      );

    case COLUMN_FORMAT.COMPANY_LOCATION:
      if (!('linkRoute' in column)) {
        throw new Error(`Table Column definition "${column.name}" must have a "linkRoute" property to implement the column format "COLUMN_FORMAT.COMPANY_LOCATION"`);
      }

      if (typeof column.linkRoute === 'function') {
        return (
          <Link to={resolvedLinkRouteString} title={value}>
            <Icon i={CompanyLocationTypeIconMap[row.type_id]} />
            <span>{value}</span>
          </Link>
        );
      }
      return (
        <Link to={`${column.linkRoute}/${('linkId' in column) ? encodeURIComponent(row[column.linkId]) : row.id}`} title={value}>
          <Icon i={CompanyLocationTypeIconMap[row.type_id]} />
          <span>{value}</span>
        </Link>
      );

    case COLUMN_FORMAT.COMPANY_SPACE:
      if (!('linkRoute' in column)) {
        throw new Error(`Table Column definition "${column.name}" must have a "linkRoute" property to implement the column format "COLUMN_FORMAT.COMPANY_SPACE"`);
      }

      if (typeof column.linkRoute === 'function') {
        return (
          <Link to={resolvedLinkRouteString} title={value}>
            <Icon i={row.icon ?? row.type.icon ?? ICON.SPACE} />
            <span>{value}</span>
          </Link>
        );
      }
      return (
        <Link to={`${column.linkRoute}/${('linkId' in column) ? encodeURIComponent(row[column.linkId]) : row.id}`} title={value}>
          <Icon i={row.icon ?? row.type.icon ?? ICON.SPACE} />
          <span>{value}</span>
        </Link>
      );

    case COLUMN_FORMAT.PROJECT_FORECAST_LINK:
      if (!value) return value;
      return (
        <div>
          <Link to={`/crm/projects/${row.project_id}/forcasting`}>
            {formatValueString(row.amount, 'whole-dollars')}
            {' - '}
            {formatValueString(row.expected_by, 'date')}
          </Link>
          {row.comment && !inTable && (
            <>
              <br />
              {row.comment}
              {' '}
            </>
          )}
        </div>
      );

    case COLUMN_FORMAT.LIKELIHOOD:
      return <LikelihoodInput id={column.name} value={value} disabled />;

    case COLUMN_FORMAT.PROGRESS_PERCENT:
      return <ProgressPercentInput id={column.name} value={value} disabled />;

    case COLUMN_FORMAT.DTOOLS_CACHE_STATUS:
      return <DtoolsCacheStatus id={column.name} row={row} />;

    case COLUMN_FORMAT.OBJECT:
      if (!column.object) {
        throw new Error(`Table Column definition "${column.name}" must have an "object" property to implement the column format "COLUMN_FORMAT.OBJECT"`);
      }
      if (!('sourceField' in column.object)) {
        throw new Error(`Table Column definition "${column.name}" must have an "object.sourceField" property to implement the column format "COLUMN_FORMAT.OBJECT"`);
      }
      if (!('key' in column.object)) {
        throw new Error(`Table Column definition "${column.name}" must have an "object.key" property to implement the column format "COLUMN_FORMAT.OBJECT"`);
      }

      // Cycle back recursively to this function to determine the format of the object rather than re-define formats again
      return getFieldDisplayComponent(
        row[column.object.sourceField] || null,
        {
          ...column,
          ...column.object,
          getValue: column.object.getValue || column.getValue,
          format: column.object.format || COLUMN_FORMAT.TEXT,
          objectKey: column.object.key,
        },
        inTable,
      );

    case COLUMN_FORMAT.BUTTON_LINK:
      return <ColumnLinkButton row={row} column={column} />;

    case COLUMN_FORMAT.FILE:
      return <FileInput isReadOnly {...column} formData={row} value={getFieldValue(row, column)} />;

    case COLUMN_FORMAT.BATCH_BUTTON:
      return <BatchButton row={row} column={column} />;

    default:
      return formatValueJSX(value, column.format, column.inputOptions, column.className, inTable);
  }
};


export {
  formatValueString,
  formatValueJSX,
  getFieldValue,
  getFieldFormattedValue,
  getFieldDisplayComponent,
  getColumnClasses,
  formatAlignClass,
  formatAlignTableColumn,
};
