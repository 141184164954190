import React from 'react';
import PropTypes from 'prop-types';
import { noop } from '../../../utils/helpers';
import IntegrationPanel from './integration-panel';
import ApiConnector from './api-connector';

const ZendeskPanel = ({ connectHandler, status }) => (
  <IntegrationPanel title="Zendesk API Connector" color="green">
    <ApiConnector status={status} color="green" connectHandler={connectHandler} />
  </IntegrationPanel>
);

ZendeskPanel.propTypes = {
  connectHandler: PropTypes.func,
  status: PropTypes.shape({
    message: PropTypes.string,
    loading: PropTypes.bool,
    connected: PropTypes.bool,
  }),
};

ZendeskPanel.defaultProps = {
  connectHandler: noop,
  status: null,
};

export default ZendeskPanel;
