import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

export const UserRolesWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Role',
  collectionItemPrimaryKeyFieldName: 'name',
  fields: [
    {
      name: 'name',
      title: 'Role Tag',
      visible: true,
      format: COLUMN_FORMAT.TEXT,
      formFieldType: FORM_FIELD_TYPE.USER_ROLE_PICKER,
      formSaveField: 'name',
    },
    {
      name: 'description',
      title: 'Role Description',
      visible: true,
      format: COLUMN_FORMAT.LONG_TEXT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
  ],
};
