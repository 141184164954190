import React from 'react';
import PropTypes from 'prop-types';

/**
 * @description
 * Breadcrumb trail of locations
 *
 * @type {{location: object}[]} historyStack
 */
export const historyStack = [];

const currentLocation = () => (historyStack.length ? historyStack[historyStack.length - 1] : null);
const previousLocation = () => (historyStack.length > 1 ? historyStack[historyStack.length - 2] : null);
const getLocationURL = (location) => (location ? `${location.pathname}${location.search}` : null);
const homeLocation = () => ({ pathname: '/', search: '' });

/**
 * @description
 * If there is a previous location, push to it
 * Otherwise, push to the new location
 *
 * TODO: This needs to accept a conditional regex rather than assuming the
 * previous location is acceptable.
 * Example: Viewing a news article, clicking edit, Saving, clicking close will take
 * the user back into edit mode. The close button needs to be able to seek the most recent
 * '/news/' route to go back to (or something).
 */
const backOrPush = (history, url) => (previousLocation()
  ? history.goBack()
  : history.push(url));

export const useHistory = {
  currentLocation,
  previousLocation,
  getLocationURL,
  homeLocation,
  backOrPush,
};

/**
 * @class HistoryTracker
 *
 * @description
 * Listens to history changes and pushes them to the mutable historyStack array
 */
class HistoryTracker extends React.Component {
  /**
   * @constructor
   *
   * @param {{}} props
   */
  constructor(props) {
    super(props);
    const { history } = this.props;

    historyStack.push(props.location);
    this.unsubscribeFromHistory = history.listen(this.handleHistoryEvent);
  }

  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory();
  }

  /**
   * @description
   * Fired by the history listener when a push, pop or replace event occurs
   *
   * @param {object} location
   * @param {string} action
   */
  handleHistoryEvent = (location, action) => {
    switch (action.toUpperCase()) {
      case 'PUSH':
        historyStack.push(location);
        break;

      case 'REPLACE':
        historyStack[historyStack.length - 1] = location;
        break;

      case 'POP':
        historyStack.pop();
        break;

      default:
        console.warn('HistoryTracker: Unhandled history action: ', { action, location });
        break;
    }

    // console.log(`History ${action} event`);
    // console.table(historyStack);

    // TODO: maybe emit our own event?
  }

  /**
   * @inheritdoc
   */
  render() {
    const { children } = this.props;
    return (
      <>
        {children}
      </>
    );
  }
}

HistoryTracker.propTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default HistoryTracker;
