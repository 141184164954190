import { DELETE_CONFIRMATION_TYPE } from '../constants/delete-confirmation-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';

export const CommissionPaymentForecastWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Salesperson',
  isReadOnly: true,
  formDeleteConfirmationType: DELETE_CONFIRMATION_TYPE.REMOVE,
  collectionItemPrimaryKeyFieldName: 'sales_owner',
  fields: [
    {
      name: 'sales_owner',
      title: 'Salesperson',
      format: COLUMN_FORMAT.TEXT,
    }, {
      name: 'project_count',
      title: 'Projects',
      format: COLUMN_FORMAT.NUMBER,
      showTotals: true,
    }, {
      name: 'missing_rate_count',
      title: 'Missing Info',
      format: COLUMN_FORMAT.NUMBER,
      showTotals: true,
    }, {
      name: 'exclusive_invoice_amount_in_commission_window',
      title: 'New Invoice $',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    }, {
      name: 'total_commission_paid',
      title: 'Total Paid',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    }, {
      name: 'prior_commission_paid',
      title: 'Previously Paid',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    }, {
      name: 'new_commission_paid',
      title: 'Recently Paid',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    }, {
      name: 'new_commission_payable',
      title: 'Payable Now',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    }, {
      name: 'financial_year_invoiced_total',
      title: 'FY Invoiced',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    }, {
      name: 'financial_year_commission_total',
      title: 'FY Commission',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    }, {
      name: 'future_commission_payable',
      title: 'Payable Ahead',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    }, {
      name: 'total_commission_expected',
      title: 'Cumulative Total',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    },
  ],
};
