import { defaultTableSettings } from './default-table-settings';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { DATA_TABLE_FLAG } from '../constants/data-table-flag.const';

const getChangeOrderLink = (row) => `/crm/ordering/project-eta-items?pdt=v=all;ps=15;p=1;cf=change_order_number:eq+${row.number},project_number:eq+${row.project?.project_number};_=1`;

/**
 * Table Definition for Project ETA item Detail
 */
const dtoolsChangeOrderTableInitialSettings = {
  ...defaultTableSettings,
  baseRoute: '/dtools-cache/project-change-orders',
  baseQueryString: [
    'with[]=project:id,project_number,partner_program_id,status_id,likelihood',
    'with[]=project.status:id,name',
  ].join('&'),
  // availableFlags: [DATA_TABLE_FLAGS.ONLY_MINE],

  columnPresets: [
    {
      name: 'default',
      title: 'Default',
      fields: [
        'project_number',
        'number',
        'name',
        'status',
        'type',
        'price_ex',
        'price',
        'notes',
      ],
    },
    {
      name: 'all',
      title: 'all',
      fields: [
        'project_number',
        'cache_hash',
        'project_id',
        'project_id_string',
        'dtools_project_guid',
        'project_updated_on',
        'dtools_project_number',
        'dtools_project_name',
        'progress',
        'dtools_co_id',
        'co_id',
        'name',
        'number',
        'status_id',
        'status',
        'type_id',
        'type',
        'created_on',
        'price_ex',
        'price',
        'notes',
        'status_updated_date',
        'check_in_date_time',
        'currency_rate',
        'price_tc',
      ],
    },
  ],

  /**
  * Definition of Columns for this table
  */
  columns: [
    {
      name: 'project_number',
      title: 'P-Number',
      description: 'Project Unique Identifier',
      format: COLUMN_FORMAT.OBJECT,
      formSaveField: 'project.project_number', // filthy filter hack
      sortColumn: 'project_id',
      object: {
        key: 'project_number',
        sourceField: 'project',
        format: COLUMN_FORMAT.P_NUMBER,
        linkId: 'id',
        linkRoute: '/crm/projects',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      order: 100,
      formOrder: 110,
    }, {
      name: 'number',
      title: 'Number',
      description: 'Number',
      format: COLUMN_FORMAT.ID_LINK,
      linkId: 'number',
      linkRoute: getChangeOrderLink,
      visible: true,
      order: 140,
      formOrder: 150,
    }, {
      name: 'name',
      title: 'Name',
      description: 'Name',
      format: COLUMN_FORMAT.ID_LINK,
      linkId: 'number',
      linkRoute: getChangeOrderLink,
      visible: true,
      order: 160,
      formOrder: 170,
    }, {
      name: 'status',
      title: 'Status',
      description: 'Status',
      visible: true,
      order: 180,
      formOrder: 190,
    }, {
      name: 'type',
      title: 'Type',
      description: 'Type',
      visible: true,
      order: 200,
      formOrder: 210,
    }, {
      name: 'price_ex',
      title: 'Price Ex',
      description: 'Price Ex',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 220,
      formOrder: 230,
    }, {
      name: 'price',
      title: 'Price',
      description: 'Price',
      format: COLUMN_FORMAT.CURRENCY,
      visible: true,
      order: 240,
      formOrder: 250,
    }, {
      name: 'notes',
      title: 'Notes',
      description: 'Notes',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      order: 260,
      formOrder: 270,
    }, {
      name: 'co_id',
      title: 'Co Id',
      description: 'Co Id',
      visible: false,
      order: 275,
      formOrder: 275,
    }, {
      name: 'created_on',
      title: 'Created On',
      description: 'Created On',
      visible: false,
      order: 280,
      formOrder: 290,
    }, {
      name: 'status_updated_date',
      title: 'Status Updated Date',
      description: 'Status Updated Date',
      visible: false,
      order: 300,
      formOrder: 310,
    }, {
      name: 'check_in_date_time',
      title: 'Check In Date Time',
      description: 'Check In Date Time',
      visible: false,
      order: 320,
      formOrder: 330,
    }, {
      name: 'currency_rate',
      title: 'Currency Rate',
      description: 'Currency Rate',
      visible: false,
      order: 340,
      formOrder: 350,
    }, {
      name: 'price_tc',
      title: 'Price Tc',
      description: 'Price Tc',
      format: COLUMN_FORMAT.CURRENCY,
      visible: false,
      order: 360,
      formOrder: 370,
    }, {
      name: 'status_id',
      title: 'Status Id',
      description: 'Status Id',
      visible: false,
      order: 380,
      formOrder: 390,
    }, {
      name: 'type_id',
      title: 'Type Id',
      description: 'Type Id',
      visible: false,
      order: 400,
      formOrder: 410,
    }, {
      name: 'cache_hash',
      title: 'Cache Hash',
      description: 'Cache Hash',
      visible: false,
      order: 420,
      formOrder: 430,
    }, {
      name: 'project_id',
      title: 'Project Id',
      description: 'Project Id',
      visible: false,
      order: 440,
      formOrder: 450,
    }, {
      name: 'project_id_string',
      title: 'Project Id String',
      description: 'Project Id String',
      visible: false,
      order: 460,
      formOrder: 470,
    }, {
      name: 'dtools_project_guid',
      title: 'Dtools Project Guid',
      description: 'Dtools Project Guid',
      visible: false,
      order: 480,
      formOrder: 490,
    }, {
      name: 'project_updated_on',
      title: 'Project Updated On',
      description: 'Project Updated On',
      visible: false,
      order: 500,
      formOrder: 510,
    }, {
      name: 'dtools_project_number',
      title: 'Dtools Project Number',
      description: 'Dtools Project Number',
      visible: false,
      order: 520,
      formOrder: 530,
    }, {
      name: 'dtools_project_name',
      title: 'Dtools Project Name',
      description: 'Dtools Project Name',
      visible: false,
      order: 540,
      formOrder: 550,
    }, {
      name: 'progress',
      title: 'Progress',
      description: 'Progress',
      visible: false,
      order: 560,
      formOrder: 570,
    }, {
      name: 'dtools_co_id',
      title: 'Dtools Co Id',
      description: 'Dtools Co Id',
      visible: false,
      order: 580,
      formOrder: 590,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'ETA Item Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'notes',
      title: 'Notes',
      apiRoute: '/',
      description: 'ETA Item Notes',
      widgetDefinition: WIDGET_DEFINITION.DTOOLS_ITEM_NOTES,
    },
  ],

  /**
   * View Definitions
   */
  viewKey: 'all',
  views: [
    {
      key: 'all',
      title: 'All D-Tools Change Orders',
      filters: [],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAG.ONLY_MINE],
          title: 'My Projects: D-Tools Change Orders',
        },
      ],
      flags: [],
    },
  ],
};

export default dtoolsChangeOrderTableInitialSettings;
