import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';

/**
 * Table Definition for People (Platforms) Table
 */
const platformsTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Platform',
  initialFormFocusFieldName: 'first',

  baseRoute: '/platform',
  baseQueryString: '',

  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'name',
        'description',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'name',
        'description',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'name',
        'description',
      ],
    },
  ],

  /**
   * Definition of columns and APIPolyForm fields for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Identity',
      description: 'Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      linkRoute: '/internal-projects/platforms',
      visible: false,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'name',
      title: 'Name',
      description: 'Platform Name',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: '/internal-projects/platforms',
      visible: true,
      showInForm: true,
      order: 10,
      formOrder: 10,
    },
    {
      name: 'description',
      title: 'Platform Description',
      description: 'Platform Description',
      format: COLUMN_FORMAT.TEXT,
      visible: true,
      required: true,
      maxLength: 50,
      order: 20,
      formOrder: 20,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add a Platform',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new Platform',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the platform',
      successMessage: 'Updated platform',
      failMessage: 'Failed to update Platform',
      hideRecordActionButton: true,
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the Platform',
      successMessage: 'Platform deleted',
      failMessage: 'Failed to delete Platform',
      hideRecordActionButton: true,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Platform Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      ...eventsTabDefault,
    },
  ],
};

export default platformsTableInitialSettings;
