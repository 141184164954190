import React from 'react';

import { ICompanySpaceRecord } from '../../types/company/company-space.record.interface';
import { NewRecordModalProps } from '../../types/modal/new-record-modal.props';

import { useIsDirty } from '../../react-hooks/use-is-dirty.hook';

import { Modal } from './Modal';
import { NewCompanySpaceForm } from '../poly-forms/new-company-space-form';
import { ICON } from '../../constants/icon.const';

export const NewCompanySpaceModal: React.FC<NewRecordModalProps<ICompanySpaceRecord>> = (props) => {
  const {
    id,
    closeModal,
    onModalComplete,
    initialData,
    baseRoute,
    apiRoute,
    apiQuery,
    rendererOptions,
  } = props;

  const [isDirty, setDirty] = useIsDirty();

  return (
    <Modal
      {...props}
      title="Create a new Company Space"
      icon={ICON.SPACE}
      allowDismiss
      isDirty={isDirty}
      size="lg"
    >
      <NewCompanySpaceForm
        baseRoute={baseRoute}
        apiRoute={apiRoute}
        apiQuery={apiQuery}
        rendererOptions={rendererOptions}
        onFormComplete={(success, recordId, newRecord) => {
          closeModal(id);
          onModalComplete((success && newRecord) ? { success, newRecord } : { success: false, newRecord: null });
        }}
        onFormChange={setDirty}
        initialData={initialData}
      />
    </Modal>
  );
};
