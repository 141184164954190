import { ICompanyLocationPortalTreeViewNodeData } from '../../../types/portal-tree-view/company-location-portal-tree-view-node-data.interface';
import { ICompanyLocationRecord } from '../../../types/company/company-location.record.interface';
import { ICompanyRecord } from '../../../types/company/company.record.interface';
import { ICompanySpaceRecord } from '../../../types/company/company-space.record.interface';
import { PortalTreeViewNodeItem } from '../../../types/portal-tree-view/portal-tree-view-node-item';

import { A_COMPANY_LOCATION_TYPE } from '../../../constants/company-location-type.const';
import { COMPANY_LOCATION_PTV_NODE_TYPE } from '../../../constants/company-locations-ptv-node-type.const';

export const buildLocationNodeId = (locationId: number): string => `l_${locationId}`;
export const buildSpaceNodeId = (spaceId: number): string => `s_${spaceId}`;

/**
 * The PortalTreeView requires data in a very specific format.
 * This function takes the locations and spaces and concatenates
 * them into a single array of node items including a root node.
 */
export const buildCompanyLocationsTreeItems = (
  company: ICompanyRecord,
  companyLocations: ICompanyLocationRecord[],
  companySpaces: ICompanySpaceRecord[],
  isRootNodeVisible: boolean,
  searchTerm?: null | string,
): PortalTreeViewNodeItem<ICompanyLocationPortalTreeViewNodeData>[] => {
  let treeItems: PortalTreeViewNodeItem<ICompanyLocationPortalTreeViewNodeData>[] = [];

  // The root node is typically hidden from the company locations tree unless the user is in edit mode
  treeItems.push(
    {
      id: 'ROOT',
      parent: 0,
      text: company.name,
      droppable: true,
      data: {
        id: -1,
        parentId: undefined,
        name: company.name,
        nodeType: COMPANY_LOCATION_PTV_NODE_TYPE.ROOT,
      },
    },
  );

  // Map over the company location data and create tree items
  if (companyLocations.length) {
    companyLocations.forEach((companyLocation) => {
      treeItems.push({
        id: buildLocationNodeId(companyLocation.id),
        parent: companyLocation.parent_id ? buildLocationNodeId(companyLocation.parent_id) : 'ROOT',
        text: companyLocation.name,
        droppable: true,
        data: {
          id: companyLocation.id,
          parentId: companyLocation.parent_id ?? undefined,
          name: companyLocation.name,
          nodeType: COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION,
          companyLocationTypeId: companyLocation.type_id as A_COMPANY_LOCATION_TYPE,
        },
      });
    });
  }

  // Map over the company space data and create tree items
  if (companySpaces.length) {
    companySpaces.forEach((companySpace) => {
      treeItems.push({
        id: buildSpaceNodeId(companySpace.id),
        parent: companySpace.location_id ? buildLocationNodeId(companySpace.location_id) : 'ROOT',
        text: companySpace.name,
        droppable: true,
        data: {
          id: companySpace.id,
          name: companySpace.name,
          parentId: companySpace.location_id ?? undefined,
          nodeType: COMPANY_LOCATION_PTV_NODE_TYPE.SPACE,
          companySpaceType: companySpace.type ?? undefined,
        },
      });
    });
  }

  // Filter out the items that don't match the search term
  if (searchTerm && searchTerm.trim() !== '') {
    const refinedSearchTerm = searchTerm.trim().toLowerCase();
    const filteredItems = [
      ...treeItems.filter((treeItem) => (
        // Search term must match
        treeItem.text.toLowerCase().includes(refinedSearchTerm)
      )),
    ];

    // Create a list of node IDs to add back in (parents etc...)
    const itemsToAddBackIn: (string | number)[] = isRootNodeVisible ? [] : ['ROOT'];

    // Iterate over each filtered item
    filteredItems.forEach((filteredItem) => {
      // Find the parent node of the filtered item
      let filteredItemParent = treeItems.find((treeItem) => treeItem.id === filteredItem.parent);

      // Iterate over all parents for that node.
      while (filteredItemParent) {
        const parentId = filteredItemParent.id;

        if (
          // Does the parent node already exist in the list? (if so it's parent's parent etc.. will also be)
          (itemsToAddBackIn.includes(parentId)) ||
          // Does the parent node already exist in the filtered items?
          (filteredItems.findIndex((fi) => fi.id === parentId) > -1)
        ) {
          // Break out of the loop
          filteredItemParent = undefined;
        } else {
          // Push the id to the item's to add back in
          itemsToAddBackIn.push(filteredItemParent.id);
          // Look for the next parent
          const nextParentId = filteredItemParent.parent;
          filteredItemParent = treeItems.find((treeItem) => treeItem.id === nextParentId);
        }
      }
    });

    // Add back in the parent nodes of the filtered tree items so that they're not dangling on their own
    treeItems = [
      ...filteredItems,
      ...treeItems.filter((treeItem) => itemsToAddBackIn.includes(treeItem.id)),
    ];
  }

  return treeItems;
};
