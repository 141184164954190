import { IFormFieldDefinition } from '../../types/poly-form/form-field-definition.interface';
import { COLUMN_FORMAT } from '../column-format.const';
import { FORM_FIELD_TYPE } from '../form-field-type.const';

export const companyLocationSiteFormFields: IFormFieldDefinition[] = [
  {
    name: 'company_id',
    visible: false,
    isParentId: true,
  },

  {
    name: 'name',
    title: 'Site Name',
    description: 'The location name',
    format: COLUMN_FORMAT.TEXT,
    visible: true,
  },

  {
    name: 'address_line_1',
    title: 'Address Line 1',
    description: 'Address line 1',
    format: COLUMN_FORMAT.TEXT,
    visible: true,
  },

  {
    name: 'address_line_2',
    title: 'Address Line 2',
    description: 'Address line 2',
    format: COLUMN_FORMAT.TEXT,
    visible: true,
  },

  {
    name: 'suburb',
    title: 'Suburb',
    description: 'Suburb',
    format: COLUMN_FORMAT.TEXT,
    visible: true,
  },

  {
    name: 'state',
    title: 'State',
    description: 'State',
    format: COLUMN_FORMAT.OBJECT,
    object: {
      sourceField: 'state',
      key: 'acronym',
      format: COLUMN_FORMAT.SHORT_TEXT,
      listSource: 'state',
    },
    formFieldType: FORM_FIELD_TYPE.STATE_PICKER,
    formSaveField: 'state_id',
    visible: true,
  },

  {
    name: 'postcode',
    title: 'Postcode',
    description: 'Postcode',
    format: COLUMN_FORMAT.SHORT_TEXT,
    visible: true,
  },

  {
    name: 'description',
    title: 'Description',
    description: 'A detailed description about the site, what it is used for etc...',
    format: COLUMN_FORMAT.TEXT,
    visible: true,
  },

  {
    name: 'path',
    title: 'Full Location Name',
    description: 'The location name with hierarchy.',
    format: COLUMN_FORMAT.TEXT,
    formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    visible: true,
    required: true,
  },
];
