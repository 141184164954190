import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';

import { ICompanyLocationRecord } from '../../../types/company/company-location.record.interface';
import { FormRendererProps } from '../../../types/poly-form/form-renderer.props';

import { LatLngLiteral, MapLocationDetails, PortalGoogleMap } from '../../portal-google-map/portal-google-map';
import { VerticalFormRenderer } from './vertical-form-renderer';
import { A_COMPANY_LOCATION_TYPE, COMPANY_LOCATION_TYPE } from '../../../constants/company-location-type.const';
import { CompanyLocationPicker } from '../../form-input/company-location-picker';
import { GMAPS_ENABLED } from '../../../utils/constants';
import { CompanyLocationTypePicker } from '../../form-input/company-location-type-picker';
import { ApiQueryDataLoader } from '../../api-query-data-loader/api-query-data-loader';
import { IStateRecord } from '../../../types/state.record.interface';
import Icon from '../../layout-helpers/icon';


export type CompanyLocationFormRendererOptions = {
  showGoogleMap?: boolean,
  showParentLocation?: boolean,
  showLocationType?: boolean,
}

export type CompanyLocationFormRendererProps = Omit<FormRendererProps, 'rendererOptions' | 'formData'> & {
  rendererOptions?: CompanyLocationFormRendererOptions,
  formData: ICompanyLocationRecord,
};

const GOOGLE_MAPS_SEARCH_VALID_FIELDS: Record<A_COMPANY_LOCATION_TYPE, string[]> = {
  [COMPANY_LOCATION_TYPE.SITE]: [
    'name',
    'address_line_1',
    'address_line_2',
    'suburb',
    'state_id',
    'postcode',
    'lat',
    'lng',
  ],
  [COMPANY_LOCATION_TYPE.BUILDING]: [
    'name',
    'lat',
    'lng',
  ],
  [COMPANY_LOCATION_TYPE.LEVEL]: [],
  [COMPANY_LOCATION_TYPE.ZONE]: [],
};

export const CompanyLocationFormRenderer:React.FC<CompanyLocationFormRendererProps> = (props) => {
  const {
    className,
    formData,
    isEditing,
    rendererOptions = {
      showGoogleMap: GMAPS_ENABLED,
      // @todo is this field still required?
      showParentLocation: false,
      showLocationType: false,
    },
    onFieldChange,
  } = props;

  const {
    showGoogleMap = GMAPS_ENABLED,
    showParentLocation = false,
    showLocationType = false,
  } = rendererOptions;

  const googleMapEnabled = useMemo(() => (showGoogleMap && formData && ([
    COMPANY_LOCATION_TYPE.SITE,
    COMPANY_LOCATION_TYPE.BUILDING,
  ] as A_COMPANY_LOCATION_TYPE[]).includes(formData.type_id as A_COMPANY_LOCATION_TYPE)), [formData, showGoogleMap]);

  const showHeaders = (googleMapEnabled || showParentLocation || showLocationType);

  const locationType: A_COMPANY_LOCATION_TYPE = useMemo(() => formData.type_id as A_COMPANY_LOCATION_TYPE, [formData.type_id]);

  /**
   * Fired by the Portal Google Map when the location changes
   */
  const handleMapLocationChange = useCallback((newLocationDetails?: MapLocationDetails) => {
    if (newLocationDetails && onFieldChange) {
      let changedFields = Object.keys(newLocationDetails).map((locationKey) => ({
        fieldName: locationKey,
        newValue: newLocationDetails[locationKey as keyof MapLocationDetails],
      }));

      // Filter out the fields that don't exist in the current form
      changedFields = changedFields.filter((field) => GOOGLE_MAPS_SEARCH_VALID_FIELDS[locationType].includes(field.fieldName));

      // Fire off change events for all of the affected fields
      onFieldChange(changedFields);
    }
  }, [locationType, onFieldChange]);


  /**
   * Fired by the Portal Google Map when the marker is moved
   */
  const handleMapMarkerPositionChange = useCallback((newMarkerPosition?: LatLngLiteral) => {
    if (newMarkerPosition && onFieldChange) {
      let changedFields = Object.keys(newMarkerPosition).map((locationKey) => ({
        fieldName: locationKey,
        newValue: newMarkerPosition[locationKey as keyof LatLngLiteral],
      }));

      // Filter out the fields that don't exist in the current form
      changedFields = changedFields.filter((field) => GOOGLE_MAPS_SEARCH_VALID_FIELDS[locationType].includes(field.fieldName));

      // Fire off change events for all of the affected fields
      onFieldChange(changedFields);
    }
  }, [locationType, onFieldChange]);


  // Render
  return (
    <VerticalFormRenderer
      {...props}
      className={classNames('company-location', className)}
    >
      {showHeaders && (
        <div className="location-form-headers">
          {/* Picker for the Parent Location */}
          {showParentLocation && (
            <div className="field-container form-group row">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                className="control-label col-md-4 col-form-label"
                htmlFor="parent_location"
              >
                <span>Parent Location</span>
              </label>

              <div className="col-md-8">
                <CompanyLocationPicker
                  name="parent"
                  id="parent"
                  value={formData.parent_id ?? null}
                  isClearable
                  isCreatable={false} // Gets too complicated to create locations from this form
                  searchRoute={`/company/${formData.company_id}/location`}
                  formData={formData}
                  placeholder="No Parent Location"
                  onChange={onFieldChange}
                />
              </div>
            </div>
          )}

          {/* Picker for the Location Type */}
          {showLocationType && (
            <div className="field-container form-group row">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                className="control-label col-md-4 col-form-label"
                htmlFor="parent_location"
              >
                <span>Location Type</span>
              </label>

              <div className="col-md-8">
                <CompanyLocationTypePicker
                  name="type"
                  id="type"
                  formSaveField="type_id"
                  value={formData.type_id}
                  formData={formData}
                  isClearable={false}
                  onChange={onFieldChange}
                />
              </div>
            </div>
          )}

          {/* Display a Google Map for the appropriate location types */}
          {googleMapEnabled && (
            <ApiQueryDataLoader
              apiQueryUrl="/state?pageLength=0"
              apiQueryCacheName="states"
              render={({
                response,
                isLoading,
              }) => {
                if (isLoading) {
                  return (
                    <div className="portal-data-field not-editable">
                      <Icon i="rolling" />
                    </div>
                  );
                }

                const statesList: IStateRecord[] = (response?.data as IStateRecord[]) ?? [];

                return (
                  <div className="google-map-wrapper">
                    <PortalGoogleMap
                      isEditing={isEditing}
                      statesList={statesList}
                      fill
                      markerPosition={(formData.lat && formData.lng)
                        ? { lat: formData.lat, lng: formData.lng }
                        : undefined}
                      onLocationChange={handleMapLocationChange}
                      onMarkerPositionChange={handleMapMarkerPositionChange}
                    />
                  </div>
                );
              }}
            />
          )}
        </div>
      )}
    </VerticalFormRenderer>
  );
};
