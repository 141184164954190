import React, { createContext, useState, useEffect, useCallback, useRef, useContext } from 'react';
import moment from 'moment-timezone';

import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { Card, CardBody, CardHeader, CardText, Container } from 'reactstrap';
import { IConstructor } from '../../types/constructor.interface';
import { IUserDetails, IUserDetailsStateDetails, IUserDetailsManagerDetails } from '../../types/user/user-details.interface';
import { IUserAlerts } from '../../types/user/user-alerts.interface';

import { PreLoader } from '../auth/pre-loader';

import { APIContext } from './api-provider';
import { IPermission } from '../../types/auth/permission.interface';
import { A_PERMISSION } from '../../constants/permissions.const';
import { localStorageLoad, localStorageSave, localStorageDelete } from '../../utils/localStorage';
import { LOCAL_STORAGE_KEYS } from '../../utils/local-storage-keys';
import { NOTIFICATION_TYPE, A_NOTIFICATION_TYPE } from '../../constants/notification-type.const';
import { NotificationContext } from './notification-provider';
import NotificationBody from '../notifications/notification-body';
import { debugLog } from '../../utils/helpers';
import { POLL_NOTIFICATIONS_INTERVAL, POLL_ALERTS_INTERVAL, POLL_PERMISSIONS_INTERVAL } from '../../utils/constants';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { API_FILTER_OPERATION } from '../../constants/api-filter-operation.const';

export type userHasPermissionsFunction = (checkPermissions?: A_PERMISSION | A_PERMISSION[]) => boolean;

// @Note: Don't forget to update CURRENT_USER_PROVIDER_PROP_TYPES when this interface is updated
export type CurrentUserContextProps = {
  userDetails: IUserDetails,
  userPermissions: IPermission[] | null,
  userPermissionsChecksum: string,
  userAlerts: null | IUserAlerts,
  userHasPermissions: userHasPermissionsFunction,
  refreshUserDetails: () => void,
  refreshUserAlerts: () => void,
  refreshUserNotifications: () => void,
  refreshUserPermissions: (notifyUser?: boolean) => void,
};

export const CurrentUserContext = createContext<CurrentUserContextProps>(null as never);

export const CurrentUserConsumer = CurrentUserContext.Consumer;

/**
 * @class CurrentUserProvider
 *
 * @todo: periodically refresh alerts
 * @todo: periodically refresh permissions
 * @todo: periodically poll notifications
 *
 * @description
 * Wraps the entire application in a provider for managing the current user
 */
export const CurrentUserProvider: React.FC = function CurrentUserProvider({ children }) {
  // The user details and permissions are pulled from local storage on boot to speed up application load
  const [userDetails, setUserDetails] = useState<IUserDetails | null>(localStorageLoad(LOCAL_STORAGE_KEYS.USER_DETAILS) as IUserDetails | null);
  const [userPermissions, setUserPermissions] = useState <IPermission[] | null>((localStorageLoad(LOCAL_STORAGE_KEYS.USER_PERMISSIONS) ?? null) as IPermission[]);
  const [userPermissionsChecksum, setUserPermissionsChecksum] = useState<string>((localStorageLoad(LOCAL_STORAGE_KEYS.USER_PERMISSIONS_CHECKSUM) ?? '') as string);
  const [userAlerts, setUserAlerts] = useState<IUserAlerts | null>(null);

  // Intervals
  const pollUserPermissionsInterval = useRef<number | null>(null);
  const pollUserAlertsInterval = useRef<number | null>(null);
  const pollUserNotificationsInterval = useRef<number | null>(null);

  // Abort Controllers
  const loadUserDetailsAbortController = useRef<null | AbortController>(null);
  const loadUserPermissionsAbortController = useRef<null | AbortController>(null);
  const loadUserAlertsAbortController = useRef<null | AbortController>(null);
  const loadUserNotificationsAbortController = useRef<null | AbortController>(null);

  // Contexts
  const { apiFetch, logout } = useContext(APIContext);
  const { addNotification } = useContext(NotificationContext);


  /**
   * Load the details about the current user
   */
  async function loadUserDetails() {
    // abort any previous loads
    if (loadUserDetailsAbortController && loadUserDetailsAbortController.current) {
      loadUserDetailsAbortController.current.abort();
    }

    // Create a new abort signal
    loadUserDetailsAbortController.current = apiAborter();

    try {
      const response = await apiFetch('/current-user', { signal: loadUserDetailsAbortController.current.signal });

      if (response.success) {
        loadUserDetailsAbortController.current = null;
        const newUserDetails = {
          id: response.body.id,
          name: response.body.name,
          email: response.body.email,
          first: response.body.first,
          middle: response.body.middle,
          last: response.body.last,
          initials: response.body.initials,
          state: response.body.state ? (response.body.state as IUserDetailsStateDetails) : null,
          directManager: response.body.direct_manager ? (response.body.direct_manager as IUserDetailsManagerDetails) : null,
          indirectManager: response.body.indirect_manager ? (response.body.indirect_manager as IUserDetailsManagerDetails) : null,
          escalationManager: response.body.escalation_manager ? (response.body.escalation_manager as IUserDetailsManagerDetails) : null,
        };

        setUserDetails(newUserDetails);
        localStorageSave(LOCAL_STORAGE_KEYS.USER_DETAILS, newUserDetails);
      }

      // if the current user details couldn't be found or loaded, log the user out with an error
      else if (!response.aborted) {
        loadUserDetailsAbortController.current = null;
        console.error('CurrentUserProvider::loadUserDetails', response.error);
        await logout('Failed to load your user details.');
      }
    } catch (error) {
      console.error('CurrentUserProvider::loadUserDetails', error);
      await logout('Failed to load your user details.');
    }
  }


  /**
   * Load the current user's Permissions
   */
  const loadUserPermissions = useCallback(async (): Promise<boolean> => {
    // This method requires a User ID from the current user.
    if (!userDetails?.id) {
      setUserPermissions(null);
      return false;
    }

    // abort any previous loads
    if (loadUserPermissionsAbortController && loadUserPermissionsAbortController.current) {
      loadUserPermissionsAbortController.current.abort();
    }

    // Create a new abort signal
    loadUserPermissionsAbortController.current = apiAborter();

    try {
      debugLog(`loading Permissions for UserID ${userDetails?.id}...`, undefined, undefined, '🔐');

      const response = await apiFetch(`/user/${userDetails?.id}/permission`, { signal: loadUserPermissionsAbortController.current.signal });

      // Data load was good - populate the User Permissions
      if (response.success) {
        loadUserPermissionsAbortController.current = null;
        const newPermissions = response.body.data.map((item: {
          name: string,
          _readable_name: string,
          _description: string,
        }) => ({
          name: item.name,
          readableName: item._readable_name,
          description: item._description,
        }));

        // return false if there are no permissions!
        if (newPermissions.length === 0) {
          setUserPermissions([]);
          return false;
        }

        setUserPermissions(newPermissions);
        localStorageSave(LOCAL_STORAGE_KEYS.USER_PERMISSIONS, newPermissions);

        const newPermissionsChecksum = newPermissions.map((newPermission: Record<string, string>) => newPermission.name).join();
        setUserPermissionsChecksum(newPermissionsChecksum);
        localStorageSave(LOCAL_STORAGE_KEYS.USER_PERMISSIONS_CHECKSUM, newPermissionsChecksum);

        return true;
      }

      // if the current user details couldn't be found or loaded, log the user out with an error
      if (!response.aborted) {
        loadUserPermissionsAbortController.current = null;
        setUserPermissions([]);
        console.error('CurrentUserProvider::loadUserPermissions', response.error);
        return false;
      }
    } catch (error) {
      setUserPermissions([]);
      console.error('CurrentUserProvider::loadUserPermissions', error);
      return false;
    }

    return false;
  }, [apiFetch, userDetails?.id]);


  /**
   * Load the current user's Alerts (the tags in the user menu)
   */
  const loadUserAlerts = useCallback(async () => {
    // This method requires a User ID from the current user.
    if (!userDetails?.id) {
      setUserAlerts(null);
      return;
    }

    // abort any previous loads
    if (loadUserAlertsAbortController && loadUserAlertsAbortController.current) {
      loadUserAlertsAbortController.current.abort();
    }

    // Create a new abort signal
    loadUserAlertsAbortController.current = apiAborter();

    try {
      debugLog(`loading Alerts for UserID ${userDetails?.id}...`, undefined, undefined, '🚩');

      const response = await apiFetch(`/user/${userDetails?.id}/alerts/summary`, { signal: loadUserAlertsAbortController.current.signal });

      // Data load was good - populate the User alerts
      if (response.success) {
        loadUserAlertsAbortController.current = null;
        setUserAlerts({
          alertCounts: response.body.data.alertCounts,
        });
      }

      // if the user alerts couldn't be loaded, log an error
      else if (!response.aborted) {
        loadUserAlertsAbortController.current = null;
        setUserAlerts(null);
        console.error('CurrentUserProvider::loadUserAlerts', response.error);
      }
    } catch (error) {
      setUserAlerts(null);
      console.error('CurrentUserProvider::loadUserAlerts', error);
    }
  }, [apiFetch, userDetails?.id]);


  /**
   * Load the current user's Notifications (since the last time the notifications were polled)
   */
  async function loadUnreadUserNotifications() {
  // This method requires a User ID from the current user.
    if (!userDetails?.id) {
      return;
    }

    // abort any previous loads
    if (loadUserNotificationsAbortController && loadUserNotificationsAbortController.current) {
      loadUserNotificationsAbortController.current.abort();
    }

    // Create a new abort signal
    loadUserNotificationsAbortController.current = apiAborter();

    try {
      // TODO: this is a work around for the data in the database being exclusively in the Australia/Melbourne timezone
      const lastPollTimestamp = localStorageLoad(LOCAL_STORAGE_KEYS.LAST_NOTIFICATIONS_POLL_TIMESTAMP)
        ? moment.tz(localStorageLoad(LOCAL_STORAGE_KEYS.LAST_NOTIFICATIONS_POLL_TIMESTAMP), 'Australia/Melbourne')
        : null;
      const threeDaysAgo = moment.tz(moment(), 'Australia/Melbourne').add(-3, 'days');

      // Don't fetch any more than a few days (just in case the user has returned to this device after weeks or months)
      const fetchFrom = lastPollTimestamp ? moment.max(lastPollTimestamp, threeDaysAgo) : threeDaysAgo;

      const lastFetchedAt = moment.tz(moment(), 'Australia/Melbourne');

      const filterDate = fetchFrom.format('YYYY-MM-DD HH:mm:ss');
      const filter = [
        'filter[0][field]=read_at',
        'filter[0][operation]=is-null',
        'filter[1][field]=created_at',
        `filter[1][operation]=${API_FILTER_OPERATION.GREATER_THAN}`,
        `filter[1][value]=${encodeURI(filterDate)}`,
      ].join('&');

      debugLog(`loading Notifications for UserID ${userDetails?.id}...`, undefined, { since: filterDate }, '📧');

      const response = await apiFetch(`/user/${userDetails?.id}/notification?${filter}`, { signal: loadUserNotificationsAbortController.current.signal });

      // Data load was good - populate the User Permissions
      if (response.success) {
        loadUserNotificationsAbortController.current = null;

        // Keep track of the last time we polled for notifications
        localStorageSave(LOCAL_STORAGE_KEYS.LAST_NOTIFICATIONS_POLL_TIMESTAMP, lastFetchedAt);

        response.body.data.forEach((notification: {
          data: string,
          id: string,
          notifiable_id: number,
          notifiable_type: string,
          read_at: null | Date,
          user_audit: {
            created_at: null | Date,
            deleted_at: null | Date,
            updated_at: null | Date,
          },
          name: string,
          _readable_name: string,
          _description: string,
        }) => {
          const notificationDetails = JSON.parse(notification.data) || {};
          addNotification({
            type: (notificationDetails.style || NOTIFICATION_TYPE.INFO) as A_NOTIFICATION_TYPE,
            headline: notificationDetails.title || 'New Notification',
            message: <NotificationBody data={notificationDetails} />,
            onClick: () => {
              // Asynchronously Fire off a "mark as read" update
              apiFetch(`/user/${notification.notifiable_id}/notification/${notification.id}`, {
                name: 'CurrentUserProvider::markNotificationAsRead',
                method: HTTP_METHOD.PATCH,
                body: {
                  is_read: true,
                },
              });
            },
          });
        });
      }

      // if the current user details couldn't be found or loaded, log the user out with an error
      else if (!response.aborted) {
        loadUserNotificationsAbortController.current = null;
        console.error('CurrentUserProvider::loadUserNotifications', response.error);
      }
    } catch (error) {
      console.error('CurrentUserProvider::loadUserNotifications', error);
    }
  }


  /**
   * Check to see if the user has access to one or more permissions
   *
   * @todo this should probably be userHasAnyPermissionIn() and have a twin called
   *       userHasAllPermissionsIn().
   */
  const userHasPermissions = useCallback((checkPermissions?: A_PERMISSION[] | A_PERMISSION) => {
    // Don't bother checking if the user ain't got no permissions!
    if (userPermissions === null || userPermissions.length === 0) return false;

    // Don't bother if there are no permissions to check
    if (!checkPermissions || (Array.isArray(checkPermissions) && (checkPermissions.length === 0))) return true;

    // If an array of permissions was provided - just return true if we find a single match
    if (Array.isArray(checkPermissions)) {
      for (let i = 0; i < checkPermissions.length; i += 1) {
        if (userPermissions && userPermissions.findIndex((permission) => permission.name === checkPermissions[i]) > -1) {
          return true;
        }
      }

      // didn't match any of the permissions in the array so return false.
      return false;
    }

    return userPermissions.findIndex((permission) => permission.name === checkPermissions) > -1;
  }, [userPermissions]);


  /**
   * Wrap the loadUserDetails function inside a callback that only updates when the userDetails?.id changes
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refreshUserDetails = useCallback(() => loadUserDetails(), [userDetails?.id]);


  /**
   * Wrap the loadUserNotifications function inside a callback that only updates when the userDetails?.id changes
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refreshUserNotifications = useCallback(() => loadUnreadUserNotifications(), [userDetails?.id]);


  /**
   * Wrap the loadUserPermissions function inside a callback that only updates when the userDetails?.id changes
   */
  const refreshUserPermissions = useCallback((notifyUser?: boolean) => {
    loadUserPermissions()
      .then((success: boolean) => {
        if (notifyUser) {
          addNotification(success ? {
            type: NOTIFICATION_TYPE.SUCCESS,
            headline: 'Permissions Refreshed',
            message: 'Your permissions have been refreshed and applied.',
          } : {
            type: NOTIFICATION_TYPE.ERROR,
            headline: 'Permission Refresh Failed',
            message: 'There was an unexpected error while attempting to refresh your permissions. Please contact IT Support for assistance.',
          });
        }
      });
  }, [addNotification, loadUserPermissions]);


  /**
   * Stop polling for user notifications
   */
  const stopPollingUserNotifications = () => {
    if (pollUserNotificationsInterval.current) {
      clearInterval(pollUserNotificationsInterval.current);
    }
  };


  /**
   * Start polling for user notifications
   */
  const beginPollingUserNotifications = () => {
    stopPollingUserNotifications();
    pollUserNotificationsInterval.current = (setInterval(refreshUserNotifications, POLL_NOTIFICATIONS_INTERVAL) as unknown as number);
  };


  /**
   * Stop polling for user alerts
   */
  const stopPollingUserAlerts = () => {
    if (pollUserAlertsInterval.current) {
      clearInterval(pollUserAlertsInterval.current);
    }
  };


  /**
   * Start polling for user alerts
   */
  const beginPollingUserAlerts = () => {
    stopPollingUserAlerts();
    pollUserAlertsInterval.current = (setInterval(loadUserAlerts, POLL_ALERTS_INTERVAL) as unknown as number);
  };


  /**
   * Stop polling for user permissions
   */
  const stopPollingUserPermissions = () => {
    if (pollUserPermissionsInterval.current) {
      clearInterval(pollUserPermissionsInterval.current);
    }
  };


  /**
   * Start polling for user permissions
   */
  const beginPollingUserPermissions = () => {
    stopPollingUserPermissions();
    pollUserPermissionsInterval.current = (setInterval(refreshUserPermissions, POLL_PERMISSIONS_INTERVAL) as unknown as number);
  };


  /**
   * @description
   * Fired when the component is mounted
   */
  useEffect(() => {
    debugLog('Current User Provider Mounted', '🔐');

    loadUserDetails();
    // All other details are loaded as a result of the current user details changing in the useEffect hook below

    // Fired when the component is un-mounted
    return () => {
      // Abort any outstanding loaders
      if (loadUserAlertsAbortController.current) loadUserAlertsAbortController.current.abort();
      if (loadUserPermissionsAbortController.current) loadUserPermissionsAbortController.current.abort();
      if (loadUserDetailsAbortController.current) loadUserDetailsAbortController.current.abort();

      debugLog('Clearing local storage user details and permission cache', undefined, undefined, '🧼');
      localStorageDelete(LOCAL_STORAGE_KEYS.USER_DETAILS);
      localStorageDelete(LOCAL_STORAGE_KEYS.USER_PERMISSIONS);
      localStorageDelete(LOCAL_STORAGE_KEYS.USER_PERMISSIONS_CHECKSUM);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  /**
   * When the UserId changes - load the initial data
   */
  useEffect(() => {
    refreshUserPermissions();
    loadUserAlerts();
    refreshUserNotifications();

    beginPollingUserNotifications();
    beginPollingUserAlerts();
    beginPollingUserPermissions();

    return () => {
      stopPollingUserNotifications();
      beginPollingUserAlerts();
      beginPollingUserPermissions();
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails?.id]);

  return (
    <>
      {/* No user details or permissions yet... */}
      {(!userDetails) && (
        <PreLoader
          label="Ci Portal"
          message="Fetching your user details..."
        />
      )}

      {(userDetails && userPermissions && userPermissions.length === 0) && (
        <Container fluid>
          <Card>
            <CardHeader className="bg-danger text-white">Permission Error</CardHeader>
            <CardBody>
              <CardText>No Permissions were found for the specified user.</CardText>
              <CardText>
                Please contact
                {' '}
                <a href="mailto:itsupport@thecigroup.com.au">IT Support</a>
                {' '}
                for Assistance.
              </CardText>
            </CardBody>
          </Card>
        </Container>
      )}

      {/* User details have loaded */}
      {!!userDetails && (userPermissions && userPermissions.length > 0) && (
        <CurrentUserContext.Provider
          value={{
            userDetails,
            userAlerts,
            userPermissions,
            userPermissionsChecksum,
            userHasPermissions,
            refreshUserDetails,
            refreshUserAlerts: loadUserAlerts,
            refreshUserPermissions,
            refreshUserNotifications,
          }}
        >
          {children}
        </CurrentUserContext.Provider>
      )}
    </>
  );
};


/**
 * @description
 * Connect a component to the auth provider
 * (requires the component to be nested under the CurrentUserProvider component)
 */
export function connectToCurrentUserProvider<P>(Component: React.FC<P> | IConstructor<React.Component<P>>): React.FC<Omit<P, keyof CurrentUserContextProps>> {
  return function CurrentUserProviderConnector(props: Omit<P, keyof CurrentUserContextProps>) {
    return (
      <CurrentUserConsumer>
        {(currentUserProvider) => <Component {...(props as P)} currentUserProvider={currentUserProvider} />}
      </CurrentUserConsumer>
    );
  };
}
