import { IPortalTreeViewNodeData } from '../../types/portal-tree-view/portal-tree-view-node-data.interface';
import { PortalTreeViewNodeItem } from '../../types/portal-tree-view/portal-tree-view-node-item';

/**
 * Determines whether a portal tree view node is the child of another
 * portal tree view node.
 *
 * @returns true if the targetNode is a child of the parentNodeId
 */
export const portalTreeViewNodeIsChildOfParent = <T extends IPortalTreeViewNodeData>(
  tree: PortalTreeViewNodeItem<T>[],
  targetNode: PortalTreeViewNodeItem<T>,
  parentNodeId: string | number,
): boolean => {
  // Iterate over the chain of parents until we either find the parent we're looking for or
  // run out of paren nodes
  let currentNode: undefined | PortalTreeViewNodeItem<T> = targetNode;

  // Bail out after 100 iterations. That should be more than any treeview requires.
  let safetyBailCounter = 100;
  while (currentNode && safetyBailCounter > 0) {
    // Find the parent node of the current node
    const parentId: string | number = currentNode.parent;
    currentNode = tree.find((node) => node.id === parentId);

    // Check to see if the new node is the parent we're looking for
    if (currentNode && currentNode.id === parentNodeId) {
      return true;
    }

    safetyBailCounter -= 1;
  }

  return false;
};
