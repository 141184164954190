import React, { PropsWithChildren, useState, useEffect, useMemo } from 'react';
import { FormGroup } from 'reactstrap';
import classNames from 'classnames';

import { APIRecord } from '../../types/api-record.interface';
import { IModalButton } from '../../types/modal/modal-button.interface';
import { ModalProps } from '../../types/modal/modal.props';
import { ReturnDataModalResult } from '../../types/modal/modal-result';

import { useIsDirty } from '../../react-hooks/use-is-dirty.hook';

import { CheckBox } from '../form-input/checkbox';
import { DebouncedTextArea } from '../debounced-input/debounced-input';
import { Modal } from './Modal';
import { InMemoryTable } from '../portal-data-table/in-memory-table';
import { InMemoryDataTableColumn } from '../../types/portal-data-table/in-memory-table-types';

import { parseCSVToRecords } from '../../helpers/parse-csv-to-records.helper';

import { BUTTON_COLOR } from '../../constants/button-color.const';
import { COLUMN_FORMAT } from '../../constants/column-format.const';
import { ICON } from '../../constants/icon.const';
import { MODAL_BUTTON_SIDE_TYPE } from '../../constants/modal-button-side-type.const';

export type PasteDataFromClipboardModalProps<T> = ModalProps<ReturnDataModalResult<T>> & {
  customRowSeparator: string,
  customColumnSeparator: string,
  columns: InMemoryDataTableColumn[],
};

export const PasteDataFromClipboardModal = <T extends APIRecord, >(props: PropsWithChildren<PasteDataFromClipboardModalProps<T>>): React.ReactElement => {
  const {
    id,
    className,
    customRowSeparator = ';',
    customColumnSeparator = ',',
    columns = [{
      title: 'Value',
      name: 'value',
      format: COLUMN_FORMAT.TEXT,
    }],
    closeModal,
    onModalComplete,
  } = props;

  const [isDirty, setDirty] = useIsDirty();
  const [rowData, setRowData] = useState<T[]>([]);
  const [pastedClipboardData, setPastedClipboardData] = useState('');
  const [importParsed, setImportParsed] = useState(false);

  const [useUnixLineEndingsForRowSeparation, setUseUnixLineEndingsForRowSeparation] = useState(true);
  const [useWindowsLineEndingsForRowSeparation, setUseWindowsLineEndingsForRowSeparation] = useState(false);
  const [useCustomCharacterForRowSeparation, setUseCustomCharacterForRowSeparation] = useState(false);

  const [useTabForColumnSeparation, setUseTabForColumnSeparation] = useState(false);
  const [useCustomCharacterForColumnSeparation, setUseCustomCharacterForColumnSeparation] = useState(true);

  const [internalRowSeparator, setInternalRowSeparator] = useState(customRowSeparator);
  const [internalColumnSeparator, setInternalColumnSeparator] = useState(customColumnSeparator);

  const modalButtons = useMemo<IModalButton[]>(() => [
    {
      label: 'Import',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.CHECK,
      sideType: MODAL_BUTTON_SIDE_TYPE.RIGHT,
      onClick: () => {
        closeModal(id);
        if (onModalComplete) {
          onModalComplete({
            success: true,
            data: rowData,
          });
        }
      },
      disabled: (!onModalComplete || !importParsed),
    },
    {
      label: 'Cancel',
      color: 'secondary',
      sideType: MODAL_BUTTON_SIDE_TYPE.LEFT,
      onClick: () => {
        closeModal(id, isDirty);
      },
    },
  ], [closeModal, id, importParsed, isDirty, onModalComplete, rowData]);

  /**
   * Fired when the user makes a change to the clipboard contents textarea
   */
  const handleClipboardContentsChanged = (newValue: string) => {
    setPastedClipboardData(newValue);
    setDirty();
  };


  /**
   * Re-parse the pasted data whenever the text or options changes
   */
  useEffect(() => {
    let rowSeparator: string = internalRowSeparator;
    if (useWindowsLineEndingsForRowSeparation) rowSeparator = '\r\n';
    if (useUnixLineEndingsForRowSeparation) rowSeparator = '\n';

    let columnSeparator: string = internalColumnSeparator;
    if (useWindowsLineEndingsForRowSeparation) columnSeparator = '\r\n';
    if (useUnixLineEndingsForRowSeparation) columnSeparator = '\n';


    const parsedCSVData = parseCSVToRecords<T>(
      pastedClipboardData,
      columns.map((column) => column.name),
      rowSeparator,
      columnSeparator,
    );
    setRowData(parsedCSVData);
  }, [columns, internalRowSeparator, internalColumnSeparator, pastedClipboardData, useWindowsLineEndingsForRowSeparation, useUnixLineEndingsForRowSeparation]);


  /**
   * Monitor the row data and update the flag that allows the "import" button to be enabled
   */
  useEffect(() => {
    setImportParsed(rowData && rowData.length > 0);
  }, [rowData]);


  // Render
  return (
    <Modal
      icon={ICON.PASTE_FROM_CLIPBOARD}
      title="Paste data from Clipboard"
      size="lg"
      allowDismiss
      buttons={modalButtons}
      {...props}
      className={classNames('paste-data-from-clipboard', className)}
      isDirty={isDirty}
    >
      <p>
        Use this form to import multiple records at the same time.
        Copy your data from a Spreadsheet, Website or Email into your clipboard and then paste it here.
      </p>
      <div>
        <DebouncedTextArea
          debounceDuration={500}
          rows={5}
          value={pastedClipboardData}
          className="form-control"
          placeholder="Place the contents of your clipboard here (CTRL+V or CMD+V)"

          onChange={handleClipboardContentsChanged}
        />
      </div>

      {/* Import Parsing Options */}
      <hr />
      <h5>Import Options</h5>
      <div className="import-options">
        <FormGroup>
          <span>Line Endings:</span>
          <CheckBox
            id="useUnixStyleLineEndings"
            name="useUnixStyleLineEndings"
            label="LF"
            infoToolTip={{
              title: 'Use Unix Style Line Endings (LF)',
              body: [
                'This is the most common style of line endings. Typically you need to select ',
                'this if you are using a Mac or Linux machine or the data you have received ',
                'comes from a Linux or Mac user. ',
                'Sometimes this is required even if you are on a Windows Machine.',
              ],
            }}
            inline
            value={useUnixLineEndingsForRowSeparation ? 1 : 0}
            onChange={(field) => {
              if (field.newValue) {
                setUseUnixLineEndingsForRowSeparation(true);
                setUseWindowsLineEndingsForRowSeparation(false);
                setUseCustomCharacterForRowSeparation(false);
                setDirty();
              }
            }}
          />
          <CheckBox
            id="useWindowsStyleLineEndings"
            name="useWindowsStyleLineEndings"
            label="CRLF"
            infoToolTip={{
              title: 'Use Windows Style Line Endings (CRLF)',
              body: 'Select this if you are using a Windows machine or the data you have received comes from a Windows user.',
            }}
            inline
            value={useWindowsLineEndingsForRowSeparation ? 1 : 0}
            onChange={(field) => {
              if (field.newValue) {
                setUseUnixLineEndingsForRowSeparation(false);
                setUseWindowsLineEndingsForRowSeparation(true);
                setUseCustomCharacterForRowSeparation(false);
                setDirty();
              }
            }}
          />
          <CheckBox
            id="useCustomCharacterLineEndings"
            name="useCustomCharacterLineEndings"
            label="Custom:"
            infoToolTip={{
              title: 'Use a Custom Character String for Line Endings',
              body: 'Select this if you know the sequence of characters that determines the end of a line or row.',
            }}
            inline
            value={useCustomCharacterForRowSeparation ? 1 : 0}
            onChange={(field) => {
              if (field.newValue) {
                setUseUnixLineEndingsForRowSeparation(false);
                setUseWindowsLineEndingsForRowSeparation(false);
                setUseCustomCharacterForRowSeparation(true);
                setDirty();
              }
            }}
          />
          <input
            className="form-control"
            onChange={(event) => {
              setInternalRowSeparator(event.currentTarget.value);
              setDirty();
            }}
            value={internalRowSeparator}
            disabled={!useCustomCharacterForRowSeparation}
          />
        </FormGroup>

        <FormGroup>
          <span>Column Separator:</span>
          <CheckBox
            id="useTabForColumnSeparator"
            name="useTabForColumnSeparator"
            label="TAB"
            infoToolTip={{
              title: 'Use a TAB character to denote the column separation',
              body: 'Select this if are importing data from a TSV or Tab separated values',
            }}
            inline
            value={useTabForColumnSeparation ? 1 : 0}
            onChange={(field) => {
              if (field.newValue) {
                setUseTabForColumnSeparation(true);
                setUseCustomCharacterForColumnSeparation(false);
                setDirty();
              }
            }}
          />
          <CheckBox
            id="useCustomCharacterForColumnSeparator"
            name="useCustomCharacterForColumnSeparator"
            label="Custom:"
            infoToolTip={{
              title: 'Use a Custom Character String to denote column separation',
              body: 'Select this if there is a particular character or sequence that separates the columns in your pasted data. Typically this is a comma "," for CSV data.',
            }}
            inline
            value={useCustomCharacterForColumnSeparation ? 1 : 0}
            onChange={(field) => {
              if (field.newValue) {
                setUseTabForColumnSeparation(false);
                setUseCustomCharacterForColumnSeparation(true);
                setDirty();
              }
            }}
          />
          <input
            className="form-control"
            onChange={(event) => {
              setInternalColumnSeparator(event.currentTarget.value);
              setDirty();
            }}
            value={internalColumnSeparator}
            disabled={!useCustomCharacterForColumnSeparation}
          />
        </FormGroup>
      </div>

      {/* Parsed Data to Import */}
      <hr />
      <InMemoryTable
        title="Data to Import"
        columns={columns}
        data={rowData}
        tightMode
        paginate
      />
    </Modal>
  );
};
