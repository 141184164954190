import { INTERNAL_PROJECT_STATUS_NAME_MAP } from '../../constants/internal-project-status.const';
import { mapSelectInputOptions } from '../../helpers/map-select.helper';
import { ICardItem } from '../../types/cardview/card-item.interface';
import { ISwimLaneDefinition } from '../../types/internal-project/swimlane-definition.interface';

/**
 * @var statusSwimlaneMap map of internal project based swimlanes using priority for sorting
 */
export const statusSwimlaneMap: ISwimLaneDefinition[] = mapSelectInputOptions(INTERNAL_PROJECT_STATUS_NAME_MAP).map((statusItem) => {
  if (Number.isInteger(statusItem.name)) return undefined;

  const result: ISwimLaneDefinition = {
    name: (statusItem.id ?? 0).toString(),
    title: statusItem.name,
    filterFunction: (item: ICardItem) => parseInt(item.status_id, 10) === parseInt((statusItem.id ?? 0).toString(), 10),
    assimilateFunction: (item: ICardItem) => {
      item.status_id = statusItem.id;
      item.status = statusItem;
      item._status_name = statusItem.name;
      return item;
    },
  };

  return result;
}).filter((statusItem) => statusItem !== undefined) as ISwimLaneDefinition[];
