import React, {
  useState, useCallback, useRef, useEffect, useContext,
} from 'react';
import { Button } from 'reactstrap';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';

import { APIContext } from '../providers/api-provider';
import { MSTeamsContext } from '../layout-helpers/msteams-provider';

import { IProjectRecord } from '../../types/project/project.record.interface';
import { WidgetProps } from '../../types/widget.props';

import FriendlyFormMessage from '../layout-helpers/friendly-form-message';
import Icon from '../layout-helpers/icon';

import { apiAborter } from '../../helpers/api-aborter.helper';

import { A_THEME_COLOR, THEME_COLOR } from '../../constants/theme-color.const';

import sharePointIcon from '../../images/share-point-icon.png';


export type ProjectDriveWidgetProps = Omit<WidgetProps, 'rowData'> & {
  rowData: IProjectRecord,
}


/**
 * This widget displays the Group and Project Assets integration components with SharePoint
 *
 * @todo use the action from the project record rather than a hard coded URL
 */
export const ProjectDriveWidget:React.FC<ProjectDriveWidgetProps> = (props) => {
  const {
    rowData,
    refreshRecord,
  } = props;

  const {
    id: projectId,
    ms_group_id: groupId,
    ms_group_drive_item_id: groupDriveItemId,
    ms_group_drive_item_url: groupDriveItemUrl,
  } = rowData;

  const { isResourceIdPending, isResourceIdValid } = useContext(MSTeamsContext);
  const { apiFetch } = useContext(APIContext);

  const [isCreating, setIsCreating] = useState<boolean>(isResourceIdPending(groupId) || isResourceIdPending(groupDriveItemId));
  const [createResultAlertColor, setCreateResultAlertColor] = useState<null | A_THEME_COLOR>(null);
  const [createResultAlertMessage, setCreateResultAlertMessage] = useState<null | string>(null);

  // Only poll if the record was loaded indicating we're waiting for something to happen
  const [pollCheckProgress, setPollCheckProgress] = useState<boolean>(isResourceIdPending(groupId) || isResourceIdPending(groupDriveItemId));

  const abortCreate = useRef<AbortController | null>(null);
  const abortCheckProgress = useRef<AbortController | null>(null);
  const checkProgressInterval = useRef<ReturnType<typeof setInterval>>();

  /**
   * @description
   * Handle the click of the "Create Group Button"
   */
  const handleClickCreate = useCallback(async () => {
    if (isCreating) return;

    setIsCreating(true);
    setCreateResultAlertMessage(null);
    setCreateResultAlertColor(null);

    if (abortCreate.current) {
      abortCreate.current.abort();
    }
    abortCreate.current = apiAborter();

    const response = await apiFetch(
      `/project/${projectId}/action/prepare-ms-drive-resources`,
      {
        method: HTTP_METHOD.POST,
        signal: abortCreate.current.signal,
        name: 'ProjectDriveWidget::prepareDriveResources',
      },
    );

    if (response.success) {
      abortCreate.current = null;
      if (response.body.success === true) {
        setIsCreating(false);
        if (refreshRecord) refreshRecord();
      }
      else if (!response.body.pending) {
        setIsCreating(false);
        setCreateResultAlertColor(THEME_COLOR.DANGER);
        setCreateResultAlertMessage(response.error);
        console.error(response.body);
      }

      setPollCheckProgress(response.body.pending);
    } else if (!response.aborted) {
      abortCreate.current = null;
      console.error(response.body);

      setIsCreating(false);
      setCreateResultAlertColor(THEME_COLOR.DANGER);
      setCreateResultAlertMessage(response.error || 'An unexpected network error occurred.');
    }
  }, [apiFetch, isCreating, setIsCreating, setCreateResultAlertColor, setCreateResultAlertMessage, projectId, refreshRecord]);


  /**
   * @description
   * Fired by the timer that is set up to continuously check and see if the group has been created
   */
  const checkProgress = useCallback(async () => {
    // Kill the interval if we're no longer creating when this method fires.
    if (!isCreating) {
      if (checkProgressInterval.current) {
        clearInterval(checkProgressInterval.current);
        checkProgressInterval.current = undefined;
      }
      return;
    }

    if (abortCheckProgress.current) {
      abortCheckProgress.current.abort();
    }
    abortCheckProgress.current = apiAborter();

    const response = await apiFetch(
      `/project/${projectId}`,
      {
        signal: abortCheckProgress.current.signal,
        name: 'ProjectDriveWidget::checkProgress',
      },
    );

    if (response.success) {
      abortCheckProgress.current = null;

      const { data: project } = response.body;

      // If the project's ms_group_id is no longer 'TBA', the group has been created.
      if (!isResourceIdPending(project.ms_group_id) && !isResourceIdPending(project.ms_group_drive_item_id)) {
        setIsCreating(false);
        setPollCheckProgress(false);
        if (refreshRecord) refreshRecord();
      }
    } else if (!response.aborted) {
      abortCheckProgress.current = null;
      console.error(response.body);
    }
  }, [apiFetch, isCreating, projectId, refreshRecord, isResourceIdPending]);


  // Whenever we arrive at this page and the row data indicates we're waiting for something to be created,
  // periodically poll the API for changes.
  useEffect(() => {
    if (checkProgressInterval.current) {
      clearInterval(checkProgressInterval.current);
      checkProgressInterval.current = undefined;
    }

    // Check back periodically to see if the operations have completed
    if (pollCheckProgress) {
      checkProgressInterval.current = setInterval(checkProgress, 5000);
    }

    return () => {
      if (checkProgressInterval.current) {
        clearInterval(checkProgressInterval.current);
        checkProgressInterval.current = undefined;
      }
    };
  }, [pollCheckProgress, checkProgress]);


  // Make sure we don't attempt to call back after the component has un-mounted
  useEffect(() => () => {
    if (abortCreate.current) {
      abortCreate.current.abort();
    }
    if (abortCheckProgress.current) {
      abortCheckProgress.current.abort();
    }
  }, []);


  // Return <ProjectDriveWidget />
  return (
    <div className="widget project-drive-widget">
      {/* no ms_group_id */}
      {(!isResourceIdValid(groupId) || !isResourceIdValid(groupDriveItemId) || isCreating) && (
        <div className="no-project-drive">
          <div className="project-drive-heading">
            <img src={sharePointIcon} alt="Project Drive" />
            Project Drive
          </div>
          {!isCreating && (
            <p>
              A Project Drive has not yet been initialized for this project so there is nowhere to store files yet. Click the button below to prepare the Project Drive.
            </p>
          )}
          {isCreating && (
            <p>
              Portal is requesting the creation of all necessary Project Drive resources for this project... please wait...
            </p>
          )}
          <p>
            <Button
              color="primary"
              onClick={handleClickCreate}
              disabled={isCreating}
            >
              <Icon
                i="bolt"
                isBusy={isCreating}
              />
              <span>{isCreating ? 'Preparing Project Drive' : 'Prepare Project Drive'}</span>
            </Button>
          </p>
        </div>
      )}

      {/* Loaded - group and group drive item exists */}
      {isResourceIdValid(groupId) && isResourceIdValid(groupDriveItemId) && (
        <div className="has-project-drive">
          <div className="project-drive-heading">
            <img src={sharePointIcon} alt="Project Drive" />
            Project Drive
          </div>
          <p>
            A Microsoft Active Directory group and a Project Drive exist for this project.
            <br />
            Click the button below to open the Project Drive and view the Project&apos;s files.
          </p>
          <p>
            <a
              className="btn btn-primary text-white"
              target="_blank"
              rel="noopener noreferrer"
              href={groupDriveItemUrl ?? undefined}
            >
              <Icon i="external-link" />
              <span>View Project Drive</span>
            </a>
          </p>
        </div>
      )}

      {/* Create Action Result */}
      {!isCreating && createResultAlertMessage && (
        <FriendlyFormMessage
          formMessage={createResultAlertMessage}
          alertColor={createResultAlertColor}
          useSimpleDefault
        />
      )}
    </div>
  );
};
