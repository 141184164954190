import React, {
  useState, useCallback, useRef, useEffect, useContext,
} from 'react';
import classNames from 'classnames';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { Button } from 'reactstrap';

import { APIContext } from '../providers/api-provider';
import { ModalContext } from '../modals/modal-context';
import { MSTeamsContext } from '../layout-helpers/msteams-provider';

import { CollectionWidgetProps } from '../../types/collection.widget.props';
import { ConfirmModalResult } from '../../types/modal/modal-result';
import { IProjectRecord } from '../../types/project/project.record.interface';
import { IProjectResourceRecord } from '../../types/project/project-resource.record.interface';

import { CollectionWidgetHeader } from './collection-widget-header';
import { CollectionWidget } from './collection.widget';
import FriendlyFormMessage from '../layout-helpers/friendly-form-message';
import Icon from '../layout-helpers/icon';
import teamsIcon from '../../images/teams-icon.png';

import { apiAborter } from '../../helpers/api-aborter.helper';
import { hasPermittedAction } from '../../helpers/has-permitted-action.helper';

import { API_ACTION } from '../../constants/api-action.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { A_THEME_COLOR } from '../../constants/theme-color.const';
import { ICON } from '../../constants/icon.const';


export type ProjectResourcesWidgetWidgetProps = Omit<CollectionWidgetProps, 'rowData' | 'widgetData'> & {
  rowData: IProjectRecord,
  widgetData: IProjectResourceRecord[],
}


/**
 * This widget displays the Project Resources by wrapping a table widget in a custom widget
 * for displaying other integration components
 *
 * @todo use the action from the project record rather than a hard coded URL to create a team
 */
export const ProjectResourcesWidget: React.FC<ProjectResourcesWidgetWidgetProps> = (props) => {
  const {
    className,
    disableHeader,
    isReadOnly,
    rowData: project,
    widgetPermittedActions,
    showAddBtn,
    isEditingCollectionItem,
    isCreatingCollectionItem,
    refreshRecord,
    createCollectionItem,
  } = props;

  const {
    id: projectId,
    ms_team_id: teamId,
    ms_team_url: teamUrl,
  } = project;

  const { apiFetch } = useContext(APIContext);
  const { getMsTeamsUrl, getMsTeamsUrlTarget, isResourceIdPending } = useContext(MSTeamsContext);
  const { showModal } = useContext(ModalContext);

  const [isCreatingTeam, setIsCreatingTeam] = useState<boolean>(isResourceIdPending(teamId));
  const [createTeamResultAlertColor, setCreateTeamResultAlertColor] = useState<null | A_THEME_COLOR>(null);
  const [createTeamResultAlertMessage, setCreatTeamResultAlertMessage] = useState<null | string>(null);

  // Only poll if the record was loaded indicating we're waiting for something to happen
  const pollCheckProgress: boolean = isResourceIdPending(teamId);

  const abortCreateTeam = useRef<AbortController | null>(null);
  const abortCheckProgress = useRef<AbortController | null>(null);
  const checkProgressInterval = useRef<ReturnType<typeof setInterval>>();


  /**
   * Handle the click of the "Create Team Button"
   */
  const handleCreateTeam = useCallback(() => {
    if (isCreatingTeam) return;

    setIsCreatingTeam(true);
    setCreatTeamResultAlertMessage(null);
    setCreateTeamResultAlertColor(null);

    if (abortCreateTeam.current) {
      abortCreateTeam.current.abort();
    }
    abortCreateTeam.current = apiAborter();

    // Call the API
    apiFetch(
      `/project/${projectId}/action/create-ms-team`,
      {
        method: HTTP_METHOD.POST,
        name: 'ProjectResourcesWidget::createTeam',
        signal: abortCreateTeam.current.signal,
      },
    ).then((response) => {
      if (response.success) {
        abortCreateTeam.current = null;

        if (response.body.success === true) {
          setIsCreatingTeam(false);
          if (refreshRecord) refreshRecord();
        }
        else if (!response.body.pending) {
          setIsCreatingTeam(false);
          setCreateTeamResultAlertColor('danger');
          setCreatTeamResultAlertMessage(response.body.error);
          console.error(response.body);
        }
      } else if (!response.aborted) {
        abortCreateTeam.current = null;
        console.error('ProjectResourcesWidget::createTeam', response.error);

        setIsCreatingTeam(false);
        setCreateTeamResultAlertColor('danger');
        setCreatTeamResultAlertMessage(response.error);
      }
    });
  }, [apiFetch, isCreatingTeam, setIsCreatingTeam, setCreateTeamResultAlertColor, setCreatTeamResultAlertMessage, projectId, refreshRecord]);


  /**
   * @description
   * Fired by the timer that is set up to continuously check and see if the team has been created
   */
  const checkProgress = useCallback(() => {
    // Kill the interval if we're no longer creating when this method fires.
    if (!isCreatingTeam) {
      if (checkProgressInterval.current) {
        clearInterval(checkProgressInterval.current);
        checkProgressInterval.current = undefined;
      }
      return;
    }

    if (abortCheckProgress.current) {
      abortCheckProgress.current.abort();
    }
    abortCheckProgress.current = apiAborter();

    // Call the API
    apiFetch(
      `/project/${projectId}`,
      {
        name: 'ProjectResourcesWidget::checkProgress',
        signal: abortCheckProgress.current.signal,
      },
    ).then((response) => {
      if (response.success) {
        abortCheckProgress.current = null;

        const { data: updatedProject } = response.body;

        // If the project's ms_team_id is no longer 'TBA', the team has been created.
        if (!isResourceIdPending(updatedProject.ms_team_id)) {
          setIsCreatingTeam(false);
          if (refreshRecord) refreshRecord();
        }
      } else if (!response.aborted) {
        abortCheckProgress.current = null;
        console.error('ProjectResourcesWidget::createTeam', response.error);
      }
    });
  }, [apiFetch, isCreatingTeam, projectId, refreshRecord, isResourceIdPending]);


  // Whenever we arrive at this page and the row data indicates we're waiting for something to be created,
  // periodically poll the API for changes.
  useEffect(() => {
    if (checkProgressInterval.current) {
      clearInterval(checkProgressInterval.current);
      checkProgressInterval.current = undefined;
    }

    // Check back periodically to see if the operations have completed
    if (pollCheckProgress) {
      checkProgressInterval.current = setInterval(checkProgress, 5000);
    }

    return () => {
      if (checkProgressInterval.current) {
        clearInterval(checkProgressInterval.current);
        checkProgressInterval.current = undefined;
      }
    };
  }, [pollCheckProgress, checkProgress]);


  // Make sure we don't attempt to call back after the component has un-mounted
  useEffect(() => () => {
    if (abortCreateTeam.current) {
      abortCreateTeam.current.abort();
    }
    if (abortCheckProgress.current) {
      abortCheckProgress.current.abort();
    }
  }, []);


  // Yes yes... inline styles. I'm a hypocrite. Can't be bothered creating a dedicated stylesheet for this simple modal.
  const createTeamConfirmationModalContent = (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '1em' }}>
          <img src={teamsIcon} style={{ width: '5em' }} alt="Project Team" />
        </div>

        <div>
          <p>
            <span>You are about to create a team for this project in  </span>
            <strong>Microsoft Teams</strong>
            <span>. </span>
          </p>
          <p>
            <span>
              Any project resources currently assigned to the project will be assigned as members.
              As you add and remove resources from this project, Portal will also be add and remove those resources from the Team.
            </span>
          </p>
          <p>
            <span>
              Once this action has been performed it cannot be reversed. Therefore, it is best to create a team for a project once it reaches the stage where collaboration is important.
            </span>
          </p>
          <p>
            <strong>Are you certain that you want to perform this action?</strong>
          </p>
        </div>
      </div>
    </>
  );


  /**
   * Create a new Resource
   */
  const handleCreateCollectionItem = useCallback(() => {
    if (createCollectionItem) createCollectionItem();
  }, [createCollectionItem]);

  // Return <ProjectResourcesWidget />
  return (
    <CollectionWidget
      {...props}
      className={classNames('project-resources-widget', className)}
      disableHeader
    >
      {/* Pass in a custom header (if not disabled in the definition */}
      {(disableHeader !== true) && (
        <CollectionWidgetHeader
          icon={ICON.USERS}

          description="Project Resources"

          showAddBtn={
            // pass down setting from the collection widget definition
            showAddBtn &&

            // The CREATE action exists in the widgetItemPermittedActions
            !!hasPermittedAction(widgetPermittedActions, API_ACTION.CREATE)
          }

          // Prevent the user from adding another row if they are editing a row
          addBtnDisabled={isEditingCollectionItem || isCreatingCollectionItem || isReadOnly}

          // Pass the event handler back up the chain so that the widget wrapper can add a data row
          onClickAddBtn={handleCreateCollectionItem}

          subHeading={(
            <div className="team-status">
              {/* Team does not exist */}
              {!teamId && !isCreatingTeam && (
                <span>This project is not yet linked to a Microsoft Team.</span>
              )}

              {/* Team is being created */}
              {isCreatingTeam && (
                <span>Portal is linking this project to a Microsoft Team...</span>
              )}

              {/* Team is linked */}
              {teamId && !isCreatingTeam && (
                <span>This project is linked to a Microsoft Team. Adding and removing resources will affect the Team&apos;s Members.</span>
              )}
            </div>
          )}

          actions={(
            <>
              <div className="teams-logo">
                <img src={teamsIcon} alt="Project Team" />
              </div>

              {/* Team does not exist */}
              {!teamId && !isCreatingTeam && (
                <div className="action">
                  {/* TODO: Ensure the action is available on the project and the user has permission */}
                  <Button
                    color="primary"
                    // Display the confirmation modal
                    onClick={() => showModal<ConfirmModalResult>(MODAL_TYPE.CONFIRM, {
                      title: 'Create Project Team',
                      color: 'primary',
                      content: createTeamConfirmationModalContent,
                      confirmButtonColor: 'primary',
                      confirmButtonLabel: 'Yes, Create a Team',
                      confirmButtonIcon: 'check',
                      onModalComplete: ({ success }) => {
                        if (success) handleCreateTeam();
                      },
                    })}
                  >
                    <Icon
                      i="plus"
                      isBusy={isCreatingTeam}
                    />
                    <span>Create a Microsoft Team</span>
                  </Button>
                </div>
              )}

              {/* Team is being created */}
              {isCreatingTeam && (
                <div className="action">
                  <Button
                    color="primary"
                    disabled
                  >
                    <Icon
                      i="plus"
                      isBusy
                    />
                    <span>Creating Microsoft Team...</span>
                  </Button>
                </div>
              )}

              {/* Team exists */}
              {teamId && !isCreatingTeam && (
                <div className="action">
                  <a
                    className="btn btn-success text-white"
                    rel="noopener noreferrer"
                    href={getMsTeamsUrl(teamUrl)}
                    target={getMsTeamsUrlTarget()}
                  >
                    <Icon i="external-link" />
                    <span>View Team</span>
                  </a>
                </div>
              )}
            </>
          )}
        />
      )}

      {/* Create Team Action Result */}
      {!isCreatingTeam && createTeamResultAlertMessage && (
        <FriendlyFormMessage
          formMessage={createTeamResultAlertMessage}
          alertColor={createTeamResultAlertColor}
          useSimpleDefault
        />
      )}
    </CollectionWidget>
  );
};
