import { COLUMN_FORMAT } from '../constants/column-format.const';
// import { ProjectInvoiceRequestsWidget } from '../components/widgets/project-invoices.widget';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { INVOICE_REQUEST_TYPE, INVOICE_REQUEST_TYPE_NAMES } from '../constants/invoice-request-type.const';
import { INVOICE_REQUEST_STATUS, INVOICE_REQUEST_STATUS_NAMES } from '../constants/invoice-request-status.const';
import { IInvoiceRequestStatusRecord } from '../types/invoice-request/invoice-request-status.record.interface';
import { IContactRecord } from '../types/contact/contact.record.interface';
import { fullName } from '../helpers/full-name.helper';
import { IProjectRecord } from '../types/project/project.record.interface';
import { ICompanyRecord } from '../types/company/company.record.interface';

export const ProjectInvoiceRequestsWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Invoice Request',
  fields: [
    {
      name: 'project_id',
      visible: false,
      isParentId: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'id',
      title: 'ID',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      format: COLUMN_FORMAT.ID_LINK,
      linkRoute: '/finance/invoice-requests',
    },
    {
      name: 'comment',
      title: 'Internal Name',
      visible: true,
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: '/finance/invoice-requests',
    },
    {
      name: 'bill_to_contact',
      title: 'Billing Contact',
      placeholder: 'Billing Contact',
      visible: true,
      showInForm: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'bill_to_contact',
        key: 'id',
        getValue: (value: number, rowData: IContactRecord) => fullName(rowData),
        format: COLUMN_FORMAT.TEXT,
      },
      formFieldType: FORM_FIELD_TYPE.CONTACT_PICKER,
      formSaveField: 'bill_to_contact_id',
      isCreatable: true,
      getNewItemData: (inputValue: string, formData: IContactRecord, parentData?: IProjectRecord): {
        company_id: null | number,
        company?: null | ICompanyRecord,
      } => ({
        company_id: parentData?.client_id ?? null,
        company: parentData?.client ?? null,
      }),
    },
    {
      name: 'type',
      visible: true,
      title: 'Invoice type',
      initialValue: INVOICE_REQUEST_TYPE.PROJECT,
      getNewItemData: (): {
        id: number,
        name: string,
      } => ({
        id: INVOICE_REQUEST_TYPE.PROJECT,
        name: INVOICE_REQUEST_TYPE_NAMES[INVOICE_REQUEST_TYPE.PROJECT],
      }),
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'type',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formSaveField: 'type_id',
    },
    {
      name: 'project_forecast',
      visible: true,
      title: 'Linked Forecast',
      formFieldType: FORM_FIELD_TYPE.PROJECT_FORECAST_PICKER,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'project_forecast',
        key: 'id',
        format: COLUMN_FORMAT.PROJECT_FORECAST_LINK,
      },
      formSaveField: 'project_forecast_id',
    },
    {
      name: 'to_be_sent',
      title: 'To be sent',
      visible: true,
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      required: true,
    },
    {
      name: 'status_id',
      visible: true,
      showInForm: true,
      title: 'Invoice status',
      initialValue: INVOICE_REQUEST_STATUS.DRAFT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'status',
        key: 'name',
        format: COLUMN_FORMAT.STATUS_BADGE,
      },
      formSaveField: 'status_id',
      getNewItemData: (): {
        status_id: null | number,
        status?: null | IInvoiceRequestStatusRecord,
      } => ({
        status_id: INVOICE_REQUEST_STATUS.DRAFT,
        status: {
          id: INVOICE_REQUEST_STATUS.DRAFT,
          name: INVOICE_REQUEST_STATUS_NAMES[INVOICE_REQUEST_STATUS.DRAFT],
        },
      }),
    },
    {
      name: 'sum_of_details_total_price',
      title: 'Total',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
    },
  ],
};
