import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import FourOhFour from '../error-pages/four-oh-four';
import RecordDetailPage, { TAB_REGEX } from '../record-detail-page/record-detail-page';
import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { GanttViewPage } from '../gantt-view/gantt-view-page';
import { SprintsDataTablePage } from '../data-table-pages/sprints.data-table-page';
import { TasksDataTablePage } from '../data-table-pages/internal-tasks.data-table-page';
import { TaskTemplatesDataTablePage } from '../data-table-pages/task-templates.data-table-page';
import { PlatformsDataTablePage } from '../data-table-pages/platforms.data-table-page';
import { TeamProcessesDataTablePage } from '../data-table-pages/team-processes.data-table-page';
import { TeamsDataTablePage } from '../data-table-pages/teams.data-table-page';
import { InternalProjectMultiView } from '../view-pages/internal-project-multi-view';
import { SingleInternalProjectMultiView } from '../view-pages/single-internal-project-multi-view';
import { INTERNAL_PROJECT_TYPE, INTERNAL_PROJECT_TYPE_ROUTES } from '../../constants/internal-project-type.const';

const individualRouteSuffix = `/:id/${TAB_REGEX}`;

const InternalProjectRoutes = () => (
  <Switch>
    <Route
      exact
      path="/internal-projects/sprints"
      render={(props) => <SprintsDataTablePage {...props} />}
    />
    <Route
      exact
      path="/internal-projects/sprints-gantt"
      render={(props) => <GanttViewPage {...props} title="Sprint Gantt Chart and Items" />}
    />
    <Route
      exact
      path={`/internal-projects/sprints/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} title="Sprints" tableIdentifier={TABLE_IDENTIFIER.SPRINTS_TABLE} />}
    />
    {/* RD&E Routes */}
    <Route
      exact
      path={`/internal-projects/${INTERNAL_PROJECT_TYPE_ROUTES[INTERNAL_PROJECT_TYPE.RD]}`}
      render={(props) => <InternalProjectMultiView {...props} title="RD&amp;E Projects" projectTypeId={INTERNAL_PROJECT_TYPE.RD} />}
    />
    <Route
      exact
      path={`/internal-projects/${INTERNAL_PROJECT_TYPE_ROUTES[INTERNAL_PROJECT_TYPE.RD]}${individualRouteSuffix}`}
      render={(props) => <RecordDetailPage {...props} title="RD&amp;E Project" tableIdentifier={TABLE_IDENTIFIER.INTERNAL_PROJECTS_TABLE} />}
    />
    {/* Product Development Routes */}
    <Route
      exact
      path={`/internal-projects/${INTERNAL_PROJECT_TYPE_ROUTES[INTERNAL_PROJECT_TYPE.PD]}`}
      render={(props) => <InternalProjectMultiView {...props} title="RD&amp;E Projects" projectTypeId={INTERNAL_PROJECT_TYPE.PD} />}
    />
    <Route
      exact
      path={`/internal-projects/${INTERNAL_PROJECT_TYPE_ROUTES[INTERNAL_PROJECT_TYPE.PD]}${individualRouteSuffix}`}
      render={(props) => <RecordDetailPage {...props} title="RD&amp;E Project" tableIdentifier={TABLE_IDENTIFIER.INTERNAL_PROJECTS_TABLE} />}
    />
    {/* Internal Development Routes */}
    <Route
      exact
      path={`/internal-projects/${INTERNAL_PROJECT_TYPE_ROUTES[INTERNAL_PROJECT_TYPE.ID]}`}
      render={(props) => <InternalProjectMultiView {...props} title="Internal Development Projects" projectTypeId={INTERNAL_PROJECT_TYPE.ID} />}
    />
    <Route
      exact
      path={`/internal-projects/${INTERNAL_PROJECT_TYPE_ROUTES[INTERNAL_PROJECT_TYPE.ID]}${individualRouteSuffix}`}
      render={(props) => <RecordDetailPage {...props} title="RD&amp;E Project" tableIdentifier={TABLE_IDENTIFIER.INTERNAL_PROJECTS_TABLE} />}
    />
    {/* Internal Development Routes */}
    <Route
      exact
      path={`/internal-projects/${INTERNAL_PROJECT_TYPE_ROUTES[INTERNAL_PROJECT_TYPE.IP]}`}
      render={(props) => <InternalProjectMultiView {...props} title="Internal Process Projects" projectTypeId={INTERNAL_PROJECT_TYPE.IP} />}
    />
    <Route
      exact
      path={`/internal-projects/${INTERNAL_PROJECT_TYPE_ROUTES[INTERNAL_PROJECT_TYPE.IP]}${individualRouteSuffix}`}
      render={(props) => <RecordDetailPage {...props} title="Internal Process Project" tableIdentifier={TABLE_IDENTIFIER.INTERNAL_PROJECTS_TABLE} />}
    />
    {/* All Internal Projects */}
    <Route
      exact
      path={`/internal-projects/internal-projects/:id/${TAB_REGEX}`}
      render={(props) => <SingleInternalProjectMultiView {...props} title="Internal Project" />}
    />
    <Route
      exact
      path="/internal-projects/internal-projects"
      render={(props) => <InternalProjectMultiView {...props} title="Internal Projects" />}
    />
    <Route
      exact
      path={`/internal-projects/internal-projects/:id/${TAB_REGEX}`}
      render={(props) => <SingleInternalProjectMultiView {...props} title="Internal Project" />}
    />
    <Route
      exact
      path="/internal-projects/tasks"
      render={(props) => <TasksDataTablePage {...props} />}
    />
    <Route
      exact
      path={`/internal-projects/tasks/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} title="Internal Project Tasks" tableIdentifier={TABLE_IDENTIFIER.INTERNAL_PROJECT_TASKS_TABLE} />}
    />
    <Route
      exact
      path="/internal-projects/task-templates"
      render={(props) => <TaskTemplatesDataTablePage {...props} />}
    />
    <Route
      exact
      path={`/internal-projects/task-templates/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} title="Task Templates" tableIdentifier={TABLE_IDENTIFIER.TASK_TEMPLATES_TABLE} />}
    />
    <Route
      exact
      path="/internal-projects/teams"
      render={(props) => <TeamsDataTablePage {...props} />}
    />
    <Route
      exact
      path={`/internal-projects/teams/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} title="Teams" tableIdentifier={TABLE_IDENTIFIER.TEAMS_TABLE} />}
    />
    <Route
      exact
      path="/internal-projects/platforms"
      render={(props) => <PlatformsDataTablePage {...props} />}
    />
    <Route
      exact
      path={`/internal-projects/platforms/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} title="Platforms" tableIdentifier={TABLE_IDENTIFIER.PLATFORMS_TABLE} />}
    />
    <Route
      exact
      path="/internal-projects/processes"
      render={(props) => <TeamProcessesDataTablePage {...props} />}
    />
    <Route
      exact
      path={`/internal-projects/processes/:id/${TAB_REGEX}`}
      render={(props) => <RecordDetailPage {...props} title="Processes" tableIdentifier={TABLE_IDENTIFIER.PROCESSES_TABLE} />}
    />
    <Route exact path="/internal-projects/*" component={FourOhFour} />
  </Switch>
);

InternalProjectRoutes.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default InternalProjectRoutes;
