import React from 'react';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { partnerLogos } from '../../constants/partner-logos.const';
import { IProjectRecord } from '../../types/project/project.record.interface';

export type ProjectNumberProps = {
  pData: IProjectRecord,
  linkSubRoute?: string,
  linkToProject?: boolean,
  highlightText?: null | string,
}

export const ProjectNumber: React.FC<ProjectNumberProps> = (props) => {
  const {
    pData,
    linkSubRoute = false,
    linkToProject = null,
    highlightText = '',
  } = props;

  let partnerName = 'Ci';

  if (Object.prototype.hasOwnProperty.call(pData, 'partner_program_name') && pData.partner_program_name) {
    partnerName = pData.partner_program_name;
  }

  if (Object.prototype.hasOwnProperty.call(pData, '_partner_program_name') && pData._partner_program_name) {
    partnerName = pData._partner_program_name;
  }

  const partner = partnerName.toLowerCase();
  const projectNumber: string = pData.project_number ?? 'ERROR';
  const projectName: string = pData.name || projectNumber;
  const projectId = pData.id || pData.project_id;

  const partnerImageElement = (
    <img
      alt={partnerName}
      src={partnerLogos[partner]}
      className="id-img"
      width="13"
      height="13"
    />
  );

  const searchWords = (highlightText !== null)
    ? highlightText.trim().replace(/[|&;$%@"<>()+,]/g, '').split(' ')
    : [];

  return (
    <div className="project-number">
      {linkToProject && (
        <Link to={`/crm/projects/${projectId}${linkSubRoute || ''}`} title={projectName}>
          {partnerImageElement}
          {projectNumber}
        </Link>
      )}
      {!linkToProject && (
        <>
          {partnerImageElement}
          {(highlightText !== null) && (
            <Highlighter
              highlightTag="span"
              highlightClassName="highlight-text"
              searchWords={searchWords}
              textToHighlight={projectNumber}
            />
          )}
          <span>{(highlightText === null) && projectNumber}</span>
        </>
      )}
    </div>
  );
};
