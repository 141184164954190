import React, { Component } from 'react';
import PropTypes, { oneOfType } from 'prop-types';

import classNames from 'classnames';
import { connectToAPIProvider } from '../providers/api-provider';
import { connectToModalProvider } from '../modals/modal-context';
import FriendlyFormMessage from '../layout-helpers/friendly-form-message';
import { ActionButton } from './action-button';

import { noop } from '../../utils/helpers';
import rollingSvg from '../../images/Rolling-1s-22px.svg';
import API_PROVIDER_PROP_TYPES from '../../prop-types/api-provider-prop-types';
import { MODAL_PROVIDER_PROP_TYPES } from '../../prop-types/modal-provider-prop-types';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { BUTTON_COLOR } from '../../constants/button-color.const';

/**
 * @class RecordActionButtons
 *
 * @description
 * Renders out actions for portal data table records detail views
 */
class RecordActionButtons extends Component {
  /**
   * @constructor
   */
  constructor(props) {
    super(props);

    this.state = {
      isProcessingAction: false,
      lastAttemptSuccess: null,
      lastAttemptMessage: null,
    };

    this.abortController = null;
  }


  /**
   * @inheritdoc
   */
  componentWillUnmount = () => {
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  /**
   * @description
   * Confirm is hit on dialog after asking if they are sure
   *
   * @param {{
    *  name: string,
    *  title: string,
    *  color: string,
    *  icon: string,
    *  description: string,
    *  successMessage: string,
    *  errorMessage: string,
    *  action: string,
    * }} button
   */
  handleConfirmActionButton = (button, formData) => {
    const {
      action,
      successMessage,
      failMessage,
    } = button;

    const {
      onSuccess,
    } = this.props;

    this.setState({
      isProcessingAction: true,
    }, async () => {
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = apiAborter();

      const { apiProvider: { apiFetch } } = this.props;

      const response = await apiFetch(action.link, {
        signal: this.abortController.signal,
        method: action.method,
        body: formData,
      });

      if (response.success) {
        this.abortController = null;
        this.setState({
          isProcessingAction: false,
          lastAttemptMessage: successMessage,
          lastAttemptSuccess: true,
        }, onSuccess);
      } else if (!response.aborted) {
        this.abortController = null;
        this.setState({
          isProcessingAction: false,
          lastAttemptMessage: `${failMessage}. ${response.error || '(Unknown Error)'}`,
          lastAttemptSuccess: false,
        });
      }
    });
  };

  /**
   * @param {{
   *  name: string,
   *  title: string,
   *  color: string,
   *  icon: string,
   *  description: string,
   *  successMessage: string,
   *  errorMessage: string,
   *  action: string,
   * }} action possible actions from this button
   *
   * This `button.action`'s content from API, eg.
   *   `{ link: 'https://api.ciportal.net/v1/things/stuff', method: 'POST' }`
   */
  handleClickActionButton = (button) => {
    const {
      modalProvider: { showModal },
      rowData,
      onSuccess,
    } = this.props;
    const {
      description, title, color, icon, modalType, requireConfirmReason,
    } = button;

    showModal(modalType ?? MODAL_TYPE.CONFIRM, {
      color,
      title,
      content: description,
      confirmButtonColor: color || BUTTON_COLOR.PRIMARY,
      confirmButtonLabel: title,
      confirmButtonIcon: icon,
      requireReason: requireConfirmReason,
      action: button.action,
      onModalComplete: ({ processAction, formData }) => {
        if (processAction) {
          this.handleConfirmActionButton(button, formData);
        } else if (typeof onSuccess === 'function') {
          onSuccess();
        }
      },
      initialData: rowData,
    });
  };


  /**
   * @description
   * Called when clicking close button on Alert message
   */
  clearActionMessage = () => {
    this.setState({
      lastAttemptSuccess: null,
      lastAttemptMessage: null,
    });
  };


  /**
   * @inheritdoc
   */
  render() {
    const {
      permittedActions, disabled, blockLevelButtons, hasLoaded, inFooter, children, showMessage,
    } = this.props;

    const {
      isProcessingAction, lastAttemptMessage, lastAttemptSuccess,
    } = this.state;

    const visibleActions = permittedActions.filter((button) => !button.hideRecordActionButton);

    return (
      <div className={classNames('record-action-buttons', { 'in-footer': inFooter })}>

        {/* Loading Spinner */}
        {!hasLoaded && (
          <div className="loading">
            <img src={rollingSvg} alt="Loading action buttons" />
          </div>
        )}

        {/* No actions message */}
        {hasLoaded && inFooter && (Object.keys(visibleActions).length === 0) && (
          <div className="no-actions">
            <span className="text-medium">There are currently no actions available.</span>
          </div>
        )}

        {/* Action Buttons */}
        {permittedActions && permittedActions.map((action) => (
          <ActionButton
            key={action.name}
            // comment out to show buttons as disabled
            className={action.hideRecordActionButton ? 'd-none' : 'd-inline-block'}
            onClick={() => {
              this.handleClickActionButton(action);
            }}
            disabled={disabled || isProcessingAction || action.hideRecordActionButton}
            block={blockLevelButtons}
            busy={isProcessingAction}
            {...action}
          />
        ))}

        {children}

        {/* Form Errors / Messages */}
        {showMessage && (
          <FriendlyFormMessage
            formMessage={lastAttemptMessage}
            alertColor={lastAttemptSuccess ? 'success' : 'danger'}
            errors={{}}
            showList
            isOpen={!isProcessingAction && !!lastAttemptMessage}
            toggle={this.clearActionMessage}
            useSimpleDefault
          />
        )}
      </div>
    );
  }
}

RecordActionButtons.propTypes = {
  permittedActions: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  hasLoaded: PropTypes.bool,
  disabled: PropTypes.bool,
  inFooter: PropTypes.bool,
  blockLevelButtons: PropTypes.bool,
  onSuccess: PropTypes.func,
  onEndEdit: PropTypes.func,
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
  modalProvider: PropTypes.shape(MODAL_PROVIDER_PROP_TYPES).isRequired,
  rowData: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  children: oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  showMessage: PropTypes.bool,
};

RecordActionButtons.defaultProps = {
  permittedActions: {},
  hasLoaded: false,
  disabled: false,
  inFooter: true,
  blockLevelButtons: false,
  onSuccess: noop,
  onEndEdit: null,
  children: undefined,
  showMessage: true,
};

export default connectToModalProvider(connectToAPIProvider(RecordActionButtons));
