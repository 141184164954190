import React, { createRef, useEffect, useState } from 'react';

export type ReactPortalProviderProps = Record<string, unknown>;
export type ReactPortalContextProps = {
  portalContainerElement: null | HTMLElement
}

export const ReactPortalContext = React.createContext<ReactPortalContextProps>(null as never);

export const ReactPortalProvider: React.FC<ReactPortalProviderProps> = ({ children }) => {
  const portalContainerRef = createRef<HTMLDivElement>();
  const [portalContainerElement, setPortalContainerElement] = useState<null | HTMLDivElement>(portalContainerRef.current);

  useEffect(() => {
    setPortalContainerElement(portalContainerRef.current);
  }, [portalContainerRef]);

  /**
   * Render
   */
  return (
    <ReactPortalContext.Provider
      value={{
        portalContainerElement,
      }}
    >
      {children}
      <div ref={portalContainerRef} id="portal_container" className="portal-container" />
    </ReactPortalContext.Provider>
  );
};
