/* eslint-disable prefer-destructuring */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import NewsListPage from './news-list-page';
import { PUSH_OR_REPLACE } from '../../utils/constants';
import SORT_DIRECTION from '../../utils/sort-directions';
import ApiNewsQueryDefinition from '../../api-queries/definitions/api-news-query-definition';
import { ApiQueryDataLoader } from '../api-query-data-loader/api-query-data-loader';
import ApiQueryManager from '../../api-queries/managers/api-query-manager';
import { makeFilterString } from '../../utils/api-calls';
import { shallowAreObjectsDifferent } from '../../helpers/shallow-are-objects-different';
import * as urlStateManager from '../url-state-manager/url-state-manager';
import { DATA_TABLE_FLAG } from '../../constants/data-table-flag.const';
import { API_FILTER_OPERATION } from '../../constants/api-filter-operation.const';
import { NEWS_STATUS } from '../../constants/news-status.const';


/**
 * @description
 * Allowable views for the news query definition to have
 */
const allowableViews = [
  {
    key: 'published',
    title: 'Published News',
    flagTitles: [
      {
        flags: [DATA_TABLE_FLAG.ONLY_MINE],
        title: 'My Published News',
      },
    ],
    filters: [
      {
        field: 'status_id',
        operation: API_FILTER_OPERATION.EQUALS,
        values: [NEWS_STATUS.PUBLISHED],
      },
    ],
    flags: [],
  },
  {
    key: 'submitted',
    title: 'Submitted News',
    flagTitles: [
      {
        flags: [DATA_TABLE_FLAG.ONLY_MINE],
        title: 'My Submitted News',
      },
    ],
    filters: [
      {
        field: 'status_id',
        operation: API_FILTER_OPERATION.EQUALS,
        values: [NEWS_STATUS.SUBMITTED],
      },
    ],
    flags: [],
  },
  {
    key: 'draft',
    title: 'Drafts',
    flagTitles: [
      {
        flags: [DATA_TABLE_FLAG.ONLY_MINE],
        title: 'My Drafts',
      },
    ],
    filters: [
      {
        field: 'status_id',
        operation: API_FILTER_OPERATION.EQUALS,
        values: [NEWS_STATUS.DRAFT],
      },
    ],
    flags: [],
  },
  {
    key: 'all',
    title: 'All News',
    flagTitles: [
      {
        flags: [DATA_TABLE_FLAG.ONLY_MINE],
        title: 'All My News',
      },
    ],
    filters: [],
    flags: [],
  },
];


/**
 * @description
 * Allowable sort options for the news query definition to have
 */
const allowableSortOptions = [
  {
    name: 'Newest First',
    id: 1,
    field: 'published_at',
    direction: SORT_DIRECTION.DESC,
    icon: 'sort-amount-desc',
  },
  {
    name: 'Oldest First',
    id: 2,
    field: 'published_at',
    direction: SORT_DIRECTION.ASC,
    icon: 'sort-amount-asc',
  },
  {
    name: 'Author (A-Z)',
    id: 5,
    field: 'author.name',
    direction: SORT_DIRECTION.ASC,
    icon: 'sort-alpha-asc',
  },
  {
    name: 'Author (Z-A)',
    id: 6,
    field: 'author.name',
    direction: SORT_DIRECTION.DESC,
    icon: 'sort-alpha-desc',
  },
  {
    name: 'Title (A-Z)',
    id: 3,
    field: 'title',
    direction: SORT_DIRECTION.ASC,
    icon: 'sort-alpha-asc',
  },
  {
    name: 'Title (Z-A)',
    id: 4,
    field: 'title',
    direction: SORT_DIRECTION.DESC,
    icon: 'sort-alpha-desc',
  },
];

const allowablePageSizes = [4, 12, 18, 24, 48, 100, 200];

const URL_STATE_DEFAULTS = {
  activePage: 1,
  pageSize: allowablePageSizes[1],
  viewKey: allowableViews[0].key,
  sortOptionId: allowableSortOptions[0].id,
  searchTerm: '',
  onlyMineFlag: 'off',
  category: '',
};


/**
 * @class
 * @name NewsListPageContainer
 *
 * @description
 * Container for the news page
 * Loads and hands down data
 *
 * @param {{history: History}} param0
 */
class NewsListPageContainer extends React.Component {
  /**
   * @constructor
   *
   * @param {{}} props
   */
  constructor(props) {
    super(props);

    // default view to first in the array
    this.newsQueryManager = new ApiQueryManager(new ApiNewsQueryDefinition({
      availableFlags: [DATA_TABLE_FLAG.ONLY_MINE],
      baseWithParameters: ['author'],
      activePage: URL_STATE_DEFAULTS.activePage,
      pageSize: URL_STATE_DEFAULTS.pageSize,
    }));

    this.syncCacheUrlStateAndQueryDefinition(props.urlState);

    // TODO: default values from local storage
    this.state = {
      newsQueryUrl: this.getApiQueryUrl(),
    };
  }


  /**
   * Cache the url state to make the updating process more efficient and less complicated
   * @type {{
   *  activePage: number,
   *  searchTerm: string,
   *  pageSize: number,
   *  viewKey: string,
   *  sortOptionId: number,
   *  onlyMineFlag: string,
   * }} cachedUrlState
   */
  cachedUrlState;


  /**
   * @description
   * Update the cached url state to match
   *
   * Allow less renders
   *
   * @param {{
   *  activePage: number,
   *  searchTerm: string,
   *  pageSize: number,
   *  viewKey: string,
   *  sortOptionId: number,
   *  onlyMineFlag: string,
   * }} urlState
   */
  syncCacheUrlStateAndQueryDefinition = (urlState) => {
    const {
      activePage, searchTerm, pageSize, viewKey, sortOptionId, onlyMineFlag, category,
    } = urlState;

    this.cachedUrlState = {
      activePage,
      pageSize,
      searchTerm,
      viewKey,
      sortOptionId,
      onlyMineFlag,
      category,
    };

    this.newsQueryManager.setActivePage(activePage);
    this.newsQueryManager.setPageSize(pageSize);
    this.newsQueryManager.setSearchTerm(searchTerm !== '' ? searchTerm : null);
    this.newsQueryManager.setAllFlags(onlyMineFlag === 'on' ? [DATA_TABLE_FLAG.ONLY_MINE] : []);
  }


  /**
   * @inheritdoc
   */
  shouldComponentUpdate(nextProps) {
    // if url state changed, update query accordingly
    // TODO: optimize by checking only certain parameters
    const { urlState } = this.props;
    if (shallowAreObjectsDifferent(nextProps.urlState, urlState)) {
      this.syncCacheUrlStateAndQueryDefinition(nextProps.urlState);
      this.regenerateQuery();
      return false;
    }
    return true;
  }


  /**
   * @description
   * Get the Query Url with which to hit the API
   *
   * @returns {string}
   */
  getApiQueryUrl = () => {
    const { sortOptionId, viewKey, category } = this.cachedUrlState;

    const categoryFilter = category ? [{ field: 'category_id', operation: API_FILTER_OPERATION.EQUALS, values: [category] }] : [];

    const sortOption = allowableSortOptions.find((so) => so.id === sortOptionId);
    const view = allowableViews.find((v) => v.key === viewKey);

    return this.newsQueryManager.getQueryUrl([
      makeFilterString([...view.filters, ...categoryFilter]),
      ...view.flags,
      `sort[0][field]=${sortOption.field}&sort[0][direction]=${sortOption.direction}`,
    ]);
  }


  /**
   * @description
   * Regenerate the news query from set parameters
   *
   * @param {Record<PropertyKey, any>} additionalStateParameters
   */
  regenerateQuery = () => {
    this.setState({ newsQueryUrl: this.getApiQueryUrl() });
  }

  /**
   * @description
   * Fired when the sort is changed
   *
   * @param { null | (typeof allowableSortOptions)[0] } selectedSortOption
   * @param {{ action: 'selected-option', option: (typeof allowableSortOptions)[0] | {} }} action
   */
  handleSortChange = (selectedSortOption) => {
    // if (selectedSortOption) this.setState({activeSortOption: selectedSortOption}, this.regenerateQuery);
    if (selectedSortOption) this.props.setUrlState({ sortOptionId: selectedSortOption.id }, PUSH_OR_REPLACE.PUSH);
  }


  handleCategoryChange = (categoryChange) => {
    this.props.setUrlState({ category: categoryChange.newValue }, PUSH_OR_REPLACE.PUSH);
  }


  /**
   * @description
   * Fired when the view key is changed
   *
   * @param {string} newViewKey
   */
  handleSetView = (newViewKey) => {
    const newView = allowableViews.find((view) => view.key === newViewKey);
    if (newView) this.props.setUrlState({ viewKey: newView.key }, PUSH_OR_REPLACE.PUSH);
  }


  /**
   * @description
   * Fired when a view flag is toggled
   *
   * @param {string} viewFlag
   */
  handleToggleFlag = (viewFlag) => {
    const { urlState, setUrlState } = this.props;

    if (viewFlag === DATA_TABLE_FLAG.ONLY_MINE) {
      const { onlyMineFlag } = urlState;
      setUrlState({ onlyMineFlag: onlyMineFlag === 'off' ? 'on' : 'off' });
    }
  }


  /**
   * @description
   * Fired when the search term is changed
   *
   * @param {string} searchTerm
   */
  handleSearchTermChange = (searchTerm) => {
    this.newsQueryManager.setSearchTerm(searchTerm);
    this.regenerateQuery();
  }


  /**
   * @description
   * Fired when the searchTerm is confirmed by the user
   * Possibly because:
   *  - They pressed "enter",
   *  - They blurred the searchTerm box
   */
  handleConfirmSearchTerm = (searchTerm) => {
    // Put the searchTerm in the url
    this.newsQueryManager.setSearchTerm(searchTerm);
    if (this.props.urlState.searchTerm === this.newsQueryManager.readOnlyQueryDefinition.searchTerm) return;

    this.props.setUrlState({ searchTerm: this.newsQueryManager.readOnlyQueryDefinition.searchTerm || '' }, PUSH_OR_REPLACE.PUSH);
  }


  /**
   * @description
   * Fired when the active page is changed
   *
   * @param {number} newActivePage
   */
  handlePageChange = (newActivePage) => {
    this.props.setUrlState({ activePage: newActivePage }, PUSH_OR_REPLACE.PUSH);
  }


  /**
   * @description
   * Fired when the page size is changed
   *
   * @param {number} newPageSize
   */
  handlePageSizeChange = (newPageSize) => {
    this.props.setUrlState({ pageSize: newPageSize }, PUSH_OR_REPLACE.PUSH);
  }


  /**
   * @description
   * Fired when there are no results because the activePage is too high
   *
   * @param {{ total: number, perPage: number, currentPage: number }} param1
   * @returns {false} do not continue rendering until page with results is retrieved
   */
  handleLastPageExceeded = ({ total, perPage }) => {
    this.props.setUrlState({ activePage: Math.ceil(total / perPage) }, PUSH_OR_REPLACE.REPLACE);
    return false;
  }


  /**
   * @inheritdoc
   */
  render() {
    const { history, location, urlState } = this.props;
    const { newsQueryUrl } = this.state;

    const { viewKey, sortOptionId, category } = urlState;

    const {
      availableFlags, customFlags, pageSize, searchTerm, activePage,
    } = this.newsQueryManager.readOnlyQueryDefinition;

    const dataToRender = ({ hasError, isLoading, response }) => (
      <NewsListPage
        articles={(response && response.data) ? response.data : []}
        availableActions={(response && response.actions) ? response.actions : {}}
        allowablePageSizes={allowablePageSizes}
        pagination={response && response.meta
          ? {
            activePage,
            pageSize: response.meta.per_page,
            totalRecords: response.meta.total,
            onPageChange: this.handlePageChange,
            onPageSizeChange: this.handlePageSizeChange,
          } : undefined}
        searchTerm={searchTerm}
        categoryFilterId={category}
        hasError={hasError}
        isLoading={isLoading}
        history={history}
        location={location}
        expectedNewsArticleCount={pageSize}

        onSetView={this.handleSetView}
        onToggleFlag={this.handleToggleFlag}
        availableFlags={availableFlags}
        customFlags={customFlags}

        sortOptions={allowableSortOptions}
        activeSortOption={allowableSortOptions.find((so) => so.id === sortOptionId)}
        defaultSortOption={allowableSortOptions[0]}
        onSortChange={this.handleSortChange}
        onCategoryChange={this.handleCategoryChange}

        onChangeSearchTerm={this.handleSearchTermChange}
        onConfirmSearchTerm={this.handleConfirmSearchTerm}
        views={allowableViews}
        viewKey={viewKey}
      />
    );

    return (
      <ApiQueryDataLoader
        apiQueryUrl={newsQueryUrl}
        onLastPageExceeded={this.handleLastPageExceeded}
        render={({ hasError, isLoading, response }) => dataToRender({ hasError, isLoading, response })}
      />
    );
  }
}

/**
 * @description
 * Fired when the URL state is changed due to navigation
 *
 * @param {{
 *  sortOptionId?: string,
 *  viewKey?: string,
 *  activePage?: string,
 *  pageSize?: string,
 *  searchTerm: string,
 *  onlyMineFlag: string,
 * }} dirtyUrlState
 *
 * @returns {{
 *  sortOptionId: number,
 *  viewKey: string,
 *  activePage: number,
 *  pageSize: number
 *  searchTerm: string,
 *  onlyMineFlag: string,
 * }}
 */
const washUrlState = (dirtyUrlState) => {
  const cleanUrlState = {};

  // activeView
  const useView = allowableViews.find((view) => view.key === dirtyUrlState.viewKey);
  cleanUrlState.viewKey = useView ? useView.key : URL_STATE_DEFAULTS.viewKey;

  // sort option
  const useSortOption = allowableSortOptions.find((sortOption) => sortOption.id === Number(dirtyUrlState.sortOptionId));
  cleanUrlState.sortOptionId = useSortOption ? useSortOption.id : URL_STATE_DEFAULTS.sortOptionId;

  // activePage
  const activePage = (Number.isInteger(Number(dirtyUrlState.activePage)) && dirtyUrlState.activePage > 0)
    ? Number(dirtyUrlState.activePage)
    : URL_STATE_DEFAULTS.activePage;
  cleanUrlState.activePage = activePage;

  // pageSize
  const usePageSize = allowablePageSizes.includes(Number(dirtyUrlState.pageSize))
    ? Number(dirtyUrlState.pageSize)
    : URL_STATE_DEFAULTS.pageSize;
  cleanUrlState.pageSize = usePageSize;

  // searchTerm
  cleanUrlState.searchTerm = dirtyUrlState.searchTerm || URL_STATE_DEFAULTS.searchTerm;

  // only mine flag
  cleanUrlState.onlyMineFlag = dirtyUrlState.onlyMineFlag === 'on' ? 'on' : 'off';

  // category
  cleanUrlState.category = dirtyUrlState.category || undefined;

  return cleanUrlState;
};

NewsListPageContainer.propTypes = {
  urlState: PropTypes.shape({
    sortOptionId: PropTypes.number.isRequired,
    viewKey: PropTypes.string.isRequired,
    activePage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    searchTerm: PropTypes.string.isRequired,
    onlyMineFlag: PropTypes.string.isRequired,
    category: PropTypes.number.isRequired,
  }).isRequired,
  setUrlState: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default urlStateManager.connectUrlState(
  washUrlState,
  urlStateManager.cleanDefaultsFromUrlState(URL_STATE_DEFAULTS),
)(NewsListPageContainer);
