/**
 * @description
 * Which side of the button row should a button be aligned
 */
export const MODAL_BUTTON_SIDE_TYPE = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
} as const;

export type MODAL_BUTTON_SIDE_TYPE = typeof MODAL_BUTTON_SIDE_TYPE;
export type A_MODAL_BUTTON_SIDE_TYPE = MODAL_BUTTON_SIDE_TYPE[keyof MODAL_BUTTON_SIDE_TYPE];
