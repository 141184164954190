import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProjectSummaryTableCellGroup from './project-summary-table-cell-group';
import ProjectSummaryTableRow from './project-summary-table-row';
import { DATA_TYPE, buildDomDataTagId } from './project-summary-helpers';
import Icon from '../../layout-helpers/icon';
import ProjectSummaryTableProjectManagerSummaryDataRows from './project-summary-table-project-manager-summary-data-rows';

const ProjectSummaryTableStateSummaryDataRows = (props) => {
  const {
    summaryFields,
    visibleSummaryFieldCount,
    stateIds,
    pmAndStateIds,

    onClick,
    onBeginHover,
    onEndHover,

    geographicalStateData,
    projectManagerData,
    projectData,
  } = props;

  return (
    <>
      {/* Error loading State Summary Data */}
      {geographicalStateData.error && !geographicalStateData.isLoading && (
        <ProjectSummaryTableRow
          className="geographical-state summary data no-data error"
        >
          <ProjectSummaryTableCellGroup span={visibleSummaryFieldCount}>
            <Icon i="exclamation" />
            <span>{`Error: ${geographicalStateData.error}`}</span>
          </ProjectSummaryTableCellGroup>
        </ProjectSummaryTableRow>
      )}

      {/* No State Summary Data, No Error, Not loading */}
      {!geographicalStateData.summaryData.data.length && !geographicalStateData.isLoading && !geographicalStateData.error && (
        <ProjectSummaryTableRow
          className="geographical-state summary data no-data"
        >
          <ProjectSummaryTableCellGroup span={visibleSummaryFieldCount}>
            <span>Nothing to display</span>
          </ProjectSummaryTableCellGroup>
        </ProjectSummaryTableRow>
      )}

      {/* No State Summary Data and Summary Data is loading */}
      {!geographicalStateData.summaryData.data.length && geographicalStateData.isLoading && (
        <ProjectSummaryTableRow
          className="geographical-state summary data no-data loading"
        >
          <ProjectSummaryTableCellGroup span={visibleSummaryFieldCount}>
            <Icon i="rolling" />
            <span>Loading...</span>
          </ProjectSummaryTableCellGroup>
        </ProjectSummaryTableRow>
      )}

      {/* State Summary Data Exists and no error */}
      {!!geographicalStateData.summaryData.data.length && !geographicalStateData.error && (
        <>
          {/* State Summary Data Rows */}
          {geographicalStateData.summaryData.data.map((geographicalState) => (
            <React.Fragment
              key={`state_${geographicalState.state_id}`}
            >
              <ProjectSummaryTableRow
                className={classNames('geographical-state', 'summary', 'data', {
                  loading: geographicalStateData.isLoading,
                  expanded: stateIds.includes(geographicalState.state_id),
                })}
                dataType={DATA_TYPE.GEOGRAPHICAL_STATE}
                id={buildDomDataTagId(DATA_TYPE.GEOGRAPHICAL_STATE, geographicalState.state_id)}
                trackHover
                onBeginHover={onBeginHover}
                onEndHover={onEndHover}
                onClick={onClick}
              >
                <ProjectSummaryTableCellGroup
                  rowKey={`state_${geographicalState.state_id}`}
                  fields={summaryFields}
                  data={geographicalState}
                  onRenderFieldData={(field, data, defaultFormattedValue) => (
                    field.key === 'name' ? data.state_name : defaultFormattedValue
                  )}
                />

              </ProjectSummaryTableRow>

              {/* Project Manager Summary Data */}
              <ProjectSummaryTableProjectManagerSummaryDataRows
                summaryFields={summaryFields}
                visibleSummaryFieldCount={visibleSummaryFieldCount}
                pmAndStateIds={pmAndStateIds}

                onBeginHover={onBeginHover}
                onEndHover={onEndHover}
                onClick={onClick}

                projectManagerData={projectManagerData ? projectManagerData.find((projectManager) => projectManager.stateId === geographicalState.state_id) : null}
                projectData={projectData}
              />

            </React.Fragment>
          ))}

          {/* State summary totals row */}
          {!!geographicalStateData.summaryData.data.length && (
            <ProjectSummaryTableRow
              key="states_totals"
              className={classNames('geographical-state', 'summary', 'total', { loading: geographicalStateData.isLoading })}
            >
              <ProjectSummaryTableCellGroup
                rowKey="states_totals"
                fields={summaryFields}
                data={{
                  state_name: '',
                  state_id: -1,
                  state_acronym: '',
                  ...geographicalStateData.summaryData.totals,
                }}
              />
            </ProjectSummaryTableRow>
          )}
        </>
      )}
    </>
  );
};

ProjectSummaryTableStateSummaryDataRows.propTypes = {
  summaryFields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  })).isRequired,
  geographicalStateData: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    summaryData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
    monthlyData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
  }).isRequired,
  projectManagerData: PropTypes.arrayOf(
    PropTypes.shape({
      stateId: PropTypes.number.isRequired,
      isLoading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      summaryData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
      monthlyData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
    }),
  ).isRequired,
  projectData: PropTypes.arrayOf(
    PropTypes.shape({
      stateId: PropTypes.number.isRequired,
      projectManagerId: PropTypes.number.isRequired,
      isLoading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      summaryData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
      monthlyData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
    }),
  ).isRequired,
  visibleSummaryFieldCount: PropTypes.number.isRequired,
  stateIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  pmAndStateIds: PropTypes.arrayOf(PropTypes.shape({
    pmId: PropTypes.number.isRequired,
    stateId: PropTypes.number.isRequired,
  })).isRequired,
  onClick: PropTypes.func.isRequired,
  onBeginHover: PropTypes.func.isRequired,
  onEndHover: PropTypes.func.isRequired,
};

export default ProjectSummaryTableStateSummaryDataRows;
