import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

export const SecurityRoleUserWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'User',
  collectionItemPrimaryKeyFieldName: 'name',
  isReadOnly: true,
  showAddBtn: false,
  fields: [
    {
      name: 'id',
      title: 'ID',
      linkRoute: '/settings/user-management',
      visible: true,
      format: COLUMN_FORMAT.ID_LINK_SHORT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'name',
      title: 'Person',
      visible: true,
      linkRoute: '/settings/user-management',
      format: COLUMN_FORMAT.NAME_LINK,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'title',
      title: 'Job Title',
      visible: true,
      format: COLUMN_FORMAT.LONG_TEXT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
  ],
};
