import {
  projectPhaseOptions,
  yesNoOptions,
} from './constants';
import { mapSelectInputOptions } from '../helpers/map-select.helper';

import { COLUMN_FILTER_TYPES } from './column-filter-types';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { PROJECT_STATUS_NAME_MAP } from '../constants/project-status.const';
import { FILTER_OPERATION } from '../constants/filter-operation.const';
import { INTERNAL_PROJECT_STATUS_NAME_MAP } from '../constants/internal-project-status.const';
import { LEAVE_APPLICATION_STATUS_MAP } from '../constants/leave-application-status.const';
import { ALLOWANCE_STATUS_MAP } from '../constants/allowance-status.const';
import { EXPENSE_CLAIM_BATCH_STATUS_MAP } from '../constants/expense-claim-batch-status.const';
import { EXPENSE_CLAIM_STATUS_MAP } from '../constants/expense-claim-status.const';
import { CONTRACT_STATUS_NAMES } from '../constants/contract-status.const';
import { INTERNAL_PROJECT_TYPE_NAMES } from '../constants/internal-project-type.const';

// column Filter Select Default
const cfsDefault = {
  caption: 'Is exactly',
  inputOneCaption: 'Between',
  inputTwoCaption: 'and',
  inputCount: 1,
  hasDatePicker: false,
};


/**
 * @description
 * List of captions for each filter field type,
 * API filter Operations and Values
 * @see https://wiki.ciportal.net/link/36#bkmrk-filtering
 */
export const columnFilterTypeOperationMap = {
  [COLUMN_FILTER_TYPES.TEXT]: {
    [FILTER_OPERATION.CONTAINS]: { ...cfsDefault, caption: 'Contains' },
    [FILTER_OPERATION.EQUALS]: { ...cfsDefault },
    // [FILTER_OPERATION.STARTS_WITH]: { caption: 'Starts with' }, // TODO not in API back-end yet
    // [FILTER_OPERATION.ENDS_WITH]: { caption: 'Ends with' }, // TODO not in API back-end yet
    [FILTER_OPERATION.IS_NULL]: { ...cfsDefault, caption: '(Blanks)', inputCount: 0 },
    [FILTER_OPERATION.NOT_NULL]: { ...cfsDefault, caption: '(Non-Blanks)', inputCount: 0 },
  },
  [COLUMN_FILTER_TYPES.NUMERIC]: {
    [FILTER_OPERATION.EQUALS]: { ...cfsDefault },
    [FILTER_OPERATION.LESS_THAN]: { ...cfsDefault, caption: 'Is less than' },
    [FILTER_OPERATION.GREATER_THAN]: { ...cfsDefault, caption: 'Is greater than' },
    [FILTER_OPERATION.BETWEEN]: { ...cfsDefault, caption: 'Is between', inputCount: 2 },
    [FILTER_OPERATION.IS_NULL]: { ...cfsDefault, caption: '(Blanks)', inputCount: 0 },
    [FILTER_OPERATION.NOT_NULL]: { ...cfsDefault, caption: '(Non-Blanks)', inputCount: 0 },
  },
  [COLUMN_FILTER_TYPES.LIKELIHOOD]: {
    [FILTER_OPERATION.EQUALS]: { ...cfsDefault },
    [FILTER_OPERATION.LESS_THAN]: { ...cfsDefault, caption: 'Is less likely than' },
    [FILTER_OPERATION.GREATER_THAN]: { ...cfsDefault, caption: 'Is more likely than' },
    [FILTER_OPERATION.BETWEEN]: { ...cfsDefault, caption: 'Is between', inputCount: 2 },
    [FILTER_OPERATION.IS_NULL]: { ...cfsDefault, caption: '(Blanks)', inputCount: 0 },
    [FILTER_OPERATION.NOT_NULL]: { ...cfsDefault, caption: '(Non-Blanks)', inputCount: 0 },
  },
  [COLUMN_FILTER_TYPES.DATE]: {
    [FILTER_OPERATION.EQUALS]: { ...cfsDefault, caption: 'On date', hasDatePicker: true },
    [FILTER_OPERATION.LESS_THAN]: { ...cfsDefault, caption: 'Before date', hasDatePicker: true },
    [FILTER_OPERATION.GREATER_THAN]: { ...cfsDefault, caption: 'After date', hasDatePicker: true },
    [FILTER_OPERATION.BETWEEN]: {
      ...cfsDefault, caption: 'Between dates', inputCount: 2, hasDatePicker: true,
    },
    [FILTER_OPERATION.IS_NULL]: { ...cfsDefault, caption: '(Blanks)', inputCount: 0 },
    [FILTER_OPERATION.NOT_NULL]: { ...cfsDefault, caption: '(Non-Blanks)', inputCount: 0 },
  },
  [COLUMN_FILTER_TYPES.ASYNC_OPTION_LIST]: {
    [FILTER_OPERATION.EQUALS]: { ...cfsDefault, caption: 'Is' },
    [FILTER_OPERATION.IN]: { ...cfsDefault, caption: 'Is either' },
  },
  [COLUMN_FILTER_TYPES.OPTION_LIST]: {
    [FILTER_OPERATION.EQUALS]: { ...cfsDefault, caption: 'Is' },
    [FILTER_OPERATION.IN]: { ...cfsDefault, caption: 'Is either' },
  },
  [COLUMN_FILTER_TYPES.OBJECT]: {
    [FILTER_OPERATION.EQUALS]: { ...cfsDefault, caption: 'Is' },
    [FILTER_OPERATION.IN]: { ...cfsDefault, caption: 'Is either' },
  },
  [COLUMN_FILTER_TYPES.ICON_ONLY]: {
    all: { ...cfsDefault, caption: '(All)', inputCount: 0 },
  },
  // TODO: this isn't finished yet. Nick's problem. 😁
  [COLUMN_FILTER_TYPES.SET_NOT_SET]: {
    all: { ...cfsDefault, caption: '(All)', inputCount: 0 },
    [FILTER_OPERATION.NOT_NULL]: { ...cfsDefault, caption: 'Links', inputCount: 0 },
    [FILTER_OPERATION.IS_NULL]: { ...cfsDefault, caption: '(Blanks)', inputCount: 0 },
  },
};

const userSearch = {
  searchUrl: '/user',
};

const companySearch = {
  searchUrl: '/company',
};

const partnerProgramSearch = {
  searchUrl: '/partnerprogram',
  loadAndKeepAll: true,
};

const projectMaintenanceTypeSearch = {
  searchUrl: '/projectmaintenancetype',
  loadAndKeepAll: true,
};

const regionSearch = {
  searchUrl: '/state',
  loadAndKeepAll: true,
  labelKey: 'acronym',
};

const allowanceTypesSearch = {
  searchUrl: '/allowancetype',
  loadAndKeepAll: true,
};

const projectSearch = {
  searchUrl: '/project',
  getOptionValue: (option) => (option && option.project_number) || '',
  getOptionLabel: (option) => (option && option.name) || '',
  dataMap: (option) => ({
    ...option,
    name: `${option.project_number} ${option.name}`,
  }),
};

const internalProjectSearch = {
  searchUrl: '/internal-project',
  getOptionValue: (option) => (option && option.number) || '',
  getOptionLabel: (option) => (option && `${option.number || ''} ${option.name || ''}`) || '',
  dataMap: (option) => ({
    ...option,
    name: `${option.number || ''} ${option.name || ''}`,
  }),
};

const getPersonText = (option) => {
  if (option && (option.id || option.id === null)) {
    return option.id ? `${option.first} ${option.last}` : '(Blanks)';
  }
  return '';
};

const personSearch = {
  searchUrl: '/person',
  getOptionValue: getPersonText,
  getOptionLabel: getPersonText,
  dataMap: (option) => ({
    ...option,
    name: `${option.first} ${option.last}`,
  }),
};

const contractTypeSearch = {
  searchUrl: '/contract-type',
  loadAndKeepAll: true,
};

const contractSubtypeSearch = {
  searchUrl: '/contract-subtype',
  loadAndKeepAll: true,
};

export const defaultFilterFormat = {
  filterType: 'text',
  options: null,
  operations: columnFilterTypeOperationMap.text,
  sortIcon: 'alpha',
};

/**
 * @description
 * Get the filter format settings for a column
 */
export const filterFormatMap = {
  [COLUMN_FORMAT.ID_NAME_OPTIONS]: {
    filterType: COLUMN_FILTER_TYPES.OPTION_LIST,
    options: null,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OPTION_LIST],
    sortIcon: 'numeric',
  },
  [COLUMN_FORMAT.NULLABLE_NUMBER]: {
    filterType: COLUMN_FILTER_TYPES.NUMERIC,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.NUMERIC],
    options: null,
    sortIcon: 'numeric',
  },
  [COLUMN_FORMAT.NUMBER]: {
    filterType: COLUMN_FILTER_TYPES.NUMERIC,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.NUMERIC],
    options: null,
    sortIcon: 'numeric',
  },
  [COLUMN_FORMAT.NUMBER_SHORT]: {
    filterType: COLUMN_FILTER_TYPES.NUMERIC,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.NUMERIC],
    options: null,
    sortIcon: 'numeric',
  },
  [COLUMN_FORMAT.CURRENCY]: {
    filterType: COLUMN_FILTER_TYPES.NUMERIC,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.NUMERIC],
    options: null,
    sortIcon: 'numeric',
  },
  [COLUMN_FORMAT.LIKELIHOOD]: {
    filterType: COLUMN_FILTER_TYPES.LIKELIHOOD,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.LIKELIHOOD],
    options: null,
    sortIcon: 'amount',
  },
  [COLUMN_FORMAT.ID_LINK]: {
    filterType: COLUMN_FILTER_TYPES.NUMERIC,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.NUMERIC],
    options: null,
    sortIcon: 'numeric',
  },
  [COLUMN_FORMAT.NAME_LINK]: {
    filterType: COLUMN_FILTER_TYPES.TEXT,
    options: null,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.TEXT],
    sortIcon: 'alpha',
  },
  [COLUMN_FORMAT.TIMESTAMP]: {
    filterType: COLUMN_FILTER_TYPES.DATE,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.DATE],
    options: null,
    sortIcon: 'numeric',
  },
  [COLUMN_FORMAT.BUTTON_LINK]: {
    filterType: COLUMN_FILTER_TYPES.SET_NOT_SET,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.SET_NOT_SET],
    options: null,
    sortIcon: 'numeric',
  },
  [COLUMN_FORMAT.CHECKBOX]: {
    filterType: COLUMN_FILTER_TYPES.YES_NO,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OPTION_LIST],
    options: yesNoOptions,
    sortIcon: 'numeric',
  },
  [COLUMN_FORMAT.DTOOLS_CACHE_STATUS]: {
    filterType: COLUMN_FILTER_TYPES.ICON_ONLY,
    operations: {},
    options: null,
    sortIcon: 'none',
  },
  allowanceStatus: {
    filterType: COLUMN_FILTER_TYPES.OPTION_LIST,
    options: mapSelectInputOptions(ALLOWANCE_STATUS_MAP),
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  expenseStatus: {
    filterType: COLUMN_FILTER_TYPES.OPTION_LIST,
    options: mapSelectInputOptions(EXPENSE_CLAIM_STATUS_MAP),
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  batchStatus: {
    filterType: COLUMN_FILTER_TYPES.OPTION_LIST,
    options: mapSelectInputOptions(EXPENSE_CLAIM_BATCH_STATUS_MAP),
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  leaveStatus: {
    filterType: COLUMN_FILTER_TYPES.OPTION_LIST,
    options: mapSelectInputOptions(LEAVE_APPLICATION_STATUS_MAP),
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  contractStatus: {
    filterType: COLUMN_FILTER_TYPES.OPTION_LIST,
    options: mapSelectInputOptions(CONTRACT_STATUS_NAMES),
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  projectStatus: {
    filterType: COLUMN_FILTER_TYPES.OPTION_LIST,
    options: mapSelectInputOptions(PROJECT_STATUS_NAME_MAP),
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  projectPhase: {
    filterType: COLUMN_FILTER_TYPES.OPTION_LIST,
    options: projectPhaseOptions,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  internalProjectType: {
    filterType: COLUMN_FILTER_TYPES.OPTION_LIST,
    options: mapSelectInputOptions(INTERNAL_PROJECT_TYPE_NAMES),
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  internalProjectStatus: {
    filterType: COLUMN_FILTER_TYPES.OPTION_LIST,
    options: mapSelectInputOptions(INTERNAL_PROJECT_STATUS_NAME_MAP),
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  allowanceType: {
    filterType: COLUMN_FILTER_TYPES.ASYNC_OPTION_LIST,
    options: allowanceTypesSearch,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  company: {
    filterType: COLUMN_FILTER_TYPES.ASYNC_OPTION_LIST,
    options: companySearch,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  partnerProgram: {
    filterType: COLUMN_FILTER_TYPES.ASYNC_OPTION_LIST,
    options: partnerProgramSearch,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  projectMaintenanceType: {
    filterType: COLUMN_FILTER_TYPES.ASYNC_OPTION_LIST,
    options: projectMaintenanceTypeSearch,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  project: {
    filterType: COLUMN_FILTER_TYPES.ASYNC_OPTION_LIST,
    options: projectSearch,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  internalProject: {
    filterType: COLUMN_FILTER_TYPES.ASYNC_OPTION_LIST,
    options: internalProjectSearch,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  person: {
    filterType: COLUMN_FILTER_TYPES.ASYNC_OPTION_LIST,
    options: personSearch,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  state: {
    filterType: COLUMN_FILTER_TYPES.ASYNC_OPTION_LIST,
    options: regionSearch,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
    loadAndKeepAll: true,
  },
  user: {
    filterType: COLUMN_FILTER_TYPES.ASYNC_OPTION_LIST,
    options: userSearch,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  contractType: {
    filterType: COLUMN_FILTER_TYPES.ASYNC_OPTION_LIST,
    options: contractTypeSearch,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  contractSubtype: {
    filterType: COLUMN_FILTER_TYPES.ASYNC_OPTION_LIST,
    options: contractSubtypeSearch,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.OBJECT],
    sortIcon: 'alpha',
  },
  date: {
    filterType: COLUMN_FILTER_TYPES.DATE,
    operations: columnFilterTypeOperationMap[COLUMN_FILTER_TYPES.DATE],
    options: null,
    sortIcon: 'numeric',
  },
};


/**
 * Returns the format associated with the `format` passed in
 * or `'text'` if no settings exist for that format
 */
export const getFilterFormatOrFallback = (format, column) => {
  if (format === COLUMN_FORMAT.ID_NAME_OPTIONS) {
    if (!('inputOptions' in column)) { console.warn(`columns with format of COLUMN_FORMAT.ID_NAME_OPTIONS must have inputOptions. Not found on column: ${column.name}`); }

    const filterFormat = filterFormatMap[COLUMN_FORMAT.ID_NAME_OPTIONS];
    // override options with the column definitions options
    filterFormat.options = column.inputOptions;
    return filterFormat;
  }

  if (filterFormatMap[format] !== undefined) return filterFormatMap[format];
  return defaultFilterFormat;
};

/**
 * Figure out the filter format from column object
 * (found in a single column in table settings columns)
 * @param {object} column The column object which can contain
 *   `filterFormat`, `object.format` or `format`
 *
 * @returns {
 *    [valueKey]?: string
 * }
 */
export const getFilterFormat = (column) => {
  const object = column.object || {};
  const listSource = object.listSource || column.listSource;
  const filterFormat = listSource || column.filterFormat || object.format || column.format;
  return getFilterFormatOrFallback(filterFormat, column);
};
