export const DATATABLE_DATA_SUCCESS = 'DATATABLE_DATA_SUCCESS';
export const DATATABLE_FETCH_ERROR = 'DATATABLE_FETCH_ERROR';
export const DATATABLE_DATA_LOADING = 'DATATABLE_DATA_LOADING';

// actions for data fetch API
export function dataError(isError, tableIdentifier, errorMessage) {
  return {
    type: DATATABLE_FETCH_ERROR,
    tableIdentifier,
    isError,
    errorMessage,
  };
}

export function dataLoading(isLoading, tableIdentifier) {
  return {
    type: DATATABLE_DATA_LOADING,
    tableIdentifier,
    isLoading,
  };
}

export function tableData(tableIdentifier, data, activePage, totalRecords, availableActions, userHasPermissions) {
  return {
    type: DATATABLE_DATA_SUCCESS,
    tableIdentifier,
    data,
    activePage,
    totalRecords,
    availableActions,
    userHasPermissions,
  };
}
