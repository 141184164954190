import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

export const ContractTypeSubtypesWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Space',
  isReadOnly: true,
  showAddBtn: false,
  fields: [
    {
      name: 'contract_type_id',
      title: 'Contract Type',
      isParentId: true,
      useParentDataFieldValue: 'type_id',
      visible: false,
    },
    {
      name: 'id',
      title: 'Identity',
      description: 'Contract Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      linkRoute: '/service-and-maintenance/admin/contract-subtypes',
      visible: false,
    },
    {
      name: 'status',
      title: 'Status',
      format: COLUMN_FORMAT.OBJECT,
      formFieldType: FORM_FIELD_TYPE.CONTRACT_SUBTYPE_STATUS_PICKER,
      object: {
        key: 'name',
        sourceField: 'status',
        format: COLUMN_FORMAT.STATUS_BADGE,
      },
    },
    {
      name: 'name',
      title: 'Name',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: '/service-and-maintenance/admin/contract-subtypes',
    },
    {
      name: 'description',
      title: 'Description',
    },
    {
      name: 'available_start_date',
      title: 'Available Start Date',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
    },
    {
      name: 'available_end_date',
      title: 'Available End Date',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
    },
  ],
};
