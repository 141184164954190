import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
import Icon from '../../layout-helpers/icon';
import { APIContext } from '../../providers/api-provider';
import { buildAPIRoute } from '../../../helpers/build-api-route.helper';
// eslint-disable-next-line import/no-cycle
import { DetailedProfitAndLossSections, IDetailedProfitAndLossSection } from './detailed-profit-and-loss-sections';
import { apiAborter } from '../../../helpers/api-aborter.helper';
// import * as jsonData from './testData.json';

type DetailedProfitAndLossTableProps = {
  rowData: {
    id: number,
    dtools_id: string,
    quickbooks_subcustomer_id: number,
    client: {
      name: string,
    },
    project_number: string,
  },
  parentId: number,
  baseRoute: string,
  handleShowDetailToggle: () => void,
}

interface IMappedCategoryDataRecord extends IDetailedProfitAndLossSection {
  columns?: {
    name: string,
    title: string,
  }[],
}

type ReportCategoryRecord = {
  id: number,
  name: string,
  parentId: number|null,
  account_lines?: {
    category_id: string,
    // [key: string]: any,
  }[],
  summary_lines?: {
    category_id: number,
    // [key: string]: any,
  }[],
}

type ReportData = {
  name?: string,
  categories: ReportCategoryRecord[],
  account_lines: {
    category_id: number,
    // [key: string]: any,
  }[],
  summary_lines: {
    category_id: number,
    // [key: string]: any,
  }[],
  columns?: {
    name: string,
    title: string,
  }[],
}

type MappedReportData = {
  sections: IDetailedProfitAndLossSection[],
  columns?: {
  name: string,
    title: string,
  } [],
}

export const DetailedProfitandLossSkipFields = [
  'split_acc',
  'dept_name',
  'rbal_nat_amount',
  'rbal_nat_home_amount',
];

export const DetailedProfitandLossColumnNumberFields = [
  'doc_num',
];

export const DetailedProfitandLossColumnCurrencyFields = [
  'subt_nat_home_amount',
  'rbal_nat_home_amount',
  'subt_nat_amount',
  'rbal_nat_amount',
];

export const DetailedProfitAndLossDateFields = [
  'tx_date',
];

export const DetailedProfitAndLossTable: React.FC<DetailedProfitAndLossTableProps> = (props) => {
  const { handleShowDetailToggle, rowData, baseRoute, parentId } = props;

  const valueFields = DetailedProfitandLossColumnCurrencyFields;
  const skipFields = DetailedProfitandLossSkipFields;

  const [isLoading, setIsLoading] = useState(true);
  const [mappedCategoryData, setMappedCategoryData] = useState<MappedReportData|null>(null);
  const [dataError, setDataError] = useState<string | null>(null);
  const [localProjectId, setLocalProjectId] = useState(parentId);

  const { apiFetch } = useContext(APIContext);
  const abortController = apiAborter();

  const mapSubCategoryData: (category: ReportCategoryRecord, reportData: ReportData) => IMappedCategoryDataRecord = (category, reportData) => ({
    ...category,
    accountLines: reportData.account_lines.filter((line) => line.category_id === category.id),
    summaryLines: reportData.summary_lines.filter((line) => line.category_id === category.id),
    sections: reportData.categories
      .filter((subCat) => subCat.parentId === category.id)
      .map((subCategory) => mapSubCategoryData(subCategory, reportData)),
  });

  const mapCategoryData: (reportData: ReportData) => MappedReportData = (reportData) => ({
    sections: reportData.categories
      .filter((cat) => cat.parentId == null)
      .map((category) => mapSubCategoryData(category, reportData)),
    columns: reportData.columns,
  });

  const visibleColumns = (mappedCategoryData?.columns || []).filter((column) => skipFields.indexOf(column.name) === -1);

  /**
   * @description
   * Load the Project Summary Data
   */
  const getProjectProfitAndLossDetail = (projectId: number) => {
    setDataError(null);

    apiFetch(
      buildAPIRoute({
        parentId: projectId,
        baseRoute,
        apiRoute: '/profit-and-loss-detail',
      }),
      {
        name: 'ProfitAndLossDetailTable:getProjectProfitAndLossDetail',
        signal: abortController.signal,
      },
    ).then((response) => {
      if (response.success) {
        setMappedCategoryData(mapCategoryData(response.body.betterData));
        setIsLoading(false);
      } else if (!response.aborted) {
        console.error('DetailedProfitAndLossTable:getProjectProfitAndLossDetail', response.error);
        setMappedCategoryData(null);
        setDataError(response.error || 'Failed to Load Data');
        setIsLoading(false);
      }
    });
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  };

  useEffect(() => {
    if (localProjectId !== parentId) {
      setLocalProjectId(parentId);
    }
    getProjectProfitAndLossDetail(parentId);
    // setMappedCategoryData(mapCategoryData(jsonData.betterData));
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId]);

  return (
    <div className="profit-and-loss-detail">
      <Card className="">
        <CardBody>
          <h3 className="m-3 text-center">
              &nbsp; &nbsp; &nbsp; Project Profit and Loss Detail
            {' - '}
            { rowData.client.name || ''}
            {' '}
            {rowData.project_number || ''}
              &nbsp;
            <Button
              onClick={handleShowDetailToggle}
              className="pull-right"
              color="default"
            >
              <Icon i="ti-close" />
            </Button>
          </h3>
          <p className="mb-5 text-center">
            {'January 2010  ->  '}
            {moment().format('MMMM YYYY')}
          </p>
          {isLoading && (
            <div className="mb-5 text-center">
              Loading... &nbsp;
              <Icon i="rolling" />
            </div>
          )}
          {dataError && (
            <div className="text-warning">
              <span>
                {`Error loading Project Summary data: ${dataError}`}
              </span>
            </div>
          )}
          {mappedCategoryData !== null && (
            <table className="table profit-and-loss-detail-table table-sm">
              <thead>
                <tr>
                  {mappedCategoryData.columns && visibleColumns.map((column) => {
                    const isCurrency = (valueFields.indexOf(column.name) > -1);
                    return (
                      <th key={column.name} className={isCurrency ? 'text-right' : ''}>{column.title}</th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <DetailedProfitAndLossSections sections={mappedCategoryData?.sections || []} columns={visibleColumns} depth={0} />
              </tbody>
            </table>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
