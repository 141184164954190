
export const CONTRACT_NOTE_TYPE: Record<string, number> = {
  GENERAL: 1,
  BILLING: 2,
  RENEWAL: 3,
  PLAN_DETAILS: 4,
} as const;
export type CONTRACT_NOTE_TYPE = typeof CONTRACT_NOTE_TYPE;
export type AN_CONTRACT_NOTE_TYPE = CONTRACT_NOTE_TYPE[keyof CONTRACT_NOTE_TYPE];

export const CONTRACT_NOTE_TYPE_NAMES: { [K in AN_CONTRACT_NOTE_TYPE]: string } = {
  [CONTRACT_NOTE_TYPE.GENERAL]: 'General',
  [CONTRACT_NOTE_TYPE.BILLING]: 'Billing',
  [CONTRACT_NOTE_TYPE.RENEWAL]: 'Renewal',
  [CONTRACT_NOTE_TYPE.PLAN_DETAILS]: 'Plan Details',
} as const;
