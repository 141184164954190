import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { PROJECT_NOTE_TYPE_NAMES } from '../constants/project-note-type.const';
import { mapSelectInputOptions } from '../helpers/map-select.helper';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';

export const ProjectNotesWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.NOTE,
  reverseOrder: false,
  className: 'notes-widget',
  itemCaption: 'Note',
  fields: [
    {
      name: 'project_id',
      visible: false,
      isParentId: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      format: COLUMN_FORMAT.TEXT,
    },
    {
      name: 'type',
      title: 'Note Type',
      formSaveField: 'type_id',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'type',
        key: 'name',
        formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
        inputOptions: mapSelectInputOptions(PROJECT_NOTE_TYPE_NAMES),
      },
    },
    {
      name: 'created_by',
      title: 'Created By',
      formSaveField: 'created_by_id',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'body',
      visible: true,
      format: COLUMN_FORMAT.NUMBER,
    },
  ],
};
