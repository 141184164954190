import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';
import { ALLOWANCE_STATUS, ALLOWANCE_STATUS_MAP } from '../constants/allowance-status.const';

/**
 * Table Definition for My Leave page
 */
const processAllowancesTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Allowance Claim',
  baseRoute: '/allowance',
  // Where status is Submitted
  baseQueryString: [
    'filter[0][field]=status_id',
    `filter[0][operation]=${API_FILTER_OPERATION.NOT}`,
    `filter[0][value]=${ALLOWANCE_STATUS.UNSUBMITTED}`,
    'with[]=user:id,name',
    'with[]=approver:id,name',
    'with[]=status:id,name',
    'with[]=allowanceType',
    'with[]=project:id,project_number,partner_program_id',
  ].join('&'),

  columnPresets: [
    {
      name: 'default',
      title: 'Default',
      fields: [
        'id',
        'user',
        'status',
        'description',
        'allowance_type',
        'nights',
        'amount',
        'project',
        'job_number',
        'start_date',
        'end_date',
        'user_audit_submitted_at',
        'approver',
        'user_audit_approved_at',
      ],
    },
    {
      name: 'all',
      title: 'All',
      fields: [
        'id',
        'user',
        'status',
        'description',
        'allowance_type',
        'nights',
        'amount',
        'project',
        'job_number',
        'start_date',
        'end_date',
        'user_audit_submitted_at',
        'approver',
        'user_audit_approved_at',
      ],
    },
  ],

  /**
   * Definition of Columns for this table
   */
  columns: [
    {
      name: 'id',
      title: 'ID',
      description: 'Allowance Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      linkRoute: '/finance/process-allowances',
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'user',
      title: 'Employee',
      description: 'Person claiming an allowance',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/hrm/browse-profiles',
        linkId: 'id',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      formSaveField: 'user_id',
      visible: true,
      showInForm: true,
      order: 1,
      formOrder: 1,
    },
    {
      name: 'approver',
      title: 'Approver',
      description: 'Person responsible for approving',
      placeholder: 'Select...',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'approver',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/hrm/browse-profiles',
        linkId: 'id',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      required: true,
      formSaveField: 'approver_id',
      visible: true,
      showInForm: true,
      order: 14,
      formOrder: 2,
    },
    {
      // NOTE statuses are controlled by ACTION buttons
      name: 'status',
      title: 'Status',
      description: 'Current Status of Project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'status',
        key: 'name',
        format: COLUMN_FORMAT.STATUS_BADGE,
        listSource: 'allowanceStatus',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      formSaveField: 'status_id',
      visible: true,
      showInForm: true,
      order: 2,
      formOrder: 3,
    },
    {
      name: 'description',
      title: 'Description',
      placeholder: 'Enter a brief description',
      description: 'Allowance name/description',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      required: true,
      showInForm: true,
      order: 4,
      formOrder: 4,
    },
    {
      name: 'allowance_type',
      title: 'Allowance Type',
      description: 'Allowance Type',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'allowance_type',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
        listSource: 'allowanceType',
      },
      formFieldType: FORM_FIELD_TYPE.ALLOWANCE_TYPE_PICKER,
      formSaveField: 'allowance_type_id',
      visible: true,
      showInForm: true,
      required: true,
      order: 5,
      formOrder: 5,
    },
    {
      name: 'nights',
      title: 'Qty Nights/Days/Kms',
      description: 'Quantity of Nights/days/Kms',
      format: COLUMN_FORMAT.NUMBER,
      visible: true,
      showInForm: true,
      required: true,
      order: 6,
      formOrder: 6,
    },
    {
      name: 'amount',
      title: 'Amount',
      description: 'Project Total Value',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 7,
      formOrder: 7,
    },
    {
      name: 'project',
      title: 'Project',
      format: COLUMN_FORMAT.OBJECT,
      description: 'Portal Project Number',
      placeholder: 'P12345ABC',
      object: {
        sourceField: 'project',
        key: 'project_number',
        format: COLUMN_FORMAT.P_NUMBER,
        listSource: 'project',
      },
      formSaveField: 'project_id',
      formFieldType: FORM_FIELD_TYPE.PROJECT_PICKER,
      visible: true,
      showInForm: true,
      order: 8,
      formOrder: 8,
    },
    {
      name: 'job_number',
      title: 'Reference No',
      description: 'Alternate job number or reference if not a P Number',
      placeholder: 'Alternative reference / job no',
      format: COLUMN_FORMAT.TEXT,
      visible: true,
      showInForm: true,
      order: 9,
      formOrder: 9,
    },
    {
      name: 'start_date',
      title: 'Start',
      description: 'Start Date',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: true,
      showInForm: true,
      order: 10,
      formOrder: 10,
      required: true,
    },
    {
      name: 'end_date',
      title: 'End',
      description: 'End Date',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: true,
      showInForm: true,
      order: 11,
      formOrder: 11,
      required: true,
    },
    {
      title: 'Submitted',
      name: 'user_audit_submitted_at',
      sortColumn: 'submitted_at',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'submitted_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: false,
      order: 13,
      formOrder: 12,
    },
    {
      title: 'Approved',
      name: 'user_audit_approved_at',
      sortColumn: 'approved_at',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'approved_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: false,
      order: 15,
      formOrder: 13,
    },
  ],
  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Allowance Claim Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      ...eventsTabDefault,
      description: 'Allowance Claim Events',
      statusMap: ALLOWANCE_STATUS_MAP,
    },
  ],

  /**
   * Action settings for action buttons and related dialogs
   * @see https://wiki.ciportal.net/books/ci-portal-developer-documentation/page/workflow-actions-5ef
   */
  possibleActions: [
    {
      name: 'submit',
      title: 'Submit',
      color: BUTTON_COLOR.INFO,
      icon: ICON.SUBMIT,
      description: 'This will submit the allowance claim',
      successMessage: 'Allowance claim submitted',
      failMessage: 'Failed to submit allowance claim',
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update',
      successMessage: 'Updated',
      failMessage: 'Failed to update',
      hideRecordActionButton: true,
    },
    {
      name: 'unsubmit',
      title: 'Withdraw',
      color: BUTTON_COLOR.WARNING,
      icon: 'undo',
      description: 'This will WITHDRAW (unsubmit) the allowance claim',
      successMessage: 'Allowance claim withdrawn',
      failMessage: 'Failed to withdraw allowance claim',
    },
    {
      name: 'approve',
      title: 'Approve',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.CHECK,
      description: 'This will approve the allowance claim',
      successMessage: 'Allowance claim approved',
      failMessage: 'Failed to delete allowance claim',
    },
    {
      name: 'reject',
      title: 'Reject',
      color: BUTTON_COLOR.DANGER,
      icon: 'times',
      description: 'This will REJECT the allowance claim',
      successMessage: 'Allowance claim rejected',
      failMessage: 'Failed to reject allowance claim',
    },
    {
      name: 'revoke',
      title: 'Revoke',
      color: BUTTON_COLOR.DANGER,
      icon: 'undo',
      description: 'This will REVOKE the approval of this allowance claim',
      successMessage: 'Allowance claim revoked',
      failMessage: 'Failed to revoke allowance claim',
    },
    {
      name: 'process',
      title: 'Process',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.CHECK,
      description: 'This will mark the allowance claim as Processed',
      successMessage: 'Allowance claim marked as processed',
      failMessage: 'Failed to mark allowance claim as processed',
    },
    {
      name: 'unprocess',
      title: 'Un-process',
      color: BUTTON_COLOR.DANGER,
      icon: 'undo',
      description: 'This will mark the allowance claim as UN-PROCESSED',
      successMessage: 'Allowance claim marked as un-processed',
      failMessage: 'Failed to mark allowance claim as un-processed',
    },
    {
      name: 'pay',
      title: 'Pay',
      color: BUTTON_COLOR.INFO,
      icon: ICON.CHECK,
      description: 'This will mark the allowance claim as Paid',
      successMessage: 'Allowance claim marked as paid',
      failMessage: 'Failed to mark allowance claim as paid',
    },
    {
      name: 'unpay',
      title: 'Un-pay',
      color: BUTTON_COLOR.DANGER,
      icon: 'undo',
      description: 'This will mark the allowance claim as UN-PAID',
      successMessage: 'Allowance claim marked as un-paid',
      failMessage: 'Failed to mark allowance claim as un-paid',
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the allowance claim',
      successMessage: 'Allowance claim deleted',
      failMessage: 'Failed to delete allowance claim',
      hideRecordActionButton: true,
    },
  ],

  /**
   * View definitions
   */
  viewKey: 'approved',
  views: [
    {
      key: 'approved',
      title: 'Pending Action',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['approved'],
        },
      ],
      flags: [],
    },
    {
      key: 'processed',
      title: 'Processed',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['processed'],
        },
      ],
      flags: [],
    },
    {
      key: 'paid',
      title: 'Paid',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['paid'],
        },
      ],
      flags: [],
    },
    {
      key: 'all',
      title: 'All Allowances',
      filters: [],
      flags: [],
    },
  ],
};

export default processAllowancesTableInitialSettings;
