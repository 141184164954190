import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Img } from 'react-image';
import { PROFILE_IMG_BASE } from '../../utils/constants';

/**
 * <UserAvatar />
 * Renders an image and generates the URL based on a pattern from their name
 *
 * @param {object} props
 * @param {string} props.name User's name with spaces and capital letters is okay
 * @param {string} props.alt the ult and title tag in the image, defaults to 'Author'
 * @param {string} props.className Usual class tag, default is 'user-avatar'
 * @param {{}} props.style Usual style tag
 *
 * TODO: Tim make this not return a 404 when not found, having a 2XX something
 *   is better to prevent filling the console with red.
 *
 * @example
 * // Basic
 * <UserAvatar name="James Heazlewood" />
 * // > <img alt="Author" src="http://prof.aws.soutamazaws/james_heazlewood.jpg" class="user-avatar" />
 *
 * // Name as alt and black background
 * <UserAvatar name={userName} alt={userName} style={background: 'black'} />
 */
export const UserAvatar = ({
  name, alt, style, className,
}) => (
  <>
    {name && (
      <Img
        alt={alt}
        title={alt}
        src={`${PROFILE_IMG_BASE}/${name.toLowerCase().replace(/\W/g, '_')}.jpg`}
        className={classNames('user-avatar', className)}
        style={style}
        unloader={(
          <img
            src={`${PROFILE_IMG_BASE}/unknown_user_trans.png`}
            alt={name}
            className={className}
          />
        )}
      />
    )}
  </>
);

UserAvatar.defaultProps = {
  alt: 'Author',
  style: null,
  className: null,
};

UserAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  alt: PropTypes.string,
  style: PropTypes.shape({}),
  className: PropTypes.string,
};
