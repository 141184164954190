import React from 'react';
import PropTypes from 'prop-types';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { getFormatWidth } from '../../../helpers/column-format-width.helper';


/**
 * @description
 * Render out the heading row of one side of the project summary grid
 */
const ProjectSummaryTableCell = (props) => {
  const {
    className, children, span, field, isHeaderCell,
  } = props;

  const style = {};
  let fieldWidth = null;
  if (field && field.format && isHeaderCell) {
    fieldWidth = getFormatWidth(field.format);
    style.minWidth = fieldWidth;
    style.width = fieldWidth;
  }

  const toolTip = isHeaderCell && field && field.toolTip;

  return (
    isHeaderCell
      ? <th className={className} style={style} colSpan={span} title={toolTip}><div className="cell-wrapper">{children}</div></th>
      : <td className={className} style={style} colSpan={span}><div className="cell-wrapper">{children}</div></td>
  );
};

ProjectSummaryTableCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  span: PropTypes.number,
  field: PropTypes.shape({
    key: PropTypes.string,
    format: PropTypes.oneOf(Object.values(COLUMN_FORMAT)),
    toolTip: PropTypes.string,
  }),
  isHeaderCell: PropTypes.bool,
};

ProjectSummaryTableCell.defaultProps = {
  className: null,
  children: null,
  span: 1,
  field: null,
  isHeaderCell: false,
};

export default ProjectSummaryTableCell;
