import React from 'react';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { IDataTableRecord } from '../../../types/portal-data-table/portal-data-table-record.interface';
import { InMemoryTable } from '../../portal-data-table/in-memory-table';
import { getVendorManufacturerModelLink } from './eta-reporting-utilities';

interface IOutstandingItemsByVendorTableProps {
  recordData: IDataTableRecord[],
  projectId?: number,
  projectManagerId?: string | number | null,
}

export const OutstandingItemsByVendorTable: React.FC<IOutstandingItemsByVendorTableProps> = ({ recordData, projectId, projectManagerId }) => (
  <InMemoryTable
    title="Outstanding Items by Vendor"
    data={recordData}
    columns={[{
      title: 'Vendor',
      name: 'vendor',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: (row: IDataTableRecord) => getVendorManufacturerModelLink(true, null, null, row.vendor, projectId, null, projectManagerId),
      filterType: 'pickListFromRecordset',
    }, {
      title: 'First Required',
      name: 'first_item_required',
      format: COLUMN_FORMAT.DATE,
    }, {
      title: 'Last Required',
      name: 'last_item_required',
      format: COLUMN_FORMAT.DATE,
    }, {
      title: 'First Expected',
      name: 'first_item_expected',
      format: COLUMN_FORMAT.DATE,
    }, {
      title: 'Last Expected',
      name: 'last_item_expected',
      format: COLUMN_FORMAT.DATE,
    }, {
      title: 'Items',
      name: 'items',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      filterType: 'number',
    }]}
    paginate
  />
);
