import { useEffect, useRef } from 'react';

/**
 * Hook to determine if the component has been mounted
 * Useful when preventing useEffect from running on first mount
 *
 * usage:
 *    const isMountedRef = useIsMounted();
 *    if (isMounted.current) {
 *      // do something here you only want to occur when the component is mounted
 *    }
 *
 * @returns [true] if the component is mounted and has rendered at least once
 */
export const useIsMounted = (): boolean => {
  const isMounted = useRef(false);

  // only fire ONCE on load - set isMounted to true
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted.current;
};
