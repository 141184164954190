import { defaultTableSettings } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

/**
 * Table Definition for Commission Structure Brackets Table
 */
const commissionStructureBracketsTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Commission Structure Bracket',
  initialFormFocusFieldName: 'first',
  baseRoute: '/commission-structure/bracket',
  baseQueryString: [
    'with[]=commissionStructureIteration:id,name',
  ].join('&'),

  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'commission_structure_iteration',
        'net_profit_threshold',
        'commission_percentage',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'iteration_id',
        'net_profit_threshold',
        'commission_percentage',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'iteration_id',
        'net_profit_threshold',
        'commission_percentage',
      ],
    },
  ],

  /**
   * Definition of columns and APIPolyForm fields for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Identity',
      description: 'Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      linkRoute: '/finance/sales-commission/commission-brackets',
      visible: false,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'iteration_id',
      title: 'Iteration ID',
      description: 'Commission reporting Bracket Iteration_id',
      format: COLUMN_FORMAT.ID_LINK,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      linkRoute: '/finance/sales-commission/commission-iterations',
      visible: false,
      showInForm: false,
      required: true,
      order: 10,
      formOrder: 10,
    },
    {
      name: 'commission_structure_iteration',
      title: 'Iteration',
      description: 'The iteration that the bracket belongs to',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'commission_structure_iteration',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/finance/sales-commission/commission-iterations',
      },
      formSaveField: 'company_id',
      // formFieldType: FORM_FIELD_TYPE.COMPANY_PICKER,
      isCreatable: true,
      visible: true,
      order: 20,
      formOrder: 20,
    },
    {
      name: 'net_profit_threshold',
      title: 'Net profit threshold',
      description: 'Net profit threshold',
      format: COLUMN_FORMAT.NUMBER,
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
      visible: true,
      showInForm: true,
      required: true,
      order: 30,
      formOrder: 30,
    },
    {
      name: 'commission_percentage',
      title: 'Commission Percentage',
      description: 'Commission percentage',
      format: COLUMN_FORMAT.NUMBER,
      formFieldType: FORM_FIELD_TYPE.TEXT_INPUT,
      visible: true,
      showInForm: true,
      required: true,
      order: 40,
      formOrder: 40,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the Commission Structure Bracket',
      successMessage: 'Updated Commission Structure Bracket',
      failMessage: 'Failed to update Commission Structure Bracket',
      hideRecordActionButton: true,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Commission Structure Bracket Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
  ],
};

export default commissionStructureBracketsTableInitialSettings;
