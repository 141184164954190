import { COLUMN_FORMAT } from '../constants/column-format.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { INVOICE_REQUEST_DETAIL_TYPE_NAMES } from '../constants/invoice-request-detail-type.const';
import { mapSelectInputOptions } from '../helpers/map-select.helper';
import { InvoiceRequestLinesWidget } from '../components/widgets/invoice-request-lines-widget';

export const InvoiceRequestLinesWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  component: InvoiceRequestLinesWidget,
  itemCaption: 'Line',
  sortWidgetRecords: (a, b) => (a.sort_order === b.sortorder ? -1 : a.sort_order - b.sort_order),
  fields: [
    {
      name: 'invoice_request_id',
      visible: false,
      isParentId: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'sort_order',
      title: 'Order',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      initialValue: (formData, parentData, widgetData) => (widgetData ? widgetData.length : 1),
    },
    {
      name: 'type_id',
      title: 'Line Type',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'type',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: mapSelectInputOptions(INVOICE_REQUEST_DETAIL_TYPE_NAMES),
      formSaveField: 'type_id',
    },
    {
      name: 'description',
      title: 'Description',
      visible: true,
      format: COLUMN_FORMAT.LONG_TEXT,
    },
    {
      name: 'item_price',
      visible: true,
      title: 'PPU',
      description: 'Price Per Unit',
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
      allowNegative: true,
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    },
    {
      name: 'item_quantity',
      title: 'Quantity',
      visible: true,
      format: COLUMN_FORMAT.NULLABLE_NUMBER,
    },
    {
      name: 'total',
      title: 'Line Total',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.CALCULATED,
      calculate: (rowData) => ((rowData?.item_price && rowData?.item_quantity) ? rowData?.item_price * rowData?.item_quantity : undefined),
      showTotals: true,
      format: COLUMN_FORMAT.CURRENCY,
    },
  ],
};
