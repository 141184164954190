import React from 'react';
import { useHistory } from 'react-router';
import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { IPortalDataTablePageProps } from '../../types/portal-data-table/portal-data-table-page.props';

/**
 * Display the "Skill" data table as a whole page
 */
export const SecurityPermissionsDataTablePage: React.FC<IPortalDataTablePageProps> = ({
  baseFilters,
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const history = useHistory();
  return (
    <DataTablePage
      title="Security Permissions"
      tableIdentifier={TABLE_IDENTIFIER.SECURITY_PERMISSIONS_TABLE}
      actionHandlers={{}}
      baseFilters={baseFilters}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      {children}
    </DataTablePage>
  );
};
