import { APIPolyFormProps } from '../../types/poly-form/api-poly-form.props';

import { companyLocationBuildingFormFields } from './company-location.form-fields.building';
import { companyLocationLevelFormFields } from './company-location.form-fields.level';
import { companyLocationSiteFormFields } from './company-location.form-fields.site';
import { companyLocationZoneFormFields } from './company-location.form-fields.zone';

import { A_COMPANY_LOCATION_TYPE, COMPANY_LOCATION_TYPE } from '../company-location-type.const';

export const CompanyLocationLocationTypeFormFieldsMap: {[K in A_COMPANY_LOCATION_TYPE]: APIPolyFormProps['fields']} = {
  [COMPANY_LOCATION_TYPE.SITE]: companyLocationSiteFormFields,
  [COMPANY_LOCATION_TYPE.BUILDING]: companyLocationBuildingFormFields,
  [COMPANY_LOCATION_TYPE.LEVEL]: companyLocationLevelFormFields,
  [COMPANY_LOCATION_TYPE.ZONE]: companyLocationZoneFormFields,
};
