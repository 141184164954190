import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'reactstrap';
import Icon from '../../layout-helpers/icon';
import { formatValueString } from '../../render-functions';
import { DToolsLinkInfoRow } from './d-tools-link-info-row';
import rollingSvg from '../../../images/Rolling-1s-22px.svg';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';

const Spinner = () => <img src={rollingSvg} alt="table is loading more data" />;

export class ProjectDtoolsLinkRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unlinking: false,
    };
  }

  handleToggleMaster = (e, rowData) => {
    e.preventDefault();
    e.stopPropagation();
    const { toggleMaster } = this.props;
    toggleMaster(rowData.Id);
  };

  render = () => {
    const {
      rowData, matchesLoading, matchLoadError, openRowId, projectData, toggleOpenRow, unlinkProject } = this.props;
    const { unlinking } = this.state;
    const thisRowIsOpen = openRowId === rowData.dtools_id || openRowId === rowData.Id;
    const isMasterRow = rowData.dtools_id === projectData.dtools_id;
    const ItemField = ({ item, field, alternate }) => {
      const fieldValue = item[field] || item[alternate] || '-';
      const showSpinner = item[field] === undefined && matchesLoading;
      return (
        <td>
          {showSpinner && <Spinner />}
          {!showSpinner && fieldValue}
        </td>
      );
    };
    return (
      <React.Fragment key={rowData.id}>
        <tr className="dtools-link" onClick={() => toggleOpenRow(rowData)}>
          <ItemField item={rowData} field="dtools_pnumber" alternate="Name" />
          <ItemField item={rowData} field="dtools_name" />
          <ItemField item={rowData} field="Progress" />
          {matchLoadError ? (
            <td className="text-danger">???</td>
          ) : (
            <ItemField item={rowData} field="Approved" />
          )}
          <td className="text-right">{formatValueString(rowData.Price || 0, COLUMN_FORMAT.CURRENCY)}</td>
          <ItemField item={rowData} field="MatchType" />
          {/* <td>{rowData.dtools_id === projectData.dtools_id ? <span>Yes</span> : <span>&nbsp;</span>}</td> */}
          <td>
            <ButtonGroup className="pull-right">
              <Button color="info" size="sm">
                <Icon i={thisRowIsOpen ? 'eye-slash' : 'eye'} />
                &nbsp; details
              </Button>
              <Button
                color="warning"
                size="sm"
                className="text-white"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  this.setState({ unlinking: true }, unlinkProject(rowData));
                }}
              >
                <Icon i="unlink" isBusy={unlinking} />
                &nbsp; unlink
              </Button>
              {isMasterRow ? (
                <Button color="danger" onClick={(e) => this.handleToggleMaster(e, rowData)}>
                  Unlink Master
                </Button>
              ) : (
                <Button color="success" onClick={(e) => this.handleToggleMaster(e, rowData)}>
                  Make Master
                </Button>
              )}
            </ButtonGroup>
          </td>
        </tr>
        {(thisRowIsOpen || (isMasterRow && openRowId === null)) && (
          <DToolsLinkInfoRow dtoolsId={rowData.dtools_id || rowData.Id} projectData={projectData} />
        )}
      </React.Fragment>
    );
  };
}

ProjectDtoolsLinkRow.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.number,
    dtools_id: PropTypes.string,
    Id: PropTypes.string,
    Price: PropTypes.number,
    Progress: PropTypes.string,
  }).isRequired,
  matchesLoading: PropTypes.bool.isRequired,
  matchLoadError: PropTypes.bool,
  openRowId: PropTypes.string,
  toggleOpenRow: PropTypes.func.isRequired,
  unlinkProject: PropTypes.func.isRequired,
  projectData: PropTypes.shape({
    id: PropTypes.number,
    dtools_id: PropTypes.string,
  }).isRequired,
  toggleMaster: PropTypes.func.isRequired,
};

ProjectDtoolsLinkRow.defaultProps = {
  openRowId: undefined,
  matchLoadError: false,
};
