import { APIRecord } from '../types/api-record.interface';
import { APIPolyFormProps } from '../types/poly-form/api-poly-form.props';
import { FormFieldComponentProps } from '../types/poly-form/form-field-component.props';

/**
 * Concatenate the route attributes passed into PolyForms into a single string
 * to be used during the post / put / delete methods.
 *
 * @param {
 *  baseRoute: string,
 *  parentId?: null | number | string,
 *  apiRoute?: string,
 *  primaryKeyValue?: null | number | string,
 *  apiQuery?: string,
 *  formData?: PArtial<T>,
 *  isNewRecord?: boolean,
 * } param0
 *
 * isNewRecord: Whether this form is representative of a new or exiting record (i.e. for post or patch)
 * parentId: (optional) The ID of the parent record this form is a child record of
 * primaryKeyValue: The computed primary key value of the current record
 * baseRoute: Part 1 / 3 of the URL to load the form data `${baseRoute}${apiRoute}?${apiQuery}`
 * apiRoute: Part 2 / 3 of the URL to load the form data `${baseRoute}${apiRoute}?${apiQuery}`
 * apiQuery: Part 3 / 3 of the URL to load the form data `${baseRoute}${apiRoute}?${apiQuery}`
 *
 * @returns the concatenated url & query string
 */
export const buildAPIRoute = <T extends APIRecord = APIRecord>(param0: {
  baseRoute: APIPolyFormProps<T>['baseRoute'],
  parentId?: APIPolyFormProps<T>['parentId'],
  apiRoute?: APIPolyFormProps<T>['apiRoute'],
  primaryKeyValue?: FormFieldComponentProps['value'],
  apiQuery?: APIPolyFormProps<T>['apiQuery'],
  formData?: APIPolyFormProps<T>['formData'],
  isNewRecord?: APIPolyFormProps<T>['isNewRecord'],
}): string => {
  const {
    baseRoute,
    parentId,
    apiRoute,
    primaryKeyValue,
    apiQuery,
    formData,
    isNewRecord = false,
  } = param0;

  const baseRouteWithParentId = `${baseRoute}${parentId ? `/${parentId}` : ''}`;
  const calculatedApiRoute = (typeof apiRoute === 'function' ? apiRoute(formData ?? {}) : (apiRoute ?? ''));
  const primaryKeyValueString = primaryKeyValue ? `/${primaryKeyValue}` : '';
  const route = isNewRecord ? `${baseRouteWithParentId}${calculatedApiRoute ?? ''}` : `${baseRouteWithParentId}${calculatedApiRoute ?? ''}${primaryKeyValueString}`;
  if (apiQuery) {
    return `${route}?${apiQuery}`;
  }
  return route;
};
