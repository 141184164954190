/* eslint-disable import/prefer-default-export */
import moment from 'moment';

// TODO: cache values against their options
// import { createAutoInvalidatingCache } from './create-auto-invalidating-cache';
// import { shallowAreObjectsDifferent } from '../helpers/shallow-are-objects-different';

// /**
//  * @type {{options: {}, cache: ReturnType<typeof createAutoInvalidatingCache>}[]} optionsCache
//  */
// const optionsCache = [];

// function findCachedOptions(findOptions) {
//   const foundCache = optionsCache.find(optionsCacheItem => !shallowAreObjectsDifferent(optionsCacheItem.options, findOptions));
//   return foundCache;
// }

const defaultOptions = {
  endOfYear: false,
  addYears: 0,
};

/**
 * @description
 * Get a moment for the desired financial year.
 * Defaults to current financial year
 *
 * @param {{
 *  base?: moment.MomentInput,
 *  endOfYear?: boolean,
 *  addYears?: number,
 * }} inputOptions
 * @returns {moment.Moment}
 */
export function financialYear(inputOptions) {
  const options = { ...defaultOptions, ...inputOptions };

  let targetDate = moment(options.base).add(options.addYears, 'year');

  // if it's it's prior to EOFY  return the target year looking back
  // (otherwise it's after EOFY so we return the target year looking forward)
  if (parseInt(targetDate.format('M'), 10) < 7) {
    targetDate = targetDate.subtract(1, 'year');
  }

  // FY start
  if (!options.endOfYear) {
    return targetDate.month('July').startOf('month');
  }
  // FY end
  return targetDate.add(1, 'year').month('June').endOf('month');
}
