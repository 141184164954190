import React, { useState } from 'react';
import shortid from 'shortid';
import classnames from 'classnames';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';

export type PasswordInputProps = Pick<FormFieldComponentProps,
  'className' |
  'id' |
  'name' |
  'value' |

  'placeholder' |
  'maxLength' |
  'autoComplete' |
  'autoCompleteIdentifier' |

  'tabIndex' |
  'disabled' |
  'hasError' |
  'inline' |

  'onKeyPress' |
  'onKeyDown' |
  'onKeyUp' |
  'onChange'
>

/**
 * TODO: This is basically identical to the TextInput. Best extend the text input to
 * take an isPassword prop or something
 */
export const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  const {
    id,
    name,
    className,
    hasError = false,
    value = null,
    placeholder = 'Password',
    disabled = false,
    tabIndex,
    inline,
    autoComplete = true,
    autoCompleteIdentifier,
    onKeyDown,
    onKeyUp,
    onKeyPress,
    onChange,
  } = props;

  // This is to prevent chrome's auto complete from getting in the way
  const [autoCompleteBlockingName] = useState(() => ((autoComplete === true) ? (autoCompleteIdentifier ?? name) : `${shortid.generate()}`));

  /**
   * If experiencing this console error
   * "[DOM] Input elements should have autocomplete attributes (suggested: "current-password"): (More info: https://goo.gl/9p2vKq)"
   * then @see https://github.com/grommet/grommet/issues/2306
   */
  return (
    <input
      id={id}
      required
      className={classnames('tabs-inline-text', 'form-control', className, {
        inline: (inline === true),
        'form-control-danger': hasError,
      })}
      onChange={(e) => {
        if (onChange) {
          onChange({
            fieldName: name,
            newValue: e.target.value,
          });
        }
      }}
      disabled={disabled}
      name={name}
      value={value === null ? '' : value}
      type="password"
      placeholder={placeholder}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onKeyPress={onKeyPress}
      tabIndex={tabIndex}
      autoComplete={(autoComplete === true) ? (autoCompleteIdentifier ?? 'on') : autoCompleteBlockingName}
    />
  );
};
