import React, { useCallback, useEffect, useRef, createRef, useState } from 'react';
import classNames from 'classnames';
import ReactResizeDetector from 'react-resize-detector';
import { Button } from 'reactstrap';

import { AModalResultType } from '../../types/modal/modal-result';
import { IModalWizardState } from '../../types/modal/modal-wizard-state.interface';
import { IModalWizardPageData } from '../../types/modal/modal-wizard-page-data.interface';
import { IModalWizardPageDataError } from '../../types/modal/modal-wizard-page-data-error.interface';
import { ModalProps } from '../../types/modal/modal.props';

import { usePreviousValue } from '../../react-hooks/use-previous-value.hook';
import { useForceUpdate } from '../../react-hooks/use-force-update.hook';

import Icon from '../layout-helpers/icon';
import { WIZARD_PAGE_TRANSITION_DURATION, ModalWizardPageProps } from './modal-wizard-page';
import { ModalWizardPageIndicator } from './modal-wizard-page-indicator';
import { Modal } from './Modal';

import { getScrollbarSize } from '../../helpers/get-scrollbar-size.helper';
import { deepCompare } from '../../helpers/deep-compare.helper';

import { TRANSITION_STATE, A_TRANSITION_STATE } from '../../constants/transition-state.const';
import { A_MODAL_WIZARD_BUTTON_TYPE, MODAL_WIZARD_BUTTON_TYPE } from '../../constants/modal-wizard-button-type.const';
import { THEME_COLOR } from '../../constants/theme-color.const';
import { A_TRANSITION_DIRECTION, TRANSITION_DIRECTION } from '../../constants/transition-direction.const';
import { FormFieldChangeProps } from '../../types/poly-form/form-field-change-props';

export type ModalWizardProps<T extends IModalWizardState, R extends AModalResultType> = ModalProps<R> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageComponents: ((props: ModalWizardPageProps<any, T, R>) => React.ReactElement)[];
  wizardState: T;

  setWizardState: <K extends keyof T>(
    state: ((prevState: Readonly<T>, props: Readonly<ModalProps<R>>) => (Pick<T, K> | T | null)) | (Pick<T, K> | T | null),
    callback?: () => void
  ) => void;

  onCheckButtonEnabled?: (buttonType: A_MODAL_WIZARD_BUTTON_TYPE, pageIndex: number, defaultButtonEnabled: boolean) => boolean;
  onCheckButtonVisible?: (buttonType: A_MODAL_WIZARD_BUTTON_TYPE, pageIndex: number, defaultButtonVisible: boolean) => boolean;

  onModalCancel: () => void;
  onModalComplete: () => void;
  onPageIndexChanged?: (newPageIndex: number) => void;
  onValidatePageData?: (pageIndex: number, pageData: IModalWizardPageData) => true | IModalWizardPageDataError[];
  onBeforePageDataChanged?: (pageIndex: number, pageData: IModalWizardPageData) => null | IModalWizardPageData;
  onPageDataChanged?: (pageIndex: number, pageData: IModalWizardPageData) => void;
  onButtonClick?: (buttonType: A_MODAL_WIZARD_BUTTON_TYPE, pageIndex: number) => boolean;
};


export const ModalWizard = <T extends IModalWizardState, R extends AModalResultType>(props: ModalWizardProps<T, R>): React.ReactElement => {
  const {
    className,
    id: modalId,
    showModal,
    closeModal,
    pageComponents,
    wizardState,
    setWizardState,

    onPageIndexChanged,
    onModalCancel,
    onModalComplete,
    onValidatePageData,
    onBeforePageDataChanged,
    onPageDataChanged,
    onCheckButtonEnabled,
    onCheckButtonVisible,
    onButtonClick,
  } = props;

  // Create an array of transition states to reflect the modal pages
  // When initialising the page transition states, assume the first page is visible and all others hidden
  // This has to be done by ref because we update this array using time-outs
  const pageTransitionStates = useRef<{
    state: A_TRANSITION_STATE,
    direction: null | A_TRANSITION_DIRECTION,
    timeout: null | ReturnType<typeof setTimeout>,
    contentHeight: null | number,
  }[]>(
    pageComponents.map(() => ({
      state: TRANSITION_STATE.HIDDEN,
      direction: null,
      timeout: null,
      contentHeight: 0,
    })),
  );

  // Keep track of the number of pages in the wizard
  const pageCount = pageComponents.length;

  // Extract some wizard state variables
  const { currentPageIndex } = wizardState;

  // This value will know the previous page index whenever the current page index changes
  const previousPageIndex = usePreviousValue(currentPageIndex);

  // Because we're using a ref to store pseudo-state (i.e. page transitions) we'll need to trigger page renders manually
  const forceUpdate = useForceUpdate();

  // Pass a ref to the modal instead of the modal creating one itself, we need to pass a ref to the wizard pages
  const modalRef = createRef<HTMLElement>();

  // The width of the page is offset by the scrollbar width to stop the scrollbar from jumping in and out
  const [scrollBarWidth, setScrollbarWidth] = useState(0);


  /**
   * Validate and Move to a specific page index
   */
  const changePage = useCallback((newPageIndex: number) => {
    // Don't attempt to set a page index out of range
    if (newPageIndex >= 0 && newPageIndex < pageCount) {
      setWizardState({
        currentPageIndex: newPageIndex,
      });

      // Let the parent know that the page index has changed (if bound)
      if (onPageIndexChanged) {
        onPageIndexChanged(newPageIndex);
      }
    }
  }, [onPageIndexChanged, pageCount, setWizardState]);


  /**
   * Called after a validation result to inform the current page of any errors in their wizard page data
   */
  const setPageDataErrors = useCallback((pageIndex: number, pageDataErrors: null | IModalWizardPageDataError[]) => {
    const newPageDataErrors = {
      ...wizardState.pageDataErrors,
      [pageIndex]: pageDataErrors,
    };

    setWizardState({
      pageDataErrors: newPageDataErrors,
    });
  }, [setWizardState, wizardState.pageDataErrors]);


  /**
   * Check the page for data errors
   */
  const validatePageData = useCallback((pageIndex: number, pageData?: IModalWizardPageData): true | IModalWizardPageDataError[] => {
    const validationResult = onValidatePageData ? onValidatePageData(currentPageIndex, pageData ?? wizardState.pageData[pageIndex]) : true;

    if (validationResult === true) {
      setPageDataErrors(pageIndex, null);
      return true;
    }
    setPageDataErrors(pageIndex, validationResult);
    return validationResult;
  }, [wizardState.pageData, currentPageIndex, onValidatePageData, setPageDataErrors]);


  /**
   * Helper to wrap the setWizardState call
   */
  const setPageData = useCallback((pageIndex: number, data: IModalWizardPageData) => {
    // If there were any data errors on the page which was changed, re-evaluate the errors
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    let newDataErrors = wizardState.pageDataErrors!;
    const newPageDataErrors = newDataErrors[pageIndex];
    if (Array.isArray(newPageDataErrors) && newPageDataErrors.length > 0) {
      const validationResult = validatePageData(pageIndex, data);

      // Slot the updated page data errors into the wizard data errors
      newDataErrors = {
        ...newDataErrors,
        [pageIndex]: validationResult === true ? null : validationResult,
      };
    }

    const oldData = { ...wizardState.pageData[pageIndex] };

    // fire the onBeforePageDataChanged event
    let alteredData: null | IModalWizardPageData = null;
    if (onBeforePageDataChanged) {
      alteredData = onBeforePageDataChanged(pageIndex, data);
    }

    const finalPageData = alteredData ?? data;

    // Slot the updated page data into the wizard data
    const newData = {
      ...wizardState.pageData,
      [pageIndex]: finalPageData,
    };

    // Update the state
    setWizardState({
      pageData: newData,
      pageDataErrors: newDataErrors,
    }, () => {
      // fire the onPageDataChanged event (if the data has changed)
      if (!deepCompare(oldData, finalPageData)) {
        if (onPageDataChanged) {
          onPageDataChanged(pageIndex, finalPageData);
        }
      }
    });
  }, [wizardState.pageDataErrors, wizardState.pageData, setWizardState, onPageDataChanged, onBeforePageDataChanged, validatePageData]);


  /**
   * Fired when rendering one of the default modal wizard buttons (next, prev, cancel, submit etc...)
   * to determine whether the button should be visible.
   */
  const checkButtonVisible = useCallback((buttonType: A_MODAL_WIZARD_BUTTON_TYPE, pageIndex: number) => {
    // Start with some basic wizard logic
    let buttonVisible = false;
    switch (buttonType) {
      // Only allow the previous button when there is no previous page
      case MODAL_WIZARD_BUTTON_TYPE.CANCEL:
        buttonVisible = pageIndex === 0;
        break;

      // Only allow the previous button when there is a page before the current page
      case MODAL_WIZARD_BUTTON_TYPE.PREV:
        buttonVisible = pageIndex > 0;
        break;

      // Only allow the next button when there is a page beyond the current page
      case MODAL_WIZARD_BUTTON_TYPE.NEXT:
        buttonVisible = pageIndex < (pageCount - 1);
        break;

      // Only allow the submit button when there is no page beyond the current page
      case MODAL_WIZARD_BUTTON_TYPE.SUBMIT:
        buttonVisible = pageIndex === (pageCount - 1);
        break;

      default:
        buttonVisible = false;
        break;
    }

    // Fire the global wizard callback for checking whether a button is enabled
    if (onCheckButtonVisible) {
      buttonVisible = onCheckButtonVisible(buttonType, pageIndex, buttonVisible);
    }

    return buttonVisible;
  }, [onCheckButtonVisible, pageCount]);


  /**
   * Fired when rendering one of the default modal wizard buttons (next, prev, cancel, submit etc...)
   * to determine whether the button should be enabled.
   */
  const checkButtonEnabled = useCallback((buttonType: A_MODAL_WIZARD_BUTTON_TYPE, pageIndex: number) => {
    let buttonEnabled = true;

    // Fire the global wizard callback for checking whether a button is enabled
    if (onCheckButtonEnabled) {
      buttonEnabled = onCheckButtonEnabled(buttonType, pageIndex, buttonEnabled);
    }

    return buttonEnabled;
  }, [onCheckButtonEnabled]);


  /**
   * Get the height of the wizard page wrapper.
   * This is constrained by the CSS min and max values but attempts to utilise as much space in the wizard container as possible
   */
  const getPageWrapperHeight = (): number => {
    // iterate over all of the page content heights and update the setMaxPageContentHeight
    let newMaxPageHeight = 0;
    pageTransitionStates.current
      // Only care about incoming or visible pages
      .filter((pageTransitionState) => ([TRANSITION_STATE.VISIBLE, TRANSITION_STATE.IN] as A_TRANSITION_STATE[]).includes(pageTransitionState.state))
      .forEach((pageTransitionState) => {
        newMaxPageHeight = Math.max(newMaxPageHeight, pageTransitionState.contentHeight ?? 0);
      });

    return newMaxPageHeight;
  };


  /**
   * Fired when a user clicks one of the default modal wizard buttons (next, prev, cancel, submit etc...)
   */
  const handleButtonClick = useCallback((
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLButtonElement>,
    buttonType: A_MODAL_WIZARD_BUTTON_TYPE,
    pageIndex: number,
  ) => {
    e.preventDefault();
    let handled = false;

    // check to see if the global wizard callback for performing an action on button click is defined, execute and check if handled
    if (!handled && onButtonClick) {
      handled = onButtonClick(buttonType, pageIndex);
    }

    // default wizard behavior
    if (!handled) {
      switch (buttonType) {
        // When the cancel button is clicked, call the onModalCancel handler
        case MODAL_WIZARD_BUTTON_TYPE.CANCEL:
          onModalCancel();
          break;

        // When the previous button is clicked, navigate to the previous page
        case MODAL_WIZARD_BUTTON_TYPE.PREV:
          changePage(currentPageIndex - 1);
          break;

          // When the next button is clicked, navigate to the next page
        case MODAL_WIZARD_BUTTON_TYPE.NEXT: {
          if (validatePageData(currentPageIndex) === true) {
            changePage(currentPageIndex + 1);
          }
          break;
        }

        // When the submit button is clicked, call back the onModalComplete handler
        case MODAL_WIZARD_BUTTON_TYPE.SUBMIT:
          onModalComplete();
          break;
      }
    }
  }, [changePage, currentPageIndex, onButtonClick, onModalCancel, onModalComplete, validatePageData]);


  /**
   * Fired when a user presses a key while focused on one of the default modal wizard buttons (next, prev, cancel, submit etc...)
   */
  const handleButtonKeyDown = useCallback((
    e: React.KeyboardEvent<HTMLButtonElement>,
    buttonType: A_MODAL_WIZARD_BUTTON_TYPE,
    pageIndex: number,
  ) => {
    // Only forward the event to the handleButtonClick event if it is a valid keyCode (space or enter/return)
    if ([13, 32].includes(e.keyCode)) {
      handleButtonClick(e, buttonType, pageIndex);
    }
  }, [handleButtonClick]);


  /**
   * Fired when one of the pages is loaded or when the height of the content on one of the wizard pages
   * changes height.
   */
  const handleWizardContentHeightChanged = (pageIndex: number, height: number) => {
    const oldMaxHeight = getPageWrapperHeight();

    pageTransitionStates.current[pageIndex].contentHeight = height;

    // As we're updating the reference object here, force a re-render
    if (oldMaxHeight !== getPageWrapperHeight()) {
      forceUpdate();
    }
  };


  /**
   * Fired when one of the data fields on a rendered wizard page is changed
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePageDataFieldChange = useCallback((pageIndex: number, field: FormFieldChangeProps | FormFieldChangeProps[]) => {
    const pageData = wizardState.pageData[pageIndex];

    // Normalise the changed fields to an array of changed fields
    const changedFields = Array.isArray(field) ? field : [field];

    let newPageData = {
      ...pageData,
    };

    // Iterate over the changed fields
    changedFields.forEach((changedField) => {
      // Update the field value and object value in the form data
      newPageData = {
        ...newPageData,
        ...(changedField.objectFieldName ? { [changedField.objectFieldName]: changedField.objectFieldNewValue } : {}),
        [changedField.fieldName]: changedField.newValue,
      };
    });

    setPageData(pageIndex, newPageData);
  }, [wizardState.pageData, setPageData]);


  /**
   * When a transition is initiated, this function creates a timeout for a specific page to ultimately lock
   * itself in or remove itself after the transition duration has completed.
   */
  const createEndTransitionTimeout = useCallback((pageIndex: number, endTransitionState: A_TRANSITION_STATE): ReturnType<typeof setTimeout> => {
    // Clear any pre-existing time-outs from incomplete page transitions
    const existingTimeout = pageTransitionStates.current[pageIndex].timeout;
    if (existingTimeout) {
      clearTimeout(existingTimeout);
    }

    // Return a timeout that will end the transition
    return setTimeout(() => {
      const pageTransitionState = pageTransitionStates.current[pageIndex];
      if (pageTransitionState.timeout) {
        clearTimeout(pageTransitionState.timeout);
        pageTransitionState.timeout = null;
      }
      pageTransitionState.direction = null;
      pageTransitionState.state = endTransitionState;
      forceUpdate();
    }, WIZARD_PAGE_TRANSITION_DURATION);
  }, [forceUpdate]);


  /**
   * When the currentPageIndex changes - mark old pages as transitioning in / out
   */
  useEffect(() => {
    // Don't do anything if this effect has been fired by the previousPageIndex catching up to the currentPageIndex
    if (previousPageIndex === currentPageIndex) return;

    // There is no transition required on the first page load. Set the first visible page to simply "visible"
    const firstPageLoad = previousPageIndex === undefined;
    const transitionDirection = (currentPageIndex < (previousPageIndex ?? -1)) ? TRANSITION_DIRECTION.RIGHT : TRANSITION_DIRECTION.LEFT;

    // Update the currentPageIndex to transitioning "IN"
    pageTransitionStates.current[currentPageIndex] = {
      ...pageTransitionStates.current[currentPageIndex],
      state: firstPageLoad ? TRANSITION_STATE.VISIBLE : TRANSITION_STATE.IN,
      direction: firstPageLoad ? null : transitionDirection,
      timeout: firstPageLoad ? null : createEndTransitionTimeout(currentPageIndex, TRANSITION_STATE.VISIBLE),
    };

    // Update the previousPageIndex to transitioning "OUT"
    if (previousPageIndex !== undefined) {
      pageTransitionStates.current[previousPageIndex] = {
        ...pageTransitionStates.current[previousPageIndex],
        state: TRANSITION_STATE.OUT,
        direction: transitionDirection,
        timeout: createEndTransitionTimeout(previousPageIndex, TRANSITION_STATE.HIDDEN),
      };
    }

    // Updating the transitions states ref will not trigger a page render. Force a render.
    forceUpdate();
  }, [currentPageIndex, previousPageIndex, createEndTransitionTimeout, forceUpdate]);


  /**
   * Fired when the pages array is updated
   */
  useEffect(() => {
    // TODO: Update the page transition states to handle page definition changes
  }, [pageCount]);


  /**
   * Fired when the component mounts
   */
  useEffect(() => {
    // Evaluate and keep track of the scrollbar width
    const { width } = getScrollbarSize();
    setScrollbarWidth(width);

    // Initialise the Page Data Errors which are potentially undefined on wizard load and when the page count changes
    const newDataErrors = wizardState.pageDataErrors ?? [];
    for (let i = 0; i < pageCount; i += 1) {
      newDataErrors[i] = newDataErrors[i] ?? null;
    }
    if (!deepCompare(wizardState.pageDataErrors, newDataErrors)) {
      setWizardState({
        pageDataErrors: newDataErrors,
      });
    }
  }, [pageCount, setWizardState, wizardState.pageDataErrors]);


  // Use some logic to determine the page render order
  const renderPages: React.ReactElement[] = [];
  // Pages that are transitioning "OUT" need to be rendered behind pages that are transitioning "IN"
  pageComponents.forEach((PageComponent, pageIndex) => {
    if (pageTransitionStates.current[pageIndex].state === TRANSITION_STATE.OUT) {
      renderPages.push(
        <PageComponent
          // eslint-disable-next-line react/no-array-index-key
          key={pageIndex}
          modalId={modalId}
          showModal={showModal}
          closeModal={closeModal}
          onModalComplete={onModalComplete}
          modalRef={modalRef}
          wizardState={wizardState}
          pageIndex={pageIndex}
          pageData={wizardState.pageData[pageIndex]}
          pageDataErrors={wizardState.pageDataErrors ? wizardState.pageDataErrors[pageIndex] : null}
          transitionState={pageTransitionStates.current[pageIndex].state}
          transitionDirection={pageTransitionStates.current[pageIndex].direction}
          onWizardContentHeightChanged={handleWizardContentHeightChanged}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onPageDataFieldChanged={(field) => handlePageDataFieldChange(
            pageIndex, field,
          )}
        />,
      );
    }
  });

  // Pages that are transitioning "IN" need to be rendered in front of pages that are transitioning "IN"
  pageComponents.forEach((PageComponent, pageIndex) => {
    if (pageTransitionStates.current[pageIndex].state === TRANSITION_STATE.IN) {
      renderPages.push(
        <PageComponent
          // eslint-disable-next-line react/no-array-index-key
          key={pageIndex}
          modalId={modalId}
          showModal={showModal}
          closeModal={closeModal}
          onModalComplete={onModalComplete}
          modalRef={modalRef}
          wizardState={wizardState}
          pageIndex={pageIndex}
          pageData={wizardState.pageData[pageIndex]}
          pageDataErrors={wizardState.pageDataErrors ? wizardState.pageDataErrors[pageIndex] : null}
          transitionState={pageTransitionStates.current[pageIndex].state}
          transitionDirection={pageTransitionStates.current[pageIndex].direction}
          onWizardContentHeightChanged={handleWizardContentHeightChanged}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onPageDataFieldChanged={(field) => handlePageDataFieldChange(
            pageIndex, field,
          )}
        />,
      );
    }
  });

  // Pages that are "VISIBLE" need to be rendered at the very front
  pageComponents.forEach((PageComponent, pageIndex) => {
    if (pageTransitionStates.current[pageIndex].state === TRANSITION_STATE.VISIBLE) {
      renderPages.push(
        <PageComponent
          // eslint-disable-next-line react/no-array-index-key
          key={pageIndex}
          modalId={modalId}
          showModal={showModal}
          closeModal={closeModal}
          onModalComplete={onModalComplete}
          modalRef={modalRef}
          wizardState={wizardState}
          pageIndex={pageIndex}
          pageData={wizardState.pageData[pageIndex]}
          pageDataErrors={wizardState.pageDataErrors ? wizardState.pageDataErrors[pageIndex] : null}
          transitionState={pageTransitionStates.current[pageIndex].state}
          transitionDirection={pageTransitionStates.current[pageIndex].direction}
          onWizardContentHeightChanged={handleWizardContentHeightChanged}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onPageDataFieldChanged={(field) => handlePageDataFieldChange(
            pageIndex, field,
          )}
        />,
      );
    }
  });


  const transitioning = pageTransitionStates.current
    .filter((pageTransitionState) => ([TRANSITION_STATE.IN, TRANSITION_STATE.OUT] as A_TRANSITION_STATE[]).includes(pageTransitionState.state))
    .length > 0;

  /**
   * Render
   */
  return (
    // Render out a modal as per usual
    <Modal
      {...props}
      ref={modalRef}
      className={classNames(className, 'wizard')}
    >
      {/*
        The resize detector is used here to determine the width of the modal.
        This width is passed down to the wizard page, which ensures its maximum width
        excludes any potential scroll bar that may appear.
        When the scroll bar is not taken into account, it can flicker on and off during
        page transitions.
      */}
      <ReactResizeDetector>
        {({ width }: {width: number}) => (
          <div className="modal-wizard">
            <>
              {/* apply the maxPageContentHeight to the height of the page container as an inline style */}
              <form
                className={classNames(
                  'modal-wizard-page-wrapper',
                  {
                    transitioning,
                  },
                )}
                style={{
                  '--content-height': `${getPageWrapperHeight()}px`,
                  '--content-width': `${width}px`,
                  '--scroll-bar-width': `${scrollBarWidth}px`,
                } as React.CSSProperties}
              >
                {renderPages}
              </form>
              <div className="modal-wizard-button-row">
                <ModalWizardPageIndicator currentPageIndex={currentPageIndex} totalPages={pageCount} />

                {/* Left buttons */}
                <div className="row-buttons left">
                  {/* Default Prev Button */}
                  {checkButtonVisible(MODAL_WIZARD_BUTTON_TYPE.PREV, currentPageIndex) && (
                    <Button
                      color={THEME_COLOR.PRIMARY}
                      disabled={!checkButtonEnabled(MODAL_WIZARD_BUTTON_TYPE.PREV, currentPageIndex)}
                      onClick={(e) => handleButtonClick(e, MODAL_WIZARD_BUTTON_TYPE.PREV, currentPageIndex)}
                      onKeyDown={(e) => handleButtonKeyDown(e, MODAL_WIZARD_BUTTON_TYPE.PREV, currentPageIndex)}
                    >
                      <Icon i="arrow-left" />
                      <span>Previous</span>
                    </Button>
                  )}

                  {/* Default Cancel Button */}
                  {checkButtonVisible(MODAL_WIZARD_BUTTON_TYPE.CANCEL, currentPageIndex) && (
                    <Button
                      color={THEME_COLOR.GRAY}
                      disabled={!checkButtonEnabled(MODAL_WIZARD_BUTTON_TYPE.CANCEL, currentPageIndex)}
                      onClick={(e) => handleButtonClick(e, MODAL_WIZARD_BUTTON_TYPE.CANCEL, currentPageIndex)}
                      onKeyDown={(e) => handleButtonKeyDown(e, MODAL_WIZARD_BUTTON_TYPE.CANCEL, currentPageIndex)}
                    >
                      <Icon i="times" />
                      <span>Cancel</span>
                    </Button>
                  )}

                  {/* Note: at some point provision for custom "left" buttons to be rendered here */}
                </div>

                {/* Right buttons */}
                <div className="row-buttons right">
                  {/* Note: at some point provision for custom "right" buttons to be rendered here */}

                  {/* Default Next Button */}
                  {checkButtonVisible(MODAL_WIZARD_BUTTON_TYPE.NEXT, currentPageIndex) && (
                    <Button
                      color={THEME_COLOR.PRIMARY}
                      disabled={!checkButtonEnabled(MODAL_WIZARD_BUTTON_TYPE.NEXT, currentPageIndex)}
                      onClick={(e) => handleButtonClick(e, MODAL_WIZARD_BUTTON_TYPE.NEXT, currentPageIndex)}
                      onKeyDown={(e) => handleButtonKeyDown(e, MODAL_WIZARD_BUTTON_TYPE.NEXT, currentPageIndex)}
                    >
                      <span>Next</span>
                      <Icon i="arrow-right" />
                    </Button>
                  )}

                  {/* Default Submit Button */}
                  {checkButtonVisible(MODAL_WIZARD_BUTTON_TYPE.SUBMIT, currentPageIndex) && (
                    <Button
                      color={THEME_COLOR.SUCCESS}
                      disabled={!checkButtonEnabled(MODAL_WIZARD_BUTTON_TYPE.SUBMIT, currentPageIndex)}
                      onClick={(e) => handleButtonClick(e, MODAL_WIZARD_BUTTON_TYPE.SUBMIT, currentPageIndex)}
                      onKeyDown={(e) => handleButtonKeyDown(e, MODAL_WIZARD_BUTTON_TYPE.SUBMIT, currentPageIndex)}
                    >
                      <span>Submit</span>
                      <Icon i="check" />
                    </Button>
                  )}
                </div>
              </div>
            </>
          </div>
        )}
      </ReactResizeDetector>
    </Modal>
  );
};
