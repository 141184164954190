import { PROJECT_STATUS } from '../constants/project-status.const';

export const PROJECT_STATUS_COLOR_MAP = {
  // $green
  [PROJECT_STATUS.LEAD]: '#36bea6',
  // $blue
  [PROJECT_STATUS.PROPOSAL]: '#0091e6',
  // $red
  [PROJECT_STATUS.LOST]: '#c62f3e',
  // $primary
  [PROJECT_STATUS.ACTIVE]: '#0078ad',
  // $green
  [PROJECT_STATUS.COMPLETED]: '#00ad35',
  // $gray-100
  [PROJECT_STATUS.ARCHIVED]: '#f8f9fa',
};
