export const SERVICE_JOB_BILLING_TYPE: Record<string, number> = {
  DIRECT: 1,
  CONTRACT: 2,
  PROJECT: 3,
  REMEDIATION: 4,
  PROMOTION: 5,
  INTERNAL: 6,
} as const;
export type SERVICE_JOB_BILLING_TYPE = typeof SERVICE_JOB_BILLING_TYPE;
export type AN_SERVICE_JOB_BILLING_TYPE = SERVICE_JOB_BILLING_TYPE[keyof SERVICE_JOB_BILLING_TYPE];

export const SERVICE_JOB_BILLING_TYPE_NAMES: {[K in AN_SERVICE_JOB_BILLING_TYPE]: string } = {
  [SERVICE_JOB_BILLING_TYPE.DIRECT]: 'Client Direct',
  [SERVICE_JOB_BILLING_TYPE.CONTRACT]: 'Bill to Contract',
  [SERVICE_JOB_BILLING_TYPE.PROJECT]: 'Bill to Project',
  [SERVICE_JOB_BILLING_TYPE.REMEDIATION]: 'Remediation',
  [SERVICE_JOB_BILLING_TYPE.PROMOTION]: 'Ci Promotion',
  [SERVICE_JOB_BILLING_TYPE.INTERNAL]: 'Ci Internal',
} as const;

// 1    Service Call            A callout job for an incident           SC
// 2    Service Follow-Up       A follow up visit for a Service Call    SF
// 3    Scheduled Maintenance   A Scheduled Maintenance Visit           SM
// 4    Service Request         Changes, Moves, Enhancements            SR
