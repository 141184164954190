/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { ConfirmActionModalResult } from '../../../../types/modal/modal-result';
import { IActivateProjectModalWizardPage4Data, IActivateProjectModalWizardState } from '../activate-project-data.interface';

import { ModalWizardPage, ModalWizardPageProps } from '../../modal-wizard-page';
import Icon from '../../../layout-helpers/icon';
import { ICON } from '../../../../constants/icon.const';
import { WizardFormErrorRow } from '../../../layout-helpers/wizard-form-error-row';

const wikiUrl = 'https://wiki.ciportal.net/books/ci-portal-documentation/page/order-confirmation-process-online#bkmrk-internal-notificatio';

export const ActivateProjectModalPage5 = (props: ModalWizardPageProps<
  IActivateProjectModalWizardPage4Data,
  IActivateProjectModalWizardState,
  ConfirmActionModalResult
>): React.ReactElement => {
  const { pageData, wizardState: { projectRecord }, pageDataErrors, onPageDataFieldChanged } = props;

  return (
    <ModalWizardPage<IActivateProjectModalWizardPage4Data, IActivateProjectModalWizardState, ConfirmActionModalResult> {...props}>
      <div className="wizard-page-section">
        <div className="wizard-page-section-heading">
          <span>Step 5: Send Notifications</span>
        </div>
        <div className="wizard-page-section-content">
          <p>
            Notifications are always sent automatically to Sales Management, Supplier Management, State Managers,
            and all team members on the Project Resources tab.
          </p>
          <p>
            <a href={wikiUrl} target="_blank" rel="noopener noreferrer">
              <span>You can view the full list of recipients in the Ci Wiki via this link</span>
              <Icon i={ICON.EXTERNAL_LINK} />
            </a>
          </p>
          <p>
            You can add or verify Contacts and Resources now, and then return to this page to continue.
          </p>
          <p>
            <a href={`/crm/projects/${projectRecord?.id}/resources`} target="_blank" rel="noopener noreferrer">
              <span>Click here to edit Project Resources in a new browser tab</span>
            </a>
          </p>
        </div>
        <div className="wizard-page-section-heading">
          <span>Include a Note:</span>
        </div>
        <div className="wizard-page-section-content">
          <p>
            Add a note here to be sent out with the activation - this will appear both on the activation email, and in the notes section against the project.
          </p>
          <label htmlFor="project_activation_team_note">Activation Note:</label>
          <textarea
            className="form-control"
            placeholder="Add your note here if required"
            id="project_activation_team_note"
            name="project_activation_team_note"
            value={pageData.project_activation_team_note}
            onChange={(e) => {
              if (typeof onPageDataFieldChanged === 'function') {
                onPageDataFieldChanged({
                  fieldName: 'project_activation_team_note',
                  newValue: e.target.value,
                });
              }
            }}
          />
          <WizardFormErrorRow fieldName="project_activation_team_note" pageDataErrors={pageDataErrors} />
        </div>
      </div>
    </ModalWizardPage>
  );
};
