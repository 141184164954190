/* eslint-disable no-multi-spaces */
/**
 * The tree line types represent the different tree line prefixes for each item rendered in the
 * portal tree view. These are calculated when the data is assigned to the tree to speed up rendering
 *
 * The CSS simply evaluates the kind of tree line type and renders the appropriate cross, extension
 * or end line for each of its parent tiers.
 */
export const PORTAL_TREE_VIEW_TREE_LINE_TYPE = {
  NONE: 0,        // " "
  CROSS: 1,       // "├"
  EXTEND: 2,      // "│"
  END: 3,         // "└"
} as const;

export type PORTAL_TREE_VIEW_TREE_LINE_TYPE = typeof PORTAL_TREE_VIEW_TREE_LINE_TYPE;
export type A_PORTAL_TREE_VIEW_TREE_LINE_TYPE = PORTAL_TREE_VIEW_TREE_LINE_TYPE[keyof PORTAL_TREE_VIEW_TREE_LINE_TYPE];

export const PortalTreeViewTreeLineTypeClassMap: Record<A_PORTAL_TREE_VIEW_TREE_LINE_TYPE, string> = {
  [PORTAL_TREE_VIEW_TREE_LINE_TYPE.NONE]: 'no',
  [PORTAL_TREE_VIEW_TREE_LINE_TYPE.CROSS]: 'crs',
  [PORTAL_TREE_VIEW_TREE_LINE_TYPE.EXTEND]: 'ext',
  [PORTAL_TREE_VIEW_TREE_LINE_TYPE.END]: 'end',
};
