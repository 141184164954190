/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Button, Table } from 'reactstrap';

import { ConfirmActionModalResult } from '../../../../types/modal/modal-result';
import { IUploadMaintenanceChecksModalWizardPage3Data, IUploadMaintenanceChecksModalWizardState, IUploadValidationResponse } from '../upload-maintenance-checks-data.interface';

import { ModalWizardPage, ModalWizardPageProps } from '../../modal-wizard-page';
import { ModalWizardItemProgress } from '../../modal-wizard-item-progress';
import { IContractRecord } from '../../../../types/service/contract.record.interface';


export const UploadMaintenanceChecksModalPage3 = (props: ModalWizardPageProps<
  IUploadMaintenanceChecksModalWizardPage3Data,
  IUploadMaintenanceChecksModalWizardState,
  ConfirmActionModalResult
>): React.ReactElement => {
  const { wizardState, onModalComplete, closeModal, modalId } = props;
  const {
    processing,
    success,
    importMaintenanceChecksResult,
  } = wizardState;

  return (
    <ModalWizardPage<IUploadMaintenanceChecksModalWizardPage3Data, IUploadMaintenanceChecksModalWizardState, ConfirmActionModalResult> {...props}>
      <div className="wizard-page-section pb-4">
        <div className="wizard-page-section-heading">
          {processing && (
            <span>Processing... Please wait...</span>
          )}
          {!processing && success && (
            <span>Success!</span>
          )}
          {!processing && !success && (
            <span className="danger">Failed!</span>
          )}
        </div>
        <div className="wizard-page-section-content">
          <div className="wizard-item-progress-wrapper">

            {/* Upload Maintenance Checks */}
            <ModalWizardItemProgress<IContractRecord>
              label="Upload Maintenance Checks"
              result={importMaintenanceChecksResult}
              aborted={!importMaintenanceChecksResult && !processing && !success}
            />

          </div>
        </div>
      </div>

      {importMaintenanceChecksResult && (
        <div className="wizard-page-section">
          <div className="wizard-page-section-heading">
            The following Rows were imported.
          </div>
          <div className="wizard-page-section-content">
            {importMaintenanceChecksResult.result && (
              <Table>
                <thead>
                  <tr>
                    <th>id</th>
                    <th>Before</th>
                    <th>Booked</th>
                    <th>Done</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  {importMaintenanceChecksResult.result.map((row: IUploadValidationResponse) => {
                    if (row.error) {
                      return (
                        <tr key={`row-${row.id}`}>
                          <td>{row.id}</td>
                          <td colSpan={5} className="text-danger">{row.error}</td>
                        </tr>
                      );
                    }
                    return (
                      <tr key={`row-${row.id}`}>
                        <td>{row.id}</td>
                        <td>{row.perform_before}</td>
                        <td>{row.booked_on}</td>
                        <td>{row.performed_on}</td>
                        <td>{row.notes}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      )}

      {/* When the processing has completed, show the close button */}
      {!processing && !!success && (
        <div className="wizard-page-section-content">
          <Button
            color="primary"
            onClick={() => {
              closeModal(modalId, false, () => onModalComplete({ processAction: false }));
            }}
          >
            Close
          </Button>
        </div>
      )}
      {/*
        <pre>{JSON.stringify(importMaintenanceChecksResult, null, 2)}</pre> */}

    </ModalWizardPage>
  );
};
