import React from 'react';
import { Link } from 'react-router-dom';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { ICON } from '../../../constants/icon.const';
import { IExpenseClaimFileRecord } from '../../../types/expense-claim/expense-claim-file.record.interface';
import { IExpenseClaimItemRecord } from '../../../types/expense-claim/expense-claim-item.record.interface';
import Icon from '../../layout-helpers/icon';
import { formatValueString } from '../../render-functions';

const myobCompanyId = process.env.REACT_APP_MYOB_COMPANY_ID;
const myobPRojectPath = `https://ci.myobadvanced.com/Main?CompanyID=${myobCompanyId}&ScreenId=PM301000&BaseType=P&ContractCD=`;

export interface IClaimItemsSummaryProps {
  item: IExpenseClaimItemRecord,
  handleCollectionItemClick: (claimId: number, itemData: IExpenseClaimFileRecord) => void,
}

export const ClaimItemSummary: React.FC<IClaimItemsSummaryProps> = ({ item, handleCollectionItemClick }) => (
  <tr key={`${item.expense_claim_id}-${item.id}`}>
    <td><Link to={`/finance/process-expenses/claims/${item.expense_claim_id}`}>{item.expense_claim_id}</Link></td>
    {/* <td><Link to={`/finance/process-expenses/claims/${item.expense_claim_id}`}>{claim.name}</Link></td> */}
    <td>{item.name}</td>
    <td>{item.description}</td>
    <td className="text-right">{formatValueString(item.amount, COLUMN_FORMAT.CURRENCY)}</td>
    <td>
      {item.receipt_file ? (
        <a
          href={item.receipt_file.filename}
          onClick={(e) => {
            e.preventDefault(); handleCollectionItemClick(item.expense_claim_id, item.receipt_file);
          }}
          title={item.receipt_file.filename}
        >
          <Icon i={ICON.FILE} />
          {' '}
          View
        </a>
      ) : '-'}
    </td>
    <td>{item.category?.name || (<span className="text-danger">NO ACCOUNT SET</span>)}</td>
    <td>
      {item.project?.project_number ? (
        <Link to={`/crm/projects/${item.project?.id}`}>{item.project?.project_number}</Link>
      ) : (
        <span> - </span>
      )}
      {' '}
      {item.service_job?.number ? (
        <Link to={`/service-and-support/service-jobs/${item.service_job?.id}`}>{item.service_job?.number}</Link>
      ) : (
        <span> - </span>
      )}
      {' '}
      {item.contract?.number ? (
        <Link to={`/service-and-support/contracts/${item.contract?.id}`}>{item.contract?.number}</Link>
      ) : (
        <span> - </span>
      )}
      {' | '}
      {item.project?.myob_project_id ? (
        <a href={`${myobPRojectPath}${item.project?.myob_project_id}`} target="_blank" rel="noreferrer">{item.project?.myob_project_id}</a>
      ) : (
        <span> - </span>
      )}
      {' '}
      {item.service_job?.myob_id ? (
        <a href={`${myobPRojectPath}${item.service_job?.myob_id}`} target="_blank" rel="noreferrer">{item.service_job?.myob_id}</a>
      ) : (
        <span> - </span>
      )}
      {' '}
      {item.contract?.myob_id ? (
        <a href={`${myobPRojectPath}${item.contract?.myob_id}`} target="_blank" rel="noreferrer">{item.contract?.myob_id}</a>
      ) : (
        <span> - </span>
      )}
    </td>
    {/* <td className="text-right">{formatValueString(claim.amount, COLUMN_FORMAT.CURRENCY)}</td> */}
  </tr>
);
