import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import UserMenu from './user-menu';
import logo from '../../images/ci-logo-trans.svg';
import Icon from '../layout-helpers/icon';
import { BUILD_VER, ENVIRONMENT, TOP_BAR_END_COLOR, TOP_BAR_START_COLOR } from '../../utils/constants';

export type AppHeaderProps = {
  isSidebarVisible?: boolean,
  isMobileSidebarVisible?: boolean,

  setShowSidebar?: (show?: boolean | undefined) => void,
  setShowMobileSidebar?: (show?: boolean | undefined) => void,
}

/**
 * <AppHeader />
 */
export const AppHeader: React.FC<AppHeaderProps> = (props) => {
  const {
    isSidebarVisible = false,
    isMobileSidebarVisible = false,

    setShowSidebar,
    setShowMobileSidebar,
  } = props;

  return (
    <header
      className="topbar"
      style={{
        '--topbar-start-color': TOP_BAR_START_COLOR,
        '--topbar-end-color': TOP_BAR_END_COLOR,
      } as React.CSSProperties}
    >
      <div className="left-container">
        {/* Desktop Button */}
        <Button
          className="nav-clickable toggle-visible desktop"
          onClick={() => {
            if (setShowSidebar) {
              setShowSidebar(!isSidebarVisible);
            }
          }}
        >
          <Icon i="ti-menu" />
        </Button>

        {/* Mobile Button */}
        <Button
          className="nav-clickable toggle-visible mobile"
          onClick={() => {
            if (setShowMobileSidebar) {
              setShowMobileSidebar(!isMobileSidebarVisible);
            }
          }}
        >
          <Icon i="ti-menu" />
        </Button>
        <div className="version">
          {BUILD_VER}
          {ENVIRONMENT !== 'production' ? `-${ENVIRONMENT}` : ''}
        </div>
      </div>
      {/* TODO: React UI Ticket #490 Temporarily hide the global search until it does something */}
      {/* <GlobalSearch /> */}
      <div className="center-container">
        <Link
          className="logo"
          to="/"
        >
          <img alt="Corporate Initiatives" src={logo} height="40" />
        </Link>
      </div>
      <div className="right-container">
        <UserMenu />
      </div>
    </header>
  );
};
