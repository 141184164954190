import React, { PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { ModalContext } from '../modals/modal-context';
import { CurrentUserContext } from '../providers/current-user-provider';

import { useMountEffect } from '../../react-hooks/use-mount-effect.hook';

import { APIRecord } from '../../types/api-record.interface';
import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { FormRendererProps } from '../../types/poly-form/form-renderer.props';
import { PolyFormProps } from '../../types/poly-form/poly-form.props';
import { ConfirmModalResult } from '../../types/modal/modal-result';

import { useHistory } from '../router/history';
import RedirectInstruction, { REDIRECT_INSTRUCTION_TYPE } from '../../helpers/redirect-instruction';

import { clearSelection } from '../../helpers/clear-selection.helper';
import { findFocusableInput } from '../../helpers/find-focusable-input.helper';
import { makeFormRendererFields } from './make-form-renderer-fields.helper';

import { DELETE_CONFIRMATION_TYPE } from '../../constants/delete-confirmation-type.const';
import { FORM_RENDERER_TYPE } from '../../constants/form-renderer-type.const';
import { FormRendererTypeComponentMap } from '../../constants/form-renderer-type-component-map.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { BUTTON_COLOR } from '../../constants/button-color.const';
import { ICON } from '../../constants/icon.const';

const FIELD_FOCUS_TIMEOUT = 10;


/**
 * The PolyForm uses a FormRenderers to render and receive input using
 * various form types and display formats
 *
 * It represents 1 record or row in a dataset
 */
export const PolyForm = <T extends APIRecord = APIRecord, >(props: PropsWithChildren<PolyFormProps<T>>): React.ReactElement<PropsWithChildren<PolyFormProps<T>>> => {
  const {
    children,

    formRendererType = FORM_RENDERER_TYPE.VERTICAL,

    parentId,
    parentData,
    baseRoute,
    apiRoute,
    apiQuery,

    formCaption = 'Details',
    itemCaption = 'item',
    confirmCancel = true,
    formDeleteConfirmationType = DELETE_CONFIRMATION_TYPE.DELETE,
    navigateBackOnDelete = false,

    rendererOptions,
    showHeader = false,
    scrollToError = true,
    statusMap,
    hideBottomButtons,

    primaryKeyFieldName = 'id',
    initialFormFocusFieldName,
    fields = [],
    formData = {} as Partial<T>,
    isReadOnly = false,
    isNewRecord = false,
    isEditing = true,

    isBusy = false,
    isDirty,

    hasErrors,
    hasSuccess,
    formMessage,
    errors,

    permittedActions = [],

    updateRecord,

    onCanStartEditRecord,
    onStartEditRecord,
    onCanEndEditRecord,
    onEndEditRecord,
    onCanDeleteRecord,
    onDeleteRecord,

    onConfirmDeleteRecord,
    onFieldChange,

    onClick,
  } = props;

  const { userHasPermissions } = useContext(CurrentUserContext);
  const { showModal } = useContext(ModalContext);

  const primaryKeyValue = primaryKeyFieldName ? formData[primaryKeyFieldName] : 'new';

  const [isLocked, setIsLocked] = useState<FormRendererProps['isLocked']>(false);
  const [showFieldInfo, setShowFieldInfo] = useState<FormRendererProps['showFieldInfo']>(false);

  const [internalFields, setInternalFields] = useState<FormRendererProps['fields']>(
    makeFormRendererFields(primaryKeyValue, fields, userHasPermissions),
  );

  const fieldFocusTimeout = useRef<null | ReturnType<typeof setTimeout>>(null);

  /**
   * Focus a form field
   *
   * @param focusFieldName the name of the field to focus. Must be present in the fields list
   */
  const focusFormField = useCallback((focusFieldName?: string): void => {
    // Specifically look for the child field the form wants to focus
    // We can only focus fields that are shown in the form and have an Id
    let focusField = internalFields.find((field) => (field.name === focusFieldName) && (field.editable !== false));

    // If there's no focus field yet, look for the initialFormFocusFieldName field
    if (!focusField && initialFormFocusFieldName) {
      focusField = internalFields.find((field) => (field.name === initialFormFocusFieldName) && (field.editable !== false));
    }

    // If there's no focus field yet, look for the first editable / focusable field
    if (!focusField) {
      focusField = internalFields.find((field) => (field.editable !== false));
    }

    // If we found a focus field, set a timeout to focus input
    if (focusField) {
      // Clearing all selections on the page prevents the "double-clicked" text from remaining selected
      clearSelection();

      // Set a timeout and return the result in case we need to keep track of the timeout to clear it
      setTimeout(() => {
        if (focusField) {
          // Look for the first appropriate element to focus
          const targetElement = document.getElementById(focusField.id);
          if (targetElement) {
            const focusableElement: null | HTMLElement = findFocusableInput(targetElement);

            // Focus the element and select the contents
            if (focusableElement) {
              focusableElement.focus();
              (focusableElement as HTMLFormElement).select();
            }
          }
        }
      }, FIELD_FOCUS_TIMEOUT);
    }
  }, [initialFormFocusFieldName, internalFields]);


  /**
   * Check if the Start Edit is allowed
   *
   * @param id the primary key value for the current record
   * @param currentFormData the current form data
   *
   * @returns true if the form can begin editing
   */
  const canStartEditRecord = useCallback((
    id: FormFieldComponentProps['value'],
    currentFormData: Partial<T>,
  ): boolean => {
    // Prevent editing if the form is isReadOnly
    if (isReadOnly) return false;

    // Check with the parent that it's ok to begin editing
    if (onCanStartEditRecord) {
      return onCanStartEditRecord(id, currentFormData);
    }

    return true;
  }, [isReadOnly, onCanStartEditRecord]);


  /**
   * Enter edit mode (Still dependant on the renderer setting `isEditing` to true)
   *
   * @param id the primary key value for the current record
   * @param currentFormData the current form data
   * @param focusFieldName the field name to focus after editing begins
   *
   * @returns true if the form can begin editing
   */
  const handleStartEditRecord = useCallback((
    id: FormFieldComponentProps['value'],
    currentFormData: Partial<T>,
    focusFieldName?: FormFieldComponentProps['name'],
  ): void => {
    if (canStartEditRecord(id, currentFormData)) {
      // Notify the parent that editing has started
      if (onStartEditRecord) {
        onStartEditRecord(id, currentFormData, focusFieldName);
      }

      // Focus the source field name (if required)
      focusFormField(focusFieldName);
    }
  }, [canStartEditRecord, focusFormField, onStartEditRecord]);


  /**
   * Check if the End Edit is allowed
   *
   * @param saveChanges Whether the end edit is a save or a cancel
   * @param id the primary key value for the current record
   * @param currentFormData the current form data
   */
  const canEndEditRecord = useCallback((
    saveChanges: boolean,
    id: FormFieldComponentProps['value'],
    currentFormData?: Partial<T>,
    forceSave?: boolean,
  ): boolean => {
    // Can't end an edit that isn't happening
    if (!isEditing && !forceSave) return false;

    let allowed = true;

    // Cancelling changes?
    if (allowed && !saveChanges) {
      allowed = (
        !isDirty ||
        !confirmCancel ||
        // eslint-disable-next-line no-alert
        (isDirty && window.confirm('Discard your unsaved changes?'))
      );
    }

    // Pass the confirmation check to the parent
    if (allowed && onCanEndEditRecord) {
      allowed = allowed && onCanEndEditRecord(saveChanges, id, currentFormData, forceSave);
    }

    return allowed;
  }, [isEditing, onCanEndEditRecord, isDirty, confirmCancel]);


  /**
   * End editing the form data (Still dependant on the renderer setting `isEditing` to false)
   *
   * @param saveChanges Whether the end edit is a save or a cancel
   * @param id the primary key value for the current record
   * @param currentFormData the current form data
   */
  const handleEndEditRecord = useCallback((
    saveChanges: boolean,
    id: FormFieldComponentProps['value'],
    currentFormData?: Partial<T>,
    forceSave?: boolean,
  ): void => {
    // Check if ending an edit is allowed
    if (canEndEditRecord(saveChanges, id, currentFormData, forceSave)) {
      // Let the parent know tha the edit has finished
      if (onEndEditRecord) {
        onEndEditRecord(saveChanges, id, currentFormData, forceSave);
      }
    }
  }, [canEndEditRecord, onEndEditRecord]);


  /**
   * This will display a standard confirmation dialog OR call the onConfirmDeleteRecord (if provided)
   *
   * @param id the primary key value for the current record
   * @param currentFormData the current form data
   *
   * @returns a boolean async/promise
   */
  const confirmDeleteRecord = useCallback(async (
    id: FormFieldComponentProps['value'],
    currentFormData: Partial<T>,
  ): Promise<boolean> => new Promise((resolve) => {
    // Let the parent's confirmation method decide
    if (onConfirmDeleteRecord) {
      onConfirmDeleteRecord(id, currentFormData).then(resolve);
    }

    // Display the default confirmation dialog
    else {
      showModal<ConfirmModalResult>(MODAL_TYPE.CONFIRM, {
        title: `${formDeleteConfirmationType === DELETE_CONFIRMATION_TYPE.REMOVE ? 'Remove' : 'Delete'} this ${itemCaption}?`,
        confirmButtonLabel: formDeleteConfirmationType === DELETE_CONFIRMATION_TYPE.REMOVE ? 'Remove' : 'Delete',
        confirmButtonIcon: formDeleteConfirmationType === DELETE_CONFIRMATION_TYPE.REMOVE ? ICON.UNLINK : ICON.DELETE,
        confirmButtonColor: BUTTON_COLOR.DANGER,
        content: `Are you sure you want to ${formDeleteConfirmationType === DELETE_CONFIRMATION_TYPE.REMOVE ? 'remove' : 'permanently delete'} this ${itemCaption}?`,
        onModalComplete: ({ success }) => resolve(success),
      });
    }
  }), [formDeleteConfirmationType, itemCaption, onConfirmDeleteRecord, showModal]);


  /**
   * Check whether the record can be deleted
   *
   * @param id the primary key value for the current record
   * @param currentFormData the current form data
   */
  const canDeleteRecord = useCallback((
    id: FormFieldComponentProps['value'],
    currentFormData: Partial<T>,
  ): boolean => {
    // Don't allow the record to be deleted if it is read only
    if (isReadOnly) return false;

    // Check with the parent
    if (onCanDeleteRecord) {
      return onCanDeleteRecord(id, currentFormData);
    }

    return true;
  }, [isReadOnly, onCanDeleteRecord]);


  /**
   * Delete the current record (Still dependant on the renderer reacting to the delete)
   *
   * @param id the primary key value for the current record
   * @param currentFormData the current form data
   * @param suppressConfirmation prevent the confirmation from checking with the user about deleting the record
   * @param redirectInstruction information from the formRenderer about what to do when the record is deleted
   */
  const handleDeleteRecord = useCallback(async (
    id: FormFieldComponentProps['value'],
    currentFormData: Partial<T>,
    suppressConfirmation?: boolean,
    redirectInstruction?: RedirectInstruction,
  ) => {
    // Check whether the current record CAN be deleted
    if (canDeleteRecord(id, currentFormData)) {
      let allowDelete = true;

      // Confirm with the user if they really want to delete the record
      if (!suppressConfirmation) {
        allowDelete = await confirmDeleteRecord(id, currentFormData);
      }

      // Let the parent know the record wants to be deleted
      if (allowDelete && onDeleteRecord) {
        // Create RedirectInstruction to know what redirect action to take when delete is fired
        const defaultRedirectInstruction = new RedirectInstruction(
          navigateBackOnDelete ? REDIRECT_INSTRUCTION_TYPE.REDIRECT : REDIRECT_INSTRUCTION_TYPE.REFRESH,
          useHistory.previousLocation() || useHistory.homeLocation(),
        );

        onDeleteRecord(id, currentFormData, redirectInstruction ?? defaultRedirectInstruction);
      }
    }
  }, [canDeleteRecord, confirmDeleteRecord, navigateBackOnDelete, onDeleteRecord]);


  /**
   * Fired when a form renderer wants to temporarily lock the form
   */
  const lockForm = (locked: boolean) => {
    setIsLocked(locked);
  };


  /**
   * Fired when a form renderer wants to toggle the display of the form field info
   */
  const toggleShowFieldInfo = useCallback(() => {
    setShowFieldInfo(!showFieldInfo);
  }, [showFieldInfo]);


  /**
   * (onComponentDidMount)
   * Perform any initialisation on the component after mounting
   */
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    /**
     * (onComponentWillUnmount)
     * Perform any unloading actions that will prevent some form of action
     * on this component after it is removed from the dom
     */
    return () => {
      // Remove lingering time outs so they don't fire after component removal
      if (fieldFocusTimeout.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(fieldFocusTimeout.current);
      }
    };
  }, []);


  /**
   * Manage the capture of keypress events on the document when in edit or create mode.
   * Typically used to capture the escape button and if not dirty reset the form.
   */
  useEffect(() => {
    /**
     * Handle the press of a key on the keyboard
     * @param event
     */
    const handleKeyPress = (event: globalThis.KeyboardEvent) => {
      if (event && (event.key?.toLowerCase() === 'escape')) {
        handleEndEditRecord(false, primaryKeyValue, formData);
      }
      else if (event && event.ctrlKey && event.key.toLowerCase() === 'enter') {
        handleEndEditRecord(true, primaryKeyValue, formData);
      }
    };

    // Start listening to keyboard events if we are in edit mode
    if (isEditing) {
      document.addEventListener('keydown', handleKeyPress, false);
    }

    // Stop listening to keyboard events
    return () => {
      document.removeEventListener('keydown', handleKeyPress, false);
    };
  }, [formData, handleEndEditRecord, isDirty, isEditing, primaryKeyValue]);


  /**
   * When the field definitions or the primary key value changes,
   * prepare the internal fields
   */
  useEffect(() => {
    setInternalFields(makeFormRendererFields(primaryKeyValue, fields, userHasPermissions));
  }, [fields, primaryKeyValue, userHasPermissions]);


  /**
   * If the form is mounted as `isEditing = true` then we need to focus the `initialFormFocusFieldName`
   */
  useMountEffect(() => {
    if (isEditing) {
      focusFormField();
    }
  });


  /**
   * Render
   * Implements VerticalFormRenderer as the default form fields if no custom type is passed in.
   */
  const FormRenderer:
    ((x: PropsWithChildren<FormRendererProps<T>>) => React.ReactElement<PropsWithChildren<FormRendererProps<T>>>)
    = FormRendererTypeComponentMap[formRendererType] as never;
  return (
    <FormRenderer
      className={classNames('portal-form', {
        'has-errors': hasErrors,
      })}
      parentId={parentId}
      parentData={parentData}

      baseRoute={baseRoute}
      apiRoute={apiRoute}
      apiQuery={apiQuery}

      formCaption={formCaption}
      showFieldInfo={showFieldInfo}
      itemCaption={itemCaption}
      formDeleteConfirmationType={formDeleteConfirmationType}
      navigateBackOnDelete={navigateBackOnDelete}

      rendererOptions={rendererOptions}
      showHeader={showHeader}
      scrollToError={scrollToError}
      statusMap={statusMap}
      hideBottomButtons={hideBottomButtons}

      primaryKeyFieldName={primaryKeyFieldName}
      primaryKeyValue={primaryKeyValue}
      fields={internalFields}
      formData={formData}
      isReadOnly={isReadOnly}
      isNewRecord={isNewRecord}
      isEditing={isEditing}

      isDirty={isDirty}
      isLocked={isLocked}
      isBusy={isBusy}

      hasErrors={hasErrors}
      hasSuccess={hasSuccess}
      formMessage={formMessage}
      errors={errors}

      permittedActions={permittedActions}

      toggleShowFieldInfo={toggleShowFieldInfo}
      lockForm={lockForm}
      updateRecord={updateRecord}
      startEditRecord={handleStartEditRecord}
      endEditRecord={handleEndEditRecord}
      deleteRecord={handleDeleteRecord}

      onFieldChange={onFieldChange}

      onClick={onClick}
    >
      {children}
    </FormRenderer>
  );
};
