export const LEAVE_APPLICATION_STATUS = {
  UNSUBMITTED: 1,
  SUBMITTED: 2,
  APPROVED: 3,
  REJECTED: 4,
  REVOKED: 5,
  CONFIRMED: 6,
  CANCELLED: 7,
} as const;
export type LEAVE_APPLICATION_STATUS = typeof LEAVE_APPLICATION_STATUS;
export type A_LEAVE_APPLICATION_STATUS = LEAVE_APPLICATION_STATUS[keyof LEAVE_APPLICATION_STATUS];

export const LEAVE_APPLICATION_STATUS_MAP: {[K in A_LEAVE_APPLICATION_STATUS]: string} = {
  [LEAVE_APPLICATION_STATUS.UNSUBMITTED]: 'Unsubmitted',
  [LEAVE_APPLICATION_STATUS.SUBMITTED]: 'Submitted',
  [LEAVE_APPLICATION_STATUS.APPROVED]: 'Approved',
  [LEAVE_APPLICATION_STATUS.REJECTED]: 'Rejected',
  [LEAVE_APPLICATION_STATUS.REVOKED]: 'Revoked',
  [LEAVE_APPLICATION_STATUS.CONFIRMED]: 'Confirmed',
  [LEAVE_APPLICATION_STATUS.CANCELLED]: 'Cancelled',
};
