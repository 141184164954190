import React from 'react';
import classnames from 'classnames';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';

import { InfoTooltip } from '../info-tooltip';

export type CheckBoxProps = Pick<FormFieldComponentProps,
  'id' |
  'name' |
  'label' |
  'value' |
  'labels' |
  'infoToolTip' |
  'tabIndex' |
  'className' |
  'disabled' |
  'hasError' |
  'inline' |
  'onChange' |
  'onKeyPress'
>;

export const CheckBox: React.FC<CheckBoxProps> = ({
  id,
  name,
  label,
  value,
  labels,
  infoToolTip,
  tabIndex,
  className,
  disabled,
  hasError,
  inline,
  onChange,
  onKeyPress,
}) => (
  <div className={classnames(
    'portal-checkbox',
    className,
    {
      inline,
      'form-control-danger': hasError,
      disabled,
    },
  )}
  >
    <input
      type="checkbox"
      id={id}
      name={name}
      disabled={disabled ?? false}
      checked={value === 1}
      tabIndex={tabIndex}

      onChange={(e) => {
        if (onChange) { onChange({
          fieldName: name,
          newValue: e.target.checked ? 1 : 0,
        }); }
      }}
      onKeyPress={onKeyPress}
    />
    {/* Specific label provided */}
    {label && (
      <label htmlFor={id}>{label}</label>
    )}
    {/* Label values provided */}
    {!label && labels && (value === 0 || value === 1) && (
      <label htmlFor={id}>{labels[value]}</label>
    )}
    {/* default labels */}
    {!label && !labels && (value === 0 || value === 1) && (
      <label htmlFor={id}>{value ? 'Yes' : 'No'}</label>
    )}
    {/* Tooltip */}
    {infoToolTip && (
      <InfoTooltip title={infoToolTip.title}>
        {infoToolTip.body}
      </InfoTooltip>
    )}
  </div>
);
