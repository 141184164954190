import React from 'react';

import { DataTablePageProps } from '../../types/data-table-page.props';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';

export type OrderConfirmationDataTablePageProps = DataTablePageProps;

/**
 * Display the "Order Confirmation" data table as a whole page
 */
export const OrderConfirmationDataTablePage: React.FC<OrderConfirmationDataTablePageProps> = ({
  baseFilters,
  baseFlags,
  match,
  history,
  location,
  availableActions,
  possibleActions,
  children,
}) => (
  <DataTablePage
    title="Order Confirmation"
    tableIdentifier={TABLE_IDENTIFIER.ORDER_CONFIRMATION_TABLE}
    baseFilters={baseFilters}
    baseFlags={baseFlags}
    match={match}
    history={history}
    location={location}
    availableActions={availableActions}
    possibleActions={possibleActions}
  >
    {children}
  </DataTablePage>
);
