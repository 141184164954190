/**
 * Deselect any text that is currently selected in text inputs or text areas
 */
export const clearSelection = (): void => {
  if (window && window.getSelection) {
    window.getSelection()?.removeAllRanges();
  }

  const { activeElement } = document;

  if (activeElement) {
    const tagName = activeElement.nodeName.toLowerCase();
    if (
      // Text Areas
      (tagName === 'textarea') ||
      // Text inputs
      ((tagName === 'input') && (activeElement.attributes.getNamedItem('type')?.value === 'text'))
    ) {
      // Collapse the selection to the end
      (<HTMLInputElement>activeElement).selectionStart = (<HTMLInputElement>activeElement).selectionEnd;
    }
  }
};
