import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import RoomDesignerPage from '../sales-toolkit/room-designer-page';
import LedDesignerPage from '../sales-toolkit/led-designer-page';

const SalesTookitRoutes = () => (
  <Switch>
    <Route
      exact
      path="/sales-toolkit/room-designer"
      render={(props) => <RoomDesignerPage {...props} title="Ci Room Designer" />}
    />
    <Route
      exact
      path="/sales-toolkit/led-designer"
      render={(props) => <LedDesignerPage {...props} title="LED Panel Designer" />}
    />
  </Switch>
);

SalesTookitRoutes.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default SalesTookitRoutes;
