import React from 'react';
import { Card, CardBody, CardHeader, CardText, CardTitle, Container } from 'reactstrap';
import PageHeader from '../app-layout/page-header';
import monkeBino from '../../images/not-found.jpeg';
import ufo from '../../images/ufo-abduction.png';

const versions = [
  {
    heading: 'Sorry...',
    message: 'We searched high and low, but we did not find your page.',
    background: monkeBino,
    headerBackground: '#ffbc34',
    footerBackground: 'rgb(35, 52, 62)',
    width: '1080px',
    height: '460px',
  },
  {
    heading: 'Bleeep Blooop...',
    message: "That page doesn't seem to be here anymore. Where could it have gone!?",
    background: ufo,
    headerBackground: 'rgb(45, 162, 132)',
    footerBackground: 'rgb(35, 52, 62)',
    width: '680px',
    height: '550px',
  },
];

const FourOhFour = () => {
  const randomNumber = Math.floor(Math.random() * 2);
  const versionInfo = versions[randomNumber];
  return (
    <Container fluid>
      <PageHeader tag="h2" title="404 - Not Found" />
      <center>
        <Card
          style={{
            maxWidth: versionInfo.width,
          }}
        >
          <CardHeader style={{ background: versionInfo.headerBackground }} className="text-white">
            404 - Not Found
          </CardHeader>
          <CardBody
            style={{
              backgroundImage: `url(${versionInfo.background})`,
              backgroundSize: 'cover',
              minHeight: versionInfo.height,
              backgroundPosition: 'center top',
            }}
          />
          <CardBody style={{ background: versionInfo.footerBackground }} className="text-white">
            <CardTitle tag="h2">
              {' '}
              {versionInfo.heading}
              {' '}
            </CardTitle>
            <CardText tag="div">
              <h4>{versionInfo.message}</h4>
              <p>
                Please feel free to
                {' '}
                <a href="mailto:support@thecigroup.com.au">let someone know</a>
                {' '}
                what you were looking for.
              </p>
            </CardText>
          </CardBody>
        </Card>
      </center>
    </Container>
  );
};

export default FourOhFour;
