/* eslint-disable class-methods-use-this */
import React from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { FUSION_CHART_TYPES_SUPPORTED, FUSION_CHART_TYPES_MULTIPLE_SERIES } from '../../utils/fusion-chart-types';
import FUSION_CHART_DATASET_RENDER_TYPES from '../../utils/fusion-chart-dataset-render-types';
import { financialYear } from '../../helpers/financial-year-moments';
import PortalFusionChart from './portal-fusion-chart';
import { DATE_FORMAT } from '../../constants/date-format.const';
import { CLIENT_FORECAST_BALANCE_BY_MONTH_VALUE_TYPE } from '../../constants/client-forecast-balance-by-month-value-type.const';
import { CLIENT_FORECAST_BALANCE_BY_MONTH_SERIES_TYPE } from '../../constants/client-forecast-balance-by-month-series-type.const';


/**
 * @class ClientForecastBalanceByMonthChart
 *
 * @description
 * This chart implements the Portal2 API's "Projects Forecasts Balances by Client By Month" report
 * Requires a "clientId" to be specified
 *
 * TODO: link to documentation
 * @see https://wiki.ciportal.net/books/ci-portal-developer-documentation/page/report-overview
 */
class ClientForecastBalanceByMonthChart extends React.Component {
  /**
   * @inheritdoc
   *
   * @param {{}} props
   */
  constructor(props) {
    super(props);

    this.state = {
      seriesList: this.createSeriesList(props),
    };
  }


  /**
   * @inheritdoc
   */
  shouldComponentUpdate(nextProps) {
    const { valueType: oldValueType, seriesOptions: oldSeriesOptions } = this.props;
    const { valueType: newValueType, seriesOptions: newSeriesOptions } = nextProps;

    if (oldValueType !== newValueType || oldSeriesOptions !== newSeriesOptions) {
      this.setState({ seriesList: this.createSeriesList(nextProps) });
      return false;
    }

    return true;
  }

  /**
   * @description
   * The starting date for the dashboard
   * Format: YYYY-MM-DD
   *
   * @returns {string}
   */
  get monthFrom() {
    const { monthFrom } = this.props;
    return monthFrom.format(DATE_FORMAT.YEAR_MONTH_DASHES);
  }


  /**
   * @description
   * The ending date for the dashboard
   * Format: YYYY-MM-DD
   *
   * @returns {string}
   */
  get monthTo() {
    const { monthTo } = this.props;
    return monthTo.format(DATE_FORMAT.YEAR_MONTH_DASHES);
  }


  /**
   * @description
   * Api url for the report
   *
   * @returns {string}
   */
  get dataUrl() {
    const { clientId } = this.props;

    return `/report/project/forecastbalances/byclientbymonth?month_from=${this.monthFrom}&month_to=${this.monthTo}&client_ids[]=${clientId}`;
  }


  /**
   * @description
   * Return the getValue function for a series type
   *
   * @param {{}} withProps
   * @param {A_CLIENT_FORECAST_BALANCE_BY_MONTH_SERIES_TYPE} seriesType
   * @returns {(dataItem: object) => number | string}
   */
  getSeriesTypeGetValueFunction = (seriesType, withProps = this.props) => {
    const { valueType } = withProps;

    switch (seriesType) {
      case CLIENT_FORECAST_BALANCE_BY_MONTH_SERIES_TYPE.FORECAST:
        return (dataItem) => (valueType === CLIENT_FORECAST_BALANCE_BY_MONTH_VALUE_TYPE.COUNT
          ? dataItem.month_forecast_count
          : dataItem.month_forecast_amount);

      case CLIENT_FORECAST_BALANCE_BY_MONTH_SERIES_TYPE.INVOICE:
        return (dataItem) => (valueType === CLIENT_FORECAST_BALANCE_BY_MONTH_VALUE_TYPE.COUNT
          ? dataItem.month_invoice_count
          : dataItem.month_invoice_amount);

      case CLIENT_FORECAST_BALANCE_BY_MONTH_SERIES_TYPE.ORDER:
        return (dataItem) => (valueType === CLIENT_FORECAST_BALANCE_BY_MONTH_VALUE_TYPE.COUNT
          ? dataItem.month_order_count
          : dataItem.month_order_amount);

      case CLIENT_FORECAST_BALANCE_BY_MONTH_SERIES_TYPE.BALANCE:
        return (dataItem) => (valueType === CLIENT_FORECAST_BALANCE_BY_MONTH_VALUE_TYPE.COUNT
          ? dataItem.month_forecast_count - dataItem.month_invoice_count
          : dataItem.month_balance_amount);

      default: throw new Error(`Unhandled seriesType: ${seriesType}`);
    }
  }


  /**
   * @description
   * Create and return a new seriesList
   *
   * @param {{}} withProps
   * @returns {{}[]}
   */
  createSeriesList = (withProps = this.props) => {
    const { seriesOptions } = withProps;

    return seriesOptions.map((singleSeries) => ({
      name: singleSeries.name,
      getValue: this.getSeriesTypeGetValueFunction(singleSeries.type, withProps),
      // TODO: renderAs not working?
      renderAs: singleSeries.renderAs || FUSION_CHART_DATASET_RENDER_TYPES.COLUMN,
      visible: singleSeries.visible !== false,
    }));
  }


  /**
   * @description
   * Fired by the Fusion chart to get the labels that should be displayed along the
   * bottom row / x-axis / column etc...
   *
   * @param {object} dataItem the data item to extract an x-axis / column label
   */
  handleGetLabel = (dataItem) => dataItem.month;


  /**
   * @inheritdoc
   */
  render() {
    const {
      id,
      paletteColors,
      chartCaption,
      onClickDataItem,
      onClickSeriesLabel,
      onChangeSeriesVisibility,
      onLoading,
      chartType,
      valueType,
      chartSubCaption,
      chartXAxisName,
      chartYAxisName,
      showValues,
    } = this.props;

    const { seriesList } = this.state;

    return (
      <>
        <PortalFusionChart
          id={id}
          paletteColors={paletteColors}
          data={{
            url: this.dataUrl,
            onLoading,
          }}
          dataMapping={{
            type: 'multi',
            chartType,
            getLabel: this.handleGetLabel,
            seriesList,
          }}
          numberPrefix={valueType === CLIENT_FORECAST_BALANCE_BY_MONTH_VALUE_TYPE.COUNT ? null : '$'}
          chartType={chartType}
          caption={chartCaption}
          subCaption={chartSubCaption}
          xAxisName={chartXAxisName}
          yAxisName={chartYAxisName}
          showValues={showValues}

          onClickDataItem={onClickDataItem}
          onClickSeriesLabel={onClickSeriesLabel}
          onChangeSeriesVisibility={onChangeSeriesVisibility}
          onLoading={onLoading}
        />
      </>
    );
  }
}

ClientForecastBalanceByMonthChart.propTypes = {
  id: PropTypes.string,
  seriesOptions: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(Object.values(CLIENT_FORECAST_BALANCE_BY_MONTH_SERIES_TYPE)).isRequired,
    name: PropTypes.string.isRequired,
    visible: PropTypes.bool,
    renderAs: PropTypes.oneOf(Object.values(FUSION_CHART_DATASET_RENDER_TYPES)),
  })),
  clientId: PropTypes.number.isRequired,
  paletteColors: PropTypes.arrayOf(PropTypes.string),
  valueType: PropTypes.oneOf(Object.values(CLIENT_FORECAST_BALANCE_BY_MONTH_VALUE_TYPE)),
  chartCaption: PropTypes.string,
  chartType: PropTypes.oneOf(Object.values(FUSION_CHART_TYPES_SUPPORTED)),
  chartSubCaption: PropTypes.string,
  chartXAxisName: PropTypes.string,
  chartYAxisName: PropTypes.string,
  showValues: PropTypes.bool,

  onClickDataItem: PropTypes.func,
  onClickSeriesLabel: PropTypes.func,
  onChangeSeriesVisibility: PropTypes.func,
  onLoading: PropTypes.func,

  monthFrom: PropTypes.instanceOf(moment),
  monthTo: PropTypes.instanceOf(moment),
};

ClientForecastBalanceByMonthChart.defaultProps = {
  id: 'chart_client_forecast_balance_by_month',
  seriesOptions: [
    {
      type: CLIENT_FORECAST_BALANCE_BY_MONTH_SERIES_TYPE.FORECAST,
      name: 'Forecasts',
      visible: true,
      renderAs: FUSION_CHART_DATASET_RENDER_TYPES.COLUMN,
    },
    {
      type: CLIENT_FORECAST_BALANCE_BY_MONTH_SERIES_TYPE.INVOICE,
      name: 'Invoices',
      visible: true,
      renderAs: FUSION_CHART_DATASET_RENDER_TYPES.COLUMN,
    },
    {
      type: CLIENT_FORECAST_BALANCE_BY_MONTH_SERIES_TYPE.ORDER,
      name: 'Orders',
      visible: true,
      renderAs: FUSION_CHART_DATASET_RENDER_TYPES.COLUMN,
    },
    {
      type: CLIENT_FORECAST_BALANCE_BY_MONTH_SERIES_TYPE.BALANCE,
      name: 'Balance',
      visible: true,
      renderAs: FUSION_CHART_DATASET_RENDER_TYPES.COLUMN,
    },
  ],
  paletteColors: null,
  showValues: false,
  valueType: CLIENT_FORECAST_BALANCE_BY_MONTH_VALUE_TYPE.VALUE,
  chartCaption: 'Projects by Status Age',
  chartType: FUSION_CHART_TYPES_MULTIPLE_SERIES.MULTI_SERIES_COLUMN_2D,
  chartSubCaption: null,
  chartXAxisName: null,
  chartYAxisName: null,

  onClickDataItem: null,
  onClickSeriesLabel: null,
  onChangeSeriesVisibility: null,
  onLoading: null,

  monthFrom: financialYear(),
  monthTo: financialYear({ endOfYear: true }),
};


export default ClientForecastBalanceByMonthChart;
