import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

/**
 * @description
 * Helper function converts a table field map to a form map
 *
 * @todo move this to somewhere sensible
 * Comes in as this.props.fields[] map -> field
 */
export const mapTableToForm = (tableDefinition) => tableDefinition.columns.map((column) => ({
  ...column,
  // the column.visible is for table view column being visible by default
  visible: true,
  showInForm: !(column.showInForm === false),
  title: column.formTitle || column.title,
  placeholder: column.placeholder || undefined,
  // eg. COLUMN_FORMAT.LONG_DATE, 'currency', COLUMN_FORMAT.TEXT
  format: column.format || COLUMN_FORMAT.TEXT,
  // eg. 'ConstSelect', 'DollarAmount', 'text'
  formFieldType: column.formFieldType || FORM_FIELD_TYPE.TEXT_INPUT,
  required: column.required || false,
}));
