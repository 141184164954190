const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

/**
 * @description
 * Get the label corresponding to a sort direction
 *
 * @param {string} direction
 * @returns {string}
 */
export function getSortDirectionDescription(direction) {
  switch (direction) {
    case SORT_DIRECTION.ASC: return 'Ascending';
    case SORT_DIRECTION.DESC: return 'Descending';
    default: throw new Error(`Unhandled Sort Direction: ${direction}`);
  }
}

export default SORT_DIRECTION;
