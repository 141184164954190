import React, { useState, useEffect } from 'react';
import { API_FILTER_OPERATION } from '../../constants/api-filter-operation.const';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';

import AsyncSelect from './async-select';

export type ExpenseTypePickerProps = AsyncSelectComponentProps;

const renderOption = (
  option: { id: number, name: string, quickbooks_class_name: string},
  labelMeta: Record<string, unknown>,
): string | React.ReactNode => {
  if (labelMeta.context === 'menu') {
    return (
      <div>
        <span className="text-dark">
          {option.name}
        </span>
        <br />
        {option.quickbooks_class_name}
        {' '}
      </div>
    );
  }
  return (
    <span className="text-dark">
      {option.name}
    </span>
  );
};

export const ExpenseTypePicker: React.FC<ExpenseTypePickerProps> = (props) => {
  const { formData } = props;
  const [typeSearch, setTypeSearch] = useState(formData?.project_id ? 'project' : 'non-project');

  useEffect(() => {
    setTypeSearch(formData?.project_id ? 'project' : 'non-project');
  }, [
    formData?.project_id,
  ]);

  return (
    <AsyncSelect
      {...props}
      renderOption={renderOption}
      searchRoute="/expenseclaim/item-class"
      appendQuery={
        [
          '/expenseclaimbatch?pagelength=1',
          'filter[0][field]=type',
          `filter[0][operation]=${API_FILTER_OPERATION.IN}`,
          `filter[0][value][0]=${typeSearch}`,
          'filter[0][value][1]=any',
        ].join('&')
      }
      loadAndKeepAll
    />
  );
};
