/**
 * Wraps the input in an array, if it isn't already one
 *
 * @param {T extends (any[] | any)} input
 * @returns T extends any[] ? T | T[]
 */
export const arrayWrap = (input) => {
  if (Array.isArray(input)) {
    return input;
  }

  return [input];
};
