import React from 'react';

import { ConfirmActionModalResult } from '../../../../types/modal/modal-result';
import { IActivateProjectModalWizardPage1Data, IActivateProjectModalWizardState } from '../activate-project-data.interface';

import { CheckBox } from '../../../form-input/checkbox';
import { DateInput } from '../../../form-input/date-input';
import { CurrencyInput } from '../../../form-input/currency-input';
import { FileSelectInput } from '../../../form-input/file-select-input';
import { Label } from '../../../layout-helpers/label';
import { ModalWizardPage, ModalWizardPageProps } from '../../modal-wizard-page';
import { TextInput } from '../../../form-input/text-input';
import { WizardFormErrorRow } from '../../../layout-helpers/wizard-form-error-row';

import { getFieldPageDataErrors } from '../../../../helpers/get-field-page-data-errors.helper';

export const ActivateProjectModalPage1 = (props: ModalWizardPageProps<
  IActivateProjectModalWizardPage1Data,
  IActivateProjectModalWizardState,
  ConfirmActionModalResult
>): React.ReactElement => {
  const { pageData, pageDataErrors, onPageDataFieldChanged, wizardState: { projectRecord } } = props;

  const hasProjectDrive = !!projectRecord?.ms_group_drive_item_url;

  /**
   * Render
   */
  return (
    <ModalWizardPage<IActivateProjectModalWizardPage1Data, IActivateProjectModalWizardState, ConfirmActionModalResult>
      {...props}
    >
      <div className="wizard-page-section">
        <div className="wizard-page-section-heading">
          <span>Step 1: Activating Purchase Order Information</span>
        </div>
        <div className="wizard-page-section-content">
          {/* A Project Drive is a mandatory requirement for project activation */}
          {!hasProjectDrive && (
            <p className="text-danger">
              Oops! It looks like a project drive does not exist for this project.
              Please close the wizard and create a Project Drive before attempting to activate the project.
            </p>
          )}
          <p>
            To activate the project, a documented client purchase order is required... even if that&apos;s a
            PDF printout of an email. If you have the As-Sold Proposal file, you can add that now.
          </p>
          {/* Email Only Activation */}
          <CheckBox
            id="email_only_activation"
            name="email_only_activation"
            label="Email Only Activation (No P.O.)"
            infoToolTip={{
              title: 'Email Only Activation (No P.O.)',
              body: 'Check this box if you have only received authorisation from the client to proceed via Email and will not receive a formal Purchase Order document.',
            }}
            inline
            value={pageData.email_only_activation}
            onChange={onPageDataFieldChanged}
          />
          <div className="wizard-form">
            {/* === Order Number === */}
            <Label
              for="order_no"
              hasError={getFieldPageDataErrors(pageDataErrors, 'order_no') !== false}
            >
              {`Client's Order Number${!pageData.email_only_activation ? '*' : ''}`}
            </Label>
            {!!pageData.email_only_activation && (
              <span>{pageData.order_no}</span>
            )}
            {!pageData.email_only_activation && (
              <TextInput
                id="order_no"
                name="order_no"
                value={pageData.order_no}
                placeholder="Order Number"
                hasError={getFieldPageDataErrors(pageDataErrors, 'order_no') !== false}
                onChange={onPageDataFieldChanged}
              />
            )}
            <WizardFormErrorRow fieldName="order_no" pageDataErrors={pageDataErrors} />


            {/* === Order Total ===  */}
            <Label
              for="order_total"
              hasError={getFieldPageDataErrors(pageDataErrors, 'order_total') !== false}
            >
              {`Order Value${!pageData.email_only_activation ? '*' : ''}`}
            </Label>
            <CurrencyInput
              id="order_total"
              name="order_total"
              value={(pageData.order_total ?? 0).toString()}
              allowNegative={false}
              hasError={getFieldPageDataErrors(pageDataErrors, 'order_total') !== false}
              onChange={onPageDataFieldChanged}
            />
            <WizardFormErrorRow fieldName="order_total" pageDataErrors={pageDataErrors} />


            {/* === Received Date2 === */}
            <Label
              for="received_date"
              hasError={getFieldPageDataErrors(pageDataErrors, 'received_date') !== false}
            >
              Received Date*
            </Label>
            <DateInput
              id="received_date"
              name="received_date"
              placeholder="Received Date"
              value={pageData.received_date}
              disabled={false}
              useStringValue
              hasError={getFieldPageDataErrors(pageDataErrors, 'received_date') !== false}
              onChange={onPageDataFieldChanged}
            />
            <WizardFormErrorRow fieldName="received_date" pageDataErrors={pageDataErrors} />


            {/* === Activating PO Document File === */}
            <Label
              for="activating_po_document_file"
              hasError={getFieldPageDataErrors(pageDataErrors, 'activating_po_document_file') !== false}
            >
              Activating PO document*
            </Label>
            <FileSelectInput
              id="activating_po_document_file"
              name="activating_po_document_file"
              value={pageData.activating_po_document_file}
              onChange={onPageDataFieldChanged}
              allowMultipleFiles={false}
              hasError={getFieldPageDataErrors(pageDataErrors, 'activating_po_document_file') !== false}
            />
            <WizardFormErrorRow fieldName="activating_po_document_file" pageDataErrors={pageDataErrors} />


            {/* === As Sold Proposal File === */}
            <Label for="as_sold_proposal_document_file">As Sold Proposal Document</Label>
            <FileSelectInput
              id="as_sold_proposal_document_file"
              name="as_sold_proposal_document_file"
              value={pageData.as_sold_proposal_document_file}
              onChange={onPageDataFieldChanged}
              allowMultipleFiles={false}
            />


            {/* === Comment === */}
            <Label for="comment">Comments</Label>
            <TextInput
              id="comment"
              name="comment"
              value={pageData.comment}
              placeholder="Comments"
              onChange={onPageDataFieldChanged}
            />
          </div>

          {projectRecord && projectRecord.supply_only_job === 1 && (
            <>
              <h6 className="text-primary">
                <br />
                <strong>NB: This is a delivery only job, so ordering can commence as soon as authorisation is confirmed.</strong>
              </h6>
              {projectRecord && projectRecord.project_freight_type && projectRecord.project_freight_type.name && (
                <p className="text-primary">
                  <strong>Freight Type: </strong>
                  &nbsp;
                  {projectRecord.project_freight_type.name}
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </ModalWizardPage>
  );
};
