import React from 'react';
import classNames from 'classnames';

import rollingSvg from '../../images/Rolling-1s-22px.svg';

type LoadingSpinnerProps = {
  className?: string,
  caption?: string,
  inline?: boolean,
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ children, className, caption, inline }) => (
  <div className={classNames('loading-spinner', className, { inline })}>
    <img className="spinner" src={rollingSvg} alt={caption} />
    {caption && (
      <span>{caption}</span>
    )}
    {children}
  </div>
);
