import React from 'react';

import { WidgetProps } from '../../../types/widget.props';
import { IProjectRecord } from '../../../types/project/project.record.interface';

import DToolsProjectOrders from '../../dtools-information/d-tools-project-orders';
import FriendlyFormMessage from '../../layout-helpers/friendly-form-message';


export type ProjectQuickbooksOrdersWidgetProps = Omit<WidgetProps, 'rowData'> & {
  rowData: IProjectRecord,
}


/**
 * ProjectQuickbooksOrdersWidget component for Data Table
 */
export const ProjectQuickbooksOrdersWidget:React.FC<ProjectQuickbooksOrdersWidgetProps> = ({ rowData }) => (
  <div className="widget project-supplier-orders-widget">
    {rowData.dtools_id && (
      <DToolsProjectOrders projectData={rowData} dtoolsId={rowData.dtools_id || null} />
    )}
    {!rowData.dtools_id && (
      <FriendlyFormMessage
        formMessage="This project is not yet linked to a DTools project. Please visit the DTools Projects tab and click 'Make Master' on one of the matching DTools Projects."
      />
    )}
  </div>
);
