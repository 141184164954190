import React from 'react';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';
import { FormFieldChangeProps } from '../../types/poly-form/form-field-change-props';
import { IProjectMaintenanceTypeRecord } from '../../types/project/project-maintenance-type.record.interface';

import AsyncSelect from './async-select';

import { startCase } from '../../utils/helpers';

export type ProjectMaintenanceTypePicketProps = Omit<AsyncSelectComponentProps, 'onChange'> & {
  onChange?: (field: FormFieldChangeProps<IProjectMaintenanceTypeRecord>) => void,
};

export const ProjectMaintenanceTypePicker:React.FC<ProjectMaintenanceTypePicketProps> = (props) => (
  <AsyncSelect
    {...props}
    searchRoute="/projectmaintenancetype"
    loadAndKeepAll
    dataMap={(option: IProjectMaintenanceTypeRecord) => ({
      ...option,
      name: startCase(option.name),
    })}
  />
);
