import PropTypes from 'prop-types';

const HISTORY_PROP_TYPES = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  push: PropTypes.func,
  replace: PropTypes.func,
};

export default HISTORY_PROP_TYPES;
