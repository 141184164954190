import React from 'react';
import classnames from 'classnames';
import { Editor } from '@tinymce/tinymce-react';
import { InfoTooltip } from '../info-tooltip';
import { TINYMCE_KEY } from '../../utils/constants';
import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';

export type HtmlInputProps = Pick<FormFieldComponentProps,
  'id' |
  'name' |
  'className' |
  'tabIndex' |
  'placeholder' |

  'value' |
  'disabled' |
  'inline' |

  'infoToolTip' |
  'hasError' |

  'onChange'
>;

export const HtmlInput: React.FC<HtmlInputProps> = ({
  id,
  name,
  value,
  infoToolTip,
  placeholder,
  tabIndex,
  className,
  disabled,
  hasError,
  inline,
  onChange,
}) => (
  <div className={classnames(
    'portal-html-input',
    className,
    {
      inline,
      'form-control-danger': hasError,
      disabled,
    },
  )}
  >
    <div
      className="form-control"
      tabIndex={tabIndex}
    >
      <Editor
        id={id}
        apiKey={TINYMCE_KEY}
        initialValue={value}
        // className="form-control"
        init={{
          branding: false,
          menubar: false,
          plugins: 'lists',
          statusbar: false,
          inline: true,
          paste_as_text: true,
          toolbar: 'undo redo | bold italic | bullist numlist',
          valid_elements: 'strong/b,em,div,br,p,ol,ul,li',
          contextmenu: false,
          disabled,
          placeholder,
        }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(event: any) => onChange && onChange({ fieldName: name, newValue: event.level.content })}
      />

    </div>
    {/* Tooltip */}
    {infoToolTip && (
      <InfoTooltip title={infoToolTip.title}>
        {infoToolTip.body}
      </InfoTooltip>
    )}
  </div>
);
