/**
 * Transition state is used on components like the Modal Wizard Page that
 * transition in and out from view
 */
export const TRANSITION_STATE = {
  IN: 'in',
  VISIBLE: 'visible',
  OUT: 'out',
  HIDDEN: 'hidden',
} as const;

export type TRANSITION_STATE = typeof TRANSITION_STATE;
export type A_TRANSITION_STATE = TRANSITION_STATE[keyof TRANSITION_STATE];
