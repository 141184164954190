import React, { createRef, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  Button, Popover, PopoverHeader, PopoverBody, PopoverProps,
} from 'reactstrap';
import classNames from 'classnames';
import shortId from 'shortid';
import Icon from './layout-helpers/icon';
import { ReactPortalContext } from './react-portal/react-portal-context';
import { AN_ICON, ICON } from '../constants/icon.const';

type InfoTooltipProps = {
  placement?: PopoverProps['placement'],
  title?: string,
  open?: boolean,
  openOnHover?: boolean,
  buttonClassName?: string,
  buttonIcon?: AN_ICON,
  tabIndex?: number,
}

export const InfoTooltip: React.FC<InfoTooltipProps> = (props) => {
  const {
    children,
    placement,
    title,
    open,
    openOnHover = true,
    buttonClassName,
    buttonIcon,
    tabIndex,
  } = props;

  const { portalContainerElement } = useContext(ReactPortalContext);
  const [isOpen, setIsOpen] = useState(open ?? false);
  const [isHovering, setIsHovering] = useState(false);
  const buttonId = useRef(`popover_${shortId.generate().replace(/[_-]/g, '')}`);
  const popoverRef = createRef<HTMLDivElement>();

  useEffect(() => {
    setIsOpen(open ?? false);
  }, [open]);


  /**
   * Open popover
   */
  const handleOpenPopover = useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
    }
  }, [isOpen]);


  /**
   * Close popover, used for hover and focus   */
  const handleClosePopover = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [isOpen]);


  /**
   * Used to bind an onClick listener to the document when the infoToolTop is opened
   * so that clicking anywhere else will close it (this is better than losing focus on the button)
   */
  useEffect(() => {
    if (isOpen && popoverRef.current) {
      /**
       * Listens to all clicks on the document and closes the popover if not clicked within the bounds of the popover
       */
      const handleDocumentClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        handleClosePopover();
      };
      document.addEventListener('click', handleDocumentClick);

      return () => {
        document.removeEventListener('click', handleDocumentClick);
      };
    }

    return () => {};
  }, [handleClosePopover, isOpen, popoverRef]);


  // Render
  return (
    <div className="info-tooltip-wrapper d-inline">
      <Button
        id={buttonId.current}
        className={buttonClassName}
        color="link"
        tabIndex={tabIndex}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (isOpen) {
            handleClosePopover();
          } else {
            handleOpenPopover();
          }
        }}
        active={isOpen}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Icon i={buttonIcon ?? ICON.INFO} size="sm" />
      </Button>
      {portalContainerElement && (
        <Popover
          className={classNames('info-tooltip', { open: isOpen })}
          isOpen={isOpen || (openOnHover && isHovering)}
          target={buttonId.current}
          container={portalContainerElement}
          boundariesElement="scrollParent"
          placement={placement ?? 'auto'}
          flip={false}
          onClick={(e) => {
            // Prevents the popover from closing when clicked inside
            e.stopPropagation();
          }}
        >
          <div
            ref={popoverRef}
          >
            {title && (
              <PopoverHeader>{title}</PopoverHeader>
            )}
            {children && (
              <PopoverBody>{children}</PopoverBody>
            )}
          </div>
        </Popover>
      )}
    </div>
  );
};
