import React from 'react';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { DataTablePageProps } from '../../types/data-table-page.props';

export type ProjectItemEtaSummaryTablePageProps = DataTablePageProps;

/**
 * Display the "Project Item ETA Details" data table as a whole page
 */
export const ProjectItemEtaSummaryTablePage: React.FC<ProjectItemEtaSummaryTablePageProps> = ({
  history,
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => (
  <DataTablePage
    title="Project ETA Summary Overviews"
    tableIdentifier={TABLE_IDENTIFIER.PROJECT_ITEM_ETA_SUMMARY_TABLE}
    baseFlags={baseFlags}
    match={match}
    history={history}
    location={location}
    availableActions={availableActions}
    possibleActions={possibleActions}
  >
    {children}
  </DataTablePage>
);
