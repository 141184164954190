import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { ReactPortalContext } from './react-portal-context';

/**
 * @class ReactPortal
 *
 * @note if you encounter problems with click events on DropDownMenuItems
 *       it is a result of the DropDownMenu Toggle firing before the onClick
 *       look at existing implementations of the dropdown menu for examples.
 *
 */
export const ReactPortal: React.FC = ({ children }) => {
  const { portalContainerElement } = useContext(ReactPortalContext);
  return (
    <>
      {portalContainerElement && (
        ReactDOM.createPortal(children, portalContainerElement)
      )}
      {!portalContainerElement && (
        children
      )}
    </>
  );
};
