import React from 'react';
import PropTypes from 'prop-types';
import preview from './rd-preview.png';
import { documentTitle } from '../../utils/helpers';

const RoomDesignerPage = ({ title }) => (
  <div className="room-designer-page">
    {documentTitle(title)}
    <iframe
      title="Room Desginer"
      frameBorder="0"
      src="https://room-designer.ciportal.net"
    >
      <img src={preview} alt="Room Designer preview" width="100%" />
    </iframe>
  </div>
);

RoomDesignerPage.propTypes = {
  title: PropTypes.string,
};

RoomDesignerPage.defaultProps = {
  title: null,
};

export default RoomDesignerPage;
