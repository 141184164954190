import React from 'react';
import classNames from 'classnames';
import { getFilterFormat } from '../../../utils/filters';
import { formatAlignTableColumn } from '../../render-functions';
import { getColumnFormatWidth } from '../../../helpers/column-format-width.helper';
import { getFilterDescription, getSortIcon } from '../table/table-head';
import Icon from '../../layout-helpers/icon';
import { InMemoryColumnFilter } from './in-memory-column-filter';
import { IColumnFilter, IColumnSort, InMemoryDataTableColumn, ISetColumnFilter, ISetColumnSort } from '../../../types/portal-data-table/in-memory-table-types';

// IOldDataTableColumn  IFormFieldDefinition

interface IInMemoryTableHeadProps {
  columns: InMemoryDataTableColumn[],
  filteredColumns: IColumnFilter[],
  sortedColumns: IColumnSort[],
  isColumnFilterDrawerVisible?: boolean,
  onFilterColumn: ISetColumnFilter,
  onSortColumn: ISetColumnSort,
}

export const InMemoryTableHead: React.FC<IInMemoryTableHeadProps> = ({ columns, sortedColumns, filteredColumns, onSortColumn, onFilterColumn, isColumnFilterDrawerVisible }) => {
  const isTextWrappingEnabled = false;
  return (
    <thead>
      <tr className="portal-datatable-head" role="row">
        {columns.map((column) => {
          const oldColumn = column;
          const columnFilter = filteredColumns.filter((thisFilter: IColumnFilter) : boolean => thisFilter.name === column.name)[0];
          const filterFormatting = getFilterFormat(oldColumn);
          const minWidth = isTextWrappingEnabled ? null : getColumnFormatWidth(oldColumn);
          const filterDescription = columnFilter ? getFilterDescription(columnFilter, filterFormatting) : null;
          const sortedColumn = sortedColumns.filter((thisSort: IColumnSort) : boolean => thisSort.name === column.name)[0];
          const columnHeaderCellStyle: React.CSSProperties = {
            ...(minWidth ? { minWidth } : {}),
          };

          // Sort icon
          const sortCaret = getSortIcon(
            sortedColumn ? sortedColumn.direction : null,
            filterFormatting,
          );


          const uniqueId = `portal-datatable-th-${oldColumn.name}`;

          // Return oldColumn header
          return (
            <th
              title={column.description || ''}
              onClick={() => {
                // dispatchSortColumn(oldColumn, tableIdentifier);
                let newSort = null;
                if (sortedColumn === undefined) {
                  newSort = {
                    name: column.name,
                    direction: 'asc',
                  };
                }
                if (sortedColumn && sortedColumn.direction === 'asc') {
                  newSort = {
                    name: column.name,
                    direction: 'desc',
                  };
                }
                if (sortedColumn && sortedColumn.direction === 'desc') {
                  newSort = null;
                }
                onSortColumn(column.name, newSort);
              }}
              className={classNames(formatAlignTableColumn(oldColumn), {
                'bg-success': columnFilter,
              })}
              key={uniqueId}
              id={uniqueId}
              style={columnHeaderCellStyle}
            >
              <div className="column-header-inner">
                <div className="title-wrapper">
                  {sortCaret && (
                    <span className="column-sort-icon">
                      <Icon i={sortCaret} />
                    </span>
                  )}
                  <span className="title">
                    {column.title}
                  </span>
                </div>
                {columnFilter && (
                  <div className="filter-description">
                    <Icon i="filter" />
                    {filterDescription && (
                      <>
                        &nbsp;
                        <span className="filter-type">{filterDescription}</span>
                      </>
                    )}
                  </div>
                )}
              </div>
            </th>
          );
        })}
      </tr>
      {isColumnFilterDrawerVisible && (
        <tr className="datatable-header-filter" role="row">
          {columns.map((column) => (
            <td
              className={classNames('datatable-header-filter-col', {
                'has-filter': filteredColumns.filter((columnFilter: IColumnFilter) : boolean => columnFilter.name === column.name)[0],
              })}
              key={`filter-column-${column.name}`}
            >
              <InMemoryColumnFilter
                column={column}
                columnFilter={filteredColumns.filter((columnFilter: IColumnFilter) : boolean => columnFilter.name === column.name)[0]}
                setColumnFilter={onFilterColumn}
              />
            </td>
          ))}
        </tr>
      )}
    </thead>
  );
};
