import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody } from 'reactstrap';

const IntegrationPanel = ({ title, color, children }) => (
  <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
    <Card>
      <CardHeader className={`bg-${color} text-white`} tag="h4">
        {title}
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  </div>
);

IntegrationPanel.defaultProps = {
  title: 'Integration Panel',
  color: 'default',
};

IntegrationPanel.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default IntegrationPanel;
