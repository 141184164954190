import React from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import Breadcrumbs from './breadcrumbs';
import Icon from '../layout-helpers/icon';
import { noop } from '../../utils/helpers';
import { ActionButton, ActionButtonProps } from '../record-action-buttons/action-button';
import { ICON } from '../../constants/icon.const';
import { BUTTON_COLOR } from '../../constants/button-color.const';


type PageHeaderProps = {
  className?: string,
  title?: string | React.ReactNode | React.ReactNode[],
  children?: React.ReactNode | React.ReactNode[],
  permittedActions?: ActionButtonProps[],
  defaultActionButton?: ActionButtonProps,
  onRenderActions?: unknown,
}


/**
 * Page header component showing header and breadcrumbs
 * If no children, will render <h4> with title
 */
const PageHeader: React.FC<PageHeaderProps> = ({
  className = null,
  title = '',
  children = null,
  permittedActions,
  defaultActionButton = {
    visible: false,
    label: 'Create New',
    icon: ICON.ADD,
    color: BUTTON_COLOR.PRIMARY,
    handleAction: noop,
    onClick: noop,
  },
  onRenderActions,
}) => (
  <>
    <div className={classNames('page-titles', className)}>
      <div className="title-and-breadcrumbs">
        <div className="title-wrapper">
          {!children && (
            <h4 className="text-themecolor">{title}</h4>
          )}
          {children}
        </div>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs />
        </div>
      </div>
      <div className="actions-wrapper">
        {/* Standard actions section behaviour */}
        {typeof onRenderActions !== 'function' && (
          <>
            {/* Default Action */}
            {defaultActionButton && defaultActionButton.visible && (
              <Button
                onClick={defaultActionButton.onClick}
                color={defaultActionButton.color || 'primary'}
                type="button"
              >
                {defaultActionButton.icon && (
                  <Icon i={defaultActionButton.icon} />
                )}
                <span>{`${defaultActionButton.label}`}</span>
              </Button>
            )}

            {/* Permitted Actions */}
            {permittedActions && permittedActions.map((action: ActionButtonProps) => (
              <ActionButton
                key={action.name}
                onClick={action.handleAction}
                {...action}
              />
            ))}
          </>
        )}

        {/* Custom actions section behaviour */}
        {typeof onRenderActions === 'function' && (
          onRenderActions(defaultActionButton, permittedActions)
        )}

      </div>
    </div>
  </>
);

export default PageHeader;
