import React from 'react';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';

import AsyncSelect from './async-select';

import { IProjectForecastRecord } from '../../types/project/project-forecast.record.interface';
import { formatValueString } from '../render-functions';

export type ProjectForecastPickerProps = AsyncSelectComponentProps;

export const ProjectForecastPicker:React.FC<ProjectForecastPickerProps> = (props) => {
  const {
    formData = {},
    parentData = {},
  } = props;

  const getOptionLabel = (option: IProjectForecastRecord) => {
    if (!option) return null;
    return option.name;
  };

  const projectId = formData.project_id || parentData.id;

  /**
 * Called by the select to render one of the options in the dropdown
 *
 * @see https://react-select.com/props
 *
 * @param option the option data to render
 * @param details information about where and what is being rendered
 * @param inputValue the search term the user has entered into the input
 */
  const renderOption = (option: IProjectForecastRecord, labelMeta: Record<string, unknown>): string | React.ReactNode => {
    if (labelMeta.context === 'menu') {
      return (
        <div>
          <span className="text-dark">
            {option._formatted_amount}
            {' - '}
            {formatValueString(option.expected_by, 'date')}
          </span>
          {option.comment && (
            <>
              <br />
              {option.comment}
              {' '}
            </>
          )}
        </div>
      );
    }
    return (
      <span className="text-dark">
        {formatValueString(option.expected_by, 'date')}
        {' - '}
        {option._formatted_amount}
      </span>
    );
  };

  return (
    <AsyncSelect
      {...props}
      valueKey="id"
      searchRoute={`/project/${projectId}/forecast`}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      loadAndKeepAll
    />
  );
};
