/**
 * @todo the open row should be stored against the table rather than set in the row,
 *   as much as possible.
 */
export const DATATABLE_UPDATE_ROW = 'DATATABLE_UPDATE_ROW';

// actions to change page
export function updateRow(tableIdentifier, id, rowData) {
  return {
    type: DATATABLE_UPDATE_ROW,
    tableIdentifier,
    id,
    rowData,
  };
}

export function updateRowData(tableIdentifier, id, apiFetch) {
  return async (dispatch, getState) => {
    const { tableSettings } = getState('tableSettings');
    const thisTable = tableSettings[tableIdentifier] || [];

    const response = await apiFetch(
      `${thisTable.baseRoute}/${id}?${thisTable.baseQueryString}`,
      {
        name: `updateRowData:${tableIdentifier}.${id}`,
      },
    );

    if (response.success) {
      dispatch(updateRow(tableIdentifier, id, response.body.data));
    } else if (!response.aborted) {
      console.error('Row Data update ', response.error);
    }
  };
}
