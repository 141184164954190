import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SalesForecastTableCellGroup from './sales-forecast-table-cell-group';
import SalesForecastTableRow from './sales-forecast-table-row';
import { DATA_TYPE, buildDomDataTagId } from './sales-forecast-helpers';
import Icon from '../../layout-helpers/icon';
import SalesForecastTableProjectSummaryDataRows from './sales-forecast-table-project-summary-data-rows';
import HighlightMissingForecast from './highlight-missing-forecast';

const SalesForecastTableSalespersonSummaryDataRows = (props) => {
  const {
    summaryFields,
    visibleSummaryFieldCount,
    pmAndStateIds,
    hideZeroValues,
    showProjectNames,

    onClick,
    onBeginHover,
    onEndHover,

    salespersonData,
    projectData,
  } = props;

  return (
    !!salespersonData && (
      <>
        {/* Error loading Salesperson Summary Data */}
        {salespersonData.error && !salespersonData.isLoading && (
          <SalesForecastTableRow
            className="project-manager summary data no-data error"
          >
            <SalesForecastTableCellGroup span={visibleSummaryFieldCount}>
              <Icon i="exclamation" />
              <span>{`Error: ${salespersonData.error}`}</span>
            </SalesForecastTableCellGroup>
          </SalesForecastTableRow>
        )}

        {/* No Salesperson Data, No Error, Not loading */}
        {!salespersonData.summaryData.data.length && !salespersonData.isLoading && !salespersonData.error && (
          <SalesForecastTableRow
            className="project-manager summary data no-data"
          >
            <SalesForecastTableCellGroup span={visibleSummaryFieldCount}>
              <span>Nothing to display.</span>
            </SalesForecastTableCellGroup>
          </SalesForecastTableRow>
        )}

        {/* No Salesperson Summary Data and Summary Data is loading */}
        {!salespersonData.summaryData.data.length && salespersonData.isLoading && (
          <SalesForecastTableRow
            className="project-manager summary data no-data loading"
          >
            <SalesForecastTableCellGroup span={visibleSummaryFieldCount}>
              <Icon i="rolling" />
              <span>Loading...</span>
            </SalesForecastTableCellGroup>
          </SalesForecastTableRow>
        )}

        {/* Salesperson Summary Data Exists and no error */}
        {!!salespersonData.summaryData.data.length && !salespersonData.error && (
          <>
            {/* Salesperson Summary Data Rows */}
            {salespersonData.summaryData.data.map((salesperson) => (
              <React.Fragment
                key={`state_${salesperson.state_id}_salesperson_${salesperson.salesperson_id}`}
              >
                <SalesForecastTableRow
                  className={classNames('project-manager', 'summary', 'data', {
                    loading: salespersonData.isLoading,
                    expanded: !!pmAndStateIds.find((pmAndStateId) => pmAndStateId.stateId === salesperson.state_id && pmAndStateId.pmId === salesperson.salesperson_id),
                  })}
                  dataType={DATA_TYPE.PROJECT_MANAGER}
                  id={buildDomDataTagId(DATA_TYPE.PROJECT_MANAGER, salesperson.state_id, salesperson.salesperson_id)}
                  trackHover
                  onBeginHover={onBeginHover}
                  onEndHover={onEndHover}
                  onClick={onClick}
                >
                  <SalesForecastTableCellGroup
                    rowKey={`state_${salesperson.state_id}_salesperson_${salesperson.salesperson_id}`}
                    fields={summaryFields}
                    data={salesperson}
                    hideZeroValues={hideZeroValues}
                    // onRenderFieldData={(field, data, defaultFormattedValue) => (
                    //   field.key === 'name' ? data.salesperson_name : defaultFormattedValue
                    // )}
                    onRenderFieldData={(field, data, defaultFormattedValue) => {
                      switch (field.key) {
                        case 'name':
                          return data.salesperson_name;
                        case 'total_forecast_amount':
                        case 'total_total_forecast_amount':
                        case 'value':
                          return (
                            <HighlightMissingForecast data={data} defaultFormattedValue={defaultFormattedValue || '*'} />
                          );
                        default:
                          return defaultFormattedValue;
                      }
                    }}
                  />

                </SalesForecastTableRow>

                {/* Project Summary Data */}
                <SalesForecastTableProjectSummaryDataRows
                  summaryFields={summaryFields}
                  visibleSummaryFieldCount={visibleSummaryFieldCount}
                  hideZeroValues={hideZeroValues}
                  showProjectNames={showProjectNames}
                  onBeginHover={onBeginHover}
                  onEndHover={onEndHover}
                  onClick={onClick}
                  projectData={projectData
                    ? projectData.find((project) => project.stateId === salesperson.state_id && project.salespersonId === salesperson.salesperson_id)
                    : null}
                />

              </React.Fragment>
            ))}
          </>
        )}
      </>
    )
  );
};

SalesForecastTableSalespersonSummaryDataRows.propTypes = {
  summaryFields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  })).isRequired,
  salespersonData: PropTypes.shape({
    stateId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    summaryData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
    monthlyData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
  }),
  projectData: PropTypes.arrayOf(PropTypes.shape({
    stateId: PropTypes.number.isRequired,
    salespersonId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    summaryData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
    monthlyData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
  })),
  visibleSummaryFieldCount: PropTypes.number.isRequired,
  pmAndStateIds: PropTypes.arrayOf(PropTypes.shape({
    pmId: PropTypes.number.isRequired,
    stateId: PropTypes.number.isRequired,
  })).isRequired,
  onClick: PropTypes.func.isRequired,
  onBeginHover: PropTypes.func.isRequired,
  onEndHover: PropTypes.func.isRequired,
  hideZeroValues: PropTypes.bool.isRequired,
  showProjectNames: PropTypes.bool.isRequired,
};

SalesForecastTableSalespersonSummaryDataRows.defaultProps = {
  salespersonData: null,
  projectData: null,
};

export default SalesForecastTableSalespersonSummaryDataRows;
