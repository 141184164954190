import React, { useCallback } from 'react';

import { IAllowanceRecord } from '../../types/allowance/allowance.record.interface';
import { NewRecordModalProps } from '../../types/modal/new-record-modal.props';

import { useIsDirty } from '../../react-hooks/use-is-dirty.hook';

import { Modal } from './Modal';
import { NewAllowanceForm } from '../poly-forms/new-allowance-form';

export const NewAllowanceModal:React.FC<NewRecordModalProps<IAllowanceRecord>> = (props) => {
  const {
    id, closeModal, onModalComplete, initialData,
  } = props;

  const [isDirty, setDirty] = useIsDirty();

  /**
   * Fired when the form successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, recordId: IAllowanceRecord['id'], newRecord?: IAllowanceRecord) => {
    closeModal(id);
    onModalComplete((success && newRecord) ? { success, newRecord } : { success: false, newRecord: null });
  }, [closeModal, id, onModalComplete]);

  // Render
  return (
    <Modal
      {...props}
      title="Claim an Allowance"
      allowDismiss
      isDirty={isDirty}
      size="lg"
    >
      <NewAllowanceForm
        onFormComplete={handleFormComplete}
        onFormChange={setDirty}
        initialData={initialData}
      />
    </Modal>
  );
};
