import React, { useCallback } from 'react';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { IInternalProjectTaskRecord } from '../../types/internal-project/internal-project-task.record.interface';
import { NewRecordFormProps } from '../../types/poly-form/new-record-form.props';

import { APIPolyForm } from './api-poly-form';

import { mapTableToForm } from '../../helpers/map-table-to-form.helper';

import internalProjecttasksTableInitialSettings from '../../table-definitions/internal-project-tasks-table';
import { API_ACTION } from '../../constants/api-action.const';

const formFields = mapTableToForm(internalProjecttasksTableInitialSettings);

export const NewInternalProjectTaskForm: React.FC<NewRecordFormProps<IInternalProjectTaskRecord>> = (props) => {
  const {
    onFormComplete, onFormChange, initialData,
  } = props;

  /**
   * Fired when the APIPolyForm successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, id: FormFieldComponentProps['value'], newData?: Partial<IInternalProjectTaskRecord>) => {
    if (onFormComplete) onFormComplete(success, id as IInternalProjectTaskRecord['id'], newData as IInternalProjectTaskRecord);
  }, [onFormComplete]);

  // Render
  return (
    <APIPolyForm
      {...internalProjecttasksTableInitialSettings}
      fields={formFields}
      formData={initialData ?? {}}
      permittedActions={internalProjecttasksTableInitialSettings.possibleActions.filter((action) => (action.name === API_ACTION.CREATE))}
      onFormComplete={handleFormComplete}
      onFormChange={onFormChange}
      isNewRecord
      isEditing
    />
  );
};
