import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const HighlightMissingForecast = ({ data, defaultFormattedValue }) => (
  <div className={classNames({ 'text-danger': (parseFloat(data.value) !== parseFloat(data.total_forecast_amount)) })}>
    {defaultFormattedValue}
  </div>
);

HighlightMissingForecast.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.number.isRequired,
    total_forecast_amount: PropTypes.number.isRequired,
  }).isRequired,
  defaultFormattedValue: PropTypes.string.isRequired,
};

export default HighlightMissingForecast;
