/**
 * @description
 * Check object has property with string value
 *
 * @param {{}} obj
 * @param {string} prop
 * @returns { obj is (obj & { [prop]: string }) }
 */
export const hasStringProperty = (obj, prop) => prop in obj && typeof obj[prop] === 'string';

/**
 * @description
 * Check object has property with number value
 *
 * @param {{}} obj
 * @param {string} prop
 * @returns { obj is (obj & { [prop]: number }) }
 */
export const hasNumberProperty = (obj, prop) => prop in obj && typeof obj[prop] === 'number';
