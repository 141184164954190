import React, { useEffect, useState } from 'react';
import { RowDetailsWidgetProps } from './row-details.widget';
import { APIPolyForm } from '../poly-forms/api-poly-form';
import { FORM_RENDERER_TYPE } from '../../constants/form-renderer-type.const';
import { mapTableToForm } from '../../helpers/map-table-to-form.helper';
import FullPageLoadingSpinner from '../layout-helpers/full-page-loading-spinner';
import { deepCompare } from '../../helpers/deep-compare.helper';

/**
 * Basic form for Data Table row detail editing
 */
export const RowPrivateDetailsWidget: React.FC<RowDetailsWidgetProps> = ({
  widgetData,
  rowDataChecksum,
  columns,
  baseRoute,
  permittedActions,
  itemCaption,
  formCaption,
  onStartEdit,
  onEndEdit,
  onDelete,
  onDirtyChange,
  navigateBackOnDelete,
  isEditing,
  isReadOnly,
  rowPrimaryKeyFieldName = 'id',
}) => {
  const [localFormData, setLocalFormData] = useState(
    widgetData && widgetData.length === 1 && widgetData[0] ? widgetData[0] : null,
  );
  const [formCheckSum, setFormChecksum] = useState(rowDataChecksum);

  /**
   * UseEffect to catch widget data passed down, and update the form if required only.
   *
   * The detail view container will pass down widgetData when rowData is updated regardless of
   * any changes.
   */
  useEffect(() => {
    //  make sure the data is there
    if (widgetData && widgetData.length === 1 && widgetData[0]) {
      // only iterate the form data once the widget data has actually incremented.
      if (!deepCompare(localFormData, widgetData[0])) {
        setFormChecksum(formCheckSum + 1);
        setLocalFormData(widgetData[0]);
      }
    }
  }, [localFormData, formCheckSum, widgetData]);

  if (!localFormData) {
    return (<FullPageLoadingSpinner />);
  }

  const detailsFields = mapTableToForm({ columns });

  return (
    <div className="widget row-details-widget">
      <APIPolyForm
        formRendererType={FORM_RENDERER_TYPE.VERTICAL}

        baseRoute={baseRoute ?? ''}

        navigateBackOnDelete={navigateBackOnDelete}
        formCaption={formCaption ?? `${itemCaption} Details`.trim()}
        itemCaption={itemCaption}

        showHeader
        hideBottomButtons

        fields={detailsFields}

        primaryKeyFieldName={rowPrimaryKeyFieldName}
        formData={localFormData}
        formDataChecksum={formCheckSum}

        isReadOnly={isReadOnly}
        isEditing={isEditing}

        permittedActions={permittedActions}

        onStartEditRecord={onStartEdit ? () => onStartEdit() : undefined}
        onEndEditRecord={onEndEdit}
        onDeleteRecord={onDelete ? (id, formData, redirectInstruction) => onDelete(id, redirectInstruction) : undefined}
        onFieldChange={onDirtyChange ? () => onDirtyChange(isEditing) : undefined}
      />
    </div>
  );
};
