/**
 * @description
 * These icons are used throughout the application
 * They are defined as constants here so similar things can be consistently represented by the same icons
 *
 * @see https://fontawesome.com/v4.7.0/icons/
 * Where `fa-iconname` just specify `iconname`
 *
 * @see https://themify.me/themify-icons
 * Prefix with `ti` i.e. `ti-iconname`
 *
 * @see https://simplelineicons.github.io/
 * @see https://thesabbir.github.io/simple-line-icons/
 * Prefix with `icon` i.e. `icon-iconname`
 */
export const ICON = {
  HOME: 'icon-home',
  COMMS: 'icon-speech',
  NEWS: 'ti-book',
  NOTIFICATIONS: 'icon-bell',
  DASHBOARDS: 'icon-speedometer',
  EXECUTIVE_DASHBOARD: 'icon-chart',
  SALES_DASHBOARD: 'icon-pie-chart',
  SALES_PERFORMANCE: 'icon-trophy',
  ACTIVE_PROJECTS: 'ti-timer',
  PROJECT_SUMMARY: 'ti-timer',
  SALES_FORECAST: 'ti-stats-up',
  PROJECT_PORTAL: 'ti-briefcase',
  LEADS_AND_PROJECTS: 'ti-agenda',
  COMPANIES: 'building-o',
  CONTACTS: 'ti-id-badge',
  DOCS_AND_RESOURCES: 'ti-folder',
  CI_DOCUMENTS: 'ti-folder',
  CI_WIKI: 'ti-agenda',
  PRODUCT_RESOURCES: 'ti-files',
  PRICE_LISTS: 'ti-money',
  HR_PORTAL: 'icon-people',
  STAFF_PROFILES: 'ti-id-badge',
  MY_PROFILE: 'ti-user',
  MY_LEAVE: 'icon-plane',
  MY_EXPENSES: 'ti-receipt',
  MY_ALLOWANCES: 'ti-receipt',
  MY_TEAM: 'icon-star',
  MANAGE_LEAVE: 'ti-check-box',
  MANAGE_EXPENSES: 'icon-check',
  MANAGE_ALLOWANCES: 'icon-check',
  FINANCE_PORTAL: 'icon-calculator',
  PROCESS_LEAVE: 'icon-plane',
  PROCESS_EXPENSES: 'ti-notepad',
  EXPENSE_CLAIMS: 'ti-receipt',
  EXPENSE_CLAIM_BADGES: 'ti-layout-tab-v',
  PROCESS_ALLOWANCES: 'ti-receipt',
  SALES_TOOLKIT: 'ti-ruler-pencil',
  LED_DESIGNER: 'ti-blackboard',
  ROOM_DESIGNER: 'building-o',
  PORTAL_SETTINGS: 'ti-panel',
  USER_MANAGEMENT: 'icon-user',
  INTEGRATIONS: 'icon-rocket',
  CHECK_API_CONSTANTS: 'icon-organization',
  ROLES: 'icon-organization',
  ROLE_MANAGEMENT: 'icon-lock',
  GROUP_MANAGEMENT: 'icon-people',
  LOST_MONKEY_UFO: 'icon-question',
  SCARED_MONSTER: 'icon-exclamation',
  ORDERING: 'ti-truck',
  STOCK_ORDERS: 'ti-package',
  TEMPLATES: 'ti-pencil-alt',
  EXTERNAL_LINK: 'external-link',
  BROWSE_FOR_FILE: 'folder-open',
  FILE: 'ti-file',
  FILES: 'ti-files',
  CLEAR: 'times',
  MARKETING_RESOURCES: 'ti-shopping-cart',
  CHECK: 'check',
  CROSS: 'times',
  DOWNLOAD_FILE: 'download',
  VIEW_FILE: 'eye',
  ETA_SUMMARY: 'ti-notepad',
  ETA_ITEMS: 'ti-timer',
  INFO: 'info-circle',
  SEND_NOTIFICATIONS: 'envelope',
  ACTIVATE: 'bolt',
  ROLLBACK: 'undo',
  INTERNAL_PROJECTS: 'ti-agenda',
  INTERNAL_PROCESS_PROJECTS: 'line-chart',
  INTERNAL_DEVELOPMENT_PROJECTS: 'code-fork',
  PRODUCT_DEVELOPMENT_PROJECTS: 'television',
  RDE_PROJECTS: 'flask',
  INTERNAL_PROCESS: 'lightbulb-o',
  SPRINTS: 'icon-control-forward',
  TASKS: 'tasks',
  TASK_TEMPLATES: 'ti-view-list',
  TEAMS: 'users',
  PLATFORMS: 'ti-server',
  PROCESSES: 'cogs',
  SAVE: 'save',
  EDIT: 'pencil',
  ADD: 'plus-circle',
  CANCEL: 'times',
  UNLINK: 'unlink',
  DELETE: 'trash',
  LOADING: 'circle-o-notch',
  UPLOAD: 'upload',
  UNDO: 'undo',
  REJECT: 'ban',
  REVOKE: 'undo',
  ARCHIVE: 'file-archive-o',
  UNARCHIVE: 'undo',
  WITHDRAW: 'undo',
  EMAIL: 'mail',
  SUBMIT: 'upload',
  UNPROCESS: 'undo',
  UNPAY: 'unpay',
  ERROR: 'exclamation-triangle',
  USERS: 'users',
  ETA_DASHBOARD: 'ti-truck',
  CHANGE_ORDERS: 'random',
  DTOOLS_CACHE_IN_PROGRESS: 'cog',
  DTOOLS_CACHE_SUCCESS: 'check',
  DTOOLS_CACHE_FAILED: 'times',
  DTOOLS_PROJECT_CHECKED_OUT: 'external-link',
  EXCEL_DOWNLOAD: 'file-excel-o',
  CLOUD: 'cloud',
  CLOCK: 'clock',
  COMMENT: 'comment',
  COPY: 'clipboard',
  SERVICE_AND_SUPPORT: 'ti-headphone-alt',
  CONTRACTS: 'gavel',
  SUPPORT_CONTRACTS: 'wrench',
  LICENCES: 'file-text-o',
  SPACES: 'building-o',
  LOCATIONS: 'ti-map-alt',
  REGIONS: 'map-o',
  CONTRACT_TYPES: 'list',
  CONTRACT_SUBTYPES: 'indent',
  DOCUMENT: 'file-text-o',
  CARET_DOWN: 'caret-down',
  CARET_RIGHT: 'caret-right',
  SITE: 'ti-map-alt',
  LOCATION: 'icon-tag',
  BUILDING: 'building-o',
  LEVEL: 'icon-layers',
  ZONE: 'object-group',
  SPACE: 'cube',
  MENU: 'ti-menu',
  CONVERT_LOCATION: 'random',
  SAFE_MODE_ENABLED: 'lock',
  SAFE_MODE_DISABLED: 'unlock-alt',
  MOVE_UP: 'level-up',
  CONFIRM: 'question-circle',
  DEBUG: 'bug',
  SECURITY: 'shield',
  MAINTENANCE: 'wrench',
  EXPAND_ALL: 'expand',
  COLLAPSE_ALL: 'compress',
  SHOW_ROOT_NODE: 'eye',
  HIDE_ROOT_NODE: 'eye-slash',
  OPTIONS: 'icon-settings',
  SEARCH: 'search',
  PASTE_FROM_CLIPBOARD: 'paste',
  REFRESH: 'refresh',
  INVOICE_REQUESTS: 'file-text-o',
  SERVICE_JOBS: 'truck',
  FOLDER: 'ti-folder',
  SERVICE_CONTRACTS_FOLDER: 'ti-folder',
  NATIONAL_LEAVE_CALENDAR: 'calendar',
  PDF: 'file-pdf-o',
  EMAIL_JOB_QUEUED: 'envelope-o blink',
  CLOUD_UPLOAD: 'cloud-upload',
} as const;
export type ICON = typeof ICON;
export type AN_ICON = ICON[keyof ICON];
