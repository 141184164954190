import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { DataTablePageProps } from '../../types/data-table-page.props';
import { NewRecordModalResult } from '../../types/modal/modal-result';
import { IContactRecord } from '../../types/contact/contact.record.interface';

import { ModalContext } from '../modals/modal-context';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';

export type ContactDataTablePageProps = DataTablePageProps;

/**
 * Display the "Contacts" data table as a whole page
 */
export const ContactDataTablePage: React.FC<ContactDataTablePageProps> = ({
  baseFilters,
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const history = useHistory();
  const { showModal } = useContext(ModalContext);

  const actionHandlers = {
    create: () => {
      showModal<NewRecordModalResult<IContactRecord>>(MODAL_TYPE.NEW_CONTACT, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/crm/contacts/${modalResult.newRecord.id}`);
        },
      });
    },
  };

  return (
    <DataTablePage
      title="Contacts"
      tableIdentifier={TABLE_IDENTIFIER.CONTACT_TABLE}
      actionHandlers={actionHandlers}
      baseFilters={baseFilters}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      { children }
    </DataTablePage>
  );
};
