import React, { useContext } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { Editor } from '@tinymce/tinymce-react';
import FriendlyFormMessage from '../../layout-helpers/friendly-form-message';
import { FormButtons } from '../form-buttons';
import { UserAvatar } from '../../user-profiles/user-avatar';

import { TINYMCE_KEY } from '../../../utils/constants';
import { CurrentUserContext } from '../../providers/current-user-provider';
import { FormRendererProps } from '../../../types/poly-form/form-renderer.props';
import { INoteRecord } from '../../../types/note.record.interface';
import { ConstSelect } from '../../form-input/const-select';

export type NotesFormRendererProps = FormRendererProps & {
  formData: INoteRecord,
}

/**
 * NotesFormRenderer is designed to be used as part of the collection widget
 * to render multiple notes
 */
export const NotesFormRenderer: React.FC<NotesFormRendererProps> = (props) => {
  const {
    children,

    primaryKeyValue,
    fields,
    formData,

    isReadOnly,
    isNewRecord = false,
    isEditing,
    isLocked,
    isBusy = false,

    hasErrors = false,
    errors,
    hasSuccess = false,
    formMessage,

    permittedActions,

    formDeleteConfirmationType,

    onFieldChange,

    startEditRecord,
    endEditRecord,
    deleteRecord,
  } = props;

  const { userDetails } = useContext(CurrentUserContext);

  const userAudit = formData.user_audit;
  const createdBy = userAudit?.created_by || formData?.created_by || {};
  let createdAt = userAudit?.created_at || moment().toDate();
  let userName = createdBy?.name ?? 'Unknown User';

  if (isEditing && !createdBy?.name) userName = 'New user';

  if (isNewRecord) {
    userName = userDetails.name || userName;
    createdAt = moment().toDate();
  }

  const typeFilterResult = fields.filter((field) => field.name === 'type');
  const typeField = typeFilterResult.length > 0 ? typeFilterResult[0] : undefined;

  const bodyField = fields.find((field) => field.name === 'body');

  const AuthorBlock = () => (
    <div className="by-line">
      <span className="user-name">
        {` ${userName} `}
      </span>
      <span className="time-to-event">
        {moment(createdAt).fromNow()}
        &nbsp; - &nbsp;
        {moment(createdAt).format('D/M/YY h:mm a')}
      </span>
      <FormButtons
        inline
        secondary={false}
        deleteInEditMode

        permittedActions={permittedActions ?? {}}
        formDeleteConfirmationType={formDeleteConfirmationType}

        formIsEditing={isEditing}
        formIsReadOnly={isReadOnly}
        formIsCreating={isNewRecord}
        formIsBusy={isBusy}
        formIsLocked={isLocked}

        startEditRecord={startEditRecord ? () => startEditRecord(primaryKeyValue, formData, 'body') : undefined}
        endEditRecord={endEditRecord ? (saveChanges) => endEditRecord(saveChanges, primaryKeyValue, formData) : undefined}
        deleteRecord={deleteRecord ? () => deleteRecord(primaryKeyValue, formData) : undefined}
      />
    </div>
  );

  // Render
  return (
    <div
      className={classnames('portal-form', {
        'has-errors': hasErrors,
      })}
    >
      {children && children}
      <div className="row-wrapper" key={formData.id}>
        {typeField && (
          // todo: move styles to scss
          <div className="type-wrapper" style={{ minWidth: '300px', maxWidth: '650px', textAlign: 'left', paddingBottom: '0.6rem', paddingLeft: '0.5rem', paddingTop: '0.2rem' }}>
            {isEditing ? (
              <>
                <span>Note Type:   </span>
                <div
                  style={{
                    minWidth: '200px',
                    border: '1px solid silver',
                    display: 'inline-block',
                    padding: '1px 2px',
                    marginLeft: '16px',
                    borderRadius: '5px',
                  }}
                >
                  <ConstSelect
                    id="type"
                    name="type"
                    formData={formData}
                    onChange={onFieldChange}
                    isClearable={false}
                    disabled={!isEditing || isLocked}
                    formSaveField="type_id"
                    value={formData[typeField.name]}
                    inputOptions={typeField?.object?.inputOptions || typeField?.inputOptions || []}
                  />
                </div>
              </>
            ) : (
              <strong>
                {formData.type.name}
                {' '}
                Note
              </strong>
            )}
          </div>
        )}

        <div className="user-avatar-container">
          <UserAvatar name={userName} />
        </div>

        {/* Display Mode */}
        {!isEditing && (
          <div className="note-details" onDoubleClick={startEditRecord ? () => startEditRecord(primaryKeyValue, formData, 'body') : undefined}>
            {/* eslint-disable-next-line react/no-danger */}
            <div id="tiny" className="body" dangerouslySetInnerHTML={{ __html: formData.body }} />
            <AuthorBlock />
          </div>
        )}

        {/* Edit Mode */}
        {isEditing && (
          <div className="note-details">
            <div style={{ minHeight: '30px' }}>
              <Editor
                id={bodyField?.id}
                apiKey={TINYMCE_KEY}
                initialValue={formData.body ?? ''}
                init={{
                  branding: false,
                  menubar: false,
                  statusbar: false,
                  placeholder: bodyField?.placeholder ?? 'Insert your note here...',
                  inline: true,
                  plugins: 'link paste lists',
                  paste_as_text: true,
                  toolbar: 'undo redo | bold italic | link | bullist numlist',
                  valid_elements: 'a[href|target=_blank],strong/b,em,div,br,p,ol,ul,li',
                  contextmenu: false,
                // auto_focus: false,
                }}
                onChange={(e) => {
                  if (onFieldChange) {
                    onFieldChange({
                      fieldName: 'body',
                      newValue: e.target.getContent() === '<p></p>' ? null : e.target.getContent(),
                    });
                  }
                }}
              />
            </div>
            <AuthorBlock />
          </div>
        )}
      </div>
      <FriendlyFormMessage
        formMessage={formMessage}
        hasSuccess={hasSuccess}
        hasErrors={hasErrors}
        errors={errors}
        showList
        inline
      />
    </div>
  );
};
