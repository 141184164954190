export const TABLE_IDENTIFIER = {
  PROJECT_TABLE: 'projectTable',
  COMPANY_TABLE: 'companyTable',
  CONTACT_TABLE: 'contactTable',
  USER_MANAGEMENT_TABLE: 'userManagementTable',
  SECURITY_PERMISSIONS_TABLE: 'securityPermissionsTable',
  SECURITY_ROLES_TABLE: 'securityRolesTable',
  STATES_TABLE: 'statesTable',

  MY_LEAVE_TABLE: 'myLeaveTable',
  MANAGE_LEAVE_TABLE: 'manageLeaveTable',
  PROCESS_LEAVE_TABLE: 'processLeaveTable',

  MY_EXPENSES_TABLE: 'myExpensesTable',
  MANAGE_EXPENSES_TABLE: 'manageExpensesTable',
  PROCESS_EXPENSE_CLAIMS_TABLE: 'processExpenseClaimsTable',
  PROCESS_EXPENSE_BATCHES_TABLE: 'processExpenseBatchesTable',

  MY_ALLOWANCES_TABLE: 'myAllowancesTable',
  MANAGE_ALLOWANCES_TABLE: 'manageAllowancesTable',
  PROCESS_ALLOWANCES_TABLE: 'processAllowancesTable',

  SALES_PERFORMANCE_DRILLDOWN_TABLE: 'salesPerformanceDrillDownTable',

  EXEC_DASH_AGED_PROPOSALS_DRILLDOWN_TABLE: 'execDashAgedProposalsDrillDownTable',
  EXEC_DASH_PIPELINE_DRILLDOWN_TABLE: 'execDashPipelineDrillDownTable',
  EXEC_DASH_FY_DRILLDOWN_TABLE_TABLE: 'execDashFyDrillDownTable',
  EXEC_DASH_FY_LAST_DRILLDOWN_TABLE: 'execDashFyLastDrillDownTable',
  EXEC_DASH_FY_LAST_2_DRILLDOWN_TABLE: 'execDashFyLast2DrillDownTable',

  PROJECT_ITEM_ETA_SUMMARY_TABLE: 'projectItemEtaSummaryTable',
  PROJECT_ITEM_ETA_ITEMS_TABLE: 'projectItemEtaItemsTable',
  DTOOLS_CHANGE_ORDER_TABLE: 'dtoolsChangeOrderTable',

  ORDER_CONFIRMATION_TABLE: 'orderConfirmationTable',

  SPRINTS_TABLE: 'sprintsTableInitialSettings',
  INTERNAL_PROJECTS_TABLE: 'internalProjectsTableInitialSettings',
  INTERNAL_PROJECT_TASKS_TABLE: 'internalProjectTasksTableInitialSettings',
  TASK_TEMPLATES_TABLE: 'taskTemplatesTableInitialSettings',
  TEAMS_TABLE: 'teamsTableInitialSettings',
  PROCESSES_TABLE: 'processesTableInitialSettings',
  PLATFORMS_TABLE: 'platformsTableInitialSettings',

  CONTRACTS_TABLE: 'contractsTableInitialSettings',
  LICENCES_TABLE: 'licencesTableInitialSettings',
  SUPPORT_TABLE: 'supportTableInitialSettings',
  CONTRACT_TYPES_TABLE: 'contractTypesTableInitialSettings',
  CONTRACT_SUBTYPES_TABLE: 'contractSubtypesTableInitialSettings',
  CONTRACT_REGIONS_TABLE: 'contractRegionsTableInitialSettings',
  COMPANY_SPACE_TYPES_TABLE: 'companySpaceTypesTable',

  SERVICE_JOBS_TABLE: 'serviceJobsTable',

  INVOICE_REQUESTS_TABLE: 'invoiceRequestsTable',

  COMMISSION_STRUCTURE_ITERATIONS_TABLE: 'commissionStructureIterationsTableSettings',
  COMMISSION_STRUCTURE_BRACKETS_TABLE: 'commissionStructureBracketsTableSettings',
  MONTHLY_COMMISSION_SNAPSHOTS_TABLE: 'monthlyCommissionSnapshotsTableSettings',
  PROJECT_PROFITABILITY_SNAPSHOTS_TABLE: 'projectProfitabilityInitialSettings',
  COMMISSION_PAYMENT_BATCHES_TABLE: 'commissionPaymentBatchesTable',
} as const;

export type TABLE_IDENTIFIER = typeof TABLE_IDENTIFIER;
export type A_TABLE_IDENTIFIER = TABLE_IDENTIFIER[keyof TABLE_IDENTIFIER];

