import React from 'react';
import { Card, CardBody, CardText } from 'reactstrap';
import { WidgetProps } from '../../types/widget.props';

export type DtoolsItemNotesWidgetProps = Omit<WidgetProps, 'rowData'> & {
  rowData: {
    project_id: number,
    engineering_notes: string,
    project_manager_notes: string,
    procurement_notes: string,
    install_notes: string,
  }
}

/**
 * This widget shows the checklist items which have been left out of the main form view.
 *
 * @todo convince the user form to do a patch instead of a PUT
 */
export const DtoolsItemNotesWidget:React.FC<DtoolsItemNotesWidgetProps> = (props) => {
  const { rowData } = props;
  return (
    <Card className="dtools-item-notes-widget">
      <CardBody>
        <p><strong>Engineering Notes</strong></p>
        <CardText>
          {rowData.engineering_notes || '-'}
        </CardText>
        <p><strong>Project Manager Notes</strong></p>
        <CardText>
          {rowData.project_manager_notes || '-'}
        </CardText>
        <p><strong>Procurement Notes</strong></p>
        <CardText>
          {rowData.procurement_notes || '-'}
        </CardText>
        <p><strong>Install Notes</strong></p>
        <CardText>
          {rowData.install_notes || '-'}
        </CardText>
      </CardBody>
    </Card>
  );
};
