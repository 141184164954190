export const PROJECT_FORECAST_TYPE = {
  PROJECT_TOTAL: 1,
  PROJECT_PARTIAL: 2,
  VARIATION: 3,
  RETENTION: 4,
} as const;
export type PROJECT_FORECAST_TYPE = typeof PROJECT_FORECAST_TYPE;
export type A_PROJECT_FORECAST_TYPE = PROJECT_FORECAST_TYPE[keyof PROJECT_FORECAST_TYPE];

export const PROJECT_FORECAST_TYPE_NAME_MAP: {[K in A_PROJECT_FORECAST_TYPE]: string} = {
  [PROJECT_FORECAST_TYPE.PROJECT_TOTAL]: 'Project Total',
  [PROJECT_FORECAST_TYPE.PROJECT_PARTIAL]: 'Project Partial',
  [PROJECT_FORECAST_TYPE.VARIATION]: 'Variation',
  [PROJECT_FORECAST_TYPE.RETENTION]: 'Retention',
};
