import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';

export const ProjectContractsWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Contract',
  className: 'contracts-widget',
  isReadOnly: true,
  showAddBtn: false,
  fields: [
    {
      name: 'project_id',
      title: 'Project ID',
      visible: false,
      isParentId: true,
    },
    {
      name: 'number',
      title: 'Number',
      format: COLUMN_FORMAT.NAME_LINK_SHORT,
      linkRoute: '/service-and-support/contracts',
      sortColumn: 'id',
    },
    {
      name: 'status',
      title: 'Status',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        key: 'name',
        sourceField: 'status',
        format: COLUMN_FORMAT.STATUS_BADGE,
      },
    },
    {
      name: 'type',
      title: 'Type',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        key: 'name',
        sourceField: 'type',
        format: COLUMN_FORMAT.TEXT,
      },
      sortColumn: 'type.name',
    },
    {
      name: 'subtype',
      title: 'Subtype',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        key: 'name',
        sourceField: 'subtype',
        format: COLUMN_FORMAT.TEXT,
      },
      sortColumn: 'subtype.name',
    },
    {
      name: 'contract_value',
      title: 'Value',
      format: COLUMN_FORMAT.WHOLE_DOLLARS,
    },
    {
      name: 'contract_months',
      title: 'Term',
      description: 'Length of the contract in months',
      format: COLUMN_FORMAT.NUMBER,
    },
    {
      name: 'start_date',
      title: 'Start',
      format: COLUMN_FORMAT.DATE,
    },
    {
      name: 'end_date',
      title: 'End',
      format: COLUMN_FORMAT.DATE,
    },
    {
      name: 'serial',
      title: 'Serial',
      format: COLUMN_FORMAT.TEXT,
    },
    {
      name: 'billing_type',
      title: 'Billing Type',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        key: 'name',
        sourceField: 'billing_type',
        format: COLUMN_FORMAT.TEXT,
      },
      visible: true,
    },
    {
      name: 'region',
      title: 'Region',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        key: 'name',
        sourceField: 'region',
        format: COLUMN_FORMAT.TEXT,
      },
      visible: true,
    },
    {
      name: 'provider',
      title: 'Provider',
      description: 'The company providing the contract',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'provider',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/crm/companies',
      },
      sortColumn: 'provider.name',
    },
    {
      name: 'renewed_from',
      title: 'Renewed From',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'renewed_from',
        key: 'number',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/service-and-support/contracts',
      },
    },
    {
      name: 'renewed_to',
      title: 'Renewed As',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'renewed_to',
        key: 'number',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/service-and-support/contracts',
      },
    },
  ],
};
