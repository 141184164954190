export const DATA_TABLE_FLAGS = {
  ONLY_MINE: 'onlyMine',
  DELETED: 'deleted',
  INCLUDE_ARCHIVED: 'includeArchived',
  ADMIN_SEE_ALL_NOTIFICATIONS: 'adminSeeAllNotifications',
  MY_STATE: 'myState',
  NATIONAL: 'national',
} as const;

export type DATA_TABLE_FLAGS = typeof DATA_TABLE_FLAGS;
export type A_DATA_TABLE_FLAG = DATA_TABLE_FLAGS[keyof DATA_TABLE_FLAGS];
