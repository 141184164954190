export const ACTIVATE_PROJECT_MODAL_WIZARD_PAGE = {
  PAGE_1_PURCHASE_ORDER_INFORMATION: 0,
  PAGE_2_DTOOLS_AS_SOLD_PROJECT: 1,
  PAGE_3_REVIEW_INVOICE_INFORMATION: 2,
  PAGE_4_CHECKLIST_ITEMS: 3,
  PAGE_5_SEND_NOTIFICATIONS: 4,
  PAGE_6_PROCESSING: 5,
} as const;
export type ACTIVATE_PROJECT_MODAL_WIZARD_PAGE = typeof ACTIVATE_PROJECT_MODAL_WIZARD_PAGE;
export type AN_ACTIVATE_PROJECT_MODAL_WIZARD_PAGE = ACTIVATE_PROJECT_MODAL_WIZARD_PAGE[keyof ACTIVATE_PROJECT_MODAL_WIZARD_PAGE];
