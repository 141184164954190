import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { createAutoInvalidatingCache } from '../../utils/create-auto-invalidating-cache';
import teamsIcon from '../../images/teams-icon.png';
import portalIcon from '../../images/ci-logo-large.png';
import Icon from './icon';

export const MSTeamsContext = React.createContext();

export const MSTeamsConsumer = MSTeamsContext.Consumer;


/**
 * @class MsTeamsProvider
 *
 * @description
 * This component simply detects whether the local machine has Microsoft Teams installed.
 *
 * If so, it stores a value to the local storage and uses this value next time to avoid having
 * to detect on every boot up.
 */
const MsTeamsProvider = function MsTeamsProvider(props) {
  const { children } = props;

  const mSTeamsClientPreferenceCache = useRef(createAutoInvalidatingCache(null, 'ms-teams-installed'));
  const useMsTeamsClient = mSTeamsClientPreferenceCache.current.get();

  const [msTeamsClientPreferenceSelected, setMsTeamsClientPreferenceSelected] = useState(useMsTeamsClient !== undefined);

  /**
   * @description
   * Set whether the user want's to use the teams client or not
   *
   * @param {boolean} useTeamsClient
   */
  const setUseTeamsClientPreference = useCallback((useTeamsClient) => {
    mSTeamsClientPreferenceCache.current.set(useTeamsClient);
    setMsTeamsClientPreferenceSelected(true);
  }, [setMsTeamsClientPreferenceSelected]);


  /**
   * @description
   * Passed down to consumers, this method will clear our previous knowledge
   * of the presence of a teams client on the machine and thus, present the user
   * with a prompt to detect the client again.
   */
  const clearUseTeamsClientPreference = useCallback(() => {
    mSTeamsClientPreferenceCache.current.set(undefined);
    setMsTeamsClientPreferenceSelected(false);
  }, [setMsTeamsClientPreferenceSelected]);


  /**
   * @description
   * Take a normal URL to a Microsoft Teams Resource and return either it,
   * or a modified link to the msteams:// protocol link if the Microsoft
   * Teams Client has previously been detected on this device.
   *
   * @param {string} webUrl
   *
   * @returns {string}
   */
  const getMsTeamsUrl = useCallback((webUrl) => {
    if (useMsTeamsClient && webUrl) return webUrl.replace(/^(http|https):\/\//, 'msteams://');
    return webUrl;
  }, [useMsTeamsClient]);


  /**
   * @description
   * Returns either "_self" or "_blank" depending on whether the useMsTeamsClient is true or false
   *
   * @returns {string}
   */
  const getMsTeamsUrlTarget = useCallback(() => {
    if (useMsTeamsClient) return '_self';
    return '_blank';
  }, [useMsTeamsClient]);


  /**
   * @description
   * Evaluates a Resource ID and returns true if the start of the resource Id begins with 'TBA'
   *
   * @param {string} resourceId
   *
   * @returns {boolean}
   */
  function isResourceIdPending(resourceId) {
    return (resourceId && resourceId.startsWith('TBA'));
  }


  /**
   * @description
   * Evaluates a Resource ID and returns true if it represents a valid link
   *
   * @param {string} resourceId
   *
   * @returns {boolean}
   */
  function isResourceIdValid(resourceId) {
    return (resourceId && !isResourceIdPending(resourceId));
  }

  return (
    <MSTeamsContext.Provider
      value={{
        useMsTeamsClient,
        clearUseTeamsClientPreference,
        getMsTeamsUrl,
        getMsTeamsUrlTarget,
        isResourceIdPending,
        isResourceIdValid,
      }}
    >

      {!msTeamsClientPreferenceSelected && (
        <div className="ms-teams-detector">
          <div className="content">
            <div className="icon-wrapper">
              <img src={portalIcon} alt="Ci Portal" />
              <Icon i="plus" />
              <img src={teamsIcon} alt="Microsoft Teams" />
            </div>
            <div className="request-wrapper">
              <h2>Portal is better with Teams</h2>
              <p>
                Portal integrates closely with Microsoft Teams. You can choose how you want to open and
                view any Teams content from Portal using the options below.
              </p>
              <p>
                If your device has the Microsoft Teams App installed, click
                <strong> Use the Teams App</strong>
                . From then on, your Microsoft Teams content will open directly in your Microsoft Teams App.
              </p>
              <p>
                If you don&apos;t have the Microsoft Teams App installed or don&apos;t want to use it, you can choose
                <strong> Use Teams in your Web Browser</strong>
                , which will result in any integrated Teams content opening in the Microsoft Teams Web Client in your browser instead.
              </p>
              <p>
                You can change your decision at any time by clicking on the option in the user menu (i.e. by clicking on your profile photo).
              </p>
              <div className="buttons">
                <Button
                  onClick={() => setUseTeamsClientPreference(false)}
                >
                  Use Teams in your Web Browser
                </Button>
                <Button
                  onClick={() => setUseTeamsClientPreference(true)}
                  color="primary"
                >
                  Use the Teams App
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {msTeamsClientPreferenceSelected && children}
    </MSTeamsContext.Provider>
  );
};

MsTeamsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

MsTeamsProvider.defaultProps = {
  children: null,
};

/**
 * @description
 * Connect a component to the ms teams provider
 * (requires the component to be nested under the ModalProvider)
 *
 * @param {JSX.Element} Component
 */
export const connectToMSTeamsProvider = (Component) => function msTeamsProviderConnector(props) {
  return (
    <MSTeamsConsumer>
      {(msTeamsProvider) => (<Component {...props} msTeamsProvider={msTeamsProvider} />)}
    </MSTeamsConsumer>
  );
};

export default MsTeamsProvider;
