import moment from 'moment';

import { defaultTableSettings } from './default-table-settings';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';
import { DATA_TABLE_FLAGS } from '../constants/data-table-flags.const';

/**
 * Table Definition for Project ETA item Detail
 */
const etaItemsTableInitialSettings = {
  ...defaultTableSettings,
  baseRoute: '/dtools-cache/project-eta-items',
  baseQueryString: [
    'with[]=project:id,project_number,partner_program_id,status_id,likelihood',
    'with[]=project.status:id,name',
    'with[]=client:id,name',
    'with[]=projectManager:project_id,project_manager_id,name',
    // this join is way too expensive even with indexes... might be optimised with a foreign key?
    // 'with[]=projectCacheSummary:dtools_project_guid,checked_out_to,last_cache_success,item_pages_to_cache,item_pages_cached',
  ].join('&'),
  availableFlags: [DATA_TABLE_FLAGS.ONLY_MINE],

  columnPresets: [
    {
      name: 'default',
      title: 'Default',
      fields: [
        'cache_status',
        'project_number',
        'status',
        'likelihood',
        'project_manager',
        'number',
        'change_order_number',
        'vendor',
        'manufacturer',
        'model',
        'required_by',
        'expected_delivery_date',
        'edd_last_updated',
        'order_number',
        'expected_in_days',
        'required_in_days',
      ],
    },
    {
      name: 'all',
      title: 'all',
      fields: [
        'cache_status',
        'checked_out_to',
        'project_id',
        'project_number',
        'status',
        'number',
        'likelihood',
        'project_manager',
        'name',
        'client',
        'change_order_number',
        'location_name',
        'location_full_name',
        'category',
        'subcategory',
        'short_description',
        'vendor',
        'manufacturer',
        'model',
        'required_by',
        'expected_delivery_date',
        'edd_last_updated',
        'order_number',
        'order_status',
        'required_year',
        'required_month',
        'expected_in_days',
        'required_in_days',
        'updated_at',
        'non_billable',
        'owner_furnished',
        'unit_cost',
        'unit_price',
        'labor_hours',
        'labor_unit_cost',
        'labor_unit_price',
        'order_date',
        'dispatched_date',
        'engineering_notes',
        'procurement_notes',
        'project_manager_notes',
        'install_notes',
        'dtools_item_last_updated',
        'last_cache_success',
        // 'deleted_at',
      ],
    },
  ],

  /**
  * Definition of Columns for this table
  */
  columns: [
    {
      name: 'cache_status',
      sortColumn: 'checked_out_to',
      title: '?',
      description: 'D-Tools Cache Status',
      format: COLUMN_FORMAT.DTOOLS_CACHE_STATUS,
      lockedInTable: true,
      visible: true,
      order: 0,
      formOrder: 0,
    },
    {

      name: 'checked_out_to',
      title: 'Checked Out To',
      description: 'User the project is checked out to, if any.',
      format: COLUMN_FORMAT.TEXT,
      sortColumn: 'projectCacheSummary.checked_out_to',
      visible: false,
      order: 10,
      formOrder: 20,
    },
    {
      name: 'project_id',
      title: 'Project Id',
      description: 'D-Tools P-Number',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 100,
      formOrder: 100,
    },
    {
      name: 'project_number',
      title: 'P-Number',
      description: 'Project Unique Identifier',
      format: COLUMN_FORMAT.OBJECT,
      formSaveField: 'project.project_number', // filthy filter hack
      sortColumn: 'project_id',
      object: {
        key: 'project_number',
        sourceField: 'project',
        format: COLUMN_FORMAT.P_NUMBER,
        linkId: 'id',
        linkRoute: '/crm/projects',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      order: 110,
      formOrder: 110,
    },
    {
      name: 'status',
      title: 'Status',
      description: 'Current Status of Project',
      format: COLUMN_FORMAT.OBJECT,
      formSaveField: 'project.status_id',
      sortColumn: 'project.status_id',
      filterOnField: 'project.status_id',
      object: {
        sourceField: 'project',
        key: '_status_name',
        format: COLUMN_FORMAT.STATUS_BADGE,
        listSource: 'projectStatus',
      },
      visible: true,
      order: 120,
      formOrder: 120,
    },
    {
      name: 'likelihood',
      title: 'Likelihood',
      description: 'Current Likelihood of Project',
      format: COLUMN_FORMAT.OBJECT,
      formSaveField: 'project.likelihood',
      object: {
        sourceField: 'project',
        key: 'likelihood',
        format: COLUMN_FORMAT.LIKELIHOOD,
      },
      visible: true,
      order: 125,
      formOrder: 125,
    },
    {
      name: 'project_manager',
      title: 'Project Manager',
      description: 'The assigned project manager resource',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'project_manager',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'project_manager_id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      formSaveField: 'projectManager.project_manager_id',
      sortColumn: 'projectManager.name',
      visible: true,
      showInForm: true,
      order: 130,
      formOrder: 130,
    },
    {
      name: 'number',
      title: 'D-Tools Project',
      description: 'P-Number from D-Tools',
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      format: COLUMN_FORMAT.NAME_LINK,
      linkId: 'number',
      linkRoute: '/crm/ordering/project-eta-items/project',
      visible: true,
      order: 140,
      formOrder: 140,
    },
    {
      name: 'name',
      title: 'Project Name',
      description: 'Project Working Title',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: false,
      order: 150,
      formOrder: 150,
    },
    {
      name: 'client',
      title: 'Client',
      description: 'The end user client for the project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'client',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/crm/companies',
        listSource: 'company',
      },
      formFieldType: FORM_FIELD_TYPE.COMPANY_PICKER,
      formSaveField: 'project.client_id',
      sortColumn: 'project.client_id',
      filterOnField: 'project.client_id',
      visible: false,
      showInForm: true,
      isCreatable: true,
      order: 155,
      formOrder: 155,
    },
    {
      name: 'change_order_number',
      title: 'Change Order Number',
      description: 'Change Order Number',
      format: COLUMN_FORMAT.ID_LINK,
      linkId: 'id',
      linkRoute: (row) => `/crm/ordering/project-eta-items?pdt=v=all;ps=15;p=1;cf=change_order_number:eq+${row.change_order_number},project_number:eq+${row.project_number};_=1`,
      visible: true,
      order: 158,
      formOrder: 158,
    },
    {
      name: 'location_name',
      title: 'Location Name',
      description: 'Location Name',
      format: COLUMN_FORMAT.TEXT,
      visible: false,
      order: 160,
      formOrder: 160,
    },
    {
      name: 'location_full_name',
      title: 'Location Full Name',
      description: 'Location Full Name',
      format: COLUMN_FORMAT.TEXT,
      visible: false,
      order: 170,
      formOrder: 170,
    },
    {
      name: 'category',
      title: 'Category',
      description: 'Category',
      format: COLUMN_FORMAT.TEXT,
      visible: false,
      order: 180,
      formOrder: 180,
    },
    {
      name: 'subcategory',
      title: 'Subcategory',
      description: 'Subcategory',
      format: COLUMN_FORMAT.TEXT,
      visible: false,
      order: 190,
      formOrder: 190,
    },
    {
      name: 'short_description',
      title: 'Product Description',
      description: 'Project Working Title',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: false,
      order: 200,
      formOrder: 200,
    },
    {
      name: 'vendor',
      title: 'Vendor',
      description: 'Vendor',
      format: COLUMN_FORMAT.NAME_LINK,
      linkId: 'vendor',
      linkRoute: '/crm/ordering/project-eta-items/vendor',
      visible: true,
      order: 210,
      formOrder: 210,
    },
    {
      name: 'manufacturer',
      title: 'Make',
      description: 'Make',
      format: COLUMN_FORMAT.NAME_LINK,
      linkId: 'manufacturer',
      linkRoute: '/crm/ordering/project-eta-items/manufacturer',
      visible: true,
      order: 220,
      formOrder: 220,
    },
    {
      name: 'model',
      title: 'Model',
      description: 'Model',
      format: COLUMN_FORMAT.NAME_LINK,
      linkId: 'model',
      linkRoute: '/crm/ordering/project-eta-items/model',
      visible: true,
      order: 230,
      formOrder: 230,
    },
    {
      name: 'non_billable',
      title: 'Non Billable',
      visible: false,
      format: COLUMN_FORMAT.CHECKBOX,
      order: 240,
      formOrder: 240,
    },
    {
      name: 'owner_furnished',
      title: 'Owner Furnished',
      visible: false,
      format: COLUMN_FORMAT.CHECKBOX,
      order: 250,
      formOrder: 250,
    },
    {
      name: 'unit_cost',
      title: 'Unit Cost',
      visible: false,
      format: COLUMN_FORMAT.CURRENCY,
      order: 260,
      formOrder: 260,
    },
    {
      name: 'unit_price',
      title: 'Unit Price',
      visible: false,
      format: COLUMN_FORMAT.CURRENCY,
      order: 270,
      formOrder: 270,
    },
    {
      name: 'labor_hours',
      title: 'Labor Hours',
      visible: false,
      format: COLUMN_FORMAT.NUMBER_SHORT,
      order: 280,
      formOrder: 280,
    },
    {
      name: 'labor_unit_cost',
      title: 'Labor Unit Cost',
      visible: false,
      format: COLUMN_FORMAT.CURRENCY,
      order: 290,
      formOrder: 290,
    },
    {
      name: 'labor_unit_price',
      title: 'Labor Unit Price',
      visible: false,
      format: COLUMN_FORMAT.TEXT,
      order: 300,
      formOrder: 300,
    },
    {
      name: 'order_date',
      title: 'Order Date',
      visible: false,
      format: COLUMN_FORMAT.DATE,
      order: 310,
      formOrder: 310,
    },
    {
      name: 'dispatched_date',
      title: 'Dispatched Date',
      visible: false,
      format: COLUMN_FORMAT.DATE,
      order: 320,
      formOrder: 320,
    },
    {
      name: 'engineering_notes',
      title: 'Engineering Notes',
      visible: false,
      showInForm: false,
      format: COLUMN_FORMAT.LONG_TEXT,
      order: 330,
      formOrder: 330,
    },
    {
      name: 'procurement_notes',
      title: 'Procurement Notes',
      visible: false,
      showInForm: false,
      format: COLUMN_FORMAT.LONG_TEXT,
      order: 340,
      formOrder: 340,
    },
    {
      name: 'project_manager_notes',
      title: 'Project Manager Notes',
      visible: false,
      showInForm: false,
      format: COLUMN_FORMAT.LONG_TEXT,
      order: 350,
      formOrder: 350,
    },
    {
      name: 'install_notes',
      title: 'Install Notes',
      visible: false,
      showInForm: false,
      format: COLUMN_FORMAT.LONG_TEXT,
      order: 360,
      formOrder: 360,
    },
    {
      name: 'dtools_item_last_updated',
      title: 'Dtools Item Last Updated',
      visible: false,
      format: COLUMN_FORMAT.DATE,
      order: 370,
      formOrder: 370,
    },
    {
      name: 'required_by',
      title: 'Required By',
      description: 'Required By Date',
      format: COLUMN_FORMAT.DATE,
      visible: true,
      order: 380,
      formOrder: 380,
    },
    {
      name: 'expected_delivery_date',
      title: 'EDD',
      description: 'Expected Delivery Date',
      format: COLUMN_FORMAT.DATE,
      visible: true,
      order: 390,
      formOrder: 390,
    },
    {
      name: 'edd_last_updated',
      title: 'EDD Updated',
      description: 'EDD Last Updated on',
      format: COLUMN_FORMAT.DATE,
      visible: true,
      order: 400,
      formOrder: 400,
    },
    {
      name: 'order_number',
      title: 'Order #',
      description: 'Order Number',
      format: COLUMN_FORMAT.NAME_LINK,
      linkId: 'order_number',
      linkRoute: '/crm/ordering/project-eta-items/order',
      visible: true,
      order: 410,
      formOrder: 410,
    },
    {
      name: 'order_status',
      title: 'Order Status',
      description: 'Item Order Status',
      format: COLUMN_FORMAT.TEXT,
      visible: false,
      order: 420,
      formOrder: 420,
    },
    {
      name: 'required_year',
      title: 'Required Year',
      description: 'Required Year',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 430,
      formOrder: 430,
    },
    {
      name: 'required_month',
      title: 'Required Month',
      description: 'Required Month',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 440,
      formOrder: 440,
    },
    {
      name: 'expected_in_days',
      title: 'Expected In Days',
      description: 'Expected In Days',
      format: COLUMN_FORMAT.NULLABLE_NUMBER,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      className: (value, inTable) => (value < 0 ? 'text-danger' : 'text-success'),
      visible: true,
      order: 450,
      formOrder: 450,
    },
    {
      name: 'required_in_days',
      title: 'Required In Days',
      description: 'Required In Days',
      format: COLUMN_FORMAT.NULLABLE_NUMBER,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      className: (value, inTable) => (value < 0 ? 'text-danger' : 'text-success'),
      visible: true,
      order: 460,
      formOrder: 460,
    },
    {
      title: 'Last Refreshed',
      name: 'updated_at',
      sortColumn: 'updated_at',
      filterOnField: 'updated_at',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'updated_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: false,
      order: 470,
      formOrder: 470,
    },
    {
      title: 'Cache Success',
      name: 'last_cache_success',
      format: COLUMN_FORMAT.CHECKBOX,
      visible: false,
      order: 480,
      formOrder: 480,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'ETA Item Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'notes',
      title: 'Notes',
      apiRoute: '/',
      description: 'ETA Item Notes',
      widgetDefinition: WIDGET_DEFINITION.DTOOLS_ITEM_NOTES,
    },
  ],

  /**
   * View Definitions
   */
  viewKey: 'all',
  views: [
    {
      key: 'all',
      title: 'All D-Tools Items',
      filters: [],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAGS.ONLY_MINE],
          title: 'My Projects: D-Tools Items',
        },
      ],
      flags: [],
    },
    {
      key: 'overdue',
      title: 'All Overdue Items',
      filters: [
        {
          field: 'expected_in_days',
          operation: API_FILTER_OPERATION.LESS_THAN,
          values: [1],
        },
      ],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAGS.ONLY_MINE],
          title: 'My Projects: Overdue Items',
        },
      ],
      flags: [],
    },
    {
      key: 'dueInTenDays',
      title: 'Due within 10 days',
      filters: [
        {
          field: 'expected_in_days',
          operation: API_FILTER_OPERATION.LESS_THAN,
          values: [10],
        },
        {
          field: 'expected_in_days',
          operation: API_FILTER_OPERATION.GREATER_THAN,
          values: [-1],
        },
      ],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAGS.ONLY_MINE],
          title: 'My Projects: Due within 10 days',
        },
      ],
      flags: [],
    },
    {
      key: 'noExpectedDelivery',
      title: 'No ETA yet',
      filters: [
        {
          field: 'expected_delivery_date',
          operation: API_FILTER_OPERATION.IS_NULL,
          values: [],
        },
      ],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAGS.ONLY_MINE],
          title: 'My Projects: No ETA Yet',
        },
      ],
      flags: [],
    },
    {
      key: 'overTwoWeeksOld',
      title: 'EDD over 2 weeks old',
      filters: [
        {
          field: 'expected_delivery_date',
          operation: API_FILTER_OPERATION.LESS_THAN,
          values: [
            moment().subtract(2, 'weeks').format('YYYY-MM-DD 00:00:01'),
          ],
        },
      ],
      flagTitles: [
        {
          flags: [DATA_TABLE_FLAGS.ONLY_MINE],
          title: 'My Projects: EDD over 2 weeks old',
        },
      ],
      flags: [],
    },
  ],
};

export default etaItemsTableInitialSettings;
