import { COLUMN_FORMAT } from '../constants/column-format.const';
// import { ServiceJobInvoiceRequestsWidget } from '../components/widgets/serviceJob-invoices.widget';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { INVOICE_REQUEST_TYPE } from '../constants/invoice-request-type.const';
import { INVOICE_REQUEST_STATUS } from '../constants/invoice-request-status.const';
import { IContactRecord } from '../types/contact/contact.record.interface';
import { fullName } from '../helpers/full-name.helper';
import { IServiceJobRecord } from '../types/service/service-job.record.interface';
import { ICompanyRecord } from '../types/company/company.record.interface';

export const ServiceJobInvoiceRequestsWidgetDefinition: ICollectionWidgetDefinition = {
  // component: ServiceJobInvoiceRequestsWidget,
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Invoice',
  fields: [
    {
      name: 'service_job_id',
      visible: false,
      isParentId: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'id',
      title: 'ID',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      format: COLUMN_FORMAT.ID_LINK,
      linkRoute: '/finance/invoice-requests',
    },
    {
      name: 'comment',
      title: 'Internal Name',
      visible: true,
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: '/finance/invoice-requests',
    },
    {
      name: 'bill_to_contact',
      title: 'Billing Contact',
      placeholder: 'Billing Contact',
      visible: true,
      showInForm: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'bill_to_contact',
        key: 'id',
        getValue: (value: number, rowData: IContactRecord) => fullName(rowData),
        format: COLUMN_FORMAT.TEXT,
      },
      formFieldType: FORM_FIELD_TYPE.CONTACT_PICKER,
      formSaveField: 'bill_to_contact_id',
      isCreatable: true,
      getNewItemData: (inputValue: string, formData: IContactRecord, parentData?: IServiceJobRecord): {
        company_id: null | number,
        company?: null | ICompanyRecord,
      } => ({
        company_id: parentData?.client_id ?? null,
        company: parentData?.client ?? null,
      }),
    },
    {
      name: 'type_id',
      visible: false,
      title: 'Invoice type',
      initialValue: INVOICE_REQUEST_TYPE.JOB,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'type',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formSaveField: 'type_id',
    },
    {
      name: 'status_id',
      visible: true,
      title: 'Invoice status',
      initialValue: INVOICE_REQUEST_STATUS.DRAFT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'status',
        key: 'name',
        format: COLUMN_FORMAT.STATUS_BADGE,
      },
      formSaveField: 'type_id',
    },
    {
      name: 'sum_of_details_total_price',
      title: 'Total',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
    },
  ],
};
