import React from 'react';
import classNames from 'classnames';

export type ModalCurrentPageIndicatorProps = {
  currentPageIndex: number,
  totalPages: number,
};


/**
 * Displays a run of dots that indicate to the user what step out of a total number of steps they are up to
 * in a wizard process
 */
export const ModalWizardPageIndicator:React.FC<ModalCurrentPageIndicatorProps> = ({
  currentPageIndex,
  totalPages,
}) => {
  const pageDotElements:React.ReactElement[] = [];

  let i: number;
  for (i = 0; i < totalPages; i += 1) {
    pageDotElements.push((
      <div
        key={`page_${i}`}
        className={classNames(
          'page-dot',
          {
            active: currentPageIndex === i,
          },
        )}
      />
    ));
  }

  return (
    <div className="modal-wizard-page-indicator">
      <div
        className="page-dot-wrapper"
        style={{
          gridTemplateColumns: `repeat(${totalPages}, auto)`,
        }}
      >
        {pageDotElements}
      </div>
    </div>
  );
};
