import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import moment from 'moment';

import { ICompanyRecord } from '../../types/company/company.record.interface';

import ProjectsByStatusChart from '../portal-fusion-chart/projects-by-status-chart';

import { startCase } from '../../utils/helpers';

import ClientForecastBalanceByMonthChart from '../portal-fusion-chart/client-forecast-balance-by-month-chart';
import FUSION_CHART_DATASET_RENDER_TYPES from '../../utils/fusion-chart-dataset-render-types';
import { WidgetProps } from '../../types/widget.props';

import { PROJECTS_BY_STATUS_MONTH_VALUE_TYPE } from '../portal-fusion-chart/projects-by-status-month-chart';
import { A_PROJECTS_BY_STATUS_VALUE_TYPE, PROJECTS_BY_STATUS_VALUE_TYPE } from '../../constants/projects-by-status-value-type.const';
import { A_CLIENT_FORECAST_BALANCE_BY_MONTH_VALUE_TYPE, CLIENT_FORECAST_BALANCE_BY_MONTH_VALUE_TYPE } from '../../constants/client-forecast-balance-by-month-value-type.const';
import { CLIENT_FORECAST_BALANCE_BY_MONTH_SERIES_TYPE } from '../../constants/client-forecast-balance-by-month-series-type.const';

const clientProjectsByStatusChartId = 'projects_by_client_by_status_chart';
const clientForecastBalancesByMonthChartId = 'forecast_balances_by_month_chart';


export type CompanyDashboardsWidgetProps = Omit<WidgetProps, 'rowData'> & {
  rowData: ICompanyRecord,
}


export type CompanyDashboardsWidgetState = {
  clientProjectsByStatusChartIsLoading: boolean,
  clientProjectsByStatusChartValueType: A_PROJECTS_BY_STATUS_VALUE_TYPE,
  clientProjectsByStatusChartIncludeArchived: boolean,

  // clientForecastBalancesByMonth
  clientForecastBalancesByMonthChartIsLoading: boolean,
  clientForecastBalancesByMonthChartValueType: A_CLIENT_FORECAST_BALANCE_BY_MONTH_VALUE_TYPE,
}


export class CompanyDashboardsWidget extends React.Component<CompanyDashboardsWidgetProps, CompanyDashboardsWidgetState> {
  /**
   * @constructor
   */
  constructor(props: CompanyDashboardsWidgetProps) {
    super(props);

    this.state = {
      // clientProjectsByStatusChart
      clientProjectsByStatusChartIsLoading: true,
      clientProjectsByStatusChartValueType: PROJECTS_BY_STATUS_VALUE_TYPE.VALUE,
      clientProjectsByStatusChartIncludeArchived: false,

      // clientForecastBalancesByMonth
      clientForecastBalancesByMonthChartIsLoading: true,
      clientForecastBalancesByMonthChartValueType: PROJECTS_BY_STATUS_VALUE_TYPE.VALUE,
    };
  }


  /**
   * @description
   * Fired when the a chart starts or finishes loading
   *
   * @param chartId
   * @param isLoading
   */
  handleChartLoading = (chartId: string, isLoading: boolean): void => {
    if (chartId === clientProjectsByStatusChartId) { this.setState({
      clientProjectsByStatusChartIsLoading: isLoading,
    }); }

    else if (chartId === clientForecastBalancesByMonthChartId) { this.setState({
      clientForecastBalancesByMonthChartIsLoading: isLoading,
    }); }
  }


  /**
   * Set the value type for a chart
   *
   * @param chartId
   * @param valueType
   */
  setChartValueType = (chartId: string, valueType: A_PROJECTS_BY_STATUS_VALUE_TYPE): void => {
    if (chartId === clientProjectsByStatusChartId) { this.setState({
      clientProjectsByStatusChartValueType: valueType,
    }); }

    else if (chartId === clientForecastBalancesByMonthChartId) { this.setState({
      clientForecastBalancesByMonthChartValueType: valueType,
    }); }
  }


  /**
   * Set "includeArchived" on the client projects by status chart
   *
   * @param shouldIncludeArchived
   */
  setIncludeArchived = (shouldIncludeArchived: boolean): void => {
    this.setState({ clientProjectsByStatusChartIncludeArchived: shouldIncludeArchived });
  }


  /**
   * Render the Client Project's chart
   */
  renderClientProjectsByStatusChart = (): React.ReactNode => {
    const { rowData } = this.props;
    const {
      clientProjectsByStatusChartIsLoading,
      clientProjectsByStatusChartValueType,
      clientProjectsByStatusChartIncludeArchived,
    } = this.state;

    let clientProjectsByStatusChartCaption = 'Project Value by Status (AUD)';
    if (clientProjectsByStatusChartValueType === PROJECTS_BY_STATUS_MONTH_VALUE_TYPE.COUNT) clientProjectsByStatusChartCaption = 'Count of Projects by Status';

    const { id: clientId, user_audit: userAudit } = rowData;
    const { created_at: rawCreatedAt } = userAudit;
    const companyCreatedAt = moment(rawCreatedAt);

    return (
      <div className="chart-wrapper client-projects-by-status">
        <div className="chart-title">
          <h4>{clientProjectsByStatusChartCaption}</h4>
          <div className="chart-buttons">
            <Button
              onClick={() => this.setIncludeArchived(!clientProjectsByStatusChartIncludeArchived)}
              active={clientProjectsByStatusChartIncludeArchived}
              size="sm"
              color="secondary"
            >
              Include archived?
            </Button>
            <ButtonGroup>
              {
                Object.values(PROJECTS_BY_STATUS_MONTH_VALUE_TYPE).map((valueType) => (
                  <Button
                    key={valueType}
                    onClick={() => this.setChartValueType(clientProjectsByStatusChartId, valueType as A_PROJECTS_BY_STATUS_VALUE_TYPE)}
                    disabled={clientProjectsByStatusChartIsLoading}
                    size="sm"
                    color="secondary"
                    active={valueType === clientProjectsByStatusChartValueType}
                  >
                    {startCase(valueType)}
                  </Button>
                ))
              }
            </ButtonGroup>
          </div>
        </div>
        <ProjectsByStatusChart
          id={clientProjectsByStatusChartId}
          // chartCaption={clientProjectsByStatusChartCaption}
          chartCaption=""
          includeArchived={clientProjectsByStatusChartIncludeArchived}
          valueType={clientProjectsByStatusChartValueType}
          onLoading={this.handleChartLoading}
          showValues
          clientId={clientId}
          dateTo={moment()}
          dateFrom={companyCreatedAt}
        />
      </div>
    );
  }


  /**
   * Render the Client Forecast Balances By Month chart
   */
  renderClientForecastBalanceByMonthChart = (): React.ReactNode => {
    const { rowData } = this.props;
    const {
      clientForecastBalancesByMonthChartIsLoading,
      clientForecastBalancesByMonthChartValueType,
    } = this.state;

    const { id: clientId, user_audit: userAudit } = rowData;
    const { created_at: rawCreatedAt } = userAudit;
    const companyCreatedAt = moment(rawCreatedAt);

    return (
      <div className="chart-wrapper client-forecast-balances-by-month">
        <div className="chart-title">
          <h4>Forecast Balances</h4>
          <div className="chart-buttons">
            <ButtonGroup>
              {
                Object.values(CLIENT_FORECAST_BALANCE_BY_MONTH_VALUE_TYPE).map((valueType) => (
                  <Button
                    key={valueType}
                    onClick={() => this.setChartValueType(clientForecastBalancesByMonthChartId, valueType as A_CLIENT_FORECAST_BALANCE_BY_MONTH_VALUE_TYPE)}
                    disabled={clientForecastBalancesByMonthChartIsLoading}
                    size="sm"
                    color="secondary"
                    active={valueType === clientForecastBalancesByMonthChartValueType}
                  >
                    {startCase(valueType)}
                  </Button>
                ))
              }
            </ButtonGroup>
          </div>
        </div>
        <ClientForecastBalanceByMonthChart
          id={clientForecastBalancesByMonthChartId}
          // chartCaption={clientForecastBalancesByMonthChartCaption}
          chartCaption=""
          valueType={clientForecastBalancesByMonthChartValueType}
          seriesOptions={[
            {
              type: CLIENT_FORECAST_BALANCE_BY_MONTH_SERIES_TYPE.FORECAST,
              name: 'Forecasts',
              visible: true,
            },
            {
              type: CLIENT_FORECAST_BALANCE_BY_MONTH_SERIES_TYPE.INVOICE,
              name: 'Invoices',
              renderAs: FUSION_CHART_DATASET_RENDER_TYPES.SPLINE,
              visible: true,
            },
            {
              type: CLIENT_FORECAST_BALANCE_BY_MONTH_SERIES_TYPE.ORDER,
              name: 'Orders',
              visible: false,
            },
            {
              type: CLIENT_FORECAST_BALANCE_BY_MONTH_SERIES_TYPE.BALANCE,
              name: 'Balance',
              visible: false,
            },
          ]}
          onLoading={this.handleChartLoading}
          showValues={false}
          clientId={clientId}
          monthTo={moment()}
          monthFrom={companyCreatedAt}
        />
      </div>
    );
  }


  /**
   * @inheritdoc
   */
  render(): React.ReactNode {
    return (
      <div className="widget dashboard-widget company-dashboard">
        {this.renderClientProjectsByStatusChart()}
        {this.renderClientForecastBalanceByMonthChart()}
      </div>
    );
  }
}
