/* eslint-disable react/no-unused-prop-types */
import React, { PropsWithChildren, ReactElement } from 'react';
import classNames from 'classnames';
import ReactResizeDetector from 'react-resize-detector';

import { AModalResultType } from '../../types/modal/modal-result';
import { IModalWizardPageDataError } from '../../types/modal/modal-wizard-page-data-error.interface';
import { ModalProps } from '../../types/modal/modal.props';
import { FormFieldChangeProps } from '../../types/poly-form/form-field-change-props';

import { ScrollToTopOnMount } from '../router/scroll-to-top-on-mount';

import { A_TRANSITION_STATE } from '../../constants/transition-state.const';
import { A_TRANSITION_DIRECTION } from '../../constants/transition-direction.const';

// @note this value must match the css $wizardPageTransitionDuration in _modal-wizard-page.scss
// @note the additional buffer is to allow CSS to finish before JS cleans up.
export const WIZARD_PAGE_TRANSITION_DURATION = 250 + 25;

export type ModalWizardPageProps<D, W, R extends AModalResultType> = {
  pageIndex: number,
  wizardState: W,
  pageData: D,
  pageDataErrors?: null | IModalWizardPageDataError[],
  modalId: number,
  modalRef?: React.RefObject<HTMLElement> | null,
  transitionState: A_TRANSITION_STATE,
  transitionDirection?: null | A_TRANSITION_DIRECTION,
  onWizardContentHeightChanged?: (pageIndex: number, newContentHeight: number) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPageDataFieldChanged?: (field: FormFieldChangeProps | FormFieldChangeProps[]) => void,
}
// Take some of the modal handling functions out of the modal props
& Pick<ModalProps<R>, 'showModal' | 'closeModal' | 'onModalComplete'>;

export const ModalWizardPage = <T, W, R, >({
  pageIndex,
  modalRef,
  transitionState,
  transitionDirection,
  children,
  onWizardContentHeightChanged,
}: PropsWithChildren<ModalWizardPageProps<T, W, R>>): ReactElement => (
    (
      <ReactResizeDetector
        handleHeight
        onResize={(newWidth?: number, newHeight?: number) => {
          // When the resize detector feeds in a new component height, pass it back up the chain so that the wizard
          // page container can set it's new height to accommodate the content.
          if (onWizardContentHeightChanged && (newHeight !== undefined)) {
            onWizardContentHeightChanged(pageIndex, newHeight);
          }
        }}
      >
        <div id={`wizard_page_${pageIndex}`} className={classNames('modal-wizard-page', transitionState, transitionDirection)}>
          <ScrollToTopOnMount scrollRef={modalRef} />
          {children}
        </div>
      </ReactResizeDetector>
    )
  );
