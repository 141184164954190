
export const INTERNAL_PROJECT_NOTE_TYPE: Record<string, number> = {
  GENERAL: 1,
  FEATURE_REQUEST: 2,
  QUESTION: 3,
  QUESTION_ANSWER: 4,
  RISK: 5,
  OPPORTUNITY: 6,
  UPDATE: 7,
} as const;
export type INTERNAL_PROJECT_NOTE_TYPE = typeof INTERNAL_PROJECT_NOTE_TYPE;
export type AN_INTERNAL_PROJECT_NOTE_TYPE = INTERNAL_PROJECT_NOTE_TYPE[keyof INTERNAL_PROJECT_NOTE_TYPE];

export const INTERNAL_PROJECT_NOTE_TYPE_NAMES: { [K in AN_INTERNAL_PROJECT_NOTE_TYPE]: string } = {
  [INTERNAL_PROJECT_NOTE_TYPE.GENERAL]: 'General',
  [INTERNAL_PROJECT_NOTE_TYPE.FEATURE_REQUEST]: 'Feature Request',
  [INTERNAL_PROJECT_NOTE_TYPE.QUESTION]: 'Question',
  [INTERNAL_PROJECT_NOTE_TYPE.QUESTION_ANSWER]: 'Question Answer',
  [INTERNAL_PROJECT_NOTE_TYPE.RISK]: 'Risk',
  [INTERNAL_PROJECT_NOTE_TYPE.OPPORTUNITY]: 'Opportunity',
  [INTERNAL_PROJECT_NOTE_TYPE.UPDATE]: 'Update',
} as const;
