import React, { useState } from 'react';
import { FORM_RENDERER_TYPE } from '../../constants/form-renderer-type.const';
import { CardSwimlanes } from '../project-card-view/card-swimlanes';
import internalProjectTasksTableInitialSettings from '../../table-definitions/internal-project-tasks-table';
import { WidgetProps } from '../../types/widget.props';
import { mapTableToForm } from '../../helpers/map-table-to-form.helper';
import { APIPolyForm } from '../poly-forms/api-poly-form';
import { ICardItem } from '../../types/cardview/card-item.interface';

export type InternalProjectCardWidgetProps = WidgetProps;

/**
 * Data component for Data Table which includes table head nad table body
 */
export const InternalProjectCardWidget: React.FC<InternalProjectCardWidgetProps> = (props) => {
  const {
    baseRoute,
    rowData,
    widgetData,
    columns,
    isEditing,
    onDirtyChange,
    onStartEdit,
    onEndEdit,
    onDelete,
  } = props;

  const detailsFields = mapTableToForm({ columns });
  const [showSwimlanes, setShowSwimlanes] = useState(false);

  return (
    <div className="internal-project-card-widget card-view" style={{ whiteSpace: 'normal' }}>
      <APIPolyForm<ICardItem>
        {...props}
        formData={rowData}
        fields={detailsFields}
        formRendererType={FORM_RENDERER_TYPE.PROJECT_CARD}
        rendererOptions={{
          onToggleSwimlanes: () => setShowSwimlanes(!showSwimlanes),
          showSwimlanes,
          inTable: true,
        }}
        apiRoute=""
        apiQuery=""
        parentId={undefined}
        baseRoute={baseRoute ?? ''}
        onStartEditRecord={onStartEdit ? () => onStartEdit() : undefined}
        onEndEditRecord={onEndEdit}
        onDeleteRecord={onDelete ? (id, formData, redirectInstruction) => onDelete(id, redirectInstruction) : undefined}
        onFieldChange={onDirtyChange ? () => onDirtyChange(isEditing) : undefined}
      />
      {showSwimlanes && (
        <CardSwimlanes
          cardItems={(widgetData as ICardItem[]) || []}
          cardLinkPath="/internal-projects/tasks"
          tableSettings={internalProjectTasksTableInitialSettings}
        />
      )}
    </div>
  );
};
