import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

const SalesForecastTableScrollContainer = (props) => {
  const {
    className, children, innerRef, partnerRef, innerStyle, pinToHorizontalEnd, endThreshold,
  } = props;


  // Whether this scroll container is the master for scroll synchronisation
  const isMaster = useRef(false);


  // Whether the scroll container should be treated as "pinned" to the horizontal scroll -end
  const isPinnedToHorizontalEnd = useRef(null);


  /**
   * @description
   * Check to see if this scroll container is the master. Basically just checks to see
   * if there is still a className against the scroll container or if it has been
   * removed by the partner scroll container.
   *
   * @returns {boolean}
   */
  const checkIsStillMaster = useCallback(() => {
    // We only want to check to see if the master class "is still set" because it can
    // be unset by the partner.
    if (!isMaster.current) return false;

    const scrollContainer = innerRef && innerRef.current;
    if (scrollContainer) {
      isMaster.current = scrollContainer.classList.contains('master');
    }

    return isMaster.current;
  }, [innerRef]);


  /**
   * @description
   * Evaluate the if the scroll container should be considered pinned to the horizontal scroll end
   * There is a threshold for determining if it is at the end.
   */
  const checkIsPinnedToHorizontalEnd = useCallback(() => {
    const scrollContainer = innerRef && innerRef.current;
    if (scrollContainer) {
      isPinnedToHorizontalEnd.current = pinToHorizontalEnd
        && scrollContainer.scrollWidth > 0
        && (scrollContainer.scrollWidth - scrollContainer.clientWidth - scrollContainer.scrollLeft) < endThreshold;
    }
  }, [innerRef, endThreshold, pinToHorizontalEnd]);


  /**
   * @description
   * Scroll the container all the way to the right.
   */
  const scrollToHorizontalEnd = useCallback(() => {
    const scrollContainer = innerRef && innerRef.current;
    if (scrollContainer) {
      scrollContainer.scrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;
    }
  }, [innerRef]);


  /**
   * @description
   * Synchronize the top of the partner element with the top of this element
   */
  const syncPartnerScrollTop = useCallback(() => {
    const scrollContainer = innerRef && innerRef.current;
    const partnerScrollContainer = partnerRef && partnerRef.current;

    if (scrollContainer && partnerScrollContainer) {
      // Make the partner match this scroll container
      partnerScrollContainer.scrollTop = scrollContainer.scrollTop;

      // Check to see if the partner element was prevented from scrolling
      if (partnerScrollContainer.scrollTop !== scrollContainer.scrollTop) {
        // Prevent this scroll container from over scrolling
        scrollContainer.scrollTop = partnerScrollContainer.scrollTop;
      }
    }
  }, [innerRef, partnerRef]);


  /**
   * @description
   * Make this scroll container the master scroll container by adding a 'master' classname
   * and removing the 'master' class name from the partner ref
   */
  const makeMaster = useCallback(() => {
    // Make this scroll container the master
    const scrollContainer = innerRef && innerRef.current;
    if (scrollContainer) {
      scrollContainer.classList.add('master');
      isMaster.current = true;
    }

    // Remove the master class from the partner
    const partnerScrollContainer = partnerRef && partnerRef.current;
    if (partnerScrollContainer) {
      partnerScrollContainer.classList.remove('master');

      // isMaster will be detected and removed by the partner's checkIsStillMaster() function
    }
  }, [innerRef, partnerRef]);


  /**
   * @description
   * Fired when the user scrolls the scroll container
   */
  const handleScroll = useCallback(() => {
    checkIsPinnedToHorizontalEnd();

    if (checkIsStillMaster()) {
      syncPartnerScrollTop();
    }
  }, [checkIsPinnedToHorizontalEnd, checkIsStillMaster, syncPartnerScrollTop]);


  /**
   * @description
   * Bind the Scroll Event Listeners
   */
  const bindScrollEventListeners = useCallback(() => {
    if (innerRef && innerRef.current) {
      innerRef.current.addEventListener('scroll', handleScroll);
    }
    window.addEventListener('resize', handleScroll);
  }, [handleScroll, innerRef]);


  /**
   * @description
   * Unbind the Scroll Event Listeners
   */
  const unbindScrollEventListeners = useCallback(() => {
    if (innerRef && innerRef.current) {
      innerRef.current.removeEventListener('scroll', handleScroll);
    }
    window.removeEventListener('resize', handleScroll);
  }, [handleScroll, innerRef]);


  /**
   * @description
   * React Hook useEffect
   *
   * ComponentWillMount
   */
  useEffect(() => {
    bindScrollEventListeners();

    // Component will Un-Mount
    return () => {
      unbindScrollEventListeners();
    };
  }, [bindScrollEventListeners, unbindScrollEventListeners]);


  /**
   * @description
   * Fired after the component is rendered. Don't overload this function, it's blocking.
   */
  useLayoutEffect(() => {
    // If we have no current knowledge of whether the scroll container is pinned to the horizontal end, check it now
    if (isPinnedToHorizontalEnd.current === null) {
      checkIsPinnedToHorizontalEnd();
    }
    // If our last knowledge of the scroll container was that it was pinned to the horizontal end, scroll horizontally to the end
    if (isPinnedToHorizontalEnd.current) {
      scrollToHorizontalEnd();
    }
    checkIsPinnedToHorizontalEnd();
  });


  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={className}
      ref={innerRef}
      style={innerStyle}
      onMouseEnter={makeMaster}
      onKeyDown={makeMaster}
      onFocus={makeMaster}
    >
      {children}
    </div>
  );
};

SalesForecastTableScrollContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  innerStyle: PropTypes.shape({}),
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  partnerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  pinToHorizontalEnd: PropTypes.bool,
  endThreshold: PropTypes.number,
};

SalesForecastTableScrollContainer.defaultProps = {
  className: null,
  children: null,
  innerStyle: {},
  innerRef: null,
  partnerRef: null,
  pinToHorizontalEnd: true,
  endThreshold: 100,
};

export default SalesForecastTableScrollContainer;
