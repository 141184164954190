/* eslint-disable key-spacing */
export const PROJECT_STATUS = {
  LEAD: 1,
  PROPOSAL: 2,
  LOST: 3,
  ACTIVE: 4,
  COMPLETED: 5,
  ARCHIVED: 6,
} as const;
export type PROJECT_STATUS = typeof PROJECT_STATUS;
export type A_PROJECT_STATUS = PROJECT_STATUS[keyof PROJECT_STATUS];

export const PROJECT_STATUS_NAME_MAP: {[K in A_PROJECT_STATUS]: string} = {
  [PROJECT_STATUS.LEAD]:        'Lead',
  [PROJECT_STATUS.PROPOSAL]:    'Proposal',
  [PROJECT_STATUS.LOST]:        'Lost',
  [PROJECT_STATUS.ACTIVE]:      'Active',
  [PROJECT_STATUS.COMPLETED]:   'Completed',
  [PROJECT_STATUS.ARCHIVED]:    'Archived',
};
