/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable-next-line react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import NewsArticleTeaser from './news-article-teaser';


/**
 * List of News Images & Teasers
 *
 * Presentational component
 * @param {{
 *  renderPlaceholders: boolean,
 *  renderFirstAsPrimary: boolean,
 *  columnLayout: boolean,
 *  showMoreNewsLink: boolean,
 *  placeholderCount?: number,
 *  articles: {
 *    id: number,
 *    title: string,
 *    teaser: string,
 *    published_at?: string,
 *    thumbnail_url: string,
 *    author: { name: string }
 *  },
 * }} param0
 */
const NewsArticleTeaserList = ({
  renderPlaceholders,
  renderFirstAsPrimary,
  columnLayout,
  showMoreNewsLink,
  placeholderCount,
  articles,
  articleReturnToLocation,
}) => {
  const newsArticleTeaserListClassname = classNames('news-article-teaser-list', { 'column-layout': columnLayout });

  // display fake articles if necessary
  if (renderPlaceholders) {
    const fakeArticles = [];

    if (placeholderCount === null) throw new Error('Must provide a "placeholderCount" to NewsArticleTeaserList if "renderPlaceholders" is "true"');
    for (let i = 0; i < placeholderCount; i += 1) {
      const isPrimaryArticle = ((i === 0) && renderFirstAsPrimary);
      fakeArticles[i] = (
        <NewsArticleTeaser
          isPrimary={isPrimaryArticle}
          isPlaceholder
          key={`fake-${i}`}
        />
      );
    }

    return (
      <div className={newsArticleTeaserListClassname}>{fakeArticles}</div>
    );
  }

  if (articles.length === 0) return <div className={newsArticleTeaserListClassname} />;

  // display real articles
  let firstArticle = null;
  let remainingArticles = null;
  if (renderFirstAsPrimary) {
    [firstArticle, ...remainingArticles] = articles;
  }
  else {
    [...remainingArticles] = articles;
  }

  return (
    <div className={newsArticleTeaserListClassname}>
      {firstArticle && (
        <NewsArticleTeaser
          article={firstArticle}
          isPrimary
          isPlaceholder={false}
          key={`real-${firstArticle.id}`}
          articleReturnToLocation={articleReturnToLocation}
        />
      )}
      {remainingArticles.map((secondaryArticle) => (
        <NewsArticleTeaser
          article={secondaryArticle}
          isPrimary={false}
          isPlaceholder={false}
          key={`real-${secondaryArticle.id}`}
          articleReturnToLocation={articleReturnToLocation}
        />
      ))}
      {showMoreNewsLink && (
        <div className="more-news-link-wrapper">
          <Link className="more-news-link" to="comms/news"><h4>More News...</h4></Link>
        </div>
      )}
    </div>
  );
};


NewsArticleTeaserList.propTypes = {
  renderPlaceholders: PropTypes.bool.isRequired,
  renderFirstAsPrimary: PropTypes.bool,
  placeholderCount: PropTypes.number,
  articles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    teaser: PropTypes.string.isRequired,
    published_at: PropTypes.string,
    author: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
  columnLayout: PropTypes.bool,
  showMoreNewsLink: PropTypes.bool,
  articleReturnToLocation: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
};

NewsArticleTeaserList.defaultProps = {
  placeholderCount: null,
  renderFirstAsPrimary: false,
  columnLayout: false,
  showMoreNewsLink: false,
  articleReturnToLocation: null,
};

export default NewsArticleTeaserList;
