/**
 * Ensures that the alpha/numeric sections of a file name are wrapped in a
 * <span/> so that they word-wrap properly
 */
export const prettyFileName = (fileName: string):string => fileName
// Regex removed 2021-06-16 due to browser compatibility issues.
// This is still the best regex.
// .split(/(?=[.,:;\-=_ +!$])|(?<=[.,:;\-=_ +!$])/g)

  .split(/(?=[^\r\n])([^a-z0-9]*)([a-z0-9]+)/gi)
  .filter((item) => !!item)
  .map((component) => `<span>${component}</span>`)
  .join('');
