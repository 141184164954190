export const EXPENSE_CLAIM_BATCH_STATUS = {
  DRAFT: 1,
  PROCESSED: 2,
  PAID: 3,
} as const;
export type EXPENSE_CLAIM_BATCH_STATUS = typeof EXPENSE_CLAIM_BATCH_STATUS;
export type AN_EXPENSE_CLAIM_BATCH_STATUS = EXPENSE_CLAIM_BATCH_STATUS[keyof EXPENSE_CLAIM_BATCH_STATUS];

export const EXPENSE_CLAIM_BATCH_STATUS_MAP: {[K in AN_EXPENSE_CLAIM_BATCH_STATUS]: string} = {
  [EXPENSE_CLAIM_BATCH_STATUS.DRAFT]: 'Draft',
  [EXPENSE_CLAIM_BATCH_STATUS.PROCESSED]: 'Processed',
  [EXPENSE_CLAIM_BATCH_STATUS.PAID]: 'Paid',
};
