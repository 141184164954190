import { DELETE_CONFIRMATION_TYPE } from '../constants/delete-confirmation-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { IContractCompanySpaceRecord } from '../types/contact/contract-company-space.record.interface';
import { IContractRecord } from '../types/service/contract.record.interface';
import { ICompanySpaceRecord } from '../types/company/company-space.record.interface';
import { buildCompanySpaceURL } from '../components/portal-tree-view/company-locations/company-locations-tree-view-url.helper';

export const ContractSpacesWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Space',
  formDeleteConfirmationType: DELETE_CONFIRMATION_TYPE.REMOVE,
  fields: [
    {
      name: 'contract_id',
      visible: false,
      isParentId: true,
    },
    {
      name: 'type_id',
      description: 'Parent Type Id',
      visible: false,
      useParentDataFieldValue: 'type_id',
      // initialValue: (formData: IContractCompanySpaceRecord, parentData?: IContractRecord) => parentData?.type_id || null,
    },

    {
      name: 'company_id',
      title: 'Company',
      formSaveField: 'company_id',
      visible: false,
      initialValue: (formData: IContractCompanySpaceRecord, parentData?: IContractRecord) => parentData?.company_id,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },


    // Space - SpacePicker
    {
      name: 'space',
      title: 'Space',
      formSaveField: 'company_space_id',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        key: 'path',
        sourceField: 'company_space',
        format: COLUMN_FORMAT.COMPANY_SPACE,
        linkRoute: (formData: ICompanySpaceRecord) => buildCompanySpaceURL(formData.company_id, formData.id),
      },
      formFieldType: FORM_FIELD_TYPE.COMPANY_SPACE_PICKER,
      searchRoute: (formData: IContractCompanySpaceRecord, parentData: IContractRecord): string => `/company/${parentData.client_id}/space`,
      isCreatable: true,
      getNewItemData: (inputValue: string, formData: IContractCompanySpaceRecord, parentData?: IContractRecord): Partial<ICompanySpaceRecord> => ({
        name: inputValue,
        company_id: parentData?.client_id,
      }),
    },
    {
      name: 'subtype',
      title: 'Optional Subtype',
      formSaveField: 'contract_subtype_id',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'subtype',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formFieldType: FORM_FIELD_TYPE.SPACE_SUBTYPE_PICKER,
    },

    // Value - CurrencyInput
    {
      name: 'value',
      visible: true,
      title: 'Value',
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
      allowNegative: false,
      placeholder: '$0.00',
      format: COLUMN_FORMAT.CURRENCY,
      description: 'Contract Total Value',
      showTotals: true,
    },
  ],
};
