import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import moment from 'moment';
import Icon from '../layout-helpers/icon';
import { UserAvatar } from '../user-profiles/user-avatar';
import { NewNewsService } from '../../utils/new-news-service';
import { ReactPortal } from '../react-portal/react-portal';
import { useHistory } from '../router/history';
import PageAlert from '../layout-helpers/page-alert';
import { toParams } from '../../utils/helpers';
import { CurrentUserContext } from '../providers/current-user-provider';
import { DATE_FORMAT } from '../../constants/date-format.const';
import StatusBadge from '../data-format/status-badge';
import { NEWS_STATUS, NEWS_STATUS_MAP } from '../../constants/news-status.const';

/**
 * @description
 * How long after publishing do we not display an "updated" if the article was
 * updated after publishing?
 */
const INDICATE_UPDATED_GRACE_PERIOD_DAYS = 1;

/**
 * @description
 * @param {{}} article
 * @param {{}[]} actions
 */
const renderHelperMessage = (article, actions, loggedInUserId) => {
  const canPublish = actions.findIndex((action) => action.name === 'publish') !== -1;
  const canSubmit = actions.findIndex((action) => action.name === 'submit') !== -1;
  const isAuthor = article.author_id === loggedInUserId;

  let message = null;
  let color = 'primary';
  let icon = null;

  switch (article.status_id) {
    case NEWS_STATUS.DRAFT: {
      if (canSubmit && isAuthor) {
        icon = 'info-circle';
        color = 'info';
        message = 'You have not yet submitted your article. It must be approved and published by a News Editor before it will be visible to News Readers.';
      }
      break;
    }

    case NEWS_STATUS.SUBMITTED: {
      if (canPublish) {
        icon = 'info-circle';
        message = 'Publish this article to make it visible to all news readers.';
      }
      else if (isAuthor) {
        message = 'Your article is submitted and will not be visible to news readers until it is published by a News Editor.';
        color = 'warning';
      }
      break;
    }

    default:
      break;
  }

  if (message) {
    return (
      <PageAlert color={color}>
        {icon && (
          <Icon i={icon} />
        )}
        <span>{message}</span>
      </PageAlert>
    );
  }

  return null;
};

/**
 * @description
 * Toggle whether the dropdown version of the button list is visible or not
 *
 * @param {React.SyntheticEvent} e
 * @param {function} setIsDropdownOpen
 * @param {boolean} isDropdownOpen
 */
const handleToggleDropDownOpen = (e, setIsDropdownOpen, isDropdownOpen) => {
  if (!e) return;

  const preventToggle = e.target.getAttribute('data-prevent-close-dropdown');
  if (!preventToggle) {
    setIsDropdownOpen(!isDropdownOpen);
  }
};


const NewsArticle = ({
  article, actions, history, location,
}) => {
  const { userDetails } = useContext(CurrentUserContext);

  // Mark the article as "read";
  if (article && article.id) NewNewsService.markArticleAsRead(article.id);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const locationParams = toParams(location.search);
  const articleReturnTo = ('art' in locationParams) ? locationParams.art : null;
  const hasEdited = ('edited' in locationParams);

  return (
    <div className="news-article">

      <div className="article-info">
        {/* Header (return button and actions) */}
        <div className="header-wrapper">
          <div className="return">
            <Button onClick={() => {
              if (!hasEdited) {
                useHistory.backOrPush(history, '/comms/news');
              }
              else {
                history.push(articleReturnTo || '/comms/news');
              }
            }}
            >
              <Icon i="chevron-left" />
              <span>
                Return to News
              </span>
            </Button>
          </div>
          <div className="action-buttons-wrapper">
            {actions.filter((action) => !action.isSecondaryAction).map(({
              name,
              label,
              title,
              color,
              icon,
              onClick,
              disabled,
            }) => (
              <Button
                className="action"
                key={name}
                title={title}
                color={color}
                icon={icon}
                onClick={onClick}
                disabled={disabled}
              >
                <Icon i={icon} />
                <span>{label}</span>
              </Button>
            ))}

            {/* Render a dropdown to contain the secondary actions (if there are any) */}
            {actions.filter((action) => action.isSecondaryAction).length > 0 && (
              <ButtonDropdown
                className="secondary-actions"
                isOpen={isDropdownOpen}
                toggle={(e) => handleToggleDropDownOpen(e, setIsDropdownOpen, isDropdownOpen)}
              >
                <DropdownToggle>
                  <Icon i="bars" />
                </DropdownToggle>
                <ReactPortal>
                  <DropdownMenu right modifiers={{ preventOverflow: { boundariesElement: 'window' } }}>
                    {actions.filter((action) => action.isSecondaryAction).map((action) => (
                      <DropdownItem
                        className={classNames('secondary-action', action.color)}
                        key={action.name}
                        onClick={() => {
                          setIsDropdownOpen(false);
                          action.onClick();
                        }}
                        disabled={action.disabled}
                        data-prevent-close-dropdown
                      >
                        <Icon i={action.icon} />
                        <span>{action.label}</span>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ReactPortal>
              </ButtonDropdown>
            )}
          </div>
        </div>

        {/* Display a prompt for the user if something else needs to happen */}
        {renderHelperMessage(article, actions, userDetails.id)}

        {/* Primary Image */}
        <div className="image-container">
          <div className="image" style={{ backgroundImage: `url('${article.thumbnail_url}')` }} />
        </div>

        {/* Article Title */}
        <div className="article-title">
          <h2>{article.title}</h2>
        </div>

        {/* Author */}
        <div className="author-details">
          <div className="avatar-wrapper">
            <UserAvatar className="avatar" name={article.author.name} />
          </div>
          <div className="author-and-date">
            <div className="author">
              <span>{article.author.name}</span>
            </div>
            <div className="publish-date">
              {/* Display the Published Date */}
              {article.status_id === NEWS_STATUS.PUBLISHED && (
                <span>{`Published ${moment(article.published_at).format(DATE_FORMAT.NEWS)}`}</span>
              )}
              {/* Display the status instead */}
              {article.status_id !== NEWS_STATUS.PUBLISHED && (
                <StatusBadge status={NEWS_STATUS_MAP[article.status_id]} />
              )}
            </div>
          </div>

          {/* Article Category */}
          <div className="article-category">
            <strong>{`Category: ${article.category.name}`}</strong>
          </div>

        </div>
      </div>

      <div className="article-body">
        {/* Has this article been updated since it was published? */}
        { (article.status_id === NEWS_STATUS.PUBLISHED)
          && (moment(article.published_at).add(INDICATE_UPDATED_GRACE_PERIOD_DAYS, 'days').isBefore(moment(article.user_audit.updated_at)))
          && (
            <PageAlert color="info">
              <Icon i="info-circle" />
              <span>{`Updated ${moment(article.user_audit.updated_at).format(DATE_FORMAT.NEWS)}`}</span>
            </PageAlert>
          )}

        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: article.body }} />
      </div>
    </div>
  );
};

NewsArticle.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.number.isRequired,
    category_id: PropTypes.number.isRequired,
    category: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    status_id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    teaser: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    published_at: PropTypes.string,
    thumbnail_url: PropTypes.string.isRequired,
    author: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    user_audit: PropTypes.shape({
      updated_at: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    successMessage: PropTypes.string.isRequired,
    failMessage: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    method: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
  })),
};

NewsArticle.defaultProps = {
  actions: null,
};

export default NewsArticle;
