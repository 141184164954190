import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';

import { mapSelectInputOptions } from '../helpers/map-select.helper';

import { COLUMN_FORMAT } from '../constants/column-format.const';
import { DELETE_CONFIRMATION_TYPE } from '../constants/delete-confirmation-type.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { PROJECT_COMPANY_TYPE_NAME_MAP } from '../constants/project-company-type.const';

export const ProjectCompaniesWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Company',
  formDeleteConfirmationType: DELETE_CONFIRMATION_TYPE.REMOVE,
  fields: [
    {
      name: 'project_id',
      visible: false,
      isParentId: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'type',
      title: 'Relationship',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'type',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formSaveField: 'type_id',
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: mapSelectInputOptions(PROJECT_COMPANY_TYPE_NAME_MAP),
    },
    {
      name: 'company',
      title: 'Company',
      formSaveField: 'company_id',
      placeholder: 'Select Company...',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'company',
        key: 'name',
        format: COLUMN_FORMAT.LONG_TEXT,
      },
      formFieldType: FORM_FIELD_TYPE.COMPANY_PICKER,
      isCreatable: true,
    },
  ],
};
