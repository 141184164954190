import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router';
import { mergeActions } from '../../utils/helpers';
import PortalDataTable from '../portal-data-table/portal-data-table';
import PageHeader from '../app-layout/page-header';
import HISTORY_PROP_TYPES from '../../prop-types/history-prop-types';
import { DATA_TABLE_FLAG } from '../../constants/data-table-flag.const';

/**
 * Wrapper that controls Overlays with the "create new" form inside.
 * @example
 * <DataTablePage />
 */
class DataTablePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      dirtyRow: {
        rowId: null,
        isDirty: false,
      },
    };

    // ensure route data exists in props
    if (!props.match) throw new Error('Need props from <Route />::render(props => <...Page />)');
  }


  /**
   * @description
   * Fired by the PortalDataTable when the user makes a change to a row deemed worthy of confirming abandonment
   *
   * @param {dirtyRow: {
   *  rowId: null | string | number,
   *  newIsDirty: boolean,
   * }}
   */
  handleDirtyRowChange = (dirtyRow) => {
    this.setState({ dirtyRow });
  };


  // Renders <DataTablePage />
  render() {
    const {
      title,
      className,
      baseFilters,
      baseFlags,
      tableIdentifier,
      match,
      history,
      location,
      availableActions,
      possibleActions,
      actionHandlers,
      children,
      headerChildren,
    } = this.props;

    const {
      redirect,
      dirtyRow,
    } = this.state;

    // Redirect if something set it
    if (redirect !== null) history.push(redirect);

    // Create button permissions - For "new" buttons mainly
    const permittedActions = mergeActions(availableActions, possibleActions, actionHandlers);

    // Returns <DataTablePage />
    return (
      <Container fluid className={className}>
        {dirtyRow && dirtyRow.isDirty && (

          // Use a React Router Prompt to remind the user that they have unsaved changes
          <Prompt
            message={
              (navLocation, navAction) => {
                // if the location action represents a record change (i.e. not a navigate away event)
                // then the Portal Datatable will have handled the action. Don't prompt the user twice.
                if ((navLocation.pathname === location.pathname) || (navAction !== 'PUSH')) {
                  return true;
                }

                // This is a proper "navigate away" action. Perform the prompt.
                return 'Discard your unsaved changes?';
              }
            }

          />
        )}
        <PageHeader
          title={title}
          permittedActions={permittedActions}
          actionHandlers={actionHandlers}
        >
          {headerChildren}
        </PageHeader>
        {children}
        <PortalDataTable
          tableIdentifier={tableIdentifier}
          title={title}
          baseFilters={baseFilters}
          baseFlags={baseFlags}
          history={history}
          location={location}
          match={match}
          colorTheme="primary"
          onDirtyRowChange={this.handleDirtyRowChange}
        />
      </Container>
    );
  }
}

DataTablePage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  headerChildren: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  urlIdentifier: PropTypes.string,
  availableActions: PropTypes.shape({}),
  possibleActions: PropTypes.arrayOf(PropTypes.shape({})),
  actionHandlers: PropTypes.objectOf(PropTypes.func),
  // used by filters later, can be undefined
  baseFilters: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]),
  baseFlags: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DATA_TABLE_FLAG))),
  tableIdentifier: PropTypes.string.isRequired,
  // Router
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({}),
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape(HISTORY_PROP_TYPES).isRequired,
};

DataTablePage.defaultProps = {
  title: 'Queried Items',
  className: '',
  urlIdentifier: undefined,
  baseFilters: undefined,
  baseFlags: undefined,
  availableActions: {},
  possibleActions: [],
  actionHandlers: {},
  children: null,
  headerChildren: null,
};

const mapStateToProps = (state, ownProps) => ({
  availableActions: state.tableSettings[ownProps.tableIdentifier].availableActions,
  possibleActions: state.tableSettings[ownProps.tableIdentifier].possibleActions,
});

export default connect(
  mapStateToProps,
)(DataTablePage);
