import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SalesForecastTableCellGroup from './sales-forecast-table-cell-group';
import SalesForecastTableRow from './sales-forecast-table-row';
import { DATA_TYPE, buildDomDataTagId } from './sales-forecast-helpers';
import Icon from '../../layout-helpers/icon';
import SalesForecastTableProjectMonthlyDataRows from './sales-forecast-table-project-monthly-data-rows';

const SalesForecastTableSalespersonMonthlyDataRows = (props) => {
  const {
    monthlyFields,
    monthGroups,
    visibleMonthlyFieldCount,
    pmAndStateIds,
    hideZeroValues,

    onClick,
    onBeginHover,
    onEndHover,

    stateId,
    salespersonData,
    projectData,
  } = props;

  return (
    !!salespersonData && (
      <>
        {/* No Salesperson Monthly Data - just display an empty cell. */}
        {(!!salespersonData.error || !salespersonData.monthlyData.data.length) && (
          <SalesForecastTableRow
            className={classNames('project-manager', 'monthly', 'data', 'no-data', 'error', { loading: salespersonData.isLoading })}
          >
            {monthGroups.map((monthGroup) => (
              <SalesForecastTableCellGroup
                key={`salesperson_${stateId}_${monthGroup.key}`}
                span={visibleMonthlyFieldCount}
              />
            ))}
          </SalesForecastTableRow>
        )}

        {!salespersonData.error && !!salespersonData.summaryData.data.length && (
          <>
            {/* Salesperson Monthly Data Fields */}
            {salespersonData.summaryData.data.map((salesperson) => (
              // Iterate over the visible months
              <React.Fragment
                key={`state_${salesperson.state_id}_salesperson_${salesperson.salesperson_id}`}
              >

                <SalesForecastTableRow
                  key={`salesperson_${salesperson.salesperson_id}_monthly_data`}
                  className={classNames('project-manager', 'monthly', 'data', {
                    loading: salespersonData.isLoading,
                    expanded: !!pmAndStateIds.find((pmAndStateId) => pmAndStateId.stateId === salesperson.state_id && pmAndStateId.pmId === salesperson.salesperson_id),
                  })}
                  trackHover
                  dataType={DATA_TYPE.PROJECT_MANAGER}
                  id={buildDomDataTagId(DATA_TYPE.PROJECT_MANAGER, salesperson.state_id, salesperson.salesperson_id)}
                  onBeginHover={onBeginHover}
                  onEndHover={onEndHover}
                  onClick={onClick}
                >
                  {monthGroups.map((monthGroup) => {
                    // Look for the salesperson / month combination in the project manager monthly data
                    const SalespersonMonthData = salespersonData.monthlyData.data
                      .find((data) => data.salesperson_id === salesperson.salesperson_id && data.month === monthGroup.key);

                    // Found it; Render it.
                    if (SalespersonMonthData) {
                      return (
                        <SalesForecastTableCellGroup
                          key={`state_${salesperson.state_id}_salesperson_${salesperson.salesperson_id}_${monthGroup.key}`}
                          rowKey={`state_${salesperson.state_id}_salesperson_${salesperson.salesperson_id}_${monthGroup.key}`}
                          hideZeroValues={hideZeroValues}
                          fields={monthlyFields}
                          data={SalespersonMonthData}
                        />
                      );
                    }

                    // Doesn't exist - render an empty / loading cell
                    return (
                      <SalesForecastTableCellGroup
                        key={`state_${salesperson.state_id}_salesperson_${salesperson.salesperson_id}_${monthGroup.key}_loading`}
                        span={visibleMonthlyFieldCount}
                      >
                        {salespersonData.isLoading && (
                          <>
                            <Icon i="rolling" />
                            <span>Loading...</span>
                          </>
                        )}
                      </SalesForecastTableCellGroup>
                    );
                  })}
                </SalesForecastTableRow>

                {/* Project Monthly Data */}
                {projectData.find((project) => project.stateId === salesperson.state_id && project.salespersonId === salesperson.salesperson_id) && (
                  <SalesForecastTableProjectMonthlyDataRows
                    monthGroups={monthGroups}
                    monthlyFields={monthlyFields}
                    visibleMonthlyFieldCount={visibleMonthlyFieldCount}
                    hideZeroValues={hideZeroValues}
                    onClick={onClick}
                    onBeginHover={onBeginHover}
                    onEndHover={onEndHover}
                    stateId={salesperson.state_id}
                    salespersonId={salesperson.salesperson_id}
                    projectData={projectData.find((project) => project.stateId === salesperson.state_id && project.salespersonId === salesperson.salesperson_id)}
                  />
                )}

              </React.Fragment>

            ))}
          </>
        )}
      </>
    )
  );
};

SalesForecastTableSalespersonMonthlyDataRows.propTypes = {
  monthlyFields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  })).isRequired,
  monthGroups: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  stateId: PropTypes.number.isRequired,
  salespersonData: PropTypes.shape({
    stateId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    summaryData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
    monthlyData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
  }),
  projectData: PropTypes.arrayOf(PropTypes.shape({
    stateId: PropTypes.number.isRequired,
    salespersonId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    summaryData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
    monthlyData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
  })),
  visibleMonthlyFieldCount: PropTypes.number.isRequired,
  pmAndStateIds: PropTypes.arrayOf(PropTypes.shape({
    pmId: PropTypes.number.isRequired,
    stateId: PropTypes.number.isRequired,
  })).isRequired,
  onClick: PropTypes.func.isRequired,
  onBeginHover: PropTypes.func.isRequired,
  onEndHover: PropTypes.func.isRequired,
  hideZeroValues: PropTypes.bool.isRequired,
};

SalesForecastTableSalespersonMonthlyDataRows.defaultProps = {
  salespersonData: null,
  projectData: null,
};

export default SalesForecastTableSalespersonMonthlyDataRows;
