import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { DataTablePageProps } from '../../types/data-table-page.props';
import { ILeaveApplicationRecord } from '../../types/leave/leave-application.record.interface';
import { NewRecordModalResult } from '../../types/modal/modal-result';

import { ModalContext } from '../modals/modal-context';
import { CurrentUserContext } from '../providers/current-user-provider';

import DataTablePage from './data-table-page';
import AvailableLeave from '../available-leave/available-leave';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { API_FILTER_OPERATION } from '../../constants/api-filter-operation.const';

export type MyLeaveDataTablePageProps = DataTablePageProps;

/**
 * Display the "My Leave" data table as a whole page
 */
export const MyLeaveDataTablePage: React.FC<MyLeaveDataTablePageProps> = ({
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const { userDetails } = useContext(CurrentUserContext);
  const history = useHistory();
  const { showModal } = useContext(ModalContext);

  const baseFilters = [
    {
      field: 'user_id',
      operation: API_FILTER_OPERATION.EQUALS,
      values: [userDetails.id],
    },
  ];

  const actionHandlers = {
    create: () => {
      showModal<NewRecordModalResult<ILeaveApplicationRecord>>(MODAL_TYPE.NEW_LEAVE_APPLICATION, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/hrm/my-leave/${modalResult.newRecord.id}/blocks`);
        },
      });
    },
  };

  return (
    <DataTablePage
      title="My Leave"
      className="my-leave-page"
      tableIdentifier={TABLE_IDENTIFIER.MY_LEAVE_TABLE}
      baseFilters={baseFilters}
      actionHandlers={actionHandlers}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      <>
        <AvailableLeave userId={userDetails.id} datePickerButton />
        {children}
      </>
    </DataTablePage>
  );
};
