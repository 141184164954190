import React, { SyntheticEvent, useState } from 'react';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { IMyobTransactionReportRecord } from '../../../types/myob-advanced/myob-transaction-report-record.interface';
import { titleCase } from '../../../utils/title-case';
import { formatValueString } from '../../render-functions';
import { TransactionReportDetailRows } from './transaction-report-detail-rows';
import { TransactionReportTransactionRows } from './transaction-report-transaction-rows';

type TransactionReportDetailRowsProps = {
  category: IMyobTransactionReportRecord,
  indentLevel: number,
  showTotals: boolean,
};

export const TransactionReportDetailRow: React.FC<TransactionReportDetailRowsProps> = ({ category, indentLevel, showTotals }) => {
  const [showDetails, setShowDetails] = useState(indentLevel < 1);
  const toggleDetails = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDetails(!showDetails);
  };
  return (
    <React.Fragment key={category.title}>
      <tr key={category.title} onClick={toggleDetails} style={{ cursor: 'pointer' }}>
        <td>
          <span style={{ width: `${indentLevel / 2}rem`, display: 'inline-block' }} />
          {category.title}
        </td>
        <td>
          <span style={{ width: `${indentLevel / 2}rem`, display: 'inline-block' }} />
          {category.description}
        </td>
        <td className="text-right currency">
          {formatValueString(category.amount * (-1), COLUMN_FORMAT.CURRENCY)}
        </td>
      </tr>
      {category.details && category.details.length > 0 && showDetails && (
        <TransactionReportDetailRows
          title={titleCase(category.title)}
          reportData={category.details}
          indentLevel={indentLevel + 3}
          showTotals={showTotals}
        />
      )}
      {category.details.length === 0 && showDetails && (
        <TransactionReportTransactionRows
          totalName={titleCase(category.description)}
          transactions={category.transactions}
          indentLevel={indentLevel + 3}
          showTotals={showTotals}
        />
      )}
    </React.Fragment>
  );
};
