/* eslint-disable react/no-unused-prop-types */
import React, { PropsWithChildren, ReactElement, Ref } from 'react';
import classNames from 'classnames';
import { Button } from 'reactstrap';

import { PortalTreeViewDropdownMenu } from './portal-tree-view-dropdown-menu';
import { PortalTreeViewMenuItems, PortalTreeViewMenuItemTreeState } from '../../types/portal-tree-view/portal-tree-view-menu-items';
import { PortalMultiLevelDropDownHandlers } from '../portal-multi-level-drop-down/portal-multi-level-drop-down';

import { ICON } from '../../constants/icon.const';
import Icon from '../layout-helpers/icon';
import { BUTTON_COLOR } from '../../constants/button-color.const';
import { IPortalTreeViewSelectedNodeIdentifier } from '../../types/portal-tree-view/portal-tree-view-selected-node-identifier.interface';
import { PortalTreeViewSearchBar } from './portal-tree-view-search-bar';

export type PortalTreeViewHeaderProps<
  S extends IPortalTreeViewSelectedNodeIdentifier = IPortalTreeViewSelectedNodeIdentifier,
> = {
  className?: string,
  treeState: PortalTreeViewMenuItemTreeState,
  title?: string,
  menuItems?: PortalTreeViewMenuItems<S>,
  addRecordMenuItems?: PortalTreeViewMenuItems<S>,
  // eslint-disable-next-line react/no-unused-prop-types
  selectedNodes?: S[],
  showSafeModeButton?: boolean,
  showAddButton?: boolean,
  isSearchBarVisible: boolean,
  searchTerm?: null | string,
  onMenuToggle?: (isMenuOpen: boolean) => void,
  onAddRecordMenuToggle?: (isMenuOpen: boolean) => void,
  // eslint-disable-next-line react/no-unused-prop-types
  onStartEdit?: () => void,
  // eslint-disable-next-line react/no-unused-prop-types
  onEndEdit?: () => void,
  onEnterSafeMode?: () => void,
  onExitSafeMode?: () => void,
  onToggleSearchBar?: (newVisible: boolean) => void,
  onSearchTermChanged?: (newSearchTerm: null | string) => void,
};

export const PortalTreeViewHeader = <
  S extends IPortalTreeViewSelectedNodeIdentifier = IPortalTreeViewSelectedNodeIdentifier,
>(props: PropsWithChildren<PortalTreeViewHeaderProps<S> & {
    menuRef?: Ref<PortalMultiLevelDropDownHandlers>,
    addRecordMenuRef?: Ref<PortalMultiLevelDropDownHandlers>,
  }>,
  ): ReactElement<PropsWithChildren<PortalTreeViewHeaderProps<S>>> => {
  const {
    menuRef,
    addRecordMenuRef,
    className,
    treeState,
    title = 'Portal Tree View',
    menuItems = [],
    addRecordMenuItems = [],
    showAddButton = true,
    showSafeModeButton = true,
    isSearchBarVisible,
    searchTerm = null,
    onMenuToggle,
    onAddRecordMenuToggle,
    onEnterSafeMode,
    onExitSafeMode,
    onToggleSearchBar,
    onSearchTermChanged,
  } = props;

  return (
    <div
      className={classNames('ptv-header', className)}
    >
      <div className="ptv-header-row main">
        <div className="button-wrapper">
          <PortalTreeViewDropdownMenu<S>
            items={menuItems}

            // After much playing around it feels better to not put contextual menu items in the header menu
            // selectedNode={selectedNode}
            treeState={treeState}
            onToggle={onMenuToggle}
            innerRef={menuRef}
          />
        </div>
        <span
          className="title"
        >
          {title}
        </span>
        <div className="button-wrapper">
          {/* Show Search Bar Button */}
          <Button
            size="sm"
            onClick={onToggleSearchBar ? () => onToggleSearchBar(!isSearchBarVisible) : undefined}
            title={isSearchBarVisible ? 'Click to disable search / filter' : 'Click to enable search / filter'}
            active={isSearchBarVisible}
            color={isSearchBarVisible ? BUTTON_COLOR.PRIMARY : undefined}
          >
            <Icon i={ICON.SEARCH} />
          </Button>

          {/* Enter Safe Mode button */}
          {showSafeModeButton && !treeState.isSafeModeEnabled && (
            <Button
              size="sm"
              onClick={onEnterSafeMode}
              title="Enable Safe Mode"
              color={BUTTON_COLOR.WARNING}
            >
              <Icon i={ICON.SAFE_MODE_DISABLED} />
            </Button>
          )}

          {/* Exit Safe Mode Button */}
          {showSafeModeButton && treeState.isSafeModeEnabled && (
            <Button
              size="sm"
              onClick={onExitSafeMode}
              title="Safe mode enabled. Click to disable."
            >
              <Icon i={ICON.SAFE_MODE_ENABLED} />
            </Button>
          )}

          {/* Add Menu */}
          {showAddButton && (
            <PortalTreeViewDropdownMenu<S>
              items={addRecordMenuItems}

              // After much playing around it feels better to not put contextual menu items in the header menu
              // selectedNode={selectedNode}
              treeState={treeState}
              onToggle={onAddRecordMenuToggle}
              innerRef={addRecordMenuRef}
              buttonCaption="Add"
              buttonIcon={ICON.ADD}
              rightAligned
            />
          )}
        </div>
      </div>
      {isSearchBarVisible && (
        <div className="ptv-header-row search">
          <PortalTreeViewSearchBar
            searchTerm={searchTerm}
            onSearchTermChanged={onSearchTermChanged}
          />
        </div>
      )}
    </div>
  );
};
