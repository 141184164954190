import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

export const CompanyNotesWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.NOTE,
  itemCaption: 'Note',
  className: 'notes-widget',
  reverseOrder: true,
  fields: [
    {
      name: 'company_id',
      visible: false,
      isParentId: true,
    },
    {
      name: 'created_by',
      title: 'Created By',
      formSaveField: 'created_by_id',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'body',
      visible: true,
      format: COLUMN_FORMAT.TEXT,
    },
  ],
};
