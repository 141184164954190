import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Card, CardHeader, Col, Container, Row, Table } from 'reactstrap';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import moment from 'moment';
import { documentTitle } from '../../utils/helpers';
import PageHeader from '../app-layout/page-header';
import { APIContext } from '../providers/api-provider';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { IProjectRecord } from '../../types/project/project.record.interface';
import { UserPicker } from '../form-input/user-picker';
import { formatValueString } from '../render-functions';
import { DateInput } from '../form-input/date-input';
import { DATE_FORMAT } from '../../constants/date-format.const';
import { IUserRecord } from '../../types/user/user.record.interface';
import { FormFieldChangeProps } from '../../types/poly-form/form-field-change-props';

interface ISalepersonInvoiceViewProps {
  title: string,
  // eslint-disable-next-line react/no-unused-prop-types
  match: {
    params: {
      [key: string]: string
    }
  }
}

interface IDataLoadResult {
  working: boolean,
  error: boolean
  data?: null | IProjectRecord[],
}

/**
 * Single Internal Project Multiview
 *
 * Allows rendering of a single internal project as either a card-view or a data record view
 *
 * @param {ISalepersonInvoiceViewProps}  props
 * @param {string}  props.title
 * @param {object}  props.match
 */
export const SalepersonInvoiceView: React.FC<ISalepersonInvoiceViewProps> = ({ title }) => {
  documentTitle(title);

  const [dataLoadResult, setDataLoadResult] = useState<IDataLoadResult>({
    working: false,
    error: false,
    data: null,
  });

  const [salesperson, setSalesperson] = useState<IUserRecord|null|undefined>(null);

  const [startDate, setStartDate] = useState(moment().subtract(1, 'month').startOf('month').toDate());
  const [endDate, setEndDate] = useState(moment().subtract(1, 'month').endOf('month').toDate());

  // set up data loading
  const { apiFetch } = useContext(APIContext);
  const etaReportAbortController = useRef<AbortController | null>(null);


  /** Load Report Data */
  const loadData = useCallback(async () => {
    if (etaReportAbortController.current) {
      etaReportAbortController.current.abort();
    }
    etaReportAbortController.current = apiAborter();

    setDataLoadResult({
      ...dataLoadResult,
      working: true,
      data: null,
    });

    let queryString = `?salesperson_id=${salesperson?.id}`;
    queryString = `${queryString}&from_date=${moment(startDate).format(DATE_FORMAT.YEAR_MONTH_DAY_DASHES)}`;
    queryString = `${queryString}&to_date=${moment(endDate).format(DATE_FORMAT.YEAR_MONTH_DAY_DASHES)}`;
    // queryString = `${queryString}&with[]=commissionIteration:id,name&with[]=commissionBracket`;

    const response = await apiFetch(
      `/report/commission/salesperson-invoices${queryString}`,
      {
        method: HTTP_METHOD.GET,
        name: 'ProjectCardViewPageListData:load',
        signal: etaReportAbortController?.current?.signal,
      },
    );

    if (response.success) {
      etaReportAbortController.current = null;
      const newProjectData = response.body.data || 0;
      setDataLoadResult({
        working: false,
        error: false,
        data: newProjectData,
      });
    } else if (!response.aborted) {
      etaReportAbortController.current = null;
      setDataLoadResult({
        working: false,
        error: true,
        data: dataLoadResult.data,
      });
    }
  }, [dataLoadResult, salesperson?.id, startDate, endDate, apiFetch]);

  useEffect(() => {
    loadData();

    return () => {
      // Kill any outstanding load when the component is un-mounted
      if (etaReportAbortController.current) {
        etaReportAbortController.current.abort();
      }
    };// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesperson, startDate, endDate]);

  const userChange = (e: FormFieldChangeProps<IUserRecord>) => {
    setSalesperson(e.objectFieldNewValue);
  };

  const startDateChange = (e: { newValue: Date }) => {
    setStartDate(e.newValue);
  };

  const endDateChange = (e: { newValue: Date }) => {
    setEndDate(e.newValue);
  };

  let personTotal = 0;
  let personCommission = 0;

  return (
    <Container fluid className="card-view">
      <PageHeader title={title} />
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col md="6">
                  <h4>
                    View for
                    {' '}
                    {salesperson?.name}
                  </h4>
                </Col>
                <Col md="2">
                  From
                  {' '}
                  <div style={{ display: 'inline-block', width: '120px' }}>
                    <DateInput id="start_date" name="start_date" value={startDate} onChange={startDateChange} placeholder="start date" />
                  </div>
                </Col>
                <Col md="2">
                  To
                  {' '}
                  <div style={{ display: 'inline-block', width: '120px' }}>
                    <DateInput id="end_date" name="end_date" value={endDate} onChange={endDateChange} placeholder="end date" />
                  </div>
                </Col>
                <Col md="2">
                  <UserPicker onChange={userChange} value={undefined} id="user_picker" name="person" placeholder="Choose Salesperson" type="commission-users" />
                </Col>
              </Row>
            </CardHeader>
            <Table>
              <thead>
                <tr>
                  {/* <th>Project</th> */}
                  <th>Invoice</th>
                  <th className="text-right">Value</th>
                  <th>Comment</th>
                  <th>Sent Date</th>
                  <th className="text-right">Commission Rate</th>
                  <th className="text-right">Commission Payable</th>
                </tr>
              </thead>
              <tbody>
                {dataLoadResult.data && dataLoadResult.data.map((projectRecord: IProjectRecord) => {
                  let projectTotal = 0;
                  let projectCommission = 0;
                  return (
                    <React.Fragment key={projectRecord.id}>
                      <tr className="bg-info text-white">
                        <td>
                          {projectRecord.project_number}
                        </td>
                        <td colSpan={5}>{projectRecord.name}</td>
                      </tr>
                      {projectRecord.invoices.map((invoiceRecord: { id: number, invoice_number: string, amount: string, comment: string, sent_date: string}) => {
                        const invoiceAmount = parseFloat(invoiceRecord.amount);
                        projectTotal += invoiceAmount;
                        personTotal += invoiceAmount;
                        const commissionAmount = ((projectRecord.commission_bracket?.commission_percentage || 0) / 100) * parseFloat(invoiceRecord.amount);
                        projectCommission += commissionAmount;
                        personCommission += commissionAmount;

                        return (
                          <tr key={invoiceRecord.id}>
                            {/* <td>{projectRecord.project_number}</td> */}
                            <td>
                              &nbsp; &nbsp; &nbsp; &nbsp;
                              {invoiceRecord.invoice_number}
                            </td>
                            <td className="currency text-right">{formatValueString(invoiceRecord.amount, 'currency')}</td>
                            <td>{invoiceRecord.comment}</td>
                            <td>{invoiceRecord.sent_date}</td>
                            <td className="text-right">{projectRecord.commission_bracket ? `${projectRecord.commission_bracket?.commission_percentage}%` : 'not set'}</td>
                            <td className="currency text-right">{formatValueString(commissionAmount, 'currency')}</td>
                          </tr>
                        ); })}
                      <tr>
                        <td><strong>{`Project Total for ${projectRecord.invoices.length} invoice${projectRecord.invoices.length > 1 ? 's' : ''}`}</strong></td>
                        <td className="currency text-right"><strong>{formatValueString(projectTotal, 'currency')}</strong></td>
                        <td />
                        <td />
                        <td />
                        <td className="currency text-right"><strong>{formatValueString(projectCommission, 'currency')}</strong></td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td><strong>{`${salesperson?.name || ''} Total for ${dataLoadResult?.data?.length || 0} project${dataLoadResult?.data?.length === 1 ? '' : 's'}`}</strong></td>
                  <td className="currency text-right"><strong>{formatValueString(personTotal, 'currency')}</strong></td>
                  <td />
                  <td />
                  <td />
                  <td className="currency text-right"><strong>{formatValueString(personCommission, 'currency')}</strong></td>
                </tr>
              </tfoot>
            </Table>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
