import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import { CurrentUserContext } from '../providers/current-user-provider';

import { DataTablePageProps } from '../../types/data-table-page.props';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { API_FILTER_OPERATION } from '../../constants/api-filter-operation.const';

export type ManageLeaveDataTablePageProps = DataTablePageProps;
/**
 * Display the "Manage Leave" data table as a whole page
 */
export const ManageLeaveDataTablePage: React.FC<ManageLeaveDataTablePageProps> = ({
  history,
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const { userDetails } = useContext(CurrentUserContext);

  // Set base query to show only leave requests that I need to approve
  const baseFilters = [
    {
      field: 'approver_id',
      operation: API_FILTER_OPERATION.EQUALS,
      values: [userDetails.id],
    },
  ];

  return (
    <DataTablePage
      headerChildren={(
        <h4>
          Leave Requests
          <Link to="/hrm/national-leave-calendar" className="pull-right btn btn-primary" target="_blank">View National Leave Calendar</Link>
        </h4>
      )}
      title="Leave Requests"
      tableIdentifier={TABLE_IDENTIFIER.MANAGE_LEAVE_TABLE}
      baseFlags={baseFlags}
      baseFilters={baseFilters}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      {children}
    </DataTablePage>
  );
};

export default ManageLeaveDataTablePage;
