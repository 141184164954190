import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import EditNewsPage from '../news/edit-news-page';
import NotificationsPage from '../notifications/notifications-page';
import FourOhFour from '../error-pages/four-oh-four';
import NewsListPageContainer from '../news/news-list-page-container';
import NewsArticlePage from '../news/news-article-page';

const CommsRoutes = () => (
  <Switch>
    <Route
      exact
      path="/comms/news"
      render={(props) => <NewsListPageContainer {...props} title="News" />}
    />
    <Route
      exact
      path="/comms/notifications"
      render={(props) => <NotificationsPage {...props} title="My Notifications" />}
    />
    <Route
      exact
      path="/comms/news/:articleId/edit"
      render={(props) => <EditNewsPage {...props} title="Edit Article" />}
    />
    <Route
      exact
      path="/comms/news/create"
      render={(props) => <EditNewsPage {...props} title="Create Article" create />}
    />
    <Route
      exact
      path="/comms/news/:articleId"
      render={(props) => <NewsArticlePage {...props} title="Read Article" />}
    />
    <Route exact path="/comms/*" component={FourOhFour} />
  </Switch>
);

CommsRoutes.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default CommsRoutes;
