import React from 'react';

interface FormFieldErrorsProps {
  errors?: null | string | string[];
}

export const FormFieldErrors: React.FC<FormFieldErrorsProps> = ({ errors }) => {
  if (!errors) return null;

  if (Array.isArray(errors)) {
    return (
      <ul className="form-field-errors">
        {errors.map((e) => (
          <li key={e} className="form-field-error">{e}</li>
        ))}
      </ul>
    );
  }

  return (
    <span className="form-field-error">{errors}</span>
  );
};
