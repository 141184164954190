import React from 'react';
import AsyncSelect from './async-select';
import { MiniProjectSummary } from '../mini-project-summary/mini-project-summary';
import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';
import { IProjectRecord } from '../../types/project/project.record.interface';
import { ProjectNumber } from '../data-format/project-number';

export type ProjectPickerProps = AsyncSelectComponentProps;

/**
 * Called by the select to render one of the options in the dropdown
 *
 * @see https://react-select.com/props
 *
 * @param option the option data to render
 * @param details information about where and what is being rendered
 * @param inputValue the search term the user has entered into the input
 */
const renderOption = (option: IProjectRecord, labelMeta: Record<string, unknown>, inputValue: string | number | null): string | React.ReactNode => {
  if (labelMeta.context === 'menu') {
    return (
      <MiniProjectSummary pData={option} highlightText={inputValue ? inputValue.toString() : null} />
    );
  }

  return <ProjectNumber pData={option} linkToProject={false} />;
};

/**
 * Project Picker
 */
export const ProjectPicker: React.FC<ProjectPickerProps> = (props) => (
  <AsyncSelect
    {...props}
    valueKey="id"
    labelKey="project_number"
    searchRoute="/project?with[]=client&search="
    useSearch={false}
    renderOption={renderOption}
    loadAndKeepAll
  />
);
