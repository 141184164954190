export const DATATABLE_FILTER_COLUMN = 'DATATABLE_FILTER_COLUMN';
export const DATATABLE_CLEAR_COLUMN_FILTERS = 'DATATABLE_CLEAR_COLUMN_FILTERS';

/**
 *
 * - Reducer: DATATABLE_FILTER_COLUMN
 * @param {string} tableIdentifier table identifier in redux, eg. 'projectsTable'
 * @param {string} columnName
 * @param {{
 *    name: string,
 *    operation: string,
 *    values: string[]
 * }} filter name of column
 * @param {boolean} [pushToHistory=false] whether to push the changes resulting from the action to history
 * @param {boolean} [replaceInHistory=false] whether to replace the changes resulting from the action in history
 *  See `initialState` in `table-settings-reducer`
 */
export function filterColumn(tableIdentifier, columnName, filter, pushToHistory = false, replaceInHistory = false) {
  return {
    type: DATATABLE_FILTER_COLUMN,
    name: columnName,
    tableIdentifier,
    filter,
    pushToHistory,
    replaceInHistory,
  };
}

/**
 * Clears all queries from all columns in the table
 * - Reducer: DATATABLE_CLEAR_COLUMN_FILTERS
 * @param {string} tableIdentifier table identifier in redux, eg. 'projectsTable'
 * @param {boolean} [pushToHistory=false] whether to push the changes resulting from the action to history
 * @param {boolean} [replaceInHistory=false] whether to replace the changes resulting from the action in history
 *  See `initialState` in `table-settings-reducer`
 */
export function clearColumnFilters(tableIdentifier, pushToHistory = false, replaceInHistory = false) {
  return {
    type: DATATABLE_CLEAR_COLUMN_FILTERS,
    tableIdentifier,
    pushToHistory,
    replaceInHistory,
  };
}
