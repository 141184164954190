import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';

export const CommissionStructureBracketsWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Bracket',
  className: 'internal-projects-widget',
  isReadOnly: false,
  showAddBtn: true,
  fields: [
    {
      name: 'id',
      title: 'ID',
      format: COLUMN_FORMAT.ID_LINK_SHORT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      linkRoute: '/finance/sales-commission/commission-brackets',
      visible: true,
    },
    {
      name: 'iteration_id',
      visible: false,
      isParentId: true,
    },
    {
      name: 'net_profit_threshold',
      title: 'Net Profit %',
      description: 'Net Profit Threshold percentage',
      format: COLUMN_FORMAT.NUMBER,
      visible: true,
    },
    {
      name: 'commission_percentage',
      title: 'Commission %',
      format: COLUMN_FORMAT.NUMBER,
      visible: true,
    },
  ],
};
