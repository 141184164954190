const initialState = {
  apiConnected: false,
  webSocket: false,
  authenticated: false,

};

const appStatus = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default appStatus;
