import React, { ReactNode, useCallback } from 'react';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { FormRendererProps } from '../../../types/poly-form/form-renderer.props';

import Icon from '../../layout-helpers/icon';
import { FormButtons } from '../form-buttons';
import { FormFieldRenderer } from '../form-field-renderer';
import FriendlyFormMessage from '../../layout-helpers/friendly-form-message';

import { fullName } from '../../../helpers/full-name.helper';
import { hasPermittedAction } from '../../../helpers/has-permitted-action.helper';

import { A_PROJECT_CONTACT_TYPE, MAX_PROJECT_CONTACT_TYPE } from '../../../constants/project-contact-type.const';
import { API_ACTION } from '../../../constants/api-action.const';
import { ICON } from '../../../constants/icon.const';
import { FORM_FIELD_TYPE } from '../../../constants/form-field-type.const';

export type ContactsFormRendererProps = FormRendererProps;

// todo: at some point these colours should be specifically chosen to avoid confusion when new contact types are added
const getContactCardColor = (contactTypeId: A_PROJECT_CONTACT_TYPE) => Math.floor((360 / MAX_PROJECT_CONTACT_TYPE) * (contactTypeId - 1));

export const ContactsFormRenderer:React.FC<ContactsFormRendererProps> = (props) => {
  const {
    children,
    className,
    parentData,
    itemCaption,

    primaryKeyValue,
    fields,
    formData,

    isReadOnly,
    isNewRecord = false,
    isEditing,
    isLocked,
    isBusy = false,

    showFieldInfo = false,
    hasSuccess = false,
    formMessage,
    hasErrors = false,
    errors,

    permittedActions,

    formDeleteConfirmationType,

    onFieldChange,

    lockForm,
    startEditRecord,
    endEditRecord,
    deleteRecord,
  } = props;


  // Extract the person from the form data
  const {
    person = undefined,
    type_id = undefined,
    type = undefined,
    send_notifications = false,
  } = formData;


  /**
   * Render out the contact card in "read only" mode
   */
  const renderContactForDisplay = useCallback(():ReactNode => {
    // Get the form data from the person object OR the formData
    // This control may be rendered as a standalone company contact or as a projectContact
    const {
      id,
      role,
      email,
      office,
      mobile,
      first,
      middle,
      last,
      company,
      address_line_one,
      address_line_two,
      address_town,
      address_postcode,
      type: contactType,
    } = person ?? formData;

    const link = `/crm/contacts/${id}`;
    const name = fullName({ first, middle, last });
    const typeName = type?.name ?? '';
    const companyName = company ? company.name : undefined;
    const companyId = company ? company.id : undefined;
    const companyLink = company ? `/crm/companies/${companyId}/detail` : undefined;

    return (
      <div className="ci-contact-display">
        <div className="ci-image-container" style={{ filter: `hue-rotate(${getContactCardColor(type_id)}deg)` }}>
          <div className="contact-name">
            <Link to={link ?? '#'}>{name}</Link>
          </div>
          {contactType && (
            <div className="contact-type">
              {!!send_notifications && (
                <Icon i={ICON.SEND_NOTIFICATIONS} title="Send email notifications to this contact" />
              )}
              <span>{typeName}</span>
            </div>
          )}
        </div>
        <div className="info-container">
          <div className="contact-head">
            {companyName && (
              <div className="contact-company">
                <Link to={companyLink || '#'}>{companyName}</Link>
              </div>
            )}
            <div className="contact-role">
              <span>{role}</span>
            </div>
          </div>
          <div className="contact-details">
            {email && (
              <div className="contact-email">
                <span><a href={`mailto:${email}`}>{email}</a></span>
              </div>
            )}
            {office && (
              <div className="contact-office">
                <a href={`tel:${office}`}>{office}</a>
              </div>
            )}
            {mobile && (
              <div className="contact-mobile">
                <a href={`tel:${mobile}`}>{mobile}</a>
              </div>
            )}
          </div>
          <div className="contact-address">
            {address_line_one && (
              <div className="contact-address-line mt-2">
                {address_line_one}
              </div>
            )}
            {address_line_two && (
              <div className="contact-address-line">
                {address_line_two}
              </div>
            )}
            {address_town && (
              <div className="contact-address-line">
                {address_town}
                {address_postcode ? `, ${address_postcode}` : ''}
              </div>
            )}
          </div>
          <div className="end-spacer" />
        </div>
      </div>
    );
  }, [formData, person, send_notifications, type?.name, type_id]);


  /**
   * Render out the contact card in "edit" mode
   */
  const renderContactForEditing = useCallback(():ReactNode => {
    const personTypeName = type?.name ?? '';
    const personName = person ? fullName(person) : null;

    return (
      <div className="ci-contact-display">
        <div className="ci-image-container" style={{ filter: `hue-rotate(${getContactCardColor(formData.type_id)}deg)` }}>
          <div className="contact-name">
            <span>{personName ?? (isNewRecord ? 'New Contact' : '')}</span>
          </div>
          <div className="contact-type">
            {!!formData.send_notifications && (
              <Icon i={ICON.SEND_NOTIFICATIONS} title="Send email notifications to this contact" />
            )}
            <span>{personTypeName}</span>
          </div>
        </div>
        <div className="contact-edit-fields">
          {fields.map((field) => {
            // showInForm is true by default, but if we have set this, don't render
            if (field.showInForm === false) return null;

            // Don't even render a "NotEditable" unless the user form has an ID
            if (field.formFieldType === FORM_FIELD_TYPE.NOT_EDITABLE && isNewRecord) return null;

            // Render the field
            return (
              <FormFieldRenderer
                id={field.id}
                key={field.id}
                inline

                parentData={parentData}
                formData={formData}
                field={field}
                errors={errors}

                showFieldInfo={showFieldInfo}
                formIsLocked={isLocked}
                isReadOnly={!isEditing || isReadOnly || field.isReadOnly || isBusy}

                lockForm={lockForm}
                onChange={onFieldChange}
              />
            );
          })}
        </div>
      </div>
    );
  }, [errors, fields, formData, isBusy, isEditing, isLocked, isNewRecord, isReadOnly, lockForm, onFieldChange, parentData, person, showFieldInfo, type?.name]);


  /**
   * Wrapper around the startEditRecord method
   */
  const handleStartEditRecord = useCallback(() => {
    if (startEditRecord) {
      startEditRecord(primaryKeyValue, formData);
    }
  }, [formData, primaryKeyValue, startEditRecord]);


  /**
   * Wrapper around the endEditRecord method
   */
  const handleEndEditRecord = useCallback((saveChanges: boolean) => {
    if (endEditRecord) {
      endEditRecord(saveChanges, primaryKeyValue, formData);
    }
  }, [endEditRecord, formData, primaryKeyValue]);


  /**
   * Wrapper around the deleteRecord method
   */
  const handleDeleteRecord = useCallback(() => {
    if (deleteRecord) {
      deleteRecord(primaryKeyValue, formData);
    }
  }, [deleteRecord, formData, primaryKeyValue]);

  // Render
  return (
    <div
      className={classnames(className, 'ci-contact', {
        'has-errors': hasErrors,
        'contact-edit': isEditing,
      })}
      key={`contact-${formData.id || 'new'}`}
    >
      {children}
      {!isEditing && renderContactForDisplay()}
      {isEditing && renderContactForEditing()}
      {isEditing && (
        <FormButtons
          className="ci-contact-form-buttons"
          buttonsClassName="border-0"
          itemCaption={itemCaption}
          inline
          deleteInEditMode

          permittedActions={permittedActions ?? {}}
          formDeleteConfirmationType={formDeleteConfirmationType}

          formIsEditing={isEditing}
          formIsReadOnly={isReadOnly}
          formIsCreating={isNewRecord}
          formIsBusy={isBusy}
          formIsLocked={isLocked}

          startEditRecord={handleStartEditRecord}
          endEditRecord={handleEndEditRecord}
          deleteRecord={handleDeleteRecord}
        />
      )}
      {!isReadOnly && !isEditing && hasPermittedAction(permittedActions, API_ACTION.UPDATE) && (
        <div className="ci-contact-form-buttons">
          <Button
            className="border-0"
            color="transparent"
            size="sm"
            onClick={handleStartEditRecord}
          >
            <Icon i="pencil" />
          </Button>
        </div>
      )}
      <FriendlyFormMessage
        formMessage={formMessage}
        hasSuccess={hasSuccess}
        hasErrors={hasErrors}
        errors={errors}
        useSimpleDefault
        showList
        inline
      />
    </div>
  );
};
