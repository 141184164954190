import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateTableSettings } from '../../../actions/portal-data-table/update-settings';
import { TableSearchControls } from './table-search-controls';
import SORT_DIRECTION from '../../../utils/sort-directions';

/**
 * Table Controls component
 */
class ApiReduxTableSearchControls extends React.Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      internalSearchTerm: props.searchTerm ?? '',
    };
    this.searchInputRef = React.createRef();
  }


  /**
   * shouldComponentUpdate
   */
  shouldComponentUpdate(nextProps) {
    const {
      internalSearchTerm,
    } = this.state;

    const {
      location: oldLocation,
      searchTerm: oldSearchTerm,
    } = this.props;

    const {
      location: newLocation,
      searchTerm: newSearchTerm,
    } = nextProps;

    // This is a navigation event.
    // We want the content of internalSearch to reflect the current props REGARDLESS of the state of focus
    if (oldLocation.search !== newLocation.search) {
      // Clear any debounce timeouts so that the navigation change isn't overridden by an outstanding debounced search input
      this.setState({
        internalSearchTerm: nextProps.searchTerm ?? '',
      });
      return false;
    }

    // Has the external search term been updated and is it different from the internal search term
    if (
      (oldSearchTerm ?? '').trim() !== (newSearchTerm ?? '').trim() &&
      ((newSearchTerm ?? '').trim() !== internalSearchTerm.trim())
    ) {
      this.setState({
        internalSearchTerm: nextProps.searchTerm ?? '',
      });
      return false;
    }

    return true;
  }


  /**
   * @description
   * Fired when the user toggles the sort button to clear any active sorts on the table
   */
  handleClearSortedColumns = () => {
    if (this.props.sortedColumns.length > 0) this.props.dispatchClearSortedColumns(true);
  };


  /**
   * @description
   * Performs the dispatch to Redux of the new settings based on the table controls
   *
   * @param {boolean} pushToHistory whether these settings should become part of the user's navigation history
   */
  updateSettings = (pushToHistory) => {
    const { internalSearchTerm } = this.state;
    const { dispatchUpdateTableSettings } = this.props;

    const newSettings = { searchTerm: internalSearchTerm };
    if (pushToHistory) { newSettings.pushToHistory = true; }

    dispatchUpdateTableSettings(newSettings);
  }


  /**
   * Handle Search Term
   *
   * @param {string} newTerm The new searh term
   * @param {boolean} pushToHistory should the search term be pushed to the url history?
   */
  handleSearchTermChange = (newTerm = '', pushToHistory = true) => {
    this.setState({
      internalSearchTerm: newTerm,
    }, () => this.updateSettings(pushToHistory));
  }


  /**
   * Render
   */
  render() {
    const {
      onToggleColumnFilterDrawer,
      onResetTableSettings,

      isLoading,
      isColumnFilterDrawerVisible,

      searchTerm,
      sortedColumns,
      filteredColumns,
    } = this.props;

    const { internalSearchTerm } = this.state;
    const isSearched = !!((searchTerm && searchTerm.trim() !== ''));
    const isSorted = sortedColumns.length > 0;
    const isFiltered = (filteredColumns.length > 0);

    // Return <TableSearchControls />
    return (
      <TableSearchControls
        searchTerm={internalSearchTerm}
        isSearched={isSearched}
        isSorted={isSorted}
        isFiltered={isFiltered}
        isLoading={isLoading}
        filteredColumns={filteredColumns}
        sortedColumns={sortedColumns}
        isColumnFilterDrawerVisible={isColumnFilterDrawerVisible}
        onResetTableSettings={onResetTableSettings}
        onToggleColumnFilterDrawer={onToggleColumnFilterDrawer}
        onClearSortedColumns={this.handleClearSortedColumns}
        onSearchTermChange={this.handleSearchTermChange}
      />
    );
  }
}

ApiReduxTableSearchControls.defaultProps = {
  searchTerm: '',
};

ApiReduxTableSearchControls.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,

  onToggleColumnFilterDrawer: PropTypes.func.isRequired,
  onResetTableSettings: PropTypes.func.isRequired,

  isColumnFilterDrawerVisible: PropTypes.bool.isRequired,

  // Redux
  searchTerm: PropTypes.string,
  sortedColumns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    direction: PropTypes.oneOf(Object.values(SORT_DIRECTION)),
    sortIndex: PropTypes.number,
  })).isRequired,
  filteredColumns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    operation: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  })).isRequired,
  isLoading: PropTypes.bool.isRequired,

  // Dispatch
  dispatchUpdateTableSettings: PropTypes.func.isRequired,
  dispatchClearSortedColumns: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const tableSettings = state.tableSettings[ownProps.tableIdentifier];
  return {
    pageSize: tableSettings.pageSize,
    searchTerm: tableSettings.searchTerm,
    sortedColumns: tableSettings.sortedColumns,
    filteredColumns: tableSettings.filteredColumns,
    isLoading: tableSettings.isLoading,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchUpdateTableSettings: (settings) => dispatch(updateTableSettings(ownProps.tableIdentifier, settings)),
  dispatchClearSortedColumns: (pushToHistory) => dispatch(updateTableSettings(ownProps.tableIdentifier, { sortedColumns: [], pushToHistory })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApiReduxTableSearchControls);
