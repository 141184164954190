import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { EventEmitter } from 'events';
import RecordDetailView from '../../record-detail-view/record-detail-view';
import TableCell from './table-cell';
import ProgressBar from '../../layout-helpers/progress-bar';
import { updateRowData } from '../../../actions/portal-data-table/refresh-row-data';
import { updateTableSettings } from '../../../actions/portal-data-table/update-settings';
import { APIContext } from '../../providers/api-provider';
import HISTORY_PROP_TYPES from '../../../prop-types/history-prop-types';

/**
 * <TableBody /> component for Data Table, usually inside <PortalTable />
 */
const TableBody = (props) => {
  const {
    tableIdentifier,
    rowKeyField,
    recordSet,
    orderedVisibleColumns,
    isLoading,
    hasError,
    openRowId,
    dispatchRefreshRecord,
    dispatchUpdateTableSettings,
    history,
    match,
    location,
    tableWrapperRef,
    tableResizeListener,
    tableScrollListener,
    onDirtyRowChange,
  } = props;

  const [recordSetDataChecksum, setRecordSetDataChecksum] = useState(0);
  const colSpan = orderedVisibleColumns.length;
  const { apiFetch } = useContext(APIContext);

  const [dirtyRow, setDirtyRow] = useState({
    rowId: null,
    isDirty: false,
  });

  /**
   * @description
   * In order to encourage the Widget data to re-load when a major change happens to the record data
   * We keep a checksum (or in this case just an iterator) and pass it down
   */
  useEffect(() => {
    setRecordSetDataChecksum((currentValue) => currentValue + 1);
  }, [recordSet]);


  /**
   * @description
   * Whenever the openRowId changes, reset the isDirty flag.
   */
  useEffect(() => {
    setDirtyRow({
      rowId: null,
      isDirty: false,
    });
  }, [openRowId]);


  /**
   * @description
   * Fired whenever the dirty row changes. Used to notify the parent.
   */
  useEffect(() => {
    if (onDirtyRowChange) {
      onDirtyRowChange(dirtyRow);
    }
  }, [onDirtyRowChange, dirtyRow]);


  /**
   * @description
   * Fired when the user clicks on a row
   *
   * @param {string | null} clickedRowId
   */
  const handleRowClick = (clickedRowId) => {
    const oldRowId = openRowId;
    const { rowId: dirtyRowId, isDirty: rowIsDirty } = dirtyRow;

    // If the openRowId isDirty, display a window confirmation warning the user about losing their unsaved changes
    if (dirtyRowId && openRowId && (dirtyRowId.toString() === openRowId.toString()) && rowIsDirty) {
      // eslint-disable-next-line no-alert
      if (!window.confirm('Discard your unsaved changes?')) {
        return;
      }

      // If the user pushes their row change, make sure the parent doesn't care about the dirty record any more
      if (onDirtyRowChange) {
        onDirtyRowChange({
          rowId: dirtyRowId,
          isDirty: false,
        });
      }
    }

    const newRowId = (typeof oldRowId === 'string' && clickedRowId === oldRowId) ? null : clickedRowId;

    let pushToHistory = false;
    let replaceInHistory = false;

    // if old = something and new = something then push | when changing open rows
    if (newRowId && oldRowId) pushToHistory = true;
    // if old = nothing and new = something then replace | when opening a row
    else if (!oldRowId && newRowId) replaceInHistory = true;
    // if old = something and new = nothing then replace | when closing a row
    else if (oldRowId && !newRowId) replaceInHistory = true;

    dispatchUpdateTableSettings({
      openRowId: newRowId,
      pushToHistory,
      replaceInHistory,
    });
  };


  /**
   * @description
   * Fired when the RecordDetailView has a significant change to the data which
   * may warrant warning the user before they navigate away
   *
   * @param {number} rowId
   * @param {boolean} newIsDirty
   */
  const handleRowIsDirtyChange = (rowId, newIsDirty) => {
    setDirtyRow({
      rowId,
      isDirty: newIsDirty,
    });
  };


  // Return <TableBody /> Loading / Error
  if (recordSet.length === 0 && isLoading) {
    return (
      <tbody>
        <tr className="portal-datatable-row">
          <td colSpan={colSpan}>
            <div className="loader-container p-10">
              {hasError ? (
                <p className="note note-danger">Sorry! There was an error loading the items</p>
              ) : (
                <>
                  <p>Loading...</p>
                  <ProgressBar complete={hasError} />
                </>
              )}
            </div>
          </td>
        </tr>
      </tbody>
    );
  }

  // Return <TableBody />
  return (
    <>
      <tbody>

        {/* Table has data */}
        {recordSet && recordSet.length > 0 && recordSet.map((row) => {
          const rowId = row[rowKeyField];
          return (
            <Fragment key={rowId}>
              <tr
                className={classnames('portal-datatable-row', {
                  active: String(rowId) === String(openRowId),
                })}
                data-id={rowId}
                data-open-row-id={openRowId}
                key={rowId}
                onClick={() => handleRowClick(String(rowId))}
              >
                {orderedVisibleColumns.map((column) => column.visible && (
                  <TableCell
                    row={row}
                    column={column}
                    key={`${rowId}-${column.name}`}
                    rowData={row}
                  />
                ))}
              </tr>
              {typeof openRowId === 'string' && (String(rowId) === String(openRowId)) && (
                <tr className="detail-row" key={`details-of-${rowId}`}>
                  <td
                    className="datatable-detail-contents"
                    colSpan={colSpan}
                    key={`detail-cell-of-${rowId}`}
                  >
                    <RecordDetailView
                      tableIdentifier={tableIdentifier}
                      rowData={row}
                      rowKeyField={rowKeyField}
                      rowDataChecksum={recordSetDataChecksum}
                      refreshRecord={() => dispatchRefreshRecord(rowId, apiFetch)}
                      history={history}
                      match={match}
                      location={location}
                      tableWrapperRef={tableWrapperRef}
                      tableResizeListener={tableResizeListener}
                      tableScrollListener={tableScrollListener}
                      onDirtyChange={(newIsDirty) => handleRowIsDirtyChange(rowId, newIsDirty)}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          );
        })}
      </tbody>
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  orderedVisibleColumns: state.tableSettings[ownProps.tableIdentifier].orderedVisibleColumns,
  openRowId: state.tableSettings[ownProps.tableIdentifier].openRowId,
  // tabs: state.tableSettings[ownProps.tableIdentifier].tabs,
  isLoading: state.tableSettings[ownProps.tableIdentifier].isLoading,
  hasError: state.tableSettings[ownProps.tableIdentifier].hasError,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const { tableIdentifier } = ownProps;
  return {
    dispatchUpdateTableSettings: (settings) => dispatch(updateTableSettings(tableIdentifier, settings)),
    dispatchRefreshRecord: (id, apiFetch) => {
      dispatch(updateRowData(tableIdentifier, id, apiFetch));
    },
  };
};

TableBody.defaultProps = {
  openRowId: null,
  rowKeyField: 'id',
  tableWrapperRef: null,
  tableResizeListener: null,
  tableScrollListener: null,
  onDirtyRowChange: null,
};

TableBody.propTypes = {
  tableIdentifier: PropTypes.string.isRequired,
  rowKeyField: PropTypes.string,
  tableWrapperRef: PropTypes.shape({ current: PropTypes.shape({}) }),
  tableResizeListener: PropTypes.instanceOf(EventEmitter),
  tableScrollListener: PropTypes.instanceOf(EventEmitter),
  recordSet: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDirtyRowChange: PropTypes.func,
  // Router
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape(HISTORY_PROP_TYPES).isRequired,
  match: PropTypes.shape({}).isRequired,
  // Redux
  orderedVisibleColumns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  openRowId: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  // Dispatch
  dispatchRefreshRecord: PropTypes.func.isRequired,
  dispatchUpdateTableSettings: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableBody);
