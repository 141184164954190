import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProjectSummaryTableCellGroup from './project-summary-table-cell-group';
import ProjectSummaryTableRow from './project-summary-table-row';
import { DATA_TYPE, buildDomDataTagId } from './project-summary-helpers';
import Icon from '../../layout-helpers/icon';
import ProjectSummaryTableProjectMonthlyDataRows from './project-summary-table-project-monthly-data-rows';

const ProjectSummaryTableProjectManagerMonthlyDataRows = (props) => {
  const {
    monthlyFields,
    monthGroups,
    visibleMonthlyFieldCount,
    pmAndStateIds,

    onClick,
    onBeginHover,
    onEndHover,

    stateId,
    projectManagerData,
    projectData,
  } = props;

  return (
    !!projectManagerData && (
      <>
        {/* No Project Manager Monthly Data - just display an empty cell. */}
        {(!!projectManagerData.error || !projectManagerData.monthlyData.data.length) && (
          <ProjectSummaryTableRow
            className={classNames('project-manager', 'monthly', 'data', 'no-data', 'error', { loading: projectManagerData.isLoading })}
          >
            {monthGroups.map((monthGroup) => (
              <ProjectSummaryTableCellGroup
                key={`project_manager_${stateId}_${monthGroup.key}`}
                span={visibleMonthlyFieldCount}
              />
            ))}
          </ProjectSummaryTableRow>
        )}

        {!projectManagerData.error && !!projectManagerData.summaryData.data.length && (
          <>
            {/* Project Manager Monthly Data Fields */}
            {projectManagerData.summaryData.data.map((projectManager) => (
              <React.Fragment
                key={`state_${projectManager.state_id}_project_manager_${projectManager.project_manager_id}`}
              >

                <ProjectSummaryTableRow
                  key={`project_manager_${projectManager.project_manager_id}_monthly_data`}
                  className={classNames('project-manager', 'monthly', 'data', {
                    loading: projectManagerData.isLoading,
                    expanded: !!pmAndStateIds.find((pmAndStateId) => pmAndStateId.stateId === projectManager.state_id && pmAndStateId.pmId === projectManager.project_manager_id),
                  })}
                  trackHover
                  dataType={DATA_TYPE.PROJECT_MANAGER}
                  id={buildDomDataTagId(DATA_TYPE.PROJECT_MANAGER, projectManager.state_id, projectManager.project_manager_id)}
                  onBeginHover={onBeginHover}
                  onEndHover={onEndHover}
                  onClick={onClick}
                >
                  {monthGroups.map((monthGroup) => {
                    // Look for the projectManager / month combination in the project manager monthly data
                    const ProjectManagerMonthData = projectManagerData.monthlyData.data
                      .find((data) => data.project_manager_id === projectManager.project_manager_id && data.month === monthGroup.key);

                    // Found it; Render it.
                    if (ProjectManagerMonthData) {
                      return (
                        <ProjectSummaryTableCellGroup
                          key={`state_${projectManager.state_id}_project_manager_${projectManager.project_manager_id}_${monthGroup.key}`}
                          rowKey={`state_${projectManager.state_id}_project_manager_${projectManager.project_manager_id}_${monthGroup.key}`}
                          fields={monthlyFields}
                          data={ProjectManagerMonthData}
                        />
                      );
                    }

                    // Doesn't exist - render an empty / loading cell
                    return (
                      <ProjectSummaryTableCellGroup
                        key={`state_${projectManager.state_id}_project_manager_${projectManager.project_manager_id}_${monthGroup.key}_loading`}
                        span={visibleMonthlyFieldCount}
                      >
                        {projectManagerData.isLoading && (
                          <>
                            <Icon i="rolling" />
                            <span>Loading...</span>
                          </>
                        )}
                      </ProjectSummaryTableCellGroup>
                    );
                  })}
                </ProjectSummaryTableRow>

                {/* Project Monthly Data */}
                {projectData.find((project) => project.stateId === projectManager.state_id && project.projectManagerId === projectManager.project_manager_id) && (
                  <ProjectSummaryTableProjectMonthlyDataRows
                    monthGroups={monthGroups}
                    monthlyFields={monthlyFields}
                    visibleMonthlyFieldCount={visibleMonthlyFieldCount}

                    onClick={onClick}
                    onBeginHover={onBeginHover}
                    onEndHover={onEndHover}

                    stateId={projectManager.state_id}
                    projectManagerId={projectManager.project_manager_id}
                    projectData={projectData.find((project) => project.stateId === projectManager.state_id && project.projectManagerId === projectManager.project_manager_id)}
                  />
                )}

              </React.Fragment>

            ))}
          </>
        )}
      </>
    )
  );
};

ProjectSummaryTableProjectManagerMonthlyDataRows.propTypes = {
  monthlyFields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  })).isRequired,
  monthGroups: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  stateId: PropTypes.number.isRequired,
  projectManagerData: PropTypes.shape({
    stateId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    summaryData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
    monthlyData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
  }).isRequired,
  projectData: PropTypes.arrayOf(
    PropTypes.shape({
      stateId: PropTypes.number.isRequired,
      projectManagerId: PropTypes.number.isRequired,
      isLoading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      summaryData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
      monthlyData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
    }),
  ).isRequired,
  visibleMonthlyFieldCount: PropTypes.number.isRequired,
  pmAndStateIds: PropTypes.arrayOf(PropTypes.shape({
    pmId: PropTypes.number.isRequired,
    stateId: PropTypes.number.isRequired,
  })).isRequired,
  onClick: PropTypes.func.isRequired,
  onBeginHover: PropTypes.func.isRequired,
  onEndHover: PropTypes.func.isRequired,
};

export default ProjectSummaryTableProjectManagerMonthlyDataRows;
