import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';

/**
 * Table Definition for People (Sprints) Table
 */
const sprintsTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Sprint',
  initialFormFocusFieldName: 'first',
  baseRoute: '/sprint',
  baseQueryString: [
    'with[]=createdBy:id,name,initials',
    'with_count[]=projects',
  ].join('&'),

  /**
   * Definition of presets for the table columns
   */
  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'name',
        'description',
        'start',
        'end',
        'count_of_projects',
        'progress',
      ],
    },
    {
      name: 'tiny',
      title: 'Tiny',
      fields: [
        'name',
        'description',
      ],
    },
    {
      name: 'all',
      title: 'All Fields',
      fields: [
        'id',
        'name',
        'description',
        'start',
        'end',
        'count_of_projects',
        'progress',
      ],
    },
  ],

  /**
   * Definition of columns and APIPolyForm fields for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Identity',
      description: 'Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      linkRoute: '/internal-projects/sprints',
      visible: false,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'name',
      title: 'Name',
      description: 'Sprint Name',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: '/internal-projects/sprints',
      visible: true,
      showInForm: true,
      required: true,
      order: 10,
      formOrder: 10,
    },
    {
      name: 'description',
      title: 'Sprint Description',
      description: 'Sprint Description',
      format: COLUMN_FORMAT.TEXT,
      visible: true,
      required: true,
      maxLength: 50,
      order: 20,
      formOrder: 20,
    },
    {
      title: 'Start Date',
      name: 'start',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: true,
      showInForm: true,
      required: true,
      order: 30,
      formOrder: 30,
    },
    {
      title: 'End Date',
      name: 'end',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: true,
      showInForm: true,
      required: true,
      order: 40,
      formOrder: 40,
    },
    {
      name: 'count_of_projects',
      title: 'Projects',
      description: 'Total count of projects for sprint',
      format: COLUMN_FORMAT.NUMBER,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: false,
      order: 50,
      formOrder: 50,
    },
    {
      title: 'Progress',
      name: 'progress',
      format: COLUMN_FORMAT.PROGRESS_PERCENT,
      formFieldType: FORM_FIELD_TYPE.PROGRESS_PERCENT_INPUT,
      filterFormat: COLUMN_FORMAT.NUMBER,
      visible: true,
      showInForm: true,
      order: 60,
      formOrder: 60,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add a Sprint',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new Sprint',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update the sprint',
      successMessage: 'Updated sprint',
      failMessage: 'Failed to update Sprint',
      hideRecordActionButton: true,
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the Sprint',
      successMessage: 'Sprint deleted',
      failMessage: 'Failed to delete Sprint',
      hideRecordActionButton: true,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Sprint Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'projects',
      title: 'Projects',
      description: 'Internal Projects Assigned to Sprint',
      widgetDefinition: WIDGET_DEFINITION.INTERNAL_PROJECTS,
      apiRoute: (row) => {
        const baseRoute = '/internal-project';
        const apiQuery = [
          'filter[0][field]=sprint_id',
          `filter[0][operation]=${API_FILTER_OPERATION.EQUALS}`,
          `filter[0][value]=${row.id}`,
          'sort[0][field]=priority',
          'sort[0][direction]=asc',
          'pagelength=100',
        ].join('&');
        return `${baseRoute}?${apiQuery}`;
      },
    },
    {
      name: 'tasks',
      title: 'Tasks',
      description: 'Tasks Assigned to Sprint',
      widgetDefinition: WIDGET_DEFINITION.SPRINT_TASKS,
      apiRoute: (row) => {
        const baseRoute = '/internal-project-item';
        const apiQuery = [
          'filter[0][field]=sprint_id',
          `filter[0][operation]=${API_FILTER_OPERATION.EQUALS}`,
          `filter[0][value]=${row.id}`,
          'with[]=internalProject:id,name,status_id',
          'sort[0][field]=priority',
          'sort[0][direction]=asc',
          'pagelength=100',
        ].join('&');
        return `${baseRoute}?${apiQuery}`;
      },
    },
    {
      name: 'swimlanes',
      title: 'Swimlanes',
      description: 'Swimlanes for Sprint',
      widgetDefinition: WIDGET_DEFINITION.SPRINT_SWIMLANES,
      apiRoute: (row) => {
        const baseRoute = '/internal-project-item';
        const apiQuery = [
          'with[]=internalProject:id,name,status_id',
          'filter[0][field]=sprint_id',
          `filter[0][operation]=${API_FILTER_OPERATION.EQUALS}`,
          `filter[0][value]=${row.id}`,
          'sort[0][field]=priority',
          'sort[0][direction]=asc',
          'pagelength=100',
        ].join('&');
        return `${baseRoute}?${apiQuery}`;
      },
    },
    {
      ...eventsTabDefault,
    },
  ],
};

export default sprintsTableInitialSettings;
