import React, { useState } from 'react';
import {
  Input, FormGroup, Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Modal } from './Modal';
import { noop } from '../../utils/helpers';
import { LIKELIHOOD_VALUES } from '../form-input/likelihood-input';
import { MODAL_BUTTON_SIDE_TYPE } from '../../constants/modal-button-side-type.const';
import { ICON } from '../../constants/icon.const';
import { BUTTON_COLOR } from '../../constants/button-color.const';

export const SalesForecastDashboardSettingsModal = (props) => {
  const {
    id,
    closeModal,
    onModalComplete,
    onCancel,
    summaryFields,
    monthlyFields,
    hideEmptyStates,
    // displayStatuses,
    hideZeroValues,
    lowLikelihoodFilter,
    highLikelihoodFilter,
    showProjectNames,
  } = props;

  const [isDirty, setIsDirty] = useState(false);
  const [internalSummaryFields, setInternalSummaryFields] = useState(summaryFields);
  const [internalMonthlyFields, setInternalMonthlyFields] = useState(monthlyFields);
  // const [internalDisplayStatuses, setInternalDisplayStatuses] = useState(displayStatuses);
  const [internalHideEmptyStates, setInternalHideEmptyStates] = useState(hideEmptyStates);
  const [internalHideZeroValues, setInternalHideZeroValues] = useState(hideZeroValues);
  const [internalLowLikelihoodFilter, setInternalLowLikelihoodFilter] = useState(lowLikelihoodFilter);
  const [internalHighLikelihoodFilter, setInternalHighLikelihoodFilter] = useState(highLikelihoodFilter);
  const [internalShowProjectName, setInternalShowProjectName] = useState(showProjectNames);

  const modalButtons = [
    {
      label: 'Save',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.CHECK,
      sideType: MODAL_BUTTON_SIDE_TYPE.RIGHT,
      onClick: () => {
        closeModal(id);
        if (typeof onModalComplete === 'function') {
          onModalComplete({
            settingsChanged: isDirty,
            newSettings: {
              summaryFields: internalSummaryFields,
              monthlyFields: internalMonthlyFields,
              // displayStatuses: internalDisplayStatuses,
              hideEmptyStates: internalHideEmptyStates,
              hideZeroValues: internalHideZeroValues,
              lowLikelihoodFilter: internalLowLikelihoodFilter,
              highLikelihoodFilter: internalHighLikelihoodFilter,
              showProjectNames: internalShowProjectName,
            },
          });
        }
      },
      disabled: (typeof onModalComplete !== 'function'),
    },
    {
      label: 'Cancel',
      color: 'secondary',
      sideType: MODAL_BUTTON_SIDE_TYPE.LEFT,
      onClick: () => {
        closeModal(id, onCancel);
      },
    },
  ];

  // Update the internal stat of one of the visibility configurations
  const updateInternalField = (fields, updateFunction, key, { visible }) => {
    updateFunction(fields.map((field) => {
      if (key === field.key) {
        return {
          ...field,
          visible,
        };
      }
      return field;
    }));
    setIsDirty(true);
  };

  const likelihoodOptions = LIKELIHOOD_VALUES.map((lv) => ({ value: lv, label: lv }));

  return (
    <Modal
      {...props}
      buttons={modalButtons}
      title="Sales Forecast Dashboard Options"
      allowDismiss
      isDirty={isDirty}
      size="lg"
      className="sales-forecast-dashboard settings"
    >
      <div className="separator">
        Select the fields you want to see on the dashboard.
        <hr />
      </div>
      <div className="fields">
        <div className="summary">
          <strong>Summary Fields:</strong>
          {internalSummaryFields.filter((field) => !field.locked).map((field) => (
            <FormGroup check key={field.key}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={field.visible}
                  onChange={() => updateInternalField(internalSummaryFields, setInternalSummaryFields, field.key, { visible: !field.visible })}
                />
                <span>{`${field.label}`}</span>
              </Label>
            </FormGroup>
          ))}
        </div>
        <div className="monthly">
          <strong>Monthly Fields:</strong>
          {internalMonthlyFields.filter((field) => !field.locked).map((field) => (
            <FormGroup check key={field.key}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={field.visible}
                  onChange={() => updateInternalField(internalMonthlyFields, setInternalMonthlyFields, field.key, { visible: !field.visible })}
                />
                <span>{`${field.label}`}</span>
              </Label>
            </FormGroup>
          ))}
        </div>
      </div>
      <div className="fields">
        {/*
        @deprecated additional statuses are no longer required on this view.
        @todo in due course this can be removed, as well as the references in
              the dashboard itself which provide state for this settings
        <div className="statuses">
          <strong>Display Statuses:</strong>
          {internalDisplayStatuses.map((status) => (
            <FormGroup check key={status.id}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={status.visible}
                  onChange={() => updateInternalField(internalDisplayStatuses, setInternalDisplayStatuses, status.key, { visible: !status.visible })}
                />
                <span>{`${status.name}`}</span>
              </Label>
            </FormGroup>
          ))}
        </div> */}
        <div className="likelihood">
          <strong>Likelihood Range:</strong>
          <FormGroup>
            <Label>
              <span>From: &nbsp;</span>
              <select
                className="form-control"
                onChange={(event) => {
                  setInternalLowLikelihoodFilter(parseInt(event.target.value, 10));
                  setIsDirty(true);
                }}
                value={internalLowLikelihoodFilter}
              >
                {likelihoodOptions.map((option) => (
                  <option value={option.value} key={option.value}>{option.label}</option>
                ))}
              </select>
            </Label>
            <Label>
              <span> &nbsp; to:  &nbsp; </span>
              <select
                className="form-control"
                onChange={(event) => {
                  setInternalHighLikelihoodFilter(parseInt(event.target.value, 10));
                  setIsDirty(true);
                }}
                value={internalHighLikelihoodFilter}
              >
                {likelihoodOptions.map((option) => (
                  <option value={option.value} key={option.value}>{option.label}</option>
                ))}
              </select>
            </Label>
          </FormGroup>
        </div>
      </div>
      <div className="separator">
        <strong>Options</strong>
        <hr />
      </div>
      <div className="options">

        {/* Hide Empty States */}
        <div>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={internalHideEmptyStates}
                onChange={() => {
                  setInternalHideEmptyStates(!internalHideEmptyStates);
                  setIsDirty(true);
                }}
              />
              <span>Hide Empty States</span>
            </Label>
          </FormGroup>

        </div>

        {/* Zeroes vs Dashes */}
        <div>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={internalHideZeroValues}
                onChange={() => {
                  setInternalHideZeroValues(!internalHideZeroValues);
                  setIsDirty(true);
                }}
              />
              <span>Hide Zero Values</span>
            </Label>
          </FormGroup>
        </div>

        {/* Show Project Name */}
        <div>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={internalShowProjectName}
                onChange={() => {
                  setInternalShowProjectName(!internalShowProjectName);
                  setIsDirty(true);
                }}
              />
              <span>Show Project Name</span>
            </Label>
          </FormGroup>

        </div>
      </div>
    </Modal>
  );
};

SalesForecastDashboardSettingsModal.propTypes = {
  id: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  summaryFields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  })).isRequired,
  monthlyFields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  })).isRequired,
  displayStatuses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  })).isRequired,
  showProjectNames: PropTypes.bool.isRequired,
  hideEmptyStates: PropTypes.bool.isRequired,
  hideZeroValues: PropTypes.bool.isRequired,
  lowLikelihoodFilter: PropTypes.number.isRequired,
  highLikelihoodFilter: PropTypes.number.isRequired,
  onModalComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

SalesForecastDashboardSettingsModal.defaultProps = {
  onCancel: noop,
};
