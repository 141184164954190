import moment from 'moment';
import React from 'react';
import { Table } from 'reactstrap';
import { ILeaveBlockRecord } from '../../../types/leave/leave-block.record.interface';

interface ILeaveCalendarEventModalBlocks {
  blockList: ILeaveBlockRecord[],
  userCanSeeDetail: boolean,
}

const LeaveCalendarEventModalBlocks: React.FC<ILeaveCalendarEventModalBlocks> = ({
  blockList,
  userCanSeeDetail = false,
}) => (
  <>
    {(blockList.length > 1 || userCanSeeDetail) && (
      <div>
        <p>&nbsp;</p>
        {userCanSeeDetail && blockList.length > 1 ? (
          <>
            <h5 className="text-strong text-danger">Warning: </h5>
            <p className="text-strong text-danger">
              This application has additional leave days associated with it.  Changes made here will impact those days also:
            </p>
          </>
        ) : (
          <h5 className="text-bold">Leave Application Dates: </h5>
        )}
        <Table>
          <thead>
            <tr>
              <th>ID&nbsp;</th>
              {/* Paid / Unpaid status is confidential  */}
              {userCanSeeDetail && (
                <>
                  <th>Type</th>
                  <th className="text-center">Paid/Unpaid</th>
                </>
              )}
              <th>From</th>
              <th>To</th>
            </tr>
            {blockList.map((block: ILeaveBlockRecord) => (
              <tr key={block.id}>
                <td>{block.id}</td>
                {userCanSeeDetail && (
                  <>
                    <td>{block.leave_block_type?.name ?? 'Not Set'}</td>
                    <td className="text-center">{`${block.paid_days}/${block.unpaid_days}`}</td>
                  </>
                )}
                <td>{moment(block.start_date).format('DD/MM/YYYY')}</td>
                <td>{moment(block.end_date).format('DD/MM/YYYY')}</td>
              </tr>
            ))}
          </thead>
        </Table>
        {/* <pre>{JSON.stringify(blockList, null, 2)}</pre> */}
      </div>
    )}
  </>
);

export default LeaveCalendarEventModalBlocks;
