import React from 'react';
import PropTypes from 'prop-types';
import { formatValueString } from '../../render-functions';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';

const DtoolsOrderTable = ({ data, loadPurchaseOrderSummaryView }) => (
  <div className="table-container" style={{ overflowY: 'auto' }}>
    <table className="table table-striped table-hover color-table muted-table">
      <thead>
        <tr>
          <th>Vendor</th>
          <th>Order Number</th>
          <th>Ordered Date</th>
          <th className="text-right">Total Cost</th>
        </tr>
      </thead>
      <tbody>
        {data.length < 1 && (
          <tr>
            <td colSpan="4">No orders listed</td>
          </tr>
        )}
        {data.map((item) => {
          if (item.TotalCost === 0 && !item.Vendor) return null;
          return (
            <tr
              key={`order-${item.Vendor}-${item.OrderNumber}-${item.OrderedDate}-${item.TotalCost}`}
              onClick={() => loadPurchaseOrderSummaryView(item)}
              style={{ cursor: 'pointer' }}
            >
              <td>{item.Vendor}</td>
              <td className="text-info">{item.OrderNumber}</td>
              <td>{formatValueString(item.OrderedDate, COLUMN_FORMAT.DTOOLS_DATE)}</td>
              <td className="text-right">{formatValueString(item.TotalCost, COLUMN_FORMAT.CURRENCY)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
);

DtoolsOrderTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadPurchaseOrderSummaryView: PropTypes.func.isRequired,
};

export default DtoolsOrderTable;
