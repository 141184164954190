import { COLUMN_FORMAT } from '../constants/column-format.const';
import { ProjectsWidget } from '../components/widgets/projects.widget';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

export const ProjectsWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  component: ProjectsWidget,
  itemCaption: 'Project',
  isReadOnly: true,
  showAddBtn: false,
  fields: [
    {
      name: 'id',
      title: 'Identity',
      description: 'Project Unique Identifier',
      format: COLUMN_FORMAT.P_NUMBER,
      visible: true,
      // showInForm: false,
      order: 0,
    },
    {
      name: 'name',
      title: 'Project Name',
      visible: true,
      format: COLUMN_FORMAT.TEXT,
    },
    {
      name: 'status',
      title: 'Status',
      format: COLUMN_FORMAT.OBJECT,
      description: 'Current Status of Project',
      object: {
        sourceField: 'status',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      visible: true,
    },
    {
      name: 'phase',
      title: 'Phase',
      format: COLUMN_FORMAT.OBJECT,
      description: 'Current Phase of Project',
      object: {
        sourceField: 'phase',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      visible: true,
    },
    {
      name: 'user_audit_updated_at',
      title: 'Last Updated',
      sortColumn: 'updated_at',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'updated_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      visible: true,
    },
    {
      name: 'value',
      title: 'Value',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
      description: 'Project Total Value',
      visible: true,
      showTotals: true,
    },
  ],
};
