import React from 'react';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { IDataTableRecord } from '../../../types/portal-data-table/portal-data-table-record.interface';
import { InMemoryTable } from '../../portal-data-table/in-memory-table';
import { getVendorManufacturerModelLink } from './eta-reporting-utilities';

interface IOverdueItemsTableProps {
  recordData: IDataTableRecord[],
  projectId?: number,
  projectManagerId?: string | number | null,
}

export const OverdueItemsTable: React.FC<IOverdueItemsTableProps> = ({ recordData, projectId, projectManagerId }) => (
  <InMemoryTable
    title="Overdue Items Details"
    columns={[{
      title: 'Vendor',
      name: 'vendor',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: (row: IDataTableRecord) => getVendorManufacturerModelLink(true, null, null, row.vendor, projectId, null, projectManagerId),
      filterType: 'pickListFromRecordset',
    }, {
      title: 'Manufacturer',
      name: 'manufacturer',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: (row: IDataTableRecord) => getVendorManufacturerModelLink(true, null, row.manufacturer, row.vendor, projectId, null, projectManagerId),
      filterType: 'pickListFromRecordset',
    }, {
      title: 'Model',
      name: 'model',
      format: COLUMN_FORMAT.NAME_LINK,
      linkRoute: (row: IDataTableRecord) => getVendorManufacturerModelLink(true, row.model, row.manufacturer, row.vendor, projectId, null, projectManagerId),
    }, {
      title: 'Qty',
      name: 'items',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      filterType: 'number',
    }, {
      title: 'Max Required Days',
      name: 'soonest_required_item',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      filterType: 'number',
    }, {
      title: 'Max Due Days',
      name: 'longest_expected_item',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      filterType: 'number',
    }, {
      title: 'Short Description',
      name: 'short_description',
      format: COLUMN_FORMAT.LONG_TEXT,
    }]}
    paginate
    data={recordData}
    color="danger"
    activeColor="warning"
  />
);
