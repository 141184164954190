import React from 'react';
import moment from 'moment';


import { WidgetProps } from '../../../types/widget.props';
import AvailableLeave from '../../available-leave/available-leave';
import { IUserRecord } from '../../../types/user/user.record.interface';
import { MyobUserLink } from './myob-user-link';

export type UserMyobInfoProps = Omit<WidgetProps, 'rowData'> & {
  rowData: IUserRecord,
}

export const UserMyobInfo: React.FC<UserMyobInfoProps> = ({ rowData }) => {
  const atDate = moment().toDate();
  const { id: userId } = rowData;
  return (
    <div>
      <MyobUserLink user={rowData} />
      <AvailableLeave userId={userId} atDate={atDate} />
    </div>
  );
};
