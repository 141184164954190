import React, { useEffect, useCallback, useContext, useState, useRef } from 'react';
import { formatValueString } from '../../render-functions';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { IProjectRecord } from '../../../types/project/project.record.interface';
import { IProjectForecastRecord } from '../../../types/project/project-forecast.record.interface';
import { APIContext } from '../../providers/api-provider';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import { ProjectNumber } from '../../data-format/project-number';

type ProjectForecastsProps = {
  project: IProjectRecord,
}

export const ProjectForecasts: React.FC<ProjectForecastsProps> = ({ project }) => {
  const [forecastData, setForecastData] = useState<IProjectForecastRecord[]>([]);

  const { apiFetch } = useContext(APIContext);

  const loadForecastsAbortController = useRef<AbortController | null>(null);

  const loadForecasts = useCallback(async () => {
    if (loadForecastsAbortController.current) {
      loadForecastsAbortController.current.abort();
    }
    loadForecastsAbortController.current = apiAborter();

    const response = await apiFetch(
      `/project/${project.id}/forecast?with[]=type&with[]=createdBy:id,name&with[]=updatedBy:id,name`,
      {
        name: 'ProjectForecastsWidget::loadForecasts',
        signal: loadForecastsAbortController.current.signal,
      },
    );

    if (response.success) {
      loadForecastsAbortController.current = null;
      setForecastData(response.body.data);
    } else if (!response.aborted) {
      loadForecastsAbortController.current = null;
      setForecastData([]);
    }
  }, [project, apiFetch]);


  /**
   * On component mount
   */
  useEffect(() => {
    // Load the forecasts for the current project
    loadForecasts();

    return () => {
      // Kill any outstanding load when the component is un-mounted
      if (loadForecastsAbortController.current) {
        loadForecastsAbortController.current.abort();
      }
    };
  }, [loadForecasts]);


  /**
   * Render
   */
  return (
    <div className="bg-white">
      <div style={{ padding: '10px' }}>
        Showing full forecasting for
        {' '}
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <ProjectNumber pData={project as any} linkToProject />
      </div>
      <table width="100%" className="table" style={{ marginBottom: '0' }}>
        <thead>
          <tr>
            <th>Type</th>
            <th className="text-right">Value</th>
            <th>Expected By</th>
            <th>Added</th>
            <th>Added By</th>
            <th>Last Updated</th>
            <th>Updated By</th>
          </tr>
        </thead>
        <tbody>
          {forecastData.length === 0 && (
            <tr>
              <td colSpan={6}>Loading Data...</td>
            </tr>
          )}
          {forecastData.map((projectForecast) => (
            <React.Fragment key={projectForecast.id}>
              <tr>
                <td>{projectForecast.type?.name}</td>
                <td className="text-right">
                  {formatValueString(projectForecast.amount, COLUMN_FORMAT.CURRENCY)}
                </td>
                <td>{formatValueString(projectForecast.expected_by, COLUMN_FORMAT.MONTH_OF_YEAR)}</td>
                <td>{formatValueString(projectForecast.user_audit?.created_at, COLUMN_FORMAT.TIMESTAMP)}</td>
                <td>{projectForecast.created_by?.name ?? 'Forecast'}</td>
                <td>{formatValueString(projectForecast.user_audit.updated_at, COLUMN_FORMAT.TIMESTAMP)}</td>
                <td>{projectForecast.updated_by?.name ?? 'Unknown'}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};
