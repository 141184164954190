import React from 'react';
import PropTypes from 'prop-types';
import { formatValueString } from '../../render-functions';
import ProjectDrillDownTable from './ProjectDrillDownTable';

class ForecastMonths extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDrillDownMonths: {},
    };
  }

  toggleProjectDrilldown = (monthRecord, column) => {
    const monthKey = `${monthRecord.year}-${monthRecord.month}`;
    const { showDrillDownMonths } = this.state;
    const currentSetting = showDrillDownMonths[monthKey];
    let newSetting = column;
    if (currentSetting instanceof Object && currentSetting.key === column.key) {
      newSetting = false;
    }
    this.setState({
      showDrillDownMonths: {
        ...showDrillDownMonths,
        [monthKey]: newSetting,
      },
    });
  };

  render = () => {
    const { monthData, columns, colSpan } = this.props;
    const { showDrillDownMonths } = this.state;
    return (
      <>
        {monthData.map((monthRecord) => {
          const monthKey = `${monthRecord.year}-${monthRecord.month}`;
          return (
            <React.Fragment key={`${monthRecord.year}-${monthRecord.month}`}>
              <tr>
                <td
                  style={{ background: '#f4f9fb', color: '#444' }}
                  // onClick={() => {
                  //   this.toggleProjectDrilldown(monthRecord);
                  // }}
                >
                  &nbsp; &nbsp;
                  {' '}
                  {monthRecord.month_name}
                </td>
                {columns.map((column) => {
                  if (column.visible !== true) return null;
                  if (column.key === 'low_high') {
                    return (
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
                      <td
                        className="text-right"
                        key={`year-${monthRecord.year}-month-${monthRecord.month_name}-column-${
                          column.title
                        }`}
                        style={{ background: '#f4f9fb', color: '#444' }}
                        onClick={() => {
                          this.toggleProjectDrilldown(monthRecord, column);
                        }}
                      >
                        {monthRecord.low_proposal_count}
                        {' '}
                        |
                        {monthRecord.high_proposal_count}
                      </td>
                    );
                  }
                  return (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
                    <td
                      className="text-right"
                      key={`year-${monthRecord.year}-month-${monthRecord.month_name}-column-${
                        column.title
                      }`}
                      style={{ background: '#f4f9fb', color: '#444' }}
                      onClick={() => {
                        this.toggleProjectDrilldown(monthRecord, column);
                      }}
                    >
                      {formatValueString(monthRecord[column.key], column.format)}
                    </td>
                  );
                })}
              </tr>
              {showDrillDownMonths[monthKey] && (
                <tr>
                  <td colSpan={colSpan} style={{ padding: '0' }}>
                    <ProjectDrillDownTable
                      data={monthRecord}
                      column={showDrillDownMonths[monthKey]}
                      colSpan={colSpan}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  };
}

ForecastMonths.propTypes = {
  monthData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  colSpan: PropTypes.number.isRequired,
};

export default ForecastMonths;
