import React from 'react';

import { IContactRecord } from '../../types/contact/contact.record.interface';

import { useIsDirty } from '../../react-hooks/use-is-dirty.hook';

import { Modal } from './Modal';
import { NewContactForm } from '../poly-forms/new-contact-form';
import { NewRecordModalProps } from '../../types/modal/new-record-modal.props';

export const NewContactModal: React.FC<NewRecordModalProps<IContactRecord>> = (props) => {
  const {
    id, closeModal, onModalComplete, initialData,
  } = props;

  const [isDirty, setDirty] = useIsDirty();

  return (
    <Modal
      {...props}
      title="Create a new Contact"
      allowDismiss
      isDirty={isDirty}
      size="lg"
    >
      <NewContactForm
        onFormComplete={(success, recordId, newRecord) => {
          closeModal(id);
          onModalComplete((success && newRecord) ? { success, newRecord } : { success: false, newRecord: null });
        }}
        onFormChange={setDirty}
        initialData={initialData}
      />
    </Modal>
  );
};
