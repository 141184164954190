import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SalesForecastTableCellGroup from './sales-forecast-table-cell-group';
import SalesForecastTableRow from './sales-forecast-table-row';
import { DATA_TYPE, buildDomDataTagId } from './sales-forecast-helpers';
import Icon from '../../layout-helpers/icon';
import SalesForecastTableSalespersonSummaryDataRows from './sales-forecast-table-salesperson-summary-data-rows';

const SalesForecastTableStateSummaryDataRows = (props) => {
  const {
    summaryFields,
    visibleSummaryFieldCount,
    stateIds,
    pmAndStateIds,
    hideZeroValues,
    showProjectNames,

    onClick,
    onBeginHover,
    onEndHover,

    geographicalStateData,
    salespersonData,
    projectData,
  } = props;

  return (
    <>
      {/* Error loading State Summary Data */}
      {geographicalStateData.error && !geographicalStateData.isLoading && (
        <SalesForecastTableRow
          className="geographical-state summary data no-data error"
        >
          <SalesForecastTableCellGroup span={visibleSummaryFieldCount}>
            <Icon i="exclamation" />
            <span>{`Error: ${geographicalStateData.error}`}</span>
          </SalesForecastTableCellGroup>
        </SalesForecastTableRow>
      )}

      {/* No State Summary Data, No Error, Not loading */}
      {!geographicalStateData.summaryData.data.length && !geographicalStateData.isLoading && !geographicalStateData.error && (
        <SalesForecastTableRow
          className="geographical-state summary data no-data"
        >
          <SalesForecastTableCellGroup span={visibleSummaryFieldCount}>
            <span>Nothing to display</span>
          </SalesForecastTableCellGroup>
        </SalesForecastTableRow>
      )}

      {/* No State Summary Data and Summary Data is loading */}
      {!geographicalStateData.summaryData.data.length && geographicalStateData.isLoading && (
        <SalesForecastTableRow
          className="geographical-state summary data no-data loading"
        >
          <SalesForecastTableCellGroup span={visibleSummaryFieldCount}>
            <Icon i="rolling" />
            <span>Loading...</span>
          </SalesForecastTableCellGroup>
        </SalesForecastTableRow>
      )}

      {/* State Summary Data Exists and no error */}
      {!!geographicalStateData.summaryData.data.length && !geographicalStateData.error && (
        <>
          {/* State Summary Data Rows */}
          {geographicalStateData.summaryData.data.map((geographicalState) => (
            <React.Fragment
              key={`state_${geographicalState.state_id}`}
            >
              <SalesForecastTableRow
                className={classNames('geographical-state', 'summary', 'data', {
                  loading: geographicalStateData.isLoading,
                  expanded: stateIds.includes(geographicalState.state_id),
                })}
                dataType={DATA_TYPE.GEOGRAPHICAL_STATE}
                id={buildDomDataTagId(DATA_TYPE.GEOGRAPHICAL_STATE, geographicalState.state_id)}
                trackHover
                onBeginHover={onBeginHover}
                onEndHover={onEndHover}
                onClick={onClick}
              >
                <SalesForecastTableCellGroup
                  rowKey={`state_${geographicalState.state_id}`}
                  fields={summaryFields}
                  hideZeroValues={hideZeroValues}
                  data={geographicalState}
                  onRenderFieldData={(field, data, defaultFormattedValue) => (
                    field.key === 'name' ? data.state_name : defaultFormattedValue
                  )}
                />

              </SalesForecastTableRow>

              {/* Salesperson Summary Data */}
              <SalesForecastTableSalespersonSummaryDataRows
                summaryFields={summaryFields}
                visibleSummaryFieldCount={visibleSummaryFieldCount}
                pmAndStateIds={pmAndStateIds}
                hideZeroValues={hideZeroValues}
                showProjectNames={showProjectNames}

                onBeginHover={onBeginHover}
                onEndHover={onEndHover}
                onClick={onClick}

                salespersonData={salespersonData ? salespersonData.find((salesperson) => salesperson.stateId === geographicalState.state_id) : null}
                projectData={projectData}
              />

            </React.Fragment>
          ))}

          {/* State summary totals row */}
          {!!geographicalStateData.summaryData.data.length && (
            <SalesForecastTableRow
              key="states_totals"
              className={classNames('geographical-state', 'summary', 'total', { loading: geographicalStateData.isLoading })}
            >
              <SalesForecastTableCellGroup
                rowKey="states_totals"
                fields={summaryFields}
                hideZeroValues={hideZeroValues}
                data={{
                  state_name: '',
                  state_id: -1,
                  state_acronym: '',
                  ...geographicalStateData.summaryData.totals,
                }}
              />
            </SalesForecastTableRow>
          )}
        </>
      )}
    </>
  );
};

SalesForecastTableStateSummaryDataRows.propTypes = {
  summaryFields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
  })).isRequired,
  geographicalStateData: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    summaryData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
    monthlyData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
      totals: PropTypes.shape({}),
    }).isRequired,
  }).isRequired,
  salespersonData: PropTypes.arrayOf(
    PropTypes.shape({
      stateId: PropTypes.number.isRequired,
      isLoading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      summaryData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
      monthlyData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
    }),
  ).isRequired,
  projectData: PropTypes.arrayOf(
    PropTypes.shape({
      stateId: PropTypes.number.isRequired,
      salespersonId: PropTypes.number.isRequired,
      isLoading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      summaryData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
      monthlyData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        totals: PropTypes.shape({}),
      }).isRequired,
    }),
  ).isRequired,
  visibleSummaryFieldCount: PropTypes.number.isRequired,
  stateIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  pmAndStateIds: PropTypes.arrayOf(PropTypes.shape({
    pmId: PropTypes.number.isRequired,
    stateId: PropTypes.number.isRequired,
  })).isRequired,
  onClick: PropTypes.func.isRequired,
  onBeginHover: PropTypes.func.isRequired,
  onEndHover: PropTypes.func.isRequired,
  hideZeroValues: PropTypes.bool.isRequired,
  showProjectNames: PropTypes.bool.isRequired,
};

export default SalesForecastTableStateSummaryDataRows;
