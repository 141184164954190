import { APP_ENV } from '../utils/constants';

/**
 * @class
 * @name ApiQueryAssertions
 *
 * @description
 * Contains assertions for the shape of BaseApiQueryDefinition properties
 *
 * Primarily to expedite development
 *
 * TODO: change error messages to have "failed to assert..."
 */
class ApiQueryAssertions {
  /**
   * @description
   * Assert the shape of a filter is correct
   *
   * @param {string} ownerName
   * @param {any | { field: string, operation: string, values: ( string | number | boolean )[] }} filter
   * @param {string} filterName
   * @returns {filter is { field: string, operation: string, values: ( string | number | boolean )[] }}
   *
   * @throws {TypeError}
   */
  static assertFilterShape = (ownerName, filter, filterName) => {
    // do nothing unless in dev
    if (!(APP_ENV.toLowerCase() === 'dev')) return true;

    // if filterName is provided, nicely insert the filter name into the error message
    const insertFilterName = filterName ? ` "${filterName}"` : '';

    if (!(filter instanceof Object)) throw new TypeError(`"${ownerName}"'s Filter${insertFilterName} must be an object`);

    // properties exist and are valid
    if (!('field' in filter && typeof filter.field === 'string')) {
      throw new TypeError(`"${ownerName}"'s Filter${insertFilterName} must have a string "field". Provided: "${filter.field}"`);
    }

    if (!('operation' in filter && typeof filter.operation === 'string')) {
      throw new TypeError(`"${ownerName}"'s Filter${insertFilterName} must have a string "operation". Provided: "${filter.operation}"`);
    }

    if (!('values' in filter && filter.values instanceof Array)) {
      throw new TypeError(`"${ownerName}"'s Filter${insertFilterName} must have an array "values". Provided: "${String(filter.values)}"`);
    }

    filter.values.forEach((filterValue) => {
      if (!(typeof filterValue === 'string' || typeof filterValue === 'number' || typeof filterValue === 'boolean')) {
        throw new TypeError(`"${ownerName}"'s Filter${insertFilterName}'s values must be an array of "string", number", and/or "boolean". Provided: "${
          filter.values.map((value) => String(value)).join(', ')
        }"`);
      }
    });

    return true;
  }


  /**
   * @description
   * Assert the shape of an array of sorts
   *
   * @param {string} ownerName
   * @param {{ field: string, direction: 'asc' | 'desc', sortIndex: number }[]} arrayOfSorts
   * @returns {arrayOfSorts is { field: string, direction: 'asc' | 'desc', sortIndex: number }}
   *
   * @throws {TypeError}
   */
  static assertArrayOfSortsShape = (ownerName, arrayOfSorts) => {
    // do nothing unless in dev
    if (!(APP_ENV.toLowerCase() === 'dev')) return true;

    if (!(arrayOfSorts instanceof Array)) {
      throw new TypeError('Array Of Sorts must be an Array');
    }

    // properties exist and are valid
    arrayOfSorts.forEach((sort) => {
      if (!('field' in sort && typeof sort.field === 'string')) {
        throw new TypeError(`"${ownerName}"' Sort must have a string "field". Provided: "${sort.field}"`);
      }

      if (!('direction' in sort && (sort.direction === 'asc' || sort.direction === 'desc'))) {
        throw new TypeError(`"${ownerName}"'s Sort must have a string "direction" that is "asc" or "desc". Provided: "${sort.direction}"`);
      }

      if (!('sortIndex' in sort && typeof sort.sortIndex === 'number')) {
        throw new TypeError(`"${ownerName}"'s Sort must have an array "values". Provided: "${sort.values}"`);
      }
    });

    // no duplicate sort indexes
    if (arrayOfSorts.some((sortA) => arrayOfSorts.some((sortB) => ((sortA.field !== sortB.field) && (sortA.sortIndex === sortB.sortIndex))))) {
      throw new TypeError(`"${ownerName}"'s SortArrays cannot contains sorts with identical sortIndexes`);
    }

    return true;
  }


  /**
   * @description
   * Assert the view key exists in the views array
   *
   * @param {string} ownerName
   * @param {{
    *  key: string,
    *  title: string,
    *  flagTitles: { flags: string[], title: string }[]
    *  filters: { field: string, operation: string, values: ( string | number | boolean )[] }[],
    *  flags: string[]
    * }[]} views
    * @param {string} viewKey
    * @returns {boolean}
    *
    * @throws {TypeError}
    */
  static assertViewKeyExists = (ownerName, views, viewKey) => {
    // do nothing unless in dev
    if (!(APP_ENV.toLowerCase() === 'dev')) return true;

    if (!views.some((view) => view.key === viewKey)) {
      throw new TypeError(`"${ownerName}"'s View Key: "${viewKey}" does not exist in set of view keys: "${views.map((view) => view.key).join(', ')}"`);
    }

    return true;
  }


  /**
   * @description
   * Assert that a flag exists in an array of available flags
   *
   * @param {string} ownerName
   * @param {string[]} allowedFlags
   * @param {string} flag
   * @returns {flag is string}
   *
   * @throws {TypeError}
   */
  static assertFlagIsAllowed = (ownerName, allowedFlags, flag) => {
    // do nothing unless in dev
    if (!(APP_ENV.toLowerCase() === 'dev')) return true;

    if (!allowedFlags.includes(flag)) {
      throw new TypeError(`"${ownerName}"'s Flag: "${flag}" does not exist in array of allowed flags: "${allowedFlags.join(', ')}"`);
    }

    return true;
  }
}

export default ApiQueryAssertions;
