/* eslint-disable key-spacing, no-multi-spaces */

export const COLUMN_FORMAT = {
  ABBREVIATED_EXT_LINK: 'abbreviatedExternalLink', // An external link which picks up common external sites and renders them with an icon
  ACTIVE_INACTIVE_BADGE: 'active-inactive-badge', // a Status Badge used in situations where the binary 0/1 needs to be displayed as inactive / active
  BATCH_BUTTON: 'batchButton',                    // Renders out a button in the column for batching an item
  BIRTHDAY: 'birthday',                           // Similar to date only it will prevent 0000 from rendering when the year has not been specified
  BUTTON_LINK: 'buttonLink',                      // display a button that links to another internal or external entity
  CHECKBOX_MEDIUM: 'checkbox-medium',             // As for checkbox, but wider
  CHECKBOX: 'checkbox',                           // Renders a good old fashioned checkbox for true/false 0/1 yes/no etc...
  CURRENCY: 'currency',                           // "$123,456.78"
  DATE: 'date',                                   // "13/05/18"
  DECIMAL: 'decimal',                             // TODO: Not sure whether this one is still required or how it differs from other options
  DTOOLS_CACHE_STATUS: 'dtools-cache-status',     // status indicator for the state of a D-Tools Chache Record
  DTOOLS_DATE: 'dtools-date',                     // TODO: Not sure whether this one is still required or how it differs from other options
  DTOOLS_TIME: 'dtools-time',                     // TODO: Not sure whether this one is still required or how it differs from other options
  DTOOLS_UTC_TIME: 'dtools-utc-time',             // Normalise the DTools Time back to UTC time
  DURATION_BUSINESS_TIME: 'duration-biz-time',    // a time duration in hours rendered as human readable business time
  DURATION_HOURS: 'duration-hours',               // a time duration in hours rendered as human readable time
  EMAIL: 'email',                                 // e.g. "bob@somedomain.com"
  FILE: 'file',                                   // Renders a file as a string with an icon
  HTML_NOTIN_TABLE: 'htmlNotInTable',             // Strip HTML from content when rendered in table (i.e. "<div>bob</div>" renders as "bob" in a table)
  ICON_NAME: 'icon-name',                         // Renders an <Icon> And the name of the icon where i={} is the value
  ID_LINK_SHORT: 'shortIdLink',                   // A Hyperlink to an ID "123"
  ID_LINK: 'idLink',                              // A Hyperlink to an ID "123"
  ID_NAME_OPTIONS: 'idNameOptions',               // display an array where id corresponds to the resources primary key value, and name is the corresponding value to render
  LEAVE_DAYS: 'leaveDays',                        // e.g. "5 paid days"
  LIKELIHOOD: 'likelihood',                       // an indicator that represents the likelihood column visually "[######----]"
  COMPANY_LOCATION: 'company-location',           // A company location path with an icon representing the location type
  COMPANY_SPACE: 'company-space',                 // A company space path with an icon representing the space type
  COMPANY_SPACE_TYPE: 'company-space-type',       // A company space type with an icon representing the space type
  LONG_DATE: 'longDate',                          // "13/05/2018"
  LONG_TEXT: 'longText',                          // "The cat sat on the mat for a long nap and th..."
  MEDIUM_TEXT: 'mediumText',                      // "The cat sat on the mat."
  MONTH_OF_YEAR: 'monthOfYear',                   // Renders a date in a longhand month of year format: "March 2021"
  NAME_LINK: 'nameLink',                          // A Hyperlink to an ID using the objects name or description "click here to view a resource"
  NAME_LINK_LONG: 'longNameLink',                 // A Hyperlink to an ID using the objects (long) name or description "click here to view a resource"
  NAME_LINK_SHORT: 'shortNameLink',               // A Hyperlink to an ID using the objects (short) name or description "click here to view a resource"
  NULLABLE_NUMBER: 'nullableNumber',              // Same as number but does not resolve to 0 and allows null.
  NUMBER_SHORT: 'shortNumber',                    // "123" or "123.45" etc.... Resolves to 0 if null.
  NUMBER: 'number',                               // "123" or "123.45" etc.... Resolves to 0 if null.
  OBJECT: 'object',                               // Not valid on its own but sometimes contains a format
  P_NUMBER: 'pnumber',                            // Project Number renders out an icon with the plain text project number i.e. "🚀P12345VPE"
  PHONE: 'phone',                                 // e.g. "03 1234 5678" or "0408 123 456"
  PROGRESS_PERCENT: 'progress-percent',           // Renders a progress bar with value text
  SHORT_TEXT: 'shortText',                        // "John Smith"
  STATUS_BADGE: 'status-badge',                   // a Status Badge representing the status / color of a record "[Completed]"
  STRIP_HTML: 'stripHtml',                        // "<div>bob</div>""  renders as  "bob"
  TEXT: 'text',                                   // "The cat sat on the mat"
  TIMESTAMP: 'timestamp',                         // "13/05/18 11:12am"
  URL: 'url',                                     // display a link icon alongside a link
  WHOLE_DOLLARS: 'whole-dollars',                 // TODO: Not sure whether this one is still required or how it differs from other options
  PROJECT_FORECAST_LINK: 'project_forecast_link', // A summary link to a single project forecast
  FRACTIONAL_PERCENTAGE: 'fractional-percentage', // A percentage which is quite small
  COMMISSION_BRACKET: 'commission-bracket',       // A commission bracket
} as const;
export type COLUMN_FORMAT = typeof COLUMN_FORMAT;
export type A_COLUMN_FORMAT = COLUMN_FORMAT[keyof COLUMN_FORMAT];

export const DEFAULT_COLUMN_FORMAT_WIDTHS: {[K in A_COLUMN_FORMAT]: undefined | string } = {
  [COLUMN_FORMAT.ABBREVIATED_EXT_LINK]:    '15em',
  [COLUMN_FORMAT.ACTIVE_INACTIVE_BADGE]:   '10em',
  [COLUMN_FORMAT.BATCH_BUTTON]:            '10em',
  [COLUMN_FORMAT.BIRTHDAY]:                '10em',
  [COLUMN_FORMAT.BUTTON_LINK]:             '15em',
  [COLUMN_FORMAT.CHECKBOX]:                '5em',
  [COLUMN_FORMAT.CHECKBOX_MEDIUM]:         '7.5em',
  [COLUMN_FORMAT.COMMISSION_BRACKET]:      '30em',
  [COLUMN_FORMAT.COMPANY_LOCATION]:        '30em',
  [COLUMN_FORMAT.COMPANY_SPACE]:           '30em',
  [COLUMN_FORMAT.COMPANY_SPACE_TYPE]:      '15em',
  [COLUMN_FORMAT.CURRENCY]:                '10em',
  [COLUMN_FORMAT.DATE]:                    '10em',
  [COLUMN_FORMAT.DECIMAL]:                 '10em',
  [COLUMN_FORMAT.DTOOLS_CACHE_STATUS]:     '2em',
  [COLUMN_FORMAT.DTOOLS_DATE]:             '10em',
  [COLUMN_FORMAT.DTOOLS_TIME]:             '15em',
  [COLUMN_FORMAT.DTOOLS_UTC_TIME]:         '15em',
  [COLUMN_FORMAT.DURATION_BUSINESS_TIME]:  '10em',
  [COLUMN_FORMAT.DURATION_HOURS]:          '10em',
  [COLUMN_FORMAT.EMAIL]:                   '15em',
  [COLUMN_FORMAT.FILE]:                    '20em',
  [COLUMN_FORMAT.HTML_NOTIN_TABLE]:        '30em',
  [COLUMN_FORMAT.ICON_NAME]:               '10em',
  [COLUMN_FORMAT.ID_LINK_SHORT]:           '5em',
  [COLUMN_FORMAT.ID_LINK]:                 '10em',
  [COLUMN_FORMAT.ID_NAME_OPTIONS]:         undefined,
  [COLUMN_FORMAT.LEAVE_DAYS]:              '10em',
  [COLUMN_FORMAT.LIKELIHOOD]:              '10em',
  [COLUMN_FORMAT.LONG_DATE]:               '10em',
  [COLUMN_FORMAT.LONG_TEXT]:               '30em',
  [COLUMN_FORMAT.MEDIUM_TEXT]:             '20em',
  [COLUMN_FORMAT.MONTH_OF_YEAR]:           '10em',
  [COLUMN_FORMAT.NAME_LINK]:               '15em',
  [COLUMN_FORMAT.NAME_LINK_LONG]:          '30em',
  [COLUMN_FORMAT.NAME_LINK_SHORT]:         '7.5em',
  [COLUMN_FORMAT.NULLABLE_NUMBER]:         '10em',
  [COLUMN_FORMAT.NUMBER_SHORT]:            '5em',
  [COLUMN_FORMAT.NUMBER]:                  '10em',
  [COLUMN_FORMAT.OBJECT]:                  undefined,
  [COLUMN_FORMAT.P_NUMBER]:                '10em',
  [COLUMN_FORMAT.PHONE]:                   '10em',
  [COLUMN_FORMAT.PROGRESS_PERCENT]:        '10em',
  [COLUMN_FORMAT.SHORT_TEXT]:              '10em',
  [COLUMN_FORMAT.STATUS_BADGE]:            '10em',
  [COLUMN_FORMAT.STRIP_HTML]:              '30em',
  [COLUMN_FORMAT.TEXT]:                    '15em',
  [COLUMN_FORMAT.TIMESTAMP]:               '15em',
  [COLUMN_FORMAT.URL]:                     '15em',
  [COLUMN_FORMAT.WHOLE_DOLLARS]:           '10em',
  [COLUMN_FORMAT.PROJECT_FORECAST_LINK]:   '12em',
  [COLUMN_FORMAT.FRACTIONAL_PERCENTAGE]:   '8em',
};

export const DEFAULT_COLUMN_ALIGNMENTS: {[K in A_COLUMN_FORMAT]: undefined | 'left' | 'center' | 'right' } = {
  [COLUMN_FORMAT.ABBREVIATED_EXT_LINK]:    'left',
  [COLUMN_FORMAT.ACTIVE_INACTIVE_BADGE]:   'left',
  [COLUMN_FORMAT.BATCH_BUTTON]:            'center',
  [COLUMN_FORMAT.BIRTHDAY]:                'left',
  [COLUMN_FORMAT.BUTTON_LINK]:             'left',
  [COLUMN_FORMAT.CHECKBOX]:                'center',
  [COLUMN_FORMAT.CHECKBOX_MEDIUM]:         'center',
  [COLUMN_FORMAT.COMMISSION_BRACKET]:      'left',
  [COLUMN_FORMAT.COMPANY_LOCATION]:        'left',
  [COLUMN_FORMAT.COMPANY_SPACE]:           'left',
  [COLUMN_FORMAT.COMPANY_SPACE_TYPE]:      'left',
  [COLUMN_FORMAT.CURRENCY]:                'right',
  [COLUMN_FORMAT.DATE]:                    'right',
  [COLUMN_FORMAT.DECIMAL]:                 'right',
  [COLUMN_FORMAT.DTOOLS_CACHE_STATUS]:     'center',
  [COLUMN_FORMAT.DTOOLS_DATE]:             'right',
  [COLUMN_FORMAT.DTOOLS_TIME]:             'right',
  [COLUMN_FORMAT.DTOOLS_UTC_TIME]:         'right',
  [COLUMN_FORMAT.DURATION_BUSINESS_TIME]:  'left',
  [COLUMN_FORMAT.DURATION_HOURS]:          'left',
  [COLUMN_FORMAT.EMAIL]:                   'left',
  [COLUMN_FORMAT.FILE]:                    'left',
  [COLUMN_FORMAT.HTML_NOTIN_TABLE]:        'left',
  [COLUMN_FORMAT.ICON_NAME]:               'left',
  [COLUMN_FORMAT.ID_LINK_SHORT]:           'left',
  [COLUMN_FORMAT.ID_LINK]:                 'left',
  [COLUMN_FORMAT.ID_NAME_OPTIONS]:         undefined,
  [COLUMN_FORMAT.LEAVE_DAYS]:              'left',
  [COLUMN_FORMAT.LIKELIHOOD]:              'left',
  [COLUMN_FORMAT.LONG_DATE]:               'right',
  [COLUMN_FORMAT.LONG_TEXT]:               'left',
  [COLUMN_FORMAT.MEDIUM_TEXT]:             'left',
  [COLUMN_FORMAT.MONTH_OF_YEAR]:           'right',
  [COLUMN_FORMAT.NAME_LINK]:               'left',
  [COLUMN_FORMAT.NAME_LINK_LONG]:          'left',
  [COLUMN_FORMAT.NAME_LINK_SHORT]:         'left',
  [COLUMN_FORMAT.NULLABLE_NUMBER]:         'right',
  [COLUMN_FORMAT.NUMBER]:                  'right',
  [COLUMN_FORMAT.NUMBER_SHORT]:            'right',
  [COLUMN_FORMAT.OBJECT]:                  undefined,
  [COLUMN_FORMAT.P_NUMBER]:                'left',
  [COLUMN_FORMAT.PHONE]:                   'left',
  [COLUMN_FORMAT.PROGRESS_PERCENT]:        'left',
  [COLUMN_FORMAT.SHORT_TEXT]:              'left',
  [COLUMN_FORMAT.STATUS_BADGE]:            'left',
  [COLUMN_FORMAT.STRIP_HTML]:              'left',
  [COLUMN_FORMAT.TEXT]:                    'left',
  [COLUMN_FORMAT.TIMESTAMP]:               'right',
  [COLUMN_FORMAT.URL]:                     'left',
  [COLUMN_FORMAT.WHOLE_DOLLARS]:           'right',
  [COLUMN_FORMAT.PROJECT_FORECAST_LINK]:   'left',
  [COLUMN_FORMAT.FRACTIONAL_PERCENTAGE]:   'right',
};

// TODO: strict type the keys
export const DEFAULT_COLUMN_CLASSES: Record<string, string> = {
  [COLUMN_FORMAT.NUMBER]:                  'mono-font',
  [COLUMN_FORMAT.NUMBER_SHORT]:            'mono-font',
  [COLUMN_FORMAT.CURRENCY]:                'mono-font',
  [COLUMN_FORMAT.PROGRESS_PERCENT]:        'progress-percent',
};
