import React from 'react';

import { IActionButton } from '../../../types/action-button.interface';
import { IProjectRecord } from '../../../types/project/project.record.interface';

import { MicrosoftServicesProjectAdminWidgetSection } from './microsoft-services-project-admin-widget-section';
import { ProjectActivationOverviewProjectAdminWidgetSection } from './project-activation-overview-project-widget-admin-section';
import { RegenerateProjectNumberProjectAdminWidgetSection } from './regenerate-project-number-project-admin-widget-section';
import { RollbackProjectAdminWidgetSection } from './rollback-project-admin-widget-section';


export type ProjectAdminWidgetProps = {
  rowData: IProjectRecord,
  refreshRecord: () => void,
  permittedActions: IActionButton[],
}

/**
 * This is the widget that holds the admin functionality for the project
 */
export const ProjectAdminWidget:React.FC<ProjectAdminWidgetProps> = (props) => {
  const {
    rowData,
    refreshRecord,
    permittedActions,
  } = props;

  /**
   * Render
   */
  return (
    <div className="widget project-admin-widget">

      <ProjectActivationOverviewProjectAdminWidgetSection
        rowData={rowData}
      />

      <RollbackProjectAdminWidgetSection
        rowData={rowData}
        refreshRecord={refreshRecord}
        permittedActions={permittedActions}
      />

      <MicrosoftServicesProjectAdminWidgetSection
        rowData={rowData}
        refreshRecord={refreshRecord}
      />

      <RegenerateProjectNumberProjectAdminWidgetSection
        rowData={rowData}
        refreshRecord={refreshRecord}
      />

    </div>
  );
};
