/**
 * Determines if an element is focusable or finds the next focusable input
 * that may exist as a child of the element.
 *
 * TODO: this may have to be extended to handle non input-based inputs (i.e. selects)
 *
 * @param element the target element to check
 * @return null | the HTMLElement that should be focused
 */
export const findFocusableInput = (element: HTMLElement): null | HTMLElement => {
  let focusableElement: null | HTMLElement = null;

  // Is the target element an appropriate focusable element?
  if (element && element.nodeName.toLowerCase() === 'input') {
    focusableElement = element;
  }

  // Look for a focusable child
  if (!focusableElement && element) {
    const focusableElements = element && element.querySelectorAll('input:not([type="hidden"])');
    if (focusableElements && focusableElements.length > 0) {
      focusableElement = focusableElements[0] as HTMLElement;
    }
  }

  return focusableElement;
};
