import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import FrontendError from '../error-pages/frontend-error';

import { LOCAL_STORAGE_KEYS } from '../../utils/local-storage-keys';
import { localStorageLoad, localStorageSave } from '../../utils/localStorage';

/**
 * @class
 * @name MainView
 *
 * @description
 * Error boundary for the application
 */
class MainView extends Component {
  /**
   * @constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }


  /**
   * @inheritDoc
   */
  componentDidMount() {
    // Stamp the date on page load
    const nowTime = moment().unix();
    localStorageSave(LOCAL_STORAGE_KEYS.PAGE_AGE, nowTime);

    // Add an event listener to the window to check the page age sometime in the future and refresh if it is (in effect) tomorrow
    window.addEventListener('focus', this.checkPageAge);
  }


  /**
   * @inheritdoc
   */
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.error(error, info);
  }


  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    window.removeEventListener('focus', this.checkPageAge);
  }


  /**
   * Check if the page is old and force reload it if required
   */
  checkPageAge = () => {
    const stampMoment = localStorageLoad(LOCAL_STORAGE_KEYS.PAGE_AGE);

    // If current date's DAY is different to the stamped moment
    if (stampMoment && !moment.unix(stampMoment).isSame(new Date(), 'day')) {
      window.location.reload(false);
    }
  };


  /**
   * @inheritdoc
   */
  render = () => {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div id="page_wrapper" className="page-wrapper">
          <FrontendError />
        </div>
      );
    }

    // Return <MainView />
    return <div id="page_wrapper" className="page-wrapper">{children}</div>;
  };
}

MainView.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default MainView;
