/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { ConfirmActionModalResult } from '../../../../types/modal/modal-result';
import { IActivateProjectModalWizardPage2Data, IActivateProjectModalWizardState } from '../activate-project-data.interface';

import { CompanyPicker } from '../../../form-input/company-picker';
import { ContactPicker } from '../../../form-input/contact-picker';
import { ProjectMaintenanceTypePicker } from '../../../form-input/project-maintenance-type-picker';
import { ModalWizardPage, ModalWizardPageProps } from '../../modal-wizard-page';
import { TextInput } from '../../../form-input/text-input';

export const ActivateProjectModalPage3 = (props: ModalWizardPageProps<
  IActivateProjectModalWizardPage2Data,
  IActivateProjectModalWizardState,
  ConfirmActionModalResult
>): React.ReactElement => {
  const { pageData, onPageDataFieldChanged } = props;

  return (
    <ModalWizardPage<IActivateProjectModalWizardPage2Data, IActivateProjectModalWizardState, ConfirmActionModalResult> {...props}>
      <div className="wizard-page-section">
        <div className="wizard-page-section-heading">
          <span>Step 3: Review Project invoicing information</span>
        </div>
        <div className="wizard-page-section-content">
          <p>
            Now that the project is active, we&apos;ll need to know some additional information,
            such as who we will be billing and where to send the invoice. We&apos;ve gathered some
            information from the Contacts Tab, and Companies Tab.
          </p>
          <p>
            Can you update any of these now?
          </p>
          <div className="wizard-form">

            {/* Billing Address */}
            <label htmlFor="billing_address">Billing Address</label>
            <TextInput
              id="billing_address"
              name="billing_address"
              value={pageData.billing_address}
              onChange={onPageDataFieldChanged}
            />

            {/* Billing Agent */}
            <label htmlFor="billing_agent">Billing Agent</label>
            <CompanyPicker
              id="billing_agent"
              name="billing_agent"
              value={pageData.billing_agent_id}
              formData={pageData}
              formSaveField="billing_agent_id"
              isCreatable={false}
              onChange={(field) => {
                if (onPageDataFieldChanged) {
                  onPageDataFieldChanged({
                    ...field,
                    objectFieldNewValue: field.objectFieldNewValue ? {
                      id: field.objectFieldNewValue.id,
                      name: field.objectFieldNewValue.name,
                    } : undefined,
                  });
                }
              }}
            />

            {/* Invoicing Contact */}
            <label htmlFor="invoicing_contact">Invoicing Contact</label>
            <ContactPicker
              id="invoicing_contact"
              name="invoicing_contact"
              value={pageData.invoicing_contact_id}
              formData={pageData}
              formSaveField="invoicing_contact_id"
              isCreatable={false}
              onChange={(field) => {
                if (onPageDataFieldChanged) {
                  onPageDataFieldChanged({
                    ...field,
                    objectFieldNewValue: field.objectFieldNewValue ? {
                      id: field.newValue,
                      first: field.objectFieldNewValue.first,
                      middle: field.objectFieldNewValue.middle,
                      last: field.objectFieldNewValue.last,
                    } : undefined,
                  });
                }
              }}
            />
            {/* {!!invoicing_contact_id && (
              <>
                <label />
                {/* Notify Invoicing Contact * /}
                <CheckBox
                  id="notify_invoicing_contact"
                  name="notify_invoicing_contact"
                  label="Notify Invoicing Contact"
                  inline
                  value={pageData.notify_invoicing_contact}
                  onChange={onPageDataFieldChanged}
                />
                <label />
                <div>
                  <p>
                    Should we notify the invoicing contact of project activation, as well as other key project milestones?
                    (You can always change this setting later on the project&apos;s Contacts tab)
                  </p>
                </div>
              </>
            )}
            */}
          </div>
        </div>
        <div className="wizard-page-section-content">
          <p>&nbsp;</p>
          <p>While you&apos;re here can you clarify the Project&apos;s Maintenance requirements?</p>
          <div className="wizard-form">
            <label htmlFor="project_maintenance_type">Maintenance Type</label>
            <ProjectMaintenanceTypePicker
              id="project_maintenance_type"
              name="project_maintenance_type"
              value={pageData.project_maintenance_type_id}
              formData={pageData}
              formSaveField="project_maintenance_type_id"
              isClearable={false}
              onChange={(field) => {
                if (onPageDataFieldChanged) {
                  onPageDataFieldChanged({
                    ...field,
                    objectFieldNewValue: field.objectFieldNewValue ? {
                      id: field.objectFieldNewValue.id,
                      name: field.objectFieldNewValue.name,
                    } : undefined,
                  });
                }
              }}
            />
          </div>
        </div>
      </div>
    </ModalWizardPage>
  );
};
