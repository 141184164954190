// @see dataset section at https://www.fusioncharts.com/dev/chart-attributes/mscombidy2d

const FUSION_CHART_DATASET_RENDER_TYPES = {
  COLUMN: 'COLUMN',
  AREA: 'AREA',
  LINE: 'LINE',
  SPLINEAREA: 'SPLINEAREA',
  SPLINE: 'SPLINE',
};

export default FUSION_CHART_DATASET_RENDER_TYPES;
