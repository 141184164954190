import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';
import { LEAVE_APPLICATION_STATUS_MAP } from '../constants/leave-application-status.const';
import { THEME_COLOR } from '../constants/theme-color.const';

/**
 * Table Definition for Manage Leave
 */
const manageLeaveTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Leave Application',
  baseRoute: '/leaveapplication',
  // Where status = Submitted
  baseQueryString: [
    'with[]=user:id,name',
    'with[]=approver:id,name',
    'with[]=status:id,name',
    'with_min[]=leaveBlocks.start_date',
    'with_max[]=leaveBlocks.end_date',
    'with_sum[]=leaveBlocks.paid_days',
    'with_sum[]=leaveBlocks.unpaid_days',
    'with_count[]=leaveBlocks',
    'with_count[]=notes',
    'with_count[]=files',
  ].join('&'),

  columnPresets: [
    {
      name: 'default',
      title: 'Default',
      fields: [
        'id',
        'user',
        'status',
        'description',
        'min_of_leaveBlocks_start_date',
        'max_of_leaveBlocks_end_date',
        'sum_of_leaveBlocks_paid_days',
        'sum_of_leaveBlocks_unpaid_days',
        'user_audit_submitted_at',
        'approver',
        'user_audit_approved_at',
      ],
    },
    {
      name: 'all',
      title: 'All',
      fields: [
        'id',
        'user',
        'status',
        'description',
        'min_of_leaveBlocks_start_date',
        'max_of_leaveBlocks_end_date',
        'sum_of_leaveBlocks_paid_days',
        'sum_of_leaveBlocks_unpaid_days',
        'user_audit_submitted_at',
        'approver',
        'user_audit_approved_at',
        'count_of_leaveBlocks',
        'count_of_files',
        'count_of_notes',
      ],
    },
  ],

  /**
   * Definition of Columns for this table
   */
  columns: [
    {
      name: 'id',
      title: 'ID',
      description: 'Leave Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      linkRoute: '/hrm/my-team/manage-leave',
      visible: true,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'user',
      title: 'Employee',
      description: 'Person requesting leave',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      formSaveField: 'user_id',
      visible: true,
      showInForm: true,
      order: 1,
      formOrder: 1,
    },
    {
      name: 'approver',
      title: 'Approver',
      description: 'Person responsible for approving',
      placeHolder: 'Select...',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'approver',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      formSaveField: 'approver_id',
      visible: true,
      showInForm: true,
      order: 9,
      formOrder: 2,
    },
    {
      name: 'status',
      title: 'Status',
      description: 'Current application status. Use action buttons to change status.',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'status',
        key: 'name',
        format: COLUMN_FORMAT.STATUS_BADGE,
        listSource: 'leaveStatus',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 2,
      formOrder: 3,
    },
    {
      name: 'description',
      title: 'Description',
      description: 'Reason of Leave',
      placeholder: 'Enter a brief description',
      format: COLUMN_FORMAT.TEXT,
      visible: true,
      showInForm: true,
      order: 3,
      formOrder: 4,
    },
    {
      title: 'Start Date',
      name: 'min_of_leaveBlocks_start_date',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: false,
      order: 4,
      formOrder: 5,
    },
    {
      title: 'End Date',
      name: 'max_of_leaveBlocks_end_date',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: false,
      order: 5,
      formOrder: 6,
    },
    {
      title: 'Submitted',
      name: 'user_audit_submitted_at',
      sortColumn: 'submitted_at',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'submitted_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: false,
      order: 8,
      formOrder: 7,
    },
    {
      title: 'Paid Days',
      name: 'sum_of_leaveBlocks_paid_days',
      format: COLUMN_FORMAT.NUMBER,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: false,
      order: 6,
      formOrder: 8,
    },
    {
      title: 'Unpaid Days',
      name: 'sum_of_leaveBlocks_unpaid_days',
      format: COLUMN_FORMAT.NUMBER,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: false,
      order: 7,
      formOrder: 9,
    },
    {
      title: 'Approved',
      name: 'user_audit_approved_at',
      sortColumn: 'approved_at',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'approved_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: false,
      order: 10,
      formOrder: 11,
    }, {
      name: 'count_of_leaveBlocks',
      title: 'Count of Leave Blocks',
      description: 'The count of leave blocks assigned to this application',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 11,
      showInForm: false,
      isReadOnly: true,
    }, {
      name: 'count_of_notes',
      title: 'Count of Notes',
      description: 'The count of the notes assigned to this application',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 12,
      showInForm: false,
      isReadOnly: true,
    }, {
      name: 'count_of_files',
      title: 'Count of Files',
      description: 'The count of the files assigned to this application',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 13,
      showInForm: false,
      isReadOnly: true,
    },
  ],
  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Leave Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'blocks',
      title: 'Dates',
      description: 'Start and End Dates',
      widgetDefinition: WIDGET_DEFINITION.LEAVE_BLOCKS,
      apiRoute: '/leaveblock',
      apiQuery: 'with[]=leaveBlockType:id,name&pagelength=100',
      badgeCountFields: [
        {
          fieldName: 'count_of_leaveBlocks',
          // Don't display "1" - it's useless. Only draw attention to the tab if there are two or more leave blocks
          onCalculateValue: (value) => (value < 2 ? 0 : value),
        },
      ],
    },
    {
      name: 'available',
      title: 'Leave Summary',
      description: "This person's available leave, as of the first date in this request",
      widgetDefinition: WIDGET_DEFINITION.AVAILABLE_LEAVE,
      apiRoute: '/leaveblock',
      apiQuery: 'with[]=leaveBlockType:id,name&pagelength=100',
    },
    {
      name: 'files',
      title: 'Files',
      description: 'Documents and Files',
      widgetDefinition: WIDGET_DEFINITION.LEAVE_APPLICATION_FILES,
      apiRoute: '/file',
      apiQuery: [
        'filter[0][field]=confirmed_at',
        `filter[0][operation]=${API_FILTER_OPERATION.NOT_NULL}`,
        'sort[0][field]=filename',
        'sort[0][direction]=asc',
        'pagelength=0',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'count_of_files',
          badgeColor: THEME_COLOR.WARNING,
        },
      ],
    },
    {
      name: 'notes',
      title: 'Notes',
      description: 'Leave Application Notes',
      widgetDefinition: WIDGET_DEFINITION.LEAVE_APPLICATION_NOTES,
      apiRoute: '/note',
      apiQuery: 'with[]=createdBy:id,name&pagelength=100',
      badgeCountFields: [
        {
          fieldName: 'count_of_notes',
          badgeColor: THEME_COLOR.WARNING,
        },
      ],
    },
    {
      ...eventsTabDefault,
      apiQuery: [
        'with[]=createdBy:id,name',
        'with[]=leaveApplicationNoteTracking:id,data,story',
        'with[]=leaveBlockTracking:id,data,story',
        'pagelength=20',
      ].join('&'),
      description: 'Manage Leave Events',
      statusMap: LEAVE_APPLICATION_STATUS_MAP,
    },
  ],

  /**
   * Possible actions
   * @todo implement the description and success, and make them less repetetive
   */
  possibleActions: [
    {
      name: 'submit',
      title: 'Submit',
      color: BUTTON_COLOR.INFO,
      icon: ICON.SUBMIT,
      description: 'This will submit this leave request',
      successMessage: 'Leave request submitted',
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update',
      successMessage: 'Updated',
      failMessage: 'Failed to update',
      hideRecordActionButton: true,
    },
    {
      name: 'approve',
      title: 'Approve',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.CHECK,
      description: 'This will approve this leave request',
      successMessage: 'Leave request approved',
      failMessage: 'Failed to update',
    },
    {
      name: 'confirm',
      title: 'Confirm',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.CHECK,
      description: 'This will confirm this leave request',
      successMessage: 'Leave request confirmed',
      failMessage: 'Failed to update',
    },
    {
      name: 'unsubmit',
      title: 'Withdraw',
      color: BUTTON_COLOR.WARNING,
      icon: 'undo',
      description: 'This will unsubmit this leave request',
      successMessage: 'Leave request withdrawn',
      failMessage: 'Failed to update',
    },
    {
      name: 'reject',
      title: 'Reject',
      color: BUTTON_COLOR.DANGER,
      icon: 'times',
      description: 'This will reject this leave request',
      successMessage: 'Leave request rejected',
      failMessage: 'Failed to update',
    },
    {
      name: 'revoke',
      title: 'Revoke',
      color: BUTTON_COLOR.DANGER,
      icon: 'undo',
      description: 'This will revoke this leave request',
      successMessage: 'Leave request revoked',
      failMessage: 'Failed to update',
    },
    {
      name: 'cancel',
      title: 'Cancel',
      color: BUTTON_COLOR.WARNING,
      icon: 'times',
      description: 'This will cancel this leave request',
      successMessage: 'Leave request cancelled',
      failMessage: 'Failed to update',
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will delete this leave request',
      successMessage: 'Leave request deleted',
      failMessage: 'Failed to update',
      hideRecordActionButton: true,
    },
  ],

  /**
   * View definitions
   */
  viewKey: 'outstanding',
  views: [
    {
      key: 'outstanding',
      title: 'Outstanding',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.IN,
          values: ['submitted', 'approved', 'revoked'],
        },
      ],
      flags: [],
    },
    {
      key: 'requires-approval',
      title: 'Requiring Approval',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.IN,
          values: ['submitted', 'revoked'],
        },
      ],
      flags: [],
    },
    {
      key: 'confirmed',
      title: 'Confirmed / Taken',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['confirmed'],
        },
      ],
      flags: [],
    },
    {
      key: 'cancelled',
      title: 'Cancelled',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['cancelled'],
        },
      ],
      flags: [],
    },
    {
      key: 'all',
      title: 'All Leave Applications',
      filters: [],
      flags: [],
    },
  ],
};

export default manageLeaveTableInitialSettings;
