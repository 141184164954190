import { ShowModalMethod } from '../components/modals/modal-context';

import { AModalResultType, ConfirmActionModalResult, ConfirmModalResult } from '../types/modal/modal-result';
import { IAPIAction } from '../types/api-action.interface';
import { ShowModalProps } from '../types/modal/show-modal.props';

import { A_BUTTON_COLOR } from '../constants/button-color.const';
import { AN_ICON, ICON } from '../constants/icon.const';
import { MODAL_TYPE } from '../constants/modal-type.const';
import { THEME_COLOR } from '../constants/theme-color.const';

// TODO: the confirm modal props are duplicated in many places - these need to be centralised
type ShowConfirmModalProps<T extends AModalResultType> = Omit<ShowModalProps<T>,
  'onModalComplete'
> & {
  content?: string | JSX.Element,
  confirmButtonLabel?: string,
  confirmButtonColor?: A_BUTTON_COLOR,
  confirmButtonIcon?: AN_ICON,
  requireReason?: boolean,
  action?: IAPIAction,
  onCancel?: () => void,
};

const _showConfirmModal = async <T extends AModalResultType>(
  showModal: ShowModalMethod,
  modalProps?: ShowConfirmModalProps<T>,
): Promise<T> => new Promise((resolve) => {
  // Set a bunch of defaults that the user prolly doesn't wanna supply
  const defaultModalProps: ShowConfirmModalProps<T> = {
    size: 'md',
    allowDismiss: true,
    title: 'Please Confirm',
    icon: ICON.CONFIRM,
    color: THEME_COLOR.PRIMARY,
  };

  showModal(MODAL_TYPE.CONFIRM, {
    ...defaultModalProps,
    ...modalProps,

    // It's important to override this property so that the promise is resolved and the async function returns.
    onModalComplete: (modalResult: T) => resolve(modalResult),
  });
});


/**
 * This is a simple wrapper around the confirmation modal to display it in an async/await pattern
 *
 * Usage:
 *
 *    const {showModal} = useContext(ModalContext);
 *    const modalResult: ConfirmModalResult = await showConfirmModal(showModal, {title: 'Be very sure!'});
 *    if (modalResult.success) {
 *       // Do your thing (Make that body sing).
 *    }
 */
export const showConfirmModal = (
  showModal: ShowModalMethod,
  modalProps?: ShowConfirmModalProps<ConfirmModalResult>,
): Promise<ConfirmModalResult> => _showConfirmModal<ConfirmModalResult>(showModal, modalProps);


/**
 * This is a simple wrapper around the action confirmation modal to display it in an async/await pattern
 *
 * Usage:
 *
 *    const {showModal} = useContext(ModalContext);
 *    const modalResult: ConfirmModalResult = await showConfirmModal(showModal, {title: 'Be very sure!'});
 *    if (modalResult.success) {
 *       // Do your thing (Make that body sing).
 *    }
 */
export const showConfirmActionModal = (
  showModal: ShowModalMethod,
  modalProps?: ShowConfirmModalProps<ConfirmActionModalResult>,
): Promise<ConfirmActionModalResult> => _showConfirmModal<ConfirmActionModalResult>(showModal, modalProps);
