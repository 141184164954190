import React, { Component } from 'react';
import { Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import NavHeading from './nav-heading';
import DropdownSublist from './dropdown-sublist';

class DropdownSubmenu extends Component {
  /**
   * @constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      open: props.selected || props.open || false,
      active: props.selected || false,
    };
  }


  /**
   * @description
   * Toggle the menu open or closed
   * @param {React.SyntheticEvent} e
   */
  toggleMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { open, active } = this.state;

    this.setState({
      open: !open,
      active: !active,
    });
  };


  /**
   * @inheritdoc
   */
  render() {
    const { open, active } = this.state;
    const {
      children, icon, title, selected, badgeCount, badgeColor,
    } = this.props;

    const stateClasses = classnames({ open, active, selected });

    return (
      <li className={stateClasses}>
        <NavHeading icon={icon} onClick={this.toggleMenu} active={active} open={open}>
          {title}
          {badgeCount > 0 && (
            <Badge className="badge-count" color={badgeColor}>
              {badgeCount}
            </Badge>
          )}
        </NavHeading>
        <DropdownSublist {...this.props} {...this.state}>
          {children}
        </DropdownSublist>
      </li>
    );
  }
}


DropdownSubmenu.defaultProps = {
  children: null,
  icon: null,
  open: false,
  active: false,
  selected: false,
  popOut: () => {},
  path: '',
  badgeCount: 0,
  badgeColor: 'info',
};

DropdownSubmenu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  icon: PropTypes.string,
  selected: PropTypes.bool,
  open: PropTypes.bool,
  active: PropTypes.bool,
  popOut: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  path: PropTypes.string,
  badgeCount: PropTypes.number,
  badgeColor: PropTypes.string,
};

export default DropdownSubmenu;
