import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { ICON } from '../../../constants/icon.const';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import { fullName } from '../../../helpers/full-name.helper';
import { ICompanyRecord } from '../../../types/company/company.record.interface';
import Icon from '../../layout-helpers/icon';
import { APIContext } from '../../providers/api-provider';

const qbAppEndpoint = process.env.REACT_APP_QUICKBOOKS_APP_ENDPOINT;
const qbPath = `${qbAppEndpoint}app/customerdetail?nameId=`;

interface IIrbCompanySummaryProps {
  type: string,
  company: ICompanyRecord,
  selected?: boolean,
  setClient?: (newClientId: number) => void
}

export const IrbCompanySummary: React.FC<IIrbCompanySummaryProps> = ({ type, company, selected, setClient }) => {
  const [companyData, setCompanyData] = useState<ICompanyRecord | null>(null);

  const { apiFetch } = useContext(APIContext);

  const loadCompanyAbortController = useRef<AbortController | null>(null);

  const loadCompany = useCallback(async () => {
    if (loadCompanyAbortController.current) {
      loadCompanyAbortController.current.abort();
    }
    loadCompanyAbortController.current = apiAborter();

    const response = await apiFetch(
      `/company/${company?.id}?with[]=defaultBillingContact`,
      {
        name: 'IRBCompanySummary::loadCompany',
        signal: loadCompanyAbortController.current.signal,
      },
    );

    if (response.success) {
      loadCompanyAbortController.current = null;
      setCompanyData(response.body.data);
    } else if (!response.aborted) {
      loadCompanyAbortController.current = null;
      setCompanyData(null);
    }
  }, [apiFetch, company?.id]);


  // on mount effect
  useEffect(() => {
    loadCompany();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <tr style={{ backgroundColor: selected ? '#E0FFE0' : '' }}>
      <td>
        <strong className={selected ? 'text-success' : ''}>
          {type}
        </strong>
      </td>
      <td>
        <Link to={`/crm/companies/${company.id}`}>{company.name}</Link>
      </td>
      <td className={`pl-3 ${(company.quickbooks_id) ? 'text-success' : 'text-danger'}`}>
        {(company.quickbooks_id) ? (
          <a
            href={`${qbPath}${company.quickbooks_id}`}
            target="_blank"
            rel="noopener noreferrer"
            color="success"
          >
            <span className="text-success">
              View in QB
              <Icon i={ICON.EXTERNAL_LINK} className="pl-2" />
            </span>
          </a>
        ) : 'Not Linked' }
      </td>
      <td>
        {companyData ? (
          <>
            {companyData.default_billing_contact ? (
              <Link to={`/crm/contacts/${companyData.default_billing_contact.id}`}>
                {fullName(companyData.default_billing_contact)}
              </Link>
            ) : (
              <span className="text-gray">
                None Set
              </span>
            )}
          </>
        ) : (
          <Icon i="rolling" />
        )}
      </td>
      <td style={{ padding: '0.5rem' }}>
        &nbsp;
        {!selected && (
          <Button size="sm" onClick={() => setClient && setClient(company.id)}>Use this Client</Button>
        )}
      </td>
    </tr>
  );
};
