import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import PageHeader from '../app-layout/page-header';
import { noop, documentTitle } from '../../utils/helpers';
import NotificationsList from './notifications-card';

const NotificationsPage = (props) => {
  const { title } = props;
  documentTitle(title || null);

  return (
    <Container fluid>
      <div style={{ position: 'relative' }}>
        <PageHeader {...props} />
        <Container className="portal-notifications">
          <NotificationsList />
        </Container>
      </div>
    </Container>
  );
};

NotificationsPage.defaultProps = {
  btnAction: noop,
  title: 'Notifications',
  showBtn: false,
  btnText: '',
};

NotificationsPage.propTypes = {
  btnAction: PropTypes.func,
  title: PropTypes.string,
  showBtn: PropTypes.bool,
  btnText: PropTypes.string,
};

export default NotificationsPage;
