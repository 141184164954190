/**
 * Colours for tables and buttons - basically the css colors bootstrap/reactstrap uses
 */
export const THEME_COLOR = {
  PRIMARY: 'primary',
  SUCCESS: 'success',
  SECONDARY: 'secondary',
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'danger',
  TRANSPARENT: 'transparent',
  LIGHT: 'light',
  CYAN: 'cyan',
  DARK: 'dark',
  PURPLE: 'purple',
  MEDIUM_AQUA: 'mediumAqua',
  GREEN: 'green',
  BLUE: 'blue',
  GRAY: 'gray',
} as const;

export type THEME_COLOR = typeof THEME_COLOR;
export type A_THEME_COLOR = THEME_COLOR[keyof THEME_COLOR];
