import React, { useState, useRef, useCallback, useEffect, createRef } from 'react';

import { ModalContext } from './modal-context';

import { AModalResultType, UpdateRecordModalResult } from '../../types/modal/modal-result';
import { IModalRecord } from '../../types/modal/modal-record.interface';
import { ModalProps } from '../../types/modal/modal.props';
import { ModalTypeComponentMap } from '../../constants/modal-type-component-map.const';
import { ShowModalProps } from '../../types/modal/show-modal.props';
import { ShowUpdateFileModalProps } from '../../types/modal/show-update-file-modal.props';

import { A_MODAL_TYPE, MODAL_TYPE } from '../../constants/modal-type.const';
import { IFileRecord } from '../../types/file.record.interface';

export type ModalProviderProps = Record<string, unknown>;

type ModalRecordAndComponent = IModalRecord & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ModalComponent: React.FC<ModalProps<any>>
}

// Global identifier for allocating unique IDs to modals when they are created
let nextModalId = 0;

/**
 * @class ModalProvider
 *
 * @description a uniform way of delivering props to a component that is dependent on the ModalController
 */
export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  // Keep the actual ModalContainer HTML element in state to pass down as a prop
  const [modalContainer, setModalContainer] = useState<HTMLDivElement | null>(null);

  // Keeping the components in state prevents the children from re-rendering too often
  const [modals, setModals] = useState<ModalRecordAndComponent[]>([]);

  // Create a container for the modals to be rendered into
  const modalContainerRef = createRef<HTMLDivElement>();

  // Keep an array of timeouts for destroying closed modals
  const destroyModalTimeouts = useRef<{
    modalId: number,
    timeout: ReturnType<typeof setTimeout>
  }[]>([]);

  /**
   * Actually remove a modal from the visible modals array
   * @param modalId
   */
  const destroyModal = (modalId: number) => {
    setModals((oldModals) => oldModals.filter((modal) => modal.id !== modalId));
    destroyModalTimeouts.current = destroyModalTimeouts.current.filter((timeout) => timeout.modalId !== modalId);
  };


  /**
   * Close the current (or a specific) modal
   */
  const closeModal = useCallback((modalId?: number, confirmClose = false, callback?: () => unknown) => {
    // Display a window confirmation warning the user about losing their unsaved changes?
    // eslint-disable-next-line no-alert
    if (confirmClose && !window.confirm('Discard your unsaved changes?')) return;

    // Find the specified modal
    let modalToClose = modals.find((modal) => modal.id === modalId);

    // If no specific modal was specified, close the top most modal
    if (!modalToClose && !modalId && modals.length) {
      modalToClose = modals[modals.length - 1];
    }

    if (modalToClose) {
      const modalIdToClose = modalToClose.id;

      setModals((oldModals) => oldModals.map((modal) => {
        // Notify the target modal that it has to close by making it invisible then destroying it later
        if (modal.id === modalIdToClose) {
          return {
            ...modal,
            isVisible: false,
          };
        }
        return modal;
      }));

      // Create a timeout to destroy the modal
      destroyModalTimeouts.current.push({
        modalId: modalIdToClose,
        timeout: setTimeout(() => destroyModal(modalIdToClose), 500),
      });

      // Fire the callback
      if (callback) callback();
    }
  }, [modals]);


  /**
   * Show a modal
   */
  const showModal = <T extends AModalResultType>(modalType: A_MODAL_TYPE, modalProps: ShowModalProps<T>): IModalRecord => {
    // Increment the global modal counter
    nextModalId += 1;

    const newModalId = nextModalId;

    const newModalRecord: IModalRecord = {
      id: newModalId,
      isVisible: true,
      modalType,
      modalProps,
    };

    const newModal: ModalRecordAndComponent = {
      ...newModalRecord,
      ModalComponent: ModalTypeComponentMap[modalType],
    };

    setModals((oldModals) => [
      ...oldModals,
      newModal,
    ]);

    return newModalRecord;
  };


  /**
   * Show the Update File modal
   */
  const showUpdateFileModal = <T extends IFileRecord>(
    modalProps: ShowUpdateFileModalProps<T>,
  ): IModalRecord => showModal<UpdateRecordModalResult<T>>(MODAL_TYPE.UPDATE_FILE, modalProps);


  /**
   * When the Modal Container ref is updated, capture the element reference to pass to modal children
   */
  useEffect(() => {
    setModalContainer(modalContainerRef.current);
  }, [modalContainerRef]);


  /**
   * When the component is un-mounted, destroy all modal closeTimeout callbacks
   */
  useEffect(() => () => {
    destroyModalTimeouts.current.forEach((destroyTimeout) => {
      if (destroyTimeout.timeout) {
        clearTimeout(destroyTimeout.timeout);
      }
    });
  }, []);


  /**
   * Render
   */
  return (
    <ModalContext.Provider
      value={{
        showModal,
        showUpdateFileModal,
        closeModal,
      }}
    >
      {children}

      <div key="modal_container" ref={modalContainerRef} id="modal_container" className="modal-container">
        {/* Render each of the modalComponents */}
        {modalContainer && modals.map((modal) => (
          <modal.ModalComponent
            {...modal.modalProps}
            key={modal.id}
            id={modal.id}
            isVisible={modal.isVisible}
            modalContainer={modalContainer}
            closeModal={closeModal}
            showModal={showModal}
          />
        ))}
      </div>
    </ModalContext.Provider>
  );
};
