import { ICompanyLocationPortalTreeViewSelectedNodeIdentifier } from '../../../types/portal-tree-view/company-location-portal-tree-view-selected-node-identifier.interface';
import { PortalTreeViewMenuItemFunction, PortalTreeViewMenuItems } from '../../../types/portal-tree-view/portal-tree-view-menu-items';

import { getSelectedNode } from '../portal-tree-view-get-selected-node.helper';

import { A_COMPANY_LOCATION_PTV_MENU_ITEM_TYPE, COMPANY_LOCATION_PTV_MENU_ITEM_TYPE } from './company-locations-portal-tree-menu-item.const';
import { COMPANY_LOCATION_TYPE } from '../../../constants/company-location-type.const';
import { COMPANY_LOCATION_PTV_NODE_TYPE } from '../../../constants/company-locations-ptv-node-type.const';
import { ICON } from '../../../constants/icon.const';

let separatorId = 0;
const getUniqueSeparatorKey = (): A_COMPANY_LOCATION_PTV_MENU_ITEM_TYPE => {
  separatorId += 1;
  return `sep_${separatorId}` as A_COMPANY_LOCATION_PTV_MENU_ITEM_TYPE;
};

const isRootNode = (selectedNode?: ICompanyLocationPortalTreeViewSelectedNodeIdentifier): boolean => (
  !!selectedNode && selectedNode.nodeType === COMPANY_LOCATION_PTV_NODE_TYPE.ROOT
);

const isLocationNode = (selectedNode?: ICompanyLocationPortalTreeViewSelectedNodeIdentifier): boolean => (
  !!selectedNode && selectedNode.nodeType === COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION
);

const isSpaceNode = (selectedNode?: ICompanyLocationPortalTreeViewSelectedNodeIdentifier): boolean => (
  !!selectedNode && selectedNode.nodeType === COMPANY_LOCATION_PTV_NODE_TYPE.SPACE
);

const isSiteNode = (selectedNode?: ICompanyLocationPortalTreeViewSelectedNodeIdentifier): boolean => (
  isLocationNode(selectedNode) && selectedNode?.companyLocationTypeId === COMPANY_LOCATION_TYPE.SITE
);

const isBuildingNode = (selectedNode?: ICompanyLocationPortalTreeViewSelectedNodeIdentifier): boolean => (
  isLocationNode(selectedNode) && selectedNode?.companyLocationTypeId === COMPANY_LOCATION_TYPE.BUILDING
);

const isLevelNode = (selectedNode?: ICompanyLocationPortalTreeViewSelectedNodeIdentifier): boolean => (
  isLocationNode(selectedNode) && selectedNode?.companyLocationTypeId === COMPANY_LOCATION_TYPE.LEVEL
);

const isZoneNode = (selectedNode?: ICompanyLocationPortalTreeViewSelectedNodeIdentifier): boolean => (
  isLocationNode(selectedNode) && selectedNode?.companyLocationTypeId === COMPANY_LOCATION_TYPE.ZONE
);


/**
 * Helper function to determine the visibility of company locations tree menu items
 * that create company location / company space records
 */
const isCreateNodeHidden: PortalTreeViewMenuItemFunction<boolean, ICompanyLocationPortalTreeViewSelectedNodeIdentifier> = (menuType, treeState, key, selectedNodes) => {
  // It's easier to determine the visibility and invert it at the end
  let isVisible = !treeState.isTreeReadOnly && !treeState.isTreeLocked;
  const selectedNode = getSelectedNode(selectedNodes);

  switch (key) {
    // A user can only create sites at the root level (i.e. when nothing is selected)
    case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE_SITE:
      isVisible = isVisible && (!selectedNode || isRootNode(selectedNode));
      break;

    // A user can only create buildings at the root level or for a site
    case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE_BUILDING:
      isVisible = isVisible && (!selectedNode || isRootNode(selectedNode) || isSiteNode(selectedNode));
      break;

    // A user can only create levels at the root level or for a site or a building
    case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE_LEVEL:
      isVisible = isVisible && (!selectedNode || isRootNode(selectedNode) || isSiteNode(selectedNode) || isBuildingNode(selectedNode));
      break;

    // A user can create zones at and level so long as the parent node is a location
    case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE_ZONE:
      isVisible = isVisible && (!selectedNode || isRootNode(selectedNode) || isLocationNode(selectedNode));
      break;

    // Spaces cannot be created underneath other spaces
    case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE:
    case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE_SPACE:
      isVisible = isVisible && (!selectedNode || isRootNode(selectedNode) || !isSpaceNode(selectedNode));
      break;
  }

  return !isVisible;
};


/**
 * Helper function to determine the visibility of company locations tree menu items
 * that convert company location space types
 */
const isConvertNodeHidden: PortalTreeViewMenuItemFunction<boolean, ICompanyLocationPortalTreeViewSelectedNodeIdentifier> = (menuType, treeState, key, selectedNodes) => {
  // TODO: enable convert functions
  return true;
  const selectedNode = getSelectedNode(selectedNodes);

  // It's easier to determine the visibility and invert it at the end
  let isVisible = !treeState.isTreeReadOnly && !treeState.isTreeLocked;

  switch (key) {
    // Only location nodes can be converted
    case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CONVERT:
      isVisible = isVisible && isLocationNode(selectedNode);
      break;

    // Allow any non-site node to be converted to a site
    case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CONVERT_SITE:
      isVisible = isVisible && isLocationNode(selectedNode) && !isSiteNode(selectedNode);
      break;

    // Allow any non-building node to be converted to a building
    case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CONVERT_BUILDING:
      isVisible = isVisible && isLocationNode(selectedNode) && !isBuildingNode(selectedNode);
      break;

    // Allow any non-level node to be converted to a level
    case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CONVERT_LEVEL:
      isVisible = isVisible && isLocationNode(selectedNode) && !isLevelNode(selectedNode);
      break;

    // Allow any non-zone node to be converted to a zone
    case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CONVERT_ZONE:
      isVisible = isVisible && isLocationNode(selectedNode) && !isZoneNode(selectedNode);
      break;
  }

  return !isVisible;
};


/**
 * Helper function to determine the visibility of company locations tree menu items
 * that delete company location and space nodes
 */
const isDeleteNodeHidden: PortalTreeViewMenuItemFunction<boolean, ICompanyLocationPortalTreeViewSelectedNodeIdentifier> = (menuType, treeState, key, selectedNodes) => {
  // It's easier to determine the visibility and invert it at the end
  let isVisible = !treeState.isTreeReadOnly && !treeState.isTreeLocked;
  const selectedNode = getSelectedNode(selectedNodes);

  switch (key) {
    // Delete should be visible if there is a node selected
    // TODO: at some point the user's security privileges will need to come into account
    // TODO: at some point we should allow the bulk delete of many selected nodes
    case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.DELETE:
      isVisible = isVisible && !!selectedNode;
      break;
  }

  return !isVisible;
};


/**
 * Helper function to determine the visibility of company locations tree menu items
 * that move nodes up into their parent's parent
 */
const isMoveNodeUpHidden: PortalTreeViewMenuItemFunction<boolean, ICompanyLocationPortalTreeViewSelectedNodeIdentifier> = (menuType, treeState, key, selectedNodes) => {
  // It's easier to determine the visibility and invert it at the end
  let isVisible = !treeState.isTreeReadOnly && !treeState.isTreeLocked;
  const selectedNode = getSelectedNode(selectedNodes);

  switch (key) {
    // Move-Up should be visible if there is a node selected and that node is not already at the top level (i.e. it has a parent node)
    // TODO: at some point the user's security privileges will need to come into account
    case COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.MOVE_UP:
      isVisible = isVisible && !!selectedNode && !!selectedNode.parentNodeId && (selectedNode.parentNodeId !== 'ROOT');
      break;
  }

  return !isVisible;
};


/**
 * Create and return a new separator menu item
 */
const separator = () => (
  {
    key: getUniqueSeparatorKey(),
    label: '',
    isSeparator: true,
  }
);


export const buildCompanyLocationsNewRecordMenuItems = (
  onClick: PortalTreeViewMenuItemFunction<void, ICompanyLocationPortalTreeViewSelectedNodeIdentifier>,
): PortalTreeViewMenuItems<ICompanyLocationPortalTreeViewSelectedNodeIdentifier> => [
  {
    key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE_SITE,
    label: 'Site',
    icon: ICON.SITE,
    isHidden: isCreateNodeHidden,
    onClick,
  },
  {
    key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE_BUILDING,
    label: 'Building',
    icon: ICON.BUILDING,
    isHidden: isCreateNodeHidden,
    onClick,
  },
  {
    key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE_LEVEL,
    label: 'Level',
    icon: ICON.LEVEL,
    isHidden: isCreateNodeHidden,
    onClick,
  },
  {
    key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE_ZONE,
    label: 'Zone',
    icon: ICON.ZONE,
    isHidden: isCreateNodeHidden,
    onClick,
  },
  separator(),
  {
    key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE_SPACE,
    label: 'Space',
    icon: ICON.SPACE,
    isHidden: isCreateNodeHidden,
    onClick,
  },
];


/**
 * Build the menu items for the company locations tree
 */
export const buildCompanyLocationsTreeMenuItems = (
  onClick: PortalTreeViewMenuItemFunction<void, ICompanyLocationPortalTreeViewSelectedNodeIdentifier>,
): PortalTreeViewMenuItems<ICompanyLocationPortalTreeViewSelectedNodeIdentifier> => [

  // Create Location / Space
  {
    key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CREATE,
    label: 'Add',
    icon: ICON.ADD,
    isHidden: isCreateNodeHidden,
    subMenuItems: buildCompanyLocationsNewRecordMenuItems(onClick),
  },

  separator(),

  // Convert Location Type
  {
    key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CONVERT,
    label: 'Convert To',
    icon: ICON.CONVERT_LOCATION,
    isHidden: isConvertNodeHidden,
    subMenuItems: [
      {
        key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CONVERT_SITE,
        label: 'Site',
        icon: ICON.SITE,
        isHidden: isConvertNodeHidden,
        onClick,
      },
      {
        key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CONVERT_BUILDING,
        label: 'Building',
        icon: ICON.BUILDING,
        isHidden: isConvertNodeHidden,
        onClick,
      },
      {
        key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CONVERT_LEVEL,
        label: 'Level',
        icon: ICON.LEVEL,
        isHidden: isConvertNodeHidden,
        onClick,
      },
      {
        key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.CONVERT_ZONE,
        label: 'Zone',
        icon: ICON.ZONE,
        isHidden: isConvertNodeHidden,
        onClick,
      },
    ],
  },

  // Move Node Up
  {
    key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.MOVE_UP,
    label: 'Move Up',
    icon: ICON.MOVE_UP,
    isHidden: isMoveNodeUpHidden,
    onClick,
  },

  // Delete Node
  {
    key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.DELETE,
    label: 'Delete',
    icon: ICON.DELETE,
    isHidden: isDeleteNodeHidden,
    onClick,
  },

  separator(),


  // Expand All
  {
    key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.EXPAND_ALL,
    label: 'Expand All',
    icon: ICON.EXPAND_ALL,
    onClick,
  },

  // Collapse All
  {
    key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.COLLAPSE_ALL,
    label: 'Collapse All',
    icon: ICON.COLLAPSE_ALL,
    onClick,
  },

  // Options group
  {
    key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.OPTIONS,
    label: 'Options',
    icon: ICON.OPTIONS,
    subMenuItems: [

      // Safe Mode toggle
      {
        key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.DISABLE_SAFE_MODE,
        label: 'Disable Safe Mode',
        icon: ICON.SAFE_MODE_DISABLED,
        isHidden: (menuType, treeState) => !treeState.isSafeModeEnabled,
        isDisabled: (menuType, treeState) => !treeState.isTreeReadOnly && !treeState.isTreeLocked,
        onClick,
      },
      {
        key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.ENABLE_SAFE_MODE,
        label: 'Enable Safe Mode',
        icon: ICON.SAFE_MODE_ENABLED,
        isHidden: (menuType, treeState) => treeState.isSafeModeEnabled,
        isDisabled: (menuType, treeState) => !treeState.isTreeReadOnly && !treeState.isTreeLocked,
        onClick,
      },

      // Show the Root Node
      {
        key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.SHOW_ROOT_NODE,
        label: 'Show Root Node',
        icon: ICON.SHOW_ROOT_NODE,
        isHidden: (menuType, treeState) => treeState.isRootNodeVisible,
        onClick,
      },

      // Hide the Root Node
      {
        key: COMPANY_LOCATION_PTV_MENU_ITEM_TYPE.HIDE_ROOT_NODE,
        label: 'Hide Root Node',
        icon: ICON.HIDE_ROOT_NODE,
        isHidden: (menuType, treeState) => !treeState.isRootNodeVisible,
        onClick,
      },
    ],
  },
];
