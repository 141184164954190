import React from 'react';
import { Moment } from 'moment';
import classnames from 'classnames';

interface IGanttViewDayTitlesProps {
  dayGap: number,
  startMoment: Moment,
  sprintRowCount: number,
  dayRemWidth: number,
}

/**
 * Titles row for gantt view
 *
 * @param {IGanttViewDayTitlesProps}  props
 * @param {number}                    props.dayGap          number of days to render for
 * @param {Moment}                    props.startMoment     Moment.js object containing the start date for the view
 * @param {number}                    props.sprintRowCount  number of sprint rows to render for
 * @param {number}                    props.dayRemWidth     width to render days in 'rem'
 */
export const GanttViewDayTitles: React.FC<IGanttViewDayTitlesProps> = ({ dayGap, startMoment, sprintRowCount, dayRemWidth }) => {
  // generate day elements

  const dayElements = [];

  for (let index = 0; index < dayGap; index += 1) {
    const dayMoment: Moment = startMoment.clone().add(index, 'days');
    const dayOfMonth = parseInt(dayMoment.format('D'), 10);
    const dayShort = dayMoment.format('ddd');
    dayElements.push(
      <div
        key={dayMoment.format()}
        className={classnames(
          'day-column',
          {
            'first-of-month': dayOfMonth === 1,
            'weekend-day': dayShort === 'Sat' || dayShort === 'Sun',
          },
        )}
        style={{
          width: `${dayRemWidth}rem`,
          height: `${sprintRowCount * 20}rem`,
        }}
      >
        <div className="day-name">
          {dayMoment.format('ddd D MMM')}
        </div>
      </div>,
    );
  }

  return (
    <div className="day-titles">
      {dayElements}
    </div>
  );
};
