import { yesNoOptions, activeInactiveOptions } from '../utils/constants';
import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { projectsWidgetFilter } from '../utils/helpers';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { PROJECT_STATUS } from '../constants/project-status.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { DATA_TABLE_FLAG } from '../constants/data-table-flag.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';

const qbAppEndpoint = process.env.REACT_APP_QUICKBOOKS_APP_ENDPOINT;
const qbPath = `${qbAppEndpoint}app/vendordetail?nameId=`;
/**
 * Table Definition for UserManagement Table
 */
const userManagementTableInitialSettings = {
  ...defaultTableSettings,
  initialFormFocusFieldName: 'first',
  baseRoute: '/user',
  itemCaption: 'User',
  baseQueryString: [
    'with[]=state:id,acronym',
    'with[]=directManager:id,name',
    'with[]=indirectManager:id,name',
    'with[]=escalationManager:id,name',
    'with[]=team:id,name',
  ].join('&'),
  // baseQueryString: 'with[]=company:id,name&with_count=projects',
  // Array of objects of configs of column presets

  columnPresets: [
    {
      name: 'default',
      title: 'Default',
      fields: [
        'name', // 10
        'initials', // 50
        'active', // 60
        'email', // 80
        'state', // 90
        'direct_manager', // 120
        'indirect_manager', // 130
        'escalation_manager', // 140
      ],
    },
    {
      name: 'manage',
      title: 'Manage',
      fields: [
        'id',
        'active',
        'first',
        'middle',
        'last',
        'initials',
        'state',
        'email',
        'birthday',
        'direct_manager',
        'indirect_manager',
        'escalation_manager',
      ],
    },
    {
      name: 'contact',
      title: 'Contact',
      fields: [
        'name',
        'email',
        'active',
        'phone_office',
        'phone_mobile',
        'direct_manager',
        'indirect_manager',
        'escalation_manager',
      ],
    },
    {
      name: 'all',
      title: 'All',
      fields: [
        'name',
        'initials',
        'email',
        'state',
        'active',
        'direct_manager',
        'indirect_manager',
        'escalation_manager',
        'id',
        'title',
        'first',
        'middle',
        'last',
        'birthday',
        'phone_office',
        'phone_mobile',
        'profile_body',
        'key_pay_id',
        'accounting_id',
        'quickbooks_supplier_id',
        'enable_family_friendly_leave',
        'family_friendly_leave_days',
        'enable_birthday_leave',
        'workday_hours',
      ],
    },
  ],

  /**
   * Definition of Columns for this table
   */
  columns: [
    {
      name: 'id',
      title: 'Identity',
      description: 'User Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      linkRoute: '/settings/user-management',
      visible: false,
      showInForm: false,
      order: 0,
      formOrder: 10,
    },

    {
      name: 'name',
      title: 'Name',
      description: 'User Name',
      format: COLUMN_FORMAT.NAME_LINK,
      key: 'name',
      linkRoute: '/settings/user-management',
      linkId: 'id',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      order: 10,
      formOrder: 20,
    },

    {
      name: 'first',
      title: 'First Name',
      description: 'Given name / first name',
      format: COLUMN_FORMAT.TEXT,
      visible: false,
      order: 20,
      required: true,
      formOrder: 30,
    },

    {
      name: 'middle',
      title: 'Middle Name',
      description: 'User Middle name',
      format: COLUMN_FORMAT.TEXT,
      visible: false,
      order: 30,
      formOrder: 40,
    },

    {
      name: 'last',
      title: 'Last Name',
      description: 'Last name / surname',
      format: COLUMN_FORMAT.TEXT,
      visible: false,
      order: 40,
      required: true,
      formOrder: 50,
    },

    {
      name: 'initials',
      title: 'Initials',
      description: 'User Initials',
      format: COLUMN_FORMAT.TEXT,
      visible: true,
      order: 50,
      required: true,
      formOrder: 60,
    },

    {
      name: 'active',
      title: 'Status',
      description: 'User status',
      format: COLUMN_FORMAT.ACTIVE_INACTIVE_BADGE,
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: activeInactiveOptions,
      visible: true,
      required: true,
      order: 60,
      formOrder: 25,
      isClearable: false,
      isSearchable: false,
    },

    {
      name: 'title',
      title: 'Position',
      description: 'Position (i.e. Account Director or Sales Support)',
      format: COLUMN_FORMAT.TEXT,
      visible: false,
      showInForm: true,
      order: 70,
      formOrder: 70,
    },
    {
      name: 'team',
      title: 'Team',
      description: 'Main Team for User',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'team',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/internal-projects/teams',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.TEAM_PICKER,
      formSaveField: 'team_id',
      visible: false,
      showInForm: true,
      required: false,
      isClearable: false,
      order: 72,
      formOrder: 72,
    },

    {
      name: 'state',
      title: 'State',
      visible: true,
      description: 'Which office state?',
      formFieldType: FORM_FIELD_TYPE.STATE_PICKER,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'state',
        key: 'acronym',
        format: COLUMN_FORMAT.TEXT,
      },
      formSaveField: 'state_id',
      order: 90,
      required: true,
      formOrder: 75,
      isClearable: false,
    },

    {
      name: 'email',
      title: 'Email Address',
      description: 'Email Address',
      format: COLUMN_FORMAT.EMAIL,
      visible: true,
      order: 80,
      required: true,
      formOrder: 80,
    },

    {
      name: 'phone_office',
      title: 'Office Number',
      description: 'Phone number for office',
      format: COLUMN_FORMAT.TEXT,
      visible: false,
      order: 100,
      formOrder: 90,
    },

    {
      name: 'phone_mobile',
      title: 'Mobile',
      description: 'Mobile phone number',
      format: COLUMN_FORMAT.TEXT,
      visible: false,
      order: 110,
      formOrder: 100,
    },

    {
      name: 'direct_manager',
      title: 'Direct Mgr.',
      description: "Employee's direct manager",
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'direct_manager',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/hrm/browse-profiles',
        linkId: 'id',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
      formSaveField: 'direct_manager_id',
      visible: true,
      order: 120,
      required: true,
    },
    {
      name: 'indirect_manager',
      title: 'Indirect Mgr.',
      description: "Employee's indirect manager",
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'indirect_manager',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/hrm/browse-profiles',
        linkId: 'id',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
      formSaveField: 'indirect_manager_id',
      visible: true,
      order: 130,
    },
    {
      name: 'escalation_manager',
      title: 'Escalation Mgr.',
      description: "Employee's escalation manager",
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'escalation_manager',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/hrm/browse-profiles',
        linkId: 'id',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
      formSaveField: 'escalation_manager_id',
      visible: true,
      order: 140,
    },
    {
      name: 'birthday',
      title: 'Birthday',
      description: 'Birthday, year is optional',
      formFieldType: FORM_FIELD_TYPE.BIRTHDAY_SELECT,
      format: COLUMN_FORMAT.BIRTHDAY,
      visible: false,
      order: 150,
    },
    {
      name: 'profile_body',
      title: 'Description',
      description: 'User profile content. HTML may be used.',
      format: COLUMN_FORMAT.TEXT,
      visible: false,
      order: 160,
    },
    {
      name: 'key_pay_id',
      title: 'KeyPay ID',
      description: "This user's ID in KeyPay",
      format: COLUMN_FORMAT.NOT_EDITABLE,
      visible: false,
      order: 170,
    },
    {
      name: 'accounting_id',
      title: 'Accounting ID',
      description: "This user's ID in KeyPay",
      format: COLUMN_FORMAT.NOT_EDITABLE,
      visible: false,
      order: 171,
    },
    {
      name: 'quickbooks_supplier_id',
      title: 'QuickBooks Supplier',
      description: 'Supplier in QuickBooks',
      format: COLUMN_FORMAT.BUTTON_LINK,
      linkId: 'quickbooks_supplier_id',
      externalLink: (row) => `${qbPath}${row.quickbooks_supplier_id}`,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      buttonLabel: 'View in QuickBooks',
      visible: false,
      showInForm: true,
      order: 172,
      formOrder: 172,
    },
    {
      name: 'microsoft_ad_user_id',
      title: 'Microsoft AD User Id',
      description: "This user's ID in the Microsoft (Azure) Active directory",
      formFieldType: FORM_FIELD_TYPE.MICROSOFT_AD_USER_ID_INPUT,
      format: COLUMN_FORMAT.TEXT,
      visible: false,
      order: 175,
    },
    {
      name: 'enable_family_friendly_leave',
      title: 'F.F. Leave',
      description: 'This user is eligible for Family Friendly Leave',
      format: COLUMN_FORMAT.ID_NAME_OPTIONS,
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: yesNoOptions,
      visible: false,
      order: 180,
      isClearable: false,
    },
    {
      name: 'family_friendly_leave_days',
      title: 'F.F. Leave Days',
      description: 'The maximum number of FFL days this user is eligible for annually.',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 190,
    },
    {
      name: 'enable_birthday_leave',
      title: 'Birthday Leave',
      description: 'This user is eligible for Birthday Leave',
      format: COLUMN_FORMAT.ID_NAME_OPTIONS,
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: yesNoOptions,
      visible: false,
      order: 200,
      isClearable: false,
    },
    {
      name: 'eligible_for_commission',
      title: 'Eligible for commision',
      description: 'This user is eligible for Sales Commission',
      format: COLUMN_FORMAT.ID_NAME_OPTIONS,
      formFieldType: FORM_FIELD_TYPE.CONST_SELECT,
      inputOptions: yesNoOptions,
      visible: false,
      order: 200,
      isClearable: false,
    },
    {
      name: 'workday_hours',
      title: 'Work-Day Hours',
      description: 'The number of hours for a regular working day for this employee ie. 7.8 or 8 hours',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      visible: false,
      order: 210,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'User Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'roles',
      title: 'Roles',
      description: 'Portal User Roles',
      widgetDefinition: WIDGET_DEFINITION.USER_ROLES,
      apiRoute: '/role',
      apiQuery: 'pagelength=0',
    },
    {
      name: 'notifications',
      title: 'Notifications',
      description: "User's Notifications",
      widgetDefinition: WIDGET_DEFINITION.USER_NOTIFICATIONS,
      apiRoute: '/notification',
    },
    {
      name: 'myob-info',
      title: 'MYOB Info',
      description: 'User MYOB Info',
      widgetDefinition: WIDGET_DEFINITION.USER_MYOB_INFO,
      apiRoute: '/myob-info',
    },
    {
      name: 'sales-targets',
      title: 'Sales Targets',
      description: 'User Sales Targets',
      widgetDefinition: WIDGET_DEFINITION.USER_SALES_TARGETS,
      apiRoute: '/sales-target',
      apiQuery: 'pagelength=0',
    },
    {
      name: 'leads',
      title: 'Leads',
      description: 'Contact Leads',
      widgetDefinition: WIDGET_DEFINITION.PROJECTS,
      apiRoute: (row) => projectsWidgetFilter('owner_id', row.id, PROJECT_STATUS.LEAD),
    },
    {
      name: 'proposals',
      title: 'Proposals',
      description: 'Contact Proposals',
      widgetDefinition: WIDGET_DEFINITION.PROJECTS,
      apiRoute: (row) => projectsWidgetFilter('owner_id', row.id, PROJECT_STATUS.PROPOSAL),
    },
    {
      name: 'active-projects',
      title: 'Active Projects',
      description: 'Contact Active Projects',
      widgetDefinition: WIDGET_DEFINITION.PROJECTS,
      apiRoute: (row) => projectsWidgetFilter('owner_id', row.id, PROJECT_STATUS.ACTIVE),
    },
    {
      name: 'completed-projects',
      title: 'Completed Projects',
      description: 'Contact Completed Projects',
      widgetDefinition: WIDGET_DEFINITION.PROJECTS,
      apiRoute: (row) => projectsWidgetFilter('owner_id', row.id, PROJECT_STATUS.COMPLETED),
    },
    {
      name: 'lost-projects',
      title: 'Lost Opportunities',
      description: 'Contact Lost Opportunities',
      widgetDefinition: WIDGET_DEFINITION.PROJECTS,
      apiRoute: (row) => projectsWidgetFilter('owner_id', row.id, PROJECT_STATUS.LOST),
    },
    {
      ...eventsTabDefault,
      description: 'User Event Audit Trail',
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add User',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update',
      successMessage: 'Updated',
      failMessage: 'Failed to update',
      hideRecordActionButton: true,
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE the user',
      successMessage: 'User deleted',
      failMessage: 'Failed to delete user',
      hideRecordActionButton: true,
    },
    {
      name: 'sendwelcomeemail',
      title: 'Send Welcome Email',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.EMAIL,
      description:
        'Send the welcome email!   Please Note: if the user follows through, this will reset their password.',
      successMessage: 'Welcome Email Sent!',
      failMessage: 'Could not send welcome email, please try again shortly.',
    },
    {
      name: 'link-quickbooks-supplier',
      title: 'Link Quickbooks Supplier',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.EMAIL,
      description:
        'This will search for the user as a supplier in quickbooks, link them if found, and create them if not found.    '
        + 'If their name is different in the ci portal, to quickbooks, this may create a duplicate',
      successMessage: 'Supplier ID linked!',
      failMessage: 'Linking the user as a supplier failed.',
    },
    {
      name: 'link-payroll-id',
      title: 'Link Payroll User',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.EMAIL,
      description:
        'This will search for the user as a supplier in payroll, and link them if found.',
      successMessage: 'Payroll ID linked!',
      failMessage: 'Linking the user to payroll failed.',
    },
  ],

  /**
   * View definitions
   */
  viewKey: 'active',
  views: [
    {
      key: 'active',
      title: 'Active Users',
      filters: [
        {
          field: 'active',
          operation: API_FILTER_OPERATION.EQUALS,
          values: [1],
        },
      ],
      flags: [],
    },
    {
      key: 'inactive',
      title: 'Inactive Users',
      filters: [
        {
          field: 'active',
          operation: API_FILTER_OPERATION.EQUALS,
          values: [0],
        },
      ],
      flags: [],
    },
    {
      key: 'all',
      title: 'All Users',
      filters: [],
      flags: [],
    },
    {
      key: 'allIncDeleted',
      title: 'All Users (Incl. Deleted)',
      filters: [],
      flags: [DATA_TABLE_FLAG.DELETED],
    },
  ],
};

export default userManagementTableInitialSettings;
