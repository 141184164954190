import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { DataTablePageProps } from '../../types/data-table-page.props';
import { NewRecordModalResult } from '../../types/modal/modal-result';
import { ICompanySpaceTypeRecord } from '../../types/company/company-space-type.record.interface';

import { ModalContext } from '../modals/modal-context';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';

export type CompanySpaceTypeDataTablePageProps = DataTablePageProps;

/**
 * Display the "CompanySpaceTypes" data table as a whole page
 */
export const CompanySpaceTypeDataTablePage: React.FC<CompanySpaceTypeDataTablePageProps> = ({
  baseFilters,
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const history = useHistory();
  const { showModal } = useContext(ModalContext);

  const actionHandlers = {
    create: () => {
      showModal<NewRecordModalResult<ICompanySpaceTypeRecord>>(MODAL_TYPE.NEW_COMPANY_SPACE_TYPE, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/settings/company-space-types/${modalResult.newRecord.id}`);
        },
      });
    },
  };

  return (
    <DataTablePage
      title="Company Space Types"
      tableIdentifier={TABLE_IDENTIFIER.COMPANY_SPACE_TYPES_TABLE}
      actionHandlers={actionHandlers}
      baseFilters={baseFilters}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      { children }
    </DataTablePage>
  );
};
