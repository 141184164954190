import React, { SyntheticEvent, useContext, useState } from 'react';
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { UserAvatar } from '../user-profiles/user-avatar';
import Icon from '../layout-helpers/icon';
import teamsIcon from '../../images/teams-icon.png';
import { MSTeamsContext } from '../layout-helpers/msteams-provider';
import { APIContext } from '../providers/api-provider';
import { CurrentUserContext } from '../providers/current-user-provider';
import { APP_ENV } from '../../utils/constants';
import { ICON } from '../../constants/icon.const';


const UserMenu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const { logout } = useContext(APIContext);
  const { userDetails, refreshUserPermissions } = useContext(CurrentUserContext);
  const { clearUseTeamsClientPreference } = useContext(MSTeamsContext);

  /**
   * @description
   * Toggle whether the user menu is open or closed
   */
  const handleToggleDropdown = (e: SyntheticEvent) => {
    if (!(e.currentTarget && e.currentTarget.classList && e.currentTarget.classList.contains('prevent-close'))) {
      e.preventDefault();
      setMenuOpen(!menuOpen);
    }
  };


  /**
   * @description
   * Fired when the user selects the "Change Teams Client" menu option
   */
  const handleClickChangeTeamsClientPreference = (e: SyntheticEvent) => {
    if (!(e.currentTarget && e.currentTarget.classList && e.currentTarget.classList.contains('prevent-close'))) {
      e.preventDefault();
      clearUseTeamsClientPreference();
      setMenuOpen(!menuOpen);
    }
  };


  /**
   * @description
   * Fired when the user clicks the log out button
   *
   * @param {React.SyntheticEvent} e
   */
  const handleClickLogOut = (e: SyntheticEvent) => {
    e.preventDefault();
    logout();
  };


  // Return <UserMenu />
  return (
    <Dropdown tag="div" className="UserMenu" isOpen={menuOpen} toggle={handleToggleDropdown} nav>
      <DropdownToggle className="UserMenu__toggle">
        <div className="user-avatar-wrapper">
          <div className="user-avatar-container">
            <UserAvatar name={userDetails.name || 'User Settings'} alt={userDetails.name || 'User Settings'} />
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu className="UserMenu__menu" right>
        <span className="with-arrow">
          <span className="bg-dark" />
        </span>
        <DropdownItem
          className="no-block align-items-center p-15 bg-dark text-white"
          header
        >
          <div className="m-l-10">
            <h4 className="m-b-0">{userDetails.name || 'User Settings'}</h4>
            <p className="m-b-0">{userDetails.email || ''}</p>
          </div>
        </DropdownItem>
        <div className="item-wrapper">
          <DropdownItem>
            <Link className="dropdown-item" to="/comms/notifications">
              <Icon i="icon-bell" fw />
              <span>Notifications</span>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link className="dropdown-item" to="/hrm/my-profile">
              <Icon i={ICON.MY_PROFILE} fw />
              <span>My Profile</span>
            </Link>
          </DropdownItem>
          <DropdownItem className="prevent-close">
            <a
              className="dropdown-item"
              href="mailto:support@thecigroup.com.au"
            >
              <Icon i="ti-email" fw />
              <span>Send Feedback</span>
            </a>
          </DropdownItem>

          <DropdownItem divider />
          <DropdownItem className="prevent-close">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="dropdown-item"
              href="#"
              onClick={handleClickChangeTeamsClientPreference}
            >
              <img src={teamsIcon} style={{ width: '1em' }} alt="MS Teams" />
              <span>Change Teams Client</span>
            </a>
          </DropdownItem>

          <DropdownItem divider />

          {/* Developer Only item to refresh security permissions */}
          {APP_ENV === 'dev' && (
            <DropdownItem className="prevent-close">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="dropdown-item"
                href="#"
                onClick={() => refreshUserPermissions(true)}
              >
                <Icon i="ti-lock" fw />
                <span>Refresh Permissions</span>
              </a>
            </DropdownItem>
          )}

          <DropdownItem>
            <Link className="dropdown-item" to="/" onClick={handleClickLogOut}>
              <Icon i="power-off" />
              <span>Log out</span>
            </Link>
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default UserMenu;
