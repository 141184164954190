import { DELETE_CONFIRMATION_TYPE } from '../constants/delete-confirmation-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { IProjectRecord } from '../types/project/project.record.interface';
import { ICompanyLocationRecord } from '../types/company/company-location.record.interface';
import { ICompanyRecord } from '../types/company/company.record.interface';
import { IProjectLocationRecord } from '../types/project/project-location.record.interface';
import { buildCompanyLocationURL } from '../components/portal-tree-view/company-locations/company-locations-tree-view-url.helper';

export const ProjectLocationsWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Location',
  formDeleteConfirmationType: DELETE_CONFIRMATION_TYPE.REMOVE,
  fields: [
    {
      name: 'project_id',
      visible: false,
      isParentId: true,
    },

    // Location - CompanyLocationPicker
    {
      name: 'company_location',
      title: 'Location',
      formSaveField: 'company_location_id',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        key: 'path',
        sourceField: 'company_location',
        format: COLUMN_FORMAT.COMPANY_LOCATION,
        linkRoute: (formData: ICompanyLocationRecord) => buildCompanyLocationURL(formData.company_id, formData.id),
      },
      formFieldType: FORM_FIELD_TYPE.COMPANY_LOCATION_PICKER,
      searchRoute: (formData: ICompanyLocationRecord, parentData: IProjectRecord): string => `/company/${parentData.client_id}/location`,
      isCreatable: true,
      getNewItemData: (inputValue: string, formData: IProjectLocationRecord, parentData?: IProjectRecord): {
        company_id: null | number,
        company?: null | ICompanyRecord,
      } => ({
        company_id: parentData?.client_id ?? null,
        company: parentData?.client ?? null,
      }),
    },

    // Project Manager - UserPicker
    {
      name: 'project_manager',
      title: 'Project Manager',
      formSaveField: 'project_manager_id',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'project_manager',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
        listSource: 'users',
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
    },

    // Value - CurrencyInput
    {
      name: 'value',
      visible: true,
      title: 'Value',
      formFieldType: FORM_FIELD_TYPE.CURRENCY_INPUT,
      allowNegative: true,
      format: COLUMN_FORMAT.CURRENCY,
      description: 'Location Value',
      showTotals: true,
      isClearable: true,
    },
  ],
};
