import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Card, CardBody, CardText, Col, Container, Row } from 'reactstrap';
import moment from 'moment';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { PERMISSION } from '../../constants/permissions.const';
import { APIContext } from '../providers/api-provider';
import { CurrentUserContext } from '../providers/current-user-provider';
import PageHeader from '../app-layout/page-header';
import { GanttViewDayTitles } from './gantt-view-day-titles';
import { GanttViewSprintRow } from './gantt-view-sprint-row';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { ISprintRecord } from '../../types/internal-project/sprint.record.interface';
import { YearMonthPicker } from '../form-input/yearmonth-picker';
import { monthsSelect, projectSummaryYearOptions } from '../../utils/constants';

interface IGanttViewPageProps {
  name: string;
  title: string;
}

interface ISprintLoadResult {
  working: boolean,
  error: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { [key: string]: any },
}

/**
 * Project Gantt View Page
 *
 * Render internal projects as a gantt view (WIP)
 *
 * @todo complete this view
 *
 * @param {IGanttViewPageProps} props
 * @param {string}              props.name    a string ID for the view
 * @param {string}              props.title   a title for the view
 */
export const GanttViewPage: React.FC<IGanttViewPageProps> = ({ name, title }) => {
  const [sprintLoadResult, setSprintLoadResult] = useState<ISprintLoadResult>({
    working: false,
    error: false,
    data: [],
  });

  // const [viewStartMoment, setViewStartMoment] = useState(moment().startOf('month').subtract(2, 'months'));
  // const [viewEndMoment, setViewEndMoment] = useState(viewStartMoment.clone().endOf('month').add(2, 'months'));
  const viewStartMoment = moment().startOf('month').subtract(2, 'months');
  const viewEndMoment = viewStartMoment.clone().endOf('month').add(2, 'months');
  const { apiFetch } = useContext(APIContext);
  const { userHasPermissions } = useContext(CurrentUserContext);

  const loadInternalSprintsAbortController = useRef<AbortController | null>(null);
  const addInternalSprintAbortController = useRef<AbortController | null>(null);

  const loadInternalSprints = useCallback(async () => {
    if (addInternalSprintAbortController.current) {
      addInternalSprintAbortController.current.abort();
    }
    addInternalSprintAbortController.current = apiAborter();

    setSprintLoadResult({
      ...sprintLoadResult,
      working: true,
    });

    const response = await apiFetch(
      '/sprint?with[]=projects&with[]=items&with_count[]=projects&with_count[]=items',
      {
        method: HTTP_METHOD.GET,
        name: 'SprintGanttViewPageListData:load',
        signal: addInternalSprintAbortController?.current?.signal,
      },
    );

    if (response.success) {
      addInternalSprintAbortController.current = null;
      const newSprintData = response.body.data;
      setSprintLoadResult({
        working: false,
        error: false,
        data: newSprintData,
      });
    } else if (!response.aborted) {
      addInternalSprintAbortController.current = null;
      setSprintLoadResult({
        working: false,
        error: true,
        data: [],
      });
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSprintLoadResult]);

  /**
   * On component mount load Sprint Lists
   */
  useEffect(() => {
    // Load the sprints for the current sprint
    if (userHasPermissions(PERMISSION.INTERNAL_PROJECT_VIEWER)) {
      loadInternalSprints();
    }

    const lISAC = loadInternalSprintsAbortController;

    return () => {
      // Kill any outstanding load when the component is un-mounted
      if (lISAC.current) {
        lISAC.current.abort();
      }
    };// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadInternalSprints]);

  const dayGap = viewEndMoment.diff(viewStartMoment, 'days');
  const sprintRowCount = sprintLoadResult.data.length || 1;

  const monthOptions = monthsSelect.map((month) => ({ id: month.value, name: month.label }));
  const yearOptions = projectSummaryYearOptions;

  const dayRemWidth = 6;

  return (
    <Container fluid className="gantt-view">
      <PageHeader
        title={title}
      />
      <Row id={name}>
        <Col md="12">
          <Card>
            <CardBody>
              <div className="view-controls">
                <h4>Internal Projects Gantt</h4>
                <YearMonthPicker
                  label="From:"
                  month={Number(viewStartMoment.format('M'))}
                  year={Number(viewStartMoment.format('Y'))}
                  monthOptions={monthOptions}
                  yearOptions={yearOptions}
                  // onChange={(field, value) => console.log('start', field, value)}
                />
                <YearMonthPicker
                  label="To:"
                  month={Number(viewEndMoment.format('M'))}
                  year={Number(viewEndMoment.format('Y'))}
                  monthOptions={monthOptions}
                  yearOptions={yearOptions}
                  // onChange={(field, value) => console.log('end', field, value)}
                />
                <CardText>
                  {'Days Covered: '}
                  {dayGap}
                  {' days.   From '}
                  {viewStartMoment.format('MMM Do')}
                  {' to '}
                  {viewEndMoment.format('MMM Do')}
                </CardText>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div className="gantt-scroll-container">
        <div className="gantt-chart" style={{ width: `${5 * dayGap}rem` }}>
          <GanttViewDayTitles
            startMoment={viewStartMoment.clone()}
            dayGap={dayGap}
            sprintRowCount={sprintRowCount}
            dayRemWidth={dayRemWidth}
          />
          {sprintLoadResult.data && sprintLoadResult.data.map((sprintRecord: ISprintRecord) => (
            <GanttViewSprintRow
              key={sprintRecord.id}
              viewStartMoment={viewStartMoment}
              dayRemWidth={dayRemWidth}
              sprintRecord={sprintRecord}
            />
          ))}
        </div>
      </div>
    </Container>
  );
};

