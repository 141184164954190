/* eslint-disable no-void */
/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Card, Button,
} from 'reactstrap';
import { ApiQueryDataLoader } from '../api-query-data-loader/api-query-data-loader';
import NewsArticle from './news-article';
import PageHeader from '../app-layout/page-header';
import { ScrollToTopOnMount } from '../router/scroll-to-top-on-mount';

import rollingSvg from '../../images/Rolling-1s-22px.svg';
import possibleActions from './possible-actions';
import { toParams } from '../../utils/helpers';
import { useHistory } from '../router/history';
import { connectToModalProvider } from '../modals/modal-context';
import { MODAL_PROVIDER_PROP_TYPES } from '../../prop-types/modal-provider-prop-types';
import API_PROVIDER_PROP_TYPES from '../../prop-types/api-provider-prop-types';
import { connectToAPIProvider } from '../providers/api-provider';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { NEWS_STATUS } from '../../constants/news-status.const';


/**
 * @class NewsArticlePage
 */
class NewsArticlePage extends React.Component {
  /**
   * @constructor
   */
  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false,
    };
    this.abortController = null;
  }


  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    if (this.abortController) this.abortController.abort();
  }

  /**
   * @description
   * Fired when the Confirm Modal's confirmation is accepted
   *
   * @param {{}} action
   */
  handleConfirmModal = async (action) => {
    const { history, forceRefreshData } = this.props;

    if (this.abortController) this.abortController.abort();
    this.abortController = apiAborter();

    this.setState(
      { isProcessing: true },
      async () => {
        const { apiProvider: { apiFetch } } = this.props;

        const response = await apiFetch(
          action.link,
          {
            method: action.method,
            name: `NewsArticlePage::ConfirmModal::${action.name}`,
            signal: this.abortController.signal,
          },
        );

        if (response.success) {
          this.abortController = null;
          if (action.name === 'delete') {
            useHistory.backOrPush(history, '/comms/news');
          } else {
            this.setState(
              forceRefreshData,
            );
          }
        } else if (!response.aborted) {
          console.error('TODO handle News Article Page apiFetch onFail()', response.error);
        }

        this.setState({ isProcessing: false });
      },
    );
  }


  /**
   * @description
   * Map Api Actions to action button definitions
   *
   * @param {{
   *  [actionName: string]: {
   *    label: string | 'Archive',
   *    link: string | 'http://localhost:8000/v1/news/281/action/archive',
   *    method: string | 'POST',
   *  }
   * }} apiActions
   * @param {{
   *  pathname: string,
   *  search: string,
   * }}
   *
   * @returns {{
   *  name: string,
   *  label: string,
   *  title: string,
   *  color: string,
   *  icon: string,
   *  description: string,
   *  successMessage: string,
   *  failMessage: string,
   *  onClick: () => {},
   *  method: string,
   *  link: string,
   * }[]}
   */
  mapApiActionsToButtons = (apiActions, location) => {
    const { history, article, modalProvider: { showModal } } = this.props;
    const { isProcessing } = this.state;
    const { status_id: statusId } = article;

    const locationParams = toParams(location.search);
    const articleReturnTo = ('art' in locationParams) ? locationParams.art : null;
    const urlParamString = articleReturnTo ? `?art=${encodeURIComponent(articleReturnTo)}` : '';

    const result = possibleActions
      .filter((action) => action.name in apiActions)
      .map((action) => {
        const mergedAction = {
          ...apiActions[action.name],
          ...action,
          disabled: isProcessing,
        };

        // Override the 'retract' label depending on the current status
        if (action.name === 'retract') {
          mergedAction.label = (statusId === NEWS_STATUS.SUBMITTED) ? 'Un-submit' : 'Un-publish';
        }

        // Override the secondaryAction property for 'update' if the article is published
        if (action.name === 'update' && statusId === NEWS_STATUS.PUBLISHED) {
          mergedAction.isSecondaryAction = true;
        }

        return ({
          ...mergedAction,
          onClick: () => {
            if (!article) return;
            switch (action.name) {
              case 'update':
                history.push(`/comms/news/${article.id}/edit${urlParamString}`);
                break;
              default:
                showModal(MODAL_TYPE.CONFIRM, {
                  label: mergedAction.title,
                  content: mergedAction.description,
                  confirmButtonLabel: mergedAction.label,
                  confirmButtonColor: mergedAction.color,
                  confirmButtonIcon: mergedAction.icon,
                  action,
                  onModalComplete: ({ processAction }) => {
                    if (processAction) this.handleConfirmModal(mergedAction);
                  },
                });
            }
          },
        });
      });

    return result;
  }

  render() {
    const {
      article, apiActions, isLoading, hasError, history, location,
    } = this.props;

    return (
      <Container fluid className="news-article-page">
        <PageHeader {...this.props} />
        <ScrollToTopOnMount />
        <Card>
          <div className="news-article-wrapper">
            {isLoading && (
              <div className="loading-indicator">
                <span>
                  <img src={rollingSvg} alt="Loading article..." />
                  <span>Loading article...</span>
                </span>
              </div>
            )}
            {!isLoading && hasError && (
              <div className="error-indicator">
                <span className="danger">Failed to load this news article. Try refreshing your browser.</span>
                <Button color="danger" onClick={() => history.push('/comms/news')}>Go back to news</Button>
              </div>
            )}
            {!isLoading && !hasError && (
              <NewsArticle
                article={article}
                actions={this.mapApiActionsToButtons(apiActions, location)}
                history={history}
                location={location}
              />
            )}
          </div>
        </Card>
      </Container>
    );
  }
}

NewsArticlePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  forceRefreshData: PropTypes.func.isRequired,
  article: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    teaser: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    status_id: PropTypes.number.isRequired,
    published_at: PropTypes.string,
    thumbnail_url: PropTypes.string.isRequired,
    author: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
  }),
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  apiActions: PropTypes.objectOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  })),
  modalProvider: PropTypes.shape(MODAL_PROVIDER_PROP_TYPES).isRequired,
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
};

NewsArticlePage.defaultProps = {
  article: null,
  apiActions: null,
};

export default function NewsArticlePageDataLoader(props) {
  const { match } = props;

  return (
    <ApiQueryDataLoader
      apiQueryUrl={`/news/${match.params.articleId}?with[]=author&with[]=category`}
      render={connectToAPIProvider(connectToModalProvider(({
        response, isLoading, hasError, forceRefreshData, modalProvider, apiProvider,
      }) => (
        <NewsArticlePage
          {...props}
          apiActions={response && response.actions ? response.actions : null}
          article={response && response.data ? response.data : null}
          isLoading={isLoading}
          hasError={hasError}
          forceRefreshData={forceRefreshData}
          modalProvider={modalProvider}
          apiProvider={apiProvider}
        />
      )))}
    />
  );
}

NewsArticlePageDataLoader.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      articleId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
