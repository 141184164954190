import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ResultsText } from '../../pagination/results-text';

/**
 * @class PortalDataTableResultsText
 *
 * @description
 * Displays results text for a Portal Data Table
 */
const PortalDataTableResultsText = (props) => {
  const {
    filteredColumns,
    searchTerm,
  } = props;

  return (
    <ResultsText
      {...props}
      isFiltered={!!searchTerm || (Array.isArray(filteredColumns) && filteredColumns.length > 0)}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const tableSettings = state.tableSettings[ownProps.tableIdentifier];
  return {
    totalRecords: tableSettings.totalRecords,
    isLoading: tableSettings.isLoading,
    hasError: tableSettings.hasError,
    pageSize: tableSettings.pageSize,
    activePage: tableSettings.activePage,
    searchTerm: tableSettings.searchTerm,
    filteredColumns: state.tableSettings[ownProps.tableIdentifier].filteredColumns,
  };
};

PortalDataTableResultsText.defaultProps = {
  searchTerm: '',
};

PortalDataTableResultsText.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  tableIdentifier: PropTypes.string.isRequired,

  // Redux state
  totalRecords: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  searchTerm: PropTypes.string,
  filteredColumns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    operation: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  })).isRequired,
};

export default connect(
  mapStateToProps,
)(PortalDataTableResultsText);
