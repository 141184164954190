import React from 'react';
import { CardTitle, CardSubtitle } from 'reactstrap';
import logo from '../../images/ci-logo-large.png';

/**
 * This component is used for the Login, Reset Password and Change Password components
 */
export const LoginPageHeader: React.FC<{ title: string; subTitle: string }> = ({
  title,
  subTitle,
}) => (
  <div className="portal-login-head-wrapper">
    <div className="portal-login-logo">
      <img alt="Corporate Initiatives" src={logo} />
    </div>
    <div className="portal-login-title-wrapper">
      <CardTitle className="portal-login-head" tag="h3">
        {title}
      </CardTitle>
      <CardSubtitle className="portal-login-head-sub" tag="h4">
        {subTitle}
      </CardSubtitle>
    </div>
  </div>
);
