import moment from 'moment';
import React from 'react';
import { INoteRecord } from '../../../types/note.record.interface';


export const IbrNotesSummary: React.FC<{ notes: INoteRecord[] }> = ({ notes }) => (
  <div>
    {notes && notes.length > 0 && (
      <h4 className="text-dark">Address Notes</h4>
    )}
    {notes && notes.map((note) => (
      <div key={note.id} className="p-2 text-dark">
        {/* eslint-disable-next-line react/no-danger */}
        <div id="tiny" className="body address-note-body" dangerouslySetInnerHTML={{ __html: note.body }} />
        <div className="by-line text-gray">
          <em>
            Added by
            {' '}
            <span className="user-name">
              {` ${note.created_by?.name} `}
            </span>
            <span className="time-to-event">
              {moment(note.user_audit.created_at).format('D/M/YY h:mm a')}
            </span>
          </em>
        </div>
      </div>
    ))}
  </div>
);
