import { getItemByKey, debugLog, compareVersion } from './helpers';
import { BUILD_VER } from './constants';
import { TABLE_IDENTIFIER } from '../constants/table-identifier.const';

/**
 * Save to local storage
 * @param {string} key key, eg 'userState'
 * @param {object} data object of user state to store
 */
export const localStorageSave = (key, data) => {
  if (!window.localStorage) return;

  try {
    const userSerializedState = JSON.stringify(data);
    window.localStorage.setItem(key, userSerializedState);
  }
  catch (err) {
    console.error(err);
  }
};


/**
 * Get from local storage
 */
export const localStorageLoad = (key) => {
  if (!window.localStorage) return null;

  try {
    const serializedState = window.localStorage.getItem(key);
    if (serializedState === null) {
      return null;
    }
    return JSON.parse(serializedState);
  }
  catch (err) {
    return null;
  }
};


/**
 * Delete a key from Local Storage
 * @param {string} key key, eg 'userState'
 */
export const localStorageDelete = (key) => {
  if (!window.localStorage) return;

  try {
    window.localStorage.removeItem(key);
  }
  catch (err) {
    console.error(err);
  }
};


/**
 * @description
 * Store some of the table state to local storage for persistence
 *
 * @param {string} tableIdentifier
 * @param {object} tableSettings
 */
export const saveTableStateToLocalStorage = (tableIdentifier, tableState) => {
  const localPrefs = localStorageLoad('localPrefs') || {};
  localPrefs.tableSettings = localPrefs.tableSettings || {};
  localPrefs.tableSettings[`${tableIdentifier}`] = {
    // TODO: table presets
    // TODO: flags
    orderedVisibleColumns: tableState.orderedVisibleColumns.map((column) => column.name),
    isTightModeEnabled: tableState.isTightModeEnabled,
    isTextWrappingEnabled: tableState.isTextWrappingEnabled,
    viewKey: tableState.viewKey,
    appVer: BUILD_VER,
  };

  localStorageSave('localPrefs', localPrefs);
};


/**
 * @description
 * Store some of the table state to local storage for persistence
 *
 * @note this should only really be used on application initialisation
 *
 * @param {string} tableIdentifier
 * @param {object} initialState
 *
 * @returns {object | null} null if not found in the local storage yet
 */
export const loadTableStateFromLocalStorage = (tableIdentifier, initialState) => {
  const localPrefs = localStorageLoad('localPrefs');
  if (localPrefs && localPrefs.tableSettings) {
    const newSettings = localPrefs.tableSettings[`${tableIdentifier}`];
    let resetSettings = false;

    // Were the local storage settings stored in an application version which should now be reset?
    if (newSettings && Array.isArray(initialState.resetForAppVersion)) {
      initialState.resetForAppVersion.forEach((invalidVersion) => {
        const versionComparison = compareVersion(newSettings.appVer, invalidVersion);
        if (
          // No previously stored appVer is in the local settings
          (!newSettings.appVer) ||

          // Previously stored appVer is less than or matches an invalid version
          (
            (versionComparison !== false) &&
            (versionComparison <= 0)
          )
        ) {
          resetSettings = true;
        }
      });
    }

    if (resetSettings) {
      debugLog('Reset Table settings', 'info', `Settings for ${tableIdentifier} were stored in an earlier invalid app version and must be reset.`);
      return null;
    }

    if (newSettings && newSettings.orderedVisibleColumns && !resetSettings) {
      // orderedVisibleColumns needs to be hydrated from the initialState.columns
      if (Array.isArray(initialState.columns)) {
        // Ensure all of the ordered visible columns exist in the table definition
        const checkedOrderedVisibleColumns = newSettings.orderedVisibleColumns.filter((columnName) => getItemByKey(initialState.columns, columnName, 'name'));

        let newOrder = 0;
        newSettings.columns = [
          // Iterate over the stored columns and update the column definition to reflect the stored column order and visibility
          ...checkedOrderedVisibleColumns.map((columnName) => {
            const column = {
              ...getItemByKey(initialState.columns, columnName, 'name'),
              order: newOrder,
              visible: true,
            };
            newOrder += 1;
            return column;
          }),
          // Iterate over the remaining columns that weren't stored in the local storage and bump them to the end (based on their existing sort order)
          ...initialState.columns
            .filter((column) => (!checkedOrderedVisibleColumns.includes(column.name)))
            .sort((a, b) => a.order - b.order)
            .map((column) => {
              const newColumn = {
                ...column,
                order: newOrder,
                visible: false,
              };
              newOrder += 1;
              return newColumn;
            }),
        ];
      }

      // The reducer will iterate over the new column definitions and populate orderedVisibleColumns in due course
      newSettings.orderedVisibleColumns = [];
    }
    return newSettings;
  }
  return null;
};


/**
 * @description
 * Loads a previously stored set of user settings for the project summary dashboard from local storage
 *
 * @param {{
 *  projectSummarySummaryFields:{
 *    key: string,
 *    visible: boolean,
 *  }[],
 *  projectSummaryMonthlyFields:{
 *    key: string,
 *    visible: boolean,
 *  }[],
 *  hideEmptyStates: boolean
 * }}
 *
 * @returns {object | null} null if not found in the local storage yet
 */
export const storeProjectSummaryDashboardSettingsToLocalStorage = ({ projectSummarySummaryFields, projectSummaryMonthlyFields, hideEmptyStates }) => {
  const projectSummarySettingsToStore = {
    projectSummarySummaryFields: projectSummarySummaryFields.map((field) => ({ key: field.key, visible: field.visible })),
    projectSummaryMonthlyFields: projectSummaryMonthlyFields.map((field) => ({ key: field.key, visible: field.visible })),
    hideEmptyStates,
  };

  localStorageSave('projectSummarySettings', projectSummarySettingsToStore);
};


/**
 * @description
 * Loads a previously stored set of user settings for the project summary dashboard from local storage
 *
 * @param {{
 *  projectSummarySummaryFields:{
 *    key: string,
 *    visible: boolean,
 *  }[],
 *  projectSummaryMonthlyFields:{
 *    key: string,
 *    visible: boolean,
 *  }[],
 *  hideEmptyStates: boolean
 * }}
 *
 * @returns {object | null} null if not found in the local storage yet
 */
export const loadProjectSummaryDashboardSettingsFromLocalStorage = ({
  projectSummarySummaryFields,
  projectSummaryMonthlyFields,
  hideEmptyStates,
}) => {
  const result = {
    projectSummarySummaryFields: [...projectSummarySummaryFields],
    projectSummaryMonthlyFields: [...projectSummaryMonthlyFields],
    hideEmptyStates,
  };

  const storedProjectSummarySettings = localStorageLoad('projectSummarySettings');
  if (storedProjectSummarySettings) {
    // Apply the stored settings for the project summary summary fields
    if (Array.isArray(storedProjectSummarySettings.projectSummarySummaryFields)) {
      result.projectSummarySummaryFields = storedProjectSummarySettings.projectSummarySummaryFields.reduce((acc, next) => {
        const field = acc.find((f) => f.key === next.key);
        if (field) {
          field.visible = next.visible;
        }
        return acc;
      }, result.projectSummarySummaryFields);
    }

    // Apply the stored settings for the project summary monthly fields
    if (Array.isArray(storedProjectSummarySettings.projectSummaryMonthlyFields)) {
      result.projectSummaryMonthlyFields = storedProjectSummarySettings.projectSummaryMonthlyFields.reduce((acc, next) => {
        const field = acc.find((f) => f.key === next.key);
        if (field) {
          field.visible = next.visible;
        }
        return acc;
      }, result.projectSummaryMonthlyFields);
    }

    // Apply the stored 'Hide Empty States' setting
    if ('hideEmptyStates' in storedProjectSummarySettings) {
      result.hideEmptyStates = !!storedProjectSummarySettings.hideEmptyStates;
    }
  }

  return result;
};


/**
 * @description
 * Loads a previously stored set of user settings for the sales forecast dashboard from local storage
 *
 * @param {{
 *  salesForecastSummaryFields:{
 *    key: string,
 *    visible: boolean,
 *  }[],
 *  salesForecastMonthlyFields:{
 *    key: string,
 *    visible: boolean,
 *  }[],
 *  hideEmptyStates: boolean
 * }}
 *
 * @returns {object | null} null if not found in the local storage yet
 */
export const storeSalesForecastDashboardSettingsToLocalStorage = ({
  salesForecastSummaryFields,
  salesForecastMonthlyFields,
  salesForecastDisplayStatuses,
  lowLikelihoodFilter,
  highLikelihoodFilter,
  hideEmptyStates,
  showProjectNames,
}) => {
  const salesForecastSettingsToStore = {
    salesForecastSummaryFields: salesForecastSummaryFields.map((field) => ({ key: field.key, visible: field.visible })),
    salesForecastMonthlyFields: salesForecastMonthlyFields.map((field) => ({ key: field.key, visible: field.visible })),
    salesForecastDisplayStatuses: salesForecastDisplayStatuses.map((field) => ({ key: field.key, visible: field.visible })),
    lowLikelihoodFilter,
    highLikelihoodFilter,
    hideEmptyStates,
    showProjectNames,
  };
  localStorageSave('salesForecastSettings', salesForecastSettingsToStore);
};


/**
 * @description
 * Loads a previously stored set of user settings for the sales forecast dashboard from local storage
 *
 * @param {{
 *  salesForecastSummaryFields:{
 *    key: string,
 *    visible: boolean,
 *  }[],
 *  salesForecastMonthlyFields:{
 *    key: string,
 *    visible: boolean,
 *  }[],
 *  hideEmptyStates: boolean
 * }}
 *
 * @returns {object | null} null if not found in the local storage yet
 */
export const loadSalesForecastDashboardSettingsFromLocalStorage = ({
  salesForecastSummaryFields,
  salesForecastMonthlyFields,
  salesForecastDisplayStatuses,
  lowLikelihoodFilter,
  highLikelihoodFilter,
  hideZeroValues,
  hideEmptyStates,
  showProjectNames,
}) => {
  const result = {
    salesForecastSummaryFields: [...salesForecastSummaryFields],
    salesForecastMonthlyFields: [...salesForecastMonthlyFields],
    salesForecastDisplayStatuses: [...salesForecastDisplayStatuses],
    lowLikelihoodFilter,
    highLikelihoodFilter,
    hideZeroValues,
    hideEmptyStates,
    showProjectNames,
  };

  const storedSalesForecastSettings = localStorageLoad('salesForecastSettings');
  if (storedSalesForecastSettings) {
    // Apply the stored settings for the sales forecast summary fields
    if (Array.isArray(storedSalesForecastSettings.salesForecastSummaryFields)) {
      result.salesForecastSummaryFields = storedSalesForecastSettings.salesForecastSummaryFields.reduce((acc, next) => {
        const field = acc.find((f) => f.key === next.key);
        if (field) {
          field.visible = next.visible;
        }
        return acc;
      }, result.salesForecastSummaryFields);
    }

    // Apply the stored settings for the sales forecast monthly fields
    if (Array.isArray(storedSalesForecastSettings.salesForecastMonthlyFields)) {
      result.salesForecastMonthlyFields = storedSalesForecastSettings.salesForecastMonthlyFields.reduce((acc, next) => {
        const field = acc.find((f) => f.key === next.key);
        if (field) {
          field.visible = next.visible;
        }
        return acc;
      }, result.salesForecastMonthlyFields);
    }

    // Apply the stored settings for the sales forecast monthly fields
    if (Array.isArray(storedSalesForecastSettings.salesForecastDisplayStatuses)) {
      result.salesForecastDisplayStatuses = storedSalesForecastSettings.salesForecastDisplayStatuses.reduce((acc, next) => {
        const field = acc.find((f) => f.key === next.key);
        if (field) {
          field.visible = next.visible;
        }
        return acc;
      }, result.salesForecastDisplayStatuses);
    }

    // Apply the stored 'Hide Empty States' setting
    if ('hideEmptyStates' in storedSalesForecastSettings) {
      result.hideEmptyStates = !!storedSalesForecastSettings.hideEmptyStates;
    }

    // Apply the stored 'Hide Zero Values' setting
    if ('hideZeroValues' in storedSalesForecastSettings) {
      result.hideZeroValues = !!storedSalesForecastSettings.hideZeroValues;
    }

    // Apply the stored 'Hide Zero Values' setting
    if ('showProjectNames' in storedSalesForecastSettings) {
      result.showProjectNames = !!storedSalesForecastSettings.showProjectNames;
    }

    // Apply the stored 'low forcast filter' setting
    if ('lowLikelihoodFilter' in storedSalesForecastSettings) {
      result.lowLikelihoodFilter = storedSalesForecastSettings.lowLikelihoodFilter;
    }

    // Apply the stored 'high forecast filter' setting
    if ('highLikelihoodFilter' in storedSalesForecastSettings) {
      result.highLikelihoodFilter = storedSalesForecastSettings.highLikelihoodFilter;
    }
  }

  return result;
};


// @deprecated

/**
 * Defaults for local prefs
 */
const defaultLocalPrefs = {
  // Column manager
  tablePresets: {
    // example
    [TABLE_IDENTIFIER.PROJECT_TABLE]: [],
  },
  // App nav
  isSidebarVisible: true,
};

/**
 * Get local preferences from local storage
 * @returns {object} json of stored `'localPrefs'` or `defaultLocalPrefs`
 */
export const loadLocalPrefs = () => {
  const localPrefs = localStorageLoad('localPrefs');
  if (localPrefs === null) {
    return defaultLocalPrefs;
  }
  return localPrefs;
};

/**
 * Save local preferences to local storage
 * @param {object} localPrefs object of user state to store
 */
export const saveLocalPrefs = (localPrefs) => localStorageSave('localPrefs', localPrefs);

/**
 * Save local preferences to local storage
 * @param {string} key
 * @param {*} value object of user state to store
 */
export const saveLocalPref = (key, value) => {
  const currentPrefs = loadLocalPrefs();
  saveLocalPrefs({
    ...currentPrefs,
    [key]: value,
  });
};
