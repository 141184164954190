import { NodeModel } from '@minoru/react-dnd-treeview';
import { IPortalTreeViewNodeData } from '../../types/portal-tree-view/portal-tree-view-node-data.interface';
import { A_PORTAL_TREE_VIEW_TREE_LINE_TYPE, PORTAL_TREE_VIEW_TREE_LINE_TYPE } from './portal-tree-view-tree-line-type.const';

/**
 * Used by the Portal Tree View to iterate over treeData
 * and update each node's understanding about child nodes,
 * indexes and depth
 *
 * TODO: this had to be broken out into multiple steps for debug. Come back and improve the efficiency of this
 */
export const calculatePortalTreeViewNodeData = <T extends IPortalTreeViewNodeData>(treeData: NodeModel<T>[], isRootNodeVisible: boolean): NodeModel<T>[] => {
  let maxDepth = 0;

  // Pad out and set the defaults for each node
  let newTreeData: NodeModel<T>[] = treeData.map((node) => ({
    ...node,
    data: node.data ? {
      ...node.data,
      siblingCount: 1,
      childCount: 0,
      displayIndex: 0,
      depth: 0,
      treeLineDisplayFlags: [] as Array<1 | 0>,
    } : undefined,
  }));

  // Sort the data by name. This is actually really important so that the tree lines render in the right order.
  // TODO: at some point introduce custom sorting into the tree
  newTreeData.sort((a, b) => (a.text > b.text ? 1 : -1));

  // Iterate over all nodes and update parent nodes as identified
  const countedTreeData: NodeModel<T>[] = [
    ...newTreeData,
  ];
  countedTreeData.forEach((node) => {
    // Find the parent node
    const parentNode = countedTreeData.find((n) => n.id === node.parent);
    if (parentNode && parentNode.data) {
      // Increment the childCount of the parent to account for this node
      parentNode.data.childCount = (parentNode.data?.childCount ?? 0) + 1;

      if (node.data) {
        // Use the parent's updated child count as the display index for that set of siblings
        node.data.displayIndex = parentNode.data.childCount - 1;
      }
    }
  });
  newTreeData = [...countedTreeData];

  // Calculate the depth and sibling details of each node
  newTreeData = newTreeData.map((node) => {
    // Wrapper around array.find
    const findParentNode = (
      targetNode: NodeModel<T>,
    ): undefined | NodeModel<T> => newTreeData.find(
      (parentNode) => ((targetNode.parent === parentNode.id) && (targetNode.id !== targetNode.parent)),
    ) ?? undefined;

    let depth = 0;
    let siblingCount = 1;

    // Find the immediate parent of the node
    let parentNode = findParentNode(node);

    if (parentNode) {
      // Keep track of how many siblings this node has
      siblingCount = parentNode.data?.childCount ?? siblingCount;

      // iterate over the parents until there are no more.
      while (parentNode && depth < 50) {
        depth += 1;
        parentNode = findParentNode(parentNode);
      }
    }


    // Update the node
    if (node.data) {
      node.data.depth = depth;
      node.data.siblingCount = siblingCount;
      node.data.isFirstSibling = (node.data.displayIndex ?? 0) === 0;
      node.data.isLastSibling = (node.data.displayIndex ?? 0) === siblingCount - 1;

      // Check the maximum depth of the tree data
      if (depth > maxDepth) {
        maxDepth = depth;
      }
    }

    return node;
  });

  // Calculate which treeLineDisplayFlags are required based on sibling counts
  for (let depth = 0; depth <= maxDepth; depth += 1) {
    newTreeData
      // Filter for the current depth
      .filter((node) => ((node.data?.depth ?? 0) === depth))

      // Iterate over the nodes in the current depth
      .forEach((node) => {
        if (node.data) {
          // Don't calculate any tree line display flags for the root node
          if (node.id === 'ROOT') return;

          // Don't calculate any tree line display flags for a node that is a child of the root node when the root node is hidden
          const isDirectChildOfRootNode = node.parent === 'ROOT';
          if (!isRootNodeVisible && isDirectChildOfRootNode) {
            return;
          }

          // Cache some values for simplification
          const siblingCount = node.data.siblingCount ?? 1;
          const isLastSibling = node.data.isLastSibling ?? true;
          const hasSiblings = siblingCount > 1;

          let parentIsLastSibling = true;
          let parentNodeTreeLineDisplayFlags: A_PORTAL_TREE_VIEW_TREE_LINE_TYPE[] = [];
          const parentIsTopLevelNode = ((depth === 1 && isRootNodeVisible) || (depth === 2 && !isRootNodeVisible));

          // Find the Parent Node
          const parentNode = newTreeData.find((n) => n.id === node.parent);
          if (parentNode && parentNode.data) {
            // capture whether the parent is the last sibling in its tier
            parentIsLastSibling = (parentNode.data.isLastSibling ?? parentIsLastSibling);

            // Determine if the parent nodes has tree lines of its own
            parentNodeTreeLineDisplayFlags = [...(parentNode.data.treeLineDisplayFlags ?? [])];
          }

          // Calculate the type of tree line display flag this node should have
          let treeLineDisplayFlag: A_PORTAL_TREE_VIEW_TREE_LINE_TYPE = PORTAL_TREE_VIEW_TREE_LINE_TYPE.NONE;


          // The last sibling gets an END type "└"
          if (isLastSibling) {
            treeLineDisplayFlag = PORTAL_TREE_VIEW_TREE_LINE_TYPE.END;

            // In some rare cases when this should be an end node, the parent may need this tree line to be a
            // cross type if the root node is hidden
            if (!isRootNodeVisible && parentIsTopLevelNode && !parentIsLastSibling) {
              treeLineDisplayFlag = PORTAL_TREE_VIEW_TREE_LINE_TYPE.CROSS;
            }
          }

          // Other nodes with siblings get the cross type "├"
          else if (hasSiblings) {
            treeLineDisplayFlag = PORTAL_TREE_VIEW_TREE_LINE_TYPE.CROSS;
          }

          // After all that should this display flag even be visible?
          // Kill the display flag for depth === 1 nodes
          if (!isRootNodeVisible && (depth === 1)) {
            treeLineDisplayFlag = PORTAL_TREE_VIEW_TREE_LINE_TYPE.NONE;
          }

          // Add the new display flag
          let newTreeLineDisplayFlags: A_PORTAL_TREE_VIEW_TREE_LINE_TYPE[] = [treeLineDisplayFlag];

          // Concatenate the parent node's tree line display flags in front of this node's display flag.
          if (parentNodeTreeLineDisplayFlags.length) {
            // tweak the display flags
            parentNodeTreeLineDisplayFlags = [...parentNodeTreeLineDisplayFlags
              // replace any "end" flags with "none" for parent lines that are beyond the top level
              .map((flag) => ((!parentIsTopLevelNode && (flag === PORTAL_TREE_VIEW_TREE_LINE_TYPE.END)) ? PORTAL_TREE_VIEW_TREE_LINE_TYPE.NONE : flag))

              // replace any "end" flags with "none" for parent lines that are beyond the top level
              .map((flag) => ((parentIsTopLevelNode && (flag === PORTAL_TREE_VIEW_TREE_LINE_TYPE.END)) ? PORTAL_TREE_VIEW_TREE_LINE_TYPE.EXTEND : flag))

              // replace any "cross" flags with "extend"
              .map((flag) => ((flag === PORTAL_TREE_VIEW_TREE_LINE_TYPE.CROSS) ? PORTAL_TREE_VIEW_TREE_LINE_TYPE.EXTEND : flag)),
            ];

            // Add the parent flags to the node's flags
            newTreeLineDisplayFlags = [
              ...newTreeLineDisplayFlags,
              ...parentNodeTreeLineDisplayFlags,
            ];
          }

          // Update the node with the calculated data
          node.data.treeLineDisplayFlags = newTreeLineDisplayFlags;
        }
      });
  }

  return newTreeData;
};
