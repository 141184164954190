import React, { SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { Button, Col, Row } from 'reactstrap';
import { APIContext } from '../../../providers/api-provider';
import { IProjectRecord } from '../../../../types/project/project.record.interface';
import { apiAborter } from '../../../../helpers/api-aborter.helper';
import { PortalAPIResponse } from '../../../../helpers/portal-api-response';
import { formatValueString } from '../../../render-functions';
import { COLUMN_FORMAT } from '../../../../constants/column-format.const';
import Icon from '../../../layout-helpers/icon';


interface IActivationDtoolsLinkWidgetProps {
  project: IProjectRecord | null,
  onSelectDtoolsProject: (dtoolsId: string | null) => void,
  setAllowManualInput: (value: boolean) => void,
  selectedDtoolsId: string | null,
}

/**
 * D-Tools Match Record Interface
 *
 * note that the non-compliant capitalisation of variables is from the D-Tools API
 *
 * @todo: globalise this
 * */
interface IDtoolsMatchRecord {
  Id: string,
  Number: string,
  Client: string,
  Name: string,
  CheckOutUserName: string,
  Price: number,
  TotalPrice: number,
  Progress: string,
  Approved: false,
  MatchType: string,
  MatchOrder: number
}

const ActivationDtoolsLinkWidget: React.FC<IActivationDtoolsLinkWidgetProps> = (props) => {
  const { apiFetch } = useContext(APIContext);

  const abortLoadMatchesRequest = useRef<AbortController | null>(null);

  const { onSelectDtoolsProject, project, selectedDtoolsId, setAllowManualInput } = props;

  const [dtoolsMatchesLoading, setDtoolsMatchesLoading] = useState<boolean>(false);
  const [dtoolsMatches, setDtoolsMatches] = useState<IDtoolsMatchRecord[] | null>(null);


  /**
   * Load D-Tools Matches
   */
  const loadDtoolsMatches = useCallback((projectId) => {
    if (abortLoadMatchesRequest.current) {
      abortLoadMatchesRequest.current.abort();
    }
    abortLoadMatchesRequest.current = apiAborter();

    setDtoolsMatchesLoading(true);

    apiFetch(
      `/project/${projectId}/dtoolsmatch`,
      {
        name: 'ProjectActivationModal: DtoolsLinkCheck',
        method: HTTP_METHOD.GET,
        signal: abortLoadMatchesRequest.current.signal,
      },
    ).then((response: PortalAPIResponse) => {
      if (response.success) {
        let matches = response.body?.data || [];
        matches = matches.sort((a: IDtoolsMatchRecord, b: IDtoolsMatchRecord) => {
          if (a.Progress < b.Progress) {
            return -1;
          }
          if (a.Progress > b.Progress) {
            return 1;
          }
          return 0;
        });
        setDtoolsMatches(matches);
        if (matches.length === 0) {
          setAllowManualInput(true);
        }
        setDtoolsMatchesLoading(false);
      } else if (!response.aborted) {
        setDtoolsMatchesLoading(false);
      }
    });
  }, [apiFetch, setAllowManualInput]);

  useEffect(() => {
    if (dtoolsMatchesLoading === false && dtoolsMatches === null) {
      loadDtoolsMatches(project?.id);
    }
  }, [loadDtoolsMatches, dtoolsMatchesLoading, dtoolsMatches, project?.id]);

  // cleanup function only
  useEffect(() => () => {
    if (abortLoadMatchesRequest.current) {
      abortLoadMatchesRequest.current.abort();
    }
  }, []);

  const handleSelectClick = (e: SyntheticEvent, dtoolsId: string) => {
    e.stopPropagation();
    e.preventDefault();
    onSelectDtoolsProject(dtoolsId);
    setAllowManualInput(false);
  };

  const handleAllowManualEntryClick = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onSelectDtoolsProject(null);
    setAllowManualInput(true);
  };

  return (
    <div>

      <Row style={{ marginBottom: '1rem' }}>
        <Col>
          <h4>Project Matches from D-Tools:</h4>
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <Button onClick={handleAllowManualEntryClick} size="sm">Enter Details Manually</Button>
        </Col>
      </Row>

      {dtoolsMatchesLoading && (
        <div><Icon i="rolling" /></div>
      )}
      {dtoolsMatches?.map((match: IDtoolsMatchRecord) => (
        <div
          key={match.Id}
          style={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: match.Id === selectedDtoolsId ? 'green' : 'silver',
            padding: '1rem',
            // borderRadius: '5px',
          }}
        >
          <h5>
            {match.Number}
            {' '}
            {match.Client}
            {' '}
            -
            {' '}
            {match.Name}
          </h5>
          <Row>
            <Col md="4" sm="3">
              <strong>Price:</strong>
            </Col>
            <Col md="8" sm="9">
              {' '}
              {formatValueString(match.Price, COLUMN_FORMAT.CURRENCY)}
            </Col>
          </Row>
          <Row>
            <Col md="4" sm="3">
              <strong>D-Tools Status:</strong>
            </Col>
            <Col md="8" sm="9">
              {match.Progress}
            </Col>
          </Row>
          <Row>
            <Col md="4" sm="3">
              <strong>D-Tools Approved:</strong>
            </Col>
            <Col md="8" sm="9">
              {match.Approved ? (<span>Yes</span>) : (<span>No</span>)}
            </Col>
          </Row>
          <Row>
            <Col md="4" sm="3">
              <strong>Match Type:</strong>
            </Col>
            <Col md="8" sm="9">
              {match.MatchType}
            </Col>
          </Row>
          {/* <p>{match.MatchOrder}</p> */}
          <Row>
            <Col style={{ textAlign: 'right' }}>
              <Button
                color={match.Id === selectedDtoolsId ? 'success' : 'primary'}
                onClick={(e: SyntheticEvent) => handleSelectClick(e, match.Id)}
                disabled={match.Id === selectedDtoolsId}
              >
                {match.Id === selectedDtoolsId ? 'Selected' : 'Select'}
              </Button>
            </Col>
          </Row>
        </div>
      ))}

    </div>
  );
};

export default ActivationDtoolsLinkWidget;
