import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SalesForecastTableCell from './sales-forecast-table-cell';
import { formatAlignClass, formatValueString } from '../../render-functions';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';


/**
 * @description
 * Render out a data row of one side of the project summary table
 */
const SalesForecastTableCellGroup = (props) => {
  const {
    className, rowKey, fields, data, children, span, onRenderFieldData, onGetFieldColSpan, hideZeroValues,
  } = props;

  const visibleFieldCount = (fields && fields.filter((field) => field.visible).length) || 0;

  const defaultRenderFieldData = (fieldDefinition, fieldData) => {
    if (hideZeroValues && fieldData[fieldDefinition.key] === 0) return ' . ';
    // console.log(hideZeroValues, fieldData);
    return formatValueString(fieldData[fieldDefinition.key], fieldDefinition.format);
  };
  const renderFieldData = (typeof onRenderFieldData === 'function')
    ? (fieldDefinition, fieldData) => onRenderFieldData(fieldDefinition, fieldData, defaultRenderFieldData(fieldDefinition, fieldData))
    : defaultRenderFieldData;

  const getFieldColSpan = (typeof onGetFieldColSpan === 'function')
    ? (fieldDefinition, fieldData) => onGetFieldColSpan(fieldDefinition, fieldData)
    : () => 1;

  return (
    <>
      {/* Render out a list of fields */}
      {fields && fields.filter((field) => field.visible).map((field, index) => {
        const fieldColSpan = getFieldColSpan(field, data);
        if (fieldColSpan) { return (
          <SalesForecastTableCell
            key={`${rowKey}_${field.key}`}
            span={fieldColSpan}
            className={classNames(
              className,
              formatAlignClass(field.format, true),
              {
                'cell-border-right': index === (visibleFieldCount - 1),
                currency: field.format === COLUMN_FORMAT.CURRENCY,
              },
            )}
            field={field}
          >
            {renderFieldData(field, data)}
          </SalesForecastTableCell>
        ); }

        return null;
      }).filter((field) => field !== null)}

      {/* No fields provided, Render out a single wide span cell */}
      {(!fields || fields.length === 0) && (
        <SalesForecastTableCell
          className={classNames(
            className,
            'cell-border-right',
          )}
          span={span}
        >
          {children}
        </SalesForecastTableCell>
      )}
    </>
  );
};

SalesForecastTableCellGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    format: PropTypes.oneOf(Object.values(COLUMN_FORMAT)),
  })),
  data: PropTypes.shape({
    source: PropTypes.shape({
      params: PropTypes.shape({}).isRequired,
      path: PropTypes.string.isRequired,
    }),
  }),
  span: PropTypes.number,
  onRenderFieldData: PropTypes.func,
  onGetFieldColSpan: PropTypes.func,
  hideZeroValues: PropTypes.bool,
};

SalesForecastTableCellGroup.defaultProps = {
  className: null,
  children: null,
  fields: null,
  rowKey: null,
  data: null,
  span: 1,
  onRenderFieldData: null,
  onGetFieldColSpan: null,
  hideZeroValues: true,
};

export default SalesForecastTableCellGroup;
