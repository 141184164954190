import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';
import { EXPENSE_CLAIM_BATCH_STATUS_MAP } from '../constants/expense-claim-batch-status.const';

const qbAppEndpoint = process.env.REACT_APP_QUICKBOOKS_APP_ENDPOINT;
const qbBillPath = `${qbAppEndpoint}app/bill?txnId=`;
const qbSupplierPath = `${qbAppEndpoint}app/vendordetail?nameId=`;

const myobCompanyId = process.env.REACT_APP_MYOB_COMPANY_ID;
const myobLinkPath = `https://ci.myobadvanced.com/Main?CompanyID=${myobCompanyId}&ScreenId=AP301000&DocType=INV&RefNbr=`;
/**
 * Table Definition for My Expenses page
 */
const processExpenseBatchesTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'Expense Claim Batch',
  baseRoute: '/expenseclaimbatch',
  // Where status != Deleted
  baseQueryString: [
    'with[]=user:id,name,quickbooks_supplier_id',
    'with[]=status:id,name',
    'with_count[]=expenseClaims',
  ].join('&'),

  columnPresets: [
    {
      name: 'default',
      title: 'Default',
      fields: [
        'id',
        'user',
        'status',
        'count_of_expenseClaims',
        'total_amount',
        'quickbooks_bill_id',
        'myob_bill_id',
        'user_audit_processed_at',
        'user_audit_paid_at',
      ],
    },
    {
      name: 'all',
      title: 'All',
      fields: [
        'id',
        'user',
        'status',
        'count_of_expenseClaims',
        'total_amount',
        'user_supplier',
        'quickbooks_bill_id',
        'user_audit_processed_at',
        'user_audit_paid_at',
      ],
    },
  ],

  /**
   * Definition of Columns for this table
   */
  columns: [
    {
      name: 'id',
      title: 'ID',
      description: 'Expense Claim Batch Unique Identifier',
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      format: COLUMN_FORMAT.ID_LINK_SHORT,
      linkId: 'id',
      linkRoute: '/finance/process-expenses/batches',
      visible: true,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'user',
      title: 'Employee',
      description: 'Person claiming expense',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkRoute: '/settings/user-management',
        linkId: 'id',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      formSaveField: 'user_id',
      visible: true,
      showInForm: true,
      order: 10,
      formOrder: 10,
    },
    {
      name: 'status',
      title: 'Status',
      description: 'Expense batch status - use actions to change',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'status',
        key: 'name',
        format: COLUMN_FORMAT.STATUS_BADGE,
        listSource: 'batchStatus',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      formSaveField: 'status_id',
      visible: true,
      showInForm: true,
      order: 20,
      formOrder: 20,
    },
    {
      name: 'count_of_expenseClaims',
      title: 'Claims',
      description: 'Number of expense claims attached to this batch',
      format: COLUMN_FORMAT.NUMBER_SHORT,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 30,
      formOrder: 30,
    },
    {
      name: 'total_amount',
      title: 'Total Amount',
      description: 'Batch total amount',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 40,
      formOrder: 40,
    },
    {
      name: 'user_supplier',
      title: 'Employee QB',
      description: 'Person claiming expense in Quickbooks as a Supplier',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user',
        key: 'quickbooks_supplier_id',
        format: COLUMN_FORMAT.BUTTON_LINK,
        linkId: 'quickbooks_supplier_id',
        externalLink: (row) => `${qbSupplierPath}${row.quickbooks_supplier_id}`,
        buttonLabel: 'View Supplier',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      formSaveField: 'quickbooks_supplier_id',
      visible: false,
      showInForm: true,
      order: 45,
      formOrder: 45,
    },
    {
      name: 'quickbooks_bill_id',
      title: 'QB Bill',
      description: 'Bill in QuickBooks',
      format: COLUMN_FORMAT.BUTTON_LINK,
      linkId: 'quickbooks_bill_id',
      externalLink: (row) => `${qbBillPath}${row.quickbooks_bill_id}`,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      buttonLabel: 'View Bill',
      visible: true,
      order: 48,
      formOrder: 48,
    },
    {
      name: 'myob_bill_id',
      title: 'MYOB Bill',
      description: 'Bill in MYOB',
      format: COLUMN_FORMAT.BUTTON_LINK,
      linkId: 'myob_bill_id',
      externalLink: (row) => `${myobLinkPath}${row.myob_bill_id}`,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      buttonLabel: 'View Bill',
      visible: true,
      order: 48,
      formOrder: 48,
    },
    {
      name: 'user_audit_processed_at',
      title: 'Processed',
      format: COLUMN_FORMAT.OBJECT,
      sortColumn: 'processed_at',
      object: {
        sourceField: 'user_audit',
        key: 'processed_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 50,
      formOrder: 50,
    },
    {
      name: 'user_audit_paid_at',
      title: 'Paid',
      format: COLUMN_FORMAT.OBJECT,
      sortColumn: 'paid_at',
      object: {
        sourceField: 'user_audit',
        key: 'paid_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 70,
      formOrder: 60,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Expense Batch Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'expenseclaims',
      title: 'Expense Claims',
      description: 'Expense Claims in this Batch',
      widgetDefinition: WIDGET_DEFINITION.EXPENSE_BATCH_EXPENSE_CLAIMS,
      apiRoute: '/expenseclaim',
      apiQuery: 'with[]=user:id,name&with[]=status:id,name&pagelength=100',
      // apiRoute: parentId =>
      //   [
      //     `/expenseclaim/?filter[0][field]=expense_claim_batch_id`,
      //     `filter[0][operation]=${API_FILTER_OPERATION.EQUALS}`,
      //     `filter[0][value]=${parentId}`,
      //     `with[]=user:id,name`,
      //     `pagelength=100`,
      //   ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'count_of_expenseClaims',
        },
      ],
    },
    {
      name: 'items',
      title: 'ITEMS',
      description: 'Expense Batch ITEMS',
      widgetDefinition: WIDGET_DEFINITION.EXPENSE_BATCH_ITEM_SUMMARY,
      apiRoute: (rowData) => {
        const apiQuery = [
          'with[]=user:id,name',
          'with[]=approver:id,name',
          'with[]=status:id,name',
          'with[]=items',
          'with[]=items.project:id,status_id,project_number,quickbooks_subcustomer_id,myob_project_id',
          'with[]=items.serviceJob:id,status_id,number,quickbooks_id', // ,myob_id
          'with[]=items.contract:id,status_id,number,quickbooks_id', // ,myob_id
          'with[]=items.category',
          'with[]=items.receiptFile',
          'with_count[]=items',
          'with_count[]=notes',
          'with_count[]=files',
          'filter[0][field]=expense_claim_batch_id',
          'filter[0][operation]=equals',
          `filter[0][value]=${rowData.id}`,
          'pagelength=1000',
        ].join('&');
        return `/expenseclaim?${apiQuery}`;
      },
    },
    {
      ...eventsTabDefault,
      description: 'Expense Batch Events',
      statusMap: EXPENSE_CLAIM_BATCH_STATUS_MAP,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'New Expense Batch',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update',
      successMessage: 'Updated',
      failMessage: 'Failed to update',
      hideRecordActionButton: true,
    },
    {
      name: 'process',
      title: 'Process',
      color: BUTTON_COLOR.PURPLE,
      icon: ICON.UPLOAD,
      description:
        'This will confirm that all expense claims in this batch have been processed into the appropriate accounting software',
      successMessage: 'Expense batch marked as Processed',
      failMessage: 'Failed to mark expense batch as Processed',
    },
    {
      name: 'pay',
      title: 'Pay',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.CHECK,
      description: 'This will mark the expense batch as Paid',
      successMessage: 'Expense batch marked as Paid',
      failMessage: 'Failed to mark expense batch as Paid',
    },
    {
      name: 'unprocess',
      title: 'Un-process',
      color: BUTTON_COLOR.WARNING,
      icon: ICON.UNPROCESS,
      description: 'This will un-process the expense batch',
      successMessage: 'Expense batch un-processed',
      failMessage: 'Failed to un-process expense batch',
    },
    // Reject an expense batch?
    // {
    //   name: 'reject',
    //   title: 'Reject',
    //   color: BUTTON_COLOR.DANGER,
    //   icon: 'times',
    //   description: 'This will REJECT the expense batch',
    //   successMessage: 'Expense batch rejected',
    //   failMessage: 'Failed to reject expense batch',
    // },
    {
      name: 'unpay',
      title: 'Un-pay',
      color: BUTTON_COLOR.WARNING,
      icon: ICON.UNPAY,
      description: 'This will un-pay the expense batch',
      successMessage: 'Expense batch successfully un-paid',
      failMessage: 'Failed to un-pay expense batch',
    },
    // Needs `expense_claim_ids` to work
    // {
    //   name: 'unbatch',
    //   title: 'Un-batch',
    //   color: BUTTON_COLOR.WARNING,
    //   icon: 'undo',
    //   description: 'This will un-pay the expense batch',
    //   successMessage: 'Expense batch successfully un-paid',
    //   failMessage: 'Failed to un-pay expense batch',
    // },
    {
      name: 'push-to-quickbooks',
      title: 'Push to Quickbooks',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.CLOCK,
      description: 'This will push the expense bill to QuickBooks',
      successMessage: 'QuickBooks Bill Created',
      failMessage: 'Failed to create QuickBooks Bill',
    },
    {
      name: 'push-to-myob',
      title: 'Push to MYOB',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.CLOCK,
      description: 'This will push the expense bill to MYOB',
      successMessage: 'MYOB Bill Created',
      failMessage: 'Unable to upload Bill to MYOB',
    },
    {
      name: 'delete-from-quickbooks',
      title: 'Delete from Quickbooks',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.CLOCK,
      description: 'This will delete the expense bill from QuickBooks',
      successMessage: 'QuickBooks Bill Deleted',
      failMessage: 'Failed to delete QuickBooks Bill',
    },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will un-pay the expense batch',
      successMessage: 'Expense batch successfully un-paid',
      failMessage: 'Failed to un-pay expense batch',
      hideRecordActionButton: true,
    },
  ],

  /**
   * View definitions
   */
  viewKey: 'pay-run',
  views: [
    {
      key: 'pay-run',
      title: 'For Current Pay Run',
      filters: [
        {
          field: 'status.name',
          operation: 'in',
          values: ['draft', 'processed'],
        },
      ],
      flags: [],
    },
    {
      key: 'draft',
      title: 'Draft / Unprocessed',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['draft'],
        },
      ],
      flags: [],
    },
    {
      key: 'processed',
      title: 'Processed Batches',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['processed'],
        },
      ],
      flags: [],
    },
    {
      key: 'paid',
      title: 'Paid Batches',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['paid'],
        },
      ],
      flags: [],
    },
    {
      key: 'all',
      title: 'All Batches',
      filters: [],
      flags: [],
    },
  ],
};

export default processExpenseBatchesTableInitialSettings;
