import React from 'react';
import { NumericInput, NumericInputProps } from './numeric-input';

export type CurrencyInputProps = NumericInputProps;

export const CurrencyInput: React.FC<CurrencyInputProps> = (props) => {
  const {
    value,
    prefix,
    allowNegative,
  } = props;

  return (
    <NumericInput
      {...props}
      value={(value ?? '').toString()}
      prefix={prefix ?? '$'}
      allowNegative={allowNegative ?? false}
    />
  );
};
