import React, { useEffect, useState } from 'react';
import { CardBody, CardText, CardTitle } from 'reactstrap';
import { Draggable } from 'react-beautiful-dnd';
import moment from 'moment';
import { Editor } from '@tinymce/tinymce-react';
import { ProgressPercentInput } from '../form-input/progress-percent-input';
import { getFieldDisplayComponent } from '../render-functions';
import { COLUMN_FORMAT } from '../../constants/column-format.const';
import { ICardItem } from '../../types/cardview/card-item.interface';
import { TINYMCE_KEY } from '../../utils/constants';
import { AbbreviatedExternalLink } from '../data-format/abbreviated-external-link';
import StatusBadge from '../data-format/status-badge';
import { FormRendererProps } from '../../types/poly-form/form-renderer.props';
import { usePreviousValue } from '../../react-hooks/use-previous-value.hook';

type SwimlaneCardProps = Omit<FormRendererProps,
'primaryKeyFieldName' |
'primaryKeyValue' |
'formData' |
'fields' |
'isReadOnly' |
'isLocked' |
'formDeleteConfirmationType' |
'permittedActions'
> & {
  cardItem: ICardItem,
  index: number,
  countFrom: number,
  idLinkPath?: string,
  save: (newFormData: ICardItem) => void,
  formButtons?: JSX.Element,
  feedbackPanel?: JSX.Element,
}

/**
 * Swimlane Card Component
 *
 * @todo fix this
 *
 * [ ] somehow
 *
 * @param {SwimlaneCardProps}    props
 * @param {ICardItem}             props.cardItem    the card item to render
 * @param {number}                props.index       iterative index for JSX & Draggable
 * @param {number}                countFrom         eg. if priority, count from this number
 * @param {string|undefined}      props.idLinkPath  path to use for linking to details
 */
export const SwimlaneCard: React.FC<SwimlaneCardProps> = ({
  cardItem, index, countFrom, idLinkPath, onFieldChange, save, isEditing, formButtons, feedbackPanel, isBusy,
}) => {
  const [localItemState, setLocalItemState] = useState<ICardItem>(cardItem);
  const [editorText, setEditorText] = useState<string>(`${cardItem.description}`);

  const assignee = cardItem.assigned_to || cardItem.project_lead || undefined;
  const assigneeLabel = cardItem.assigned_to ? 'Assigned To:' : 'Project Lead:';

  const wasMoved = usePreviousValue(cardItem.hasMoved);

  useEffect(() => {
    setLocalItemState(cardItem);
    if (cardItem.description !== editorText) {
      setEditorText(cardItem.description);
    }
    if (cardItem.hasMoved === true && !wasMoved && !isBusy) {
      save({
        ...cardItem,
        hasMoved: false,
      });
    }
  }, [cardItem, isBusy, editorText, onFieldChange, save, wasMoved]);

  return (
    <Draggable draggableId={`draggable-${localItemState.id}`} index={index} isDragDisabled={isEditing}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="swimlane-card card"
        >
          <CardBody>
            <CardTitle>
              {localItemState.internal_project && localItemState.internal_project.name && (
                <h5><strong>{localItemState.internal_project.name}</strong></h5>
              )}
              <h5 className="pull-right">
                Pr
                {localItemState.priority - (countFrom - 1)}
              </h5>
              <h5>
                #
                {localItemState.id}
                {' '}
                {idLinkPath && getFieldDisplayComponent(localItemState, {
                  name: 'name',
                  linkRoute: idLinkPath || '',
                  format: COLUMN_FORMAT.NAME_LINK,
                }, false)}
              </h5>
            </CardTitle>
            {isEditing ? (
              <Editor
                apiKey={TINYMCE_KEY}
                initialValue={editorText}
                init={{
                  branding: false,
                  menubar: false,
                  statusbar: false,
                  inline: true,
                  paste_as_text: true,
                  placeholder: 'Insert your description here...',
                  plugins: 'lists',
                  toolbar: 'undo redo | bold italic | bullist numlist',
                  valid_elements: 'strong/b,em,div,br,p,ol,ul,li',
                  contextmenu: false,
                }}
                disabled={!isEditing}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(e: any) => (onFieldChange ? onFieldChange({ fieldName: 'description', newValue: e.level.content }) : undefined)}
              />
            ) : (
              // eslint-disable-next-line react/no-danger
              <div id="tiny" className="body" dangerouslySetInnerHTML={{ __html: localItemState.description }} />
            )}
            <CardText>
              {localItemState.external_url && <AbbreviatedExternalLink href={localItemState.external_url} />}
            </CardText>
            {assignee && (
              <CardText>
                <strong>{assigneeLabel}</strong>
                &nbsp;&nbsp;
                {getFieldDisplayComponent({ user: assignee }, {
                  name: 'user',
                  format: COLUMN_FORMAT.OBJECT,
                  object: {
                    sourceField: 'user',
                    key: 'name',
                    format: COLUMN_FORMAT.NAME_LINK,
                    linkId: 'id',
                    linkRoute: '/hrm/browse-profiles',
                  },
                }, false)}
              </CardText>
            )}
            <ProgressPercentInput id="progress" name="progress" value={localItemState.progress} onChange={onFieldChange} disabled={!isEditing} />
            <div>
              {formButtons}
              {(localItemState._status_name || localItemState.status?.name) && (
                <div className="pull-right" style={{ margin: '0.75rem' }}>
                  <StatusBadge status={localItemState._status_name || localItemState.status?.name} />
                </div>
              )}
              {localItemState.user_audit && localItemState.user_audit.created_at && (
                <div
                  className="text-gray pull-left"
                  style={{ marginTop: '1rem', cursor: 'help' }}
                  title={localItemState.user_audit.created_at && moment(localItemState.user_audit.created_at).fromNow()}
                >
                  {localItemState.created_by?.name || ''}
                  &nbsp;
                  {moment(localItemState.user_audit.created_at).format('D MMM YY')}
                </div>
              )}
            </div>
            {/* @todo this whole area should be flex boxes not pull-left stupidity */}
            <div className="clearfix" />
            {!cardItem.hasMoved && feedbackPanel}
          </CardBody>
        </div>
      )}
    </Draggable>
  );
};
