import alphawestLogo from '../images/partner-alphawest.png';
import winyaLogo from '../images/partner-winya.png';
import miViewLogo from '../images/partner-miview.png';
import greenTelstraLogo from '../images/partner-green-t.png';
import optusLogo from '../images/partner-optus.png';
import toshibaLogo from '../images/partner-toshiba.png';
import blueTelstraLogo from '../images/partner-blue-t.png';
import orangeTelstraLogo from '../images/partner-orange-t.png';
import ciLogo from '../images/partner-ci.png';
import mutualMarketplaceLogo from '../images/partner-mutual-marketplace.png';
import purpleTelstraLogo from '../images/partner-purple-t.png';
import datacomLogo from '../images/partner-datacom.png';
import hansenYunckenLogo from '../images/partner-hansen-yuncken.png';
import spotlessLogo from '../images/partner-spotless.png';
import ammacusLogo from '../images/partner-ammacus.png';

// TODO: move partner logos to the API
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const partnerLogos: Record<string, any> = {
  ci: ciLogo,
  telstra: blueTelstraLogo,
  tbs: greenTelstraLogo,
  'telstra dx - ivx': purpleTelstraLogo,
  'telstra dx - tbs': orangeTelstraLogo,
  optus: optusLogo,
  winya: winyaLogo,
  miview: miViewLogo,
  alphawest: alphawestLogo,
  toshiba: toshibaLogo,
  'mutual marketplace': mutualMarketplaceLogo,
  datacom: datacomLogo,
  'hansen yuncken': hansenYunckenLogo,
  spotless: spotlessLogo,
  ammacus: ammacusLogo,
};
