import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import classNames from 'classnames';
import { usePopper } from 'react-popper';

import { AN_ICON, ICON } from '../../constants/icon.const';
import Icon from '../layout-helpers/icon';
import { ReactPortal } from '../react-portal/react-portal';
import { usePreviousValue } from '../../react-hooks/use-previous-value.hook';

export type PortalMultiLevelDropDownProps = {
  className?: string,
  rightAligned?: boolean,
  keepOpen?: boolean,
  buttonIcon?: AN_ICON,
  buttonCaption?: string,
  onToggle?: (isMenuOpen: boolean) => void,
};

export declare type PortalMultiLevelDropDownHandlers = {
  close(): void;
  open(): void;
};

export const PortalMultiLevelDropDown: React.FC<PortalMultiLevelDropDownProps & {
  ref?: Ref<PortalMultiLevelDropDownHandlers>,
}> = forwardRef((props, ref) => {
  const {
    className,
    children,
    rightAligned = false,
    keepOpen = false,
    buttonIcon = ICON.MENU,
    buttonCaption,
    onToggle,
  } = props;

  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const oldIsDropDownOpen = usePreviousValue(isDropDownOpen);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: rightAligned ? 'bottom-end' : 'bottom-start',
  });


  /**
   * This adds some properties to the ref object passed back to the parent which
   * allows the parent to "call" methods on the child. This technically breaks
   * the react uni-directional flow pattern but sometimes it's easier to work around it
   */
  useImperativeHandle(ref, () => ({
    close: ():void => {
      if (isDropDownOpen) setIsDropDownOpen(false);
    },
    open: ():void => {
      if (!isDropDownOpen) setIsDropDownOpen(true);
    },
  }), [isDropDownOpen]);


  /**
   * Toggle whether the dropdown is open or not
   */
  const toggleDropDownOpen = (newValue?: boolean) => setIsDropDownOpen((oldValue) => ((newValue !== undefined) ? newValue : !oldValue));


  /**
   * Listen to clicks anywhere else on the document and close the dropdown if the dropdown is open
   */
  useEffect(() => {
    if (isDropDownOpen && !keepOpen) {
      /**
       * Listens to all clicks on the document and closes the dropdown
       */
      const handleDocumentClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDropDownOpen(false);
      };
      document.addEventListener('click', handleDocumentClick);

      return () => {
        document.removeEventListener('click', handleDocumentClick);
      };
    }
    return () => {};
  }, [keepOpen, isDropDownOpen]);


  /**
   * Let the consumer know that the menu has toggled open / closed
   */
  useEffect(() => {
    if (onToggle && (oldIsDropDownOpen !== isDropDownOpen)) {
      onToggle(isDropDownOpen);
    }
  }, [isDropDownOpen, oldIsDropDownOpen, onToggle]);


  // Render
  return (
    <>
      <button
        type="button"
        className={classNames('pmldd-toggle btn btn-sm icon-btn', {
          'btn-primary': isDropDownOpen,
          'btn-secondary': !isDropDownOpen,
        })}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleDropDownOpen();
        }}
        ref={setReferenceElement}
      >
        <Icon i={buttonIcon} />
        {buttonCaption && (
          <span>{buttonCaption}</span>
        )}
      </button>

      {isDropDownOpen && (
        <ReactPortal>
          <div
            className={classNames('pmldd', className, {
              right: rightAligned,
              left: !rightAligned,
            })}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <ul
              className="pmldd-menu"
            >
              {children}
            </ul>
          </div>
        </ReactPortal>
      )}
    </>
  );
});
