import React, { useCallback, useState } from 'react';
import classNames from 'classnames';

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { CollectionWidgetProps } from '../../../types/collection.widget.props';

import { CollectionWidget } from '../collection.widget';

import { hasPermittedAction } from '../../../helpers/has-permitted-action.helper';

import { API_ACTION } from '../../../constants/api-action.const';
import { CollectionWidgetHeader } from '../collection-widget-header';
import { IDataTableRecord } from '../../../types/portal-data-table/portal-data-table-record.interface';
import { A_FORM_RENDERER_TYPE, FORM_RENDERER_TYPE } from '../../../constants/form-renderer-type.const';
import { IFormFieldDefinition } from '../../../types/poly-form/form-field-definition.interface';


export type ContactsWidgetProps = Omit<CollectionWidgetProps, 'rowData'> & {
  rowData: IDataTableRecord,
}

interface IViewModeProps {
  viewMode: string,
  headings?: IFormFieldDefinition[],
  className?: string,
  formRendererType: A_FORM_RENDERER_TYPE,
}

/**
 * This widget displays Contacts by wrapping a table widget in a custom widget
 * for displaying other components
 */
export const ContactsWidget:React.FC<ContactsWidgetProps> = (props) => {
  const {
    className,
    disableHeader,
    description,
    headings,
    widgetPermittedActions,
    isEditingCollectionItem,
    isCreatingCollectionItem,
    isReadOnly,
    showAddBtn,
    createCollectionItem,
  } = props;

  const cardViewProps = {
    viewMode: 'cards',
    headings: [],
    className: classNames(className, 'as-cards'),
    formRendererType: FORM_RENDERER_TYPE.CONTACT,
  };

  const tableViewProps = {
    viewMode: 'table',
    headings,
    className,
    formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  };

  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);
  const [viewModeProps, setViewModeProps] = useState<IViewModeProps>(cardViewProps);

  /**
   * Create a new Project invoice
   */
  const handleCreateCollectionItem = useCallback(() => {
    if (createCollectionItem) createCollectionItem();
  }, [createCollectionItem]);


  // Render
  return (
    <CollectionWidget
      {...props}
      {...viewModeProps}
      disableHeader
    >
      {/* Pass in a custom header (if not disabled in the definition */}
      {(disableHeader !== true) && (
        <CollectionWidgetHeader
          description={description}

          showAddBtn={
            // pass down setting from the collection widget definition
            showAddBtn &&

            // The CREATE action exists in the widgetItemPermittedActions
            !!hasPermittedAction(widgetPermittedActions, API_ACTION.CREATE)
          }

          // Prevent the user from adding another row if they are editing a row
          addBtnDisabled={isEditingCollectionItem || isCreatingCollectionItem || isReadOnly}

          // Pass the event handler back up the chain so that the widget wrapper can add a data row
          onClickAddBtn={handleCreateCollectionItem}

          actions={(
            <>
              <Dropdown isOpen={dropDownOpen} toggle={() => setDropDownOpen(!dropDownOpen)} size="sm">
                <DropdownToggle color="primary" caret>
                  View Mode &nbsp;
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setViewModeProps(cardViewProps)} active={viewModeProps.viewMode === 'cards'}>
                    Cards
                  </DropdownItem>
                  <DropdownItem onClick={() => setViewModeProps(tableViewProps)} active={viewModeProps.viewMode === 'table'}>
                    Table
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </>
          )}
        />
      )}
    </CollectionWidget>
  );
};
