import React, { useCallback } from 'react';

import { NewRecordModalProps } from '../../types/modal/new-record-modal.props';

import { useIsDirty } from '../../react-hooks/use-is-dirty.hook';

import { Modal } from './Modal';
import { NewCommissionPaymentBatchForm } from '../poly-forms/new-commission-payment-batch-form';
import { ICommissionPaymentBatchRecord } from '../../types/finance/commission-payment-batch.record.interface';

export const NewCommissionPaymentBatchModal:React.FC<NewRecordModalProps<ICommissionPaymentBatchRecord>> = (props) => {
  const {
    id, closeModal, onModalComplete, initialData,
  } = props;

  const [isDirty, setDirty] = useIsDirty();

  /**
   * Fired when the form successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, recordId: ICommissionPaymentBatchRecord['id'], newRecord?: ICommissionPaymentBatchRecord) => {
    closeModal(id);
    onModalComplete((success && newRecord) ? { success, newRecord } : { success: false, newRecord: null });
  }, [closeModal, id, onModalComplete]);

  // Render
  return (
    <Modal
      {...props}
      title="Add a Commission Payment Batch"
      allowDismiss
      isDirty={isDirty}
      size="lg"
    >
      <NewCommissionPaymentBatchForm
        onFormComplete={handleFormComplete}
        onFormChange={setDirty}
        initialData={initialData}
      />
    </Modal>
  );
};
