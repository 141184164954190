import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Button } from 'reactstrap';

import { FormRendererProps } from '../../../types/poly-form/form-renderer.props';

import { FormFieldRenderer } from '../form-field-renderer';
import FriendlyFormMessage from '../../layout-helpers/friendly-form-message';
import { FormButtons } from '../form-buttons';
import Icon from '../../layout-helpers/icon';

// import { FORM_FIELD_TYPE } from '../../../constants/form-field-type.const';
import { ICON } from '../../../constants/icon.const';

export const TableRowFormRenderer:React.FC<FormRendererProps> = (props) => {
  const {
    className,

    parentData,
    primaryKeyFieldName,
    fields,
    formData,

    isReadOnly,
    isNewRecord = false,
    isEditing,
    isLocked,
    isBusy = false,

    hasErrors,
    errors,
    hasSuccess,
    formMessage,

    permittedActions,

    showFieldInfo,
    formDeleteConfirmationType,

    lockForm,

    startEditRecord,
    endEditRecord,
    deleteRecord,

    onFieldChange,
  } = props;

  const itemPrimaryKeyValue = isNewRecord ? null : formData[primaryKeyFieldName ?? 'id'];


  /**
   * Fired when the user double clicks on the form (or form fields)
   *
   * @param focusFieldName the field that was double clicked (optional)
   */
  const handleDoubleClick = useCallback((focusFieldName?: string) => {
    if (!isEditing && startEditRecord) {
      startEditRecord(itemPrimaryKeyValue, formData, focusFieldName);
    }
  }, [isEditing, startEditRecord, itemPrimaryKeyValue, formData]);


  // Render
  return (
    <div
      className={classNames(className, 'datatable-inline-editor')}
    >
      <div className={classNames('datatable-widget-line', { editing: isEditing })}>
        <Button size="sm" color="secondary" disabled>
          {/* Display the error */}
          {hasErrors && (
            <Icon i={ICON.ERROR} className="text-danger" />
          )}
          {/* TODO: sometime in the future only display the caret icon if the row is selected / active */}
          {!hasErrors && isEditing && (
            <Icon i={ICON.EDIT} />
          )}
          {!hasErrors && !isEditing && (<Icon />)}
        </Button>
        {
          // fields will be generated from props.fields
          fields.map((field) => {
            // showInForm is true by default, but if we have set this, don't render
            if (field.showInForm === false) return null;

            // Don't render a "NotEditable" if the form is creating a new record
            // Unsure why this was done - it breaks the columns!
            // if ((field.formFieldType === FORM_FIELD_TYPE.NOT_EDITABLE) && isNewRecord) return null;

            return (
              <FormFieldRenderer
                id={field.id}
                key={field.id}

                parentData={parentData}
                formData={formData}
                field={field}
                errors={errors}

                inline
                showFieldInfo={showFieldInfo}
                formIsLocked={isLocked}
                isReadOnly={!isEditing || isReadOnly || field.isReadOnly || isBusy}
                isNewRecord={isNewRecord}

                lockForm={lockForm}
                onChange={onFieldChange}

                onDoubleClick={() => handleDoubleClick(field.name)}
              />
            );
          })
        }
        <FormButtons
          permittedActions={permittedActions}
          formDeleteConfirmationType={formDeleteConfirmationType}

          formIsEditing={isEditing}
          formIsReadOnly={isReadOnly}
          formIsCreating={isNewRecord}
          formIsBusy={isBusy}
          formIsLocked={isLocked}

          inline
          secondary={false}

          startEditRecord={startEditRecord ? () => startEditRecord(itemPrimaryKeyValue, formData) : undefined}
          endEditRecord={endEditRecord ? (saveChanges) => endEditRecord(saveChanges, itemPrimaryKeyValue, formData) : undefined}
          deleteRecord={deleteRecord ? () => deleteRecord(itemPrimaryKeyValue, formData) : undefined}
        />

        <div
          className={classNames('feedback-footer', {
            'has-success': hasSuccess,
            active: isBusy,
          })}
        />
      </div>

      {(formMessage || hasSuccess || hasErrors) && (
        <div className="datatable-widget-line">
          <FriendlyFormMessage
            formMessage={formMessage}
            hasSuccess={hasSuccess}
            hasErrors={hasErrors}
            errors={errors}
            showList
            inline
          />
        </div>
      )}
    </div>
  );
};
