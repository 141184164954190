import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';
import { ALLOWANCE_STATUS_MAP } from '../constants/allowance-status.const';

/**
 * Table Definition for My Allowances page
 */
const myAllowancesTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'My Allowance Claim',
  initialFormFocusFieldName: 'description',
  baseRoute: '/allowance',
  // Where status != Deleted
  baseQueryString: [
    'with[]=user',
    'with[]=approver:id,name',
    'with[]=allowanceType',
    'with[]=status:id,name',
    'with[]=project:id,project_number,partner_program_id',
    'with[]=project.partnerProgram:id,name',
  ].join('&'),

  columnPresets: [
    {
      name: 'default',
      title: 'Default',
      fields: [
        'id',
        'status',
        'description',
        'allowance_type',
        'project',
        'job_number',
        'amount',
        'nights',
        'start_date',
        'end_date',
        'user_audit_submitted_at',
        'approver',
        'user_audit_approved_at',
      ],
    },
    {
      name: 'all',
      title: 'All',
      fields: [
        'id',
        'status',
        'user',
        'description',
        'allowance_type',
        'project',
        'job_number',
        'amount',
        'nights',
        'start_date',
        'end_date',
        'user_audit_submitted_at',
        'approver',
        'user_audit_approved_at',
      ],
    },
  ],

  /**
   * Definition of Columns for this table
   * Includes settings for APIPolyForm fields
   */
  columns: [
    {
      name: 'id',
      title: 'ID',
      description: 'Allowance Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      linkRoute: '/hrm/my-allowances',
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'user',
      title: 'Employee',
      description: 'Person claiming an allowance',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      formSaveField: 'user_id',
      visible: false,
      order: 1,
      formOrder: 1,
    },
    {
      name: 'approver',
      title: 'Approver',
      description: 'Manager chosen to approve allowance',
      placeholder: 'Select...',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'approver',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
      formSaveField: 'approver_id',
      required: true,
      visible: true,
      order: 12,
      formOrder: 2,
    },
    {
      // NOTE statuses are controlled by ACTION buttons
      name: 'status',
      title: 'Status',
      description: 'Current Status of Project',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'status',
        key: 'name',
        format: COLUMN_FORMAT.STATUS_BADGE,
        listSource: 'allowanceStatus',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 2,
      formOrder: 3,
    },
    {
      name: 'description',
      title: 'Description',
      description: 'Allowance name/description',
      placeholder: 'Enter a brief description',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      required: true,
      order: 3,
      maxLength: 100,
      formOrder: 4,
    },
    {
      name: 'allowance_type',
      title: 'Allowance Type',
      description: 'Allowance Type',
      format: COLUMN_FORMAT.OBJECT,
      placeholder: 'Select...',
      object: {
        sourceField: 'allowance_type',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
        listSource: 'allowanceType',
      },
      formFieldType: FORM_FIELD_TYPE.ALLOWANCE_TYPE_PICKER,
      visible: true,
      required: true,
      formSaveField: 'allowance_type_id',
      order: 4,
      formOrder: 5,
    },
    {
      name: 'nights',
      title: 'Qty Nights/Days/Kms',
      description: 'Quantity of Nights/days/Kms',
      format: COLUMN_FORMAT.NUMBER,
      visible: true,
      required: true,
      order: 8,
      formOrder: 6,
    },
    {
      name: 'amount',
      title: 'Amount',
      description: 'Allowance Total Value',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      order: 7,
      formOrder: 7,
    },
    {
      name: 'project',
      title: 'Project',
      visible: true,
      format: COLUMN_FORMAT.OBJECT,
      description: 'Portal Project Number',
      placeholder: 'P12345ABC',
      object: {
        sourceField: 'project',
        key: 'project_number',
        format: COLUMN_FORMAT.P_NUMBER,
        listSource: 'project',
      },
      formSaveField: 'project_id',
      formFieldType: FORM_FIELD_TYPE.PROJECT_PICKER,
      order: 5,
      formOrder: 8,
    },
    {
      name: 'job_number',
      title: 'Reference No',
      description: 'Alternate job number or reference if not a P Number',
      placeholder: 'Alternative reference / job no',
      format: COLUMN_FORMAT.TEXT,
      visible: true,
      order: 6,
      formOrder: 9,
    },
    {
      name: 'start_date',
      title: 'Start',
      description: 'Start Date',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: true,
      order: 9,
      formOrder: 10,
      required: true,
    },
    {
      name: 'end_date',
      title: 'End',
      description: 'End Date',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: true,
      order: 10,
      formOrder: 11,
      required: true,
    },
    {
      title: 'Submitted',
      name: 'user_audit_submitted_at',
      sortColumn: 'submitted_at',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'submitted_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      showInForm: false,
      visible: true,
      order: 11,
      formOrder: 12,
    },
    {
      title: 'Approved',
      name: 'user_audit_approved_at',
      sortColumn: 'approved_at',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'approved_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      showInForm: false,
      visible: true,
      order: 13,
      formOrder: 13,
    },
  ],
  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Allowance Claim Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      ...eventsTabDefault,
      description: 'Allowance Claim Events',
      statusMap: ALLOWANCE_STATUS_MAP,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Claim New Allowance',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: '',
      successMessage: '',
      failMessage: '',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: '',
      successMessage: '',
      failMessage: '',
      hideRecordActionButton: true,
    },
    {
      name: 'submit',
      title: 'Submit',
      color: BUTTON_COLOR.INFO,
      icon: ICON.SUBMIT,
      description: 'This will submit your allowance claim',
      successMessage: 'Allowance claim submitted',
      failMessage: 'Failed to submit your allowance claim',
    },
    {
      name: 'unsubmit',
      title: 'Withdraw',
      color: BUTTON_COLOR.WARNING,
      icon: ICON.UNDO,
      description: 'This will WITHDRAW (unsubmit) your allowance claim',
      successMessage: 'Allowance claim withdrawn',
      failMessage: 'Failed to withdraw your allowance claim',
    },
    // Reject your own allowance claim?
    // {
    //   name: 'reject',
    //   title: 'Reject',
    //   color: BUTTON_COLOR.DANGER,
    //   icon: 'times',
    //   description: 'This will reject your allowance claim',
    //   successMessage: 'Your allowance claim was rejected',
    //   failMessage: 'Failed to reject your allowance claim',
    // },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE your allowance claim',
      successMessage: 'Allowance claim deleted',
      failMessage: 'Failed to delete your allowance claim',
      hideRecordActionButton: true,
    },
  ],

  /**
   * View definitions
   */
  viewKey: 'unpaid',
  views: [
    {
      key: 'unpaid',
      title: 'Unpaid',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.NOT,
          values: ['paid'],
        },
      ],
      flags: [],
    },
    {
      key: 'paid',
      title: 'Paid',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['paid'],
        },
      ],
      flags: [],
    },
    {
      key: 'all',
      title: 'All Allowances',
      filters: [],
      flags: [],
    },
  ],
};

export default myAllowancesTableInitialSettings;
