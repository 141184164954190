import React, { useContext } from 'react';

import { DataTablePageProps } from '../../types/data-table-page.props';

import { CurrentUserContext } from '../providers/current-user-provider';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { API_FILTER_OPERATION } from '../../constants/api-filter-operation.const';

export type ManageExpenseClaimsDataTablePageProps = DataTablePageProps;

/**
 * Display the "Manage Expenses" data table as a whole page
 */
export const ManageExpenseClaimsDataTablePage: React.FC<ManageExpenseClaimsDataTablePageProps> = ({
  baseFlags,
  match,
  history,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const { userDetails } = useContext(CurrentUserContext);

  const baseFilters = [
    {
      field: 'approver_id',
      operation: API_FILTER_OPERATION.EQUALS,
      values: [userDetails.id],
    },
  ];

  return (
    <DataTablePage
      title="Manage Expenses"
      tableIdentifier={TABLE_IDENTIFIER.MANAGE_EXPENSES_TABLE}
      baseFilters={baseFilters}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      {children}
    </DataTablePage>
  );
};

