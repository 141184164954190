import { AN_ICON, ICON } from './icon.const';

export const COMPANY_LOCATION_PTV_NODE_TYPE = {
  ROOT: 0,
  LOCATION: 1,
  SPACE: 2,
} as const;
export type COMPANY_LOCATION_PTV_NODE_TYPE = typeof COMPANY_LOCATION_PTV_NODE_TYPE;
export type A_COMPANY_LOCATION_PTV_NODE_TYPE = COMPANY_LOCATION_PTV_NODE_TYPE[keyof COMPANY_LOCATION_PTV_NODE_TYPE];

export const CompanyLocationPTVNodeTypeIconMap: Record<A_COMPANY_LOCATION_PTV_NODE_TYPE, AN_ICON | undefined> = {
  [COMPANY_LOCATION_PTV_NODE_TYPE.ROOT]: undefined,
  [COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION]: ICON.LOCATION, // This is further refined by the company location type
  [COMPANY_LOCATION_PTV_NODE_TYPE.SPACE]: ICON.SPACE,
};
