/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

export const CURRENT_USER_DETAILS_PROP_TYPES = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  first: PropTypes.string,
  middle: PropTypes.string,
  last: PropTypes.string,
  initials: PropTypes.string,
  state: PropTypes.shape({
    id: PropTypes.number.isRequired,
    acronym: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  directManager: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  indirectManager: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  escalationManager: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
};
