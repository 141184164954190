// eslint-disable-file no-void
import React from 'react';
import PropTypes from 'prop-types';
import {
  PORTAL_FUSION_CHART_LOADED_DATA_PROP_TYPES,
  PORTAL_FUSION_CHART_DATA_MAPPING_PROP_TYPES,
} from '../../prop-types/portal-fusion-charts-prop-types';
import { shallowAreObjectsDifferent } from '../../helpers/shallow-are-objects-different';
import { debugLog } from '../../utils/helpers';
import { isMultiSeriesChart } from '../../utils/fusion-chart-types';


/**
 * @description
 * Handles mapping of loadedData to chartData for the Portal Fusion Chart
 */
class PortalFusionChartDataMapper extends React.Component {
  /**
   * @constructor
   *
   * @param {{}} props
   */
  constructor(props) {
    super(props);

    this.state = {
      chartData: this.mapLoadedDataToChartData(props),
      chartCategories: this.mapLoadedDataToChartCategories(props),
    };

    this.notifyMapped();
  }


  /**
   * @inheritdoc
   */
  shouldComponentUpdate = (nextProps) => {
    const { data: oldData, dataMapping: oldDataMapping } = this.props;
    const { data: newData, dataMapping: newDataMapping } = nextProps;

    if (oldData.loadedData !== newData.loadedData || shallowAreObjectsDifferent(oldDataMapping, newDataMapping)) {
      this.setState({
        chartData: this.mapLoadedDataToChartData(nextProps),
        chartCategories: this.mapLoadedDataToChartCategories(nextProps),
      }, this.notifyMapped);
      return false;
    }

    return true;
  }


  /**
   * @description
   * Notify that data was mapped
   */
  notifyMapped = () => {
    const {
      onChartDataMapped, data, dataMapping, id,
    } = this.props;
    const { chartCategories, chartData } = this.state;
    const { loadedData } = data;

    if (typeof onChartDataMapped === 'function') { onChartDataMapped(id, {
      chartCategories, chartData, loadedData, dataMapping,
    }); }
  }


  /**
   * @description
   * Map loaded data to chart data for the chart type
   *
   * @param {{}} withProps
   */
  mapLoadedDataToChartData = (withProps = this.props) => {
    const { data, dataMapping, id } = withProps;
    const { loadedData } = data;
    const { chartType, type } = dataMapping;

    debugLog(`[PortalFusionChartDataMapper:__${id}__:mapLoadedDataToChartData]`, 'warn', '', '🎢');

    // chart is multi series
    if (isMultiSeriesChart(chartType)) {
      // only support multi series mappings
      if (type !== 'multi') throw new TypeError(`Data mapping type "${type}" does not support Multi Series chartType "${chartType}"`);
      const { seriesList } = dataMapping;

      const chartData = seriesList.map((seriesItem) => ({
        seriesname: seriesItem.name,
        renderAs: seriesItem.renderAs,
        visible: seriesItem.visible,
        // legendItemId: key,
        ...(seriesItem.color ? { color: seriesItem.color } : {}),
        ...(seriesItem.lineThickness ? { lineThickness: seriesItem.lineThickness } : {}),
        ...(seriesItem.hoverGradientColor ? { hoverGradientColor: seriesItem.hoverGradientColor } : {}),
        data: loadedData
          .filter((dataItem) => (typeof seriesItem.filterIn === 'function' ? seriesItem.filterIn(dataItem) : true))
          // map to value
          .map((dataItem) => ({
            value: seriesItem.getValue(dataItem),
            dataItem,
          })),
      }));

      return chartData;
    }

    // chart is single series
    let getLabel;
    let getValue;
    let getColor;
    let filterIn;

    // get the dataMapping (from either single or multi series mappings)
    if (type === 'single') {
      ({ getLabel } = dataMapping);
      ({ getValue } = dataMapping);
      ({ getColor } = dataMapping);
      ({ filterIn } = dataMapping);
    }
    else if (type === 'multi') {
      const firstSeries = dataMapping.seriesList[0];
      ({ getLabel } = dataMapping);
      ({ getValue } = firstSeries);
      getColor = firstSeries.color && (() => firstSeries.color);
      ({ filterIn } = firstSeries);
    }

    const chartData = loadedData
      .filter((dataItem) => (typeof filterIn === 'function' ? filterIn(dataItem) : true))
      .map((dataItem) => ({
        ...(typeof getColor === 'function' ? { color: getColor(dataItem) } : {}),
        label: getLabel(dataItem),
        value: getValue(dataItem),
        dataItem,
      }));

    return chartData;
  }


  /**
   * @description
   * Map loaded data to chart data for the chart type
   *
   * @param {{}} withProps
   */
  mapLoadedDataToChartCategories = (withProps = this.props) => {
    const { data, dataMapping, id } = withProps;
    const { type } = dataMapping;
    const { loadedData } = data;

    debugLog(`[PortalFusionChartDataMapper:__${id}__:mapLoadedDataToChartCategories]`, 'warn', '', '🎢');

    // chart is single series
    let getLabel;
    let filterIn;

    // get the dataMapping
    if (type === 'single') {
      ({ getLabel } = dataMapping);
      ({ filterIn } = dataMapping);
    }
    else if (type === 'multi') {
      const firstSeries = dataMapping.seriesList[0];
      ({ getLabel } = dataMapping);
      ({ filterIn } = firstSeries);
    }

    const result = [{
      // take labels from only one of the seriesList
      category: loadedData
        .filter((dataItem) => (typeof filterIn === 'function' ? filterIn(dataItem) : true))
        .map((dataItem) => ({ label: getLabel(dataItem) })),
    }];

    return result;
  }

  /**
   * @inheritdoc
   */
  render() {
    const { render } = this.props;
    const {
      chartData, chartCategories, chartHeight, chartWidth,
    } = this.state;

    return render({
      chartData, chartHeight, chartWidth, chartCategories,
    });
  }
}

PortalFusionChartDataMapper.propTypes = {
  id: PropTypes.string.isRequired,
  data: PORTAL_FUSION_CHART_LOADED_DATA_PROP_TYPES.isRequired,
  dataMapping: PORTAL_FUSION_CHART_DATA_MAPPING_PROP_TYPES.isRequired,
  onChartDataMapped: PropTypes.func,
  render: PropTypes.func.isRequired,
};

PortalFusionChartDataMapper.defaultProps = {
  onChartDataMapped: null,
};


export default PortalFusionChartDataMapper;
