import React, { createContext, useState, useEffect, useCallback, useContext, useRef } from 'react';
import { ciPortalApiFactory, AuthTokenWithTimestamp, AuthStatus, AUTH_STATUS_TYPE, HTTP_METHOD, APIVersion } from '@corporate-initiatives/ci-portal-js-sdk';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location, Pathname, Search } from 'history';
import { useLocation, useHistory as useReactRouterHistory } from 'react-router';

import { assertDefined } from '../../helpers/assert-defined.helper';
import { localStorageLoad, localStorageSave, localStorageDelete } from '../../utils/localStorage';
import { useHistory } from '../router/history';

import { IChangePasswordResult } from '../../types/auth/change-password-result.interface';
import { IConstructor } from '../../types/constructor.interface';
import { ILoginResult } from '../../types/auth/login-result.interface';
import { IPasswordResetResult } from '../../types/auth/password-reset-result.interface';
import { PortalAPIResponse } from '../../helpers/portal-api-response';

import { ChangePasswordPage } from '../auth/change-password-page';
import { LoginPage } from '../auth/login-page';
import { NotificationContext } from './notification-provider';
import { PasswordResetPage } from '../auth/password-reset-page';
import { PreLoader } from '../auth/pre-loader';

import { API_URL, API_VER, API_CLIENT_ID, API_CLIENT_SECRET } from '../../utils/constants';
import { APP_VIEW, AN_APP_VIEW } from '../../constants/app-view.const';
import { LOCAL_STORAGE_KEYS } from '../../utils/local-storage-keys';
import { NOTIFICATION_TYPE } from '../../constants/notification-type.const';
import { APIFetchOptions } from '../../types/auth/api-fetch-options';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { PortalAPIBlobResponse } from '../../helpers/portal-api-blob-response';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type APIFetchFunction<T = any> = (
  url: string,
  opts?: APIFetchOptions
) => Promise<PortalAPIResponse<T>>;

export type APIBlobFetchFunction<T = unknown> = (
  url: string,
  opts?: APIFetchOptions
) => Promise<PortalAPIBlobResponse<T>>

// @Note: Don't forget to update API_PROVIDER_PROP_TYPES when this interface is updated
export type APIContextProps = {
  logout: (reason?: string) => void,
  apiFetch: APIFetchFunction,
  apiFetchBlob: APIBlobFetchFunction,
};

// <T = any>(url: string, opts?: APIFetchOptions) => Promise<PortalAPIResponse<T>>;

export const APIContext = createContext<APIContextProps>(null as never);

export const APIConsumer = APIContext.Consumer;

const ciPortalAPI = ciPortalApiFactory({
  baseApiUrl: API_URL,
  apiVersion: API_VER as APIVersion,
  authRefreshWindow: 30000, // 30 Seconds
  client: {
    client_id: API_CLIENT_ID,
    client_secret: API_CLIENT_SECRET,
  },
  authToken: (localStorageLoad(LOCAL_STORAGE_KEYS.API_AUTH_TOKEN) as AuthTokenWithTimestamp) ?? undefined,
});

interface IPasswordRouteURLDetails {
  pathname: string,
  search: string,
  userEmail: null | string,
  changeToken: null | string,
  isChangePasswordRoute: boolean,
  isResetPasswordRoute: boolean,
}


/**
 * Analyse the current URL and extract information about the intent the user has to reset or change their password
 */
function getChangeResetPasswordURLDetails(pathname: Pathname, search: Search): IPasswordRouteURLDetails {
  const result: IPasswordRouteURLDetails = {
    pathname,
    search,
    userEmail: null,
    changeToken: null,
    isChangePasswordRoute: false,
    isResetPasswordRoute: false,
  };

  const resetPath = '/password/reset';
  const changePath = '/password/change';

  result.isChangePasswordRoute = pathname.startsWith(changePath);
  result.isResetPasswordRoute = pathname.startsWith(resetPath);

  if (result.isChangePasswordRoute) {
    result.changeToken = pathname.replace(`${changePath}/`, '');
    if (!!result.changeToken && (result.changeToken === changePath)) {
      result.changeToken = null;
    }
    result.userEmail = new URLSearchParams(search).getAll('email')[0] ?? null;
  }

  if (result.isResetPasswordRoute) {
    result.userEmail = new URLSearchParams(search).getAll('email')[0] ?? null;
  }

  return result;
}


/**
 * Take a whole load of information at start up into consideration when determining which initial
 * view to put the app into.
 */
function determineInitialAppView(location: Location): AN_APP_VIEW {
  // If the ciPortalAPi is reporting that the credentials are valid, set the page to logged in
  if (ciPortalAPI.status.type === AUTH_STATUS_TYPE.LOGGED_IN) return APP_VIEW.LOGGED_IN;

  // Evaluate the URL for any information leading to displaying a password reset or change password page
  const urlDetails: IPasswordRouteURLDetails = getChangeResetPasswordURLDetails(location.pathname, location.search);

  // If the has arrived at the Reset Password page, change to the reset password view
  if (urlDetails.isResetPasswordRoute) return APP_VIEW.RESET_PASSWORD;

  // If the has arrived at the Change Password page, change to the change password view
  if (urlDetails.isChangePasswordRoute) return APP_VIEW.CHANGE_PASSWORD;

  return APP_VIEW.LOADING;
}


/**
 * Store or Clear the locally stored auth token for the signed in user
 *
 * Typically fired by the Portal API SDK when the Auth Token changes
 * This could happen after a successful login or after a refresh token has been used
 */
function handleApiAuthTokenChanged(newToken: AuthTokenWithTimestamp | null) {
  if (newToken) {
    localStorageSave(LOCAL_STORAGE_KEYS.API_AUTH_TOKEN, newToken);
  } else {
    localStorageDelete(LOCAL_STORAGE_KEYS.API_AUTH_TOKEN);
  }
}
// On application boot, make sure the local storage matches that of the ciApiSDK instance by running the handler once.
handleApiAuthTokenChanged(ciPortalAPI.authToken);


/**
 * @class APIProvider
 *
 * @description
 * Wraps the entire application in a provider for managing interactions with the Portal API
 */
export const APIProvider: React.FC = function APIProvider({ children }) {
  const location: Location = useLocation() as Location;
  const history = useReactRouterHistory();

  const [appView, setAppView] = useState<AN_APP_VIEW>(() => determineInitialAppView(location));
  const [isBusy, setIsBusy] = useState(false);
  const [userEmail, setUserEmail] = useState<null | string>(null);

  // State variables for Changing Password
  const [changePasswordToken, setChangePasswordToken] = useState<null | string>(null);
  const [changePasswordResult, setChangePasswordResult] = useState<undefined | IChangePasswordResult>(undefined);

  // State variables for Resetting Password
  const [passwordResetResult, setPasswordResetResult] = useState<undefined | IPasswordResetResult>(undefined);

  // State variables for Logging in
  const [loginResult, setLoginResult] = useState<undefined | ILoginResult>(undefined);

  // Contexts
  const { addNotification } = useContext(NotificationContext);

  // Abort Controllers
  const loginAbortController = useRef<null | AbortController>(null);
  const resetPasswordAbortController = useRef<null | AbortController>(null);
  const changePasswordAbortController = useRef<null | AbortController>(null);


  /**
   * Put the app in "Reset Password" view
   */
  const displayResetPasswordPage = useCallback((email: null | string = null) => {
    setUserEmail(email ?? userEmail);
    setPasswordResetResult({
      failed: false,
      succeeded: false,
      fieldErrors: null,
      message: null,
    });
    setAppView(APP_VIEW.RESET_PASSWORD);
  }, [userEmail]);


  /**
   * Put the app in "Change Password" view
   */
  const displayChangePasswordPage = useCallback((email: null | string = null, token: string) => {
    setUserEmail(email ?? userEmail);
    setChangePasswordToken(token);
    setChangePasswordResult({
      failed: false,
      succeeded: false,
      fieldErrors: null,
      message: null,
    });
    setAppView(APP_VIEW.CHANGE_PASSWORD);
  }, [userEmail]);


  /**
   * Put the app in "Login" view
   */
  const displayLoginPage = useCallback((email: null | string = null) => {
    setUserEmail(email ?? userEmail);
    setLoginResult({
      failed: false,
      succeeded: false,
      fieldErrors: null,
      message: null,
    });
    setAppView(APP_VIEW.LOG_IN);
  }, [userEmail]);


  /**
   * Wrap the ciPortalAPI fetch function for use in the application
   * @note: this wraps the SDK fetch method inside a try/catch and ensures a PortalAPIResponse result.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function apiFetch<T = any>(
    url: string,
    opts?: APIFetchOptions,
  ): Promise<PortalAPIResponse<T>> {
    try {
      const apiFetchResponse = await ciPortalAPI.apiFetchWithAuth(
        url,
        opts?.method ?? HTTP_METHOD.GET,
        {
          body: opts?.body,
          headers: opts?.headers,
          signal: opts?.signal,
          apiVersion: opts?.apiVersion,
        },
      );
      return new PortalAPIResponse(apiFetchResponse);
    } catch (error) {
      if (opts?.signal && opts?.signal.aborted) {
        return new PortalAPIResponse(null, 'The request was aborted', true);
      }

      console.error(`PortalAPIProvider.apiFetch Error${opts?.name ? ` [${opts.name}]` : ''}`, error);

      return new PortalAPIResponse(null, error instanceof Error ? error.message : 'Unknown Error');
    }
  }

  /**
   * Wrap the ciPortalAPI fetch function for use in the application
   * @note: this wraps the SDK fetch method inside a try/catch and ensures a PortalAPIResponse result.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function apiFetchBlob<T = any>(
    url: string,
    opts?: APIFetchOptions,
  ): Promise<PortalAPIBlobResponse<T>> {
    try {
      const apiFetchResponse = await ciPortalAPI.apiFetchBlobWithAuth(
        url,
        opts?.method ?? HTTP_METHOD.GET,
        {
          body: opts?.body,
          headers: opts?.headers,
          signal: opts?.signal,
          apiVersion: opts?.apiVersion,
        },
      );
      return new PortalAPIBlobResponse(apiFetchResponse);
    } catch (error) {
      if (opts?.signal && opts?.signal.aborted) {
        return new PortalAPIBlobResponse(null, 'The request was aborted', true);
      }

      console.error(`PortalAPIProvider.apiFetch Error${opts?.name ? ` [${opts.name}]` : ''}`, error);
      return new PortalAPIBlobResponse(null, error instanceof Error ? error.message : 'Unknown Error');
    }
  }

  /**
   * Log the current user out.
   * Provide a reason for the logout to be an "Error"
   */
  const logout = useCallback(async (reason?: string) => {
    // Notify the API that we want the user's auth token to be expired
    ciPortalAPI.logout(); // no need to await this

    // TODO: is this required?
    history.push('/');

    // Pop up a notification
    addNotification({
      type: reason ? NOTIFICATION_TYPE.ERROR : NOTIFICATION_TYPE.INFO,
      headline: 'Logged out',
      message: reason ?? 'Logged out successfully',
      timeout: 5000,
    });
  }, [history, addNotification]);


  /**
   * Handle the submission of the login form
   */
  const login = useCallback(async (email, password) => {
    // abort any previous attempts
    if (loginAbortController && loginAbortController.current) {
      loginAbortController.current.abort();
    }

    // Create a new abort signal
    loginAbortController.current = apiAborter();

    // Prepare to send the request
    setLoginResult({
      succeeded: false,
      failed: false,
      message: null,
      fieldErrors: null,
    });
    setIsBusy(true);
    try {
      // TODO: add an abort signal once the SDK login method supports it
      const result = await ciPortalAPI.login({ login: { email, password }, storeUserLogin: false });

      // Update the form with the login result
      setLoginResult({
        failed: !result.success,
        succeeded: result.success,
        fieldErrors: {},
        message: !result.success ? result.payload.body.message : null,
      });
    } catch (error) {
      // Login failed - update the error message for the form
      setLoginResult({
        failed: true,
        succeeded: false,
        fieldErrors: {},
        message: error instanceof Error ? error.message : 'Unknown Error',
      });
    } finally {
      setIsBusy(false);
      loginAbortController.current = null;
    }
  }, []);


  /**
   * Handle the submission of a reset password request
   */
  const handleSubmitResetPassword = useCallback(async (email) => {
    // abort any previous attempts
    if (resetPasswordAbortController && resetPasswordAbortController.current) {
      resetPasswordAbortController.current.abort();
    }

    // Create a new abort signal
    resetPasswordAbortController.current = apiAborter();

    // Prepare to send the request
    setPasswordResetResult({
      succeeded: false,
      failed: false,
      message: null,
      fieldErrors: null,
    });
    setIsBusy(true);
    try {
      // TODO: add an abort signal once the SDK requestPasswordChangeEmail method supports it
      const response = await ciPortalAPI.requestPasswordChangeEmail({ email });

      if (response.ok) {
        setPasswordResetResult({
          succeeded: true,
          failed: false,
          message: response.body.message,
          fieldErrors: null,
        });
      } else {
        setPasswordResetResult({
          succeeded: false,
          failed: true,
          message: response.body.message,
          fieldErrors: response.body.errors,
        });
      }
    } catch (error) {
      setPasswordResetResult({
        succeeded: false,
        failed: true,
        message: error instanceof Error ? error.message : 'Unknown Error',
        fieldErrors: null,
      });
    } finally {
      setIsBusy(false);
      resetPasswordAbortController.current = null;
    }
  }, []);


  /**
   * Handle the submission of the change password form
   */
  const handleSubmitChangePassword = useCallback(async (password, confirmPassword) => {
    // abort any previous attempts
    if (changePasswordAbortController && changePasswordAbortController.current) {
      changePasswordAbortController.current.abort();
    }

    // Create a new abort signal
    changePasswordAbortController.current = apiAborter();

    // Prepare to send the request
    setChangePasswordResult({
      succeeded: false,
      failed: false,
      message: null,
      fieldErrors: null,
    });
    setIsBusy(true);
    try {
      if (!changePasswordToken) {
        throw new Error('The Password Reset token is missing for some reason.');
      }

      if (!userEmail) {
        throw new Error('The User Email is missing for some reason.');
      }

      // TODO: add an abort signal once the SDK changePassword method supports it
      const response = await ciPortalAPI.changePassword({
        email: userEmail,
        password,
        password_confirmation: confirmPassword,
        token: changePasswordToken,
      });

      if (response.ok) {
        setChangePasswordResult({
          succeeded: true,
          failed: false,
          message: response.body.message,
          fieldErrors: null,
        });

        addNotification({
          type: NOTIFICATION_TYPE.SUCCESS,
          headline: 'Password Changed',
          message: response.body.message,
          timeout: 10000,
        });

        // Attempt to log the user in with the new credentials now that the API has confirmed their new details
        setTimeout(() => {
          login(userEmail, password);
        }, 0);
      } else {
        setChangePasswordResult({
          succeeded: false,
          failed: true,
          message: response.body.message,
          fieldErrors: response.body.errors,
        });
        setIsBusy(false);
      }
    } catch (error) {
      setChangePasswordResult({
        succeeded: false,
        failed: true,
        message: error instanceof Error ? error.message : 'Unknown Error',
        fieldErrors: null,
      });
      setIsBusy(false);
    } finally {
      changePasswordAbortController.current = null;
    }
  }, [changePasswordToken, userEmail, login, addNotification]);


  /**
   * Fired by the Portal API SDK when the Auth Token changes
   * This could happen after a successful login or after a refresh token has been used
   */
  const handleApiAuthStatusChanged = useCallback((newStatus: AuthStatus) => {
    // Don't react to API SDK Auth Status changes if we're viewing the change password page
    if (appView === APP_VIEW.RESET_PASSWORD) return;

    let newAppView = appView;

    switch (newStatus.type) {
      case AUTH_STATUS_TYPE.UNINITIALISED:
      case AUTH_STATUS_TYPE.FAILED:
        newAppView = APP_VIEW.LOG_IN;
        break;

      case AUTH_STATUS_TYPE.LOGGING_IN:
        newAppView = APP_VIEW.LOG_IN;
        break;

      case AUTH_STATUS_TYPE.LOGGED_IN:
        newAppView = APP_VIEW.LOGGED_IN;
        break;

      case AUTH_STATUS_TYPE.REFRESHING:
        // No need to redraw the page just to respond to a token refresh
        break;

      default:
        // @ts-expect-error
        throw new Error(`APIProvider.handleApiAuthStatusChanged: Unhandled AUTH_STATUS_TYPE: ${newStatus.type}`);
    }

    if (newAppView !== appView) {
      setAppView(newAppView);
    }
  }, [appView]);


  /**
   * When the URL path changes, determine what page needs to be displayed
   */
  useEffect(() => {
    // If the ci Portal API SDK reports that the user is authorised - ignore this logic.
    if (ciPortalAPI.status.type === AUTH_STATUS_TYPE.LOGGED_IN) return;

    // Scan the URL for the details about the current path.
    const urlDetails = getChangeResetPasswordURLDetails(location.pathname, location.search);

    // Display either the Change Password or Reset Password pages
    if (urlDetails.isChangePasswordRoute) {
      displayChangePasswordPage(urlDetails.userEmail, assertDefined(urlDetails.changeToken));
    } else if (urlDetails.isResetPasswordRoute) {
      displayResetPasswordPage(urlDetails.userEmail);
    }

    // Nothing in the URL can be interpreted as an un-authenticated route. Display the login page.
    else {
      displayLoginPage(urlDetails.userEmail);
    }
  }, [displayChangePasswordPage, displayResetPasswordPage, displayLoginPage, location.pathname, location.search]);


  /**
   * Add and remove listeners on the Ci Portal API SDK
   */
  useEffect(() => {
    const onAuthStatusChanged = (payload: { newStatus: AuthStatus }) => handleApiAuthStatusChanged(payload.newStatus);
    const onAuthTokenChanged = (payload: { newToken: AuthTokenWithTimestamp | null }) => handleApiAuthTokenChanged(payload.newToken);

    // Bind event listeners to the API sdk
    ciPortalAPI.authEvents.on('AUTH_STATUS_CHANGE', onAuthStatusChanged);
    ciPortalAPI.authEvents.on('AUTH_TOKEN_CHANGE', onAuthTokenChanged);

    return () => {
      ciPortalAPI.authEvents.off('AUTH_STATUS_CHANGE', onAuthStatusChanged);
      ciPortalAPI.authEvents.off('AUTH_TOKEN_CHANGE', onAuthTokenChanged);
      // No need to un-bind - this is a top level component.
    };
  }, [handleApiAuthStatusChanged]);


  /**
   * Fired when the component is mounted
   */
  useEffect(() => () => {
    // Fired when the component is un-mounted
    if (loginAbortController.current) loginAbortController.current.abort();
    if (resetPasswordAbortController.current) resetPasswordAbortController.current.abort();
    if (changePasswordAbortController.current) changePasswordAbortController.current.abort();
  },
  []);


  /**
   * Render
   */
  return (
    <APIContext.Provider
      value={{
        logout,
        apiFetch,
        apiFetchBlob,
      }}
    >
      {/* Loading or Logging In */}
      {(appView === APP_VIEW.LOADING) && (
        <PreLoader
          label="Ci Portal"
          message="Loading... Please wait..."
        />
      )}


      {/* Password Reset Page */}
      {(appView === APP_VIEW.RESET_PASSWORD) && (
        <PasswordResetPage
          onCancel={() => { useHistory.backOrPush(history, useHistory.homeLocation().pathname); }}
          onSubmit={handleSubmitResetPassword}
          isBusy={isBusy}
          formResult={passwordResetResult}
          initialEmail={userEmail}
        />
      )}

      {/* Change Password Page */}
      {(appView === APP_VIEW.CHANGE_PASSWORD) && (
        <ChangePasswordPage
          onCancel={() => { useHistory.backOrPush(history, useHistory.homeLocation().pathname); }}
          onSubmit={handleSubmitChangePassword}
          isBusy={isBusy}
          formResult={changePasswordResult}
        />
      )}

      {/* Login Page */}
      {(appView === APP_VIEW.LOG_IN) && (
        <LoginPage
          onSubmit={login}
          onShowResetPasswordPage={(email) => {
            setUserEmail(email);
            history.push('/password/reset');
          }}
          isBusy={isBusy}
          formResult={loginResult}
          initialEmail={userEmail}
        />
      )}

      {/* Logged In */}
      {(appView === APP_VIEW.LOGGED_IN) && children}
    </APIContext.Provider>
  );
};

/**
 * Connect a component to the API provider
 * (requires the component to be nested under the APIProvider component)
 */
export function connectToAPIProvider<P>(Component: React.FC<P> | IConstructor<React.Component<P>>): React.FC<Omit<P, keyof APIContextProps>> {
  return function APIProviderConnector(props: Omit<P, keyof APIContextProps>) {
    return (
      <APIConsumer>
        {(apiProvider) => <Component {...(props as P)} apiProvider={apiProvider} />}
      </APIConsumer>
    );
  };
}
