import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { DataTablePageProps } from '../../types/data-table-page.props';
import { NewRecordModalResult } from '../../types/modal/modal-result';

import { ModalContext } from '../modals/modal-context';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { IInvoiceRequestRecord } from '../../types/invoice-request/invoice-request.record.interface';
import { INVOICE_REQUEST_STATUS } from '../../constants/invoice-request-status.const';

export type InvoiceRequestDataTablePageProps = DataTablePageProps;

/**
 * Display the "Companies" data table as a whole page
 */
export const InvoiceRequestDataTablePage: React.FC<InvoiceRequestDataTablePageProps> = ({
  baseFilters,
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const history = useHistory();
  const { showModal } = useContext(ModalContext);

  const actionHandlers = {
    create: () => {
      showModal<NewRecordModalResult<IInvoiceRequestRecord>>(
        MODAL_TYPE.NEW_INVOICE_REQUEST,
        {
          onModalComplete: (modalResult) => {
            if (modalResult.success) history.push(`/finance/invoice-requests/${modalResult.newRecord.id}`);
          },
          initialData: {
            status_id: INVOICE_REQUEST_STATUS.DRAFT,
          },
        },
      );
    },
  };

  return (
    <DataTablePage
      title="Invoice Requests"
      tableIdentifier={TABLE_IDENTIFIER.INVOICE_REQUESTS_TABLE}
      actionHandlers={actionHandlers}
      baseFilters={baseFilters}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      {children}
    </DataTablePage>
  );
};
