import React from 'react';
import { useHistory } from 'react-router';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { DataTablePageProps } from '../../types/data-table-page.props';

export type ProjectProfitabilitySnapshotsDataTablePageProps = DataTablePageProps;

/**
 * Display the "ProjectProfitabilitySnapshots" data table as a whole page
 */
export const ProjectProfitabilitySnapshotsDataTablePage: React.FC<ProjectProfitabilitySnapshotsDataTablePageProps> = ({
  baseFilters,
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const history = useHistory();

  return (
    <DataTablePage
      title="Project Profitability Snapshots"
      tableIdentifier={TABLE_IDENTIFIER.PROJECT_PROFITABILITY_SNAPSHOTS_TABLE}
      baseFilters={baseFilters}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      {children}
    </DataTablePage>
  );
};
