import React, { useState } from 'react';
import { Button, Dropdown, DropdownMenu, DropdownToggle, Input } from 'reactstrap';
import Icon from '../../../layout-helpers/icon';
import { CategoryTree } from './default-category-tree';


/**
 * Array Move function
 *
 * @todo is this a helper? Is it a duplicate?
 *
 * @param arr the source array
 * @param fromIndex moving from
 * @param toIndex moving to
 *
 * @returns An updated deep clone of the original array
 */
export const arrayMove = (arr: any[], fromIndex: number, toIndex: number) => {
  const newArr = [...arr];
  newArr.splice(toIndex, 0, newArr.splice(fromIndex, 1)[0]);
  return newArr;
};

/**
 * Category Tree Sorter
 *
 * @param props
 * @returns {React.FC] CategoryTreeManager
 */
export const CategoryTreeManager: React.FC<{
  controlCategoryTree: CategoryTree,
  setControlCategoryTree: (categoryTree: CategoryTree) => void
  setWorkingCategoryTree: (categoryTree: CategoryTree) => void
}> = ({ controlCategoryTree, setControlCategoryTree, setWorkingCategoryTree }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  /**
   * Move a category up
   *
   * @param {number} index the category to move
   */
  const moveUp = (index: number) => {
    const updatedCategoryTree = arrayMove([...controlCategoryTree], index, index - 1);
    setControlCategoryTree(updatedCategoryTree);
    setWorkingCategoryTree(updatedCategoryTree.filter((category) => category.active));
  };


  /**
   * Move a category down
   *
   * @param {number} index the category to move
   */
  const moveDown = (index: number) => {
    const updatedCategoryTree = arrayMove([...controlCategoryTree], index, index + 1);
    setControlCategoryTree(updatedCategoryTree);
    setWorkingCategoryTree(updatedCategoryTree.filter((category) => category.active));
  };


  /**
   * Toggle a Category
   *
   * @param {number} index the category to toggle
   */
  const toggleCategory = (index: number) => {
    const newControlCategoryTree = [...controlCategoryTree];
    newControlCategoryTree[index].active = !newControlCategoryTree[index].active;
    setControlCategoryTree(newControlCategoryTree);
    setWorkingCategoryTree(newControlCategoryTree.filter((category) => category.active));
  };

  /**
   * render
   */
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="category-sort-selector">
      <DropdownToggle caret size="sm" color="purple">
        <Icon i="sort" size="sm" className="mr-2" />
        Manage Categories &nbsp;
      </DropdownToggle>
      <DropdownMenu>
        {controlCategoryTree.map((category, index) => (
          <div key={category.name} className="selector-item dropdown-item">
            {category.name}
            <div className="buttonbar">
              <Input type="checkbox" checked={category.active} onChange={() => toggleCategory(index)} />
              <Button size="sm" className="bt-secondary ml-2 mr-1" onClick={() => moveUp(index)}>
                <Icon i="caret-up" size="sm" />
              </Button>
              <Button size="sm" className="bt-secondary" onClick={() => moveDown(index)}>
                <Icon i="caret-down" size="sm" />
              </Button>
            </div>
          </div>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
