import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'reactstrap';

import { CollectionWidgetProps } from '../../types/collection.widget.props';
import { CollectionWidget } from './collection.widget';
import RecordActionButtons from '../record-action-buttons/record-action-buttons';

import { hasPermittedAction } from '../../helpers/has-permitted-action.helper';

import { API_ACTION } from '../../constants/api-action.const';
import { CollectionWidgetHeader } from './collection-widget-header';
import { IInvoiceRequestRecord } from '../../types/invoice-request/invoice-request.record.interface';
import { ReportDownloadButton } from '../report-views/report-download-button';
import { ICON } from '../../constants/icon.const';
import { IActionButton } from '../../types/action-button.interface';
import Icon from '../layout-helpers/icon';


export type InvoiceRequestLinesWidgetProps = Omit<CollectionWidgetProps, 'rowData'> & {
  rowData: IInvoiceRequestRecord,
}


const qbAppEndpoint = process.env.REACT_APP_QUICKBOOKS_APP_ENDPOINT;
const qbPath = `${qbAppEndpoint}app/invoice?txnId=`;

const localActionList = ['push-to-quickbooks', 'void-quickbooks'];

/**
 * This widget displays the InvoiceRequest Invoices by wrapping a table widget in a custom widget
 * for displaying other components
 */
export const InvoiceRequestLinesWidget:React.FC<InvoiceRequestLinesWidgetProps> = (props) => {
  const {
    className,
    rowData: invoiceRequest,
    parentId,
    permittedActions,
    widgetPermittedActions,
    isEditingCollectionItem,
    isCreatingCollectionItem,
    isReadOnly,
    showAddBtn,
    description,
    createCollectionItem,
    refreshRecord,
    refreshActions,
    refreshWidgetData,
  } = props;

  /**
   * Create a new InvoiceRequest invoice
   */
  const handleCreateCollectionItem = useCallback(() => {
    if (createCollectionItem) createCollectionItem();
  }, [createCollectionItem]);

  const [localActions, setLocalActions] = useState<IActionButton[]>(
    permittedActions.filter((action) => localActionList.indexOf(action.name) > -1),
  );
  const [localReadOnly, setLocalReadOnly] = useState(invoiceRequest.quickbooks_invoice_id ? true : isReadOnly);

  useEffect(() => {
    setLocalActions(
      permittedActions
        .filter((action) => localActionList.indexOf(action.name) > -1)
        .map((action) => ({ ...action, hideRecordActionButton: false })),
    );
  }, [permittedActions]);

  useEffect(() => {
    setLocalReadOnly(invoiceRequest.quickbooks_invoice_id ? true : isReadOnly);
  }, [invoiceRequest.quickbooks_invoice_id, isReadOnly]);

  // Render
  return (
    <CollectionWidget
      {...props}
      className={classNames('invoice-request-invoices-widget', className)}
      disableHeader
      isReadOnly={localReadOnly}
    >
      <CollectionWidgetHeader
        description={description}

        showAddBtn={
          // pass down setting from the collection widget definition
          showAddBtn &&

          // not in read only mode

          !localReadOnly &&

          // The CREATE action exists in the widgetItemPermittedActions
          !!hasPermittedAction(widgetPermittedActions, API_ACTION.CREATE)
        }

        actions={(
          <RecordActionButtons
            permittedActions={localActions}
            inFooter={false}
            rowData={invoiceRequest}
            showMessage={false}
            onSuccess={() => {
              if (refreshRecord) refreshRecord();
              if (refreshActions) refreshActions();
              if (refreshWidgetData) refreshWidgetData();
            }}
            hasLoaded
          >
            {invoiceRequest.quickbooks_invoice_id && (
              <>
                <Button href={`${qbPath}${invoiceRequest.quickbooks_invoice_id}`} target="_blank" color="success">
                  View in QB
                  <Icon i={ICON.EXTERNAL_LINK} className="pl-2" />
                </Button>
                <ReportDownloadButton
                  url={`/invoice-request/${parentId}/action/get-pdf-preview`}
                  filename="invoice.pdf"
                  caption="Preview PDF"
                  type="pdf"
                  icon={ICON.PDF}
                />
              </>
            )}
          </RecordActionButtons>
        )}

        // Prevent the user from adding another row if they are editing a row
        addBtnDisabled={isEditingCollectionItem || isCreatingCollectionItem || isReadOnly}

        // Pass the event handler back up the chain so that the widget wrapper can add a data row
        onClickAddBtn={handleCreateCollectionItem}
      />
      {invoiceRequest.quickbooks_invoice_id && (
        <div className="p-4">
          <p><strong>Note:</strong></p>
          {' '}
          <p>This invoice has been imported to quickbooks, and can no longer be edited here.</p>
          <p>Additionally, some of the lines may have been changed in QuickBooks by the finance team.</p>
          <p>Please check in QuickBooks for the most up-to-date version</p>
        </div>
      )}
    </CollectionWidget>
  );
};
