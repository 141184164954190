import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';

import { APIContext } from '../../providers/api-provider';

import { usePreviousValue } from '../../../react-hooks/use-previous-value.hook';

import { APIPolyFormProps } from '../../../types/poly-form/api-poly-form.props';
import { IAPIActions } from '../../../types/api-actions.interface';
import { IActionButton } from '../../../types/action-button.interface';
import { ICompanyLocationRecord } from '../../../types/company/company-location.record.interface';
import { ICompanySpaceRecord } from '../../../types/company/company-space.record.interface';
import { ICompanyLocationPortalTreeViewSelectedNodeIdentifier } from '../../../types/portal-tree-view/company-location-portal-tree-view-selected-node-identifier.interface';

import { CompanyLocationsWidgetSelectedLocationDetail } from './company-locations-widget-selected-location-detail';
import { CompanyLocationsWidgetSelectedSpaceDetail } from './company-locations-widget-selected-space-detail';

import { apiAborter } from '../../../helpers/api-aborter.helper';
import { buildAPIRoute } from '../../../helpers/build-api-route.helper';
import { mergeActions } from '../../../utils/helpers';

import { CompanyLocationLocationTypeFormFieldsMap } from '../../../constants/form-fields/company-location-location-type-form-fields-map.const';
import { companySpaceFormFields } from '../../../constants/form-fields/company-space.form-fields';

import { COMPANY_LOCATION_PTV_NODE_TYPE } from '../../../constants/company-locations-ptv-node-type.const';
import { FORM_RENDERER_TYPE } from '../../../constants/form-renderer-type.const';
import { CompanyLocationTypeNameMap, COMPANY_LOCATION_TYPE } from '../../../constants/company-location-type.const';

export type SelectedRecordSupplementaryAPIPolyFormProps = {
  formRendererType: APIPolyFormProps['formRendererType'],
  rendererOptions: APIPolyFormProps['rendererOptions'],
  fields: APIPolyFormProps['fields'],
  baseRoute: APIPolyFormProps['baseRoute'],
  apiRoute: APIPolyFormProps['apiRoute'],
  apiQuery: APIPolyFormProps['apiQuery'],
  possibleActions?: IActionButton[],
  actionHandlers?: Partial<Record<keyof IAPIActions, () => void>>,
}

export type CompanyLocationsWidgetSelectedRecordDetail = {
  className?: string;
  selectedRecord: undefined | ICompanyLocationPortalTreeViewSelectedNodeIdentifier,
  selectedRecordChecksum: number,
  isEditing: boolean,
  isReadOnly?: boolean,
  companyId?: number,
  selectedNodeCount?: number,

  locationsBaseRoute: APIPolyFormProps['baseRoute'],
  locationsApiRoute?: APIPolyFormProps['apiRoute'],
  locationsApiQuery?: APIPolyFormProps['apiQuery'],
  locationsPossibleActions?: IActionButton[],
  locationsActionHandlers?: Partial<Record<keyof IAPIActions, () => void>>,

  spacesBaseRoute: APIPolyFormProps['baseRoute'],
  spacesApiRoute?: APIPolyFormProps['apiRoute'],
  spacesApiQuery?: APIPolyFormProps['apiQuery'],
  spacesPossibleActions?: IActionButton[],
  spacesActionHandlers?: Partial<Record<keyof IAPIActions, () => void>>,

  onStartEditRecord?: (node: ICompanyLocationPortalTreeViewSelectedNodeIdentifier, formData: Partial<ICompanyLocationRecord | ICompanySpaceRecord>) => void,
  onEndEditRecord?: (saveChanges: boolean, node: ICompanyLocationPortalTreeViewSelectedNodeIdentifier, formData?: Partial<ICompanyLocationRecord | ICompanySpaceRecord>) => void,
  onDirtyChange?: (isDirty: boolean) => void,
};

export const CompanyLocationsWidgetSelectedRecordDetail: React.FC<CompanyLocationsWidgetSelectedRecordDetail> = (props) => {
  const {
    className,
    selectedRecord,
    selectedRecordChecksum,
    companyId,
    isEditing,
    isReadOnly = false,
    selectedNodeCount = 0,

    locationsBaseRoute,
    locationsApiRoute,
    locationsApiQuery,
    locationsPossibleActions,
    locationsActionHandlers,

    spacesBaseRoute,
    spacesApiRoute,
    spacesApiQuery,
    spacesPossibleActions,
    spacesActionHandlers,

    onStartEditRecord,
    onEndEditRecord,
    onDirtyChange,
  } = props;

  const { apiFetch } = useContext(APIContext);

  const oldSelectedRecord = usePreviousValue(selectedRecord);
  const oldSelectedRecordChecksum = usePreviousValue(selectedRecordChecksum);

  const abortController = useRef<null | AbortController>(null);

  const locationSupplementaryAPIPolyFormProps: SelectedRecordSupplementaryAPIPolyFormProps = useMemo(() => ({
    formRendererType: FORM_RENDERER_TYPE.COMPANY_LOCATION,
    rendererOptions: {
      showGoogleMap: true,
      showParentLocation: false,
      showLocationType: false,
    },
    fields: CompanyLocationLocationTypeFormFieldsMap[COMPANY_LOCATION_TYPE.SITE],
    baseRoute: locationsBaseRoute,
    apiRoute: locationsApiRoute,
    apiQuery: locationsApiQuery,
    possibleActions: locationsPossibleActions,
    actionHandlers: locationsActionHandlers,
  }), [locationsBaseRoute, locationsApiRoute, locationsApiQuery, locationsPossibleActions, locationsActionHandlers]);

  const spaceSupplementaryAPIPolyFormProps: SelectedRecordSupplementaryAPIPolyFormProps = useMemo(() => ({
    formRendererType: FORM_RENDERER_TYPE.COMPANY_SPACE,
    rendererOptions: {
      showParentLocation: false,
    },
    fields: companySpaceFormFields,
    baseRoute: spacesBaseRoute,
    apiRoute: spacesApiRoute,
    apiQuery: spacesApiQuery,
    possibleActions: spacesPossibleActions,
    actionHandlers: spacesActionHandlers,
  }), [spacesBaseRoute, spacesApiRoute, spacesApiQuery, spacesPossibleActions, spacesActionHandlers]);

  // These are the props that are updated and passed down to the APIPolyForm depending on the selected node
  const [selectedRecordFormProps, setSelectedRecordFormProps] = useState<SelectedRecordSupplementaryAPIPolyFormProps
  & {
    itemCaption: string,
    formCaption: string,
    isLoading: boolean,
    error: null | string,
    primaryKeyValue: null | number,
    formData: undefined | ICompanyLocationRecord | ICompanySpaceRecord,
    formDataChecksum: number,
    permittedActions: IActionButton[],
  }>({
    ...locationSupplementaryAPIPolyFormProps,
    itemCaption: 'Location',
    formCaption: 'Location Details',
    isLoading: false,
    error: null,
    primaryKeyValue: null,
    formData: undefined,
    formDataChecksum: 0,
    permittedActions: [],
  });

  /**
   * Load the data for the selected location
   */
  const loadNodeFormData = useCallback(async (node: ICompanyLocationPortalTreeViewSelectedNodeIdentifier) => {
    setSelectedRecordFormProps((oldProps) => ({
      ...oldProps,
      isLoading: true,
      error: null,
    }));

    const supplementaryAPIPolyFormProps = node.nodeType === COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION
      ? locationSupplementaryAPIPolyFormProps
      : spaceSupplementaryAPIPolyFormProps;

    // eslint-disable-next-line no-nested-ternary
    const newItemCaption = (node.nodeType === COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION)
      ? (node.companyLocationTypeId ? CompanyLocationTypeNameMap[node.companyLocationTypeId] : 'Location')
      : (node.companySpaceType?.name ?? 'Space');

    const fields = (node.nodeType === COMPANY_LOCATION_PTV_NODE_TYPE.LOCATION) && node.companyLocationTypeId
      ? CompanyLocationLocationTypeFormFieldsMap[node.companyLocationTypeId]
      : companySpaceFormFields;

    try {
      // Terminate existing data load
      if (abortController.current) {
        abortController.current.abort();
      }
      // Create a new abort controller
      abortController.current = apiAborter();

      // Call the API
      const response = await apiFetch(
        buildAPIRoute({
          primaryKeyValue: node.id,
          baseRoute: supplementaryAPIPolyFormProps.baseRoute,
          apiRoute: supplementaryAPIPolyFormProps.apiRoute,
          apiQuery: supplementaryAPIPolyFormProps.apiQuery,
          parentId: companyId,
        }),
        {
          method: HTTP_METHOD.GET,
          name: 'CompanyLocationsWidgetSelectedRecordDetail::loadSelectedLocation',
          signal: abortController.current.signal,
        },
      );

      if (response.success) {
        abortController.current = null;

        const availableActions: IAPIActions = response.body.actions;

        setSelectedRecordFormProps((oldProps) => ({
          ...oldProps,
          ...supplementaryAPIPolyFormProps,
          itemCaption: newItemCaption,
          formCaption: `${newItemCaption} Details`,
          isLoading: false,
          error: null,
          primaryKeyValue: node.id,
          formDataChecksum: oldProps.formDataChecksum + 1,
          formData: response.body.data,
          fields,
          permittedActions: mergeActions(availableActions, supplementaryAPIPolyFormProps.possibleActions, supplementaryAPIPolyFormProps.actionHandlers),
        }));
      } else if (!response.aborted) {
        abortController.current = null;
        setSelectedRecordFormProps((oldProps) => ({
          ...oldProps,
          ...supplementaryAPIPolyFormProps,
          itemCaption: newItemCaption,
          formCaption: `${newItemCaption} Details`,
          isLoading: false,
          error: response.error ?? response.body.message ?? 'An unknown error has occurred!',
          primaryKeyValue: node.id,
          formDataChecksum: oldProps.formDataChecksum + 1,
          formData: undefined,
          fields,
          permittedActions: mergeActions([], supplementaryAPIPolyFormProps.possibleActions, supplementaryAPIPolyFormProps.actionHandlers),
        }));
      }
    } catch (error) {
      setSelectedRecordFormProps((oldProps) => ({
        ...oldProps,
        ...supplementaryAPIPolyFormProps,
        itemCaption: newItemCaption,
        formCaption: `${newItemCaption} Details`,
        isLoading: false,
        error: error instanceof Error ? error.message : 'An unknown error has occurred!',
        primaryKeyValue: node.id,
        formDataChecksum: oldProps.formDataChecksum + 1,
        formData: undefined,
        fields,
        permittedActions: mergeActions([], supplementaryAPIPolyFormProps.possibleActions, supplementaryAPIPolyFormProps.actionHandlers),
      }));
    }
  }, [
    apiFetch,
    companyId,
    locationSupplementaryAPIPolyFormProps,
    spaceSupplementaryAPIPolyFormProps,
  ]);


  /**
   * Fired by the APIPolyForm when the user starts editing the record via one of the API Actions
   */
  const handleStartEditRecord = useCallback((id: number, formData: Partial<ICompanyLocationRecord | ICompanySpaceRecord>) => {
    // Pass the event back to the consumer
    if (onStartEditRecord && selectedRecord) {
      onStartEditRecord(selectedRecord, formData);
    }
  }, [selectedRecord, onStartEditRecord]);


  /**
   * Fired by the APIPolyForm when the user finishes editing the record (cancel or save)
   */
  const handleEndEditRecord = useCallback((saveChanges: boolean, id: number, formData?: Partial<ICompanyLocationRecord | ICompanySpaceRecord>) => {
    // Pass the event back to the consumer
    if (onEndEditRecord && selectedRecord) {
      onEndEditRecord(saveChanges, selectedRecord, formData);
    }
  }, [selectedRecord, onEndEditRecord]);


  /**
   * When the selected node changes, update all of the relevant information within the form
   */
  useEffect(() => {
    if (
      // The ne selected node actually exists
      (selectedRecord) &&

      (
        // Old selected node does not exist
        (oldSelectedRecord === undefined) ||

        (
          // Old selected node exists
          (oldSelectedRecord !== undefined) &&

          (
            // Selected node id is different
            (oldSelectedRecord.id !== selectedRecord.id) ||

            // Selected node type is different
            (oldSelectedRecord.nodeType !== selectedRecord.nodeType) ||

            // Selected Node checksum is different
            (oldSelectedRecordChecksum !== selectedRecordChecksum)
          )
        )
      )
    ) {
      loadNodeFormData(selectedRecord);
    }
  }, [selectedRecord, oldSelectedRecord, selectedRecordChecksum, oldSelectedRecordChecksum, loadNodeFormData]);


  // Render
  return (
    <div
      className={classNames('selected-record-detail', className)}
    >
      {/* Render the location details form for the selected location node */}
      {selectedRecord && (selectedRecordFormProps.formRendererType === FORM_RENDERER_TYPE.COMPANY_LOCATION) && (
        <CompanyLocationsWidgetSelectedLocationDetail
          {...selectedRecordFormProps}
          formData={selectedRecordFormProps.formData as ICompanyLocationRecord}
          isEditing={isEditing}
          isReadOnly={selectedRecordFormProps.isLoading || isReadOnly}
          parentId={companyId}
          onStartEditRecord={handleStartEditRecord}
          onEndEditRecord={handleEndEditRecord}
          onFieldChange={onDirtyChange ? () => onDirtyChange(isEditing) : undefined}
        />
      )}

      {/* Render the space details form for the selected space node */}
      { selectedRecord && (selectedRecordFormProps.formRendererType === FORM_RENDERER_TYPE.COMPANY_SPACE) && (
        <CompanyLocationsWidgetSelectedSpaceDetail
          {...selectedRecordFormProps}
          formData={selectedRecordFormProps.formData as ICompanySpaceRecord}
          isEditing={isEditing}
          isReadOnly={selectedRecordFormProps.isLoading || isReadOnly}
          parentId={companyId}
          onStartEditRecord={handleStartEditRecord}
          onEndEditRecord={handleEndEditRecord}
          onFieldChange={onDirtyChange ? () => onDirtyChange(isEditing) : undefined}

          isLoading={selectedRecordFormProps.isLoading}
        />
      )}

      {/* Display a helper indicating how many nodes the user has selected */}
      {!selectedRecord && (selectedNodeCount > 0) && (
        <div
          className="selected-node-count"
        >
          <span>{`${selectedNodeCount} items selected`}</span>
        </div>
      )}
    </div>
  );
};
