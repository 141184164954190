import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Draggable, { ControlPosition } from 'react-draggable';
import classnames from 'classnames';
import mouseWheelListen from 'mouse-wheel';
import {
  Button, Col, ButtonGroup, Navbar, NavbarBrand,
} from 'reactstrap';
import ProgressBar from './progress-bar';
import Icon from './icon';
import { downloadFile } from '../../helpers/download-file.helper';
import { prettyFileSize } from '../../helpers/pretty-file-size.helper';
import { ICON } from '../../constants/icon.const';

const SCALE_DELAY = 1000;

export type PDFViewerProps = {
  isOpen: boolean,
  filePath: string,
  filename: string,
  fileSize: number,
  comment?: string,
  closePdfViewer: () => void,
}

export type PDFViewerState = {
  isLoaded: boolean,
  isBusy: boolean,
  currentPage: number,
  numberPages: number,
  isOpen: boolean,
  isClosing: boolean,
  hasError: boolean,
  scale: number,
  zoom: number,
  scalerLocked: boolean,
  dragPosition: ControlPosition | undefined,
}

/**
 * PDFViewer
 * TODO: This was upgraded pretty much verbatim from the original .JSX and needs a lot of work to be compliant
 *
 * TODO: The component this class is based on is deprecated and has been migrated to a new package
 * @see https://www.npmjs.com/package/react-pdf-js
 * @see https://www.npmjs.com/package/@mikecousins/react-pdf
 *
 * TODO: this also binds many events that shouldn't really be bound unless the pdf viewer is open which can create excess strain on the browser
 *
 * TODO: this needs to use the ReactPortal component not ReactDom.createPortal()
 */
export class PDFViewer extends Component<PDFViewerProps, PDFViewerState> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private PDFLib: any = null;

  private scaleDelay: null | ReturnType<typeof setTimeout> = null;

  // see https://github.com/reactstrap/reactstrap/blob/master/src/Modal.js
  private element: null | HTMLDivElement = null;

  constructor(props: PDFViewerProps) {
    super(props);

    this.state = {
      isLoaded: false,
      isBusy: false,
      hasError: false,
      currentPage: 0,
      numberPages: 0,
      isOpen: props.isOpen,
      isClosing: false,
      scale: 2,
      zoom: 1,
      scalerLocked: false,
      dragPosition: undefined,
    };
  }


  /**
   * @inheritdoc
   */
  componentDidMount(): void {
    this.loadPdfViewer();
  }


  /**
   * @inheritdoc
   */
  componentDidUpdate(): void {
    const { isOpen: propsIsOpen } = this.props;
    const { isOpen: stateIsOpen } = this.state;
    if (propsIsOpen !== stateIsOpen) {
      // toggle PDF viewer when our parent changes isOpen
      this.togglePdfViewer();
    }
  }


  /**
   * @inheritdoc
   */
  componentWillUnmount(): void {
    if (this.element) {
      this.destroy();
    }
  }


  /**
   * PDF viewer method - pdf loaded
   * @param numberPages total PDF file pages
   */
  onPDFLoaded = (numberPages: number): void => {
    this.setState({ currentPage: 1, numberPages, isBusy: false });
  };


  /**
   * PDF viewer method - page left
   */
  handlePrevious = (): void => {
    this.setState((oldState) => ({ currentPage: oldState.currentPage - 1 }));
  };


  /**
   * PDF viewer method - page right
   */
  handleNext = (): void => {
    this.setState((oldState) => ({ currentPage: oldState.currentPage + 1 }));
  };


  /**
   * Lazy loads PDF lib and sets the component state as loaded
   */
  loadPdfViewer = (): void => {
    /**
     * Place modal at end of body
     * TODO: Not happy with this, we should figure out a better way to use a ref on a rendered div instead
     */
    // Check if we can create element
    if (
      !this.element &&
      typeof window !== 'undefined' &&
      window.document &&
      window.document.createElement
    ) {
      this.element = document.createElement('div');
      this.element.setAttribute('tabindex', '-1');
      this.element.style.position = 'relative';
      this.element.style.zIndex = '10000';
      mouseWheelListen(this.element, this.wheel, true);
      document.body.appendChild(this.element);
    }

    if (!this.PDFLib) {
      // Webpack sees this and creates something like 0.chunk.js, 0.chunk.js.map
      // uses https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.0.943/pdf.worker.js for worker
      // or can use node_modules/pdfjs-dist/build/pdf.worker.js
      import('react-pdf-js').then((reactPdfJs) => {
        this.PDFLib = reactPdfJs.default;
        this.setState({
          isLoaded: true,
          isBusy: true,
        });
      });
    }
  };


  /**
   * Toggle PDF viewer modal box
   */
  togglePdfViewer = (): void => {
    if (this.state.isOpen) {
      // Adds a overflow: hidden; to body to prevent scrolling when zooming in and out
      // The image viewer also does this
      document.body.classList.remove('viewer-open');
      if (this.scaleDelay) {
        window.clearTimeout(this.scaleDelay);
      }
      this.scaleDelay = setTimeout(() => {
        this.setState({ isClosing: false });
      }, SCALE_DELAY);
      this.setState({ isOpen: false, isClosing: true });
      this.props.closePdfViewer();
    }
    else {
      // Adds a overflow: hidden; to body to prevent scrolling when zooming in and out
      // The image viewer also does this
      document.body.classList.add('viewer-open');
      if (this.scaleDelay) {
        window.clearTimeout(this.scaleDelay);
      }
      this.setState({
        isOpen: true,
        currentPage: 1,
        numberPages: 1,
      });
    }
  };

  /**
   * Zoom PDF in or out
   * Corresponds to css transform scale values eg. positive is getting bigger
   *
   * @param amount Amount to zoom by positive is zoom IN.
   */
  doZoom = (amount: number): void => {
    let { scale, scalerLocked } = this.state;
    const { zoom: oldZoom } = this.state;
    let zoom = oldZoom + amount;

    // limit zoom to 0.5 scale up to 30X scale zoomed in
    if (zoom > 30) zoom = 30;
    if (zoom < 0.5) zoom = 0.5;

    // delay next scale to prevent canvas errors when
    // quickly switching scale
    // reset back to 1 second delay every mouse scroll tick
    if (this.scaleDelay) {
      window.clearTimeout(this.scaleDelay);
    }
    this.scaleDelay = setTimeout(() => {
      if (!scalerLocked) {
        scalerLocked = true;
        if (zoom >= 0.8 && zoom < 2.2) {
          scale = 2;
        }
        else if (zoom >= 2.2 && zoom < 3.2) {
          scale = 3;
        }
        else if (zoom >= 3.2 && zoom < 4.2) {
          scale = 4;
        }
        else if (zoom >= 4.2) {
          scale = 5;
        }
        else {
          scale = 1;
        }
      }
      this.setState({ zoom, scale, scalerLocked: false });
    }, SCALE_DELAY);

    // Set zoom and scale
    this.setState({
      zoom, scale, dragPosition: undefined, scalerLocked,
    });
  };


  /**
   * Zooms PDF out (shrinks it)
   */
  zoomOut = (): void => {
    this.doZoom(-0.4);
  };


  /**
   * Zooms PDF in (grows it)
   */
  zoomIn = (): void => {
    this.doZoom(0.4);
  };


  /**
   * Zooms PDF in (grows it)
   */
  zoomReset = (): void => {
    this.setState({ zoom: 1, scale: 1, dragPosition: { x: 0, y: 0 } });
  };


  /**
   * Trigger browser download
   */
  download = async (): Promise<void> => downloadFile(this.props.filename, this.props.filePath);


  /**
   * Mouse wheel and swipe control for PDF
   *
   * @param deltaX amount of scrolling horizontally in pixels
   * @param deltaY amount of scrolling vertically in pixels
   *
   * Can also have:
   * @param deltaZ amount of scrolling depth-wise in pixels
   * @param e the MouseEvent object associated with the event
   * @see https://github.com/mikolalysenko/mouse-wheel#api
   */
  wheel = (deltaX: number, deltaY: number): void => {
    // only do stuff if the viewer is open
    if (this.state.isOpen) {
      this.doZoom(deltaY * 0.001);
    }
  };

  /**
   * Reset drag position to null
   * Called to reset the state of dragPosition as if it has a value, the drag
   * position is forced into that value instead of using the Draggable component's
   */
  startDrag = (): void => {
    this.setState({ dragPosition: undefined });
  };

  /**
   * Removes modal from end of body
   */
  destroy(): void {
    if (this.element) {
      document.body.removeChild(this.element);
      this.element = null;
    }
  }

  /**
   * @inheritdoc
   */
  render(): React.ReactPortal | null {
    if (!this.element) return null;

    const {
      isLoaded,
      isBusy,
      isOpen,
      isClosing,
      hasError,
      currentPage,
      numberPages,
      scale,
      zoom,
      dragPosition,
    } = this.state;

    const {
      filePath, filename, fileSize, comment,
    } = this.props;

    // returns <PDFViewer />
    // TODO get the background click to close modal without breaking the drag
    // TODO printing. CORS prevents printing over different origin, need to manually render each
    //   page of the PDF into some iframe window that is on the same origin and print that
    return ReactDOM.createPortal(
      <div
        className={classnames('pdf-viewer', {
          open: isOpen,
          closing: isClosing,
        })}
      >
        <div
          className="viewer-overlay"
          // onKeyDown={this.togglePdfViewer}
          // onClick={this.togglePdfViewer}
          // role="button"
          // tabIndex={-1}
        >
          {isLoaded && filePath && (isOpen || isClosing) && (
            <Draggable position={dragPosition} onMouseDown={this.startDrag}>
              <div className="draggable-container">
                <div
                  className="scale-container"
                  style={{
                    transform: `scale3d(${zoom}, ${zoom}, 1)`,
                  }}
                >
                  {/* eslint-disable-next-line no-undef */}
                  <this.PDFLib
                    file={filePath}
                    scale={scale}
                    onDocumentComplete={this.onPDFLoaded}
                    page={currentPage}
                  />
                  <ProgressBar complete={!isBusy} />
                  {hasError && <Icon className="danger" i={ICON.ERROR} /> }
                </div>
              </div>
            </Draggable>
          )}
          <Navbar color="light" className="toolbar" light fixed="top">
            <div className="controls-and-title">
              {/* File Name */}
              <NavbarBrand>
                <Icon i="file-pdf-o" spaceRight />
                &nbsp;
                {filename}
              </NavbarBrand>

              {/* Page Controls */}
              <div className="controls">
                <ButtonGroup>
                  <Button
                    className="page-previous"
                    disabled={currentPage === 1}
                    onClick={this.handlePrevious}
                  >
                    <Icon i="arrow-left" spaceRight />
                    {' '}
                    Previous
                  </Button>
                  <Button
                    className="page-next"
                    disabled={currentPage === numberPages}
                    onClick={this.handleNext}
                  >
                    Next
                    {' '}
                    <Icon i="arrow-right" spaceLeft />
                  </Button>
                  <span className="navbar-text">
                    &nbsp; Page
                    {' '}
                    {currentPage}
                    {' '}
                    of
                    {' '}
                    {numberPages}
                  </span>
                </ButtonGroup>

                {/* Download Controls */}
                <Button onClick={this.download} title="Download this PDF">
                  <Icon i="download" />
                </Button>
                <span className="navbar-text">
                  {prettyFileSize(fileSize)}
                </span>

                {/* Print */}
                {/* <Col>
                  <Button onClick={this.print} title="Print">
                  <Icon i="print" />
                  </Button>
                </Col> */}

                {/* zoom controls */}
                <ButtonGroup>
                  <Button className="text-nowrap" onClick={this.zoomOut} title="Zoom out">
                    <Icon i="search-minus" spaceRight />
                  </Button>
                  <Button
                    className="text-nowrap reset-zoom"
                    onClick={this.zoomReset}
                    title="Reset Zoom to 100%"
                  >
                    <Icon i="search" spaceRight />
                    {' '}
                    Zoom
                    {' '}
                    {Math.floor(zoom * 100)}
                    %
                  </Button>
                  <Button className="text-nowrap" onClick={this.zoomIn} title="Zoom in">
                    <Icon i="search-plus" spaceLeft />
                  </Button>
                </ButtonGroup>

                {/* Close Button */}
                <Col className="text-right">
                  <Button className="close" onClick={this.togglePdfViewer}>
                    &times;
                  </Button>
                </Col>
              </div>
            </div>

            {comment && (
              <div className="comment">
                {/* eslint-disable-next-line react/no-danger */}
                <span className="navbar-text" dangerouslySetInnerHTML={{ __html: comment }} />
              </div>
            )}
          </Navbar>
        </div>
      </div>,
      this.element,
    );
  }
}
