import React from 'react';
import PropTypes from 'prop-types';
import { Button, CardTitle } from 'reactstrap';
import rollingSvg from '../../../images/Rolling-1s-22px.svg';

const ApiConnector = ({
  connectHandler, status, color, title,
}) => (
  <div>
    <CardTitle>{title}</CardTitle>
    <div>
      <div>
        <p>{status.message}</p>
      </div>
      {status.data && (typeof status.data === 'object') && Object.entries(status.data).map(([key, value]) => (
        <div key={key}>
          <span><strong>{`${key}: `}</strong></span>
          <span>{value}</span>
        </div>
      ))}

      {status.loading ? (
        <p>
          Verifying... &nbsp;
          <img src={rollingSvg} alt="Verifying..." />
        </p>
      ) : (
        <p className={status.connected ? 'text-success' : 'text-danger'}>
          {status.connected ? 'connected  ' : 'not connected  '}
        </p>
      )}
      {connectHandler && (
        <Button color={color} onClick={connectHandler}>
          connect
        </Button>
      )}
    </div>

  </div>
);

ApiConnector.defaultProps = {
  status: {
    message: '',
    loading: true,
    connected: false,
  },
  color: 'default',
  title: 'Current Status',
  connectHandler: null,
};

ApiConnector.propTypes = {
  status: PropTypes.shape({
    message: PropTypes.string,
    loading: PropTypes.bool,
    connected: PropTypes.bool,
    data: PropTypes.shape({}),
  }),
  color: PropTypes.string,
  title: PropTypes.string,
  connectHandler: PropTypes.func,
};

export default ApiConnector;
