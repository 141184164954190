export const COMPANY_LOCATION_PTV_MENU_ITEM_TYPE = {
  DISABLE_SAFE_MODE: 'disable-safe-mode',
  ENABLE_SAFE_MODE: 'enable-safe-mode',
  EXPAND_ALL: 'expand-all',
  COLLAPSE_ALL: 'collapse-all',
  CREATE: 'create',
  CREATE_SITE: 'create-site',
  CREATE_BUILDING: 'create-building',
  CREATE_LEVEL: 'create-level',
  CREATE_ZONE: 'create-zone',
  CREATE_SPACE: 'create-space',
  CONVERT: 'convert',
  CONVERT_SITE: 'convert-site',
  CONVERT_BUILDING: 'convert-building',
  CONVERT_LEVEL: 'convert-level',
  CONVERT_ZONE: 'convert-zone',
  MOVE_UP: 'move-up',
  DELETE: 'delete',
  SHOW_ROOT_NODE: 'show-root-node',
  HIDE_ROOT_NODE: 'hide-root-node',
  OPTIONS: 'options',
} as const;
export type COMPANY_LOCATION_PTV_MENU_ITEM_TYPE = typeof COMPANY_LOCATION_PTV_MENU_ITEM_TYPE;
export type A_COMPANY_LOCATION_PTV_MENU_ITEM_TYPE = COMPANY_LOCATION_PTV_MENU_ITEM_TYPE[keyof COMPANY_LOCATION_PTV_MENU_ITEM_TYPE];
