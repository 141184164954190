import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { SERObject } from '../../helpers/ser-object.helper';
import rollingSvg from '../../images/Rolling-1s-22px.svg';
import Icon from '../layout-helpers/icon';
import { ICON } from '../../constants/icon.const';
import ProgressBar from '../layout-helpers/progress-bar';

export type ModalWizardItemProgressProps<T> = {
  className?: string,
  label: string,
  result: null | SERObject<T>,
  aborted?: boolean,
  progress?: number,
};

export const ModalWizardItemProgress = <T, >({
  className,
  label,
  result,
  aborted,
  progress,
}: ModalWizardItemProgressProps<T>): ReactElement => (
    (
      <div className={classNames('wizard-item-progress', className, {
        aborted,
      })}
      >
        <div className="item-label-wrapper">
          {/* Item Processing */}
          {!aborted && !result?.success && !result?.error && (
            <img className="item-icon spinner" src={rollingSvg} alt="Please Wait..." />
          )}

          {/* Item Aborted */}
          {aborted && (
            <Icon i={ICON.CROSS} className="item-icon" />
          )}

          {/* Item Created Successfully */}
          {result?.success && (
            <Icon i={ICON.CHECK} className="item-icon success" />
          )}

          {/* Item Creation Failed! */}
          {!!result?.error && (
            <Icon i={ICON.CROSS} className="item-icon danger" />
          )}

          {/* Item label */}
          <span className="item-label">{label}</span>
        </div>

        {/* Progress bar is required */}
        {(progress !== undefined) && (
          <div className="item-progress-wrapper">
            <ProgressBar
              tiny
              start={0}
              progress={progress ?? 0}
              complete={progress === 100}
              error={result && !!result.error}
            />
          </div>
        )}

        {/* Item encountered Errors */}
        {result && !result.success && !!result.error && (
          <ul className="item-error-wrapper">
            {/* Proper Error */}
            {(result.error instanceof Error) && (
              <li>{result.error.message}</li>
            )}

            {/* Plain string Error */}
            {(typeof result.error === 'string') && (
              <li>{result.error}</li>
            )}

            {/* string array */}
            {Array.isArray(result.error) && (result.error).map((error: string) => (<li key={error}>{error}</li>))}

            {/* Error object */}
            {(typeof result.error === 'object') && Object.keys(result.error).map((errorKey) => {
              const error = (result.error as Record<string, string[]>)[errorKey];

              // Array of values, return a nested list
              if (Array.isArray(error) && error.length > 1) {
                return (
                  <li key={errorKey}>
                    {`${errorKey}:`}
                    <ul>
                      {error.map((errorItem) => (<li key={errorItem}>{errorItem}</li>))}
                    </ul>
                  </li>
                );
              }

              // Single value. Don't bother with a nested list.
              return (
                <li key={errorKey}>
                  {`${errorKey}: ${error[0] ?? error}`}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    )
  );
