import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { NewRecordModalResult } from '../../types/modal/modal-result';
import { ITaskTemplateRecord } from '../../types/internal-project/task-template.record.interface';

import { ModalContext } from '../modals/modal-context';

import DataTablePage from './data-table-page';

import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { DataTablePageProps } from '../../types/data-table-page.props';

export type TaskTemplatesDataTablePageProps = DataTablePageProps;

/**
 * Display the "Task Templates" data table as a whole page
 */
export const TaskTemplatesDataTablePage: React.FC<TaskTemplatesDataTablePageProps> = ({
  baseFilters,
  baseFlags,
  match,
  location,
  availableActions,
  possibleActions,
  children,
}) => {
  const history = useHistory();
  const { showModal } = useContext(ModalContext);

  const actionHandlers = {
    create: () => {
      showModal<NewRecordModalResult<ITaskTemplateRecord>>(MODAL_TYPE.NEW_TASK_TEMPLATE, {
        onModalComplete: (modalResult) => {
          if (modalResult.success) history.push(`/internal-projects/task-templates/${modalResult.newRecord.id}`);
        },
      });
    },
  };

  return (
    <DataTablePage
      title="Task Templates"
      tableIdentifier={TABLE_IDENTIFIER.TASK_TEMPLATES_TABLE}
      actionHandlers={actionHandlers}
      baseFilters={baseFilters}
      baseFlags={baseFlags}
      match={match}
      history={history}
      location={location}
      availableActions={availableActions}
      possibleActions={possibleActions}
    >
      {children}
    </DataTablePage>
  );
};
