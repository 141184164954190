import React, { useEffect, useState } from 'react';
import { Card, CardBody, Button, CardText } from 'reactstrap';
import Icon from '../layout-helpers/icon';
import { QuickBooksPushForm } from '../custom-forms/quickbooks-push-form';
import { APIRecord } from '../../types/api-record.interface';
import { ICompanyRecord } from '../../types/company/company.record.interface';
import { INVOICE_REQUEST_TYPE } from '../../constants/invoice-request-type.const';
import { SERVICE_JOB_BILLING_TYPE } from '../../constants/service-job-billing-type-name-map.const';
import RecordActionButtons from '../record-action-buttons/record-action-buttons';
import { CollectionWidgetHeader } from './collection-widget-header';
import { CollectionWidgetProps } from '../../types/collection.widget.props';
import { IActionButton } from '../../types/action-button.interface';
import { ReportDownloadButton } from '../report-views/report-download-button';
import { ICON } from '../../constants/icon.const';
import { CollectionWidget } from './collection.widget';

const qbAppEndpoint = process.env.REACT_APP_QUICKBOOKS_APP_ENDPOINT;
const qbPath = `${qbAppEndpoint}app/invoice?txnId=`;

const localActionList = ['push-to-quickbooks', 'void-quickbooks'];

export type InvoiceRequestQuickbooksWidgetProps = Omit<CollectionWidgetProps, 'rowData, baseRoute, refreshRecord, parentId'> & {
  rowData: APIRecord & {
    id: number,
    project_number: string,
    quickbooks_id: number | null,
    name: string,
    client: ICompanyRecord,
  },
  refreshRecord: () => void,
  parentId: number,
  baseRoute: string,
}

export const InvoiceRequestQuickbooksWidget: React.FC<InvoiceRequestQuickbooksWidgetProps> = (props) => {
  const {
    rowData, baseRoute, parentId, refreshRecord, refreshActions, refreshWidgetData, permittedActions,
  } = props;
  const [localActions, setLocalActions] = useState<IActionButton[]>(
    permittedActions.filter((action) => localActionList.indexOf(action.name) > -1),
  );

  useEffect(() => {
    setLocalActions(
      permittedActions
        .filter((action) => localActionList.indexOf(action.name) > -1)
        .map((action) => ({ ...action, hideRecordActionButton: false })),
    );
  }, [permittedActions]);

  let relationshipData;
  let titleText = `This is a ${rowData.type.name} Invoice Request...`;
  let type = rowData.type.name;
  if (rowData.type_id === INVOICE_REQUEST_TYPE.PROJECT) {
    relationshipData = rowData.project;
  }
  if (rowData.type_id === INVOICE_REQUEST_TYPE.CONTRACT) {
    relationshipData = rowData.contract;
  }
  if (rowData.type_id === INVOICE_REQUEST_TYPE.JOB) {
    relationshipData = rowData.service_job;
    if (rowData.service_job.billing_type_id === SERVICE_JOB_BILLING_TYPE.DIRECT) {
      titleText = 'This is a Service Job Invoice Request set to Client Direct billing...';
      type = 'Client';
    }
    if (rowData.service_job.billing_type_id === SERVICE_JOB_BILLING_TYPE.CONTRACT) {
      relationshipData = rowData.service_job.contract;
      titleText = 'This is a Service Job Invoice Request set to Contract billing...';
      type = 'Contract';
    }
    if (rowData.service_job.billing_type_id === SERVICE_JOB_BILLING_TYPE.PROJECT) {
      titleText = 'This is a Service Job Invoice Request set to Project billing...';
      relationshipData = rowData.service_job.project;
      type = 'Project';
    }
  }
  return (
    <CollectionWidget className="widget project-quickbooks-widget" {...props} disableHeader>
      <CollectionWidgetHeader
        description={titleText}
        showAddBtn={false}
        actions={(
          <RecordActionButtons
            permittedActions={localActions}
            inFooter={false}
            rowData={rowData}
            showMessage={false}
            onSuccess={() => {
              if (refreshRecord) refreshRecord();
              if (refreshActions) refreshActions();
              if (refreshWidgetData) refreshWidgetData();
            }}
            hasLoaded
          >
            {rowData.quickbooks_invoice_id && (
              <>
                <Button href={`${qbPath}${rowData.quickbooks_invoice_id}`} target="_blank" color="success">
                  View Invoice in QB
                  <Icon i={ICON.EXTERNAL_LINK} className="pl-2" />
                </Button>
                <ReportDownloadButton
                  url={`/invoice-request/${parentId}/action/get-pdf-preview`}
                  filename="invoice.pdf"
                  caption="Preview PDF"
                  type="pdf"
                  icon={ICON.PDF}
                />
              </>
            )}
          </RecordActionButtons>
        )}
      />
      <Card>
        <CardBody>
          <CardText>Please make sure the client is correct before proceeding.</CardText>
          <QuickBooksPushForm
            entityName={type}
            baseRoute={baseRoute}
            onLinkComplete={refreshRecord}
            parentId={parentId}
            rowData={relationshipData}
          />
          {/* <pre>
            {JSON.stringify(rowData, null, 2)}
          </pre> */}
        </CardBody>
      </Card>
    </CollectionWidget>
  );
};
