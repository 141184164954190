import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import moment from 'moment';
import { debugLog } from '../../utils/helpers';
import { APIContextProps, connectToAPIProvider } from '../providers/api-provider';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { ApiQueryCacheData, storeApiQueryCache } from '../../actions/api-query-cache-actions';

interface RootState {
  apiQueryCache: {
    [key: string]: ApiQueryCacheData,
  }
}

const mapState = (state: RootState) => ({
  apiQueryCache: state.apiQueryCache,
});

const mapDispatch = {
  storeQueryCache: storeApiQueryCache,
};

const connector = connect(mapState, mapDispatch);

// The inferred type will look like:
// {isOn: boolean, storeApiQueryCache: () => void}
type PropsFromRedux = ConnectedProps<typeof connector>

export type ApiQueryDataLoaderProps = PropsFromRedux & {
  apiQueryUrl: string,
  apiQueryCacheName?: string, // @todo restrict to specific list
  apiProvider: APIContextProps,
  // return false to not render a page without results (used to wait until valid results have been retrieved)
  onLastPageExceeded?: (pagination: {
    total: number,
    perPage: number,
    currentPage: number,
  }) => boolean,
  render: (renderParams: {
    response: null | Record<string, unknown>,
    isLoading: boolean,
    hasError: boolean,
    error: null | string,
    hasLoadedOnce: boolean,
    forceRefreshData: () => void,
  }) => null | React.ReactNode;
};

export type ApiQueryDataLoaderState = {
  response: null | Record<string, unknown>,
  isLoading: boolean,
  hasError: boolean,
  error: null | string,
  hasLoadedOnce: boolean,
};
/**
 * ApiQueryDataLoader
 *
 * Container component - Loads data
 *
 * TODO
 *  handle multiple queries at once (using an array or map) so consumers
 *  aren't forced to nest the component multiple times for multiple queries
 */
class ApiQueryDataLoaderComponent extends React.Component<ApiQueryDataLoaderProps, ApiQueryDataLoaderState> {
  abortController: null | AbortController = null;

  /**
   * @constructor
   */
  constructor(props: ApiQueryDataLoaderProps) {
    super(props);
    if (props.apiQueryCacheName) {
      const { queryResponse } = props.apiQueryCache[props.apiQueryCacheName];
      this.state = {
        response: queryResponse,
        isLoading: queryResponse !== null,
        hasLoadedOnce: queryResponse !== null,
        hasError: false,
        error: null,
      };
    } else {
      this.state = {
        response: null,
        isLoading: true,
        hasError: false,
        error: null,
        hasLoadedOnce: false,
      };
    }
  }

  /**
   * @inheritdoc
   */
  componentDidMount(): void {
    const { apiQueryUrl, apiQueryCacheName, apiQueryCache } = this.props;
    const { hasLoadedOnce, response } = this.state;
    if (apiQueryCacheName && hasLoadedOnce && response !== null) {
      const { lastRefresh, expiresInMinutes, queryResponse } = apiQueryCache[apiQueryCacheName];
      if (expiresInMinutes === undefined || (lastRefresh && moment(lastRefresh).diff(moment(), 'minutes') < expiresInMinutes)) {
        this.setState({
          response: queryResponse,
          isLoading: false,
          hasLoadedOnce: true,
          hasError: false,
          error: null,
        });
        // return early, no need to load right now
        return;
      }
    }
    this.loadResponse(apiQueryUrl);
  }


  /**
   * @inheritdoc
   */
  shouldComponentUpdate(nextProps: ApiQueryDataLoaderProps): boolean {
    const { apiQueryUrl } = this.props;

    // reload if the query changes
    debugLog(
      '[ApiQueryDataLoader:shouldComponentUpdate]',
      'info',
      { message: 'Deciding whether to load data...', old: apiQueryUrl, new: nextProps.apiQueryUrl },
      '👉',
    );

    if (nextProps.apiQueryUrl !== apiQueryUrl) {
      debugLog('[ApiQueryDataLoader:shouldComponentUpdate]', 'warn', '...Loading data', '👉');
      this.loadResponse(nextProps.apiQueryUrl);
      return false;
    }

    debugLog('[ApiQueryDataLoader:shouldComponentUpdate]', 'success', '...No loading required', '👉');
    return true;
  }


  /**
   * @inheritdoc
   */
  componentWillUnmount(): void {
    if (this.abortController) this.abortController.abort();
  }


  /**
   * Forces data to be reloaded
   */
  forceRefreshData = (): void => {
    const { apiQueryUrl } = this.props;
    this.loadResponse(apiQueryUrl);
  }


  /**
   * Load the data from an api endpoint
   *
   * @param {string} apiQueryUrl
   */
  loadResponse = (apiQueryUrl: ApiQueryDataLoaderProps['apiQueryUrl']): void => {
    this.setState({ isLoading: true }, async () => {
      const { apiProvider: { apiFetch }, storeQueryCache, apiQueryCacheName } = this.props;

      // Abort any previous load
      if (this.abortController) this.abortController.abort();
      this.abortController = apiAborter();

      const response = await apiFetch(apiQueryUrl, {
        signal: this.abortController.signal,
      });

      if (response.success) {
        this.abortController = null;
        const { onLastPageExceeded } = this.props;

        // if (not true that (we should halt render)), do render
        if (!(
          typeof onLastPageExceeded === 'function'
          && response.body
          && response.body.data instanceof Array
          && response.body.data.length === 0
          && response.body.meta
          && response.body.meta.total !== 0
          && response.body.meta.per_page
          && response.body.meta.current_page
          && (onLastPageExceeded({
            total: response.body.meta.total,
            perPage: response.body.meta.per_page,
            currentPage: response.body.meta.current_page,
          }) === false)
        )) {
          if (apiQueryCacheName) {
            storeQueryCache(
              apiQueryCacheName,
              {
                lastRefresh: moment().format(),
                queryResponse: response.body,
              },
            );
          }
          this.setState({
            isLoading: false, hasError: false, error: null, response: response.body, hasLoadedOnce: true,
          });
        }
      } else if (!response.aborted) {
        this.abortController = null;
        this.setState({
          isLoading: false, hasError: true, error: response.error, hasLoadedOnce: true,
        });
      }
    });
  }


  /**
   * @inheritdoc
   */
  render(): React.ReactNode {
    const { render } = this.props;
    const {
      isLoading, response, hasError, error, hasLoadedOnce,
    } = this.state;

    // Follow Render Props design pattern
    // @see https://chrisnoring.gitbooks.io/react/content/patterns/render-props.html
    return render({
      response, isLoading, hasError, error, hasLoadedOnce, forceRefreshData: this.forceRefreshData,
    });
  }
}

export const ApiQueryDataLoader = connector(connectToAPIProvider<Omit<ApiQueryDataLoaderProps, 'apiProvider'>>(ApiQueryDataLoaderComponent));
