import BaseApiQueryDefinition from './base-api-query-definition';


/**
 * @class
 * @name ApiNewsQueryDefinition
 */
class ApiNewsQueryDefinition extends BaseApiQueryDefinition {
  /**
   * @inheritdoc
   */
  routePath = '/news';

  /**
   * @inheritdoc
   */
  pageSize = 12; // News items are layed out in grids of 4 or 3
}

export default ApiNewsQueryDefinition;
