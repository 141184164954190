import React from 'react';
import PropTypes from 'prop-types';
import IntegrationPanel from './integration-panel';
import ApiConnector from './api-connector';

const DtoolsPanel = ({ status }) => (
  <IntegrationPanel title="Dtools" color="warning">
    <ApiConnector title="Their Api Status" status={status.dtools} color="warning" />
    <ApiConnector title="Our Api Status" status={status.cidtools} color="warning" />
  </IntegrationPanel>
);

DtoolsPanel.propTypes = {
  status: PropTypes.shape({
    dtools: PropTypes.shape({}),
    cidtools: PropTypes.shape({}),
  }),
};

DtoolsPanel.defaultProps = {
  status: {
    dtools: {
      message: '',
      loading: true,
      connected: false,
    },
    cidtools: {
      message: '',
      loading: true,
      connected: false,
    },
  },
};

export default DtoolsPanel;
