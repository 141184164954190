/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable-next-line react/no-danger */
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import { Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import { UserAvatar } from '../user-profiles/user-avatar';
import { NewNewsService } from '../../utils/new-news-service';
import { DATE_FORMAT } from '../../constants/date-format.const';
import StatusBadge from '../data-format/status-badge';
import { NEWS_STATUS, NEWS_STATUS_MAP } from '../../constants/news-status.const';


/**
 * @description
 * Generates a fake news article
 *
 * @param {boolean} [isPrimary=false]
 * @returns {React.Component}
 */
const getPlaceholderJSX = (isPrimary = false) => (
  <div role="article" className={classNames('article', { primary: isPrimary }, 'fake')}>
    <div className="inner-wrapper">
      <div className="image-container">
        <div className="image" />
      </div>

      <div className="text-container">
        <div className="text-wrapper">
          <div className="text-background" />
          <div className="header"><div className="placeholder" /></div>
          <div className="article-info">
            <div className="avatar-wrapper"><div className="placeholder" /></div>
            <div className="author-and-date">
              <div className="author"><div className="placeholder" /></div>
              <div className="publish-date"><div className="placeholder" /></div>
            </div>
          </div>
          <div className="teaser-text">
            <div className="placeholder" />
            <div className="placeholder" />
            <div className="placeholder" />
          </div>
        </div>
      </div>
    </div>
  </div>
);


/**
 * @description
 * Generate the JSX for an article
 *
 * @param {{
 *  id: number,
 *  title: string,
 *  teaser: string,
 *  published_at?: string,
 *  thumbnail_url: string,
 *  author: { name: string }
 * }} article
 * @param {boolean} isPrimary
 * @param {object} articleReturnToLocation
 *
 * @returns {React.Component}
 */
const getArticleJSX = (article, isPrimary, articleReturnToLocation) => {
  const publishedDate = moment(article.published_at);
  const articleIsNew = NewNewsService.isArticleNew(article, publishedDate);

  let linkUrl = `/comms/news/${article.id}`;

  if (articleReturnToLocation) {
    linkUrl += `?art=${encodeURIComponent(`${articleReturnToLocation.pathname}${articleReturnToLocation.search}`)}`;
  }

  return (
    <Link
      to={linkUrl}
      role="article"
      className={classNames('article', { primary: isPrimary })}
    >
      <div className="inner-wrapper">
        <div
          className="image-container"
        >
          <div className="image" style={{ backgroundImage: `url('${article.thumbnail_url}')` }} />
        </div>

        <div className="text-container">
          <div className="text-wrapper">
            <div className="text-background" />
            <div className="header"><span>{article.title}</span></div>
            <div className="article-info">
              <>
                <div className="avatar-wrapper">
                  <UserAvatar className="avatar" name={article.author.name} />
                </div>
                <div className="author-and-date">
                  <div className="author"><span>{article.author.name}</span></div>
                  <div className="publish-date">
                    {/* Published articles get a published date */}
                    {article.status_id === NEWS_STATUS.PUBLISHED && (
                      <>
                        {publishedDate.isValid() && (
                          <span>{publishedDate.format(DATE_FORMAT.NEWS)}</span>
                        )}
                        {articleIsNew && (
                          <Badge color="warning">NEW!</Badge>
                        )}
                      </>
                    )}

                    {/* Un published articles get a status badge */}
                    {article.status_id !== NEWS_STATUS.PUBLISHED && (
                      <StatusBadge status={NEWS_STATUS_MAP[article.status_id]} />
                    )}
                  </div>
                </div>
              </>
            </div>
            {/* eslint-disable-next-line react/no-danger */}
            <div className="teaser-text" dangerouslySetInnerHTML={{ __html: article.teaser }} />
          </div>
        </div>
      </div>
    </Link>
  );
};

/**
 * A single News Article Teaser
 *
 * Presentational component
 *
 * @param {{
 *  isPlaceholder: boolean,
 *  isPrimary: boolean,
 *  key: null | string | number,
 *  article: null | {
 *    id: number,
 *    title: string,
 *    teaser: string,
 *    published_at?: string,
 *    thumbnail_url: string,
 *    author?: { name: string }
 *  },
 * }} param0
 */
const NewsArticleTeaser = ({
  isPlaceholder, isPrimary, article, articleReturnToLocation,
}) => {
  if (isPlaceholder) return getPlaceholderJSX(isPrimary);

  // must provide an article if no placeholder is provided
  if (!article) throw new TypeError('A News article must be provided if the NewsArticleTeaser is not a placeholder.');

  return (
    <>
      {getArticleJSX(article, isPrimary, articleReturnToLocation)}
    </>
  );
};


NewsArticleTeaser.propTypes = {
  isPlaceholder: PropTypes.bool.isRequired,
  isPrimary: PropTypes.bool.isRequired,
  article: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    teaser: PropTypes.string.isRequired,
    published_at: PropTypes.string,
    thumbnail_url: PropTypes.string.isRequired,
    author: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
  }),
  articleReturnToLocation: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
};

NewsArticleTeaser.defaultProps = {
  article: null,
  articleReturnToLocation: null,
};

export default NewsArticleTeaser;
