import moment from 'moment';
import React, { SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Alert, Button, Card, CardHeader, Container, Form, Input, InputGroup, InputGroupAddon, Table } from 'reactstrap';
import { COLUMN_FORMAT } from '../../constants/column-format.const';
import { DATE_FORMAT } from '../../constants/date-format.const';
import { ICON } from '../../constants/icon.const';
import { apiAborter } from '../../helpers/api-aborter.helper';
import { IMyobSupplierOrder } from '../../types/integration/myob/myob-supplier-order.interface';
import PageHeader from '../app-layout/page-header';
import Icon from '../layout-helpers/icon';
import { APIContext } from '../providers/api-provider';
import { formatValueString } from '../render-functions';

const companyId = process.env.REACT_APP_MYOB_COMPANY_ID;

export const MyobPoQuickSearchPage: React.FC = () => {
  const urlQuery = new URLSearchParams(useLocation().search);
  const [searchString, setSearchString] = useState(String(urlQuery.get('search')));
  const [orders, setOrders] = useState<IMyobSupplierOrder[]|null>(null);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [orderLoadingError, setOrderLoadingError] = useState<string|null>(null);
  const { apiFetch } = useContext(APIContext);

  const searchOrdersAbortController = useRef<AbortController | null>(null);

  /**
   * Load Vendor Orders
   *
   * @param {string} vendorId
   */
  const searchForPo = useCallback(() => {
    if (searchOrdersAbortController.current) {
      searchOrdersAbortController.current.abort();
    }
    searchOrdersAbortController.current = apiAborter();
    setOrderLoadingError(null);
    setOrdersLoading(true);
    setOrders(null);
    apiFetch(
      `/myob-advanced-connector/supplier-order?search=${searchString}&detailed=true`,
      {
        name: 'DToolsProjectOrders:loadOrders',
        signal: searchOrdersAbortController?.current?.signal,
      },
    ).then((response) => {
      setOrdersLoading(false);
      if (response.success) {
        setOrders(response.body);
      } else if (!response.aborted) {
        setOrderLoadingError(response.body.message || 'Could not retrieve an error message');
      }
    });
  }, [apiFetch, searchString]);

  /**
   * Component mount effect
   */
  useEffect(() => {
    searchForPo();
    return () => {
      if (searchOrdersAbortController.current) {
        searchOrdersAbortController.current.abort();
      }
    };
  }, [searchForPo]);

  return (
    <Container fluid>
      <PageHeader title="Purchase Order Quick Search">
        <h4 className="text-themecolor">
          Purchase Order Quick Search
          <Form inline onSubmit={(e: SyntheticEvent) => { e.preventDefault(); e.stopPropagation(); }} className="pull-right">
            <InputGroup>
              <Input
                type="text"
                value={searchString}
                onChange={((e: React.FormEvent<HTMLInputElement>) => setSearchString(e.currentTarget.value))}
                placeholder="PO Quick Search"
                bsSize="sm"
              />
              <InputGroupAddon addonType="append">
                <Button size="sm"><Icon i={ICON.SEARCH} /></Button>
              </InputGroupAddon>
            </InputGroup>
          </Form>

        </h4>
      </PageHeader>

      <Card>
        <CardHeader className="bg-purple text-white">
          <h3>Purchase Order Matches</h3>
        </CardHeader>
        {orderLoadingError && (
          <Alert color="danger" className="mb-0">
            <h4 className="alert-title">Search Error:</h4>
            {orderLoadingError}
          </Alert>
        )}
        <Table bordered striped hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Vendor</th>
              <th>Number</th>
              <th>Project</th>
              <th>Description</th>
              <th className="text-right">Line Total</th>
              <th className="text-right">Order Total</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {ordersLoading && (
              <tr>
                <td colSpan={8}>
                  <Icon i="rolling" />
                  {' '}
                  Loading Matches...
                </td>
              </tr>
            )}
            {orders && orders.length === 0 && (
              <tr>
                <td colSpan={8}>
                  No Matches Found.
                </td>
              </tr>
            )}
            {orders && orders.map((order: IMyobSupplierOrder) => {
              const matchExpression = /(P[\d]{5}[\w]{3,4})+/ig;
              const projectMatchArray = order.Description.value.match(matchExpression);
              const projectMatch = projectMatchArray ? projectMatchArray[0] || '' : '';
              const projectId = projectMatch.match(/([\d]{5})+/ig);
              return (
                <React.Fragment key={order.OrderNbr.value}>
                  <tr>
                    <td>{moment(order.Date.value).format(DATE_FORMAT.AUSTRALIAN_READABLE)}</td>
                    <td>
                      <a href={`https://ci.myobadvanced.com/Main?CompanyID=${companyId}&ScreenId=AP303000&AcctCD=${order?.VendorID?.value}`} target="_new">
                        {order?.VendorID?.value}
                        &nbsp;
                        {order?.AccountName?.value}
                      </a>
                    </td>
                    <td>
                      <a href={`https://ci.myobadvanced.com/Main?CompanyID=${companyId}&ScreenId=PO301000&OrderType=RO&OrderNbr=${order?.OrderNbr?.value}`} target="_new">
                        {order.OrderNbr.value}
                      </a>
                    </td>
                    <td>
                      {projectId ? (
                        <Link to={`/crm/projects/${projectId}/myoborders`}>
                          {projectMatch}
                        </Link>
                      ) : projectMatch}
                    </td>
                    <td>
                      {order.Description.value}
                    </td>
                    <td className="text-right">
                      {formatValueString(order.LineTotal.value, COLUMN_FORMAT.CURRENCY)}
                    </td>
                    <td className="text-right">
                      {formatValueString(order.OrderTotal.value, COLUMN_FORMAT.CURRENCY)}
                    </td>
                    <td>{order.Status.value}</td>
                  </tr>
                  {order.Details && order.Details.length > 0 && (
                    <tr>
                      <td colSpan={8} style={{ padding: '0 0 0 2rem' }} className="bg-gray">
                        <div style={{ backgroundColor: 'white' }}>
                          <Table className="purple-table" bordered striped hover>
                            <thead>
                              <th>Description</th>
                              <th className="text-right">Qty</th>
                              <th className="text-right">Unit Cost</th>
                              <th className="text-right">Value</th>
                              <th>State</th>
                              <th>Project</th>
                              <th>Category</th>
                            </thead>
                            <tbody>
                              {order.Details.map((detail) => (
                                <tr key={detail.id}>
                                  <td>{detail.LineDescription.value}</td>
                                  <td>{detail.OrderQty.value}</td>
                                  <td className="text-right">{formatValueString(detail.UnitCost.value, COLUMN_FORMAT.CURRENCY)}</td>
                                  <td className="text-right">{formatValueString(detail.ExtendedCost.value, COLUMN_FORMAT.CURRENCY)}</td>
                                  <td>{detail.Subaccount.value === '0' ? '' : detail.Subaccount.value}</td>
                                  <td>{detail.Project.value}</td>
                                  <td>{detail.ProjectTask.value}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </Card>
    </Container>
  );
};
