import {
  makeFilterString, makeSortStringFromCustomSortArray,
  // TODO
  // makeSortStringFromCustomSortArray,
} from '../../utils/api-calls';
import { debugLog } from '../../utils/helpers';
import ApiQueryAssertions from '../api-query-assertions';
import BaseApiQueryDefinition from '../definitions/base-api-query-definition';


/**
 * @class
 * @name ApiQueryManager
 *
 * @description
 * Writes to (through helper methods) an ApiQueryDefinition
 *
 * Consumers of this class should not write directly to the query definition,
 * instead they should use this classes methods to safely make changes
 *
 * TODO: document in the wiki
 *
 * TODO:
 *  figure out how to represent the apiQueryDefinition properly (via generic),
 *  rather than pretending it's just a "BaseApiQueryDefinition" (it's a
 *  class extending BaseApiQueryDefinition)
 *
 * TODO:
 *  Figure out how to merge incoming filters and sorts with the
 *  queryDefinitions internal filters and sorts, without
 *  conflicting array indexes and sortIndexes
 */
class ApiQueryManager {
  /**
   * @constructor
   *
   * @param {BaseApiQueryDefinition} apiQueryDefinition
   */
  constructor(apiQueryDefinition) {
    if (!(apiQueryDefinition instanceof BaseApiQueryDefinition)) throw new TypeError(`Must provide a "BaseApiQueryDefinition" to ${this.constructor.name}`);

    this._queryDefinition = apiQueryDefinition;

    this.readOnlyQueryDefinition = new Proxy(this._queryDefinition, {
      get: (queryDefinition, p) => queryDefinition[p],
      set: (queryDefinition, p, value) => {
        throw new Error(`Cannot write to ReadOnly ${queryDefinition.constructor.name}. Attempted property: "${String(p)}", value: "${String(value)}"`);
      },
    });
  }


  /**
   * @description
   * Definition of the query that the ApiQueryManager works on
   *
   * @type {BaseApiQueryDefinition} _queryDefinition
   *
   * @private
   * @readonly
   */
  _queryDefinition;


  /**
   * @description
   * Readable query definition - granting readonly access of the ApiQueryDefinitions properties
   *
   * @type {BaseApiQueryDefinition} readOnlyQueryDefinition
   *
   * @public
   */
  readOnlyQueryDefinition;


  /**
   * @description
   * URL to query the API
   *
   * @param {string[]} additionalParameters
   * @returns {string}
   */
  getQueryUrl = (additionalParameters = []) => {
    const {
      activePage,
      searchTerm,
      pageSize,
      customFilters,
      customFlags,
      baseFilters,
      baseFlags,
      customSorts,
      baseWithParameters,
    } = this._queryDefinition;

    // TODO: clean up assertions

    // assert filters and sorts are valid
    customFilters.forEach((customFilter) => ApiQueryAssertions.assertFilterShape(this.constructor.name, customFilter, 'customFilter'));
    baseFilters.forEach((baseFilter) => ApiQueryAssertions.assertFilterShape(this.constructor.name, baseFilter, 'baseFilter'));
    ApiQueryAssertions.assertArrayOfSortsShape(this.constructor.name, customSorts);

    const queryParams = [
      baseWithParameters.map((withParameter) => `with[]=${withParameter}`).join('&'),
      makeFilterString([...(baseFilters || []), ...(customFilters || [])]),
      (baseFlags || []).join('&'),
      (customFlags || []).join('&'),
      ...((typeof searchTerm === 'string' && searchTerm.trim()) ? [`search=${searchTerm.trim()}`] : []),
      ...((activePage && activePage > 1) ? [`page=${activePage}`] : []),
      ...((pageSize && pageSize > 0) ? [`pagelength=${pageSize}`] : []),
      (makeSortStringFromCustomSortArray(customSorts)),
      ...additionalParameters,
    ];

    const result = `${this._queryDefinition.routePath}?${queryParams.filter((param) => param !== '').join('&')}`;

    debugLog('[ApiQueryManager:url]', 'info', { url: result, queryDefinition: { ...(this.readOnlyQueryDefinition || {}) } }, '🏆');
    return result;
  }


  /**
   * @description
   * Toggle the flag on or off for the query definition
   *
   * @param {string} flag
   */
  toggleFlag = (flag) => {
    debugLog('[ApiQueryManager:toggleFlag]', 'info', `Toggling flag: ${flag}`, '🏆');
    ApiQueryAssertions.assertFlagIsAllowed(this.constructor.name, this._queryDefinition.availableFlags, flag);

    // remove
    if (this._queryDefinition.customFlags.includes(flag)) this._queryDefinition.customFlags = this._queryDefinition.customFlags.filter((existingFlag) => existingFlag !== flag);

    // add
    else this._queryDefinition.customFlags = [...this._queryDefinition.customFlags, flag];
  }


  /**
   * @description
   * Toggle the flag on or off for the query definition
   *
   * @param {string[]} flags
   */
  setAllFlags = (flags) => {
    debugLog('[ApiQueryManager:toggleFlag]', 'info', `Setting flags: ${flags.join(', ')}`, '🏆');
    flags.forEach((flag) => ApiQueryAssertions.assertFlagIsAllowed(this.constructor.name, this._queryDefinition.availableFlags, flag));

    // force all flags to match the input
    this._queryDefinition.customFlags = flags;
  }


  /**
   * @description
   * Set the searchTerm of the query definition
   *
   * @param {string} searchTerm
   */
  setSearchTerm = (searchTerm) => {
    debugLog('[ApiQueryManager:setSearchTerm]', 'info', `Setting searchTerm to: ${searchTerm}`, '🏆');
    this._queryDefinition.searchTerm = searchTerm;
  }


  /**
   * @description
   * Set custom filters for a field in the query definition
   *
   * @param {{field: string, operation: string, values: string}} newFilter
   */
  setCustomFilter = (newFilter) => {
    debugLog('[ApiQueryManager:setCustomFilter]', 'info', { message: 'Setting custom filter', newFilter }, '🏆');
    const index = this._queryDefinition.customFilters.findIndex((customFilter) => customFilter.field === newFilter.field);

    if (index !== -1) this._queryDefinition.customFilters[index] = newFilter;
    else this._queryDefinition.customFilters.push(newFilter);

    // assert custom filters are still valid
    this._queryDefinition.customFilters.forEach((customFilter) => ApiQueryAssertions.assertFilterShape(this.constructor.name, customFilter, 'customFilter'));
  }


  /**
   * @description
   * Remove a custom filter by field name
   *
   * @param {string} fieldToRemove
   */
  removeCustomFilter = (fieldToRemove) => {
    debugLog('[ApiQueryManager:removeCustomFilter]', 'info', `Removing custom filter "${fieldToRemove}"`, '🏆');
    this._queryDefinition.customFilters = this._queryDefinition.customFilters.filter((customFilter) => customFilter.field !== fieldToRemove);

    // assert custom filters are still valid
    this._queryDefinition.customFilters.forEach((customFilter) => ApiQueryAssertions.assertFilterShape(this.constructor.name, customFilter, 'customFilter'));
  }


  /**
   * @description
   * Remove all custom filters from the query definition
   */
  removeAllCustomFilters = () => {
    debugLog('[ApiQueryManager:removeAllCustomFilters]', 'info', 'Removing all custom filters', '🏆');
    this._queryDefinition.customFilters = [];

    // assert custom filters are still valid
    this._queryDefinition.customFilters.forEach((customFilter) => ApiQueryAssertions.assertFilterShape(this.constructor.name, customFilter, 'customFilter'));
  }


  /**
   * @description
   * Get the next customSort sortIndex for the query definition
   *
   * @returns {number}
   */
  getNextCustomSortIndex = () => {
    let highestSortIndex = this._queryDefinition.customSorts[0]
      ? this._queryDefinition.customSorts[0].sortIndex
      : 0;

    this._queryDefinition.customSorts.forEach((customSort) => {
      if (customSort.sortIndex > highestSortIndex) highestSortIndex = customSort.sortIndex;
    });

    debugLog('[ApiQueryManager:getNextCustomSortIndex]', 'info', `Next CustomSortIndex retrieved: "${highestSortIndex + 1}"`, '🏆');
    return highestSortIndex + 1;
  }


  /**
   * @description
   * Set a customSort for a field of the query definition
   *
   * @param {{field: string, direction: 'asc' | 'desc', sortIndex: string}} newSort
   */
  setCustomSort = (newSort) => {
    debugLog('[ApiQueryManager:setCustomSort]', 'info', { message: 'Setting sort', newSort }, '🏆');
    const index = this._queryDefinition.customSorts.findIndex((customSort) => customSort.field === newSort.field);

    if (index !== -1) this._queryDefinition.customSorts[index] = newSort;
    else this._queryDefinition.customSorts.push(newSort);

    // assert custom sorts are still valid
    ApiQueryAssertions.assertArrayOfSortsShape(this.constructor.name, this._queryDefinition.customSorts);
  }


  /**
   * @description
   * Remove all custom sorts from the query definition
   */
  removeAllCustomSorts = () => {
    debugLog('[ApiQueryManager:removeAllCustomSorts]', 'info', { message: 'Removing all custom sorts', oldSorts: this._queryDefinition.customSorts }, '🏆');
    this._queryDefinition.customSorts = [];

    // assert custom sorts are still valid
    ApiQueryAssertions.assertArrayOfSortsShape(this.constructor.name, this._queryDefinition.customSorts);
  }


  /**
   * @description
   * Set the active page of the query definition
   *
   * @param {number} newActivePage
   */
  setActivePage = (newActivePage) => {
    debugLog('[ApiQueryManager:setActivePage]', 'info', `Setting active page from "${this._queryDefinition.activePage}" to "${newActivePage}"`, '🏆');
    this._queryDefinition.activePage = newActivePage;
  }


  /**
   * @description
   * Set the page size of the query definition
   *
   * @param {number} newPageSize
   */
  setPageSize = (newPageSize) => {
    debugLog('[ApiQueryManager:setPageSize]', 'info', `Setting page size from "${this._queryDefinition.pageSize}" to "${newPageSize}"`, '🏆');
    this._queryDefinition.pageSize = newPageSize;
  }
}

export default ApiQueryManager;
