import PropTypes from 'prop-types';
import { FUSION_CHART_SUPPORTED_SINGLE_SERIES_TYPES, FUSION_CHART_TYPES_SUPPORTED } from '../utils/fusion-chart-types';
import FUSION_CHART_DATASET_RENDER_TYPES from '../utils/fusion-chart-dataset-render-types';

/**
 * Data
 */

// URL
export const PORTAL_FUSION_CHART_DATA_URL_PROP_TYPES = PropTypes.shape({
  url: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  getReportDataFromResponse: PropTypes.func,
});

// Pre loaded data
export const PORTAL_FUSION_CHART_LOADED_DATA_PROP_TYPES = PropTypes.shape({
  isLoading: PropTypes.bool.isRequired,
  loadedData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasError: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.any,
});

export const PORTAL_FUSION_CHART_DATA_PROP_TYPES = PropTypes.oneOfType([
  PORTAL_FUSION_CHART_DATA_URL_PROP_TYPES,
  PORTAL_FUSION_CHART_LOADED_DATA_PROP_TYPES,
]);

/**
 * Data Mapping
 */

// single series
export const PORTAL_FUSION_CHART_SINGLE_SERIES_DATA_MAPPING_PROP_TYPES = PropTypes.shape({
  type: PropTypes.oneOf(['single']).isRequired,
  chartType: PropTypes.oneOf(FUSION_CHART_SUPPORTED_SINGLE_SERIES_TYPES).isRequired,
  getValue: PropTypes.func.isRequired,
  getLabel: PropTypes.func.isRequired,
  getColor: PropTypes.func,
  filterIn: PropTypes.func,
});

// multi series
export const PORTAL_FUSION_CHART_MULTI_SERIES_DATA_MAPPING_PROP_TYPES = PropTypes.shape({
  type: PropTypes.oneOf(['multi']).isRequired,
  // The multi-series data mapping currently supports both single & multi series chart types
  chartType: PropTypes.oneOf(FUSION_CHART_TYPES_SUPPORTED).isRequired,
  getLabel: PropTypes.func.isRequired,
  seriesList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    getValue: PropTypes.func.isRequired,
    filterIn: PropTypes.func,
    renderAs: PropTypes.oneOf(Object.values(FUSION_CHART_DATASET_RENDER_TYPES)).isRequired,
    visible: PropTypes.bool.isRequired,
    legendItemId: PropTypes.string,
    getColor: PropTypes.func,
    lineThickness: PropTypes.number,
    hoverGradientColor: PropTypes.string,
  })),
});

// one of
export const PORTAL_FUSION_CHART_DATA_MAPPING_PROP_TYPES = PropTypes.oneOfType([
  PORTAL_FUSION_CHART_SINGLE_SERIES_DATA_MAPPING_PROP_TYPES,
  PORTAL_FUSION_CHART_MULTI_SERIES_DATA_MAPPING_PROP_TYPES,
]);

// TODO: variable series??
