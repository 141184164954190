import React from 'react';
import classNames from 'classnames';

import { ConstSelect, ConstSelectProps } from './const-select';
import { INewsCategoryRecord } from '../../types/news/news-category.record.interface';
import { SelectOption } from '../../types/poly-form/select-option';

import { ApiQueryDataLoader } from '../api-query-data-loader/api-query-data-loader';
import Icon from '../layout-helpers/icon';

export type NewsCategoryPickerProps = ConstSelectProps;

export const NewsCategoryPicker: React.FC<NewsCategoryPickerProps> = (props) => {
  const {
    hasError,
    // tabSelectsValue = true,s
    disabled,
    formSaveField,
  } = props;

  return (
    <ApiQueryDataLoader
      apiQueryUrl="/news/category?pageLength=0"
      render={({
        response,
        isLoading,
        hasError: hasRoleLoadingError,
      }) => {
        if (isLoading) {
          return (
            <div
              className={classNames('portal-data-field', 'not-editable', {
                disabled,
              })}
            >
              <Icon i="rolling" />
            </div>
          );
        }

        const data: INewsCategoryRecord[] = (response?.data as INewsCategoryRecord[]) ?? [];

        return (
          <ConstSelect
            {...props}
            formSaveField={formSaveField || 'category_id'}
            hasError={hasError || hasRoleLoadingError}
            inputOptions={data.map((dataItem: INewsCategoryRecord) => ({ id: dataItem.id, name: dataItem.name } as SelectOption))}
            // tabSelectsValue={tabSelectsValue}
          />
        );
      }}
    />
  );
};
