import { PROJECT_STATUS } from '@corporate-initiatives/ci-portal-js-sdk';

const baseItemQueryRoute = '/crm/ordering/project-eta-items?pdt=v=all;ps=200;p=1;';

export const getVendorManufacturerModelLink = (
  orderedOnly: boolean|null,
  model?: string|number|null,
  manufacturer?: string|number|null,
  vendor?: string|number|null,
  project_id?: string|number|null,
  dtools_id?: string|number|null,
  project_manager_id?: string|number|null,
): string => {
  const modelQuery = model ? `model:eq+${encodeURIComponent(model)},` : '';
  const manufactuerQuery = manufacturer ? `manufacturer:ct+${encodeURIComponent(manufacturer)},` : '';
  const vendorQuery = vendor ? `vendor:ct+${encodeURIComponent(vendor)},` : '';
  const projectQuery = project_id ? `project_id:eq+${project_id},` : '';
  const dtoolsQuery = dtools_id ? `dtools_id:eq+${dtools_id},` : '';
  const pmQuery = project_manager_id ? `project_manager:eq+${project_manager_id},` : '';
  const orderedQuery = orderedOnly ? 'order_status:ct+order,' : '';
  const fullQuery = `${modelQuery}${manufactuerQuery}${vendorQuery}${projectQuery}${dtoolsQuery}${pmQuery}${orderedQuery}`;
  return `${baseItemQueryRoute}cf=${fullQuery}status:eq+${PROJECT_STATUS.ACTIVE.ID};_=1`;
};

export const getOrderStatusLink = (
  order_status?: string|number|null,
  project_id?: string|number|null,
  project_manager_id?: string|number|null,
): string => {
  const projectQuery = project_id ? `project_id:eq+${project_id},` : '';
  const orderStatusQuery = order_status ? `order_status:eq+${encodeURIComponent(order_status)},` : 'order_status:b+';
  const pmQuery = project_manager_id ? `project_manager:eq+${project_manager_id},` : '';
  const fullQuery = `${projectQuery}${orderStatusQuery}${pmQuery}`;
  return `${baseItemQueryRoute}cf=${fullQuery}status:eq+${PROJECT_STATUS.ACTIVE.ID};_=1`;
};

export const getEddUpdatedLink = (
  orderedOnly: boolean|null,
  edd_last_updated?: string|number|null,
  project_id?: string|number|null,
  project_manager_id?: string|number|null,
): string => {
  const projectQuery = project_id ? `project_id:eq+${project_id},` : '';
  const eddUpdatedQuery = edd_last_updated ? `edd_last_updated:eq+${edd_last_updated},` : '';
  const pmQuery = project_manager_id ? `project_manager:eq+${project_manager_id},` : '';
  const orderedQuery = orderedOnly ? 'order_status:ct+order,' : '';
  const fullQuery = `${projectQuery}${eddUpdatedQuery}${pmQuery}${orderedQuery}`;
  return `${baseItemQueryRoute}cf=${fullQuery}status:eq+${PROJECT_STATUS.ACTIVE.ID};_=1`;
};
