import React, { useCallback } from 'react';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { IPlatformRecord } from '../../types/internal-project/platform.record.interface';
import { NewRecordFormProps } from '../../types/poly-form/new-record-form.props';

import { APIPolyForm } from './api-poly-form';

import { mapTableToForm } from '../../helpers/map-table-to-form.helper';

import platformTableInitialSettings from '../../table-definitions/platforms-table';
import { API_ACTION } from '../../constants/api-action.const';

const formFields = mapTableToForm(platformTableInitialSettings);

export const NewPlatformForm: React.FC<NewRecordFormProps<IPlatformRecord>> = (props) => {
  const {
    onFormComplete, onFormChange, initialData,
  } = props;

  /**
   * Fired when the APIPolyForm successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, id: FormFieldComponentProps['value'], newData?: Partial<IPlatformRecord>) => {
    if (onFormComplete) onFormComplete(success, id as IPlatformRecord['id'], newData as IPlatformRecord);
  }, [onFormComplete]);

  // Render
  return (
    <APIPolyForm
      {...platformTableInitialSettings}
      fields={formFields}
      formData={{
        ...(initialData ?? {}),
      }}
      permittedActions={platformTableInitialSettings.possibleActions.filter((action) => (action.name === API_ACTION.CREATE))}
      onFormComplete={handleFormComplete}
      onFormChange={onFormChange}
      isNewRecord
      isEditing
    />
  );
};
