import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import rollingSvg from '../../images/Rolling-1s-22px.svg';

/**
 * Map size of image container for the rolling svg
 * to the same visual sizes as the fontawesome icon sizes
 */
const sizeMap = {
  md: '18',
  lg: '30',
  '2x': '40',
  '3x': '55',
  '4x': '70',
  '5x': '80',
};

/**
 * <Icon /> Returns JSX FontAwesome fa icon, Themify ti icon or rolling svg spinner icon
 *
 * param {string} i - The icon eg `'retro-camera'`
 * - If this is `'spinner'`, then`'fa-pulse'` is added automatically.
 * - You can also use `'rolling'` to draw a rolling spinner svg.
 * - You can also use Themify icons if you prepend the `ti-` at the beginning,
 *   eg. `'ti-angle-double-up'`, However the FontAwesome modifiers won't work.
 *
 * param {string[]} mods - List of `fa` modifiers:
 * - Animation: `'spin'`, `'pulse'`
 * - Rotate: `'rotate-90'`, `'rotate-180'`, `'rotate-270'`
 * - Flip: `'flip-horizontal'`, `'flip-vertical'`
 * - Stacked: `'stack-2x'`, `'stack-1x'`, `'inverse'`
 * - Other: `'border'`, `'pull-left'`, `'pull-right'`
 * @see https://fontawesome.com/v4.7.0/examples/ for all modifiers and usage info
 *
 * param {string} className - Classname as usual. eg. `'text-danger'` makes it red.
 * param {boolean} spaceLeft - Add a nbsp to the left of the icon
 * param {boolean} spaceRight - Add a nbsp to the right of the icon
 * param {boolean} isBusy - Always use fa-spinner and fa-pulse
 * param {boolean} fw - Use fixed width
 * param {string} size - Use specific Icon size, default 'md' (no class)
 * - Icon sizes: `'lg'`, `'2x'`, `'3x'`, `'4x'`, `'5x'`
 *
 * @example
 * <Icon />
 * // <i class="fa fa-check-square" aria-hidden="true" />
 *
 * <Icon i="ban" />
 * // <i class="fa fa-ban" aria-hidden="true" />
 *
 * <Icon i="home" spaceRight />Home
 * // <i class="fa fa-home" aria-hidden="true" />&nbsp;Home
 *
 * <Icon i="spinner" fw size="3x" />
 * // <i class="fa fa-spinner fa-pulse fa-3x fa-fw" aria-hidden="true" />
 *
 * <Icon i="home" isBusy />
 * // <i class="fa fa-spinner fa-pulse" aria-hidden="true" />
 *
 * <Icon i="ti-angle-double-up" />
 * // <i class="ti-angle-double-up" aria-hidden="true" />
 *
 * <Icon i="rolling" size="lg" />
 * // <img width="30" height="30" src="rollingSvg" alt="loading..." aria-hidden="true" />
 */
const Icon = ({ i, mods, className, spaceLeft, spaceRight, isBusy, fw, size, style, title, spin }) => {
  let innerContent = null;

  // Rolling SVG
  if (i === 'rolling') {
    innerContent = (
      <img
        width={sizeMap[size]}
        height={sizeMap[size]}
        src={rollingSvg}
        alt="Loading..."
        className={className}
        aria-hidden="true"
        style={style}
        title={title}
      />
    );
  } else {
    // Collect conditions of the main class name of
    // the icon that makes correct glyph appear
    let useFa = true;
    if (typeof i === 'string' && (i.slice(0, 3) === 'ti-' || i.slice(0, 5) === 'icon-')) {
      useFa = false;
    }
    let iconClass = i;
    if (useFa) {
      iconClass = `fa-${i}`;
    }
    if (isBusy || i === 'spinner') {
      iconClass = 'fa-spinner fa-pulse';
    }
    if (spin) {
      iconClass = `${iconClass} fa-spin`;
    }
    innerContent = (
      <i
        className={classnames(
          'portal-icon',
          {
            fa: size || mods.length || isBusy || useFa,
            'fa-fw': fw,
          },
          iconClass,
          className,
          size && `fa-${size}`,
          mods.map((j) => `fa-${j}`).join(' '),
        )}
        aria-hidden="true"
        style={style}
        title={title}
      />
    );
  }

  // Return <Icon />
  return (
    <>
      {spaceLeft && <>&nbsp;</>}
      {innerContent}
      {spaceRight && <>&nbsp;</>}
    </>
  );
};

Icon.propTypes = {
  i: PropTypes.string,
  mods: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  spaceLeft: PropTypes.bool,
  spaceRight: PropTypes.bool,
  fw: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', '2x', '3x', '4x', '5x']),
  isBusy: PropTypes.bool,
  style: PropTypes.shape({}),
  title: PropTypes.string,
  spin: PropTypes.bool,
};

Icon.defaultProps = {
  i: null,
  mods: [],
  className: '',
  spaceLeft: false,
  spaceRight: false,
  fw: false,
  size: null,
  isBusy: false,
  style: {},
  title: null,
  spin: false,
};

export default Icon;
