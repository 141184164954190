import React, { SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, CardText, Form, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import moment from 'moment';
import { BUTTON_COLOR } from '../../../constants/button-color.const';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { ICON } from '../../../constants/icon.const';
import { MODAL_TYPE } from '../../../constants/modal-type.const';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import { NewRecordModalResult } from '../../../types/modal/modal-result';
import { IProjectRecord } from '../../../types/project/project.record.interface';
import Icon from '../../layout-helpers/icon';
import { ModalContext } from '../../modals/modal-context';
import { APIContext } from '../../providers/api-provider';
import { formatValueString } from '../../render-functions';
import { IDtoolsOrderRecord } from '../../../types/integration/dtools/dtools-order-record.interface';
import { MyobOrderItemsTable } from './myob-order-items-table';
import { DtoolsVendorOrderItemsTable } from './dtools-vendor-order-items-table';
import { IMyobSupplierOrder } from '../../../types/integration/myob/myob-supplier-order.interface';
import { DATE_FORMAT } from '../../../constants/date-format.const';


interface IVendorOpenOrdersTableProps {
  vendorItem: IDtoolsOrderRecord,
  projectData: IProjectRecord,
}

interface IMyobVendorRecord {
  id: string,
  name: string
}

const companyId = process.env.REACT_APP_MYOB_COMPANY_ID;
const baseHref = `https://ci.myobadvanced.com/Main?CompanyID=${companyId}&ScreenId=PO301000&OrderType=RO&OrderNbr=`;

export const VendorOpenOrdersTable: React.FC<IVendorOpenOrdersTableProps> = ({ vendorItem, projectData }) => {
  const { apiFetch } = useContext(APIContext);
  const { showModal } = useContext(ModalContext);

  const myobVendorAbortController = useRef<AbortController | null>(null);
  const myobOrderAbortController = useRef<AbortController | null>(null);

  // const [vendorsLoading, setVendorsLoading] = useState(false);
  const [vendorMatches, setVendorMatches] = useState<IMyobVendorRecord[] | null>(null);
  const [vendorOrders, setVendorOrders] = useState<IMyobSupplierOrder[] | null>(null);
  const [selectedVendorRecord, setSelectedVendorRecord] = useState<{ id: string | null, name: string | null } | null>({ id: null, name: vendorItem.Vendor });
  const [vendorOrdersLoading, setVendorOrdersLoading] = useState<boolean>(false);
  const [vendorLoadingError, setVendorLoadingError] = useState<boolean>(false);
  const [vendorOrderLoadingError, setVendorOrderLoadingError] = useState<boolean>(false);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState<string|null>(null);
  const [vendorSearchText, setVendorSearchText] = useState<string>(vendorItem.Vendor || '');
  const [lastSearchText, setLastSearchText] = useState<string>(vendorItem.Vendor || '');
  const [processedOrders, setProcessedOrders] = useState<{[key: string]: IMyobSupplierOrder}>({});

  /**
   * Load Vendor Orders
   *
   * @param {string} vendorId
   */
  const loadVendorOrders = useCallback((vendorId: string) => {
    if (myobOrderAbortController.current) {
      myobOrderAbortController.current.abort();
    }
    myobOrderAbortController.current = apiAborter();
    setVendorOrderLoadingError(false);
    setVendorOrdersLoading(true);
    apiFetch(
      `/myob-advanced-connector/supplier-order?vendor_id=${vendorId}&project_id=${projectData.id}`,
      {
        name: 'DToolsProjectOrders:loadOrders',
        signal: myobOrderAbortController?.current?.signal,
      },
    ).then((response) => {
      setVendorOrdersLoading(false);
      if (response.success) {
        setVendorOrders(response.body);
      } else if (!response.aborted) {
        setVendorOrderLoadingError(true);
      }
    });
  }, [apiFetch, projectData]);


  /**
   * Load Vendor Matches
   */
  const loadVendorMatches = useCallback((searchTerm: string) => {
    if (myobVendorAbortController.current) {
      myobVendorAbortController.current.abort();
    }
    myobVendorAbortController.current = apiAborter();
    setVendorLoadingError(false);
    apiFetch(
      `/myob-advanced-connector/vendor?search=${searchTerm}`,
      {
        name: 'DToolsProjectOrders:loadVendor',
        signal: myobVendorAbortController?.current?.signal,
      },
    ).then((response) => {
      if (response.success && response.body && response.body.length === 1) {
        setSelectedVendorRecord(response.body[0]);
        loadVendorOrders(response.body[0].id);
        setVendorMatches(response.body);
      } else if (response.success && response.body) {
        setSelectedVendorRecord(null);
        setVendorMatches(response.body);
      } else if (!response.aborted) {
        setVendorMatches(null);
        setVendorLoadingError(true);
      }
    });
  }, [apiFetch, loadVendorOrders]);


  /**
   * Search Change
   */
  useEffect(() => {
    if (vendorMatches === null) {
      loadVendorMatches(vendorSearchText);
    }
  }, [lastSearchText, vendorSearchText, loadVendorMatches, vendorItem, vendorMatches]);


  /**
   * Mount Effect
   *
   * don't do anything, just prepare an unmount
   */
  useEffect(() => () => {
    if (myobVendorAbortController.current) {
      myobVendorAbortController.current.abort();
    }
  },
  []);


  /**
   * Handle selection of a vendor match
   */
  const selectVendorMatchHandler = (vendorMatch: IMyobVendorRecord) => {
    setSelectedVendorRecord(vendorMatch);
    loadVendorOrders(vendorMatch.id);
  };


  /**
   * Add a processed order
   */
  const addProcessedOrder = useCallback((orderId: string, newOrder: IMyobSupplierOrder) => {
    setProcessedOrders({
      ...processedOrders,
      [orderId]: newOrder,
    });
    return true;
  }, [processedOrders]);


  /**
   * Add Vendor Purchase Order - Button Click Handler
   */
  const addVendorPurchaseOrderClickHandler = (vendorRecord: IDtoolsOrderRecord) => {
    showModal<NewRecordModalResult<IMyobSupplierOrder>>(MODAL_TYPE.NEW_MYOB_PURCHASE_ORDER, {
      onModalComplete: (modalResult) => {
        if (modalResult.newRecord?.VendorID?.value) {
          loadVendorOrders(modalResult.newRecord?.VendorID?.value);
          setSelectedPurchaseOrder(modalResult.newRecord?.OrderNbr?.value);
          navigator.clipboard.writeText(modalResult.newRecord?.OrderNbr?.value);
        }
      },
      initialData: {
        vendorId: selectedVendorRecord?.id,
        vendorName: selectedVendorRecord?.name,
        projectId: projectData.id,
        projectNumber: projectData.project_number,
        orderNumber: vendorRecord.OrderNumber || null,
        orderDate: formatValueString(vendorRecord.OrderedDate || null, COLUMN_FORMAT.DTOOLS_DATE),
      },
    });
  };


  /**
   * Set Order Checked
   *
   * @param e Event
   */
  const setOrderChecked = (e: SyntheticEvent) => {
    const targetId = e.currentTarget.id;
    setSelectedPurchaseOrder(targetId);
    navigator.clipboard.writeText(targetId);
  };


  /**
   * Handle Search Event
   */
  const handleSearchEvent = useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedVendorRecord(null);
    setVendorMatches(null);
    setVendorOrders(null);
    setVendorSearchText(vendorSearchText);
    setLastSearchText(vendorSearchText);
    // loadVendorMatches(vendorSearchText);
  }, [vendorSearchText]);

  //
  // Render the component
  //

  // null while loading...
  if (vendorMatches === null) {
    return (
      <CardText>
        <Icon i="rolling" />
        &nbsp;
        Vendor Matches Loading for
        {' '}
        {lastSearchText}
        ...
      </CardText>
    );
  }

  // loaded and empty
  if (vendorMatches && vendorMatches.length === 0) {
    return (
      <>
        <CardText className="text-danger">
          No Vendor Matches were found in MYOB for
          {' '}
          {lastSearchText}
          {' '}
          <br />
        </CardText>

        <Form inline onSubmit={handleSearchEvent}>
          <InputGroup>
            <Input type="text" value={vendorSearchText} onChange={((e: React.FormEvent<HTMLInputElement>) => setVendorSearchText(e.currentTarget.value))} />
            <InputGroupAddon addonType="append">
              <Button onClick={handleSearchEvent}><Icon i={ICON.SEARCH} /></Button>
            </InputGroupAddon>
          </InputGroup>
        </Form>

      </>
    );
  }

  return (
    <>
      {/* <pre>{JSON.stringify({ matches: vendorMatches, length: vendorMatches.length, selected: selectedVendorRecord }, undefined, 2)}</pre> */}
      {vendorLoadingError && (
        <CardText className="text-danger">
          Error Loading Vendor Matches.  Is MYOB Connected?
        </CardText>
      )}
      {vendorMatches && vendorMatches.length > 1 && (selectedVendorRecord === null || selectedVendorRecord?.id === null) && (
        <div>
          <CardText>
            Multiple Possible Vendor Matches were found for
            {' '}
            {lastSearchText}
            . Please choose a vendor:
            {' '}
          </CardText>
          <Form inline onSubmit={handleSearchEvent}>
            {vendorMatches.map((vendorMatch) => (
              <Button key={vendorMatch.id} className="m-2" color="warning" onClick={() => selectVendorMatchHandler(vendorMatch)}>
                {vendorMatch.name}
              </Button>
            ))}
            <InputGroup>
              <Input type="text" value={vendorSearchText} onChange={((e: React.FormEvent<HTMLInputElement>) => setVendorSearchText(e.currentTarget.value))} />
              <InputGroupAddon addonType="append">
                <Button onClick={handleSearchEvent}><Icon i={ICON.SEARCH} /></Button>
              </InputGroupAddon>
            </InputGroup>
          </Form>
        </div>
      )}
      {vendorOrderLoadingError && (
        <CardText className="text-danger">
          Error Loading Vendor Orders. Is MYOB Connected?
        </CardText>
      )}
      {vendorOrders && vendorOrders.length === 0 && (
        <div className="text-dark">
          <Form inline onSubmit={handleSearchEvent}>
            No Open Orders were found in MYOB for
            {' '}
            {selectedVendorRecord?.id}
            {' '}
            {selectedVendorRecord?.name}
            {' '}
            <InputGroup className="ml-2">
              <Input type="text" value={vendorSearchText} onChange={((e: React.FormEvent<HTMLInputElement>) => setVendorSearchText(e.currentTarget.value))} />
              <InputGroupAddon addonType="append">
                <Button onClick={handleSearchEvent}><Icon i={ICON.SEARCH} /></Button>
              </InputGroupAddon>
            </InputGroup>
            {' '}
            <Button color="purple" className="m-2" onClick={(e: SyntheticEvent) => { e.preventDefault(); e.stopPropagation(); addVendorPurchaseOrderClickHandler(vendorItem); }}>
              Add New PO
              {' '}
              <Icon i="plus" />
            </Button>
          </Form>
        </div>
      )}
      {vendorOrdersLoading && (
        <CardText>
          <Icon i="rolling" />
          &nbsp;
          Vendor Orders Loading for
          {' '}
          {selectedVendorRecord?.id}
          {' '}
          {selectedVendorRecord?.name}
          {' '}
        </CardText>
      )}
      {vendorOrders && vendorOrders.length > 0 && (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Form inline onSubmit={handleSearchEvent}>
              <strong>
                Open MYOB Orders for
                {' '}
                {selectedVendorRecord?.id}
                {' '}
                {selectedVendorRecord?.name}
                {' '}
                &nbsp;
              </strong>
              <InputGroup>
                <Input type="text" value={vendorSearchText} onChange={((e: React.FormEvent<HTMLInputElement>) => setVendorSearchText(e.currentTarget.value))} />
                <InputGroupAddon addonType="append">
                  <Button onClick={handleSearchEvent}><Icon i={ICON.SEARCH} /></Button>
                </InputGroupAddon>
              </InputGroup>
            </Form>
            <Button color={BUTTON_COLOR.PURPLE} className=" m-2" onClick={() => addVendorPurchaseOrderClickHandler(vendorItem)}>
              Add New PO
              {' '}
              <Icon i="plus" />
            </Button>
          </div>
          <table className="table color-table purple-table">
            <thead>
              <tr>
                <th>Order No</th>
                <th>Description</th>
                <th>Date</th>
                <th>Status</th>
                <th>Value</th>
                <th style={{ width: '3rem' }}>Use</th>
                <th style={{ width: '3rem' }}>Copy</th>
              </tr>
            </thead>
            <tbody>
              {vendorOrders && vendorOrders.map((order) => (
                <React.Fragment key={order.id}>
                  <tr id={order.OrderNbr.value} onClick={setOrderChecked} style={{ cursor: 'pointer' }}>
                    <td>
                      <a
                        href={`${baseHref}${order?.OrderNbr?.value}`}
                        target="_new"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {order?.OrderNbr?.value}
                      </a>
                    </td>
                    <td>
                      {order?.Description?.value || ' - no description - '}
                    </td>
                    <td>
                      {order?.Date?.value ? moment(order?.Date?.value).format(DATE_FORMAT.AUSTRALIAN_READABLE) : ' - '}
                    </td>
                    <td>
                      {order?.Status?.value || ' - no status - '}
                    </td>
                    <td className="text-right">
                      {formatValueString(order?.LineTotal?.value, COLUMN_FORMAT.CURRENCY)}
                    </td>
                    <td>
                      <Input
                        type="checkbox"
                        name={order?.OrderNbr?.value}
                        checked={selectedPurchaseOrder === order?.OrderNbr?.value}
                        id={order?.OrderNbr?.value}
                        onChange={setOrderChecked}
                        style={{ marginLeft: '2px' }}
                      />
                    </td>
                    <td className="text-center">
                      <Button onClick={() => navigator.clipboard.writeText(order?.OrderNbr?.value)} size="sm"><Icon i={ICON.COPY} size="sm" /></Button>
                    </td>
                  </tr>
                  {processedOrders && processedOrders[order?.OrderNbr?.value] && (
                    <tr>
                      <td colSpan={7}>
                        <MyobOrderItemsTable orderRecord={processedOrders[order?.OrderNbr?.value]} />
                        {/* <code><pre>{JSON.stringify(processedOrders, undefined, 2)}</pre></code> */}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </>
      )}
      <DtoolsVendorOrderItemsTable
        rowData={vendorItem}
        dtoolsId={projectData.dtools_id}
        selectedPoId={selectedPurchaseOrder}
        projectData={projectData}
        addProcessedOrder={addProcessedOrder}
      />
    </>
  );
};
