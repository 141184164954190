import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader } from 'reactstrap';
import { formatValueString } from '../render-functions';
import { COLUMN_FORMAT } from '../../constants/column-format.const';
import { yesNoOptions } from '../../utils/constants';
import { APIContext } from '../providers/api-provider';
import { LoadingSpinner } from '../layout-helpers/loading-spinner';
import { apiAborter } from '../../helpers/api-aborter.helper';


/**
 * DToolsProjectSummary component for Data Table
 */
export const DToolsProjectSummary = ({ dtoolsId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { apiFetch } = useContext(APIContext);

  useEffect(() => {
    let abortController = apiAborter();

    apiFetch(
      `/dtools-connector/project/${dtoolsId}`,
      {
        name: 'DToolsProjectSummary:load',
        signal: abortController.signal,
      },
    ).then((response) => {
      if (response.success) {
        abortController = null;
        setData(response.body.data || []);
        setLoading(false);
      } else if (!response.aborted) {
        abortController = null;
        // TODO: error state?
        console.error('DTools Project Summary Error', response.error);
        setData([]);
        setLoading(false);
      }
    });

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {loading && <LoadingSpinner />}

      {!loading && (
        <Card>
          <CardHeader className="bg-info text-white" tag="h4">
            Project Summary
          </CardHeader>
          <div className="table-container" style={{ height: '490px', overflowY: 'auto' }}>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-bold">Progress </td>
                  <td>{data.Progress}</td>
                </tr>
                <tr>
                  <td className="text-bold">Client </td>
                  <td>{data.Client}</td>
                </tr>
                <tr>
                  <td className="text-bold">Assigned To </td>
                  <td>{data.AssignedTo}</td>
                </tr>
                <tr>
                  <td className="text-bold">Checked Out To </td>
                  <td>{data.CheckOutUserName}</td>
                </tr>
                <tr>
                  <td className="text-bold">Archived </td>
                  <td>{formatValueString(data.Archived, COLUMN_FORMAT.ID_NAME_OPTIONS, yesNoOptions)}</td>
                </tr>
                <tr>
                  <td className="text-bold">Margin</td>
                  <td className="text-right">
                    {' '}
                    {formatValueString(data.Margin, COLUMN_FORMAT.FRACTIONAL_PERCENTAGE)}
                  </td>
                </tr>
                <tr>
                  <td className="text-bold">Markup</td>
                  <td className="text-right">
                    {' '}
                    {formatValueString(data.Markup, COLUMN_FORMAT.FRACTIONAL_PERCENTAGE)}
                  </td>
                </tr>
                <tr>
                  <td className="text-bold">Cost</td>
                  <td className="text-right">
                    {' '}
                    {formatValueString(data.Cost, COLUMN_FORMAT.CURRENCY)}
                  </td>
                </tr>
                <tr>
                  <td className="text-bold">Price</td>
                  <td className="text-right">
                    {' '}
                    {formatValueString(data.Price, COLUMN_FORMAT.CURRENCY)}
                  </td>
                </tr>
                <tr>
                  <td className="text-bold">Total Price</td>
                  <td className="text-right">
                    {' '}
                    {formatValueString(data.TotalPrice, COLUMN_FORMAT.CURRENCY)}
                  </td>
                </tr>
                <tr>
                  <td className="text-bold">Tax</td>
                  <td className="text-right">
                    {' '}
                    {formatValueString(data.Tax, COLUMN_FORMAT.CURRENCY)}
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
          </div>
        </Card>
      )}
    </>
  );
};

DToolsProjectSummary.propTypes = {
  dtoolsId: PropTypes.string.isRequired,
};
