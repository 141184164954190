import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { mapArraySelect } from '../../utils/constants';

/**
 * @class
 * @name PageSizeSelect
 *
 * Renders a changeable page size
 */
class PageSizeSelect extends React.Component {
  /**
   * @description
   * Fired when the page size changes
   *
   * @param {{id: number, name: number}[]} newPageSize
   * @returns {void}
   */
  handlePageSizeChange = (newPageSize) => {
    const {
      pageSize,
      onPageSizeChange,
    } = this.props;

    if (('id' in newPageSize) &&
      (!Number.isNaN(parseInt(newPageSize.id, 10))) &&
      (pageSize !== parseInt(newPageSize.id, 10))
    ) {
      onPageSizeChange(newPageSize.id);
    }
  };


  /**
   * @inheritdoc
   */
  render() {
    const { pageSize, pageSizes } = this.props;

    const selectOptions = mapArraySelect(pageSizes).map((option) => ({
      ...option,
      name: `${option.name} results per page`,
    }));

    return (
      <div className="page-size-select">
        <Select
          className="Select"
          classNamePrefix="Select"
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
          options={selectOptions}
          clearable={false}
          onChange={this.handlePageSizeChange}
          readOnly
          menuPortalTarget={document.getElementById('portal_container')}
          menuPlacement="auto"
          value={selectOptions.find((option) => option.id === pageSize)}
        />
      </div>
    );
  }
}

PageSizeSelect.defaultProps = {
};

PageSizeSelect.propTypes = {
  pageSize: PropTypes.number.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  pageSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default PageSizeSelect;
