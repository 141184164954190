import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader } from 'reactstrap';
import { LoadingSpinner } from '../layout-helpers/loading-spinner';
import { APIContext } from '../providers/api-provider';
import { apiAborter } from '../../helpers/api-aborter.helper';

/**
 * ProjectLocations component for Data Table
 */
export const DToolsProjectLocations = ({ dtoolsId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { apiFetch } = useContext(APIContext);

  useEffect(() => {
    let abortController = apiAborter();

    apiFetch(
      `/dtools-connector/project/${dtoolsId}/locations`,
      {
        name: 'DToolsProjectLocations:load',
        signal: abortController.signal,
      },
    ).then((response) => {
      if (response.success) {
        abortController = null;
        setData(response.body.data || []);
        setLoading(false);
      } else if (!response.aborted) {
        abortController = null;
        // TODO: error state?
        console.error('DTools Project Locations Error', response.error);
        setData([]);
        setLoading(false);
      }
    });

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {loading && <LoadingSpinner />}

      {!loading && (
        <Card>
          <CardHeader className="bg-info text-white" tag="h4">
            Project Locations
          </CardHeader>
          <div className="table-container" style={{ height: '490px', overflowY: 'auto' }}>
            <table style={{ width: '100%' }}>
              <thead>
                {/*
                  "Id": "9fa4d113-d502-492c-90d8-3b3c1b4b64c8",
                  "ProjectId": "25a6bebf-86ba-4d73-bce5-4656914f281f",
                  "Name": "L24 Reception 24.19",
                  "Abbreviation": "1",
                  "Description": null,
                  "FullName": "Transurban\\L24 Reception 24.19",
                  "FullAbbreviation": "1\\1",
                  "FullOrder": "001\\147",
                  "ParentId": "a28dfdc0-5f4b-4bca-bd49-3a70023af3ff",
                  "Order": 147,
                  "OldId": 109,
                  "Id2": 25952
              */}
                <tr>
                  {/* <th>Id</th>
                <th>ProjectId</th> */}
                  <th>Id</th>
                  {/* <th>Name</th> */}
                  <th>Full Location Name</th>
                  {/* <th>FullAbbreviation</th> */}
                  {/* <th>Description</th> */}
                  {/* <th>FullOrder</th>
                <th>ParentId</th>
                <th>Order</th>
                <th>OldId</th>
                <th>Id2</th> */}
                </tr>
              </thead>
              <tbody>
                {data.length < 1 && (
                  <tr>
                    <td colSpan="8">No locations listed</td>
                  </tr>
                )}
                {data.map((item) => (
                  <tr key={`location-${item.Id}`}>
                    {/* <td>{item.Id}</td>
                  <td>{item.ProjectId}</td> */}
                    <td>{item.Abbreviation}</td>
                    {/* <td>{item.Name}</td> */}
                    {/* <td>{item.Description}</td> */}
                    <td>{item.FullName}</td>
                    {/* <td>{item.FullAbbreviation}</td> */}
                    {/* <td>{item.FullOrder}</td> */}
                    {/* <td>{item.ParentId}</td> */}
                    {/* <td>{item.Order}</td> */}
                    {/* <td>{item.OldId}</td>
                  <td>{item.Id2}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
          </div>
        </Card>
      )}
    </>
  );
};

DToolsProjectLocations.propTypes = {
  dtoolsId: PropTypes.string.isRequired,
};
