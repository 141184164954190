/**
 * Forces a download in the browser by:
 *
 * @TODO: at some point improve this more inline with how the CiFlux front end handles downloads
 *
 * @example downloadFile('myImage.jpg', 'http://bob.com/images/someImage.jpg')
 */
export async function downloadFile(fileName: string, downloadUrl: string): Promise<void> {
  // Fake link click, window.open will cause popup blockers to trigger
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = downloadUrl;
  tempLink.setAttribute('download', fileName);

  // Safari thinks _blank anchors are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
}
