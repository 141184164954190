import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Card, CardBody, Button } from 'reactstrap';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import { formatValueString } from '../../render-functions';
import Icon from '../../layout-helpers/icon';
import { ProjectSummary } from './project-summary';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { APIContext } from '../../providers/api-provider';
import { buildAPIRoute } from '../../../helpers/build-api-route.helper';
import { ProfitAndLossTableAccountLineSection } from './profit-and-loss-table-account-line-section';
import { IUserAudit } from '../../../types/user-audit.interface';
import { apiAborter } from '../../../helpers/api-aborter.helper';

const labelAliases: { [key: string]: string } = {
  // 'Total Income': 'Total Invoicing',
  // 'Total Cost of Sales': 'Total Cost of Goods',
  // 'Gross Profit': 'Gross Earnings',
  // 'Net Earnings': 'Sub Total',
};

type ProfitAndLossTableProps = {
  refreshWidgetData: () => unknown,
  widgetData: {
    user_audit: IUserAudit,
    id: number,
    title: string,
    summary_title: string,
    summary_value: number,
    has_account_lines: boolean,
    quick_books_profit_and_loss_account_lines: {
      id: number,
      title: string,
      value: number,
    }[],
  }[],
  rowData: ({
    id: number,
    dtools_id: string,
    quickbooks_subcustomer_id: number,
  }),
  parentId: number,
  baseRoute: string,
  isLoading: boolean,
  // tSheetsTotal: number,
  handleShowDetailToggle: () => void,
};

type ProjectFincancialSummary = {
  guestimate_project_total: number,
  financial_summary: {
    client_order_total: number,
    project_partial_total: number,
    variation_total: number,
    retention_total: number,
    guestimate_project_total: number,
  }
}

const getLabel = (sourceString: string) => labelAliases[sourceString] || sourceString;

export const ProfitAndLossTable: React.FC<ProfitAndLossTableProps> = (props) => {
  const [projectSummary, setProjectSummary] = useState<ProjectFincancialSummary | null>(null);
  const {
    widgetData = [],
    isLoading = true,
    rowData,
    // tSheetsTotal,
    parentId,
    baseRoute,
    handleShowDetailToggle,
    refreshWidgetData,
  } = props;
  const [summaryError, setSummaryError] = useState<string|null>(null);
  const [refreshingCache, setRefreshingCache] = useState(false);
  const [refreshAttempted, setRefreshAttempted] = useState(false);
  const [localProjectId, setLocalProjectId] = useState(parentId);

  const { apiFetch } = useContext(APIContext);

  const summaryAbortController = apiAborter();
  const syncAbortController = apiAborter();

  /**
   * @description
   * Synchronise the Cached Profit and Loss Data
   */
  const synchroniseProfitAndLoss = useCallback(async () => {
    setRefreshingCache(true);
    setRefreshAttempted(true);
    const response = await apiFetch(
      buildAPIRoute({
        parentId,
        baseRoute,
        apiRoute: '/action/synchronise-profitandloss',
      }),
      {
        name: 'ProfitAndLossTable:synchroniseProfitAndLoss',
        signal: syncAbortController.signal,
        method: HTTP_METHOD.POST,
      },
    );
    if (response.success) {
      setRefreshingCache(false);
      refreshWidgetData();
    } else if (!response.aborted) {
      // TODO: error state?
      setRefreshingCache(false);
    }
    return () => {
      if (syncAbortController) {
        syncAbortController.abort();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiFetch, parentId, baseRoute, refreshWidgetData]);

  const getProjectSummary = useCallback((projectId: number) => {
    setRefreshingCache(true);
    setSummaryError(null);
    apiFetch(
      buildAPIRoute({
        parentId: projectId,
        baseRoute,
        apiRoute: '/summary',
      }),
      {
        name: 'ProfitAndLossTable:getProjectSummary',
        signal: summaryAbortController.signal,
      },
    ).then((response) => {
      if (response.success) {
        setRefreshingCache(false);
        setProjectSummary(response.body.data);
        setSummaryError(null);
      } else if (!response.aborted) {
        setRefreshingCache(false);
        setProjectSummary(null);
        setSummaryError(response.error);
      }
    });
    return () => {
      if (summaryAbortController) {
        summaryAbortController.abort();
      }
    };
  }, [apiFetch, baseRoute, summaryAbortController]);

  useEffect(() => {
    if (localProjectId !== parentId) {
      setLocalProjectId(parentId);
    }
    getProjectSummary(parentId);
    return () => {
      if (summaryAbortController) {
        summaryAbortController.abort();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId]);

  const handleSynchroniseProfitAndLoss = useCallback(() => {
    synchroniseProfitAndLoss();
  }, [synchroniseProfitAndLoss]);

  useEffect(() => {
    if (!refreshAttempted && widgetData && (widgetData[0] || widgetData.length === 0)) {
      if (widgetData[0]) {
        const lastUpdated = moment(widgetData[0].user_audit.updated_at);
        if (moment().diff(lastUpdated, 'm') < 10) {
          return;
        }
      }
      if (widgetData.length > 0) {
        return;
      }
      synchroniseProfitAndLoss();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetData, refreshAttempted]);


  // let netIncomeValue = 0;
  // if (widgetData && widgetData.length > 0) {
  //   const netIncomeRow = widgetData.filter((row) => row.name === 'NetIncome')[0] || {};
  //   netIncomeValue = netIncomeRow.summary_value || 0;
  // }
  return (
    <div className="row justify-content-lg-center">
      <Card className="col-md-12 col-lg-10 col-xl-8">
        <CardBody>
          <h3 className="mt-4">
            Project Profit and Loss &nbsp;
            <Button
              onClick={handleShowDetailToggle}
              className="pull-right"
              color="success"
            >
              Toggle Detail&nbsp;
              <Icon i="info-circle" />
            </Button>
            <Button
              onClick={handleSynchroniseProfitAndLoss}
              disabled={refreshingCache}
              className="pull-right"
              color="default"
            >
              Refresh&nbsp;
              <Icon i="refresh" isBusy={refreshingCache} />
            </Button>
          </h3>
          {summaryError && (
            <div className="text-warning">
              <span>
                {`Error loading Project Summary data: ${summaryError}`}
              </span>
            </div>
          )}
          {projectSummary && <ProjectSummary summaryData={projectSummary} />}
          {rowData.quickbooks_subcustomer_id ? (
            <div className="mt-4">
              <p>
                QuickBooks Project is Linked (
                {rowData.quickbooks_subcustomer_id}
                )
              </p>
            </div>
          ) : (
            <div>
              <p className="text-danger">No QuickBooks Project Found</p>
              <p>You may need to link this project to QuickBooks manually.</p>
            </div>
          )}
          {isLoading && (
            <div>
              Loading... &nbsp;
              <Icon i="rolling" />
            </div>
          )}
          {widgetData && (
            <div>
              {widgetData[0] && (
                <p>
                  Last Updated: &nbsp;
                  {moment(widgetData[0].user_audit.updated_at).format('MMMM Do YYYY, h:mm:ss a')}
                </p>
              )}
              <table
                className="table table-striped color-table success-table"
                style={{ border: '1px solid silver' }}
              >
                <thead>
                  <tr>
                    <th className="pl-5">Account</th>
                    <th className="text-right">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {widgetData.length === 0 && (
                    <tr>
                      <td colSpan={2}> No Records to Display... </td>
                    </tr>
                  )}
                  {widgetData.map((categoryRow) => {
                    if (categoryRow.has_account_lines) {
                      return (
                        <ProfitAndLossTableAccountLineSection categoryRow={categoryRow} key={categoryRow.id} />
                      );
                    }
                    if (!categoryRow.has_account_lines) {
                      return (
                        <Fragment key={categoryRow.id}>
                          <tr>
                            <td className="font-weight-bold text-uppercase">
                              {getLabel(categoryRow.summary_title)}
                            </td>
                            <td className="font-weight-bold text-right">
                              {formatValueString(categoryRow.summary_value, COLUMN_FORMAT.CURRENCY)}
                            </td>
                          </tr>
                        </Fragment>
                      );
                    }
                    return null;
                  })}
                  {/* This gets passed down from above if/once the sister component for T-Sheets loads */}
                  {/* {!tSheetsTotal && (
                      <tr>
                        <td colSpan="2" className="font-weight-bold text-uppercase text-warning">
                          No T-Sheets Labour Hours Registered Yet
                        </td>
                      </tr>
                    )} */}
                  {/* {tSheetsTotal > 0 && (
                      <>
                        <tr>
                          <td className="font-italic">Labour Expenses</td>
                          <td className="text-right">&nbsp;</td>
                        </tr>
                        <tr>
                          <td className="pl-5">T-Sheets Labour Cost</td>
                          <td className="text-right">
                            {formatValueString(tSheetsTotal, COLUMN_FORMAT.CURRENCY)}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold text-uppercase">
                            Net Earnings
                          </td>
                          <td className="font-weight-bold text-right">
                            {formatValueString(netIncomeValue - tSheetsTotal, COLUMN_FORMAT.CURRENCY)}
                          </td>
                        </tr>
                      </>
                    )} */}
                </tbody>
              </table>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
