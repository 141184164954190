import { defaultTableSettings } from './default-table-settings';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { COLUMN_FORMAT } from '../constants/column-format.const';

/**
 * Table Definition for Security Categories Table
 *
 * @todo import securityCategoriesTableInitialSettings in /reducers/portal-datatable/settings-reducer.js
 *
 */
const securityPermissionTableInitialSettings = {
  ...defaultTableSettings,
  // where to focus on form render
  rowKeyField: 'name',
  focusField: 'name',
  baseRoute: '/permission',

  columnPresets: [
    {
      name: 'default',
      title: 'Default Fields',
      fields: [
        'name',
        '_readable_name',
        '_description',
      ],
    },
  ],
  /**
   * Definition of Columns for this table
   */
  columns: [
    {
      name: 'name',
      title: 'Tag',
      description: 'Security Permission name',
      format: COLUMN_FORMAT.TEXT,
      visible: true,
      order: 1,
    },
    {
      name: '_readable_name',
      title: 'Name',
      description: 'Readable Permission description',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      order: 2,
    },
    {
      name: '_description',
      title: 'Description',
      description: 'Long Permission description',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      order: 3,
    },
  ],

  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Security Permission Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'roles',
      description: 'Security Permission Assigned to Roles',
      title: 'Attached to Roles',
      widgetDefinition: WIDGET_DEFINITION.SECURITY_PERMISSION_ROLE,
      apiRoute: '/role',
    },
    {
      name: 'users',
      description: 'Security Permission Assigned to Users',
      title: 'Attached to Users',
      widgetDefinition: WIDGET_DEFINITION.SECURITY_PERMISSION_USER,
      apiRoute: '/user',
    },
  ],
};

export default securityPermissionTableInitialSettings;
