/* eslint-disable key-spacing */
export const INTERNAL_PROJECT_STATUS = {
  REQUESTED: 1,
  DOCUMENTING: 2,
  REVIEWING: 3,
  APPROVED: 4,
  SCHEDULED: 5,
  IN_PROGRESS: 6,
  ON_HOLD: 7,
  IN_UAT: 8,
  COMPLETED: 9,
  CANCELLED: 10,
} as const;
export type INTERNAL_PROJECT_STATUS = typeof INTERNAL_PROJECT_STATUS;
export type AN_INTERNAL_PROJECT_STATUS = INTERNAL_PROJECT_STATUS[keyof INTERNAL_PROJECT_STATUS];

export const INTERNAL_PROJECT_STATUS_NAME_MAP = {
  [INTERNAL_PROJECT_STATUS.REQUESTED]: 'Requested',
  [INTERNAL_PROJECT_STATUS.DOCUMENTING]: 'Documenting',
  [INTERNAL_PROJECT_STATUS.REVIEWING]: 'Reviewing',
  [INTERNAL_PROJECT_STATUS.APPROVED]: 'Approved',
  [INTERNAL_PROJECT_STATUS.SCHEDULED]: 'Scheduled',
  [INTERNAL_PROJECT_STATUS.IN_PROGRESS]: 'In Progress',
  [INTERNAL_PROJECT_STATUS.ON_HOLD]: 'On Hold',
  [INTERNAL_PROJECT_STATUS.IN_UAT]: 'In UAT',
  [INTERNAL_PROJECT_STATUS.COMPLETED]: 'Completed',
  [INTERNAL_PROJECT_STATUS.CANCELLED]: 'Cancelled',
};
