import React from 'react';
import moment, { Moment } from 'moment';
import { Card, CardBody, CardText } from 'reactstrap';
import { GanttViewProjectRow } from './gantt-view-project-row';
import { ISprintRecord } from '../../types/internal-project/sprint.record.interface';
import { IInternalProjectRecord } from '../../types/internal-project/internal-project.record.interface';
import { IInternalProjectTaskRecord } from '../../types/internal-project/internal-project-task.record.interface';
import { GanttViewTaskRow } from './gantt-view-task-row';

interface IGanttViewSprintRowProps {
  viewStartMoment: Moment,
  dayRemWidth: number,
  sprintRecord: ISprintRecord
}

/**
 * Gantt View Sprint Row
 *
 * @param {IGanttViewSprintRowProps} props
 *
 * @param {Moment} viewStartMoment
 * @param {number} dayRemWidth
 * @param {ISprintRecord} sprintRecord
 */
export const GanttViewSprintRow: React.FC<IGanttViewSprintRowProps> = ({ viewStartMoment, sprintRecord, dayRemWidth }) => {
  const sprintStartMoment = moment(sprintRecord.start);
  const sprintEndMoment = moment(sprintRecord.end);
  const startGap = sprintStartMoment.diff(viewStartMoment, 'days');
  const widthGap = sprintEndMoment.diff(sprintStartMoment, 'days');
  const marginLeft = `${dayRemWidth * (startGap + 1)}rem`;
  const sprintWidth = `${dayRemWidth * (widthGap)}rem`;
  return (
    <div key={sprintRecord.id}>
      <Card className="sprint-bar" style={{ marginLeft, width: sprintWidth }}>
        <CardBody>
          <CardText className="inner" tag="div">
            <div>{sprintStartMoment.format('Do')}</div>
            <div>{sprintRecord.name}</div>
            <div>{sprintEndMoment.format('Do')}</div>
          </CardText>
        </CardBody>
      </Card>
      <div className="project-row-container" style={{ marginLeft }}>
        {sprintRecord.projects && sprintRecord.projects.map((project: IInternalProjectRecord) => (
          <GanttViewProjectRow project={project} key={project.id} />
        ))}
      </div>
      <div className="item-row-container" style={{ marginLeft }}>
        {sprintRecord.items && sprintRecord.items.map((item: IInternalProjectTaskRecord) => (
          <GanttViewTaskRow task={item} key={item.id} />
        ))}
      </div>
    </div>
  );
};
