import React, { useCallback, useContext } from 'react';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { IInternalProjectRecord } from '../../types/internal-project/internal-project.record.interface';
import { NewRecordFormProps } from '../../types/poly-form/new-record-form.props';

import { IFormFieldOverride } from '../../types/poly-form/form-field-override.interface';
import { APIPolyForm } from './api-poly-form';

import { mapTableToForm } from '../../helpers/map-table-to-form.helper';

import internalProjectsTableInitialSettings from '../../table-definitions/internal-projects-table';
import { API_ACTION } from '../../constants/api-action.const';
import { CurrentUserContext } from '../providers/current-user-provider';
import { PERMISSION } from '../../constants/permissions.const';

const formFields = mapTableToForm(internalProjectsTableInitialSettings);

export type NewInternalProjectFormProps = NewRecordFormProps<IInternalProjectRecord> & {
  formFieldOverrides?: IFormFieldOverride[],
}

export const NewInternalProjectForm: React.FC<NewInternalProjectFormProps> = (props) => {
  const {
    onFormComplete, onFormChange, initialData, formFieldOverrides,
  } = props;

  const { userHasPermissions } = useContext(CurrentUserContext);
  const showFullForm = userHasPermissions([
    PERMISSION.INTERNAL_PROJECT_ADMIN,
    PERMISSION.INTERNAL_PROJECT_EDITOR,
  ]);
  const userOnlyFields = ['name', 'description', 'external_url', 'type'];
  let applicableFields = [];
  if (showFullForm) {
    applicableFields = formFields;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    applicableFields = formFields.filter((formField: any) => userOnlyFields.indexOf(formField.name) > -1);
  }

  /**
   * Fired when the APIPolyForm successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, id: FormFieldComponentProps['value'], newData?: Partial<IInternalProjectRecord>) => {
    if (onFormComplete) onFormComplete(success, id as IInternalProjectRecord['id'], newData as IInternalProjectRecord);
  }, [onFormComplete]);

  return (
    <APIPolyForm
      {...internalProjectsTableInitialSettings}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fields={applicableFields.map((formField: any) => ({
        ...formField,
        ...(formFieldOverrides ? formFieldOverrides.find((f) => f.name === formField.name) : {}),
      }))}
      formData={initialData ?? {}}
      permittedActions={internalProjectsTableInitialSettings.possibleActions.filter((action) => (action.name === API_ACTION.CREATE))}
      onFormComplete={handleFormComplete}
      onFormChange={onFormChange}
      isNewRecord
      isEditing
    />
  );
};
