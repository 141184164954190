import moment from 'moment';
import * as urlStateManager from '../../url-state-manager/url-state-manager';
import isConvertableToFiniteNumber from '../../../helpers/is-convertable-to-finite-number';
import { hasStringProperty } from '../../../helpers/type-guards';
import {
  projectSummaryYearOptions,
} from '../../../utils/constants';
import { COLUMN_FORMAT } from '../../../constants/column-format.const';
import { DATE_FORMAT } from '../../../constants/date-format.const';
import { PROJECT_STATUS, PROJECT_STATUS_NAME_MAP } from '../../../constants/project-status.const';

export const STATELESS_PM_ID_PREFIX = 'not-set-state-';
export const REPORT_BASE_API_PATH = '/report/project/forecastbalances/';
export const DATA_TYPE = {
  GEOGRAPHICAL_STATE: 'GEOGRAPHICAL_STATE',
  PROJECT_MANAGER: 'PROJECT_MANAGER',
  PROJECT: 'PROJECT',
};

/**
 * @description
 * Project Summary: Summary Fields (left side) of the Project Summary 'expected_start_total'
 *
 * @type {{
 * }}
 */
export const defaultSalesForecastSummaryFields = [
  {
    key: 'name',
    label: 'Name',
    visible: true,
    locked: true,
    format: COLUMN_FORMAT.SHORT_TEXT,
  },
  {
    key: 'count',
    label: 'Count',
    visible: true,
    format: COLUMN_FORMAT.NUMBER,
    toolTip: 'The number of projects with a Forecast, Order or Invoice within the specified date range.',
  },
  {
    key: 'value',
    label: 'Project Total',
    visible: true,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: 'The listed total value for the projects within the date range',
  },
  {
    key: 'total_total_forecast_amount',
    label: 'Forecast Total',
    visible: false,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: 'The $ project total amount within the specified date range.',
  },
  {
    key: 'total_forecast_amount',
    label: 'Partials Sum',
    visible: true,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: 'The $ forecast amount within the specified date range.',
  },
  {
    key: 'total_order_amount',
    label: 'Ordered',
    visible: false,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: 'The $ order amount within the specified date range.',
  },
  {
    key: 'total_invoice_amount',
    label: 'Invoiced',
    visible: false,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: 'The $ invoice amount within the specified date range.',
  },
  {
    key: 'total_balance_amount',
    label: 'Balance',
    visible: false,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: 'The $ balance within the specified date range. $ Balance is the $ Forecast amount less the $ Invoice amount.',
  },
  {
    key: 'grand_total_forecast_amount',
    label: 'Project Forecast',
    visible: false,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: 'The total $ Forecast amount for any project included in the report regardless of whether the forecast falls within the specified date range.',
  },
  {
    key: 'grand_total_order_amount',
    label: 'Project Ordered',
    visible: false,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: 'The total $ Order amount for any project included in the report regardless of whether the order falls within the specified date range.',
  },
  {
    key: 'grand_total_invoice_amount',
    label: 'Project Invoiced',
    visible: false,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: 'The total $ Invoice amount for any project included in the report regardless of whether the invoice falls within the specified date range.',
  },
  {
    key: 'grand_total_balance_amount',
    label: 'Project Balance',
    visible: false,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: [
      'The total $ Balance amount for any project included in the report regardless of whether the invoice or forecast',
      'falls within the specified date range. $ Balance is the $ Forecast amount less the $ Invoice amount.',
    ].join(' '),
  },
];

export const defaultSalesForecastMonthlyFields = [
  {
    key: 'expected_start_total',
    label: 'PO Expected',
    visible: true,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: 'The sum of all $ Forecast amounts where the expected start date falls within this month.',
  },
  {
    key: 'month_forecast_amount',
    label: 'Inv Forecast',
    visible: true,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: 'The sum of all $ Forecast amounts where the expected date falls within this month.',
  },
  {
    key: 'month_forecast_count',
    label: 'Forecast Count',
    visible: false,
    format: COLUMN_FORMAT.NUMBER,
    toolTip: 'The count of all Forecasts where the expected date falls within this month.',
  },
  {
    key: 'month_order_amount',
    label: 'Ordered',
    visible: false,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: 'The sum of all $ Order amounts where the order received date falls within this month.',
  },
  {
    key: 'month_order_count',
    label: 'Order Count',
    visible: false,
    format: COLUMN_FORMAT.NUMBER,
    toolTip: 'The count of all Orders where the order received date falls within this month.',
  },
  {
    key: 'month_invoice_amount',
    label: 'Invoiced',
    visible: false,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: 'The sum of all $ Invoice amounts where the invoice sent date falls within this month.',
  },
  {
    key: 'month_invoice_count',
    label: 'Invoice Count',
    visible: false,
    format: COLUMN_FORMAT.NUMBER,
    toolTip: 'The count of all Invoices where the invoice sent date falls within this month.',
  },
  {
    key: 'month_balance_amount',
    label: 'Balance',
    visible: false,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: 'The sum of all $ Forecast amounts less the sum of all $ Invoice amounts for this month',
  },
  {
    key: 'cumulative_forecast_amount',
    label: 'Cumulative Forecast Amount',
    visible: false,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: 'The cumulative sum of all $ Forecast amounts up until this month.',
  },
  {
    key: 'cumulative_order_amount',
    label: 'Cumulative Order Amount',
    visible: false,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: 'The cumulative sum of all $ Order amounts up until this month.',
  },
  {
    key: 'cumulative_invoice_amount',
    label: 'Cumulative Invoice Amount',
    visible: false,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: 'The cumulative sum of all $ Invoice amounts up until this month.',
  },
  {
    key: 'cumulative_balance_amount',
    label: 'Cumulative Balance',
    visible: false,
    format: COLUMN_FORMAT.CURRENCY,
    toolTip: 'The cumulative sum of all $ Balance amounts up until this month. $ Balance is the $ Forecast amount less the $ Invoice amount.',
  },
];


/**
 * @description
 * A list of statuses that can be included or excluded
 */
export const defaultStatusOptions = [
  {
    name: PROJECT_STATUS_NAME_MAP[PROJECT_STATUS.PROPOSAL],
    id: PROJECT_STATUS.PROPOSAL,
    key: PROJECT_STATUS.PROPOSAL,
    visible: true,
  },
  {
    name: PROJECT_STATUS_NAME_MAP[PROJECT_STATUS.ACTIVE],
    id: PROJECT_STATUS.ACTIVE,
    key: PROJECT_STATUS.ACTIVE,
    visible: false,
  },
  {
    name: PROJECT_STATUS_NAME_MAP[PROJECT_STATUS.COMPLETED],
    id: PROJECT_STATUS.COMPLETED,
    key: PROJECT_STATUS.COMPLETED,
    visible: false,
  },
];

/**
 * @constant baseFilterString the base string to add to all salesforecast requests
 */
export const baseFilterString = '&provide_summary_source=false&people_source=salespeople';

/**
 * @description
 * Transforms the Salesperson and State Ids into a format suitable for use in the URL state
 *
 * @param {{ pmId: string, stateId: string }[]} pmAndStateId
 */
export const transformPmAndStateIdsForUrlState = (pmAndStateIds) => (pmAndStateIds && pmAndStateIds.length > 0
  ? pmAndStateIds.map(({ pmId, stateId }) => [pmId, stateId].join('+')).join(',')
  : urlStateManager.REMOVE_FROM_URL);


/**
 * @description
 * Transforms an array of State Ids into a format suitable for use in the URL state
 * @param {*} stateIds
 */
export const transformStateIdsForUrlState = (stateIds) => (stateIds && stateIds.length > 0
  ? stateIds.join(',')
  : urlStateManager.REMOVE_FROM_URL);


/**
 * @description
 * Transform the url state into start and end moments
 *
 * @param {{
  *  startYear: string,
  *  startMonth: string,
  *  endYear: string,
  *  endMonth: string,
  * }} param0
  *
  */
export const urlStateToStartAndEndMoments = ({
  startYear, startMonth, endYear, endMonth,
}) => {
  const startMoment = moment(`${startYear}-${startMonth}`, DATE_FORMAT.YEAR_MONTH_DASHES);
  const endMoment = moment(`${endYear}-${endMonth}`, DATE_FORMAT.YEAR_MONTH_DASHES);
  return { startMoment, endMoment };
};


/**
* @description
* Get years/months between a start and end
*
* @param {moment} startMoment
* @param {moment} endMoment
* @returns {string[]}
*/
export const getMonthsBetween = (startMoment, endMoment) => {
  let current = startMoment.clone();
  const months = [];
  do {
    months.push({
      key: current.format(DATE_FORMAT.YEAR_MONTH_DASHES),
      label: current.format(DATE_FORMAT.LONG_MONTH_NAME_AND_YEAR),
    });
    current = current.add(1, 'months');
  } while (current <= endMoment);
  return months;
};


/**
 * @description
 * Returns a string for use in the API queries which represents the properly formatted
 * start and end months
 *
 * @param {{
 *  startMonth: string,
 *  startYear: string,
 *  endMonth: string,
 *  endYear: string,
 * }} param0
 *
 * @returns {string}
 */
export const getDateRangeString = ({
  startMonth, startYear, endMonth, endYear,
}) => `month_from=${startYear}-${startMonth}&month_to=${endYear}-${endMonth}`;


/**
 * @description
 * Concatenate a set of Geographical State Ids together in preparation for use in a query to the API
 *
 * @param {number[]} stateIds
 *
 * @returns {string}
 */
export const getGeographicalStateFilterString = (stateIds = []) => {
  if (Array.isArray(stateIds) && stateIds.length > 0) { return stateIds.reduce((acc, stateId) => `${acc}&state_ids[]=${stateId}`, ''); }
  return '';
};

/**
* @description
* Concatenate a set of Geographical State Ids together in preparation for use in a query to the API
*
* @param {number[]} stateIds
*
* @returns {string}
*/
export function getLikelihoodFilterString(lowLikelihoodFilter, highLikelihoodFilter) {
  return `&lowest_likelihood=${parseInt(lowLikelihoodFilter, 10)}&highest_likelihood=${parseInt(highLikelihoodFilter, 10)}`;
}

/**
 * Get a QueryString Array of Integer Status Ids for only Visible Statuses
 *
 * @param {{
 *    id: integer,
 *    name: string,
 *    visible: bool
 * }[]} statusList the full status list of objects
 */
export function getVisibleStatusIdsFilterString(statusList) {
  return statusList
    .filter((status) => status.visible)
    .map((status) => `&status_ids[]=${status.id}`)
    .join('');
}


/**
 * @description
 * Concatenate a set of State and Salesperson Ids together in preparation for use in a query to the API
 *
 * @param {{
 *  stateId: number,
 *  pmId: number,
 * }[]} pmAndStateIds
 *
 * @returns {string}
 */
export const getSalespersonFilterString = (pmAndStateIds = []) => {
  let result = '';

  if (Array.isArray(pmAndStateIds) && pmAndStateIds.length > 0) {
    const pmIds = Array.from(new Set(pmAndStateIds.map((pmAndStateId) => pmAndStateId.pmId)));
    const stateIds = Array.from(new Set(pmAndStateIds.map((pmAndStateId) => pmAndStateId.stateId)));
    result = `${
      result
    }${
      pmIds.reduce((acc, pmId) => `${acc}&salesperson_ids[]=${pmId}`, '')
    }${
      stateIds.reduce((acc, stateId) => `${acc}&state_ids[]=${stateId}`, '')
    }`;
  }

  return result;
};


/**
 * @description
 * Check that an array of salesperson ids are valid
 *
 * @param {[string, string][]} pmAndStateIds
 */
export const pmsAreValid = (pmAndStateIds) => pmAndStateIds.every(
  (pmAndStateId) => {
    // ensure there exists both a pm and state id
    if (pmAndStateId.length !== 2) return false;

    const [pmId, stateId] = pmAndStateId;

    const validPm = isConvertableToFiniteNumber(
      pmId.startsWith(STATELESS_PM_ID_PREFIX)
        ? pmId.replace(STATELESS_PM_ID_PREFIX, '')
        : pmId,
    );

    const validState = isConvertableToFiniteNumber(stateId);

    return validPm && validState;
  },
);


/**
 * @description
 * Get the default values for the start and end month and year
 *
 * @returns {{
 *  startMonth: string,
 *  startYear: string,
 *  endMonth: string,
 *  endYear: string,
 * }}
 */
export const defaultDateRange = () => {
  const start = moment();
  const end = moment().add(3, 'years');

  return {
    startMonth: parseInt(start.format('MM'), 10).toString().padStart(2, 0),
    startYear: parseInt(start.format('YYYY'), 10).toString().padStart(4, 0),
    endMonth: parseInt(end.format('MM'), 10).toString().padStart(2, 0),
    endYear: parseInt(end.format('YYYY'), 10).toString().padStart(4, 0),
  };
};

/**
 * @description
 * Increments a year-month code by the monthCount
 *
 * @param {{
 *  year: string,
 *  month: string,
 * }} param0 the year and month "YYYY-MM" values to increment
 * @param {number} monthCount
 *
 * @returns {{
 *  year: string,
 *  month: string,
 * }}
 */
export const incMonth = ({ year, month }, monthCount) => {
  const parsedMonth = moment(`${year}-${month}`, DATE_FORMAT.YEAR_MONTH_DASHES, true);
  const incrementedMonth = parsedMonth.add(monthCount, 'months');

  const minMonth = moment(`${projectSummaryYearOptions[0].id}-01`, DATE_FORMAT.YEAR_MONTH_DASHES, true);
  const maxMonth = moment(`${projectSummaryYearOptions[projectSummaryYearOptions.length - 1].id}-12`, DATE_FORMAT.YEAR_MONTH_DASHES, true);

  const result = moment.max([moment.min([incrementedMonth, maxMonth]), minMonth]);
  return {
    year: result.format('YYYY').toString().padStart(4, 0),
    month: result.format('MM').toString().padStart(2, 0),
  };
};


/**
 * @description
 * Wash the incoming URL state in preparation for the fields this component cares about
 *
 * @param {string} dirtyUrlState
 * @returns {{
 *  pmAndStateIds: {
 *    pmId: PropTypes.string,
 *    stateId: PropTypes.string,
 *  }[],
 *  stateIds: string[],
 *  startYear: string,
 *  startMonth: string,
 *  endYear: string,
 *  endMonth: string,
 *  hideEmptyStates: boolean,
 * }}
 */
export const washSalesForecastUrlState = (dirtyUrlState) => {
  let cleanUrlState = {};

  // Start and End Dates
  // ------------------------------
  const defaultDates = defaultDateRange();
  const startMonth = hasStringProperty(dirtyUrlState, 'startMonth') ? dirtyUrlState.startMonth : defaultDates.startMonth;
  const startYear = hasStringProperty(dirtyUrlState, 'startYear') ? dirtyUrlState.startYear : defaultDates.startYear;
  const endMonth = hasStringProperty(dirtyUrlState, 'endMonth') ? dirtyUrlState.endMonth : defaultDates.endMonth;
  const endYear = hasStringProperty(dirtyUrlState, 'endYear') ? dirtyUrlState.endYear : defaultDates.endYear;

  const start = moment(`${startYear}-${startMonth}`, 'YYYY-MM', true);
  const end = moment(`${endYear}-${endMonth}`, 'YYYY-MM', true);

  // if not valid, reset start and end to defaults
  if (!start || !end || !start.isValid() || !end.isValid() || start.isAfter(end)) {
    cleanUrlState = {
      ...cleanUrlState,
      ...defaultDateRange(),
    };
  }
  else {
    cleanUrlState.startMonth = parseInt(start.format('MM'), 10).toString().padStart(2, 0);
    cleanUrlState.startYear = parseInt(start.format('YYYY'), 10).toString().padStart(4, 0);
    cleanUrlState.endMonth = parseInt(end.format('MM'), 10).toString().padStart(2, 0);
    cleanUrlState.endYear = parseInt(end.format('YYYY'), 10).toString().padStart(4, 0);
  }

  // Salespersons and State IDs
  // ------------------------------
  cleanUrlState.pmAndStateIds = [];
  if (hasStringProperty(dirtyUrlState, 'pmAndStateIds')) {
    const pmAndStateIds = dirtyUrlState
      .pmAndStateIds
      .split(',')
      .map((pmAndStateString) => pmAndStateString.split('+'));
    if (pmsAreValid(pmAndStateIds)) cleanUrlState.pmAndStateIds = pmAndStateIds.map(([pmId, stateId]) => ({ pmId: parseInt(pmId, 10), stateId: parseInt(stateId, 10) }));
  }

  // State IDs
  // ------------------------------
  cleanUrlState.stateIds = [];
  if (hasStringProperty(dirtyUrlState, 'stateIds')) {
    const cleanStateIds = dirtyUrlState.stateIds.split(',');
    if (cleanStateIds.every((stateId) => isConvertableToFiniteNumber(stateId))) cleanUrlState.stateIds = cleanStateIds.map((stateId) => parseInt(stateId, 10));
  }

  return cleanUrlState;
};


/**
 * @description
 * construct a string for use when identifying a row in the DOM via a `data-id` attribute
 *
 * @param {DATA_TYPE} dataType
 * @param {number} stateId
 * @param {number} salespersonId
 * @param {number} projectId
 *
 * @returns {string}
 */
export const buildDomDataTagId = (dataType, stateId, salespersonId, projectId) => {
  let result = `${stateId}`;

  if ([DATA_TYPE.PROJECT_MANAGER, DATA_TYPE.PROJECT].includes(dataType)) {
    result += `_${salespersonId}`;
  }

  if (dataType === DATA_TYPE.PROJECT) {
    result += `_${projectId}`;
  }

  return result;
};


/**
 * @description
 * Take a DOM `data-id` attribute and break it down into state / PM and Project IDs
 * @param {DATA_TYPE} dataType
 * @param {string} id
 *
 * @returns {{
 *  stateId: number,
 *  salespersonId: number | null,
 *  projectId: number | null,
 * }}
 */
export const deConstructDomDataTagId = (dataType, id) => {
  const parts = id.split('_');
  return {
    stateId: parts.length ? parseInt(parts[0], 10) : null,
    salespersonId: (([DATA_TYPE.PROJECT_MANAGER, DATA_TYPE.PROJECT].includes(dataType)) && parts.length >= 2) ? parseInt(parts[1], 10) : null,
    projectId: (dataType === DATA_TYPE.PROJECT && parts.length >= 3) ? parseInt(parts[2], 10) : null,
  };
};


/**
 * Return project name hyphenated with client name
 *
 * @todo convert to TypeScript and put somewhere more generic.
 *
 * @param {string} projectName The Project Name
 * @param {string} clientName The Project's Client's name
 *
 * @returns {string} the hyphenated long name
 */
export const projectNameWithClientName = (projectName, clientName) => {
  const hyphenatedClientName = `${clientName} - `;
  if (projectName.startsWith(hyphenatedClientName)) {
    return projectName;
  }
  if (projectName.startsWith(clientName)) {
    return projectName.replace(clientName, hyphenatedClientName);
  }
  return `${hyphenatedClientName}${projectName}`;
};
