import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';

export const LeaveBlocksWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Leave Block',
  fields: [
    {
      name: 'leave_application_id',
      visible: false,
      isParentId: true,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'start_date',
      title: 'Start date',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      format: COLUMN_FORMAT.LONG_DATE,
    },
    {
      name: 'end_date',
      title: 'End date',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      format: COLUMN_FORMAT.LONG_DATE,
    },
    {
      name: 'leave_block_type',
      title: 'Leave type',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.LEAVE_TYPE_PICKER,
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'leave_block_type',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formSaveField: 'leave_block_type_id',
    },
    {
      name: 'paid_days',
      title: 'Paid days',
      initialValue: '0.0',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.LEAVE_DAYS_INPUT,
      format: COLUMN_FORMAT.LEAVE_DAYS,
      showTotals: true,
    },
    {
      name: 'unpaid_days',
      title: 'Unpaid days',
      initialValue: '0.0',
      visible: true,
      formFieldType: FORM_FIELD_TYPE.LEAVE_DAYS_INPUT,
      format: COLUMN_FORMAT.LEAVE_DAYS,
      showTotals: true,
    },
  ],
};
