import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Base64 } from 'js-base64';
import { Container, ButtonGroup, Button } from 'reactstrap';
import PageHeader from '../app-layout/page-header';
import { startCase } from '../../utils/helpers';
import { PUSH_OR_REPLACE } from '../../utils/constants';
import ProjectsByStatusByRelation, {
  PROJECT_BY_STATUS_BY_RELATION_RELATION_TYPE,
  PROJECTS_BY_STATUS_BY_RELATION_VALUE_TYPE,
} from '../portal-fusion-chart/projects-by-status-by-relation-chart';
import { financialYear } from '../../helpers/financial-year-moments';
import * as urlStateManager from '../url-state-manager/url-state-manager';
import DrillDownTable from '../portal-data-table/drill-down-table';
import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { ScrollToTopOnMount } from '../router/scroll-to-top-on-mount';
import { SERObject } from '../../helpers/ser-object.helper';
import { PROJECT_STATUS } from '../../constants/project-status.const';

const chartIds = {
  SALES_PERFORMANCE: 'chart_sales_performance',
};

const getSalesPerformanceChartStatusOptions = () => [
  {
    name: 'New Leads',
    statusId: PROJECT_STATUS.LEAD,
    visible: true,
  },
  {
    name: 'New Proposals',
    statusId: PROJECT_STATUS.PROPOSAL,
    visible: true,
  },
  {
    name: 'Lost Opportunities',
    statusId: PROJECT_STATUS.LOST,
    visible: false,
  },
  {
    name: 'Projects Activated',
    statusId: PROJECT_STATUS.ACTIVE,
    visible: true,
  },
  {
    name: 'Projects Completed',
    statusId: PROJECT_STATUS.COMPLETED,
    visible: true,
  },
  {
    name: 'Archived Archived',
    statusId: PROJECT_STATUS.ARCHIVED,
    visible: true,
  },
];


/**
 * @class SalesPerformance
 */
class SalesPerformance extends Component {
  /**
   * @constructor
   *
   * @param {{}} props
   */
  constructor(props) {
    super(props);

    this.state = {
      salesPerformanceChartIsLoading: true,
      salesPerformanceChartStyle: {},
      salesPerformanceChartStatusOptions: getSalesPerformanceChartStatusOptions(),
      hideInactiveUsers: false,
    };
  }


  /**
   * @description
   * Get the current drillDownSource
   *
   * @returns {{ title: string, params: Record<string, string>, chartId: string } |  null}
   */
  get drillDownSource() {
    const { urlState, setUrlState } = this.props;
    const { drillDownSource } = urlState;

    const decodeOperation = this.decodeDrillDownSource(drillDownSource);

    if (!decodeOperation.success) {
      // failed - unset drillDownSource
      console.error('failed do decode dds');
      setUrlState({ drillDownSource: null });
      return null;
    }

    return decodeOperation.result;
  }


  /**
   * @description
   * decode a drillDownSource object
   *
   * @returns {SERObject}
   */
  decodeDrillDownSource = (drillDownSource) => {
    const ser = new SERObject(true);
    try {
      ser.result = drillDownSource ? JSON.parse(Base64.decode(drillDownSource)) : null;
    }
    catch (e) {
      ser.success = false;
      ser.error = `Unable to get drillDownSource ${e}`;
      console.error('Unable to get drillDownSource', e);
    }
    return ser;
  }


  /**
   * @description
   * Get the title for a Drill Down Table corresponding to the Plot Data Item
   *
   * @param {(typeof chartIds)} chartId
   * @param {{
   *  source: {
   *    params: Record<string, string>,
   *    source: string | 'http://api.ciportal.local/v1/project',
   *  },
   *  [responseKey: string]: string | number,
   * }} dataItem
   * @param {{
   *  categoryLabel: string | '120 Days'
   *  dataValue: string | number | 319
   *  displayValue: string | '319'
   *  toolText: '120 Days, 319',
   * }} dataPlot
   */
  getDrillDownTitleFromDataItem = (chartId, dataItem, dataPlot) => {
    switch (chartId) {
      // Sales Performance
      case chartIds.SALES_PERFORMANCE: {
        const statusId = dataItem.status_id;
        let statusDescription = '';

        if (statusId === PROJECT_STATUS.LEAD) statusDescription = 'Leads';
        else if (statusId === PROJECT_STATUS.PROPOSAL) statusDescription = 'Proposals';
        else if (statusId === PROJECT_STATUS.LOST) statusDescription = 'Lost Projects';
        else if (statusId === PROJECT_STATUS.ACTIVE) statusDescription = 'Active Projects';
        else if (statusId === PROJECT_STATUS.COMPLETED) statusDescription = 'Completed Projects';
        else if (statusId === PROJECT_STATUS.ARCHIVED) statusDescription = 'Archived Projects';
        else throw new ReferenceError(`Unable to get phrase for Project Status: "${statusId}"`);

        return `${statusDescription} for ${dataPlot.categoryLabel}`;
      }
      default: throw new ReferenceError(`Unhandled chartId "${chartId}"`);
    }
  }

  /**
   * @description
   * Fired when the user asks to change the value type of one of the charts
   */
  setChartValueType = (chartId, valueType) => {
    const { urlState, setUrlState } = this.props;

    if (chartId === chartIds.SALES_PERFORMANCE && urlState.salesPerformanceChartValueType !== valueType) {
      setUrlState({ salesPerformanceChartValueType: valueType }, PUSH_OR_REPLACE.REPLACE);
    }
  }


  /**
   * @description
   * Fired when one of the charts starts or finishes loading
   */
  handleChartLoading = (chartId, isLoading) => {
    if (chartId === chartIds.SALES_PERFORMANCE) {
      this.setState({ salesPerformanceChartIsLoading: isLoading });
    }
  }


  /**
   * @description
   * Fired when a Data Item is clicked
   *
   * @param {string} chartId
   * @param {object} dataItem
   * @param {object} dataPlot
   */
  handleClickDataItem = (chartId, dataItem, dataPlot) => {
    const { setUrlState } = this.props;

    const encodedDrillDownSource = Base64.encodeURI(JSON.stringify({
      chartId,
      title: this.getDrillDownTitleFromDataItem(chartId, dataItem, dataPlot),
      source: dataItem.source,
    }));

    setUrlState({ drillDownSource: encodedDrillDownSource }, PUSH_OR_REPLACE.REPLACE);
  }

  /**
   * @description
   * Fired when chart data is mapped
   *
   * @param {string} chartId
   * @param {{
   *  chartData: { data: {}[] }[],
   * }} param1
   *
   * @returns {string}
   */
  handleChartDataMapped = (chartId, { chartData }) => {
    if (chartId === chartIds.SALES_PERFORMANCE) {
      setTimeout(() => {
        const emsPerSeries = 2;
        const emsPerItem = 3;
        const seriesCount = chartData.length;
        const itemCount = chartData[0].data.length;
        const calculatedHeightEms = seriesCount * emsPerSeries + emsPerItem * itemCount;

        this.setState({ salesPerformanceChartStyle: { height: `${calculatedHeightEms}em` } });
      }, 0);
    }
  }


  /**
   * @description
   * Fired when a statusOptions on the the fusion chart is made visible or invisible by the user
   *
   * @param {string} chartId
   * @param {number} seriesIndex
   * @param {boolean} visible
   */
  handleSeriesVisibilityChanged = (chartId, seriesIndex, visible) => {
    if (chartId === chartIds.SALES_PERFORMANCE) {
      const { salesPerformanceChartStatusOptions } = this.state;
      this.setState({
        salesPerformanceChartStatusOptions: salesPerformanceChartStatusOptions
          .map((statusOption, index) => (index === seriesIndex
            ? { ...statusOption, visible }
            : statusOption)),
      });
    }
  }


  /**
   * @inheritdoc
   */
  render() {
    const {
      salesPerformanceChartIsLoading,
      salesPerformanceChartStatusOptions,
      salesPerformanceChartStyle,
      hideInactiveUsers,
    } = this.state;
    const { urlState } = this.props;

    const { salesPerformanceChartValueType } = urlState;

    const drillDownSource = this.drillDownSource || {};

    const financialYearStart = financialYear({ addYears: 0 });
    const financialYearEnd = financialYear({ addYears: 0, endOfYear: true });

    const salesPerformanceChartTitle = `Sales Pipeline Summary FY ${financialYearStart.format('YY')}/${financialYearEnd.format('YY')}`;

    return (
      <Container fluid className="sales-performance-dashboard">
        <PageHeader {...this.props} />
        <ScrollToTopOnMount />
        <div className="sales-performance-wrapper">
          <div className="chart-wrapper sales-performance">
            <div className="chart-title">
              <ButtonGroup>
                {Object.values(PROJECTS_BY_STATUS_BY_RELATION_VALUE_TYPE).map((valueType) => (
                  <Button
                    key={valueType}
                    onClick={() => this.setChartValueType(chartIds.SALES_PERFORMANCE, valueType)}
                    disabled={salesPerformanceChartIsLoading}
                    size="sm"
                    color="secondary"
                    active={valueType === salesPerformanceChartValueType}
                  >
                    {startCase(valueType)}

                  </Button>
                ))}
              </ButtonGroup>
              {' '}
              <ButtonGroup className="pl-2">
                <Button
                  onClick={() => this.setState({ hideInactiveUsers: !hideInactiveUsers })}
                  disabled={salesPerformanceChartIsLoading}
                  size="sm"
                  color="secondary"
                  active={hideInactiveUsers}
                >
                  {hideInactiveUsers ? 'Show' : 'Hide'}
                  {' '}
                  Inactive Users
                </Button>
              </ButtonGroup>
            </div>
            <div className="chart-container" style={salesPerformanceChartStyle}>
              <ProjectsByStatusByRelation
                id={chartIds.SALES_PERFORMANCE}
                chartCaption={salesPerformanceChartTitle}
                valueType={salesPerformanceChartValueType}
                relationType={PROJECT_BY_STATUS_BY_RELATION_RELATION_TYPE.OWNER}
                hideInactiveUsers={hideInactiveUsers}
                includeArchived
                statusOptions={salesPerformanceChartStatusOptions}
                onClickDataItem={this.handleClickDataItem}
                onClickSeriesLabel={this.handleClickSeriesLabel}
                onChangeSeriesVisibility={this.handleSeriesVisibilityChanged}
                onChartDataMapped={this.handleChartDataMapped}
                onLoading={this.handleChartLoading}
                dateFrom={financialYearStart}
                dateTo={financialYearEnd}
              />
            </div>
            {drillDownSource.chartId === chartIds.SALES_PERFORMANCE
          && drillDownSource.source
          && drillDownSource.source.params
          && drillDownSource.title
          && (
            <div className="drilldown-wrapper sales-performance">
              <DrillDownTable
                title={drillDownSource.title}
                urlIdentifier={drillDownSource.chartId}
                tableIdentifier={TABLE_IDENTIFIER.SALES_PERFORMANCE_DRILLDOWN_TABLE}
                reportFilters={drillDownSource.source.params}
              />
            </div>
          )}
          </div>
        </div>
      </Container>
    );
  }
}

SalesPerformance.propTypes = {
  urlState: PropTypes.shape({
    salesPerformanceChartValueType: PropTypes.oneOf(Object.values(PROJECTS_BY_STATUS_BY_RELATION_VALUE_TYPE)),
    drillDownSource: PropTypes.string,
  }).isRequired,
  setUrlState: PropTypes.func.isRequired,
};


const URL_STATE_DEFAULTS = {
  drillDownSource: null,
  salesPerformanceChartValueType: PROJECTS_BY_STATUS_BY_RELATION_VALUE_TYPE.VALUE,
};

/**
 * @description
 * Wash the urlState coming in from the queryString
 *
 * @param {
 *  salesPerformanceChartValueType?: PROJECTS_BY_STATUS_BY_RELATION_VALUE_TYPE,
 * } dirtyUrlState
 */
const washUrlState = (dirtyUrlState) => {
  const cleanUrlState = {};

  cleanUrlState.salesPerformanceChartValueType = Object.values(PROJECTS_BY_STATUS_BY_RELATION_VALUE_TYPE).includes(dirtyUrlState.salesPerformanceChartValueType)
    ? dirtyUrlState.salesPerformanceChartValueType
    : URL_STATE_DEFAULTS.salesPerformanceChartValueType;

  cleanUrlState.drillDownSource = dirtyUrlState.drillDownSource
    ? dirtyUrlState.drillDownSource
    : URL_STATE_DEFAULTS.drillDownSource;

  return cleanUrlState;
};

export default urlStateManager.connectUrlState(
  washUrlState,
  urlStateManager.cleanDefaultsFromUrlState(URL_STATE_DEFAULTS),
)(SalesPerformance);
