import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';

export const ExpenseBatchExpenseClaimsWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  isReadOnly: true,
  showAddBtn: false,
  itemCaption: 'Batch',
  fields: [
    {
      name: 'id',
      title: 'id',
      visible: true,
      format: COLUMN_FORMAT.ID_LINK,
      linkId: 'id',
      linkRoute: '/finance/process-expenses/claims',
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    },
    {
      name: 'expense_claim_batch_id',
      visible: false,
      isParentId: true,
    },
    {
      name: 'user',
      title: 'User',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
      formSaveField: 'user_id',
      visible: true,
    },
    {
      name: 'name',
      title: 'Description',
      description: 'Description',
      format: COLUMN_FORMAT.TEXT,
      visible: true,
    },
    {
      name: 'amount',
      title: 'Amount',
      description: 'Expense Claim total amount',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showTotals: true,
    },
    {
      name: 'status', // So sorting doesn't error
      title: 'Status',
      description: 'Status',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'status',
        key: 'name',
        format: COLUMN_FORMAT.STATUS_BADGE,
        listSource: 'expenseBatchStatus',
      },
      visible: true,
    },
  ],
};
