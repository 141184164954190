import React, { useCallback, useEffect, useMemo } from 'react';

import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { ICompanySpaceRecord } from '../../types/company/company-space.record.interface';
import { NewRecordFormProps } from '../../types/poly-form/new-record-form.props';

import { APIPolyForm } from './api-poly-form';

import { companySpaceFormFields } from '../../constants/form-fields/company-space.form-fields';
import { FORM_RENDERER_TYPE } from '../../constants/form-renderer-type.const';

// @note: this form requires that a parent company_id is passed to it in the initialData
export const NewCompanySpaceForm: React.FC<NewRecordFormProps<ICompanySpaceRecord>> = (props) => {
  const {
    onFormComplete,
    onFormChange,
    initialData,
    baseRoute = '/company',
    apiRoute = '/space',
    apiQuery = [
      'with[]=type:id,name',
      'with[]=location:id,name',
    ].join('&'),
    rendererOptions = {
      showParentLocation: false,
    },
  } = props;

  const {
    showParentLocation = false,
  } = rendererOptions;

  const company_id = useMemo<undefined | number>(() => (initialData?.company_id), [initialData]);

  /**
   * Fired when the APIPolyForm successfully saves or cancels the record creation
   */
  const handleFormComplete = useCallback((success: boolean, id: FormFieldComponentProps['value'], newData?: Partial<ICompanySpaceRecord>) => {
    if (onFormComplete) onFormComplete(success, id as ICompanySpaceRecord['id'], newData as ICompanySpaceRecord);
  }, [onFormComplete]);


  /**
   * When the form is first loaded, ensure that required fields are provided
   */
  useEffect(() => {
    // company is mandatory
    if (!company_id) throw new Error('NewCompanyLocationForm: `company_id` must be provided in the initialData!');
  }, [company_id]);


  // Render
  return (
    <APIPolyForm<ICompanySpaceRecord>
      formRendererType={FORM_RENDERER_TYPE.COMPANY_SPACE}
      rendererOptions={{
        showParentLocation,
      }}
      baseRoute={baseRoute}
      apiRoute={apiRoute}
      apiQuery={apiQuery}
      parentId={company_id}
      fields={companySpaceFormFields}
      formData={{
        ...(initialData ?? {}),
      }}
      onFormComplete={handleFormComplete}
      onFormChange={onFormChange}
      isNewRecord
      isEditing
    />
  );
};
