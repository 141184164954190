import React, { useCallback, useContext, useEffect, useState } from 'react';
import AsyncSelect from './async-select';

import { CurrentUserContext } from '../providers/current-user-provider';
import { ModalContext } from '../modals/modal-context';

import { NewRecordModalResult } from '../../types/modal/modal-result';

import { MODAL_TYPE } from '../../constants/modal-type.const';
import { PERMISSION } from '../../constants/permissions.const';
import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';
import { IContractSubtypeRecord } from '../../types/service/contract-subtype.record.interface';
import { IContractContractSubtypeRecord } from '../../types/service/contract-contract-subtype.record.interface';

export type SpaceSubtypePickerProps = AsyncSelectComponentProps;

export const SpaceSubtypePicker: React.FC<SpaceSubtypePickerProps> = (props) => {
  const {
    name,
    formData = {},
    parentData = {},
    formSaveField = null,
    isCreatable = true,
    getNewItemData,
    onChange,
  } = props;

  const { showModal } = useContext(ModalContext);
  const { userHasPermissions } = useContext(CurrentUserContext);
  const [typeId, setTypeId] = useState(formData.type_id || parentData.type_id || null);
  const [parentId, setParentId] = useState(parentData.id || null);

  /**
   * Show a modal to create a new ContractSubtype
   */
  const handleCreateOption = useCallback((inputValue: string) => {
    const initialData = {
      name: inputValue,
      type_id: typeId,
      ...(getNewItemData ? getNewItemData(inputValue, formData, parentData) : {}),
    };

    showModal<NewRecordModalResult<IContractSubtypeRecord>>(MODAL_TYPE.NEW_CONTRACT_SUBTYPE, {
      initialData,
      onModalComplete: ({ success, newRecord: newContractSubtype }) => {
        if (success && onChange && newContractSubtype) {
          onChange({
            fieldName: formSaveField || `${name}_id`,
            newValue: newContractSubtype.id,
            objectFieldName: name,
            objectFieldNewValue: newContractSubtype,
          });
        }
      },
    });
  }, [formData, typeId, formSaveField, getNewItemData, name, onChange, parentData, showModal]);

  useEffect(() => {
    setTypeId(formData.type_id || parentData.type_id || null);
    setParentId(parentData.id || null);
  }, [parentData, formData]);


  return (
    <AsyncSelect
      {...props}
      searchTerm={null}
      // Make sure the current user has permissions to create a new ContractSubtype before allowing them to create a ContractSubtype
      isCreatable={!!isCreatable && userHasPermissions([PERMISSION.COMPANY_CREATE])}
      loadAndKeepAll
      cacheOptions
      defaultOptions
      searchRoute={`/contract/${parentId}/subtype`}
      getOptionLabel={(option: IContractContractSubtypeRecord | null) => (option ? option.contract_subtype?.name : '')}
      getOptionValue={(option: IContractContractSubtypeRecord | null) => (option ? option.contract_subtype?.id : null)}
      appendQuery="&with[]=contractSubtype"
      // appendQuery={`&filter[0][field]=contract_type_id&filter[0][operation]=equals&filter[0][value]=${typeId}`}
      onCreateOption={handleCreateOption}
    />
  );
};
