import { A_MODAL_TYPE, MODAL_TYPE } from './modal-type.const';
import { ActivateProjectModalWizard } from '../components/modals/activate-project-modal/activate-project-modal-wizard';
import { ConfirmModal } from '../components/modals/confirm.modal';
import { FindMicrosoftADUserIdModal } from '../components/modals/find-microsoft-ad-user-id.modal';
import { NewAllowanceModal } from '../components/modals/new-allowance.modal';
import { NewCompanyModal } from '../components/modals/new-company.modal';
import { NewCompanyLocationModal } from '../components/modals/new-company-location.modal';
import { NewCompanySpaceModal } from '../components/modals/new-company-space.modal';
import { NewCompanySpaceTypeModal } from '../components/modals/new-company-space-type.modal';
import { NewContactModal } from '../components/modals/new-contact.modal';
import { NewExpenseModal } from '../components/modals/new-expense.modal';
import { NewLeaveApplicationModal } from '../components/modals/new-leave-application.modal';
import { NewProjectLeadModal } from '../components/modals/new-project-lead.modal';
import { NewProjectProposalModal } from '../components/modals/new-project-proposal.modal';
import { NewUserModal } from '../components/modals/new-user.modal';
import { NewInternalProjectModal } from '../components/modals/new-internal-project.modal';
import { NewSprintModal } from '../components/modals/new-sprint.modal';
import { NewInternalProjectTaskModal } from '../components/modals/new-internal-project-task.modal';
import { NewTaskTemplateModal } from '../components/modals/new-task-template.modal';
import { NewPlatformModal } from '../components/modals/new-platform.modal';
import { NewTeamProcessModal } from '../components/modals/new-team-process.modal';
import { NewTeamModal } from '../components/modals/new-team.modal';
import { PasteDataFromClipboardModal } from '../components/modals/paste-data-from-clipboard.modal';
import { ProjectSummaryDashboardSettingsModal } from '../components/modals/project-summary-dashboard-settings.modal';
import { SalesForecastDashboardSettingsModal } from '../components/modals/sales-forecast-dashboard-settings.modal';
import { UpdateFileModal } from '../components/modals/update-file.modal';
import { NewContractModal } from '../components/modals/new-contract.modal';
import { NewContractTypeModal } from '../components/modals/new-contract-type.modal';
import { NewContractSubtypeModal } from '../components/modals/new-contract-subtype.modal';
import { RenewContractModalWizard } from '../components/modals/renew-contract-modal/renew-contract-modal-wizard';
import { NewInvoiceRequestModal } from '../components/modals/new-invoice-request-modal';
import { NewServiceJobModal } from '../components/modals/new-service-job.modal';
import { LeaveCalendarEventModal } from '../components/modals/leave-calendar-event-modal/leave-calendar-event.modal';
import { UploadMaintenanceChecksModalWizard } from '../components/modals/upload-maintenance-checks-modal/upload-maintenance-checks-modal-wizard';
import { RejectInvoiceRequestModal } from '../components/modals/reject-invoice-request.modal';
import { PushToQuickbooksModal } from '../components/modals/push-to-quickbooks.modal';
import { NewMyobPurchaseOrderModal } from '../components/modals/new-myob-purchase-order.modal';
import { NewCommissionPaymentBatchModal } from '../components/modals/new-commission-payment-batch.modal';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ModalTypeComponentMap: {[K in A_MODAL_TYPE]: React.FC<any>} = {
  [MODAL_TYPE.ACTIVATE_PROJECT]: ActivateProjectModalWizard,
  [MODAL_TYPE.CONFIRM]: ConfirmModal,
  [MODAL_TYPE.FIND_MICROSOFT_AD_USER_ID]: FindMicrosoftADUserIdModal,
  [MODAL_TYPE.NEW_ALLOWANCE]: NewAllowanceModal,
  [MODAL_TYPE.NEW_COMPANY]: NewCompanyModal,
  [MODAL_TYPE.NEW_COMPANY_LOCATION]: NewCompanyLocationModal,
  [MODAL_TYPE.NEW_COMPANY_SPACE]: NewCompanySpaceModal,
  [MODAL_TYPE.NEW_COMPANY_SPACE_TYPE]: NewCompanySpaceTypeModal,
  [MODAL_TYPE.NEW_CONTACT]: NewContactModal,
  [MODAL_TYPE.NEW_EXPENSE]: NewExpenseModal,
  [MODAL_TYPE.NEW_LEAVE_APPLICATION]: NewLeaveApplicationModal,
  [MODAL_TYPE.NEW_PROJECT_LEAD]: NewProjectLeadModal,
  [MODAL_TYPE.NEW_PROJECT_PROPOSAL]: NewProjectProposalModal,
  [MODAL_TYPE.NEW_INTERNAL_PROJECT]: NewInternalProjectModal,
  [MODAL_TYPE.NEW_INTERNAL_PROJECT_TASK]: NewInternalProjectTaskModal,
  [MODAL_TYPE.NEW_SPRINT]: NewSprintModal,
  [MODAL_TYPE.NEW_USER]: NewUserModal,
  [MODAL_TYPE.NEW_TASK_TEMPLATE]: NewTaskTemplateModal,
  [MODAL_TYPE.NEW_PLATFORM]: NewPlatformModal,
  [MODAL_TYPE.NEW_TEAM_PROCESS]: NewTeamProcessModal,
  [MODAL_TYPE.NEW_TEAM]: NewTeamModal,
  [MODAL_TYPE.NEW_CONTRACT]: NewContractModal,
  [MODAL_TYPE.NEW_CONTRACT_TYPE]: NewContractTypeModal,
  [MODAL_TYPE.NEW_CONTRACT_SUBTYPE]: NewContractSubtypeModal,
  [MODAL_TYPE.NEW_SERVICE_JOB]: NewServiceJobModal,
  [MODAL_TYPE.NEW_INVOICE_REQUEST]: NewInvoiceRequestModal,
  [MODAL_TYPE.RENEW_CONTRACT]: RenewContractModalWizard,
  [MODAL_TYPE.PASTE_DATA_FROM_CLIPBOARD_MODAL]: PasteDataFromClipboardModal,
  [MODAL_TYPE.PROJECT_SUMMARY_DASHBOARD_SETTINGS]: ProjectSummaryDashboardSettingsModal,
  [MODAL_TYPE.SALES_FORECAST_DASHBOARD_SETTINGS]: SalesForecastDashboardSettingsModal,
  [MODAL_TYPE.UPDATE_FILE]: UpdateFileModal,
  [MODAL_TYPE.LEAVE_CALENDAR_EVENT_MODAL]: LeaveCalendarEventModal,
  [MODAL_TYPE.UPLOAD_MAINTENANCE_CHECKS]: UploadMaintenanceChecksModalWizard,
  [MODAL_TYPE.REJECT_INVOICE_REQUEST]: RejectInvoiceRequestModal,
  [MODAL_TYPE.PUSH_TO_QUICKBOOKS]: PushToQuickbooksModal,
  [MODAL_TYPE.NEW_MYOB_PURCHASE_ORDER]: NewMyobPurchaseOrderModal,
  [MODAL_TYPE.NEW_COMMISSION_PAYMENT_BATCH]: NewCommissionPaymentBatchModal,
};
