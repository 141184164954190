import React, { createRef, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import ReactResizeDetector from 'react-resize-detector';
import {
  Nav, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';

import { CurrentUserContext } from '../providers/current-user-provider';

import { ITabDefinition } from '../../types/tabs-with-more/tab-definition.interface';
import { APIRecord } from '../../types/api-record.interface';

import { ReactPortal } from '../react-portal/react-portal';
import { ITabWithRef, TabWithRef } from './tab-with-ref';
import { usePreviousValue } from '../../react-hooks/use-previous-value.hook';
import { TabBadgeCounts } from './tab-badge-counts';

const TAB_COLLAPSE_DEBOUNCE = 100;

type InternalTab = ITabWithRef & {
  isMore: boolean,
  permissionGranted: boolean,
}

export type TabsWithMoreProps = {
  tabs: ITabDefinition[],
  activeTabKey: string,
  rowData: APIRecord,
  changeTab: (tabName: string) => void,
}

/**
 * Renders tabs, then get their widths and figures out how many to collapse into a
 * 'more' menu.
 *
 * Responds to both a window resize and a parent width resize listener (if available)
 */
export const TabsWithMore: React.FC<TabsWithMoreProps> = (props) => {
  const {
    tabs,
    activeTabKey,
    rowData,
    changeTab,
  } = props;

  const { userHasPermissions } = useContext(CurrentUserContext);

  const [internalTabs, setInternalTabs] = useState<InternalTab[]>(tabs.map((tab) => ({
    ...tab,
    isMore: true,
    permissionGranted: false,
    ref: createRef<HTMLLIElement>(),
  })));

  const [moreDropdownOpen, setMoreDropdownOpen] = useState<boolean>(false);

  const oldActiveTabKey = usePreviousValue(activeTabKey);

  // keep a reference to the "more" button
  const moreButtonRef = React.useRef<null | HTMLLIElement>(null);

  // keep a reference to the container
  const containerRef = React.useRef<null | HTMLDivElement>(null);

  // Used to debounce the calculation of the collapse function
  const collapseDebounceTimeoutRef = React.useRef<undefined | ReturnType<typeof setTimeout>>(undefined);

  // Simple evaluation to determine if the "more" tab is included in the internalTabs array
  const hasMoreTab = useMemo(() => internalTabs.findIndex((tab) => tab.isMore) > -1, [internalTabs]);

  // Add all of the tabs the user is allowed to see to the visible tabs list
  const visibleTabs = useMemo(() => internalTabs.map((tab) => {
    const tabVisible = (typeof tab.isVisible === 'function') ? tab.isVisible(rowData) : tab.isVisible !== false;
    if (tab.permissionGranted && !tab.isMore && tabVisible) {
      return (
        <TabWithRef
          key={`navtab-${tab.name}`}
          activeTabKey={activeTabKey}
          tab={tab}
          changeTab={changeTab}
          rowData={rowData}
        />
      );
    }
    return null;
  }).filter((tab) => tab !== null), [activeTabKey, changeTab, internalTabs, rowData]);


  // Add the tabs we can't fit onto the navbar to a list for rendering in a dropdown
  const dropdownItems = useMemo(() => internalTabs.map((tab) => ((tab.permissionGranted && tab.isMore) ? (
    <DropdownItem
      key={`navtab-${tab.name}`}
      className={classnames({
        active: activeTabKey === tab.name,
      })}
      onClick={(e) => {
        e.preventDefault();
        changeTab(tab.name);
        setMoreDropdownOpen(false);
      }}
      data-prevent-close-dropdown
    >
      {tab.title}
      {rowData && tab.badgeCountFields && (
        <TabBadgeCounts
          rowData={rowData}
          badgeCountFields={tab.badgeCountFields}
        />
      )}
    </DropdownItem>
  ) : null)).filter((tab) => tab !== null), [activeTabKey, changeTab, internalTabs, rowData]);


  // Add all of the tabs the user is not allowed to see or don't fit to the offScreenTabs list
  // We still need to render these into an invisible container so we can use their offsetWidths in calculation
  const offScreenTabs = useMemo(() => internalTabs.map((tab) => ((!tab.permissionGranted || tab.isMore) ? (
    <TabWithRef
      key={`navtab-${tab.name}`}
      activeTabKey={activeTabKey}
      tab={tab}
      rowData={rowData}
    />
  ) : null)).filter((tab) => tab !== null), [activeTabKey, internalTabs, rowData]);


  // Is the active tab visible or is it buried in the dropdown?
  const activeTabVisible = useMemo(() => internalTabs.findIndex((tab) => (tab.permissionGranted && !tab.isMore && tab.name === activeTabKey)) > -1, [activeTabKey, internalTabs]);

  // Calculate the label for the more button based on whether the active tab is inside the more items
  const moreButtonTab = useMemo(() => (
    !activeTabVisible ? internalTabs.find((tab) => tab.name === activeTabKey) : null
  ), [activeTabKey, activeTabVisible, internalTabs]);
  const moreButtonLabel = useMemo(() => (
    moreButtonTab ? moreButtonTab?.title ?? 'more' : 'more'
  ), [moreButtonTab]);

  /**
   * @description
   * Collapse Tab List
   * Checks widths of all tabs, and updates state
   */
  const collapse = () => setInternalTabs((oldInternalTabs) => {
    const containerWidth = (containerRef.current ? containerRef.current.clientWidth : 0);

    // Start the width of the "more" button
    const moreButtonWidth = moreButtonRef.current ? moreButtonRef.current.offsetWidth : 0;
    let moreRequired = false;

    let newInternalTabs: InternalTab[] = [];

    // First sweep checks the widths. Second sweep is only required if the more button was made visible at some point
    for (let sweep = 0; sweep < 2; sweep += 1) {
      // The combined width of all buttons
      let combinedWidth = moreRequired ? moreButtonWidth : 0;

      // eslint-disable-next-line no-loop-func
      newInternalTabs = oldInternalTabs.map((tab) => {
        // Only those tabs that have passed a security permission check are considered
        // @ts-ignore
        const tabWidth = (tab.permissionGranted && tab.ref?.current) ? (tab.ref.current?.offsetWidth ?? 0) : 0;
        combinedWidth += tabWidth;
        moreRequired = combinedWidth > containerWidth;

        return {
          ...tab,
          isMore: moreRequired,
        };
      });

      // Don't bother with the second sweep if the more button is not required
      if (!moreRequired) break;
    }

    return newInternalTabs;
  });


  /*
   * Debounce the collapse of the tab list
   */
  const debounceCollapse = useCallback(() => {
    if (collapseDebounceTimeoutRef.current) {
      clearTimeout(collapseDebounceTimeoutRef.current);
    }
    collapseDebounceTimeoutRef.current = setTimeout(() => {
      collapseDebounceTimeoutRef.current = undefined;
      collapse();
    }, TAB_COLLAPSE_DEBOUNCE);
  }, []);


  /**
   * Callback for toggling the state of dropdown
   */
  const handleToggleDropDown = useCallback((e: React.MouseEvent<HTMLLIElement>) => {
    if (!e || !e.currentTarget) return;

    let preventToggle = false;
    if (['a', 'li', 'button'].includes(e.currentTarget.tagName?.toLowerCase() ?? '')) {
      preventToggle = !!e.currentTarget.getAttribute('data-prevent-close-dropdown');
    }

    // DON'T "close" the dropdown if the target was one of the options being clicked.
    // We'll toggle the menu closed as part of the onClick() handler
    // This is a result of the DropDownMenu being rendered inside a portal and the event chain firing the toggle before click
    if (!preventToggle) {
      setMoreDropdownOpen((oldMoreDropdownOpen) => !oldMoreDropdownOpen);
    }
  }, []);


  /**
   * Update the tab permissions whenever contributing attributes to the visibility of the tabs changes
   *
   * This should also
   */
  useEffect(() => {
    // Iterate over the tabs and determine if the user has security to view each tab
    setInternalTabs((oldInternalTabs) => oldInternalTabs.map((tab) => ({
      ...tab,
      permissionGranted: tab.permissions ? userHasPermissions(tab.permissions) : true,
    })));
  }, [userHasPermissions]);


  /**
   * When the active tab key changes recalculate the collapsed tabs
   */
  useEffect(() => {
    if (oldActiveTabKey && (oldActiveTabKey !== activeTabKey)) {
      debounceCollapse();
    }
  }, [activeTabKey, debounceCollapse, oldActiveTabKey]);


  /**
   * onMount / onUnMount
   */
  useEffect(() => {
    // Make sure the initial collapse calculations are performed on the first render
    collapse();

    // Make sure the debounced collapse timeout doesn't get called after component unmount
    return () => {
      if (collapseDebounceTimeoutRef.current) {
        clearTimeout(collapseDebounceTimeoutRef.current);
        collapseDebounceTimeoutRef.current = undefined;
      }
    };
  }, []);


  // Prepare the "more" button for render
  const moreButton = (
    <li className={classnames('nav-item more', { active: !activeTabVisible })} ref={moreButtonRef}>
      <Dropdown isOpen={moreDropdownOpen} toggle={handleToggleDropDown}>
        <DropdownToggle nav caret className={classnames({ active: !activeTabVisible })}>
          <span>{moreButtonLabel}</span>
          {rowData && moreButtonTab && moreButtonTab.badgeCountFields && (
            <TabBadgeCounts
              rowData={rowData}
              badgeCountFields={moreButtonTab.badgeCountFields}
            />
          )}
        </DropdownToggle>
        <ReactPortal>
          <DropdownMenu right modifiers={{ preventOverflow: { boundariesElement: 'window' } }}>
            {dropdownItems}
          </DropdownMenu>
        </ReactPortal>
      </Dropdown>
    </li>
  );

  return (
    <ReactResizeDetector
      handleWidth
      onResize={debounceCollapse}
    >
      <div className="tabs-with-more" ref={containerRef}>
        <Nav
          className="customtab offscreen-tab-container"
          tabs
        >
          {offScreenTabs}
          {!hasMoreTab && moreButton}
        </Nav>
        <Nav
          className="customtab"
          tabs
          // onSelect={(tabId) => changeTab(tabId)}
        >
          {visibleTabs}
          {hasMoreTab && moreButton}
        </Nav>
      </div>
    </ReactResizeDetector>
  );
};
