import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Alert } from 'reactstrap';
import List from './list';
import Icon from './icon';
import { THEME_COLOR } from '../../constants/theme-color.const';

// Rejected exclaims: 'F&$k!', 'Gosh darn it!', 'Geez...', 'Awww sheeeeit!'
//   'Oh dear.', 'Bum.', 'Christ!', 'Ass-piss!'
const exclaim = 'Oops!';

/**
 * Maps of strings that CONTAINED in an formMessage will mark with the key
 * @returns {object} Object of arrays containing possible matches for formMessages
 */
export const serverFormMessages = {
  invalid: ['The given data was invalid', 'Unable to send reset email'],
  timeout: ['Failed to fetch'],
  credentials: ['The user credentials were incorrect'],
  malformed: ['The request is missing a required parameter'],
  authorized: ['is not authorized to'],
  token: ['Client authentication failed'],
  leaveBlocks: ['as there are no valid leave blocks'],
};

/**
 * Maps server messages to friendly versions for the end user.
 * @returns {object} Object of string keys with JSX object values
 */
export const serverMessageReplacements = {
  invalid: <span>{`${exclaim} Please fix the highlighted issues and try again`}</span>,
  timeout: <span>The CiPortal server cannot be reached. Please check your internet connection and try again</span>,
  credentials: <span>{`${exclaim} Wrong username/password`}</span>,
  malformed: <span>{`${exclaim} Please check you have entered both your username and password.`}</span>,
  authorized: <span>{`${exclaim} You are not authorised to do that.`}</span>,
  token: <span>{`${exclaim} Authentication token out of date. Hard-refresh the page and try again.`}</span>,
  leaveBlocks: <span>Please add at least 1 Date range before submitting this application.</span>,
};


/**
 * Replace Laravel default errors with something nicer
 * <FriendlyFormMessage />
 * @param {*} props
 */
const FriendlyFormMessage = (props) => {
  const {
    formMessage,
    alertColor,
    hasErrors,
    hasSuccess,
    errors,
    showList,
    inline,
    showIcon,
    isOpen,
    toggle,
    useSimpleDefault,
  } = props;

  // If no formMessage, don't even, like... you know... just leave...
  if (!formMessage) return null;

  // @deprecated props, can remove this
  let calculatedAlertColor = 'info';
  if (!alertColor) {
    if (hasSuccess && !hasErrors) {
      calculatedAlertColor = 'success';
    }
    else if (hasErrors) {
      calculatedAlertColor = 'danger';
    }
  }
  else {
    calculatedAlertColor = alertColor;
  }
  // Set default If error message
  const ohNoError = useSimpleDefault ? (
    <span>
      {formMessage && formMessage.toString().split('\n').map((line, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`line-num-${index}`}>{line}</div>
      ))}
    </span>
  ) : (
    <span>
      <span>Oh no! Something bad happened on our end, any data submitted was not saved.</span>
      <br />
      <span>Please </span>
      <a href="mailto:support@thecigroup.com.au">let someone know</a>
      <span>.</span>
      <br />
      <br />
      <strong>Info:</strong>
      <br />
      <code>{formMessage}</code>
    </span>
  );

  // Create a nice list of all the errors in all fields
  const fieldErrors = [];
  if (showList) {
    Object.keys(errors).forEach((k) => {
      const e = errors[k];
      // could also be an array not a string :C
      /**
       * @todo replacing the string ` id field ` is a bit of a hack
       * needs to be reviewed in conversation with API server
       */
      if (e instanceof Object) { // TODO: should this be "e instanceof Array"?
        if (e.length) {
          for (let i = 0; i < e.length; i += 1) {
            fieldErrors.push(e[i].replace(' id field ', ' '));
          }
        }
      }
      else if (typeof e === 'string') {
        fieldErrors.push(e.replace(' id field ', ' '));
      }
    });
  }

  // Remap formMessage into appropriate friendlyMessage
  let friendlyMessage = null;
  Object.keys(serverFormMessages).forEach((k) => {
    serverFormMessages[k].forEach((check) => {
      if (typeof formMessage === 'string' && formMessage.indexOf(check) !== -1) {
        friendlyMessage = serverMessageReplacements[k];
      }
    });
  });
  if (friendlyMessage === null) {
    friendlyMessage = formMessage;
    if (alertColor === 'danger') {
      friendlyMessage = ohNoError;
    }
  }

  // <FriendlyFormMessage />
  return (
    <Alert
      color={calculatedAlertColor}
      className={classnames({
        'inline-error': inline,
      })}
      isOpen={isOpen}
      toggle={toggle}
    >
      {friendlyMessage || 'No Message Provided!'}
      {showIcon && <Icon i="exclamation-triangle" className="text-danger" fw spaceRight />}
      {showList && <List items={fieldErrors} />}
    </Alert>
  );
};

FriendlyFormMessage.defaultProps = {
  formMessage: null,
  alertColor: null,
  isOpen: true,
  // 'undefined' disables it
  toggle: undefined,
  // @deprecated prop
  hasErrors: false,
  // @deprecated prop
  hasSuccess: false,
  inline: false,
  errors: {},
  showList: false,
  showIcon: false,
  useSimpleDefault: false,
};

FriendlyFormMessage.propTypes = {
  formMessage: PropTypes.string,
  alertColor: PropTypes.oneOf(Object.values(THEME_COLOR)),
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  // @deprecated prop
  hasErrors: PropTypes.bool,
  // @deprecated prop
  hasSuccess: PropTypes.bool,
  inline: PropTypes.bool,
  errors: PropTypes.shape({}),
  showList: PropTypes.bool,
  showIcon: PropTypes.bool,
  useSimpleDefault: PropTypes.bool,
};

export default FriendlyFormMessage;
