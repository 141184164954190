import React from 'react';
import classNames from 'classnames';
import { AN_ICON } from '../../constants/icon.const';
import Icon from '../layout-helpers/icon';

export type PortalMultiLevelDropDownItemProps = {
  className?: string,
  disabled?: boolean,
  icon?: AN_ICON,
  label?: string,
  onClick?: (e: React.MouseEvent<HTMLElement>) => void,
};

export const PortalMultiLevelDropDownItem: React.FC<PortalMultiLevelDropDownItemProps> = (props) => {
  const {
    className,
    children,
    disabled = false,
    icon,
    label = 'item',
    onClick,
  } = props;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <li
      className={classNames('pmldd-item', className, {
        disabled,
      })}
      role="menuitem"
      onClick={onClick}
    >
      {/* Render the label and icon if no custom children provided */}
      {!children && (
        <>
          {icon && (
            <Icon i={icon} />
          )}
          <span>{label}</span>
        </>
      )}

      {/* Custom children */}
      {children}
    </li>
  );
};
