import projectTableInitialSettings from './project-table';

/**
 * Table Definition for Project Table
 */
const projectDrilldownTableInitialSettings = {
  ...projectTableInitialSettings,
  availableFlags: [],
  viewKey: undefined,
  views: [],
  // viewKey: 'all',
  // views: [
  //   {
  //     key: 'all',
  //     title: 'All Leads, Proposals & Projects',
  //     flagTitles: [],
  //     filters: [],
  //     flags: [],
  //   },
  // ],
};

export default projectDrilldownTableInitialSettings;
