import { DELETE_CONFIRMATION_TYPE } from '../constants/delete-confirmation-type.const';
import { ICollectionWidgetDefinition } from '../types/collection.widget-definition';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { FORM_RENDERER_TYPE } from '../constants/form-renderer-type.const';

export const CommissionPaymentSummaryWidgetDefinition: ICollectionWidgetDefinition = {
  formRendererType: FORM_RENDERER_TYPE.TABLE_ROW,
  itemCaption: 'Salesperson',
  isReadOnly: true,
  formDeleteConfirmationType: DELETE_CONFIRMATION_TYPE.REMOVE,
  collectionItemPrimaryKeyFieldName: 'sales_owner',
  fields: [
    {
      name: 'sales_owner',
      title: 'Salesperson',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user',
        key: 'name',
        format: COLUMN_FORMAT.TEXT,
      },
    }, {
      name: 'project_count',
      title: 'Projects',
      format: COLUMN_FORMAT.NUMBER,
    }, {
      name: 'amount',
      title: '$ Payable',
      format: COLUMN_FORMAT.CURRENCY,
      showTotals: true,
    },
  ],
};
