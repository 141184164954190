import classNames from 'classnames';
import React, { useCallback, useContext } from 'react';
import { FormatOptionLabelMeta } from 'react-select';

import { CurrentUserContext } from '../providers/current-user-provider';
import { ModalContext } from '../modals/modal-context';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';
import { ICompanyLocationRecord } from '../../types/company/company-location.record.interface';
import { NewRecordModalResult } from '../../types/modal/modal-result';

import AsyncSelect from './async-select';

import Icon from '../layout-helpers/icon';

import { A_COMPANY_LOCATION_TYPE, CompanyLocationTypeIconMap } from '../../constants/company-location-type.const';
import { MODAL_TYPE } from '../../constants/modal-type.const';
import { PERMISSION } from '../../constants/permissions.const';

export type CompanyLocationPickerProps = AsyncSelectComponentProps;

export const CompanyLocationPicker:React.FC<CompanyLocationPickerProps> = (props) => {
  const {
    className,
    name,
    formData = {},
    value,
    parentData = {},
    formSaveField = null,
    isCreatable = true,
    searchRoute,

    renderOption,
    getNewItemData,
    onChange,
  } = props;

  const { showModal } = useContext(ModalContext);
  const { userHasPermissions } = useContext(CurrentUserContext);

  /**
   * Show a modal to create a new company location
   */
  const handleCreateOption = useCallback((inputValue: string) => {
    const initialData = {
      name: inputValue,
      ...(getNewItemData ? getNewItemData(inputValue, formData, parentData) : {}),
    };

    showModal<NewRecordModalResult<ICompanyLocationRecord>>(MODAL_TYPE.NEW_COMPANY_LOCATION, {
      initialData,
      onModalComplete: ({ success, newRecord: newCompanyLocation }) => {
        if (success && onChange && newCompanyLocation) {
          onChange({
            fieldName: formSaveField || `${name}_id`,
            newValue: newCompanyLocation.id,
            objectFieldName: name,
            objectFieldNewValue: newCompanyLocation,
          });
        }
      },
    });
  }, [formData, formSaveField, getNewItemData, name, onChange, parentData, showModal]);


  /**
   * Renders an option using the icon and the location path
   */
  const handleRenderOption = useCallback((
    option: ICompanyLocationRecord | null,
    labelMeta: FormatOptionLabelMeta<{label: string, value: string}, false>,
  ): string | React.ReactNode => {
    if (renderOption) {
      return renderOption(option, labelMeta as unknown as Record<string, unknown>, value ?? null);
    }

    if (!option) return null;

    // Return the label when the AsyncSelect is rendering a "Create new XXXX" option
    if (option.__isNew__) { return (
      <>
        <Icon i="plus-circle" />
        <span>{option.label as string}</span>
      </>
    ); }

    return (
      <span>
        <Icon i={CompanyLocationTypeIconMap[option.type_id as A_COMPANY_LOCATION_TYPE]} />
        <span>{option.path}</span>
      </span>
    );
  }, [renderOption, value]);


  // Render
  return (
    <AsyncSelect
      {...props}
      className={classNames('company-location-picker', className)}
      // Make sure the current user has permissions to create a new company location before allowing them to create a company
      // TODO: This may not be 100% accurate. Make sure there is more testing on permissions to add locations to the selected client
      isCreatable={!!isCreatable && userHasPermissions([PERMISSION.COMPANY_UPDATE])}
      loadAndKeepAll
      searchRoute={searchRoute ?? `/company/${parentData.client_id ?? null}/location`}
      appendQuery={[
        'sort[0][field]=path',
        'sort[0][direction]=asc',
      ].join('&')}
      onCreateOption={handleCreateOption}
      renderOption={handleRenderOption}
      labelKey="path"
    />
  );
};
