import React from 'react';

import { APIRecord } from '../types/api-record.interface';
import { FormRendererProps } from '../types/poly-form/form-renderer.props';

import { CompanyLocationFormRenderer } from '../components/poly-forms/form-renderers/company-location-form-renderer';
import { CompanySpaceFormRenderer } from '../components/poly-forms/form-renderers/company-space-form-renderer';
import { ContactsFormRenderer } from '../components/poly-forms/form-renderers/contacts-form-renderer';
import { FileFormRenderer } from '../components/poly-forms/form-renderers/file-form-renderer';
import { NotesFormRenderer } from '../components/poly-forms/form-renderers/notes-form-renderer';
import { NotificationFormRenderer } from '../components/poly-forms/form-renderers/notification-form-renderer';
import { TrackingFormRenderer } from '../components/poly-forms/form-renderers/tracking-form-renderer';
import { VerticalFormRenderer } from '../components/poly-forms/form-renderers/vertical-form-renderer';
import { TableRowFormRenderer } from '../components/poly-forms/form-renderers/table-row-form-renderer';

import ProjectCardFormRenderer from '../components/poly-forms/form-renderers/project-card-form-renderer';
import SwimlaneCardFormRenderer from '../components/poly-forms/form-renderers/swimlane-card-form-renderer';

import { A_FORM_RENDERER_TYPE, FORM_RENDERER_TYPE } from './form-renderer-type.const';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormRendererTypeComponentMap: {[K in A_FORM_RENDERER_TYPE]: React.FC<FormRendererProps<APIRecord>> | React.FC<any>} = {
  [FORM_RENDERER_TYPE.VERTICAL]: VerticalFormRenderer,
  [FORM_RENDERER_TYPE.NOTE]: NotesFormRenderer,
  [FORM_RENDERER_TYPE.EVENT]: TrackingFormRenderer,
  [FORM_RENDERER_TYPE.NOTIFICATION]: NotificationFormRenderer,
  [FORM_RENDERER_TYPE.TABLE_ROW]: TableRowFormRenderer,
  [FORM_RENDERER_TYPE.CONTACT]: ContactsFormRenderer,
  [FORM_RENDERER_TYPE.COMPANY_LOCATION]: CompanyLocationFormRenderer,
  [FORM_RENDERER_TYPE.COMPANY_SPACE]: CompanySpaceFormRenderer,
  [FORM_RENDERER_TYPE.FILE]: FileFormRenderer,

  [FORM_RENDERER_TYPE.PROJECT_CARD]: ProjectCardFormRenderer,
  [FORM_RENDERER_TYPE.SWIMLANE_CARD]: SwimlaneCardFormRenderer,
};
