import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import SalesForecastTableHeaderGroup from './sales-forecast-table-header-group';
import Icon from '../../layout-helpers/icon';

const SalesForecastTableHeaderMonthCell = (props) => {
  const {
    monthGroup,
    monthGroups,
    span,

    onAddMonthStart,
    onAddMonthEnd,
    onRemoveMonthStart,
    onRemoveMonthEnd,
  } = props;

  const isFirstMonth = (monthGroups.findIndex((mg) => mg.key === monthGroup.key) === 0);
  const isLastMonth = (monthGroups.findIndex((mg) => mg.key === monthGroup.key) === (monthGroups.length - 1));
  const multipleMonths = monthGroups.length > 1;

  return (
    <SalesForecastTableHeaderGroup
      key={monthGroup.key}
      className="month"
      span={span}
    >
      <div className="month-wrapper">
        {/* First Month "Add a previous month" button */}
        {isFirstMonth && (
          <Button onClick={onAddMonthStart} title="Add a month prior to this month"><Icon i="plus" /></Button>
        )}

        {/* Last Month "Remove this month" button */}
        {multipleMonths && isLastMonth && (
          <Button onClick={onRemoveMonthEnd} title="Remove this month"><Icon i="minus" /></Button>
        )}

        <div className="label-wrapper">
          {monthGroup.label}
        </div>

        {/* First Month "Remove this month" button */}
        {multipleMonths && isFirstMonth && (
          <Button onClick={onRemoveMonthStart} title="Remove this month"><Icon i="minus" /></Button>
        )}

        {/* Last Month "Add another month" button */}
        {isLastMonth && (
          <Button onClick={onAddMonthEnd} title="Add a month after this month"><Icon i="plus" /></Button>
        )}
      </div>
    </SalesForecastTableHeaderGroup>
  );
};

SalesForecastTableHeaderMonthCell.propTypes = {
  monthGroups: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  monthGroup: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  span: PropTypes.number.isRequired,
  onAddMonthStart: PropTypes.func.isRequired,
  onAddMonthEnd: PropTypes.func.isRequired,
  onRemoveMonthStart: PropTypes.func.isRequired,
  onRemoveMonthEnd: PropTypes.func.isRequired,
};

export default SalesForecastTableHeaderMonthCell;
