import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Card } from 'reactstrap';
import moment from 'moment';
import SalesForecastPageHeader from './sales-forecast-page-header';
import * as urlStateManager from '../../url-state-manager/url-state-manager';
import {
  washSalesForecastUrlState,
  transformPmAndStateIdsForUrlState,
  transformStateIdsForUrlState,
  urlStateToStartAndEndMoments,
  getMonthsBetween,
  defaultSalesForecastMonthlyFields,
  defaultSalesForecastSummaryFields,
  defaultStatusOptions,
  REPORT_BASE_API_PATH,
  DATA_TYPE,
  incMonth,
  getDateRangeString,
  defaultDateRange,
  getGeographicalStateFilterString,
  getVisibleStatusIdsFilterString,
  getSalespersonFilterString,
  baseFilterString,
  getLikelihoodFilterString,
} from './sales-forecast-helpers';
import { MODAL_PROVIDER_PROP_TYPES } from '../../../prop-types/modal-provider-prop-types';
import SalesForecastTable from './sales-forecast-table';
import { shallowAreObjectsDifferent } from '../../../helpers/shallow-are-objects-different';
import { PUSH_OR_REPLACE } from '../../../utils/constants';
import { storeSalesForecastDashboardSettingsToLocalStorage, loadSalesForecastDashboardSettingsFromLocalStorage } from '../../../utils/localStorage';
import { connectToAPIProvider } from '../../providers/api-provider';
import API_PROVIDER_PROP_TYPES from '../../../prop-types/api-provider-prop-types';
import { connectToModalProvider } from '../../modals/modal-context';
import { MODAL_TYPE } from '../../../constants/modal-type.const';
import { apiAborter } from '../../../helpers/api-aborter.helper';
import { FullScreenWrapper } from '../../layout-helpers/full-screen-wrapper';
import { ProjectCacheStatusWidget } from '../project-cache-status-widget';


/**
 * @class SalesForecastDashboard
 *
 * @description
 * Displays the projects by state and sales person
 */
class SalesForecastDashboard extends React.Component {
  /**
   * @constructor
   */
  constructor(props) {
    super(props);

    const { urlState } = props;
    const {
      startMoment,
      endMoment,
    } = urlStateToStartAndEndMoments(urlState);

    // Load the default project summary settings from local storage
    const storedSalesForecastSettings = loadSalesForecastDashboardSettingsFromLocalStorage({
      salesForecastSummaryFields: defaultSalesForecastSummaryFields,
      salesForecastMonthlyFields: defaultSalesForecastMonthlyFields,
      salesForecastDisplayStatuses: defaultStatusOptions,
      showProjectNames: false,
      hideEmptyStates: true,
      hideZeroValues: true,
      lowLikelihoodFilter: 0,
      highLikelihoodFilter: 10,
    });

    this.state = {
      summaryFields: storedSalesForecastSettings.salesForecastSummaryFields,
      monthlyFields: storedSalesForecastSettings.salesForecastMonthlyFields,
      displayStatuses: storedSalesForecastSettings.salesForecastDisplayStatuses,
      hideEmptyStates: storedSalesForecastSettings.hideEmptyStates,
      hideZeroValues: storedSalesForecastSettings.hideZeroValues,
      showProjectNames: storedSalesForecastSettings.showProjectNames,
      lowLikelihoodFilter: storedSalesForecastSettings.lowLikelihoodFilter,
      highLikelihoodFilter: storedSalesForecastSettings.highLikelihoodFilter,
      monthGroups: getMonthsBetween(startMoment, endMoment),
      isFullScreen: false,
      showStatusTable: false,
      hasCacheError: false,
      splitHandleForceUpdateId: 0,
      geographicalStateData: {
        isLoading: true,
        error: null,
        summaryData: {
          data: [],
          totals: null,
        },
        monthlyData: {
          data: [],
          totals: null,
        },
      },
      salespersonData: [],
      projectData: [],
    };

    /**
     * @description
     * Keeps all of the fetch requests in a searchable array for targeted aborting
     *
     * @type {[{
     *  dataType: DATA_TYPE,
     *  id: number | string | null,
     *  abortController: AbortController
     * }]}
     */
    this.fetchRequests = [];
  }


  /**
   * @inheritdoc
   */
  componentDidMount = () => {
    // Load the initial data
    setTimeout(this.fetchGeographicalStatesData, 0);
    setTimeout(this.fetchSalespersonData, 0);
    setTimeout(this.fetchProjectData, 0);
  }


  /**
   * @inheritdoc
   */
  componentDidUpdate(prevProps, prevState) {
    const {
      displayStatuses: oldDisplayStatuses,
      lowLikelihoodFilter: oldLowLikelihoodFilter,
      highLikelihoodFilter: oldHighLikelihoodFilter,
    } = prevState;
    const {
      displayStatuses: newDisplayStatuses,
      lowLikelihoodFilter: newLowLikelihoodFilter,
      highLikelihoodFilter: newHighLikelihoodFilter,
    } = this.state;
    const { urlState: newUrlState } = this.props;
    const { urlState: oldUrlState } = prevProps;

    const {
      startYear: oldStartYear,
      startMonth: oldStartMonth,
      endMonth: oldEndMonth,
      endYear: oldEndYear,
      stateIds: oldStateIds,
      pmAndStateIds: oldPmAndStateIds,
    } = oldUrlState;

    const {
      startYear: newStartYear,
      startMonth: newStartMonth,
      endMonth: newEndMonth,
      endYear: newEndYear,
      stateIds: newStateIds,
      pmAndStateIds: newPmAndStateIds,
    } = newUrlState;

    // date has changed. This requires all data be re-loaded, regardless of any changes to the toggled States and Salespersons
    const dateChange = oldStartYear !== newStartYear || oldStartMonth !== newStartMonth || oldEndMonth !== newEndMonth || oldEndYear !== newEndYear;
    const statusListChange = oldDisplayStatuses !== newDisplayStatuses;
    const likelihoodChange = oldLowLikelihoodFilter !== newLowLikelihoodFilter || oldHighLikelihoodFilter !== newHighLikelihoodFilter;

    if (dateChange || statusListChange || likelihoodChange) {
      const {
        startMoment,
        endMoment,
      } = urlStateToStartAndEndMoments(newUrlState);
      const { monthGroups } = this.state;
      const newMonthGroups = getMonthsBetween(startMoment, endMoment);

      // Bump the setState out of the component did update
      setTimeout(() => this.setState({
        monthGroups: newMonthGroups || monthGroups,
      }, () => {
        // Each of the fetch methods performs a setState. Make sure they don't suffer
        // from a race condition by pushing them all onto the back of the stack
        setTimeout(this.fetchGeographicalStatesData, 0);
        setTimeout(this.fetchSalespersonData, 0);
        setTimeout(this.fetchProjectData, 0);
      }), 0);
    }

    // If the month's haven't changed, fire off the incremental loading for project manager and project data (where applicable)
    else {
      // Removed Salesperson States are those which exist in the oldStateIds but not in the newStateIds
      const removedStateIds = oldStateIds.filter((stateId) => !newStateIds.includes(stateId));

      // Removed Projects Salesperson and StateIds are those where the PM and stateID combo exist in oldPmAndStateIds but not in the newPmAndStateIds
      const removedPmAndStateIds = oldPmAndStateIds.filter((pmAndState) => !newPmAndStateIds.find((newPMAndState) => (
        pmAndState.stateId === newPMAndState.stateId &&
          pmAndState.pmId === newPMAndState.pmId
      )));

      // Added Salesperson States are those which exist in he newStateIds but not on the oldStateIds
      const addedStateIds = newStateIds.filter((stateId) => !oldStateIds.includes(stateId));

      // Aded Projects Salesperson and StateIds are those where the PM and StateID combo exist in the newPmAndStateIds but not in the oldPmAndStateIds
      const addedPmAndStateIds = newPmAndStateIds.filter((pmAndState) => !oldPmAndStateIds.find((oldPMAndState) => (
        oldPMAndState.stateId === pmAndState.stateId &&
          oldPMAndState.pmId === pmAndState.pmId
      )));

      // Purge the project managers and projects we no longer want to see
      if (removedStateIds.length) {
        setTimeout(() => this.purgeSalespersonData(removedStateIds), 0);
      }
      if (removedPmAndStateIds.length) {
        setTimeout(() => this.purgeProjectData(removedPmAndStateIds), 0);
      }

      // Load project manager data for the added state ids
      if (addedStateIds.length) {
        setTimeout(() => this.fetchSalespersonData(addedStateIds), 0);
      }

      // Load project data for the added project manager and state ids
      if (addedPmAndStateIds.length) {
        setTimeout(() => this.fetchProjectData(addedPmAndStateIds), 0);
      }
    }
  }


  /**
   * @inheritdoc
   */
  getSnapshotBeforeUpdate = (prevProps, prevState) => {
    const { splitHandleForceUpdateId, isFullScreen } = this.state;
    const { splitHandleForceUpdateId: oldSplitHandleForceUpdateId, isFullScreen: oldIsFullScreen } = prevState;
    // This simply makes sure the split handle updates whenever the table changes in some way
    if (
      (splitHandleForceUpdateId === oldSplitHandleForceUpdateId) &&
      // For some reason updating the split handle when the fullScreenMode is flipped causes a crash
      (isFullScreen === oldIsFullScreen)
    ) {
      setTimeout(this.forceUpdateSplitHandle, 0);
    }
    return null;
  }


  /**
   * @inheritdoc
   */
  componentWillUnmount = () => {
    // Kill off any outstanding abort requests
    this.fetchRequests.forEach((fetchRequest) => fetchRequest.abortController.abort());
  }


  /**
   * @description
   * Locate existing, ongoing fetch request(s). Typically used to abort an existing fetch.
   *
   * @param {DATA_TYPE} dataType
   * @param {string | number | null} [id=null]

   * @returns {{
   *  dataType: DATA_TYPE,
   *  id: number | string | null,
   *  abortController: AbortController
   * }[]}
   */
  findFetchRequests = (dataType, id = null) => this.fetchRequests.filter((fetchRequest) => (
    // The fetch request matches the data type
    (fetchRequest.dataType === dataType) &&

      // for a specific id or all fetch requests of this type
      (
        id === null ||
        (fetchRequest.id !== null && (
          (Array.isArray(id) && JSON.stringify(id) === JSON.stringify(fetchRequest.id)) ||
          ((typeof id === 'object' && !shallowAreObjectsDifferent(fetchRequest.id, id))) ||
          fetchRequest.id === id
        ))
      )
  ))


  /**
   * @description
   * Register a fetch request
   *
   * @param {DATA_TYPE} dataType
   * @param {string | number | null} [id=null]
   *
   * @returns {{
   *  dataType: DATA_TYPE,
   *  id: number | string | null,
   *  abortController: AbortController
   * }}
   */
  registerFetchRequest = (dataType, id = null) => {
    // Are there any existing matching fetch request?
    const existingFetchRequests = this.findFetchRequests(dataType, id);
    // OK, so this is awkward. There's already a matching fetch request.
    if (existingFetchRequests.length) {
      // Iterate over each matching fetch request and abort the fetch
      existingFetchRequests.forEach((fetchRequest) => {
        try {
          // Kill the existing call
          fetchRequest.abortController.abort();
        }
        catch (ex) {
          // sink
        }
        // Remove the registration
        this.unregisterFetchRequest(fetchRequest);
      });
    }

    const newFetchRequest = {
      dataType,
      id,
      abortController: apiAborter(),
    };

    this.fetchRequests.push(newFetchRequest);
    return newFetchRequest;
  };


  /**
   * @description
   * Clear an existing fetch request from the list of registered fetch requests
   *
   * @param {{
   *  dataType: DATA_TYPE,
   *  id: number,
   *  abortController: AbortController,
   * }} dataType
   * @param {number} id
   */
  unregisterFetchRequest = (fetchRequest) => {
    // Simply splice the fetch request out from our array of stored fetch requests
    // const index = this.fetchRequests.findIndex(fr => fr.dataType === fetchRequest.dataType && fr.id === fetchRequest.id);
    const index = this.fetchRequests.findIndex((fr) => fr === fetchRequest);
    if (index > -1) {
      this.fetchRequests.splice(index, 1);
    }
  }

  /**
   * @description
   * Build out the common filter string for all aspects of this dashboard, based on URL and this.state data.
   */
  getSfDashboardFilterStrings = () => {
    const { urlState } = this.props;
    const { displayStatuses, lowLikelihoodFilter, highLikelihoodFilter } = this.state;
    const dateRangeString = getDateRangeString(urlState);
    const statusFilterString = getVisibleStatusIdsFilterString(displayStatuses);
    const likelihoodFilterString = getLikelihoodFilterString(lowLikelihoodFilter, highLikelihoodFilter);
    return `${baseFilterString}${statusFilterString}&${dateRangeString}${likelihoodFilterString}`;
  }


  /**
   * @description
   * Fetch the geographical states summary and monthly data
   */
  fetchGeographicalStatesData = () => {
    const { geographicalStateData } = this.state;

    // Update the state to reflect the loading of the geographical state data
    this.setState({
      geographicalStateData: {
        ...geographicalStateData,
        error: null,
        isLoading: true,
      },
    }, () => {
      // Create a new fetch request
      const fetchRequest = this.registerFetchRequest(DATA_TYPE.GEOGRAPHICAL_STATE);
      const dashboardFilterString = this.getSfDashboardFilterStrings();
      const { apiProvider: { apiFetch } } = this.props;

      // Get the State Summary Data
      const summaryDataRequest = apiFetch(
        `${REPORT_BASE_API_PATH}bystate?${dashboardFilterString}`,
        {
          signal: fetchRequest.abortController.signal,
        },
      );

      // Get the State Monthly Data
      const monthlyDataRequest = apiFetch(
        `${REPORT_BASE_API_PATH}bystatebymonth?${dashboardFilterString}`,
        {
          signal: fetchRequest.abortController.signal,
        },
      );

      // Execute and wait for both requests to complete
      Promise.all([summaryDataRequest, monthlyDataRequest]).then(([summaryDataResponse, monthlyDataResponse]) => {
        this.unregisterFetchRequest(fetchRequest);

        // Data fetched successfully
        if (summaryDataResponse.success && monthlyDataResponse.success) {
          this.setState({
            geographicalStateData: {
              isLoading: false,
              summaryData: {
                data: summaryDataResponse.body.data,
                totals: summaryDataResponse.body.totals,
              },
              monthlyData: {
                data: monthlyDataResponse.body.data,
                totals: monthlyDataResponse.body.totals,
              },
            },
          });
        }

        // Failed to fetch the data for some reason
        else if (!summaryDataResponse.aborted) {
          this.setState({
            geographicalStateData: {
              ...geographicalStateData,
              error: summaryDataResponse.error ?? monthlyDataResponse.error ?? 'An unexpected error has occurred',
              isLoading: false,
            },
          });
        }
      }).catch(() => this.unregisterFetchRequest(fetchRequest));
    });
  }


  /**
   * @description
   * Fetch the Salesperson summary and monthly data for all of the current or specific stateIds
   *
   * @param {number[]} [newStateIds=null] an array of id of a specific state to load. Provide null to abort and re-load all of the currently selected states
   */
  fetchSalespersonData = (newStateIds = null) => {
    const { urlState } = this.props;
    const { stateIds } = urlState;
    const { salespersonData } = this.state;

    // What stateIds are we really trying to load here?
    const stateIdsToLoad = newStateIds || stateIds;

    // We never load all project manager data. Only project manager data for specified state Ids.
    if (stateIdsToLoad.length === 0) return;

    // Update the project manager data we already have to reflect the new "isLoading" status
    const existingSalespersonData = salespersonData
      .map((pmd) => ({
        ...pmd,
        isLoading: pmd.isLoading || !!stateIdsToLoad.includes(pmd.stateId),
      }));

    // Create project manager data structures for data that has not been loaded yet
    const newSalespersonData = stateIdsToLoad
      .filter((stateIdToLoad) => !existingSalespersonData.find((pmd) => pmd.stateId === stateIdToLoad))
      .map((stateIdToLoad) => ({
        stateId: stateIdToLoad,
        isLoading: true,
        error: null,
        summaryData: {
          data: [],
        },
        monthlyData: {
          data: [],
        },
      }));

    // Update the project manager data to reflect the loading of the new data
    this.setState({
      salespersonData: [
        ...existingSalespersonData,
        ...newSalespersonData,
      ],
    }, () => {
      // Create a new fetch request
      const fetchRequest = this.registerFetchRequest(DATA_TYPE.PROJECT_MANAGER, newStateIds);

      const { apiProvider: { apiFetch } } = this.props;

      const dashboardFilterString = this.getSfDashboardFilterStrings();
      const geographicalStateFilterString = getGeographicalStateFilterString(stateIdsToLoad);

      // Get the Salesperson Summary Data
      const summaryDataRequest = apiFetch(
        `${REPORT_BASE_API_PATH}bysalesperson?${dashboardFilterString}${geographicalStateFilterString}`,
        {
          signal: fetchRequest.abortController.signal,
        },
      );

      // Get the Salesperson Monthly Data
      const monthlyDataRequest = apiFetch(
        `${REPORT_BASE_API_PATH}bysalespersonbymonth?${dashboardFilterString}${geographicalStateFilterString}`,
        {
          signal: fetchRequest.abortController.signal,
        },
      );

      // Execute and wait for both requests to complete
      Promise.all([summaryDataRequest, monthlyDataRequest]).then(([summaryDataResponse, monthlyDataResponse]) => {
        this.unregisterFetchRequest(fetchRequest);

        const { salespersonData: oldSalespersonData } = this.state;

        // Data fetched successfully
        if (summaryDataResponse.success && monthlyDataResponse.success) {
          const updatedSalespersonData = oldSalespersonData.map((pmd) => {
            if (stateIdsToLoad.includes(pmd.stateId)) {
              return {
                ...pmd,
                isLoading: false,
                summaryData: {
                  data: summaryDataResponse.body.data.filter((item) => item.state_id === pmd.stateId),
                },
                monthlyData: {
                  data: monthlyDataResponse.body.data.filter((item) => item.state_id === pmd.stateId),
                },
              };
            }
            return pmd;
          });
          this.setState({
            salespersonData: updatedSalespersonData,
          });
        }

        // Failed to fetch the data for some reason
        else if (!summaryDataResponse.aborted) {
          const updatedSalespersonData = oldSalespersonData.map((pmd) => {
            if (stateIdsToLoad.includes(pmd.stateId)) {
              return {
                ...pmd,
                error: summaryDataResponse.error ?? monthlyDataResponse.error ?? 'An unexpected error has occurred',
                isLoading: false,
              };
            }
            return pmd;
          });
          this.setState({
            salespersonData: updatedSalespersonData,
          });
        }
      }).catch(() => this.unregisterFetchRequest(fetchRequest));
    });
  }


  /**
   * @description
   * Fetch the Salesperson summary and monthly data for all of the current or specific stateIds
   *
   * @param {{
   *  stateId: number
   *  pmId: number,
   * }[]} [newPmAndStateIds = null] an array of id of a specific project manager / state to load. Provide null to abort and re-load all of the currently selected project managers
   */
  fetchProjectData = (newPmAndStateIds = null) => {
    const { urlState } = this.props;
    const { pmAndStateIds } = urlState;
    const { projectData } = this.state;

    // What pmAndStateIds are we really trying to load here?
    const pmAndStateIdsToLoad = newPmAndStateIds || pmAndStateIds;

    // We never load all project data. Only project data for specified project manager and state Ids.
    if (pmAndStateIdsToLoad.length === 0) return;

    // Update the project data we already have to reflect the new "isLoading" status
    const existingProjectData = projectData
      .map((pd) => ({
        ...pd,
        isLoading: pd.isLoading || !!pmAndStateIdsToLoad.find((pmAndStateId) => (pd.salespersonId === pmAndStateId.pmId && pd.stateId === pmAndStateId)),
      }));

    // Create project data structures for data that has not been loaded yet
    const newProjectData = pmAndStateIdsToLoad
      .filter((pmAndStateIdToLoad) => !existingProjectData.find((pd) => (pd.salespersonId === pmAndStateIdToLoad.pmId && pd.stateId === pmAndStateIdToLoad.stateId)))
      .map((pmAndStateIdToLoad) => ({
        salespersonId: pmAndStateIdToLoad.pmId,
        stateId: pmAndStateIdToLoad.stateId,
        isLoading: true,
        error: null,
        summaryData: {
          data: [],
        },
        monthlyData: {
          data: [],
        },
      }));

    // Update the project data to reflect the loading of the new data
    this.setState({
      projectData: [
        ...existingProjectData,
        ...newProjectData,
      ],
    }, () => {
      // Create a new fetch request
      const fetchRequest = this.registerFetchRequest(DATA_TYPE.PROJECT, newPmAndStateIds);

      const { apiProvider: { apiFetch } } = this.props;

      const salespersonFilterString = getSalespersonFilterString(pmAndStateIdsToLoad);
      const dashboardFilterString = this.getSfDashboardFilterStrings();

      // Get the Project Summary Data
      const summaryDataRequest = apiFetch(
        `${REPORT_BASE_API_PATH}byproject?${dashboardFilterString}${salespersonFilterString}`,
        {
          signal: fetchRequest.abortController.signal,
        },
      );

      // Get the Project Monthly Data
      const monthlyDataRequest = apiFetch(
        `${REPORT_BASE_API_PATH}byprojectbymonth?${dashboardFilterString}${salespersonFilterString}`,
        {
          signal: fetchRequest.abortController.signal,
        },
      );

      // Execute and wait for both requests to complete
      Promise.all([summaryDataRequest, monthlyDataRequest]).then(([summaryDataResponse, monthlyDataResponse]) => {
        this.unregisterFetchRequest(fetchRequest);

        const { projectData: oldProjectData } = this.state;

        // Data fetched successfully
        if (summaryDataResponse.success && monthlyDataResponse.success) {
          const updatedProjectData = oldProjectData.map((pd) => {
            if (pmAndStateIdsToLoad.find((pmAndState) => pmAndState.stateId === pd.stateId && pmAndState.pmId === pd.salespersonId)) {
              return {
                ...pd,
                isLoading: false,
                summaryData: {
                  nonsense: true,
                  data: summaryDataResponse.body.data.filter((item) => item.state_id === pd.stateId && item.salesperson_id === pd.salespersonId),
                },
                monthlyData: {
                  nonsense: true,
                  data: monthlyDataResponse.body.data.filter((item) => item.state_id === pd.stateId && item.salesperson_id === pd.salespersonId),
                },
              };
            }
            return pd;
          });

          this.setState({
            projectData: updatedProjectData,
          });
        }

        // Failed to fetch the data for some reason
        else if (!summaryDataResponse.aborted) {
          const updatedProjectData = oldProjectData.map((pd) => {
            if (pmAndStateIdsToLoad.find((pmAndState) => pmAndState.stateId === pd.stateId && pmAndState.pmId === pd.salespersonId)) {
              return {
                ...pd,
                error: summaryDataResponse.error ?? monthlyDataResponse.error ?? 'An unexpected error has occurred',
                isLoading: false,
              };
            }
            return pd;
          });
          this.setState({
            projectData: updatedProjectData,
          });
        }
      }).catch(() => this.unregisterFetchRequest(fetchRequest));
    });
  }


  /**
   * @description
   * Purge the project manager data for a specified stateId
   *
   * @param {number | number[]} stateIds
   */
  purgeSalespersonData = (stateIds) => {
    const stateIdsToPurge = Array.isArray(stateIds) ? stateIds : [stateIds];
    const { salespersonData } = this.state;

    stateIdsToPurge.forEach((stateId) => {
      // Purge the fetch request and any outstanding data fetch for the project manager data
      const existingFetchRequests = this.findFetchRequests(DATA_TYPE.PROJECT_MANAGER, stateId);
      if (existingFetchRequests && existingFetchRequests.length) {
        existingFetchRequests.forEach((existingFetchRequest) => {
          existingFetchRequest.abortController.abort();
          this.unregisterFetchRequest(existingFetchRequest);
        });
      }
    });

    // set the new project manager data to everything it is currently except that data for purged state ids.
    this.setState({
      salespersonData: salespersonData.filter((pmd) => !stateIds.includes(pmd.stateId)),
    });
  }


  /**
   * @description
   * Purge the project data for a specified stateId and salespersonId
   *
   * @param {{
   *  stateId: number,
   *  pmId: number,
   * }[] | {
   *  stateId: number,
   *  pmId: number,
   * }} pmAndStateIds
   */
  purgeProjectData = (pmAndStateIds) => {
    const pmAndStateIdsToPurge = Array.isArray(pmAndStateIds) ? pmAndStateIds : [pmAndStateIds];
    const { projectData } = this.state;

    pmAndStateIdsToPurge.forEach((pmAndStateId) => {
      // Purge the fetch request and any outstanding data fetch for the project data
      const existingFetchRequests = this.findFetchRequests(DATA_TYPE.PROJECT, pmAndStateId);
      if (existingFetchRequests && existingFetchRequests.length) {
        existingFetchRequests.forEach((existingFetchRequest) => {
          existingFetchRequest.abortController.abort();
          this.unregisterFetchRequest(existingFetchRequest);
        });
      }
    });

    // set the new project data to everything it is currently except that data for purged ids.
    this.setState({
      projectData: projectData.filter((pd) => !pmAndStateIds
        .find((pmAndStateId) => (pmAndStateId.stateId === pd.stateId) &&
          (pmAndStateId.pmId === pd.salespersonId))),
    });
  }


  /**
   * @description
   * Turn on / off one of the geographical states
   *
   * @param {number} stateId
   */
  toggleGeographicalState = (stateId) => {
    // Check to see if the state is in the current list of stateIds
    const { urlState, setUrlState } = this.props;
    const { stateIds, pmAndStateIds } = urlState;
    let newStateIds = [...stateIds];
    let newPmAndStateIds = [...pmAndStateIds];

    // Remove a stateId
    if (newStateIds.includes(stateId)) {
      newStateIds = newStateIds.filter((id) => id !== stateId);

      // Also remove any matching pmAndStateIds
      newPmAndStateIds = newPmAndStateIds.filter((pmAndStateId) => (pmAndStateId.stateId !== stateId));
    }

    // Add a stateId
    else {
      newStateIds.push(stateId);
    }

    setUrlState({
      stateIds: newStateIds,
      pmAndStateIds: newPmAndStateIds,
    }, PUSH_OR_REPLACE.REPLACE);
  }


  /**
   * @description
   * Turn on / off one of the project managers within one of the geographical states
   *
   * @param {number} stateId
   * @param {number} salespersonId
   */
  toggleSalesperson = (stateId, salespersonId) => {
    // Check to see if the project manager is in the current list of pmAndStateIds
    const { urlState, setUrlState } = this.props;
    const { pmAndStateIds } = urlState;
    let newPmAndStateIds = [...pmAndStateIds];

    // Remove the Salesperson
    if (newPmAndStateIds.find((pmAndStateId) => pmAndStateId.stateId === stateId && pmAndStateId.pmId === salespersonId)) {
      newPmAndStateIds = newPmAndStateIds.filter((pmAndStateId) => !(pmAndStateId.stateId === stateId && pmAndStateId.pmId === salespersonId));
    }

    // Add the Salesperson
    else {
      newPmAndStateIds.push({
        stateId,
        pmId: salespersonId,
      });
    }

    setUrlState({
      pmAndStateIds: newPmAndStateIds,
    }, PUSH_OR_REPLACE.REPLACE);
  }


  /**
   * @description
   * Increments a state value that results in the split handle re-rendering
   * and thus, recalculating its position
   */
  forceUpdateSplitHandle = () => {
    const { splitHandleForceUpdateId } = this.state;
    this.setState({
      splitHandleForceUpdateId: splitHandleForceUpdateId + 1,
    });
  }


  /**
   * @description
   * Store the table settings to local storage
   */
  saveLocalSettings = () => {
    const {
      summaryFields,
      monthlyFields,
      displayStatuses,
      hideEmptyStates,
      hideZeroValues,
      showProjectNames,
      lowLikelihoodFilter,
      highLikelihoodFilter,
    } = this.state;

    storeSalesForecastDashboardSettingsToLocalStorage({
      salesForecastSummaryFields: summaryFields,
      salesForecastMonthlyFields: monthlyFields,
      salesForecastDisplayStatuses: displayStatuses,
      lowLikelihoodFilter,
      highLikelihoodFilter,
      showProjectNames,
      hideEmptyStates,
      hideZeroValues,
    });
  }


  /**
   * @description
   * Fired when the user clicks the reset date range button
   */
  handleResetDateRange = () => {
    const { setUrlState } = this.props;
    setUrlState(defaultDateRange(), PUSH_OR_REPLACE.REPLACE);
  }


  /**
   * @description
   * Handle change to one of the date range elements
   *
   * @param {'start' | 'end'} context
   * @param {'month' | 'year'} field
   * @param {number} value
   */
  handleChangeDateRangeElement = (context, field, value) => {
    const { setUrlState, urlState } = this.props;

    let urlKey = null;

    if (context === 'start' && field === 'month') urlKey = 'startMonth';
    else if (context === 'start' && field === 'year') urlKey = 'startYear';
    else if (context === 'end' && field === 'month') urlKey = 'endMonth';
    else if (context === 'end' && field === 'year') urlKey = 'endYear';
    else throw new TypeError(`Unhandled context & field. Context: "${context}", Field: "${field}"`);

    let startMonth = urlKey === 'startMonth' ? value : urlState.startMonth;
    let startYear = urlKey === 'startYear' ? value : urlState.startYear;
    let endMonth = urlKey === 'endMonth' ? value : urlState.endMonth;
    let endYear = urlKey === 'endYear' ? value : urlState.endYear;

    let start = moment(`${startYear}-${startMonth}`, 'YYYY-MM');
    let end = moment(`${endYear}-${endMonth}`, 'YYYY-MM');

    if (context === 'start' && end.isBefore(start)) {
      // make sure we're not moving the end too far back
      end = start.clone();
    }

    else if (start.isAfter(end)) {
      // make sure we're not moving the start too far forward
      start = end.clone();
    }

    startMonth = parseInt(start.format('MM'), 10).toString().padStart(2, 0);
    startYear = parseInt(start.format('YYYY'), 10).toString().padStart(4, 0);
    endMonth = parseInt(end.format('MM'), 10).toString().padStart(2, 0);
    endYear = parseInt(end.format('YYYY'), 10).toString().padStart(4, 0);

    setUrlState({
      startMonth,
      startYear,
      endMonth,
      endYear,
    }, PUSH_OR_REPLACE.REPLACE);
  };


  /**
   * @description
   * Called by either the user clicking on a FullScreen toggle button
   * or by the browser breaking the user out of full screen mode
   *
   * @param {boolean} newValue
   */
  handleSetFullScreen = (newValue) => {
    this.setState({
      isFullScreen: newValue,
    });
  }


  toggleShowStatusTable = () => {
    const { showStatusTable } = this.state;
    this.setState({
      showStatusTable: !showStatusTable,
    });
  }


  setHasCacheError = (newValue) => {
    const { showStatusTable } = this.state;
    this.setState({
      hasCacheError: newValue === true,
      showStatusTable: newValue === true ? true : showStatusTable,
    });
  }


  /**
   * @description
   * Fired when the user clicks the settings button in the header
   */
  handleSettingsButtonClick = () => {
    const { modalProvider: { showModal } } = this.props;
    const {
      summaryFields,
      monthlyFields,
      displayStatuses,
      hideEmptyStates,
      hideZeroValues,
      showProjectNames,
      lowLikelihoodFilter,
      highLikelihoodFilter,
    } = this.state;

    showModal(MODAL_TYPE.SALES_FORECAST_DASHBOARD_SETTINGS, {
      summaryFields,
      monthlyFields,
      displayStatuses,
      hideEmptyStates,
      hideZeroValues,
      showProjectNames,
      lowLikelihoodFilter,
      highLikelihoodFilter,
      onModalComplete: ({ settingsChanged, newSettings }) => {
        if (settingsChanged) {
          this.setState(newSettings, this.saveLocalSettings);
        }
      },
    });
  }


  /**
   * @description
   * Fired when the user clicks a row
   *
   * @param {DATA_TYPE} dataType the type of row that was clicked
   * @param {{
   *  stateId: number,
   *  salespersonId: number | null,
   *  projectId: number | null
   * }}
   */
  // eslint-disable-next-line no-unused-vars
  handleRowClick = (dataType, { stateId, salespersonId }) => { // , projectId was defined but never used
    switch (dataType) {
      case DATA_TYPE.GEOGRAPHICAL_STATE:
        this.toggleGeographicalState(stateId);
        break;
      case DATA_TYPE.PROJECT_MANAGER:
        this.toggleSalesperson(stateId, salespersonId);
        break;
      default:
        break;
    }
  }


  /**
   * @description
   * Fired when the user clicks one of the inline "Add Month" buttons either
   * to the beginning of the month selection or the end of the month selection
   *
   * @param {-1 | 1} beforeOrAfter -1 to add/remove at the start, 1 to add/remove at the end
   * @param {-1 | 1} addOrRemove -1 to remove, 1 to add
   */
  handleAddRemoveMonth = (beforeOrAfter, addOrRemove) => {
    const { urlState, setUrlState } = this.props;
    const {
      startMonth,
      startYear,
      endMonth,
      endYear,
    } = urlState;

    let newRange = {
      startMonth,
      startYear,
      endMonth,
      endYear,
    };

    let changed = false;

    // Adding a month to the start
    if (beforeOrAfter < 0) {
      const newStart = incMonth({ year: startYear, month: startMonth }, (addOrRemove * -1));
      newRange = {
        ...newRange,
        startMonth: newStart.month,
        startYear: newStart.year,
      };
      changed = true;
    }

    // Adding a month to the end
    else if (beforeOrAfter > 0) {
      const newEnd = incMonth({ year: endYear, month: endMonth }, addOrRemove);
      newRange = {
        ...newRange,
        endMonth: newEnd.month,
        endYear: newEnd.year,
      };
      changed = true;
    }

    if (changed) {
      setUrlState(newRange, PUSH_OR_REPLACE.REPLACE);
    }
  }


  /**
   * @description
   * Fired when the user clicks the collapse all rows button
   */
  handleCollapseAllRows = () => {
    const { setUrlState } = this.props;

    // TODO: sometime in the future allow this to detect multiple tiers.

    // Remove all of the project manager Ids and State Ids from the URL state
    setUrlState({
      stateIds: [],
      pmAndStateIds: [],
    }, PUSH_OR_REPLACE.REPLACE);
  }


  /**
   * @description
   * Fired when the user clicks the expand all rows button
   */
  handleExpandAllRows = () => {
    const { setUrlState } = this.props;
    const { geographicalStateData } = this.state;

    // TODO: sometime in the future allow this to detect multiple tiers and work with project managers.

    // Remove all of the project manager Ids and State Ids from the URL state
    if (geographicalStateData && geographicalStateData.summaryData && Array.isArray(geographicalStateData.summaryData.data)) {
      setUrlState({
        stateIds: geographicalStateData.summaryData.data.map((sd) => sd.state_id),
      }, PUSH_OR_REPLACE.REPLACE);
    }
  }


  /**
   * @inheritdoc
   */
  render = () => {
    const { urlState } = this.props;

    const {
      startMonth,
      endMonth,
      startYear,
      endYear,
      stateIds,
      pmAndStateIds,
    } = urlState;

    const {
      isFullScreen,
      splitHandleForceUpdateId,
      summaryFields,
      monthlyFields,
      // displayStatuses,
      hideEmptyStates,
      hideZeroValues,
      showProjectNames,
      monthGroups,
      geographicalStateData,
      salespersonData,
      projectData,
      showStatusTable,
      hasCacheError,
    } = this.state;

    const allowCollapseAllRows = (
      Array.isArray(stateIds) &&
      (stateIds.length > 0) &&
      !!geographicalStateData &&
      !!geographicalStateData.summaryData &&
      Array.isArray(geographicalStateData.summaryData.data) &&
      (geographicalStateData.summaryData.data.length > 0)
    );

    const allowExpandAllRows = (
      Array.isArray(stateIds) &&
      !!geographicalStateData &&
      !!geographicalStateData.summaryData &&
      Array.isArray(geographicalStateData.summaryData.data) &&
      (geographicalStateData.summaryData.data.length > stateIds.length)
    );

    return (
      <FullScreenWrapper
        isFullScreen={isFullScreen}
        onChange={this.handleSetFullScreen}
      >
        <Container fluid className="sales-forecast-dashboard">
          <SalesForecastPageHeader
            {...this.props}
            isFullScreen={isFullScreen}
            startMonth={Number(startMonth)}
            endMonth={Number(endMonth)}
            startYear={Number(startYear)}
            endYear={Number(endYear)}
            hasCacheError={hasCacheError}
            statusTableVisible={showStatusTable}

            onSetFullScreen={this.handleSetFullScreen}
            onChangeDateRangeElement={this.handleChangeDateRangeElement}
            onSettingsButtonClick={this.handleSettingsButtonClick}
            onResetDateRange={this.handleResetDateRange}
            onToggleStatusTable={this.toggleShowStatusTable}
          />

          <ProjectCacheStatusWidget showStatusTable={showStatusTable} onCacheErrorDetected={this.setHasCacheError} />

          <Card className={classNames('sales-forecast')}>

            <SalesForecastTable
              summaryFields={summaryFields}
              monthlyFields={monthlyFields}
              monthGroups={monthGroups}
              geographicalStateData={geographicalStateData}
              salespersonData={salespersonData}
              projectData={projectData}
              stateIds={stateIds}
              pmAndStateIds={pmAndStateIds}

              splitHandleForceUpdateId={splitHandleForceUpdateId}
              hideEmptyStates={hideEmptyStates}
              hideZeroValues={hideZeroValues}
              showProjectNames={showProjectNames}
              allowCollapseAllRows={allowCollapseAllRows}
              allowExpandAllRows={allowExpandAllRows}

              onRowClick={this.handleRowClick}
              onAddMonthStart={() => this.handleAddRemoveMonth(-1, 1)}
              onAddMonthEnd={() => this.handleAddRemoveMonth(1, 1)}
              onRemoveMonthStart={() => this.handleAddRemoveMonth(-1, -1)}
              onRemoveMonthEnd={() => this.handleAddRemoveMonth(1, -1)}
              onCollapseAllRows={this.handleCollapseAllRows}
              onExpandAllRows={this.handleExpandAllRows}
            />

          </Card>
        </Container>
      </FullScreenWrapper>
    );
  }
}


SalesForecastDashboard.propTypes = {
  modalProvider: PropTypes.shape(MODAL_PROVIDER_PROP_TYPES).isRequired,
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
  urlState: PropTypes.shape({
    stateIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    pmAndStateIds: PropTypes.arrayOf(PropTypes.shape({
      stateId: PropTypes.number,
      pmId: PropTypes.number,
    })).isRequired,
    startYear: PropTypes.string.isRequired,
    startMonth: PropTypes.string.isRequired,
    endYear: PropTypes.string.isRequired,
    endMonth: PropTypes.string.isRequired,
  }).isRequired,
  setUrlState: PropTypes.func.isRequired,
};

SalesForecastDashboard.defaultProps = {};

// Connect the Project Summary Dashboard to the URL State manager
export default connectToAPIProvider(connectToModalProvider(urlStateManager.connectUrlState(
  washSalesForecastUrlState,
  (componentUrlState) => {
    // required to avoid washing objects out of the initial urlState
    const newComponentUrlState = { ...componentUrlState };

    if (newComponentUrlState.pmAndStateIds && newComponentUrlState.pmAndStateIds instanceof Object) {
      newComponentUrlState.pmAndStateIds = transformPmAndStateIdsForUrlState(newComponentUrlState.pmAndStateIds);
    }

    if (newComponentUrlState.stateIds && newComponentUrlState.stateIds instanceof Object) {
      newComponentUrlState.stateIds = transformStateIdsForUrlState(newComponentUrlState.stateIds);
    }

    // Remove Date Range parameters from the URL String if they match the defaults
    Object.entries(defaultDateRange()).forEach(([key, value]) => {
      // Does the date key exist in the incoming URL state?
      if (newComponentUrlState[key] !== undefined) {
        newComponentUrlState[key] = newComponentUrlState[key] === value ? urlStateManager.REMOVE_FROM_URL : newComponentUrlState[key];
      }
    });


    return newComponentUrlState;
  },
)(SalesForecastDashboard)));
