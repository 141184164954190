import React from 'react';
import classNames from 'classnames';

import { ConstSelect, ConstSelectProps } from './const-select';
import { IStateRecord } from '../../types/state.record.interface';
import { SelectOption } from '../../types/poly-form/select-option';

import { ApiQueryDataLoader } from '../api-query-data-loader/api-query-data-loader';
import Icon from '../layout-helpers/icon';

export type StatePickerProps = ConstSelectProps;

export const StatePicker: React.FC<StatePickerProps> = (props) => {
  const {
    hasError,
    tabSelectsValue = true,
    disabled,
  } = props;

  return (
    <ApiQueryDataLoader
      apiQueryUrl="/state?pageLength=0"
      apiQueryCacheName="states"
      render={({
        response,
        isLoading,
        hasError: hasRoleLoadingError,
      }) => {
        if (isLoading) {
          return (
            <div
              className={classNames('portal-data-field', 'not-editable', {
                disabled,
              })}
            >
              <Icon i="rolling" />
            </div>
          );
        }

        const data: IStateRecord[] = (response?.data as IStateRecord[]) ?? [];

        return (
          <ConstSelect
            {...props}
            hasError={hasError || hasRoleLoadingError}
            inputOptions={data.map((dataItem: IStateRecord) => ({ id: dataItem.id, name: dataItem.acronym } as SelectOption))}
            tabSelectsValue={tabSelectsValue}
          />
        );
      }}
    />
  );
};
