import React, { PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';

import { APIContext } from '../providers/api-provider';
import { CurrentUserContext } from '../providers/current-user-provider';

import { APIRecord } from '../../types/api-record.interface';
import { PolyFormProps } from '../../types/poly-form/poly-form.props';
import { APIPolyFormProps } from '../../types/poly-form/api-poly-form.props';
import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';
import { FormRendererProps } from '../../types/poly-form/form-renderer.props';

import { useIsMounted } from '../../react-hooks/use-is-mounted.hook';

import { PolyForm } from './poly-form';

import { apiAborter } from '../../helpers/api-aborter.helper';
import { buildAPIRoute } from '../../helpers/build-api-route.helper';
import { hasPermittedAction } from '../../helpers/has-permitted-action.helper';
import { makeFormRendererFields } from './make-form-renderer-fields.helper';
import { prepareNewRecordFormData } from './prepare-new-record-form-data.helper';
import RedirectInstruction from '../../helpers/redirect-instruction';

import { API_ACTION } from '../../constants/api-action.const';
import { DELETE_CONFIRMATION_TYPE } from '../../constants/delete-confirmation-type.const';
import { NotificationContext } from '../providers/notification-provider';
import { NOTIFICATION_TYPE } from '../../constants/notification-type.const';
import { usePreviousValue } from '../../react-hooks/use-previous-value.hook';
import { deepCompare } from '../../helpers/deep-compare.helper';
import { FormFieldChangeProps } from '../../types/poly-form/form-field-change-props';

// How long the success message should remain on the screen
const SUCCESS_MESSAGE_TIMEOUT = 3000;


/**
 * Render a PolyForm that maintains its own data and connection to the database
 * for standard API commands (POST, PUT etc...)
 *
 * @info If you're tracking an issue with internalFormData not reflecting passed down
 *       formData, consider passing in a formDataChecksum to bump the refresh
 */
export const APIPolyForm = <T extends APIRecord = APIRecord, >(props: PropsWithChildren<APIPolyFormProps<T>>): React.ReactElement => {
  const {
    children,

    formRendererType,

    parentId,
    parentData,
    siblingData,

    baseRoute,
    apiRoute,
    apiQuery,

    formCaption = 'Details',
    itemCaption = 'item',
    confirmCancel = true,
    formDeleteConfirmationType = DELETE_CONFIRMATION_TYPE.DELETE,
    navigateBackOnDelete = false,

    rendererOptions,
    showHeader = false,
    scrollToError = true,
    statusMap,
    hideBottomButtons,

    primaryKeyFieldName = 'id',
    initialFormFocusFieldName,
    fields,
    formDataChecksum = 0,
    formData = {} as Partial<T>,
    isReadOnly = false,
    isNewRecord = false,
    isEditing = true,

    permittedActions = [],

    updateRecord,

    onCanStartEditRecord,
    onStartEditRecord,
    onCanEndEditRecord,
    onEndEditRecord,
    onCanDeleteRecord,
    onDeleteRecord,
    onConfirmDeleteRecord,

    onFormChange,
    onFieldChange,
    onBeforeSave,
    onFormComplete,

    onClick,
  } = props;

  const { userHasPermissions, refreshUserAlerts } = useContext(CurrentUserContext);
  const { apiFetch } = useContext(APIContext);
  const { addNotification } = useContext(NotificationContext);

  const [internalFormData, setInternalFormData] = useState<Partial<T>>((isNewRecord ? prepareNewRecordFormData<T>(formData, fields, parentId, parentData, siblingData) : formData));
  const previousInternalFormData = usePreviousValue<Partial<T>>(internalFormData);
  const [oldInternalFormData, setOldInternalFormData] = useState<Partial<T>>();
  const primaryKeyValue = internalFormData[primaryKeyFieldName] ?? null;

  const [internalFields, setInternalFields] = useState<FormRendererProps['fields']>(
    makeFormRendererFields(primaryKeyValue, fields, userHasPermissions),
  );

  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [hasSuccess, setHasSuccess] = useState<PolyFormProps['hasSuccess']>(false);
  const [formMessage, setFormMessage] = useState<PolyFormProps['formMessage']>();
  const [hasErrors, setHasErrors] = useState<PolyFormProps['hasErrors']>(false);
  const [errors, setErrors] = useState<PolyFormProps['errors']>({});
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const [oldFormDataChecksum, setOldFormDataChecksum] = useState<APIPolyFormProps['formDataChecksum']>(formDataChecksum);

  const successMessageTimeout = useRef<null | ReturnType<typeof setTimeout>>(null);

  const abortController = useRef<AbortController | null>(null);

  const isMounted = useIsMounted();

  /**
   * Clear both the success and error form messages
   */
  const clearFormMessage = useCallback(() => {
    setHasSuccess(false);
    setFormMessage(undefined);
    setHasErrors(false);
    setErrors({});
  }, []);


  /**
   * Momentarily show a success message and clear it after the success message interval
   *
   * @param message the message to display at the bottom of the form
   */
  const showFormSuccessMessage = useCallback((message: PolyFormProps['formMessage']) => {
    // Set the success message
    setHasSuccess(true);
    setFormMessage(message);

    // Clear the errors
    setHasErrors(false);
    setErrors({});

    // In a few moments, kill off the success message
    successMessageTimeout.current = setTimeout(() => {
      setHasSuccess(false);
      setFormMessage(undefined);
    }, SUCCESS_MESSAGE_TIMEOUT);
  }, []);


  /**
   * Show an error message
   *
   * @param newMessage the message to display at the bottom of the form
   * @param newErrors the errors object to display under the message (optional)
   */
  const showFormErrorMessage = useCallback((newMessage: PolyFormProps['formMessage'], newErrors?: PolyFormProps['errors']) => {
    // Clear the success message
    setHasSuccess(false);

    // Show the errors
    setHasErrors(true);
    setFormMessage(newMessage);
    setErrors(newErrors ?? {});
  }, []);


  /**
   * Cancel all internally managed changes and revert to the provided form data
   *
   * @param id the primary key value for the current record
   */
  const cancelRecordChanges = useCallback((
    id: FormFieldComponentProps['value'],
  ): void => {
    const oldFormData = oldInternalFormData ?? ((isNewRecord ? formData : prepareNewRecordFormData<T>(formData, fields, parentId, parentData, siblingData)));

    setHasSuccess(false);
    setFormMessage(undefined);
    setHasErrors(false);
    setErrors({});
    setInternalFormData(oldFormData);
    setOldInternalFormData(undefined);
    setIsDirty(false);

    // run end edit event to check if the implementer is happy to end the edit
    try {
      if (onEndEditRecord) onEndEditRecord(false, id, oldFormData);
    } catch (ex) {
    // dump it
      console.error('Unhandled exception calling APIPolyForm::onEndEditRecord', ex);
    }

    // Run the formComplete event
    try {
      if (onFormComplete) onFormComplete(false, id, oldFormData);
    } catch (ex) {
    // dump it
      console.error('Unhandled exception calling APIPolyForm::onFormComplete', ex);
    }
  }, [oldInternalFormData, isNewRecord, formData, parentData, siblingData, fields, parentId, onEndEditRecord, onFormComplete]);


  /**
   * save the row data back to the api
   */
  const saveRecordChanges = useCallback(async (newFormData?: Partial<T>): Promise<boolean> => {
    // Bail if the form is already doing something
    if (isBusy) {
      console.error('APIPolyForm::saveRecordChanges aborted, form is busy.');
      return false;
    }

    // Create a new Abort Controller
    if (abortController.current) {
      abortController.current.abort();
    }
    abortController.current = apiAborter();

    // if new form data has been passed, use it as our dirty state
    const dirtyFormData = (newFormData ?? internalFormData);

    // remove fields with editPermission false, only when editing, not creating new
    let fieldsToSave = { ...dirtyFormData };

    if (!isNewRecord) {
      internalFields.forEach((field) => {
        if (field.editPermission === false) {
          if (field.formSaveField && (field.formSaveField in fieldsToSave)) {
            delete fieldsToSave[field.formSaveField];
          }
          if (field.name in fieldsToSave) {
            delete fieldsToSave[field.name];
          }
        }
      });
    }

    // Give the implementer a chance to update / augment the data that is sent to the API
    if (onBeforeSave) {
      fieldsToSave = onBeforeSave(primaryKeyValue, fieldsToSave);
    }

    // Prepare the form
    setIsBusy(true);
    clearFormMessage();

    // Execute the save on the API
    const response = await apiFetch(
      buildAPIRoute({ isNewRecord, parentId, primaryKeyValue, formData: dirtyFormData, baseRoute, apiRoute, apiQuery }),
      {
        signal: abortController.current.signal,
        method: isNewRecord ? HTTP_METHOD.POST : HTTP_METHOD.PATCH,
        body: fieldsToSave,
      },
    );

    // Was the API call successful?
    if (response.success) {
      // Clear the abort controller
      abortController.current = null;

      // new primary key value back from the API
      const newPrimaryKeyValue = response.body.data[primaryKeyFieldName] ?? null;
      const newInternalFormData = {
        ...dirtyFormData,
        // The API response should return all of the data required to hydrate the field
        ...response.body.data,
      };

      // Update the form data
      setInternalFormData(newInternalFormData);
      setOldInternalFormData(undefined);

      // Reflect the success in the form state
      setIsDirty(false);
      setIsBusy(false);
      showFormSuccessMessage(response.body?.message ?? `${itemCaption} saved!`);

      // Update the list of user alerts (the save may have impacted some of the counts/tags in the sidebar)
      refreshUserAlerts();

      // Run the onEndEdit callback
      if (onEndEditRecord) {
        try {
          // TODO: Check if a record that was just created causes problems when the newPrimaryKeyValue is different from what the implementer is expecting
          onEndEditRecord(true, newPrimaryKeyValue, newInternalFormData);
        } catch (ex) {
          // dump it
          console.error('Unhandled exception calling APIPolyForm::onEndEditRecord', ex);
        }
      }

      // Run the onFormComplete callback
      if (onFormComplete) {
        try {
          onFormComplete(true, newPrimaryKeyValue, newInternalFormData);
        } catch (ex) {
          // dump it
          console.error('Unhandled exception calling APIPolyForm::onFormComplete', ex);
        }
      }

      return true;
    }

    // API Call failed
    if (!response.aborted) {
      // Clear the abort controller
      abortController.current = null;

      // Reflect the failure in the form state
      showFormErrorMessage(response.body?.message ?? response.error ?? 'There was an error with your data', response.body?.errors);
      setIsBusy(false);
    }

    return false;
  }, [
    isBusy,
    internalFormData,
    isNewRecord,
    clearFormMessage,
    apiFetch,
    parentId,
    primaryKeyValue,
    baseRoute,
    apiRoute,
    apiQuery,
    internalFields,
    primaryKeyFieldName,
    showFormSuccessMessage,
    itemCaption,
    refreshUserAlerts,
    onEndEditRecord,
    onFormComplete,
    onBeforeSave,
    showFormErrorMessage,
  ]);


  /**
   * ACTUALLY DELETE DATA method.
   *
   * @param args
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const deleteRecord = useCallback(async (
    redirectInstruction?: RedirectInstruction,
  // eslint-disable-next-line arrow-body-style
  ) => {
    // Bail if the form is already doing something
    if (isBusy) {
      console.error('APIPolyForm::deleteRecord aborted, form is busy.');
      return false;
    }

    // Create a new Abort Controller
    if (abortController.current) {
      abortController.current.abort();
    }
    abortController.current = apiAborter();

    // Prepare the form
    setIsBusy(true);
    clearFormMessage();

    // Execute the delete on the API
    const response = await apiFetch(
      buildAPIRoute({ isNewRecord, parentId, primaryKeyValue, formData: internalFormData, baseRoute, apiRoute, apiQuery }),
      {
        signal: abortController.current.signal,
        method: HTTP_METHOD.DELETE,
      },
    );

    // Was the API call successful? (Expected a 204 (no content))
    if (response.success && (response.status === 204)) {
      // Clear the abort controller
      abortController.current = null;

      // Reflect the success in the form state
      setIsDirty(false);
      setIsBusy(false);

      // Pop up a notification
      addNotification({
        type: NOTIFICATION_TYPE.DANGER,
        headline: `${itemCaption} deleted`,
        message: response.body?.message ?? `${itemCaption} deleted.`,
        timeout: 3000,
      });

      // Update the list of user alerts (the delete may have impacted some of the counts/tags in the sidebar)
      refreshUserAlerts();

      // Run callback for parent to redirect or something
      if (onDeleteRecord) {
        try {
          onDeleteRecord(primaryKeyValue, internalFormData, redirectInstruction);
        } catch (ex) {
          // dump it
          console.error('Unhandled exception calling APIPolyForm::onDeleteRecord', ex);
        }
      }

      return true;
    }

    // API Call failed
    if (!response.aborted) {
      // Clear the abort controller
      abortController.current = null;

      // Reflect the failure in the form state
      showFormErrorMessage(response.body?.message ?? response.error ?? `Error while deleting the ${itemCaption}!`, response.body?.errors);
      setIsBusy(false);
    }

    return false;
  }, [
    apiFetch,
    apiRoute,
    baseRoute,
    apiQuery,
    clearFormMessage,
    internalFormData,
    isBusy,
    isNewRecord,
    itemCaption,
    onDeleteRecord,
    parentId,
    primaryKeyValue,
    refreshUserAlerts,
    showFormErrorMessage,
    addNotification,
  ]);


  /**
   * Check if the Start Edit is allowed
   * @note: This method ignores the incoming formData and instead uses the internalFormData
   *
   * @param id the primary key value for the current record
   * @param currentFormData the current form data
   *
   * @returns true if the form can begin editing
   */
  const handleCanStartEditRecord = useCallback((
    id: FormFieldComponentProps['value'],
  ): boolean => {
    // Check the API Permissions (if they exist)
    if (permittedActions && !hasPermittedAction(permittedActions, API_ACTION.UPDATE)) return false;

    // Check with the parent
    if (onCanStartEditRecord) {
      return onCanStartEditRecord(id, internalFormData);
    }

    return true;
  }, [internalFormData, onCanStartEditRecord, permittedActions]);


  /**
   * Enter edit mode (Still dependant on the renderer setting `isEditing` to true)
   * @note: This method ignores the incoming formData and instead uses the internalFormData
   *
   * @param id the primary key value for the current record
   * @param currentFormData the current form data
   * @param focusFieldName the field name to focus after editing begins
   *
   * @returns true if the form can begin editing
   */
  const handleStartEditRecord = useCallback((
    id: FormFieldComponentProps['value'],
    currentFormData: Partial<T>,
    focusFieldName?: FormFieldComponentProps['name'],
  ): void => {
    // keep track of the old internal form data (used when changes are cancelled to revert)
    setOldInternalFormData(internalFormData);

    // Let the parent know the for is moving into edit mode
    if (onStartEditRecord) {
      onStartEditRecord(id, internalFormData, focusFieldName);
    }
  }, [internalFormData, onStartEditRecord]);


  /**
   * Check if the End Edit is allowed
   * @note: This method ignores the incoming formData and instead uses the internalFormData
   *
   * @param saveChanges Whether the end edit is a save or a cancel
   * @param id the primary key value for the current record
   * @param newFormData the new form data
   *
   * @returns true if allowed to end the edit
   */
  const handleCanEndEditRecord = useCallback((
    saveChanges: boolean,
    id: FormFieldComponentProps['value'],
    newFormData?: Partial<T>,
    forceSave?: boolean,
  ): boolean => {
    // run the can end edit event to check if the implementer is happy to end the edit
    if (onCanEndEditRecord) {
      return onCanEndEditRecord(saveChanges, id, newFormData ?? internalFormData, forceSave);
    }

    return true;
  }, [internalFormData, onCanEndEditRecord]);


  /**
   * End editing the form data (Still dependant on the renderer setting `isEditing` to false)
   *
   * @param saveChanges Whether the end edit is a save or a cancel
   * @param id the primary key value for the current record
   * @param newFormData the new form data
   */
  const handleEndEditRecord = useCallback((
    saveChanges: boolean,
    id: FormFieldComponentProps['value'],
    newFormData?: Partial<T>,
    forceSave ?: boolean,
  ): void => {
    // Save or Cancel
    if (saveChanges && (isDirty || isNewRecord || forceSave)) {
      saveRecordChanges(newFormData);
    } else {
      cancelRecordChanges(id);
    }
  }, [isDirty, isNewRecord, saveRecordChanges, cancelRecordChanges]);


  /**
   * Check whether the record can be deleted
   * @note: This method ignores the incoming formData and instead uses the internalFormData
   *
   * @param id the primary key value for the current record
   * @param currentFormData the current form data
   */
  const handleCanDeleteRecord = useCallback((
    id: FormFieldComponentProps['value'],
  ): boolean => {
    // Check the API Permissions (if they exist)
    if (permittedActions && !hasPermittedAction(permittedActions, API_ACTION.DELETE)) return false;

    // Check with the parent
    if (onCanDeleteRecord) {
      return onCanDeleteRecord(id, internalFormData);
    }

    return true;
  }, [internalFormData, onCanDeleteRecord, permittedActions]);


  /**
   * Delete the current record (Still dependant on the renderer reacting to the delete)
   * @note: This method ignores the incoming formData and instead uses the internalFormData
   *
   * @param id the primary key value for the current record
   * @param currentFormData the current form data
   * @param redirectInstruction information from the formRenderer about what to do when the record is deleted
   */
  const handleDeleteRecord = useCallback(async (
    id: FormFieldComponentProps['value'],
    currentFormData: Partial<T>,
    redirectInstruction?: RedirectInstruction,
  ) => {
    // Don't "Actually" delete a new record.
    if (!isNewRecord) {
      await deleteRecord(redirectInstruction);
    }

    // Pass the request to the parent (who can delete a new record if required)
    else if (onDeleteRecord) {
      onDeleteRecord(id, internalFormData, redirectInstruction);
    }
  }, [deleteRecord, internalFormData, isNewRecord, onDeleteRecord]);


  /**
   * Abstracted event handler for changing field values
   */
  const handleFieldChange = useCallback((field: FormFieldChangeProps | FormFieldChangeProps[]) => {
    // Normalise the changed fields to an array of changed fields
    const changedFields = Array.isArray(field) ? field : [field];

    const newErrors = { ...errors };
    let newFormData = {
      ...internalFormData,
    };

    // Iterate over the changed fields
    changedFields.forEach((changedField) => {
      // If the field was previously part of the error array, remove it
      delete newErrors[changedField.fieldName];
      if (changedField.objectFieldName) {
        delete newErrors[changedField.objectFieldName];
      }

      // Update the field value and object value in the form data
      newFormData = {
        ...newFormData,
        ...(changedField.objectFieldName ? { [changedField.objectFieldName]: changedField.objectFieldNewValue } : {}),
        [changedField.fieldName]: changedField.newValue,
      };
    });

    setErrors(newErrors);
    setHasErrors(Object.keys(newErrors).length > 0);
    setFormMessage(Object.keys(newErrors).length > 0 ? formMessage : undefined);

    // Update the Form Data
    setInternalFormData(newFormData);

    // The form is now dirty
    setIsDirty(true);

    // Fire off the onFieldChange event
    if (onFieldChange) {
      onFieldChange(field);
    }
  }, [errors, formMessage, internalFormData, onFieldChange]);


  /**
   * When the internalFormData changes, fire off the onFormChange event
   */
  useEffect(() => {
    if (isMounted && isEditing && onFormChange && previousInternalFormData && !deepCompare(previousInternalFormData, internalFormData)) {
      onFormChange(primaryKeyValue, internalFormData);
    }
  }, [isMounted, isEditing, previousInternalFormData, internalFormData, primaryKeyValue, onFormChange]);


  /**
   * When the field definitions or the primary key value changes,
   * prepare the internal fields
   */
  useEffect(() => {
    setInternalFields(makeFormRendererFields(primaryKeyValue, fields, userHasPermissions));
  }, [fields, primaryKeyValue, userHasPermissions]);


  /**
   * update the internalFormData (if we're not in edit mode)
   */
  const updateInternalFormData = useCallback(() => {
    if (!isEditing) {
      setInternalFormData((isNewRecord ? prepareNewRecordFormData(formData, fields, parentId, parentData, siblingData) : formData));
    }
  }, [isEditing, isNewRecord, formData, parentData, siblingData, fields, parentId]);


  /**
   * When we detect that the external formData has changed,
   * update the internalFormData (if we're not in edit mode)
   */
  useEffect(() => {
    if (oldFormDataChecksum !== formDataChecksum) {
      setOldFormDataChecksum(formDataChecksum);
      updateInternalFormData();
    }
  }, [oldFormDataChecksum, formDataChecksum, updateInternalFormData]);


  /**
   * (onComponentDidMount)
   * Perform any initialisation on the component after mounting
   */
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    /**
     * (onComponentWillUnmount)
     * Perform any unloading actions that will prevent some form of action
     * on this component after it is removed from the dom
     */
    return () => {
      // Remove lingering time outs so they don't fire after component removal
      if (successMessageTimeout.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(successMessageTimeout.current);
      }

      // Cancel any outstanding calls to the API
      if (abortController.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        abortController.current.abort();
      }
    };
  }, []);

  // Render
  return (
    <PolyForm<T>
      formRendererType={formRendererType}

      parentId={parentId}
      parentData={parentData}

      baseRoute={baseRoute}
      apiRoute={apiRoute}
      apiQuery={apiQuery}

      navigateBackOnDelete={navigateBackOnDelete}
      formCaption={formCaption}
      itemCaption={itemCaption}
      confirmCancel={confirmCancel}
      formDeleteConfirmationType={formDeleteConfirmationType ?? DELETE_CONFIRMATION_TYPE.DELETE}

      rendererOptions={rendererOptions}
      showHeader={showHeader}
      scrollToError={scrollToError}
      statusMap={statusMap}
      hideBottomButtons={hideBottomButtons}

      primaryKeyFieldName={primaryKeyFieldName}
      initialFormFocusFieldName={initialFormFocusFieldName}
      fields={internalFields}
      formData={internalFormData}
      isReadOnly={isReadOnly}
      isNewRecord={isNewRecord}
      isEditing={isEditing}

      isBusy={isBusy}
      isDirty={isDirty}

      hasErrors={hasErrors}
      hasSuccess={hasSuccess}
      formMessage={formMessage}
      errors={errors}

      permittedActions={permittedActions}

      updateRecord={updateRecord}

      onCanStartEditRecord={handleCanStartEditRecord}
      onStartEditRecord={handleStartEditRecord}
      onCanEndEditRecord={handleCanEndEditRecord}
      onEndEditRecord={handleEndEditRecord}
      onCanDeleteRecord={handleCanDeleteRecord}
      onDeleteRecord={handleDeleteRecord}

      onConfirmDeleteRecord={onConfirmDeleteRecord}
      onFieldChange={handleFieldChange}
      onClick={onClick}
    >
      {children}
    </PolyForm>
  );
};
