import React, { useContext } from 'react';
import { Button } from 'reactstrap';

import { ModalContext } from '../modals/modal-context';

import { ReturnValueModalResult } from '../../types/modal/modal-result';
import { FormFieldComponentProps } from '../../types/poly-form/form-field-component.props';

import Icon from '../layout-helpers/icon';
import { TextInput, TextInputProps } from './text-input';

import { MODAL_TYPE } from '../../constants/modal-type.const';

export type MicrosoftADUserIdInputProps = TextInputProps & Pick<FormFieldComponentProps,
  'formData'
>

/**
 * MicrosoftADUserIdInput
 */
export const MicrosoftADUserIdInput: React.FC<MicrosoftADUserIdInputProps> = (props) => {
  const {
    onChange,
    name,
    formData,
  } = props;

  const { showModal } = useContext(ModalContext);

  /**
   * @description
   * Fired when the user clicks the "Find" button next to the input
   */
  const handleFindIdClick = () => {
    // Display the search modal
    showModal<ReturnValueModalResult<string>>(MODAL_TYPE.FIND_MICROSOFT_AD_USER_ID, {
      userRecord: formData,
      onModalComplete: ({ success, value: microsoftAdUserId }) => {
        if (success && microsoftAdUserId && onChange) {
          onChange({
            fieldName: name,
            newValue: microsoftAdUserId,
          });
        }
      },
    });
  };

  return (
    <div className="microsoft-ad-user-id-input">
      <TextInput
        {...props}
      />
      <Button onClick={handleFindIdClick}>
        <Icon i="search" />
        <span>Find</span>
      </Button>
    </div>
  );
};
