import React from 'react';

import { APIRecord } from '../../types/api-record.interface';
import { ITabBadgeCountField } from '../../types/tabs-with-more/tab-badge-count-field.interface';

export type TabBadgeCountsProps = {
  badgeCountFields: ITabBadgeCountField[],
  rowData: APIRecord,
};

/**
 * These are the little numbers that follow the label on a tab
 * as defined by the table definition `badgeCountFields` array.
 */
export const TabBadgeCounts: React.FC<TabBadgeCountsProps> = (props) => {
  const {
    badgeCountFields,
    rowData,
  } = props;

  const getTabCountValue = (field: ITabBadgeCountField) => (field.onCalculateValue ? field.onCalculateValue(rowData[field.fieldName], rowData) : rowData[field.fieldName]);

  return (
    <div className="badge-count-wrapper">
      {badgeCountFields
        .filter((field) => getTabCountValue(field) > 0)
        .map((field) => (
          <div
            key={field.fieldName}
            className={`badge-count badge badge-${field.badgeColor ?? 'info'}`}
          >
            {getTabCountValue(field)}
          </div>
        ))}
    </div>
  );
};
