import React, { useCallback, useContext } from 'react';

import { ModalContext } from '../modals/modal-context';

import { AsyncSelectComponentProps } from '../../types/poly-form/async-select-component.props';
import { IContactRecord } from '../../types/contact/contact.record.interface';
import { NewRecordModalResult } from '../../types/modal/modal-result';
import { FormFieldChangeProps } from '../../types/poly-form/form-field-change-props';

import AsyncSelect from './async-select';
import Icon from '../layout-helpers/icon';

import { fullNameToNameComponents } from '../../helpers/full-name-to-components.helper';
import { fullName } from '../../helpers/full-name.helper';

import { MODAL_TYPE } from '../../constants/modal-type.const';

export type ContactPickerProps = Omit<AsyncSelectComponentProps, 'onChange'> & {
  onChange?: (field: FormFieldChangeProps<IContactRecord>) => void,
};

export const ContactPicker:React.FC<ContactPickerProps> = (props) => {
  const {
    name,
    formData = {},
    parentData = {},
    formSaveField = null,
    getNewItemData,
    onChange,
  } = props;

  const { showModal } = useContext(ModalContext);

  // Concatenate the first and last names together to get a full name
  const getOptionLabel = (option: Record<string, unknown>) => {
    if (!option) return '';

    // Return the label when the AsyncSelect is rendering a "Create new XXXX" option
    if (option.__isNew__) { return (
      <>
        <Icon i="plus-circle" />
        <span>{option.label as string}</span>
      </>
    ); }
    return fullName(option);
  };

  /**
   * Show a modal to create a new contact
   */
  const handleCreateOption = useCallback((inputValue: string) => {
    const initialData = {
      ...fullNameToNameComponents(inputValue),
      ...((typeof getNewItemData === 'function') ? getNewItemData(inputValue, formData, parentData) : {}),
    };

    showModal<NewRecordModalResult<IContactRecord>>(MODAL_TYPE.NEW_CONTACT, {
      initialData,
      onModalComplete: ({ success, newRecord: newContact }) => {
        if (success && onChange && newContact) {
          onChange({
            fieldName: formSaveField || `${name}_id`,
            newValue: newContact.id,
            objectFieldName: name,
            objectFieldNewValue: newContact,
          });
        }
      },
    });
  }, [formData, formSaveField, getNewItemData, name, onChange, parentData, showModal]);

  /**
 * Called by the select to render one of the options in the dropdown
 *
 * @see https://react-select.com/props
 *
 * @param option the option data to render
 * @param details information about where and what is being rendered
 * @param inputValue the search term the user has entered into the input
 */
  const renderOption = (option: IContactRecord, labelMeta: Record<string, unknown>): string | React.ReactNode => {
    if (labelMeta.context === 'menu') {
      return (
        <div>
          <span className="text-dark">
            {option.first}
            {' '}
            {option.last}
          </span>
          <br />
          {option.company?.name}
          {' '}
        </div>
      );
    }
    return (
      <span className="text-dark">
        {option.first}
        {' '}
        {option.last}
      </span>
    );
  };


  return (
    <AsyncSelect
      {...props}
      loadAndKeepAll
      searchRoute="/person"
      appendQuery="&with[]=company"
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      onCreateOption={handleCreateOption}
      onChange={onChange}
      dataMap={(option: IContactRecord) => ({
        ...option,
        name: fullName(option),
      })}
    />
  );
};
