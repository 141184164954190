import { defaultTableSettings, eventsTabDefault } from './default-table-settings';
import { COLUMN_FORMAT } from '../constants/column-format.const';
import { WIDGET_DEFINITION } from '../constants/widget-definition.const';
import { BUTTON_COLOR } from '../constants/button-color.const';
import { ICON } from '../constants/icon.const';
import { FORM_FIELD_TYPE } from '../constants/form-field-type.const';
import { API_FILTER_OPERATION } from '../constants/api-filter-operation.const';
import { EXPENSE_CLAIM_STATUS_MAP } from '../constants/expense-claim-status.const';
import { THEME_COLOR } from '../constants/theme-color.const';

/**
 * Table Definition for My Expenses page
 */
const myExpensesTableInitialSettings = {
  ...defaultTableSettings,
  itemCaption: 'My Expense Claim',
  initialFormFocusFieldName: 'name',
  baseRoute: '/expenseclaim',
  // Where status != Deleted
  baseQueryString: [
    'with[0]=user:id,name',
    'with[1]=approver:id,name',
    'with[2]=status:id,name',
    'with_count[]=items',
    'with_count[]=notes',
    'with_count[]=files',
  ].join('&'),

  columnPresets: [
    {
      name: 'default',
      title: 'Default',
      fields: [
        'id',
        'expense_claim_batch_id',
        'status',
        'expense_date',
        'name',
        'count_of_items',
        'amount',
        'user_audit_submitted_at',
        'approver',
        'user_audit_approved_at',
      ],
    },
    {
      name: 'all',
      title: 'All',
      fields: [
        'id',
        'expense_claim_batch_id',
        'expense_date',
        'status',
        'user',
        'name',
        'count_of_items',
        'amount',
        'user_audit_submitted_at',
        'approver',
        'user_audit_approved_at',
        'count_of_items',
        'count_of_files',
        'count_of_notes',
      ],
    },
  ],

  /**
   * Definition of Columns for this table
   * Includes settings for APIPolyForm fields
   */
  columns: [
    {
      name: 'id',
      title: 'ID',
      description: 'Expense Claim Unique Identifier',
      format: COLUMN_FORMAT.ID_LINK,
      linkRoute: '/hrm/my-expenses',
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: false,
      order: 0,
      formOrder: 0,
    },
    {
      name: 'expense_claim_batch_id',
      title: 'Batch',
      description: 'Expense Claim Batch Unique Identifier',
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      format: COLUMN_FORMAT.ID_LINK,
      linkId: 'expense_claim_batch_id',
      linkRoute: '/finance/process-expenses/batches',
      visible: true,
      showInForm: true,
      order: 1,
      formOrder: 1,
    },
    {
      name: 'user',
      title: 'Employee',
      description: 'Person claiming an expense',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      formSaveField: 'user_id',
      visible: false,
      showInForm: true,
      order: 30,
      formOrder: 10,
    },
    {
      name: 'approver',
      title: 'Approver',
      description: 'Manager chosen to approve the Expense Claim',
      placeholder: 'Select...',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'approver',
        key: 'name',
        format: COLUMN_FORMAT.NAME_LINK,
        linkId: 'id',
        linkRoute: '/hrm/browse-profiles',
        listSource: 'user',
      },
      formFieldType: FORM_FIELD_TYPE.USER_PICKER,
      formSaveField: 'approver_id',
      visible: true,
      showInForm: true,
      required: true,
      order: 70,
      formOrder: 20,
    },
    {
      // NOTE statuses are controlled by ACTION buttons
      name: 'status',
      title: 'Status',
      description: 'Current Expense Claim status',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'status',
        key: 'name',
        format: COLUMN_FORMAT.STATUS_BADGE,
        listSource: 'expenseStatus',
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 20,
      formOrder: 30,
    },
    {
      title: 'Date',
      name: 'expense_date',
      description: 'The date the expense was incurred',
      format: COLUMN_FORMAT.DATE,
      formFieldType: FORM_FIELD_TYPE.DATE_INPUT,
      visible: true,
      showInForm: true,
      required: true,
      order: 20,
      formOrder: 40,
    },
    {
      name: 'name',
      title: 'Short Description',
      description: 'Expense name/description',
      placeholder: 'Enter a brief description',
      format: COLUMN_FORMAT.LONG_TEXT,
      visible: true,
      showInForm: true,
      required: true,
      maxLength: 50,
      order: 30,
      formOrder: 50,
    },
    {
      name: 'amount',
      title: 'Amount',
      description: 'Expense Claim Amount',
      format: COLUMN_FORMAT.CURRENCY,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: true,
      order: 50,
      formOrder: 60,
    },
    {
      title: 'Submitted',
      name: 'user_audit_submitted_at',
      sortColumn: 'submitted_at',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'submitted_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: false,
      order: 60,
      formOrder: 70,
    },
    {
      title: 'Items',
      name: 'count_of_items',
      description: 'The count of items in the Expense Claim',
      format: COLUMN_FORMAT.NUMBER,
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: false,
      order: 40,
      formOrder: 80,
    },
    {
      title: 'Approved',
      name: 'user_audit_approved_at',
      sortColumn: 'approved_at',
      format: COLUMN_FORMAT.OBJECT,
      object: {
        sourceField: 'user_audit',
        key: 'approved_at',
        format: COLUMN_FORMAT.TIMESTAMP,
      },
      formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
      visible: true,
      showInForm: false,
      order: 80,
      formOrder: 90,
    },
    {
      name: 'count_of_items',
      title: 'Count of Items',
      description: 'The count of items claimed',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 90,
      showInForm: false,
      isReadOnly: true,
    }, {
      name: 'count_of_notes',
      title: 'Count of Notes',
      description: 'The count of the notes assigned to this claim',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 100,
      showInForm: false,
      isReadOnly: true,
    }, {
      name: 'count_of_files',
      title: 'Count of Files',
      description: 'The count of the files uploaded to support this claim',
      format: COLUMN_FORMAT.NUMBER,
      visible: false,
      order: 110,
      showInForm: false,
      isReadOnly: true,
    },
  ],
  /**
   *  Definition of Tab Groups for this table
   */
  tabs: [
    {
      name: 'detail',
      title: 'Details',
      description: 'Expense Claim Details',
      widgetDefinition: WIDGET_DEFINITION.ROW_DETAILS,
    },
    {
      name: 'files',
      title: 'Receipts',
      description: 'Receipt Documents and Files',
      widgetDefinition: WIDGET_DEFINITION.EXPENSE_CLAIM_FILES,
      apiRoute: '/file',
      apiQuery: [
        'filter[0][field]=confirmed_at',
        `filter[0][operation]=${API_FILTER_OPERATION.NOT_NULL}`,
        'sort[0][field]=filename',
        'sort[0][direction]=asc',
        'pagelength=0',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'count_of_files',
        },
      ],
    },
    {
      name: 'expenseitems',
      title: 'Items',
      description: 'Items on this Expense Claim',
      widgetDefinition: WIDGET_DEFINITION.EXPENSE_CLAIM_ITEMS,
      apiRoute: '/item',
      apiQuery: [
        'with[]=project:id,name,project_number',
        'with[]=contract:id,status_id,number',
        'with[]=serviceJob:id,status_id,number',
        'with[]=receiptFile:id,filename',
        'with[]=category',
        'pagelength=100',
      ].join('&'),
      badgeCountFields: [
        {
          fieldName: 'count_of_items',
        },
      ],
    },
    {
      name: 'notes',
      title: 'Notes',
      description: 'Expense Claim Notes',
      widgetDefinition: WIDGET_DEFINITION.EXPENSE_CLAIM_NOTES,
      apiRoute: '/note',
      apiQuery: 'with[]=createdBy:id,name&pagelength=100',
      badgeCountFields: [
        {
          fieldName: 'count_of_notes',
          badgeColor: THEME_COLOR.WARNING,
        },
      ],
    },
    {
      name: 'Summary',
      title: 'summary',
      description: 'Expense Batch ITEMS',
      widgetDefinition: WIDGET_DEFINITION.EXPENSE_CLAIM_ITEM_SUMMARY,
      apiRoute: '/item',
      apiQuery: [
        'with[]=project:id,name,project_number,quickbooks_subcustomer_id',
        'with[]=contract:id,status_id,number,quickbooks_id',
        'with[]=serviceJob:id,status_id,number,quickbooks_id',
        'with[]=receiptFile',
        'with[]=category',
        'pagelength=0',
      ].join('&'),
    },
    {
      ...eventsTabDefault,
      apiQuery: [
        'with[]=createdBy:id,name',
        'with[]=expenseClaimNoteTracking:id,data,story',
        'with[]=expenseClaimItemTracking:id,data,story',
        'pagelength=20',
      ].join('&'),
      description: 'Expense Claim Events',
      statusMap: EXPENSE_CLAIM_STATUS_MAP,
    },
  ],

  /**
   * Possible actions
   */
  possibleActions: [
    {
      name: 'create',
      title: 'Add Expense Claim',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.ADD,
      description: 'Create a new',
      successMessage: 'Created',
      failMessage: 'Failed to Create',
      hideRecordActionButton: true,
    },
    {
      name: 'update',
      title: 'Edit',
      color: BUTTON_COLOR.PRIMARY,
      icon: ICON.EDIT,
      description: 'This will update',
      successMessage: 'Updated',
      failMessage: 'Failed to update',
      hideRecordActionButton: true,
    },
    {
      name: 'submit',
      title: 'Submit',
      color: BUTTON_COLOR.INFO,
      icon: ICON.SUBMIT,
      description: 'This will submit your Expense Claim',
      successMessage: 'Expense Claim submitted',
      failMessage: 'Failed to submit your Expense Claim',
    },
    {
      name: 'unsubmit',
      title: 'Withdraw',
      color: BUTTON_COLOR.WARNING,
      icon: ICON.UNDO,
      description: 'This will WITHDRAW (unsubmit) your Expense Claim',
      successMessage: 'Expense Claim withdrawn',
      failMessage: 'Failed to withdraw your Expense Claim',
    },
    {
      name: 'approve',
      title: 'Approve',
      color: BUTTON_COLOR.SUCCESS,
      icon: ICON.CHECK,
      description: 'This will approve your Expense Claim',
      successMessage: 'Expense Claim approved',
      failMessage: 'Failed to approve your Expense Claim',
    },
    // Reject your own expense claim?
    // {
    //   name: 'reject',
    //   title: 'Reject',
    //   color: BUTTON_COLOR.DANGER,
    //   icon: 'times',
    //   description: 'This will REJECT your Expense Claim',
    //   successMessage: 'Expense Claim rejected',
    //   failMessage: 'Failed to reject your Expense Claim',
    // },
    {
      name: 'revoke',
      title: 'Revoke',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.REVOKE,
      description: 'This will REVOKE your Expense Claim',
      successMessage: 'Expense Claim revoked',
      failMessage: 'Failed to revoke your Expense Claim',
    },
    // {
    //   name: 'process',
    //   title: 'Process',
    //   color: BUTTON_COLOR.PRIMARY,
    //   icon: ICON.CHECK,
    //   description: 'This will mark your Expense Claim as processed',
    //   successMessage: 'Expense Claim was processed',
    //   failMessage: 'Failed to mark your Expense Claim as processed',
    // },
    {
      name: 'delete',
      title: 'Delete',
      color: BUTTON_COLOR.DANGER,
      icon: ICON.DELETE,
      description: 'This will DELETE your Expense Claim',
      successMessage: 'Expense Claim deleted',
      failMessage: 'Failed to delete your Expense Claim',
      hideRecordActionButton: true,
    },
  ],

  /**
   * View definitions
   */
  viewKey: 'unpaid',
  views: [
    {
      key: 'unpaid',
      title: 'Unpaid',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.NOT,
          values: ['paid'],
        },
      ],
      flags: [],
    },
    {
      key: 'paid',
      title: 'Paid',
      filters: [
        {
          field: 'status.name',
          operation: API_FILTER_OPERATION.EQUALS,
          values: ['paid'],
        },
      ],
      flags: [],
    },
    {
      key: 'all',
      title: 'All Expense Claims',
      filters: [],
      flags: [],
    },
  ],
};

export default myExpensesTableInitialSettings;
