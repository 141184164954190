import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { HTTP_METHOD } from '@corporate-initiatives/ci-portal-js-sdk';
import Icon from '../layout-helpers/icon';
import FriendlyFormMessage from '../layout-helpers/friendly-form-message';
import { updateRowData } from '../../actions/portal-data-table/refresh-row-data';
import { TABLE_IDENTIFIER } from '../../constants/table-identifier.const';
import { connectToAPIProvider } from '../providers/api-provider';
import API_PROVIDER_PROP_TYPES from '../../prop-types/api-provider-prop-types';
import { FORM_FIELD_TYPE } from '../../constants/form-field-type.const';
import { API_FILTER_OPERATION } from '../../constants/api-filter-operation.const';
import { EXPENSE_CLAIM_BATCH_STATUS } from '../../constants/expense-claim-batch-status.const';
import { EXPENSE_CLAIM_STATUS } from '../../constants/expense-claim-status.const';

/**
 * <BatchButton />
 */
class BatchButton extends Component {
  /**
   * Convert a set of props to their corresponding
   * representation in this components state
   *
   * Return a JSON object that can be used to directly
   * set the state or inside of this.setState(...)
   *
   * @param {object} nextProps
   */
  static mapPropsToState = (nextProps) => {
    const statusId = nextProps.row.status_id;
    // User ID column name, eg. could be `approver_id`
    const userIdColumn = nextProps.column.userId ? nextProps.column.userId : 'user_id';

    return {
      batchId: nextProps.row.expense_claim_batch_id,
      statusId,
      userIdColumn,
      isBatched: statusId === EXPENSE_CLAIM_STATUS.BATCHED,
      recordId: nextProps.column.linkId ? nextProps.row[nextProps.column.linkId] : nextProps.row.id,
      userId: nextProps.row[userIdColumn],
    };
  };


  /**
   * @constructor
   */
  constructor(props) {
    super(props);

    this.state = {
      isBusy: false,
      formMessage: null,
      alertColor: null,
      ...BatchButton.mapPropsToState(props),
    };
  }


  /**
   * @inheritdoc
   */
  static getDerivedStateFromProps(props) {
    return BatchButton.mapPropsToState(props);
  }


  refreshRecord = () => {
    const { refreshRecord, apiProvider: { apiFetch } } = this.props;
    const { recordId } = this.state;
    refreshRecord(recordId, apiFetch);
  };


  // Batch / un-batch if we can
  // Get first expense batch matching the filters
  doAction = (e) => {
    // Prevent row opening row-details
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isBusy: true }, async () => {
      let { batchId } = this.state;
      const { recordId } = this.state;
      const { apiProvider: { apiFetch } } = this.props;

      if (batchId) {
        // UNBATCH
        const response = await apiFetch(
          `/expenseclaim/${recordId}/action/unbatch`,
          {
            // signal: //TODO: add an abortController?
            method: HTTP_METHOD.POST,
            name: 'BatchButton::unbatch',
          },
        );

        if (response.success) {
          this.setState(
            {
              isBusy: false,
              isBatched: false,
              batchId: null,
              statusId: EXPENSE_CLAIM_STATUS.APPROVED,
            },
            this.refreshRecord,
          );
        } else if (!response.aborted) {
          this.setState({
            isBusy: false,
            formMessage: response.error,
            alertColor: 'danger',
          });
        }
      }
      else {
        const { userIdColumn, userId } = this.state;
        const existingBatchesResponse = await apiFetch(
          [
            '/expenseclaimbatch?pagelength=1',
            `filter[0][field]=${userIdColumn}`,
            `filter[0][operation]=${API_FILTER_OPERATION.EQUALS}`,
            `filter[0][value]=${userId}`,
            'filter[1][field]=status_id',
            `filter[1][operation]=${API_FILTER_OPERATION.EQUALS}`,
            `filter[1][value]=${EXPENSE_CLAIM_BATCH_STATUS.DRAFT}`,
          ].join('&'),
          {
            // signal: //TODO: add an abortController?
            name: 'BatchButton::existing',
          },
        );

        if (existingBatchesResponse.success) {
          batchId = existingBatchesResponse.body.data.length > 0 ? existingBatchesResponse.body.data[0].id : null;

          // Check if a batch
          // (to put the claim against)
          // already exists
          if (batchId !== null) {
            const addResponse = await apiFetch(
              `/expenseclaim/${recordId}/action/batch`,
              {
                name: 'BatchButton::batch',
                method: HTTP_METHOD.POST,
                body: {
                  expense_claim_batch_id: batchId,
                },
              },
            );

            if (addResponse.success) {
              this.setState(
                {
                  isBusy: false,
                  isBatched: true,
                  batchId,
                  statusId: EXPENSE_CLAIM_STATUS.BATCHED,
                },
                this.refreshRecord,
              );
            } else if (!addResponse.aborted) {
              this.setState({
                isBusy: false,
                formMessage: addResponse.error,
                alertColor: 'danger',
              });
            }
          }
          else {
            // Create a new batch, and include our expense claim ID in it
            const newBatchResponse = await apiFetch(
              '/expenseclaimbatch',
              {
                method: HTTP_METHOD.POST,
                body: {
                  name: 'BatchButton::create',
                  user_id: userId,
                  expense_claim_ids: [recordId],
                },
              },
            );

            if (newBatchResponse.success) {
              batchId = newBatchResponse.body.data ? newBatchResponse.body.data.id : null;
              if (!batchId) {
                this.setState({
                  isBusy: false,
                  formMessage: 'Failed to create new batch for this expense claim.',
                  alertColor: 'danger',
                });
              }
              else {
                this.setState(
                  {
                    isBusy: false,
                    isBatched: true,
                    batchId,
                    statusId: EXPENSE_CLAIM_STATUS.BATCHED,
                  },
                  this.refreshRecord,
                );
              }
            } else if (!newBatchResponse.aborted) {
              this.setState({
                isBusy: false,
                formMessage: newBatchResponse.error,
                alertColor: 'danger',
              });
            }
          }
        } else if (!existingBatchesResponse.aborted) {
          this.setState({
            isBusy: false,
            formMessage: existingBatchesResponse.error,
            alertColor: 'danger',
          });
        }
      }
    });
  };

  // Renders <BatchButton />
  render() {
    const {
      isBusy, formMessage, alertColor, isBatched, statusId,
    } = this.state;
    const isApproved = statusId === EXPENSE_CLAIM_STATUS.APPROVED;

    // Return <BatchButton />
    return (
      <div className="batch-item">
        {isApproved && !isBatched && (
          <Button disabled={isBusy} size="sm" color="primary" onClick={this.doAction}>
            {isBusy ? <Icon i="rolling" /> : 'Batch'}
          </Button>
        )}
        {!isApproved && isBatched && (
          <Button disabled={isBusy} size="sm" color="warning" onClick={this.doAction}>
            {isBusy ? <Icon i="rolling" /> : 'Un-batch'}
          </Button>
        )}
        <FriendlyFormMessage
          formMessage={formMessage}
          alertColor={alertColor}
          isOpen={formMessage}
          useSimpleDefault
          inline
        />
      </div>
    );
  }
}

BatchButton.defaultProps = {
  row: {
    user_id: null,
  },
  column: {
    name: 'id',
    title: 'ID',
    description: 'Batch this Row',
    formFieldType: FORM_FIELD_TYPE.NOT_EDITABLE,
    userId: 'user_id',
    linkId: 'id',
    linkRoute: '/finance/process-expenses/claims',
    visible: true,
    showInForm: false,
    order: 0,
  },
};

BatchButton.propTypes = {
  refreshRecord: PropTypes.func.isRequired,
  row: PropTypes.shape({
    id: PropTypes.number,
    user_id: PropTypes.number,
    status_id: PropTypes.number,
    expense_claim_batch_id: PropTypes.number,
  }),
  column: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    formFieldType: PropTypes.oneOf(Object.values(FORM_FIELD_TYPE)),
    format: PropTypes.string,
    userId: PropTypes.string,
    linkId: PropTypes.string,
    linkRoute: PropTypes.string,
    visible: PropTypes.bool,
    showInForm: PropTypes.bool,
    order: PropTypes.number,
  }),
  apiProvider: PropTypes.shape(API_PROVIDER_PROP_TYPES).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  refreshRecord: (id, apiFetch) => {
    dispatch(updateRowData(TABLE_IDENTIFIER.PROCESS_EXPENSE_CLAIMS_TABLE, id, apiFetch));
  },
});

export default connectToAPIProvider(connect(
  () => ({}),
  mapDispatchToProps,
)(BatchButton));
